import React, { useState, useEffect } from 'react';
import { Button, Checkbox } from '@storaensods/seeds-react';
import TimePicker from 'react-times';
import { SettingsCopyModal } from './settingsCopyModal';
import { Input } from 'reactstrap';

const MAX_DURATION_HOURS = 168;

export function SleepScheduling(props) {
    const { t, selectedCabinetSettings, submitSettingsCopy, cabinets, locations, title, updateSelectedCabinetConfiguration } = props;
    const { sleepScheduling } = selectedCabinetSettings || {};

    const [selectedDays, setSelectedDays] = useState([]);
    const [timeRange, setTimeRange] = useState({ start: '06:00', end: '22:00' });
    const [copySettingsOpen, setCopySettingsOpen] = useState(false);
    const [endHour, setEndHour] = useState(1);

    useEffect(() => {
        let previouslySelectedDays = [];
        let startHour = '06:00';
        let endHour = '22:00';

        if (sleepScheduling) {
            let parsedSleepScheduling = sleepScheduling.split(' ');
            let hours = parsedSleepScheduling[1];
            let startAndEndHours = hours.split('-');
            startHour = startAndEndHours[0].length === 1 ? '0' + startAndEndHours[0] + ':00' : (startHour = startAndEndHours[0] + ':00');
            setEndHour(Number(startAndEndHours[1]));

            let dayOfWeeks = parsedSleepScheduling[4];
            previouslySelectedDays = convertDayOfWeekToDays(dayOfWeeks);
        }

        // initializing the selected days array.
        setSelectedDays(previouslySelectedDays);

        // initializing the timer.
        setTimeRange({ start: startHour, end: endHour });
    }, [selectedCabinetSettings, sleepScheduling]);

    const onDaySelected = (checked, day) => {
        let allDays = [...selectedDays];
        if (checked) {
            allDays.push(day);
        } else {
            allDays = selectedDays.filter(selected => selected !== day);
        }
        setSelectedDays(allDays);
    };

    const onTimeRangeChange = ({ hour, minute }, type) => {
        const timeString = `${hour}:${minute}`;
        if (type === 'start') {
            setTimeRange({ start: timeString, end: timeRange.end });
        } else {
            setTimeRange({ start: timeRange.start, end: timeString });
        }
    };

    const getCorrectSettingsForModal = () => {
        const usedSettings = {};
        if (sleepScheduling) {
            usedSettings['sleepScheduling'] = sleepScheduling;
        }
        return usedSettings;
    };

    const convertDayToDayOfWeek = day => {
        let dayOfWeek = '';

        if (day === 'monday') {
            dayOfWeek = '1';
        } else if (day === 'tuesday') {
            dayOfWeek = '2';
        } else if (day === 'wednesday') {
            dayOfWeek = '3';
        } else if (day === 'thursday') {
            dayOfWeek = '4';
        } else if (day === 'friday') {
            dayOfWeek = '5';
        } else if (day === 'saturday') {
            dayOfWeek = '6';
        } else if (day === 'sunday') {
            dayOfWeek = '0';
        }

        return dayOfWeek;
    };

    const convertDayOfWeekToDays = dayOfWeeks => {
        let splittedDays = dayOfWeeks.split(',');
        let days = [];

        splittedDays.forEach(splitDay => {
            if (splitDay === '1') {
                days.push('monday');
            } else if (splitDay === '2') {
                days.push('tuesday');
            } else if (splitDay === '3') {
                days.push('wednesday');
            } else if (splitDay === '4') {
                days.push('thursday');
            } else if (splitDay === '5') {
                days.push('friday');
            } else if (splitDay === '6') {
                days.push('saturday');
            } else if (splitDay === '0') {
                days.push('sunday');
            }
        });

        return days;
    };

    const convertTimeToHour = timeRange => {
        let startHour = timeRange.start.split(':')[0];
        let endHour = timeRange.end.split(':')[0];

        return { startHour, endHour };
    };

    const constructSleepSchedulingSetting = () => {
        let minute = '*';
        let day_month = '*';
        let month = '*';
        let hour = '';
        let dayOfWeeks = '';
        let sleepSchedulingExpression;
        const { startHour } = convertTimeToHour(timeRange);
        let duration = isNaN(endHour) ? 1 : Number(endHour);
        hour = Number(startHour) + '-' + duration;

        if (selectedDays.length === 0) {
            sleepSchedulingExpression = null;
        } else if (selectedDays.length === 1) {
            dayOfWeeks = convertDayToDayOfWeek(selectedDays[0]);
            sleepSchedulingExpression = minute + ' ' + hour + ' ' + day_month + ' ' + month + ' ' + dayOfWeeks;
        } else {
            for (let dayIndex = 0; dayIndex < selectedDays.length; dayIndex++) {
                if (dayIndex === selectedDays.length - 1) {
                    dayOfWeeks += convertDayToDayOfWeek(selectedDays[dayIndex]);
                } else {
                    dayOfWeeks += convertDayToDayOfWeek(selectedDays[dayIndex]) + ',';
                }
            }
            sleepSchedulingExpression = minute + ' ' + hour + ' ' + day_month + ' ' + month + ' ' + dayOfWeeks;
        }

        addNewSetting(sleepSchedulingExpression);
    };

    const addNewSetting = sleepSchedulingExpression => {
        let toBeUpdated = {};
        toBeUpdated['sleepScheduling'] = sleepSchedulingExpression;
        updateSelectedCabinetConfiguration(toBeUpdated);
    };

    const handleDiscountStopTimeHoursChange = event => {
        let inputValue = event.target.value;

        if (inputValue === '0') {
            inputValue = '1';
        }

        let duration = parseInt(inputValue);

        if (duration > MAX_DURATION_HOURS) {
            duration = MAX_DURATION_HOURS;
        }

        setEndHour(duration);
    };

    return (
        <div>
            <p>{t('sleepScheduling:timeRangeTitle')}</p>
            <div className="sleepScheduling__selectedDays">
                <div className="sleepScheduling__selectedDays-days">
                    <p className="sleepScheduling__selectedDays-title">{t('sleepScheduling:selectDays')}</p>
                    <ul>
                        <li>
                            <Checkbox id="monday" onChange={e => onDaySelected(e.target.checked, 'monday')} checked={selectedDays.includes('monday')} />{' '}
                            {t('main:monday')}
                        </li>
                        <li>
                            <Checkbox id="tuesday" onChange={e => onDaySelected(e.target.checked, 'tuesday')} checked={selectedDays.includes('tuesday')} />{' '}
                            {t('main:tuesday')}
                        </li>
                        <li>
                            <Checkbox
                                id="wednesday"
                                onChange={e => onDaySelected(e.target.checked, 'wednesday')}
                                checked={selectedDays.includes('wednesday')}
                            />{' '}
                            {t('main:wednesday')}
                        </li>
                        <li>
                            <Checkbox id="thursday" onChange={e => onDaySelected(e.target.checked, 'thursday')} checked={selectedDays.includes('thursday')} />{' '}
                            {t('main:thursday')}
                        </li>
                        <li>
                            <Checkbox id="friday" onChange={e => onDaySelected(e.target.checked, 'friday')} checked={selectedDays.includes('friday')} />{' '}
                            {t('main:friday')}
                        </li>
                        <li>
                            <Checkbox id="saturday" onChange={e => onDaySelected(e.target.checked, 'saturday')} checked={selectedDays.includes('saturday')} />{' '}
                            {t('main:saturday')}
                        </li>
                        <li>
                            <Checkbox id="sunday" onChange={e => onDaySelected(e.target.checked, 'sunday')} checked={selectedDays.includes('sunday')} />{' '}
                            {t('main:sunday')}
                        </li>
                    </ul>
                </div>
                <div className="sleepScheduling__selectedDays-timer">
                    <label>{t('sleepScheduling:startTime')}</label>
                    <TimePicker
                        theme="classic"
                        timeMode="24"
                        timeConfig={{
                            step: 1,
                            unit: 'hour',
                        }}
                        time={timeRange.start}
                        onTimeChange={time => onTimeRangeChange(time, 'start')}
                    />
                    <br />
                    <label>{t('sleepScheduling:duration')}</label>
                    <Input type="number" class="form-control" step="any" value={endHour.toString()} onChange={e => handleDiscountStopTimeHoursChange(e)} />
                </div>
            </div>

            <div className="d-flex justify-content-between text-right mt-4">
                <Button onClick={() => setCopySettingsOpen(true)}>
                    <i className="fas fa-copy mr-2" />
                    <span>{t('settings:settingsCopyButton')}</span>
                </Button>

                {/* Save button */}
                <Button
                    onClick={() => {
                        constructSleepSchedulingSetting();
                    }}
                >
                    {t('save')}
                </Button>
            </div>
            {copySettingsOpen && (
                <SettingsCopyModal
                    closeModal={() => setCopySettingsOpen(!copySettingsOpen)}
                    t={t}
                    submitSettingsCopy={submitSettingsCopy}
                    cabinets={cabinets}
                    locations={locations}
                    settingsType={title}
                    settings={getCorrectSettingsForModal(selectedCabinetSettings)}
                />
            )}
        </div>
    );
}
