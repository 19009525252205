import React, { Fragment, useEffect, useState } from 'react';
import { Button, InfoBanner } from '../../commons/utils';
import icons from './../../../icons/icomoon.svg';
import CabinetLists from '../segments/cabinetLists';
import EmailLists from '../segments/emailLists';
import { createAutomation, updateAutomation, fetchAutomation, deleteAutomation } from '../../../actions/automations';
import { connect } from 'react-redux';
import AutomationSavedModal from '../modals/AutomationSavedModal';

const DAILY = 1;
const WEEKLY = 7;

/**
 *
 * @param {object} props
 * @param {object} props.cabinetsByLocations
 * @param {function} props.cancelPage
 * @param {function} props.t
 * @param {string} props.reportType
 * @param {function} props.createAutomation
 * @returns
 */
const ReportsAutomation = props => {
    const { cabinetsByLocations, cancelPage, t, reportType, createAutomation, updateAutomation, fetchAutomation, deleteAutomation } = props;

    const [selectedCabinets, setSelelctedCabinets] = useState({});
    const [emailAddresses, setEmailAddresses] = useState([]);
    const [duration, setDuration] = useState('');
    const [reportUrl, setReportUrl] = useState('');
    const [reportVisibility, setReportVisibility] = useState('none');
    const [automationId, setAutomationId] = useState('');
    const [isAutomationExist, setIsAutomationExist] = useState(false);
    const [automationSaveModalOpen, setAutomationSaveModalOpen] = useState(false);
    const [automationSaveData, setAutomationSaveData] = useState({});
    const [isSaveButtonActive, setIsSavedButtonActive] = useState(false);
    const [message, setMessage] = useState('');

    useEffect(() => {
        const initialization = [fetchAutomation(reportType)];
        Promise.all(
            initialization.map(async func => {
                const automationResponse = await func;
                if (automationResponse.error) {
                    console.error(automationResponse.error);
                    return Promise.reject(automationResponse.error);
                }
                if (automationResponse.automation.length === 0) {
                    setIsAutomationExist(false);
                    setEmailAddresses([]);
                    setSelelctedCabinets({});
                    setDuration('');
                    setReportUrl('');
                    setReportVisibility('none');
                    setAutomationId('');
                    return Promise.resolve();
                } else {
                    setIsAutomationExist(true);
                    setEmailAddresses(automationResponse.automation[0].toList);
                    setSelelctedCabinets(
                        Object.entries(cabinetsByLocations).reduce((acc, [location, cabinets]) => {
                            const selectedCabinets = automationResponse.automation[0].deviceCodeList
                                .map(deviceCode => {
                                    return cabinets.find(cabinet => cabinet.deviceCode === deviceCode);
                                })
                                .filter(cabinet => cabinet);
                            acc[location] = selectedCabinets;
                            return acc;
                        }, {})
                    );
                    if (automationResponse.automation[0].metadata.durationAsDay === DAILY) {
                        setDuration('daily');
                    } else if (automationResponse.automation[0].metadata.durationAsDay === WEEKLY) {
                        setDuration('weekly');
                    } else if (automationResponse.automation[0].metadata.durationAsMonth) {
                        setDuration('monthly');
                    }
                    if (automationResponse.automation[0].metadata.reportUrl) {
                        setReportUrl(automationResponse.automation[0].metadata.reportUrl);
                        setReportVisibility('flex');
                    }
                    setAutomationId(automationResponse.automation[0]._id);
                    return Promise.resolve();
                }
            })
        )
            .then(() => {})
            .catch(error => {
                // Handle errors
                console.error('Error fetching data:', error);
            });
        setIsSavedButtonActive(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reportType]);

    const handleFrequencyChange = e => {
        setDuration(e.target.value);
        if (Object.keys(selectedCabinets).length > 0) {
            setIsSavedButtonActive(true);
        }
        else {
            setIsSavedButtonActive(false);
        }
    };

    /**
     * when save button is clicked
     * @returns
     */
    const onSaveClicked = async () => {
        setAutomationSaveData({ saving: true });
        setAutomationSaveModalOpen(true);
        if (Object.keys(selectedCabinets).length === 0) {
            console.error('no cabinet selected');
            return;
        }

        const deviceCodes = Object.entries(selectedCabinets)
            .map(([location, cabinets]) => {
                return cabinets.map(cabinet => cabinet.deviceCode);
            })
            .flat();

        const emailList = emailAddresses.filter(email => email !== '');
        const sendMail = emailList.length > 0;
        let durationAsDay = 0;
        let durationAsMonth = 0;
        switch (duration) {
            case 'daily':
                durationAsMonth = 0;
                durationAsDay = 1;
                break;
            case 'weekly':
                durationAsMonth = 0;
                durationAsDay = 7;
                break;
            case 'monthly':
                durationAsMonth = 1;
                durationAsDay = 0;
                break;
            default:
                durationAsMonth = 0;
                durationAsDay = 0;
                console.error('no duration selected');
                return;
        }

        let occurencesCron = '';
        switch (duration) {
            case 'daily':
                occurencesCron = '0 0 0 * * *';
                break;
            case 'weekly':
                occurencesCron = '0 0 0 * * 0';
                break;
            case 'monthly':
                occurencesCron = '0 0 0 1 * *';
                break;
            default:
                console.error('no occurences selected');
                return;
        }

        const automationData = {
            name: 'Transaction Report',
            type: reportType,
            occurences: occurencesCron,
            startTime: null,
            endTime: null,
            deviceCodeList: deviceCodes,
            status: 'active',
            metadata: {
                durationAsDay,
                durationAsMonth,
            },
            sendMail: sendMail,
            toList: emailList,
        };
        let result;
        if (!isAutomationExist) {
            result = await createAutomation(automationData);
        } else {
            result = await updateAutomation(automationId, automationData);
        }
        if (result.error) {
            setAutomationSaveData({ saving: false, error: true });
        } else {
            setAutomationId(result.automation._id);
            setIsAutomationExist(true);
            setAutomationSaveData({ saving: false, isSaved: true });
        }
    };

    /**
     *
     * @param {*} value
     * @param {*} position
     */
    const emailAddressChange = (value, position) => {
        const allEmailAdresses = [];
        emailAddresses.forEach((email, index) => {
            if (index === position) {
                allEmailAdresses.push(value);
                return;
            }
            allEmailAdresses.push(email);
        });

        setEmailAddresses(allEmailAdresses);
    };

    /**
     *
     * @param {*} position index of the email address
     */
    const removeEmail = position => {
        const allEmailAdresses = [];
        emailAddresses.forEach((email, index) => {
            if (index !== position && email !== '') {
                allEmailAdresses.push(email);
            }
        });
        setEmailAddresses(allEmailAdresses);
    };

    const onDeleteClicked = async () => {
        setAutomationSaveData({
            saving: true,
        });
        setAutomationSaveModalOpen(true);
        const result = await deleteAutomation(automationId);
        if (result.error) {
            setAutomationSaveData({ saving: false, error: true });
            setMessage(t('automations:automationDeleted'));
        } else {
            setIsAutomationExist(false);
            setEmailAddresses([]);
            setSelelctedCabinets({});
            setDuration('');
            setReportUrl('');
            setReportVisibility('none');
            setAutomationId('');
            setAutomationSaveData({ saving: false, isSaved: true });
            setMessage(t('automations:automationDeleted'));
        }
    };

    const handleCabinetSelection = cabinets => {
        setSelelctedCabinets(cabinets);
        if (Object.keys(cabinets).length > 0 && 
            Object.keys(cabinets).some(location => cabinets[location].length > 0) &&
            duration !== '') 
        {
            setIsSavedButtonActive(true);
        }
        else {
            setIsSavedButtonActive(false);
        }
    };

    return (
        <Fragment>
            <AutomationSavedModal
                isOpen={automationSaveModalOpen}
                data={automationSaveData}
                closeModal={() => {
                    setAutomationSaveModalOpen(false);
                }}
                successMessage={message}
                t={t}
            />
            {
                <div className="automations__container">
                    <CabinetLists
                        cabinetsByLocations={cabinetsByLocations}
                        selectedCabinets={selectedCabinets}
                        cabinetSelections={cabinets => handleCabinetSelection(cabinets)}
                        t={t}
                    />

                    <div className="automations__contents">
                        <div className="automations__reports-download" title="click to download file">
                            <a style={{ display: reportVisibility }} href={reportUrl} target="_blank" rel="noopener noreferrer">
                                <svg>
                                    <use xlinkHref={`${icons}#icon-download`}></use>
                                </svg>
                                <span>Latest Report</span>
                            </a>
                        </div>
                        <InfoBanner
                            message={reportType === 'transactionReport' ? t('automations:transactionReportTitle') : t('automations:refillReportTitle')}
                        />
                        <div className="automations__contents-selects">
                            <p>{t('automations:frequencyTitle')}</p>
                            <ul>
                                <li>
                                    <input type="radio" name="reportFrequency" checked={duration === 'daily'} value="daily" onChange={handleFrequencyChange} />{' '}
                                    {t('automations:everyDay')}
                                </li>
                                <li>
                                    <input
                                        type="radio"
                                        name="reportFrequency"
                                        checked={duration === 'weekly'}
                                        value="weekly"
                                        onChange={handleFrequencyChange}
                                    />{' '}
                                    {t('automations:everyWeek')}
                                </li>
                                <li>
                                    <input
                                        type="radio"
                                        name="reportFrequency"
                                        checked={duration === 'monthly'}
                                        value="monthly"
                                        onChange={handleFrequencyChange}
                                    />{' '}
                                    {t('automations:everyMonth')}
                                </li>
                            </ul>
                        </div>
                        <EmailLists
                            emailAddresses={emailAddresses}
                            emailAddressChange={(email, index) => emailAddressChange(email, index)}
                            removeEmail={index => removeEmail(index)}
                            addNewEmail={() =>
                                setEmailAddresses(prevState => {
                                    return [...prevState, ''];
                                })
                            }
                            t={t}
                        />
                        <div className="automations__contents-actions">
                            <Button type="cancel" text={t('main:cancel')} onClick={() => cancelPage()} />
                            <Button
                                type="delete"
                                text={t('main:delete')}
                                disabled={!isAutomationExist}
                                onClick={() => {
                                    onDeleteClicked();
                                }}
                                ml
                            />
                            <Button
                                type="save"
                                text={t('main:save')}
                                onClick={() => {
                                    onSaveClicked();
                                }}
                                disabled={!isSaveButtonActive}
                                ml
                            />
                        </div>
                    </div>
                </div>
            }
        </Fragment>
    );
};

export default connect(
    state => ({
        organization: state.user.group.Name,
    }),
    {
        createAutomation,
        updateAutomation,
        fetchAutomation,
        deleteAutomation,
    }
)(ReportsAutomation);
