import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Button, Spinner } from '@storaensods/seeds-react';
import Switch from 'react-switch';
import { translate } from 'react-i18next';

const AdminSettingItem = props => {
    const { settingKey, settingValue, updatedSettings, updateUpdatedSettings, disabled = false, isFetching } = props;

    const updatedSetting = updatedSettings[settingKey];
    const deleted = updatedSetting && updatedSetting.deleted;
    const updated = !deleted && updatedSetting && typeof updatedSetting.value !== 'undefined' && updatedSetting.value !== settingValue;
    const isNew = !deleted && updatedSetting && updatedSetting.new;

    const verifiedValue = updatedSetting && typeof updatedSetting.value !== 'undefined' ? updatedSetting.value : settingValue;

    return (
        <div key={settingKey} className={`d-flex align-items-center mb-2 ${deleted ? 'deleted' : ''} ${updated ? 'updated' : ''} ${isNew ? 'new' : ''}`}>
            {/* Key */}
            <div className="setting-label cabinet-label-setting">
                <span>{settingKey}</span>
            </div>

            {/* Value editor */}
            {isFetching ? (
                <div>
                    <Spinner />
                </div>
            ) : (
                <div className="se-input-container ml-3">
                    {/* Boolean settings */}
                    {typeof verifiedValue === 'boolean' && (
                        <Switch
                            // eslint-disable-next-line
                            checked={verifiedValue}
                            onColor="#67b419"
                            offColor="#d6d9df"
                            className="se-seeds-toggle setting-value"
                            disabled={deleted || disabled}
                            onChange={newValue => {
                                const uSettings = { ...updatedSettings };
                                uSettings[settingKey] = { ...updatedSettings[settingKey], value: newValue };
                                updateUpdatedSettings(uSettings);
                            }}
                        />
                    )}

                    {/* All other settings */}
                    {typeof verifiedValue !== 'boolean' && (
                        <input
                            type="text"
                            placeholder="Input text"
                            className="se-input se-input--md setting-value"
                            disabled={deleted || disabled}
                            value={verifiedValue}
                            onChange={event => {
                                const newValue =
                                    ['true', 'false'].indexOf(event.target.value.toLowerCase()) >= 0
                                        ? event.target.value.toLowerCase() === 'true'
                                        : event.target.value;
                                const uSettings = { ...updatedSettings };
                                uSettings[settingKey] = { ...updatedSettings[settingKey], value: newValue };
                                updateUpdatedSettings(uSettings);
                            }}
                        />
                    )}
                </div>
            )}

            {/* Delete button */}
            {!isFetching && (
                <Button
                    className={`se-btn se-btn--sm se-btn--secondary ml-3 ${deleted ? 'se-btn--negative white' : ''}`}
                    icon={deleted ? 'settings_backup_restore' : 'delete'}
                    onClick={() => {
                        const uSettings = { ...updatedSettings };
                        uSettings[settingKey] = {
                            ...updatedSettings[settingKey],
                            deleted: !(updatedSettings[settingKey] && updatedSettings[settingKey].deleted),
                        };
                        updateUpdatedSettings(uSettings);
                    }}
                />
            )}

            {/* Reset button */}
            {updated && (
                <Button
                    className={'se-btn se-btn--sm se-btn--secondary ml-3'}
                    onClick={() => {
                        const uSettings = { ...updatedSettings };
                        delete uSettings[settingKey];
                        updateUpdatedSettings(uSettings);
                    }}
                >
                    <i className="fas fa-redo" />
                </Button>
            )}
        </div>
    );
};

export default withRouter(
    connect(state => ({
        isFetching: state.controlPanel.selectedCabinet.fetchingSettings,
    }))(translate('main')(AdminSettingItem))
);
