export const initState = {
    isInventoryAlarmRaised: false,
};
export default function alarmIcon(state = initState, action) {
    switch (action.type) {
        case 'SHOW_INVENTORY_ALARMICON':
            return {
                ...state,
                isInventoryAlarmRaised: true,
            };
        case 'HIDE_INVENTORY_ALARMICON':
            return {
                ...state,
                isInventoryAlarmRaised: false,
            };
        default:
            return state;
    }
}
