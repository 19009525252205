/**
 * Sort function alphabetically by name for locations
 * @param {object} a location
 * @param {object} b location
 */
export const locationSortFunc = function(a, b) {
    if (!a.name && !b.name) return 0;
    if (!a.name) return 1;
    if (!b.name) return -1;
    const nameA = a.name.toUpperCase();
    const nameB = b.name.toUpperCase();
    if (nameA > nameB) return 1;
    if (nameA < nameB) return -1;
    return 0;
};

/**
 * Sort function alphabetically by name for cabinets.
 * Also makes sure cabinets that are online are given priority in sort.
 * @param {object} a cabinet
 * @param {object} b cabinet
 */
export const cabinetSortFunc = function(a, b) {
    if (!a.name && !b.name) return 0;
    if (!a.name) return 1;
    if (!b.name) return -1;
    const nameA = a.name.toUpperCase();
    const nameB = b.name.toUpperCase();
    const onlineA = a.isOnline;
    const onlineB = b.isOnline;
    if ((onlineA && onlineB) || (!onlineA && !onlineB)) {
        if (nameA > nameB) return 1;
        if (nameA < nameB) return -1;
        return 0;
    } else if (onlineA) {
        return -1;
    } else {
        return 1;
    }
};
