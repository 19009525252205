import React from 'react';

import { Tooltip, ResponsiveContainer, PieChart, Pie, Legend, Cell, Label } from 'recharts';

import { chartColors } from './../../../cubeCharts';

const WasteVsSalesPieChart = ({ data }) => {
    const chartData = data.data;
    const currency = data.currency;

    function pieChartLabelContent(props) {
        const { value, percent, x, y, midAngle } = props;

        return (
            <g
                transform={`translate(${x}, ${y})`}
                textAnchor={midAngle < -90 || midAngle >= 90 ? 'end' : 'start'}
            >
                <text x={0} y={0}>{`Amount: ${value.toFixed(2)} ${currency} ( ${(
                    percent * 100
                ).toFixed(2)}% )`}</text>
            </g>
        );
    }

    return (
        <div className="pie-chart-wrapper">
            <ResponsiveContainer width="99%" height={420}>
                <PieChart>
                    <Legend paylodUniqBy />
                    <Pie
                        data={chartData}
                        dataKey="value"
                        startAngle={180}
                        endAngle={-180}
                        innerRadius={80}
                        outerRadius={150}
                        label={pieChartLabelContent}
                        paddingAngle={0}
                        isAnimationActive={false}
                    >
                        {chartData.map((entry, index) => (
                            <Cell key={`slice-${index}`} fill={chartColors[index % 10]} />
                        ))}
                        <Tooltip />
                        <Label width={50} position="center">
                            Sales vs Waste
                        </Label>
                    </Pie>
                </PieChart>
            </ResponsiveContainer>
        </div>
    );
};

export default WasteVsSalesPieChart;
