import { v1 as uuidv1 } from 'uuid';
import { createThemeImage as createThemeImageHttpRequest } from './../api.js';

export const THEMING_IMAGE_UPLOAD = 'THEMING_IMAGE_UPLOAD';
export const THEMING_IMAGE_RECEIVE = 'THEMING_IMAGE_RECEIVE';
export const THEMING_IMAGE_ERROR = 'THEMING_IMAGE_ERROR';
export const POST_PURCHASE_THEME_IMAGE_UPLOAD='POST_PURCHASE_THEME_IMAGE_UPLOAD';
export const POST_PURCHASE_THEME_IMAGE_RECEIVE='POST_PURCHASE_THEME_IMAGE_RECEIVE';
export const POST_PURCHASE_THEME_IMAGE_ERROR='POST_PURCHASE_THEME_IMAGE_ERROR';

/**
 * notify imageuploading started
 */
const requestUploadImageStart = () => {
    return {
        type: THEMING_IMAGE_UPLOAD,
    };
};

/**
 *
 * @param {*} imgUrl
 * @returns
 */
const requestUploadImageSuccess = imgUrl => {
    return {
        type: THEMING_IMAGE_RECEIVE,
        imgUrl,
    };
};

/**
 *
 * @param {*} error
 * @returns
 */
const requestUploadImageFailure = error => {
    return {
        type: THEMING_IMAGE_ERROR,
        error,
    };
};

/**
 * 
 * @returns 
 */
const requestUploadPostPurchaseImageStart= ()=>{
    return {
        type:POST_PURCHASE_THEME_IMAGE_UPLOAD
    }
}

const requestUploadPostPurchaseImageSuccess=(imgUrl)=>{
    return{
        type:POST_PURCHASE_THEME_IMAGE_RECEIVE,
        imgUrl
    }
}

const requestUploadPostPurchaseImageFailure=(error)=>{
    return{
        type:POST_PURCHASE_THEME_IMAGE_ERROR,
        error
    }
}

/**
 *
 * @param {File} imageFile
 */
export function uploadThemeImage(imageFile) {
    return (dispatch, getState) => {
        // call state to notify uploa begins
        dispatch(requestUploadImageStart());
        // convert image file to form data and give unique id for image name
        const formData = new FormData();
        const fileName = `${uuidv1()}.jpeg`;
        formData.append(0, imageFile, fileName);

        const user = getState().user;

        return createThemeImageHttpRequest(formData, user.accessToken, user.group)
            .then(response => dispatch(requestUploadImageSuccess(encodeURI(response.imgUrl))))
            .catch(error => dispatch(requestUploadImageFailure(error)));
    };
}

/**
 *action for creating post purchase theme image
 * @param {File} imageFile
 */
export function uploadPostPurchaseThemeImage(imageFile) {
    return (dispatch, getState) => {
        // call state to notify uploa begins
        dispatch(requestUploadPostPurchaseImageStart());
        // convert image file to form data and give unique id for image name
        const formData = new FormData();
        const fileName = `${uuidv1()}.jpeg`;
        formData.append(0, imageFile, fileName);

        const user = getState().user;

        return createThemeImageHttpRequest(formData, user.accessToken, user.group)
            .then(response => dispatch(requestUploadPostPurchaseImageSuccess(encodeURI(response.imgUrl))))
            .catch(error => dispatch(requestUploadPostPurchaseImageFailure(error)));
    };
}
