import React from 'react';
import { tabs } from './../config';

const ProductTabs = ({ tabChange, activeTab, t }) => {
    return (
        <div className="products products__tab">
            {tabs.map(tab => {
                return (
                    <div
                        key={tab.index}
                        className={`products__tab-item ${activeTab === tab.index ? 'products__tab-item--active' : ''}`}
                        onClick={() => tabChange(tab.index, 'tabChange')}
                    >
                        <span className="products__tab-index">{tab.index}</span>{' '}
                        <span className="products__tab-title">
                            {tab.mandatory ? '*' : ''}
                            {t(tab.title)}
                        </span>
                    </div>
                );
            })}
        </div>
    );
};

export default ProductTabs;
