import React from 'react';
import { Row, Col } from 'reactstrap';
import { CubeBarChartForConsumerResponses } from '../cubeCharts.js';
import FeedbacksAndWishes from './feedbacksAndWishes.js';

const ConsumerResponsesDashboard = ({ consumerResponseDashboardData, t }) => {
    const data = consumerResponseDashboardData?.consumerResponseRatings?.data;
    const consumerResponsesQuery = consumerResponseDashboardData?.consumerResponses;
    let chartData = [];
    if (data)
        data.forEach(d => {
            chartData.push([
                { rating: '100% Happy', target: d['Device_CF.deviceName'] || d['Location.name'], count: d['ConsumerFeedback.rating100Count'] },
                { rating: '66% Happy', target: d['Device_CF.deviceName'] || d['Location.name'], count: d['ConsumerFeedback.rating66Count'] },
                { rating: '33% Happy', target: d['Device_CF.deviceName'] || d['Location.name'], count: d['ConsumerFeedback.rating33Count'] },
                { rating: '0% Happy', target: d['Device_CF.deviceName'] || d['Location.name'], count: d['ConsumerFeedback.rating0Count'] },
            ]);
        });
    return (
        <>
            <Row className="pl-4 pr-4 pt-3">
                <Col xs="12" md="6" lg="6">
                    <div className="dashboard-chart-container">
                        {!chartData || !chartData.length ? (
                            <div className="dashboard-no-data">{t('noData')}</div>
                        ) : (
                            <CubeBarChartForConsumerResponses data={chartData} t={t} chartHeight={400} xKey="count" yKey="rating" />
                        )}
                    </div>
                </Col>
                <Col md="6" lg="6">
                    <b>{t('wishes')}</b>
                    <div className="dashboard-consumer-response-feedback-wishes-container">
                        <FeedbacksAndWishes data={consumerResponsesQuery} t={t} />
                    </div>
                </Col>
            </Row>
        </>
    );
};

export default ConsumerResponsesDashboard;
