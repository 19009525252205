import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { checkdigit } from 'gs1';
import validator from 'validator';
import { ListGroup, ListGroupItem, Col, Row, Form, FormGroup, Label, Input, FormFeedback, FormText } from 'reactstrap';
import { ButtonGroup, Button, Card, Spinner, Modal, Tile, Tag, Icon } from '@storaensods/seeds-react';
import SingleSelectFilter from '../shared/singleSelectFilter.js';
import MultipleSelectFilter from '../shared/multipleSelectFilter.js';
import {
    fetchProducts,
    createProduct,
    archiveProduct,
    deleteProduct,
    dismissCreateProductResult,
    updateProduct,
    dismissUpdateProductResult,
    uploadProductImage,
    uploadProductImageSuccess,
    removeProductImage,
} from '../../actions/products.js';
import { fetchCabinets, fetchLocations } from '../../actions/cabinets.js';
import { fetchProductTypes } from '../../actions/productTypes.js';
import { fetchProductCategories } from '../../actions/productCategories';
import { fetchProductLabels } from '../../actions/productLabels';
import { fetchVatCategories } from '../../actions/vatCategories.js';
import { fetchSuppliers } from '../../actions/suppliers.js';
import { store } from './../../index';
import FetchingAlert from '../fetching/fetchingAlert.js';
import ImageUpload from '../imageUpload/imageUpload.js';
import './products.css';
import { Tooltip } from 'react-tippy';
import ProductSearchBar from './productSearchBar';
import { showNotification } from '../toastNotification/toastNotification.js';
import Switch from 'react-switch';
import NotAccessibleLabel from '../notAccessibleLabel/NotAccessibleLabel.jsx';
import DeleteProductModal from './Modals/deleteProductModal';
import { split } from 'lodash';
import { formatProductsForXLSX, generateProductsCSVHeaders, formattedProductsForCSV } from '../report/export.js';
import ProductsExport from './productsExport.js';
import '../settings/cabinetTheming.css';
import icons from '../settings/theming/icons.svg';

const currenciesAll = require('../../commonCurrency.json');
const currencies = JSON.parse(process.env.REACT_APP_CURRENCIES).map(code => currenciesAll[code]);
if (!currencies || currencies.length === 0) {
    console.error('Missing currencies in REACT_APP_CURRENCIES configuration!');
    throw new Error('Missing currencies in REACT_APP_CURRENCIES configuration!');
}

/**
 * Product details (image, name, barcode, price...)
 * @param {Product} product Inside props object
 * @param {boolean} editProduct editing existing product
 * @param {boolean} canEdit
 */
class Product extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isFrozen: false,
            showingLocationPrices: false,
            openModel: false,
            productStatusProgress: false,
        };
    }

    toggleShowingLocationPrices = () => {
        this.setState(prevState => ({
            ...prevState,
            showingLocationPrices: !prevState.showingLocationPrices,
        }));
    };

    render() {
        const { product, editProduct, canEdit, t, isAdmin } = this.props;
        const locationPricings = (product.prices || []).filter(price => price.locationId != null);
        const locationPricingsComponent = (
            <table className="table table-sm table-borderless mt-2 animated fadeIn faster bg-light shadow-sm">
                <thead>
                    <tr>
                        <th>{t('location')}</th>
                        <th>{t('price')}</th>
                    </tr>
                </thead>
                <tbody>
                    {locationPricings.length
                        ? locationPricings.map((price, i) => (
                              <tr key={`${price.id}-${i}`}>
                                  <td>
                                      {this.props.locations
                                          ? this.props.locations.find(location => location.id === price.locationId)?.name || price.locationId
                                          : t('notDefined')}
                                  </td>
                                  <td>
                                      {price.price.toFixed(2)}&nbsp;{price.currency}
                                  </td>
                              </tr>
                          ))
                        : null}
                    {!locationPricings.length && (
                        <tr>
                            <td colSpan="2">
                                <small>{t('noLocationPrices')}</small>
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>
        );

        return (
            <div>
                <Row className="my-3 no-gutters se-paragraph-base">
                    <Col xs="4" md="1" className="px-1">
                        {product.imageUrl ? (
                            <img src={product.imageUrl} alt="product" style={{ width: '60px' }} />
                        ) : (
                            <div className="text-center d-flex align-items-center products-missing-image-container">
                                <span className="icon-picture material-icons" style={{ width: '60px' }}></span>
                            </div>
                        )}
                    </Col>
                    <Col xs="4" md="2" className="px-1">
                        <div className="font-weight-bold no-overflow">{product.name}</div>
                        <div>
                            {t('barcode')}: {product.barcode}
                        </div>
                        {product.labels && product.labels.length > 0 && (
                            <div>
                                {product.labels.map(label => {
                                    return (
                                        <Tag className="mr-1" type="attention" key={label}>
                                            {label}
                                        </Tag>
                                    );
                                })}
                            </div>
                        )}
                    </Col>
                    <Col xs="4" md="2" className="px-1">
                        <div>
                            <div>
                                <h5>
                                    <Tooltip theme="light" title={t('clickEditLocationPrices')} position="right" trigger="mouseenter">
                                        <span className="mr-3" onClick={this.toggleShowingLocationPrices}>
                                            <Tag type={locationPricings.length > 0 ? 'positive' : 'primary'}>{locationPricings.length}</Tag>
                                        </span>
                                    </Tooltip>

                                    <span className="product-price" onClick={this.toggleShowingLocationPrices}>
                                        {(+product.price).toFixed(2)}&nbsp;{product.currency}
                                    </span>
                                </h5>
                                {this.state.showingLocationPrices ? locationPricingsComponent : null}
                            </div>
                        </div>
                    </Col>
                    <Col xs="4" md="2" lg="2" className="px-1 hide-in-mb">
                        <div className="no-overflow">
                            {product.productCategory && product.productCategory.name ? `${product.productCategory.name}` : t('notDefined')}
                        </div>
                    </Col>
                    <Col xs="4" md="3" lg="2" className="px-1 hide-in-mb">
                        <div className="no-overflow">
                            {product.vatCategory && product.vatCategory.name
                                ? `${product.vatCategory.name} - ${product.vatCategory.taxValue}%`
                                : t('notDefined')}
                        </div>
                    </Col>
                    <Col xs="4" md="2" lg="2" className="px-1 hide-in-mb">
                        <div className="no-overflow">{product.supplier && product.supplier.name ? `${product.supplier.name}` : t('notDefined')}</div>
                    </Col>
                    <Col xs="auto" md="auto" lg="auto" className="px-1 hide-in-mb">
                        <div>{product.timeToLiveDays}</div>
                    </Col>
                    {this.props.isAdmin && (
                        <div className="product-options">
                            {canEdit && product.status === 2 ? (
                                <ButtonGroup>
                                    <Button size="md" type="secondary" icon="mode_edit" onClick={editProduct.bind(this, product)} disabled={!isAdmin} />
                                    <Button
                                        size="md"
                                        type="secondary"
                                        icon="remove_circle_outline"
                                        disabled={!isAdmin}
                                        onClick={() => {
                                            this.props.openProductStatusModel(product);
                                        }}
                                    />
                                </ButtonGroup>
                            ) : (
                                <>
                                    <Button size="md" type="secondary" icon="mode_edit" onClick={editProduct.bind(this, product)} disabled={true} />
                                    {!this.props.formOpen && (
                                        <>
                                            <Button
                                                size="md"
                                                type="positive"
                                                icon="done"
                                                disabled={!isAdmin}
                                                onClick={() => {
                                                    this.props.openProductStatusModel(product);
                                                }}
                                            />
                                            <Button
                                                size="md"
                                                type="negative"
                                                icon="delete_forever"
                                                disabled={!isAdmin}
                                                onClick={() => {
                                                    this.props.openDeleteProductModal(product);
                                                }}
                                            />
                                        </>
                                    )}
                                </>
                            )}
                        </div>
                    )}
                </Row>
            </div>
        );
    }
}

/**
 * React product edit form component.
 * Can be used for creating or updating products.
 * Result of create/update is also displayed by this component.
 *
 * @param {function} productRequest In props function to call with resulting product
 * @param {object} reduxProduct In props state of product create or update request
 * @param {object} reduxProductTypes In props state of product types
 * @param {function} dismissResult In props function called to dismiss request result from server
 * @param {function} uploadProductImageSuccess Action in props must be set when editing
 * @param {function} uploadProductImage Action in props to upload image
 * @param {function} removeProductImage Action in props to remove current image
 * @param {boolean} editing In props set to true if editing and not creating
 * @param {Product} product In props must be set if editing
 */
export class EditProduct extends Component {
    constructor(props) {
        super(props);
        this.state = {
            bannerToolTipOpen: false,
            product: {
                barcode: '',
                barcodeChecksum: '',
                barcodeType: 'EAN-13',
                name: '',
                description: '',
                price: 1.0,
                currency: currencies[0].symbol,
                locationPricings: {},
                productType: '',
                imageUrl: '',
                timeToLiveDays: '',
                vatCategory: '',
                productCategory: '',
                supplier: '',
                isPrebooked: false,
                isFrozen: false,
            },
            validationErrors: {
                barcode: '',
                name: '',
                price: '',
                productType: '',
                timeToLiveDays: '',
            },
            isFreshFood: false,
            cropping: false,
            translations: {
                requesting: 'creatingProduct',
                errorRequesting: 'errorCreatingProduct',
                requestSuccess: 'productCreated',
                title: 'createProductTitle',
                submit: 'create',
                cancel: 'cancel',
                dismiss: 'dismiss',
            },
            productTypesModalOpen: this.props.editing ? false : true,

            productTypesModalRequired: this.props.editing ? false : true,
            addLocationPricingLocationId: '',
            packageForFreezer: false,
        };
        this.handleFreshFood = this.handleFreshFood.bind(this);
        this.handleIsFrozenProduct = this.handleIsFrozenProduct.bind(this);
    }

    componentDidMount() {
        // Fill form with product data if editing existing product
        if (this.props.editing) {
            this.props.uploadProductImageSuccess(this.props.product.imageUrl);
            this.setState(prevState => ({
                ...prevState,
                isFreshFood: this.props.product.timeToLiveDays || this.props.product.timeToLiveDays === 0 ? true : false,
                packageForFreezer: this.state.packageForFreezer ? true : false,
                product: {
                    ...prevState.product,
                    ...this.props.product,
                    imageUrl: '',
                    timeToLiveDays: this.props.product.timeToLiveDays || this.props.product.timeToLiveDays === 0 ? this.props.product.timeToLiveDays : '',
                    productType: this.props.product.productType ? this.props.product.productType : '',
                    locationPricings: (this.props.product.prices || [])
                        .filter(price => price.locationId != null)
                        .filter(price => this.props.locations.find(location => location.id === price.locationId)) // remove location prices for locations that do not exist
                        .reduce(
                            (a, b) => (
                                (a[b.locationId] = {
                                    price: b.price,
                                    currency: b.currency,
                                    // eslint-disable-next-line
                                }),
                                a
                            ),
                            {}
                        ),
                    vatCategory:
                        this.props.product && this.props.product.vatCategory
                            ? this.props.product.vatCategory
                            : this.props.vatCategories && this.props.vatCategories.vatCategories
                            ? this.props.vatCategories.vatCategories.filter(vatCategory => vatCategory.name.toLowerCase().includes('default'))[0] ||
                              this.props.vatCategories.vatCategories[0]
                            : '',
                    productCategory: this.props.product && this.props.product.productCategory ? this.props.product.productCategory : '',
                    supplier: this.props.product && this.props.product.supplier ? this.props.product.supplier : '',
                    isPrebooked: this.props.product.isPrebooked,
                },
                translations: {
                    requesting: 'updatingProduct',
                    errorRequesting: 'errorUpdatingProduct',
                    requestSuccess: 'productUpdated',
                    title: 'updateProductTitle',
                    submit: 'update',
                    cancel: 'cancel',
                    dismiss: 'dismiss',
                },
            }));
        } else {
            this.setState(prevState => ({
                ...prevState,
                product: {
                    ...prevState.product,
                    vatCategory:
                        this.props.vatCategories && this.props.vatCategories.vatCategories
                            ? this.props.vatCategories.vatCategories.filter(vatCategory => vatCategory.name.toLowerCase().includes('default'))[0] ||
                              this.props.vatCategories.vatCategories[0]
                            : '',
                },
            }));
        }
    }

    notifyMessage(title, text, isError) {
        showNotification(title, text, isError ? 'error' : 'success');
        this.props.dismissResult();
    }

    /**
     * Input onChange handler to update component state on change
     * @param {string} target State to update (name, price...)
     * @param {Event} event Html input change event
     */
    handleProductFormChange = (target, event) => {
        // value of input field
        const value = event.target.value;

        // don't allow changing price to negative
        if (target === 'price' && value < 0) {
            return;
        }

        if (target === 'bannerText' && value.length > 25) return;

        // don't allow changing timeToLiveDays to negative
        if (target === 'timeToLiveDays' && value < 0) {
            return;
        }

        // make name input valid if changed and error
        if (target === 'name' && this.state.validationErrors.name) {
            this.setState(prevState => ({
                ...prevState,
                validationErrors: {
                    ...prevState.validationErrors,
                    name: '',
                },
            }));
            return;
        }

        // make barcode input valid if changed and error
        if (target === 'barcode' && this.state.validationErrors.barcode) {
            this.setState(prevState => ({
                ...prevState,
                validationErrors: {
                    ...prevState.validationErrors,
                    barcode: '',
                },
            }));
            return;
        }

        if (target === 'barcode') {
            // calculate check sum for barcode and store.getState() it
            const { value } = event.target;
            const checkSum = checkdigit(value);
            this.setState(prevState => ({
                ...prevState,
                product: {
                    ...prevState.product,
                    barcodeChecksum: checkSum.toString(),
                },
                barcode: value,
            }));
        }

        if (target === 'timeToLiveDays' && this.state.validationErrors.timeToLiveDays) {
            this.setState(prevState => ({
                ...prevState,
                validationErrors: {
                    ...prevState.validationErrors,
                    timeToLiveDays: '',
                },
            }));
            return;
        }

        if (target === 'vatCategory') {
            this.setState(prevState => ({
                ...prevState,
                product: {
                    ...prevState.product,
                    vatCategory: this.props.vatCategories.vatCategories.filter(vatCategory => vatCategory.id === value)[0],
                },
            }));
            return;
        }

        if (target === 'supplier') {
            this.setState(prevState => ({
                ...prevState,
                product: {
                    ...prevState.product,
                    supplier: this.props.suppliers.suppliers.filter(supplier => supplier.id === value)[0],
                },
            }));
            return;
        }

        if (target === 'productType' && this.state.validationErrors.productType) {
            this.setState(prevState => ({
                ...prevState,
                validationErrors: {
                    ...prevState.validationErrors,
                    productType: '',
                },
            }));
            return;
        }

        if (target === 'productCategory') {
            this.setState(prevState => ({
                ...prevState,
                product: {
                    ...prevState.product,
                    productCategory: this.props.productCategories.productCategories.filter(productCategory => productCategory.id === value)[0],
                },
            }));
        } else {
            // update product to create state
            this.setState(prevState => ({
                ...prevState,
                product: {
                    ...prevState.product,
                    [target]: value,
                },
            }));
        }
    };

    /**
     * Product form submit handler. Sends update/create product request to backend if valid
     */
    submitProduct() {
        // validate name input
        if (!this.state.product.name) {
            return this.setState(prevState => ({
                ...prevState,
                validationErrors: {
                    ...prevState.validationErrors,
                    name: 'invalidProductName',
                },
            }));
        }

        if (this.state.isFreshFood) {
            // check if ttl value is integer(s), non-decimal and non-numeric
            const ttlValid =
                this.state.product && typeof this.state.product.timeToLiveDays === 'string'
                    ? validator.isInt(this.state.product.timeToLiveDays.toString(), {
                          min: 0,
                          max: 32767,
                      })
                    : true;

            if (!ttlValid) {
                return this.setState(prevState => ({
                    ...prevState,
                    validationErrors: {
                        ...prevState.validationErrors,
                        timeToLiveDays: 'invalidLifetime',
                    },
                }));
            }
        }

        let requiredBarcodeLength = null;
        switch (this.state.product.barcodeType) {
            case 'EAN-13':
                requiredBarcodeLength = 13;
                break;
            case 'EAN-8':
                requiredBarcodeLength = 8;
                break;
            case 'UPC-A':
                requiredBarcodeLength = 12;
                break;
            default:
                break;
        }

        // validate barcode input
        if (!this.state.product.barcode) {
            return this.setState(prevState => ({
                ...prevState,
                validationErrors: {
                    ...prevState.validationErrors,
                    barcode: 'invalidProductBarcode',
                },
            }));
        } else if (this.state.product.barcode.match(/^[0-9]+$/) === null) {
            return this.setState(prevState => ({
                ...prevState,
                validationErrors: {
                    ...prevState.validationErrors,
                    barcode: 'invalidBarcodeOnlyDigits',
                },
            }));
        } else if (this.state.product.barcode.length + this.state.product.barcodeChecksum.length > requiredBarcodeLength && !this.props.editing) {
            return this.setState(prevState => ({
                ...prevState,
                validationErrors: {
                    ...prevState.validationErrors,
                    barcode: 'invalidBarcodeTooLong',
                },
            }));
        } else if (this.state.product.barcode.length + this.state.product.barcodeChecksum.length < requiredBarcodeLength && !this.props.editing) {
            return this.setState(prevState => ({
                ...prevState,
                validationErrors: {
                    ...prevState.validationErrors,
                    barcode: 'invalidBarcodeTooShort',
                },
            }));
        } else if (this.state.product.price <= 0) {
            return this.setState(prevState => ({
                ...prevState,
                validationErrors: {
                    ...prevState.validationErrors,
                    price: 'invalidPrice',
                },
            }));
        } else if (this.state.product.productType === '') {
            return this.setState(prevState => ({
                ...prevState,
                validationErrors: {
                    ...prevState.validationErrors,
                    productType: 'noProductTypeSelected',
                },
            }));
        }

        // send product create/update request
        if (this.props.reduxProduct.imageUrl) {
            this.props.productRequest(
                Object.assign(
                    {},
                    this.state.product,
                    { productCategoryId: this.state.product.productCategory ? this.state.product.productCategory.id : null },
                    { supplierId: this.state.product.supplier ? this.state.product.supplier.id : null },
                    { barcodeType: this.state.product.barcodeType },
                    {
                        barcode: this.state.product.barcodeChecksum
                            ? `${this.state.product.barcode}${this.state.product.barcodeChecksum}`
                            : this.state.product.barcode,
                        barcodeChecksum: '',
                    },
                    {
                        imageUrl: this.props.reduxProduct.imageUrl,
                        locationPricings: Object.keys(this.state.product.locationPricings).map(locationId => ({
                            locationId,
                            price: this.state.product.locationPricings[locationId].price,
                            currency: this.state.product.locationPricings[locationId].currency,
                        })),
                    }
                )
            );
        } else {
            this.props.productRequest(
                Object.assign(
                    {},
                    this.state.product,
                    { productCategoryId: this.state.product.productCategory ? this.state.product.productCategory.id : null },
                    { supplierId: this.state.product.supplier ? this.state.product.supplier.id : null },
                    { barcodeType: this.state.product.barcodeType },
                    {
                        barcode: this.state.product.barcodeChecksum
                            ? `${this.state.product.barcode}${this.state.product.barcodeChecksum}`
                            : this.state.product.barcode,
                        barcodeChecksum: '',
                    },
                    {
                        locationPricings: Object.keys(this.state.product.locationPricings).map(locationId => ({
                            locationId,
                            price: this.state.product.locationPricings[locationId].price,
                            currency: this.state.product.locationPricings[locationId].currency,
                        })),
                    }
                )
            );
        }

        // clear component product state
        this.setState({
            product: {
                barcode: '',
                barcodeChecksum: '',
                name: '',
                description: '',
                price: 1.0,
                currency: currencies[0].symbol,
                imageUrl: '',
                locationPricings: {},
                productType: '',
                timeToLiveDays: '',
                isPrebooked: false,
                isFrozen: false,
                bannerText: '',
            },
        });

        // clear image redux state
        this.props.removeProductImage();
    }

    /**
     * Cancel create/update product form
     */
    cancelForm = () => {
        this.props.dismissResult();
        this.setState({
            product: {
                barcode: '',
                barcodeChecksum: '',
                name: '',
                description: '',
                price: 0.0,
                currency: currencies[0].symbol,
                locationPricings: {},
                imageUrl: '',
                timeToLiveDays: '',
                isFreshFood: false,
                isPrebooked: false,
                isFrozen: false,
            },
            isFreshFood: false,
            validationErrors: {
                barcode: '',
                name: '',
                productType: '',
                timeToLiveDays: '',
            },
        });
        this.props.removeProductImage();
    };

    /**
     * Dismiss product create or update result
     */
    dismissResult = () => {
        this.props.dismissResult();
    };

    /**
     * Toggle currently cropping to disable submitting while cropping
     */
    toggleCropping = () => {
        this.setState({
            cropping: !this.state.cropping,
        });
    };

    filterFreezerPackages = () => {
        this.setState({
            packageForFreezer: !this.state.packageForFreezer,
        });
    };

    handleAddLocationPricingFormChange = event => {
        const value = event.target.value;
        this.setState(prevState => ({
            ...prevState,
            addLocationPricingLocationId: value,
        }));
    };

    submitAddLocationPricing = () => {
        if (this.state.addLocationPricingLocationId === '') return;
        this.setState(prevState => ({
            ...prevState,
            product: {
                ...prevState.product,
                locationPricings: {
                    ...prevState.product.locationPricings,
                    [prevState.addLocationPricingLocationId]: {
                        price: prevState.product.price,
                        currency: prevState.product.currency,
                    },
                },
            },
            addLocationPricingLocationId: '',
        }));
    };

    handleLocationPricingFormChange = (locationId, target, event) => {
        // value of input field
        let value = event.target.value;

        // convert value to float if target is price
        if (target === 'price') {
            value = parseFloat(value);
            if (value < 0) return; // price cant be negative
        }

        // update product to create state
        this.setState(prevState => ({
            ...prevState,
            product: {
                ...prevState.product,
                locationPricings: {
                    ...prevState.product.locationPricings,
                    [locationId]: {
                        ...prevState.product.locationPricings[locationId],
                        [target]: value,
                    },
                },
            },
        }));
    };

    deleteLocationPricing = locationId => {
        this.setState(prevState => {
            const newLocationPrices = prevState.product.locationPricings;
            delete newLocationPrices[locationId];
            return {
                ...prevState,
                product: {
                    ...prevState.product,
                    locationPricings: newLocationPrices,
                },
            };
        });
    };

    clickProductTypeListItem = productType => {
        this.setState({ productTypesModalOpen: false, productTypesModalRequired: false });
        if (this.state.packageForFreezer === true) {
            this.setState(prevState => ({
                ...prevState,
                product: {
                    ...prevState.product,
                    isFrozen: !this.state.product.isFrozen,
                },
            }));
        }
        this.handleProductFormChange('productType', { target: { value: productType } });
    };

    handleFreshFood(checked) {
        if (checked) {
            this.setState(prevState => ({
                ...prevState,
                isFreshFood: checked,
                product: {
                    ...prevState.product,
                    timeToLiveDays: 0,
                },
            }));
        } else {
            this.setState(prevState => ({
                ...prevState,
                isFreshFood: checked,
                product: {
                    ...prevState.product,
                    timeToLiveDays: null,
                },
            }));
        }
    }

    handleIsFrozenProduct(checked) {
        this.setState(prevState => ({
            ...prevState,
            isFrozen: checked,
            product: {
                ...prevState.product,
                productType: '',
                isFrozen: checked,
            },
        }));
    }

    renderTTLUserInput() {
        const { t } = this.props;

        if (this.state.isFreshFood) {
            const ttl = this.state.product.timeToLiveDays;

            return (
                <FormGroup className="animated fadeIn">
                    <Label for="productFormTTL">
                        {this.props.t('lifetime')} ({t('days')})
                        <Tooltip theme="light" title={t('lifeTimeExplanation')} position="bottom" trigger="click">
                            <i className="material-icons se-icon ml-1" style={{ cursor: 'pointer', color: '#4DA8E1' }}>
                                help_outline
                            </i>
                        </Tooltip>
                    </Label>
                    <div className="d-flex align-items-center">
                        <Input
                            size="md"
                            type="number"
                            name="timeToLiveDays"
                            invalid={this.state.validationErrors.timeToLiveDays !== ''}
                            onChange={this.handleProductFormChange.bind(this, 'timeToLiveDays')}
                            value={ttl}
                        />
                        <FormFeedback valid={false}>{t(this.state.validationErrors.timeToLiveDays)}</FormFeedback>
                    </div>
                </FormGroup>
            );
        } else {
            return null;
        }
    }

    render() {
        const { reduxProduct, reduxProductTypes, editing, t, lng } = this.props;
        // productType selector
        let productTypesModal = <div />;
        if (reduxProductTypes.isFetching) {
        } else if (reduxProductTypes.isError) {
        } else if (reduxProductTypes.productTypes.length === 0) {
        } else {
            productTypesModal = (
                <div className={this.state.productTypesModalRequired ? 'se-modal-close-hidden' : 'se-modal-close-pointer'}>
                    <Modal
                        title={t('selectProductType') + ':'}
                        active={this.state.productTypesModalOpen}
                        onClose={this.state.productTypesModalRequired ? () => {} : () => this.setState({ productTypesModalOpen: false })}
                    >
                        <div style={{ maxHeight: '75vh', overflowY: 'scroll' }}>
                            <FormGroup>
                                <Label for="productFormTTL">{t('freezerPackage')} </Label>
                                <br></br>
                                <Switch
                                    className="se-seeds-toggle"
                                    onColor="#67b419"
                                    checked={this.state.packageForFreezer}
                                    onChange={this.filterFreezerPackages}
                                ></Switch>
                            </FormGroup>
                            {this.state.packageForFreezer ? (
                                <Row>
                                    {reduxProductTypes.productTypes
                                        .filter(freezerPack => freezerPack.packageForFreezer === true)
                                        .map((productType, i) => {
                                            return (
                                                <Col key={`${productType.title}-${i}`}>
                                                    <Tile
                                                        color="light"
                                                        style={{ cursor: 'pointer' }}
                                                        key={productType.productType}
                                                        onClick={this.clickProductTypeListItem.bind(this, productType.productType)}
                                                        className="h-100 p-2 product-type-tile"
                                                    >
                                                        <h5>
                                                            {split(productType.productType, '-')[0]}
                                                            {' - '}
                                                            {productType.title[lng] || productType.title['en'] || productType.title}
                                                        </h5>
                                                        <p>
                                                            <b>Tag type: {productType.tagTypes.join(', ')}</b>
                                                        </p>

                                                        {productType.imageUrls &&
                                                            Array.isArray(productType.imageUrls) &&
                                                            productType.imageUrls.map(imageUrl => (
                                                                <img key={imageUrl} src={imageUrl} alt="Unable to fetch" style={{ maxWidth: '240px' }} />
                                                            ))}
                                                    </Tile>
                                                </Col>
                                            );
                                        })}
                                </Row>
                            ) : (
                                <Row>
                                    {reduxProductTypes.productTypes
                                        .filter(freezerPack => freezerPack.packageForFreezer !== true)
                                        .map((productType, i) => {
                                            return (
                                                <Col key={`${productType.title}-${i}`} xs="12" md="6" lg="4" className="mb-2">
                                                    <Tile
                                                        color="light"
                                                        style={{ cursor: 'pointer' }}
                                                        key={productType.productType}
                                                        onClick={this.clickProductTypeListItem.bind(this, productType.productType)}
                                                        className="h-100 p-2 product-type-tile"
                                                    >
                                                        <h5>
                                                            {split(productType.productType, '-')[0]}
                                                            {' - '}
                                                            {productType.title[lng] || productType.title['en'] || productType.title}
                                                        </h5>
                                                        <p>
                                                            <b>Tag type: {productType.tagTypes.join(', ')}</b>
                                                        </p>

                                                        {productType.imageUrls &&
                                                            Array.isArray(productType.imageUrls) &&
                                                            productType.imageUrls.map(imageUrl => (
                                                                <img key={imageUrl} src={imageUrl} alt="Unable to fetch" style={{ maxWidth: '240px' }} />
                                                            ))}
                                                    </Tile>
                                                </Col>
                                            );
                                        })}
                                </Row>
                            )}
                        </div>
                    </Modal>
                </div>
            );
        }

        // creating product in backend
        if (reduxProduct.requesting) {
            return (
                <Card title={t(this.state.translations.requesting)} className="animated slideInDown">
                    <div className="d-flex justify-content-center w-100">
                        <Spinner />
                    </div>
                </Card>
            );
        }

        // error in create product request
        if (reduxProduct.finished && reduxProduct.error) {
            this.notifyMessage(t(this.state.translations.errorRequesting), reduxProduct.error, true);
            return null;
        }

        // product created in backend
        if (reduxProduct.finished) {
            this.notifyMessage(null, t(this.state.translations.requestSuccess), false);
            return null;
        }

        return (
            <div style={this.state.productTypesModalOpen ? { minHeight: '50vh' } : {}} className="animated fadeInLeft">
                <Card title={t(this.state.translations.title)} type="attention">
                    <Form>
                        <Row>
                            <Col style={{ maxWidth: '17rem' }}>
                                <FormGroup>
                                    <Label for="productFormName">{t('name')}</Label>
                                    <Input
                                        type="text"
                                        name="name"
                                        id="productFormName"
                                        placeholder={t('name')}
                                        value={this.state.product.name}
                                        onChange={this.handleProductFormChange.bind(this, 'name')}
                                        invalid={this.state.validationErrors.name !== ''}
                                    />
                                    <FormFeedback valid={false}>{t(this.state.validationErrors.name)}</FormFeedback>
                                </FormGroup>
                            </Col>
                            {editing ? (
                                <>
                                    <Col style={{ maxWidth: '13rem' }}>
                                        <FormGroup>
                                            <Label for="barcodeType">{t('barcodeType')} </Label>
                                            <Input disabled type="select" name="barcodeType" id="productFormBarcodeType" value={this.state.product.barcodeType}>
                                                <option value={'EAN-13'}>EAN-13</option>
                                                <option value={'EAN-8'}>EAN-8</option>
                                                <option value={'UPC-A'}>UPC-A</option>
                                            </Input>
                                            <FormText color="muted">{t('barcodeTypeCannotBeChanged')}</FormText>
                                        </FormGroup>
                                    </Col>
                                    <Col style={{ maxWidth: '17rem' }}>
                                        <FormGroup>
                                            <Label for="productFormBarcode">{t('barcode')}</Label>
                                            <Input
                                                disabled
                                                type="text"
                                                name="barcode"
                                                id="productFormBarcode"
                                                placeholder={t('barcode')}
                                                value={this.state.product.barcode}
                                            />
                                            <FormText color="muted">{t('barcodeCannotBeChanged')}</FormText>
                                            <div className="smart-cabinet-error-text">{t(this.state.validationErrors.barcode)}</div>
                                        </FormGroup>
                                    </Col>
                                </>
                            ) : (
                                <>
                                    <Col style={{ maxWidth: '13rem' }}>
                                        <FormGroup>
                                            <Label for="barcodeType">{t('barcodeType')} </Label>
                                            <Input
                                                type="select"
                                                name="barcodeType"
                                                id="productFormBarcodeType"
                                                value={this.state.product.barcodeType}
                                                onChange={this.handleProductFormChange.bind(this, 'barcodeType')}
                                            >
                                                <option value={'EAN-13'}>EAN-13</option>
                                                <option value={'EAN-8'}>EAN-8</option>
                                                <option value={'UPC-A'}>UPC-A</option>
                                            </Input>
                                        </FormGroup>
                                    </Col>
                                    <Col style={{ maxWidth: '17rem' }}>
                                        <FormGroup>
                                            <Label for="productFormBarcode">{t('barcode')}</Label>
                                            <div>
                                                <Input
                                                    maxLength={
                                                        (this.state.product.barcodeType === 'EAN-13' && 12) ||
                                                        (this.state.product.barcodeType === 'UPC-A' && 11) ||
                                                        (this.state.product.barcodeType === 'EAN-8' && 7)
                                                    }
                                                    type="text"
                                                    name="barcode"
                                                    id="productFormBarcode"
                                                    placeholder={t('barcode')}
                                                    value={this.state.product.barcode}
                                                    onChange={this.handleProductFormChange.bind(this, 'barcode')}
                                                    invalid={this.state.validationErrors.barcode !== ''}
                                                />
                                                <FormFeedback valid={false}>{t(this.state.validationErrors.barcode)}</FormFeedback>
                                                {this.state.barcode && (
                                                    <div className="mt-2 d-flex justify-content-start align-items-center">
                                                        <i className="fas fa-barcode" />
                                                        &nbsp;
                                                        <span className="text-monospace">
                                                            {this.state.barcode}
                                                            <span className="text-info">{this.state.product.barcodeChecksum}</span>
                                                        </span>
                                                    </div>
                                                )}
                                                <div>
                                                    <small className="text-muted">
                                                        {this.state.product.barcodeType === 'EAN-13' && t('barcodeHelpText12')}
                                                        {this.state.product.barcodeType === 'UPC-A' && t('barcodeHelpText11')}
                                                        {this.state.product.barcodeType === 'EAN-8' && t('barcodeHelpText7')}
                                                    </small>
                                                </div>
                                            </div>
                                        </FormGroup>
                                    </Col>
                                </>
                            )}
                            <Col lg="1">{this.renderTTLUserInput()}</Col>
                            <Col lg="auto">
                                <FormGroup>
                                    <Label for="productFormTTL">{t('freshFood')} </Label>
                                    <Switch
                                        onChange={this.handleFreshFood}
                                        checked={this.state.isFreshFood}
                                        className="se-seeds-toggle d-block"
                                        onColor="#67b419"
                                    ></Switch>
                                </FormGroup>
                            </Col>
                            <Col lg="auto">
                                <FormGroup>
                                    <Label>{t('prebooking')}</Label>
                                    <br />
                                    <Switch
                                        className="se-seeds-toggle"
                                        onColor="#67b419"
                                        checked={this.state.product.isPrebooked}
                                        onChange={checked => {
                                            this.setState(prevState => ({
                                                ...prevState,
                                                product: {
                                                    ...prevState.product,
                                                    isPrebooked: checked,
                                                },
                                            }));
                                        }}
                                    ></Switch>
                                </FormGroup>
                            </Col>
                            <Col lg="auto">
                                <FormGroup>
                                    <Label>{t('freezerPackage')}</Label>
                                    <br />
                                    <Switch
                                        className="se-seeds-toggle"
                                        onColor="#67b419"
                                        checked={this.state.product.isFrozen}
                                        onChange={this.handleIsFrozenProduct}
                                    ></Switch>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg="4">
                                <FormGroup>
                                    <Label for="productCategory">{t('productCategory')} </Label>
                                    <Input
                                        disabled={
                                            this.props.productCategories &&
                                            this.props.productCategories.productCategories &&
                                            this.props.productCategories.productCategories.length === 0
                                        }
                                        type="select"
                                        name="productCategory"
                                        value={this.state.product.productCategory ? this.state.product.productCategory.id : ''}
                                        onChange={this.handleProductFormChange.bind(this, 'productCategory')}
                                    >
                                        <option></option>
                                        {this.props.productCategories &&
                                            this.props.productCategories.productCategories &&
                                            this.props.productCategories.productCategories.map(productCategory => (
                                                <option key={productCategory.id} value={productCategory.id}>
                                                    {`${productCategory.name} - ${productCategory.description}`}
                                                </option>
                                            ))}
                                    </Input>
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup>
                                    <Label for="supplier">{t('supplier')} </Label>
                                    <Input
                                        disabled={this.props.supplier && this.props.suppliers.suppliers && this.props.suppliers.suppliers.length === 0}
                                        type="select"
                                        name="supplier"
                                        value={this.state.product.supplier ? this.state.product.supplier.id : ''}
                                        onChange={this.handleProductFormChange.bind(this, 'supplier')}
                                    >
                                        <option></option>
                                        {this.props.suppliers &&
                                            this.props.suppliers.suppliers &&
                                            this.props.suppliers.suppliers.map(supplier =>
                                                supplier.status === 1 ? (
                                                    <option key={supplier.id} value={supplier.id}>
                                                        {`${supplier.name} - ${supplier.description}`}
                                                    </option>
                                                ) : null
                                            )}
                                    </Input>
                                </FormGroup>
                            </Col>
                            <Col lg="4">
                                <FormGroup>
                                    <Label for="productType">{t('productType')} </Label>
                                    <Input
                                        type="select"
                                        name="type"
                                        id="productFormType"
                                        placeholder={t('productType')}
                                        value={this.state.product.productType}
                                        onChange={this.handleProductFormChange.bind(this, 'productType')}
                                        invalid={this.state.validationErrors.productType !== ''}
                                    >
                                        <option></option>
                                        {this.state.product.isFrozen
                                            ? reduxProductTypes.productTypes &&
                                              reduxProductTypes.productTypes
                                                  .filter(productType => productType.packageForFreezer === true)
                                                  .map(productType => (
                                                      <option key={productType.productType} value={productType.productType}>
                                                          {`${productType.productType}`}
                                                      </option>
                                                  ))
                                            : reduxProductTypes.productTypes &&
                                              reduxProductTypes.productTypes
                                                  .filter(productType => productType.packageForFreezer !== true)
                                                  .map(productType => (
                                                      <option key={productType.productType} value={productType.productType}>
                                                          {`${productType.productType}`}
                                                      </option>
                                                  ))}
                                    </Input>
                                </FormGroup>
                            </Col>
                            <Col xs="12" lg="4">
                                {productTypesModal}
                            </Col>
                            {/* 
                            <Col xs="12">
                                <FormGroup>
                                    <Label for="productFormDescription">{t('description')}</Label>
                                    <Input
                                        type="textarea"
                                        name="description"
                                        id="productFormDescription"
                                        placeholder={t('description')}
                                        value={this.state.product.description}
                                        onChange={this.handleProductFormChange.bind(
                                            this,
                                            'description'
                                        )}
                                    />
                                </FormGroup>
                            </Col>
                            */}
                            <Col xs="12" className="mb-3">
                                <h6>{t('price')}</h6>
                                <Tile color="light" className="m-2">
                                    <h6>{t('default')}</h6>
                                    <Row>
                                        <Col xs="6">
                                            <FormGroup>
                                                <Label>{t('price')}</Label>
                                                <Input
                                                    type="number"
                                                    name="price"
                                                    placeholder={t('price')}
                                                    value={this.state.product.price}
                                                    onChange={this.handleProductFormChange.bind(this, 'price')}
                                                    invalid={this.state.validationErrors.price !== ''}
                                                />
                                                <FormFeedback valid={false}>{t(this.state.validationErrors.price)}</FormFeedback>
                                            </FormGroup>
                                        </Col>
                                        <Col xs="6">
                                            {currencies.length > 1 ? (
                                                <FormGroup>
                                                    <Label>{t('currency')}</Label>
                                                    <Input
                                                        type="select"
                                                        name="type"
                                                        value={this.state.product.currency}
                                                        onChange={this.handleProductFormChange.bind(this, 'currency')}
                                                    >
                                                        {currencies.map(currency => (
                                                            <option key={currency.code} value={currency.symbol}>
                                                                {currency.name} ({currency.symbol})
                                                            </option>
                                                        ))}
                                                    </Input>
                                                </FormGroup>
                                            ) : null}
                                        </Col>
                                    </Row>
                                </Tile>
                                {Object.keys(this.state.product.locationPricings).map(locationId => (
                                    <Tile key={locationId} color="light" className="m-2">
                                        <Row>
                                            <Col xs="12">
                                                <Button
                                                    className="float-right"
                                                    onClick={this.deleteLocationPricing.bind(this, locationId)}
                                                    type="attention"
                                                    icon="clear"
                                                />
                                                <h6>{this.props.locations.find(location => location.id === locationId).name}</h6>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs="6">
                                                <FormGroup>
                                                    <Label>{t('price')}</Label>
                                                    <Input
                                                        type="number"
                                                        name="price"
                                                        placeholder={t('price')}
                                                        value={this.state.product.locationPricings[locationId].price}
                                                        onChange={this.handleLocationPricingFormChange.bind(this, locationId, 'price')}
                                                        invalid={this.state.product.locationPricings[locationId].price <= 0}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col xs="6">
                                                {currencies.length > 1 ? (
                                                    <FormGroup>
                                                        <Label>{t('currency')}</Label>
                                                        <Input
                                                            type="select"
                                                            name="type"
                                                            value={this.state.product.locationPricings[locationId].currency}
                                                            onChange={this.handleLocationPricingFormChange.bind(this, locationId, 'currency')}
                                                        >
                                                            {currencies.map(currency => (
                                                                <option key={currency.code} value={currency.symbol}>
                                                                    {currency.name} ({currency.symbol})
                                                                </option>
                                                            ))}
                                                        </Input>
                                                    </FormGroup>
                                                ) : null}
                                            </Col>
                                        </Row>
                                    </Tile>
                                ))}
                                <Label>{t('addNewLocation')} </Label>
                                <Row>
                                    <Col xs="12" lg="6">
                                        <Input
                                            type="select"
                                            name="type"
                                            value={this.state.addLocationPricingLocationId}
                                            onChange={this.handleAddLocationPricingFormChange.bind(this)}
                                        >
                                            <option key="" value="" />
                                            {this.props.locations
                                                .filter(location => !Object.keys(this.state.product.locationPricings).includes(location.id))
                                                .map(location => (
                                                    <option key={location.id} value={location.id}>
                                                        {location.name}
                                                    </option>
                                                ))}
                                        </Input>
                                    </Col>
                                    <Col xs="12" lg="6">
                                        <Button onClick={this.submitAddLocationPricing} disabled={this.state.addLocationPricingLocationId === ''}>
                                            {t('addRegionPrice')}
                                        </Button>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col xs="12" md="6" lg="6">
                                        <FormGroup>
                                            <Label for="vatCategory">{t('vatCategory')} </Label>
                                            <Input
                                                disabled={
                                                    this.props.vatCategories &&
                                                    this.props.vatCategories.vatCategories &&
                                                    this.props.vatCategories.vatCategories.length === 0
                                                }
                                                type="select"
                                                name="vatCategory"
                                                value={this.state.product.vatCategory ? this.state.product.vatCategory.id : ''}
                                                onChange={this.handleProductFormChange.bind(this, 'vatCategory')}
                                            >
                                                {this.props.vatCategories &&
                                                    this.props.vatCategories.vatCategories &&
                                                    this.props.vatCategories.vatCategories.map(vatCategory => (
                                                        <option key={vatCategory.id} value={vatCategory.id}>
                                                            {`${vatCategory.name} - ${vatCategory.taxValue}%`}
                                                        </option>
                                                    ))}
                                            </Input>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs="12" md="6" lg="6">
                                        <FormGroup>
                                            <Label for="bannerText">
                                                {t('productOnScreenBannerText')}
                                                <span id="bannerTextInfoIcon" className=" ml-2">
                                                    {' '}
                                                    <Tooltip
                                                        theme="light"
                                                        html={
                                                            <div className="previewContent--singleProduct--container d-block">
                                                                <Row className="justify-content-center">
                                                                    <div className="previewContent--singleProduct productPageCabinetBlockPreviewContainer">
                                                                        <svg className="productPageCabinetBlockPreview">
                                                                            <use xlinkHref={`${icons}#icon-cube`} />
                                                                        </svg>
                                                                        <br />
                                                                        <p style={{ background: '#d9ecc4', width: '100%' }}>
                                                                            {t('productOnScreenBannerExampleText')}
                                                                        </p>
                                                                        <div className="previewContent--singleProduct--footer ">
                                                                            <div style={{ color: 'black' }}>
                                                                                <span class="currency">€</span>
                                                                                <span className="value">4</span>
                                                                                <span className="currencyDecimal">00</span>
                                                                            </div>
                                                                            <div style={{ color: 'black' }}>{t('cabinetSettings:productName')}</div>
                                                                        </div>
                                                                    </div>
                                                                </Row>

                                                                <p className="my-2">{t('productOnScreenBannerAdditionalInfo')}</p>
                                                            </div>
                                                        }
                                                        position="right"
                                                        trigger="mouseenter"
                                                    >
                                                        <i className="fas fa-info-circle" style={{ color: '#007bc7', verticalAlign: 'middle' }}></i>
                                                    </Tooltip>
                                                </span>{' '}
                                            </Label>
                                            <div className="input-group mb-3">
                                                <Input
                                                    type="text"
                                                    name="bannerText"
                                                    value={this.state.product.bannerText}
                                                    onChange={this.handleProductFormChange.bind(this, 'bannerText')}
                                                />
                                                <span className="input-group-text" id="basic-addon2">
                                                    {this.state.product.bannerText?.length || 0} / 25
                                                </span>
                                            </div>
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </Col>
                            <Col xs="12">
                                <ImageUpload
                                    targetWidth={parseInt(process.env.REACT_APP_PRODUCT_IMAGE_WIDTH)}
                                    targetHeight={parseInt(process.env.REACT_APP_PRODUCT_IMAGE_HEIGHT)}
                                    imageUrl={reduxProduct.imageUrl}
                                    storing={reduxProduct.imageStoring}
                                    uploadImage={this.props.uploadProductImage}
                                    removeImage={this.props.removeProductImage}
                                    toggleCropping={this.toggleCropping}
                                    paddedAspectRatio={true}
                                    t={t}
                                />
                            </Col>
                            {this.state.cropping ? (
                                <Col xs="12 text-right">
                                    <Button className="mr-2" disabled>
                                        {t(this.state.translations.submit)}
                                    </Button>
                                    <Button type="negative" disabled>
                                        {t(this.state.translations.cancel)}
                                    </Button>
                                </Col>
                            ) : (
                                <Col xs="12 text-right">
                                    <Button onClick={this.submitProduct.bind(this)} className="mr-2">
                                        {t(this.state.translations.submit)}
                                    </Button>
                                    <Button onClick={this.cancelForm} type="negative">
                                        {t(this.state.translations.cancel)}
                                    </Button>
                                </Col>
                            )}
                        </Row>
                    </Form>
                </Card>
            </div>
        );
    }
}

/**
 * Model to confirm the product status change
 */
class ProductStatusChangeModel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            productStatusProgress: false,
        };
    }

    setProductStatus = (active = false) => {
        this.setState({
            productStatusProgress: true,
        });

        const product = {
            barcode: this.props.product.barcode,
            barcodeType: this.props.product.barcodeType,
            status: active ? 2 : 3,
        };
        this.props.archiveProduct(product);
    };

    render() {
        const { openModel, closeModel, product, removingProduct, t } = this.props;

        return (
            <Modal active={openModel} onClose={closeModel}>
                <div className="container" style={{ width: '30rem' }}>
                    {removingProduct && this.state.productStatusProgress && (
                        <div className="row justify-content-center align-items-center" style={{ minHeight: '10rem' }}>
                            <Spinner size="lg" />
                        </div>
                    )}
                    {this.state.productStatusProgress && !removingProduct && (
                        <div className="align-items-center" style={{ minHeight: '10rem', textAlign: 'center' }}>
                            <div className="cu-change-success"> </div>
                            <div>{product.status === 2 ? t('archiveSuccess') : t('activateSuccess')}</div>
                            <div style={{ float: 'right', marginTop: '10px' }}>
                                <Button size="sm" type="positive" icon="cancel" onClick={closeModel}>
                                    {t('close')}
                                </Button>
                            </div>
                        </div>
                    )}
                    {!this.state.productStatusProgress && !removingProduct && (
                        <div className="row se-p-lg">
                            <h6 style={{ textAlign: 'center', fontWeight: '600', width: '100%' }}>
                                {product.status === 2 ? t('confirmDisable') : t('confirmEnable')}
                            </h6>
                            <div className="col-12" style={{ textAlign: 'center' }}>
                                <div>
                                    <img src={product.imageUrl} alt="Unable to fetch" style={{ width: '150px', textAlign: 'center' }} />
                                    <p>{product.name}</p>
                                    <p>
                                        {t('barcode')} : {product.barcode}
                                    </p>
                                </div>
                                <div>
                                    {product.status === 2 && (
                                        <Button
                                            size="sm"
                                            type="attention"
                                            icon="remove_circle_outline"
                                            onClick={() => this.setProductStatus(false)}
                                            style={{ marginRight: '2rem' }}
                                        >
                                            {t('disable')}
                                        </Button>
                                    )}

                                    {product.status === 3 && (
                                        <Button
                                            size="sm"
                                            type="attention"
                                            icon="done"
                                            onClick={() => this.setProductStatus(true)}
                                            style={{ marginRight: '2rem' }}
                                        >
                                            {t('enable')}
                                        </Button>
                                    )}

                                    <Button
                                        size="sm"
                                        type="positive"
                                        icon="cancel"
                                        onClick={() => {
                                            closeModel(false);
                                        }}
                                    >
                                        {t('cancel')}
                                    </Button>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </Modal>
        );
    }
}

/**
 * Products page component
 * @param {function} fetchProducts Redux action
 * @param {function} createProduct Redux action
 * @param {function} dismissCreateProductResult Redux action
 * @param {function} updateProduct Redux action
 * @param {function} dismissUpdateProductResult Redux action
 * @param {function} uploadProductImage Redux action
 * @param {function} uploadProductImageSuccess Redux actiondb
 * @param {function} removeProductImage Redux action
 * @param {function} fetchLocations Redux action
 * @param {object} products Redux products state
 */

export class Products extends Component {
    constructor(props) {
        super(props);
        this.state = {
            formOpen: false,
            editingProduct: null,
            creatingProduct: false,
            deleteModelOpen: false,
            openModel: false,
            selectedProduct: {},
            nameSorting: true,
            productCategorySorting: false,
            sortAsc: true,
            productTypeView: 'active',
            productCategoryFilter: [],
            productLabelFilter: [],
            vatCategoryFilter: [],
            supplierFilter: [],
        };

        this.isAdmin = store.getState().user.isAdmin;
    }

    componentDidMount() {
        // locations have not been fetched
        if (!this.props.cabinets.locationsFetched && !this.props.cabinets.locationsIsFetching) {
            this.props.fetchLocations();
        }

        // cabinets have not been fetched
        if (!this.props.cabinets.fetched && !this.props.cabinets.isFetching) {
            this.props.fetchCabinets();
        }

        this.props.fetchProducts();
        this.props.fetchProductTypes();
        this.props.fetchVatCategories();
        this.props.fetchProductCategories();
        this.props.fetchProductLabels();
        this.props.fetchSuppliers();
    }

    // Passes to parent component to control state
    componentDidUpdate(prevState) {
        if (prevState.formOpen !== this.state.formOpen) {
            this.props.handleProductForm(this.state.formOpen);
        }
    }

    // open create product form
    startCreateProduct = () => {
        this.props.history.push('/products/create');
        /*
        this.setState({
            creatingProduct: true,
            formOpen: true,
        });
        */
    };

    isProductInFilters = product => {
        const { productCategoryFilter, productLabelFilter, vatCategoryFilter, supplierFilter } = this.state;
        const { t } = this.props;
        let isProductInFilters = false;

        let isInProductCategoryFilter = false;
        let isInProductLabelFilter = false;
        let isInVatCategoryFilter = false;
        let isInSupplierFilter = false;

        if (productCategoryFilter.length === 0) {
            isInProductCategoryFilter = true;
        } else {
            productCategoryFilter.forEach(category => {
                if (product.productCategory) {
                    if (product.productCategory.name === category) isInProductCategoryFilter = true;
                } else {
                    if (category === t('notDefined')) isInProductCategoryFilter = true;
                }
            });
        }

        if (productLabelFilter.length === 0) {
            isInProductLabelFilter = true;
        } else {
            productLabelFilter.forEach(label => {
                if (product.labels && product.labels.length) {
                    if (product.labels.indexOf(label) !== -1) isInProductLabelFilter = true;
                } else {
                    if (label === t('notDefined')) isInProductLabelFilter = true;
                }
            });
        }

        if (vatCategoryFilter.length === 0) {
            isInVatCategoryFilter = true;
        } else {
            vatCategoryFilter.forEach(category => {
                if (product.vatCategory) {
                    if (product.vatCategory.name === category) isInVatCategoryFilter = true;
                } else {
                    if (category === t('notDefined')) isInVatCategoryFilter = true;
                }
            });
        }

        if (supplierFilter.length === 0) {
            isInSupplierFilter = true;
        } else {
            supplierFilter.forEach(category => {
                if (product.supplier) {
                    if (product.supplier.name === category) isInSupplierFilter = true;
                } else {
                    if (category === t('notDefined')) isInSupplierFilter = true;
                }
            });
        }

        if (isInProductCategoryFilter && isInVatCategoryFilter && isInSupplierFilter && isInProductLabelFilter) {
            isProductInFilters = true;
        }

        return isProductInFilters;
    };

    handleProductTypeFilter = selection => {
        this.setState({ productTypeView: selection });
    };

    handleProductCategoryFilter = selection => {
        this.setState({ productCategoryFilter: selection });
    };

    handleProductLabelFilter = selection => {
        this.setState({ productLabelFilter: selection });
    };

    handleVatCategoryFilter = selection => {
        this.setState({ vatCategoryFilter: selection });
    };

    handleSupplierFilter = selection => {
        this.setState({ supplierFilter: selection });
    };

    // open edit product form and
    editProduct = product => {
        this.props.history.push(`/products/${product.id}`);

        window.scroll(0, 110);
        /*
        this.setState({
            editingProduct: product,
            formOpen: true,
        });*/
    };

    // close create product result
    dismissCreateProductResult = () => {
        this.props.dismissCreateProductResult();
        this.setState({
            creatingProduct: false,
            formOpen: false,
        });
    };

    // close update product result
    dismissUpdateProductResult = () => {
        this.props.dismissUpdateProductResult();
        this.setState({
            editingProduct: null,
            formOpen: false,
        });
    };

    setSelectedProduct = product => {
        this.setState({
            openModel: true,
            selectedProduct: product,
        });
    };

    productStatusChange = (refetchProduct = true) => {
        if (refetchProduct) {
            this.setState({ openModel: false, deleteModelOpen: false, productTypeView: 'active' });

            this.props.fetchProducts();
            this.props.fetchProductTypes();
            this.props.fetchVatCategories();
            this.props.fetchProductCategories();
            this.props.fetchProductLabels();
            this.props.fetchSuppliers();
        } else {
            this.setState({ openModel: false, deleteModelOpen: false });
        }
    };

    sortProductsByName = () => {
        this.setState({
            nameSorting: true,
            productCategorySorting: false,
            sortAsc: !this.state.nameSorting ? true : !this.state.sortAsc, // this makes sure that upon first click, sorting will be always ascending
        });
    };
    sortProductsByProductCategory = () => {
        this.setState({
            nameSorting: false,
            productCategorySorting: true,
            sortAsc: !this.state.productCategorySorting ? true : !this.state.sortAsc, // this makes sure that upon first click, sorting will be always ascending
        });
    };

    generateExcel = products => {
        return formatProductsForXLSX(products);
    };
    generateCSV = products => {
        return formattedProductsForCSV(products);
    };

    getProducts = (productView = 'active') => {
        const { products, t, searchQuery } = this.props;
        const { nameSorting, productCategorySorting, sortAsc } = this.state;

        let productStatus;
        if (productView === 'active') {
            productStatus = 2;
        } else if (productView === 'archived') {
            productStatus = 3;
        }

        const allProducts = products.products;

        if (nameSorting && sortAsc) {
            allProducts.sort((p1, p2) => {
                if (!p1.name) return -1;
                if (!p2.name) return 1;
                return p1.name.toLowerCase().trim() > p2.name.toLowerCase().trim() ? 1 : -1;
            });
        } else if (nameSorting && !sortAsc) {
            allProducts.reverse();
        }

        if (productCategorySorting && sortAsc) {
            allProducts.sort((p1, p2) => {
                const prodCategNameP1 = p1.productCategory ? p1.productCategory.name : '';
                const prodCategNameP2 = p2.productCategory ? p2.productCategory.name : '';
                if (!prodCategNameP1) return -1;
                if (!prodCategNameP2) return 1;
                return prodCategNameP1.toLowerCase().trim() > prodCategNameP2.toLowerCase().trim() ? 1 : -1;
            });
        } else if (productCategorySorting && !sortAsc) {
            allProducts.reverse();
        }

        const selectedProducts = allProducts
            .filter(product => {
                if (!searchQuery) {
                    if (productView === 'active') {
                        return product.status === 2 && !product.isPrebooked && this.isProductInFilters(product);
                    } else if (productView === 'prebooked') {
                        return product.isPrebooked && product.status === 2 && this.isProductInFilters(product);
                    } else {
                        return product.status === 3 && this.isProductInFilters(product);
                    }
                } else {
                    // For each type of product status, search products by name, barcode, product category, or vat category
                    return (
                        this.isProductInFilters(product) &&
                        ((productView !== 'prebooked' &&
                            (!product.isPrebooked || product.status === 3) &&
                            product.status === productStatus &&
                            ((product.name || '').toLowerCase().indexOf(searchQuery.toLowerCase()) !== -1 ||
                                (product.barcode || '').toLowerCase().indexOf(searchQuery.toLowerCase()) !== -1 ||
                                (product.productCategory
                                    ? (product.productCategory.name || '').toLowerCase().indexOf(searchQuery.toLowerCase()) !== -1
                                    : null) ||
                                (product.vatCategory ? (product.vatCategory.name || '').toLowerCase().indexOf(searchQuery.toLowerCase()) !== -1 : null) ||
                                (product.supplier ? (product.supplier.name || '').toLowerCase().indexOf(searchQuery.toLowerCase()) !== -1 : null))) ||
                            (product.labels && product.labels.length
                                ? product.labels.findIndex(el => el.toLowerCase().indexOf(searchQuery.toLowerCase()) !== -1) !== -1
                                : null) ||
                            (productView === 'prebooked' &&
                                product.isPrebooked &&
                                product.status === 2 &&
                                ((product.name || '').toLowerCase().indexOf(searchQuery.toLowerCase()) !== -1 ||
                                    (product.barcode || '').toLowerCase().indexOf(searchQuery.toLowerCase()) !== -1 ||
                                    (product.productCategory
                                        ? (product.productCategory.name || '').toLowerCase().indexOf(searchQuery.toLowerCase()) !== -1
                                        : null) ||
                                    (product.vatCategory ? (product.vatCategory.name || '').toLowerCase().indexOf(searchQuery.toLowerCase()) !== -1 : null) ||
                                    (product.labels && product.labels.length
                                        ? product.labels.findIndex(el => el.toLowerCase().indexOf(searchQuery.toLowerCase()) !== -1) !== -1
                                        : null) ||
                                    (product.supplier ? (product.supplier.name || '').toLowerCase().indexOf(searchQuery.toLowerCase()) !== -1 : null))))
                    );
                }
            })
            .map(product => {
                return (
                    <ListGroupItem key={product.barcode + product.id} className="p-0 product-list-item">
                        <Product
                            product={product}
                            editProduct={this.editProduct}
                            canEdit={!this.state.formOpen}
                            t={t}
                            removingProduct={products.removingProduct}
                            removingProductComplete={products.removingProductError}
                            openProductStatusModel={() => {
                                this.setSelectedProduct(product);
                            }}
                            openDeleteProductModal={product => {
                                this.setState({
                                    selectedProduct: product,
                                    deleteModelOpen: true,
                                });
                            }}
                            formOpen={this.state.formOpen}
                            isAdmin={this.isAdmin}
                            locations={this.props.cabinets.locations}
                        />
                    </ListGroupItem>
                );
            });
        return selectedProducts.length ? selectedProducts : <div className="no-results">{t('noResults')}</div>;
    };

    render() {
        const { t, products } = this.props;
        const { nameSorting, sortAsc, productCategorySorting } = this.state;

        let nameSortingClassName = '';
        let productCategorySortingClassName = '';

        if (sortAsc) {
            if (nameSorting) {
                productCategorySortingClassName = 'mt-1';
                nameSortingClassName = 'se-header--top';
            }

            if (productCategorySorting) {
                nameSortingClassName = 'mt-1';
                productCategorySortingClassName = 'se-header--top';
            }
        } else if (!sortAsc) {
            if (nameSorting) {
                productCategorySortingClassName = 'mt-1';
                nameSortingClassName = 'se-header--bottom';
            }

            if (productCategorySorting) {
                nameSortingClassName = 'mt-1';
                productCategorySortingClassName = 'se-header--bottom';
            }
        }

        // locations have not been fetched
        if (!this.props.cabinets.locationsFetched || this.props.cabinets.locationsIsFetching) {
            return (
                <div>
                    <Spinner size="lg" />
                </div>
            );
        }

        // locations fetch error
        if (this.props.cabinets.locationsIsError) {
            return (
                <FetchingAlert
                    fetchingMessage={t('fetchingProducts')}
                    noDataMessage={t('noProducts')}
                    errorMessage={t('productsFetchError')}
                    isError={true}
                    noData={false}
                />
            );
        }

        const productTypesOptions = [
            {
                label: t('activeProducts'),
                value: 'active',
            },
            {
                label: t('prebookedProducts'),
                value: 'prebooked',
            },
            {
                label: t('showDisable'),
                value: 'archived',
            },
        ];

        const productCategoryOptions = this.props.productCategories.productCategories
            ? [
                  ...this.props.productCategories.productCategories.map(pc => {
                      return { label: pc.name, value: pc.id };
                  }),
                  { label: t('notDefined'), value: 'notDefined' },
              ]
            : undefined;

        const productLabelOptions = this.props.productLabels.productLabels
            ? [
                  ...this.props.productLabels.productLabels.map(pl => {
                      return { label: pl, value: pl };
                  }),
                  { label: t('notDefined'), value: 'notDefined' },
              ]
            : undefined;

        const vatCategoryOptions = this.props.vatCategories.vatCategories
            ? [
                  ...this.props.vatCategories.vatCategories.map(vc => {
                      return { label: vc.name, value: vc.id };
                  }),
                  { label: t('notDefined'), value: 'notDefined' },
              ]
            : undefined;

        const activeSuppliers = this.props.suppliers.suppliers ? this.props.suppliers.suppliers.filter(supplier => supplier.status === 1) : undefined;

        const supplierOptions = this.props.suppliers.suppliers
            ? [
                  ...activeSuppliers.map(s => {
                      return { label: s.name, value: s.id };
                  }),
                  { label: t('notDefined'), value: 'notDefined' },
              ]
            : undefined;

        return (
            <div>
                {this.state.openModel && (
                    <ProductStatusChangeModel
                        product={this.state.selectedProduct}
                        openModel={this.state.openModel}
                        closeModel={this.productStatusChange}
                        archiveProduct={this.props.archiveProduct}
                        removingProduct={products.removingProduct}
                        removingProductComplete={products.removingProductError}
                        t={t}
                    />
                )}
                {this.state.deleteModelOpen && (
                    <DeleteProductModal
                        product={this.state.selectedProduct}
                        deleteModelOpen={this.state.deleteModelOpen}
                        deleteModalClose={refetch => this.productStatusChange(refetch)}
                        deleteProduct={this.props.deleteProduct}
                        deleteProgress={this.props.deleteProgress}
                        t={t}
                    />
                )}

                <>
                    {!this.state.formOpen ? (
                        <Row>
                            <Col>
                                <Row>
                                    <Col xs="12" sm="12" md="12" lg="12">
                                        {!this.isAdmin && <NotAccessibleLabel message={t('notAccessibleForYou')} />}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        {products.isFetching || products.isError || products.products.length === 0 ? (
                                            <Col className="px-0">
                                                <Row className="mt-3">
                                                    <Col className="catalog-top-row m-0">
                                                        {this.props.vatCategories &&
                                                        this.props.vatCategories.vatCategories &&
                                                        this.props.vatCategories.vatCategories.length ? (
                                                            <>
                                                                <ProductSearchBar />
                                                                {products.fetched && !products.isError && products.products && (
                                                                    <Button
                                                                        className="ml-4 catalog-top-row-element"
                                                                        type="positive"
                                                                        icon="add_circle"
                                                                        onClick={this.startCreateProduct}
                                                                        disabled={!this.isAdmin}
                                                                    >
                                                                        {t('addProduct')}
                                                                    </Button>
                                                                )}
                                                            </>
                                                        ) : (
                                                            !products.isFetching &&
                                                            !products.isError && <FetchingAlert noDataMessage={t('noVatForProductCreation')} noData={true} />
                                                        )}
                                                    </Col>
                                                </Row>
                                                <Row className="mt-3">
                                                    <Col>
                                                        <FetchingAlert
                                                            fetchingMessage={t('fetchingProducts')}
                                                            noDataMessage={t('noProducts')}
                                                            errorMessage={t('productsFetchError')}
                                                            isError={products.isError}
                                                            noData={products.products.length === 0 && !products.isFetching}
                                                        />
                                                    </Col>
                                                </Row>
                                            </Col>
                                        ) : (
                                            <>
                                                {!this.state.creatingProduct && !this.state.editingProduct ? (
                                                    <>
                                                        <Row className="mt-3">
                                                            <Col className="catalog-top-row m-0 product-catalog">
                                                                {this.props.vatCategories &&
                                                                this.props.vatCategories.vatCategories &&
                                                                this.props.vatCategories.vatCategories.length ? (
                                                                    <>
                                                                        <ProductSearchBar />
                                                                        <div className="catalog-top-row-end-elements">
                                                                            <div className="hide-in-mb">
                                                                                <ProductsExport
                                                                                    t={t}
                                                                                    products={products.products}
                                                                                    generateExcel={this.generateExcel}
                                                                                    generateCSV={this.generateCSV}
                                                                                    exportHeaders={generateProductsCSVHeaders()}
                                                                                />
                                                                            </div>
                                                                            <Button
                                                                                className="ml-2 catalog-top-row-element"
                                                                                type="positive"
                                                                                icon="add_circle"
                                                                                onClick={this.startCreateProduct}
                                                                                disabled={!this.isAdmin}
                                                                            >
                                                                                {t('addProduct')}
                                                                            </Button>
                                                                        </div>
                                                                    </>
                                                                ) : (
                                                                    !products.isFetching &&
                                                                    !products.isError && (
                                                                        <FetchingAlert noDataMessage={t('noVatForProductCreation')} noData={true} />
                                                                    )
                                                                )}
                                                            </Col>
                                                        </Row>
                                                        <Row className="mt-3 mb-4 hide-in-tab">
                                                            <Col style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                                <div>
                                                                    <SingleSelectFilter
                                                                        defaultValue={'active'}
                                                                        options={productTypesOptions}
                                                                        handleFilter={this.handleProductTypeFilter}
                                                                    />
                                                                </div>
                                                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                                    <MultipleSelectFilter
                                                                        labelName={t('labels')}
                                                                        options={productLabelOptions}
                                                                        handleFilter={this.handleProductLabelFilter}
                                                                    />
                                                                    <div style={{ width: '16px' }} />
                                                                    <MultipleSelectFilter
                                                                        labelName={t('productCategoriesTitle')}
                                                                        options={productCategoryOptions}
                                                                        handleFilter={this.handleProductCategoryFilter}
                                                                    />
                                                                    <div style={{ width: '16px' }} />
                                                                    <MultipleSelectFilter
                                                                        labelName={t('vats')}
                                                                        options={vatCategoryOptions}
                                                                        handleFilter={this.handleVatCategoryFilter}
                                                                    />
                                                                    <div style={{ width: '16px' }} />
                                                                    <MultipleSelectFilter
                                                                        labelName={t('suppliers')}
                                                                        options={supplierOptions}
                                                                        handleFilter={this.handleSupplierFilter}
                                                                    />
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                        <ListGroup flush className="product-catalog-table">
                                                            <div className="se-table-header">
                                                                <Row className="no-gutters">
                                                                    <Col xs="4" md="1" className="px-1 my-1">
                                                                        <div>{t('image')}</div>
                                                                    </Col>
                                                                    <Col xs="4" md="2" className={`px-1 ${nameSortingClassName}`}>
                                                                        <div className="se-header-action" onClick={this.sortProductsByName}>
                                                                            {t('name')}
                                                                            <Icon className="header-sort-icon">sort</Icon>
                                                                        </div>
                                                                    </Col>
                                                                    <Col xs="4" md="2" className="px-1 my-1">
                                                                        <div>{t('price')}</div>
                                                                    </Col>
                                                                    <Col xs="4" md="2" lg="2" className={`px-1 ${productCategorySortingClassName}`}>
                                                                        <div
                                                                            className="se-header-action hide-in-mb"
                                                                            style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
                                                                            onClick={this.sortProductsByProductCategory}
                                                                        >
                                                                            {t('productCategory')}
                                                                            <Icon className="header-sort-icon">sort</Icon>
                                                                        </div>
                                                                    </Col>
                                                                    <Col xs="4" md="3" lg="2" className="px-1 my-1 hide-in-mb">
                                                                        <div style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>{t('vatCategory')}</div>
                                                                    </Col>
                                                                    <Col xs="4" md="2" lg="2" className="px-1 my-1 hide-in-mb">
                                                                        <div>{t('supplier')}</div>
                                                                    </Col>
                                                                    <Col xs="auto" md="auto" lg="auto" className="px-1 my-1 hide-in-mb">
                                                                        <div style={{ width: '4rem', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                                                            {t('lifetime')}
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                            </div>
                                                            {this.getProducts(this.state.productTypeView)}
                                                        </ListGroup>
                                                    </>
                                                ) : null}
                                            </>
                                        )}
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    ) : null}
                    {this.state.creatingProduct ? (
                        <Col xs="12" className="mb-3">
                            <EditProduct
                                t={t}
                                reduxProductTypes={this.props.productTypes}
                                removeProductImage={this.props.removeProductImage}
                                uploadProductImage={this.props.uploadProductImage}
                                reduxProduct={products}
                                productRequest={this.props.createProduct}
                                dismissResult={this.dismissCreateProductResult}
                                editing={false}
                                locations={this.props.cabinets.locations}
                                vatCategories={this.props.vatCategories}
                                productCategories={this.props.productCategories}
                                productLabels={this.props.productLabels}
                                suppliers={this.props.suppliers}
                            />
                        </Col>
                    ) : null}
                    {this.state.editingProduct ? (
                        <Col xs="12" className="mb-3">
                            <EditProduct
                                t={t}
                                editing={true}
                                reduxProductTypes={this.props.productTypes}
                                removeProductImage={this.props.removeProductImage}
                                uploadProductImageSuccess={this.props.uploadProductImageSuccess}
                                uploadProductImage={this.props.uploadProductImage}
                                product={this.state.editingProduct}
                                reduxProduct={products}
                                productRequest={this.props.updateProduct}
                                dismissResult={this.dismissUpdateProductResult}
                                locations={this.props.cabinets.locations}
                                vatCategories={this.props.vatCategories}
                                productCategories={this.props.productCategories}
                                productLabels={this.props.productLabels}
                                suppliers={this.props.suppliers}
                            />
                        </Col>
                    ) : null}
                </>
            </div>
        );
    }
}

export default withRouter(
    connect(
        state => ({
            products: state.products,
            productTypes: state.productTypes,
            cabinets: state.cabinets,
            searchQuery: state.products.searchQuery,
            vatCategories: state.vatCategories,
            productCategories: state.productCategories,
            productLabels: state.productLabels,
            suppliers: state.suppliers,
            deleteProgress: state.products.deleteProgress,
        }),
        {
            fetchProducts,
            createProduct,
            archiveProduct,
            deleteProduct,
            dismissCreateProductResult,
            updateProduct,
            dismissUpdateProductResult,
            uploadProductImage,
            uploadProductImageSuccess,
            removeProductImage,
            fetchProductTypes,
            fetchCabinets,
            fetchLocations,
            fetchVatCategories,
            fetchProductCategories,
            fetchProductLabels,
            fetchSuppliers,
        }
    )(translate('main')(Products))
);
