export const initState = {
    fetched: false,
    fetching: false,
    error: false,
    releaseVersions: [],
    updateableReleaseVersions: [],
};

export default function releaseVersions(state = initState, action) {
    switch (action.type) {
        case 'REQUEST_ORGANIZATION_RELEASE_VERSIONS':
            return {
                ...state,
                fetched: false,
                fetching: true,
                error: false,
                releaseVersions: [],
            };
        case 'RECEIVE_ORGANIZATION_RELEASE_VERSIONS':
            return {
                ...state,
                fetching: false,
                error: false,
                fetched: true,
                releaseVersions: action.organizationReleaseVersions.filter(version => version.applicableVersions && version.applicableVersions.length),
                newerVersions: action.organizationReleaseVersions.filter(version => version.newerVersions && version.newerVersions.length),
                lastUpdated: action.receivedAt,
            };
        case 'RECEIVE_ORGANIZATION_RELEASE_VERSIONS_ERROR':
            // console.error(action.error);
            return {
                ...state,
                fetching: false,
                error: action.error.toString(),
            };
        case 'RECEIVE_DEVICE_UPDATE_RESPONSE':
            const updatedDevices = action.deviceUpdateResponse.devices;
            const updatedSingleDeviceId = action.deviceUpdateResponse.deviceId;
            const schedulingType = action.deviceUpdateResponse.schedulingType;
            if (updatedDevices && updatedDevices.length) {
                let devices = [];
                if (schedulingType === 'now') {
                    devices = state.newerVersions.filter(device => {
                        const updatedIds = updatedDevices.map(device => device.deviceId);
                        return !updatedIds.includes(device.deviceId);
                    });
                } else {
                    devices = state.newerVersions.map(device => {
                        const deviceUpdated = updatedDevices.find(d => device.deviceId === d.deviceId);
                        if (deviceUpdated) return { ...device, scheduling: [...device.scheduling, deviceUpdated] };
                        else return device;
                    });
                }
                return {
                    ...state,
                    fetched: true,
                    fetching: false,
                    newerVersions: devices,
                };
            } else if (!updatedDevices) {
                return {
                    ...state,
                    fetched: true,
                    fetching: false,
                    newerVersions: [...state.newerVersions.filter(version => version.deviceId !== updatedSingleDeviceId)],
                };
            } else return state;
        case 'REQUEST_UPDATE_SCHEDULING_REMOVAL':
            return {
                ...state,
                fetched: false,
                fetching: true,
                error: false,
            };
        case 'RECEIVE_UPDATE_SCHEDULING_REMOVAL':
            const removedObj = state.newerVersions.find(v => v.deviceId === action.removeResponse.deviceId);
            removedObj.scheduling = removedObj.scheduling.filter(s => s._id !== action.removeResponse.scheduleId);
            return {
                ...state,
                fetching: false,
                fetched: true,
                error: false,
                newerVersions: [...state.newerVersions.map(version => (version.deviceId === action.removeResponse.deviceId ? removedObj : version))],
            };
        default:
            return state;
    }
}
