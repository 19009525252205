import React, { Fragment } from 'react';
import ReportHeader from '../reportHeader.js';
import TemperatureChart from './temperatureChart.js';
import { formatTemperatureForCSV, generateTemperatureCSVHeaders, formatTemperatureForXLSX } from '../export.js';

import { Icon, Spinner } from '@storaensods/seeds-react';
import FetchingAlert from '../../fetching/fetchingAlert.js';

/**
 * Temperature report component
 */
export default function TemperatureReport({ t, temperature, currentStatus, deviceOnline, cabinetName, timeRange, isFreezer }) {
    const hasTemperatureData = (temperature.current && temperature.current.data.length) || (temperature.average && temperature.average.data.length);

    const hasCurrentStatus = currentStatus.currentStatus && currentStatus.currentStatus.data.length;
    const currentTemperature =
        hasCurrentStatus && currentStatus.currentStatus.data[0]['Device.currentTemperature']
            ? currentStatus.currentStatus.data[0]['Device.currentTemperature']
            : null;

    if (temperature.isFetching || temperature.error || !hasTemperatureData) {
        return (
            <Fragment>
                <div className="col-md-12 col-lg-12 mt-3 p-2">
                    <div className="row temp-color temp-current">
                        <span className="">&#42;</span> {t('current')} {t('temperature')} :
                        {currentStatus.fetched && currentTemperature && deviceOnline && (
                            <Fragment>
                                {parseFloat(currentTemperature).toFixed(2)}
                                <span>&deg;C&nbsp;</span> |&nbsp;
                            </Fragment>
                        )}
                        {currentStatus.fetched && !currentTemperature && (
                            <Fragment>
                                <span className="temp-no-data" color="warning" size="sm">
                                    <Icon size="sm">warning</Icon> &nbsp;{t('noData')}
                                </span>
                            </Fragment>
                        )}
                        {currentStatus.isFetching && <Spinner />}
                    </div>
                </div>
                <div style={{ padding: '15px' }}>
                    <FetchingAlert
                        fetchingMessage={t('fetching')}
                        noDataMessage={t('noData')}
                        errorMessage={t('fetchingError')}
                        isError={temperature.error}
                        noData={!hasTemperatureData && !temperature.isFetching}
                    />
                </div>
            </Fragment>
        );
    }

    return (
        <>
            <div className="col-1">
                <ReportHeader
                    title={''}
                    exportFileName={t('temperatureTitle')}
                    exportData={formatTemperatureForCSV(temperature, cabinetName)}
                    exportDataExcel={formatTemperatureForXLSX(temperature, cabinetName)}
                    exportHeaders={generateTemperatureCSVHeaders()}
                />
            </div>

            <div className="col-md-12 col-lg-12">
                <TemperatureChart
                    average={temperature.average}
                    current={{
                        temperature: currentTemperature,
                        deviceOnline,
                        lastRefreshTime: hasCurrentStatus && currentStatus.currentStatus.lastRefreshTime ? currentStatus.currentStatus.lastRefreshTime : null,
                    }}
                    timeRange={timeRange}
                    t={t}
                    defrost={isFreezer}
                />
            </div>
        </>
    );
}
