import React from 'react';

export function DashboardSummary({ disabled, t, dateRange, filter }) {
    let classNames = 'dashboard-summary-container text-center mt-2';
    if (disabled) {
        classNames += ' disabled';
    }

    if (filter && filter.filterBy === 'locations') {
        return (
            <div className={classNames}>
                <h6>{t(dateRange)}</h6>
                <span>
                    {t('locations')}: {filter.values.join(', ')}
                </span>
            </div>
        );
    } else if (filter && filter.filterBy === 'cabinets') {
        return (
            <div className={classNames}>
                <h6>{t(dateRange)}</h6>
                <span>
                    {t('cabinets')}: {filter.values.map(cabinet => cabinet.name).join(', ')}
                </span>
            </div>
        );
    } else {
        return (
            <div className={classNames}>
                <h6>{t(dateRange)}</h6>
            </div>
        );
    }
}

/**
 * Dashboard data aggregation cards
 *
 * @param {object} dashboard redux dashboard state
 * @param {string} selectedCard
 * @param {function} onCardClick will be called with clicked card string as input
 * @param {function} t
 */
export function DashboardDataCards({ t, dashboard, selectedCard, onCardClick }) {
    let currency;
    let totalTransactions;
    let totalSales;
    let totalItemsSold;
    let totalUniqueCustomers;
    let avgSalesPerTransaction;
    let avgItemsPerTransaction;
    let totalUnpaidCount;
    let totalUnpaidAmount;
    if (dashboard.summaryData.data.length === 0) {
        currency = '';
        totalTransactions = 0;
        totalSales = 0;
        totalItemsSold = 0;
        totalUniqueCustomers = 0;
        avgSalesPerTransaction = 0;
        avgItemsPerTransaction = 0;
        totalUnpaidCount = 0;
        totalUnpaidAmount = 0;
    } else {
        totalUnpaidAmount =
            Array.isArray(dashboard.totalUnpaidAmount.data) &&
            dashboard.totalUnpaidAmount.data.length &&
            dashboard.totalUnpaidAmount.data[0]['TransactionDetails.unpaidSales']
                ? dashboard.totalUnpaidAmount.data[0]['TransactionDetails.unpaidSales']
                : 0;

        totalUnpaidCount =
            Array.isArray(dashboard.totalUnpaidCount.data) & dashboard.totalUnpaidCount.data.length
                ? dashboard.totalUnpaidCount.data[0]['TransactionDetails.unpaidTransactions']
                : 0;

        currency = dashboard.summaryData.data[0]['Transactions.currency'];
        totalTransactions = dashboard.summaryData.data[0]['Transactions.count'];
        totalSales =
            (dashboard.summaryDataTransactionDetails.data.length && dashboard.summaryDataTransactionDetails.data[0]['TransactionDetails.orderSum']) || 0;
        const totalOrderSum = (dashboard.totalOrderSum.data.length && dashboard.totalOrderSum.data[0]['Transactions.orderSum']) || 0;

        totalItemsSold = dashboard.summaryData.data[0]['Transactions.itemsSoldCount'];
        totalUniqueCustomers = dashboard.summaryData.data[0]['Transactions.customersCount'];

        avgSalesPerTransaction = totalTransactions !== 0 ? totalOrderSum / totalTransactions : 0;
        avgItemsPerTransaction = totalTransactions !== 0 ? totalItemsSold / totalTransactions : 0;
    }

    return (
        <div className="ml-4">
            <div
                className={selectedCard === 'sales' ? 'se-dashboard-aggregation-container-active' : 'se-dashboard-aggregation-container'}
                onClick={onCardClick.bind(this, 'sales')}
            >
                <div className="se-dashboard-aggregation-container-item">
                    <h6>{t('sales')}</h6>
                    <h4>
                        {totalSales.toFixed(2)} {currency}
                    </h4>
                </div>
            </div>
            <div
                className={selectedCard === 'transactions' ? 'se-dashboard-aggregation-container-active' : 'se-dashboard-aggregation-container'}
                onClick={onCardClick.bind(this, 'transactions')}
            >
                <div className="se-dashboard-aggregation-container-item">
                    <h6>{t('transactions')}</h6>
                    <h4>{totalTransactions}</h4>
                </div>
            </div>
            <div
                className={selectedCard === 'unpaidSales' ? 'se-dashboard-aggregation-container-active' : 'se-dashboard-aggregation-container'}
                onClick={onCardClick.bind(this, 'unpaidSales')}
            >
                <div className="se-dashboard-aggregation-container-item">
                    <h6>{t('unpaidSales')}</h6>
                    <h4>
                        {totalUnpaidAmount.toFixed(2)} {currency}
                    </h4>
                </div>
            </div>
            <div
                className={selectedCard === 'unpaidTransactions' ? 'se-dashboard-aggregation-container-active' : 'se-dashboard-aggregation-container'}
                onClick={onCardClick.bind(this, 'unpaidTransactions')}
            >
                <div className="se-dashboard-aggregation-container-item">
                    <h6>{t('unpaidTransactions')}</h6>
                    <h4>{totalUnpaidCount}</h4>
                </div>
            </div>
            <div
                className={selectedCard === 'uniqueCustomers' ? 'se-dashboard-aggregation-container-active' : 'se-dashboard-aggregation-container'}
                onClick={onCardClick.bind(this, 'uniqueCustomers')}
            >
                <div className="se-dashboard-aggregation-container-item">
                    <h6>{t('uniqueCustomers')}</h6>
                    <h4>{totalUniqueCustomers}</h4>
                </div>
            </div>
            <div
                className={selectedCard === 'averageSales' ? 'se-dashboard-aggregation-container-active' : 'se-dashboard-aggregation-container'}
                onClick={onCardClick.bind(this, 'averageSales')}
            >
                <div className="se-dashboard-aggregation-container-item">
                    <h6>{t('averageSales')}</h6>
                    <h4>
                        {avgSalesPerTransaction && avgSalesPerTransaction.toFixed(2)} {currency}
                    </h4>
                </div>
            </div>
            <div
                className={selectedCard === 'averageItems' ? 'se-dashboard-aggregation-container-active' : 'se-dashboard-aggregation-container'}
                onClick={onCardClick.bind(this, 'averageItems')}
            >
                <div className="se-dashboard-aggregation-container-item">
                    <h6>{t('averageItems')}</h6>
                    <h4>{avgItemsPerTransaction && avgItemsPerTransaction.toFixed(1)}</h4>
                </div>
            </div>
        </div>
    );
}
