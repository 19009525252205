const initState = {
    isAuthenticated: false,
    accessToken: null,
    profile: {},
    fetching: false,
    error: null,
    isAdmin: false,
    isReportingOperator: false,
    isRefillOperator:false
};

export default function user(state = initState, action) {
    switch (action.type) {
        case 'AUTH_DATA_RECEIVED':
            return {
                ...state,
                accessToken: action.accessToken,
                userName: action.userName,
                expiresAt: action.expiresAt,
                fetching: false,
            };
        case 'PROFILE_DATA_RECEIVED': {
            //check if the user has right to edit/udpdate cabinet
            const cabinetOperators = ['Admin', 'Administrator', 'Cabinet Operator'];
            const reportingOperators = ['Reporting Operator'];
            const refillOperators = ['Refill Operator']
            const roles = action.profile.roles;
            const cabinetOperatorRoles = Array.isArray(roles) ? roles.filter(role => cabinetOperators.includes(role)) : [];
            const reportingOperatorRoles = Array.isArray(roles) ? roles.filter(role => reportingOperators.includes(role)) : [];
            const refillOperatorRoles = Array.isArray(roles) ? roles.filter(role => refillOperators.includes(role)) : [];

            return {
                ...state,
                profile: action.profile,
                isAuthenticated: true,
                isAdmin: cabinetOperatorRoles.length > 0 ? true : false,
                isReportingOperator: reportingOperatorRoles.length > 0 ? true : false,
                isRoot: action.profile.accessScope === 'Root',
                isRefillOperator: refillOperatorRoles.length>0 ? true : false
            };
        }
        case 'SELECT_GROUP':
            return {
                ...state,
                group: action.group,
            };
        case 'REDIRECTING_TO_AUTHORIZATION_PAGE':
            return {
                ...state,
                fetching: false,
                error: action.error,
                accessToken: null,
                isAuthenticated: false,
                isAdmin: false,
                isReportingOperator: false,
            };
        case 'SIGN_IN_REJECTED':
            return {
                isAuthenticated: false,
                isAdmin: false,
                isReportingOperator: false,
                accessToken: null,
                profile: {},
                fetching: false,
                error: action.error.toString().match(/Unauthorized/g) ? 'Session expired' : action.error.toString(),
            };
        case 'SIGN_OUT_USER':
            return {
                isAdmin: false,
                isReportingOperator: false,
                isAuthenticated: false,
                accessToken: null,
                profile: {},
                fetching: false,
                error: null,
            };
        default:
            return state;
    }
}
