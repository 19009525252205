import {
    fetchOrganizationSettings as fetchOrganizationSettingsHttpRequest,
    updateOrganizationSettings as updateOrganizationSettingsHttpRequest,
    createLogoImage as createLogoImageHttpRequest,
} from '../api';
import { v1 as uuidv1 } from 'uuid';

export const REQUEST_ORGANIZATIONS_SETTINGS = 'REQUEST_ORGANIZATIONS_SETTINGS';
export const RECEIVE_ORGANIZATIONS_SETTINGS = 'RECEIVE_ORGANIZATIONS_SETTINGS';
export const RECEIVE_ORGANIZATIONS_SETTINGS_ERROR = 'RECEIVE_ORGANIZATIONS_SETTINGS_ERROR';
export const REQUEST_ORGANIZATIONS_SETTINGS_UPDATE = 'REQUEST_ORGANIZATIONS_UPDATE_SETTINGS';
export const RECEIVE_ORGANIZATIONS_SETTINGS_UPDATE = 'RECEIVE_ORGANIZATIONS_UPDATE_SETTINGS';
export const RECEIVE_ORGANIZATIONS_SETTINGS_UPDATE_ERROR = 'RECEIVE_ORGANIZATIONS_SETTINGS_ERROR';
export const REQUEST_ORGANIZATION_UPLOAD_LOGO_IMAGE = 'REQUEST_ORGANIZATION_UPLOAD_LOGO_IMAGE';
export const RECEIVE_UPLOAD_ORGANIZATION_LOGO_IMAGE_SUCCESS = 'RECEIVE_UPLOAD_ORGANIZATION_LOGO_IMAGE_SUCCESS';
export const RECEIVE_UPLOAD_ORGANIZATION_LOGO_IMAGE_ERROR = 'RECEIVE_UPLOAD_ORGANIZATION_LOGO_IMAGE_ERROR';
export const REVERT_ORGANIZATION_REMOVE_LOGO_IMAGE_STATE = 'REVERT_ORGANIZATION_REMOVE_LOGO_IMAGE_STATE';
export const REMOVE_ORGANIZATION_LOGO_IMAGE = 'REMOVE_ORGANIZATION_LOGO_IMAGE';
export const REQUEST_ORGANIZATION_REMOVE_LOGO_IMAGE = 'REQUEST_ORGANIZATION_REMOVE_LOGO_IMAGE';
export const RESET_ORG_SETTINGS = 'RESET_ORG_SETTINGS';
/**
 * Request organizations Redux action creator
 */
function requestOrganizations() {
    return {
        type: REQUEST_ORGANIZATIONS_SETTINGS,
    };
}

/**
 * Receive organizations Redux action creator
 * @param {array} organizations Array of  received from root api
 */
function receiveOrganizations(organizationSettings) {
    return {
        type: RECEIVE_ORGANIZATIONS_SETTINGS,
        organizationSettings,
        receivedAt: Date.now(),
    };
}

/**
 * Receive organizations error Redux action creator
 * @param {Error} error The error object created in api.js
 */
function receiveOrganizationsError(error) {
    return {
        type: RECEIVE_ORGANIZATIONS_SETTINGS_ERROR,
        error: error,
    };
}

/**
 * Request create logo image Redux action creator
 */
function requestUpdateLogoImage() {
    return {
        type: REQUEST_ORGANIZATION_UPLOAD_LOGO_IMAGE,
    };
}

/**
 * Receive create logo image success response Redux action creator
 */
function uploadLogoImageSuccess(url) {
    return {
        type: RECEIVE_UPLOAD_ORGANIZATION_LOGO_IMAGE_SUCCESS,
        url,
        receivedAt: Date.now(),
    };
}

/**
 * Receive create logo image error Redux action creator
 * @param {Error} error
 */
function uploadLogoImageError(error) {
    return {
        type: RECEIVE_UPLOAD_ORGANIZATION_LOGO_IMAGE_ERROR,
        error,
    };
}

/**
 * Used to remove image from form of product being updated/created
 */
export function removeOrganizationLogoImage() {
    return {
        type: REQUEST_ORGANIZATION_REMOVE_LOGO_IMAGE,
    };
}

/**
 * Used to remove image from form of product being updated/created
 */
export function revertOrganizationLogoImageState() {
    return {
        type: REVERT_ORGANIZATION_REMOVE_LOGO_IMAGE_STATE,
    };
}

/**
 * Thunk action for fetching organizations
 */
export function fetchOrganisations() {
    return (dispatch, getState) => {
        dispatch(requestOrganizations());
        const user = getState().user;
        return fetchOrganizationSettingsHttpRequest(user.group, user.accessToken)
            .then(response => dispatch(receiveOrganizations(response)))
            .catch(error => dispatch(receiveOrganizationsError(error)));
    };
}

/**
 * Request organizations Redux action creator
 */
function requestOrganizationSettingsUpdate() {
    return {
        type: REQUEST_ORGANIZATIONS_SETTINGS_UPDATE,
    };
}

/**
 * Receive organizations Redux action creator
 * @param {array} organizationSettings Array of  received from root api
 */
function receiveOrganizationSettingsUpdate(organizationSettings) {
    return {
        type: RECEIVE_ORGANIZATIONS_SETTINGS_UPDATE,
        organizationSettings,
        receivedAt: Date.now(),
    };
}

/**
 * Receive organizations error Redux action creator
 * @param {Error} error The error object created in api.js
 */
function receiveOrganizationSettingsUpdateError(error) {
    return {
        type: RECEIVE_ORGANIZATIONS_SETTINGS_UPDATE_ERROR,
        error: error,
    };
}

/**
 * Thunk action for fetching organizations
 * @param {object} settings Object of organization settings
 */
export function updateOrganizationSettings(settings, id) {
    return (dispatch, getState) => {
        dispatch(requestOrganizationSettingsUpdate());
        const user = getState().user;
        return updateOrganizationSettingsHttpRequest(id, user.accessToken, settings)
            .then(response => dispatch(receiveOrganizationSettingsUpdate(response)))
            .catch(error => dispatch(receiveOrganizationSettingsUpdateError(error)));
    };
}
/**
 *
 * @param {File} imageFile
 */
export function uploadOrganizationLogo(imageFile) {
    return (dispatch, getState) => {
        // update state to inform API call started
        dispatch(requestUpdateLogoImage());

        // convert image file to HTTP form data with unique filename
        const formData = new FormData();
        formData.append(0, imageFile, `${uuidv1()}.jpeg`);
        const user = getState().user;

        // store image in backend
        const accessKey = getState().user.accessToken;
        return createLogoImageHttpRequest(formData, accessKey, user.group)
            .then(response => dispatch(uploadLogoImageSuccess(encodeURI(response.url))))
            .catch(error => dispatch(uploadLogoImageError(error)));
    };
}

/**
 * Thunk action to refetch the organisation settings when organisation change
 */
export function refreshOrgSettings() {
    return dispatch => {
        dispatch({ type: RESET_ORG_SETTINGS });
        dispatch(fetchOrganisations());
    };
}
