import React, { useEffect, useState } from 'react';

import { Icon, Input } from '@storaensods/seeds-react';
import {
    cabinetDragStartHandler,
    locationDragStartHandler,
    cabinetDragEndHandler,
    locationDragEndHandler,
    locationDropHandler,
    locationDragoverHandler,
    locationDropZoneEnter,
    locationDropZoneExit,
} from './dragDropHandlers.js';
import { locationSortFunc, cabinetSortFunc } from '../cabinetUtils/utils.js';
import DeviceItem from './deviceItem.jsx';

import './dragDropList.css';
import icons from './../icons.svg';

/**
 * Returns draggable DOM location element with location drop zone, draggable location cabinets
 * and draggable child DOM location elements
 * @param {object} props.location
 * @param {array} props.filteredDevices devices that match search query
 * @param {boolean} props.rootLocation set to true if location is a root element
 * @param {boolean} props.filtering set to true if user typed something in cabinet search input
 * @param {function} props.t translator function
 */
function CabinetLocationDragDropList(props) {
    const {
        cabinetSearchQueryUpdated,
        locationIn = null,
        filteredDevices,
        rootLocation = false,
        filtering = false,
        cabinets,
        setCabinetLocation,
        updateLocationParent,
        updateLocationName,
        updateCabinetName,
        startRemoveLocationPrompt,
        selectedCabinetDeviceCode,
        selectedEncodingDeviceCode,
        selectDevice,
        isLocationNameDuplicated,
        t,
        isAdmin,
        alarms,
    } = props;

    const [showLocationEdit, setShowLocationEdit] = useState(false);
    const [startEditingLocation, setStartEditingLocation] = useState(false);
    const [locationEditItem, setLocationEditItem] = useState(null);
    const [locationEditValue, setLocationEditValue] = useState(null);
    const [isLocationNameShort, setIsLocationNameShort] = useState(false);
    const [locationNameDuplicated, setLocationNameDuplicated] = useState(false);

    useEffect(() => {
        if (locationEditItem) {
            setLocationEditValue(locationEditItem.name);
        }
    }, [locationEditItem]);

    useEffect(() => {
        if (locationEditValue !== null) {
            if (locationEditValue.length < 1) {
                setIsLocationNameShort(true);
            } else {
                setIsLocationNameShort(false);
            }

            if (locationEditItem && locationEditItem.name !== locationEditValue) {
                if (isLocationNameDuplicated(locationEditValue)) {
                    setLocationNameDuplicated(true);
                } else {
                    setLocationNameDuplicated(false);
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [locationEditValue]);

    /**
     * Checks if the name user choice for a cabinet is duplicated or not.
     * @param {string} name The target name which a cabinet should be renamed to
     * @returns true if there is another cabinet for this organization with the same name
     */
    const isCabinetNameDuplicated = name => {
        if (!cabinets) {
            return false;
        }
        return cabinets.some(cabinet => cabinet.name.replace(/\s+/g, '').toLowerCase() === name.replace(/\s+/g, '').toLowerCase());
    };

    let dropZoneDepth = 0;

    /**
     * Compute number of cabinets in location including child location cabinets
     * @param {object} location
     * @returns {number} Number of cabinets
     */
    const getLocationDevicesCount = location => {
        const locationCabinetsCount = filteredDevices.filter(cabinet => cabinet.locationId === location.id).length;

        const sumArray = [locationCabinetsCount, ...location.children.map(childLocation => getLocationDevicesCount(childLocation))];

        return sumArray.reduce(function(a, b) {
            return a + b;
        });
    };

    /**
     * returns all locations that are direct children of current location
     */
    const getChildLocations = () => {
        return location.children
            .sort(locationSortFunc)
            .map((childLocation, index) => (
                <CabinetLocationDragDropList
                    locationIn={childLocation}
                    filteredDevices={filteredDevices}
                    rootLocation={false}
                    filtering={filtering}
                    cabinetSearchQueryUpdated={cabinetSearchQueryUpdated}
                    cabinets={cabinets}
                    selectedCabinetDeviceCode={selectedCabinetDeviceCode}
                    selectedEncodingDeviceCode={selectedEncodingDeviceCode}
                    setCabinetLocation={setCabinetLocation}
                    updateLocationName={updateLocationName}
                    updateCabinetName={updateCabinetName}
                    updateLocationParent={updateLocationParent}
                    startRemoveLocationPrompt={startRemoveLocationPrompt}
                    selectDevice={selectDevice}
                    isLocationNameDuplicated={isLocationNameDuplicated}
                    key={index}
                    t={t}
                    isAdmin={isAdmin}
                    alarms={alarms}
                />
            ));
    };

    // When edit icon clicked
    const startEditingLocationName = location => {
        setLocationEditItem(location);
        setStartEditingLocation(true);
    };

    // when X icon clicked to cancel editing
    const cancelEditingLocationName = () => {
        setLocationEditItem(null);
        setStartEditingLocation(false);
    };

    // checks if the location name changed and if so, will save the changes
    const handleChangeLocationName = () => {
        if (locationEditItem.name !== locationEditValue.trim()) {
            updateLocationName(locationEditItem.code, locationEditValue.trim(), locationEditItem.parentLocationCode);
        }
        setStartEditingLocation(false);
    };

    const handleShowLocationEditIcon = location => {
        if (location.id) {
            setShowLocationEdit(true);
        }
    };

    const location = locationIn ? locationIn : { id: null, code: null, name: 'No location', children: [] };

    // cabinets belonging this location
    const locationDevices = filteredDevices
        .filter(cabinet => cabinet.locationId === location.id || (locationIn === null && !Object.hasOwn(cabinet, 'locationId')))
        .sort(cabinetSortFunc);

    // location cabinets count including all child branch location cabinets
    const locationDevicesCount = getLocationDevicesCount(location);

    if (filtering && locationDevicesCount === 0) {
        return null;
    }

    return (
        <div key={location.id || 'No location'} className="d-flex flex-row se-location-container">
            {!rootLocation ? (
                <div className="cabinet-drop-container-railing">
                    <div className="cabinet-drop-container-railing-line"></div>
                </div>
            ) : null}
            <div className="cabinet-drop-container">
                <div
                    className="se-location-dnd-drop-zone"
                    onDrop={
                        isAdmin &&
                        (event => {
                            dropZoneDepth = 0; // reset drop zone depth counter
                            locationDropHandler(event, cabinets, setCabinetLocation, updateLocationParent);
                        })
                    }
                    onDragOver={isAdmin && (event => locationDragoverHandler(event, dropZoneDepth))}
                    onDragEnter={
                        isAdmin &&
                        (event => {
                            dropZoneDepth = locationDropZoneEnter(event, dropZoneDepth);
                        })
                    }
                    onDragLeave={
                        isAdmin &&
                        (event => {
                            dropZoneDepth = locationDropZoneExit(event, dropZoneDepth);
                        })
                    }
                    data-locationid={location.id}
                    onMouseEnter={isAdmin && (() => handleShowLocationEditIcon(location))}
                    onMouseLeave={isAdmin && (() => setShowLocationEdit(false))}
                >
                    <div
                        draggable={isAdmin && location.id ? 'true' : 'false'}
                        data-locationid={location.id}
                        className="draggable-location"
                        onDragStart={isAdmin && (event => locationDragStartHandler(event, cabinetSearchQueryUpdated))}
                        onDragEnd={
                            isAdmin &&
                            (event => {
                                dropZoneDepth = 0; // reset drop zone depth counter
                                locationDragEndHandler(event);
                            })
                        }
                    >
                        <div className="d-flex align-items-center">
                            <Icon className="location-icon">location_on</Icon>
                            <span className="flex-fill">
                                <div className="location-name-editable">
                                    {startEditingLocation ? (
                                        <Input
                                            placeholder={location.name}
                                            value={locationEditValue}
                                            size="sm"
                                            className="location-name-edit-input"
                                            onChange={event => {
                                                setLocationEditValue(event.target.value);
                                            }}
                                            invalid={isLocationNameShort || locationNameDuplicated}
                                            helpText={
                                                isLocationNameShort
                                                    ? t('invalidLocationNameTooShort')
                                                    : locationNameDuplicated
                                                    ? t('invalidLocationNameDuplicated')
                                                    : null
                                            }
                                        />
                                    ) : (
                                        location.name
                                    )}
                                    {startEditingLocation ? (
                                        <div className="edit-location-icons">
                                            <Icon
                                                className={
                                                    isLocationNameShort || locationNameDuplicated
                                                        ? 'location-edit-confirm-icon-disable'
                                                        : 'location-edit-confirm-icon-enable'
                                                }
                                                style={{ cursor: 'pointer' }}
                                                onClick={() => !(isLocationNameShort || locationNameDuplicated) && handleChangeLocationName()}
                                            >
                                                check
                                            </Icon>

                                            <Icon
                                                className="location-edit-cancel-icon"
                                                style={{ cursor: 'pointer' }}
                                                onClick={() => cancelEditingLocationName()}
                                            >
                                                clear
                                            </Icon>
                                        </div>
                                    ) : (
                                        isAdmin && (
                                            <Icon
                                                style={{ cursor: 'pointer' }}
                                                className={`ml-2 location-edit-start-icon ${showLocationEdit ? '' : 'se-hide-important'}`}
                                                onClick={() => startEditingLocationName(location)}
                                            >
                                                edit
                                            </Icon>
                                        )
                                    )}
                                </div>
                            </span>

                            <div className="se-location-cabinets-count-tag">{locationDevicesCount}</div>

                            {isAdmin && locationDevices.length === 0 && location.children.length === 0 && location.id != null ? (
                                <svg className="remove-location-tag" onClick={() => startRemoveLocationPrompt(location)}>
                                    <use xlinkHref={`${icons}#icon-delete`}></use>
                                </svg>
                            ) : null}
                        </div>
                    </div>
                    <ul>
                        {locationDevices.map(device => (
                            <li
                                key={device.deviceCode}
                                draggable={isAdmin ? 'true' : 'false'}
                                className={
                                    device.deviceCode === selectedCabinetDeviceCode || device.deviceCode === selectedEncodingDeviceCode
                                        ? 'draggable-cabinet se-list-item device-list se-list-item-active'
                                        : 'draggable-cabinet se-list-item device-list'
                                }
                                onClick={() => {
                                    selectDevice({
                                        value: device.deviceCode,
                                        id: device.id,
                                        type: device.deviceType,
                                    });
                                }}
                                data-devicecode={device.deviceCode}
                                onDragStart={isAdmin && (event => cabinetDragStartHandler(event, cabinetSearchQueryUpdated))}
                                onDragEnd={
                                    isAdmin &&
                                    (event => {
                                        dropZoneDepth = 0; // reset drop zone depth counter
                                        cabinetDragEndHandler(event);
                                    })
                                }
                            >
                                <DeviceItem
                                    isCabinetNameDuplicated={isCabinetNameDuplicated}
                                    device={device}
                                    updateCabinetName={updateCabinetName}
                                    t={t}
                                    isAdmin={isAdmin}
                                    alarms={alarms}
                                />
                            </li>
                        ))}
                    </ul>
                </div>
                <div className="nested-locations-container">{getChildLocations()}</div>
            </div>
        </div>
    );
}

export default CabinetLocationDragDropList;
