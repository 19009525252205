import React, { useRef, useState, useEffect } from 'react';
import { Checkbox, Icon } from '@storaensods/seeds-react';
import { Popover, PopoverBody } from 'reactstrap';

/**
 * Multiselect component can handle selection and its sub-select options
 * if filter is provided, it looks for the filter to add as sub-options
 * otherwise filters are ignored
 * {members:[], filters:[]}
 */

const MultiSelect = ({ members, label, name, selectionChange, selected, info, t, searchable = false, ...otherProps }) => {
    const componentRef = useRef(null);
    const componentOnFocus = useComponentOnFocus(componentRef);
    const [isMultiSelectOpen, setIsMultiSelectOpen] = useState(false);
    const [infoPopOverOpen, setInfoPopOverOpen] = useState(false);
    const [searchText, setSearchText] = useState('');

    useEffect(() => {
        // Ensure componentOnFocus and isMultiSelectOpen stay in sync
        if (componentOnFocus === false) {
            setIsMultiSelectOpen(false);
        }

        setSearchText('');
    }, [componentOnFocus]);

    const toggleMultiSelect = () => {
        setIsMultiSelectOpen(!isMultiSelectOpen);
        setSearchText('');
    };

    const toggleInfoPopOver = () => {
        setInfoPopOverOpen(!infoPopOverOpen);
    };

    const onSelectionChange = (checked, selection) => {
        let selectedMembers = [...selected.members];

        if (checked) {
            if (name === 'locations') {
                const childLocations = [];
                getChildLocations(selection, childLocations);

                // Combine the selection with the children found and ensures duplicate children do not get added
                selectedMembers = [...new Set([...selectedMembers, ...childLocations])];
            }

            if (!selectedMembers.includes(selection.value)) {
                selectedMembers.push(selection.value);
            }
            selectionChange({ ...selected, members: selectedMembers });
        } else {
            const selectedMemberIndex = selected.members.indexOf(selection.value);
            selectedMembers.splice(selectedMemberIndex, 1);

            //Also remove any associated filters for this
            const selectedFilters = [...selected.filters];
            const allmembers = [...members];
            const selectedMemberObject = allmembers.find(member => member.value === selection.value);

            const newFilters = [];
            if (selectedMemberObject && selectedMemberObject.filters) {
                selectedFilters.forEach(filter => {
                    const hasFilter = selectedMemberObject.filters.dimension === filter.dimension;
                    // if the member is unchecked, unselect all of its filters
                    if (!hasFilter) {
                        newFilters.push(filter);
                    }
                });
            }
            selectionChange({ filters: newFilters, members: selectedMembers });
        }
    };

    const onFiltersChange = (checked, selection, filter) => {
        const selectedFilters = [...selected.filters];
        const currentFilter = selectedFilters.find(item => item.dimension === selection.dimension);

        if (checked) {
            if (!currentFilter) {
                selectedFilters.push({
                    dimension: selection.dimension,
                    operator: selection.operator,
                    values: [filter.value],
                });
            } else {
                if (!currentFilter.values.includes(filter.value)) {
                    currentFilter.values.push(filter.value);
                }
            }
        } else {
            const currentValueIndex = currentFilter.values.indexOf(filter.value);
            if (currentValueIndex !== -1) {
                currentFilter.values.splice(currentValueIndex, 1);
            }
        }
        selectionChange({ ...selected, filters: selectedFilters });
    };

    // This is only used for the searchable select bars, cabinets and locations
    const filteredMembers = members.filter(member => member.label.toLowerCase().includes(searchText.toLowerCase()));

    const getChildLocations = (selectedLocation, childLocations) => {
        const children = members.filter(location => location.parentLocationCode === selectedLocation.value);

        children.forEach(child => {
            childLocations.push(child.value);
            getChildLocations(child, childLocations);
        });
    };

    return (
        <div className="multi-select" ref={componentRef}>
            <label className="multi-select__title">{label}</label>
            {info && (
                <label className="multi-select__info ml-2" id={`info-${name}`}>
                    <Icon>info_outline</Icon>
                    <Popover
                        className="multi-select__popover"
                        isOpen={infoPopOverOpen}
                        target={`info-${name}`}
                        toggle={toggleInfoPopOver}
                        placement="right"
                        trigger="hover click focus"
                    >
                        <PopoverBody className="multi-select__popover">{info}</PopoverBody>
                    </Popover>
                </label>
            )}
            <div className="multi-select__label" onClick={toggleMultiSelect}>
                <span className="multi-select__label-text">
                    {searchable ? (
                        <input
                            className="multi-select__label-text-input"
                            type="text"
                            value={componentOnFocus ? searchText : selected.members.length === 0 ? t('select') : `${selected.members.length} ${t('selected')}`}
                            onChange={e => {
                                setIsMultiSelectOpen(true);
                                setSearchText(e.target.value);
                            }}
                        />
                    ) : (
                        <>
                            {selected.members.length === 0 ? (
                                t('select')
                            ) : (
                                <>
                                    {selected.members.length} {t('selected')}
                                </>
                            )}
                        </>
                    )}
                </span>
                {selected.members.length !== 0 ? (
                    <i
                        className="material-icons se-icon multi-select__label-clear"
                        style={{ cursor: 'pointer' }}
                        onClick={e => selectionChange({ ...selected, members: [] })}
                    >
                        clear
                    </i>
                ) : null}
                <span className="multi-select__label-icon">&#x025BE;</span>
            </div>
            <div className={`multi-select__container ${isMultiSelectOpen && componentOnFocus ? 'multi-select__container--open' : ''}`}>
                <ul className="multi-select__options" style={{ zIndex: otherProps.reportViewPageZIndex ? 2 : null }}>
                    {filteredMembers.map(member => {
                        return (
                            <li className="muli-select__options-list" key={member.value}>
                                <Checkbox
                                    id={member.value}
                                    name={name}
                                    size="md"
                                    onChange={event => {
                                        onSelectionChange(event.target.checked, member);
                                    }}
                                    checked={selected.members.includes(member.value)}
                                >
                                    {member.label}
                                </Checkbox>
                                {Object.keys(member).indexOf('filters') !== -1 && (
                                    <ul className={`multi-select__filters ${selected.members.includes(member.value) ? 'multi-select__filters--open' : ''}`}>
                                        {member.filters.values.map(filter => {
                                            return (
                                                <li className="multi-select__filters-list" key={filter.value}>
                                                    <Checkbox
                                                        id={filter.value.toString()}
                                                        onChange={event => {
                                                            onFiltersChange(event.target.checked, member.filters, filter);
                                                        }}
                                                        size="sm"
                                                        checked={selected.filters.find(s => s.values.includes(filter.value))}
                                                    >
                                                        {filter.label}
                                                    </Checkbox>
                                                </li>
                                            );
                                        })}
                                    </ul>
                                )}
                            </li>
                        );
                    })}
                </ul>
            </div>
        </div>
    );
};

/*
 * Component to check if mouse click
 * is outside dropedown compoenent
 */
const useComponentOnFocus = ref => {
    const [focus, setFocus] = useState(false);
    useEffect(() => {
        const handleClickOutside = event => {
            if (ref.current && !ref.current.contains(event.target)) {
                setFocus(false);
            } else {
                setFocus(true);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
    }, [ref]);
    return focus;
};

export default MultiSelect;
