import React from 'react';
import { Spinner } from '@storaensods/seeds-react';
import { Alert } from 'reactstrap';

/**
 * Informs user that data is being fetched from backend
 * @param {object} props Inside props object
 * @param {boolean} props.isError Inside props object
 * @param {boolean}  props.noData Inside props object
 * @param {string}  props.fetchingMessage Inside props object
 * @param {string}  props.errorMessage Inside props object
 * @param {string}  props.noDataMessage Inside props object
 */
export default function FetchingAlert({ fetchingMessage, errorMessage, noDataMessage, isError, noData }) {
    let message = null;
    let colorClass = null;
    let addSpinner = false;
    let iconName = '';

    if (isError) {
        colorClass = 'danger';
        iconName = 'error_outline';
        message = errorMessage;
    } else if (noData) {
        colorClass = 'warning';
        iconName = 'warning';
        message = noDataMessage;
    } else {
        colorClass = 'light';
        addSpinner = true;
        message = fetchingMessage;
    }

    return (
        <Alert color={colorClass} className="float-left">
            <div className="h-100 d-flex align-items-center">
                <i className="material-icons se-icon">{iconName}</i> {addSpinner && <Spinner />}
                &nbsp;&nbsp;{message}
            </div>
        </Alert>
    );
}
