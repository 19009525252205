export const storeUserNotification = notification => {
    const currentNotifications = window.localStorage.getItem('userNotifications') ? JSON.parse(window.localStorage.getItem('userNotifications')) : null;
    if (currentNotifications) {
        window.localStorage.setItem('userNotifications', JSON.stringify([...currentNotifications, notification]));
    } else window.localStorage.setItem('userNotifications', JSON.stringify([notification]));
};

export const getUserNotifications = () => {
    return JSON.parse(window.localStorage.getItem('userNotifications'));
};

export const setNotificationsUnSeenStatus = status => {
    window.localStorage.setItem('userNotificationsUnSeen', status);
    return status;
};

export const getNotificationUnSeenStatus = () => {
    const status = window.localStorage.getItem('userNotificationsUnSeen');
    if (status === 'true') return true;
    if (status === 'false') return false;
    if (!status) return true;
};
