import Oidc from 'oidc-client';
import { store } from './index.js';
import { redirectToAuthorizationPage } from './actions/user.js';

const mgr = new Oidc.UserManager({
    authority: process.env.REACT_APP_AUTH_URL, // replace with real auth link based on environment
    client_id: 'scp',
    redirect_uri: `${window.location.origin}/callback`, // created callback file to finalize open ID loop
    response_type: 'id_token token',
    scope: 'openid profile internal_credential',
    post_logout_redirect_uri: window.location.origin, // get current URL
});

mgr.startSilentRenew();

mgr.events.addAccessTokenExpired(() => {
    store.dispatch(redirectToAuthorizationPage('signInExpiredError'));
});

// export user manager
export default mgr;
