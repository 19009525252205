const initState = {
    fetched: false,
    isFetching: false,
    isError: false,
    cabinets: [],
    searchQuery: null,
    selectedCabinetDeviceCode: null,
    selectedCabinetDeviceId: null,
    locations: [],
    locationsFetched: false,
    locationsIsFetching: false,
    locationsIsError: false,
    creatingLocation: null,
    locationsTree: [],
    refillRule: null,
    refillRuleFetched: false,
    refillRuleFetching: false,
    refillRuleError: null,
};

function treeifyLocations(rawData) {
    // tree format data will be built to this variable (starting with root parents)
    const rootParents = rawData.filter(entry => !entry.parentLocationCode);

    // store all found ids here
    let remainingData = rawData.filter(entry => entry.parentLocationCode);

    const getNodeWithChildren = parent => {
        // find children and remove them from remaining data
        const foundRawData = remainingData.filter(entry => entry.parentLocationCode === parent.code);
        remainingData = remainingData.filter(entry => entry.parentLocationCode !== parent.code);

        return {
            ...parent,
            children: foundRawData.map(entry => getNodeWithChildren(entry)),
        };
    };

    // root parents with children
    const rootParentsWithChildren = rootParents.map(rootParent => getNodeWithChildren(rootParent));

    // add remaining data whose parents were not found as root parents with no children
    const nodesWithMissingParents = remainingData.map(entry => ({ ...entry, children: [] }));

    return [...rootParentsWithChildren, ...nodesWithMissingParents];
}

export default function inventory(state = initState, action) {
    switch (action.type) {
        case 'REQUEST_CABINETS':
            return {
                ...state,
                isFetching: true,
            };
        case 'RECEIVE_CABINETS':
            return {
                ...state,
                isFetching: false,
                isError: false,
                fetched: true,
                cabinets: action.cabinets,
                lastUpdated: action.receivedAt,
                selectedCabinetDeviceCode: action.selectedCabinetDeviceCode || null,
                selectedCabinetDeviceId: action.selectedCabinetDeviceId || null,
            };
        case 'RECEIVE_CABINETS_ERROR':
            console.error(action.error);
            return {
                ...state,
                isFetching: false,
                isError: true,
                fetched: true,
                error: action.error.toString(),
            };
        case 'SELECT_CABINET':
            return {
                ...state,
                selectedCabinetDeviceCode: action.selectedCabinetDeviceCode,
                selectedCabinetDeviceId: action.selectedCabinetDeviceId,
            };
        case 'RESET_CABINETS':
            return {
                ...initState,
            };
        case 'REQUEST_SET_CABINET_LOCATION':
            return {
                ...state,
                cabinets: state.cabinets.map(cabinet => {
                    if (cabinet.deviceCode === action.deviceCode) {
                        return Object.assign({}, cabinet, {
                            locationId: action.locationId,
                            movingFromLocation: cabinet.locationId,
                        });
                    } else {
                        return cabinet;
                    }
                }),
            };
        case 'SET_CABINET_LOCATION_SUCCESS':
            return {
                ...state,
                cabinets: state.cabinets.map(cabinet => {
                    if (cabinet.deviceCode === action.deviceCode) {
                        const newCabinet = Object.assign({}, cabinet);
                        delete newCabinet['movingFromLocation'];
                        return newCabinet;
                    } else {
                        return cabinet;
                    }
                }),
            };
        case 'SET_CABINET_LOCATION_ERROR':
            console.error(action.error);
            return {
                ...state,
                cabinets: state.cabinets.map(cabinet => {
                    if (cabinet.deviceCode === action.deviceCode) {
                        // move cabinet back to original location
                        const newCabinet = Object.assign({}, cabinet, {
                            locationId: cabinet.movingFromLocation,
                        });
                        delete newCabinet['movingFromLocation'];
                        return newCabinet;
                    } else {
                        return cabinet;
                    }
                }),
            };
        case 'CABINET_SEARCH_QUERY_UPDATED': {
            // search query defines that we are searching cabinets by it
            // name, ID, code
            return {
                ...state,
                searchQuery: action.searchQuery,
            };
        }
        case 'REQUEST_LOCATIONS':
            return {
                ...state,
                locationsIsFetching: true,
            };
        case 'RECEIVE_LOCATIONS':
            return {
                ...state,
                locationsIsFetching: false,
                locationsIsError: false,
                locationsFetched: true,
                locations: action.locations,
                lastUpdated: action.receivedAt,
                locationsTree: treeifyLocations(action.locations),
            };
        case 'RECEIVE_LOCATIONS_ERROR':
            console.error(action.error);
            return {
                ...state,
                locationsIsFetching: false,
                locationsIsError: true,
                locationsFetched: true,
                error: action.error.toString(),
                locationsTree: [],
            };
        case 'REQUEST_CREATE_LOCATION':
            return {
                ...state,
                creatingLocation: {
                    id: action.locationId,
                    code: action.locationId,
                    name: action.locationName,
                    parentLocationCode: '',
                },
            };
        case 'CREATE_LOCATION_SUCCESS':
            return {
                ...state,
                locations: [...state.locations, state.creatingLocation],
                locationsTree: treeifyLocations([...state.locations, state.creatingLocation]),
                creatingLocation: null,
            };
        case 'CREATE_LOCATION_ERROR':
            console.error(action.error);
            return {
                ...state,
                isFetching: false,
                isError: true,
                fetched: true,
                creatingLocation: null,
                error: 'Error creating location',
            };
        case 'REQUEST_RETIRE_LOCATION':
            return {
                ...state,
                locations: state.locations.filter(location => location.code !== action.locationCode),
                locationsTree: treeifyLocations(state.locations.filter(location => location.code !== action.locationCode)),
            };
        case 'RETIRE_LOCATION_ERROR':
            console.error(action.error);
            return {
                ...state,
                isFetching: false,
                isError: true,
                fetched: true,
                creatingLocation: null,
                error: 'Error retiring location',
            };
        case 'CLEAR_STATE_ERROR':
            return {
                ...state,
                isError: false,
                error: '',
            };
        case 'REQUEST_UPDATE_LOCATION': {
            const newLocations = state.locations;
            const updatedIndex = state.locations.findIndex(location => location.code === action.locationCode);
            newLocations[updatedIndex] = Object.assign(newLocations[updatedIndex], action.updateFields);
            return {
                ...state,
                locations: newLocations,
                locationsTree: treeifyLocations(newLocations),
            };
        }
        case 'UPDATE_LOCATION_ERROR':
            console.error(action.error);
            return {
                ...state,
                isFetching: false,
                isError: true,
                fetched: true,
                creatingLocation: null,
                error: 'Error updating location',
            };

        case 'REQUEST_UPDATE_CABINET_NAME': {
            // let cabinets = state.cabinets;
            const cabinets = state.cabinets.map(cabinet => {
                if (cabinet.deviceCode === action.deviceCode) {
                    cabinet.name = action.name;
                }
                return cabinet;
            });

            return {
                ...state,
                cabinets: cabinets,
            };
        }
        case 'UPDATE_CABINET_NAME_ERROR':
            console.error(action.error);
            return {
                ...state,
                isFetching: false,
                isError: true,
                fetched: true,
                error: 'Error updating location',
            };

        default:
            return state;
    }
}
