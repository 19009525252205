import {
    REQUEST_VAT_CATEGORIES,
    RECEIVE_VAT_CATEGORIES,
    RECEIVE_VAT_CATEGORIES_ERROR,
    REQUEST_CREATE_VAT_CATEGORY,
    RECEIVE_CREATE_VAT_CATEGORY,
    RECEIVE_CREATE_VAT_CATEGORY_ERROR,
    REQUEST_UPDATE_VAT_CATEGORY,
    RECEIVE_UPDATE_VAT_CATEGORY,
    RECEIVE_UPDATE_VAT_CATEGORY_ERROR,
    REQUEST_DELETE_VAT_CATEGORY,
    RECEIVE_DELETE_VAT_CATEGORY,
    RECEIVE_DELETE_VAT_CATEGORY_ERROR,
    DISMISS_VAT_CATEGORY_RESULT,
    RESET_VAT_CATEGORIES,
    SEARCH_VAT_CATEGORY_QUERY,
} from '../actions/vatCategories.js';

export const initState = {
    isFetching: false,
    isError: false,
    isRequesting: false,
    isRequestingFinished: false,
    isDeletingFailed: false,
    vatCategories: null,
    searchQuery: '',
};

export default function vatCategories(state = initState, action) {
    switch (action.type) {
        case REQUEST_VAT_CATEGORIES:
            return {
                ...state,
                isFetching: true,
                isError: false,
            };
        case RECEIVE_VAT_CATEGORIES:
            return {
                ...state,
                isFetching: false,
                isError: false,
                vatCategories: action.vatCategories,
                lastUpdate: action.receivedAt,
            };
        case RECEIVE_VAT_CATEGORIES_ERROR:
            console.log(action.error);
            return {
                ...state,
                isFetching: false,
                isError: true,
                error: action.error.toString(),
            };
        case REQUEST_CREATE_VAT_CATEGORY:
            return {
                ...state,
                isRequesting: true,
                isRequestingFinished: false,
                error: '',
            };
        case RECEIVE_CREATE_VAT_CATEGORY:
            return {
                ...state,
                isRequesting: false,
                isRequestingFinished: true,
                error: '',
                vatCategories: [action.vatCategory, ...state.vatCategories],
            };
        case RECEIVE_CREATE_VAT_CATEGORY_ERROR:
            console.log(action.error);
            return {
                ...state,
                isRequesting: false,
                isRequestingFinished: true,
                isError: true,
                error: action.error.toString(),
            };

        case REQUEST_UPDATE_VAT_CATEGORY:
            return {
                ...state,
                isRequesting: true,
                isRequestingFinished: false,
                error: '',
            };
        case RECEIVE_UPDATE_VAT_CATEGORY:
            return {
                ...state,
                isRequesting: false,
                isRequestingFinished: true,
                error: '',
                vatCategories: [action.vatCategory, ...state.vatCategories.filter(vatCategory => vatCategory.id !== action.vatCategory.id)],
            };
        case RECEIVE_UPDATE_VAT_CATEGORY_ERROR:
            console.log(action.error);
            return {
                ...state,
                isRequesting: false,
                isRequestingFinished: true,
                isError: true,
                error: action.error.toString(),
            };

        case REQUEST_DELETE_VAT_CATEGORY:
            return {
                ...state,
                isRequesting: true,
                isRequestingFinished: false,
                error: '',
            };
        case RECEIVE_DELETE_VAT_CATEGORY:
            return {
                ...state,
                isRequesting: false,
                isRequestingFinished: true,
                error: '',
                vatCategories: [...state.vatCategories.filter(vatCategory => vatCategory.id !== action.id)],
            };
        case RECEIVE_DELETE_VAT_CATEGORY_ERROR:
            console.log(action.error);
            return {
                ...state,
                isRequesting: false,
                isRequestingFinished: true,
                isError: true,
                isDeletingFailed: true,
                error: action.error.toString(),
            };
        case DISMISS_VAT_CATEGORY_RESULT:
            return {
                ...state,
                isRequesting: false,
                isRequestingFinished: false,
                isError: false,
                isDeletingFailed: false,
                error: '',
            };
        case RESET_VAT_CATEGORIES:
            return {
                ...initState,
            };
        case SEARCH_VAT_CATEGORY_QUERY:
            return {
                ...state,
                searchQuery: action.query,
            };
        default:
            return state;
    }
}
