const initState = {
    error: null,
    alarms: [],
    isFetching: false,
    fetched: false,
    test: 'i dont know',
};

const alarms = (state = initState, action) => {
    switch (action.type) {
        case 'REQUEST_ALARMS':
            return {
                ...state,
                isFetching: true,
                fetched: false,
            };
        case 'RECEIVE_ALARMS':
            return {
                isFetching: false,
                alarms: action.alarms,
                error: null,
                fetched: true,
            };
        case 'REQUEST_ALARMS_ERRORS':
            return {
                isFetching: false,
                alarms: [],
                error: action.error,
                fetched: false,
            };
        default:
            return state;
    }
};

export default alarms;
