import { NavigationBar, TabItem } from '@storaensods/seeds-react';
import React from 'react';
import { useHistory, withRouter } from 'react-router-dom';
import { storeRedirectPath } from '../../localStorage.js';

import { connect } from 'react-redux';
import { translate } from 'react-i18next';

export function Navigation(props) {
    const history = useHistory();
    const { t, user, location } = props;
    function isActivePath(path) {
        return history.location.pathname === path;
    }

    function isVisibleToUser(user, readOnly = false, refillManagementPage=false) {
        const roles = user.profile.roles;
        let allowedRolesForVisibility = ['Cabinet Operator', 'Administrator'];
        if(refillManagementPage){
            allowedRolesForVisibility.push('Refill Operator')
        }
        if (readOnly) {
            allowedRolesForVisibility.push('Reporting Operator');
        }
        const visibility = (Array.isArray(roles) ? roles.filter(role => allowedRolesForVisibility.includes(role)) : []).length > 0;
        return visibility;
    }

    function redirectTo(path) {
        history.push(path);
        storeRedirectPath(path);
    }

    return (
        <div className="scp-navigation-bar">
            {!location.pathname.includes('settings-consumer-app') && !location.pathname.includes('software-versions') && !location.pathname.includes('automations') && (
                <NavigationBar>
                    {isVisibleToUser(user, true) && (
                        <TabItem onClick={redirectTo.bind(this, '/analytics')} isActive={isActivePath.call(this, '/analytics')}>
                            <span>{t('analytics')}</span>
                        </TabItem>
                    )}
                    <TabItem onClick={redirectTo.bind(this, '/devices')} isActive={isActivePath.call(this, '/devices')}>
                        <span>{t('deviceManagement')}</span>
                    </TabItem>
                    {isVisibleToUser(user, true) && (
                        <TabItem onClick={redirectTo.bind(this, '/advertisements')} isActive={isActivePath.call(this, '/advertisements')}>
                            <span>{t('advertisements')}</span>
                        </TabItem>
                    )}
                    <TabItem onClick={redirectTo.bind(this, '/products')} isActive={isActivePath.call(this, '/products')}>
                        <span>{t('productCatalog')}</span>
                    </TabItem>
                    {isVisibleToUser(user, true) && (
                        <TabItem onClick={redirectTo.bind(this, '/discounts')} isActive={isActivePath.call(this, '/discounts')}>
                            <span>{t('discounts')}</span>
                        </TabItem>
                    )}
                    {isVisibleToUser(user, true, true) && (
                        <TabItem onClick={redirectTo.bind(this, '/refill-plans')} isActive={isActivePath.call(this, '/refill-plans')}>
                            <span>{t('refillManagement')}</span>
                        </TabItem>
                    )}
                    <TabItem onClick={() => window.open('https://community.selflystore.com', '_blank', 'noopener')} isActive={false}>
                        <span>{t('community')}</span>
                        <i className="ml-1 fas fa-external-link-alt"></i>
                    </TabItem>
                </NavigationBar>
            )}
        </div>
    );
}

export default withRouter(
    connect(
        state => ({
            user: state.user,
        }),
        {}
    )(translate('navigation')(Navigation))
);
