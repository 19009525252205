import React, { Component } from 'react';
import Toggle from 'react-toggle';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { Row, Col, Form, FormGroup, Label, Input, FormFeedback, Badge } from 'reactstrap';
import { Button, Card, Tag, Icon, Spinner, DatePicker, Modal } from '@storaensods/seeds-react';
import { fetchAds, updateAd, dismissUpdateAdResult, uploadAdImage, uploadMediaFile, uploadAdImageSuccess, deleteAd } from '../../actions/advertisements.js';
import { fetchCabinets, fetchLocations } from '../../actions/cabinets.js';
import ImageUpload from '../imageUpload/imageUpload.js';
import FetchingAlert from '../fetching/fetchingAlert.js';
import { showNotification } from '../toastNotification/toastNotification.js';
import './advertisements.css';

/**
 * List of advertisements
 * @param {array} ads
 */
function Ads({ ads, t, cabinets, locations, deleteAd, editAd, isAdmin }) {
    return ads.map(ad => {
        const split = ad.url.split('.');
        const mediaType = split[split.length - 1];
        const locationNames = ad.regions.map(locationId => {
            const location = locations.find(location => location.id === locationId);
            if (!location) {
                console.error(`Could not find location with id ${locationId}`);
                return locationId;
            }

            return location.name;
        });

        return (
            <div key={ad.url} className="mb-3">
                <Card body>
                    <Row>
                        <Col xs="12" md="6" lg="4">
                            {mediaType === 'mp4' && <video src={ad.url} controls style={{ width: '100%' }} />}
                            {mediaType === 'jpeg' && <img src={ad.url} alt="advertisements" className="img-thumbnail" style={{ width: '100%' }} />}
                        </Col>
                        <Col xs="12" md="6" lg="5">
                            <h6>
                                {ad.title || t('noTitle')} {ad.isActive ? <Tag type="positive">{t('active')}</Tag> : <Tag type="negative">{t('inActive')}</Tag>}
                            </h6>
                            <p>{ad.description || t('noDescription')}</p>
                            <div>
                                <h6>{t('regions')}</h6>
                                <p>
                                    <Icon>map</Icon>{' '}
                                    {ad.individualCabinetsOnly ? t('individualCabinetsOnly') : locationNames.length > 0 ? locationNames.join(', ') : t('all')}
                                </p>
                            </div>
                            <div>
                                <h6>{t('individualCabinets')}</h6>
                                <p>
                                    <Icon>kitchen</Icon>{' '}
                                    {ad.cabinets && ad.cabinets.length > 0
                                        ? ad.cabinets
                                              .map(cabinetId => {
                                                  const foundCabinet = cabinets.find(cabinet => cabinet.deviceCode === cabinetId);
                                                  if (foundCabinet) {
                                                      return foundCabinet.name;
                                                  } else {
                                                      return cabinetId;
                                                  }
                                              })
                                              .join(', ')
                                        : t('none')}
                                </p>
                            </div>
                        </Col>
                        <Col xs="12" lg="3" className="smart-cabinet-advertisements-side-container">
                            {isAdmin && (
                                <div className="mb-2 text-right">
                                    <Button className="mr-2" onClick={editAd.bind(this, ad)}>
                                        {t('edit')}
                                    </Button>
                                    <Button type="negative" onClick={deleteAd.bind(this, ad.id)}>
                                        {t('delete')}
                                    </Button>
                                </div>
                            )}
                            <div>
                                <h6>{t('activationDate')}</h6>
                                <p>
                                    <Icon>event</Icon> {ad.activationDate ? new Date(ad.activationDate).toLocaleDateString() : t('none')}
                                </p>
                            </div>
                            <div>
                                <h6>{t('deactivationDate')}</h6>
                                <p>
                                    <Icon>event</Icon> {ad.deactivationDate ? new Date(ad.deactivationDate).toLocaleDateString() : t('none')}
                                </p>
                            </div>
                        </Col>
                    </Row>
                </Card>
            </div>
        );
    });
}

function DeleteAdPrompt({ deleteAd, cancelDelete, adToDelete, t }) {
    return (
        <Modal
            actions={[
                {
                    label: 'Delete',
                    onClick: deleteAd.bind(this, adToDelete),
                },
                {
                    label: 'Cancel',
                    onClick: cancelDelete,
                },
            ]}
            active={true}
            onClose={cancelDelete}
        >
            {t('deleteAdConfirm')}
        </Modal>
    );
}

/**
 * React advertisement edit form component.
 * Can be used for creating or updating advertisements.
 * Result of create/update is also displayed by this component.
 *
 * @param {function} adRequest In props function to call with resulting advertisement
 * @param {object} adState In props state of advertisement create or update request
 * @param {function} dismissResult In props function called to dismiss request result from server
 * @param {function} uploadAdImageSuccess Action in props must be set when editing
 * @param {function} uploadAdImage Action in props to upload image
 * @param {function} removeAdImage Action in props to remove current image
 * @param {booelan} editing In props set to true if editing and not creating
 * @param {Product} ad In props must be set if editing
 * @param {array} locations
 * @param {array} cabinets
 */
export class EditAd extends Component {
    constructor(props) {
        super(props);
        
        this.editRef = React.createRef(null);

        this.state = {
            targetWidth: parseInt(process.env.REACT_APP_AD_IMAGE_WIDTH),
            targetHeight: parseInt(process.env.REACT_APP_AD_IMAGE_HEIGHT),
            ad: {
                title: '',
                description: '',
                activationDate: null,
                deactivationDate: null,
                noRegions: false,
                regions: [],
                cabinets: [],
                individualCabinetsOnly: false,
            },
            validationErrors: {},
            cropping: false,
            translations: {
                requesting: props.t('creatingAd'),
                errorRequesting: props.t('errorCreatingAd'),
                requestSuccess: props.t('createdAd'),
                title: props.t('createAdTitle'),
                submit: props.t('create'),
                cancel: props.t('cancel'),
                dismiss: props.t('dismiss'),
            },
        };
    }

    componentDidMount() {
        this.editRef.current.scrollIntoView({behavior:'smooth'});
        // Fill form with advertisement data if editing existing
        if (this.props.editing) {
            const split = this.props.ad.url.split('.');
            this.mediaType = split[split.length - 1] === 'mp4' ? 'video' : 'image';

            this.props.uploadAdImageSuccess(this.props.ad.imageUrl, this.props.ad.id);
            this.setState(prevState => ({
                ...prevState,
                ad: {
                    ...prevState.ad,
                    ...this.props.ad,
                },
                translations: {
                    requesting: this.props.t('updatingAd'),
                    errorRequesting: this.props.t('updateAdError'),
                    requestSuccess: this.props.t('updatedAd'),
                    title: this.props.t('updateAdTitle'),
                    submit: this.props.t('create'),
                    cancel: this.props.t('cancel'),
                    dismiss: this.props.t('dismiss'),
                    update: this.props.t('update'),
                },
            }));
        }
    }

    notifyMessage(title, text, isError) {
        showNotification(title, text, isError ? 'error' : 'success');
        this.props.dismissResult();
    }

    /**
     * Input onChange handler to update component state on change
     * @param {string} target State to update (title...)
     * @param {Event} event Html input change event
     */
    handleFormChange = (target, event) => {
        // value of input field
        const value = event.target.value;

        // update ad state
        this.setState(prevState => ({
            ...prevState,
            ad: {
                ...prevState.ad,
                [target]: value,
            },
        }));
    };

    /**
     * Form submit handler. Sends update/create advertisement request to backend if valid
     */
    submitForm() {
        const { t } = this.props;
        if (!this.state.ad.title) {
            return this.setState(prevState => ({
                ...prevState,
                validationErrors: {
                    ...prevState.validationErrors,
                    title: t('invalidTitle'),
                },
            }));
        }
        if (!this.props.adState.imageId) {
            return this.setState(prevState => ({
                ...prevState,
                validationErrors: {
                    ...prevState.validationErrors,
                    image: t('missingImage'),
                },
            }));
        }

        if (!this.props.editing) {
            this.props.adRequest(Object.assign({}, this.state.ad, { id: this.props.adState.imageId }));
        } else {
            this.props.adRequest(this.state.ad);
        }

        this.setState({
            ad: {
                title: '',
                description: '',
                activationDate: null,
                deactivationDate: null,
                noRegions: false,
                regions: [],
                cabinets: [],
                individualCabinetsOnly: false,
            },
        });
        this.props.dismissResult();
    }

    /**
     * Cancel create/update form
     */
    cancelForm = () => {
        // remove stored image if creating new advertisement
        if (!this.props.editing && this.props.adState.imageId) {
            this.props.removeAdImage(this.props.adState.imageId);
        }
        this.props.dismissResult();
        this.setState({
            ad: {
                title: '',
                description: '',
                activationDate: null,
                deactivationDate: null,
                noRegions: false,
                regions: [],
                cabinets: [],
                individualCabinetsOnly: false,
            },
            validationErrors: {},
        });
    };

    /**
     * Dismiss create/update result
     */
    dismissResult = () => {
        this.props.dismissResult();
    };

    /**
     * Toggle currently cropping to disable submitting while cropping
     */
    toggleCropping = () => {
        this.setState({
            cropping: !this.state.cropping,
        });
    };

    removeAdCreateFormImage = () => {
        const imageId = this.props.adState.imageId;
        this.props.removeAdImage(imageId);
    };

    toggleIndividualCabinetsOnly = () => {
        this.setState(prevState => ({
            ...prevState,
            ad: {
                ...prevState.ad,
                individualCabinetsOnly: !prevState.ad.individualCabinetsOnly,
            },
        }));
    };

    handleAddRegion = event => {
        // value of input field
        const region = event.target.value;

        if (region === '') return;

        // update ad state
        this.setState(prevState => ({
            ...prevState,
            ad: {
                ...prevState.ad,
                regions: [...prevState.ad.regions, region],
            },
        }));
    };

    handleAddCabinet = event => {
        // value of input field
        const cabinet = event.target.value;

        // update ad state
        this.setState(prevState => ({
            ...prevState,
            ad: {
                ...prevState.ad,
                cabinets: [...prevState.ad.cabinets, cabinet],
            },
        }));
    };

    removeRegion = regionToRemove => {
        this.setState(prevState => ({
            ...prevState,
            ad: {
                ...prevState.ad,
                regions: prevState.ad.regions.filter(region => region !== regionToRemove),
            },
        }));
    };

    removeCabinet = cabinetIdToRemove => {
        this.setState(prevState => ({
            ...prevState,
            ad: {
                ...prevState.ad,
                cabinets: prevState.ad.cabinets.filter(cabinet => cabinet !== cabinetIdToRemove),
            },
        }));
    };

    setActivationDate = date => {
        this.setState(prevState => ({
            ...prevState,
            ad: {
                ...prevState.ad,
                activationDate: date,
            },
        }));
    };

    clearActivationDate = () => {
        this.setState(prevState => ({
            ...prevState,
            ad: {
                ...prevState.ad,
                activationDate: null,
            },
        }));
    };

    setDeactivationDate = date => {
        this.setState(prevState => ({
            ...prevState,
            ad: {
                ...prevState.ad,
                deactivationDate: date,
            },
        }));
    };

    clearDeactivationDate = () => {
        this.setState(prevState => ({
            ...prevState,
            ad: {
                ...prevState.ad,
                deactivationDate: null,
            },
        }));
    };

    render() {
        const { adState, t } = this.props;
        // creating/updating in backend
        if (adState.requesting) {
            return (
                <Card title={this.state.translations.requesting}>
                    <div className="d-flex justify-content-center w-100">
                        <Spinner />
                    </div>
                </Card>
            );
        }

        // error in create/update request
        if (adState.finished && adState.error) {
            this.notifyMessage(t(this.state.translations.errorRequesting), adState.error, true);
            return null;
        }

        // created/updated in backend
        if (adState.finished) {
            this.notifyMessage(null, t(this.state.translations.requestSuccess), false);
            return null;
        }

        return (
            <div className="animated fadeInLeft" ref={this.editRef}>
                <Card title={this.state.translations.title} type="attention">
                    <Form>
                        <Row>
                            <Col lg="4">
                                <FormGroup>
                                    <Label for="adFormTitle">{t('title')}</Label>
                                    <Input
                                        type="text"
                                        name="title"
                                        id="adFormTitle"
                                        placeholder={t('title')}
                                        value={this.state.ad.title}
                                        onChange={this.handleFormChange.bind(this, 'title')}
                                        invalid={this.state.validationErrors.title}
                                    />
                                    <FormFeedback valid={false}>{this.state.validationErrors.name}</FormFeedback>
                                </FormGroup>
                            </Col>
                            <Col xs="12">
                                <FormGroup>
                                    <Label for="adFormDescription">{t('description')}</Label>
                                    <Input
                                        type="textarea"
                                        name="description"
                                        id="adFormDescription"
                                        placeholder={t('description')}
                                        value={this.state.ad.description}
                                        onChange={this.handleFormChange.bind(this, 'description')}
                                    />
                                </FormGroup>
                            </Col>
                            <Col xs="12" lg="6">
                                <DatePicker
                                    label={t('activationDate')}
                                    placeholderText={t('selectDate')}
                                    selected={this.state.ad.activationDate ? new Date(this.state.ad.activationDate) : null}
                                    onChange={date => (date ? this.setActivationDate(date.toISOString()) : this.setActivationDate(null))}
                                    className="d-inline-block"
                                />
                                {this.state.ad.activationDate != null ? (
                                    <Button onClick={() => this.clearActivationDate()} className="ml-2 mt-1">
                                        <i className="material-icons se-icon">remove_circle_outline</i>
                                    </Button>
                                ) : null}
                            </Col>
                            <Col xs="12" lg="6">
                                <DatePicker
                                    label={t('deactivationDate')}
                                    placeholderText={t('selectDate')}
                                    selected={this.state.ad.deactivationDate ? new Date(this.state.ad.deactivationDate) : null}
                                    onChange={date => (date ? this.setDeactivationDate(date.toISOString()) : this.setDeactivationDate(null))}
                                    className="d-inline-block"
                                />
                                {this.state.ad.deactivationDate != null ? (
                                    <Button onClick={() => this.clearDeactivationDate()} className="ml-2 mt-1">
                                        <i className="material-icons se-icon">remove_circle_outline</i>
                                    </Button>
                                ) : null}
                            </Col>
                            <Col xs="12 mt-3">
                                <div className="se-checkbox se-checkbox--md mb-0">
                                    <input
                                        type="checkbox"
                                        id="individual-cabinets-only-checkbox"
                                        checked={this.state.ad.individualCabinetsOnly}
                                        onChange={this.toggleIndividualCabinetsOnly}
                                    />
                                    <label htmlFor="individual-cabinets-only-checkbox">
                                        <span className="se-checkbox-label">{t('individualCabinetsOnly')}</span>
                                    </label>
                                </div>
                            </Col>
                            {!this.state.ad.individualCabinetsOnly ? (
                                <Col xs="12" lg="6">
                                    <FormGroup>
                                        <div>
                                            <Label>{t('regions')}</Label>
                                        </div>
                                        {this.state.ad.regions.length > 0 ? (
                                            this.state.ad.regions.map(region => (
                                                <Badge key={region} className="se-ads-form-badge" color="primary">
                                                    {this.props.locations.find(location => location.id === region)
                                                        ? this.props.locations.find(location => location.id === region).name
                                                        : region}{' '}
                                                    <i
                                                        className="material-icons se-icon ml-1"
                                                        style={{ cursor: 'pointer' }}
                                                        onClick={this.removeRegion.bind(this, region)}
                                                    >
                                                        cancel
                                                    </i>
                                                </Badge>
                                            ))
                                        ) : (
                                            <Badge className="se-ads-form-badge" color="warning">
                                                {t('allLocations')}
                                            </Badge>
                                        )}
                                        <Input type="select" name="type" value="" onChange={this.handleAddRegion.bind(this)}>
                                            <option key="" value="" />
                                            {this.props.locations
                                                .filter(location => !this.state.ad.regions.includes(location.id))
                                                .map(location => (
                                                    <option key={location.id} value={location.id}>
                                                        {location.name}
                                                    </option>
                                                ))}
                                        </Input>
                                    </FormGroup>
                                </Col>
                            ) : null}
                            <Col xs="12" lg={this.state.ad.individualCabinetsOnly ? '12' : '6'}>
                                <FormGroup>
                                    <div>
                                        <Label>{t('individualCabinets')}</Label>
                                    </div>
                                    {this.state.ad.cabinets.length > 0 ? (
                                        this.state.ad.cabinets.map(cabinetId => (
                                            <Badge className="se-ads-form-badge" key={cabinetId} color="primary">
                                                {this.props.cabinets.find(cabinet => cabinet.deviceCode === cabinetId)
                                                    ? this.props.cabinets.find(cabinet => cabinet.deviceCode === cabinetId).name
                                                    : cabinetId}{' '}
                                                <i
                                                    className="material-icons se-icon ml-1"
                                                    style={{ cursor: 'pointer' }}
                                                    onClick={this.removeCabinet.bind(this, cabinetId)}
                                                >
                                                    cancel
                                                </i>
                                            </Badge>
                                        ))
                                    ) : (
                                        <Badge color="warning" className="se-ads-form-badge">
                                            {t('noIndividualCabinets')}
                                        </Badge>
                                    )}
                                    <Input type="select" name="type" value="" onChange={this.handleAddCabinet.bind(this)}>
                                        <option key="" value="" />
                                        {this.props.cabinets
                                            .filter(cabinet => !this.state.ad.cabinets.includes(cabinet.deviceCode))
                                            .map(cabinet => (
                                                <option key={cabinet.deviceCode} value={cabinet.deviceCode}>
                                                    {cabinet.name}
                                                </option>
                                            ))}
                                    </Input>
                                </FormGroup>
                            </Col>
                            <Col xs="12">
                                {!this.props.editing ? (
                                    <FormGroup>
                                        <ImageUpload
                                            targetWidth={this.state.targetWidth}
                                            targetHeight={this.state.targetHeight}
                                            imageUrl={adState.imageUrl}
                                            storing={adState.imageStoring}
                                            uploadProgress={adState.uploadProgress}
                                            //uploadImage={this.props.uploadAdImage}
                                            uploadImage={this.props.uploadMedia}
                                            //uploadMedia={this.props.uploadMedia}
                                            removeImage={this.removeAdCreateFormImage.bind(this)}
                                            toggleCropping={this.toggleCropping}
                                            t={t}
                                            errorMsg={this.state.validationErrors.image}
                                        />
                                    </FormGroup>
                                ) : (
                                    <div className="mb-2" style={{ maxWidth: '800px' }}>
                                        {this.mediaType === 'video' && (
                                            <video
                                                style={{
                                                    objectFit: 'contain',
                                                    width: '50%',
                                                    maxHeight: '500px',
                                                }}
                                                controls
                                                src={this.state.ad.url}
                                            />
                                        )}
                                        {this.mediaType === 'image' && (
                                            <img
                                                style={{
                                                    objectFit: 'contain',
                                                    width: '50%',
                                                    maxHeight: '500px',
                                                }}
                                                src={this.state.ad.url}
                                                alt="advertisements"
                                            />
                                        )}
                                    </div>
                                )}
                            </Col>
                            <Col xs="6 text-left">
                                {!this.props.editing && (
                                    <>
                                        <div className="d-inline-block ml-2 mr-2">{'1920x850 '}</div>
                                        <Toggle
                                            className="d-inline-flex ml-2 mr-2"
                                            icons={false}
                                            checked={this.state.targetWidth === 1450}
                                            onChange={e =>
                                                this.setState({ targetWidth: e.target.checked ? 1450 : 1920, targetHeight: e.target.checked ? 1080 : 850 })
                                            }
                                        />
                                        <div className="d-inline-flex ml-2 mr-2">{' 1450x1080'}</div>
                                    </>
                                )}
                            </Col>
                            {this.state.cropping ? (
                                <Col xs="6 text-right">
                                    <Button className="mr-2" disabled>
                                        {this.state.translations.submit}
                                    </Button>
                                    <Button type="negative" disabled>
                                        {this.state.translations.cancel}
                                    </Button>
                                </Col>
                            ) : (
                                <Col xs="6 text-right">
                                    <Button onClick={this.submitForm.bind(this)} className="mr-2">
                                        {this.props.editing ? this.state.translations.update : this.state.translations.submit}
                                    </Button>
                                    <Button onClick={this.cancelForm} type="negative">
                                        {this.state.translations.cancel}
                                    </Button>
                                </Col>
                            )}
                        </Row>
                    </Form>
                </Card>
            </div>
        );
    }
}

/**
 * Advertisements page component
 * @param {function} fetchAds Redux action
 * @param {function} createAd Redux action
 * @param {function} dismissCreateAdResult Redux action
 * @param {function} updateAd Redux action
 * @param {function} dismissUpdateAdResult Redux action
 * @param {function} uploadAdImage Redux action
 * @param {function} uploadAdImageSuccess Redux action
 * @param {function} removeAdImage Redux action
 * @param {object} ads Redux ads state
 */
export class Advertisements extends Component {
    constructor(props) {
        super(props);
        this.state = {
            formOpen: false,
            editingAd: null,
            creatingAd: false,
            adStagedForDelete: null,
        };
    }

    componentDidMount() {
        // locations have not been fetched
        if (!this.props.cabinets.locationsFetched && !this.props.cabinets.locationsIsFetching) {
            this.props.fetchLocations();
        }

        // cabinets have not been fetched
        if (!this.props.cabinets.fetched && !this.props.cabinets.isFetching) {
            this.props.fetchCabinets();
        }

        this.props.fetchAds();
    }

    // open create ad form
    startCreateAd = () => {
        this.setState({
            creatingAd: true,
            formOpen: true,
        });
    };

    // open edit ad form and
    editAd = ad => {
        this.setState({
            editingAd: ad,
            formOpen: true,
        });
    };

    // close update ad result
    dismissUpdateAdResult = () => {
        this.props.dismissUpdateAdResult();
        this.setState({
            editingAd: null,
            formOpen: false,
            creatingAd: false,
        });
    };

    stageAdForDelete = ad => {
        this.setState({
            adStagedForDelete: ad,
        });
    };

    unstageAdForDelete = () => {
        this.setState({
            adStagedForDelete: null,
        });
    };

    deleteStagedAd = () => {
        this.props.deleteAd(this.state.adStagedForDelete);
        this.setState({
            adStagedForDelete: null,
        });
    };

    render() {
        const { t, ads, user } = this.props;

        const isAdmin = user && user.isAdmin;

        // locations have not been fetched
        if (!this.props.cabinets.locationsFetched || this.props.cabinets.locationsIsFetching) {
            return (
                <div>
                    <Spinner size="lg" />
                </div>
            );
        }

        // locations fetch error
        if (this.props.cabinets.locationsIsError) {
            return (
                <FetchingAlert
                    fetchingMessage={t('fetchAdvertisements')}
                    noDataMessage={t('noAdvertisements')}
                    errorMessage={t('advertisementsFetchError')}
                    isError={true}
                    noData={false}
                />
            );
        }

        return (
            <div className="container-fluid">
                {this.state.adStagedForDelete != null ? (
                    <DeleteAdPrompt deleteAd={this.deleteStagedAd} cancelDelete={this.unstageAdForDelete} adToDelete={this.state.adStagedForDelete} t={t}/>
                ) : null}
                <Row>
                    <Col>
                        <span className="float-left smart-cabinet-page-title se-display-3">{t('advertisementsTitle')}</span>
                    </Col>
                    {!this.state.formOpen && isAdmin ? (
                        <Col className="mb-2">
                            <Button onClick={this.startCreateAd} className="float-right mt-3" type="positive" icon="add_circle">
                                {t('addAdvertisement')}
                            </Button>
                        </Col>
                    ) : null}
                    {this.state.creatingAd ? (
                        <Col xs="12" className="mb-3">
                            <EditAd
                                t={t}
                                removeAdImage={this.props.deleteAd}
                                uploadAdImage={this.props.uploadAdImage}
                                uploadMedia={this.props.uploadMediaFile}
                                adState={this.props.ads}
                                adRequest={this.props.updateAd}
                                dismissResult={this.dismissUpdateAdResult}
                                editing={false}
                                regions={this.props.cabinets.regions}
                                locations={this.props.cabinets.locations}
                                cabinets={this.props.cabinets.cabinets}
                            />
                        </Col>
                    ) : null}
                    {this.state.editingAd ? (
                        <Col xs="12" className="mb-3">
                            <EditAd
                                t={t}
                                editing={true}
                                removeAdImage={this.props.deleteAd}
                                uploadAdImageSuccess={this.props.uploadAdImageSuccess}
                                uploadMedia={this.props.uploadAdvertisementMedia}
                                uploadAdImage={this.props.uploadAdImage}
                                ad={this.state.editingAd}
                                adState={ads}
                                adRequest={this.props.updateAd}
                                dismissResult={this.dismissUpdateAdResult}
                                locations={this.props.cabinets.locations}
                                cabinets={this.props.cabinets.cabinets}
                            />
                        </Col>
                    ) : null}
                </Row>

                {ads.fetched && !ads.isError && ads.ads.length !== 0 ? (
                    <Ads
                        isAdmin={isAdmin}
                        ads={ads.ads}
                        t={t}
                        cabinets={this.props.cabinets.cabinets}
                        locations={this.props.cabinets.locations}
                        deleteAd={this.stageAdForDelete}
                        editAd={this.editAd}
                    />
                ) : (
                    <FetchingAlert
                        fetchingMessage={t('fetchAdvertisements')}
                        noDataMessage={t('noAdvertisements')}
                        errorMessage={t('advertisementsFetchError')}
                        isError={ads.isError}
                        noData={ads.fetched && ads.ads.length === 0}
                    />
                )}
            </div>
        );
    }
}

export default withRouter(
    connect(state => ({ user: state.user, ads: state.advertisements, cabinets: state.cabinets }), {
        fetchAds,
        updateAd,
        dismissUpdateAdResult,
        uploadAdImage,
        uploadMediaFile,
        uploadAdImageSuccess,
        deleteAd,
        fetchCabinets,
        fetchLocations,
    })(translate('main')(Advertisements))
);
