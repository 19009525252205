const _13digitBarcode = (barcodeType, barcode) => {
    if (!barcode) return
    if (barcode.length === 13) return barcode;
    // always make the barcode having the length 13, if barcodeType missing
    if (!barcodeType) {
        return barcode.padStart(13, 0);
    }

    switch (barcodeType) {
        case 'EAN-13':
            return barcode;
        case 'EAN-8':
            return '00000' + barcode;
        case 'UPC-A':
            return '0' + barcode;
        default:
            return barcode; // To be backwards compatible, products that do not have barcode types are treated as having a EAN-13 barcode type
    }
};

/**
 * A help text that appears when mouse is hover over action buttons
 * @param {string} type pdf | excel | add | delete
 * @returns title text
 */
const getHelperTitleText = (type, t) => {
    const titles = {
        pdf: t('exportPDFHelp'),
        excel: t('exportExcelHelp'),
        add: t('addPlanHelp'),
        delete: t('deletePlanHelp'),
        generatePlans: t('generatePlansHelp'),
    };

    return titles[type];
};

const defaultPlanogramData = {
    shelvesCount: 5,
    shelves: [
        {
            shelf: 1,
            slotCount: 5,
            productData: []
        },
        {
            shelf: 2,
            slotCount: 5,
            productData: []
        },
        {
            shelf: 3,
            slotCount: 5,
            productData: []
        },
        {
            shelf: 4,
            slotCount: 5,
            productData: []
        },
        {
            shelf: 5,
            slotCount: 5,
            productData: []
        }
    ]
}

const imageUrlToBase64 = async (url, productId, cabinetName) => {
    return new Promise((resolve, reject) => {
        fetch(url)
            .then((response) => {
                response.blob().then((blob) => {
                    const reader = new FileReader();
                    reader.readAsDataURL(blob);
                    reader.onload = () => {
                        const base64data = reader.result;
                        resolve({ imageUrl: base64data, id: productId, cabinetName })
                    }
                })
            })
            .catch((e) => {
                console.log(e)
                resolve({ imageUrl: '', id: productId, cabinetName })
            })
    })
}


module.exports = {
    _13digitBarcode,
    getHelperTitleText,
    defaultPlanogramData,
    imageUrlToBase64
};
