import {
    REQUEST_SUPPLIERS,
    RECEIVE_SUPPLIERS,
    RECEIVE_SUPPLIERS_ERROR,
    REQUEST_CREATE_SUPPLIER,
    RECEIVE_CREATE_SUPPLIER,
    RECEIVE_CREATE_SUPPLIER_ERROR,
    REQUEST_UPDATE_SUPPLIER,
    RECEIVE_UPDATE_SUPPLIER,
    RECEIVE_UPDATE_SUPPLIER_ERROR,
    REQUEST_DELETE_SUPPLIER,
    RECEIVE_DELETE_SUPPLIER,
    RECEIVE_DELETE_SUPPLIER_ERROR,
    DISMISS_SUPPLIER_RESULT,
    RESET_SUPPLIERS,
    SEARCH_SUPPLIER_QUERY,
} from '../actions/suppliers.js';

export const initState = {
    isFetching: false,
    isError: false,
    fetched: false,
    isRequesting: false,
    isRequestingFinished: false,
    suppliers: null,
    error: '',
    searchQuery: '',
};

export default function suppliers(state = initState, action) {
    switch (action.type) {
        case REQUEST_SUPPLIERS:
            return {
                ...state,
                isFetching: true,
                isError: false,
                fetched: false,
            };
        case RECEIVE_SUPPLIERS:
            return {
                ...state,
                isFetching: false,
                isError: false,
                suppliers: action.suppliers,
                fetched: true,
            };
        case RECEIVE_SUPPLIERS_ERROR:
            console.log(action.error);
            return {
                ...state,
                isFetching: false,
                isError: true,
                error: action.error.toString(),
            };
        case REQUEST_CREATE_SUPPLIER:
            return {
                ...state,
                isRequesting: true,
                isRequestingFinished: false,
                error: '',
            };
        case RECEIVE_CREATE_SUPPLIER:
            return {
                ...state,
                isRequesting: false,
                isRequestingFinished: true,
                error: '',
                suppliers: [action.supplier, ...state.suppliers],
            };
        case RECEIVE_CREATE_SUPPLIER_ERROR:
            console.log(action.error);
            return {
                ...state,
                isRequesting: false,
                isRequestingFinished: true,
                isError: true,
                error: action.error.toString(),
            };
        case REQUEST_UPDATE_SUPPLIER:
            return {
                ...state,
                isRequesting: true,
                isRequestingFinished: false,
                error: '',
            };
        case RECEIVE_UPDATE_SUPPLIER:
            return {
                ...state,
                isRequesting: false,
                isRequestingFinished: true,
                error: '',
                suppliers: [action.supplier, ...state.suppliers.filter(supplier => supplier.id !== action.supplier.id)],
            };
        case RECEIVE_UPDATE_SUPPLIER_ERROR:
            console.log(action.error);
            return {
                ...state,
                isRequesting: false,
                isRequestingFinished: true,
                isError: true,
                error: action.error.toString(),
            };
        case REQUEST_DELETE_SUPPLIER:
            return {
                ...state,
                isRequesting: true,
                isRequestingFinished: false,
                error: '',
            };
        case RECEIVE_DELETE_SUPPLIER:
            return {
                ...state,
                isRequesting: false,
                isRequestingFinished: true,
                error: '',
                suppliers: [...state.suppliers.filter(supplier => supplier.id !== action.id)],
            };
        case RECEIVE_DELETE_SUPPLIER_ERROR:
            console.log(action.error);
            return {
                ...state,
                isRequesting: false,
                isRequestingFinished: true,
                isError: true,
                error: action.error.toString(),
            };
        case DISMISS_SUPPLIER_RESULT:
            return {
                ...state,
                isRequesting: false,
                isRequestingFinished: false,
                isError: false,
                error: '',
            };
        case RESET_SUPPLIERS:
            return {
                ...initState,
            };
        case SEARCH_SUPPLIER_QUERY:
            return {
                ...state,
                searchQuery: action.query,
            };
        default:
            return state;
    }
}
