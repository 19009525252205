import React, { Component } from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { Icon, Input } from '@storaensods/seeds-react';
import { debounce } from 'lodash';

import { supplierSearchQuery } from '../../actions/suppliers';

/**
 * Search bar for the supplier list
 */
class SupplierSearchBar extends Component {
    constructor(props) {
        super(props);
        const { supplierSearchQuery } = this.props;
        this.search = debounce(query => {
            supplierSearchQuery(query);
        }, 250);
    }

    handleSearchChange(event) {
        const query = event.target.value;
        this.search(query);
    }

    componentDidMount() {
        if (this.props.searchQuery && this.props.searchQuery !== '') {
            this.props.supplierSearchQuery('');
        }
    }

    render() {
        const { t } = this.props;
        return (
            <div className="catalog-search-bar">
                <Input className="catalog-top-row-element" placeholder={t('search')} onChange={this.handleSearchChange.bind(this)} />
                <Icon className="icon-search">search</Icon>
            </div>
        );
    }
}

export default connect(
    state => ({
        searchQuery: state.suppliers.searchQuery,
    }),
    {
        supplierSearchQuery,
    }
)(translate('main')(SupplierSearchBar));
