import React, { Fragment, useState, useEffect } from 'react';
import { Spinner, Button, Accordion } from '@storaensods/seeds-react';
import { Row, Col } from 'reactstrap';
import Toggle from 'react-toggle';
import moment from 'moment';

import CustomTable from './../customTable';
import { CubeCustomQueryChart } from './../cubeCharts';
import { exportExcelDataFromCubeResponse, exportExcelDataFromPredefinedInventory } from './../export';
import SelectionSummary from './selectionSummary';

function create13DigitBarcode(product) {
    switch (product.barcodeType) {
        case 'EAN-13':
            return product.barcode;
        case 'EAN-8':
            return '00000' + product.barcode;
        case 'UPC-A':
            return '0' + product.barcode;
        default:
            return product.barcode; // To be backwards compatible, products that do not have barcode types are treated as having a EAN-13 barcode type
    }
}

const ReportView = ({ custom, t, cabinets, products, currentSelection, changePanel, locations }) => {
    const [cubeData, setCubeDate] = useState(custom.cubeResponse ? custom.cubeResponse.data : []);
    const hasQueryChartView = custom.cubeResponse && currentSelection.queryType === 'custom' && custom.cubeResponse.query.dimensions.length > 0;
    // If individual cabinet selection is done, then they are stored at selectedCabinets to use as filter below
    // Otherwise it's null, which will mean no filter to apply
    const selectedCabinets =
        currentSelection.groupBy === 'cabinets' && currentSelection.selectedCabinetFilters && currentSelection.selectedCabinetFilters.members
            ? currentSelection.selectedCabinetFilters.members
            : null;

    useEffect(() => {
        if (!custom.cubeResponse) return;
        const allProducts = products.products;
        if (!allProducts) return;
        let tagMappedToProducts = {};

        if (currentSelection.preDefinedSegment === 'InventoryHistory' && currentSelection.queryType === 'preDefined') {
            if (!custom.cubeResponse.data || !Array.isArray(custom.cubeResponse.data)) return;

            custom.cubeResponse.data.forEach(item => {
                if (!item['InventoryHistory.tags']) return;
                if (!item['InventoryHistory.deviceCode']) return;
                if (selectedCabinets && !selectedCabinets.find(d => d === item['InventoryHistory.deviceCode'])) return;

                const deviceCode = item['InventoryHistory.deviceCode'];
                const cabinet = cabinets.find(cabinet => cabinet.deviceCode === deviceCode);
                const cabinetName = cabinet ? cabinet.name : deviceCode;
                const deviceLocation = locations.find(i => i.id === cabinet.locationId);

                // need to clear the data for previous day with same device code, when we group, inventoryItems does not always have same day :-(
                if (
                    tagMappedToProducts.hasOwnProperty(cabinetName) &&
                    Array.isArray(tagMappedToProducts[cabinetName]) &&
                    tagMappedToProducts[cabinetName].length > 0 &&
                    !moment(tagMappedToProducts[cabinetName][0].Date).isBefore(item['InventoryHistory.readtime'])
                ) {
                    return null;
                } else if (tagMappedToProducts.hasOwnProperty(cabinetName)) {
                    delete tagMappedToProducts[cabinetName];
                }

                JSON.parse(item['InventoryHistory.tags']).forEach(eachTag => {
                    const productItem = allProducts.find(product => create13DigitBarcode(product) === eachTag.barcode);
                    let mapData = null;

                    if (productItem) {
                        const objectHasBarcode = tagMappedToProducts[cabinetName]
                            ? tagMappedToProducts[cabinetName].find(tag => tag.Barcode === eachTag.barcode)
                            : false;

                        if (objectHasBarcode) {
                            objectHasBarcode['Count'] = objectHasBarcode['Count'] + 1;
                        } else {
                            mapData = {
                                Image: productItem.imageUrl,
                                Barcode: create13DigitBarcode(productItem),
                                'Product Name': productItem.name,
                                Price: productItem.price,
                                Currency: productItem.currency,
                                'Location Name': deviceLocation ? deviceLocation.name : null,
                                'Product Category': productItem.productCategory ? productItem.productCategory.name : null,
                                'Vat Category': productItem.vatCategory ? productItem.vatCategory.name : null,
                                'Vat Value': productItem.vatCategory ? productItem.vatCategory.taxValue : null,
                                Count: 1,
                                'Device Name': cabinetName,
                                Date: moment(item['InventoryHistory.readtime']).format('YYYY-MM-DD'),
                            };
                        }
                    }

                    if (tagMappedToProducts.hasOwnProperty(cabinetName)) {
                        if (mapData) tagMappedToProducts[cabinetName].push(mapData);
                    } else {
                        tagMappedToProducts = { ...tagMappedToProducts, [cabinetName]: mapData ? [mapData] : [] };
                    }
                });
            });
            setCubeDate(tagMappedToProducts);
        } else {
            setCubeDate(custom.cubeResponse.data);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [products, custom]);

    const isInventoryHistoryData = custom.cubeResponse && custom.cubeResponse.data.some(item => item.hasOwnProperty('InventoryHistory.readtime'));
    const showExportToExcel =
        (!isInventoryHistoryData && currentSelection.queryType === 'preDefined') || (isInventoryHistoryData && currentSelection.queryType === 'custom');

    return (
        <Fragment>
            <div className="report-view__toggle mr-2">
                <div className="report-view__toggle-content">
                    <div className="mb-2 mt-2 small">{t('viewMode')}</div>
                    <div className="d-inline-block">
                        <h6 style={{ color: '#a0a1a5' }}>{t('dashboard')}</h6>
                    </div>
                    <Toggle
                        className="d-inline-block ml-3 mr-3 dashboard-toggle"
                        checked={true}
                        icons={false}
                        onChange={() => {
                            changePanel('dashboard');
                        }}
                        readOnly
                    />
                    <div className="d-inline-block">
                        <h6>{t('reportsTitle')}</h6>
                    </div>
                </div>
            </div>

            <Row className="mt-1 ml-3">
                <SelectionSummary currentSelection={currentSelection} cabinets={cabinets} t={t} />
            </Row>

            {custom.isError || custom.isFetching || !custom.fetched ? (
                <Row>
                    {custom.isError ? (
                        <Col xs={{ size: '4', offset: '4' }} className="mt-4 d-flex justify-content-center">
                            <div className="se-notification se-notification--negative">
                                <div className="se-notification-content"> {t('fetchingError')}</div>
                            </div>
                        </Col>
                    ) : (
                        <Col xs="12" className="d-flex justify-content-center">
                            {custom.isFetching ? <Spinner /> : null}
                        </Col>
                    )}
                </Row>
            ) : (
                <Row>
                    <Col xs="12" md={`${hasQueryChartView ? 8 : 12}`}>
                        <div className="dashboard-chart-container mb-3">
                            <div className="mb-2">
                                <Button
                                    className="d-block"
                                    icon="file_download"
                                    disabled={!custom.cubeResponse.data.length || showExportToExcel}
                                    onClick={() => {
                                        if (currentSelection.queryType === 'preDefined') exportExcelDataFromPredefinedInventory('analytics_report', cubeData);
                                        else if (currentSelection.queryType === 'custom')
                                            exportExcelDataFromCubeResponse('analytics_report', custom.cubeResponse);
                                    }}
                                >
                                    Export to excel
                                </Button>
                            </div>

                            {currentSelection.preDefinedSegment === 'InventoryHistory' && currentSelection.queryType === 'preDefined' ? (
                                Object.keys(cubeData).length ? (
                                    Object.keys(cubeData).map((deviceCode, index) => {
                                        if (Array.isArray(cubeData)) return null;
                                        return (
                                            <Accordion title={deviceCode} isOpen={index === 0 ? true : false} key={`${deviceCode}- at index ${index}`}>
                                                <CustomTable
                                                    granularity={
                                                        custom.cubeResponse.query.timeDimensions[0]
                                                            ? custom.cubeResponse.query.timeDimensions[0].granularity
                                                            : 'day'
                                                    }
                                                    data={cubeData[deviceCode]}
                                                    t={t}
                                                    key={deviceCode}
                                                />
                                            </Accordion>
                                        );
                                    })
                                ) : (
                                    <div>{t('noInventoryOnSelectedDate')}</div>
                                )
                            ) : isInventoryHistoryData ? null : (
                                <CustomTable
                                    granularity={custom.cubeResponse.query.timeDimensions[0] ? custom.cubeResponse.query.timeDimensions[0].granularity : 'day'}
                                    data={custom.cubeResponse.data}
                                    t={t}
                                />
                            )}
                        </div>
                    </Col>
                    {hasQueryChartView && (
                        <Col xs="12" md="4">
                            <div className="dashboard-chart-container mt-3 mb-3">
                                <CubeCustomQueryChart cubeResponse={custom.cubeResponse} t={t} />
                            </div>
                        </Col>
                    )}
                </Row>
            )}
        </Fragment>
    );
};

export default ReportView;
