import React, { useEffect, useState, Fragment } from 'react';
import { Row, Col } from 'reactstrap';
import { Spinner } from '@storaensods/seeds-react';
import { groupBy } from 'lodash';

import DiscountAreaChart from './charts/discountAreaChart';
import DiscountPieChart from './charts/discountPieChart';
import DiscountList from './discountList';

const DiscountDashboard = props => {
    const { discountDashboardState, dashboardState, fetchDiscountDashboardData, t } = props;

    const { dateRange, filter } = dashboardState;
    const { isFetching, isError, fetched, discountProducts } = discountDashboardState;

    useEffect(() => {
        // fetch the discount dashboard data when componet loads
        if (!isFetching && !fetched) {
            fetchDiscountDashboardData(dateRange, filter);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [products, setProducts] = useState({});

    // when data is fetched
    useEffect(() => {
        if (discountProducts && discountProducts.hasOwnProperty('data')) {
            if (discountProducts.data.length) {
                let productLists = {};
                const products = discountProducts.data.filter(product => {
                    return (product['Transactions.isDiscounted'] || product['Transactions.isDiscounted'] === 'true') && product['Transactions.discountAmount'];
                });
                const discountRules = groupBy(products, 'Transactions.discountName');
                const rules = Object.keys(discountRules);

                rules.forEach(rule => {
                    const discountByStops = groupBy(discountRules[rule], 'Transactions.discountAmount');
                    productLists = { ...productLists, [rule]: discountByStops };
                });
                setProducts(productLists);
            }
        }
    }, [discountProducts]);

    return (
        <Fragment>
            {isError && <div>{t('fetchingError')}</div>}
            {isFetching && (
                <div className="d-flex justify-content-center pt-3">
                    <Spinner />
                </div>
            )}
            {fetched && (
                <Fragment>
                    <DiscountList discountProducts={products} t={t} />
                    <Row className="mt-5">
                        <Col sm="12" md="6" lg="7" className="dashboard-chart-container">
                            <div className="text-center">
                                <strong>{t('discountSalesData')}</strong>
                            </div>
                            <div className="text-center">
                                <span className="legend-total-discount">{t('discountAmount')}</span>
                                <span className="legend-total-sale">{t('discountSalesAmount')}</span>
                            </div>
                            {discountProducts && discountProducts.data.length === 0 ? (
                                <div className="dashboard-no-data">{t('noData')}</div>
                            ) : (
                                <DiscountAreaChart discountProducts={discountProducts} t={t} />
                            )}
                        </Col>
                        <Col sm="12" md="6" lg="5" className="dashboard-chart-container">
                            <div className="text-center">
                                <strong>{t('discountSalesAmount')}</strong>
                            </div>
                            {discountProducts && discountProducts.data.length === 0 ? (
                                <div className="dashboard-no-data">{t('noData')}</div>
                            ) : (
                                <DiscountPieChart discountProducts={discountProducts} t={t} />
                            )}
                        </Col>
                    </Row>
                </Fragment>
            )}
        </Fragment>
    );
};

export default DiscountDashboard;
