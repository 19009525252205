import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { replace } from 'react-router-redux';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { Alert, Col, Row } from 'reactstrap';
import { fetchCabinetReplenishments } from '../../actions/replenishments';
import { getTransactions, setTimeRange, selectView, fetchTemperature, fetchCurrentStatus } from '../../actions/report.js';
import Filter from '../datesfilter/datesfilter';
import ReplenishmentReport from './replenishment/replenishmentReport.js';
import TemperatureReport from './temperature/temperatureReport.js';
import TransactionReport from './transactions/transactionReport.js';
import Select from 'react-select';
import ReportHeader from './reportHeader';
import { formatTransactionsForCSV, generateTransactionCSVHeaders, formatTransactionsForXLSX } from './export.js';
import { Button } from '@storaensods/seeds-react';
// report page CSS
import './report.css';
import { fetchProducts } from '../../actions/products.js';

/**
 * Report page component
 */
export class Report extends Component {
    constructor(props) {
        super(props);
        this.state = {
            cabinetDropdownOpen: false,
            reportType: ['sales', 'replenishments', 'temperature'].includes(this.props.reportType) ? this.props.reportType : 'sales',
            timeSelect: this.props.timeRange,
            selectedFilters: null,
            filteredTransactions: { data: [] },
            allRowsExpanded: false,
        };
    }

    componentDidMount() {
        if (this.props.selectedCabinetDeviceCode) {
            // make sure it fetch the data after mount
            this.updateDataSelection(this.props.cabinet);
        }
        const { products, fetchProducts } = this.props;

        if (products && !products.products.length && !products.isFetching) {
            fetchProducts();
        }
    }

    componentWillUnmount() {
        if (this.props.temperature.isFetching && this.state.previousController) {
            this.state.previousController.abort();
        }
        this.setState({
            previousController: null,
            previousSignal: null,
        });
    }

    componentDidUpdate(prevProps) {
        const { selectedCabinetDeviceCode, transactions } = this.props;
        if (selectedCabinetDeviceCode && (selectedCabinetDeviceCode !== prevProps.selectedCabinetDeviceCode || prevProps.timeRange !== this.props.timeRange)) {
            this.setState({ allRowsExpanded: false });
            this.updateDataSelection(this.props.cabinet);
        }
        if (transactions && transactions !== prevProps.transactions) {
            this.setState({ filteredTransactions: transactions }, () => {
                this.filterTransactions();
            });
        }
    }

    updateDataSelection(cabinet) {
        const {
            timeRange: { from, to },
        } = this.props;
        const controller = new AbortController();
        const { signal } = controller;
        if (this.props.temperature.isFetching && this.state.previousController) {
            this.state.previousController.abort();
        }
        this.setState({
            previousController: controller,
            previousSignal: signal,
        });

        /**
         * Used for resolving device temperature data from currentStatus table.
         * the table contain deviceId values so Device Id should be used unless deviceCode and Id are similar.
         */
        const codeOrId = cabinet.deviceCode === cabinet.id ? cabinet.deviceCode : cabinet.id;
        this.props.fetchTemperature(cabinet.deviceCode, from, to, signal);
        this.props.fetchCurrentStatus(codeOrId, from, to);
        this.props.getTransactions(cabinet.deviceCode, from, to);
        this.props.fetchCabinetReplenishments(cabinet, from, to);
    }

    getCabinetName = (cabinetCode, cabinets) => {
        if (cabinets && cabinets.cabinets) {
            const cabinet = cabinets.cabinets.find(cabinet => cabinet.deviceCode === cabinetCode);
            return cabinet ? cabinet.name : cabinetCode;
        }
        return cabinetCode;
    };

    /**
     *
     * @param {string} cabinetCode The deviceCode of of the cabinet
     * @param {array} cabinets All the cabinets information received from API
     * @returns whether the cabinet is online or not
     */
    getCabinetStatus = (cabinetCode, cabinets) => {
        if (cabinets && cabinets.cabinets) {
            const cabinet = cabinets.cabinets.find(cabinet => cabinet.deviceCode === cabinetCode);
            return cabinet ? cabinet.isOnline : false;
        }
        return false;
    };

    filterTransactions = () => {
        const { selectedFilters } = this.state;
        if (selectedFilters) {
            let transactions = [...this.props.transactions.data];
            transactions = transactions.filter(transaction => {
                const filterValues = [];
                selectedFilters.map(v => {
                    if (v.value === 'OTHER') {
                        filterValues.push('NEW', 'TIMEOUT', 'IN_PROGRESS', 'CANCELED');
                    } else if (v.value === 'FAIL') {
                        filterValues.push('FAIL', 'CANCEL');
                    } else {
                        filterValues.push(v.value);
                    }
                    return v;
                });
                return filterValues.includes(transaction.status);
            });
            this.setState({ filteredTransactions: { ...this.state.filteredTransactions, data: transactions } });
        } else this.setState({ filteredTransactions: this.props.transactions });
    };

    filtersOnChange = values => {
        this.setState({ selectedFilters: values }, () => {
            this.filterTransactions();
        });
    };

    toggleAllRowExpand = () => {
        this.setState(prevState => {
            return { ...prevState, allRowsExpanded: !prevState.allRowsExpanded };
        });
    };

    /**
     *
     * @param {boolean} value
     */
    setAllRowsExpanded = value => {
        this.setState({ allRowsExpanded: value });
    };

    render() {
        const { t, cabinets, cabinet, replenishments, temperature, currentStatus, view, timeRange, selectedCabinetDeviceCode, products } = this.props;

        const { filteredTransactions, allRowsExpanded } = this.state;

        return (
            <div>
                {selectedCabinetDeviceCode ? (
                    <div>
                        <Row>
                            <Col lg={10} xl={11} md={10}>
                                <Filter />
                            </Col>
                            {this.state.reportType === 'sales' && (
                                <div className="col-1">
                                    <ReportHeader
                                        title={''}
                                        exportFileName={t('reports:transactions')}
                                        exportData={formatTransactionsForCSV(
                                            filteredTransactions?.data,
                                            this.getCabinetName(selectedCabinetDeviceCode, cabinets),
                                            products?.products || []
                                        )}
                                        exportHeaders={generateTransactionCSVHeaders()}
                                        exportDataExcel={formatTransactionsForXLSX(
                                            filteredTransactions?.data,
                                            this.getCabinetName(selectedCabinetDeviceCode, cabinets),
                                            products?.products || []
                                        )}
                                    />
                                </div>
                            )}
                            {this.state.reportType === 'sales' && (
                                <>
                                    <div className="col-12 mt-3 px-0 d-flex  justify-content-between">
                                        <div className="col-6">
                                            <Select
                                                className="transactions__filters"
                                                isMulti
                                                placeholder="Select filters..."
                                                menuPortalTarget={document.body}
                                                menuPosition={'fixed'}
                                                options={[
                                                    { value: 'DONE', label: t('reports:paidTransactions') },
                                                    { value: 'PARTIAL', label: t('reports:partiallyPaid') },
                                                    { value: 'FAIL', label: t('reports:failedTransactions') },
                                                    { value: 'NO_PURCHASE', label: t('reports:noPurchase') },
                                                    { value: 'OTHER', label: t('reports:other') },
                                                ]}
                                                onChange={values => this.filtersOnChange(values)}
                                                value={this.state.selectedFilters}
                                            />
                                        </div>
                                        <div className="col-3">
                                            <Button
                                                className="expandCollapseAllButton"
                                                icon={allRowsExpanded ? 'arrow_drop_up' : 'arrow_drop_down'}
                                                type="secondary"
                                                onClick={() => this.toggleAllRowExpand()}
                                            >
                                                {allRowsExpanded ? 'Collapse all' : 'Expand all'}
                                            </Button>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <TransactionReport
                                            allRowsExpanded={allRowsExpanded}
                                            setAllRowsExpanded={this.setAllRowsExpanded}
                                            transactions={filteredTransactions}
                                            summary={view === 'summary'}
                                        />
                                    </div>
                                </>
                            )}

                            {this.state.reportType === 'replenishments' && (
                                <ReplenishmentReport
                                    t={t}
                                    cabinetName={this.getCabinetName(selectedCabinetDeviceCode, cabinets)}
                                    replenishments={replenishments}
                                    timeRange={timeRange}
                                />
                            )}
                            {this.state.reportType === 'temperature' && (
                                <TemperatureReport
                                    t={t}
                                    temperature={temperature}
                                    currentStatus={currentStatus}
                                    deviceOnline={this.getCabinetStatus(selectedCabinetDeviceCode, cabinets)}
                                    cabinetName={this.getCabinetName(selectedCabinetDeviceCode, cabinets)}
                                    timeRange={timeRange}
                                    isFreezer={cabinet.deviceSubType === 'IF3Freezer'}
                                />
                            )}
                        </Row>
                    </div>
                ) : (
                    <Alert color="light" className="float-left">
                        <div className="h-100 d-flex align-items-center">{t('noCabinetSelected')}</div>
                    </Alert>
                )}
            </div>
        );
    }
}

const mapStateToProps = state => ({
    cabinets: state.cabinets,
    cabinet: state.cabinets.cabinets.find(c => c.deviceCode === state.cabinets.selectedCabinetDeviceCode),
    replenishments: state.replenishments[state.cabinets.selectedCabinetDeviceCode] || null,
    transactions: state.report.transactions,
    temperature: state.report.temperature,
    currentStatus: state.report.currentStatus,
    timeRange: state.report.timeRange,
    view: state.report.view,
    selectedCabinetDeviceCode: state.cabinets.selectedCabinetDeviceCode,
    products: state.products,
});

const mapDispatchToProps = {
    fetchCabinetReplenishments,
    fetchTemperature,
    fetchCurrentStatus,
    setTimeRange,
    getTransactions,
    replace,
    selectView,
    fetchProducts,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(translate('reports')(Report)));
