export const dateRanges = [
    'All time',
    'Today',
    'Yesterday',
    'This week',
    'This month',
    'This quarter',
    'This year',
    'Last 7 days',
    'Last 30 days',
    'Last week',
    'Last month',
    'Last quarter',
    'Last year',
];

export const granularities = ['w/o grouping', 'hour', 'day', 'week', 'month', 'year'];

export const measures = [
    {
        value: 'Transactions.count',
        label: 'Transactions count',
        filters: {
            dimension: 'Transactions.status',
            operator: 'equals',
            values: [
                {
                    value: 'DONE',
                    label: 'Transaction | Complete (Paid)',
                },
                {
                    value: 'PARTIAL',
                    label: 'Transaction | Partial (Paid & Unpaid)',
                },
                {
                    value: 'CANCEL',
                    label: 'Transaction | User Cancelled (Unpaid)',
                },
                {
                    value: 'CANCELED',
                    label: 'Transaction | Session Cancelled',
                },
                {
                    value: 'NEW',
                    label: 'Transaction | Started',
                },
                {
                    value: 'IN_PROGRESS',
                    label: 'Transaction | In Progress',
                },
                {
                    value: 'TIMEOUT',
                    label: 'Transaction | Timeout (Unpaid)',
                },
                {
                    value: 'FAIL',
                    label: 'Transaction | Failed (Unpaid)',
                },
                {
                    value: 'NO_PURCHASE',
                    label: 'Transaction | No Purchase',
                },
                /*
                {
                    value: 'REFUND',
                    label: 'Transaction | Refunded',
                },
                {
                    value: 'PARTIAL_REFUND',
                    label: 'Transaction | Partial refund',
                },*/
            ],
        },
    },
    { value: 'Transactions.itemsSoldCount', label: 'Items sold count' },
    { value: 'Transactions.orderSum', label: 'Order sum' },
    { value: 'Transactions.averageOrderSum', label: 'Average order sum' },
    { value: 'Transactions.averageItemsCount', label: 'Average items count' },
    { value: 'Transactions.minimumOrderSum', label: 'Minimum order sum' },
    { value: 'Transactions.maximumOrderSum', label: 'Maximum order sum' },
    { value: 'Transactions.customersCount', label: 'Customers count' },
    { value: 'Transactions.productsCount', label: 'Products count' },
    { value: 'Transactions.netOrderSum', label: 'Net order sum' },
    { value: 'Transactions.vatValueSum', label: 'VAT value sum' },
    { value: 'DeviceTemperatures.averageComputedTemperature', label: 'Average Temperature' },
    { value: 'DeviceTemperatures.minComputedTemperature', label: 'Minimum Temperature' },
    { value: 'DeviceTemperatures.maxComputedTemperature', label: 'Maximum Temperature' },
];

export const queryTypeOptions = [
    {
        value: 'custom',
        label: 'custom',
    },
    {
        value: 'preDefined',
        label: 'preDefined',
    },
];

export const cabinets = [
    { value: 'cabinet.id', label: 'Cabinet id' },
    { value: 'cabinet.deviceCode', label: 'Cabinet device code' },
    { value: 'cabinet.deviceName', label: 'Cabinet name' },
    { value: 'cabinet.locationName', label: 'Location' },
    { value: 'cabinet.customerId', label: 'Customer id' },
];

export const locations = [
    { value: 'Location.id', label: 'Location id' },
    { value: 'Location.deviceCode', label: 'Location device code' },
    { value: 'Location.deviceName', label: 'Location name' },
    { value: 'Location.locationName', label: 'Location' },
    { value: 'Location.customerId', label: 'Customer id' },
];

export const dimensions = [
    { value: 'Device.id', label: 'Cabinet id' },
    { value: 'Device.deviceCode', label: 'Cabinet device code' },
    { value: 'Device.deviceName', label: 'Cabinet name' },
    { value: 'Transactions.locationName', label: 'Location' },
    { value: 'Transactions.customerId', label: 'Customer id' },
    { value: 'Transactions.dayOfWeek', label: 'Day of week' },
    { value: 'Transactions.month', label: 'Month' },
    { value: 'Transactions.barcode', label: 'Barcode' },
    { value: 'Transactions.productName', label: 'Product name' },
    { value: 'Transactions.productCategoryName', label: 'Product Category' },
    { value: 'Transactions.soldCount', label: 'Sold count' },
    { value: 'Transactions.status', label: 'Status' },
    { value: 'Transactions.currency', label: 'Currency' },
    { value: 'Transactions.vatValue', label: 'VAT %' },
    { value: 'Transactions.paymentMethod', label: 'Payment method' },
];

// predefined for now is just for segments.
// TO DO: if more predefined necessary in future which is not segments
//        put this as an array of named queries.
export const predefined = {
    'InventoryHistory.latest': {
        dimensions: [
            'Device.deviceName',
            'Location.name',
            'ProductType.name',
            'ProductType.code',
            'ProductPrice.price',
            'ProductPrice.currency',
            'VatCategory.name',
            'VatCategory.taxValue',
            'InventorySnapshot.expiryDate',
            'InventoryHistory.tags',
            'InventoryHistory.deviceCode',
        ],
        measures: ['InventorySnapshot.goodAntennaCount'],
    },
    InventoryHistory: {
        dimensions: ['InventoryHistory.tags', 'InventoryHistory.deviceCode'],
        // measures: ['InventoryHistory.goodAntennaCount'],
    },
};

export const predefinedOptions = [{ value: 'InventoryHistory', label: 'Cabinet Inventory History' }];

export const groupingOptions = [
    { label: 'all', value: 'all' },
    { label: 'cabinets', value: 'cabinets' },
    { label: 'locations', value: 'locations' },
];
