import React from 'react';
import ReportHeader from '../reportHeader.js';
import TransactionDetail from '../transactions/transactionDetail.js';
import { formatReplenishmentForCSV, generateReplenishmentCSVHeaders, formatReplenishmentForXLSX } from '../export.js';
import FetchingAlert from '../../fetching/fetchingAlert.js';

/**
 * Parse transaction like history from replenishments
 */
const transactionizeReplenishments = (replenishments, t) => {
    const result = replenishments.map(replenishment => {
        const id = replenishment.audit.id;
        const user = replenishment.audit.userName;
        const timestamp = replenishment.audit.timestamp;

        let totalSum = 0;
        const purchase = [];
        replenishment.refill.map(product => {
            const p = {
                count: -1 * product.removedItems.total,
                name: t('unknown'),
                barcode: product.barcode,
                price: 0,
                tags: product.removedItems.tags.map(t => t.epc),
            };
            if (product.is_showable) {
                p.name = product.name;
                p.price = product.price;
                p.currency = product.currency;
            }
            if (p.count !== 0) purchase.push(p);
            const p2 = Object.assign({}, p);
            p2.count = product.addedItems.total;
            p2.tags = product.addedItems.tags.map(t => t.epc);
            if (p2.count !== 0) purchase.push(p2);
            totalSum += (product.addedItems.total - product.removedItems.total) * p.price;
            return p;
        });

        return {
            orderId: `replenishment ${id}`,
            user,
            status: 'REPLENISH',
            timestamp,
            purchases: {
                purchase,
                totalSum,
            },
            replenishment, // NOTE: this is completely different from v1
            // As far as I can see, it is not used so it's okay
        };
    });

    return result;
};

/**
 * Replenishment report component
 */
export default function replenishmentReport({ t, replenishments, cabinetName }) {
    if (!replenishments) {
        return <div />;
    }

    if (replenishments.isFetching || replenishments.error || replenishments.replenishments.length === 0) {
        return (
            <div style={{ padding: '15px' }}>
                <FetchingAlert
                    fetchingMessage={t('fetching')}
                    noDataMessage={t('noData')}
                    errorMessage={t('fetchingError')}
                    isError={replenishments.error}
                    noData={!replenishments.isFetching && replenishments.replenishments && replenishments.replenishments.length === 0}
                />
            </div>
        );
    }

    const replenishmentData = transactionizeReplenishments(replenishments.replenishments, t);

    return (
        <>
            <div className="col-1">
                <ReportHeader
                    title={''}
                    exportFileName={t('replenishmentsTitle')}
                    exportData={formatReplenishmentForCSV(replenishmentData, cabinetName)}
                    exportDataExcel={formatReplenishmentForXLSX(replenishmentData, cabinetName)}
                    exportHeaders={generateReplenishmentCSVHeaders()}
                />
            </div>
            <div className="col-md-12 col-lg-12">
                <TransactionDetail transactions={replenishmentData} tableType="replenishment" t={t} />
            </div>
        </>
    );
}
