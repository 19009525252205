import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Col } from 'reactstrap';
import { groupBy } from 'lodash';
import { DefaultTooltipContent } from 'recharts/lib/component/DefaultTooltipContent';
import { BarChart, Bar, XAxis, YAxis, Tooltip, CartesianGrid, ResponsiveContainer } from 'recharts';

import { getDatesRange, formatTimeAxisDatetime, formatDatetime } from './../../cubeCharts';

const ExpiredProductsBarChart = ({ t, data }) => {
    const [dateRange, setDateRange] = useState([]);
    const [expiredProducts, setExpiredProducts] = useState([]);

    // when expired products data loads
    useEffect(() => {
        if (data && data.data.length) {
            // filter out any data if the expiry date is null
            const validProducts = data.data.filter(product => product['ExpiredProducts.expiryDate'] !== null);
            if (validProducts && validProducts.length) {
                setExpiredProducts(validProducts);
                const { timeDimensions } = data.query;
                const startDate = timeDimensions[0].dateRange ? timeDimensions[0].dateRange[0] : validProducts[0]['ExpiredProducts.expiryDate'];
                const endDate = timeDimensions[0].dateRange
                    ? timeDimensions[0].dateRange[1]
                    : validProducts[validProducts.length - 1]['ExpiredProducts.expiryDate'];
                const granularity = timeDimensions[0].granularity;

                setDateRange(expiryDatesRange(granularity, startDate, endDate));
            }
        }
    }, [data]);

    // group the product by expiry dates
    function getProductsByDate() {
        const products = expiredProducts.map(product => {
            return {
                ...product,
                'ExpiredProducts.expiryDate': moment(product['ExpiredProducts.expiryDate']).format('YYYY-MM-DD'),
            };
        });

        return groupBy(products, product => {
            return product['ExpiredProducts.expiryDate'];
        });
    }

    const getExpiredProductsChartData = () => {
        const products = getProductsByDate();
        return dateRange.map(date => {
            if (products.hasOwnProperty(date)) {
                let cost = 0;
                let currency = '';
                let count = 0;
                products[date].forEach(product => {
                    if (!currency) {
                        currency = product['ExpiredProducts.currency'];
                    }
                    cost = cost + parseFloat(product['ExpiredProducts.price']);
                    count += product['ExpiredProducts.count'];
                });

                return { cost, currency, expiryDate: date, count };
            } else {
                return { cost: 0, currency: '', expiryDate: date, count: 0 };
            }
        });
    };

    return (
        <Col xs="12" md="6" lg="6">
            <div className="dashboard-chart-container">
                <div className="text-center">
                    <h6>{t('expiredProductsCount')}</h6>
                </div>
                {getExpiredProductsChartData().length === 0 ? (
                    <div className="dashboard-no-data">{t('noData')}</div>
                ) : (
                    <ProductsBarChart
                        data={getExpiredProductsChartData()}
                        xKey="expiryDate"
                        yKey="count"
                        granularity={data.query && data.query.timeDimensions[0].granularity}
                    />
                )}
            </div>
        </Col>
    );
};

export function expiryDatesRange(granularity, startDate, endDate) {
    const firstDate = new Date(startDate + 'Z');
    const lastDate = new Date(endDate + 'Z');

    // set first date to first monday if granularity is week
    if (granularity === 'week') {
        const weekday = firstDate.getDay();
        let daysOffset;
        switch (weekday) {
            case 0:
                daysOffset = 1;
                break;
            case 1:
                daysOffset = 0;
                break;
            case 2:
                daysOffset = 6;
                break;
            case 3:
                daysOffset = 5;
                break;
            case 4:
                daysOffset = 4;
                break;
            case 5:
                daysOffset = 3;
                break;
            case 6:
                daysOffset = 2;
                break;
            default:
                daysOffset = 0;
        }
        firstDate.setDate(firstDate.getDate() + daysOffset);
    }

    // get all dates including dates missing in data
    const datesRange = getDatesRange(firstDate, lastDate, granularity).map(date => {
        return moment(date.toISOString()).format('YYYY-MM-DD');
    });

    return datesRange;
}

//Tooltips for the expired products charts
function CustomToolTip(props) {
    if (!props || !props.payload) {
        return <p>Data invalid !</p>;
    }

    if (!props.payload[0]) {
        return <DefaultTooltipContent {...props} />;
    }

    const newPayload = [
        {
            name: 'Total cost',
            value: props.payload[0].payload.cost.toFixed(2) + props.payload[0].payload.currency,
        },
        ...props.payload,
    ];
    return <DefaultTooltipContent {...props} payload={newPayload} />;
}

export function ProductsBarChart({ data, xKey, yKey, granularity }) {
    function tooltipFormatter(value, name) {
        return [typeof value === 'number' ? value.toFixed(2).replace(/[.,]00$/, '') : value, name === 'count' ? 'Total products' : name];
    }

    const CustomizedAxisTick = props => {
        const { x, y, payload } = props;

        return (
            <g transform={`translate(${x},${y})`}>
                <text x={0} y={0} dy={16} textAnchor="end" fill="#666" transform="rotate(-45)">
                    {formatTimeAxisDatetime(granularity, payload.value)}
                </text>
            </g>
        );
    };

    return (
        <ResponsiveContainer width="99%" height={400}>
            <BarChart data={data} margin={{ top: 5, right: 0, left: -10, bottom: 5 }}>
                <XAxis dataKey={xKey} tickFormatter={formatTimeAxisDatetime.bind(this, granularity)} tick={<CustomizedAxisTick />} height={100} />
                <YAxis dataKey={yKey} />
                <Tooltip
                    active={true}
                    labelFormatter={formatDatetime.bind(this, granularity)}
                    content={<CustomToolTip payload={data} />}
                    formatter={tooltipFormatter}
                />
                <CartesianGrid stroke="#f5f5f5" />
                <Bar type="monotone" dataKey="count" fill="#118BD6" yAxisId={0} />
            </BarChart>
        </ResponsiveContainer>
    );
}

export default ExpiredProductsBarChart;
