import React, { useState, useEffect } from 'react';
import { Button, Modal, Input } from '@storaensods/seeds-react';
import { Row, Col } from 'reactstrap';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { fetchProducts } from '../../actions/products';
import { fetchSuppliers, createSupplier, updateSupplier, deleteSupplier, dismissSupplierResults } from '../../actions/suppliers';
import FetchingAlert from '../fetching/fetchingAlert.js';
import DeletePrompt from '../deletePrompt/DeletePrompt.jsx';
import { showNotification } from '../toastNotification/toastNotification.js';
import SupplierSearchBar from './supplierSearchBar';
import SupplierCards from './supplierCards';

import './suppliers.css';

/**
 * The Entry point of the supplier CRUD UI
 * @param {object} props Props for Suppliers
 * @param {function} props.t i18n translator function
 * @param {object} props.suppliers The Redux state for suppliers CRUD
 * @param {Boolean} props.isAdmin From Redux state. Indicates if the user has admin privileges
 * @param {function} props.fetchSuppliers From Redux state. Thunk action for fetching suppliers
 * @param {function} props.createSupplier From Redux state. Thunk action for creating a new supplier
 * @param {function} props.updateSupplier From Redux state. Thunk action for updating a supplier
 * @param {function} props.deleteSupplier From Redux state. Thunk action for deleting a supplier
 * @param {function} props.dismissSupplierResults From Redux state. Dismisses the supplier results.
 */
export function Suppliers(props) {
    const {
        t,
        suppliers,
        searchQuery,
        products,
        fetchProducts,
        isAdmin,
        fetchSuppliers,
        createSupplier,
        updateSupplier,
        deleteSupplier,
        dismissSupplierResults,
    } = props;

    // Hooks for activating new/edit supplier modal and set its mode to 'NEW' or 'EDIT'
    const [activateModal, setActivateModal] = useState(false);
    const [mode, setModalMode] = useState();

    // Hooks for setting items to be deleted or edited
    const [editItem, setEditItem] = useState(null);
    const [deleteItem, setDeleteItem] = useState(null);

    // Hook for showing confirm delete prompt
    const [showDeletePrompt, setShowDeletePrompt] = useState(false);

    /**
     * Gets the suppliers and handles the filtering for the search bar
     */
    function getSupplierItems() {
        if (suppliers) {
            if (suppliers.suppliers) {
                const selectedSuppliers = suppliers.suppliers.filter(supplier => {
                    if (supplier.status === 3) {
                        return false;
                    } else if (!searchQuery) {
                        return true;
                    } else {
                        return (supplier.name || '').toLowerCase().indexOf(searchQuery.toLowerCase()) !== -1;
                    }
                });
                return selectedSuppliers;
            }
        }
    }

    const supplierItems = getSupplierItems();

    // UseEffect hook for fetching the data after component is mounted in case the data is not loaded yet
    useEffect(() => {
        if (!supplierItems) {
            fetchSuppliers();
            fetchProducts();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [supplierItems, fetchSuppliers]);

    // The hook to monitor deleteItem value and open the prompt.
    // deleteItem value will be set if the delete icon is clicked on the ui.
    useEffect(() => {
        if (deleteItem) {
            setShowDeletePrompt(true);
        }
    }, [deleteItem]);

    /**
     * Call back function for cancel button in confirm delete item prompt.
     * resets the deleteItem value and closes the prompt
     */
    function onDeleteCanceled() {
        setDeleteItem(null);
        setShowDeletePrompt(false);
    }

    function checkAssociatedProducts(supplierId) {
        const associatedProducts = products.products.filter(p => {
            return p.supplierId === supplierId;
        });
        if (associatedProducts.length > 0) {
            return true;
        } else {
            return false;
        }
    }

    /**
     * Call back function for confirm button in confirm delete prompt.
     * sends a Thunk action to delete the item, resets the deleteItem value and closes the prompt.
     */
    function onDeleteConfirmed() {
        if (!checkAssociatedProducts(deleteItem.id)) {
            const supplierToDelete = {
                id: deleteItem.id,
                status: 3,
            };

            deleteSupplier(supplierToDelete);
        } else {
            showNotification(t('generalRequestReject'), t('supplierDeleteRejected'), 'error', onNotificationToastChanged);
        }

        setDeleteItem(null);
        setShowDeletePrompt(false);
    }

    /**
     * Call back function to pass to Toastify toast and react on its changes.
     * In this case, it sends a redux action to dismiss the results of the request.
     * @param {number} state The state of the toast returned from Toastify toasts. Will be '1' when opened and '0' when closed.
     */
    function onNotificationToastChanged(state) {
        if (state === 1) {
            dismissSupplierResults();
        }
    }

    /**
     * Call back function to open the modal for a new supplier
     */
    function openModal() {
        setModalMode('NEW');
        setActivateModal(true);
    }

    return (
        <div className="suppliers">
            <div>
                <SupplierModal
                    setActive={setActivateModal}
                    active={activateModal}
                    t={t}
                    mode={mode}
                    editItem={{ ...editItem }} // Creating a new object every time so if an item clicked twice in a row, the shallow comparisons between two objects, happening in the hooks, does not make bugs.
                    setEditItem={setEditItem}
                    createSupplier={createSupplier}
                    updateSupplier={updateSupplier}
                />
            </div>
            <div>
                <DeletePrompt
                    showDeletePrompt={showDeletePrompt}
                    onDeleteClicked={onDeleteConfirmed}
                    onCancelClicked={onDeleteCanceled}
                    message={t('supplierPromptMessage')}
                    t={t}
                />
            </div>
            {suppliers.isRequestingFinished &&
                suppliers.isError &&
                showNotification(
                    t('generalFetchError'),
                    suppliers.isDeletingFailed ? t('supplierDeleteRejected') : t('generalRequestReject'),
                    'error',
                    onNotificationToastChanged
                )}
            {suppliers.isRequestingFinished && !suppliers.isError && showNotification(null, t('requestSuccess'), 'success', onNotificationToastChanged)}
            <Row className="my-3">
                <Col className="catalog-top-row">
                    <SupplierSearchBar />
                    <div className="catalog-top-row-end-elements hide-in-mb">
                        <Button className="catalog-top-row-element" type="positive" icon="add_circle" onClick={openModal} disabled={!isAdmin}>
                            {t('createNewSupplier')}
                        </Button>
                    </div>
                </Col>
            </Row>
            {suppliers.isFetching || !suppliers.suppliers || (suppliers.suppliers && suppliers.suppliers.length === 0) ? (
                <FetchingAlert
                    fetchingMessage={t('fetchingSuppliers')}
                    noDataMessage={t('noSuppliers')}
                    errorMessage={t('suppliersFetchError')}
                    isError={suppliers.isError}
                    noData={suppliers.suppliers && suppliers.suppliers.length === 0 && !suppliers.isFetching}
                />
            ) : supplierItems.length ? (
                <SupplierList
                    t={t}
                    supplierList={supplierItems}
                    setModalMode={setModalMode}
                    setActivateModal={setActivateModal}
                    setEditItem={setEditItem}
                    setDeleteItem={setDeleteItem}
                    isAdmin={isAdmin}
                />
            ) : (
                <div className="no-results">{t('noResults')}</div>
            )}
        </div>
    );
}

/**
 * Uses SEEDS data tables to show the list of suppliers
 * @param {object} props Props for Supplier List
 * @param {function} props.t i18n translator function
 * @param {Array} props.supplierList The list of supplier items
 * @param {function} props.setModalMode React hook function to set the modal mode, 'EDIT' or 'NEW'
 * @param {function} props.setActivateModal React hook function to open the modal
 * @param {function} props.setEditItem React hook function to set the item to be edited
 * @param {function} props.setDeleteItem React hook function to set the item to be deleted
 * @param {Boolean} props.isAdmin Determins if the user has Create/Update/Delete permissions
 */
function SupplierList(props) {
    const { supplierList, setModalMode, setActivateModal, setEditItem, setDeleteItem, isAdmin } = props;

    /**
     * Call back function to react if the edit icon in front of an item is clicked
     * Sets the item to be edited, sets the modal mode and opens the modal
     * @param {object} item The item to be edited
     */
    function onEditButtonClicked(item) {
        setEditItem(item);
        setModalMode('EDIT');
        setActivateModal(true);
    }

    /**
     * Call back function to react when the delete icon is clicked  in front of an item.
     * Sets the item to be deleted using the react hook
     * @param {object} item The item to be deleted
     */
    function onDeleteButtonClicked(item) {
        setDeleteItem({ ...item });
    }

    return (
        <div className="SupplierCardContainer">
            <SupplierCards isAdmin={isAdmin} data={supplierList} deleteAction={onDeleteButtonClicked} editAction={onEditButtonClicked} />
        </div>
    );
}

/**
 * The modal for entering new supplier information or edit an existing one
 * @param {object} props Props for Supplier Modal
 * @param {function} props.t i18n translator function
 * @param {Boolean} props.active Indicates if the modal should be active (open) or not
 * @param {function} props.setActive React hook function to activate/deactivate the modal
 * @param {String} props.mode Indicated the mode of the modal. cab be 'NEW' or 'EDIT'
 * @param {Object} props.editItem The supplier to be edited
 * @param {function} props.setEditItem React hook function to set the item to be edited
 * @param {function} props.createSupplier Thunk action function for creating a new supplier
 * @param {function} props.updateSupplier Thunk action function for updating a supplier
 */
function SupplierModal(props) {
    const { t, active, setActive, mode, editItem, setEditItem, createSupplier, updateSupplier } = props;

    // React hooks to set the values of suppliers and show their value in the input components
    const [supplierName, setSupplierName] = useState('');
    const [supplierDescription, setSupplierDescription] = useState('');

    // React hooks to set the validity of entered data for name and description of entered data
    const [supplierNameValid, setSupplierNameValid] = useState(false);

    // React hook to enable/disable saving changes in case enetered data is valid/invalid
    const [dontSave, setDontSave] = useState(false);

    /**
     * The Hook for checking validity of the entered data
     */
    useEffect(() => {
        if (
            typeof supplierName === 'string' &&
            supplierName.length > 2 &&
            supplierName.length < 51 &&
            isNaN(Number(supplierName)) // Not to allow a number for the name
        ) {
            setSupplierNameValid(true);
        } else {
            setSupplierNameValid(false);
        }
    }, [supplierName]);

    /**
     * The hook for setting the values in value hooks in case a new edit item is received
     */
    useEffect(() => {
        setSupplierName(editItem.name || '');
        setSupplierDescription(editItem.description ? editItem.description.toString() : '');
    }, [editItem]);

    /**
     * Call back function to be called when save button is clicked in the modal
     * in case the input values are not valid, it will set dontSave to true and the color of the button turns red and does not react.
     */
    function onSaveButtonClicked() {
        if (!supplierNameValid) {
            setDontSave(true);
            return;
        }

        // get value and form the body
        const itemBody = {
            name: supplierName,
            description: supplierDescription,
        };

        // send them to be saved
        if (mode === 'NEW') {
            // send to create
            createSupplier(itemBody);
        } else if (mode === 'EDIT') {
            //send to update
            updateSupplier(editItem.id, itemBody);
        }
        // Close modal and clean up values
        onModalClosed();
    }

    /**
     * Cleans up the name and description from the states hooks and closes the modal
     */
    function onModalClosed() {
        setSupplierName('');
        setSupplierDescription('');
        setEditItem(null);
        setDontSave(false);
        setActive(false);
    }

    return (
        <div>
            <Modal
                active={active}
                actions={[
                    {
                        label: t('save'),
                        onClick: () => onSaveButtonClicked(),
                        type: dontSave ? 'negative' : 'primary',
                    },
                ]}
                onClose={() => onModalClosed()}
                title={mode === 'NEW' ? t('newSupplierModalTitle') : mode === 'NEW' ? t('editSupplierModalTitle') : t('supplier')}
            >
                <div className="supplier-modal">
                    <div className="supplier-name-input">
                        <span>{t('supplierName')}</span>
                        <Input
                            helpText={t('requiredText') + '3 - 50'}
                            placeholder={t('supplierHelpTextName')}
                            valid={supplierNameValid}
                            invalid={!supplierNameValid}
                            onChange={event => setSupplierName(event.target.value)}
                            value={supplierName}
                        />
                    </div>
                    <div className="supplier-description-input">
                        <span>{t('supplierDescription')}</span>
                        <Input
                            placeholder={t('supplierHelpTextDescription')}
                            onChange={event => setSupplierDescription(event.target.value)}
                            value={supplierDescription}
                        />
                    </div>
                </div>
            </Modal>
        </div>
    );
}

export default connect(
    state => ({
        suppliers: state.suppliers,
        searchQuery: state.suppliers.searchQuery,
        products: state.products,
        isAdmin: state && state.user && state.user.isAdmin,
    }),
    {
        fetchProducts,
        fetchSuppliers,
        createSupplier,
        updateSupplier,
        deleteSupplier,
        dismissSupplierResults,
    }
)(translate('main')(Suppliers));
