import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { Row } from 'reactstrap';
import { NavigationBar, TabItem, Input as SeInput, Modal } from '@storaensods/seeds-react';
import {
    fetchCabinets,
    fetchLocations,
    selectCabinet,
    setCabinetLocation,
    createLocation,
    retireLocation,
    clearStateError,
    updateLocationParent,
    updateLocationName,
    updateCabinetName,
    cabinetSearchQueryUpdated,
} from '../../actions/cabinets.js';

import { fetchOrganizationAlarms } from './../../actions/cabinetAlarms.js';
import { fetchEncodingDevices, selectEncodingDevice } from '../../actions/encodingDevices';
import Inventory from '../inventory/inventory.js';
import ControlPanel from '../controlPanel/controlPanel.js';
import './deviceManagement.css';
import '../cabinetsDropdown/cabinetsDropdown.css';
import SearchBar from './searchBar.js';

import { locationSortFunc } from './cabinetUtils/utils';
import CabinetLocationDragDropList from './cabinetDragDropList/dragDropList.jsx';
import LocationDeletePrompt from './locationDeletePrompt/locationDeletePrompt.jsx';
import { showNotification } from '../toastNotification/toastNotification.js';
import Reports from './reports.js';
import Settings from '../settings/settings.js';
import icons from './icons.svg';

/**
 * Device management page component
 */

export class DeviceManagement extends Component {
    constructor(props) {
        super(props);
        this.state = {
            newLocationString: '',
            invalidNewLocationString: true,
            panel: 'inventory',
            draggingLocation: null,
            removingLocation: null,
            addlocationModalOpen: false,
            viewDeviceList: false,
        };

        this.dropZoneDepth = 0;
    }

    componentDidMount() {
        // fetch locations if not fetched
        if (!this.props.cabinets.locationsFetched && !this.props.cabinets.locationsIsFetching) {
            this.props.fetchLocations();
        }

        // fetch cabinets if not fetched
        if (!this.props.cabinets.fetched && !this.props.cabinets.isFetching) {
            this.props.fetchCabinets();
        }

        // fetch cabinets if not fetched
        if (!this.props.encodingDevices.fetched && !this.props.encodingDevices.isFetching) {
            this.props.fetchEncodingDevices();
        }

        // fetch alarms for the selected group
        if (!this.props.alarms.isFetching && !this.props.alarms.fetched) {
            this.props.fetchOrganizationAlarms();
        }

        // select first cabinet in device list if no cabinet selected
        if (this.props.selectedCabinetDeviceCode == null && this.props.selectedEncodingDeviceCode == null && this.props.cabinets.cabinets.length > 0) {
            this.props.selectCabinet(this.props.cabinets.cabinets[0].deviceCode, this.props.cabinets.cabinets[0].id);
        }
    }

    componentDidUpdate() {
        // fetch locations if not fetched
        if (!this.props.cabinets.locationsFetched && !this.props.cabinets.locationsIsFetching) {
            this.props.fetchLocations();
        }

        // select first cabinet in device list if no cabinet selected
        if (this.props.selectedCabinetDeviceCode == null && this.props.selectedEncodingDeviceCode == null && this.props.cabinets.cabinets.length > 0) {
            this.props.selectCabinet(this.props.cabinets.cabinets[0].deviceCode, this.props.cabinets.cabinets[0].id);
        }

        // Notify user of error retiring location, with a toast message
        if (this.props.cabinets.isError && this.props.cabinets.error === 'Error retiring location') {
            const { t } = this.props;
            showNotification(t('error'), t('errorRetiringLocationMsg'), 'error');
            this.props.clearStateError();
        }
    }

    /**
     * Select cabinet to display on page
     * @param {string} deviceCode
     */
    selectDevice = ({ value: deviceCode, id: deviceId, type: deviceType }) => {
        this.setState(prevState => ({ ...prevState, viewDeviceList: false }));

        if (!deviceCode) {
            if (this.props.cabinets.cabinets.length > 0) {
                this.props.selectCabinet(this.props.cabinets.cabinets[0].deviceCode, this.props.cabinets.cabinets[0].id);
            } else {
                this.props.selectCabinet(null, null);
            }
        } else {
            if (deviceType === 'smartCabinet') {
                this.props.selectEncodingDevice(null, null);
                this.props.selectCabinet(deviceCode, deviceId);
            } else if (deviceType === 'encodingStation') {
                this.props.selectCabinet(null, null);
                this.props.selectEncodingDevice(deviceCode, deviceId);
            } else if (deviceType === 'selflyEncode') {
                this.props.selectCabinet(null, null);
                this.props.selectEncodingDevice(deviceCode, deviceId);
            }
        }
    };

    /**
     * Call for new location name input change
     * @param {object} event
     */
    handleNewLocationInputChange = event => {
        const locations = this.props.cabinets.locations;
        const value = event.target.value;

        if (value === '') {
            return this.setState(prevState => ({
                ...prevState,
                newLocationString: '',
                invalidNewLocationString: true,
            }));
        }

        if (locations.map(location => location.name).includes(value)) {
            return this.setState(prevState => ({
                ...prevState,
                newLocationString: value,
                invalidNewLocationString: true,
            }));
        }

        return this.setState(prevState => ({
            ...prevState,
            newLocationString: value,
            invalidNewLocationString: false,
        }));
    };

    /**
     * Trigger to add new location with name that is contained in new location input
     */
    addNewLocationSubmit = () => {
        const { newLocationString, invalidNewLocationString } = this.state;
        const { createLocation } = this.props;

        if (invalidNewLocationString) {
            return;
        }

        this.setState(prevState => ({
            ...prevState,
            invalidNewLocationString: true,
            newLocationString: '',
            addlocationModalOpen: false,
        }));

        createLocation(newLocationString);
    };

    changePanel = panel => {
        return this.setState(prevState => ({
            ...prevState,
            panel: panel,
        }));
    };

    /**
     * Takes location object as input. Signals prompt to open to confirm retiring given location.
     * @param {object} location
     */
    startRemoveLocationPrompt = location => {
        this.setState(prevState => ({
            ...prevState,
            removingLocation: location,
        }));
    };

    /**
     * Signals to close retire location prompt.
     */
    clearRemoveLocationPrompt = () => {
        this.setState(prevState => ({
            ...prevState,
            removingLocation: null,
        }));
    };

    /**
     * Retires location and closes retire location prompt
     * @param {string} locationCode
     */
    removeLocation = locationCode => {
        this.props.retireLocation(locationCode);
        this.clearRemoveLocationPrompt();
    };

    /**
     * Checks if the name user chose for a location is duplicated.
     * @param {string} name The target name which a location should be renamed to
     * @returns true if there is another location for this organization with the same name
     */
    isLocationNameDuplicated = name => {
        if (!this.props.cabinets || !this.props.cabinets.locations) {
            return false;
        }
        return this.props.cabinets.locations.some(location => location.name.replace(/\s+/g, '').toLowerCase() === name.replace(/\s+/g, '').toLowerCase());
    };

    canSeeSettings(user) {
        const roles = user.profile.roles;
        const allowedRolesToSeeTransactions = ['Cabinet Operator', 'Administrator'];
        const canSeeSettings = (Array.isArray(roles) ? roles.filter(role => allowedRolesToSeeTransactions.includes(role)) : []).length > 0;
        return canSeeSettings;
    }

    isPanelVisible() {
        if (this.props.selectedEncodingDeviceCode) return false;
        else if (this.props.selectedCabinetDeviceCode) return true;
    }

    render() {
        const { t, cabinets, searchQuery, encodingDevices, user } = this.props;
        // filter cabinets
        const filteredDevices = [...cabinets.cabinets, ...encodingDevices.encodingDevices].filter(device => {
            if (!searchQuery) {
                return true;
            }
            return (
                (device.name || '').toLowerCase().indexOf(searchQuery.toLowerCase()) !== -1 ||
                (device.deviceCode || '').toLowerCase().indexOf(searchQuery.toLowerCase()) !== -1 ||
                (device.id || '').toLowerCase().indexOf(searchQuery.toLowerCase()) !== -1
            );
        });

        if (this.state.panel !== 'controlPanel' && this.props.selectedEncodingDeviceCode && !this.props.selectedCabinetDeviceCode) {
            this.changePanel('controlPanel');
        }
        return (
            <div className="container-fluid">
                {this.state.addlocationModalOpen ? (
                    <Modal
                        active={this.state.addlocationModalOpen}
                        title="Add new location"
                        actions={[
                            {
                                label: t('save'),
                                onClick: () => this.addNewLocationSubmit(),
                            },
                            {
                                label: t('close'),
                                onClick: () => this.setState(prevState => ({ ...prevState, addlocationModalOpen: false })),
                            },
                        ]}
                        onClose={() => this.setState(prevState => ({ ...prevState, addlocationModalOpen: false }))}
                    >
                        <p>Please provide a name of the new location</p>
                        <SeInput
                            className="pl-3"
                            size="sm"
                            placeholder={t('location')}
                            value={this.state.newLocationString}
                            onChange={this.handleNewLocationInputChange}
                        />
                    </Modal>
                ) : null}
                {this.state.removingLocation ? (
                    <LocationDeletePrompt
                        removeLocation={this.removeLocation}
                        clearRemoveLocationPrompt={this.clearRemoveLocationPrompt}
                        removingLocation={this.state.removingLocation}
                        t={t}
                    />
                ) : null}
                <Row className="mt-3">
                    <div className="device-dropdown" onClick={() => this.setState(prevState => ({ ...prevState, viewDeviceList: !prevState.viewDeviceList }))}>
                        <span>
                            {this.props.cabinet?.name ||
                                this.props.encodingDevices.encodingDevices.find(device => device.deviceCode === this.props.selectedEncodingDeviceCode)?.name}
                        </span>
                        <span className={`${this.state.viewDeviceList ? 'device-dropdown-open' : ''}`}>
                            <svg>
                                <use xlinkHref={`${icons}#icon-arrow_drop_down`}></use>
                            </svg>
                        </span>
                    </div>
                    <div className={`not-printable device-list-section ${this.state.viewDeviceList ? 'device-list-open' : ''}`}>
                        <div className="device-list-topbar">
                            <SearchBar />
                            {user.isAdmin && (
                                <div
                                    size="sm"
                                    className="ml-2 add-location-button hide-in-mb"
                                    onClick={() => {
                                        this.setState(prevState => ({ ...prevState, addlocationModalOpen: true }));
                                    }}
                                >
                                    {t('addRegion')}
                                </div>
                            )}
                        </div>
                        <div className="all-location-devices">
                            <CabinetLocationDragDropList
                                locationIn={null}
                                filteredDevices={filteredDevices}
                                rootLocation={true}
                                filtering={searchQuery ? true : false}
                                cabinetSearchQueryUpdated={cabinetSearchQueryUpdated}
                                cabinets={this.props.cabinets.cabinets}
                                selectedCabinetDeviceCode={this.props.selectedCabinetDeviceCode}
                                selectedEncodingDeviceCode={this.props.selectedEncodingDeviceCode}
                                setCabinetLocation={this.props.setCabinetLocation}
                                updateLocationParent={this.props.updateLocationParent}
                                updateLocationName={this.props.updateLocationName}
                                updateCabinetName={this.props.updateCabinetName}
                                startRemoveLocationPrompt={this.startRemoveLocationPrompt}
                                selectDevice={this.selectDevice}
                                isLocationNameDuplicated={this.isLocationNameDuplicated}
                                t={t}
                                isAdmin={user.isAdmin}
                                alarms={this.props.alarms}
                            />
                            {cabinets.locationsTree.sort(locationSortFunc).map((location, index) => {
                                return (
                                    <CabinetLocationDragDropList
                                        locationIn={location}
                                        filteredDevices={filteredDevices}
                                        rootLocation={true}
                                        filtering={searchQuery ? true : false}
                                        cabinetSearchQueryUpdated={cabinetSearchQueryUpdated}
                                        cabinets={this.props.cabinets.cabinets}
                                        selectedCabinetDeviceCode={this.props.selectedCabinetDeviceCode}
                                        selectedEncodingDeviceCode={this.props.selectedEncodingDeviceCode}
                                        setCabinetLocation={this.props.setCabinetLocation}
                                        updateLocationParent={this.props.updateLocationParent}
                                        updateLocationName={this.props.updateLocationName}
                                        updateCabinetName={this.props.updateCabinetName}
                                        startRemoveLocationPrompt={this.startRemoveLocationPrompt}
                                        selectDevice={this.selectDevice}
                                        isLocationNameDuplicated={this.isLocationNameDuplicated}
                                        key={index}
                                        t={t}
                                        isAdmin={user.isAdmin}
                                        alarms={this.props.alarms}
                                    />
                                );
                            })}
                        </div>
                    </div>
                    <div className="device-controller-section pr-3">
                        <NavigationBar className="mb-3 not-printable">
                            {this.isPanelVisible() && (
                                <TabItem
                                    onClick={this.changePanel.bind(this, 'inventory')}
                                    isActive={this.state.panel === 'inventory'}
                                    className="device-tab-item"
                                >
                                    {this.props.isInventoryAlarmRaised ? <i className="material-icons se-icon text-danger mr-1">error_outline</i> : null}
                                    {t('inventoryTitle')}
                                </TabItem>
                            )}
                            <TabItem
                                onClick={this.changePanel.bind(this, 'controlPanel')}
                                isActive={this.state.panel === 'controlPanel'}
                                className="device-tab-item"
                            >
                                {t('controlPanelTitle')}
                            </TabItem>
                            {this.isPanelVisible() && (
                                <TabItem onClick={this.changePanel.bind(this, 'reports')} isActive={this.state.panel === 'reports'} className="device-tab-item">
                                    {t('navigation:reports')}
                                </TabItem>
                            )}

                            {this.isPanelVisible() && this.canSeeSettings(user) && (
                                <TabItem
                                    onClick={this.changePanel.bind(this, 'settings')}
                                    isActive={this.state.panel === 'settings'}
                                    className="device-tab-item"
                                >
                                    {t('translations:settings')}
                                </TabItem>
                            )}
                        </NavigationBar>
                        {this.isPanelVisible() && this.state.panel === 'inventory' && <Inventory />}
                        {this.state.panel === 'controlPanel' && <ControlPanel canSeeSettings={this.canSeeSettings(user)} />}
                        {this.state.panel === 'reports' && <Reports user={user} />}
                        {this.isPanelVisible() && this.canSeeSettings(user) && this.state.panel === 'settings' && <Settings t={t} />}
                    </div>
                </Row>
            </div>
        );
    }
}

export default withRouter(
    connect(
        state => ({
            cabinets: state.cabinets,
            encodingDevices: state.encodingDevices,
            selectedCabinetDeviceCode: state.cabinets.selectedCabinetDeviceCode,
            selectedCabinetDeviceId: state.cabinets.selectedCabinetDeviceId,
            selectedEncodingDeviceCode: state.encodingDevices.selectedEncodingDeviceCode,
            selectedEncodingDeviceId: state.encodingDevices.selectedEncodingDeviceId,
            cabinet: state.cabinets.cabinets.find(cabinet => cabinet.deviceCode === state.cabinets.selectedCabinetDeviceCode),
            isInventoryAlarmRaised: state.alarmIcon.isInventoryAlarmRaised,
            searchQuery: state.cabinets.searchQuery,
            user: state.user,
            alarms: state.alarms,
        }),
        {
            fetchCabinets,
            fetchEncodingDevices,
            fetchLocations,
            selectCabinet,
            selectEncodingDevice,
            setCabinetLocation,
            createLocation,
            retireLocation,
            clearStateError,
            updateLocationParent,
            updateLocationName,
            updateCabinetName,
            cabinetSearchQueryUpdated,
            fetchOrganizationAlarms,
        }
    )(translate('main')(DeviceManagement))
);
