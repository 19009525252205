import React from 'react';
import { Modal } from '@storaensods/seeds-react';

function LocationDeletePrompt(props) {
    const { removeLocation, removingLocation, clearRemoveLocationPrompt, t } = props;
    return (
        <Modal
            actions={[
                {
                    label: 'YES',
                    onClick: () => removeLocation(removingLocation.code),
                },
                {
                    label: 'NO',
                    onClick: clearRemoveLocationPrompt,
                },
            ]}
            active={removingLocation ? true : false}
            onClose={clearRemoveLocationPrompt}
            title={t('retireLocation')}
        >
            <div>{`Are you sure you want to retire location "${removingLocation.name}"?`}</div>
        </Modal>
    );
}

export default LocationDeletePrompt;
