import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { translate } from 'react-i18next';
import { Col, Row } from 'reactstrap';
import Products from '../products/products';
import ProductCategories from '../productCategories/productCategories';
import VatCategories from '../vatCategories/VatCategories';
import Suppliers from '../suppliers/suppliers';
import icons from './icons.svg';
import './productCatalog.css';

function ProductCatalog({ t }) {
    const [viewType, setViewType] = useState('products');
    const [sidebarOpen, setSidebarOpen] = useState(true);
    const [viewDropDownList, setViewDropDownList] = useState(false);

    // When the product form is open, the sidebar will be closed
    const handleProductForm = isOpen => {
        setSidebarOpen(!isOpen);
    };

    const changePageView = view => {
        setViewType(view);
        setViewDropDownList(false);
    };

    return (
        <div className="container-fluid" style={{ display: 'flex' }}>
            <Row style={{ flexGrow: '1' }}>
                {sidebarOpen && (
                    <Col lg="2" className={`sidebar-fixed ${viewDropDownList ? 'sidebar-fixed--open' : ''}`}>
                        <Row className="sidebar-title">{t('productsTitle')}</Row>
                        <Row className="sidebar-title-sm" onClick={() => setViewDropDownList(!viewDropDownList)}>
                            <span> {t('productsTitle')} </span>
                            <svg className={`${viewDropDownList ? 'dropdown--open' : ''}`}>
                                <use xlinkHref={`${icons}#icon-arrow_drop_down`}></use>
                            </svg>
                        </Row>
                        <div className={`sidebar-contents ${viewDropDownList ? 'sidebar-content--open' : ''}`}>
                            <Row
                                onClick={() => changePageView('products')}
                                className={`${viewType === 'products' ? 'sidebar-option-selected' : 'sidebar-option'}`}
                            >
                                <svg className="icon icon-file-text sidebar-icon">
                                    <use xlinkHref={`${icons}#icon-file-text`}></use>
                                </svg>
                                {t('products')}
                            </Row>
                            <Row
                                onClick={() => changePageView('productCategories')}
                                className={`${viewType === 'productCategories' ? 'sidebar-option-selected' : 'sidebar-option'}`}
                            >
                                <svg className="icon icon-drawer sidebar-icon">
                                    <use xlinkHref={`${icons}#icon-drawer`}></use>
                                </svg>
                                {t('categories')}
                            </Row>
                            <Row
                                onClick={() => changePageView('vatCategories')}
                                className={`${viewType === 'vatCategories' ? 'sidebar-option-selected' : 'sidebar-option'}`}
                            >
                                <svg className="icon icon-price-tags sidebar-icon">
                                    <use xlinkHref={`${icons}#icon-price-tags`}></use>
                                </svg>
                                {t('vats')}
                            </Row>
                            <Row
                                onClick={() => changePageView('suppliers')}
                                className={`${viewType === 'suppliers' ? 'sidebar-option-selected' : 'sidebar-option'}`}
                            >
                                <svg className="icon icon-office sidebar-icon">
                                    <use xlinkHref={`${icons}#icon-office`}></use>
                                </svg>
                                {t('suppliers')}
                            </Row>
                        </div>
                    </Col>
                )}
                <Col lg="10">
                    {viewType === 'products' && <Products handleProductForm={handleProductForm} />}

                    {viewType === 'productCategories' && <ProductCategories />}

                    {viewType === 'vatCategories' && <VatCategories />}

                    {viewType === 'suppliers' && <Suppliers />}
                </Col>
            </Row>
        </div>
    );
}

export default withRouter(translate('main')(ProductCatalog));
