import React, { Component } from 'react';
import { Spinner, NavigationBar, TabItem } from '@storaensods/seeds-react';
import { Row, Col } from 'reactstrap';
import DashboardSelector from './dashboardSelector.js';
import { storeDashboardSelectorSetting, getDashboardSelectorSetting, storeDashboardDefaultView, getDashboardDefaultView } from '../../localStorage.js';
import SalesDashboard from './salesDashboard';
import DiscountDashboard from './discountDashboard';
import WasteDashboard from './wasteDashboard/';
import ConsumerResponseDashboard from './consumerResponseDashboard';
/**
 * Analytics page component
 * @param {function} fetchDashboardData Redux action
 * @param {function} fetchDashboardChartData Redux action
 * @param {object} dashboard Redux analytics dashboard state
 * @param {function} t Translations function
 * @param {array} regions
 * @param {array} cabinets
 */
export class Dashboard extends Component {
    constructor(props) {
        super(props);

        this.state = {
            dateRange: 'Last 7 days',
            filter: null,
            selectedCard: 'sales',
            dashboardView: 'sales',
        };

        const prevState = getDashboardSelectorSetting();
        const prevDashboardView = getDashboardDefaultView();

        if (prevState) {
            this.state.dateRange = prevState.dateRange;
            this.state.filter = prevState.filter;
            this.state.selectedCard = prevState.selectedCard;
        }

        if (prevDashboardView) {
            this.state.dashboardView = prevDashboardView;
        }
    }

    fetchCorrespondingTabData = (dashboardView = null, dateRange = null, filter = null, selectedCard = null, forced = false) => {
        const { dashboard, wasteDashboard, consumerResponseDashboard, discountDashboardState } = this.props;
        if (!dashboardView) {
            dashboardView = this.state.dashboardView;
        }
        if (!dateRange) {
            dateRange = this.state.dateRange;
            filter = this.state.filter;
            selectedCard = this.state.selectedCard;
        }

        if (dashboardView === 'sales' && (forced || (!dashboard.isFetching && !dashboard.fetched))) {
            this.props.fetchDashboardData(dateRange, filter, selectedCard);
        } else if (dashboardView === 'waste' && (forced || (!wasteDashboard.isFetching && !wasteDashboard.fetched))) {
            this.props.fetchWasteDashboardData(dateRange, filter);
        } else if (dashboardView === 'consumerResponses' && (forced || (!consumerResponseDashboard.isFetching && !consumerResponseDashboard.fetched))) {
            this.props.fetchConsumerResponseDashboardData(dateRange, filter);
        } else if (dashboardView === 'discount' && (forced || (!discountDashboardState.isFetching && !discountDashboardState.fetched))) {
            this.props.fetchDiscountDashboardData(dateRange, filter);
        }
    };
    componentDidMount() {
        this.fetchCorrespondingTabData();
    }

    runQuery = (dateRange, filter = null) => {
        const { selectedCard, dashboardView } = this.state;
        this.props.resetAnalyticsData();
        this.setState(() => ({ dateRange, filter }));
        storeDashboardSelectorSetting(dateRange, filter, selectedCard); // save query in localstorage
        this.fetchCorrespondingTabData(dashboardView, dateRange, filter, selectedCard, true);
    };

    onCardClick = clickedCard => {
        const { dateRange, filter } = this.state;
        this.setState(() => ({ selectedCard: clickedCard }));
        this.props.fetchDashboardChartData(clickedCard, dateRange, filter);
        storeDashboardSelectorSetting(dateRange, filter, clickedCard); // save query in localstorage
    };

    changeChartGranularity = granularity => {
        const { dateRange, filter, selectedCard } = this.state;
        this.props.fetchDashboardChartData(selectedCard, dateRange, filter, granularity);
    };

    changeDashboard = dashboardView => {
        this.setState({
            dashboardView,
        });

        storeDashboardDefaultView(dashboardView);
        this.fetchCorrespondingTabData(dashboardView);
    };

    render() {
        const { t, dashboard, locations, cabinets, changePanel, wasteDashboard, consumerResponseDashboard, discountDashboardState } = this.props;
        const { filter, dateRange, dashboardView } = this.state;
        let isFetching = dashboard.isFetching;
        let fetched = dashboard.fetched;
        let isError = dashboard.isError;
        if (dashboardView === 'waste') {
            isFetching = wasteDashboard.isFetching;
            fetched = wasteDashboard.fetched;
            isError = wasteDashboard.isError;
        } else if (dashboardView === 'consumerResponses') {
            isFetching = consumerResponseDashboard.isFetching;
            fetched = consumerResponseDashboard.fetched;
            isError = consumerResponseDashboard.isError;
        } else if (dashboardView === 'discount') {
            //discount
            isFetching = discountDashboardState.isFetching;
            fetched = discountDashboardState.fetched;
            isError = discountDashboardState.isError;
        }

        return (
            <div>
                <Row className="mt-2">
                    <Col xs="12">
                        <DashboardSelector
                            t={t}
                            locations={locations}
                            cabinets={cabinets}
                            fetching={isFetching}
                            runQuery={this.runQuery.bind(this)}
                            initDateRange={dateRange}
                            initFilter={filter}
                            changePanel={changePanel}
                        />
                    </Col>
                </Row>

                <NavigationBar className="mb-3 mt-3 not-printable">
                    <TabItem className="device-tab-item" isActive={this.state.dashboardView === 'sales'} onClick={this.changeDashboard.bind(this, 'sales')}>
                        {t('sales')}
                    </TabItem>
                    <TabItem
                        className="device-tab-item"
                        isActive={this.state.dashboardView === 'discount'}
                        onClick={this.changeDashboard.bind(this, 'discount')}
                    >
                        {t('discounts')}
                    </TabItem>
                    <TabItem className="device-tab-item" isActive={this.state.dashboardView === 'waste'} onClick={this.changeDashboard.bind(this, 'waste')}>
                        {t('waste')}
                    </TabItem>
                    <TabItem
                        className="device-tab-item"
                        isActive={this.state.dashboardView === 'consumerResponses'}
                        onClick={this.changeDashboard.bind(this, 'consumerResponses')}
                    >
                        {t('consumerResponses')}
                    </TabItem>
                </NavigationBar>

                {isError || isFetching || !fetched ? (
                    <div>
                        {isError ? (
                            <div>{t('fetchingError')}</div>
                        ) : (
                            <div className="d-flex justify-content-center pt-3">
                                <Spinner />
                            </div>
                        )}
                    </div>
                ) : (
                    <div style={{ backgroundColor: '#F8F8F9' }} className="mb-3 mt-4">
                        {this.state.dashboardView === 'sales' && (
                            <SalesDashboard
                                dashboard={dashboard}
                                t={t}
                                selectedCard={this.state.selectedCard}
                                onCardClick={this.onCardClick}
                                changeChartGranularity={this.changeChartGranularity}
                            />
                        )}
                        {this.state.dashboardView === 'discount' && (
                            <DiscountDashboard
                                fetchDiscountDashboardData={this.props.fetchDiscountDashboardData}
                                discountDashboardState={this.props.discountDashboardState}
                                dashboardState={this.state}
                                t={t}
                            />
                        )}
                        {this.state.dashboardView === 'waste' && (
                            <WasteDashboard
                                fetchWasteDashboardData={this.props.fetchWasteDashboardData}
                                wasteDashboardData={this.props.wasteDashboard}
                                dashboardState={this.state}
                                chart={this.props.chart}
                                t={t}
                            />
                        )}
                        {this.state.dashboardView === 'consumerResponses' && (
                            <ConsumerResponseDashboard
                                fetchConsumerResponseDashboardData={this.props.fetchConsumerResponseDashboardData}
                                consumerResponseDashboardData={this.props.consumerResponseDashboard}
                                dashboardState={this.state}
                                chart={this.props.chart}
                                t={t}
                            />
                        )}
                    </div>
                )}
            </div>
        );
    }
}
