import React, { useState, useEffect, useRef } from 'react';
import icons from '../styles/icons.svg';
import { Button, NavigationBar, TabItem, Icon, Spinner, Checkbox } from '@storaensods/seeds-react';
import { withRouter, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { ToastNotification } from '../../toastNotification/toastNotification';
import { Row, Col, Modal, ModalBody, Table, ModalFooter } from 'reactstrap';
import Select from 'react-select';
import { updateOneOrMoreDeviceUpdate, removeUpdateScheduling, RECEIVE_DEVICE_UPDATE_RESPONSE_ERROR } from '../../../actions/releaseVersions';
import { showNotification } from '../../toastNotification/toastNotification';
import { compareVersions, compare } from 'compare-versions';
import moment from 'moment';
import { orderBy } from 'lodash';

const SoftwareVersions = props => {
    const { t, releaseVersions, newerVersions = [], organization } = props;
    const [activeTab, setActiveTab] = useState('IF2');
    const [selectedDevices, setSelectedDevices] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedVersion, setSelectedVersion] = useState({});
    const [confirmationModal, setConfirmationModal] = useState(false);
    const [versions, setVersions] = useState([]);
    const [releaseNotesOpen, setReleaseNotesOpen] = useState(false);
    const [versionOptions, setVersionOptions] = useState([]);
    const [selectedUpdateTiming, setSelectedUpdateTiming] = useState('now');
    const [updateRemovalScheduleModalOpen, setUpdateRemovalScheduleModalOpen] = useState(false);
    const [selectedScheduleIdForRemoval, setSelectedScheduleIdForRemoval] = useState(null);
    const [sorting, setSorting] = useState({});
    const [filters, setFilters] = useState([]);
    let history = useHistory();
    const prevOrganization = usePrevious(organization);
    function usePrevious(value) {
        const ref = useRef();
        useEffect(() => {
            ref.current = value;
        }, [value]);
        return ref.current;
    }

    useEffect(() => {
        if (releaseVersions.fetching) setLoading(true);
        if (releaseVersions.fetched) {
            let vers = filterByDevice(newerVersions);
            setVersions(vers);
            distinctVersionNumbers(vers);
            setLoading(false);
        }
        if (filters.length) filterBy();
        if (prevOrganization !== organization) {
            setFilters([]);
            setSelectedDevices([]);
            setSelectedVersion({});
            setReleaseNotesOpen(false);
            setSorting({});
            setSelectedUpdateTiming('now');
        }
        if (Object.keys(sorting).length && !filters.length) sortVersions();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [releaseVersions, sorting, filters, organization, prevOrganization]);

    const filterByDevice = devices => {
        return devices.filter(version => {
            switch (activeTab) {
                case 'IF3':
                case 'IF2':
                case 'IF3Freezer':
                    return version.deviceSubType === activeTab;
                case 'encodingStation':
                    return version.deviceType === activeTab;
                default:
                    return false;
            }
        });
    };

    const filterBy = () => {
        let filteredVersions = newerVersions ? [...newerVersions] : [];
        filteredVersions = filterByDevice(filteredVersions);
        if (Object.keys(sorting).length) filteredVersions = sortVersions(true, filteredVersions);
        filters.forEach(filter => {
            switch (filter.type) {
                case 'deviceLocation':
                    filteredVersions = filteredVersions.filter(version => filter.value.some(filter => version.deviceLocation === filter.value));
                    break;
                case 'input':
                    filteredVersions = filteredVersions.filter(version => {
                        if (
                            version.deviceName.toLowerCase().includes(filter.value.toLowerCase()) ||
                            version.deviceLocation?.toLowerCase().includes(filter.value.toLowerCase())
                        ) {
                            return true;
                        }
                        return false;
                    });
                    break;
                case 'version':
                    if (filter.value !== 'All') {
                        filteredVersions = filteredVersions.filter(version => version.newerVersions.some(nV => nV.version === filter.value));
                    }
                    break;
                default:
                    break;
            }
            return null;
        });
        document.getElementById('selectAllVersionsCheckbox').checked = false;
        setSelectedDevices([]);

        setVersions(filteredVersions);
    };

    const sortVersions = (returnValue = false, givenVersions = undefined) => {
        let versionArray;
        let orderedArray;
        if (givenVersions) versionArray = givenVersions;
        else versionArray = versions;
        if (Object.keys(sorting).length > 0) {
            const fieldArray = [];
            const orderArray = [];
            const sortables = Object.entries(sorting);
            sortables.forEach(s => {
                if (s[0] === 'deviceName') fieldArray.push(device => device.deviceName.toLowerCase());
                if (s[0] === 'deviceLocation') fieldArray.push(device => device.deviceLocation?.toLowerCase());
                else if (s[0]) fieldArray.push(s[0]);
                if (s[1]) orderArray.push(s[1]);
            });

            if (fieldArray[0] === 'currentVersion') {
                orderedArray = versionArray.sort((a, b) => {
                    if (orderArray[0] === 'asc') return compareVersions(a.currentVersion, b.currentVersion, '>');
                    if (orderArray[0] === 'desc') return compareVersions(b.currentVersion, a.currentVersion, '>');
                    return 1;
                });
            } else {
                orderedArray = orderBy(versionArray, fieldArray, orderArray);
            }
            if (!returnValue) setVersions(orderedArray);
            else return orderedArray;
        } else return;
    };

    const selectVersionForUpdate = (checked, versionObj) => {
        const stateVersions = [...selectedDevices];
        if (versionObj === 'all' && checked) setSelectedDevices(versions);
        else if (versionObj === 'all' && !checked) setSelectedDevices([]);
        else if (versionObj !== 'all' && checked) setSelectedDevices([...stateVersions, versionObj]);
        else if (versionObj !== 'all' && !checked) {
            setSelectedDevices([...stateVersions.filter(v => v.deviceName !== versionObj.deviceName)]);
        }
    };

    const distinctVersionNumbers = deviceTypeVersions => {
        if (deviceTypeVersions && deviceTypeVersions.length) {
            let allNewVersions = {};
            deviceTypeVersions.forEach(v => {
                v.newerVersions.forEach(ver => {
                    if (!allNewVersions[v.deviceOsVersion]) allNewVersions[v.deviceOsVersion] = ver.version;
                    else {
                        if (compare(ver.version, allNewVersions[v.deviceOsVersion], '>')) allNewVersions[v.deviceOsVersion] = ver.version;
                    }
                });
            });
            allNewVersions = Object.values(allNewVersions).map(version => version);

            const distinctVersions = [...new Set(allNewVersions)];

            let sortedVersions = distinctVersions.sort(compareVersions).reverse();
            sortedVersions = sortedVersions.map(v => {
                return { label: v, value: v };
            });

            setVersionOptions([{ value: 'All', label: t('softwareVersions:allVersionNumbers') }, ...sortedVersions]);
        } else {
            setVersionOptions([{ value: 'All', label: t('softwareVersions:allVersionNumbers') }]);
        }
    };

    const updateVersions = () => {
        // note: to reduce the payload, selectedDevices reduced to a new list that includes only deviceId property.
        const applicableDevices = selectedDevices
            .filter(d => isSelectedVersionApplicable(d))
            .map(sd => {
                return { deviceId: sd.deviceId };
            });

        const releaseObj = {
            devices: applicableDevices,
            release: selectedVersion.version,
            scheduling: selectedUpdateTiming,
        };

        props.updateOneOrMoreDeviceUpdate(releaseObj).then(response => {
            setSelectedDevices([]);
            document.getElementById('selectAllVersionsCheckbox').checked = false;

            if (response && response.type && response.type === RECEIVE_DEVICE_UPDATE_RESPONSE_ERROR) {
                showNotification(t('softwareVersions:updateNotificationTitle'), t('generalRequestReject'), 'error');
            } else {
                showNotification(t('softwareVersions:updateNotificationTitle'), t('softwareVersions:updateNotificationContent'), 'success');
            }
        });
    };

    const versionNumberOnChange = selectedOption => {
        let newFilters = [...filters];
        let existingFilter = filters.find(filter => filter.type === 'version');
        if (existingFilter) {
            existingFilter.value = selectedOption.value;
            newFilters = [existingFilter];
        } else newFilters = [{ type: 'version', value: selectedOption.value }];
        document.getElementById('selectAllVersionsCheckbox').checked = false;
        setSelectedDevices([]);

        if (selectedOption.value === 'All') {
            setSelectedVersion({});
            setFilters(newFilters);
            setReleaseNotesOpen(false);
        } else {
            const allVersions = [];
            newerVersions.forEach(v => {
                v.newerVersions.forEach(ver => allVersions.push(ver));
            });
            const release = allVersions.find(v => v.version === selectedOption.value);
            const releaseVersionObj = {};
            if (release) releaseVersionObj.releaseContent = release.releaseContent;
            releaseVersionObj.version = selectedOption.value;

            setSelectedVersion(releaseVersionObj);
            setFilters(newFilters);
            setReleaseNotesOpen(true);
        }
    };

    const isLink = string => {
        if (/https|http|www/.test(string)) return true;
        else return false;
    };

    const isSelectedVersionApplicable = rowVersion => {
        const applicableVersionNumbers = rowVersion.newerVersions.map(ver => ver.version);
        if (
            (!rowVersion.scheduling.length || rowVersion.scheduling.every(s => s.status === 'DONE')) &&
            (!selectedVersion.version || selectedVersion.version === 'All' || applicableVersionNumbers.includes(selectedVersion?.version))
        ) {
            return true;
        } else return false;
    };

    const closeSettings = () => {
        history.push('/');
    };

    const changeDeviceTab = tab => {
        setActiveTab(tab);
        setSelectedVersion({});
        setReleaseNotesOpen(false);
        setSelectedDevices([]);
        setVersions(filterByDevice(newerVersions));
        setFilters([]);
        setSelectedUpdateTiming('now');
        document.getElementById('selectAllVersionsCheckbox').checked = false;
    };

    const distinctLocations = () => {
        let vers = filterByDevice(newerVersions);
        return [...new Set((vers || []).map(item => item.deviceLocation))]
            .map(v => {
                return {
                    value: v,
                    label: v,
                };
            })
            .filter(l => l.value);
    };

    const checkIfAnyDevicesCanBeUpdated = () => {
        if (!selectedDevices.length || !selectedVersion.version) return false;
        else if (selectedVersion.version && selectedDevices.some(v => isSelectedVersionApplicable(v))) return true;
        else if (selectedVersion.version && !selectedDevices.some(v => isSelectedVersionApplicable(v))) return false;
    };

    const getReleaseContentWidth = () => {
        if (selectedVersion.version && releaseNotesOpen) return 6;
        else if (selectedVersion.version && !releaseNotesOpen) return 1;
        else if (!selectedVersion.version && !releaseNotesOpen) return 0;
    };
    const getSoftwareVersionSideWidth = () => {
        if (selectedVersion.version && releaseNotesOpen) return 6;
        else if (selectedVersion.version && !releaseNotesOpen) return 11;
        else if (!selectedVersion.version && !releaseNotesOpen) return 12;
    };
    const getTimeSlotsForUpdate = () => {
        const timeslotArray = [{ value: 'now', label: t('now') }];
        let now = moment().clone();
        now = now.add(1, 'days');
        let endDate = moment().add(7, 'days');
        while (now.isBefore(endDate)) {
            const dayDateString = now.format('DD/MM/YYYY');
            const nightDateString = now.format('DD/MM/YYYY');
            timeslotArray.push(
                { label: nightDateString.concat(' - 1AM UTC'), value: nightDateString.concat('_night') },
                { label: dayDateString.concat(' - 4PM UTC'), value: dayDateString.concat('_day') }
            );
            now.add(1, 'days');
        }
        return timeslotArray;
    };
    const generateSchedulingString = device => {
        const activeScheduling = device.scheduling && device.scheduling.find(s => s.status === 'NOT_DONE');
        if (activeScheduling) {
            let schedulingString = activeScheduling.schedule;
            let date = schedulingString.split('_')[0];
            let timeslot = schedulingString.split('_')[1];
            let dateString = `${date} - ${timeslot}`;
            return t('softwareVersions:scheduledUpdateNote', { scheduled: dateString, version: activeScheduling.version });
        } else return null;
    };

    const removeUpdateScheduling = () => {
        props.removeUpdateScheduling(selectedScheduleIdForRemoval).then(() => {
            showNotification(t('softwareVersions:scheduleRemoveNotificationTitle'), t('softwareVersions:scheduleRemoveNotificationContent'), 'success');
            setSelectedUpdateTiming('now');
        });
    };

    const sortByColumn = column => {
        if (!sorting[column] && Object.keys(sorting).length === 1) {
            const emptyObj = {};
            emptyObj[column] = 'asc';
            setSorting(emptyObj);
        } else {
            if (!sorting[column]) setSorting({ ...sorting, [column]: 'asc' });
            if (sorting[column] === 'asc') setSorting({ ...sorting, [column]: 'desc' });
            if (sorting[column] === 'desc') {
                delete sorting[column];
                setSorting({ ...sorting });
            }
        }
    };

    const getSortIcon = column => {
        if (sorting[column] && sorting[column] === 'asc') return <Icon>arrow_drop_up</Icon>;
        if (sorting[column] && sorting[column] === 'desc') return <Icon>arrow_drop_down</Icon>;
        if (!sorting[column]) return;
    };
    return (
        <>
            {confirmationModal && (
                <Modal size="lg" isOpen={confirmationModal} centered={true}>
                    <ModalBody>
                        <h5>{t('softwareUpdateConfirmation')}</h5>
                        <p className="software-version-modal-body">{t('softwareUpdateWarn')}</p>
                        <Row>
                            <Col lg={6} md={6}>
                                <Select
                                    options={getTimeSlotsForUpdate()}
                                    onChange={({ value }) => {
                                        setSelectedUpdateTiming(value);
                                    }}
                                    defaultValue={{ value: 'now', label: t('now') }}
                                ></Select>
                            </Col>
                            <Col lg={2} md={2}>
                                <Button
                                    color="primary"
                                    icon="update"
                                    type="attention"
                                    onClick={() => {
                                        updateVersions();
                                        setConfirmationModal(false);
                                    }}
                                >
                                    Update
                                </Button>
                            </Col>
                            <Col lg={2} md={2}>
                                <Button
                                    color="secondary"
                                    icon="close"
                                    onClick={() => {
                                        setConfirmationModal(false);
                                    }}
                                >
                                    {confirmationModal && t('cancel')}
                                </Button>
                            </Col>
                        </Row>
                    </ModalBody>
                </Modal>
            )}
            {updateRemovalScheduleModalOpen && (
                <Modal size="lg" isOpen={updateRemovalScheduleModalOpen} centered={true}>
                    <ModalBody>
                        <h5>{t('softwareVersions:scheduleRemoveNotificationTitle')}</h5>
                        <p className="software-version-modal-body">{t('softwareVersions:scheduleRemoveNotificationModalContent')}</p>
                        <ModalFooter>
                            <Button
                                color="negative"
                                icon="delete"
                                type="negative"
                                onClick={() => {
                                    removeUpdateScheduling();
                                    setUpdateRemovalScheduleModalOpen(false);
                                }}
                            >
                                {t('delete')}
                            </Button>
                            <Button
                                color="secondary"
                                onClick={() => {
                                    setUpdateRemovalScheduleModalOpen(false);
                                }}
                            >
                                {t('cancel')}
                            </Button>
                        </ModalFooter>
                    </ModalBody>
                </Modal>
            )}
            <section className="scp-settings">
                <ToastNotification />

                {/** settings page breadcrumbs */}
                <div className="scp-settings__breadcrumb">
                    <svg>
                        <use xlinkHref={`${icons}#icon-params`}></use>
                    </svg>
                    <span>{t('settings')}</span>
                    <div className="scp-settings__breadcrumb-direction">
                        <svg>
                            <use xlinkHref={`${icons}#icon-arrow-down`}></use>
                        </svg>
                    </div>
                    <span>{t('softwareVersions:softwareVersions')}</span>
                </div>
                <NavigationBar className="software-versions-tabBar">
                    <TabItem
                        onClick={() => {
                            setSorting({});
                            changeDeviceTab('IF2');
                        }}
                        isActive={activeTab === 'IF2'}
                    >
                        <span className="cabinet__icon cabinet__icon-if2"></span>
                        {t('IF2Cabinets')}
                    </TabItem>
                    <TabItem
                        onClick={() => {
                            setSorting({});
                            changeDeviceTab('IF3');
                        }}
                        isActive={activeTab === 'IF3'}
                    >
                        <span className="cabinet__icon cabinet__icon-if3"></span>
                        {t('IF3Cabinets')}
                    </TabItem>
                    <TabItem
                        onClick={() => {
                            setSorting({});
                            changeDeviceTab('IF3Freezer');
                        }}
                        isActive={activeTab === 'IF3Freezer'}
                    >
                        <span className="cabinet__icon cabinet__icon-freezer"></span>
                        {t('IF3Freezers')}
                    </TabItem>
                    <TabItem
                        onClick={() => {
                            setSorting({});
                            changeDeviceTab('encodingStation');
                        }}
                        isActive={activeTab === 'encodingStation'}
                    >
                        <span className="encodingStation__icon"></span>
                        {t('encodingStations')}
                    </TabItem>
                </NavigationBar>
                <div>
                    <Row>
                        <Col md={getSoftwareVersionSideWidth()} className="mt-4">
                            <Row>
                                <Col md={2}>
                                    <Select
                                        onChange={option => {
                                            versionNumberOnChange(option);
                                        }}
                                        value={
                                            selectedVersion.version
                                                ? { label: selectedVersion.version, value: selectedVersion.version }
                                                : { label: t('softwareVersions:allVersionNumbers'), value: null }
                                        }
                                        defaultValue={{ label: t('softwareVersions:selectAVersionPlaceholder'), value: null }}
                                        options={versionOptions}
                                        className="softwareVersionInput"
                                        menuPortalTarget={document.body}
                                        menuPosition={'fixed'}
                                    />
                                </Col>
                                <Col className="pl-0" md={selectedVersion.version && releaseNotesOpen ? 4 : 2}>
                                    <Button
                                        onClick={() => {
                                            setConfirmationModal(true);
                                        }}
                                        disabled={!checkIfAnyDevicesCanBeUpdated()}
                                        className="updateVersionsButton"
                                    >
                                        <Icon>update</Icon> {t('softwareVersions:updateSelected')}
                                    </Button>
                                </Col>

                                <Col md={selectedVersion.version && releaseNotesOpen ? 5 : 3}>
                                    <div className="input-group softwareVersionInput">
                                        <span style={{ height: '44px' }} className="input-group-prepend border-right-0 border">
                                            <div className="input-group-text border-0 bg-transparent">
                                                <i className="fa fa-search"></i>
                                            </div>
                                        </span>
                                        <input
                                            onChange={e => {
                                                let newFilters = [...filters];
                                                const existingFilter = filters.find(filter => filter.type === 'input');
                                                if (existingFilter)
                                                    newFilters = newFilters.map(f => {
                                                        if (f.type === 'input') return { ...f, value: e.target.value };
                                                        else return f;
                                                    });
                                                else newFilters = [...newFilters, { type: 'input', value: e.target.value }];
                                                setFilters(newFilters);
                                            }}
                                            style={{ height: '44px' }}
                                            className="form-control py-2 border-left-0"
                                            placeholder={`${t('softwareVersions:searchDevices')}`}
                                            id={`${activeTab}_search_input`}
                                            key={`${activeTab}_${organization}_${selectedVersion.version ? selectedVersion.version : ''}_search_input`}
                                        />
                                    </div>
                                </Col>
                                <Col
                                    md={{
                                        size: selectedVersion.version && releaseNotesOpen ? 6 : 4,
                                        offset: selectedVersion.version && releaseNotesOpen ? 0 : 1,
                                    }}
                                >
                                    <Select
                                        onChange={selection => {
                                            let newFilters = [...filters];

                                            if (!selection) {
                                                newFilters = [
                                                    ...newFilters.filter(f => {
                                                        return f.type !== 'deviceLocation';
                                                    }),
                                                ];
                                            } else {
                                                const existingFilter = filters.find(filter => filter.type === 'deviceLocation');
                                                if (existingFilter)
                                                    newFilters = newFilters.map(f => {
                                                        if (f.type === 'deviceLocation') return { ...f, value: selection };
                                                        else return f;
                                                    });
                                                else newFilters = [...newFilters, { type: 'deviceLocation', value: selection }];
                                            }
                                            setSelectedDevices([]);
                                            setFilters(newFilters);
                                        }}
                                        className={`softwareVersionInput ${selectedVersion.version && releaseNotesOpen ? 'mt-2' : ''}`}
                                        isMulti
                                        placeholder={t('softwareVersions:filterLocationsPlaceholder')}
                                        value={
                                            filters.length && filters.find(f => f.type === 'deviceLocation')
                                                ? filters.find(f => f.type === 'deviceLocation').value
                                                : null
                                        }
                                        defaultValue={null}
                                        options={distinctLocations()}
                                        menuPortalTarget={document.body}
                                        menuPosition={'fixed'}
                                    />
                                </Col>
                            </Row>
                            <Table striped className="mt-2 softwareVersionTable">
                                <thead>
                                    <tr>
                                        <th>
                                            <div className="se-checkbox se-checkbox--md ">
                                                <input
                                                    type="checkbox"
                                                    id="selectAllVersionsCheckbox"
                                                    onChange={event => selectVersionForUpdate(event.target.checked, 'all')}
                                                />
                                                <label htmlFor="selectAllVersionsCheckbox">
                                                    <span className="se-checkbox-label"></span>
                                                </label>
                                            </div>
                                        </th>
                                        <th onClick={() => sortByColumn('deviceLocation')}>
                                            {t('location')} {getSortIcon('deviceLocation')}
                                        </th>
                                        <th onClick={() => sortByColumn('deviceName')}>
                                            {t('translations:device')} {getSortIcon('deviceName')}
                                        </th>
                                        <th onClick={() => sortByColumn('currentVersion')}>
                                            {t('softwareVersions:softwareVersion')} {getSortIcon('currentVersion')}
                                        </th>
                                        <th>{t('reports:note')}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {loading && (
                                        <tr>
                                            <th colSpan="3" scope="row"></th>
                                            <td colSpan="2">
                                                <Spinner />
                                            </td>
                                        </tr>
                                    )}
                                    {!loading && versions.length
                                        ? versions.map(version => {
                                              return (
                                                  <tr key={`${version.newerVersionAvailable}_${version.deviceName}`}>
                                                      <th scope="row">
                                                          {(!version.scheduling.length || version.scheduling.every(s => s.status === 'DONE')) &&
                                                              isSelectedVersionApplicable(version) && (
                                                                  <Checkbox
                                                                      checked={selectedDevices.some(s => s.deviceName === version.deviceName)}
                                                                      size="md"
                                                                      id={`${version.newerVersionAvailable}_${version.deviceName}_checkbox`}
                                                                      onChange={event => selectVersionForUpdate(event.target.checked, version)}
                                                                  />
                                                              )}
                                                      </th>
                                                      <td>{version.deviceLocation}</td>
                                                      <td>{version.deviceName}</td>
                                                      <td>{version.currentVersion}</td>
                                                      <td>
                                                          {version.scheduling.length ? (
                                                              <>
                                                                  <span>{generateSchedulingString(version)}</span>
                                                                  {generateSchedulingString(version) ? (
                                                                      <span
                                                                          className="removeDeviceUpdateScheduleLink"
                                                                          onClick={() => {
                                                                              setSelectedScheduleIdForRemoval(
                                                                                  version.scheduling.find(s => s.status === 'NOT_DONE')._id
                                                                              );
                                                                              setUpdateRemovalScheduleModalOpen(true);
                                                                          }}
                                                                      >
                                                                          ({t('delete')})
                                                                      </span>
                                                                  ) : null}
                                                              </>
                                                          ) : null}
                                                      </td>
                                                  </tr>
                                              );
                                          })
                                        : null}
                                    {!loading && !versions.length ? (
                                        <tr>
                                            <th colSpan="3" scope="row"></th>
                                            <td colSpan="2">{t('softwareVersions:noVersionsForUpdating')}</td>
                                        </tr>
                                    ) : null}
                                </tbody>
                            </Table>
                            <Row className="softwareVersionGoBackContainer">
                                <Button
                                    onClick={() => {
                                        closeSettings();
                                    }}
                                    className="softwareVersionGoBackButton"
                                >
                                    <Icon>arrow_back </Icon> {t('back')}
                                </Button>
                            </Row>
                        </Col>

                        <Col md={getReleaseContentWidth()} className="softwareVersionReleaseContentSection fade-in-animation">
                            {selectedVersion.version && (
                                <>
                                    <div
                                        className={`releaseNotes__toggle ${releaseNotesOpen ? 'releaseNotes__toggle--fixed' : ''}`}
                                        onClick={() => setReleaseNotesOpen(!releaseNotesOpen)}
                                    >
                                        <Icon>{releaseNotesOpen ? 'chevron_right' : 'chevron_left'}</Icon>
                                    </div>

                                    {selectedVersion.version && releaseNotesOpen && isLink(selectedVersion.releaseContent) && (
                                        <iframe
                                            style={{ height: '100vh', width: '49vw' }}
                                            src={`${selectedVersion.releaseContent}#view=FitH`}
                                            title={selectedVersion.releaseContent}
                                        ></iframe>
                                    )}
                                    {selectedVersion.version && releaseNotesOpen && !isLink(selectedVersion.releaseContent) && (
                                        <div className="p-4">{selectedVersion.releaseContent}</div>
                                    )}
                                </>
                            )}
                        </Col>
                    </Row>
                </div>
            </section>
        </>
    );
};
export default withRouter(
    connect(
        state => ({
            user: state.user,
            organization: state.user.group.Name,
            releaseVersions: state.releaseVersions,
            newerVersions: state.releaseVersions.newerVersions,
        }),
        { updateOneOrMoreDeviceUpdate, removeUpdateScheduling }
    )(translate('main')(SoftwareVersions))
);
