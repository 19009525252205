import {
    fetchDiscountRules as fetchDiscountRulesReq,
    updateDiscountRule as updateDiscountRuleReq,
    createDiscountRule as createDiscountRuleReq,
    uploadDiscountImage as uploadDiscountImageHttpRequest,
} from '../api.js';
import { v1 as uuidv1 } from 'uuid';
export const REQUEST_DISCOUNT_RULES = 'REQUEST_DISCOUNT_RULES';
export const RECEIVE_DISCOUNT_RULES = 'RECEIVE_DISCOUNT_RULES';
export const RECEIVE_DISCOUNT_RULES_ERROR = 'RECEIVE_DISCOUNT_RULES_ERROR';

export const REQUEST_CREATE_DISCOUNT_RULE = 'REQUEST_CREATE_DISCOUNT_RULE';
export const RECEIVE_CREATE_DISCOUNT_RULE = 'RECEIVE_CREATE_DISCOUNT_RULE';
export const RECEIVE_CREATE_DISCOUNT_RULE_ERROR = 'RECEIVE_CREATE_DISCOUNT_RULE_ERROR';

export const REQUEST_DELETE_DISCOUNT_RULE = 'REQUEST_DELETE_DISCOUNT_RULE';
export const RECEIVE_DELETE_DISCOUNT_RULE = 'RECEIVE_DELETE_DISCOUNT_RULE';
export const RECEIVE_DELETE_DISCOUNT_RULE_ERROR = 'RECEIVE_DELETE_DISCOUNT_RULE_ERROR';

export const REQUEST_UPDATE_DISCOUNT_RULE = 'REQUEST_UPDATE_DISCOUNT_RULE';
export const RECEIVE_UPDATE_DISCOUNT_RULE = 'RECEIVE_UPDATE_DISCOUNT_RULE';
export const RECEIVE_UPDATE_DISCOUNT_RULE_ERROR = 'RECEIVE_UPDATE_DISCOUNT_RULE_ERROR';
export const RESET_DISCOUNT_RULES = 'RESET_DISCOUNT_RULES';

export const UPLOAD_BUNDLE_IMAGE_ERROR = 'UPLOAD_BUNDLE_IMAGE_ERROR';
export const UPLOAD_BUNDLE_IMAGE_SUCCESS = 'UPLOAD_BUNDLE_IMAGE_SUCCESS';
export const REQUEST_UPLOAD_BUNDLE_IMAGE = 'REQUEST_UPLOAD_BUNDLE_IMAGE';

export const RESET_BUNDLE_IMAGE = 'RESET_BUNDLE_IMAGE';

export const UPLOAD_SCHEDULED_IMAGE_ERROR = 'UPLOAD_SCHEDULED_IMAGE_ERROR';
export const UPLOAD_SCHEDULED_IMAGE_SUCCESS = 'UPLOAD_SCHEDULED_IMAGE_SUCCESS';
export const REQUEST_UPLOAD_SCHEDULED_IMAGE = 'REQUEST_UPLOAD_SCHEDULED_IMAGE';

export const RESET_SCHEDULED_IMAGE = 'RESET_SCHEDULED_IMAGE';

export const UPLOAD_EXPIRY_IMAGE_ERROR = 'UPLOAD_EXPIRY_IMAGE_ERROR';
export const UPLOAD_EXPIRY_IMAGE_SUCCESS = 'UPLOAD_EXPIRY_IMAGE_SUCCESS';
export const REQUEST_UPLOAD_EXPIRY_IMAGE = 'REQUEST_UPLOAD_EXPIRY_IMAGE';

export const RESET_EXPIRY_IMAGE = 'RESET_EXPIRY_IMAGE';

/**
 * Request discount rules Redux action creator
 */
function requestDiscountRules() {
    return {
        type: REQUEST_DISCOUNT_RULES,
    };
}

/**
 * Receive discount rules Redux action creator
 * @param {array} discountRules array of discount rules received from root api
 */
function receiveDiscountRules(discountRules) {
    return {
        type: RECEIVE_DISCOUNT_RULES,
        discountRules,
    };
}

/**
 * Receive discount rules error Redux action creator
 * @param {Error} error api request error
 */
function receiveDiscountRulesError(error) {
    return {
        type: RECEIVE_DISCOUNT_RULES_ERROR,
        error: error,
    };
}

/**
 * Thunk action for fetching discount rules
 */
export function fetchDiscountRules() {
    return (dispatch, getState) => {
        dispatch(requestDiscountRules());
        const user = getState().user;

        return fetchDiscountRulesReq(user.group, user.accessToken)
            .then(response => dispatch(receiveDiscountRules(response)))
            .catch(error => dispatch(receiveDiscountRulesError(error)));
    };
}

/**
 * Request create discount rule Redux action creator
 */
function requestCreateDiscountRule() {
    return {
        type: REQUEST_CREATE_DISCOUNT_RULE,
    };
}

/**
 * Receive create discount rule Redux action creator
 * @param {object} discountRule
 */
function receiveCreateDiscountRule(discountRule) {
    return {
        type: RECEIVE_CREATE_DISCOUNT_RULE,
        discountRule,
    };
}
function requestUpdateDiscountRule(updatedDiscountRule) {
    return {
        type: REQUEST_UPDATE_DISCOUNT_RULE,
        updatedDiscountRule,
    };
}

function receiveUpdateDiscountRule(updatedDiscountRule) {
    return {
        type: RECEIVE_UPDATE_DISCOUNT_RULE,
        updatedDiscountRule,
    };
}

/**
 * Request create bundle image Redux action creator
 */
export function requestBundleImageUpload(progress) {
    return {
        type: REQUEST_UPLOAD_BUNDLE_IMAGE,
        progress,
    };
}

/**
 * Receive create bundle image success response Redux action creator
 */
export function uploadBundleImageSuccess(url, id) {
    return {
        type: UPLOAD_BUNDLE_IMAGE_SUCCESS,
        url,
        id,
        receivedAt: Date.now(),
    };
}

/**
 * Receive create bundle image error Redux action creator
 * @param {Error} error
 */
function uploadBundleImageError(error) {
    return {
        type: UPLOAD_BUNDLE_IMAGE_ERROR,
        error,
    };
}
/**
 * Request create bundle image Redux action creator
 */
export function requestScheduledImageUpload(progress) {
    return {
        type: REQUEST_UPLOAD_SCHEDULED_IMAGE,
        progress,
    };
}

/**
 * Receive create bundle image success response Redux action creator
 */
export function uploadScheduledImageSuccess(url, id) {
    return {
        type: UPLOAD_SCHEDULED_IMAGE_SUCCESS,
        url,
        id,
        receivedAt: Date.now(),
    };
}

/**
 * Receive create bundle image error Redux action creator
 * @param {Error} error
 */
function uploadScheduledImageError(error) {
    return {
        type: UPLOAD_SCHEDULED_IMAGE_ERROR,
        error,
    };
}
/**
 * Request create bundle image Redux action creator
 */
export function requestExpiryImageUpload(progress) {
    return {
        type: REQUEST_UPLOAD_EXPIRY_IMAGE,
        progress,
    };
}

/**
 * Receive create bundle image success response Redux action creator
 */
export function uploadExpiryImageSuccess(url, id) {
    return {
        type: UPLOAD_EXPIRY_IMAGE_SUCCESS,
        url,
        id,
        receivedAt: Date.now(),
    };
}

/**
 * Receive create bundle image error Redux action creator
 * @param {Error} error
 */
function uploadExpiryImageError(error) {
    return {
        type: UPLOAD_EXPIRY_IMAGE_ERROR,
        error,
    };
}

/**
 * Receive discount rule update error Redux action creator
 * @param {Error} error api request error
 */
function requestUpdateDiscountRuleError(error) {
    return {
        type: RECEIVE_UPDATE_DISCOUNT_RULE_ERROR,
        error,
    };
}

/**
 * Receive discount rule error Redux action creator
 * @param {Error} error api request error
 */
function receiveCreateDiscountRuleError(error) {
    return {
        type: RECEIVE_CREATE_DISCOUNT_RULE_ERROR,
        error,
    };
}

/**
 * Reset bundle image state back to null
 * @param {Error} error api request error
 */
function resetBundleImageState(error) {
    return {
        type: RESET_BUNDLE_IMAGE,
        error,
    };
}
/**
 * Reset expiry image state back to null
 * @param {Error} error api request error
 */
function resetExpiryImageState(error) {
    return {
        type: RESET_EXPIRY_IMAGE,
        error,
    };
}
/**
 * Reset scheduled image state back to null
 * @param {Error} error api request error
 */
function resetScheduledImageState(error) {
    return {
        type: RESET_SCHEDULED_IMAGE,
        error,
    };
}

/**
 * Thunk action for creating a new discount rule
 * @param {object} discountRule
 */
export function createDiscountRule(discountRule) {
    return (dispatch, getState) => {
        dispatch(requestCreateDiscountRule());
        const user = getState().user;

        return createDiscountRuleReq(discountRule, user.group, user.accessToken)
            .then(createdDiscountRule => dispatch(receiveCreateDiscountRule(createdDiscountRule)))
            .catch(error => dispatch(receiveCreateDiscountRuleError(error)));
    };
}

/**
 * Thunk action for updating a new discount rule
 * @param {object} discountRule
 */
export function updateDiscountRule(discountRule) {
    return (dispatch, getState) => {
        dispatch(requestUpdateDiscountRule());
        const user = getState().user;

        return updateDiscountRuleReq(discountRule, user.group, user.accessToken)
            .then(updatedDiscountRule => dispatch(receiveUpdateDiscountRule(updatedDiscountRule)))
            .catch(error => dispatch(requestUpdateDiscountRuleError(error)));
    };
}

/**
 *
 * @param {File} imageFile
 */
export function uploadBundleImage(imageFile) {
    return (dispatch, getState) => {
        // update state to inform API call started
        dispatch(requestBundleImageUpload());
        // convert image file to HTTP form data with unique filename
        const formData = new FormData();
        const fileName = `${uuidv1()}.jpeg`;
        //`${uuidv1()}.jpeg`
        formData.append(0, imageFile, fileName);

        // store image in backend
        const user = getState().user;

        return uploadDiscountImageHttpRequest(formData, user.accessToken, user.group, 'bundle')
            .then(response => dispatch(uploadBundleImageSuccess(encodeURI(response.url), response.id)))
            .catch(error => dispatch(uploadBundleImageError(error)));
    };
}

export function resetBundleImage() {
    return dispatch => {
        // update state to inform API call started
        dispatch(resetBundleImageState());
    };
}

/**
 *
 * @param {File} imageFile
 */
export function uploadExpiryImage(imageFile) {
    return (dispatch, getState) => {
        // update state to inform API call started
        dispatch(requestExpiryImageUpload());
        // convert image file to HTTP form data with unique filename
        const formData = new FormData();
        const fileName = `${uuidv1()}.jpeg`;
        //`${uuidv1()}.jpeg`
        formData.append(0, imageFile, fileName);

        // store image in backend
        const user = getState().user;

        return uploadDiscountImageHttpRequest(formData, user.accessToken, user.group, 'expiry')
            .then(response => dispatch(uploadExpiryImageSuccess(encodeURI(response.url), response.id)))
            .catch(error => dispatch(uploadExpiryImageError(error)));
    };
}

export function resetExpiryImage() {
    return dispatch => {
        // update state to inform API call started
        dispatch(resetExpiryImageState());
    };
}

/**
 *
 * @param {File} imageFile
 */
export function uploadScheduledImage(imageFile) {
    return (dispatch, getState) => {
        // update state to inform API call started
        dispatch(requestScheduledImageUpload());
        // convert image file to HTTP form data with unique filename
        const formData = new FormData();
        const fileName = `${uuidv1()}.jpeg`;
        //`${uuidv1()}.jpeg`
        formData.append(0, imageFile, fileName);

        // store image in backend
        const user = getState().user;

        return uploadDiscountImageHttpRequest(formData, user.accessToken, user.group, 'scheduled')
            .then(response => dispatch(uploadScheduledImageSuccess(encodeURI(response.url), response.id)))
            .catch(error => dispatch(uploadScheduledImageError(error)));
    };
}

export function resetScheduledImage() {
    return dispatch => {
        // update state to inform API call started
        dispatch(resetScheduledImageState());
    };
}

/**
 * Thunk action to refetch the discount rules when organisation change
 */
export function refreshDiscounts() {
    return dispatch => {
        dispatch({ type: RESET_DISCOUNT_RULES });
        dispatch(fetchDiscountRules());
    };
}
