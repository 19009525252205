import { composeWithDevTools } from 'redux-devtools-extension';
import { createBrowserHistory } from 'history';
import { applyMiddleware, createStore } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import promise from 'redux-promise-middleware';
import thunk from 'redux-thunk';
import createRootReducer from './reducers';

export const history = createBrowserHistory();

export default function configureStore(preloadedState) {
    const store = createStore(createRootReducer(history), preloadedState, composeWithDevTools(applyMiddleware(routerMiddleware(history), promise(), thunk)));

    return store;
}
