import { fetchCabinetInventory as fetchCabinetInventoryHttpRequest } from '../api.js';

export const REQUEST_CABINET_INVENTORY = 'REQUEST_CABINET_INVENTORY';
export const RECEIVE_CABINET_INVENTORY = 'RECEIVE_CABINET_INVENTORY';
export const RECEIVE_CABINET_INVENTORY_ERROR = 'RECEIVE_CABINET_INVENTORY_ERROR';
export const RESET_CABINET_INVENTORY = 'RESET_CABINET_INVENTORY';

/**
 * Request cabinet inventory Redux action creator
 * @param {number} code Location code
 */
function requestCabinetInventory(code) {
    return {
        type: REQUEST_CABINET_INVENTORY,
        code,
    };
}

/**
 * Receive cabinet inventory Redux action creator
 * @param {number} code Location code
 * @param {array} inventory
 * @param {date} readTime
 */
function receiveCabinetInventory(code, inventory, readTime) {
    return {
        type: RECEIVE_CABINET_INVENTORY,
        code,
        inventory,
        readTime,
        receivedAt: Date.now(),
    };
}

/**
 * Receive cabinet inventory error Redux action creator
 * @param {number} code Location code
 * @param {Error} error
 */
function receiveCabinetInventoryError(code, error) {
    return {
        type: RECEIVE_CABINET_INVENTORY_ERROR,
        code,
        error,
    };
}

/**
 * Reset to init state
 */
export function resetCabinetInventory() {
    return {
        type: RESET_CABINET_INVENTORY,
    };
}

/**
 * Thunk action creator for fetching cabinet inventory
 */
export function fetchCabinetInventory(cabinetCode) {
    return (dispatch, getState) => {
        // update state to inform API call started
        dispatch(requestCabinetInventory(cabinetCode));

        // fetch products then update state
        const accessKey = getState().user.accessToken;
        return fetchCabinetInventoryHttpRequest(cabinetCode, {}, accessKey)
            .then(response => {
                // new api returns tag objects so convert to epc string array
                const tags =
                    typeof response.tags[0] !== 'string'
                        ? response.tags
                        : response.tags.map(epc => ({ epc }));

                dispatch(
                    receiveCabinetInventory(
                        cabinetCode,
                        tags,
                        response.readTime ? new Date(Date.parse(response.readTime)) : null
                    )
                );
            })
            .catch(error => dispatch(receiveCabinetInventoryError(cabinetCode, error)));
    };
}
