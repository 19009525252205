import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';

import { Spinner, Button, Input, Card, Tag, Modal, NavigationBar, TabItem, Icon } from '@storaensods/seeds-react';
import { Row, Col, Modal as ReactStrapModal, ModalHeader, ModalBody } from 'reactstrap';
import FetchingAlert from '../fetching/fetchingAlert.js';
import {
    fetchDiscountRules,
    createDiscountRule,
    updateDiscountRule,
    uploadBundleImage,
    uploadExpiryImage,
    uploadScheduledImage,
    resetExpiryImage,
    resetScheduledImage,
    resetBundleImage,
} from '../../actions/discounts.js';
import { fetchCabinets, fetchLocations } from '../../actions/cabinets.js';
import { fetchProducts } from '../../actions/products.js';
import { fetchProductCategories } from '../../actions/productCategories.js';
import ListTransfer from './listTransfer.jsx';
import DiscountRuleStops, { DiscountRuleStopForm } from './discountStops.jsx';
import ScheduledDiscounts, { ScheduledDiscountsForm } from './scheduledDiscounts.jsx';
import './discounts.css';
import { showNotification } from '../toastNotification/toastNotification.js';
import moment from 'moment';
import BundleDiscounts, { BundleDiscountsForm } from './bundledDiscounts.jsx';

/**
 * Discounts page
 * @param {object} props Discount props object
 * @param {function} props.t i18n translator function
 * @param {object} props.discounts The Redux state for discount rules
 * @param {object} props.cabinets The Redux state for cabinets
 * @param {object} props.products The Redux state for products
 * @param {object} props.productCategories The Redux state for product categories
 * @param {Boolean} props.isAdmin From Redux state. Indicates if the user has admin privileges
 * @param {function} props.fetchDiscountRules  Thunk action for fetching discount rules
 * @param {function} props.createDiscountRule Thunk action for creating a new discount rule
 * @param {function} props.updateDiscountRule Thunk action for updating a discount rule
 * @param {function} props.deleteDiscountRule Thunk action for deleting a discount rule
 * @param {function} props.fetchCabinets Thunk action for fetching cabinets
 * @param {function} props.fetchLocations Thunk action for fetching locations
 * @param {function} props.fetchProducts Thunk action for fetching products
 * @param {function} props.fetchProductCategories Thunk action for fetching product categories
 */
function Discounts(props) {
    const { t, fetchProducts, products, productCategories, fetchProductCategories, isAdmin } = props;
    const [creatingRule, setCreatingRuleStatus] = useState({ page: null });
    const [discountType, setDiscountType] = useState('expiryDiscounts');
    const [editableDiscount, setEditableDiscount] = useState(null);

    useEffect(() => {
        if (!productCategories.fetched && !productCategories.isFetching && !productCategories.error) {
            fetchProductCategories();
        }
    }, [productCategories, fetchProductCategories]);

    useEffect(() => {
        if (!products.fetched && !products.isFetching && !products.error) {
            fetchProducts();
        }
    }, [products, fetchProducts]);

    // fetch cabinets and locations
    const { cabinets, fetchCabinets, fetchLocations } = props;
    useEffect(() => {
        if (!cabinets.fetched && !cabinets.isFetching && !cabinets.error) {
            fetchCabinets();
        }
        if (!cabinets.locationsFetched && !cabinets.locationsIsFetching) {
            fetchLocations();
        }
    }, [cabinets, fetchCabinets, fetchLocations]);

    // fetch discount rules
    const { discounts, fetchDiscountRules } = props;
    useEffect(() => {
        if (!discounts.fetched && !discounts.fetching && !discounts.error) {
            fetchDiscountRules(false);
        }
    }, [discounts, fetchDiscountRules]);

    const createDiscountRule = async discountRule => {
        const discountCreateRequest = await props.createDiscountRule(discountRule);
        setCreatingRuleStatus({ page: null });
        if (discountCreateRequest.discountRule) {
            showNotification(t('discountCreateTitle'), t('discountCreated'), 'success');
            return discountCreateRequest;
        }
    };

    const updateDiscountRule = async discountRule => {
        const discountUpdateRequest = await props.updateDiscountRule(discountRule);
        setCreatingRuleStatus({ page: null });
        setEditableDiscount(null);
        if (discountUpdateRequest.updatedDiscountRule) {
            showNotification(t('discountUpdateTitle'), t('discountUpdated'), 'success');
        }
    };

    const toggleDiscountStatus = rule => {
        props.updateDiscountRule(rule);
        if (rule.Status === 3) showNotification(t('discountDeleteTitle'), t('discountDeleteContent'), 'success');
        else showNotification(t('discountUpdateTitle'), t('discountStatusUpdated'), 'success');
    };

    const handleDiscountEdit = rule => {
        setEditableDiscount(rule);
        setCreatingRuleStatus({ page: discountType });
    };

    const cancelDiscountEdit = () => {
        setEditableDiscount(null);
        setCreatingRuleStatus({ page: null });
    };

    // state of page data requests
    const allFetched = props.discounts.fetched && props.cabinets.fetched && props.cabinets.locationsFetched && props.products.fetched;
    const someFetching = props.discounts.fetching || props.cabinets.fetching || props.cabinets.locationsIsFetching || props.products.isFetching;
    const fetchError = props.discounts.error || props.cabinets.error || props.products.error || null;
    const discountTypeOptions = [
        { label: t('expiryDiscountNavLabel'), value: 'expiryDiscounts' },
        { label: t('scheduledDiscountNavLabel'), value: 'scheduledDiscounts' },
        { label: t('bundleDiscountNavLabel'), value: 'bundleDiscounts' },
    ];
    const getDiscountType = () => {
        switch (discountType) {
            case 'expiryDiscounts':
                return props.discounts.discountRules.filter(rule => rule.Type === 'expiry' && rule.Status !== 3).sort((a, b) => (a.Status > b.Status ? 1 : -1));
            case 'scheduledDiscounts':
                return props.discounts.discountRules
                    .filter(rule => rule.Type === 'scheduled' && rule.Status !== 3)
                    .map(rule => {
                        //Automatically disable rules with passed time range
                        if (moment(rule.DiscountData.endDate).isBefore(moment())) {
                            return { ...rule, Status: 2 };
                        } else {
                            return rule;
                        }
                    })
                    .sort((a, b) => {
                        if (a.Status > b.Status) return 1;
                        if (a.Status < b.Status) return -1;

                        if (a.DiscountData.discountAmount > b.DiscountData.discountAmount) return -1;
                        if (a.DiscountData.discountAmount < b.DiscountData.discountAmount) return 1;
                        return 1;
                    });
            case 'bundleDiscounts':
                return props.discounts.discountRules
                    .filter(rule => rule.Type === 'bundle' && rule.Status !== 3)
                    .map(rule => {
                        //Automatically disable rules with passed time range
                        if (moment(rule.DiscountData.Scheduling.endDate).isBefore(moment())) {
                            return { ...rule, Status: 2 };
                        } else {
                            return rule;
                        }
                    })
                    .sort((a, b) => {
                        if (a.Status > b.Status) return 1;
                        if (a.Status < b.Status) return -1;
                        if (a.DiscountData.lastEdited) {
                            if (moment(a.DiscountData.lastEdited).isBefore(b.DiscountData.lastEdited)) return 1;
                            if (moment(a.DiscountData.lastEdited).isAfter(b.DiscountData.lastEdited)) return -1;
                        } else {
                            return 1;
                        }
                        return 1;
                    });
            default:
                return null;
        }
    };

    if (fetchError) {
        return (
            <div className="m-2">
                <FetchingAlert errorMessage={t('fetchingError')} isError={true} />
            </div>
        );
    } else if (allFetched && creatingRule.page) {
        // Creating a discount rule
        return (
            <CreateDiscountRule
                allCabinets={props.cabinets.cabinets}
                allLocations={props.cabinets.locations}
                allProducts={props.products.products}
                allProductCategories={props.productCategories.productCategories}
                cancelCreate={cancelDiscountEdit}
                createDiscountRule={createDiscountRule}
                updateDiscountRule={updateDiscountRule}
                resetBundleImage={props.resetBundleImage}
                isAdmin={props.isAdmin}
                t={t}
                uploadBundleImage={props.uploadBundleImage}
                discountType={creatingRule.page}
                editData={editableDiscount}
                bundleImageUrl={props.bundleImageUrl}
                bundleImageStoring={props.bundleImageStoring}
                expiryImageUrl={props.expiryImageUrl}
                uploadExpiryImage={props.uploadExpiryImage}
                uploadScheduledImage={props.uploadScheduledImage}
                expiryImageStoring={props.expiryImageStoring}
                resetExpiryImage={props.resetExpiryImage}
                resetScheduledImage={props.resetScheduledImage}
                scheduledImageUrl={props.scheduledImageUrl}
                scheduledImageStoring={props.scheduledImageStoring}
            />
        );
    } else if (allFetched) {
        // display list of discount rules
        return (
            <>
                <div className="container-fluid">
                    <Row>
                        <Col xs="6" sm="6" md="6" className="d-flex">
                            <span className="float-left smart-cabinet-page-title se-display-3">{t('discounts')}</span>
                        </Col>
                    </Row>
                    <Row>
                        <NavigationBar className="mb-3 not-printable">
                            {discountTypeOptions.map(dType => {
                                return (
                                    <TabItem key={dType.value} onClick={() => setDiscountType(dType.value)} isActive={discountType === dType.value}>
                                        {' '}
                                        {dType.label}
                                    </TabItem>
                                );
                            })}
                        </NavigationBar>
                    </Row>
                    <div style={{ minHeight: '437px' }}>
                        <Row>
                            {discountType !== 'expiryDiscounts' && (
                                <Col className="my-auto" lg="9">
                                    <Icon className="my-auto">info_outline</Icon>
                                    <span>{t('scheduledDiscountsAutomaticDeactivationInfo')}</span>
                                </Col>
                            )}
                            {isAdmin && (
                                <Col lg={discountType !== 'expiryDiscounts' ? 3 : 12}>
                                    <Button
                                        onClick={setCreatingRuleStatus.bind(this, { page: discountType })}
                                        icon="add"
                                        type="positive"
                                        className="float-right mb-3 mt-3 mr-4 ml-auto"
                                    >
                                        {t('addDiscountRule')}
                                    </Button>
                                </Col>
                            )}
                        </Row>
                        <div className="d-flex flex-wrap discount-container">
                            {someFetching ||
                                fetchError ||
                                (getDiscountType() && getDiscountType().length === 0 && (
                                    <div>
                                        <FetchingAlert
                                            noDataMessage={t('noDiscountRules')}
                                            fetchingMessage={t('fetchingDiscountRules')}
                                            noData={props.discounts.discountRules && getDiscountType().length === 0 && !props.discounts.isFetching}
                                        />
                                    </div>
                                ))}
                            {getDiscountType().map(rule => (
                                <>
                                    {discountType === 'scheduledDiscounts' && (
                                        <ScheduledDiscounts
                                            isAdmin={props.isAdmin}
                                            t={t}
                                            rule={rule}
                                            cabinets={props.cabinets}
                                            products={props.products}
                                            productCategories={props.productCategories}
                                            toggleDiscountStatus={toggleDiscountStatus}
                                            handleDiscountEdit={handleDiscountEdit}
                                        />
                                    )}

                                    {discountType === 'expiryDiscounts' && (
                                        <DiscountRuleStops
                                            isAdmin={props.isAdmin}
                                            toggleDiscountStatus={toggleDiscountStatus}
                                            rule={rule}
                                            cabinets={props.cabinets}
                                            products={props.products}
                                            productCategories={props.productCategories}
                                            t={t}
                                            handleDiscountEdit={handleDiscountEdit}
                                        />
                                    )}
                                    {discountType === 'bundleDiscounts' && (
                                        <BundleDiscounts
                                            isAdmin={props.isAdmin}
                                            toggleDiscountStatus={toggleDiscountStatus}
                                            rule={rule}
                                            cabinets={props.cabinets}
                                            products={props.products.products}
                                            productCategories={props.productCategories.productCategories}
                                            t={t}
                                            handleDiscountEdit={handleDiscountEdit}
                                        />
                                    )}
                                </>
                            ))}
                        </div>
                    </div>
                </div>
            </>
        );
    } else if (someFetching) {
        return <Spinner />;
    } else {
        return <div></div>;
    }
}

/**
 * Create discount rule page
 * @param {Object} props
 * @param {('expiryDiscounts'|'scheduledDiscounts'|'bundleDiscounts')} props.discountType Type of discount going to be created
 * @param {function} props.cancelCreate
 * @param {Array<Object>} props.allCabinets
 * @param {Array<Object>} props.allLocations
 * @param {Array<Object>} props.allProducts
 * @param {Array<Object>} props.allProductCategories
 * @param {function} props.createDiscountRule
 * @param {function} props.t i18n translation function
 * @param {object} props.editData Rule object to be edited
 * @param {function} props.updateDiscountRule
 */
function CreateDiscountRule(props) {
    const { t, discountType, editData = {} } = props;
    const mapCabinetIds = cabinetIds => {
        return cabinetIds.map(id => {
            const cabinet = props.allCabinets.find(cabinet => {
                return cabinet.deviceCode === id;
            });
            if (cabinet) return cabinet;
            else return { deviceCode: id, id, name: `${id} (${t('doesNotExist')})`, invalid: true };
        });
    };
    const mapLocationIds = locationIds => {
        return locationIds.map(id => {
            const location = props.allLocations.find(loc => {
                return loc.id === id;
            });
            if (location) return location;
            else return { id, name: `${id} (${t('doesNotExist')})`, invalid: true };
        });
    };
    const mapProductIds = productIds => {
        return productIds.map(id => {
            const product = props.allProducts.find(prod => {
                return prod.id === id;
            });
            if (product) return product;
            else return { id, name: `${id} (${t('doesNotExist')})`, invalid: true };
        });
    };
    const mapProductCategoryIds = productCategoryIds => {
        return productCategoryIds.map(id => {
            const productCategory = props.allProductCategories.find(pc => {
                return pc.id === id;
            });
            if (productCategory) return productCategory;
            else return { id, name: `${id} (${t('doesNotExist')})`, invalid: true };
        });
    };

    // staged discount rule data
    const [userRestricted, setUserRestricted] = useState(editData ? editData.isUserRestricted : false);

    const [ruleName, setRuleName] = useState(editData ? editData.Name : '');
    const [ruleId] = useState(editData ? editData.Id : undefined);
    const [ruleStatus, setRuleStatus] = useState(editData ? editData.Status : 1);
    const [ruleCabinets, setRuleCabinets] = useState(editData && editData.DeviceCodes ? mapCabinetIds(editData.DeviceCodes) : []);
    const [ruleLocations, setRuleLocations] = useState(editData && editData.LocationIds ? mapLocationIds(editData.LocationIds) : []);
    const [ruleProducts, setRuleProducts] = useState(editData && editData.ProductIds ? mapProductIds(editData.ProductIds) : []);
    const [ruleProductCategories, setRuleProductCategories] = useState(
        editData && editData.ProductCategoryIds ? mapProductCategoryIds(editData.ProductCategoryIds) : []
    );
    const [ruleStops, setRuleStops] = useState(editData && editData.Type === 'expiry' ? editData.DiscountData.discountStops : []);
    const [ruleEditMode, setRuleEditMode] = useState(editData && Object.keys(editData.DiscountData).length ? true : false);

    // state for list transfer components
    const [tmpRuleCabinets, setTmpRuleCabinets] = useState(editData && editData.DeviceCodes ? mapCabinetIds(editData.DeviceCodes) : []);
    const [tmpRuleLocations, setTmpRuleLocations] = useState(editData && editData.LocationIds ? mapLocationIds(editData.LocationIds) : []);
    const [tmpRuleProducts, setTmpRuleProducts] = useState(editData && editData.ProductIds ? mapProductIds(editData.ProductIds) : []);
    const [tmpRuleProductCategories, setTmpRuleProductCategories] = useState(
        editData && editData.ProductCategoryIds ? mapProductCategoryIds(editData.ProductCategoryIds) : []
    );
    const [tmpExpiryDiscount, setTmpExpiryDiscount] = useState(
        editData && editData.Type === 'expiry' ? { ...editData, DiscountData: editData.DiscountData } : null
    );
    const [tmpScheduledDiscount, setTmpScheduledDiscount] = useState(
        editData && editData.Type === 'scheduled' ? { ...editData, DiscountData: editData.DiscountData } : null
    );
    const [tmpBundleDiscount, setTmpBundleDiscount] = useState(editData ? { ...editData, DiscountData: editData.DiscountData } : null);
    const [tmpEditedScheduleDiscount, setTmpEditedScheduleDiscount] = useState(editData ? { ...editData, DiscountData: editData.DiscountData } : null);
    const [tmpRuleStopFormMessage, setTmpRuleStopFormMessage] = useState('');

    // modals state
    const [ruleCabinetsModalOpen, setRuleCabinetsModalOpen] = useState(false);
    const [ruleLocationsModalOpen, setRuleLocationsModalOpen] = useState(false);
    const [ruleProductsModalOpen, setRuleProductsModalOpen] = useState(false);
    const [ruleProductCategoriesModalOpen, setRuleProductCategoriesModalOpen] = useState(false);
    const [ruleStopModalOpen, setRuleStopModalOpen] = useState(false);
    const [scheduledDiscountModalOpen, setScheduledDiscountModalOpen] = useState(false);
    const [bundledDiscountModalOpen, setBundledDiscountModalOpen] = useState(false);
    const [markExpiryImageAsDeleted, setMarkExpiryImageAsDeleted] = useState(false);
    const [markScheduledImageAsDeleted, setMarkScheduledImageAsDeleted] = useState(false);
    const handleRuleNameInputChange = event => {
        setRuleName(event.target.value);
    };

    const cancelCabinetsModal = () => {
        setRuleCabinetsModalOpen(false);
        setTmpRuleCabinets(ruleCabinets);
    };

    const cancelLocationsModal = () => {
        setRuleLocationsModalOpen(false);
        setTmpRuleLocations(ruleLocations);
    };

    const cancelProductsModal = () => {
        setRuleProductsModalOpen(false);
        setTmpRuleProducts(ruleProducts);
    };

    const cancelStopModal = () => {
        setRuleStopModalOpen(false);
        setRuleEditMode(false);
        if (ruleEditMode) setRuleStops(editData.DiscountData.discountStops);
        setMarkExpiryImageAsDeleted(null);
        if (props.expiryImageUrl && !editData.DiscountData.imageUrl) props.resetExpiryImage();
        if (!ruleEditMode) setRuleStops([]);
        setTmpRuleStopFormMessage('');
    };
    const cancelScheduledModal = () => {
        setScheduledDiscountModalOpen(false);
        setRuleEditMode(false);
        setTmpEditedScheduleDiscount(null);
        if (tmpScheduledDiscount) {
            setTmpScheduledDiscount({ ...tmpScheduledDiscount, DiscountData: tmpScheduledDiscount.DiscountData });
        } else {
            setTmpScheduledDiscount(editData && editData.Type === 'scheduled' ? { ...editData, DiscountData: editData.DiscountData } : null);
        }
        setTmpRuleStopFormMessage('');
    };

    const cancelBundleModal = () => {
        setBundledDiscountModalOpen(false);
        setTmpBundleDiscount((editData && editData.DiscountData) || ruleEditMode ? { DiscountData: tmpBundleDiscount.DiscountData } : null);
        setTmpRuleStopFormMessage('');
    };

    const saveRuleCabinets = () => {
        setRuleCabinets(tmpRuleCabinets);
        setRuleCabinetsModalOpen(false);
    };

    const saveRuleLocations = () => {
        setRuleLocations(tmpRuleLocations);
        setRuleLocationsModalOpen(false);
    };

    const saveRuleProducts = () => {
        setRuleProducts(tmpRuleProducts);
        setRuleProductsModalOpen(false);
    };

    const saveRuleProductCategories = () => {
        setRuleProductCategories(tmpRuleProductCategories);
        setRuleProductCategoriesModalOpen(false);
    };

    const cancelProductCategoriesModal = () => {
        setRuleProductCategoriesModalOpen(false);
        setTmpRuleProductCategories(ruleProductCategories);
    };

    const saveTmpBundleDiscount = data => {
        let rule;
        if (Number(data)) {
            rule = { ...tmpBundleDiscount, Status: data };
            setRuleStatus(data);
        } else rule = { ...tmpBundleDiscount, Status: 1, DiscountData: data };
        setTmpBundleDiscount(rule);
        setBundledDiscountModalOpen(false);
    };

    const deleteEditedExpiryImg = ruleId => {
        if (!ruleId) setMarkExpiryImageAsDeleted(null);
        else setMarkExpiryImageAsDeleted({ id: ruleId });
    };
    const deleteEditedScheduledImg = ruleId => {
        setMarkScheduledImageAsDeleted({ id: ruleId });
    };

    // add tmp discount stop from discount stop form as staged discount stop
    const addTmpRuleStop = (stop, method = 'add') => {
        // form validation
        let expiryDiscount = stop.selectedRuleStop;

        if (!ruleStops) {
            setTmpRuleStopFormMessage(t('fillForm'));
            return;
        }
        if (!expiryDiscount || (expiryDiscount && !expiryDiscount.discountAmount)) {
            setTmpRuleStopFormMessage(t('setDiscountAmount'));
            return;
        }
        if (expiryDiscount.minsBeforeExpiry < 0) {
            setTmpRuleStopFormMessage(t('setDiscountTimeBeforeExpiry'));
            return;
        }
        const ruleStopWithMinsBeforeExpiryExists = ruleStops.find(stop => expiryDiscount.minsBeforeExpiry === stop.minsBeforeExpiry);
        if (ruleStopWithMinsBeforeExpiryExists) {
            setTmpRuleStopFormMessage(t('discountTimeBeforeExpiryExists'));
            return;
        }

        const existingStop = ruleStops.find(s => s.id === expiryDiscount.id);
        let newRuleStops = ruleStops.map(s => {
            if (expiryDiscount.id === s.id) return expiryDiscount;
            else return s;
        });
        if (!existingStop) newRuleStops = [...newRuleStops, expiryDiscount];
        if (ruleEditMode && newRuleStops.length) {
            setRuleStops([
                ...newRuleStops.sort((stopA, stopB) => {
                    return stopB.minsBeforeExpiry - stopA.minsBeforeExpiry;
                }),
            ]);
        } else setRuleStops(newRuleStops);
        if (!expiryDiscount) setRuleStopModalOpen(false);
        setTmpRuleStopFormMessage('');
    };

    // add tmp discount stop from discount stop form as staged discount stop
    const addTmpScheduledDiscount = () => {
        // form validation
        if (
            tmpEditedScheduleDiscount
                ? Object.keys(tmpEditedScheduleDiscount.DiscountData).length < 1
                : (!tmpScheduledDiscount || Object.keys(tmpScheduledDiscount.DiscountData).length) < 1
        ) {
            setTmpRuleStopFormMessage(t('fillForm'));
            return;
        }
        if (
            (tmpEditedScheduleDiscount && tmpEditedScheduleDiscount.DiscountData?.startDate && !tmpEditedScheduleDiscount?.DiscountData.endDate) ||
            (!tmpEditedScheduleDiscount.DiscountData.startDate && tmpEditedScheduleDiscount.DiscountData.endDate)
        ) {
            setTmpRuleStopFormMessage(t('scheduledDiscountDateMissingError'));
            return;
        }
        if (
            tmpEditedScheduleDiscount &&
            tmpEditedScheduleDiscount.DiscountData.startDate &&
            tmpEditedScheduleDiscount.DiscountData.endDate &&
            moment(tmpEditedScheduleDiscount.DiscountData.startDate).format('HH:mm') === moment(tmpEditedScheduleDiscount.DiscountData.endDate).format('HH:mm')
        ) {
            setTmpRuleStopFormMessage(t('scheduledDiscountDuplicateTimeError'));
            return;
        }

        if (tmpEditedScheduleDiscount ? !tmpEditedScheduleDiscount.DiscountData.discountAmount : !tmpScheduledDiscount.DiscountData.discountAmount) {
            setTmpRuleStopFormMessage(t('setDiscountAmount'));
            return;
        }

        const tmpDiscount = tmpEditedScheduleDiscount.DiscountData;
        setTmpScheduledDiscount({
            Id: tmpScheduledDiscount ? tmpScheduledDiscount.Id : null,
            DiscountData: { ...tmpDiscount, imageUrl: tmpDiscount.imageUrl ? tmpDiscount.imageUrl : undefined },
            Status: ruleStatus,
            Name: ruleName,
            isUserRestricted: userRestricted || false,
            userIds: userRestricted ? [] : undefined,
            ProductIds: ruleProducts.map(product => product.id),
            LocationIds: ruleLocations.map(location => location.id),
            DeviceCodes: ruleCabinets.map(cabinet => cabinet.deviceCode),
            ProductCategories: ruleProductCategories.map(category => category.id),
        });

        setScheduledDiscountModalOpen(false);
        setTmpRuleStopFormMessage('');
        setRuleEditMode(false);
    };

    /**
     * Removes only discount stop with given time before expiry.
     * Removes all discount stops if stopTimeBeforeExpiry is null.
     * @param {number} stopTimeBeforeExpiry
     */
    const removeDiscountRuleStop = stopTimeBeforeExpiry => {
        if (stopTimeBeforeExpiry == null) {
            setRuleStops([]);
        } else {
            setRuleStops(ruleStops.filter(stop => stop.minsBeforeExpiry !== stopTimeBeforeExpiry));
        }
    };

    // create discount rule with staged data
    const createScheduledDiscountRule = () => {
        if (!ruleName || !ruleName.replace(/^\s+|\s+$/gm, '')) {
            console.error('createScheduledDiscountRule: attempted to create discount rule with no name');
            return;
        }

        if (ruleName.length < 3 || ruleName.length > 125) {
            console.error('createScheduledDiscountRule: attempted to create discount rule with name less than 3 characters or more than 125');
            return;
        }

        if (!tmpScheduledDiscount) {
            console.error('createScheduledDiscountRule: attempted to create discount rule with no rule');
            return;
        }

        const getImageUrl = () => {
            if (tmpScheduledDiscount?.DiscountData.imageUrl && props.scheduledImageUrl) {
                return props.scheduledImageUrl;
            } else if (tmpScheduledDiscount?.DiscountData.imageUrl && markScheduledImageAsDeleted.id !== ruleId) {
                return tmpScheduledDiscount.DiscountData.imageUrl;
            } else if (!tmpScheduledDiscount?.DiscountData.imageUrl && props.scheduledImageUrl) {
                return props.scheduledImageUrl;
            } else if (!tmpScheduledDiscount?.DiscountData.imageUrl && markScheduledImageAsDeleted.id === ruleId) {
                return null;
            } else return undefined;
        };

        const discountRule = {
            Id: ruleId,
            Name: ruleName,
            Type: 'scheduled',
            ProductIds: ruleProducts.map(product => product.id),
            LocationIds: ruleLocations.map(location => location.id),
            DeviceCodes: ruleCabinets.map(cabinet => cabinet.deviceCode),
            ProductCategoryIds: ruleProductCategories.map(category => category.id),
            isUserRestricted: userRestricted || false,
            userIds: userRestricted ? [] : undefined,
            DiscountData: {
                ...tmpScheduledDiscount.DiscountData,
                imageUrl: getImageUrl(),
                DiscountTypeOrder: 2,
            },
            Status: ruleStatus,
        };

        // Remove id necessary during editing but not necessary to be saved
        delete discountRule.DiscountData.id;

        if (editData) props.updateDiscountRule(discountRule);
        else props.createDiscountRule(discountRule);
        props.resetScheduledImage();
    };

    const createBundleDiscountRule = () => {
        if (!ruleName || !ruleName.replace(/^\s+|\s+$/gm, '')) {
            console.error('createBundleDiscountRule: attempted to create discount rule with no name');
            return;
        }

        if (ruleName.length < 3 || ruleName.length > 125) {
            console.error('createBundleDiscountRule: attempted to create discount rule with name less than 3 characters or more than 125');
            return;
        }

        const discountRule = {
            Id: ruleId,
            Name: ruleName,
            Type: 'bundle',
            isUserRestricted: userRestricted || false,
            userIds: userRestricted ? [] : undefined,
            LocationIds: ruleLocations.map(location => location.id),
            DeviceCodes: ruleCabinets.map(cabinet => cabinet.deviceCode),
            DiscountData: { ...tmpBundleDiscount.DiscountData, DiscountTypeOrder: 3 },
            Status: ruleStatus,
        };
        if (editData)
            props.updateDiscountRule(discountRule).then(() => {
                props.resetBundleImage();
            });
        else
            props.createDiscountRule(discountRule).then(() => {
                props.resetBundleImage();
            });
    };

    const handleUserRestrictToggle = () => {
        setUserRestricted(!userRestricted);
    };

    // create discount rule with staged data
    const createExpiryDiscountRule = () => {
        if (!ruleName || !ruleName.replace(/^\s+|\s+$/gm, '')) {
            console.error('createExpiryDiscountRule: attempted to create discount rule with no name');
            return;
        }

        if (ruleName.length < 3 || ruleName.length > 125) {
            console.error('createExpiryDiscountRule: attempted to create discount rule with name less than 3 characters or more than 125');
            return;
        }

        if (ruleStops.length === 0) {
            console.error('createExpiryDiscountRule: attempted to create discount rule with no stops');
            return;
        }

        const getImageUrl = () => {
            if ((!markExpiryImageAsDeleted || !markExpiryImageAsDeleted?.id === ruleId) && editData && !props.expiryImageUrl)
                return editData.DiscountData.imageUrl;
            else if (
                (!markExpiryImageAsDeleted || !markExpiryImageAsDeleted?.id === ruleId) &&
                editData &&
                !editData.DiscountData.imageUrl &&
                props.expiryImageUrl
            )
                return props.expiryImageUrl;
            else if (!editData && props.expiryImageUrl) return props.expiryImageUrl;
            else if (markExpiryImageAsDeleted && markExpiryImageAsDeleted.id === ruleId) return null;
            else return null;
        };

        let isUserRestricted = userRestricted || false;

        const discountRule = {
            Id: ruleId,
            Name: ruleName,
            Type: 'expiry',
            Status: ruleStatus,
            ProductIds: ruleProducts.map(product => product.id),
            LocationIds: ruleLocations.map(location => location.id),
            ProductCategoryIds: ruleProductCategories.map(category => category.id),
            DeviceCodes: ruleCabinets.map(cabinet => cabinet.deviceCode),
            isUserRestricted,
            userIds: isUserRestricted ? [] : undefined, // allow all users for now
            DiscountData: {
                imageUrl: getImageUrl(),
                DiscountTypeOrder: 1,
                discountStops: ruleStops.map(stop => ({
                    id: stop.id,
                    minsBeforeExpiry: stop.minsBeforeExpiry,
                    discountAmount: stop.discountAmount,
                })),
            },
        };
        if (editData) {
            props.updateDiscountRule(discountRule);
        } else props.createDiscountRule(discountRule);
        props.resetExpiryImage();

        setTmpExpiryDiscount(null);
    };

    const selectCabinetsModal = (
        <Modal
            actions={[
                {
                    label: t('save'),
                    onClick: saveRuleCabinets,
                },
                {
                    label: t('cancel'),
                    onClick: cancelCabinetsModal,
                },
            ]}
            onClose={cancelCabinetsModal}
            active={ruleCabinetsModalOpen}
            title={t('selectDiscountRuleCabinets')}
        >
            <ListTransfer allItems={props.allCabinets} selectedItems={tmpRuleCabinets} setSelectedItems={setTmpRuleCabinets} t={t} />
        </Modal>
    );

    const selectLocationsModal = (
        <Modal
            actions={[
                {
                    label: t('save'),
                    onClick: saveRuleLocations,
                },
                {
                    label: t('cancel'),
                    onClick: cancelLocationsModal,
                },
            ]}
            onClose={cancelLocationsModal}
            active={ruleLocationsModalOpen}
            title={t('selectDiscountRuleLocations')}
        >
            <ListTransfer allItems={props.allLocations} selectedItems={tmpRuleLocations} setSelectedItems={setTmpRuleLocations} t={t} />
        </Modal>
    );

    const selectProductsModal = (
        <Modal
            actions={[
                {
                    label: t('save'),
                    onClick: saveRuleProducts,
                },
                {
                    label: t('cancel'),
                    onClick: cancelProductsModal,
                },
            ]}
            onClose={cancelProductsModal}
            active={ruleProductsModalOpen}
            title={t('selectDiscountRuleProducts')}
        >
            <ListTransfer
                allItems={props.allProducts.filter(p => p.status !== 3)}
                selectedItems={tmpRuleProducts}
                setSelectedItems={setTmpRuleProducts}
                t={t}
            />
        </Modal>
    );

    const selectProductCategoriesModal = (
        <Modal
            actions={[
                {
                    label: t('save'),
                    onClick: saveRuleProductCategories,
                },
                {
                    label: t('cancel'),
                    onClick: cancelProductCategoriesModal,
                },
            ]}
            onClose={cancelProductCategoriesModal}
            active={ruleProductCategoriesModalOpen}
            title={t('selectDiscountRuleProductCategories')}
        >
            <ListTransfer allItems={props.allProductCategories} selectedItems={tmpRuleProductCategories} setSelectedItems={setTmpRuleProductCategories} t={t} />
        </Modal>
    );

    const discountStopModal = (
        <Modal
            actions={[
                {
                    label: t('save'),
                    onClick: () => setRuleStopModalOpen(false),
                },
                {
                    label: t('cancel'),
                    onClick: cancelStopModal,
                },
            ]}
            onClose={cancelStopModal}
            active={ruleStopModalOpen}
            title={t('editExpiryConfiguration')}
        >
            <DiscountRuleStopForm
                discountStops={ruleStops}
                rule={tmpExpiryDiscount}
                addNewStop={addTmpRuleStop}
                formMessage={tmpRuleStopFormMessage}
                uploadExpiryImage={props.uploadExpiryImage}
                resetExpiryImage={props.resetExpiryImage}
                expiryImageStoring={props.expiryImageStoring}
                expiryImageUrl={props.expiryImageUrl}
                isAdmin={props.isAdmin}
                userRestricted={userRestricted}
                markExpiryImageAsDeleted={deleteEditedExpiryImg}
                handleUserRestrictToggle={handleUserRestrictToggle}
                t={t}
            />
        </Modal>
    );

    const tempSaveEditScheduledDiscount = discount => {
        setTmpEditedScheduleDiscount({ id: tmpScheduledDiscount ? tmpScheduledDiscount.Id : null, DiscountData: discount });
    };

    const scheduledDiscountsModal = (
        <ReactStrapModal centered isOpen={scheduledDiscountModalOpen} backdrop="static" className="scheduledDiscountFormModal">
            <ModalHeader toggle={cancelScheduledModal}>{ruleEditMode ? t('editScheduledDiscountStop') : t('addScheduledDiscountStop')}</ModalHeader>
            <ModalBody>
                <ScheduledDiscountsForm
                    setScheduledDiscount={tempSaveEditScheduledDiscount}
                    scheduledDiscount={ruleEditMode ? tmpEditedScheduleDiscount : tmpScheduledDiscount}
                    formMessage={tmpRuleStopFormMessage}
                    saveDiscount={addTmpScheduledDiscount}
                    uploadScheduledImage={props.uploadScheduledImage}
                    resetScheduledImage={props.resetScheduledImage}
                    scheduledImageStoring={props.scheduledImageStoring}
                    scheduledImageUrl={props.scheduledImageUrl}
                    markScheduledImageAsDeleted={deleteEditedScheduledImg}
                    userRestricted={userRestricted}
                    handleUserRestrictToggle={handleUserRestrictToggle}
                    t={t}
                />
            </ModalBody>
        </ReactStrapModal>
    );
    const bundledDiscountsModal = (
        <ReactStrapModal centered isOpen={bundledDiscountModalOpen} backdrop="static" className="bundleDiscountFormModal">
            <ModalHeader toggle={cancelBundleModal}>{t('editBundleConfiguration')}</ModalHeader>
            <ModalBody>
                <BundleDiscountsForm
                    setBundleDiscount={saveTmpBundleDiscount}
                    bundledDiscount={tmpBundleDiscount}
                    uploadBundleImage={props.uploadBundleImage}
                    bundleImageStoring={props.bundleImageStoring}
                    bundleImageUrl={props.bundleImageUrl}
                    formMessage={tmpRuleStopFormMessage}
                    t={t}
                    products={props.allProducts}
                    productCategories={props.allProductCategories}
                    userRestricted={userRestricted}
                    handleUserRestrictToggle={handleUserRestrictToggle}
                />
            </ModalBody>
        </ReactStrapModal>
    );

    // currently open modal
    let activeModal = null;
    if (ruleCabinetsModalOpen) {
        activeModal = selectCabinetsModal;
    } else if (ruleLocationsModalOpen) {
        activeModal = selectLocationsModal;
    } else if (ruleProductsModalOpen) {
        activeModal = selectProductsModal;
    } else if (ruleStopModalOpen) {
        activeModal = discountStopModal;
    } else if (scheduledDiscountModalOpen) {
        activeModal = scheduledDiscountsModal;
    } else if (bundledDiscountModalOpen) {
        activeModal = bundledDiscountsModal;
    } else if (ruleProductCategoriesModalOpen) {
        activeModal = selectProductCategoriesModal;
    }

    // currently selected category to apply filter for
    const activeApplyRuleCategory = [];
    if (ruleCabinets.length > 0) {
        activeApplyRuleCategory.push('cabinets');
    }
    if (ruleLocations.length > 0) {
        activeApplyRuleCategory.push('locations');
    }
    if (ruleProducts.length > 0) {
        activeApplyRuleCategory.push('products');
    }
    if (ruleProductCategories.length > 0) {
        activeApplyRuleCategory.push('productCategories');
    }

    const ruleNameIsValid = ruleName && ruleName.length > 2 && ruleName.length < 126;

    const saveTmpScheduleDiscount = tmpRule => {
        let data = tmpRule;
        if (tmpRule.Status === 3) data = null;
        if (Number(tmpRule)) {
            data = { ...tmpScheduledDiscount, Status: tmpRule };
            setRuleStatus(tmpRule);
        }
        setTmpScheduledDiscount(data);
    };

    const isCreationAllowed = () => {
        if (discountType === 'expiryDiscounts') {
            if (ruleStops.length === 0 || !ruleNameIsValid) {
                return false;
            } else {
                return true;
            }
        } else if (discountType === 'scheduledDiscounts') {
            if (!tmpScheduledDiscount?.DiscountData || !ruleNameIsValid) {
                return false;
            } else {
                return true;
            }
        } else {
            if (!tmpBundleDiscount || !ruleNameIsValid) {
                return false;
            } else {
                return true;
            }
        }
    };

    const handleNewExpiryDiscountStatusChange = Status => {
        setRuleStatus(Status);
        setTmpExpiryDiscount({ ...tmpExpiryDiscount, Status });
    };

    const handleDiscountEdit = data => {
        switch (discountType) {
            case 'scheduledDiscounts':
                setRuleEditMode(true);
                setTmpEditedScheduleDiscount({ id: data.Id, DiscountData: data.DiscountData });
                setScheduledDiscountModalOpen(!scheduledDiscountModalOpen);
                break;
            case 'expiryDiscounts':
                setRuleEditMode(true);
                setRuleStopModalOpen(!ruleStopModalOpen);
                break;
            case 'bundleDiscounts':
                setRuleEditMode(true);
                setTmpBundleDiscount(data);
                setBundledDiscountModalOpen(data ? true : false);
                break;
            default:
                break;
        }
    };

    const handleRuleCreationClick = () => {
        switch (discountType) {
            case 'expiryDiscounts':
                return createExpiryDiscountRule();
            case 'scheduledDiscounts':
                return createScheduledDiscountRule();
            case 'bundleDiscounts':
                return createBundleDiscountRule();
            default:
                return;
        }
    };

    const handleNewExpiryStopClick = () => {
        setRuleEditMode(false);
        setRuleStopModalOpen(true);
    };
    // display create discount rule form
    return (
        <div className="ml-3 mr-3">
            {activeModal}
            <div className="se-font-h3 mb-1">
                {discountType === 'expiryDiscounts' && <>{editData ? t('editExpiryDiscountRule') : t('createExpiryDiscountRule')}</>}
                {discountType === 'scheduledDiscounts' && <>{editData ? t('editScheduledDiscountRule') : t('createScheduledDiscountRule')}</>}
                {discountType === 'bundleDiscounts' && <>{editData ? t('editBundleDiscountRule') : t('createBundleDiscountRule')}</>}
            </div>
            <Row>
                <Col xs="12" md="6" className="mb-2">
                    <Input
                        label={t('name')}
                        placeholder={t('discountRuleName')}
                        value={ruleName}
                        onChange={handleRuleNameInputChange}
                        invalid={!ruleNameIsValid}
                    />
                </Col>
            </Row>
            <Row>
                <Col className="mb-1" xs="12">
                    {t('discountRuleAffects')}:
                </Col>
                <Col>
                    <Card title={t('cabinets')} type={activeApplyRuleCategory.some(active => active === 'cabinets') ? 'positive' : 'primary'}>
                        <Row className="mb-2 ml-0 mr-0">
                            {ruleCabinets.length === 0 ? <Tag>{t('all')}</Tag> : null}
                            {ruleCabinets.map(cabinet => (
                                <Tag key={cabinet?.deviceCode} type={cabinet.invalid ? 'negative' : 'positive'} className="m-1">
                                    {cabinet?.name}
                                </Tag>
                            ))}
                        </Row>
                        <Row className="ml-0">
                            <Button
                                icon="edit"
                                onClick={setRuleCabinetsModalOpen.bind(this, true)}
                                disabled={activeApplyRuleCategory.some(active => active === 'locations')}
                            >
                                {t('change')}
                            </Button>
                        </Row>
                    </Card>
                </Col>
                <Col>
                    <Card title={t('locations')} type={activeApplyRuleCategory.some(active => active === 'locations') ? 'positive' : 'primary'}>
                        <Row className="mb-2 ml-0 mr-0">
                            {ruleLocations.length === 0 ? <Tag>{t('all')}</Tag> : <div></div>}
                            {ruleLocations.map(location => (
                                <Tag key={location?.id} type={location.invalid ? 'negative' : 'positive'} className="m-1">
                                    {location?.name}
                                </Tag>
                            ))}
                        </Row>
                        <Row className="ml-0">
                            <Button
                                icon="edit"
                                onClick={setRuleLocationsModalOpen.bind(this, true)}
                                disabled={activeApplyRuleCategory.some(active => active === 'cabinets')}
                            >
                                {t('change')}
                            </Button>
                        </Row>
                    </Card>
                </Col>
                {discountType !== 'bundleDiscounts' && (
                    <>
                        <Col>
                            <Card title={t('products')} type={activeApplyRuleCategory.some(active => active === 'products') ? 'positive' : 'primary'}>
                                <Row className="mb-2 ml-0 mr-0">
                                    {ruleProducts.length === 0 ? <Tag>{t('all')}</Tag> : <div></div>}
                                    {ruleProducts.map(product => (
                                        <Tag key={product?.id} type={product.invalid ? 'negative' : 'positive'} className="m-1">
                                            {product?.name}
                                        </Tag>
                                    ))}
                                </Row>
                                <Row className="ml-0">
                                    <Button
                                        icon="edit"
                                        onClick={setRuleProductsModalOpen.bind(this, true)}
                                        disabled={activeApplyRuleCategory.some(active => active === 'productCategories')}
                                    >
                                        {t('change')}
                                    </Button>
                                </Row>
                            </Card>
                        </Col>
                        <Col>
                            <Card
                                title={t('productCategoriesTitle')}
                                type={activeApplyRuleCategory.some(active => active === 'productCategories') ? 'positive' : 'primary'}
                            >
                                <Row className="mb-2 ml-0 mr-0">
                                    {ruleProductCategories.length === 0 ? <Tag>{t('all')}</Tag> : <div></div>}
                                    {ruleProductCategories.map(productCategory => (
                                        <Tag key={productCategory?.id} type={productCategory.invalid ? 'negative' : 'positive'} className="m-1">
                                            {productCategory?.name}
                                        </Tag>
                                    ))}
                                </Row>
                                <Row className="ml-0">
                                    <Button
                                        icon="edit"
                                        onClick={() => setRuleProductCategoriesModalOpen(true)}
                                        disabled={activeApplyRuleCategory.some(active => active === 'products')}
                                    >
                                        {t('change')}
                                    </Button>
                                </Row>
                            </Card>
                        </Col>
                    </>
                )}
            </Row>
            {discountType === 'expiryDiscounts' && (
                <>
                    <Row>
                        <Col xs="12">{t('discountRuleStops')}:</Col>
                        <Col xs="12" className="mb-2">
                            <DiscountRuleStops
                                isAdmin={props.isAdmin}
                                preview={true}
                                rule={{
                                    ...tmpExpiryDiscount,
                                    Status: ruleStatus,
                                    DiscountData: { ...tmpExpiryDiscount?.DiscountData, discountStops: ruleStops },
                                }}
                                toggleDiscountStatus={handleNewExpiryDiscountStatusChange}
                                removeStop={removeDiscountRuleStop}
                                handleDiscountEdit={handleDiscountEdit}
                                imageUrl={props.expiryImageUrl}
                                imageMarkedAsDeleted={markExpiryImageAsDeleted}
                                t={t}
                            />
                        </Col>
                    </Row>
                    <Row>
                        {!ruleEditMode && !ruleStops.length && (
                            <Col xs="12" md={4}>
                                <Button icon="add" type="positive" onClick={() => handleNewExpiryStopClick()}>
                                    {t('addDiscountStop')}
                                </Button>
                            </Col>
                        )}
                    </Row>
                </>
            )}
            {discountType === 'scheduledDiscounts' && (
                <Row className="mt-2">
                    <Col xs="12">{t('scheduledDiscounts')}:</Col>
                    {/* Conditional not to show empty discount */}
                    {tmpScheduledDiscount?.DiscountData && (
                        <Col xs="12" className="mb-2">
                            <ScheduledDiscounts
                                isAdmin={props.isAdmin}
                                preview={true}
                                toggleDiscountStatus={saveTmpScheduleDiscount}
                                handleDiscountEdit={handleDiscountEdit}
                                cabinets={props.allCabinets}
                                products={props.allProducts}
                                productCategories={props.allProductCategories}
                                rule={tmpScheduledDiscount}
                                imageUrl={props.scheduledImageUrl}
                                imageMarkedAsDeleted={markScheduledImageAsDeleted}
                                t={t}
                            />
                        </Col>
                    )}
                    {!ruleEditMode && !tmpScheduledDiscount?.DiscountData && (
                        <Col xs="12">
                            <Button
                                icon="add"
                                type="positive"
                                onClick={() => {
                                    setRuleEditMode(true);
                                    setScheduledDiscountModalOpen(true);
                                }}
                            >
                                {t('addScheduledDiscount')}
                            </Button>
                        </Col>
                    )}
                </Row>
            )}
            {discountType === 'bundleDiscounts' && (
                <Row className="mt-2">
                    <Col xs="12">{t('bundledDiscounts')}:</Col>
                    {/* Conditional not to show empty discount */}
                    {tmpBundleDiscount && (
                        <Col xs="12" className="mb-2">
                            <BundleDiscounts
                                isAdmin={props.isAdmin}
                                preview={true}
                                toggleDiscountStatus={saveTmpBundleDiscount}
                                handleDiscountEdit={handleDiscountEdit}
                                cabinets={props.allCabinets}
                                products={props.allProducts}
                                productCategories={props.allProductCategories}
                                rule={{ ...tmpBundleDiscount, Status: ruleStatus }}
                                t={t}
                            />
                        </Col>
                    )}
                    {!tmpBundleDiscount && (
                        <Col xs="12">
                            <Button icon="add" type="positive" onClick={() => setBundledDiscountModalOpen(true)}>
                                {t('addBundleDiscount')}
                            </Button>
                        </Col>
                    )}
                </Row>
            )}

            <Row>
                <Col xs="12">
                    <div className="d-flex flex-row-reverse">
                        <Button className="m-2" onClick={() => handleRuleCreationClick()} disabled={!isCreationAllowed()}>
                            {editData ? t('update') : t('create')}
                        </Button>
                        <Button className="m-2" type="secondary" onClick={props.cancelCreate}>
                            {t('cancel')}
                        </Button>
                    </div>
                </Col>
            </Row>
        </div>
    );
}

export default withRouter(
    connect(
        state => ({
            isAdmin: state && state.user && state.user.isAdmin,
            discounts: state.discounts,
            bundleImageStoring: state.discounts.bundleImageStoring,
            bundleImageUrl: state.discounts.bundleImageUrl,
            expiryImageStoring: state.discounts.expiryImageStoring,
            expiryImageUrl: state.discounts.expiryImageUrl,
            scheduledImageStoring: state.discounts.scheduledImageStoring,
            scheduledImageUrl: state.discounts.scheduledImageUrl,
            cabinets: state.cabinets,
            products: state.products,
            productCategories: state.productCategories,
        }),
        {
            fetchDiscountRules,
            createDiscountRule,
            updateDiscountRule,
            fetchCabinets,
            fetchLocations,
            fetchProducts,
            fetchProductCategories,
            uploadBundleImage,
            uploadExpiryImage,
            uploadScheduledImage,
            resetBundleImage,
            resetExpiryImage,
            resetScheduledImage,
        }
    )(translate('main')(Discounts))
);
