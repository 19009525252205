import { fetchDeviceAlarms as fetchDeviceAlarmsHttpRequest } from '../api.js';

export const REQUEST_ALARMS = 'REQUEST_ALARMS';
export const RECEIVE_ALARMS = 'RECEIVE_ALARMS';
export const REQUEST_ALARMS_ERRORS = 'REQUEST_ALARMS_ERRORS';

const notifyAlarmsQuestStarted = () => {
    return {
        type: REQUEST_ALARMS,
    };
};

const receivedAlarms = alarms => {
    return {
        type: RECEIVE_ALARMS,
        alarms,
    };
};

const receiveAlarmErrors = error => {
    return {
        type: REQUEST_ALARMS_ERRORS,
        error,
    };
};

/**
 *
 * @param {string} group
 * @returns
 */
export function fetchOrganizationAlarms(group) {
    return (dispatch, getState) => {
        dispatch(notifyAlarmsQuestStarted());

        const user = getState().user;
        return fetchDeviceAlarmsHttpRequest(user.group, user.accessToken)
            .then(data => dispatch(receivedAlarms(data)))
            .catch(error => dispatch(receiveAlarmErrors(error)));
    };
}
