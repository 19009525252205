import React, { useEffect, useState } from 'react';
import { TabItem } from '@storaensods/seeds-react';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { withRouter } from 'react-router';

import { fetchCabinets, fetchLocations } from '../../actions/cabinets';
import { fetchProducts } from '../../actions/products';
import { fetchRefillPlans, updateRefillPlan, deleteRefillPlan, refillPlanCabinetSuggestions, createRefillPlan } from '../../actions/refillPlans';
import icons from './../../icons/icomoon.svg';
import RefillSchemas from './tabs/refillSchemas';
import InfoBanner from './segments/InfoBanner';
import { fetchCabinetRefillRule, fetchRefillRules, postSettingsCopyRequest, uploadPlanogramImage } from '../../actions/controlPanel';
import Loader from './segments/loader';
import { updateRefillRule, createRefillRule, deleteRefillRule } from '../../actions/controlPanel';
import ActivePlans from './tabs/activePlans';
import { fetchCabinetInventory } from '../../actions/inventory';
import { getTransactions } from '../../actions/report';
import RefillQRCode from './tabs/refillQRCode';

const RefillPlans = props => {
    const {
        fetchCabinets,
        fetchLocations,
        cabinets,
        products,
        fetchProducts,
        fetchRefillPlans,
        refillPlans,
        fetchCabinetRefillRule,
        refillRule,
        allRefillRules,
        fetchRefillRules,
        updateRefillRule,
        createRefillPlan,
        createRefillRule,
        deleteRefillRule,
        updateRefillPlan,
        fetchCabinetInventory,
        inventory,
        getTransactions,
        cabinetTransactionReport,
        refillPlanCabinetSuggestions,
        deleteRefillPlan,
        organization,
        t,
        postSettingsCopyRequest,
        planogramImageInStore,
        uploadPlanogramImage,
        user,
    } = props;

    const [activeTab, setActiveTab] = useState('qrCode');
    const [loading, setLoading] = useState(true);
    const [hasErrors, setHasErrors] = useState(false);
    // eslint-disable-next-line
    const [selectedOrganization, setSelectedOrganization] = useState(organization);

    //on component load,
    //1. fetch all cabinets, if not already fetched
    //2. fetch all refill plans
    //3. fetch transaction from last 7 data
    //4. fetch current cabinet inventory
    //5. fetch products, if not fetched already
    useEffect(() => {
        let apiRequest = [];

        if (!cabinets.fetched) apiRequest.push(fetchCabinets());
        if (!cabinets.locationsFetched) apiRequest.push(fetchLocations());
        if (!products.fetched && !products.isFetching) apiRequest.push(fetchProducts());
        if (!refillPlans.fetched && !refillPlans.isFetching) apiRequest.push(fetchRefillPlans());
        if (!allRefillRules.fetched && !allRefillRules.isFetching) apiRequest.push(fetchRefillRules());

        Promise.all(apiRequest)
            .then(() => setLoading(false))
            .catch(e => {
                setHasErrors(true);
                setLoading(false);
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (selectedOrganization === organization) return;
        setLoading(true);
        //when organization changes, refetch all the data for the new organization
        const apiRequest = [fetchCabinets(), fetchLocations(), fetchProducts(), fetchRefillPlans(), fetchRefillRules()];

        Promise.all(apiRequest)
            .then(() => setLoading(false))
            .catch(e => {
                setHasErrors(true);
                setLoading(false);
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [organization]);

    if (loading) {
        return <Loader message={t('main:fetchingLoader')} />;
    }

    if (hasErrors) {
        return (
            <div className="refillplans__status refillplans__status--error">
                <svg>
                    <use xlinkHref={`${icons}#icon-error_outline`}></use>
                </svg>
                <p>Error loading the refill schema. Please try again later.</p>
            </div>
        );
    }

    const getRefillPlansCount = () => {
        const allActivePlans = refillPlans.activePlans || {};
        return Object.keys(allActivePlans).length ? Object.keys(allActivePlans).length : 0;
    };

    return (
        <div className="refillplans">
            <InfoBanner message={activeTab === 'refillSchemas' ? t('refillSchemaTitle') : activeTab === 'qrCode' ? t('qrcodeTitle') : t('refillPlansTitle')} />
            <div className="refillplans__tabs">
                {user && (user.isAdmin || user.isRefillOperator) && (
                    <TabItem isActive={activeTab === 'qrCode'} onClick={() => setActiveTab('qrCode')}>
                        {t('main:refillQRCodeTitle')}
                    </TabItem>
                )}

                <TabItem isActive={activeTab === 'refillSchemas'} onClick={() => setActiveTab('refillSchemas')}>
                    {t('refillSchemas')}
                </TabItem>
                <TabItem isActive={activeTab === 'activePlans'} onClick={() => setActiveTab('activePlans')}>
                    {t('refillPlans')} ({getRefillPlansCount()})
                </TabItem>
            </div>

            {activeTab === 'qrCode' && <RefillQRCode cabinets={cabinets.cabinets || []} locations={cabinets.locations || []} t={t} />}

            {activeTab === 'refillSchemas' && (
                <RefillSchemas
                    cabinets={cabinets.cabinets || []}
                    locations={cabinets.locations || []}
                    refillRule={refillRule}
                    fetchCabinetRefillRule={fetchCabinetRefillRule}
                    allProducts={products.products || []}
                    updateCabinetRefillSchema={updateRefillRule}
                    createRefillSchema={createRefillRule}
                    deleteRefillSchema={deleteRefillRule}
                    fetchRefillRules={fetchRefillRules}
                    t={t}
                    postSettingsCopyRequest={postSettingsCopyRequest}
                    allRefillRules={allRefillRules}
                    allRefillPlans={refillPlans}
                    planogramImageInStore={planogramImageInStore}
                    uploadPlanogramImage={uploadPlanogramImage}
                    isAdmin={user.isAdmin}
                />
            )}

            {activeTab === 'activePlans' && (
                <ActivePlans
                    refillPlans={refillPlans}
                    allRefillRules={allRefillRules}
                    fetchRefillRules={fetchRefillRules}
                    locations={cabinets.locations || []}
                    cabinets={cabinets.cabinets || []}
                    updateRefillPlan={updateRefillPlan}
                    fetchRefillPlans={fetchRefillPlans}
                    fetchCabinetInventory={fetchCabinetInventory}
                    cabinetInventory={inventory}
                    getTransactions={getTransactions}
                    cabinetTransactionReport={cabinetTransactionReport}
                    deleteRefillPlan={deleteRefillPlan}
                    organization={organization}
                    refillPlanCabinetSuggestions={refillPlanCabinetSuggestions}
                    allProducts={products.products || []}
                    createRefillPlan={createRefillPlan}
                    t={t}
                    planogramImageInStore={planogramImageInStore}
                    uploadPlanogramImage={uploadPlanogramImage}
                    isAdmin={user.isAdmin}
                />
            )}
        </div>
    );
};

export default withRouter(
    connect(
        state => ({
            cabinets: state.cabinets,
            products: state.products,
            refillPlans: state.refillPlans,
            refillRule: state.controlPanel.cabinetRefillRule,
            controlPanel: state.controlPanel,
            allRefillRules: state.refillRules,
            inventory: state.inventory,
            cabinetTransactionReport: state.report.transactions,
            organization: state.user.group.Name,
            planogramImageInStore: state.controlPanel.cabinetPlanogramImage,
            user: state.user,
        }),
        {
            fetchCabinets,
            fetchLocations,
            fetchProducts,
            fetchRefillPlans,
            fetchCabinetRefillRule,
            updateRefillRule,
            createRefillRule,
            deleteRefillRule,
            fetchRefillRules,
            updateRefillPlan,
            fetchCabinetInventory,
            getTransactions,
            deleteRefillPlan,
            refillPlanCabinetSuggestions,
            createRefillPlan,
            postSettingsCopyRequest,
            uploadPlanogramImage,
        }
    )(translate('refill')(RefillPlans))
);
