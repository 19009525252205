const defaultState = {
    automation: null,
    fetched: false,
    isFetching: false,
    isError: false,
};

export default function automations(state = defaultState, action) {
    switch (action.type) {
        case 'REQUEST_CREATE_AUTOMATION':
            return {
                ...state,
                isFetching: true,
            };
        case 'RECEIVE_CREATE_AUTOMATION':
            return {
                ...state,
                isFetching: false,
                isError: false,
                fetched: true,
            };
        case 'RECEIVE_CREATE_AUTOMATION_ERROR':
            return {
                ...state,
                isFetching: false,
                isError: false,
                fetched: true,
                error: action.error.toString(),
            };
        case 'REQUEST_AUTOMATIONS':
            return {
                ...state,
                isFetching: true,
            };
        case 'RECEIVE_AUTOMATIONS':
            return {
                ...state,
                isFetching: false,
                isError: false,
                fetched: true,
                automation: action.automation,
            };
        case 'RECEIVE_AUTOMATIONS_ERROR':
            return {
                ...state,
                isFetching: false,
                isError: false,
                fetched: true,
                error: action.error.toString(),
            };
        default:
            return state;
    }
}