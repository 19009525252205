import React, { Component } from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { Card, Row, Col } from 'reactstrap';
import { Tag, Button } from '@storaensods/seeds-react';
import './signin.css';
import { signInUser, redirectToAuthorizationPage, signOutUser } from '../../actions/user.js';
import Loading from './loading/loading.js';

function SignInForm({ t, signInError, handleSignIn }) {
    return (
        <div>
            {/* Lock icon */}
            <div className="text-center mb-3">
                <img src="https://res.cloudinary.com/stora-enso-oyj/image/upload/w_50/v1509297104/04b7e7_1_bojyie.png" alt="lock" />
            </div>

            {signInError ? <div className="mb-5 text-danger">{t(signInError)}</div> : null}
            <p>{t('signInMessage')}</p>
            <hr />
            <div className="w-100 text-center">
                <Button onClick={handleSignIn}>{t('signInButton')}</Button>
            </div>
        </div>
    );
}

function BadgeLink({ onClick, selected, text }) {
    return (
        <Tag className="m-1" type={selected ? 'attention' : 'primary'} onClick={onClick}>
            {text}
        </Tag>
    );
}

/**
 * Sign in page.
 */
export class SignIn extends Component {
    /**
     * Constructor.
     * @param {*} props
     */
    constructor(props) {
        super(props);
        this.state = {
            languages: JSON.parse(process.env.REACT_APP_LANGUAGES),
        };
    }
    /**
     * Try to sign in automatically when component mounts (if no organizations, then back to authorization page + error message).
     */
    componentDidMount() {
        if (!this.props.fetching && !this.props.signInError) {
            this.props.signInUser();
        } else {
            this.props.signInError === 'signInExpiredError'
                ? this.props.redirectToAuthorizationPage('signInExpiredError')
                : this.props.redirectToAuthorizationPage('noOrganizationsError');
        }
    }

    /**
     * Handle user sign in (if user had no organizations he/she'll be sign out).
     */
    handleSignIn() {
        this.props.signInError === 'noOrganizationsError' ? this.props.signOutUser() : this.props.redirectToAuthorizationPage();
    }

    /**
     * Switches the language.
     * @param {*} lang Current selected language.
     */
    handleLanguageSelect(lang) {
        this.props.i18n.changeLanguage(lang);
    }

    /**
     * Render
     */
    render() {
        const { t, i18n, fetching, signInError } = this.props;
        return (
            <div className="renewable-materials-company">
                <div className="container">
                    <Row className="justify-content-md-center">
                        <Col xs="12" md="6" lg="5">
                            <Card body className="signin-card card-4">
                                {fetching ? (
                                    <Loading />
                                ) : (
                                    <div>
                                        <div className="mb-3 text-center">
                                            {this.state.languages.map(lang => (
                                                <BadgeLink
                                                    key={lang}
                                                    selected={i18n.language === lang}
                                                    lang={lang}
                                                    text={i18n.getNativeName(lang)}
                                                    onClick={() => {
                                                        this.handleLanguageSelect(lang);
                                                    }}
                                                />
                                            ))}
                                        </div>
                                        <SignInForm t={t} signInError={signInError} handleSignIn={this.handleSignIn.bind(this)} />
                                    </div>
                                )}
                            </Card>
                        </Col>
                    </Row>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        fetching: state.user.fetching,
        signInError: state.user.error,
    };
};

const mapDispatchToProps = { signInUser, redirectToAuthorizationPage, signOutUser };

export default connect(mapStateToProps, mapDispatchToProps)(translate('signIn')(SignIn));
