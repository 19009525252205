export const initState = {
    isFetching: false,
    isError: false,
    productTypes: [],
};

export default function products(state = initState, action) {
    switch (action.type) {
        case 'REQUEST_PRODUCT_TYPES':
            return {
                ...state,
                isFetching: true,
                isError: false,
            };
        case 'RECEIVE_PRODUCT_TYPES':
            return {
                ...state,
                isFetching: false,
                isError: false,
                productTypes: action.productTypes,
                lastUpdated: action.receivedAt,
            };
        case 'RECEIVE_PRODUCT_TYPES_ERROR':
            console.error(action.error);
            return {
                ...state,
                isFetching: false,
                isError: true,
                error: action.error.toString(),
            };
        default:
            return state;
    }
}
