import React, { useState, useEffect, Fragment } from 'react';
import { Button, Spinner, RadioButton } from '@storaensods/seeds-react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { Modal, ModalBody, ModalFooter, Table } from 'reactstrap';
import { compareVersions, validate } from 'compare-versions';

import { fetchDeviceSoftwareVersion, updateDeviceSoftwareVersion } from '../../actions/controlPanel';

const SoftwareVersion = ({ deviceCode, deviceId, softwareVersions, fetchDeviceSoftwareVersion, updateDeviceSoftwareVersion, t }) => {
    const [selectedVersion, setSelectedVersion] = useState();
    const [confirmationOpen, setConfirmationOpen] = useState(false);
    const [updateRequest, setUpdateRequest] = useState(false);
    const [expandReleaseNotes, setExpandReleaseNotes] = useState(false);
    const [releaseNotesDetails, setReleaseNotesDetails] = useState({});
    const maxReleaseNotesDisplayLength = 80; // Amount of characters shown in the three displayed lines in the table

    useEffect(() => {
        fetchDeviceSoftwareVersion(deviceId);
    }, [deviceId, fetchDeviceSoftwareVersion]);

    useEffect(() => {
        setSelectedVersion(softwareVersions.current);
    }, [softwareVersions]);

    useEffect(() => {
        if (!updateRequest) {
            return;
        }

        setTimeout(() => {
            setUpdateRequest(false);
        }, 10000);
    }, [updateRequest]);

    const validateURL = releaseNotesInput => /^(ftp|http|https):\/\/[^ "]+$/.test(releaseNotesInput);

    const getSoftwareVersions = (deviceType = { type: '', osVersion: '' }, allVersions) => {
        const validatedSoftwareVersions = [];

        return allVersions
            .filter(version => {
                if (version.releaseTagVersion != null && validate(version.releaseTagVersion)) return true;
                else return false;
            })
            .filter(version => version.deviceType === deviceType.type)
            .filter(version => version.osVersion === deviceType.osVersion)
            .filter(version => version.deviceSubType === deviceType.subType || !version.deviceSubType)
            .reduce((acc, cur) => {
                if (acc.findIndex(i => i.releaseTagVersion === cur.releaseTagVersion) === -1) acc.push(cur);
                return acc;
            }, [])
            .map(version => {
                validatedSoftwareVersions.push(version);
                return version.releaseTagVersion;
            })
            .sort(compareVersions)
            .reverse()
            .map((version, index) => {
                const softwareVersion = validatedSoftwareVersions.find(item => item.releaseTagVersion === version);
                if (!softwareVersion) {
                    return <></>;
                }
                const releaseNotes = softwareVersion.releaseContent;
                const current = softwareVersions && softwareVersions.current === version;
                const isReleaseNotesURL = validateURL(releaseNotes);

                // Renders a link or text of the release notes based on the isReleaseNotesURL URL validator
                const releaseNotesDiv = () => (
                    <div>
                        <div className="software-tables-release-notes">
                            {isReleaseNotesURL ? (
                                <a href={releaseNotes} target="_blank" rel="noopener noreferrer">
                                    {releaseNotes}
                                </a>
                            ) : (
                                releaseNotes
                            )}
                        </div>
                        {releaseNotes && releaseNotes.length > maxReleaseNotesDisplayLength && (
                            <Button
                                onClick={() => {
                                    setExpandReleaseNotes(true);
                                    setReleaseNotesDetails({ version, releaseNotes });
                                }}
                                className="software-table-release-notes-button"
                            >
                                {t('seeMore')}
                            </Button>
                        )}
                    </div>
                );

                return (
                    <tr className={selectedVersion === version ? 'software-table-selection' : ''} key={version}>
                        <td className="software-table-row">
                            <RadioButton
                                id={version}
                                label={version}
                                name="release-version"
                                checked={selectedVersion === version}
                                onChange={() => {
                                    setSelectedVersion(version);
                                }}
                            />
                            <span className="software-tables-info">
                                {index === 0 ? <div className="software-table-latest">{t('latestRelease')}</div> : ''}
                                {current && <div className={`${current ? 'software-table-current' : ''}`}>{t('currentVersion')}</div>}
                            </span>
                        </td>
                        {<td>{releaseNotesDiv()}</td>}
                    </tr>
                );
            });
    };

    const openDialog = confirmationOpen || expandReleaseNotes;

    return (
        <div className="software">
            {openDialog && (
                <Modal isOpen={openDialog} centered={true}>
                    <ModalBody>
                        <h5>
                            {confirmationOpen && t('softwareUpdateConfirmation')}{' '}
                            <strong>
                                {confirmationOpen && selectedVersion}
                                {expandReleaseNotes && releaseNotesDetails.version}
                            </strong>{' '}
                            {expandReleaseNotes && t('releaseNotes')}
                        </h5>
                        <p className="software-version-modal-body">
                            {confirmationOpen && t('softwareUpdateWarn')}
                            {expandReleaseNotes && releaseNotesDetails.releaseNotes}
                        </p>
                    </ModalBody>
                    <ModalFooter>
                        {confirmationOpen && (
                            <Button
                                color="primary"
                                icon="update"
                                type="attention"
                                onClick={() => {
                                    setUpdateRequest(true);
                                    updateDeviceSoftwareVersion(deviceId, selectedVersion);
                                    setConfirmationOpen(false);
                                }}
                            >
                                {t('update')}
                            </Button>
                        )}
                        <Button
                            color="secondary"
                            icon="close"
                            onClick={() => {
                                if (confirmationOpen) setConfirmationOpen(false);
                                if (expandReleaseNotes) {
                                    setExpandReleaseNotes(false);
                                    setReleaseNotesDetails({});
                                }
                            }}
                        >
                            {confirmationOpen && t('cancel')}
                            {expandReleaseNotes && t('close')}
                        </Button>
                    </ModalFooter>
                </Modal>
            )}
            {softwareVersions.isError && softwareVersions.fetched && <div className="software-error">{true('fetchingError')}</div>}
            {softwareVersions.fetched && updateRequest && <div className="software-notification">{t('softwareUpdateSuccess')}</div>}
            {softwareVersions.isFetching && <Spinner />}
            {deviceCode && softwareVersions.fetched && !softwareVersions.isError && (
                <Fragment>
                    <div className="software-current">
                        <label>
                            <strong>
                                {t('currentSoftwareVersion')}
                                <span className="software-current-version">{softwareVersions.current ? softwareVersions.current : '-'}</span>
                            </strong>
                        </label>
                    </div>
                    <Table className="software-table">
                        <thead>
                            <tr>
                                <th className="software-table-version-header">{t('releaseVersion')}</th>
                                <th className="software-table-version-header">{t('releaseNotes')}</th>
                            </tr>
                        </thead>
                        <tbody>{getSoftwareVersions(softwareVersions.deviceType, softwareVersions.allVersions)}</tbody>
                    </Table>
                    <Button
                        icon="save"
                        disabled={selectedVersion === softwareVersions.current || !selectedVersion}
                        onClick={() => {
                            setConfirmationOpen(true);
                        }}
                    >
                        {t('update')}
                    </Button>
                </Fragment>
            )}
        </div>
    );
};

export default withRouter(
    connect(
        state => ({
            softwareVersions: state.controlPanel.softwareVersions,
        }),
        {
            fetchDeviceSoftwareVersion,
            updateDeviceSoftwareVersion,
        }
    )(translate('main')(SoftwareVersion))
);
