import { storeSelectedEncodingDeviceId } from '../localStorage.js';
import { storeSelectedEncodingDevice } from '../localStorage';
import { fetchEncodingDevices as fetchEncodingDevicesHttpRequest } from '../api.js';

export const REQUEST_ES_DEVICES = 'REQUEST_ES_DEVICES';
export const RECEIVE_ES_DEVICES = 'RECEIVE_ES_DEVICES';
export const RECEIVE_ES_DEVICES_ERROR = 'RECEIVE_ES_DEVICES_ERROR';
export const SELECT_ES_DEVICE = 'SELECT_ES_DEVICE';
export const RESET_ES_DEVICES = 'RESET_ES_DEVICES';
export const REQUEST_SET_CABINET_LOCATION = 'REQUEST_SET_CABINET_LOCATION';
export const SET_CABINET_LOCATION_SUCCESS = 'SET_CABINET_LOCATION_SUCCESS';
export const SET_CABINET_LOCATION_ERROR = 'SET_CABINET_LOCATION_ERROR';
export const CABINET_SEARCH_QUERY_UPDATED = 'CABINET_SEARCH_QUERY_UPDATED';
export const REQUEST_LOCATIONS = 'REQUEST_LOCATIONS';
export const RECEIVE_LOCATIONS = 'RECEIVE_LOCATIONS';
export const RECEIVE_LOCATIONS_ERROR = 'RECEIVE_LOCATIONS_ERROR';
export const REQUEST_CREATE_LOCATION = 'REQUEST_CREATE_LOCATION';
export const CREATE_LOCATION_SUCCESS = 'CREATE_LOCATION_SUCCESS';
export const CREATE_LOCATION_ERROR = 'CREATE_LOCATION_ERROR';
export const REQUEST_RETIRE_LOCATION = 'REQUEST_RETIRE_LOCATION';
export const RETIRE_LOCATION_ERROR = 'RETIRE_LOCATION_ERROR';
export const REQUEST_UPDATE_LOCATION = 'REQUEST_UPDATE_LOCATION';
export const UPDATE_LOCATION_ERROR = 'UPDATE_LOCATION_ERROR';
export const REQUEST_UPDATE_CABINET_NAME = 'REQUEST_UPDATE_CABINET_NAME';
export const UPDATE_CABINET_NAME_ERROR = 'UPDATE_CABINET_NAME_ERROR';

export const CLEAR_STATE_ERROR = 'CLEAR_STATE_ERROR';

/**
 * Request DES devices Redux action creator
 */
function requestEncodingDevices() {
    return {
        type: REQUEST_ES_DEVICES,
    };
}

/**
 * Receive devices Redux action creator
 * @param {array} encodingDevices
 */
function receiveEncodingDevices(encodingDevices) {
    return {
        type: RECEIVE_ES_DEVICES,
        encodingDevices,
        receivedAt: Date.now(),
    };
}

/**
 * Receive DES devices error Redux action creator
 * @param {Error} error
 */
function receiveEncodingDevicesError(error) {
    return {
        type: RECEIVE_ES_DEVICES_ERROR,
        error,
    };
}

/**
 * Reset to init state
 */
function resetEncodingDevices() {
    return {
        type: RESET_ES_DEVICES,
    };
}

/**
 * Sets a cabinet as active selected cabinet
 * @param {string} selectedCabinetDeviceCode Cabinet device code to select
 */
export function selectEncodingDevice(selectedEncodingDeviceCode, selectedEncodingDeviceId = null) {
    storeSelectedEncodingDevice(selectedEncodingDeviceCode);
    storeSelectedEncodingDeviceId(selectedEncodingDeviceId);
    return {
        type: SELECT_ES_DEVICE,
        selectedEncodingDeviceCode,
        selectedEncodingDeviceId,
    };
}

export function refreshDevices() {
    return dispatch => {
        dispatch(resetEncodingDevices());
        dispatch(fetchEncodingDevices());
    };
}

/**
 * Thunk action creator for fetching devices
 */
export function fetchEncodingDevices() {
    return (dispatch, getState) => {
        // update state to inform API call started
        dispatch(requestEncodingDevices());

        // fetch products then update state
        const user = getState().user;
        return fetchEncodingDevicesHttpRequest(user.accessToken, user.group)
            .then(devices => {
                // get selected cabinet from localStorage if set

                dispatch(receiveEncodingDevices(devices));
            })
            .catch(error => dispatch(receiveEncodingDevicesError(error)));
    };
}
