import { v1 as uuidv1 } from 'uuid';
import {
    fetchAdvertisements as fetchAdsHttpRequest,
    updateAd as updateAdHttpRequest,
    createAdImage as createAdImageHttpRequest,
    createAdMedia,
    deleteAd as deleteAdHttpRequest,
} from '../api.js';

export const REQUEST_ADS = 'REQUEST_ADS';
export const RECEIVE_ADS = 'RECEIVE_ADS';
export const RECEIVE_ADS_ERROR = 'RECEIVE_ADS_ERROR';
export const REQUEST_UPDATE_AD = 'REQUEST_UPDATE_AD';
export const RECEIVE_UPDATE_AD = 'RECEIVE_UPDATE_AD';
export const RECEIVE_UPDATE_AD_ERROR = 'RECEIVE_UPDATE_AD_ERROR';
export const DISMISS_UPDATE_AD_RESULT = 'DISMISS_UPDATE_AD_RESULT';
export const REQUEST_UPLOAD_AD_IMAGE = 'REQUEST_UPLOAD_AD_IMAGE';
export const UPLOAD_AD_IMAGE_SUCCESS = 'UPLOAD_AD_IMAGE_SUCCESS';
export const UPLOAD_AD_IMAGE_ERROR = 'UPLOAD_AD_IMAGE_ERROR';
export const REQUEST_DELETE_AD = 'REQUEST_DELETE_AD';
export const DELETE_AD_SUCCESS = 'DELETE_AD_SUCCESS';
export const DELETE_AD_ERROR = 'DELETE_AD_ERROR';
export const RESET_ADS = 'RESET_ADS';

/**
 * Request advertiseemnts Redux action creator
 */
function requestAds() {
    return {
        type: REQUEST_ADS,
    };
}

/**
 * Receive advertiseemnts Redux action creator
 * @param {array} ads
 */
function receiveAds(ads) {
    return {
        type: RECEIVE_ADS,
        ads,
        receivedAt: Date.now(),
    };
}

/**
 * Receive advertisements error Redux action creator
 * @param {Error} error
 */
function receiveAdsError(error) {
    return {
        type: RECEIVE_ADS_ERROR,
        error,
    };
}

/**
 * Request update ad Redux action creator
 */
function requestUpdateAd(ad) {
    return {
        type: REQUEST_UPDATE_AD,
        ad,
    };
}

/**
 * Receive update ad error Redux action creator
 * @param {Error} error
 */
function receiveUpdateAdError(error) {
    return {
        type: RECEIVE_UPDATE_AD_ERROR,
        error,
    };
}

/**
 * Dismiss update ad result Redux action creator
 */
export function dismissUpdateAdResult() {
    return {
        type: DISMISS_UPDATE_AD_RESULT,
    };
}

/**
 * Request create ad image Redux action creator
 */
export function requestUpdateAdImage(progress) {
    return {
        type: REQUEST_UPLOAD_AD_IMAGE,
        progress,
    };
}

/**
 * Receive create ad image success response Redux action creator
 */
export function uploadAdImageSuccess(url, id) {
    return {
        type: UPLOAD_AD_IMAGE_SUCCESS,
        url,
        id,
        receivedAt: Date.now(),
    };
}

/**
 * Receive create ad image error Redux action creator
 * @param {Error} error
 */
function uploadAdImageError(error) {
    return {
        type: UPLOAD_AD_IMAGE_ERROR,
        error,
    };
}

/**
 * Resets to init state
 */
function resetAds() {
    return {
        type: RESET_ADS,
    };
}

export function requestDeleteAd() {
    return {
        type: REQUEST_DELETE_AD,
    };
}

export function deleteAdSuccess() {
    return {
        type: DELETE_AD_SUCCESS,
    };
}

export function deleteAdError() {
    return {
        type: DELETE_AD_ERROR,
    };
}

/**
 * Used to remove image from form of ad being updated/created
 */
export function refreshAds() {
    return dispatch => {
        dispatch(resetAds());
        dispatch(fetchAds());
    };
}

/**
 * Thunk action creator for fetching advertisements
 */
export function fetchAds() {
    return (dispatch, getState) => {
        // update state to inform API call started
        dispatch(requestAds());

        // fetch ads then update state
        const user = getState().user;
        return fetchAdsHttpRequest(user.accessToken, user.group)
            .then(response => dispatch(receiveAds(response)))
            .catch(error => dispatch(receiveAdsError(error)));
    };
}

/**
 * Thunk action creator for updating existing ad
 * @param {Ad} ad
 */
export function updateAd(adIn) {
    // ad with only fields permitted by backend PUT endpoint
    const ad = {
        title: adIn.title,
        regions: adIn.regions,
        cabinets: adIn.cabinets,
        individualCabinetsOnly: adIn.individualCabinetsOnly,
    };

    if (adIn.description !== '' && adIn.description != null) ad.description = adIn.description;
    if (adIn.activationDate) ad.activationDate = adIn.activationDate;
    if (adIn.deactivationDate) ad.deactivationDate = adIn.deactivationDate;

    return (dispatch, getState) => {
        // update state to inform API call started
        dispatch(requestUpdateAd(adIn));

        // fetch ads then update state
        const user = getState().user;
        return updateAdHttpRequest(adIn.id, ad, user.accessToken, user.group)
            .then(() => {
                dispatch(refreshAds());
            })
            .catch(error => dispatch(receiveUpdateAdError(error)));
    };
}

/**
 *
 * @param {File} imageFile
 */
export function uploadAdImage(imageFile, isVideo = false) {
    return (dispatch, getState) => {
        // update state to inform API call started
        dispatch(requestUpdateAdImage());

        // convert image file to HTTP form data with unique filename
        const formData = new FormData();
        const fileName = `${uuidv1()}${isVideo ? '.mp4' : '.jpeg'}`;
        //`${uuidv1()}.jpeg`
        formData.append(0, imageFile, fileName);

        // store image in backend
        const user = getState().user;

        return createAdImageHttpRequest(formData, user.accessToken, user.group)
            .then(response => dispatch(uploadAdImageSuccess(encodeURI(response.url), response.id)))
            .catch(error => dispatch(uploadAdImageError(error)));
    };
}

/**
 *
 * Upload adverttisement media file
 */
export function uploadMediaFile(blobChunck, fileName) {
    return (dispatch, getState) => {
        dispatch(requestUpdateAdImage());

        const formData = new FormData();
        formData.append(0, blobChunck, fileName);

        const user = getState().user;

        return createAdMedia(formData, user.accessToken, user.group)
            .then(response => {
                dispatch(uploadAdImageSuccess(encodeURI(response.url), response.id));
            })
            .catch(error => dispatch(uploadAdImageError(error)));
    };
}

/*
export function uploadAdvertisementMedia(blobChunck, fileInfo){
    return (dispatch, getState) =>{

        const {chunckID,  chunckLength} = fileInfo
        const progress = (chunckID/chunckLength)*100
        console.log(progress)
        dispatch(requestUpdateAdImage(progress));

        const formData = new FormData()
        const fileName = fileInfo.fileName
        formData.append(0, blobChunck, fileName)

        const user = getState().user

        return createAdMedia(formData, user.accessToken, user.group)
            .then(response => {
                console.log('The response url has been received', response.url)
                dispatch(uploadAdImageSuccess(encodeURI(response.url), response.id))
            })
            .catch(error => dispatch(uploadAdImageError(error)));
    }
}
*/

/**
 *
 * @param {File} imageFile
 */
export function deleteAd(adId) {
    return (dispatch, getState) => {
        dispatch(requestDeleteAd());

        // store image in backend
        const user = getState().user;
        return deleteAdHttpRequest(adId, user.accessToken, user.group)
            .then(() => {
                dispatch(deleteAdSuccess(adId));
                dispatch(refreshAds());
            })
            .catch(error => {
                dispatch(refreshAds());
                dispatch(deleteAdError(error));
            });
    };
}
