import React, { useEffect, useState } from 'react';
import TimePicker from 'react-times';
import { Checkbox } from '@storaensods/seeds-react';
import { forEach, set } from 'lodash';
import { createAutomation, updateAutomation, fetchAutomation, deleteAutomation } from '../../../actions/automations';
import CabinetLists from '../segments/cabinetLists';
import { InfoBanner, Button } from '../../commons/utils';
import { connect } from 'react-redux';
import AutomationSavedModal from '../modals/AutomationSavedModal';

const RefillPlanAutomation = props => {
    const { cabinetsByLocations, refillSchemas, cancelPage, createAutomation, fetchAutomation, updateAutomation, deleteAutomation, t } = props;

    const [selectedCabinets, setSelelctedCabinets] = useState({});
    const [selectedTime, setSelectedTime] = useState('07:00');
    const [selectedDays, setSelectedDays] = useState([]);
    const [cabinetsWithRefillSchema, setCabinetsWitheRefillSchema] = useState({});
    const [emailAddresses, setEmailAddresses] = useState([]);
    const [automationId, setAutomationId] = useState(null);
    const [isAutomationExist, setIsAutomationExist] = useState(false);
    const [automationSaveModalOpen, setAutomationSaveModalOpen] = useState(false);
    const [automationSaveData, setAutomationSaveData] = useState({});
    const [isSavedButtonActive, setIsSavedButtonActive] = useState(false);
    const [message, setMessage] = useState('');

    useEffect(() => {
        const initialization = [fetchAutomation('refillPlan')];
        Promise.all(
            initialization.map(async func => {
                const automationResponse = await func;
                if (automationResponse.error) {
                    return Promise.reject(automationResponse.error);
                }
                if (automationResponse.automation.length === 0) {
                    return Promise.resolve();
                }
                setIsAutomationExist(true);
                setEmailAddresses(automationResponse.automation[0].toList);
                setSelelctedCabinets(
                    Object.entries(cabinetsByLocations).reduce((acc, [location, cabinets]) => {
                        const selectedCabinets = automationResponse.automation[0].deviceCodeList
                            .map(deviceCode => {
                                return cabinets.find(cabinet => cabinet.deviceCode === deviceCode);
                            })
                            .filter(cabinet => cabinet);
                        acc[location] = selectedCabinets;
                        return acc;
                    }, {})
                );
                //TODO: set selected days and time
                setSelectedDays(automationResponse.automation[0].metadata.days);
                setSelectedTime(automationResponse.automation[0].metadata.executionTime);
                setAutomationId(automationResponse.automation[0]._id);
                return Promise.resolve();
            })
        )
            .then(() => {})
            .catch(error => {});
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        //filter out only the cabinets that have refill schemas
        let cabinetGrouping = {};
        forEach(cabinetsByLocations, (cabinets, location) => {
            const cabinetSchemas = cabinets.filter(cabinet => refillSchemas.find(schema => schema.deviceCode === cabinet.deviceCode));

            if (cabinetSchemas.length) {
                set(cabinetGrouping, location, cabinetSchemas);
            }
        });
        setCabinetsWitheRefillSchema(cabinetGrouping);
    }, [cabinetsByLocations, refillSchemas]);

    /**
     * when days are checked/unched
     * @param {*} checked
     * @param {*} day
     */
    const onDaySelected = (checked, day) => {
        let allDays = [...selectedDays];
        if (checked) {
            allDays.push(day);
        } else {
            allDays = selectedDays.filter(selected => selected !== day);
        }
        setSelectedDays(allDays);
        if (Object.keys(selectedCabinets).length > 0 && allDays.length > 0) {
            setIsSavedButtonActive(true);
        } else {
            setIsSavedButtonActive(false);
        }
    };

    /**
     * when time is selected, set a new time
     * @param {*} param0
     */
    const onTimeSelected = ({ hour, minute }) => {
        const timeString = `${hour}:${minute}`;
        setSelectedTime(timeString);
        setIsSavedButtonActive(true);
    };

    const onSaveClicked = async () => {
        setAutomationSaveModalOpen(true);
        setAutomationSaveData({ saving: true });
        if (selectedDays.length === 0) {
            alert('Please select at least one day for the refill plan');
            setAutomationSaveModalOpen(false);
            return;
        }
        const sendMail = emailAddresses && emailAddresses.filter(email => email !== '').length > 0 ? true : false;
        const deviceCodes = Object.values(selectedCabinets).reduce((acc, cabinets) => {
            const deviceCodes = cabinets.map(cabinet => cabinet.deviceCode);
            return [...acc, ...deviceCodes];
        }, []);
        const automationData = {
            name: 'Refill Plan Automation',
            type: 'refillPlan',
            occurences: `0 0 ${parseInt(selectedTime)} * * *`,
            startTime: null,
            endTime: null,
            deviceCodeList: deviceCodes,
            status: 'active',
            metadata: {
                days: selectedDays,
                executionTime: selectedTime,
            },
            sendMail: sendMail,
            toList: null,
        };
        let result;
        if (!isAutomationExist) {
            result = await createAutomation(automationData);
        } else {
            result = await updateAutomation(automationId, automationData);
        }
        if (result.error) {
            setAutomationSaveData({ saving: false, error: true });
        } else {
            setAutomationId(result.automation._id);
            setIsAutomationExist(true);
            setAutomationSaveData({ saving: false, isSaved: true });
        }
    };

    const onDeleteClicked = async () => {
        setAutomationSaveData({
            saving: true,
        });
        setAutomationSaveModalOpen(true);
        const result = await deleteAutomation(automationId);
        if (result.error) {
            setAutomationSaveData({ saving: false, error: true });
            setMessage(t('automations:automationDeleted'));
        } else {
            setIsAutomationExist(false);
            setEmailAddresses([]);
            setSelelctedCabinets({});
            setSelectedDays([]);
            setSelectedTime('07:00');
            setAutomationId(null);
            setAutomationSaveData({ saving: false, isSaved: true });
            setMessage(t('automations:automationDeleted'));
        }
    };

    const handleCabinetSelection = cabinets => {
        setSelelctedCabinets(cabinets);
        if (Object.keys(cabinets).length > 0 && 
            Object.keys(cabinets).some(location => cabinets[location].length > 0) &&
            selectedDays.length > 0) 
        {
            setIsSavedButtonActive(true);
        } else {
            setIsSavedButtonActive(false);
        }
    };

    return (
        <div className="automations__container">
            <AutomationSavedModal
                isOpen={automationSaveModalOpen}
                data={automationSaveData}
                closeModal={() => {
                    setAutomationSaveModalOpen(false);
                }}
                successMessage={message}
                t={t}
            />
            <CabinetLists
                cabinetsByLocations={cabinetsWithRefillSchema}
                selectedCabinets={selectedCabinets}
                cabinetSelections={cabinets => handleCabinetSelection(cabinets)}
                t={t}
            />
            <div className="automations__contents">
                <InfoBanner message={t('automations:refillPlanGenerationTitle')} />
                <div className="automations__refill">
                    <div className="automations__refill-days">
                        <p className="automations__refill-title">{t('automations:selectDays')}</p>
                        <ul>
                            <li>
                                <Checkbox id="monday" onChange={e => onDaySelected(e.target.checked, 'monday')} checked={selectedDays.includes('monday')} />
                                {t('main:monday')}
                            </li>
                            <li>
                                <Checkbox id="tuesday" onChange={e => onDaySelected(e.target.checked, 'tuesday')} checked={selectedDays.includes('tuesday')} />
                                {t('main:tuesday')}
                            </li>
                            <li>
                                <Checkbox
                                    id="wednesday"
                                    onChange={e => onDaySelected(e.target.checked, 'wednesday')}
                                    checked={selectedDays.includes('wednesday')}
                                />
                                {t('main:wednesday')}
                            </li>
                            <li>
                                <Checkbox
                                    id="thursday"
                                    onChange={e => onDaySelected(e.target.checked, 'thursday')}
                                    checked={selectedDays.includes('thursday')}
                                />
                                {t('main:thursday')}
                            </li>
                            <li>
                                <Checkbox id="friday" onChange={e => onDaySelected(e.target.checked, 'friday')} checked={selectedDays.includes('friday')} />
                                {t('main:friday')}
                            </li>
                            <li>
                                <Checkbox
                                    id="saturday"
                                    onChange={e => onDaySelected(e.target.checked, 'saturday')}
                                    checked={selectedDays.includes('saturday')}
                                />
                                {t('main:saturday')}
                            </li>
                            <li>
                                <Checkbox id="sunday" onChange={e => onDaySelected(e.target.checked, 'sunday')} checked={selectedDays.includes('sunday')} />
                                {t('main:sunday')}
                            </li>
                        </ul>
                    </div>
                    <div className="automations__refill-timer">
                        <div className="automations__refill-timer">
                            <p className="automations__refill-title">{t('automations:refillPlanTime')}</p>
                            <TimePicker
                                theme="classic"
                                timeMode="24"
                                timeConfig={{
                                    step: 1,
                                    unit: 'hour',
                                }}
                                time={selectedTime}
                                onTimeChange={e => {
                                    onTimeSelected(e);
                                }}
                            />
                        </div>
                    </div>
                </div>
                {/* <EmailLists style={{display: "none"}}
                    emailAddresses={emailAddresses}
                    emailAddressChange ={(email, index)=>emailAddressChange(email, index)}
                    removeEmail={(index)=>removeEmail(index)}
                    addNewEmail={()=>setEmailAddresses(prevState=>{return [...prevState, ""]})}
                    t={t}
                /> */}
                <div className="automations__contents-actions">
                    <Button type="cancel" text={t('main:cancel')} onClick={() => cancelPage()} />
                    <Button type="delete" text={t('main:delete')} disabled={!isAutomationExist} onClick={() => onDeleteClicked()} ml />
                    <Button type="save" text={t('main:save')} onClick={() => onSaveClicked()} disabled={!isSavedButtonActive} ml />
                </div>
            </div>
        </div>
    );
};

export default connect(
    state => ({
        organization: state.user.group.Name,
    }),
    {
        createAutomation,
        fetchAutomation,
        updateAutomation,
        deleteAutomation,
    }
)(RefillPlanAutomation);
