/**
 * Call for HTML5 draggable cabinet onDragStart event
 * @param {object} ev event
 * @param {function} cabinetSearchQueryUpdated the function which sets the cabinet search query
 */
export const cabinetDragStartHandler = (ev, cabinetSearchQueryUpdated) => {
    // data-devicecode attribute in cabinet draggable element is expected to equal device code
    const deviceCode = ev.target.getAttribute('data-devicecode');

    console.debug(`DEVICE_LIST_DND: started dragging cabinet ${deviceCode}`);

    // Add HTML5 drag data
    ev.dataTransfer.setData('text/plain', JSON.stringify({ deviceCode, type: 'device' }));

    // create drag element out of user view and use as drag image
    const ghostEl = ev.target.cloneNode(true);
    ghostEl.style.position = 'absolute'; // hide from view
    ghostEl.style.top = '-1000px'; // hide from view
    ghostEl.id = 'drag-ghost';
    const dragIndicator = ghostEl.getElementsByClassName('drag-indicator')[0]; // clean ghost
    if (dragIndicator) dragIndicator.remove(); // clean ghost
    document.body.appendChild(ghostEl); // add ghost element to DOM
    ev.dataTransfer.setDragImage(ghostEl, 0, 0); // set ghost element as drag image

    // hide original cabinet element in immediate timeout to fix Chrome dragend triggering on draggable target mutation
    // also clear cabinet search query to make all locations visible
    const currentTarget = ev.currentTarget;
    setTimeout(
        function(currentTarget, cabinetSearchQueryUpdated) {
            cabinetSearchQueryUpdated('');
            currentTarget.classList.add('se-hide-important');
        },
        0,
        currentTarget,
        cabinetSearchQueryUpdated
    );
};

/**
 * Call for HTML5 draggable location onDragStart event
 * @param {object} ev
 * @param {function} cabinetSearchQueryUpdated the function which sets the cabinet search query
 */
export const locationDragStartHandler = (ev, cabinetSearchQueryUpdated) => {
    // data-locationid attribute in location draggable element is expected to equal location id

    // If it's an image find the parent element
    let locationId;
    if (ev.target.nodeName === 'IMG') locationId = ev.target.parentNode.parentNode.getAttribute('data-locationid');
    else locationId = ev.target.getAttribute('data-locationid');

    console.debug(`DEVICE_LIST_DND: started dragging location ${locationId}`);

    // Add HTML5 drag data
    ev.dataTransfer.setData('text/plain', JSON.stringify({ locationId, type: 'location' }));

    // create drag-ghost element and use as drag image
    const ghostEl = ev.target.cloneNode(true);
    ghostEl.style.position = 'absolute'; // hide from view
    ghostEl.style.top = '-1000px'; // hide from view
    ghostEl.id = 'drag-ghost';
    const removeLocationTag = ghostEl.getElementsByClassName('se-remove-location-tag')[0]; // clean ghost
    const locationCabinetsCountTag = ghostEl.getElementsByClassName('se-location-cabinets-count-tag')[0]; // clean ghost
    if (removeLocationTag) removeLocationTag.remove(); // clean ghost
    if (locationCabinetsCountTag) locationCabinetsCountTag.remove(); // clean ghost
    document.body.appendChild(ghostEl); // add ghost element to DOM
    ev.dataTransfer.setDragImage(ghostEl, 0, 0); // set ghost element as drag image

    // hide original location element in immediate timeout to fix Chrome dragend triggering on draggable target mutation
    // also clear cabinet search query to make all locations visible
    const currentTarget = ev.currentTarget;
    setTimeout(
        function(currentTarget, cabinetSearchQueryUpdated) {
            cabinetSearchQueryUpdated('');
            currentTarget.parentNode.parentNode.parentNode.classList.add('se-hide-important');
        },
        0,
        currentTarget,
        cabinetSearchQueryUpdated
    );
};

/**
 * Call for cabinet draggable HTML5 onDragEnd event
 * @param {object} ev Event
 */
export const cabinetDragEndHandler = ev => {
    console.debug('DEVICE_LIST_DND: ended dragging cabinet');

    // make original drag target visible
    ev.target.classList.remove('se-hide-important');

    // remove ghost element
    const ghostEl = document.getElementById('drag-ghost');
    if (ghostEl) {
        ghostEl.remove();
    }

    // clean any on active on drag hover drop zones
    inactivateDropZones();
};

/**
 * Call for location draggable HTML5 onDragEnd event
 * @param {object} ev
 */
export const locationDragEndHandler = ev => {
    console.debug('DEVICE_LIST_DND: ended dragging location');

    // make original drag target visible
    ev.target.parentNode.parentNode.parentNode.classList.remove('se-hide-important');

    // remove ghost element
    const ghostEl = document.getElementById('drag-ghost');
    if (ghostEl) {
        ghostEl.remove();
    }

    // clean any on active on drag hover drop zones
    inactivateDropZones();
};

/**
 * Call for location drop zone HTML5 onDrop event
 * @param {object} ev
 */
export const locationDropHandler = (ev, cabinets, setCabinetLocation, updateLocationParent) => {
    ev.preventDefault();
    ev.stopPropagation();
    inactivateDropZones(); // clean any on active on drag hover drop zones

    // get HTML5 dnd dataTransfer data set in onDragStart handlers
    const transferData = ev.dataTransfer.getData('text/plain');
    if (!transferData) {
        console.error('DEVICE_LIST_DND: Drag and drop error! No data transfer data!');
        return;
    }
    const transferDataObj = JSON.parse(transferData);

    // expect drop zone element to have data-locationid attribute set to location id
    const locationId = ev.currentTarget.getAttribute('data-locationid');

    if (transferDataObj.type === 'device') {
        console.debug(`DEVICE_LIST_DND: ${transferDataObj.deviceCode} cabinet dropped into ${locationId}`);

        // get cabinet object with device code
        const draggedCabinet = cabinets.find(cabinet => cabinet.deviceCode === transferDataObj.deviceCode);
        // current device location id
        const draggedCabinetLocationId = draggedCabinet?.locationId;
        // cabinet region change request already in progress
        if (draggedCabinet?.movingFromRegion) {
            console.info(`DEVICE_LIST_DND: Not changing cabinet ${transferDataObj.deviceCode} region because change request already in progress`);
            return;
        }
        // in case user did not change location, do not make the call
        if (draggedCabinetLocationId !== locationId) {
            // set cabinet location to location that it was dropped into
            setCabinetLocation(transferDataObj.deviceCode, locationId);
        }
    } else if (transferDataObj.type === 'location') {
        console.debug(`DEVICE_LIST_DND: ${transferDataObj.locationId} location dropped into ${locationId}`);

        // set location parent to location that it was dropped into
        updateLocationParent(transferDataObj.locationId, locationId);
    } else {
        console.error(`DEVICE_LIST_DND: Drag and drop error! Unknown transfer data format: ${JSON.stringify(transferData)}`);
    }
};

/**
 * Call for location drop zone HTML5 onDragOver event
 * @param {object} ev event
 * @param {number} dropZoneDepth Counts the depth of drop zones
 */
export const locationDragoverHandler = (ev, dropZoneDepth) => {
    ev.preventDefault();
    ev.stopPropagation();

    // if drop zone is not already active
    if (!ev.currentTarget.classList.contains('se-location-dnd-drop-zone-active')) {
        console.debug(`DEVICE_LIST_DND: On dragover triggered on inactive drop zone and drop zone depth: ${dropZoneDepth}`);

        // inactivate all drop zones for robustness (sometimes onDragExit and onDragEnter are not robust enough when dragging very fast)
        inactivateDropZones();

        // activate event drop zone
        ev.currentTarget.classList.add('se-location-dnd-drop-zone-active');
        ev.currentTarget.parentNode.classList.add('cabinet-drop-container-active');
    }
};

/**
 * Call for location drop zone HTML5 onDragEnter event
 * @param {object} ev
 * @param {number} dropZoneDepth Counts the depth of drop zones
 */
export const locationDropZoneEnter = (ev, dropZoneDepth) => {
    ev.preventDefault();
    ev.stopPropagation();

    // onDragEnter gets triggered when moving to different element within drop zone
    // if drop zone depth is 0 it means drop zone is being entered for first time
    if (dropZoneDepth === 0) {
        console.debug('DEVICE_LIST_DND: Entering drop zone');
        inactivateDropZones();
        ev.currentTarget.classList.add('se-location-dnd-drop-zone-active');
        ev.currentTarget.parentNode.classList.add('cabinet-drop-container-active');
    }

    return dropZoneDepth++;
};

/**
 * Call for location drop zone HTML5 onDragExit event
 * @param {object} ev
 * @param {number} dropZoneDepth Counts the depth of drop zones
 */
export const locationDropZoneExit = (ev, dropZoneDepth) => {
    ev.preventDefault();
    ev.stopPropagation();
    dropZoneDepth--;

    // onDragExit event signifies exiting drop zone when drop zone depth is 0
    if (dropZoneDepth === 0) {
        console.debug('DEVICE_LIST_DND: Exiting drop zone');
        inactivateDropZones();
    }

    return dropZoneDepth;
};

/**
 * Removes se-location-dnd-drop-zone-active and cabinet-drop-container-active classes from all location drop zones and
 * cabinet drop containers in DOM
 */
export const inactivateDropZones = () => {
    // make sure all drop zones are inactive
    const dropZoneElems = document.getElementsByClassName('se-location-dnd-drop-zone-active');
    for (let i = 0; i < dropZoneElems.length; i++) {
        dropZoneElems[i].parentNode.classList.remove('cabinet-drop-container-active');
        dropZoneElems[i].classList.remove('se-location-dnd-drop-zone-active');
    }
};
