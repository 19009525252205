export const ExtendedCountryList = [
    {
        name: 'Andorra',
        code: 'AD',
    },
    {
        name: 'Albania',
        code: 'AL',
    },
    {
        name: 'Austria',
        code: 'AT',
    },
    {
        name: 'Åland Islands',
        code: 'AX',
    },
    {
        name: 'Bosnia and Herzegovina',
        code: 'BA',
    },
    {
        name: 'Belgium',
        code: 'BE',
    },
    {
        name: 'Bulgaria',
        code: 'BG',
    },
    {
        name: 'Belarus',
        code: 'BY',
    },
    {
        name: 'Switzerland',
        code: 'CH',
    },
    {
        name: 'Cyprus',
        code: 'CY',
    },
    {
        name: 'Czech Republic',
        code: 'CZ',
    },
    {
        name: 'Germany',
        code: 'DE',
    },
    {
        name: 'Denmark',
        code: 'DK',
    },
    {
        name: 'Estonia',
        code: 'EE',
    },
    {
        name: 'Spain',
        code: 'ES',
    },
    {
        name: 'Finland',
        code: 'FI',
    },
    {
        name: 'Faroe Islands',
        code: 'FO',
    },
    {
        name: 'France',
        code: 'FR',
    },
    {
        name: 'United Kingdom',
        code: 'GB',
    },
    {
        name: 'Guernsey',
        code: 'GG',
    },
    {
        name: 'Greece',
        code: 'GR',
    },
    {
        name: 'Croatia',
        code: 'HR',
    },
    {
        name: 'Hungary',
        code: 'HU',
    },
    {
        name: 'Ireland',
        code: 'IE',
    },
    {
        name: 'Isle of Man',
        code: 'IM',
    },
    {
        name: 'Iceland',
        code: 'IC',
    },
    {
        name: 'Italy',
        code: 'IT',
    },
    {
        name: 'Jersey',
        code: 'JE',
    },
    {
        name: 'Liechtenstein',
        code: 'LI',
    },
    {
        name: 'Lithuania',
        code: 'LT',
    },
    {
        name: 'Luxembourg',
        code: 'LU',
    },
    {
        name: 'Latvia',
        code: 'LV',
    },
    {
        name: 'Monaco',
        code: 'MC',
    },
    {
        name: 'Moldova, Republic of',
        code: 'MD',
    },
    {
        name: 'Macedonia, The Former Yugoslav Republic of',
        code: 'MK',
    },
    {
        name: 'Malta',
        code: 'MT',
    },
    {
        name: 'Netherlands',
        code: 'NL',
    },
    {
        name: 'Norway',
        code: 'NO',
    },
    {
        name: 'Poland',
        code: 'PL',
    },
    {
        name: 'Portugal',
        code: 'PT',
    },
    {
        name: 'Romania',
        code: 'RO',
    },
    {
        name: 'Russia',
        code: 'RU',
    },
    {
        name: 'Sweden',
        code: 'SE',
    },
    {
        name: 'Slovenia',
        code: 'SI',
    },
    {
        name: 'Svalbard and Jan Mayen',
        code: 'SJ',
    },
    {
        name: 'Slovakia',
        code: 'SK',
    },
    {
        name: 'San Marino',
        code: 'SM',
    },
    {
        name: 'Ukraine',
        code: 'UA',
    },
    {
        name: 'Holy See (Vatican City State)',
        code: 'VA',
    },
    {
        name: 'United States of America (The)',
        code: 'US',
    },
    {
        name: 'New Zealand',
        code: 'NZ',
    },
    {
        name: 'Australia',
        code: 'AU',
    },
    {
        name: 'Canada',
        code: 'CA',
    },
];
