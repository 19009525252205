import React, { Component } from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import { Button } from 'reactstrap';
import classnames from 'classnames';
import moment from 'moment';
import { setTimeRange } from '../../actions/report';

import './datesfilter.css';

class DatesFilter extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    /**
     * On user selects different filter for date range
     */
    handleDateRangeFilterChange(newValue) {
        const { dispatch } = this.props;
        dispatch(setTimeRange(newValue[0], newValue[1], null));
    }

    /**
     * On last week button click.
     */
    handleLastWeekButtonClick() {
        const { dispatch } = this.props;
        dispatch(setTimeRange(moment().subtract(7, 'day'), moment(), 'week'));
    }

    /**
     * On today button click.
     */
    handleTodayButtonClick() {
        const { dispatch } = this.props;
        dispatch(setTimeRange(moment().startOf('day'), moment(), 'today'));
    }

    /**
     * On yesterday button click.
     */
    handleYestrdayButtonClick() {
        const { dispatch } = this.props;
        dispatch(
            setTimeRange(
                moment()
                    .startOf('day')
                    .subtract(1, 'day'),
                moment()
                    .endOf('day')
                    .subtract(1, 'day'),
                'yesterday'
            )
        );
    }

    /**
     * On two weeks button click.
     */
    handleTwoWeeksButtonClick() {
        const { dispatch } = this.props;
        dispatch(setTimeRange(moment().subtract(2, 'week'), moment(), 'twoweeks'));
    }

    /**
     * On one month button click.
     */
    handleMonthButtonClick() {
        const { dispatch } = this.props;
        dispatch(setTimeRange(moment().subtract(1, 'month'), moment(), 'month'));
    }

    /**
     * Renders the component.
     */
    render() {
        const { dates, t } = this.props;
        return (
            <div className="daterange-container">
                <div>
                    <DateRangePicker
                        className="mr-2"
                        clearIcon={null}
                        value={[new Date(dates.from), new Date(dates.to)]}
                        onChange={this.handleDateRangeFilterChange.bind(this)}
                    />{' '}
                    |
                    <span className="ml-1d">
                        <Button
                            className={classnames('filterrange-button', {
                                active: dates.selectedPeriod === 'today',
                            })}
                            color="link"
                            onClick={this.handleTodayButtonClick.bind(this)}
                        >
                            <i className="fas fa-caret-right" />
                            &nbsp;{t('dateRangeFilter:today')}
                        </Button>
                    </span>{' '}
                    |
                    <span className="ml-1d">
                        <Button
                            className={classnames('filterrange-button', {
                                active: dates.selectedPeriod === 'yesterday',
                            })}
                            color="link"
                            onClick={this.handleYestrdayButtonClick.bind(this)}
                        >
                            <i className="fas fa-caret-right" />
                            &nbsp;{t('dateRangeFilter:yesterday')}
                        </Button>
                    </span>{' '}
                    |
                    <span className="ml-1d">
                        <Button
                            className={classnames('filterrange-button', {
                                active: dates.selectedPeriod === 'week',
                            })}
                            color="link"
                            onClick={this.handleLastWeekButtonClick.bind(this)}
                        >
                            <i className="fas fa-caret-right" />
                            &nbsp;{t('dateRangeFilter:lastWeek')}
                        </Button>
                    </span>{' '}
                    |
                    <span className="ml-1d">
                        <Button
                            className={classnames('filterrange-button', {
                                active: dates.selectedPeriod === 'twoweeks',
                            })}
                            color="link"
                            onClick={this.handleTwoWeeksButtonClick.bind(this)}
                        >
                            <i className="fas fa-caret-right" />
                            &nbsp;{t('dateRangeFilter:lastTwoWeek')}
                        </Button>
                    </span>{' '}
                    |
                    <span className="ml-1d">
                        <Button
                            className={classnames('filterrange-button', {
                                active: dates.selectedPeriod === 'month',
                            })}
                            color="link"
                            onClick={this.handleMonthButtonClick.bind(this)}
                        >
                            <i className="fas fa-caret-right" />
                            &nbsp;{t('dateRangeFilter:lastMonth')}
                        </Button>
                    </span>
                </div>
            </div>
        );
    }
}

export default connect(state => {
    return {
        dates: state.report.timeRange,
    };
})(translate()(DatesFilter));
