import React from 'react';

import { Card, Icon } from '@storaensods/seeds-react';

import './productCategories.css';

const ProductCategoryCards = props => {
    return props.data.map(productCategory => {
        return (
            <Card
                key={productCategory.id}
                actions={
                    props.isAdmin && [
                        {
                            label: <Icon>edit</Icon>,
                            onClick: () => props.editAction(productCategory),
                        },
                        {
                            label: <Icon>remove_circle_outline</Icon>,
                            onClick: () => props.deleteAction(productCategory),
                            type: 'negative',
                        },
                    ]
                }
                className="ProductCategoryCard"
            >
                <h5 className="ProductCategoryCardHeader card-no-overflow">{productCategory.name}</h5>
                <p className="card-no-overflow">{productCategory.description}</p>
            </Card>
        );
    });
};

export default ProductCategoryCards;
