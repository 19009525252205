import React, { Fragment, useState } from 'react';
import { Icon } from '@storaensods/seeds-react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { signOutUser, handleGroupChange } from '../../actions/user';

import icons from './styles/icons.svg';
import i18n from '../../i18n';

const MobileNavigation = props => {
    const { user, handleGroupChange, signOutUser, t } = props;
    const [openMenuContent, setOpenMenuContent] = useState(false);
    const [openOrganisationContent, setOpenOrganisationContent] = useState(false);
    const [openLanguageContent, setOpenLanguageContent] = useState(false);
    const [selectedLanguage, setSelectedLanguage] = useState(i18n.language);
    const [selectedOrganisation, setSelectedOrganisation] = useState(user.group);

    const isAdmin = user && user.isAdmin;
    const isRefillOperator = user && user.isRefillOperator;

    const redirectPage = destination => {
        const { history } = props;
        setOpenLanguageContent(false);
        setOpenOrganisationContent(false);
        setOpenMenuContent(false);
        history.push(`/${destination}`);
    };

    const allLanguages = JSON.parse(process.env.REACT_APP_LANGUAGES);

    const getLanguageContents = () => {
        return (
            <div className="nav-mb__items">
                <ul className="nav-mb__content-menu">
                    {allLanguages.map(language => {
                        return (
                            <li
                                onClick={() => {
                                    i18n.changeLanguage(language);
                                    setSelectedLanguage(language);
                                    closeMenuPage();
                                }}
                                key={language}
                                className={`${selectedLanguage === language ? 'nav-mb__content-menu--active' : ''}`}
                            >
                                <span> {i18n.getNativeName(language)} </span>
                            </li>
                        );
                    })}
                </ul>
            </div>
        );
    };

    const getOrganisationContent = () => {
        const allgroups = user.profile.groups;

        if (allgroups.length === 0) return;

        const changeGroup = group => {
            handleGroupChange({ Id: group.Id, Name: group.Name });
            setSelectedOrganisation(group);
            closeMenuPage();
        };

        return (
            <div className="nav-mb__items">
                <ul className="nav-mb__content-menu">
                    {allgroups.map(group => {
                        return (
                            <li
                                onClick={() => {
                                    changeGroup(group);
                                }}
                                className={`${group.Name === selectedOrganisation.Name ? 'nav-mb__content-menu--active' : ''}`}
                            >
                                <span>{group.Name}</span>
                            </li>
                        );
                    })}
                </ul>
            </div>
        );
    };

    const closeMenuPage = () => {
        setOpenLanguageContent(false);
        setOpenOrganisationContent(false);
        setOpenMenuContent(false);
    };

    const getMenuContents = () => {
        return (
            <div className="nav-mb__items">
                <ul className="nav-mb__content-menu">
                    <li onClick={() => redirectPage('analytics')}>
                        <svg>
                            <use xlinkHref={`${icons}#icon-stats-dots`}></use>
                        </svg>
                        <span>{t('analytics')}</span>
                    </li>
                    {isAdmin && (
                        <Fragment>
                            <li onClick={() => redirectPage('devices')}>
                                <svg>
                                    <use xlinkHref={`${icons}#icon-cog`}></use>
                                </svg>
                                <span>{t('deviceManagement')}</span>
                            </li>
                            <li onClick={() => redirectPage('advertisements')}>
                                <svg>
                                    <use xlinkHref={`${icons}#icon-images`}></use>
                                </svg>
                                <span>{t('advertisements')}</span>
                            </li>
                        </Fragment>
                    )}
                    <li onClick={() => redirectPage('products')}>
                        <svg>
                            <use xlinkHref={`${icons}#icon-cube`}></use>
                        </svg>
                        <span>{t('productCatalog')}</span>
                    </li>
                    {isAdmin && (
                        <li onClick={() => redirectPage('discounts')}>
                            <svg>
                                <use xlinkHref={`${icons}#icon-discout`}></use>
                            </svg>
                            <span>{t('discounts')}</span>
                        </li>
                    )}
                    {(isAdmin || isRefillOperator) && (
                        <li onClick={() => redirectPage('refill-plans')}>
                            <svg>
                                <use xlinkHref={`${icons}#icon-workspaces_filled`}></use>
                            </svg>
                            <span>{t('refillManagement')}</span>
                        </li>
                    )}
                </ul>
                {isAdmin && (
                    <ul className="nav-mb__content-menu">
                        <li onClick={() => redirectPage('settings-consumer-app')}>
                            <svg>
                                <use xlinkHref={`${icons}#icon-phone`}></use>
                            </svg>
                            <span>{t('mySelfly')}</span>
                        </li>
                        <li onClick={() => redirectPage('software-versions')}>
                            <svg>
                                <use xlinkHref={`${icons}#icon-refresh1`}></use>
                            </svg>
                            <span>{t('softwareVersions:softwareVersions')}</span>
                        </li>
                        <li onClick={() => redirectPage('automations')}>
                            <svg>
                                <use xlinkHref={`${icons}#automations`}></use>
                            </svg>
                            <span>{t('automations')}</span>
                        </li>
                    </ul>
                )}
                <ul className="nav-mb__content-menu">
                    <li
                        className="nav-mb__content-menu nav-mb__content-menu-navigate"
                        onClick={() => {
                            if (user.profile.groups.length === 1) return;
                            setOpenOrganisationContent(true);
                        }}
                    >
                        <span>
                            <svg>
                                <use xlinkHref={`${icons}#icon-office`}></use>
                            </svg>
                            {user.group.Name}
                        </span>
                        <span>
                            <svg>
                                <use xlinkHref={`${icons}#icon-arrow-right`}></use>
                            </svg>
                        </span>
                    </li>
                    <li className="nav-mb__content-menu nav-mb__content-menu-navigate" onClick={() => setOpenLanguageContent(true)}>
                        <span>
                            <svg>
                                <use xlinkHref={`${icons}#icon-world`}></use>
                            </svg>
                            {i18n.getNativeName(selectedLanguage)}
                        </span>
                        <span>
                            <svg>
                                <use xlinkHref={`${icons}#icon-arrow-right`}></use>
                            </svg>
                        </span>
                    </li>
                </ul>
                <ul className="nav-mb__content-menu">
                    <li
                        className="nav-mb__content-menu nav-mb__content-menu-navigate"
                        onClick={() => window.open('https://community.selflystore.com', '_blank', 'noopener')}
                    >
                        <span>
                            <svg>
                                <use xlinkHref={`${icons}#icon-users`}></use>
                            </svg>
                            {t('community')}
                        </span>
                        <span className="nav-mb__content-menu-blank">
                            <i className="ml-1 fas fa-external-link-alt"></i>
                        </span>
                    </li>

                    <li
                        className="nav-mb__content-menu nav-mb__content-menu-navigate"
                        onClick={() => window.open('https://www.selflystore.com/privacy', '_blank', 'noopener')}
                    >
                        <span>
                            <svg>
                                <use xlinkHref={`${icons}#icon-privacy_tip`}></use>
                            </svg>
                            {t('main:privacyPolicy')}
                        </span>
                        <span className="nav-mb__content-menu-blank">
                            <i className="ml-1 fas fa-external-link-alt"></i>
                        </span>
                    </li>
                    <li
                        className="nav-mb__content-menu nav-mb__content-menu-navigate"
                        onClick={() => window.open('https://www.storaenso.com/en/legal-notice', '_blank', 'noopener')}
                    >
                        <span>
                            <svg>
                                <use xlinkHref={`${icons}#icon-gavel`}></use>
                            </svg>
                            {t('main:legalNotice')}
                        </span>
                        <span className="nav-mb__content-menu-blank">
                            <i className="ml-1 fas fa-external-link-alt"></i>
                        </span>
                    </li>
                </ul>
            </div>
        );
    };

    return (
        <Fragment>
            <div className="nav-mb">
                <div>
                    <div className="nav-mb__menu" onClick={() => setOpenMenuContent(true)}>
                        <Icon>menu</Icon>
                    </div>
                </div>
                <img className="scp-header__trademark-logo" src="https://cdn.selflystore.com/images-general/logos/selfly-logo.svg" alt="selfly-store-logo" />
            </div>
            <div className={`nav-mb__content ${openMenuContent ? 'nav-mb__content--open' : ''}`}>
                <div className="nav-mb__content-header">
                    <div className="nav-mb__menu" onClick={() => closeMenuPage()}>
                        <Icon>close</Icon>
                    </div>
                </div>
                {openLanguageContent && getLanguageContents()}

                {openOrganisationContent && getOrganisationContent()}

                {!openLanguageContent && !openOrganisationContent && getMenuContents()}
                <div className="nav-mb__signout" onClick={() => signOutUser()}>
                    <svg>
                        <use xlinkHref={`${icons}#icon-log-out`}></use>
                    </svg>
                    <span>Logout</span>
                </div>
            </div>
        </Fragment>
    );
};
export default withRouter(
    connect(
        state => ({
            user: state.user,
        }),
        { signOutUser, handleGroupChange }
    )(translate('navigation')(MobileNavigation))
);
