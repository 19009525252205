import { showLoading, hideLoading } from 'react-redux-loading-bar';
import { NotificationManager } from 'react-notifications';
import { sumBy } from 'lodash';
import { fetchTransactions, refundOrder, runCubeQuery as runCubeQueryHttpRequest } from '../api.js';

import { getCurrentTemperatureCubeQuery, getTemperatureCubeQuery } from './../cubeQuery';

export const SELECT_CABINET = 'SELECT_CABINET';
export const REQUEST_TRANSACTIONS = 'REQUEST_TRANSACTIONS';
export const RECEIVE_TRANSACTIONS = 'RECEIVE_TRANSACTIONS';
export const RECEIVE_TRANSACTIONS_ERROR = 'RECEIVE_TRANSACTIONS_ERROR';
export const SET_TRANSACTION_MODAL = 'SET_TRANSACTION_MODAL';
export const SET_TIME_RANGE = 'SET_TIME_RANGE';
export const SELECT_VIEW = 'SELECT_VIEW';
export const RECEIVE_TEMPERATURE = 'RECEIVE_TEMPERATURE';
export const RECEIVE_TEMPERATURE_ERROR = 'RECEIVE_TEMPERATURE_ERROR';
export const REQUEST_TEMPERATURE = 'REQUEST_TEMPERATURE';
export const REQUEST_CURRENT_STATUS = 'REQUEST_CURRENT_STATUS';
export const RECEIVE_CURRENT_STATUS = 'RECEIVE_CURRENT_STATUS';
export const RECEIVE_CURRENT_STATUS_ERROR = 'RECEIVE_CURRENT_STATUS_ERROR';
export const RESET_REPORTS = 'RESET_REPORTS';

/**
 * Start request for temperature
 */
function requestTemperature() {
    return {
        type: REQUEST_TEMPERATURE,
    };
}

/**
 * Receive temperature Redux action creator
 * @param {Number} temperature
 */
function receiveTemperature(avgTemperature) {
    return {
        type: RECEIVE_TEMPERATURE,
        average: avgTemperature,
        receivedAt: Date.now(),
    };
}

/**
 * Receive temperature error Redux action creator
 * @param {Error} error
 */
function receiveTemperatureError(error) {
    return {
        type: RECEIVE_TEMPERATURE_ERROR,
        error,
    };
}

/**
 * Request to get current temperature starts
 */

function requestCurrentStatus() {
    return {
        type: REQUEST_CURRENT_STATUS,
    };
}

/**
 * Receive current status
 */
function receiveCurrentStatus(currentStatus) {
    return {
        type: RECEIVE_CURRENT_STATUS,
        currentStatus,
    };
}

/**
 * Receive current status error
 */
function receiveCurrentStatusError(error) {
    return {
        type: RECEIVE_CURRENT_STATUS_ERROR,
        error,
    };
}

/**
 * Action to let know that transactions request has started
 */
function requestTransactions() {
    return {
        type: REQUEST_TRANSACTIONS,
    };
}

/**
 * Action to call when receiving successful response to transactions request
 */
function receiveTransactions(transactions) {
    return {
        type: RECEIVE_TRANSACTIONS,
        transactions,
    };
}

/**
 * Action to call when receiving error response to transactions request
 */
function receiveTransactionsError(error) {
    return {
        type: RECEIVE_TRANSACTIONS_ERROR,
        error,
    };
}

/**
 * Sets transaction modal state
 * @param {???} transaction
 * @param boolean open
 */
function setTransactionModal(transaction, open) {
    return {
        type: SET_TRANSACTION_MODAL,
        open,
        transaction,
    };
}

/**
 * Select reports view
 * @param {???} from
 * @param {???} to
 * @param {string} selectedPeriod
 */
export function setTimeRange(from, to, selectedPeriod) {
    return {
        type: SET_TIME_RANGE,
        from,
        to,
        selectedPeriod,
    };
}

/**
 * Select reports view
 * @param {string} view
 */
export function selectView(view) {
    return {
        type: SELECT_VIEW,
        view,
    };
}

/**
 * Resets reports data. Intended to be used when user changes group.
 */
export function resetReports() {
    return {
        type: RESET_REPORTS,
    };
}

/**
 * Helper function for opening transaction modal
 * @param transaction
 */
export const openTransactionModal = transaction => setTransactionModal(transaction, true);

/**
 * Helper function for closing transaction modal
 */
export const closeTransactionModal = () => setTransactionModal(null, false);

/**
 * Thunk action creator for fetching temperature
 * @param {string} deviceCode
 * @param {???} from
 * @param {???} to
 */

export function fetchTemperature(deviceCode, from, to, signal) {
    return (dispatch, getState) => {
        // update state to inform API call started
        dispatch(requestTemperature());
        const user = getState().user;
        return runCubeQueryHttpRequest(getTemperatureCubeQuery(deviceCode, from, to, 'average'), user.group, user.accessToken, -8, true, signal)
            .then(response => {
                return dispatch(receiveTemperature(response));
            })
            .catch(error => {
                if (!signal || !signal.aborted) {
                    return dispatch(receiveTemperatureError(error));
                }
            });
    };
}

/**
 *
 * @param {*} deviceCode
 * @param {*} from
 * @param {*} to
 */

export const fetchCurrentStatus = deviceCode => {
    return (dispatch, getState) => {
        dispatch(requestCurrentStatus());

        const user = getState().user;

        return runCubeQueryHttpRequest(getCurrentTemperatureCubeQuery(deviceCode), user.group, user.accessToken, 0, false)
            .then(response => {
                return dispatch(receiveCurrentStatus(response));
            })
            .catch(error => {
                return dispatch(receiveCurrentStatusError(error));
            });
    };
};

/**
 * Thunk action creator for fetching transactions
 * @param {string} deviceCode
 * @param {???} from
 * @param {???} to
 */
export const getTransactions = (deviceCode, from, to) => async (dispatch, getState) => {
    dispatch(requestTransactions());
    const user = getState().user;

    // if dates are not given, fetch from redux state
    const dateFrom = from || getState().report.timeRange.from;
    const dateTo = to || getState().report.timeRange.to;

    await fetchTransactions(user.accessToken, deviceCode, dateFrom, dateTo)
        .then(transactions => dispatch(receiveTransactions(transactions)))
        .catch(error => dispatch(receiveTransactionsError(error)));
};

/**
 * Claims the refund for the payment system. Not all cabinets suport refund, see the payment system implementation.
 * @param {*} deviceCode Device code if the cabinet.
 * @param {*} orderId Order ID
 * @param {*} refundItems List of items to be refund. [{ price: 1.00, count: 1}, { price: 2.22, count: 2 }]
 * @param {*} reason Reason of the refund.
 */
export function claimRefund(deviceCode, orderId, refundItems, reason, t) {
    return (dispatch, getState) => {
        dispatch(showLoading('refundClaim'));

        // access token for network request
        const accessKey = getState().user.accessToken;

        // ??
        const sum = sumBy(refundItems, item => item.price * item.refundAmount).toFixed(2);
        refundOrder(accessKey, deviceCode, orderId, refundItems, reason)
            .then(() => {
                // notify success
                dispatch(hideLoading('refundClaim'));
                NotificationManager.success(t('refundSuccess', { sum }));

                // reload transactions with refunded items
                dispatch(getTransactions(deviceCode));
            })
            .catch(err => {
                dispatch(hideLoading('refundClaim'));
                NotificationManager.error(err.message);
            });
    };
}
