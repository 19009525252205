import React from 'react';
import { TabItem, NavigationBar } from '@storaensods/seeds-react';

const NavBar = ({ t, selectedNav, active }) => {
    const navItems = [
        { name: 'all', value: t('summary') },
        { name: 'products', value: t('products') },
        { name: 'locations', value: t('locations') },
        { name: 'cabinets', value: t('cabinets') },
    ];

    const getNavItems = () => {
        return navItems.map(item => (
            <TabItem
                className="device-tab-item"
                isActive={active === item.name}
                onClick={() => selectedNav(item.name)}
                key={item.name}
            >
                {t(item.value)}
            </TabItem>
        ));
    };

    return <NavigationBar className="not-printable">{getNavItems()}</NavigationBar>;
};

export default NavBar;
