import React, { Fragment, useState, useEffect } from 'react';
import { Button, Select } from '@storaensods/seeds-react';
import moment from 'moment';
import DatePicker from 'react-datepicker';

import DateGranularity from './dateGranularity';
import RadioButtonGroup from './radioButtonGroup';
import MultiSelect from './multiSelect';
import { measures, dimensions, queryTypeOptions, groupingOptions, predefined, predefinedOptions } from './../config';
import { storeCustomQuerySelectorSetting, resetCustomQueryFilter } from './../../../../localStorage';
import { store } from './../../../../index';
import icons from './../icons.svg';
import UserPresetQueries from './userPresets';

// make the custom date default to last seven days
const getDefaultCustomDate = () => {
    return [
        moment()
            .subtract(7, 'days')
            .startOf('day')
            .toString(),
        moment().toString(),
    ];
};

const SideBar = ({ t, fetchCustomCubeQuery, custom, cabinets, locations, currentSelection, setCurrentSelection, prevState }) => {
    const [selectedMeasures, setSelectedMeasures] = useState({ members: [], filters: [] });
    const [selectedDimensions, setSelectedDimensions] = useState({ members: [], filters: [] });
    const [selectedDateRange, setSelectedDateRange] = useState('All time');
    const [selectedCustomDateRange, setSelectedCustomDateRange] = useState(getDefaultCustomDate());
    const [selectedGranularity, setSelectedGranularity] = useState('w/o grouping');
    const [queryType, setQueryType] = useState('custom');
    const [groupBy, setGroupBy] = useState('all');
    const [selectedCabinetFilters, setSelectedCabinetFilters] = useState({
        members: [],
        filters: [],
    });
    const [selectedLocationFilters, setSelectedLocationFilters] = useState({
        members: [],
        filters: [],
    });
    const [preDefinedQuery, setPreDefinedQuery] = useState({});
    const [preDefinedSegment, setPreDefinedSegment] = useState('InventoryHistory');
    const [inventoryHistoryDate, setInventoryHistoryDate] = useState(new Date());
    const [preDefinedSelectedOption, setPreDefinedSelectedOption] = useState(predefinedOptions[0].value);
    const userGroup = store.getState().user.group.Id;

    /**
     * When component loads, check the localstorage
     * and update the states immediately
     */
    useEffect(() => {
        if (!prevState) {
            return;
        }

        onUserPresetQuerySelected(prevState);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
     * When user group is changed,
     * reset any location and cabinet filters from the localstorage
     * to avoid one organisation's cabinet/filter appearing in
     * select filter options in another organisations
     */

    useEffect(() => {
        if (prevState && prevState.userGroup) {
            if (prevState.userGroup !== userGroup) {
                setGroupBy('all');
                setSelectedCabinetFilters({
                    members: [],
                    filters: [],
                });
                setSelectedLocationFilters({
                    members: [],
                    filters: [],
                });
                resetCustomQueryFilter();
            }
        } else {
            setGroupBy('all');
            setSelectedCabinetFilters({
                members: [],
                filters: [],
            });
            setSelectedLocationFilters({
                members: [],
                filters: [],
            });
            resetCustomQueryFilter();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userGroup]);

    /**
     * For every selection change,
     * send the current state to parent component
     * to update the selection summary view
     */
    useEffect(() => {
        const selections = {
            selectedMeasures,
            selectedDimensions,
            selectedDateRange,
            selectedGranularity,
            queryType,
            groupBy,
            selectedCabinetFilters,
            selectedLocationFilters,
            preDefinedSegment,
            inventoryHistoryDate,
        };
        setCurrentSelection(selections);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        selectedMeasures,
        selectedDimensions,
        selectedDateRange,
        selectedGranularity,
        queryType,
        groupBy,
        selectedCabinetFilters,
        selectedLocationFilters,
        preDefinedSegment,
        inventoryHistoryDate,
    ]);

    /**
     * When user saved query is selected
     * fill the fields with selected data
     */
    const onUserPresetQuerySelected = preset => {
        const prevDateRange = preset.dateRange ? preset.dateRange : selectedDateRange;
        const prevFilter = preset.filter ? preset.filter : groupBy;
        const prevCabinetFilters = preset.cabinets ? preset.cabinets : selectedCabinetFilters;
        const prevLocationFilters = preset.locations ? preset.locations : selectedLocationFilters;
        const prevDimensions = preset.dimensions ? preset.dimensions : selectedDimensions;
        const prevMeasures = preset.measures ? preset.measures : selectedMeasures;
        const prevGranularity = preset.granularity ? preset.granularity : selectedGranularity;
        const prevReportType = preset.reportType ? preset.reportType : queryType;
        const prevSegments = preset.segments ? preset.segments : preDefinedSegment;

        // Quick fix to ensure "Transactions count" metric always has a parameter
        if (prevMeasures.members.includes('Transactions.count') && prevMeasures.filters.length === 0) {
            prevMeasures.filters.push({
                dimension: 'Transactions.status',
                operator: 'equals',
                values: ['DONE'],
            });
        }

        // if there is a predefined selected in store,
        // prepare the query
        if (prevSegments) {
            const query = {
                ...predefined[prevSegments],
                segments: [prevSegments],
            };
            setPreDefinedQuery(query);
        }

        if (prevState) {
            setSelectedMeasures(prevMeasures);
            setSelectedDimensions(prevDimensions);
            setSelectedGranularity(prevGranularity);
            setSelectedDateRange(prevDateRange);
            setQueryType(prevReportType);
            setGroupBy(prevFilter);
            setSelectedCabinetFilters(prevCabinetFilters);
            setSelectedLocationFilters(prevLocationFilters);
            setPreDefinedSegment(prevSegments);
        }
    };

    /**
     * When predefined query type is selected
     * fetch the predefined query from config
     */
    const onPredefinedQueryChange = selection => {
        const day = moment();
        const addDays = day.add('days', -3);
        let query = {};

        setPreDefinedSelectedOption(selection.value);

        if (selection.value === 'InventoryHistory') {
            query = {
                ...predefined[selection.value],
                timeDimensions: [
                    {
                        dimension: 'InventoryHistory.readtime',
                        dateRange: [day, addDays],
                        granularity: 'day',
                    },
                ],
            };
        } else {
            query = {
                ...predefined[selection.value],
                segments: [selection.value],
            };
        }

        setPreDefinedQuery(query);
        setPreDefinedSegment(selection.value);
    };

    /**
     * When filtering is done by cabinets, get all cabinets for given organisation
     */
    const getCabinetsGroupingData = () =>
        cabinets.map(device => {
            return { value: device.deviceCode, label: device.name };
        });

    /**
     * When filtering is done by locations, get all locations for given organisation
     */
    const getLocationsGroupingData = () =>
        locations.map(location => {
            return { value: location.id, label: location.name, parentLocationCode: location.parentLocationCode };
        });

    /**
     * Run cube query when update data button pressed
     */
    const runCubeQuery = () => {
        // get the selected filters
        const filters = [];
        let dateRange = selectedDateRange === 'custom' ? selectedCustomDateRange : selectedDateRange;
        // If queryType is preDefined, then filtering per cabinet/location is done at UI for performance, so no need to add filter to query.
        if (groupBy === 'cabinets' && queryType !== 'preDefined') {
            filters.push({
                dimension: 'Device.deviceCode',
                operator: 'equals',
                values: selectedCabinetFilters.members,
            });
        } else if (groupBy === 'locations' && queryType !== 'preDefined') {
            filters.push({
                dimension: 'Location.id',
                operator: 'equals',
                values: selectedLocationFilters.members,
            });
        }

        if (queryType === 'custom') {
            fetchCustomCubeQuery(selectedMeasures.members, selectedDimensions.members, dateRange, selectedGranularity, [
                ...filters,
                ...selectedMeasures.filters,
            ]);
        } else if (queryType === 'preDefined') {
            if (preDefinedSelectedOption === 'InventoryHistory') {
                if (!('dimensions' in preDefinedQuery)) {
                    preDefinedQuery.dimensions = predefined['InventoryHistory'].dimensions;
                }
                dateRange = [
                    moment(inventoryHistoryDate)
                        .subtract(7, 'days')
                        .startOf('day')
                        .toDate(),
                    moment(inventoryHistoryDate).endOf('day'),
                ];
            }
            fetchCustomCubeQuery(preDefinedQuery.measures, preDefinedQuery.dimensions, dateRange, selectedGranularity, filters);
        }

        // each time query runs,
        // save the selection status to the localstorage
        storeCustomQuerySelectorSetting(
            (dateRange = dateRange === 'custom' ? 'custom' : selectedDateRange),
            selectedGranularity,
            groupBy,
            selectedCabinetFilters,
            selectedLocationFilters,
            selectedMeasures,
            selectedDimensions,
            queryType,
            preDefinedSegment,
            userGroup
        );
    };

    /*  enable the update data button only when
     *  atleast measure/dimenison is choosen or
     *  predeefined report type is selected or
     *  if the query is not in fetching state
     */
    const shouldUpdateButtonDisable =
        (queryType === 'custom' && !(selectedMeasures.members.length || selectedDimensions.members.length)) ||
        (queryType === 'preDefined' && preDefinedSegment === '') ||
        custom.isFetching;
    const updatedGroupOptions = queryType === 'preDefined' ? groupingOptions.filter(o => o.value !== 'locations') : groupingOptions;

    // check that correct params are selected
    if (groupBy === 'all') delete custom.invalidParams;
    else if (groupBy === 'cabinets') {
        if (selectedCabinetFilters.members.length === 0) custom.invalidParams = true;
        else delete custom.invalidParams;
    } else if (groupBy === 'locations') {
        if (queryType === 'preDefined') delete custom.invalidParams;
        if (selectedLocationFilters.members.length === 0) custom.invalidParams = true;
        else delete custom.invalidParams;
    }

    // function to check query type
    const returnGroupBy = () => {
        if (groupBy === 'locations' && queryType === 'preDefined') {
            setGroupBy('all');
            return 'all';
        } else return groupBy;
    };

    return (
        <Fragment>
            {queryType === 'custom' && (
                <DateGranularity
                    selectedDateRange={selectedDateRange}
                    selectedGranularity={selectedGranularity}
                    onDateRangeChange={dateRange => setSelectedDateRange(dateRange)}
                    onGranularityChange={granularity => setSelectedGranularity(granularity.value)}
                    setSelectedCustomDateRange={setSelectedCustomDateRange}
                    selectedCustomDateRange={selectedCustomDateRange}
                    t={t}
                />
            )}

            <Fragment>
                <RadioButtonGroup
                    options={updatedGroupOptions}
                    label={t('filterBy')}
                    name="data-grouping"
                    selectionChange={grouping => {
                        setGroupBy(grouping);
                    }}
                    current={returnGroupBy()}
                    t={t}
                />
            </Fragment>
            {groupBy === 'cabinets' && (
                <MultiSelect
                    members={getCabinetsGroupingData()}
                    label={t('cabinets')}
                    name={'cabinets'}
                    selectionChange={selection => {
                        setSelectedCabinetFilters(selection);
                    }}
                    selected={selectedCabinetFilters}
                    t={t}
                    reportViewPageZIndex="true"
                    searchable="true"
                />
            )}
            {groupBy === 'locations' && queryType === 'custom' && (
                <MultiSelect
                    members={getLocationsGroupingData()}
                    label={t('locations')}
                    name={'locations'}
                    selectionChange={selection => {
                        setSelectedLocationFilters(selection);
                    }}
                    selected={selectedLocationFilters}
                    searchable="true"
                    info={t('locationsSelectHelp')}
                    t={t}
                />
            )}

            <Fragment>
                <RadioButtonGroup
                    options={queryTypeOptions}
                    label={t('reportType')}
                    name="query"
                    selectionChange={type => {
                        setQueryType(type);
                    }}
                    current={queryType}
                    t={t}
                />

                {queryType === 'custom' && (
                    <Fragment>
                        <MultiSelect
                            members={measures}
                            label={t('metrics')}
                            name={'metrics'}
                            selectionChange={selection => {
                                // Quick fix to ensure "Transactions count" metric always has a parameter
                                if (selection.members.includes('Transactions.count') && selection.filters.length === 0) {
                                    selection.filters.push({
                                        dimension: 'Transactions.status',
                                        operator: 'equals',
                                        values: ['DONE'],
                                    });
                                }

                                setSelectedMeasures(selection);
                            }}
                            selected={selectedMeasures}
                            info={t('metricsInfo')}
                            t={t}
                        />
                        <MultiSelect
                            members={dimensions}
                            label={t('dimensions')}
                            name={'dimensions'}
                            selectionChange={selection => {
                                setSelectedDimensions(selection);
                            }}
                            selected={selectedDimensions}
                            info={t('dimensionsInfo')}
                            t={t}
                        />
                    </Fragment>
                )}

                {queryType === 'preDefined' && (
                    <Select
                        options={predefinedOptions}
                        onChange={selection => onPredefinedQueryChange(selection)}
                        className="mt-2"
                        defaultValue={predefinedOptions[0]}
                    />
                )}
                {queryType === 'preDefined' && preDefinedSelectedOption === 'InventoryHistory' && (
                    <div className="date-picker inventory__history">
                        <div className="radio-group__label">Select a day</div>
                        <svg className="date-picker-icon inventory__history-icon">
                            <use xlinkHref={`${icons}#icon-calendar`}></use>
                        </svg>
                        <DatePicker selected={inventoryHistoryDate} onChange={date => setInventoryHistoryDate(date)} />
                    </div>
                )}
            </Fragment>
            <div className="buttons">
                <Button icon="refresh" type="attention" disabled={shouldUpdateButtonDisable || custom.invalidParams} onClick={runCubeQuery}>
                    {t('updateData')}
                </Button>
            </div>
            <div className="divider" />
            <UserPresetQueries t={t} custom={custom} currentSelection={currentSelection} onSelected={onUserPresetQuerySelected} />
        </Fragment>
    );
};

export default SideBar;
