import React from 'react';
import { Modal, ModalBody, ModalFooter } from 'reactstrap';

const ConfirmationModal = ({ isOpen, confirmDelete, cancel, message,t }) => {
    return (
        <Modal isOpen={isOpen} centered={true} size="md">
            <p className="refillschema__modal-header">{t('main:pleaseConfirm')}</p>
            <ModalBody className="refillschema__modal refillschema__modal-confirmation">{message}</ModalBody>
            <ModalFooter>
                <div className="refillschema__modal-btn" onClick={() => cancel()}>
                    {t('main:no')}
                </div>
                <div className="refillschema__modal-btn refillschema__modal-btn--confirm" onClick={() => confirmDelete()}>
                {t('main:yes')}
                </div>
            </ModalFooter>
        </Modal>
    );
};

export default ConfirmationModal;
