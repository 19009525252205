import React, { Component } from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { Icon, Input } from '@storaensods/seeds-react';
import { debounce } from 'lodash';

import { analyticsSearchQuery } from '../../../actions/analytics';

/**
 * Search bar for the cabinet list
 */
class AnalyticsSearchBar extends Component {
    constructor(props) {
        super(props);
        const { analyticsSearchQuery } = this.props;
        this.search = debounce(query => {
            analyticsSearchQuery(query);
        }, 250);
    }

    handleSearchChange(event) {
        const query = event.target.value;
        this.search(query);
    }

    componentDidMount() {
        if (this.props.searchQuery && this.props.searchQuery !== '') {
            this.props.analyticsSearchQuery('');
        }
    }

    render() {
        const { t } = this.props;
        return (
            <div className="d-flex align-items-center mb-3">
                <Icon className="m-2 text-muted">search</Icon>
                <Input placeholder={t('search')} size="sm" onChange={this.handleSearchChange.bind(this)} />
            </div>
        );
    }
}

export default connect(
    state => ({
        searchQuery: state.analytics.chart.searchQuery,
    }),
    {
        analyticsSearchQuery,
    }
)(translate('main')(AnalyticsSearchBar));
