import React, { useState } from 'react';
import { Button, Input, Divider } from '@storaensods/seeds-react';
import { Row, Col, ListGroup, ListGroupItem } from 'reactstrap';

/**
 * List transfer React component
 * @param {function} props.t i18n translator function
 * @param {array} props.allItems items need to have name and id properties
 * @param {array} props.selectedItems
 * @param {function} props.setSelectedItems
 * @param {function} props.t i18n translation function
 */
export default function ListTransfer(props) {
    const { t } = props;

    const [searchQuery, setSearchQuery] = useState('');
    const [highlightedItemId, highlightItemId] = useState(null);

    // sort items by search query match and alphabetically
    const itemSortFunc = (itemA, itemB) => {
        if (itemA.queryMatch && !itemB.queryMatch) {
            return -1;
        }
        if (!itemA.queryMatch && itemB.queryMatch) {
            return 1;
        }
        if (!itemA.name) return 1;
        if (!itemB.name) return -1;
        const nameA = itemA.name.toUpperCase();
        const nameB = itemB.name.toUpperCase();
        if (nameA > nameB) return 1;
        if (nameA < nameB) return -1;
        return 0;
    };

    // items that are not selected
    const notSelectedItems = props.allItems
        .filter(item => !props.selectedItems.find(selectedItem => item.id === selectedItem.id))
        .map(item =>
            Object.assign({}, item, {
                queryMatch: searchQuery
                    ? item.name.toLowerCase().includes(searchQuery.toLowerCase())
                    : true,
            })
        )
        .sort(itemSortFunc);

    // items that are selected
    const selectedItems = props.selectedItems
        .map(item =>
            Object.assign({}, item, {
                queryMatch: searchQuery
                    ? item.name.toLowerCase().includes(searchQuery.toLowerCase())
                    : true,
            })
        )
        .sort(itemSortFunc);

    // true if highlighted item is selected item for transfer buttons disabling and enabling
    const selectedItemHighlighted =
        props.selectedItems.filter(item => item.id === highlightedItemId).length !== 0;

    const handleSearchQueryInputChange = event => {
        highlightItemId(null);
        setSearchQuery(event.target.value);
    };

    const selectItem = itemId => {
        const item = props.allItems.find(item => item.id === itemId);
        if (!item) {
            console.error('ListTransfer component: Unable to find highlighted item!');
            return;
        }
        highlightItemId(null);
        props.setSelectedItems([...props.selectedItems, item]);
    };

    const unselectItem = itemId => {
        highlightItemId(null);
        props.setSelectedItems(props.selectedItems.filter(item => item.id !== itemId));
    };

    return (
        <div>
            <Row>
                <Col xs="12">
                    <Input
                        label={t('search')}
                        placeholder={t('name')}
                        value={searchQuery}
                        onChange={handleSearchQueryInputChange}
                    />
                </Col>
                <Col xs="12">
                    <Divider />
                </Col>
                <Col xs="12" lg="5">
                    <ListGroup className="border" style={{ height: '50vh', overflowY: 'scroll' }}>
                        {notSelectedItems.map(item => (
                            <ListGroupItem
                                key={item.id}
                                onClick={highlightItemId.bind(this, item.id)}
                                active={highlightedItemId === item.id}
                                className={!item.queryMatch ? 'se-list-transfer-item-disabled' : ''}
                                style={{ cursor: 'pointer' }}
                            >
                                {item.name}
                            </ListGroupItem>
                        ))}
                    </ListGroup>
                </Col>
                <Col xs="12" lg="2">
                    <Row>
                        <Col xs="12" className="mb-2">
                            <Button
                                icon="arrow_forward"
                                className="w-100"
                                disabled={!highlightedItemId || selectedItemHighlighted}
                                onClick={selectItem.bind(this, highlightedItemId)}
                            ></Button>
                        </Col>
                        <Col xs="12">
                            <Button
                                icon="arrow_back"
                                className="w-100"
                                disabled={!highlightedItemId || !selectedItemHighlighted}
                                onClick={unselectItem.bind(this, highlightedItemId)}
                            ></Button>
                        </Col>
                    </Row>
                </Col>
                <Col xs="12" lg="5">
                    <ListGroup className="border" style={{ height: '50vh', overflowY: 'scroll' }}>
                        {selectedItems.map(item => (
                            <ListGroupItem
                                key={item.id}
                                onClick={highlightItemId.bind(this, item.id)}
                                active={highlightedItemId === item.id}
                                className={!item.queryMatch ? 'se-list-transfer-item-disabled' : ''}
                                style={{ cursor: 'pointer' }}
                            >
                                {item.name}
                            </ListGroupItem>
                        ))}
                    </ListGroup>
                </Col>
            </Row>
        </div>
    );
}

/**
 * List transfer React component
 * @param {function} props.t i18n translator function
 * @param {array} props.selectedInts
 * @param {function} props.setSelectedInts
 */
export function ListTransferInteger(props) {
    const { t } = props;
    const [highlightedInt, highlightInt] = useState(0);
    const [integerInputValue, setIntegerInputValue] = useState('');

    const selectedInts = props.selectedInts.sort((a, b) => a > b);

    const selectInt = () => {
        if (integerInputValue === '') {
            console.warn('ListTransferInteger: attempted to add empty integer input to list');
            return;
        }

        const integer = parseInt(integerInputValue);
        if (isNaN(integer)) {
            console.warn('ListTransferInteger: attempted to add integer that is not a number');
            return;
        }

        if (selectedInts.includes(integer)) {
            console.warn('ListTransferInteger: attempted to add existing integer to list');
            return;
        }

        if (integer < 0) {
            console.warn('ListTransferInteger: attempted to add integer less than 0 to list');
            return;
        }

        highlightInt(0);
        setIntegerInputValue('');
        props.setSelectedInts([...props.selectedInts, integer]);
    };

    const unselectInt = unselectInteger => {
        if (!unselectInteger) {
            console.error('ListTransferInteger component: unselectInt no integer to unselect!');
            return;
        }
        highlightInt(0);
        props.setSelectedInts(props.selectedInts.filter(integer => integer !== unselectInteger));
    };

    const handleIntegerInputChange = event => {
        if (isNaN(event.target.value) || isNaN(parseInt(event.target.value))) {
            return;
        }

        const integer = parseInt(event.target.value);
        if (integer < 0) {
            return;
        }

        setIntegerInputValue(integer);
    };

    return (
        <div>
            <Row>
                <Col xs="12" lg="5">
                    <Input
                        label={t('ttlInDays')}
                        placeholder="Number"
                        value={integerInputValue}
                        onChange={handleIntegerInputChange}
                    />
                </Col>
                <Col xs="12" lg="2">
                    <Row>
                        <Col xs="12" className="mb-2">
                            <Button
                                icon="arrow_forward"
                                className="w-100"
                                disabled={
                                    integerInputValue === '' ||
                                    selectedInts.includes(parseInt(integerInputValue))
                                }
                                onClick={selectInt}
                            ></Button>
                        </Col>
                        <Col xs="12">
                            <Button
                                icon="arrow_back"
                                className="w-100"
                                disabled={highlightedInt === 0}
                                onClick={unselectInt.bind(this, highlightedInt)}
                            ></Button>
                        </Col>
                    </Row>
                </Col>
                <Col xs="12" lg="5">
                    <ListGroup className="border" style={{ height: '50vh', overflowY: 'scroll' }}>
                        {selectedInts.map(integer => (
                            <ListGroupItem
                                key={integer}
                                onClick={highlightInt.bind(this, integer)}
                                active={highlightedInt === integer}
                                style={{ cursor: 'pointer' }}
                            >
                                {integer}
                            </ListGroupItem>
                        ))}
                    </ListGroup>
                </Col>
            </Row>
        </div>
    );
}
