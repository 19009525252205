import React from 'react';
import { Row, Col } from 'reactstrap';
import { Card, Icon } from '@storaensods/seeds-react';

const CardData = ({ salesData, wasteData, selectedNav, t }) => {
    const getPercentage = type => {
        const salesValue = parseFloat(salesData.totalValue);
        const wasteValue = parseFloat(wasteData.totalValue);

        const totalValue = salesValue + wasteValue;

        if (!totalValue) {
            return 0;
        }

        if (type === 'sales') {
            const salesPercent = (salesValue / totalValue) * 100;
            return salesPercent.toFixed(2);
        } else {
            const wastePercent = (wasteValue / totalValue) * 100;

            return wastePercent.toFixed(2);
        }
    };

    return (
        <Row className="card-container">
            {selectedNav === 'products' && (
                <Col className="card-info col-sm-12 col-md-12 col-lg-4">
                    <Card title={salesData.productName}>
                        <div className="card-content">
                            {salesData.imageUrl && (
                                <img src={salesData.imageUrl} style={{ height: '75px', paddingRight: '15px' }} alt={salesData.productName} />
                            )}
                            <div>
                                {t('barcode')}: {salesData.barcode} <br />
                                {t('total')} {t('products')}: {salesData.totalCount + wasteData.totalCount}
                                <br />
                                <br />
                            </div>
                        </div>
                    </Card>
                </Col>
            )}
            <Col className={`card-sales col-sm-12  ${selectedNav === 'all' ? 'col-md-6 col-lg-3' : 'col-md-12 col-lg-4'}`}>
                <Card title={t('sales')}>
                    <div className="card-content">
                        <Icon color="green" size="lg" className="icon">
                            attach_money
                        </Icon>
                        <div>
                            {t('totalValue')}: {salesData.totalValue.toFixed(2)} {salesData.currency}
                            <br />
                            {t('total')} {t('products')} : {salesData.totalCount} <br />
                            {t('sales')} {t('percentage')} : {getPercentage('sales')} %
                        </div>
                    </div>
                </Card>
            </Col>
            <Col className={`card-waste col-sm-12 ${selectedNav === 'all' ? 'col-lg-3 col-md-6 ' : 'col-md-12  col-lg-4'}`}>
                <Card title={t('waste')}>
                    <div className="card-content">
                        <Icon color="green" size="lg" className="icon">
                            money_off
                        </Icon>
                        <div>
                            {t('totalValue')}: {wasteData.totalValue.toFixed(2)} {salesData.currency}
                            <br />
                            {t('total')} {t('products')} : {wasteData.totalCount}
                            <br />
                            {t('waste')} {t('percentage')} : {getPercentage('waste')} %
                        </div>
                    </div>
                </Card>
            </Col>
        </Row>
    );
};

export default CardData;
