import React, { useState, useEffect, Fragment } from 'react';
import { groupBy } from 'lodash';

import icons from './../../../icons/icomoon.svg';

const DevicesByLocations =({cabinets, locations, t, cabinetSelectionChange })=>{
    const [cabinetSearchQuery, setCabinetSearchQuery] = useState('');
    const [cabinetGrouping, setCabinetGroupings] = useState({});
    const [activeLocationTab, setActiveLocationTab] = useState();
    const [selectedCabinet, setSelectedCabinet] = useState('');
    const [collapseDevicesView, setCollapseDevicesView] = useState(false);

    useEffect(() => {
        const groupCabinetsByLocations = groupBy(cabinets, 'locationId') || {};
        const locationKeys = Object.keys(groupCabinetsByLocations) || [];

        // map the locationId to location name
        if (!locationKeys.length) return;

        let locationsWithCabinets = {};

        locationKeys.forEach(loc => {
            if (loc === 'null') {
                locationsWithCabinets = { ...locationsWithCabinets, 'noLocation': [...groupCabinetsByLocations[loc]] };
            } else {
                const locationMap = locations.find(l => l.id === loc);
                if (locationMap) {
                    locationsWithCabinets = { ...locationsWithCabinets, [locationMap.name]: [...groupCabinetsByLocations[loc]] };
                }
            }
        });
        setCabinetGroupings(locationsWithCabinets);
    }, [cabinets, locations]);

    const getAllCabinetsByLocation = () => {
        let cabinetByLocations = {}

        if(cabinetSearchQuery){
            const searchQuery = cabinetSearchQuery.toString().toLowerCase();
            for(let location in cabinetGrouping){
                
                const cabinets = cabinetGrouping[location].filter(cabinet=>{                
                return cabinet.name.toString().toLowerCase().includes(searchQuery) || cabinet.deviceCode.toString().toLowerCase().includes(searchQuery)
                })
                if(cabinets.length){
                    cabinetByLocations={...cabinetByLocations,[location]:cabinets}
                }       
            }
        }else{
            cabinetByLocations = {...cabinetGrouping}
        }

        const locationKeys = Object.keys(cabinetByLocations);
        if (!locationKeys.length) return;

        const locationClicked = loc => {
            if (loc === activeLocationTab) {
                setActiveLocationTab(null);
                return;
            }

            setActiveLocationTab(loc);
        };

        return locationKeys.map(location => {
            return (
                <Fragment key={location}>
                    <div
                        className={`refillschema__locations-header ${((location === activeLocationTab) || cabinetSearchQuery) ? 'refillschema__locations-header--active' : ''}`}
                        onClick={() => locationClicked(location)}
                    >
                        <svg>
                            <use xlinkHref={`${icons}#icon-keyboard_arrow_right`}></use>
                        </svg>
                        <svg>
                            <use xlinkHref={`${icons}#icon-location`}></use>
                        </svg>
                        <span className="refillschema__locations-name">{t(`main:${location}`)}</span>
                        <span className="refillschema__count">{cabinetByLocations[location].length}</span>
                    </div>
                    <ul className={`refillschema__locations-cabinets ${((location === activeLocationTab) || cabinetSearchQuery) ? ' refillschema__locations-cabinets--active' : ''}`}>
                        {cabinetByLocations[location].map(cabinet => {
                            return (
                                <li
                                    onClick={() => {
                                        setSelectedCabinet(cabinet);
                                        setCollapseDevicesView(true)
                                        cabinetSelectionChange(cabinet)
                                    }}
                                    className={`${
                                        selectedCabinet && selectedCabinet.deviceCode === cabinet.deviceCode ? 'refillschema__locations-cabinet--selected' : ''
                                    }`}
                                    key={cabinet.deviceCode}
                                >
                                    {' '}
                                    {cabinet.name}{' '}
                                </li>
                            );
                        })}
                    </ul>
                </Fragment>
            );
        });
    };


    return(
        <div className="refillschema__cabinets">
            <div className={`refillschema__cabinets-accordion ${selectedCabinet ? ' refillschema__cabinets-accordion--visible':''}`} onClick={()=>setCollapseDevicesView(!collapseDevicesView)}>
                <span>
                    {selectedCabinet.name}
                </span>
                <svg className={`${collapseDevicesView ? 'refillschema__cabinets-accordion--up':'refillschema__cabinets-arrow--down'}`}>
                    <use xlinkHref={`${icons}#icon-arrow_drop_down`}></use>
                </svg>
            </div>
            <div className={`refillschema__cabinets-search ${collapseDevicesView ? 'refillschema__cabinets-search--hide':''}`}>
                <input type="text" placeholder={t('main:searchCabinets')} onChange={(e)=>setCabinetSearchQuery(e.target.value)}/>
                <svg>
                    <use xlinkHref={`${icons}#icon-search`}></use>
                </svg>
            </div>
            <div className={`refillschema__locations ${collapseDevicesView ? 'refillschema__locations--hide':''}`}>
                {getAllCabinetsByLocation()}
            </div>
        </div>
    )

}

export default DevicesByLocations;