import React from 'react';
import { Modal } from '@storaensods/seeds-react';

/**
 * Opens a modal to ask if the user confirming a delete request. Uses SEEDS modal.
 *
 * @param {bool} showDeletePrompt Show/hides the modal
 * @param {function} onDeleteClicked The callback function to be called on Cancel button pressed
 * @param {function} onCancelClicked The call back function to be called when Delete button pressed
 * @param {string} message The message to be shown in the body of the modal. Defaults to  'Are you sure?'
 */
export default function DeletePrompt({ showDeletePrompt, onDeleteClicked, onCancelClicked, message = 'Are you sure?', t }) {
    return (
        <Modal
            actions={[
                {
                    label: t('delete'),
                    onClick: () => onDeleteClicked(),
                },
                {
                    label: t('cancel'),
                    onClick: () => onCancelClicked(),
                },
            ]}
            active={showDeletePrompt}
            onClose={() => onCancelClicked()}
        >
            {message}
        </Modal>
    );
}
