import { v1 as uuidv1 } from 'uuid';
import {
    fetchDeviceStatus as fetchDeviceStatusHttpRequest,
    fetchRefillRules as fetchRefillRuleHttpRequest,
    updateRefillRule as updateRefillRuleHttpRequest,
    createRefillRule as createRefillRuleHttpRequest,
    deleteRefillRule as deleteRefillRuleHttpRequest,
    fetchCabinetRefillRule as fetchCabinetRefillRuleHttpRequest,
    sendCommand,
    emitEventToSocketIO,
    fetchDeviceConfiguration,
    updateTagQualityMetrics,
    updateCabinetSettings,
    postSettingsCopyRequest as postSettingsCopyRequestHttp,
    createLogoImage as createLogoImageHttpRequest,
    fetchDeviceSoftwareVersion as selectedDeviceSoftwareVersionHttpRequest,
    fetchAllSoftwareVersions as allSoftwareVersionsHttpRequest,
    fetchSelectedDeviceType as selectedDeviceTypeHttpRequest,
    updateDeviceSoftwareVersion as updateDeviceSoftwareVersionHttpRequest,
    imageUpload as imageUploadHttpRequest
} from '../api.js';
export const REQUEST_DEVICE_STATUS = 'REQUEST_DEVICE_STATUS';
export const RECEIVE_CABINET_STATUS = 'RECEIVE_CABINET_STATUS';
export const RECEIVE_CABINET_STATUS_ERROR = 'RECEIVE_CABINET_STATUS_ERROR';
export const RECEIVE_ES_DEVICE_STATUS = 'RECEIVE_ES_DEVICE_STATUS';
export const SEND_LOCK_DOOR_COMMAND = 'SEND_LOCK_DOOR_COMMAND';
export const SEND_UNLOCK_DOOR_COMMAND = 'SEND_UNLOCK_DOOR_COMMAND';
export const RECEIVE_LOCK_DOOR_COMMAND_STATUS = 'RECEIVE_LOCK_DOOR_COMMAND_STATUS';
export const RECEIVE_UNLOCK_DOOR_COMMAND_STATUS = 'RECEIVE_UNLOCK_DOOR_COMMAND_STATUS';
export const CLEAR_LOCK_DOOR_COMMAND_STATUS = 'CLEAR_LOCK_DOOR_COMMAND_STATUS';
export const CLEAR_UNLOCK_DOOR_COMMAND_STATUS = 'CLEAR_UNLOCK_DOOR_COMMAND_STATUS';
export const SEND_MAINTENANCE_START_COMMAND = 'SEND_MAINTENANCE_START_COMMAND';
export const SEND_MAINTENANCE_END_COMMAND = 'SEND_MAINETNANCE_END_COMMAND';
export const RECEIVE_MAINTENANCE_START_COMMAND_STATUS = 'RECEIVE_MAINETNANCE_START_COMMAND_STATUS';
export const RECEIVE_MAINTENANCE_END_COMMAND_STATUS = 'RECEIVE_MAINETNANCE_END_COMMAND_STATUS';
export const CLEAR_START_MAINTENANCE_COMMAND_STATUS = 'CLEAR_START_MAINTENANCE_COMMAND_STATUS';
export const CLEAR_END_MAINTENANCE_COMMAND_STATUS = 'CLEAR_END_MAINTENANCE_COMMAND_STATUS';
export const SELECTED_CABINET_SETTINGS_LOADED = 'SELECTED_CABINET_SETTINGS_LOADED';
export const SELECTED_CABINET_SETTINGS_LOAD_FAILED = 'SELECTED_CABINET_SETTINGS_LOAD_FAILED';
export const TAG_QUALITY_METRICS_UPDATED = 'TAG_QUALITY_METRICS_UPDATED';
export const SELECTED_CABINET_SETTINGS_UPDATED = 'SELECTED_CABINET_SETTINGS_UPDATED';
export const SELECTED_CABINET_SETTINGS_UPDATE_FAILED = 'SELECTED_CABINET_SETTINGS_UPDATE_FAILED';
export const SELECTED_CABINET_FETCHING_CABINET_SETTINGS = 'SELECTED_CABINET_FETCHING_CABINET_SETTINGS';
export const REQUEST_UPLOAD_LOGO_IMAGE = 'REQUEST_UPLOAD_LOGO_IMAGE';
export const UPLOAD_LOGO_IMAGE_SUCCESS = 'UPLOAD_LOGO_IMAGE_SUCCESS';
export const UPLOAD_LOGO_IMAGE_ERROR = 'UPLOAD_LOGO_IMAGE_ERROR';
export const REMOVE_LOGO_IMAGE = 'REMOVE_LOGO_IMAGE';
export const REQUEST_REFILL_RULE = 'REQUEST_REFILL_RULE';
export const RECEIVE_REFILL_RULE = 'RECEIVE_REFILL_RULE';
export const RECEIVE_REFILL_RULE_ERROR = 'RECEIVE_REFILL_RULE_ERROR';
export const REQUEST_REFILL_CREATE_RULE = 'REQUEST_REFILL_CREATE_RULE';
export const RECEIVE_REFILL_RULE_CREATED = 'RECEIVE_REFILL_RULE_CREATED';
export const RECEIVE_REFILL_RULE_CREATED_ERROR = 'RECEIVE_REFILL_RULE_CREATED_ERROR';
export const REQUEST_REFILL_UPDATE_RULE = 'REQUEST_REFILL_UPDATE_RULE';
export const RECEIVE_REFILL_RULE_UPDATED = 'RECEIVE_REFILL_RULE_UPDATED';
export const RECEIVE_REFILL_RULE_UPDATED_ERROR = 'RECEIVE_REFILL_RULE_UPDATED_ERROR';
export const REQUEST_REFILL_DELETE_RULE = 'REQUEST_REFILL_DELETE_RULE';
export const RECEIVE_REFILL_RULE_DELETED = 'RECEIVE_REFILL_RULE_DELETED';
export const RECEIVE_REFILL_RULE_DELETED_ERROR = 'RECEIVE_REFILL_RULE_DELETED_ERROR';
export const RESET_REFILL_RULES = 'RESET_REFILL_RULES';
export const REQUEST_DEVICE_REFILL_RULE = 'REQUEST_DEVICE_REFILL_RULE';
export const RECEIVE_DEVICE_REFILL_RULE = 'RECEIVE_DEVICE_REFILL_RULE';
export const ERROR_DEVICE_REFILL_RULE = 'ERROR_DEVICE_REFILL_RULE';

export const REQUEST_DEVICE_SOFTWARE_VERSION = 'REQUEST_DEVICE_SOFTWARE_VERSION';
export const REQUEST_DEVICE_SOFTWARE_VERSION_SUCCESS = 'REQUEST_DEVICE_SOFTWARE_VERSION_SUCCESS';
export const REQUEST_DEVICE_SOFTWARE_VERSION_FAILED = 'REQUEST_DEVICE_SOFTWARE_VERSION_FAILED';
export const UPDATE_DEVICE_SOFTWARE_VERSION = 'UPDATE_DEVICE_SOFTWARE_VERSION';
export const UPDATE_DEVICE_SOFTWARE_VERSION_SUCCESS = 'UPDATE_DEVICE_SOFTWARE_VERSION_SUCCESS';
export const UPDATE_DEVICE_SOFTWARE_VERSION_FAILED = 'UPDATE_DEVICE_SOFTWARE_VERSION_FAILED';
export const SEND_DEVICE_REBOOT_COMMAND = 'SEND_DEVICE_REBOOT_COMMAND';
export const RECEIVE_DEVICE_REBOOT_COMMAND_STATUS = 'RECEIVE_DEVICE_REBOOT_COMMAND_STATUS';
export const CLEAR_DEVICE_REBOOT_COMMAND_STATUS = 'CLEAR_DEVICE_REBOOT_COMMAND_STATUS';
export const SEND_UPDATE_CABINET_SETTINGS_MANUALLY_COMMAND = 'SEND_UPDATE_CABINET_SETTINGS_MANUALLY_COMMAND';
export const RECEIVE_UPDATE_CABINET_SETTINGS_MANUALLY_COMMAND = 'RECEIVE_UPDATE_CABINET_SETTINGS_MANUALLY_COMMAND';

export const REQUEST_PLANOGRAM_IMAGE_UPLOADING = 'REQUEST_PLANOGRAM_IMAGE_UPLOADING';
export const REQUEST_PLANOGRAM_IMAGE_UPLOADED = 'REQUEST_PLANOGRAM_IMAGE_UPLOADED';
export const REQUEST_PLANOGRAM_IMAGE_UPLOAD_ERROR = 'REQUEST_PLANOGRAM_IMAGE_UPLOAD_ERROR';
export const RESET_PLANOGRAM_IMAGE = "RESET_PLANOGRAM_IMAGE"

/**
 * Request cabinet status Redux action creator
 */
export function requestDeviceStatus() {
    return {
        type: REQUEST_DEVICE_STATUS,
    };
}

/**
 * Receive cabinet status Redux action creator
 * @param {array} cabinets
 */
export function receiveCabinetStatus(cabinet) {
    return {
        type: RECEIVE_CABINET_STATUS,
        cabinet,
        receivedAt: Date.now(),
    };
}
/**
 * Receive DES device status Redux action creator
 * @param {array} cabinets
 */
export function receiveEncodingDeviceStatus(encodingDevice) {
    return {
        type: RECEIVE_ES_DEVICE_STATUS,
        encodingDevice,
        receivedAt: Date.now(),
    };
}

/**
 * Receive cabinet status error Redux action creator
 * @param {Error} error
 */
export function receiveCabinetStatusError(error) {
    return {
        type: RECEIVE_CABINET_STATUS_ERROR,
        error,
    };
}

export function sendLockDoorCommand() {
    return {
        type: SEND_LOCK_DOOR_COMMAND,
    };
}

export function sendUnlockDoorCommand() {
    return {
        type: SEND_UNLOCK_DOOR_COMMAND,
    };
}

export function sendDeviceRebootCommand() {
    return {
        type: SEND_DEVICE_REBOOT_COMMAND,
    };
}

export function sendStartMaintenanceModeCommand() {
    return {
        type: SEND_MAINTENANCE_START_COMMAND,
    };
}

export function sendEndMaintenanceModeCommand() {
    return {
        type: SEND_MAINTENANCE_END_COMMAND,
    };
}

export function ReceiveLockDoorCommandStatus(status) {
    return {
        type: RECEIVE_LOCK_DOOR_COMMAND_STATUS,
        status: status,
    };
}

export function ReceiveUnlockDoorCommandStatus(status) {
    return {
        type: RECEIVE_UNLOCK_DOOR_COMMAND_STATUS,
        status: status,
    };
}

export function ReceiveDeviceRebootCommandStatus() {
    return {
        type: RECEIVE_DEVICE_REBOOT_COMMAND_STATUS,
    };
}

export function receiveStartMaintenanceCommand(status) {
    return {
        type: RECEIVE_MAINTENANCE_START_COMMAND_STATUS,
        status,
    };
}

export function receiveEndMaintenanceCommand(status) {
    return {
        type: RECEIVE_MAINTENANCE_END_COMMAND_STATUS,
        status,
    };
}

export function clearLockDoorCommandStatus() {
    return dispatch => {
        dispatch({
            type: CLEAR_LOCK_DOOR_COMMAND_STATUS,
        });
    };
}

export function clearUnlockDoorCommandStatus() {
    return dispatch => {
        dispatch({
            type: CLEAR_UNLOCK_DOOR_COMMAND_STATUS,
        });
    };
}

export function clearDeviceRebootCommandStatus() {
    return dispatch => {
        dispatch({
            type: CLEAR_DEVICE_REBOOT_COMMAND_STATUS,
        });
    };
}

export function clearStartMaintenaceStatus() {
    return dispatch => {
        dispatch({
            type: CLEAR_START_MAINTENANCE_COMMAND_STATUS,
        });
    };
}

export function clearEndMaintenaceStatus() {
    return dispatch => {
        dispatch({
            type: CLEAR_END_MAINTENANCE_COMMAND_STATUS,
        });
    };
}

/**
 * Request create logo image Redux action creator
 */
function requestUpdateLogoImage() {
    return {
        type: REQUEST_UPLOAD_LOGO_IMAGE,
    };
}

/**
 * Receive create logo image success response Redux action creator
 */
function uploadLogoImageSuccess(url) {
    return {
        type: UPLOAD_LOGO_IMAGE_SUCCESS,
        url,
        receivedAt: Date.now(),
    };
}

/**
 * Receive create logo image error Redux action creator
 * @param {Error} error
 */
function uploadLogoImageError(error) {
    return {
        type: UPLOAD_LOGO_IMAGE_ERROR,
        error,
    };
}

/**
 * Used to remove image from form of product being updated/created
 */
export function removeLogoImage() {
    return {
        type: REMOVE_LOGO_IMAGE,
    };
}

/**
 * Request to fetch software version of the selected cabinet
 */
function requestSoftwareVersion() {
    return {
        type: REQUEST_DEVICE_SOFTWARE_VERSION,
    };
}

function requestSoftwareVersionSuccess(data) {
    return {
        type: REQUEST_DEVICE_SOFTWARE_VERSION_SUCCESS,
        currentDevice: Array.isArray(data) ? data[0] : {},
        allVersions: Array.isArray(data) ? data[1] : [],
        deviceType: Array.isArray(data) ? data[2] : {},
    };
}

function requestSoftwareVersionFailed(error) {
    return {
        type: REQUEST_DEVICE_SOFTWARE_VERSION_FAILED,
        error: error,
    };
}

function requestSoftwareVersionUpdate() {
    return {
        type: UPDATE_DEVICE_SOFTWARE_VERSION,
    };
}

function updateSoftwareVersionSuccess(data) {
    return {
        type: UPDATE_DEVICE_SOFTWARE_VERSION_SUCCESS,
        currentDevice: data,
    };
}

function updateSoftwareVersionFailed(error) {
    return {
        type: UPDATE_DEVICE_SOFTWARE_VERSION_FAILED,
        error: error,
    };
}

function requestUpdateCabinetInfoManually() {
    return {
        type: SEND_UPDATE_CABINET_SETTINGS_MANUALLY_COMMAND,
    };
}

export function receiveUpdateCabinetInfoManually(status) {
    return {
        type: RECEIVE_UPDATE_CABINET_SETTINGS_MANUALLY_COMMAND,
        status,
    };
}

/**
 * Request refill rule Redux action creator
 */
function requestRefillRule() {
    return {
        type: REQUEST_REFILL_RULE,
    };
}

/**
 * Receive locations Redux action creator
 * @param {object} refillRule
 */
function receiveRefillRule(refillRules) {
    return {
        type: RECEIVE_REFILL_RULE,
        refillRules,
        receivedAt: Date.now(),
    };
}

/**
 * Receive refill rule for a specific cabinet
 * @param {*} response
 * @returns
 */
function receiveCabinetRefillRule(response) {
    return {
        type: RECEIVE_DEVICE_REFILL_RULE,
        refillRule: response,
    };
}

function receiveCabinetRefillRuleError(error) {
    return {
        type: ERROR_DEVICE_REFILL_RULE,
        error: error,
    };
}

/**
 * Receive refill rule error Redux action creator
 * @param {Error} error
 */
function receiveRefillRuleError(error) {
    return {
        type: RECEIVE_REFILL_RULE_ERROR,
        error,
    };
}
/**
 * Request refill rule Redux action creator
 */
function requestRefillRuleCreate() {
    return {
        type: REQUEST_REFILL_CREATE_RULE,
    };
}
/**
 * Request refill rule Redux action creator
 */
function requestRefillRuleUpdate() {
    return {
        type: REQUEST_REFILL_UPDATE_RULE,
    };
}
/**
 * Request refill rule Redux action creator
 */
function requestRefillRuleDelete() {
    return {
        type: REQUEST_REFILL_DELETE_RULE,
    };
}

/**
 * Receive locations Redux action creator
 * @param {object} refillRule
 */
function createRefillRuleSuccess(refillRule) {
    return {
        type: RECEIVE_REFILL_RULE_CREATED,
        refillRule,
        receivedAt: Date.now(),
    };
}
/**
 * Receive locations Redux action creator
 * @param {object} refillRule
 */
function updateRefillRuleSuccess(refillRule) {
    return {
        type: RECEIVE_REFILL_RULE_UPDATED,
        refillRule: refillRule.productData,
        planogram:refillRule.planogram,
        planogramImageUrl:refillRule.planogramImageUrl || null,
        receivedAt: Date.now(),
    };
}

/**
 * Receive refill rule error Redux action creator
 * @param {Error} error
 */
function createRefillRuleError(error) {
    return {
        type: RECEIVE_REFILL_RULE_CREATED_ERROR,
        error,
    };
}
/**
 * Receive refill rule error Redux action creator
 * @param {Error} error
 */
function updateRefillRuleError(error) {
    return {
        type: RECEIVE_REFILL_RULE_UPDATED_ERROR,
        error,
    };
}
/**
 * Receive locations Redux action creator
 * @param {object} refillRule
 */
function deleteRefillRuleSuccess(ruleId) {
    return {
        type: RECEIVE_REFILL_RULE_DELETED,
        ruleId,
        receivedAt: Date.now(),
    };
}

/**
 * Receive refill rule error Redux action creator
 * @param {Error} error
 */
function deleteRefillRuleError(error) {
    return {
        type: RECEIVE_REFILL_RULE_DELETED_ERROR,
        error,
    };
}

export function fetchDeviceStatus(deviceCode) {
    return (dispatch, getState) => {
        dispatch(requestDeviceStatus());
        const accessKey = getState().user.accessToken;
        return fetchDeviceStatusHttpRequest(deviceCode, accessKey)
            .then(response => {
                if (response.type === 'encodingStation') {
                    dispatch(receiveEncodingDeviceStatus(response));
                } else if (response.type === 'selflyEncode') {
                    dispatch(receiveEncodingDeviceStatus(response));
                } else if (response.type === 'smartCabinet' || response.type === 'cabinet') {
                    dispatch(receiveCabinetStatus(response));
                }
            })
            .catch(error => dispatch(receiveCabinetStatusError(error)));
    };
}

export const sendCommandToAPI = (deviceCode, command, accessToken, resultHandler, params = {}) => {
    return sendCommand(deviceCode, command, params, accessToken)
        .then(() => {
            resultHandler(201);
        })
        .catch(error => {
            resultHandler(error);
        });
};

export function lockTheDoor(deviceCode) {
    return (dispatch, getState) => {
        dispatch(sendLockDoorCommand());
        return sendCommandToAPI(deviceCode, 'doorClose', getState().user.accessToken, statusCode => {
            dispatch(ReceiveLockDoorCommandStatus(statusCode));
        });
    };
}

export function rebootDevice(deviceCode) {
    return (dispatch, getState) => {
        dispatch(sendDeviceRebootCommand());
        return sendCommandToAPI(deviceCode, 'reboot', getState().user.accessToken, statusCode => {
            // also file socket io command for old cabinets sw version
            emitEventToSocketIO(`device/${deviceCode}/v2/command/device_reboot`);
            dispatch(ReceiveDeviceRebootCommandStatus());
        });
    };
}

export function unlockTheDoor(deviceCode, reason) {
    return (dispatch, getState) => {
        dispatch(sendUnlockDoorCommand());
        return sendCommandToAPI(
            deviceCode,
            'doorOpen',
            getState().user.accessToken,
            statusCode => {
                dispatch(ReceiveUnlockDoorCommandStatus(statusCode));
            },
            { reason }
        );
    };
}

/**
 * start and end the device maintenace mode
 */
export function startMaintenanceMode(deviceCode) {
    return (dispatch, getState) => {
        dispatch(sendStartMaintenanceModeCommand());
        const accessToken = getState().user.accessToken;
        return sendCommandToAPI(deviceCode, 'maintenanceStart', accessToken, statusCode => {
            dispatch(receiveStartMaintenanceCommand(statusCode));
        });
    };
}

export function endMaintenanceMode(deviceCode) {
    return (dispatch, getState) => {
        dispatch(sendEndMaintenanceModeCommand());
        const accessToken = getState().user.accessToken;
        return sendCommandToAPI(deviceCode, 'maintenanceEnd', accessToken, statusCode => {
            dispatch(receiveEndMaintenanceCommand(statusCode));
        });
    };
}

/**
 * Fetches the cabinet configuration from the database.
 */
export function fetchSelectedDeviceConfiguration() {
    return (dispatch, getState) => {
        const { selectedCabinetDeviceCode } = getState().cabinets;
        const { selectedEncodingDeviceCode } = getState().encodingDevices;
        const { accessToken } = getState().user;

        let selectedDeviceCode;

        if (selectedCabinetDeviceCode) selectedDeviceCode = selectedCabinetDeviceCode;
        else if (selectedEncodingDeviceCode) selectedDeviceCode = selectedEncodingDeviceCode;
        fetchDeviceConfiguration(selectedDeviceCode, accessToken)
            .then(settings => {
                dispatch({
                    type: 'SELECTED_CABINET_SETTINGS_LOADED',
                    settings,
                });
            })
            .catch(() => {
                dispatch({
                    type: 'SELECTED_CABINET_SETTINGS_LOAD_FAILED',
                });
            });
    };
}

/**
 * Update selected cabinet configuration from database
 */
export function updateSelectedCabinetConfiguration(settings) {
    return (dispatch, getState) => {
        const { selectedCabinetDeviceCode, cabinets } = getState().cabinets;
        const selectedCabinet = cabinets.find(cabinet => cabinet.deviceCode === selectedCabinetDeviceCode);
        const { accessToken } = getState().user;

        dispatch({
            type: 'SELECTED_CABINET_FETCHING_CABINET_SETTINGS',
        });

        updateCabinetSettings(selectedCabinet.id, accessToken, settings)
            .then(() => {
                sendCommand(selectedCabinetDeviceCode, 'notifyCabinetToFetchDeviceConfiguration', { value: true }, accessToken).catch(err =>
                    console.error(err)
                );

                dispatch({
                    type: 'SELECTED_CABINET_SETTINGS_UPDATED',
                });

                fetchDeviceConfiguration(selectedCabinetDeviceCode, accessToken)
                    .then(newSettings => {
                        dispatch({
                            type: 'SELECTED_CABINET_SETTINGS_LOADED',
                            settings: newSettings,
                        });
                    })
                    .catch(() => {
                        dispatch({
                            type: 'SELECTED_CABINET_SETTINGS_LOAD_FAILED',
                        });
                    });
            })
            .catch(() => {
                dispatch({
                    type: 'SELECTED_CABINET_SETTINGS_UPDATE_FAILED',
                });
            });
    };
}

/**
 * Copy settings to the selected cabinets in the database
 * @param {*} targetCabinets The selected cabinets to copy settings to
 * @param {*} settings The settings to be updated
 */
export function postSettingsCopyRequest(targetCabinets, settingType, settings) {
    return (dispatch, getState) => {
        const user = getState().user;
        const { accessToken, group } = user;

        return postSettingsCopyRequestHttp(targetCabinets, settingType, settings, group, accessToken)
            .then(response => {
                targetCabinets.forEach(cabinet => {
                    sendCommand(cabinet.deviceCode, 'notifyCabinetToFetchDeviceConfiguration', { value: true }, accessToken).catch(err => console.error(err));

                    fetchDeviceConfiguration(cabinet.deviceCode, accessToken)
                        .then(newSettings => {
                            dispatch({
                                type: 'SELECTED_CABINET_SETTINGS_LOADED',
                                settings: newSettings,
                            });
                        })
                        .catch(() => {
                            dispatch({
                                type: 'SELECTED_CABINET_SETTINGS_LOAD_FAILED',
                            });
                        });
                });

                return response;
            })
            .catch(err => {
                return err;
            });
    };
}

/**
 *
 * @param {*} detectTagQuality
 * @param {*} ignoreBadTags
 * @param {*} removeTagQualityData
 * @param {*} acceptablePercentageOfBadTags
 * @param {*} removeTagHistoryData
 * @param {*} removeReplenishmentData
 * @param {*} chargeSoldTags
 * @param {*} chargeUnreplenishedTags
 * @param {*} markAllTagsUnsold
 * @returns
 */
export function updateSelectedCabinetTagQualityMetrics(
    detectTagQuality,
    ignoreBadTags,
    removeTagQualityData,
    acceptablePercentageOfBadTags,
    removeTagHistoryData,
    removeReplenishmentData,
    chargeSoldTags,
    chargeUnreplenishedTags,
    markAllTagsUnsold,
    cabinetTagSensitivityLevel
) {
    return (dispatch, getState) => {
        const { selectedCabinetDeviceCode, selectedCabinetDeviceId } = getState().cabinets;
        const { accessToken } = getState().user;

        updateTagQualityMetrics(
            selectedCabinetDeviceId, // update by cabinetId since fetch cabinet is by cabinetId
            accessToken,
            detectTagQuality,
            ignoreBadTags,
            acceptablePercentageOfBadTags,
            chargeSoldTags,
            chargeUnreplenishedTags,
            cabinetTagSensitivityLevel
        )
            .then(() => {
                // send command asynchronously that the
                // tag detection qualities were changed
                // no need to wait for command to be succeeded as
                // backend of the cabinet will eventually fetch the settings
                // but to speed it up, send command
                // also it will store the audit record
                sendCommand(selectedCabinetDeviceCode, 'setDetectTagsQuality', { value: detectTagQuality }, accessToken).catch(err => console.error(err));
                sendCommand(selectedCabinetDeviceCode, 'setIgnoreBadTags', { value: ignoreBadTags }, accessToken).catch(err => console.error(err));
                if (removeTagQualityData !== null) {
                    sendCommand(selectedCabinetDeviceCode, 'removeTagQualityData', { value: true }, accessToken).catch(err => console.error(err));
                }
                if (removeTagHistoryData) {
                    sendCommand(selectedCabinetDeviceCode, 'removeTagHistoryData', { value: true }, accessToken).catch(err => console.error(err));
                }
                if (removeReplenishmentData) {
                    sendCommand(selectedCabinetDeviceCode, 'removeReplenishmentData', { value: true }, accessToken).catch(err => console.error(err));
                }

                sendCommand(selectedCabinetDeviceCode, 'chargeSoldTags', { value: chargeSoldTags }, accessToken).catch(err => console.error(err));

                if (markAllTagsUnsold) {
                    sendCommand(selectedCabinetDeviceCode, 'markAllTagsUnsold', { value: true }, accessToken).catch(err => console.error(err));
                }

                sendCommand(selectedCabinetDeviceCode, 'chargeUnreplenishedTags', { value: chargeUnreplenishedTags }, accessToken).catch(err =>
                    console.error(err)
                );

                sendCommand(selectedCabinetDeviceCode, 'notifyCabinetToFetchDeviceConfiguration', { value: true }, accessToken).catch(err =>
                    console.error(err)
                );
                if(cabinetTagSensitivityLevel!==null){
                    sendCommand(selectedCabinetDeviceCode, 'cabinetTagSensitivityLevel', {value:cabinetTagSensitivityLevel}, accessToken).catch(err=> console.error(err))
                }
                
                dispatch({
                    type: 'TAG_QUALITY_METRICS_UPDATED',
                    data: {
                        detectTagQuality,
                        ignoreBadTags,
                        acceptablePercentageOfBadTags,
                        chargeSoldTags,
                        chargeUnreplenishedTags,
                        markAllTagsUnsold,
                        cabinetTagSensitivityLevel
                    },
                });
            })
            .catch(() => {
                dispatch({
                    type: 'TAG_QUALITY_METRICS_UPDATE_FAILURE',
                });
            });
    };
}

/**
 *
 * @param {File} imageFile
 */
export function uploadLogoImage(imageFile) {
    return (dispatch, getState) => {
        // update state to inform API call started
        dispatch(requestUpdateLogoImage());

        // convert image file to HTTP form data with unique filename
        const formData = new FormData();
        formData.append(0, imageFile, `${uuidv1()}.jpeg`);

        // get user
        const user = getState().user;
        const accessKey = user.accessToken;
        // send the group Id
        const group = user.group;
        // store image in backend

        return createLogoImageHttpRequest(formData, accessKey, group)
            .then(response => dispatch(uploadLogoImageSuccess(encodeURI(response.url))))
            .catch(error => dispatch(uploadLogoImageError(error)));
    };
}

/**
 *
 */
export function fetchDeviceSoftwareVersion(deviceId) {
    return (dispatch, getState) => {
        dispatch(requestSoftwareVersion());
        const accessToken = getState().user.accessToken;
        return Promise.all([
            selectedDeviceSoftwareVersionHttpRequest(deviceId, accessToken),
            allSoftwareVersionsHttpRequest(accessToken),
            selectedDeviceTypeHttpRequest(deviceId, accessToken),
        ])
            .then(response => dispatch(requestSoftwareVersionSuccess(response)))
            .catch(error => requestSoftwareVersionFailed(error));
    };
}

export function updateDeviceSoftwareVersion(deviceId, version) {
    return (dispatch, getState) => {
        dispatch(requestSoftwareVersionUpdate());
        const accessToken = getState().user.accessToken;
        return updateDeviceSoftwareVersionHttpRequest(deviceId, version, accessToken)
            .then(response => dispatch(updateSoftwareVersionSuccess(response)))
            .catch(error => updateSoftwareVersionFailed(error));
    };
}

export function updateCabinetInfoManually(deviceCode) {
    return (dispatch, getState) => {
        dispatch(requestUpdateCabinetInfoManually());

        const accessToken = getState().user.accessToken;

        return sendCommandToAPI(deviceCode, 'updateCabinetInfoManually', accessToken, statusCode => {
            dispatch(receiveUpdateCabinetInfoManually(statusCode));
        });
    };
}

/**
 * Thunk action creator for fetching refill rule
 */
export function fetchRefillRules() {
    return (dispatch, getState) => {
        // update state to inform API call started
        dispatch(requestRefillRule());

        // fetch locations then update state
        const user = getState().user;
        return fetchRefillRuleHttpRequest(user.accessToken, user.group.Id)
            .then(response => {
                return dispatch(receiveRefillRule(response));
            })
            .catch(error => dispatch(receiveRefillRuleError(error)));
    };
}

/**
 * fetch a refill rule for a provided devicecode
 * @param {*} deviceCode
 * @returns
 */
export function fetchCabinetRefillRule(deviceCode) {
    return (dispatch, getState) => {
        dispatch({
            type: REQUEST_DEVICE_REFILL_RULE,
        });
        const user = getState().user;

        return fetchCabinetRefillRuleHttpRequest(user.accessToken, user.group.Id, deviceCode)
            .then(response => {
                return dispatch(receiveCabinetRefillRule(response));
            })
            .catch(error => {
                console.log(error);
                return dispatch(receiveCabinetRefillRuleError(error));
            });
    };
}
/**
 * Thunk action creator for fetching refill rule
 */
export function createRefillRule(data, deviceCode) {
    return (dispatch, getState) => {
        // update state to inform API call started
        dispatch(requestRefillRuleCreate());

        // fetch locations then update state
        const user = getState().user;
        return createRefillRuleHttpRequest(data, user.accessToken, user.group.Id, deviceCode)
            .then(response => {
                dispatch(createRefillRuleSuccess(response));
                dispatch({type: RESET_PLANOGRAM_IMAGE})
            })
            .catch(error => dispatch(createRefillRuleError(error)));
    };
}

/**
 * Thunk action creator for fetching refill rule
 */
export function updateRefillRule(data, ruleId, deviceCode) {
    return (dispatch, getState) => {
        // update state to inform API call started
        dispatch(requestRefillRuleUpdate());

        // fetch locations then update state
        const user = getState().user;
        return updateRefillRuleHttpRequest({ data, id: ruleId, deviceCode }, user.accessToken, user.group.Id)
            .then(response => {
                dispatch(updateRefillRuleSuccess(response.data));
                dispatch({type: RESET_PLANOGRAM_IMAGE})
            })
            .catch(error => dispatch(updateRefillRuleError(error)));
    };
}
/**
 * Thunk action creator for fetching refill rule
 */
export function deleteRefillRule(deviceCode) {
    return (dispatch, getState) => {
        // update state to inform API call started
        dispatch(requestRefillRuleDelete());

        // fetch locations then update state
        const user = getState().user;
        return deleteRefillRuleHttpRequest(deviceCode, user.accessToken, user.group.Id)
            .then(response => {
                dispatch(deleteRefillRuleSuccess(response?.id));
                dispatch({type: RESET_PLANOGRAM_IMAGE})
            })
            .catch(error => {
                console.log(error);
                return dispatch(deleteRefillRuleError(error));
            });
    };
}

/**
 *action for creating post purchase theme image
 * @param {File} imageFile
 */
 export function uploadPlanogramImage(imageFile) {
    return (dispatch, getState) => {
        // call state to notify uploa begins
        dispatch({type:'REQUEST_PLANOGRAM_IMAGE_UPLOADING'});
        // convert image file to form data and give unique id for image name
        const formData = new FormData();
        const fileName = `${uuidv1()}.jpeg`;
        formData.append(0, imageFile, fileName);

        const user = getState().user;

        return imageUploadHttpRequest(formData, user.accessToken, user.group.Id, 'planogram')
            .then(response => dispatch({
                type:'REQUEST_PLANOGRAM_IMAGE_UPLOADED',
                imageUrl:encodeURI(response.imgUrl)
            }))
            .catch(error => dispatch({
                type:'REQUEST_PLANOGRAM_IMAGE_UPLOAD_ERROR',
                error
            }));
    };
}

/**
 * Resets VAT categories state to initial state
 */
function resetRefillRules() {
    return {
        type: RESET_REFILL_RULES,
    };
}

export function refreshRefillRules() {
    return dispatch => {
        dispatch(resetRefillRules());
        dispatch(fetchRefillRules());
    };
}
