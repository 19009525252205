import React, { Component, Fragment } from 'react';
import ReactCrop from 'react-image-crop';
import { FormGroup, Label, Input, FormFeedback } from 'reactstrap';
import { Button, Spinner, RoundProgressIndicator } from '@storaensods/seeds-react';
import 'react-image-crop/dist/ReactCrop.css';
import './imageUpload.css';

import { v1 as uuidv1 } from 'uuid';

/**
 *
 * @param {number} targetWidth Width of target image after cropping
 * @param {number} targetHeight Height of target image after cropping
 * @param {boolean} storing State to know if image is being stored
 * @param {string} imageUrl State that holds image url after it has been uploaded to server
 * @param {function} uploadImage Action to upload image
 * @param {function} removeImage Action to remove current image
 * @param {function} toggleCropping Optional called when starts and ends cropping
 * @param {string} errorMsg Error message if form is submitted without image
 * @param {boolean} freeAspectRatio If the selection/crop area can be of any aspect ratio freely or not
 * @param {boolean} paddedAspectRatio This cannot be used if freeAspectRatio is set to true. It means the selection/crop
 * area can be of free aspect ratio, however the image will be padded with whitespace (transparent) to maintain the aspect
 * ratio for target image.
 */
export default class ImageUpload extends Component {
    constructor(props) {
        super(props);
        this.state = {
            imageUncropped: '',
            //check if the uploaded is video
            isVideo: false,
            videoUploading: false,
            // react-image-cropper init
            crop: {
                aspect: this.props.freeAspectRatio || this.props.paddedAspectRatio ? undefined : this.props.targetWidth / this.props.targetHeight,
                width: this.props.targetWidth,
                height: this.props.freeAspectRatio || this.props.paddedAspectRatio ? this.props.targetHeight : undefined,
                x: 0,
                y: 0,
            },
        };
    }

    componentDidUpdate(prevProps) {
        // set x and y to 0 when target width or height changed from outside of component
        // also update aspect ratio
        if (prevProps.targetWidth !== this.props.targetWidth || prevProps.targetHeight !== this.props.targetHeight) {
            this.setState(prevState => ({
                ...prevState,
                crop: {
                    ...prevState.crop,
                    aspect: this.props.freeAspectRatio || this.props.paddedAspectRatio ? undefined : this.props.targetWidth / this.props.targetHeight,
                    x: 0,
                    y: 0,
                },
            }));
        }
    }

    /**
     * Image is loaded to input or discarded
     * @param {Event} event HTML input event
     */
    handleInputImageChange = event => {
        // if file added to input
        if (event.target.files) {
            this.props.toggleCropping();
            const file = event.target.files[0];

            const mediaType = file.type.split('/')[0];

            if (mediaType !== 'image') {
                // Disabling other media type than image
                return;
            }

            // Legacy video support
            if (mediaType === 'video') {
                this.videoRef = URL.createObjectURL(file); //created blob url
                this.videoFile = file;
                this.setState({
                    isVideo: true,
                });
                return;
            }

            /**if it is an image */
            if (URL) {
                this.setState({
                    imageUncropped: URL.createObjectURL(file),
                });

                event.target.value = '';
            } else if (FileReader) {
                const reader = new FileReader();
                reader.onload = function() {
                    this.setState({
                        imageUncropped: reader.result,
                    });

                    event.target.value = '';
                };
                reader.readAsDataURL(file);
            } else {
                throw new Error("Can't read image because neither URL or FileReader loaded");
            }
        } else {
            if (URL) {
                URL.revokeObjectURL(this.state.imageUncropped);
            }
            this.setState({
                imageUncropped: '',
            });
        }
    };

    /**
     * ReactCrop component onImageLoaded handler to save ref to image
     * @param {Image} image Returned from ReactCrop component
     */
    onImageLoaded = image => {
        this.imageRef = image;
        if (!this.props.targetHeight || !this.props.targetWidth) {
            this.setState({
                pixelCrop: {
                    x: 0,
                    y: 0,
                    width: image.naturalWidth,
                    height: image.naturalHeight,
                },
            });
        }

        // 100 should be max width and height in crop state
        if ((this.state.crop.width > 100 || this.state.crop.height > 100) && !this.state.aspect) {
            this.setState(prevState => ({
                crop: {
                    ...prevState.crop,
                    x: 0,
                    y: 0,
                    width: 100,
                    height: 100,
                },
            }));
        }
    };

    /**
     * ReactCrop component onChange handler to update image crop state
     * @param {Crop} crop React-crop-image crop data
     */
    onCropChange = crop => {
        this.setState({ crop });
    };

    /**
     * ReactCrop component onComplete handler to update state crop pixel coordinates.
     * @param {Crop} crop Not used
     * @param {PixelCrop} pixelCrop React-image-crop crop pixel coordinates
     */
    onCropComplete = (crop, pixelCrop) => {
        this.setState({
            pixelCrop,
        });
    };

    /**
     * Discard uploaded image or image being cropped
     */
    cancelImage = () => {
        if (this.state.imageUncropped) {
            this.props.toggleCropping();
        }

        this.setState({
            imageUncropped: '',
            isVideo: false,
            videoRef: false,
        });

        this.props.removeImage();
    };

    /**
     * Creates new Jpeg image from cropped section of react-image-crop component and readies for upload.
     */
    async useImage() {
        if (!this.state.imageUncropped) {
            throw new Error('Cannot crop image because no image selected');
        }

        const croppedImage = await this.getCroppedImg(this.imageRef, this.state.pixelCrop, 'newFile.jpeg');

        this.props.uploadImage(croppedImage).then(() => this.props.toggleCropping());

        this.setState({
            imageUncropped: '',
        });
    }

    async useVideo() {
        this.setState({
            videoUploading: true,
            isVideo: true,
        });

        if (!this.state.isVideo) {
            throw new Error('No video selected.');
        }

        const file = await new Blob([this.videoFile], { type: 'video/mp4' });

        this.props.uploadImage(file, this.state.isVideo).then(() => {
            this.props.toggleCropping();
            this.setState({
                videoUploading: false,
            });
        });
    }

    /*
    async useMediaFile() {
        if (!this.state.isVideo && !this.state.imageUncropped) {
            throw new Error('No media file selected');
        }

        if (this.state.isVideo) {
            this.setState({
                videoUploading: true,
            });
        }

        let blobFile = null;

        if (this.state.isVideo) {
            blobFile = await new Blob([this.videoFile], { type: 'video/mp4' });
        } else {
            blobFile = await this.getCroppedImg(
                this.imageRef,
                this.state.pixelCrop,
                'newFile.jpeg'
            );
        }

        const uniqueID = uuidv1();
        const fileName = `${uuidv1()}${this.state.isVideo ? '.mp4' : '.jpeg'}`;

        this.props.toggleCropping();

        const { size, type } = blobFile;
        this.chuncksArray = [];
        let offset = 0
        const chunckSize = 512 * 1024
        if(size <=chunckSize){
       
            const slice = blobFile.slice(0, size, type);
            this.chuncksArray = [...this.chuncksArray, slice];
        }else{
            
            const completeQuotient = Math.ceil(size / (chunckSize))
            const remainder = size % (chunckSize)
            console.log('The complete quote', completeQuotient)
            console.log('The real remainer', remainder)
            console.log('The compelte file size', size)

            for(let i =0; i<completeQuotient; i++){
                offset = chunckSize * i
                const slice =  blobFile.slice(offset, offset + chunckSize, type);
                console.log(slice)
                this.chuncksArray = [...this.chuncksArray, slice]
            }
        }
   
        this.chunckID = 0;
        this.sendBlockChunck({
            fileName,
            size,
            chunckLength: this.chuncksArray.length,
            fileID: uniqueID,
        });

        this.props.toggleCropping();

        this.setState({
            imageUncropped: '',
        });
    }
    */

    async useAdvertisementMediaFile() {
        if (!this.state.isVideo && !this.state.imageUncropped) {
            throw new Error('No media file selected');
        }

        if (this.state.isVideo) {
            this.setState({
                videoUploading: true,
            });
        }

        let blobFile = null;

        if (this.state.isVideo) {
            blobFile = await new Blob([this.videoFile], { type: 'video/mp4' });
        } else {
            blobFile = await this.getCroppedImg(this.imageRef, this.state.pixelCrop, 'newFile.jpeg');
        }

        const fileName = `${uuidv1()}${this.state.isVideo ? '.mp4' : '.jpeg'}`;

        //this.props.toggleCropping();
        this.props
            .uploadImage(blobFile, fileName)
            .then(() => {
                this.props.toggleCropping();
                this.setState({
                    videoUploading: false,
                });
            })
            .catch(() => {
                console.error('upload interrupted..');
            });
    }

    /*
    sendBlockChunck = (fileInfo = {}) => {
        if (!this.chuncksArray.length) {
            console.log('Upload complete');
            this.props.toggleCropping();
            this.setState({
                videoUploading: false,
            });
            return;
        }

        this.chunckID = this.chunckID + 1;

        this.props
            .uploadMedia(this.chuncksArray[0], { ...fileInfo, chunckID: this.chunckID })
            .then(() => {
                this.chuncksArray.shift();
                this.sendBlockChunck({ ...fileInfo, chunckID: this.chunckID });
            })
            .catch(() => {
                console.error('upload interrupted. Reattempting..');
                this.sendBlockChunck({ ...fileInfo, chunckID: this.chunckID });
            });
    };
    */

    cancelVideo = () => {
        this.setState({
            isVideo: false,
        });
        this.videoFile = '';
    };

    /**
     * Creates new jpeg image from given image using given pixel crop and file name
     * @param {Image} image
     * @param {PixelCrop} pixelCrop React-crop-image component pixel crop data
     * @param {string} fileName File name of new image
     */
    getCroppedImg(image, pixelCrop, fileName) {
        // draw cropped image to canvas
        const canvas = document.createElement('canvas');
        if (!pixelCrop || !pixelCrop.width || !pixelCrop.height) {
            pixelCrop = { x: 0, y: 0, width: image.naturalWidth, height: image.naturalHeight };
        }
        canvas.width = this.props.freeAspectRatio ? pixelCrop.width : this.props.targetWidth || pixelCrop.width;
        canvas.height = this.props.freeAspectRatio ? pixelCrop.height : this.props.targetHeight || pixelCrop.height;
        const ctx = canvas.getContext('2d');
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        let paddingHeight = 0;
        let paddingWidth = 0;
        let newHeight = canvas.height;
        let newWidth = canvas.width;
        if (this.props.paddedAspectRatio) {
            // When the source image aspect ratio is free, but target image aspect ratio should be fixed,
            // we try to calculate the white-space (transparent) padding area.
            const sourceAspectRatio = pixelCrop.width / pixelCrop.height;
            const targetAspectRatio = canvas.width / canvas.height;
            if (sourceAspectRatio > targetAspectRatio) {
                // In this case, the selected area is wider than the desired aspect ratio.
                // So we try to add white-space (transparent) at the top and bottom parts
                newHeight = Math.floor((canvas.width / pixelCrop.width) * pixelCrop.height);
                paddingHeight = Math.floor((canvas.height - newHeight) / 2);
            } else {
                // In this case, the selected area is narrower than the desired aspect ratio.
                // So we try to add white-space (transparent) at the left and right parts
                newWidth = Math.floor((canvas.height / pixelCrop.height) * pixelCrop.width);
                paddingWidth = Math.floor((canvas.width - newWidth) / 2);
            }
        }
        ctx.drawImage(image, pixelCrop.x, pixelCrop.y, pixelCrop.width, pixelCrop.height, paddingWidth, paddingHeight, newWidth, newHeight);

        // convert canvas to jpeg blob and resolve with created url
        return new Promise((resolve, reject) => {
            canvas.toBlob(blob => {
                if (!blob) {
                    reject(new Error('Canvas is empty'));
                    console.error('Canvas is empty');
                    return;
                }
                blob.name = fileName;
                resolve(blob);
            }, 'image/png');
        });
    }

    render() {
        const { t } = this.props;

        if (this.props.imageUrl && !this.props.storing) {
            // image set and has been uploaded
            return (
                <div className="mb-2">
                    <div className="mb-2 d-flex justify-content-between">
                        <Button type="attention" icon="clear" onClick={this.cancelImage}>
                            {this.state.isVideo ? t('removeVideo') : t('removeImage')}
                        </Button>
                    </div>
                    <div className="d-flex justify-content-center">
                        {!this.state.isVideo && (
                            <img style={{ objectFit: 'contain', maxHeight: '500px', border: 'solid 1px lightgray' }} src={this.props.imageUrl} alt="none" />
                        )}
                        {this.state.isVideo && (
                            <video style={{ objectFit: 'contain', width: '50%', maxHeight: '500px' }} controls src={this.props.imageUrl}>
                                Video not supported in the browser
                            </video>
                        )}
                    </div>
                </div>
            );
        }

        if (this.state.isVideo && !this.props.imageUrl && !this.props.storing) {
            return (
                <div className="mb-2">
                    <div className="text-right">
                        <Button type="positive" icon="check" className="mr-2" onClick={this.useAdvertisementMediaFile.bind(this)}>
                            {t('use')}
                        </Button>
                        <Button type="attention" icon="clear" onClick={this.cancelVideo.bind(this)} className="mr-2">
                            {t('clear')}
                        </Button>
                    </div>
                    <div className="mb-2 d-flex justify-content-center">
                        <video src={this.videoRef} controls style={{ objectFit: 'contain', width: '50%', maxHeight: '500px' }}>
                            Video not supported in the browser
                        </video>
                    </div>
                </div>
            );
        }

        if (this.props.storing) {
            return (
                <div className="mb-2 d-flex justify-content-center" style={{ marginTop: '15px', marginBottom: '15px' }}>
                    {this.props.uploadProgress && (
                        <RoundProgressIndicator
                            color="green"
                            label={this.props.uploadProgress !== 100 ? t('uploading') : t('holdOn')}
                            mode="percentage"
                            step={this.props.uploadProgress}
                            steps={100}
                        />
                    )}
                    {!this.props.uploadProgress && (
                        <Fragment>
                            <Spinner />
                            <div>{t('uploading')}</div>
                        </Fragment>
                    )}
                    {/* <Spinner /> */}
                    {/*<div>{t('uploading')}</div>*/}
                </div>
            );
        }

        if (this.state.imageUncropped) {
            // cropping image
            return (
                <div>
                    <div className="mb-2 d-flex justify-content-between">
                        <h6>{t('image')}</h6>
                        <div className="text-right">
                            <button onClick={this.cancelImage} className="image-upload__clear" type="button">
                                {t('cancel')}
                            </button>
                            <button onClick={this.useAdvertisementMediaFile.bind(this)} className="image-upload__use" type="button">
                                {t('use')}
                            </button>
                        </div>
                    </div>
                    <div className="mb-2 d-flex justify-content-center">
                        <ReactCrop
                            key={this.state.crop.aspect} // needed so that cropping updates when aspect ratio changes
                            src={this.state.imageUncropped}
                            crop={this.state.crop}
                            onChange={this.onCropChange}
                            onImageLoaded={this.onImageLoaded}
                            onComplete={this.onCropComplete}
                        />
                    </div>
                </div>
            );
        }

        return (
            // image upload input if no image
            <FormGroup>
                <Label for="FormImageInput">{t('image')}</Label>
                <Input type="file" onChange={this.handleInputImageChange.bind(this)} name="FormImageInput" id="FormImageInput" accept="image/jpeg,image/png" />

                <FormFeedback>{this.props.errorMsg}</FormFeedback>
            </FormGroup>
        );
    }
}
