import React, { useEffect, useState, Fragment } from 'react';

import { FormGroup, Label, Input, InputGroup, InputGroupText } from 'reactstrap';
import ProductTabs from '../segments/productTabs';
import icons from './../icons.svg';

const AdditionalDetail = ({ suppliers, productDataChange, selectedProduct, tabNavigation, activeTab, t, isUpdating, submitProductToApi }) => {
    const [productData, setProductData] = useState(selectedProduct);

    useEffect(() => {
        setProductData(selectedProduct);
    }, [selectedProduct]);

    /**
     * whenever input field changes, update the state
     * @param {*} type
     * @param {*} value
     * @returns
     */
    const onProductDetailChange = (type, value) => {
        switch (type) {
            case 'isFrozen':
                setProductData(prevState => ({
                    ...prevState,
                    isFrozen: !prevState.isFrozen,
                }));
                break;
            case 'isPrebooked':
                setProductData(prevState => ({
                    ...prevState,
                    isPrebooked: !prevState.isPrebooked,
                }));
                break;
            case 'bannerText':
                if (value.length > 25) return;
                setProductData(prevState => ({
                    ...prevState,
                    bannerText: value,
                }));
                break;
            default:
                setProductData(prevState => ({
                    ...prevState,
                    [type]: value,
                }));
                break;
        }
    };

    /**
     * send the current state of product data to global state.
     * @param {*} index
     * @param {*} tabChange
     */
    const navigateToNextTab = (index, tabChange, submitData = false) => {
        const dataToSave = { ...productData };
        productDataChange(dataToSave);
        tabNavigation(index, tabChange, submitData);
    };

    return (
        <Fragment>
            <ProductTabs tabChange={navigateToNextTab} activeTab={activeTab} t={t} />
            <div className="row">
                {/**left side column */}
                <div className="col-md-6 products__layout-left">
                    <div className="products__group">
                        <h3 className="products__group-title">{t('productSupplier')}</h3>
                        <FormGroup>
                            <Label for="supplier">{t('supplier')}</Label>
                            <Input
                                id="supplier"
                                type="select"
                                value={productData.supplierId || ''}
                                onChange={e => onProductDetailChange('supplierId', e.target.value)}
                                disabled={!suppliers.length}
                            >
                                <option></option>
                                {suppliers
                                    .filter(supplier => supplier.status === 1)
                                    .map(supplier => {
                                        return (
                                            <option key={supplier.id} value={supplier.id}>
                                                {supplier.name}
                                            </option>
                                        );
                                    })}
                            </Input>
                        </FormGroup>
                    </div>

                    <div className="products__group">
                        <h3 className="products__group-title">{t('freezerPackage')}</h3>
                        <FormGroup>
                            <InputGroup>
                                <InputGroupText>
                                    <Input
                                        addon
                                        aria-label="Checkbox for following text input"
                                        type="checkbox"
                                        checked={productData.isFrozen}
                                        onChange={() => onProductDetailChange('isFrozen')}
                                        id="frozen"
                                    />
                                </InputGroupText>
                                <Label for="frozen" className="products__checkbox-text">
                                    {t('frozenProductLable')}
                                </Label>
                            </InputGroup>
                        </FormGroup>
                    </div>

                    <div className="products__group">
                        <h3 className="products__group-title">{t('prebooking')}</h3>
                        <FormGroup>
                            <InputGroup>
                                <InputGroupText>
                                    <Input
                                        addon
                                        aria-label="Checkbox for following text input"
                                        type="checkbox"
                                        checked={productData.isPrebooked}
                                        onChange={() => onProductDetailChange('isPrebooked')}
                                        id="prebooking"
                                    />
                                </InputGroupText>
                                <Label for="prebooking" className="products__checkbox-text">
                                    {t('prebookingLabel')}
                                </Label>
                            </InputGroup>
                        </FormGroup>
                    </div>
                </div>
                {/**right side column */}
                <div className="col-md-6">
                    <div className="products__group">
                        <h3 className="products__group-title">{t('productOnScreenBannerText')}</h3>
                        <FormGroup>
                            <Label for="bannertext">
                                {t('bannerText')} <span className="products__group-placeholder">( {t('max25Chars')} ) </span>{' '}
                            </Label>
                            <Input name="bannertext" value={productData.bannerText} onChange={e => onProductDetailChange('bannerText', e.target.value)} />
                            <div className="products__bannertext">
                                <div className="products__card">
                                    <svg className="products__banner-icon">
                                        <use xlinkHref={`${icons}#icon-cube`} />
                                    </svg>
                                    <div className="products__card-text">{t('productOnScreenBannerExampleText')}</div>
                                </div>
                                <p className="products__bannertext-info">{t('productOnScreenBannerAdditionalInfo')}</p>
                            </div>
                        </FormGroup>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <div className="products__cta-buttons">
                        <div className="products__cta-button products__cta-button--neutral" onClick={() => navigateToNextTab(-1)}>
                           ‹‹ {t('previous')}
                        </div>
                        {isUpdating && (
                            <div className="products__cta-button products__cta-button--success" onClick={() => navigateToNextTab(1, null, true)}>
                                {t('save')}
                            </div>
                        )}
                        <div className="products__cta-button products__cta-button--positive" onClick={() => navigateToNextTab(1)}>
                            {t('next')} ››
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default AdditionalDetail;
