import React, { Component } from 'react';
import Select from 'react-select';
import { Button, Dropdown } from '@storaensods/seeds-react';
import { Row, Col } from 'reactstrap';
import Toggle from 'react-toggle';
import { Tooltip } from 'react-tippy';
import { DashboardSummary } from './dashboardDataVisualization.js';

const dateRanges = [
    'All time',
    'Today',
    'Yesterday',
    'This week',
    'This month',
    'This quarter',
    'This year',
    'Last 7 days',
    'Last 30 days',
    'Last week',
    'Last month',
    'Last quarter',
    'Last year',
];

const customStylesSelector = {
    control: (provided, state) => ({
        ...provided,
        borderRadius: '0px',
        borderColor: state.isFocused ? '#4DA8E1' : '#9599a3',
        padding: '1px',
        boxShadow: 'none',
        '&:hover': {
            borderColor: '#4DA8E1',
        },
    }),
    menu: provided => ({
        ...provided,
        borderRadius: '0px',
        border: '1px solid #4DA8E1',
    }),
    option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isFocused ? '#F8F8F9' : '#FFFFFF',
        borderLeft: '1px solid #4DA8E1',
    }),
};

/**
 *
 * @param {array} regions All possible regions as strings
 * @param {array} cabinets All possible cabinets as objects with "id" and "name" keys
 * @param {boolean} fetching Is currently fetching dashboard data
 * @param {function} runQuery
 * @param {function} t translations
 * @param {string} initDateRange
 * @param {object} initFilter
 */
export default class DashboardSelector extends Component {
    constructor(props) {
        super(props);
        this.state = {
            group: 'all',
            dateRange: 'Last 7 days',
            selectedLocations: [],
            selectedCabinets: [],
            queryChanged: false,
        };
    }

    componentDidMount() {
        const { initDateRange, initFilter } = this.props;

        if (initDateRange) {
            const dateRange = initDateRange;
            if (initFilter) {
                const group = initFilter.filterBy;
                const values = initFilter.values;

                if (group === 'locations') {
                    this.setState(() => ({ dateRange, group, selectedLocations: values }));
                } else {
                    this.setState(() => ({ dateRange, group, selectedCabinets: values }));
                }
            } else {
                this.setState(() => ({ dateRange }));
            }
        }
    }

    setGroup = group => this.setState(() => ({ group, queryChanged: true }));

    setDateRange = dateRange => this.setState(() => ({ dateRange, queryChanged: true }));

    getChildLocations = (selectedLocation, childLocations) => {
        const { locations } = this.props;

        const children = locations.filter(location => location.parentLocationCode === selectedLocation.id);

        children.forEach(child => {
            childLocations.push({
                value: child.name,
                label: child.name,
                id: child.id,
            });
            this.getChildLocations(child, childLocations);
        });
    };

    onLocationsSelectorChange = selectedLocations => {
        const childLocations = [];
        if (selectedLocations) {
            selectedLocations.forEach(selectedLocation => {
                this.getChildLocations(selectedLocation, childLocations);
            });

            // Combine the selection with the children found and ensures duplicate children do not get added
            selectedLocations = [...new Set([...selectedLocations, ...childLocations])];
        }

        this.setState(() => ({
            selectedLocations: selectedLocations ? selectedLocations.map(location => location.value) : [],
            queryChanged: true,
        }));
    };

    onCabinetsSelectorChange = selectedCabinets =>
        this.setState(() => ({
            selectedCabinets: selectedCabinets
                ? selectedCabinets.map(cabinet => ({
                      deviceCode: cabinet.value,
                      name: cabinet.label,
                  }))
                : [],
            queryChanged: true,
        }));

    runQuery = () => {
        const { group, dateRange, selectedLocations, selectedCabinets } = this.state;
        const { runQuery } = this.props;

        if (group === 'all') {
            runQuery(dateRange);
        } else if (group === 'locations') {
            runQuery(dateRange, { filterBy: 'locations', values: selectedLocations });
        } else if (group === 'cabinets') {
            runQuery(dateRange, { filterBy: 'cabinets', values: selectedCabinets });
        }
        this.setState(() => ({
            queryChanged: false,
        }));
    };

    queryReady = () => {
        const { group, selectedLocations, selectedCabinets, queryChanged } = this.state;

        if (!queryChanged) return false;

        if (group === 'locations' && selectedLocations.length === 0) {
            return false;
        } else if (group === 'cabinets' && selectedCabinets.length === 0) {
            return false;
        }

        return true;
    };

    render() {
        const { group, dateRange, selectedLocations, selectedCabinets } = this.state;
        const { t, locations, cabinets, fetching, changePanel, initFilter, initDateRange } = this.props;

        return (
            <div>
                <Row>
                    <Col xs="12" md="8" lg="9">
                        <div className="d-inline-block mr-3">
                            <div className="mb-1 small">{t('dateRange')}</div>
                            <Dropdown
                                className="analytics-dropdown"
                                value={t(dateRange)}
                                onSelect={({ value }) => this.setDateRange(value)}
                                options={dateRanges.map(dateRange => ({
                                    label: t(dateRange),
                                    value: dateRange,
                                }))}
                            />
                        </div>
                        <div className="d-inline-block mr-3">
                            <div className="mb-1 small">{t('group')}</div>
                            <Dropdown
                                className="analytics-dropdown"
                                onSelect={({ value }) => this.setGroup(value)}
                                value={t(group)}
                                options={[
                                    {
                                        value: 'all',
                                        label: t('all'),
                                    },
                                    {
                                        value: 'locations',
                                        label: t('locations'),
                                    },
                                    {
                                        value: 'cabinets',
                                        label: t('cabinets'),
                                    },
                                ]}
                            />
                        </div>
                        {group === 'locations' ? (
                            <div className="d-inline-block mr-3" style={{ width: '100%', maxWidth: '500px' }}>
                                <div className="mb-1 small">{t('locations')}</div>
                                <Tooltip theme="light" title={t('locationsSelectHelp')} position="top" trigger="mouseenter">
                                    <Select
                                        className="analytics-selector"
                                        value={selectedLocations.map(location => ({
                                            value: location,
                                            label: location,
                                            id: location.id,
                                        }))}
                                        options={locations.map(location => ({
                                            value: location.name,
                                            label: location.name,
                                            id: location.id,
                                        }))}
                                        onChange={this.onLocationsSelectorChange.bind(this)}
                                        isMulti
                                        styles={customStylesSelector}
                                    />
                                </Tooltip>
                            </div>
                        ) : null}
                        {group === 'cabinets' ? (
                            <div className="d-inline-block mr-3" style={{ width: '100%', maxWidth: '500px' }}>
                                <div className="mb-1 small">{t('cabinets')}</div>
                                <Select
                                    className="analytics-selector"
                                    defaultValue={selectedCabinets.map(cabinet => ({
                                        value: cabinet.deviceCode,
                                        label: cabinet.name,
                                    }))}
                                    options={cabinets.map(cabinet => ({
                                        value: cabinet.deviceCode,
                                        label: cabinet.name,
                                    }))}
                                    onChange={this.onCabinetsSelectorChange.bind(this)}
                                    isMulti
                                    styles={customStylesSelector}
                                />
                            </div>
                        ) : null}
                        <div className="d-inline-block mr-3 align-bottom" style={{ marginTop: '29px' }}>
                            <Button
                                className="se-analytics-button-attention"
                                disabled={fetching || !this.queryReady()}
                                onClick={this.runQuery.bind(this)}
                                icon="refresh"
                            >
                                {t('updateData')}
                            </Button>
                        </div>
                    </Col>
                    <Col xs="12" md="4" lg="3">
                        <div className="float-right mr-2">
                            <div className="mb-2 mt-2 small">{t('viewMode')}</div>
                            <div className="d-inline-block">
                                <h6>{t('dashboard')}</h6>
                            </div>
                            <Toggle
                                className="d-inline-block ml-3 mr-3 dashboard-toggle"
                                checked={false}
                                icons={false}
                                onClick={changePanel.bind(this, 'customQuery')}
                                readOnly
                            />
                            <div className="d-inline-block">
                                <h6 style={{ color: '#a0a1a5' }}>{t('reportsTitle')}</h6>
                            </div>
                        </div>
                    </Col>
                </Row>
                {<DashboardSummary disabled={!fetching && this.queryReady()} t={t} filter={initFilter} dateRange={initDateRange} />}
            </div>
        );
    }
}
