import React, { useState, useEffect, Fragment } from 'react';
import { FormGroup, Label, Input, FormText, InputGroup, InputGroupText, ModalBody, Modal, FormFeedback, ModalHeader, ModalFooter } from 'reactstrap';
import { checkdigit } from 'gs1';

import ImageUpload from '../../imageUpload/imageUpload';
import ProductTabs from '../segments/productTabs';
import { barcodeTypes } from '../config';

const currenciesAll = require('../../../commonCurrency.json');
const currencies = JSON.parse(process.env.REACT_APP_CURRENCIES).map(code => currenciesAll[code]);
if (!currencies || currencies.length === 0) {
    console.error('Missing currencies in REACT_APP_CURRENCIES configuration!');
    throw new Error('Missing currencies in REACT_APP_CURRENCIES configuration!');
}

const BasicDetail = ({
    productCategories,
    cabinets,
    vatCategories,
    productDataChange,
    selectedProduct,
    tabNavigation,
    mode,
    uploadProductImage,
    t,
    reduxProduct,
    validationErrors,
    history,
    activeTab,
    submitProductToApi,
    updateValidationError,
    isUpdating,
}) => {
    const [productData, setProductData] = useState(selectedProduct);
    const [isExpiryProduct, setIsExpiryProduct] = useState(false);
    const [toggleCropping, setToggleCropping] = useState(false);
    const [productModelOpen, setProductImageModalOpen] = useState(false);
    const [locationPricesAccordion, setLocationPricesAccordion] = useState(false);
    const [addLocationForPrice, setAddLocationForPrice] = useState(undefined);
    const [errors, setErrors] = useState(validationErrors);
    const [productImageChange, setProductImageChange] = useState(false);

    useEffect(() => {
        //make sure page always scrolls to top when tab changes
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        setProductData(selectedProduct);
        setIsExpiryProduct(selectedProduct.timeToLiveDays || selectedProduct.timeToLiveDays === 0 ? true : false);
    }, [selectedProduct]);

    useEffect(() => {
        if (!productImageChange) return;
        setProductData(prevState => ({
            ...prevState,
            imageUrl: reduxProduct.imageUrl,
        }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reduxProduct.imageUrl]);

    useEffect(()=>{
        setErrors(validationErrors)
        submitProductToApi(false)
         // eslint-disable-next-line react-hooks/exhaustive-deps
    },[validationErrors])

    /**
     * update state whenever input fields change
     * @param {*} inputType
     * @param {*} value
     * @returns
     */
    const onProductDetailChange = (inputType, value) => {
        switch (inputType) {
            case 'name':
                if (value.length > 125) return;
                setProductData(prevState => ({
                    ...prevState,
                    name: value,
                }));
                break;
            case 'barcode':
                const checkSum = checkdigit(value);
                setProductData(prevState => ({
                    ...prevState,
                    barcode: value,
                    barcodeCheckSum: checkSum,
                }));
                break;
            case 'price':
                let price = value;
                if (value < 0) price = 0;
                setProductData(prevState => ({
                    ...prevState,
                    price,
                }));
                break;
            case 'timeToLiveDays':
                let days = value;
                if (value < 0) days = 0;
                setProductData(prevState => ({
                    ...prevState,
                    timeToLiveDays: days,
                }));
                break;
            default:
                setProductData(prevState => ({
                    ...prevState,
                    [inputType]: value,
                }));
                break;
        }

        setErrors(prevState => ({
            ...prevState,
            [inputType]: '',
        }));
    };



    const navigateToNextTab = (index, tabChange, submitData = false) => {
        const dataToSave = { ...productData };
        if (!isExpiryProduct) {
            dataToSave.timeToLiveDays = '';
        }

        if (isExpiryProduct && !dataToSave.timeToLiveDays) {
            dataToSave.timeToLiveDays = '';
        }

        //send the data to save to the main component
        productDataChange(dataToSave);
        updateValidationError(errors);
        if(submitData){
            submitProductToApi(true)
        }else{
            tabNavigation(index, tabChange, false);
        }
        
    };

    /**
     *
     * @returns DOM for image upload modal
     */
    const imageUploadEditModel = () => {
        return (
            <Modal isOpen={true} centered={true} size="lg" className="theming-imageModal">
                <ModalHeader>Product Image</ModalHeader>
                <ModalBody>
                    <ImageUpload
                        className="se-input-container"
                        freeAspectRatio={false}
                        targetWidth={180}
                        targetHeight={220}
                        imageUrl={productData.imageUrl}
                        storing={reduxProduct.imageStoring}
                        uploadImage={img => {
                            setProductImageChange(true);
                            return uploadProductImage(img);
                        }}
                        removeImage={() => setProductData(prevState => ({ ...prevState, imageUrl: undefined }))}
                        toggleCropping={() => setToggleCropping(!toggleCropping)}
                        paddedAspectRatio={true}
                        t={t}
                    />
                </ModalBody>
                <ModalFooter>
                    <div className="products__cta-button products__cta-button--neutral" onClick={() => setProductImageModalOpen(false)}>
                        {t('close')}
                    </div>
                </ModalFooter>
            </Modal>
        );
    };

    /**
     * When user selects a location and click Add, then a new list will be added to the existing pricins list
     * @returns
     */
    const addNewLocationPrice = value => {
        if (!addLocationForPrice) return;

        setProductData(prevState => {
            return {
                ...prevState,
                locationPricings: [
                    ...prevState.locationPricings,
                    {
                        price: prevState.price,
                        currency: prevState.currency,
                        locationId: addLocationForPrice,
                    },
                ],
            };
        });

        setAddLocationForPrice(undefined);
    };

    /**
     * Removes the price list frrom the loaction pricisng data
     * @param {*} locationId
     * @returns
     */
    const removeLocationPrice = locationId => {
        if (!locationId) return;

        const prices = [...productData.locationPricings];
        const newPrices = prices.filter(price => price.locationId !== locationId);

        setProductData(prevState => ({
            ...prevState,
            locationPricings: newPrices,
        }));
    };

    /**
     * updates changes of the location prices and currency.
     * @param {*} locationId
     * @param {*} type
     * @param {*} value
     */
    const onLocationPriceChange = (locationId, type, value) => {
        const prices = [...productData.locationPricings];
        const locationPrice = prices.find(price => price.locationId === locationId);
        if (!locationPrice) return;

        switch (type) {
            case 'price':
                let price = value;
                // price can't be negative
                if (price < 0) price = 0;
                setProductData(prevState => ({
                    ...prevState,
                    locationPricings: prices.map(p => {
                        if (p.locationId === locationId) {
                            return {
                                ...p,
                                price: price,
                            };
                        }
                        return p;
                    }),
                }));
                break;
            case 'currency':
                let currency = value;
                if (!currency) currency = productData.currency;
                setProductData(prevState => ({
                    ...prevState,
                    locationPricings: prices.map(p => {
                        if (p.locationId === locationId) {
                            return {
                                ...p,
                                currency: value,
                            };
                        }
                        return p;
                    }),
                }));
                break;
            default:
                break;
        }
    };

    /**
     *
     * @returns list of all the location prisings
     */
    const getLocationPricings = () => {
        const allLocations = cabinets.locations ? cabinets.locations : [];
        const locationPricings = productData.locationPricings ? productData.locationPricings : [];

        // filter for the location price where location id had not been removed.
        return locationPricings.map(locPrice => {
            return (
                <div className="row" key={locPrice.locationId}>
                    <FormGroup className="col-md-6">
                        <Label for="lcoation">{}</Label>
                        <Input id="lcoation" name="lcoation" type="text" disabled value={allLocations.find(l => l.id === locPrice.locationId)?.name} />
                    </FormGroup>
                    <FormGroup className="col-md-2">
                        <Label for="price">{}</Label>
                        <Input
                            id="locationPrice"
                            name="price"
                            type="number"
                            value={locPrice.price}
                            onChange={e => onLocationPriceChange(locPrice.locationId, 'price', e.target.value)}
                        />
                    </FormGroup>
                    <FormGroup className="col-md-3">
                        <Label for="locationCurrency">{}</Label>
                        <Input
                            id="locationCurrency"
                            name="locationCurrency"
                            type="select"
                            value={currencies.find(currency => currency.symbol === locPrice.currency)?.symbol}
                            onChange={e => onLocationPriceChange(locPrice.locationId, 'currency', e.target.value)}
                        >
                            {currencies.map(currency => (
                                <option key={currency.code + currency.symbol} value={currency.symbol}>
                                    {currency.name} ({currency.symbol})
                                </option>
                            ))}
                        </Input>
                    </FormGroup>
                    <FormGroup className="col-md-1 products__prices-btn">
                        <Label></Label>
                        <button type="button" onClick={() => removeLocationPrice(locPrice.locationId)}>
                            <i className="fas fa-trash"></i>
                        </button>
                    </FormGroup>
                </div>
            );
        });
    };

    /**
     *  if there is any validation errors, show the errors
     * @returns DOM to visualize warnings
     */
    const  getValidationWarningView = () => {
        const { name, price, timeToLiveDays } = errors;
        if (name || price || timeToLiveDays) {
            return (
                <div className="products__errors">
                    <i className="fas fa-exclamation-circle"></i> <span>{t('productValidationError')} </span>
                </div>
            );
        }
    };

    return (
        <Fragment>
            {productModelOpen && imageUploadEditModel()}
            <ProductTabs tabChange={navigateToNextTab} activeTab={activeTab} t={t} />
            <div className="row">
                {/**left side column */}
                <div className="col-md-6 products__layout-left">
                    {getValidationWarningView()}
                    <div className="products__group">
                        <h3 className="products__group-title">{t('productDetails')}</h3>
                        <FormGroup>
                            <Label for="name">*{t('name')}</Label>
                            <Input
                                id="name"
                                onChange={e => onProductDetailChange('name', e.target.value)}
                                value={productData.name}
                                invalid={errors.name ? true : false}
                            />
                            {errors.name && <FormFeedback>{t('invalidProductName')}.</FormFeedback>}
                        </FormGroup>

                        <div className="row">
                            <FormGroup className="col-md-4">
                                <Label for="barcodeType">*{t('barcodeType')}</Label>
                                <Input
                                    id="barcodeType"
                                    type="select"
                                    disabled={mode === 'edit'}
                                    onChange={e => onProductDetailChange('barcodeType', e.target.value)}
                                    value={productData.barcodeType}
                                >
                                    {barcodeTypes.map(barcodeType => {
                                        return (
                                            <option value={barcodeType.value} key={barcodeType.value}>
                                                {barcodeType.title}
                                            </option>
                                        );
                                    })}
                                </Input>
                            </FormGroup>
                            <FormGroup className="col-md-8">
                                <Label for="barcode">*{t('barcode')}</Label>
                                <Input
                                    id="barcode"
                                    disabled={mode === 'edit'}
                                    value={productData.barcode || ''}
                                    onChange={e => onProductDetailChange('barcode', e.target.value)}
                                    invalid={errors.barcode ? true : false}
                                    maxLength={barcodeTypes.find(type => type.value === productData.barcodeType).barcodeLength - 1}
                                />
                                {!productData.barcode && (
                                    <FormText className="products__helptext">
                                        {t(barcodeTypes.find(type => type.value === productData.barcodeType)?.helpText)}
                                    </FormText>
                                )}
                                {productData.barcode && (
                                    <p>
                                        <i className="fas fa-barcode" /> {productData.barcode}
                                        <span className="products__checksum">{productData.barcodeCheckSum}</span>
                                    </p>
                                )}
                                {errors.barcode && <FormFeedback>{t('invalidProductBarcode')}</FormFeedback>}
                            </FormGroup>
                        </div>
                    </div>

                    <div className="products__group">
                        <h3 className="products__group-title">{t('price')}</h3>
                        <div className="row products__group-price">
                            <FormGroup className="col-md-8">
                                <Label for="price">*{t('standardPrice')}</Label>
                                <Input
                                    id="price"
                                    type="number"
                                    min="0"
                                    value={productData.price || ''}
                                    onChange={e => onProductDetailChange('price', e.target.value)}
                                />
                            </FormGroup>
                            <FormGroup className="col-md-4">
                                <Label for="currency">*{t('currency')}</Label>
                                <Input
                                    id="currency"
                                    type="select"
                                    value={currencies.find(c => c.symbol === productData.currency)?.symbol}
                                    onChange={e => onProductDetailChange('currency', e.target.value)}
                                >
                                    {currencies.map(currency => (
                                        <option key={currency.code} value={currency.symbol}>
                                            {currency.name} ({currency.symbol})
                                        </option>
                                    ))}
                                </Input>
                            </FormGroup>
                        </div>

                        <div className="row">
                            <div className="col">
                                <div className="products__prices-accordion" onClick={() => setLocationPricesAccordion(!locationPricesAccordion)}>
                                    <div className="products__prices-count">
                                        {t('locationPrices')}
                                        <span>{productData.locationPricings.length}</span>
                                    </div>
                                    <div className="products__prices-accordion-sign">{locationPricesAccordion ? '-' : '+'}</div>
                                </div>
                                <div className={`products__prices ${locationPricesAccordion ? 'products__prices--open' : ''}`}>
                                    {getLocationPricings()}
                                    <div className="row">
                                        <div className="col">
                                            <hr />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <FormGroup className="col-md-9">
                                            <Label for="location">{t('addNewLocation')}</Label>
                                            <Input
                                                id="location"
                                                value={addLocationForPrice}
                                                type="select"
                                                onChange={e => setAddLocationForPrice(e.target.value)}
                                            >
                                                <option></option>
                                                {cabinets.locations
                                                    .filter(location => !productData.locationPricings.find(price => price.locationId === location.id))
                                                    .map(location => (
                                                        <option value={location.id} name="locationPrice" key={location.id}>
                                                            {' '}
                                                            {location.name}{' '}
                                                        </option>
                                                    ))}
                                            </Input>
                                        </FormGroup>
                                        <FormGroup className="col-md-3">
                                            <Label>{}</Label>
                                            <button
                                                className={`products__prices-add ${addLocationForPrice ? '' : 'products__prices-add--disabled'}`}
                                                type="button"
                                                onClick={addNewLocationPrice}
                                            >
                                                {t('addNew')}
                                            </button>
                                        </FormGroup>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="products__group">
                        <h3 className="products__group-title">{t('productCategoryItems')}</h3>
                        <div className="row">
                            <FormGroup className="col">
                                <Label for="name">{t('productCategory')}</Label>
                                <Input
                                    id="productCategory"
                                    type="select"
                                    value={productData.productCategoryId || ''}
                                    onChange={e => onProductDetailChange('productCategoryId', e.target.value)}
                                    disabled={!productCategories.length}
                                >
                                    <option></option>
                                    {productCategories.map(category => {
                                        return (
                                            <option value={category.id} key={category.id}>
                                                {category.name}
                                            </option>
                                        );
                                    })}
                                </Input>
                            </FormGroup>
                        </div>
                        <div className="row">
                            <FormGroup className="col">
                                <Label for="vat">*{t('vatCategory')}</Label>
                                <Input
                                    id="barvatcodeType"
                                    type="select"
                                    value={productData.vatCategoryId}
                                    onChange={e => onProductDetailChange('vatCategoryId', e.target.value)}
                                    disabled={!vatCategories.length}
                                >
                                    {vatCategories.map(vat => {
                                        return (
                                            <option value={vat.id} key={vat.id}>
                                                {vat.name + ' (' + vat.taxValue + '%)'}
                                            </option>
                                        );
                                    })}
                                </Input>
                            </FormGroup>
                        </div>
                    </div>

                    <div className="products__group">
                        <h3 className="products__group-title">{t('productExpiry')}</h3>
                        <div className="row">
                            <FormGroup className="col">
                                <InputGroup>
                                    <InputGroupText>
                                        <Input
                                            addon
                                            aria-label="Checkbox for following text input"
                                            type="checkbox"
                                            checked={isExpiryProduct || false}
                                            onChange={e => setIsExpiryProduct(!isExpiryProduct)}
                                        />
                                    </InputGroupText>
                                    <Label for="name" className="products__checkbox-text">
                                        {t('freshFood')}
                                    </Label>
                                </InputGroup>
                            </FormGroup>
                        </div>
                        {isExpiryProduct && (
                            <div className="row">
                                <FormGroup className="col">
                                    <Label for="ttl">{t('productLifeTime')}</Label>
                                    <Input
                                        type="number"
                                        id="ttl"
                                        value={productData.timeToLiveDays}
                                        onChange={e => onProductDetailChange('timeToLiveDays', e.target.value)}
                                        invalid={errors.timeToLiveDays ? true : false}
                                    />
                                    <FormText className="products__helptext">{t('lifeTimeExplanation')}</FormText>
                                    {errors.timeToLiveDays && <FormFeedback>{t('invalidTTL')}</FormFeedback>}
                                </FormGroup>
                            </div>
                        )}
                    </div>
                </div>

                {/**right side column */}
                <div className="col-md-6 products__layout-right">
                    <div className="products__group">
                        <h3 className="products__group-title">{t('productImage')}</h3>
                        <div className="row">
                            {productData.imageUrl && (
                                <FormGroup className="col">
                                    <div className="products__imageUrl">
                                        <img src={productData.imageUrl} alt="" />
                                        <span onClick={() => setProductImageModalOpen(true)}>{t('edit')}</span>
                                    </div>
                                </FormGroup>
                            )}
                            {!productData.imageUrl && (
                                <FormGroup className="col">
                                    <Label>{t('pleaseUploadImage')}</Label>
                                    <div className="products__image">
                                        <div className="products__image-label">180 X 220</div>
                                        <div className="products__image-btn" onClick={() => setProductImageModalOpen(true)}>
                                            {t('uploadImage')}
                                        </div>
                                    </div>
                                </FormGroup>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <div className="products__cta-buttons">
                        <div className="products__cta-button products__cta-button--neutral" onClick={() => history.push('/products')}>
                            {t('cancel')}
                        </div>
                        {isUpdating && (
                            <div className="products__cta-button products__cta-button--success" onClick={() => navigateToNextTab(1, null, true)}>
                                {t('save')}
                            </div>
                        )}
                        <div className="products__cta-button products__cta-button--positive" onClick={() => navigateToNextTab(1)}>
                            {t('next')} ››
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default BasicDetail;
