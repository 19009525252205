import React, { useEffect, Fragment, useState } from 'react';
import { forEach, groupBy, set } from 'lodash';

import icons from './../../../icons/icomoon.svg';
import InfoBanner from './InfoBanner';
import { Checkbox } from '@storaensods/seeds-react';
import { getHelperTitleText } from '../utils';

const AddRefillPlans = ({
    allActiveRefillPlans,
    allRefillSchemas,
    locations,
    cabinets,
    refillPlans,
    generateRefillPlanSuggestions,
    cancelAddNewNewPlans,
    t,
    isAdmin,
}) => {
    const [cabinetsBylocations, setCabinetsByLocations] = useState({});
    const [activeLocation, setActiveLocation] = useState();
    const [selectedCabinets, setSelectedCabinets] = useState({});
    const [cabinetSearchQuary, setCabinetSearchQuery] = useState('');

    useEffect(() => {
        //filter out the cabinets/refillschemas that do not have active plans yet.
        const activePlansCabinetCodes = allActiveRefillPlans.map(plan => plan.deviceCode);
        const schemas = allRefillSchemas.refillRules || [];
        const deviceCodesWithoutPlans = schemas.filter(schema => !activePlansCabinetCodes.includes(schema.deviceCode)).map(schema => schema.deviceCode);

        //map the deviceCodes to location name
        const cabinetsWithoutPlans = cabinets.filter(cabinet => deviceCodesWithoutPlans.includes(cabinet.deviceCode));
        const groupCabinetsByLocations = groupBy(cabinetsWithoutPlans, 'locationId') || {};
        const locationKeys = Object.keys(groupCabinetsByLocations) || [];

        let locationNameMapping = {};

        locationKeys.forEach(locationKey => {
            if (locationKey === 'null') {
                locationNameMapping = { ...locationNameMapping, noLocation: groupCabinetsByLocations[locationKey] };
            } else {
                const locationName = locations.find(location => location.id === locationKey)?.name;
                if (locationName) {
                    locationNameMapping = { ...locationNameMapping, [locationName]: groupCabinetsByLocations[locationKey] };
                }
            }
        });

        setCabinetsByLocations(locationNameMapping);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [allActiveRefillPlans, allRefillSchemas]);

    const onLocationTabClicked = location => {
        if (location === activeLocation) {
            setActiveLocation(null);
            return;
        }
        setActiveLocation(location);
    };

    /**
     *
     * @param {boolean} checked
     * @param {stinng} location name
     * @param {object} cabinet if the cabinet checkbox is choosen
     */
    const onCheckBoxChange = (checked, location, cabinet) => {
        let selectedDevicesByLocations = { ...selectedCabinets };
        //if cabinet is not provided, its the location checkbox clicked
        //otherwise its the cabinet checkbox clicked
        if (!cabinet) {
            if (checked) {
                selectedDevicesByLocations = { ...selectedDevicesByLocations, [location]: cabinetsBylocations[location] };
            } else {
                delete selectedDevicesByLocations[location];
            }
        } else {
            const cabinetLocation = selectedDevicesByLocations[location];

            //if cabinet is not selected earlier, put it under the given location
            if (!cabinetLocation) {
                selectedDevicesByLocations = { ...selectedDevicesByLocations, [location]: [cabinet] };
            } else {
                if (checked) {
                    selectedDevicesByLocations = {
                        ...selectedDevicesByLocations,
                        [location]: [...selectedDevicesByLocations[location].filter(device => device.deviceCode !== cabinet.deviceCode), cabinet],
                    };
                } else {
                    selectedDevicesByLocations = {
                        ...selectedDevicesByLocations,
                        [location]: selectedDevicesByLocations[location].filter(device => device.deviceCode !== cabinet.deviceCode),
                    };
                }
            }
        }

        // if there is no cabinet in a location, remove it from the state
        if (selectedDevicesByLocations[location] && !selectedDevicesByLocations[location].length) {
            delete selectedDevicesByLocations[location];
        }

        setSelectedCabinets(selectedDevicesByLocations);
    };

    /**
     *
     * @param {string} location name
     * @param {object} cabinet cabinet that has checkbox checked
     * @returns boolean, weather device/location is checked or not
     */
    const isChecked = (location, cabinet) => {
        const selected = selectedCabinets[location];
        if (!selected) return false;
        //if no cabinet provided, the request is for location selection
        // otherwise its the cabinet selection
        if (!cabinet) {
            if (selected.length === cabinetsBylocations[location].length) return true;
        } else {
            if (selected.find(device => device.deviceCode === cabinet.deviceCode)) return true;
        }
        return false;
    };

    /**
     * when select all checkbox is checked or unchecked.
     * @param {*boolean} toggle
     */
    const onSelectAllToggle = toggle => {
        if (toggle) {
            setSelectedCabinets(cabinetsBylocations);
        } else {
            setSelectedCabinets({});
        }
    };

    /**
     * how many cabinets are selected for generating refill plan
     * @returns interger
     */
    const getCabinetsCount = () => {
        let count = 0;
        Object.keys(selectedCabinets).forEach(location => {
            count = count + selectedCabinets[location].length;
        });

        return count;
    };

    const apiRequestToGenerateRefillPlans = () => {
        const locationKeys = Object.keys(selectedCabinets);
        let deviceCodes = [];
        locationKeys.forEach(location => {
            selectedCabinets[location].forEach(cabinet => {
                deviceCodes.push(cabinet.deviceCode);
            });
        });

        if (!deviceCodes.length) return;

        generateRefillPlanSuggestions(deviceCodes);
    };

    //if there is no cabinet with refill schema, show the banner messsage
    if (!Object.keys(cabinetsBylocations).length) {
        return <InfoBanner message={t('noSchema')} />;
    }

    /**
     * check for searh quary and send the cabinets accordingly
     * @returns cabinetGrouping
     */
    const getCabinetsForView = () => {
        //if no search quary, show all the cabinets
        if (!cabinetSearchQuary) return cabinetsBylocations;

        let filteredCabinetData = {};
        const cabinetGrouping = { ...cabinetsBylocations };
        const searchQuery = cabinetSearchQuary.toString().toLowerCase();

        forEach(cabinetGrouping, (cabinets, location) => {
            const filteredCabinets = cabinets.filter(cabinet => {
                if (selectedCabinets[location]) {
                    if (selectedCabinets[location].find(device => device.deviceCode === cabinet.deviceCode)) return true;
                }

                return (
                    cabinet.name
                        .toString()
                        .toLowerCase()
                        .includes(searchQuery) ||
                    cabinet.deviceCode
                        .toString()
                        .toLowerCase()
                        .includes(searchQuery)
                );
            });

            if (filteredCabinets.length) {
                set(filteredCabinetData, location, filteredCabinets);
            }
        });
        return filteredCabinetData;
    };

    return (
        <div className="refillschema">
            <div className="refillschema__cabinets">
                <div className="refillschema__cabinets-search">
                    <input type="text" placeholder={t('main:searchCabinets')} onChange={e => setCabinetSearchQuery(e.target.value)} />
                    <svg>
                        <use xlinkHref={`${icons}#icon-search`}></use>
                    </svg>
                </div>
                <div className="refillschema__locations">
                    <div className="refillschema__locations-header">
                        <div className="refillschema__locations-header-checkbox">
                            <Checkbox size="sm" id="selectall" onChange={e => onSelectAllToggle(e.target.checked)}>
                                {t('main:selectAll')}
                            </Checkbox>
                        </div>
                        <div className="refillschema__locations-header-items"> </div>
                    </div>
                    {Object.keys(getCabinetsForView()).map(location => {
                        return (
                            <Fragment key={location}>
                                <div
                                    className={`refillschema__locations-header ${location === activeLocation ? 'refillschema__locations-header--active' : ''}`}
                                    key={location}
                                >
                                    <div className="refillschema__locations-header-checkbox">
                                        <Checkbox
                                            size="sm"
                                            id={location}
                                            onChange={e => onCheckBoxChange(e.target.checked, location)}
                                            checked={isChecked(location)}
                                        />
                                    </div>
                                    <div onClick={() => onLocationTabClicked(location)} className="refillschema__locations-header-items">
                                        <svg>
                                            <use xlinkHref={`${icons}#icon-keyboard_arrow_right`}></use>
                                        </svg>
                                        <svg>
                                            <use xlinkHref={`${icons}#icon-location`}></use>
                                        </svg>
                                        <span className="refillschema__locations-name">{t(`main:${location}`)}</span>
                                        <span className="refillschema__count">{cabinetsBylocations[location].length}</span>
                                    </div>
                                </div>

                                <ul
                                    className={`refillschema__locations-cabinets ${
                                        location === activeLocation ? 'refillschema__locations-cabinets--active' : ''
                                    }`}
                                >
                                    {cabinetsBylocations[location].map(cabinet => {
                                        return (
                                            <li key={cabinet.deviceCode}>
                                                <Checkbox
                                                    size="sm"
                                                    id={cabinet.deviceCode}
                                                    onChange={e => onCheckBoxChange(e.target.checked, location, cabinet)}
                                                    checked={isChecked(location, cabinet)}
                                                />{' '}
                                                {cabinet.name}{' '}
                                            </li>
                                        );
                                    })}
                                </ul>
                            </Fragment>
                        );
                    })}
                </div>
            </div>
            <div className="refillschema__contents">
                <div className="activeplans__empty">
                    <InfoBanner message={t('selectCabinetsToAddPlans')} />
                    {isAdmin && (
                        <button
                            onClick={() => apiRequestToGenerateRefillPlans()}
                            title={getHelperTitleText('generatePlans', t)}
                            className={`activeplans__empty-btn ${Object.keys(selectedCabinets).length ? '' : 'activeplans__empty-btn--disabled'}`}
                        >
                            {t('generatePlans')} ( {getCabinetsCount()} )
                        </button>
                    )}
                    <button onClick={() => cancelAddNewNewPlans()} className="activeplans__empty-btn activeplans__empty-btn--cancel">
                        {t('main:cancel')}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default AddRefillPlans;
