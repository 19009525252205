import React, { Fragment } from "react";

import icons from './../../icons/icomoon.svg';
import { sensitivityDescription, ALLOWED_SENSITIVITY_LEVELS } from ".";

const TagSensitivityInfoText=({tagSensitivityLevel,t})=>{
    const info = sensitivityDescription.find(info=>info.level ===tagSensitivityLevel) || {}
    return(
        <Fragment>
            <div className="tag-sensitivity__icon"> 
                {
                    ALLOWED_SENSITIVITY_LEVELS.map(level=>{
                        return(
                            <span key={`span-${level}`} className={`tag-sensitivity__icon-arrow ${level === tagSensitivityLevel ? '' :'tag-sensitivity__icon-arrow--hidden'}`}></span>
                        )
                    })
                }
            </div>
            <div className="tag-sensitivity__info">
            <h3>
                {
                    info && (info.level ===0 || info.level ===100) && (
                        <Fragment>
                            <svg className="tag-sensitivity__info-warn">
                                <use xlinkHref={`${icons}#icon-error_outline`}></use>
                            </svg> 
                        </Fragment>
                    )
                }
              {t('settings:sensitivityLevel')}: {info.level / 20} 
            </h3>
            <p className='tag-sensitivity-text'>{t(info.text)}</p>
            </div>
        </Fragment>
    )
}

export default TagSensitivityInfoText