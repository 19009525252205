import {
    REQUEST_REPLENISHMENTS,
    RECEIVE_REPLENISHMENTS,
    RECEIVE_REPLENISHMENTS_ERROR,
} from '../actions/replenishments';

const initState = {};

const reducer = (state = initState, action) => {
    switch (action.type) {
        case REQUEST_REPLENISHMENTS:
            return {
                ...state,
                [action.id]: {
                    isFetching: true,
                    fetched: false,
                    isError: false,
                    replenishments: [],
                },
            };
        case RECEIVE_REPLENISHMENTS:
            return {
                ...state,
                [action.id]: {
                    isFetching: false,
                    fetched: true,
                    isError: false,
                    replenishments: action.replenishments,
                },
            };
        case RECEIVE_REPLENISHMENTS_ERROR:
            console.error(action.error);
            return {
                ...state,
                [action.id]: {
                    isFetching: false,
                    fetched: true,
                    isError: true,
                    error: action.error.toString(),
                },
            };
        default:
            return state;
    }
};

export default reducer;
