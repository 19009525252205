import React, { useState, Fragment, useEffect } from 'react';
import { Card, Button, Tag, Modal, Icon, NavigationBar, RadioButton, TabItem } from '@storaensods/seeds-react';
import Toggle from 'react-toggle';
import { Row, Col, Label, FormGroup, ListGroup, ListGroupItem, Input } from 'reactstrap';
import TimePicker from 'react-times';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import moment from 'moment';
import Select from 'react-select';
import 'react-times/css/classic/default.css';
import { InfoBanner, Toggle as CustomToggle } from '../commons/utils';
import ImageUpload from '../imageUpload/imageUpload';
/**
 * Bundle discounts React component
 * @param {object} props Properties for the component
 * @param {object} props.cabinets Redux state object of cabinets
 * @param {object} props.products Redux state object of products
 * @param {object} props.productCategories Redux state object of productcategories
 * @param {object} props.rule Object which consists data of discount rule
 * @param {string} props.rule.Id Id of discount rule which
 * @param {string} props.rule.Name Name of discount rule
 * @param {'bundle'} props.rule.Type Type of discount rule
 * @param {boolean} props.rule.editMode Flag to imply if editing a rule is active (no preview is viewed)
 * @param {1 | 2 | 3} props.rule.Status Status of discount rule
 * @param {Array<string>} props.rule.LocationIds Array of location IDs where discount is applied
 * @param {Array<string>} props.rule.DeviceCodes Array of cabinet IDs where discount is applied
 * @param {Object} props.rule.DiscountData Object which consists data of discount itself
 * @param {Array<Object>} props.rule.DiscountData.bundleDiscountData Array of discount data objects
 * @param {Object} props.rule.DiscountData.bundleDiscountData Object which consists discount's items and their amounts etc
 * @param {number} [props.rule.DiscountData.bundleDiscountData.value] Value of fixed price or discount percentage. Can be assigned only to one item.
 * @param {'percentage'|'fixed'|'free'} [props.rule.DiscountData.bundleDiscountData.bundleDiscountType] Defines type of discount.
 * @param {number} props.rule.DiscountData.bundleDiscountData.amount Amount of required items
 * @param {string} props.rule.DiscountData.bundleDiscountData.id Item's id
 * @param {object} [props.rule.DiscountData.bundePricing] Object to keep bundlepricing value's, type and value
 * @param {Object} props.rule.DiscountData.Scheduling Object which consists data of scheduling discount
 * @param {Date} props.rule.DiscountData.Scheduling.startDate Start date of discount date range
 * @param {Date} props.rule.DiscountData.Scheduling.endDate End date of discount date range
 * @param {string} props.rule.DiscountData.Scheduling.startTime Start time of discount time range
 * @param {string} props.rule.DiscountData.Scheduling.endTime End time of discount time range
 * @param {Array.<string>} props.rule.DiscountData.Scheduling.recurrenceDays Days when discount reoccurs
 * @param {3} props.rule.DiscountData.discountTypeOrder Priorization of different type discounts. Bundle one is hardcoded to 3.
 * @param {Date} props.rule.DiscountData.lastEdited Timestamp of discount's last edit
 * @param {function} props.toggleDiscountStatus Function to handle discount's status
 * @param {function} props.handleDiscountEdit Function to handle discount's edit
 * @param {function} props.t i18n translation function
 * @param {boolean} [props.preview = false] Boolean for if preview mode is used
 * @param {Boolean} props.isAdmin Flag for if user is admin
 */

const checkIfFloat = val => {
    const isFloat = !!(val % 1);
    if (isFloat) return parseFloat(val.toFixed(2));
    else return val;
};

export default function BundleDiscounts(props) {
    const { t, rule, toggleDiscountStatus, preview, handleDiscountEdit, isAdmin } = props;
    const [detailsOpen, setDetailsOpen] = useState(false);
    const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
    const [deleteActive, setDeleteActive] = useState(false);
    const [invalidBundleWarnings, setInvalidBundleWarnings] = useState([]);

    const isDayChecked = weekday => {
        const dayChecked = rule?.DiscountData?.Scheduling.recurrenceDays
            ? rule?.DiscountData?.Scheduling.recurrenceDays.some(day => {
                  return weekday === day;
              })
            : false;
        if (dayChecked) {
            return 'recurrenceDayChecked';
        }
    };

    const handleDiscountStatusChange = () => {
        let Status = rule?.Status === 1 ? 2 : 1;
        if (deleteActive) Status = 3;
        const payload = preview
            ? Status
            : {
                  ...rule,
                  Status,
              };
        toggleDiscountStatus(payload);
        setConfirmationDialogOpen(false);
    };

    const handleDiscountDelete = () => {
        if (!preview) {
            setDeleteActive(true);
            setConfirmationDialogOpen(true);
        } else {
            handleDiscountEdit(null);
        }
    };

    const getModalTitle = () => {
        if (!deleteActive) {
            switch (rule.Status) {
                case 1:
                    return t('sureDeactivateDiscountRule');
                case 2:
                    return t('sureActivateDiscountRule');
                default:
                    break;
            }
        } else return t('sureDeleteDiscountRule');
    };

    const getProduct = id => {
        return props.products.find(product => product.id === id);
    };

    const getProducts = idArray => {
        return idArray.map(id => {
            return getProduct(id);
        });
    };

    // incase redux store returns an error
    let orgProductCurrency = '';
    try {
        orgProductCurrency = props.products[0].currency || '';
    } catch (error) {
        console.error(error);
    }

    const getCorrectDiscountAmountView = () => {
        let bundleValue;
        let newPrice = 0;
        const productIds = rule.DiscountData.bundleDiscountData.filter(d => d.itemType === 'product').map(d => d.id);
        const oldPrice = getProducts(productIds).reduce((accumulator, object) => {
            return accumulator + object?.price;
        }, 0);
        switch (rule.DiscountData.pricingType) {
            case 'bundle':
                bundleValue = rule.DiscountData.bundlePricing.value;
                break;
            case 'item':
                rule.DiscountData.bundleDiscountData.forEach(discount => {
                    if (discount.itemType === 'product') {
                        if (discount.bundleDiscountType === 'fixed') {
                            newPrice = newPrice + discount.value * discount.amount;
                        } else {
                            const product = getProduct(discount.id);
                            newPrice = newPrice + discount.amount * (parseFloat(product.price) - (parseFloat(product.price) * discount.value) / 100);
                        }
                    }
                });
                break;
            default:
                break;
        }
        const currency = orgProductCurrency;

        // Check if not a float toFixed
        bundleValue = rule.DiscountData.bundlePricing?.type === 'fixed' ? `${bundleValue.toFixed(2)}${currency}` : `-${checkIfFloat(bundleValue)}%`;
        return { currency, oldPrice: oldPrice.toFixed(2), newPrice: newPrice.toFixed(2), bundleValue };
    };
    const getCorrectBundleProductView = () => {
        // If an item level discount is changed to a bundle level discount,
        // unnecessary values for the bundle level are cleared to prevent an item splitting view
        if (rule.DiscountData.pricingType === 'bundle') {
            rule.DiscountData.bundleDiscountData.forEach(i => {
                i.bundleDiscountType = undefined;
                i.value = undefined;
            });
        }

        let HTML;
        const getProduct = id => {
            const product = props.products.find(product => product.id === id || product.barcode === id);
            if (!product) {
                const bundleWarnings = [...invalidBundleWarnings];
                if (!bundleWarnings.some(w => w.Id === rule.Id)) {
                    bundleWarnings.push({ Id: rule.Id, productId: id });
                    setInvalidBundleWarnings(bundleWarnings);
                }
                return undefined;
            } else {
                const bundleWarnings = [...invalidBundleWarnings];
                if (bundleWarnings.some(w => w.Id === rule.Id && w.productId === id)) {
                    bundleWarnings.filter(w => w.productId === id);
                    setInvalidBundleWarnings(bundleWarnings);
                }
                return product;
            }
        };
        const getProductCategory = id => {
            const productCategory = props.productCategories.find(category => category.id === id);
            if (!productCategory) {
                const bundleWarnings = [...invalidBundleWarnings];
                if (!bundleWarnings.some(w => w.Id === rule.Id)) {
                    bundleWarnings.push({ Id: rule.Id, productId: id });
                    setInvalidBundleWarnings(bundleWarnings);
                }
                return undefined;
            } else {
                const bundleWarnings = [...invalidBundleWarnings];
                if (bundleWarnings.some(w => w.Id === rule.Id && w.productId === id)) {
                    bundleWarnings.filter(w => w.productId === id);
                    setInvalidBundleWarnings(bundleWarnings);
                }
                return productCategory;
            }
        };
        const percentageOrFixed = discountConf => {
            if (discountConf.bundleDiscountType === 'percentage' && discountConf.value) {
                return `-${discountConf.value}%`;
            } else {
                if (orgProductCurrency && discountConf.value) return ` ${orgProductCurrency}${discountConf.value.toFixed(2)}`;
                else return null;
            }
        };
        let discountData = rule.DiscountData.bundleDiscountData;
        // Get discounted item
        const discountedItem = discountData.find(i => !!i.value);
        // filter it out and sort, then push at end of array
        if (discountedItem) {
            discountData = discountData.filter(i => !i.value).sort((a, b) => a.amount - b.amount);
            discountData.push(discountedItem);
        }

        const discountObjectToSplit = discountData.find(d => d.amount > 1 && d.value);
        const alreadySplitted = discountData.find(d => d.amount === 1 && d.id === discountObjectToSplit?.id);
        let discount_data = [...discountData];
        if (discountObjectToSplit) {
            if (!alreadySplitted) {
                const newDiscountObj = { ...discountObjectToSplit, amount: 1 };
                discount_data = [...discountData, newDiscountObj];
            }
            discount_data = discount_data.map(d => {
                if (d.amount > 1 && d.value) {
                    return { ...d, amount: d.amount - 1, value: undefined, bundleDiscountType: undefined };
                } else return d;
            });
        }

        switch (rule.DiscountData.pricingType) {
            case 'bundle':
                HTML = (
                    <Row className="bundleProductRow no-gutters">
                        {discount_data.map((item, i) => {
                            if (item.itemType === 'product') {
                                const fullProduct = getProduct(item.id);
                                return (
                                    <Fragment key={i}>
                                        <Col>
                                            {fullProduct && fullProduct.imageUrl ? (
                                                <img alt="product" className="bundleProductImage" src={fullProduct?.imageUrl} />
                                            ) : (
                                                <div className="missingImage">{fullProduct?.name}</div>
                                            )}
                                            <div className="bundleItemAmount">x{item?.amount}</div>
                                        </Col>
                                        {i !== discount_data.length - 1 ? (
                                            <Col className="addIcon">
                                                <Icon>add</Icon>
                                            </Col>
                                        ) : null}
                                    </Fragment>
                                );
                            } else {
                                const fullProductCategory = getProductCategory(item.id);
                                return (
                                    <Fragment key={i}>
                                        <Col>
                                            <div className="missingImage">
                                                <span className="bundleProductCategoryItem">{fullProductCategory?.name}</span>
                                            </div>
                                            <div className="bundleItemAmount">x{item?.amount}</div>
                                        </Col>
                                        {i !== discount_data.length - 1 ? (
                                            <Col className="addIcon">
                                                <Icon>add</Icon>
                                            </Col>
                                        ) : null}
                                    </Fragment>
                                );
                            }
                        })}
                    </Row>
                );
                break;
            case 'item':
                HTML = (
                    <Row className="bundleProductRow no-gutters">
                        {discount_data.map((product, i) => {
                            if (product.itemType === 'product') {
                                const fullProduct = getProduct(product.id);
                                return (
                                    <Fragment key={i}>
                                        <Col>
                                            {fullProduct && fullProduct.imageUrl ? (
                                                <img alt="product" className="bundleProductImage" src={fullProduct?.imageUrl} />
                                            ) : (
                                                <div className="missingImage">{fullProduct?.name}</div>
                                            )}
                                            <div className={`text-center mt-1${!fullProduct ? ' bundleMissingProductOpacity' : ''}`}>
                                                <div className="bundleItemAmount">x{product?.amount}</div>
                                                <span className="text-success">
                                                    {product.bundleDiscountType === 'fixed' && (
                                                        <>
                                                            {' '}
                                                            <span className="text-danger mr-2">
                                                                <s>
                                                                    {fullProduct?.price.toFixed(2)} {orgProductCurrency}
                                                                </s>
                                                            </span>{' '}
                                                            {percentageOrFixed(product)}{' '}
                                                        </>
                                                    )}
                                                    {product.bundleDiscountType === 'free' && (
                                                        <>
                                                            {' '}
                                                            <span className="text-success">{t('free')}</span>
                                                        </>
                                                    )}

                                                    {product.bundleDiscountType === 'percentage' && (
                                                        <div className="bundleProductPercentageDiscount se-tile--yellow">
                                                            <div> {percentageOrFixed(product)} </div>
                                                        </div>
                                                    )}
                                                </span>
                                            </div>
                                        </Col>
                                        {i !== discount_data.length - 1 ? (
                                            <Col className="addIcon">
                                                <Icon>add</Icon>
                                            </Col>
                                        ) : null}
                                    </Fragment>
                                );
                            } else {
                                const fullProductCategory = getProductCategory(product.id);
                                return (
                                    <Fragment key={i}>
                                        <Col>
                                            <div className="missingImage">
                                                <span className="bundleProductCategoryItem">{fullProductCategory?.name}</span>
                                            </div>
                                            <div className={`text-center  mt-1${!fullProductCategory ? ' bundleMissingProductOpacity' : ''}`}>
                                                <div className="bundleItemAmount">x{product?.amount}</div>
                                                <span className="text-success">
                                                    {product.bundleDiscountType === 'fixed' && <> {percentageOrFixed(product)}</>}
                                                    {product.bundleDiscountType === 'percentage' && (
                                                        <div className="bundleProductPercentageDiscount se-tile--yellow">
                                                            <div> {percentageOrFixed(product)} </div>
                                                        </div>
                                                    )}
                                                    {product.bundleDiscountType === 'free' && (
                                                        <>
                                                            {' '}
                                                            <span className="text-success">{t('free')}</span>
                                                        </>
                                                    )}
                                                </span>
                                            </div>
                                        </Col>
                                        {i !== discount_data.length - 1 ? (
                                            <Col className="addIcon">
                                                <Icon>add</Icon>
                                            </Col>
                                        ) : null}
                                    </Fragment>
                                );
                            }
                        })}
                    </Row>
                );
                break;
            default:
                break;
        }
        return HTML;
    };

    const editingAllowed = () => {
        if (invalidBundleWarnings.some(w => w.Id === rule.Id) && preview) {
            return false;
        } else if (invalidBundleWarnings.some(w => w.Id === rule.Id) && !preview) {
            return true;
        } else if (!invalidBundleWarnings.some(w => w.Id === rule.Id) && (preview || !preview)) {
            return true;
        }
    };

    if (!rule || !rule.DiscountData) {
        return null;
    }

    // disable active inactive toggle
    const disableDiscountToggle = () => {
        const currentTime = moment();

        let endDate = rule.DiscountData.Scheduling.endDate;
        let endTime = rule.DiscountData.Scheduling.endTime;

        if (endTime) endTime = moment(endTime, 'HH:mm');
        if (endDate) endDate = moment(endDate);

        if (endDate && endDate.isBefore(currentTime)) {
            return true;
        } else if (endTime && endTime.isBefore(currentTime)) {
            return true;
        }

        return false;
    };

    return (
        <div className={`d-flex ${preview ? 'cardMaxWidth' : ''}`}>
            <Card
                title={rule?.Name}
                className="mt-2 DiscountContainerCard BundleDiscountContainerCard"
                subtitle={
                    <>
                        {editingAllowed() && isAdmin && (
                            <div className="mr-2">
                                <Button icon="edit" type="secondary" onClick={() => handleDiscountEdit(rule)}>
                                    {t('edit')}
                                </Button>
                            </div>
                        )}
                        {isAdmin && (
                            <div>
                                <Button icon="delete" type="secondary" onClick={() => handleDiscountDelete()}>
                                    {t('delete')}
                                </Button>
                            </div>
                        )}
                    </>
                }
            >
                <Modal
                    actions={[
                        {
                            label: t('confirm'),
                            onClick: () => handleDiscountStatusChange(),
                        },
                        {
                            label: t('cancel'),
                            onClick: () => setConfirmationDialogOpen(false),
                        },
                    ]}
                    active={confirmationDialogOpen}
                    title={getModalTitle()}
                    onClose={() => setConfirmationDialogOpen(false)}
                >
                    <h2>{rule?.Name}</h2>
                </Modal>
                <Row>
                    <Col lg="4" md="4" className="bundledDiscountLeftSide text-center my-auto">
                        {rule.DiscountData.imageUrl && (
                            <div className="mb-5">
                                <div className="bundleDiscountImage_leftSide mx-auto my-auto">
                                    <img src={rule.DiscountData.imageUrl} alt="" />
                                </div>
                            </div>
                        )}
                        {rule.DiscountData.pricingType === 'bundle' && (
                            <div className="mb-5">
                                <div className="bundleDiscountPercentage_leftSide mx-auto se-tile--yellow my-auto">
                                    <h4 className="w-100 my-auto">{getCorrectDiscountAmountView().bundleValue}</h4>
                                </div>
                            </div>
                        )}

                        <Row>
                            <Col lg="12" className="text-center">
                                {isAdmin && (
                                    <Toggle
                                        className={`${rule?.Status === 2 ? 'deactivatedDiscountToggle' : ''}`}
                                        icons={false}
                                        checked={rule?.Status === 1}
                                        disabled={invalidBundleWarnings.some(w => w.Id === rule.Id) || disableDiscountToggle()}
                                        onChange={() => {
                                            if (!preview) setConfirmationDialogOpen(true);
                                            else handleDiscountStatusChange();
                                        }}
                                    />
                                )}
                            </Col>
                        </Row>
                    </Col>
                    <Col lg="8" md="8" className="pl-4 bundleDiscount_rightSide">
                        {invalidBundleWarnings.some(w => w.Id === rule.Id) && <div className="text-danger">{t('invalidBundleItems')}</div>}
                        {getCorrectBundleProductView()}
                        <Row className="my-3">
                            <Col lg="12">
                                <span style={{ verticalAlign: 'top' }}>
                                    {' '}
                                    <Icon size="lg">date_range</Icon>{' '}
                                </span>
                                <div className="d-inline-flex ml-2">
                                    {rule?.DiscountData?.Scheduling?.startDate && rule?.DiscountData?.Scheduling?.endDate ? (
                                        <>
                                            <div className=" mr-1">{moment(rule?.DiscountData?.Scheduling.startDate).format('LL')}</div>-
                                            <div className=" ml-1">{moment(rule?.DiscountData?.Scheduling.endDate).format('LL')}</div>
                                        </>
                                    ) : (
                                        <div className="d-inline-flex mr-1"> {t('permanent')} </div>
                                    )}
                                </div>
                            </Col>
                        </Row>
                        <Row className="mt-4">
                            <Col lg="12">
                                <span style={{ verticalAlign: 'top' }}>
                                    <Icon size="lg">access_time</Icon>
                                </span>
                                <div className="d-inline-flex ml-3">
                                    <div className=" mr-1">{rule?.DiscountData?.Scheduling?.startTime}</div>-
                                    <div className="ml-1">{rule?.DiscountData?.Scheduling?.endTime}</div>
                                </div>
                            </Col>
                        </Row>

                        <Row className="mt-4">
                            <Col lg="12">
                                <span>{t('recurrence')}</span>
                                <div className={'d-flex justify-content-between my-2'}>
                                    <div className={`recurrenceWeekdayBtn rounded-circle d-inline-flex ${isDayChecked('Mon')} `}>
                                        <span>{t('weekDayAbrvMonday')}</span>
                                    </div>
                                    <div className={`recurrenceWeekdayBtn rounded-circle d-inline-flex ${isDayChecked('Tue')}`}>
                                        <span>{t('weekDayAbrvTuesday')}</span>
                                    </div>
                                    <div className={`recurrenceWeekdayBtn rounded-circle d-inline-flex ${isDayChecked('Wed')}`}>
                                        <span>{t('weekDayAbrvWednesday')}</span>
                                    </div>
                                    <div className={`recurrenceWeekdayBtn rounded-circle d-inline-flex ${isDayChecked('Thu')}`}>
                                        <span>{t('weekDayAbrvThursday')}</span>
                                    </div>
                                    <div className={`recurrenceWeekdayBtn rounded-circle d-inline-flex ${isDayChecked('Fri')}`}>
                                        <span>{t('weekDayAbrvFriday')}</span>
                                    </div>
                                    <div className={`recurrenceWeekdayBtn rounded-circle d-inline-flex ${isDayChecked('Sat')}`}>
                                        <span>{t('weekDayAbrvSaturday')}</span>
                                    </div>
                                    <div className={`recurrenceWeekdayBtn rounded-circle d-inline-flex ${isDayChecked('Sun')}`}>
                                        <span>{t('weekDayAbrvSunday')}</span>
                                    </div>
                                </div>
                            </Col>
                        </Row>

                        <Row className={`mt-3 ${preview ? 'invisible' : ''}`}>
                            <Col lg="12">
                                <Button onClick={() => setDetailsOpen(!detailsOpen)} type="positive">
                                    {' '}
                                    {t('translations:seeDetails')} <Icon className="align-middle">{detailsOpen ? 'arrow_drop_up' : 'arrow_drop_down'}</Icon>
                                </Button>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row>
                    {detailsOpen && (
                        <div className="discountDetailContainer p-3">
                            <div>
                                <span>{t('locations')}: </span>
                                {rule.LocationIds && typeof rule.LocationIds === 'object' && rule.LocationIds.length > 0 ? (
                                    rule.LocationIds.map(locationId => {
                                        const location = props.cabinets.locations.find(location => location.id === locationId);
                                        if (!location) {
                                            return (
                                                <Tag className="mr-2" type="negative">
                                                    {locationId} ({t('doesNotExist')})
                                                </Tag>
                                            );
                                        } else {
                                            return (
                                                <Tag className="mr-2" type="positive">
                                                    {location.name}
                                                </Tag>
                                            );
                                        }
                                    })
                                ) : (
                                    <Tag>{t('all')}</Tag>
                                )}
                            </div>
                            <div>
                                <span>{t('cabinets')}: </span>
                                {rule.DeviceCodes && typeof rule.DeviceCodes === 'object' && rule.DeviceCodes.length > 0 ? (
                                    rule.DeviceCodes.map(deviceCode => {
                                        const cabinet = props.cabinets.cabinets.find(cabinet => cabinet.deviceCode === deviceCode);
                                        if (!cabinet) {
                                            return (
                                                <Tag className="mr-2" key={deviceCode} type="negative">
                                                    {deviceCode} ({t('doesNotExist')})
                                                </Tag>
                                            );
                                        } else {
                                            return (
                                                <Tag className="mr-2" key={deviceCode} type="positive">
                                                    {cabinet.name}
                                                </Tag>
                                            );
                                        }
                                    })
                                ) : (
                                    <Tag>{t('all')}</Tag>
                                )}
                            </div>
                        </div>
                    )}
                </Row>
            </Card>
        </div>
    );
}

/**
 * Add discount rule stop form React component
 * @param {object} props
 * @param {function} props.t i18n translator function
 * @param {object} props.bundledDiscount Discount object
 * @param {function} props.setBundleDiscount Function to set temporary bundle discount
 * @param {string} props.formMessage Optional informative message to display on form
 * @param {Array<object>} props.products Array of products to be selected in bundle
 * @param {Array<object>} props.productCategories Array of product categories to be selected in bundle
 */
export function BundleDiscountsForm(props) {
    const { t, setBundleDiscount, bundledDiscount, bundleImageUrl, userRestricted, handleUserRestrictToggle } = props;
    const data = bundledDiscount?.DiscountData ? bundledDiscount.DiscountData : undefined;

    const dropdownProducts = () => {
        return props.products
            .filter(p => p.status !== 3)
            .map(product => {
                return {
                    value: product?.id,
                    label: product?.name,
                    price: product?.price,
                    productCategoryId: product?.productCategoryId,
                    currency: product?.currency,
                };
            });
    };
    const dropdownProductCategories = () => {
        return props.productCategories.map(product => {
            return { value: product.id, label: product.name };
        });
    };

    const mapDropdownData = (data, source) => {
        if (source === 'products') {
            const ids = data.filter(d => d.itemType === 'product').map(d => d.id);

            return ids
                .map(p => {
                    const product = props.products.find(pr => p === pr.id);
                    return product;
                })
                .map(product => {
                    const discountProduct = data.find(discount => discount.id === product.id);
                    return {
                        value: product?.id,
                        label: product?.name,
                        price: product?.price,
                        productCategoryId: product?.productCategoryId,
                        currency: product?.currency,
                        amount: discountProduct ? discountProduct.amount : 1,
                    };
                });
        } else {
            const ids = data.filter(d => d.itemType === 'productCategory').map(d => d.id);
            return ids
                .map(p => {
                    const productCategory = props.productCategories.find(pc => p === pc.id);

                    return productCategory;
                })
                .map(pc => {
                    const discountProductCategory = data.find(discount => discount.id === pc.id);
                    return { value: pc.id, label: pc.name, amount: discountProductCategory ? discountProductCategory.amount : 1 };
                });
        }
    };

    const getImageUrl = () => {
        if (bundleDiscountData) return bundleDiscountData.bundleImageUrl;
        else if (bundleImageUrl) return bundleImageUrl;
        else return null;
    };

    const [bundleProducts, setBundleProducts] = useState(data?.bundleDiscountData ? mapDropdownData(data?.bundleDiscountData, 'products') : []);
    const [bundleProductCategories, setBundleProductCategories] = useState(
        data?.bundleDiscountData ? mapDropdownData(data?.bundleDiscountData, 'productCategories') : []
    );
    const [activeTab, setActiveTab] = useState('Products');
    const [errorMsg, setErrorMsg] = useState(props.formMessage ? props.formMessage : '');
    const [bundleDiscountData, setBundleDiscountData] = useState(data?.bundleDiscountData ? data?.bundleDiscountData : []);
    const [bundleDiscountPercentageAmount, setBundleDiscountPercentageAmount] = useState(
        data?.bundlePricing?.type === 'percentage' ? data?.bundlePricing.value : null
    );
    const [bundleDiscountFixedAmount, setBundleDiscountFixedAmount] = useState(data?.bundlePricing?.type === 'fixed' ? data?.bundlePricing.value : null);
    const [itemPricing, setItemPricing] = useState(data?.pricingType ? data?.pricingType : 'item');
    const [bundlePricingType, setBundlePricingType] = useState(data?.bundlePricing?.type ? data?.bundlePricing?.type : null);
    const [Scheduling, setScheduling] = useState({
        startDate: data?.Scheduling?.startDate ? moment(data?.Scheduling?.startDate).toDate() : null,
        endDate: data?.Scheduling.endDate ? moment(data?.Scheduling.endDate).toDate() : null,
        endTime: data?.Scheduling.endTime ? data?.Scheduling.endTime : '23:59',
        startTime: data?.Scheduling.startTime ? data?.Scheduling.startTime : '00:00',
        recurrenceDays: data?.Scheduling?.recurrenceDays.length > 0 ? data?.Scheduling?.recurrenceDays : [],
    });
    const [imageStoring, setImageStoring] = useState(false);
    const [imageUrl, setImageUrl] = useState(getImageUrl());
    const [imageCropping, toggleImageCropping] = useState(false);
    useEffect(() => {
        if (bundleImageUrl) {
            setImageStoring(false);
            setImageUrl(bundleImageUrl);
        } else if (bundledDiscount && bundledDiscount.Id) {
            if (bundledDiscount.DiscountData.imageUrl) setImageUrl(bundledDiscount.DiscountData.imageUrl);
        }
    }, [bundledDiscount, bundleImageUrl]);

    const handleDateRangeChange = dates => {
        if (!dates) {
            setScheduling({ ...Scheduling, startDate: null, endDate: null });
        } else {
            setScheduling({ ...Scheduling, startDate: dates[0], endDate: dates[1] });
        }
    };

    /**
     * Function to handle and manipulate times from picker
     * @param {object} timeObj Timeobject
     * @param {string} timeObj.hour Hour of selected time
     * @param {string} timeObj.minute Minute of selected time
     * @param {string} timeObj.meridiem AM/PM if 12h mode is activated
     * @param {'start' | 'end'} component Parameter to define which picker is used
     */

    const onTimeChange = (timeObj, component) => {
        const endHour = Scheduling.endTime.substring(0, 2);
        const startHour = Scheduling.startTime.substring(0, 2);
        if (component === 'start') {
            if (parseInt(timeObj.hour) > parseInt(endHour)) {
                setErrorMsg(t('schedulingStartHourIsOverEndHour'));
                return false;
            } else {
                setScheduling({
                    ...Scheduling,
                    startTime: `${timeObj.hour}:${timeObj.minute}`,
                });
                setErrorMsg('');
            }
        } else {
            if (parseInt(timeObj.hour) < parseInt(startHour)) {
                setErrorMsg(t('schedulingEndHourIsUnderStartHour'));
                return false;
            } else {
                setScheduling({
                    ...Scheduling,
                    endTime: `${timeObj.hour}:${timeObj.minute}`,
                });
                setErrorMsg('');
            }
        }
    };

    const selectRecurrencyDay = weekday => {
        const daySelectedAlready = Scheduling.recurrenceDays?.some(day => {
            return weekday === day;
        });
        if (daySelectedAlready) {
            const newWeekDays = Scheduling.recurrenceDays?.filter(day => {
                return day !== weekday;
            });
            setScheduling({ ...Scheduling, recurrenceDays: newWeekDays });
        } else {
            setScheduling({
                ...Scheduling,
                recurrenceDays: [...Scheduling.recurrenceDays, weekday],
            });
        }
    };

    const isDayChecked = weekday => {
        const dayChecked = Scheduling.recurrenceDays.some(day => {
            return weekday === day;
        });
        if (dayChecked) {
            return 'recurrenceDayChecked';
        }
    };

    const areRecurrenceAvailable = () => {
        if (Scheduling.startDate && Scheduling.endDate) {
            if (moment(Scheduling.startDate).isSame(Scheduling.endDate, 'day')) {
                if (Scheduling.recurrenceDays.length) {
                    setScheduling({
                        ...Scheduling,
                        recurrenceDays: [],
                    });
                }
                return false;
            } else {
                return true;
            }
        } else {
            if (Scheduling.recurrenceDays.length) {
                setScheduling({
                    ...Scheduling,
                    recurrenceDays: [],
                });
            }
            return false;
        }
    };

    const handleProductChange = products => {
        if (!products) {
            setBundleProducts([]);
        } else {
            products = products.map(p => {
                return { ...p, amount: 1 };
            });
            setBundleProducts(products);
        }
    };

    const handleProductCategorySelect = productCategories => {
        if (!productCategories) setBundleProductCategories([]);
        else {
            let products = bundleProducts;
            productCategories.forEach(pc => {
                const matchingProduct = products.find(p => p.productCategoryId === pc.value);
                if (matchingProduct) {
                    products = products.filter(p => p.productCategoryId !== matchingProduct.productCategoryId);
                }
            });
            setBundleProducts(products);
            productCategories = productCategories.map(p => {
                return { ...p, amount: 1 };
            });
            setBundleProductCategories(productCategories);
        }
    };

    const getProductInfo = products => {
        if (products) {
            return products.map(p => {
                return {
                    ...p,
                    itemType: 'product',
                    ...props.products.find(pr => pr.id === p.value),
                };
            });
        } else {
            return [];
        }
    };

    const getProductCategoryInfo = categories => {
        if (categories) {
            return categories.map(c => {
                return { ...c, itemType: 'productCategory', ...props.productCategories.find(pc => pc.id === c.value) };
            });
        } else {
            return [];
        }
    };

    const listifyProductsAndCategories = () => {
        let list = [];
        if (bundleProductCategories.length) {
            list = [...list, ...getProductCategoryInfo(bundleProductCategories)];

            if (bundleProducts.length) {
                list.unshift({ listTitle: t('productCategoriesTitle') });
                list.push({ listTitle: t('products') });
                list = [...list, ...getProductInfo(bundleProducts)];
            }
        } else if (bundleProducts.length && !bundleProductCategories.length) {
            list = [...getProductInfo(bundleProducts)];
        }
        return list;
    };

    const validatePage = page => {
        switch (page) {
            case 'products':
                if ([...bundleProductCategories, ...bundleProducts].length >= 1 && [...bundleProductCategories, ...bundleProducts].length <= 3) {
                    setErrorMsg('');
                    setActiveTab('Amount');
                } else {
                    if ([...bundleProductCategories, ...bundleProducts].length < 1) setErrorMsg(t('setBundleProducts'));
                    else if ([...bundleProductCategories, ...bundleProducts].length > 3) {
                        setErrorMsg(t('bundleItemsLengthExceeded'));
                    }
                }
                break;
            case 'Scheduling':
                if (errorMsg === '') {
                    setActiveTab('userRestrict');
                }
                break;
            case 'CustomImage':
                if (errorMsg === '') saveTmpBundleDiscount();
                break;
            case 'amount': {
                if ([...bundleProductCategories, ...bundleProducts].length === 1 && [...bundleProductCategories, ...bundleProducts][0].amount === 1)
                    setErrorMsg(t('setAmountMoreThanOne'));
                else {
                    setErrorMsg('');
                    setActiveTab('Pricing');
                }

                break;
            }
            case 'Pricing': {
                const oldPrice = bundleProducts.reduce((accumulator, object) => {
                    const price = object.amount ? object.price * object.amount : object.price;
                    return accumulator + price;
                }, 0);
                const productPricesExceedsOriginalPrices = bundleDiscountData.some(d => {
                    if (d.itemType === 'product' && d.bundleDiscountType === 'fixed') {
                        const product = bundleProducts.find(p => p.value === d.id);
                        if (product && product.price < d.value) return true;
                    } else return false;
                    return null;
                });
                if (itemPricing === 'bundle' && oldPrice > 0 && oldPrice < bundleDiscountFixedAmount && !bundleProductCategories.length) {
                    setErrorMsg(t('bundleFixedAmountExceededOriginal'));
                } else if (itemPricing === 'bundle' && bundleDiscountFixedAmount !== null && bundleDiscountFixedAmount === 0) {
                    setErrorMsg(t('bundleFixedAmountNotAllowedToBeZero'));
                } else if (itemPricing === 'bundle' && bundleDiscountFixedAmount !== null && bundleDiscountFixedAmount === 0) {
                    setErrorMsg(t('bundleFixedAmountNotAllowedToBeZero'));
                } else if (itemPricing === 'bundle' && bundleDiscountPercentageAmount && bundleDiscountPercentageAmount === 100) {
                    setErrorMsg(t('bundlePercentageNotAllowedToBeFull'));
                } else if (itemPricing === 'bundle' && bundleDiscountPercentageAmount !== null && bundleDiscountPercentageAmount === 0) {
                    setErrorMsg(t('bundlePercentageNotAllowedToBeZero'));
                } else if (itemPricing === 'bundle' && (bundleDiscountPercentageAmount || bundleDiscountFixedAmount)) {
                    setErrorMsg('');
                    setActiveTab('Scheduling');
                }
                if (itemPricing === 'item' && !productPricesExceedsOriginalPrices && bundleDiscountData.some(d => d.value && d.bundleDiscountType)) {
                    setErrorMsg('');
                    setActiveTab('Scheduling');
                } else if (itemPricing === 'item' && productPricesExceedsOriginalPrices) {
                    setErrorMsg(t('bundleItemFixedAmountExceededOriginal'));
                } else if (itemPricing === 'item' && !bundleDiscountData.some(d => d.value && d.bundleDiscountType)) {
                    setErrorMsg(t('setBundleItemDiscount'));
                } else if (itemPricing === 'bundle' && bundleDiscountPercentageAmount === null && bundleDiscountFixedAmount === null) {
                    setErrorMsg(t('setBundleDiscount'));
                }
                break;
            }
            default:
                break;
        }
    };

    const handleProductAmount = (id, operator) => {
        let products = [...bundleProducts];
        const product = products.find(p => id === p.value);
        if (operator === 'minus') {
            if (product.amount - 1 !== 0) product.amount = product.amount - 1;
        } else {
            product.amount = product.amount + 1;
        }
        products = products.map(p => (p.value !== id ? p : product));
        setBundleProducts(products);
    };

    const handleProductCategoryAmount = (productCatId, operator) => {
        let productCategories = [...bundleProductCategories];
        const product = productCategories.find(p => productCatId === p.value);
        if (operator === 'minus') {
            if (product.amount - 1 !== 0) product.amount = product.amount - 1;
        } else {
            product.amount = product.amount + 1;
        }
        productCategories = productCategories.map(p => (p.value !== productCatId ? p : product));
        setBundleProductCategories(productCategories);
    };

    const handleBundleDiscountAmount = (event, type) => {
        let inputValue = event.target.value;
        if (inputValue === '') {
            inputValue = '0';
        }
        if (isNaN(inputValue) || isNaN(parseFloat(inputValue))) {
            return; // invalid change
        }
        const value = parseFloat(inputValue);
        setBundlePricingType(type);
        if (type === 'percentage') {
            // To handle empty values and allow 0 values for clear validation
            if (value < 0 || !event.target.value) {
                setBundleDiscountPercentageAmount(null); // min 0 %
            } else if (value > 100) {
                setBundleDiscountPercentageAmount(100); // max 100 %
            } else {
                setBundleDiscountPercentageAmount(value);
            }
        } else {
            // To handle empty values and allow 0 values for clear validation
            if (value < 0 || !event.target.value) {
                setBundleDiscountFixedAmount(null); // min 0
            } else if (value > 100000) setBundleDiscountFixedAmount(100000);
            else {
                setBundleDiscountFixedAmount(value);
            }
        }
    };

    const mapItemsToBundleDiscountData = (products, productCategories) => {
        let data = [...bundleDiscountData];
        products.forEach(p => {
            const existingProduct = data.find(d => d.id === p.value);
            if (!existingProduct) {
                const product = { id: p.value, itemType: 'product', amount: p.amount };
                data.push(product);
            } else {
                data = data.map(d => (d.id === p.value ? { ...existingProduct, itemType: 'product', amount: p.amount } : d));
            }
        });
        productCategories.forEach(p => {
            const existingProduct = data.find(d => d.id === p.value);
            if (!existingProduct) {
                const product = { id: p.value, itemType: 'productCategory', amount: p.amount };
                data.push(product);
            } else {
                data = data.map(d => (d.id === p.value ? { ...existingProduct, itemType: 'productCategory', amount: p.amount } : d));
            }
        });
        //Check deleted items
        data = data.filter(d => {
            const deletedItem = !products.find(p => d.id === p.value) && !productCategories.find(pc => d.id === pc.value);
            if (!deletedItem) return true;
            else return false;
        });

        // Clear overlapping bundle discount conditions
        data = data.map(d => {
            if (itemPricing === 'bundle') return { ...d, value: undefined, bundleDiscountType: undefined };
            else return d;
        });

        return data;
    };

    const getItemType = id => {
        if (bundleProducts.some(product => product.value === id)) {
            return 'product';
        } else if (bundleProductCategories.some(pc => pc.value === id)) {
            return 'productCategory';
        }
    };

    const handleItemDiscountAmount = (event, id) => {
        let pDiscountData = [...bundleDiscountData];
        let inputValue = event.target.value;

        if (inputValue === '') {
            inputValue = '0';
        }

        if (isNaN(inputValue) || isNaN(parseFloat(inputValue))) {
            return; // invalid change
        }

        let value = parseFloat(inputValue);

        const type = bundleDiscountData.find(d => d.id === id)?.bundleDiscountType;
        const amount = bundleDiscountData.find(d => d.id === id)?.amount;
        if (type === 'percentage') {
            // Check if not a float toFixed //
            value = checkIfFloat(value);

            if (value < 0 || value === 0) {
                value = null;
            } else if (value > 100) {
                value = 100;
            }
        } else if (type === 'fixed') {
            value = parseFloat(value.toFixed(2));
            if (value < 0 || value === 0) {
                value = null;
            } else if (value > 100000) value = 100000;
        } else {
            value = 100;
        }

        const item = pDiscountData.find(p => p.id === id);
        const discountData = { ...item, value, itemType: getItemType(item.id), bundleDiscountType: type, amount };
        pDiscountData = pDiscountData.map(p => {
            const discountObj = p.id !== id ? p : discountData;
            //Only one can be filled with discount data
            if (p.id !== id) {
                discountObj.value = null;
                discountObj.bundleDiscountType = null;
            }
            return discountObj;
        });
        setBundleDiscountData(pDiscountData);
    };

    const saveTmpBundleDiscount = () => {
        const bundleDiscountData = mapItemsToBundleDiscountData(bundleProducts, bundleProductCategories);

        //Returns undefined if bundleDiscountData has some value which indicates that bundle has itempricing
        const getBundlePricing = () => {
            if ((bundleDiscountFixedAmount || bundleDiscountPercentageAmount) && !bundleDiscountData.some(d => d.value)) {
                const bundleConf = { type: bundlePricingType };
                if (bundleDiscountFixedAmount && !bundleDiscountPercentageAmount) {
                    bundleConf.value = bundleDiscountFixedAmount;
                }
                if (bundleDiscountPercentageAmount && !bundleDiscountFixedAmount) {
                    bundleConf.value = bundleDiscountPercentageAmount;
                }
                return bundleConf;
            } else {
                return undefined;
            }
        };

        const bundleConfiguration = {
            pricingType: itemPricing,
            bundleDiscountData,
            bundlePricing: getBundlePricing(),
            Scheduling,
            imageUrl,
        };
        setBundleDiscount(bundleConfiguration);
    };

    const handleItemDiscountType = (type, id) => {
        let pDiscountData = [...bundleDiscountData];

        const product = pDiscountData.find(p => p.id === id);
        const value = type === 'free' ? 100 : null;

        if (product) {
            pDiscountData = [{ ...product, bundleDiscountType: type, value }];
        } else {
            pDiscountData = [{ id, bundleDiscountType: type, value }];
        }

        setBundleDiscountData(pDiscountData);
    };

    const pricingOptionsVisible = items => {
        if (items.length > 1) {
            return true;
        }
        if (items.length === 1 && items[0].amount > 1) {
            return true;
        }
        if (items.length === 1 && items[0].amount === 1) {
            return false;
        }
    };

    const uploadBundleImage = file => {
        setImageStoring(true);
        return props.uploadBundleImage(file);
    };

    const bundleImageUpload = () => {
        return (
            <div className="logoEditContainer bundleImageUploadContainer d-flex mb-2 justify-content-between">
                <div className="">
                    <div className="scp-settings__inputs-item">
                        <div className="logoEditContainer d-flex mb-2 justify-content-between">
                            <ImageUpload
                                className="se-input-container"
                                freeAspectRatio={false}
                                targetWidth={parseInt(process.env.REACT_APP_BUNDLE_IMAGE_WIDTH) || 1920}
                                targetHeight={parseInt(process.env.REACT_APP_BUNDLE_IMAGE_HEIGHT) || 850}
                                imageUrl={imageUrl}
                                storing={imageStoring}
                                uploadImage={img => uploadBundleImage(img)}
                                removeImage={() => setImageUrl(null)}
                                toggleCropping={() => toggleImageCropping(!imageCropping)}
                                t={props.t}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    // bundleDiscount wizard
    return (
        <div className="p-2 bundleDiscountFormModal">
            <Row>
                <NavigationBar className="mb-3 not-printable">
                    <TabItem isActive={activeTab === 'Products'}>{`${t('products')}/${t('productCategoriesTitle')}`}</TabItem>
                    <TabItem isActive={activeTab === 'Amount'}> {t('amount')}</TabItem>
                    <TabItem isActive={activeTab === 'Pricing'}>{t('pricing')} </TabItem>
                    <TabItem isActive={activeTab === 'Scheduling'}>{t('scheduling')} </TabItem>
                    <TabItem isActive={activeTab === 'userRestrict'}>{t('userRestrictTitle')} </TabItem>
                    <TabItem isActive={activeTab === 'CustomImage'}>{t('discountCustomImageTitle')} </TabItem>
                </NavigationBar>
            </Row>
            <Row>
                <Col xs="12">{errorMsg ? <p style={{ color: '#CF142B' }}>{errorMsg}</p> : null}</Col>
            </Row>

            {activeTab === 'Products' && (
                <>
                    <Row className="p-3">
                        <Icon className="my-auto">info_outline</Icon>
                        <span>{t('bundleMaxProductLimitInfo')}</span>
                    </Row>
                    <Row className="p-3">
                        <FormGroup>
                            <Label for="productCategoryBundleSelect"> {t('productCategoriesTitle')}</Label>
                            <Select
                                value={bundleProductCategories}
                                id="productCategoryBundleSelect"
                                onChange={handleProductCategorySelect}
                                isMulti
                                options={dropdownProductCategories()}
                                className="bundleDiscountsSelect"
                                placeholder={t('selectDiscountRuleProductCategories')}
                            />
                        </FormGroup>
                    </Row>
                    <Row className="p-3">
                        <FormGroup>
                            <Label for="productBundleSelect">{t('products')}</Label>
                            <Select
                                value={bundleProducts}
                                isOptionDisabled={option =>
                                    bundleProductCategories.some(pc => {
                                        return pc.value === option.productCategoryId;
                                    })
                                }
                                onChange={handleProductChange}
                                id="productBundleSelect"
                                options={dropdownProducts()}
                                isMulti
                                className="bundleDiscountsSelect"
                                label={t('products')}
                                placeholder={t('selectDiscountRuleProducts')}
                            />
                        </FormGroup>
                    </Row>

                    <div className="text-right">
                        <Button onClick={() => validatePage('products')}>{t('next')}</Button>
                    </div>
                </>
            )}
            {activeTab === 'Amount' && (
                <div>
                    <div className="bundleListContainer  mb-4">
                        <ListGroup>
                            {listifyProductsAndCategories().map((listItem, i) => {
                                if (listItem.listTitle)
                                    return (
                                        <ListGroupItem key={`${listItem.title}-${i}`}>
                                            <Row className="se-paragraph-base">
                                                <Col className="text-info">{listItem.listTitle}</Col>
                                            </Row>
                                        </ListGroupItem>
                                    );
                                else if (listItem.itemType === 'productCategory') {
                                    return (
                                        <ListGroupItem key={listItem.id}>
                                            <Row className="se-paragraph-base">
                                                <Col lg="3" md="3" xs="3" style={{ width: '105px' }} className="my-auto no-overflow">
                                                    <span className="ml-1">
                                                        <b>{listItem.name}</b>
                                                    </span>
                                                </Col>
                                                <Col className="my-auto no-overflow" lg="4" xs="4">
                                                    {listItem.description}
                                                </Col>

                                                <Col
                                                    lg="auto"
                                                    xs="auto"
                                                    className="d-inline-flex my-auto"
                                                    md={{ size: 3, offset: 2 }}
                                                    sm={{ size: 3, offset: 3 }}
                                                >
                                                    <div className="number-input pl-2">
                                                        <Button onClick={() => handleProductCategoryAmount(listItem.id, 'minus')} flat>
                                                            -
                                                        </Button>
                                                        <input className="quantity" min="0" name="quantity" readOnly value={listItem.amount} type="number" />
                                                        <Button onClick={() => handleProductCategoryAmount(listItem.id, 'plus')} flat>
                                                            +
                                                        </Button>
                                                    </div>
                                                    <Label className="ml-2 mt-1">{t('pieces')}</Label>
                                                </Col>
                                            </Row>
                                        </ListGroupItem>
                                    );
                                }
                                return (
                                    <ListGroupItem key={listItem.id}>
                                        <Row className="se-paragraph-base">
                                            <Col lg="auto" xs="auto" md="auto" className="bundleAmountImgContainer">
                                                {listItem.imageUrl ? (
                                                    <img alt="product" className="bundleProductImage" src={listItem.imageUrl} />
                                                ) : (
                                                    <span className="icon-picture inventory-missing-image-icon bundleProductImage"></span>
                                                )}
                                            </Col>
                                            <Col lg="3" md="3" xs="3" className="my-auto no-overflow">
                                                <span className="ml-1">
                                                    <b>{listItem.name}</b>
                                                </span>
                                                <br />
                                                <span className="ml-1">
                                                    {' '}
                                                    {listItem.price} {listItem.currency}
                                                </span>
                                            </Col>
                                            <Col lg="auto" xs="auto" className="d-inline-flex my-auto" md={{ size: 3, offset: 2 }} sm={{ size: 3, offset: 3 }}>
                                                <div className="number-input">
                                                    <Button onClick={() => handleProductAmount(listItem.id, 'minus')} flat>
                                                        -
                                                    </Button>
                                                    <input className="quantity" min="0" name="quantity" value={listItem.amount} type="number" />
                                                    <Button onClick={() => handleProductAmount(listItem.id, 'plus')} flat>
                                                        +
                                                    </Button>
                                                </div>
                                                <Label className="ml-2 mt-1">{t('pieces')}</Label>
                                            </Col>
                                        </Row>
                                    </ListGroupItem>
                                );
                            })}
                        </ListGroup>
                    </div>

                    <Row>
                        <div className="text-left ml-1">
                            <Button
                                onClick={() => {
                                    setErrorMsg('');
                                    setActiveTab('Products');
                                }}
                            >
                                {t('back')}
                            </Button>
                        </div>
                        <div style={{ flex: 'auto' }} className="mr-2 text-right">
                            <Button onClick={() => validatePage('amount')}>{t('next')}</Button>
                        </div>
                    </Row>
                </div>
            )}
            {activeTab === 'Pricing' && (
                <>
                    {pricingOptionsVisible(listifyProductsAndCategories()) && (
                        <Row className="my-3">
                            <Col className="my-auto" lg="auto" sm="auto">
                                <RadioButton
                                    checked={itemPricing === 'item'}
                                    id="itemLevelTab"
                                    onChange={() => setItemPricing('item')}
                                    label={t('applyItemLevelLabel')}
                                />
                            </Col>
                            <Col className="my-auto" lg="auto" sm="auto">
                                <RadioButton
                                    checked={itemPricing === 'bundle'}
                                    id="bundleLevelTab"
                                    onChange={() => setItemPricing('bundle')}
                                    label={t('applyBundleLevelLabel')}
                                />
                            </Col>
                        </Row>
                    )}

                    {itemPricing === 'item' && (
                        <div className="bundleListContainer mb-4">
                            <div className="bundleItemPriceMessage">
                                <Icon>info_outline</Icon>
                                <span>{t('bundleItemPriceMessage')}</span>
                            </div>
                            <ListGroup className="mb-2">
                                {listifyProductsAndCategories().map(listItem => {
                                    if (listItem.listTitle)
                                        return (
                                            <ListGroupItem>
                                                <Row className="se-paragraph-base">
                                                    <Col className="text-info">{listItem.listTitle}</Col>
                                                </Row>
                                            </ListGroupItem>
                                        );
                                    const bundleDiscountItem = bundleDiscountData.find(p => p.id === listItem.id);
                                    const checkType = type => bundleDiscountData.find(p => p.bundleDiscountType === type && p.id === listItem.id);
                                    return (
                                        <ListGroupItem key={listItem.id}>
                                            <Row className="se-paragraph-base">
                                                <Col lg="4" xs="3" md="12" className="my-auto no-overflow">
                                                    <span className="ml-1">
                                                        <b>
                                                            {listItem.name} ({listItem.amount})
                                                        </b>
                                                    </span>
                                                    <br />
                                                    <span className="ml-1">
                                                        {listItem.price ? `${listItem.price * listItem.amount.toFixed(2)} ${listItem.currency}` : ''}
                                                    </span>
                                                </Col>

                                                <Col lg="4">
                                                    <RadioButton
                                                        checked={checkType('percentage')}
                                                        id={`percentageRadio_${listItem.id}`}
                                                        onChange={() => handleItemDiscountType('percentage', listItem.id)}
                                                        label={t('percentageDiscount')}
                                                        name="pricingType"
                                                    />
                                                    <RadioButton
                                                        checked={checkType('fixed')}
                                                        id={`fixedRadio_${listItem.id}`}
                                                        onChange={() => handleItemDiscountType('fixed', listItem.id)}
                                                        label={t('fixedPriceDiscount')}
                                                        name="pricingType"
                                                    />
                                                    <RadioButton
                                                        checked={checkType('free')}
                                                        id={`freeRadio_${listItem.id}`}
                                                        onChange={() => handleItemDiscountType('free', listItem.id)}
                                                        label={t('freeDiscount')}
                                                        name="pricingType"
                                                    />
                                                </Col>
                                                <Col lg="4" md="6" xs="auto" className="d-inline-flex my-auto">
                                                    {bundleDiscountItem?.bundleDiscountType && bundleDiscountItem?.bundleDiscountType !== 'free' && (
                                                        <Input
                                                            value={bundleDiscountItem ? bundleDiscountItem.value : listItem.price}
                                                            key={`${listItem.id}-${bundleDiscountItem?.bundleDiscountType}`}
                                                            placeholder={checkType('percentage') ? '%' : t('productFixedPrice')}
                                                            onChange={e => handleItemDiscountAmount(e, listItem.id)}
                                                            type="number"
                                                        />
                                                    )}
                                                </Col>
                                            </Row>
                                        </ListGroupItem>
                                    );
                                })}
                            </ListGroup>
                        </div>
                    )}

                    {itemPricing === 'bundle' && (
                        <Row className="se-paragraph-base my-4">
                            <Col lg="3" md="5">
                                <Input
                                    disabled={bundleDiscountFixedAmount}
                                    onChange={e => handleBundleDiscountAmount(e, 'percentage')}
                                    placeholder={t('percentageBundleDiscountInputPlaceholder')}
                                    value={bundleDiscountPercentageAmount}
                                    type="number"
                                />
                            </Col>
                            <Col lg="1" md="1" className="my-auto">
                                OR
                            </Col>
                            <Col lg="3" md="5">
                                <Input
                                    disabled={bundleDiscountPercentageAmount}
                                    onChange={e => handleBundleDiscountAmount(e, 'fixed')}
                                    placeholder={t('fixedPriceBundleDiscountInputPlaceholder')}
                                    value={bundleDiscountFixedAmount}
                                    type="number"
                                />
                            </Col>
                        </Row>
                    )}

                    <Row>
                        <div className="text-left ml-1">
                            <Button
                                onClick={() => {
                                    setErrorMsg('');
                                    setActiveTab('Amount');
                                }}
                            >
                                {t('back')}
                            </Button>
                        </div>
                        <div style={{ flex: 'auto' }} className="mr-2 text-right">
                            <Button onClick={() => validatePage('Pricing')}>{t('next')}</Button>
                        </div>
                    </Row>
                </>
            )}
            {activeTab === 'Scheduling' && (
                <>
                    <Row className="mt-3">
                        <Col xs="12" lg="5">
                            <label className="se-label "> {t('scheduledDiscountDateRangeLabel')}</label>
                            <DateRangePicker
                                className="bundledDiscount-daterangePicker"
                                minDate={Scheduling.startDate ? new Date(Scheduling.startDate) : new Date()}
                                required
                                minDetail="year"
                                format="dd.MM.yyyy"
                                value={[Scheduling.startDate, Scheduling.endDate]}
                                onChange={handleDateRangeChange}
                            />
                        </Col>

                        <Col xs="12" md="3" lg="3" className="ml-2 align-self-end">
                            <label className="se-label "> {t('scheduledDiscountTimeRangeLabel')}</label>
                            <TimePicker withoutIcon theme="classic" time={Scheduling.startTime} onTimeChange={time => onTimeChange(time, 'start')} />
                        </Col>

                        <Col xs="12" md="3" lg="3" className="ml-4 align-self-end">
                            <TimePicker withoutIcon theme="classic" time={Scheduling.endTime} onTimeChange={time => onTimeChange(time, 'end')} />
                        </Col>
                    </Row>

                    <Row className="mb-4">
                        <Col xs="12" lg="6" className="mt-3">
                            <label className="se-label">{t('recurrence')}</label>

                            <div className={`d-flex justify-content-between ${areRecurrenceAvailable() ? '' : 'recurrenceDaysDisabled'}`}>
                                <div
                                    role="button"
                                    onClick={() => (areRecurrenceAvailable() ? selectRecurrencyDay('Mon') : undefined)}
                                    className={`recurrenceWeekdayBtn rounded-circle d-inline-flex ${isDayChecked('Mon')} `}
                                >
                                    <span>{t('weekDayAbrvMonday')}</span>
                                </div>
                                <div
                                    role="button"
                                    onClick={() => (areRecurrenceAvailable() ? selectRecurrencyDay('Tue') : undefined)}
                                    className={`recurrenceWeekdayBtn rounded-circle d-inline-flex ${isDayChecked('Tue')}`}
                                >
                                    <span>{t('weekDayAbrvTuesday')}</span>
                                </div>
                                <div
                                    role="button"
                                    onClick={() => (areRecurrenceAvailable() ? selectRecurrencyDay('Wed') : undefined)}
                                    className={`recurrenceWeekdayBtn rounded-circle d-inline-flex ${isDayChecked('Wed')}`}
                                >
                                    <span>{t('weekDayAbrvWednesday')}</span>
                                </div>
                                <div
                                    role="button"
                                    onClick={() => (areRecurrenceAvailable() ? selectRecurrencyDay('Thu') : undefined)}
                                    className={`recurrenceWeekdayBtn rounded-circle d-inline-flex ${isDayChecked('Thu')}`}
                                >
                                    <span>{t('weekDayAbrvThursday')}</span>
                                </div>
                                <div
                                    role="button"
                                    onClick={() => (areRecurrenceAvailable() ? selectRecurrencyDay('Fri') : undefined)}
                                    className={`recurrenceWeekdayBtn rounded-circle d-inline-flex ${isDayChecked('Fri')}`}
                                >
                                    <span>{t('weekDayAbrvFriday')}</span>
                                </div>
                                <div
                                    role="button"
                                    onClick={() => (areRecurrenceAvailable() ? selectRecurrencyDay('Sat') : undefined)}
                                    className={`recurrenceWeekdayBtn rounded-circle d-inline-flex ${isDayChecked('Sat')}`}
                                >
                                    <span>{t('weekDayAbrvSaturday')}</span>
                                </div>
                                <div
                                    role="button"
                                    onClick={() => (areRecurrenceAvailable() ? selectRecurrencyDay('Sun') : undefined)}
                                    className={`recurrenceWeekdayBtn rounded-circle d-inline-flex ${isDayChecked('Sun')}`}
                                >
                                    <span>{t('weekDayAbrvSunday')}</span>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <div className="text-left ml-1">
                            <Button
                                onClick={() => {
                                    setErrorMsg('');
                                    setActiveTab('Pricing');
                                }}
                            >
                                {t('back')}
                            </Button>
                        </div>

                        <div style={{ flex: 'auto' }} className="mr-2 text-right">
                            <Button onClick={() => validatePage('Scheduling')}>{t('next')}</Button>
                        </div>
                    </Row>
                </>
            )}
            {activeTab === 'userRestrict' && (
                <div>
                    <InfoBanner message={t('userRestrictHelperText')} />
                    <Row className="expiryUserDiscount mt-4">
                        <Col lg="12" className="text-center mt-1 d-flex">
                            <CustomToggle
                                options={[
                                    { text: t('userRestrict'), value: true },
                                    { text: t('userNotRestrict'), value: false },
                                ]}
                                selectedOption={userRestricted}
                                onClick={() => handleUserRestrictToggle()}
                            />
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <div className="text-left ml-1">
                            <Button
                                onClick={() => {
                                    setActiveTab('Scheduling');
                                }}
                            >
                                {t('back')}
                            </Button>
                        </div>
                        <div style={{ flex: 'auto' }} className="mr-2 text-right">
                            <Button onClick={() => setActiveTab('CustomImage')}>{t('next')}</Button>
                        </div>
                    </Row>
                </div>
            )}
            {activeTab === 'CustomImage' && (
                <>
                    {bundleImageUpload()}
                    <Row>
                        <div className="text-left ml-1">
                            <Button
                                onClick={() => {
                                    setErrorMsg('');
                                    setActiveTab('userRestrict');
                                }}
                            >
                                {t('back')}
                            </Button>
                        </div>

                        <div style={{ flex: 'auto' }} className="mr-2 text-right">
                            <Button onClick={() => validatePage('CustomImage')}>{t('Save')}</Button>
                        </div>
                    </Row>
                </>
            )}
        </div>
    );
}
