import moment from 'moment';

function transactionDetailsFilter(query, filter) {
    if (filter.filterBy === 'locations') {
        query.filters.push({
            dimension: 'TransactionDetails.locationName',
            operator: 'equals',
            values: filter.values,
        });
    } else if (filter.filterBy === 'cabinets') {
        query.filters.push({
            dimension: 'TransactionDetails.cabinetId',
            operator: 'equals',
            values: filter.values.map(cabinet => cabinet.deviceCode),
        });
    }
}

function transactionsFilter(query, filter) {
    if (filter.filterBy === 'locations') {
        query.filters.push({
            dimension: 'Transactions.locationName',
            operator: 'equals',
            values: filter.values,
        });
    } else if (filter.filterBy === 'cabinets') {
        query.filters.push({
            dimension: 'Transactions.deviceCode',
            operator: 'equals',
            values: filter.values.map(cabinet => cabinet.deviceCode),
        });
    }
}

function consumerResponseFilter(query, filter) {
    if (filter.filterBy === 'locations') {
        query.filters.push({
            dimension: 'Location.name',
            operator: 'equals',
            values: filter.values,
        });
    } else if (filter.filterBy === 'cabinets') {
        query.filters.push({
            dimension: 'Device_CF.deviceCode',
            operator: 'equals',
            values: filter.values.map(cabinet => cabinet.deviceCode),
        });
    }
}

function applyTransactionsDimensionFromFilterToQuery(query, filter) {
    if (filter.filterBy === 'locations') {
        query.dimensions.push('Transactions.locationName');
    } else if (filter.filterBy === 'cabinets') {
        query.dimensions.push('Transactions.deviceName');
    }
}

function applyFilterToQuery(query, filter) {
    if (filter.filterBy === 'locations') {
        query.filters.push({
            dimension: 'Location.name',
            operator: 'equals',
            values: filter.values,
        });
    } else if (filter.filterBy === 'cabinets') {
        query.filters.push({
            dimension: 'Device.deviceCode',
            operator: 'equals',
            values: filter.values.map(cabinet => cabinet.deviceCode),
        });
    }
}

function applyTimeDimension(query, dateRange, granularity) {
    const timeDimension = {
        dimension: 'Transactions.timestamp',
    };
    if (dateRange !== 'All time' && dateRange) timeDimension.dateRange = dateRange;
    if (granularity) timeDimension.granularity = granularity;

    query.timeDimensions.push(timeDimension);
}
function applyTimeDimensionForConsumerResponse(query, dateRange, granularity) {
    const timeDimension = {
        dimension: 'ConsumerFeedback.created',
    };
    if (dateRange !== 'All time' && dateRange) timeDimension.dateRange = dateRange;
    if (granularity) timeDimension.granularity = granularity;

    query.timeDimensions.push(timeDimension);
}

export function getDashboardSummaryDataCubeQuery(dateRange, filter) {
    const query = {
        dimensions: [],
        timeDimensions: [],
        measures: ['Transactions.orderSum', 'Transactions.count', 'Transactions.itemsSoldCount', 'Transactions.customersCount'],
        filters: [
            {
                dimension: 'Transactions.status',
                operator: 'equals',
                values: ['DONE', 'PARTIAL'],
            },
        ],
    };

    applyTimeDimension(query, dateRange);

    if (filter) {
        transactionsFilter(query, filter);
    }

    return query;
}

export function getDashboardSummaryPaidAmountCubeQuery(dateRange, filter) {
    const query = {
        dimensions: [],
        timeDimensions: [],
        measures: ['TransactionDetails.orderSum'],
        filters: [
            {
                dimension: 'TransactionDetails.status',
                operator: 'equals',
                values: ['DONE', 'PARTIAL'],
            },
        ],
    };

    const timeDimension = {
        dimension: 'TransactionDetails.timestamp',
    };
    if (dateRange !== 'All time' && dateRange) timeDimension.dateRange = dateRange;

    query.timeDimensions.push(timeDimension);

    if (filter) {
        transactionDetailsFilter(query, filter);
    }

    return query;
}

/**
 * Get total sales CubeJS query.
 *
 * @param {string} dateRange
 */
export function getTotalSalesCubeQuery(dateRange, filter, granularity) {
    const query = {
        dimensions: [],
        timeDimensions: [],
        measures: ['Transactions.orderSum'],
        filters: [
            {
                dimension: 'Transactions.status',
                operator: 'equals',
                values: ['DONE', 'PARTIAL'],
            },
        ],
    };

    applyTimeDimension(query, dateRange, granularity);

    if (filter) {
        transactionsFilter(query, filter);
    }

    return query;
}

/**
 * Get total sales CubeJS query.
 *
 * @param {string} dateRange
 */
export function getTotalSalesTransactionDetails(dateRange, filter, granularity) {
    const query = {
        dimensions: [],
        timeDimensions: [],
        measures: ['Transactions.orderSum'],
        filters: [
            {
                dimension: 'Transactions.status',
                operator: 'equals',
                values: ['DONE', 'PARTIAL'],
            },
        ],
    };

    const timeDimension = {
        dimension: 'Transactions.timestamp',
    };
    if (dateRange !== 'All time' && dateRange) timeDimension.dateRange = dateRange;
    if (granularity) timeDimension.granularity = granularity;

    query.timeDimensions.push(timeDimension);

    if (filter) {
        transactionsFilter(query, filter);
        if (granularity) applyTransactionsDimensionFromFilterToQuery(query, filter);
    }

    return query;
}

/**
 * Get total unpaid amount CubeJS query.
 *
 * @param {string} dateRange
 */
export function getUnpaidSalesCubeQuery(dateRange, filter, granularity) {
    const query = {
        dimensions: [],
        timeDimensions: [],
        measures: ['TransactionDetails.unpaidSales'],
        filters: [
            {
                dimension: 'TransactionDetails.status',
                operator: 'equals',
                values: ['PARTIAL', 'FAIL', 'CANCEL', 'TIMEOUT'],
            },
        ],
    };

    const timeDimension = {
        dimension: 'TransactionDetails.timestamp',
    };
    if (dateRange !== 'All time' && dateRange) timeDimension.dateRange = dateRange;
    if (granularity) timeDimension.granularity = granularity;

    query.timeDimensions.push(timeDimension);

    if (filter) {
        transactionDetailsFilter(query, filter);
    }

    return query;
}

/**
 * Get total unpaid transaction count CubeJS query.
 *
 * @param {string} dateRange
 */
export function getUnpaidSalesCountCubeQuery(dateRange, filter, granularity) {
    const query = {
        dimensions: [],
        timeDimensions: [],
        measures: ['TransactionDetails.unpaidTransactions'],
        filters: [
            {
                dimension: 'TransactionDetails.status',
                operator: 'equals',
                values: ['PARTIAL', 'FAIL', 'CANCEL', 'TIMEOUT'],
            },
        ],
    };

    const timeDimension = {
        dimension: 'TransactionDetails.timestamp',
    };
    if (dateRange !== 'All time' && dateRange) timeDimension.dateRange = dateRange;
    if (granularity) timeDimension.granularity = granularity;

    query.timeDimensions.push(timeDimension);

    if (filter) {
        transactionDetailsFilter(query, filter);
    }

    return query;
}

/**
 * Get the expired products information
 */
export function getExpiredProductsCubeQuery(dateRange, filter, granularity) {
    const query = {
        dimensions: [
            'ExpiredProducts.productName',
            'ExpiredProducts.barcode',
            'ExpiredProducts.price',
            'ExpiredProducts.currency',
            'Device.deviceName',
            'Device.region',
        ],
        measures: ['ExpiredProducts.count'],
        filters: [
            {
                dimension: 'ExpiredProducts.reason',
                operator: 'equals',
                values: ['expiry'],
            },
        ],
        timeDimensions: [
            {
                dimension: 'ExpiredProducts.expiryDate',
            },
        ],
    };

    if (granularity) query.timeDimensions[0].granularity = granularity;
    if (dateRange && dateRange !== 'All time') query.timeDimensions[0].dateRange = dateRange;

    if (filter) {
        applyFilterToQuery(query, filter);
    }
    return query;
}

export function getDiscountCubeQuery(dateRange, filter, granularity) {
    const query = {
        dimensions: [
            'Transactions.barcode',
            'Transactions.soldUnitsPrice',
            'Transactions.oneUnitPrice',
            'Transactions.imageUrl',
            'Transactions.originalPrice',
            'Transactions.discountName',
            'Transactions.discountAmount',
            'Transactions.productName',
            'Transactions.soldCount',
            'Transactions.isDiscounted',
            'Transactions.discountAmountType',
            'Transactions.currency',
            'Transactions.deviceName',
            'Transactions.locationName',
        ],
        filters: [
            {
                dimension: 'Transactions.isDiscounted',
                operator: 'set',
            },
        ],
        timeDimensions: [
            {
                dimension: 'Transactions.timestamp',
            },
        ],
    };
    // granularity is set to second on purpose here to get all the products sold per second.
    // if you set granularity to hour, cubejs merges all the transactions that is made in the same hour into one
    // this problem causes inconsistency with discount and sales analytics.
    if (granularity) query.timeDimensions[0].granularity = 'second';
    if (dateRange && dateRange !== 'All time') query.timeDimensions[0].dateRange = dateRange;

    if (filter) {
        transactionsFilter(query, filter);
    }

    return query;
}

export function getProductsTransactionsCubeQuery(dateRange, filter, granularity) {
    const query = {
        dimensions: [
            'Transactions.barcode',
            'Transactions.productName',
            'Transactions.soldUnitsPrice',
            'Transactions.imageUrl',
            'Transactions.deviceName',
            'Transactions.currency',
            'Transactions.soldCount',
            'Transactions.locationName',
        ],
        measures: ['Transactions.orderSum'],
        filters: [
            {
                dimension: 'Transactions.status',
                operator: 'equals',
                values: ['DONE', 'PARTIAL'],
            },
        ],
        timeDimensions: [
            {
                dimension: 'Transactions.timestamp',
            },
        ],
    };
    // granularity is set to second on purpose here to get all the products sold per second.
    // if you set granularity to hour, cubejs merges all the transactions that is made in the same hour into one
    // this problem causes inconsistency with waste and sales analytics.
    if (granularity) {
        if (granularity) query.timeDimensions[0].granularity = 'second';
    }

    if (dateRange && dateRange !== 'All time') query.timeDimensions[0].dateRange = dateRange;

    if (filter) {
        transactionsFilter(query, filter);
    }
    return query;
}

/**
 *
 * get the Unit price of products
 */
export function getProductsUnitPriceCubeQuery(filter) {
    const query = {
        dimensions: ['ProductPrice.price', 'ProductPrice.currency', 'ProductType.code'],
        segments: [],
        measures: [],
        filters: [],
        timeDimensions: [],
    };

    if (filter) {
        applyFilterToQuery(query, filter);
    }

    return query;
}

/**
 * Get total transaction count CubeJS query.
 *
 * @param {string} dateRange
 */
export function getTotalTransactionCountCubeQuery(dateRange, filter, granularity) {
    const query = {
        dimensions: [],
        timeDimensions: [],
        measures: ['Transactions.count'],
        filters: [
            {
                dimension: 'Transactions.status',
                operator: 'equals',
                values: ['DONE', 'PARTIAL'],
            },
        ],
    };

    applyTimeDimension(query, dateRange, granularity);

    if (filter) {
        transactionsFilter(query, filter);
        if (granularity) applyTransactionsDimensionFromFilterToQuery(query, filter); // only for chart data
    }

    return query;
}

/**
 * Get total transaction count CubeJS query.
 *
 * @param {string} dateRange
 */
export function getTotalItemsSoldCountCubeQuery(dateRange, filter, granularity) {
    const query = {
        measures: ['Transactions.itemsSoldCount'],
        timeDimensions: [],
        filters: [
            {
                dimension: 'Transactions.status',
                operator: 'equals',
                values: ['DONE', 'PARTIAL'],
            },
        ],
    };

    applyTimeDimension(query, dateRange, granularity);

    if (filter) {
        transactionsFilter(query, filter);
        if (granularity) applyTransactionsDimensionFromFilterToQuery(query, filter); // only for chart data
    }

    return query;
}

/**
 * Get total unique customers count CubeJS query.
 *
 * @param {string} granularity Either year, month or week
 */
export function getTotalUniqueCustomersCountCubeQuery(dateRange, filter, granularity) {
    const query = {
        dimensions: [],
        timeDimensions: [],
        measures: ['Transactions.customersCount'],
        filters: [
            {
                dimension: 'Transactions.status',
                operator: 'equals',
                values: ['DONE', 'PARTIAL'],
            },
        ],
    };

    applyTimeDimension(query, dateRange, granularity);

    if (filter) {
        transactionsFilter(query, filter);
        if (granularity) applyTransactionsDimensionFromFilterToQuery(query, filter); // only for chart data
    }

    return query;
}

/**
 * Get consumer response ratings CubeJS query.
 *
 * @param {string} dateRange Daterange for Cube Query
 */
export function getTotalConsumerResponseRatingsCubeQuery(dateRange, filter) {
    const query = {
        measures: ['ConsumerFeedback.rating100Count', 'ConsumerFeedback.rating66Count', 'ConsumerFeedback.rating33Count', 'ConsumerFeedback.rating0Count'],
        timeDimensions: [],
        dimensions: [],
        filters: [],
    };

    applyTimeDimensionForConsumerResponse(query, dateRange);

    if (filter && filter.filterBy === 'locations') {
        query.dimensions.push('Location.name');
    } else if (filter && filter.filterBy === 'cabinets') {
        query.dimensions.push('Device_CF.deviceName');
    }

    if (filter) {
        consumerResponseFilter(query, filter);
    }

    return query;
}
/**
 * Get consumer responses CubeJS query.
 *
 * @param {string} dateRange Daterange for Cube Query
 */
export function getTotalConsumerResponsesCubeQuery(dateRange, filter) {
    const query = {
        measures: [],
        timeDimensions: [],
        dimensions: ['ConsumerFeedback.orderId', 'ConsumerFeedback.created', 'ConsumerFeedback.deviceCode', 'ConsumerFeedback.wish', 'ConsumerFeedback.rating'],
        filters: [],
    };

    applyTimeDimensionForConsumerResponse(query, dateRange);

    if (filter) {
        consumerResponseFilter(query, filter);
    }
    return query;
}

/**
 * Get total products sold and total product sales CubeJS query.
 *
 * @param {string} dateRange
 */
export function getTotalProductsSoldCubeQuery(dateRange, filter) {
    const query = {
        measures: ['Transactions.orderSum', 'Transactions.itemsSoldCount'],
        timeDimensions: [],
        dimensions: ['Transactions.productName'],
        filters: [
            {
                dimension: 'Transactions.status',
                operator: 'equals',
                values: ['DONE', 'PARTIAL'],
            },
        ],
    };

    applyTimeDimension(query, dateRange);

    if (filter) {
        transactionsFilter(query, filter);
    }

    return query;
}

/**
 * Get total transactions cancelled CubeJS query.
 *
 * @param {string} dateRange
 */
export function getTotalCanceledCubeQuery(dateRange, filter, granularity) {
    const query = {
        dimensions: [],
        timeDimensions: [],
        measures: ['Transactions.orderSum', 'Transactions.count'],
        filters: [
            {
                dimension: 'Transactions.status',
                operator: 'equals',
                values: ['CANCEL', 'TIMEOUT', 'FAIL'],
            },
        ],
    };

    applyTimeDimension(query, dateRange, granularity);

    if (filter) {
        transactionsFilter(query, filter);
        if (granularity) applyTransactionsDimensionFromFilterToQuery(query, filter); // only for chart data
    }

    return query;
}

/**
 * Get average sales CubeJS query.
 *
 * @param {string} dateRange
 */
export function getAvgSalesCubeQuery(dateRange, filter, granularity) {
    const query = {
        dimensions: [],
        timeDimensions: [],
        measures: ['Transactions.averageOrderSum'],
        filters: [
            {
                dimension: 'Transactions.status',
                operator: 'equals',
                values: ['DONE', 'PARTIAL'],
            },
        ],
    };

    applyTimeDimension(query, dateRange, granularity);

    if (filter) {
        transactionsFilter(query, filter);
        if (granularity) applyTransactionsDimensionFromFilterToQuery(query, filter); // only for chart data
    }

    return query;
}

/**
 * Get average items CubeJS query.
 *
 * @param {string} dateRange
 */
export function getAvgItemsCountCubeQuery(dateRange, filter, granularity) {
    const query = {
        dimensions: [],
        timeDimensions: [],
        measures: ['Transactions.averageItemsCount'],
        filters: [
            {
                dimension: 'Transactions.status',
                operator: 'equals',
                values: ['DONE', 'PARTIAL'],
            },
        ],
    };

    applyTimeDimension(query, dateRange, granularity);

    if (filter) {
        transactionsFilter(query, filter);
        if (granularity) applyTransactionsDimensionFromFilterToQuery(query, filter); // only for chart data
    }

    return query;
}

/**
 * Get custom cube query
 * @param {array} measures
 * @param {array} dimensions
 * @param {string} dateRange
 * @param {string} granularity
 * @param {Boolean} renewQuery
 */
export function getCustomCubeQuery(
    measures = [],
    dimensions = [],
    dateRange = 'All time',
    granularity = 'w/o grouping',
    filters = [],
    segments = [],
    renewQuery = true
) {
    let query = {
        measures: [...measures],
        timeDimensions: [],
        filters: [...filters],
        dimensions: [...dimensions],
        segments: [...segments],
        renewQuery: renewQuery,
    };

    let timeDimension = {};

    try {
        if (query.dimensions.length && query.dimensions[0].split('.')[0] === 'InventoryHistory') {
            timeDimension = {
                dimension: 'InventoryHistory.readtime',
            };
        } else {
            timeDimension = {
                dimension: 'Transactions.timestamp',
            };
        }
    } catch (err) {
        console.log(err);
    }

    if (dateRange && dateRange !== 'All time') {
        timeDimension.dateRange = dateRange;
    }
    if (granularity && granularity !== 'w/o grouping') {
        timeDimension.granularity = granularity;
    }

    if (segments && segments.length > 0) {
        if (segments.includes('InventorySnapshot.latest')) {
            timeDimension.dimension = 'InventorySnapshot.readtime';
            delete timeDimension.dateRange;
        }
    }

    if (dateRange && Array.isArray(dateRange)) {
        timeDimension.dateRange = dateRange;
        // Day granularity is default only if it's preDefined Inventory query!
        if (timeDimension.dimension === 'InventoryHistory.readtime') {
            timeDimension.granularity = 'day';
        }
    }

    if (
        measures.includes('DeviceTemperatures.averageComputedTemperature') ||
        measures.includes('DeviceTemperatures.minComputedTemperature') ||
        measures.includes('DeviceTemperatures.maxComputedTemperature')
    ) {
        timeDimension.dimension = 'DeviceTemperatures.timestamp';
    }
    query.timeDimensions.push(timeDimension);

    return query;
}

export function getDefaultGranularity(dateRange) {
    if (dateRange === 'Today' || dateRange === 'Yesterday') return 'hour';
    if (
        dateRange === 'This week' ||
        dateRange === 'This month' ||
        dateRange === 'Last 7 days' ||
        dateRange === 'Last 30 days' ||
        dateRange === 'Last week' ||
        dateRange === 'Last month'
    )
        return 'day';
    if (dateRange === 'Last year' || dateRange === 'Last quarter' || dateRange === 'All time' || dateRange === 'This year') return 'month';
    return 'week';
}

const resolveDate = (from, to) => {
    const dateFrom = moment(from).format('YYYY-MM-DD');
    const dateTo = moment(to).format('YYYY-MM-DD');

    const days = moment(to).diff(from, 'days');
    let granularity = 'hour';

    if (days <= 1) {
        granularity = 'hour';
    } else if (days > 1 && days <= 31) {
        granularity = 'hour';
    } else if (days > 31 && days <= 90) {
        granularity = 'day';
    } else {
        granularity = 'week';
    }

    return {
        range: [dateFrom, dateTo],
        granularity,
    };
};

/**
 *
 * @param {string} deviceId the deviceId of the device under discussion. Can be changed to device code when called if deviceCode and Id are similar
 * @returns
 */
export function getTemperatureCubeQuery(deviceId, from, to, measure) {
    const date = resolveDate(from, to);

    const cubeQuery = {
        dimensions: [],
        measures: ['DeviceTemperatures.averageComputedTemperature'],
        timeDimensions: [
            {
                dimension: 'DeviceTemperatures.timestamp',
                dateRange: date.range,
                granularity: date.granularity,
            },
        ],
        filters: [
            {
                dimension: 'DeviceTemperatures.deviceCode',
                operator: 'equals',
                values: [deviceId],
            },
        ],
    };
    return cubeQuery;
}

/**
 *
 * @param {string} deviceId the deviceId of the device under discussion. Can be changed to device code when called if deviceCode and Id are similar
 * @returns
 */
export function getCurrentTemperatureCubeQuery(deviceId) {
    return {
        dimensions: ['Device.currentTemperature'],
        measures: [],
        timeDimensions: [],
        filters: [
            {
                dimension: 'Device.id',
                operator: 'equals',
                values: [deviceId],
            },
        ],
    };
}
