import React, { useEffect, useState } from 'react';
import { withRouter, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { Form } from 'reactstrap';
import validator from 'validator';

import { barcodeTypes } from './config.js';
import { createProduct, updateProduct, uploadProductImage, uploadProductImageSuccess, removeProductImage } from '../../actions/products.js';

import BasicDetail from './sections/basicDetail.jsx';
import AdditionalDetail from './sections/additionalDetail.jsx';
import ProductTypes from './sections/productTypes.jsx';
import Classification from './sections/classification.jsx';
import ProductSaving from './segments/productRequesting.jsx';
import { fetchVatCategories } from '../../actions/vatCategories.js';
import TagConfiguration from './sections/tagConfiguration.jsx';

const currenciesAll = require('../../commonCurrency.json');
const currencies = JSON.parse(process.env.REACT_APP_CURRENCIES).map(code => currenciesAll[code]);
if (!currencies || currencies.length === 0) {
    console.error('Missing currencies in REACT_APP_CURRENCIES configuration!');
    throw new Error('Missing currencies in REACT_APP_CURRENCIES configuration!');
}

const ProductCreateAndEdit = props => {
    // get if ID is present in the parameter
    const { id } = useParams();
    const {
        products,
        productCategories,
        vatCategories,
        suppliers,
        productLabels,
        productTypes,
        createProduct,
        updateProduct,
        uploadProductImage,
        removeProductImage,
        cabinets,
        organization,
        t,
    } = props;

    const [activeTab, setActiveTab] = useState(1);
    const [isSavingProduct, setIsSavingProduct] = useState(false);
    const [submitProductToApi, setSubmitProductToApi] = useState(false);
    // eslint-disable-next-line
    const [initalUserGroup, setInitalUserGroup] = useState(organization);
    // TODO remove the state this is a bad way to use state
    const [productData, setProductData] = useState({
        barcode: undefined,
        barcodeType: barcodeTypes[0]?.value,
        barcodeCheckSum: undefined,
        name: '',
        price: 1,
        locationPricings: [],
        timeToLiveDays: undefined,
        currency: currencies[0].symbol,
        imageUrl: products.imageUrl || undefined,
        productType: undefined,
        isPrebooked: false,
        bannerText: '',
        isFrozen: false,
        vatCategoryId:
            vatCategories && vatCategories.vatCategories
                ? vatCategories.vatCategories.filter(vatCategory => vatCategory.name.toLowerCase().includes('default'))[0]?.id ||
                  vatCategories.vatCategories[0]?.id
                : '',
        productCategoryId: undefined,
        supplierId: undefined,
        description: undefined,
        productClass: [],
        labels: [],
        tagSensitivity:-1
    });

    const [validationErrors, setValidationErrors] = useState({});

    useEffect(() => {
        //if someone tries to access this page directly without loading product catalog page first,
        // redirect to the products catalog page so that all the necessary products and states are fetched in redux
        if (!products.fetched) {
            props.history.push('/products');
        }

        // if its update route, prefill the products data to the form
        if (id) {
            const selectedProduct = products.products.find(product => product.id === id);
            
            //if invalid product id in url, redirect to the catalog page
            if (!selectedProduct) {
                props.history.push('/products');
                return;
            }

            setProductData({
                barcode: selectedProduct.barcode,
                barcodeCheckSum: undefined,
                barcodeType: selectedProduct.barcodeType || barcodeTypes[0]?.value,
                name: selectedProduct.name,
                price: selectedProduct.price,
                locationPricings: (selectedProduct.prices || [])
                    .filter(price => price.locationId != null)
                    .filter(price => cabinets.locations.find(location => location.id === price.locationId))
                    .map(locPrice => ({ locationId: locPrice.locationId, price: locPrice.price, currency: locPrice.currency })),
                timeToLiveDays: selectedProduct.timeToLiveDays || selectedProduct.timeToLiveDays === 0 ? selectedProduct.timeToLiveDays : '',
                currency: selectedProduct.currency || currencies[0].symbol,
                imageUrl: selectedProduct.imageUrl,
                productType: selectedProduct.productType,
                isPrebooked: selectedProduct.isPrebooked ? selectedProduct.isPrebooked : false,
                bannerText: selectedProduct.bannerText || '',
                isFrozen: selectedProduct.isFrozen ? selectedProduct.isFrozen : false,
                vatCategoryId: selectedProduct.vatCategory
                    ? selectedProduct.vatCategory.id
                    : vatCategories.vatCategories.filter(vatCategory => vatCategory.name.toLowerCase().includes('default'))[0]?.id ||
                      vatCategories.vatCategories[0]?.id,
                productCategoryId: selectedProduct.productCategoryId,
                supplierId: selectedProduct.supplierId,
                description: selectedProduct.description,
                productClass: selectedProduct.productClass,
                labels: selectedProduct.labels || [],
                tagSensitivity:(selectedProduct.tagSensitivity !==undefined && selectedProduct.tagSensitivity>=0) ? selectedProduct.tagSensitivity : -1
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
     * if user changes organisation, we redirect back to the product catalog page.
     */
    useEffect(() => {
        if (initalUserGroup === organization) return;
        props.history.push('/products');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [organization]);

    useEffect(() => {
        if (!submitProductToApi) return;
        const errors = validateTheProductPayload();

        // if there are errors, redirect to the tab where error fields are and show errors
        if (Object.keys(errors).length) {
            setValidationErrors(errors);

            if (errors.name || errors.barcode || errors.timeToLiveDays) {
                setActiveTab(1);
                return;
            }

            if (errors.productType) {
                setActiveTab(2);
                return;
            }

            if(errors.productClass){
                setActiveTab(4);
                return
            }
            submitProductToApi(false);
        }

        const dataToSave = {
            ...productData,
            barcode:
                productData.barcodeCheckSum || productData.barcodeCheckSum === 0 ? `${productData.barcode}${productData.barcodeCheckSum}` : productData.barcode,
        };

        delete dataToSave.barcodeCheckSum;
       
        if (id) {
            updateProduct(dataToSave);
            setIsSavingProduct(true);
        } else {
            createProduct(dataToSave);
            setIsSavingProduct(true);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [submitProductToApi]);

    /**
     * validate the data before sending to the API
     * @returns object of errors
     */
    const validateTheProductPayload = () => {
        let errors = {};
        // product name is a mandatory field
        if (!productData.name) {
            errors.name = 'invalidProductName';
        }
        // check if the present timeToLiveDays is valid;
        if (productData.timeToLiveDays === 0 || productData.timeToLiveDays) {
            const isttlValid = validator.isInt(productData.timeToLiveDays.toString(), {
                min: 0,
                max: 32767,
            });

            if (!isttlValid) {
                errors.timeToLiveDays = 'invalidLifetime';
            }
        }

        // if barcode is invalid throw error
        if (!productData.barcode) {
            errors.barcode = 'invalidBarcodeLength';
        } else if (productData.barcode.match(/^[0-9]+$/) === null) {
            errors.barcode = 'invalidBarcodeDigits';
        } else if (productData.barcode.length + 1 !== barcodeTypes.find(type => type.value === productData.barcodeType)?.barcodeLength && !id) {
            errors.barcode = 'invalidBarcodeLength';
        }
        // packaging type is requrired
        if (!productData.productType) {
            errors.productType = 'productTypeRequired';
        }

        // if the array of product class contains all null, throw an error
        if(Array.isArray(productData.productClass) && productData.productClass.every(pClass => pClass === null)){
            errors.productClass = 'productClassRequired'
        }

        // negative price should be invalid
        // this infact is already dealt in input field - but just in case
        if (productData.price < 0) {
            errors.price = 'invalidPrice';
        }

        return errors;
    };

    /**
     * whenever product data is changed in local tabs and switched to next tab, save the state
     * @param {*} data
     *
     */
    const onProductDataChange = data => {
        setProductData(prevState => ({
            ...prevState,
            ...data,
        }));

        setSubmitProductToApi(false);
    };

    /**
     * request to go next, back or tab switch
     * @param {*} index
     * @param {*} tabChange
     */
    const tabNavigation = (index, tabChange = null, submitData = false) => {
        let navigation;
        if (submitData) {
            setSubmitProductToApi(true);
            return;
        }

        if (tabChange) {
            navigation = index;
        } else {
            navigation = activeTab + index;
        }
        if (navigation < 1) navigation = 1;
        if (navigation > 5) navigation = 5;

        setActiveTab(navigation);
    };

    const tabs = [
        {
            index: 1,
            title: 'Basic details',
            component: (
                <BasicDetail
                    productCategories={productCategories.productCategories || []}
                    vatCategories={vatCategories.vatCategories || []}
                    productDataChange={onProductDataChange}
                    selectedProduct={productData}
                    tabNavigation={tabNavigation}
                    mode={id ? 'edit' : 'create'}
                    uploadProductImage={uploadProductImage}
                    removeProductImage={removeProductImage}
                    uploadProductImageSuccess={uploadProductImageSuccess}
                    reduxProduct={products}
                    cabinets={cabinets}
                    validationErrors={validationErrors}
                    t={t}
                    history={props.history}
                    activeTab={activeTab}
                    updateValidationError={e => setValidationErrors(e)}
                    submitProductToApi={state => setSubmitProductToApi(state)}
                    isUpdating={id ? true : false}
                />
            ),
        },
        {
            index: 2,
            title: 'Packagings',
            component: (
                <ProductTypes
                    productTypes={productTypes.productTypes || []}
                    selectedProduct={productData}
                    activeTab={activeTab}
                    productDataChange={onProductDataChange}
                    tabNavigation={tabNavigation}
                    validationErrors={validationErrors}
                    updateValidationError={e => setValidationErrors(e)}
                    t={t}
                    submitProductToApi={state => setSubmitProductToApi(state)}
                    isUpdating={id ? true : false}
                />
            ),
        },
        {
            index: 3,
            title: 'Additional details',
            component: (
                <AdditionalDetail
                    suppliers={suppliers.suppliers || []}
                    productDataChange={onProductDataChange}
                    activeTab={activeTab}
                    selectedProduct={productData}
                    tabNavigation={tabNavigation}
                    t={t}
                    submitProductToApi={state => setSubmitProductToApi(state)}
                    isUpdating={id ? true : false}
                />
            ),
        },
        {
            index: 4,
            title: 'Classification',
            component: (
                <Classification
                    productLabels={productLabels.productLabels || []}
                    activeTab={activeTab}
                    productDataChange={onProductDataChange}
                    selectedProduct={productData}
                    tabNavigation={tabNavigation}
                    submitProductToApi={state => setSubmitProductToApi(state)}
                    t={t}
                    isUpdating={id ? true : false}
                    validationErrors={validationErrors}
                />
            ),
        },
        {
            index:5,
            title:'Tag settings',
            component:(
                <TagConfiguration 
                    t={t}
                    activeTab={activeTab}
                    tabNavigation={tabNavigation}
                    productDataChange={onProductDataChange}
                    submitProductToApi={state => setSubmitProductToApi(state)}
                    productTagSensitivityLevel={productData.tagSensitivity}
                />
            )
        }
    ];

    return (
        <div className="container-fluid content-wrapper">
            <ProductSaving
                requesting={products.requesting}
                errors={products.error}
                finished={products.finished}
                savingProduct={isSavingProduct}
                closeModal={() => {
                    removeProductImage();
                    props.history.push('/products');
                }}
                t={t}
            />
            <div className="products">
                <Form className="products__form">
                    {tabs.find(tab => tab.index === activeTab).component}
                </Form>
            </div>
        </div>
    );
};

export default withRouter(
    connect(
        state => ({
            products: state.products,
            productTypes: state.productTypes,
            productCategories: state.productCategories,
            vatCategories: state.vatCategories,
            suppliers: state.suppliers,
            productLabels: state.productLabels,
            cabinets: state.cabinets,
            organization: state.user.group.Name,
        }),
        {
            fetchVatCategories,
            createProduct,
            updateProduct,
            uploadProductImage,
            uploadProductImageSuccess,
            removeProductImage,
        }
    )(translate('main')(ProductCreateAndEdit))
);
