import React from 'react';
import ReactDOM from 'react-dom';
import { Route, Switch } from 'react-router';
import { Provider, connect } from 'react-redux';
import { I18nextProvider } from 'react-i18next';
import { ConnectedRouter } from 'connected-react-router';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@storaensods/seeds-core/dist/css/index.css';
import '@storaensods/seeds-core/dist/css/styles.css';
import 'react-sliding-pane/dist/react-sliding-pane.css';

import './index.css';
import i18n from './i18n.js';
import configureStore, { history } from './store.js';
import * as serviceWorker from './serviceWorker.js';
import App from './App.js';
import SignIn from './components/signin/signin.js';
import SignInCallback from './components/signin/callback/callback.js';

export const store = configureStore(/* provide initial state if any */);

if (module.hot) {
    module.hot.accept();
}

class PrivateRouteContainer extends React.Component {
    render() {
        return (
            <I18nextProvider i18n={i18n}>
                {/* Only display app if authenticated (else login page) */}
                {this.props.isAuthenticated ? (
                    <Route path="/" component={App} />
                ) : (
                    <Switch>
                        <Route path="/callback" component={SignInCallback} />
                        <Route path="*" component={SignIn} />
                    </Switch>
                )}
            </I18nextProvider>
        );
    }
}

const PrivateRoute = connect(state => ({
    isAuthenticated: state.user.isAuthenticated,
}))(PrivateRouteContainer);

ReactDOM.render(
    <Provider store={store}>
        <ConnectedRouter history={history}>
            <I18nextProvider i18n={i18n}>
                <Route path="/" component={PrivateRoute} />
            </I18nextProvider>
        </ConnectedRouter>
    </Provider>,
    document.getElementById('root') || document.createElement('div') // for testing purposes
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
