export const initState = {
    isFetching: false,
    isError: false,
    fetched: false,
    products: [],
    requesting: false,
    finished: false,
    error: '',
    imageStoring: false,
    imageUrl: '',
    imageStoringError: null,
    productUpdate: null,
    removingProduct: false,
    deleteProgress: {
        isDeleting: false,
        deleted: false,
        isError: false,
    },
    removingProductComplete: true,
    searchQuery: '',
};

export default function products(state = initState, action) {
    switch (action.type) {
        case 'REQUEST_PRODUCTS':
            return {
                ...state,
                isFetching: true,
                isError: false,
                deleteProgress: {
                    isDeleting: false,
                    deleted: false,
                    isError: false,
                },
                fetched: false,
            };
        case 'RECEIVE_PRODUCTS':
            return {
                ...state,
                isFetching: false,
                isError: false,
                products: action.products,
                lastUpdated: action.receivedAt,
                fetched: true,
            };
        case 'RECEIVE_PRODUCTS_ERROR':
            console.error(action.error);
            return {
                ...state,
                isFetching: false,
                isError: true,
                fetched: false,
                error: action.error.toString(),
            };
        case 'REQUEST_CREATE_PRODUCT':
            return {
                ...state,
                requesting: true,
                finished: false,
                error: '',
            };
        case 'RECEIVE_CREATE_PRODUCT':
            return {
                ...state,
                requesting: false,
                finished: true,
                error: '',
                products: [...action.product, ...state.products],
            };
        case 'REQUEST_ARCHIVE_PRODUCT':
            return {
                ...state,
                removingProduct: action.removingProduct,
            };
        case 'START_DELETE_PRODUCT':
            return {
                ...state,
                deleteProgress: {
                    isDeleting: true,
                    deleted: false,
                    isError: false,
                },
            };
        case 'DELETE_PRODUCT_SUCCESS':
            return {
                ...state,
                deleteProgress: {
                    isDeleting: false,
                    deleted: true,
                    isError: false,
                },
            };
        case 'DELETE_PRODUCT_ERROR':
            return {
                ...state,
                deleteProgress: {
                    isDeleting: false,
                    deleted: false,
                    isError: true,
                },
            };
        case 'RECEIVE_CREATE_PRODUCT_ERROR':
            console.error(action.error);
            return {
                ...state,
                requesting: false,
                finished: true,
                error: action.error.toString(),
            };
        case 'DISMISS_CREATE_PRODUCT_RESULT':
            return {
                ...state,
                requesting: false,
                finished: false,
                error: '',
            };
        case 'REQUEST_UPDATE_PRODUCT':
            return {
                ...state,
                requesting: true,
                finished: false,
                error: '',
                productUpdate: action.product,
            };
        case 'RECEIVE_UPDATE_PRODUCT':
            return {
                ...state,
                requesting: false,
                finished: true,
                error: '',
                products: [
                    state.productUpdate,
                    ...state.products.filter(
                        product => product.barcode !== state.productUpdate.barcode
                    ),
                ],
                productUpdate: null,
            };
        case 'RECEIVE_UPDATE_PRODUCT_ERROR':
            console.error(action.error);
            return {
                ...state,
                requesting: false,
                finished: true,
                error: action.error.toString(),
                productUpdate: null,
            };
        case 'DISMISS_UPDATE_PRODUCT_RESULT':
            return {
                ...state,
                requesting: false,
                finished: false,
                error: '',
                imageStoring: false,
                imageUrl: '',
                imageStoringError: null,
            };
        case 'REQUEST_UPLOAD_PRODUCT_IMAGE':
            return {
                ...state,
                imageStoring: true,
                imageUrl: '',
                imageStoringError: null,
            };
        case 'UPLOAD_PRODUCT_IMAGE_SUCCESS':
            return {
                ...state,
                imageStoring: false,
                imageUrl: action.url,
                imageStoringError: null,
            };
        case 'UPLOAD_PRODUCT_IMAGE_ERROR':
            console.error(action.error);
            return {
                ...state,
                imageStoring: false,
                imageUrl: '',
                imageStoringError: action.error.toString(),
            };
        case 'REMOVE_PRODUCT_IMAGE':
            return {
                ...state,
                imageUrl: '',
            };
        case 'RESET_PRODUCTS':
            return {
                ...initState,
            };
        case 'SEARCH_PRODUCT_QUERY':
            return {
                ...state,
                searchQuery: action.query,
            };
        default:
            return state;
    }
}
