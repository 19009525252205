import React, { Fragment, useEffect, useState } from 'react';
import { TextArea } from '@storaensods/seeds-react';
import { Modal, ModalBody, ModalFooter } from 'reactstrap';

const MAX_REFILL_NOTE_CHARACTERS = 125;

const RefillNoteModel = ({ isOpen, product, refillNote, disabledEditing, closeRefillNote, t, isAdmin }) => {
    const [refillNoteText, setRefillNoteText] = useState('');

    const refillNoteChange = value => {
        if (value.length > MAX_REFILL_NOTE_CHARACTERS) {
            setRefillNoteText(value.slice(0, MAX_REFILL_NOTE_CHARACTERS));
        } else {
            setRefillNoteText(value);
        }
    };

    useEffect(() => {
        setRefillNoteText(refillNote);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [product]);

    return (
        <Modal isOpen={isOpen} centered={true} size="md">
            <ModalBody className="refillschema__modal">
                <div className="refillschema__modal-title">{t('refillNoteTitle')}</div>
                <div className="refillschema__modal-contents">
                    <div className="refillschema__modal-product">
                        <img src={product?.imageUrl} alt="" />
                        <span>{t('main:barcode')}</span>
                        <span>
                            <strong> {product?.barcode}</strong>
                        </span>
                    </div>
                    <div className="refillschema__modal-textarea">
                        {disabledEditing && <div className="refillschema__modal-textarea-textonly">{refillNote}</div>}

                        {!disabledEditing && isAdmin && (
                            <Fragment>
                                <span>
                                    {refillNoteText?.length}/{MAX_REFILL_NOTE_CHARACTERS} {t('refillNoteCharacters')}
                                </span>
                                <TextArea
                                    placeholder="Refill note"
                                    onChange={e => refillNoteChange(e.target.value)}
                                    value={refillNoteText}
                                    disabled={disabledEditing}
                                />
                            </Fragment>
                        )}
                    </div>
                </div>
            </ModalBody>
            <ModalFooter>
                <div className="refillschema__modal-btn" onClick={() => closeRefillNote(product, refillNoteText)}>
                    {t('main:okay')}
                </div>
            </ModalFooter>
        </Modal>
    );
};

export default RefillNoteModel;
