import React, { useEffect, useState } from 'react';
import { OutlinedInput, InputLabel, MenuItem, FormControl, ListItemText, Select, Checkbox } from '@mui/material';
import FilterAltIcon from '@mui/icons-material/FilterAlt';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 208,
        },
    },
};

export default function MultipleSelectFilter({ labelName, options, handleFilter }) {
    const [selection, setSelection] = useState([]);

    useEffect(() => {
        handleFilter(selection);
    }, [selection, handleFilter]);

    const handleChange = event => {
        const {
            target: { value },
        } = event;
        setSelection(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value
        );
    };

    return (
        <div>
            <FormControl sx={{ width: '13rem' }} size="small">
                <InputLabel id={labelName}>{labelName}</InputLabel>
                <Select
                    labelId={labelName}
                    id={labelName}
                    IconComponent={FilterAltIcon}
                    multiple
                    value={selection}
                    onChange={handleChange}
                    input={<OutlinedInput label={labelName} />}
                    renderValue={selected => selected.join(', ')}
                    MenuProps={MenuProps}
                    sx={{
                        '.MuiOutlinedInput-notchedOutline': {
                            borderColor: '#9599a3',
                            borderRadius: '12px',
                        },
                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                            border: '1px solid #118bd6',
                        },
                        '&:hover .MuiOutlinedInput-notchedOutline': {
                            borderColor: 'powderblue',
                        },
                        '.MuiSelect-icon': {
                            transform: 'rotate(0deg)',
                        },
                    }}
                >
                    {options
                        ? options.map(option => (
                              <MenuItem key={option.label} value={option.label}>
                                  <Checkbox checked={selection.indexOf(option.label) > -1} />
                                  <ListItemText primary={option.label} />
                              </MenuItem>
                          ))
                        : null}
                </Select>
            </FormControl>
        </div>
    );
}
