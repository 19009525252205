import React, { Component } from 'react';
import './callback.css';
import Loading from '../loading/loading.js';
import usrMgr from '../../../userAuthManager.js';

/**
 * Sign in callback
 */
class Callback extends Component {
    componentDidMount() {
        usrMgr
            .signinRedirectCallback()
            .then(() => {
                // redirect to route
                window.location = window.location.origin;
            })
            .catch(function(e) {
                console.error(e);
            });
    }

    /**
     * This component does not need to be rendered. It should handle sign in callack from Open ID Connect
     */
    render() {
        return (
            <div className="text-center callback-loading-container">
                <Loading />
            </div>
        );
    }
}

export default Callback;
