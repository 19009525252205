import React, { Component } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './toastNotification.css';

export class ToastNotification extends Component {
    render() {
        return (
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss={false}
                draggable={false}
                pauseOnHover
            />
        );
    }
}

function ToastContent(title, text) {
    return (
        <div>
            {title && <h6>{title}</h6>}
            {text && <div>{text}</div>}
        </div>
    );
}

/**
 * Form a toast massage and shows it for 5 seconds on the top right corner of the screen.
 * @param {string} title The title of the toast message
 * @param {string} text The text in the main body of the toast
 * @param {string} type The type of the toast to be opened. Can be info (default), success, warn or error.
 * @param {function} onChange Call back function which is called when the toast is opened and closed. When opened '1' will be passed to call back function and when closed value '0'0 will be passed to call back function.
 */
export function showNotification(title, text, type, onChange) {
    const content = ToastContent(title, text);
    switch (type) {
        case 'info':
            toast.info(content);
            toast.onChange(onChange);
            break;
        case 'success':
            toast.success(content);
            toast.onChange(onChange);
            break;
        case 'warn':
            toast.warn(content);
            toast.onChange(onChange);
            break;
        case 'error':
            toast.error(content);
            toast.onChange(onChange);
            break;
        default:
            toast.info(content);
            toast.onChange(onChange);
            break;
    }
}
