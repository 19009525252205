import React, { useEffect, useState } from 'react';
import { Popover, PopoverBody } from 'reactstrap';
import { RadioButton, Select, Button } from '@storaensods/seeds-react';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import { dateRanges, granularities } from './../config';
import moment from 'moment';

const DateGranularity = ({
    onGranularityChange,
    onDateRangeChange,
    selectedGranularity,
    selectedDateRange,
    t,
    setSelectedCustomDateRange,
    selectedCustomDateRange,
}) => {
    const [openDateRange, setOpenDateRange] = useState(false);

    const toggleDateRangePopOver = () => {
        setOpenDateRange(!openDateRange);
    };

    const [openCustomDateRange, setOpenCustomDateRange] = useState();

    useEffect(() => {
        setOpenCustomDateRange(selectedDateRange === 'custom' ? true : false);
    }, [selectedDateRange]);

    const toggleCustomRangePopOver = () => {
        setOpenCustomDateRange(!openCustomDateRange);
    };

    const onCustomDateRangeChange = value => {
        const startDate = moment(value[0])
            .startOf('day')
            .toString();
        const endDate = moment(value[1])
            .endOf('day')
            .toString();
        setSelectedCustomDateRange([startDate, endDate]);
    };

    const backLimitForCustomTime = moment()
        .subtract('2', 'year')
        .toDate();
    const customFromDate = selectedCustomDateRange[0];
    const customToDate = selectedCustomDateRange[1];

    return (
        <div className="date-range mt-3">
            <label className="radio-group__label">{t('dateRange')}</label>
            <label className="date-range__label" id="datePopOver">
                <span className="date-range__label-text">
                    {t(selectedDateRange)} ( {t('groupBy')} {t(selectedGranularity)} )
                </span>
                <span className="date-range__label-icon">&#x025BE;</span>
            </label>

            <Popover
                className="date-range__popover"
                isOpen={openDateRange}
                target="datePopOver"
                toggle={toggleDateRangePopOver}
                placement="bottom"
                trigger="click focus"
            >
                <PopoverBody className="date-range__container">
                    <div className="date-range__content">
                        <div className="date-range__lists">
                            <label className="date-range__label-text">{t('dateRange')}</label>
                            <ul className="date-range__date">
                                {dateRanges.map(date => {
                                    return (
                                        <li key={date}>
                                            <RadioButton
                                                id={date.toString()}
                                                checked={selectedDateRange === date}
                                                label={t(date)}
                                                name="dateRange"
                                                onChange={() => {
                                                    onDateRangeChange(date);
                                                }}
                                            />
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>
                    </div>

                    <div className="date-range-customRangeContainer mt-2 mb-4">
                        <RadioButton
                            id={'Custom'}
                            checked={selectedDateRange === 'custom'}
                            label={t('custom')}
                            name="dateRange"
                            onChange={() => {
                                onDateRangeChange('custom');
                                toggleCustomRangePopOver();
                            }}
                        />
                        {openCustomDateRange && (
                            <DateRangePicker
                                className="mr-2 date-range-picker"
                                clearIcon={null}
                                value={[customFromDate, customToDate]}
                                minDate={backLimitForCustomTime}
                                maxDate={new Date()}
                                onChange={onCustomDateRangeChange}
                            />
                        )}
                    </div>

                    <div className="date-range-cta">
                        <div className="date-range__granularity">
                            <label className="date-range__label-text">{t('timeGrouping')} </label>
                            <Select
                                size="md"
                                options={granularities.map(granularity => {
                                    return {
                                        label: t(granularity),
                                        value: granularity,
                                    };
                                })}
                                onChange={granularity => {
                                    onGranularityChange(granularity);
                                }}
                                defaultValue={[{ label: t(selectedGranularity), value: selectedGranularity }]}
                            />
                        </div>
                        <Button size="sm" className="mr-1" onClick={() => setOpenDateRange(false)}>
                            Ok
                        </Button>
                    </div>
                </PopoverBody>
            </Popover>
        </div>
    );
};

export default DateGranularity;
