import React from 'react';

const TextField = ({ value = '', label, placeholder, maxLength, onChange }) => {
    return (
        <div className="text-field">
            <label>{label}</label>
            <input type="text" value={value} placeholder={placeholder} maxLength={maxLength} onChange={onChange} />
        </div>
    );
};

export default TextField;
