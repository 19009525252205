import React, { useEffect, useState, Fragment } from 'react';
import { Input, Card, Button, Tag, Modal, Icon, NavigationBar, TabItem } from '@storaensods/seeds-react';
import Toggle from 'react-toggle';
import { Row, Col } from 'reactstrap';
import TimePicker from 'react-times';
import DatePicker from 'react-date-picker';
import 'react-date-picker/dist/DatePicker.css';
import { InfoBanner, Toggle as CustomToggle } from '../commons/utils';
import moment from 'moment';
import 'react-times/css/classic/default.css';
import ImageUpload from '../imageUpload/imageUpload';

/**
 * Scheduled discounts React component
 * @param {object} props Properties for the component
 * @param {object} props.cabinets Redux state object of cabinets
 * @param {object} props.products Redux state object of products
 * @param {object} props.productCategories Redux state object of product categories
 * @param {object} props.rule Object which consists data of discount rule
 * @param {string} props.rule.Id Id of discount rule which
 * @param {string} props.rule.Name Name of discount rule
 * @param {1 | 2 | 3} props.rule.Status Status of discount rule
 * @param {Array.<string>} props.rule.LocationIds Array of location IDs where discount is applied
 * @param {Array.<string>} props.rule.DeviceCodes Array of cabinet IDs where discount is applied
 * @param {Array.<string>} props.rule.ProductIds Array of product IDs where discount is applied
 * @param {Array.<string>} props.rule.ProductCategoryIds Array of product category IDs where discount is applied
 * @param {object} props.rule.DiscountData Object which consists data of discount itself
 * @param {number} props.rule.DiscountData.discountAmount Percentage amount of discount
 * @param {Date} props.rule.DiscountData.startDate Start date of discount date range
 * @param {Date} props.rule.DiscountData.endDate End date of discount date range
 * @param {string} props.rule.DiscountData.imageUrl URL of optional discount's image
 * @param {Array.<string>} props.rule.DiscountData.recurrenceDays Days when discount reoccurs
 * @param {function} props.toggleDiscountStatus Function to handle discount's status
 * @param {function} props.handleDiscountEdit Function to handle discount's edit
 * @param {function} props.t i18n translation function
 * @param {boolean} [props.preview = false] Boolean for if preview mode is used
 * @param {Boolean} props.isAdmin Flag for if user is admin

 */
export default function ScheduledDiscounts(props) {
    const { t, rule, toggleDiscountStatus, preview, handleDiscountEdit, imageMarkedAsDeleted, imageUrl, isAdmin } = props;
    const [detailsOpen, setDetailsOpen] = useState(false);
    const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
    const [deleteActive, setDeleteActive] = useState(false);

    const isDayChecked = weekday => {
        const dayChecked = rule?.DiscountData?.recurrenceDays
            ? rule?.DiscountData?.recurrenceDays.some(day => {
                  return weekday === day;
              })
            : false;
        if (dayChecked) {
            return 'recurrenceDayChecked';
        }
    };

    const handleDiscountStatusChange = () => {
        let Status = rule?.Status === 1 ? 2 : 1;
        if (deleteActive) Status = 3;
        const payload = preview
            ? Status
            : {
                  ...rule,
                  Status,
                  DiscountData: {
                      ...rule.DiscountData,
                      DiscountTypeOrder: 2,
                  },
              };
        toggleDiscountStatus(payload);
        setConfirmationDialogOpen(false);
    };

    const handleDiscountDelete = () => {
        setDeleteActive(true);
        setConfirmationDialogOpen(true);
    };

    const getModalTitle = () => {
        if (!deleteActive) {
            switch (rule.Status) {
                case 1:
                    return t('sureDeactivateDiscountRule');
                case 2:
                    return t('sureActivateDiscountRule');
                default:
                    return t('sureDeactivateDiscountRule');
            }
        } else return t('sureDeleteDiscountRule');
    };
    if (!rule) {
        return null;
    }

    // disable active inactive toggle
    const disableDiscountToggle = () => {
        const currentTime = moment();

        let endDate = rule.DiscountData.endDate;
        let endTime = rule.DiscountData.endTime;

        if (endTime) endTime = moment(endTime, 'HH:mm');
        if (endDate) endDate = moment(endDate);
        if (endDate && endDate.isBefore(currentTime)) {
            return true;
        } else if (endTime && endTime.isBefore(endTime)) {
            return true;
        }

        return false;
    };

    const getCorrectImagePath = () => {
        if (preview && imageUrl) return imageUrl;
        else if (!preview && rule.DiscountData.imageUrl) return rule.DiscountData.imageUrl;
        else if (preview && rule.DiscountData.imageUrl && !imageMarkedAsDeleted) return rule.DiscountData.imageUrl;
        else if (preview && imageMarkedAsDeleted.id === rule.Id) return null;
    };

    return (
        <Fragment>
            <Card
                title={rule?.Name}
                subtitle={
                    <>
                        {!rule.version && isAdmin && (
                            <div className="mr-2">
                                <Button icon="edit" type="secondary" disabled={rule.version && rule.version === 'v1'} onClick={() => handleDiscountEdit(rule)}>
                                    {t('edit')}
                                </Button>
                            </div>
                        )}
                        {!preview && isAdmin && (
                            <div>
                                <Button icon="delete" type="secondary" onClick={() => handleDiscountDelete()}>
                                    {t('delete')}
                                </Button>
                            </div>
                        )}
                    </>
                }
            >
                <div className="mt-2 DiscountContainerCard">
                    <Modal
                        actions={[
                            {
                                label: t('confirm'),
                                onClick: () => handleDiscountStatusChange(),
                            },
                            {
                                label: t('cancel'),
                                onClick: () => setConfirmationDialogOpen(false),
                            },
                        ]}
                        active={confirmationDialogOpen}
                        title={getModalTitle()}
                        onClose={() => setConfirmationDialogOpen(false)}
                    >
                        <h2>{rule?.Name}</h2>
                    </Modal>
                    <Row>
                        <Col lg="4" className="scheduledDiscountLeftSide">
                            <div>
                                {getCorrectImagePath() ? (
                                    <Row className="mb-4">
                                        <div className="bundleDiscountImage_leftSide mx-auto my-auto">
                                            <img src={getCorrectImagePath()} alt="" />
                                        </div>
                                    </Row>
                                ) : null}
                                <Row fluid>
                                    <Col lg="12">
                                        <div className="d-block text-center span12">
                                            <h1 className={`discountHeader ${rule?.Status === 1 ? 'activatedDiscount' : 'deactivatedDiscount'}`}>
                                                {rule?.DiscountData?.discountAmount} %
                                            </h1>
                                        </div>
                                    </Col>
                                </Row>
                                {!rule.version && isAdmin && (
                                    <Row fluid>
                                        <Col lg="12" className="text-center">
                                            <Toggle
                                                className={`${rule?.Status === 2 ? 'deactivatedDiscountToggle' : ''}`}
                                                icons={false}
                                                checked={rule?.Status === 1}
                                                onChange={() => (preview ? handleDiscountStatusChange() : setConfirmationDialogOpen(true))}
                                                disabled={disableDiscountToggle()}
                                            />
                                        </Col>
                                    </Row>
                                )}
                            </div>
                        </Col>
                        <Col lg="8" className="pl-4 mobileSchedulingPaddingFix">
                            <Row className="mt-2 scheduleDaterangeRow">
                                <Col lg="1" className="align-self-center">
                                    <span style={{ verticalAlign: 'top' }}>
                                        {' '}
                                        <Icon size="lg">date_range</Icon>{' '}
                                    </span>
                                </Col>
                                <Col lg="11">
                                    {rule?.DiscountData?.startDate && rule?.DiscountData?.endDate ? (
                                        <>
                                            <div className=" mr-1">
                                                <strong>{t('starts')}</strong> {moment(rule?.DiscountData?.startDate).format('LL')}{' '}
                                                {moment(rule?.DiscountData?.startDate).format('HH:mm')}{' '}
                                            </div>
                                            <div className="">
                                                <strong>{t('ends')}</strong> {moment(rule?.DiscountData?.endDate).format('LL')}{' '}
                                                {moment(rule?.DiscountData?.endDate).format('HH:mm')}
                                            </div>
                                        </>
                                    ) : (
                                        <div className="d-inline-flex  ml-1"> {t('permanent')} </div>
                                    )}
                                </Col>
                            </Row>

                            <Row className="mt-4">
                                <Col lg="12">
                                    <span>
                                        {t('recurrence')} ({rule?.DiscountData?.startTime} - {rule?.DiscountData?.endTime})
                                    </span>
                                    <div className={'d-flex justify-content-between my-2'}>
                                        <div className={`recurrenceWeekdayBtn rounded-circle d-inline-flex ${isDayChecked('Mon')} `}>
                                            <span>{t('weekDayAbrvMonday')}</span>
                                        </div>
                                        <div className={`recurrenceWeekdayBtn rounded-circle d-inline-flex ${isDayChecked('Tue')}`}>
                                            <span>{t('weekDayAbrvTuesday')}</span>
                                        </div>
                                        <div className={`recurrenceWeekdayBtn rounded-circle d-inline-flex ${isDayChecked('Wed')}`}>
                                            <span>{t('weekDayAbrvWednesday')}</span>
                                        </div>
                                        <div className={`recurrenceWeekdayBtn rounded-circle d-inline-flex ${isDayChecked('Thu')}`}>
                                            <span>{t('weekDayAbrvThursday')}</span>
                                        </div>
                                        <div className={`recurrenceWeekdayBtn rounded-circle d-inline-flex ${isDayChecked('Fri')}`}>
                                            <span>{t('weekDayAbrvFriday')}</span>
                                        </div>
                                        <div className={`recurrenceWeekdayBtn rounded-circle d-inline-flex ${isDayChecked('Sat')}`}>
                                            <span>{t('weekDayAbrvSaturday')}</span>
                                        </div>
                                        <div className={`recurrenceWeekdayBtn rounded-circle d-inline-flex ${isDayChecked('Sun')}`}>
                                            <span>{t('weekDayAbrvSunday')}</span>
                                        </div>
                                    </div>
                                </Col>
                            </Row>

                            <Row className={`mt-3 scheduledDetailsRow ${preview ? 'invisible' : ''}`}>
                                <Col lg="12">
                                    <Button className="discountDetailsBtn" onClick={() => setDetailsOpen(!detailsOpen)} type="positive">
                                        {' '}
                                        {t('translations:seeDetails')} <Icon className="align-middle">{detailsOpen ? 'arrow_drop_up' : 'arrow_drop_down'}</Icon>
                                    </Button>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row>
                        {detailsOpen && (
                            <div className="discountDetailContainer">
                                <div>
                                    <span>{t('locations')}: </span>
                                    {rule.LocationIds && typeof rule.LocationIds === 'object' && rule.LocationIds.length > 0 ? (
                                        rule.LocationIds.map(locationId => {
                                            const location = props.cabinets.locations.find(location => location.id === locationId);
                                            if (!location) {
                                                return (
                                                    <Tag className="mr-2" type="negative">
                                                        {locationId} ({t('doesNotExist')})
                                                    </Tag>
                                                );
                                            } else {
                                                return (
                                                    <Tag className="mr-2" type="positive">
                                                        {location.name}
                                                    </Tag>
                                                );
                                            }
                                        })
                                    ) : (
                                        <Tag>{t('all')}</Tag>
                                    )}
                                </div>
                                <div>
                                    <span>{t('cabinets')}: </span>
                                    {rule.DeviceCodes && typeof rule.DeviceCodes === 'object' && rule.DeviceCodes.length > 0 ? (
                                        rule.DeviceCodes.map(deviceCode => {
                                            const cabinet = props.cabinets.cabinets.find(cabinet => cabinet.deviceCode === deviceCode);
                                            if (!cabinet) {
                                                return (
                                                    <Tag className="mr-2" key={deviceCode} type="negative">
                                                        {deviceCode} ({t('doesNotExist')})
                                                    </Tag>
                                                );
                                            } else {
                                                return (
                                                    <Tag className="mr-2" key={deviceCode} type="positive">
                                                        {cabinet.name}
                                                    </Tag>
                                                );
                                            }
                                        })
                                    ) : (
                                        <Tag>{t('all')}</Tag>
                                    )}
                                </div>
                                <div>
                                    <span>{t('products')}: </span>
                                    {rule.ProductIds && typeof rule.ProductIds === 'object' && rule.ProductIds.length > 0 ? (
                                        rule.ProductIds.map(productId => {
                                            const product = props.products.products.find(product => product.id === productId);
                                            if (!product) {
                                                return (
                                                    <Tag className="mr-2" key={productId} type="negative">
                                                        {productId} ({t('doesNotExist')})
                                                    </Tag>
                                                );
                                            } else {
                                                return (
                                                    <Tag className="mr-2" key={productId} type="positive">
                                                        {product.name}
                                                    </Tag>
                                                );
                                            }
                                        })
                                    ) : (
                                        <Tag>{t('all')}</Tag>
                                    )}
                                </div>
                                <div>
                                    <span>{t('productCategoriesTitle')}: </span>
                                    {rule.ProductCategoryIds && typeof rule.ProductCategoryIds === 'object' && rule.ProductCategoryIds.length > 0 ? (
                                        rule.ProductCategoryIds.map(pcId => {
                                            const product = props.productCategories.productCategories.find(productCategory => productCategory.id === pcId);
                                            if (!product) {
                                                return (
                                                    <Tag className="mr-2" key={pcId} type="negative">
                                                        {pcId} ({t('doesNotExist')})
                                                    </Tag>
                                                );
                                            } else {
                                                return (
                                                    <Tag className="mr-2" key={pcId} type="positive">
                                                        {product.name}
                                                    </Tag>
                                                );
                                            }
                                        })
                                    ) : (
                                        <Tag>{t('all')}</Tag>
                                    )}
                                </div>
                            </div>
                        )}
                    </Row>
                </div>
            </Card>
        </Fragment>
    );
}

/**
 * Add discount rule stop form React component
 * @param {object} props
 * @param {function} props.t i18n translator function
 * @param {object} props.scheduledDiscount Discount object
 * @param {function} props.saveDiscount Save the discount
 * @param {function} props.setScheduledDiscount Function to set temporary discount
 * @param {string} props.formMessage Optional informative message to display on form
 *  @param {string} props.scheduledImageUrl Redux state of image url
 * @param {Boolean} props.scheduledImageStoring Redux state of image storing status
 */
const getImageUrl = props => {
    if (props.scheduledDiscount?.DiscountData.imageUrl) return props.scheduledDiscount.DiscountData.imageUrl;
    else if (!props.scheduledDiscount?.DiscountData.imageUrl && props.scheduledImageUrl) return props.scheduledImageUrl;
    else return null;
};
export function ScheduledDiscountsForm(props) {
    const { t, resetScheduledImage, markScheduledImageAsDeleted, userRestricted, handleUserRestrictToggle } = props;
    const [activeTab, setActiveTab] = useState('DiscountAmount');
    const [formMessage, setFormMessage] = useState(props.formMessage ? props.formMessage : '');
    const [scheduledStartDate, setScheduledStartDate] = useState(
        props.scheduledDiscount?.DiscountData.startDate ? new Date(props.scheduledDiscount.DiscountData.startDate) : undefined
    );
    const [scheduledEndDate, setScheduledEndDate] = useState(
        props.scheduledDiscount?.DiscountData.endDate ? new Date(props.scheduledDiscount.DiscountData.endDate) : undefined
    );
    const [scheduledActivationTime, setScheduledActivationTime] = useState(
        props.scheduledDiscount?.DiscountData.startDate ? moment(props.scheduledDiscount?.DiscountData.startDate).format('HH:mm') : '00:00'
    );
    const [scheduledDeactivationTime, setScheduledDeactivationTime] = useState(
        props.scheduledDiscount?.DiscountData.endDate ? moment(props.scheduledDiscount?.DiscountData.endDate).format('HH:mm') : '23:59'
    );
    const [scheduledImageStoring, setScheduledImageStoring] = useState(props.scheduledImageStoring ? props.scheduledImageStoring : false);
    const [scheduledImageUrl, setScheduledImageUrl] = useState(getImageUrl(props));
    const [scheduledImageCropping, toggleScheduledImageCropping] = useState(false);
    // scheduled discount form data
    const scheduledDiscount = {};
    useEffect(() => {
        if (props.scheduledImageUrl) {
            setScheduledImageStoring(false);
            setScheduledImageUrl(props.scheduledImageUrl);
        }
        if (props.formMessage) setFormMessage(props.formMessage);
    }, [props.scheduledImageUrl, props.formMessage]);
    scheduledDiscount.id = props.scheduledDiscount?.id;
    scheduledDiscount.discountAmount = props.scheduledDiscount?.DiscountData.discountAmount ? props.scheduledDiscount.DiscountData.discountAmount : '';
    scheduledDiscount.startDate = props.scheduledDiscount?.DiscountData.startDate ? new Date(props.scheduledDiscount.DiscountData.startDate) : null;
    scheduledDiscount.endDate = props.scheduledDiscount?.DiscountData.endDate ? new Date(props.scheduledDiscount.DiscountData.endDate) : null;
    scheduledDiscount.startTime = props.scheduledDiscount?.DiscountData.startTime ? props.scheduledDiscount.DiscountData.startTime : '00:00';
    scheduledDiscount.endTime = props.scheduledDiscount?.DiscountData.endTime ? props.scheduledDiscount.DiscountData.endTime : '23:59';
    scheduledDiscount.recurrenceDays =
        props.scheduledDiscount?.DiscountData.recurrenceDays && props.scheduledDiscount?.DiscountData.recurrenceDays.length > 0
            ? props.scheduledDiscount.DiscountData.recurrenceDays
            : [];
    scheduledDiscount.imageUrl = props.scheduledDiscount?.DiscountData.imageUrl ? props.scheduledDiscount.DiscountData.imageUrl : null;

    const handlePercentageInputChange = event => {
        let inputValue = event.target.value;
        if (inputValue === '') {
            inputValue = '0';
        }

        if (isNaN(inputValue) || isNaN(parseFloat(inputValue))) {
            return; // invalid change
        }

        const percentage = parseFloat(inputValue);
        if (percentage < 0) {
            props.setScheduledDiscount({
                ...scheduledDiscount,

                discountAmount: undefined,
            }); // min 0 %
        } else if (percentage > 100) {
            props.setScheduledDiscount({
                ...scheduledDiscount,
                discountAmount: 100,
            }); // max 100 %
        } else {
            props.setScheduledDiscount({
                ...scheduledDiscount,

                discountAmount: percentage,
            });
        }
    };

    const handleDateChange = (date, pickerInput) => {
        if (pickerInput === 'start') {
            if (!date) {
                setScheduledStartDate(null);
                props.setScheduledDiscount({
                    ...scheduledDiscount,
                    startDate: null,
                });
            } else {
                date = moment(date).set({ minute: '00', hour: '00' });
                setScheduledStartDate(date);
                props.setScheduledDiscount({
                    ...scheduledDiscount,
                    startDate: date,
                });
            }
        } else if (pickerInput === 'end') {
            if (!date) {
                setScheduledEndDate(null);
                props.setScheduledDiscount({
                    ...scheduledDiscount,
                    endDate: null,
                });
            } else {
                date = moment(date).set({ minute: '59', hour: '23' });
                setScheduledEndDate(date);
                props.setScheduledDiscount({
                    ...scheduledDiscount,
                    endDate: date,
                });
            }
        }
    };

    /**
     * Function to handle and manipulate times from picker
     * @param {object} timeObj Timeobject
     * @param {string} timeObj.hour Hour of selected time
     * @param {string} timeObj.minute Minute of selected time
     * @param {string} timeObj.meridiem AM/PM if 12h mode is activated
     * @param {'start' | 'end'} component Parameter to define which picker is used
     */

    const onDiscountTimeChange = (timeObj, component) => {
        const endHour = scheduledDiscount.endTime.substring(0, 2);
        const startHour = scheduledDiscount.startTime.substring(0, 2);
        if (component === 'start') {
            if (parseInt(timeObj.hour) > parseInt(endHour)) {
                setFormMessage(t('schedulingStartHourIsOverEndHour'));
                return false;
            } else {
                props.setScheduledDiscount({
                    ...scheduledDiscount,
                    startTime: `${timeObj.hour}:${timeObj.minute}`,
                });
                setFormMessage('');
            }
        } else {
            if (parseInt(timeObj.hour) < parseInt(startHour)) {
                setFormMessage(t('schedulingEndHourIsUnderStartHour'));
                return false;
            } else {
                props.setScheduledDiscount({
                    ...scheduledDiscount,
                    endTime: `${timeObj.hour}:${timeObj.minute}`,
                });
                setFormMessage('');
            }
        }
    };

    /**
     * Function to handle and manipulate times from picker
     * @param {object} timeObj Timeobject
     * @param {string} timeObj.hour Hour of selected time
     * @param {string} timeObj.minute Minute of selected time
     * @param {string} timeObj.meridiem AM/PM if 12h mode is activated
     * @param {'start' | 'end'} component Parameter to define which picker is used
     */

    const onDiscountActivationTimeChange = (timeObj, component) => {
        const { hour, minute } = timeObj;
        if (component === 'start') {
            setScheduledActivationTime(`${hour}:${minute}`);
            const newTimeToOldDate = moment(scheduledDiscount.startDate).set({ minute, hour });
            props.setScheduledDiscount({
                ...scheduledDiscount,
                startDate: newTimeToOldDate,
            });
            setFormMessage('');
        } else {
            setScheduledDeactivationTime(`${hour}:${minute}`);
            const newTimeToOldDate = moment(scheduledDiscount.endDate).set({ minute, hour });
            props.setScheduledDiscount({
                ...scheduledDiscount,
                endDate: newTimeToOldDate,
            });
            setFormMessage('');
        }
    };

    const selectRecurrencyDay = weekday => {
        const daySelectedAlready = scheduledDiscount.recurrenceDays?.some(day => {
            return weekday === day;
        });
        if (daySelectedAlready) {
            const newWeekDays = scheduledDiscount.recurrenceDays?.filter(day => {
                return day !== weekday;
            });
            props.setScheduledDiscount({
                ...scheduledDiscount,
                recurrenceDays: newWeekDays,
            });
        } else {
            props.setScheduledDiscount({
                ...scheduledDiscount,
                recurrenceDays: [...scheduledDiscount.recurrenceDays, weekday],
            });
        }
    };

    const isDayChecked = weekday => {
        const dayChecked = scheduledDiscount.recurrenceDays.some(day => {
            return weekday === day;
        });
        if (dayChecked) {
            return 'recurrenceDayChecked';
        }
    };

    const areRecurrenceAvailable = () => {
        if (scheduledStartDate && scheduledEndDate) {
            if (moment(scheduledStartDate).isSame(scheduledEndDate, 'day')) {
                if (scheduledDiscount.recurrenceDays.length) {
                    props.setScheduledDiscount({
                        ...scheduledDiscount,
                        recurrenceDays: [],
                    });
                }
                return false;
            } else {
                return true;
            }
        } else {
            return true;
        }
    };

    const uploadDiscountImage = file => {
        setScheduledImageStoring(true);
        markScheduledImageAsDeleted(null);
        return props.uploadScheduledImage(file);
    };

    const markImageAsDeleted = ruleId => {
        setScheduledImageUrl(null);
        resetScheduledImage();
        markScheduledImageAsDeleted(ruleId);
    };

    // discount stop form
    const discountAmountString = scheduledDiscount.discountAmount ? scheduledDiscount.discountAmount.toString() : '';
    return (
        <div className="p-2 scheduledDiscountModal">
            <NavigationBar className="mb-3 not-printable">
                <TabItem onClick={() => setActiveTab('DiscountAmount')} isActive={activeTab === 'DiscountAmount'}>
                    {t('discountAmount')}
                </TabItem>
                <TabItem onClick={() => setActiveTab('Scheduling')} isActive={activeTab === 'Scheduling'}>
                    {' '}
                    {t('scheduling')}
                </TabItem>
                <TabItem onClick={() => setActiveTab('Recurrence')} isActive={activeTab === 'Recurrence'}>
                    {t('recurrence')}{' '}
                </TabItem>
                <TabItem onClick={() => setActiveTab('userRestrict')} isActive={activeTab === 'userRestrict'}>
                    {t('userRestrictTitle')}{' '}
                </TabItem>
                <TabItem onClick={() => setActiveTab('Image')} isActive={activeTab === 'Image'}>
                    {t('discountCustomImageTitle')}{' '}
                </TabItem>
            </NavigationBar>
            {formMessage && (
                <Row className="p-3">
                    <Col xs="12">
                        {' '}
                        <p className="mb-0" style={{ color: '#CF142B' }}>
                            {formMessage}
                        </p>
                    </Col>
                </Row>
            )}
            <div className="p-2">
                {activeTab === 'DiscountAmount' && (
                    <div className="p-2 scheduledDiscountFormContainer">
                        <Row>
                            <Col xs="12" lg="12" className="scheduledDiscountFormDiscountAmountInput">
                                <Input
                                    label={t('discountAmount') + ' (%)'}
                                    placeholder="%"
                                    value={discountAmountString}
                                    onChange={handlePercentageInputChange}
                                />
                            </Col>
                        </Row>

                        <Row className="mt-3">
                            <div style={{ flex: 'auto' }} className="mr-2 text-right">
                                <Button onClick={() => setActiveTab('Scheduling')}>{t('next')}</Button>
                            </div>
                        </Row>
                    </div>
                )}
                {activeTab === 'Scheduling' && (
                    <div className="p-2 scheduledDiscountFormContainer">
                        <Row className="pl-3 pb-2">
                            <label className="se-label "> {t('scheduledDiscountDateInfoLabel')}</label>
                        </Row>
                        <Row className="p-1">
                            <Col xs="6" lg="6">
                                <label>
                                    {' '}
                                    <strong> {t('startDate')}:</strong>
                                </label>
                                <DatePicker
                                    className="scheduledDiscount-datePicker ml-2"
                                    minDate={scheduledDiscount?.startDate ? new Date(scheduledDiscount.startDate) : new Date()}
                                    required
                                    minDetail="year"
                                    format="d.M.yyyy"
                                    value={scheduledDiscount?.startDate}
                                    onChange={value => handleDateChange(value, 'start')}
                                />
                            </Col>
                            <Col xs="5" lg="5" className="align-self-end">
                                <TimePicker
                                    withoutIcon
                                    theme="classic"
                                    disabled={!scheduledDiscount?.startDate}
                                    time={scheduledActivationTime}
                                    onTimeChange={time => onDiscountActivationTimeChange(time, 'start')}
                                />
                            </Col>
                        </Row>
                        <Row className="p-1 mt-2">
                            <Col xs="6" lg="6">
                                <label>
                                    {' '}
                                    <strong> {t('endDate')}:</strong>
                                </label>

                                <DatePicker
                                    className="scheduledDiscount-datePicker ml-3"
                                    minDate={scheduledDiscount?.startDate ? new Date(scheduledDiscount.startDate) : new Date()}
                                    required
                                    minDetail="year"
                                    format="d.M.yyyy"
                                    value={scheduledDiscount?.endDate}
                                    onChange={value => handleDateChange(value, 'end')}
                                />
                            </Col>
                            <Col xs="6" lg="6" className="align-self-end">
                                <TimePicker
                                    withoutIcon
                                    theme="classic"
                                    disabled={!scheduledDiscount?.endDate}
                                    time={scheduledDeactivationTime}
                                    onTimeChange={time => onDiscountActivationTimeChange(time, 'end')}
                                />
                            </Col>
                        </Row>

                        <Row className="mt-3">
                            <div className="text-left ml-1">
                                <Button
                                    onClick={() => {
                                        setActiveTab('DiscountAmount');
                                    }}
                                >
                                    {t('back')}
                                </Button>
                            </div>
                            <div style={{ flex: 'auto' }} className="mr-2 text-right">
                                <Button onClick={() => setActiveTab('Recurrence')}>{t('next')}</Button>
                            </div>
                        </Row>
                    </div>
                )}

                {activeTab === 'Recurrence' && (
                    <div className="p-2 scheduledDiscountFormContainer">
                        <Row className="pl-3">
                            <label className="se-label ">
                                {' '}
                                {scheduledDiscount.recurrenceDays.length ? (
                                    <b>{t('scheduledDiscountApplySelectedDays')}</b>
                                ) : (
                                    <b>{t('scheduledDiscountApplyAllDays')}</b>
                                )}
                            </label>
                        </Row>
                        <Row className="p-1">
                            <div className={`pl-2 d-flex justify-content-between ${areRecurrenceAvailable() ? '' : 'recurrenceDaysDisabled'}`}>
                                <div
                                    role="button"
                                    onClick={() => (areRecurrenceAvailable() ? selectRecurrencyDay('Mon') : undefined)}
                                    className={`recurrenceWeekdayBtn rounded-circle d-inline-flex ${isDayChecked('Mon')} `}
                                >
                                    <span>{t('weekDayAbrvMonday')}</span>
                                </div>
                                <div
                                    role="button"
                                    onClick={() => (areRecurrenceAvailable() ? selectRecurrencyDay('Tue') : undefined)}
                                    className={`recurrenceWeekdayBtn rounded-circle d-inline-flex ${isDayChecked('Tue')}`}
                                >
                                    <span>{t('weekDayAbrvTuesday')}</span>
                                </div>
                                <div
                                    role="button"
                                    onClick={() => (areRecurrenceAvailable() ? selectRecurrencyDay('Wed') : undefined)}
                                    className={`recurrenceWeekdayBtn rounded-circle d-inline-flex ${isDayChecked('Wed')}`}
                                >
                                    <span>{t('weekDayAbrvWednesday')}</span>
                                </div>
                                <div
                                    role="button"
                                    onClick={() => (areRecurrenceAvailable() ? selectRecurrencyDay('Thu') : undefined)}
                                    className={`recurrenceWeekdayBtn rounded-circle d-inline-flex ${isDayChecked('Thu')}`}
                                >
                                    <span>{t('weekDayAbrvThursday')}</span>
                                </div>
                                <div
                                    role="button"
                                    onClick={() => (areRecurrenceAvailable() ? selectRecurrencyDay('Fri') : undefined)}
                                    className={`recurrenceWeekdayBtn rounded-circle d-inline-flex ${isDayChecked('Fri')}`}
                                >
                                    <span>{t('weekDayAbrvFriday')}</span>
                                </div>
                                <div
                                    role="button"
                                    onClick={() => (areRecurrenceAvailable() ? selectRecurrencyDay('Sat') : undefined)}
                                    className={`recurrenceWeekdayBtn rounded-circle d-inline-flex ${isDayChecked('Sat')}`}
                                >
                                    <span>{t('weekDayAbrvSaturday')}</span>
                                </div>
                                <div
                                    role="button"
                                    onClick={() => (areRecurrenceAvailable() ? selectRecurrencyDay('Sun') : undefined)}
                                    className={`recurrenceWeekdayBtn rounded-circle d-inline-flex ${isDayChecked('Sun')}`}
                                >
                                    <span>{t('weekDayAbrvSunday')}</span>
                                </div>
                            </div>
                        </Row>
                        <Row className="p-2">
                            <Col xs="6" lg="6" className=" align-self-end">
                                <label className="se-label">
                                    {' '}
                                    <strong>{t('scheduledDiscountTimeRangeLabel')}</strong>
                                </label>
                                <TimePicker
                                    withoutIcon
                                    theme="classic"
                                    time={scheduledDiscount?.startTime}
                                    onTimeChange={time => onDiscountTimeChange(time, 'start')}
                                />
                            </Col>

                            <Col xs="5" lg="5" className=" align-self-end">
                                <TimePicker
                                    withoutIcon
                                    theme="classic"
                                    time={scheduledDiscount?.endTime}
                                    onTimeChange={time => onDiscountTimeChange(time, 'end')}
                                />
                            </Col>
                        </Row>
                        <Row className="mt-3">
                            <div className="text-left ml-1">
                                <Button
                                    onClick={() => {
                                        setActiveTab('Scheduling');
                                    }}
                                >
                                    {t('back')}
                                </Button>
                            </div>
                            <div style={{ flex: 'auto' }} className="mr-2 text-right">
                                <Button className="scheduledDiscount-saveBtn" onClick={() => setActiveTab('userRestrict')}>
                                    {t('next')}
                                </Button>
                            </div>
                        </Row>
                    </div>
                )}
                {activeTab === 'userRestrict' && (
                    <div>
                        <InfoBanner message={t('userRestrictHelperText')} />
                        <Row className="expiryUserDiscount mt-4">
                            <Col lg="12" className="text-center mt-1 d-flex">
                                <CustomToggle
                                    // className={userRestricted ? 'activatedDiscount': 'deactivatedDiscountToggle' }

                                    options={[
                                        { text: t('userRestrict'), value: true },
                                        { text: t('userNotRestrict'), value: false },
                                    ]}
                                    selectedOption={userRestricted}
                                    onClick={() => handleUserRestrictToggle()}
                                />
                            </Col>
                        </Row>
                        <Row className="mt-3">
                            <div className="text-left ml-1">
                                <Button
                                    onClick={() => {
                                        setActiveTab('Recurrence');
                                    }}
                                >
                                    {t('back')}
                                </Button>
                            </div>
                            <div style={{ flex: 'auto' }} className="mr-2 text-right">
                                <Button onClick={() => setActiveTab('Image')}>{t('next')}</Button>
                            </div>
                        </Row>
                    </div>
                )}
                {activeTab === 'Image' && (
                    <>
                        <Row className="scheduledImageUploadContainer">
                            <div className="logoEditContainer bundleImageUploadContainer d-flex mb-2 justify-content-between">
                                <div className="">
                                    <div className="scp-settings__inputs-item">
                                        <div className="logoEditContainer d-flex mb-2 justify-content-between">
                                            <ImageUpload
                                                className="se-input-container"
                                                freeAspectRatio={false}
                                                targetWidth={parseInt(process.env.REACT_APP_BUNDLE_IMAGE_WIDTH) || 1920}
                                                targetHeight={parseInt(process.env.REACT_APP_BUNDLE_IMAGE_HEIGHT) || 850}
                                                imageUrl={scheduledImageUrl}
                                                storing={scheduledImageStoring}
                                                uploadImage={img => uploadDiscountImage(img)}
                                                removeImage={() => markImageAsDeleted(scheduledDiscount.id)}
                                                toggleCropping={() => toggleScheduledImageCropping(!scheduledImageCropping)}
                                                t={props.t}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Row>
                        <Row className="mt-3">
                            <div className="text-left ml-1">
                                <Button
                                    onClick={() => {
                                        setActiveTab('userRestrict');
                                    }}
                                >
                                    {t('back')}
                                </Button>
                            </div>
                            <div style={{ flex: 'auto' }} className="mr-2 text-right">
                                <Button className="scheduledDiscount-saveBtn" onClick={() => props.saveDiscount()}>
                                    {t('save')}
                                </Button>
                            </div>
                        </Row>
                    </>
                )}
            </div>
        </div>
    );
}
