import React from 'react';

import icons from './../../../icons/icomoon.svg';
import { getHelperTitleText } from '../utils';

const ActivePlanControlBar = ({ deletePlans, selectedRefillPlans, exportData, editing, addNewPlans, t, activeViewMode, toggleViewMode, isAdmin }) => {
    const onActionButtonClicked = action => {
        if (!selectedRefillPlans.length) return;
        switch (action) {
            case 'delete':
                deletePlans();
                break;
            case 'pdf':
                exportData('pdf');
                break;
            case 'excel':
                exportData('excel');
                break;
            default:
                // default action
                break;
        }
    };

    return (
        <div className="refillschema__controller">
            <div className="refillschema__controller-toggle">
                <span
                    className={`${activeViewMode === 'inventory' ? 'refillschema__controller-toggle-inventory refillschema__controller-toggle--active' : ''}`}
                    onClick={() => toggleViewMode('inventory')}
                >
                    Inventory
                </span>
                <span
                    className={`${activeViewMode === 'planogram' ? 'refillschema__controller-toggle-planogram refillschema__controller-toggle--active' : ''}`}
                    onClick={() => toggleViewMode('planogram')}
                >
                    Planogram
                </span>
            </div>
            {!editing && (
                <div className="refillschema__controller-btns">
                    {isAdmin && (
                        <div
                            className="refillschema__controller-action refillschema__controller-action-primary"
                            onClick={() => addNewPlans()}
                            title={getHelperTitleText('add', t)}
                        >
                            <svg>
                                <use xlinkHref={`${icons}#icon-plus-circle`}></use>
                            </svg>
                            <span>{t('addNewRefillPlan')}</span>
                        </div>
                    )}
                    <div
                        className={`refillschema__controller-action refillschema__controller-action-primary ${
                            !selectedRefillPlans.length ? 'refillschema__controller-action--inactive' : ''
                        }`}
                        title={getHelperTitleText('pdf', t)}
                        onClick={() => onActionButtonClicked('pdf')}
                    >
                        <svg>
                            <use xlinkHref={`${icons}#icon-file-pdf-o`}></use>
                        </svg>
                        <span>pdf</span>
                    </div>
                    <div
                        className={`refillschema__controller-action refillschema__controller-action-primary refillschema__controller-action-primary--excel ${
                            !selectedRefillPlans.length ? 'refillschema__controller-action--inactive' : ''
                        }`}
                        title={getHelperTitleText('excel', t)}
                        onClick={() => onActionButtonClicked('excel')}
                    >
                        <svg>
                            <use xlinkHref={`${icons}#icon-file-excel`}></use>
                        </svg>
                        <span>excel</span>
                    </div>
                    {isAdmin && (
                        <div
                            className={`refillschema__controller-action refillschema__controller-action-negative ${
                                !selectedRefillPlans.length ? 'refillschema__controller-action--inactive' : ''
                            }`}
                            onClick={() => onActionButtonClicked('delete')}
                            title={getHelperTitleText('delete', t)}
                        >
                            <svg>
                                <use xlinkHref={`${icons}#icon-trash-2`}></use>
                            </svg>
                            <span>{t('main:delete')}</span>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default ActivePlanControlBar;
