import {
    createSupplier as createSupplierHttpRequest,
    fetchSuppliers as fetchSuppliersHttpRequest,
    updateSupplier as updateSupplierHttpRequest,
    archiveSupplier as archiveSupplierHttpRequest,
} from '../api.js';

export const REQUEST_SUPPLIERS = 'REQUEST_SUPPLIERS';
export const RECEIVE_SUPPLIERS = 'RECEIVE_SUPPLIERS';
export const RECEIVE_SUPPLIERS_ERROR = 'RECEIVE_SUPPLIERS_ERROR';

export const REQUEST_CREATE_SUPPLIER = 'REQUEST_CREATE_SUPPLIER';
export const RECEIVE_CREATE_SUPPLIER = 'RECEIVE_CREATE_SUPPLIER';
export const RECEIVE_CREATE_SUPPLIER_ERROR = 'RECEIVE_CREATE_SUPPLIER_ERROR';

export const REQUEST_UPDATE_SUPPLIER = 'REQUEST_UPDATE_SUPPLIER';
export const RECEIVE_UPDATE_SUPPLIER = 'RECEIVE_UPDATE_SUPPLIER';
export const RECEIVE_UPDATE_SUPPLIER_ERROR = 'RECEIVE_UPDATE_SUPPLIER_ERROR';

export const REQUEST_DELETE_SUPPLIER = 'REQUEST_DELETE_SUPPLIER';
export const RECEIVE_DELETE_SUPPLIER = 'RECEIVE_DELETE_SUPPLIER';
export const RECEIVE_DELETE_SUPPLIER_ERROR = 'RECEIVE_DELETE_SUPPLIER_ERROR';

export const DISMISS_SUPPLIER_RESULT = 'DISMISS_SUPPLIER_RESULT';

export const RESET_SUPPLIERS = 'RESET_SUPPLIERS';

export const SEARCH_SUPPLIER_QUERY = 'SEARCH_SUPPLIER_QUERY';

/**
 * Request suppliers Redux action creator
 */
function requestSuppliers() {
    return {
        type: REQUEST_SUPPLIERS,
    };
}

/**
 * Receive suppliers Redux action creator
 * @param {array} suppliers Array of suppliers received from root api
 */
function receiveSuppliers(suppliers) {
    return {
        type: RECEIVE_SUPPLIERS,
        suppliers,
    };
}

/**
 * Receive supplier error Redux action creator
 * @param {Error} error The error object created in api.js
 */
function receiveSuppliersError(error) {
    return {
        type: RECEIVE_SUPPLIERS_ERROR,
        error: error,
    };
}

/**
 * Thunk action for fetching suppliers
 */
export function fetchSuppliers() {
    return (dispatch, getState) => {
        dispatch(requestSuppliers());

        const user = getState().user;
        return fetchSuppliersHttpRequest(user.group, user.accessToken)
            .then(response => dispatch(receiveSuppliers(response)))
            .catch(error => dispatch(receiveSuppliersError(error)));
    };
}

/**
 * Request create supplier Redux action creator
 */
function requestCreateSupplier() {
    return {
        type: REQUEST_CREATE_SUPPLIER,
    };
}

/**
 * Receive create supplier Redux action creator
 * @param {object} supplier The new supplier object that is created. Coming from root API.
 */
function receiveCreateSupplier(supplier) {
    return {
        type: RECEIVE_CREATE_SUPPLIER,
        supplier,
    };
}

/**
 * Receive supplier error Redux action creator
 * @param {Error} error The Error object received from api.js while creating a new supplier
 */
function receiveCreateSupplierError(error) {
    return {
        type: RECEIVE_CREATE_SUPPLIER_ERROR,
        error,
    };
}

/**
 * Thunk action for creating a new supplier
 * @param {object} supplier The new supplier object to be created in db.
 */
export function createSupplier(supplier) {
    return (dispatch, getState) => {
        dispatch(requestCreateSupplier());

        const user = getState().user;
        return createSupplierHttpRequest(supplier, user.group, user.accessToken)
            .then(createdSupplier => dispatch(receiveCreateSupplier(createdSupplier)))
            .catch(error => dispatch(receiveCreateSupplierError(error)));
    };
}

/**
 * Request update supplier Redux action creator
 */
function requestUpdateSupplier() {
    return {
        type: REQUEST_UPDATE_SUPPLIER,
    };
}

/**
 * Receive update supplier Redux action creator
 * @param {object} supplier The updated supplier object received from root api
 */
function receiveUpdateSupplier(supplier) {
    return {
        type: RECEIVE_UPDATE_SUPPLIER,
        supplier,
    };
}

/**
 * Receive update supplier error Redux action creator
 * @param {Error} error The error object received from api.js while handling update supplier
 */
function receiveUpdateSupplierError(error) {
    return {
        type: RECEIVE_UPDATE_SUPPLIER_ERROR,
        error,
    };
}

/**
 * Thunk action for updating a supplier
 * @param {string} id The id of the supplier which needs to be updated
 * @param {object} supplierToBeUpdated The supplier object to be updated
 */
export function updateSupplier(id, supplierToBeUpdated) {
    return (dispatch, getState) => {
        dispatch(requestUpdateSupplier());

        const user = getState().user;
        return updateSupplierHttpRequest(id, supplierToBeUpdated, user.group, user.accessToken)
            .then(updatedSupplier => dispatch(receiveUpdateSupplier(updatedSupplier)))
            .catch(error => dispatch(receiveUpdateSupplierError(error)));
    };
}

/**
 * Delete supplier request started
 */
function requestDeleteSupplier() {
    return {
        type: REQUEST_DELETE_SUPPLIER,
    };
}

/**
 * Deleting supplier is successful
 */
function receiveDeleteSupplier(id) {
    return {
        type: RECEIVE_DELETE_SUPPLIER,
        id,
    };
}

/**
 * Delete supplier has error
 */
function receiveDeleteSupplierError(error) {
    return {
        type: RECEIVE_DELETE_SUPPLIER_ERROR,
        error,
    };
}

/**
 * Delete the supplier from database
 * @param {Object} supplier
 */
export function deleteSupplier(supplier) {
    return (dispatch, getState) => {
        dispatch(requestDeleteSupplier());

        const user = getState().user;
        return archiveSupplierHttpRequest(supplier, user.accessToken, user.group)
            .then(() => dispatch(receiveDeleteSupplier(supplier.id)))
            .catch(error => dispatch(receiveDeleteSupplierError(error)));
    };
}

/**
 * Dismiss results of supplier requests Redux action creator
 */
export function dismissSupplierResults() {
    return {
        type: DISMISS_SUPPLIER_RESULT,
    };
}

/**
 * Resets suppliers state to initial state
 */
function resetSuppliers() {
    return {
        type: RESET_SUPPLIERS,
    };
}

/**
 * Thunk action creator for refreshing suppliers state
 */
export function refreshSuppliers() {
    return dispatch => {
        dispatch(resetSuppliers());
        dispatch(fetchSuppliers());
    };
}

/**
 * Thunk action for handling supplier search
 */
export function supplierSearchQuery(query) {
    return dispatch => {
        dispatch({
            type: SEARCH_SUPPLIER_QUERY,
            query,
        });
    };
}
