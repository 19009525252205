import React, {useState, useEffect, Fragment} from "react";
import { Checkbox } from '@storaensods/seeds-react';
import { forEach, set } from 'lodash';

import icons from './../../../icons/icomoon.svg';

const CabinetLists =({cabinetsByLocations={}, selectedCabinets={}, cabinetSelections, t})=>{
    const [activeLocation, setActiveLocation] = useState();
    const [cabinetsForView, setCabinetsForView] = useState(cabinetsByLocations);

    useEffect(()=>{
        setCabinetsForView(cabinetsByLocations)
    },[cabinetsByLocations])

    const onCheckBoxChange=(checked, location, cabinet)=>{
        const selectedDevicesByLocations = {...selectedCabinets}
        // if the selection is on the location checkbox
        if(!cabinet){
            if(checked){
                set(selectedDevicesByLocations, location, cabinetsByLocations[location])
            }else{
                delete selectedDevicesByLocations[location]
            }
        }else{
            const cabinetLocation = selectedDevicesByLocations[location]
            if(!cabinetLocation){
                set(selectedDevicesByLocations, location, [cabinet])
            }else{
                if(checked){
                    set(selectedDevicesByLocations, location, [...selectedDevicesByLocations[location], cabinet])
                }else{
                    set(selectedDevicesByLocations, location, [...selectedDevicesByLocations[location].filter(device=>device.deviceCode !== cabinet.deviceCode)])
                }
            }
        }
        cabinetSelections(selectedDevicesByLocations)
    }
    
    const isChecked=(location, cabinet)=>{
  
        const selected = selectedCabinets[location];
        
        if (!selected) return false;
        //if no cabinet provided, the request is for location selection
        // otherwise its the cabinet selection
        if (!cabinet) {
            if (selected.length === cabinetsByLocations[location].length) return true;
        } else {
            if (selected.find(device => device.deviceCode === cabinet.deviceCode)) return true;
        }
        return false;
        
    }

    /**
     * when location tab is clicked
     * @param {location id} location 
     * @returns 
     */
    const onLocationTabClicked=(location)=>{
        if(activeLocation === location){
            setActiveLocation(null)
            return
        }

        setActiveLocation(location)
    }

    /**
     * when select all cabinet button is checked or uncheked
     * @param {boolean} checked 
     */
    const onSelectAllCabinets=(checked)=>{
        if(checked){
            cabinetSelections(cabinetsByLocations)
        }else{
            cabinetSelections({})
        }
    }

    /**
     * search the cabinet based on the search query and send for the view
     * @param {string} text search text in the search field
     * @returns 
     */
    const getCabinetsForView=(text)=>{
        if(!text){
            setCabinetsForView(cabinetsByLocations);
            return
        }

        const searchQuery = text.toString().toLowerCase();
        const filteredCabinets= {}
       
        forEach(cabinetsByLocations, (cabinets, location)=>{
            const searchedCabinets = cabinets.filter(cabinet=>{
                //if the cabinet is already selected, keep the cabinet in search filtered.
               
                if(selectedCabinets[location]){
                    if(selectedCabinets[location].find(device=>device.deviceCode === cabinet.deviceCode))return true
                }
                
                return cabinet.name.toString().toLowerCase().includes(searchQuery) || cabinet.deviceCode.toString().toLowerCase().includes(searchQuery)
            })
            

            if(searchedCabinets.length){               
                set(filteredCabinets, location, searchedCabinets)
            }
        })

        setCabinetsForView(filteredCabinets)
    }

    const isSelectAllChecked = () => {
        let selectedCabinetsLength = 0;
        let cabinetsLength = 0;
        if (Object.keys(selectedCabinets).length === 0) 
            return false;
        Object.keys(selectedCabinets).forEach(key => {
            selectedCabinetsLength += selectedCabinets[key].length;
        });
        Object.keys(cabinetsByLocations).forEach(key => {
            cabinetsLength += cabinetsByLocations[key].length;
        });
        return selectedCabinetsLength === cabinetsLength;
    }

    return (
        <div className="automations__cabinets">
            <div className="automations__cabinets-search">
                <input type="text" placeholder={t('main:searchCabinets')} onChange={(e)=>getCabinetsForView(e.target.value)}/>
                <svg>
                    <use xlinkHref={`${icons}#icon-search`}></use>
                </svg>
            </div>
            <div className="automations__locations">
                <div className="automations__locations-header">
                    <div className="automations__locations-header-checkbox">
                        <Checkbox size="sm" id="selectall" checked={isSelectAllChecked()} onChange={(e)=>onSelectAllCabinets(e.target.checked)}>{t('main:selectAll')}</Checkbox>
                    </div>
                    <div className="automations__locations-header-items"> </div>
                </div>
                {Object.keys(cabinetsForView).map(location => {
                    return (
                        <Fragment key={location}>
                            <div
                                className={`automations__locations-header ${location === activeLocation ? 'automations__locations-header--active' : ''}`}
                                key={location}
                            >
                                <div className="automations__locations-header-checkbox">
                                    <Checkbox
                                        size="sm"
                                        id={location}
                                        onChange={e => onCheckBoxChange(e.target.checked, location)}
                                        checked={isChecked(location)}
                                    />
                                </div>
                                <div onClick={() => onLocationTabClicked(location)} className="automations__locations-header-items">
                                    <svg>
                                        <use xlinkHref={`${icons}#icon-keyboard_arrow_right`}></use>
                                    </svg>
                                    <svg>
                                        <use xlinkHref={`${icons}#icon-location`}></use>
                                    </svg>
                                    <span className="automations__locations-name">{location}</span>
                                    <span className="automations__count">{cabinetsForView[location].length}</span>
                                </div>
                            </div>

                            <ul
                                className={`automations__locations-cabinets ${
                                    location === activeLocation ? 'automations__locations-cabinets--active' : ''
                                }`}
                            >
                                {Array.isArray(cabinetsForView[location]) && cabinetsForView[location].map(cabinet => {
                                    return (
                                        <li key={cabinet.deviceCode}>
                                            <Checkbox
                                                size="sm"
                                                id={cabinet.deviceCode}
                                                onChange={e => onCheckBoxChange(e.target.checked, location, cabinet)}
                                                checked={isChecked(location, cabinet)}
                                            />{' '}
                                            {cabinet.name}{' '}
                                        </li>
                                    );
                                })}
                            </ul>
                        </Fragment>
                    );
                })}
            </div>
        </div>
    )
}

export default CabinetLists;