import React, { Component } from 'react';
import { translate } from 'react-i18next';
import { Row, Col } from 'reactstrap';
import { Button, Popover, Input } from '@storaensods/seeds-react';
import './confirmPopover.css';

class ConfirmPopover extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: '',
        };
    }

    render() {
        const { t } = this.props;
        return (
            <Popover
                ref={r => (this.popoverRef = r)}
                component={
                    <Button className="mr-2" icon={this.props.icon} disabled={this.props.disabled} style={this.props.style}>
                        {this.props.title}
                    </Button>
                }
            >
                <Row>
                    <Col xs="12" md="12" className="mb-2 popover-text">
                        {this.props.confirm}
                    </Col>
                </Row>
                {this.props.askReason && (
                    <Row>
                        <Col xs="12" md="12" className="mb-2">
                            <Input
                                placeholder={this.props.hint}
                                value={this.state.value}
                                onChange={event => {
                                    this.setState({ value: event.target.value });
                                }}
                            />
                        </Col>
                    </Row>
                )}
                <Row>
                    <Col xs="6" md="6" className="mb-2">
                        <Button
                            className="mr-2"
                            icon={this.props.icon}
                            onClick={() => {
                                this.props.action(this.state.value);
                                this.setState({ value: '' }); // reset value
                            }}
                            disabled={this.props.disabled || (this.props.askReason && !this.state.value)}
                        >
                            {t('confirm')}
                        </Button>
                    </Col>
                </Row>
            </Popover>
        );
    }
}

export default translate('main')(ConfirmPopover);
