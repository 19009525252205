import React from 'react';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';
import { Button, Popover } from '@storaensods/seeds-react';
import { Row, Col } from 'reactstrap';
import { CSVLink } from 'react-csv';
import moment from 'moment';
import zipcelx from 'zipcelx';

const getCabinetNameAndDescription = (deviceCode, cabinets) => {
    if (cabinets) {
        const cabinet = cabinets.filter(cabinet => cabinet.deviceCode === deviceCode)[0];
        return cabinet ? `${cabinet.name}` : deviceCode;
    }
    return deviceCode;
};

const getFileName = (exportFileName, cabinetName, timeRange) => {
    return `${exportFileName} ${cabinetName} ${formatDate(timeRange)}`;
};

const formatDate = timeRange => {
    if (moment(timeRange.from).format('YYYY-MM-DD') === moment(timeRange.to).format('YYYY-MM-DD')) {
        return `${moment(timeRange.from).format('YYYY-MM-DD')}`;
    } else {
        return `${moment(timeRange.from).format('YYYY-MM-DD')} ${moment(timeRange.to).format('YYYY-MM-DD')}`;
    }
};

const ReportHeader = ({ t, title, exportData, exportDataExcel, exportHeaders, deviceCode, cabinets, timeRange, exportFileName }) => (
    <div className="export-button">
        <h1>{title}</h1>
        <Popover
            component={
                <Button icon="file_download" type="secondary">
                    {t('reports:export')}
                </Button>
            }
            isFlipping
            position="bottom"
        >
            <Row>
                <Col>
                    <Button
                        onClick={() => {
                            zipcelx({
                                ...exportDataExcel,
                                filename: `${getFileName(
                                    exportFileName ? exportFileName : title,
                                    getCabinetNameAndDescription(deviceCode, cabinets),
                                    timeRange
                                )}`,
                            });
                        }}
                    >
                        Excel
                    </Button>
                </Col>
                <Col>
                    <CSVLink
                        className="export-csv se-btn se-btn--md se-btn--primary"
                        data={exportData}
                        headers={exportHeaders}
                        target="_blank"
                        filename={`${getFileName(exportFileName ? exportFileName : title, getCabinetNameAndDescription(deviceCode, cabinets), timeRange)}.csv`}
                    >
                        CSV
                    </CSVLink>
                </Col>
            </Row>
        </Popover>
    </div>
);

const mapStateToProps = state => ({
    deviceCode: state.cabinets.selectedCabinetDeviceCode,
    timeRange: state.report.timeRange,
    cabinets: state.cabinets.cabinets,
});

export default connect(mapStateToProps, null)(translate()(ReportHeader));
