import React from 'react';
import { Table } from 'reactstrap';
import moment from 'moment';
import { cubeDataKeyToLabel } from './customSelector.js';
import { getLocalTime } from './../report/export';

export default function CustomTable({ data, t, granularity }) {
    let report = [...data];
    if (report.length === 0) {
        return <div>{t('noData')}</div>;
    }

    // Note! Cube returns the times already converted to client timezone, so we need to use them as they are without conversion!
    const clientTimeZone = 'Etc/UTC'; //Intl.DateTimeFormat().resolvedOptions().timeZone;
    report = report.map(d => {
        const cubeData = { ...d };
        const propertyNames = Object.keys(cubeData);

        propertyNames.forEach(name => {
            if (name.includes('timestamp') || name.includes('readtime') || name.includes('expiryDate')) {
                if (name.includes(`timestamp.${granularity}`) || name.includes(`readtime.${granularity}`)) {
                    delete cubeData[name];
                    return;
                }
                if (name.includes('expiryDate')) {
                    cubeData[name] = cubeData[name] ? cubeData[name] : undefined;
                } else {
                    /* getLocalTime was removed from many cases because it messed actually time calculation. E.g, when grouped by day,
                     it already gave times from previous day as "today-00:00:00", and logic converted them back to previous day*/
                    switch (granularity) {
                        case 'hour':
                            cubeData[name] = moment(getLocalTime(cubeData[name], clientTimeZone)).format('YYYY-MM-DD HH:mm');
                            break;
                        case 'day':
                            cubeData[name] = moment(cubeData[name]).format('YYYY-MM-DD');
                            break;
                        case 'week':
                            cubeData[name] = moment(cubeData[name]).format('YYYY-MM-DD');
                            break;
                        case 'month':
                            cubeData[name] = moment(cubeData[name]).format('YYYY-MM');
                            break;
                        case 'year':
                            cubeData[name] = moment(cubeData[name]).format('YYYY');
                            break;
                        default:
                            cubeData[name] = moment(getLocalTime(cubeData[name], clientTimeZone)).format('YYYY-MM-DD');
                            break;
                    }
                }
            }
        });

        return cubeData;
    });

    const keys = Object.keys(report[0]);

    return (
        <div style={{ overflowX: 'auto' }}>
            <Table>
                <thead>
                    <tr>
                        {keys.map(key => (
                            <th key={key}>{cubeDataKeyToLabel(key)}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {report.map((entry, i) => (
                        <tr key={i}>
                            {keys.map(key => (
                                <td key={key}>
                                    {key === 'Image' ? (
                                        <>
                                            {' '}
                                            {entry[key] ? (
                                                <img style={{ width: '100px', height: 'auto' }} src={entry[key]} alt="product" />
                                            ) : (
                                                <div className="text-center d-flex align-items-center products-missing-image-container">
                                                    <span className="icon-picture material-icons" style={{ width: '100px', height: 'auto' }}></span>
                                                </div>
                                            )}
                                        </>
                                    ) : typeof entry[key] === 'number' ? (
                                        entry[key].toFixed(2).replace(/[.,]00$/, '')
                                    ) : (
                                        entry[key]
                                    )}
                                    {}
                                </td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </Table>
        </div>
    );
}
