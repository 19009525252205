import React, { Fragment, useEffect, useState } from 'react';
import { Table } from 'reactstrap';
import { groupBy, map, sumBy } from 'lodash';
import moment from 'moment';

import icons from './../../../icons/icomoon.svg';
import RefillNoteModel from '../modals/refillNoteModel';
import { Checkbox } from '@storaensods/seeds-react';
import ApiRequestModal from '../modals/ApiRequestModal';
import { _13digitBarcode, imageUrlToBase64 } from '../utils';
import ActivePlanControlBar from './activePlanControlBar';
import ConfirmationModal from '../modals/confirmationModal';
import generatePDF from '../refillPlanPDF';
import refillPlanExcel from '../refillPlanEXCEL';
import Planogram from './planogram';
import { Loader, Button } from '../../commons/utils';
import InfoBanner from './InfoBanner';
import PlanogramImageModal from '../modals/planogramImageModal';

const ActivePlanData = ({
    refillPlans,
    allActiveRefillPlans,
    updateRefillPlan,
    fetchCabinetInventory,
    cabinetInventory,
    getTransactions,
    cabinetTransactionReport,
    deleteRefillPlan,
    organization,
    addNewPlans,
    allProducts,
    t,
    toggleViewMode,
    activeViewMode,
    allRefillSchemas,
    uploadPlanogramImage,
    planogramImageInStore,
    isAdmin,
}) => {
    const [expandedRefillPlanId, setExpandedRefillPlanId] = useState();
    const [editEnabled, setEditEnabled] = useState(false);
    const [refillPlanData, setRefillPlanData] = useState(allActiveRefillPlans);
    const [openApiRequestModal, setOpenApiRequestModal] = useState(false);
    const [prestine, setPrestine] = useState(true);
    const [refillNoteModal, setRefillNoteModal] = useState({ isOpen: false, product: null, refillNote: null });
    const [cabinetTransactions, setCabinetTransaction] = useState({});
    const [selectedRefillPlans, setSelectedRefillPlans] = useState([]);
    const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] = useState(false);
    const [openPlanogramImageModal, setOpenPlanogramImagemModal] = useState(false);
    const [planogramImageUrl, setPlanogramImageUrl] = useState({ prestine: true, imageUrl: null });

    useEffect(() => {
        if (cabinetTransactionReport.isFetching) return;
        const purchases = cabinetTransactionReport.data || [];
        if (!purchases.length) {
            const planSelected = refillPlanData.find(plan => plan.id === expandedRefillPlanId)?.deviceCode;
            setCabinetTransaction({ ...cabinetTransactions, [planSelected]: [] });
            return;
        }

        //check if the data has already been saved in state,
        //if already in state, no need to calclutate payload again
        const deviceCode = purchases[0].deviceCode;
        if (cabinetTransactions[deviceCode]) return;

        let purchasedBarcodes = [];

        purchases.forEach(data => {
            const purchasedProducts = data.purchases?.purchase || [];
            if (!purchasedProducts.length) return;

            purchasedProducts.forEach(product => {
                purchasedBarcodes.push({
                    barcode: product.barcode,
                    count: product.count,
                });
            });
        });

        const groupedBarcode = groupBy(purchasedBarcodes, 'barcode');
        const totalPurchases = map(groupedBarcode, (products, barcode) => {
            return {
                barcode,
                totalCount: sumBy(products, product => product.count),
            };
        });

        setCabinetTransaction({
            ...cabinetTransactions,
            [deviceCode]: totalPurchases,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cabinetTransactionReport]);

    useEffect(() => {
        setRefillPlanData(allActiveRefillPlans);
    }, [allActiveRefillPlans]);

    /**
     * Onclick, toggle refill plan view on and off
     * if not already in state, fetched the current inventory of the device
     * if not already in  state, fetch last 7 days transaction of the device
     * @param {*} id refillplan id
     * @param {*} deviceCode
     */
    const toggleRefillPlanView = (id, deviceCode, isEditing = false) => {
        if (!cabinetTransactions[deviceCode]) {
            const from = moment()
                .subtract(7, 'days')
                .startOf('day')
                .toDate();
            const today = moment().toDate();
            getTransactions(deviceCode, from, today);
        }

        if (!cabinetInventory[deviceCode]) {
            fetchCabinetInventory(deviceCode);
        }

        if (isEditing) return;
        if (id === expandedRefillPlanId) {
            setExpandedRefillPlanId(null);
        } else {
            setExpandedRefillPlanId(id);
        }
    };

    /**
     * update refill data state when + or - is clicked
     * @param {*} type 'amountToRefill' | 'refillNote'
     * @param {*} action 'plus' | 'minus'
     * @param {*} product refill product data
     * @param {*} value 'refillnote'
     * @returns
     */
    const handleRefillDataChange = (type, action, product, value) => {
        const getRefillPlan = refillPlanData.find(plan => plan.id === expandedRefillPlanId);
        if (!getRefillPlan) return;
        setPrestine(false);

        let updatePlans = [...getRefillPlan.productData];

        if (type === 'refillNote') {
            updatePlans = updatePlans.map(plan => {
                if (plan.barcode === product.barcode) {
                    return {
                        ...plan,
                        refillNote: value,
                    };
                }
                return plan;
            });
        } else {
            updatePlans = updatePlans.map(plan => {
                if (plan.barcode === product.barcode) {
                    return {
                        ...plan,
                        [type]: action === 'plus' ? plan[type] + 1 : plan[type] - 1,
                    };
                }
                return plan;
            });
        }

        setRefillPlanData(
            refillPlanData.map(data => {
                if (data.id === expandedRefillPlanId) {
                    return {
                        ...data,
                        productData: updatePlans,
                    };
                }
                return data;
            })
        );
    };

    /**
     *
     * @param {*} planId check or uncheck refill plan
     */
    const onRefillPlanChecked = planId => {
        let plansChoosen = [...selectedRefillPlans];
        if (plansChoosen.includes(planId)) {
            plansChoosen = plansChoosen.filter(id => id !== planId);
        } else {
            plansChoosen.push(planId);
        }

        setSelectedRefillPlans(plansChoosen);
    };

    /**
     * if select all checkbox is checked/unchecked
     * @param {boolean} toggle
     * @returns
     */
    const onSelectAllRefillPlans = toggle => {
        if (!toggle) {
            setSelectedRefillPlans([]);
            return;
        }
        const plansIds = [];

        refillPlanData.forEach(plan => {
            plansIds.push(plan.id);
        });

        setSelectedRefillPlans(plansIds);
    };

    /**
     * calcultes the amount of product items in the current inventory
     * @param {*} deviceCode
     * @param {*} product
     * @returns
     */
    const getCurrentInventoryProduct = (deviceCode, product) => {
        const deviceInventory = cabinetInventory[deviceCode];
        if (!deviceInventory) return '-';

        if (deviceInventory.isFetching)
            return (
                <svg className="activeplans__spinner--small">
                    <use xlinkHref={`${icons}#icon-spinner7`}></use>
                </svg>
            );

        const inventory = deviceInventory.inventory;
        if (!inventory) return '-';
        if (!inventory.length) return 0;

        // group the inventory by barcode,
        const inventoryByBarcode = groupBy(inventory, 'barcode');
        return inventoryByBarcode[product.barcode] ? inventoryByBarcode[product.barcode].length : 0;
    };

    /**
     * How many items were sold for each product.
     * @param {*} deviceCode
     * @param {*} product
     * @returns
     */
    const getProductSalesCount = (deviceCode, product) => {
        if (cabinetTransactionReport.isFetching) {
            return (
                <svg className="activeplans__spinner--small">
                    <use xlinkHref={`${icons}#icon-spinner7`}></use>
                </svg>
            );
        }

        const purchaseData = cabinetTransactions[deviceCode];
        if (!purchaseData) return '-';

        const productSales = purchaseData.find(data => _13digitBarcode(data.barcodeType, data.barcode) === product.barcode);
        if (!productSales) return 0;
        return productSales.totalCount;
    };

    const deleteSelectedRefillPlans = () => {
        const refillPlansIds = [...selectedRefillPlans];
        deleteRefillPlan(refillPlansIds);
        setIsDeleteConfirmationOpen(false);
        setSelectedRefillPlans([])
    };

    /**
     * a function called to print refill plan and planogram data in the PDF
     * makes API calls to convert products image in planograms to base64 blob data to display in planogram table
     * @param {*} refillPlanExportData
     * @param {*} organization
     */
    const exportPlanogramAndRefillData = (refillPlanExportData, organization) => {
        const plansToExport = refillPlanData.filter(plan => selectedRefillPlans.includes(plan.id));
        const planogramExportData = [];
        const productsInPlanogram = [];
        const allPlanogramImages = [];

        plansToExport.forEach(plan => {
            let refillPlanPlanogram = plan.planogram;
            let refillPlanogramImageUrl = plan.planogramImageUrl;

            // take the planogram from refill schema, if refill plan does not have a planogram
            if (!refillPlanPlanogram) {
                const allSchemas = allRefillSchemas.refillRules || [];
                const refillSchema = allSchemas.find(schema => schema.deviceCode === plan.deviceCode)?.refillRule;
                if (refillSchema && refillSchema.planogram) {
                    refillPlanPlanogram = refillSchema.planogram;
                    refillPlanogramImageUrl = refillSchema.planogramImageUrl;
                }
            }

            if (!refillPlanPlanogram) return;

            //map product name, barcode and images from productID in planogram data
            const { shelves } = refillPlanPlanogram || [];
            for (let shelf of shelves) {
                const productData = shelf.productData;

                productData.forEach(slot => {
                    slot.products.forEach(productId => {
                        const product = allProducts.find(p => p.id === productId);
                        if (!product) return;
                        if (!productsInPlanogram.find(p => p.id === productId)) {
                            productsInPlanogram.push({
                                id: productId,
                                imageUrl: product.imageUrl ? product.imageUrl.replace('cdn.intelligentpackaging.online', 'cdn.selflystore.com') : '',
                                barcode: product.barcode,
                                name: product.name,
                            });
                        }
                    });
                });
            }
            planogramExportData.push({
                planogram: refillPlanPlanogram,
                planogramImageUrl: refillPlanogramImageUrl,
                cabinetName: plan.deviceName,
                locationName: plan.location,
            });

            if (refillPlanogramImageUrl) {
                allPlanogramImages.push({ cabinetName: plan.deviceName, imageUrl: refillPlanogramImageUrl });
            }
        });

        // we need to convert images in planogram products as well as planogramImages to base64
        const imagesToConvert = [...productsInPlanogram, ...allPlanogramImages];
        Promise.all(imagesToConvert.map(data => imageUrlToBase64(data.imageUrl, data.id, data.cabinetName)))
            .then(respose => {
                const planogramProducts = [];
                const planogramImages = [];
                respose.forEach(imageData => {
                    const base64Url = imageData.imageUrl.includes('data:text/html') || !imageData.imageUrl ? '' : imageData.imageUrl;
                    if (imageData.id) {
                        const product = productsInPlanogram.find(p => p.id === imageData.id);
                        planogramProducts.push({ ...product, imageUrl: base64Url });
                    } else {
                        planogramImages.push({ cabinetName: imageData.cabinetName, imageUrl: base64Url });
                    }
                });

                //generatePlanogramPDF(planogramExportData, planogramProducts)
                generatePDF(refillPlanExportData, organization, planogramExportData, planogramProducts, planogramImages);
            })
            .catch(e => console.log(e));
    };

    /**
     * export the refill plan data, based on the type selected
     * @param {*} type pdf| excel
     * @returns
     */
    const exportRefillPlanData = type => {
        if (!type) return;

        const plansToExport = refillPlanData.filter(plan => selectedRefillPlans.includes(plan.id));
        let exportData = [];

        plansToExport.forEach(plan => {
            const productDatas = plan.productData || [];

            // map products data for each refill plan to be exported in PDF
            productDatas.forEach(product => {
                // Filter out the products where both target and refill amounts are 0
                (product.amountToRefill || product.targetInvAmount) &&
                    exportData.push({
                        barcode: product.barcode,
                        product: product.name,
                        cabinetName: plan.deviceName,
                        locationName: plan.location,
                        refillAmount: product.amountToRefill,
                        refillNote: product.refillNote,
                        targetInvAmount: product.targetInvAmount || 0,
                        category: allProducts.find(p => _13digitBarcode(p.barcodeType, p.barcode) === product.barcode)?.productCategory?.name,
                    });
            });
        });

        if (type === 'excel') {
            refillPlanExcel(exportData, organization, t);
        } else {
            // call to a function to map planogram products
            exportPlanogramAndRefillData(exportData, organization);
        }
    };

    /**
     * get the planogram image url for the selected refill plan
     * @returns planogramImageUrl
     */
    const getPlanogramImage = () => {
        const allRefillPlans = refillPlans.refillPlan || [];

        const plan = allRefillPlans.find(plan => plan.id === expandedRefillPlanId);
        if (!plan) return null;

        const refillPlanImageUrl = plan.planogramImageUrl;
        const schemas = allRefillSchemas.refillRules || [];
        const selectedCabinetSchema = schemas.find(schema => schema.deviceCode === plan.deviceCode);

        if (!planogramImageUrl.prestine) return planogramImageUrl.imageUrl;

        return refillPlanImageUrl || selectedCabinetSchema.refillRule.planogramImageUrl;
    };

    /**
     * send the updated refill plan to API for update
     * @returns
     */
    const sendRefillPlanToApi = (planogramData = null) => {
        if (prestine && !planogramData) return;
        const getRefillPlan = refillPlanData.find(plan => plan.id === expandedRefillPlanId);
        if (!getRefillPlan) return;

        const deviceCode = getRefillPlan.deviceCode;
        if (!planogramData) {
            planogramData = getRefillPlan.planogram;
        }
        const productData = getRefillPlan.productData.map(product => {
            delete product.targetInvAmount;
            return product;
        });

        const imageUrl = getPlanogramImage();

        updateRefillPlan(expandedRefillPlanId, {
            deviceCode,
            productData,
            ...(planogramData && { planogram: planogramData }),
            ...(imageUrl && { planogramImageUrl: imageUrl }),
            status: 'Active',
        });
        setOpenApiRequestModal(true);
    };

    /**
     * get the planogram view in the refill plan.
     * @param {*} refillPlan
     * @returns Planogram component
     */
    const getCabinetPlanogramView = refillPlan => {
        if (allRefillSchemas.isFetching) {
            return <Loader message="fetching data. Please wait." />;
        }
        const deviceCode = refillPlan.deviceCode;

        //get planogram data from refillPlan
        let planogramData = refillPlan.planogram;
        const refillPlanImageUrl = refillPlan.planogramImageUrl;
        const schemas = allRefillSchemas.refillRules || [];
        const selectedCabinetSchema = schemas.find(schema => schema.deviceCode === deviceCode);
        const productData = selectedCabinetSchema.refillRule.productData || [];
        const imageUrl = planogramImageUrl.imageUrl || refillPlanImageUrl || selectedCabinetSchema.refillRule.planogramImageUrl;

        const schema = { rule: { productData, planogramImageUrl: imageUrl } };

        //if refill plan does not have planogram, get from refill schema.
        if (!planogramData) {
            planogramData = selectedCabinetSchema.refillRule.planogram ? selectedCabinetSchema.refillRule.planogram : {};
        }

        if (!planogramData || !Object.keys(planogramData).length) {
            return (
                <div className={`activeplans__planogram ${refillPlan.id === expandedRefillPlanId ? ' activeplans__planogram--active' : ''}`}>
                    <InfoBanner message={t('noPlanogramInPlan')} />
                </div>
            );
        }

        return (
            <div className={`activeplans__planogram ${refillPlan.id === expandedRefillPlanId ? ' activeplans__planogram--active' : ''}`}>
                {(() => {
                    if (editEnabled || !imageUrl) {
                        return (
                            <Planogram
                                t={t}
                                cabinetRefillSchema={schema}
                                allProducts={allProducts}
                                selectedCabinet={{ deviceCode: refillPlan.deviceCode }}
                                planogramProductData={planogramData}
                                showViewModeToggle={false}
                                activeViewMode={activeViewMode}
                                refillPlan
                                allowEditing={editEnabled && expandedRefillPlanId === refillPlan.id}
                                saveRefillPlanogram={planogramData => {
                                    setPrestine(false);
                                    sendRefillPlanToApi(planogramData);
                                }}
                                planogramImageInStore={planogramImageInStore}
                                uploadPlanogramImage={uploadPlanogramImage}
                                changeRefillPlanogramImageData={imageUrl => {
                                    setPrestine(false);
                                    setPlanogramImageUrl({ imageUrl, prestine: false });
                                }}
                                isAdmin={isAdmin}
                            />
                        );
                    } else {
                        return (
                            <div className="activeplans__planogram-image">
                                <img src={imageUrl} alt="" />
                                {editEnabled && (
                                    <Button type="add" mb mt text={t('refill:editPlanogramImage')} onClick={() => setOpenPlanogramImagemModal(true)} />
                                )}
                            </div>
                        );
                    }
                })()}
            </div>
        );
    };

    /**
     * if edit enabled, show only selected refill plan, else show all
     * @returns array of refillplan to show in the view
     */
    const getRefillPlanDataForView = () => {
        if (!editEnabled || (editEnabled && !expandedRefillPlanId)) {
            return refillPlanData;
        }

        const planToEdit = refillPlanData.find(plan => plan.id === expandedRefillPlanId);
        if (!planToEdit) return refillPlanData;

        return [planToEdit];
    };

    return (
        <div className="activeplans__products">
            <ApiRequestModal
                isOpen={openApiRequestModal}
                data={refillPlans}
                closeModal={() => {
                    setOpenApiRequestModal(false);
                    setEditEnabled(false);
                    setPrestine(true);
                    setIsDeleteConfirmationOpen(false);
                    setSelectedRefillPlans([]);
                }}
                successMessage={activeViewMode === 'inventory' ? t('planSaved') : t('planogramSaved')}
                t={t}
            />
            <RefillNoteModel
                isOpen={refillNoteModal.isOpen}
                product={refillNoteModal.product}
                refillNote={refillNoteModal.refillNote}
                closeRefillNote={(product, text) => {
                    if (editEnabled) {
                        handleRefillDataChange('refillNote', null, product, text);
                    }
                    setRefillNoteModal({ isOpen: false, product: null, refillNote: '' });
                }}
                disabledEditing={editEnabled ? false : true}
                t={t}
                isAdmin={isAdmin}
            />
            <ConfirmationModal
                isOpen={isDeleteConfirmationOpen}
                cancel={() => setIsDeleteConfirmationOpen(false)}
                confirmDelete={() => deleteSelectedRefillPlans()}
                message={t('deleteConfirmation')}
                t={t}
            />
            <ActivePlanControlBar
                deletePlans={() => setIsDeleteConfirmationOpen(true)}
                selectedRefillPlans={selectedRefillPlans}
                exportData={type => exportRefillPlanData(type)}
                editing={expandedRefillPlanId ? true : false}
                addNewPlans={() => addNewPlans()}
                t={t}
                toggleViewMode={mode => toggleViewMode(mode)}
                activeViewMode={activeViewMode}
                isAdmin={isAdmin}
            />
            <PlanogramImageModal
                isOpen={openPlanogramImageModal}
                closeModal={() => setOpenPlanogramImagemModal(false)}
                t={t}
                planogramImageUrl={getPlanogramImage()}
                uploadPlanogramImage={uploadPlanogramImage}
                planogramImageInStore={planogramImageInStore}
                planogramImageData={imageUrl => {
                    setPrestine(false);
                    setPlanogramImageUrl({ imageUrl, prestine: false });
                }}
            />
            <div className="activeplans__item activeplans__item-selectall">
                <div className="activeplans__item-checkbox">
                    <Checkbox
                        id="selectAll"
                        onChange={e => onSelectAllRefillPlans(e.target.checked)}
                        checked={selectedRefillPlans.length === refillPlanData.length}
                    />
                </div>
                <div className="activeplans__item-header">
                    <span className="activeplans__item-name">
                        {t('main:selectAll')} ({selectedRefillPlans.length})
                    </span>
                </div>
            </div>
            {getRefillPlanDataForView().map(plan => {
                return (
                    <Fragment key={plan.id}>
                        <div className={`activeplans__item ${plan.id === expandedRefillPlanId ? ' activeplans__item--active' : ''}`}>
                            <div className="activeplans__item-checkbox">
                                <Checkbox
                                    id={plan.id}
                                    onChange={e => onRefillPlanChecked(plan.id)}
                                    checked={selectedRefillPlans.find(id => id === plan.id) || false}
                                />
                            </div>
                            <div onClick={() => toggleRefillPlanView(plan.id, plan.deviceCode)} className="activeplans__item-header">
                                <svg className="activeplans__item-drope">
                                    <use xlinkHref={`${icons}#icon-keyboard_arrow_right`}></use>
                                </svg>
                                <svg>
                                    <use xlinkHref={`${icons}#icon-location`}></use>
                                </svg>
                                <span className="activeplans__item-name">
                                    {t(`main:${plan.location}`)} | {plan.deviceName}
                                </span>
                            </div>
                            {editEnabled && plan.id === expandedRefillPlanId && (
                                <Fragment>
                                    <div className="activeplans__item-btn activeplans__item-btn--cancel">
                                        <button
                                            onClick={() => {
                                                setRefillPlanData(allActiveRefillPlans);
                                                setEditEnabled(false);
                                                setExpandedRefillPlanId(null);
                                            }}
                                        >
                                            <svg>
                                                <use xlinkHref={`${icons}#icon-close-outline`}></use>
                                            </svg>
                                            <span>{t('main:close')}</span>
                                        </button>
                                    </div>
                                    {(activeViewMode === 'inventory' || (activeViewMode === 'planogram' && !prestine)) && (
                                        <div className="activeplans__item-btn activeplans__item-btn--save">
                                            <button onClick={() => sendRefillPlanToApi()} className={`${prestine ? 'activeplans__item-btn--disabled' : ''}`}>
                                                <svg>
                                                    <use xlinkHref={`${icons}#icon-save`}></use>
                                                </svg>
                                                <span>{t('main:save')}</span>
                                            </button>
                                        </div>
                                    )}
                                </Fragment>
                            )}
                            {
                                (()=>{
                                    if(!(!editEnabled && isAdmin)){
                                        return
                                    }
                                    //check planogram from refillPlan
                                    let planogramData = plan.planogram

                                    //if refillPlan does not have planogram, get it from template
                                    if (!planogramData) {
                                        const schemas = allRefillSchemas.refillRules || [];
                                        const selectedCabinetSchema = schemas.find(schema => schema.deviceCode === plan.deviceCode);
                                        planogramData = selectedCabinetSchema.refillRule.planogram ? selectedCabinetSchema.refillRule.planogram : {};
                                    }

                                    // Edit button is only available if there is a planogram to edit in planogramViewMode
                                    if(activeViewMode==='inventory' || Object.keys(planogramData).length){
                                        return (
                                            <div className="activeplans__item-btn activeplans__item-btn--edit">
                                                <button
                                                    onClick={() => {
                                                        setExpandedRefillPlanId(plan.id);
                                                        setEditEnabled(true);
                                                        toggleRefillPlanView(plan.id, plan.deviceCode, true);
                                                    }}
                                                >
                                                    <svg>
                                                        <use xlinkHref={`${icons}#icon-create`}></use>
                                                    </svg>
                                                    <span>{activeViewMode === 'inventory' ? t('editPlan') : t('editPlanogram')}</span>
                                                </button>
                                            </div>
                                        )
                                    }
                                })()
                            }
                        </div>
                        {/** if the viewMode is planogram, show planogram here */}
                        {activeViewMode === 'planogram' && getCabinetPlanogramView(plan)}
                        <div
                            className={`activeplans__table ${
                                plan.id === expandedRefillPlanId && activeViewMode === 'inventory' ? 'activeplans__table--active' : ''
                            }`}
                        >
                            <Table responsive>
                                <thead>
                                    <tr>
                                        <th className="activeplans__table-left">{t('cabinetSettings:productName')}</th>
                                        <th className="hide-in-mb">{t('main:barcode')}</th>
                                        <th>{t('inventoryChange')}</th>
                                        <th className="hide-in-medium">{t('currentInventory')}</th>
                                        <th className="hide-in-medium">{t('lastSevenDaySales')}</th>
                                        <th className="hide-in-sm">{t('targetAmount')}</th>
                                        <th className="hide-in-medium">{t('refillNote')}</th>
                                    </tr>
                                </thead>

                                {plan.productData.map(product => {
                                    const productInventory = getCurrentInventoryProduct(plan.deviceCode, product);
                                    const salesCount = getProductSalesCount(plan.deviceCode, product);
                                    return (
                                        <tbody key={`${plan.id}${product.barcode}`}>
                                            <tr>
                                                <td className="activeplans__table-left">
                                                    <img src={product.imageUrl} alt="" className="activeplans__table-img" />
                                                    <span className="activeplans__table-name">{product.name}</span>
                                                </td>
                                                <td className="hide-in-mb">{product.barcode}</td>
                                                <td>
                                                    <div
                                                        className={`activeplans__table-invchange ${
                                                            editEnabled && plan.id === expandedRefillPlanId ? 'activeplans__table-invchange--active' : ''
                                                        }`}
                                                    >
                                                        <button onClick={() => handleRefillDataChange('amountToRefill', 'minus', product)}>-</button>
                                                        <span
                                                            className={
                                                                product.amountToRefill > 0
                                                                    ? 'activeplans__table-invchange-positive'
                                                                    : product.amountToRefill === 0
                                                                    ? 'activeplans__table-invchange-neutral'
                                                                    : 'activeplans__table-invchange-negetive'
                                                            }
                                                        >
                                                            {' '}
                                                            {product.amountToRefill}{' '}
                                                        </span>
                                                        <button onClick={() => handleRefillDataChange('amountToRefill', 'plus', product)}>+</button>
                                                    </div>
                                                </td>
                                                <td className="hide-in-medium">
                                                    <span className={productInventory > 0 ? 'activeplans__table-invchange-value' : ''}>
                                                        {' '}
                                                        {productInventory}{' '}
                                                    </span>
                                                </td>
                                                <td className="hide-in-medium">
                                                    <span className={salesCount > 0 ? 'activeplans__table-invchange-value' : ''}>{salesCount} </span>
                                                </td>
                                                <td className="hide-in-sm">
                                                    <div className="activeplans__table-invchange">
                                                        <span
                                                            className={
                                                                product.targetInvAmount > 0
                                                                    ? 'activeplans__table-invchange-positive'
                                                                    : product.targetInvAmount === 0
                                                                    ? 'activeplans__table-invchange-neutral'
                                                                    : 'activeplans__table-invchange-negetive'
                                                            }
                                                        >
                                                            {' '}
                                                            {product.targetInvAmount || 0}{' '}
                                                        </span>
                                                    </div>
                                                </td>
                                                <td className="hide-in-medium">
                                                    <div
                                                        className={`activeplans__table-refillnote ${
                                                            product.refillNote ? 'activeplans__table-refillnote--active' : ''
                                                        }`}
                                                        onClick={() => setRefillNoteModal({ isOpen: true, product, refillNote: product.refillNote })}
                                                    >
                                                        <Fragment>
                                                            <svg>
                                                                <use xlinkHref={`${icons}#icon-clipboard-edit`}></use>
                                                            </svg>
                                                            <span>{editEnabled ? t('editNote') : t('refillNote')}</span>
                                                        </Fragment>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    );
                                })}
                            </Table>
                        </div>
                    </Fragment>
                );
            })}
        </div>
    );
};

export default ActivePlanData;
