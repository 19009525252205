import moment from 'moment';

export const initState = {
    selectedCabinetDeviceCode: null,
    transactions: {
        data: [],
        fetched: false,
        isFetching: false,
        modal: {
            open: false,
            transaction: null,
        },
    },
    temperature: {
        maximum: null,
        minimum: null,
        average: null,
        current: null,
        fetched: false,
        isFetching: false,
    },
    currentStatus: {
        isFetching: false,
        fetched: false,
        temperature: null,
        deviceOnline: null,
    },
    timeRange: {
        from: moment()
            .subtract(7, 'day')
            .toISOString(),
        to: moment().toISOString(),
        selectedPeriod: 'week',
    },
    view: 'transactions',
};

export default function report(state = initState, action) {
    switch (action.type) {
        case 'REQUEST_TRANSACTIONS':
            return Object.assign({}, state, {
                transactions: {
                    data: [],
                    isFetching: true,
                    fetched: false,
                },
            });
        case 'RECEIVE_TRANSACTIONS':
            return Object.assign({}, state, {
                transactions: {
                    isFetching: false,
                    fetched: true,
                    data: action.transactions,
                },
            });
        case 'RECEIVE_TRANSACTIONS_ERROR':
            console.error(action.error);
            return Object.assign({}, state, {
                transactions: {
                    data: [],
                    isFetching: false,
                    fetched: false,
                    error: action.error.toString(),
                },
            });
        case 'REQUEST_TEMPERATURE':
            return Object.assign({}, state, {
                temperature: {
                    maximum: null,
                    minimum: null,
                    average: null,
                    isFetching: true,
                    fetched: false,
                },
            });
        case 'RECEIVE_TEMPERATURE':
            return Object.assign({}, state, {
                temperature: {
                    maximum: action.maximum,
                    minimum: action.minimum,
                    average: action.average,
                    isFetching: false,
                    fetched: true,
                },
            });
        case 'RECEIVE_TEMPERATURE_ERROR':
            console.error(action.error);
            return Object.assign({}, state, {
                temperature: {
                    maximum: null,
                    minimum: null,
                    average: null,
                    isFetching: false,
                    fetched: false,
                    error: action.error,
                },
            });
        case 'REQUEST_CURRENT_STATUS':
            return Object.assign({}, state, {
                currentStatus: {
                    isFetching: true,
                    fetched: false,
                    currentStatus: null,
                    deviceOnline: null,
                },
            });
        case 'RECEIVE_CURRENT_STATUS':
            return Object.assign({}, state, {
                currentStatus: {
                    isFetching: false,
                    fetched: true,
                    currentStatus: action.currentStatus,
                },
            });
        case 'RECEIVE_CURRENT_STATUS_ERROR':
            return Object.assign({}, state, {
                currentStatus: {
                    isFetching: false,
                    fetched: false,
                    error: action.error,
                    currentStatus: null,
                },
            });
        case 'SET_TRANSACTION_MODAL':
            return Object.assign({}, state, {
                transaction: {
                    ...state.transaction,
                    modal: {
                        open: action.open,
                        transaction: action.transaction,
                    },
                },
            });
        case 'SET_TIME_RANGE':
            return {
                ...state,
                timeRange: {
                    from: action.from.toISOString() || state.timeRange.from,
                    to: action.to.toISOString() || state.timeRange.to,
                    selectedPeriod: action.selectedPeriod,
                },
            };
        case 'SELECT_VIEW':
            return {
                ...state,
                view: action.view,
            };
        case 'RESET_REPORTS':
            return {
                ...initState,
            };
        default:
            return state;
    }
}
