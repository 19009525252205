import React, { useState, useEffect } from 'react';
import i18n from '../../i18n';
import { TimeZones } from './timeZonesList';
import { ExtendedCountryList } from '../navigation/settings/countryList';
import { Button, Spinner, Dropdown, Select } from '@storaensods/seeds-react';
import Switch from 'react-switch';
import { SettingsCopyModal } from './settingsCopyModal';
import './cabinetSettings.css';

const defaultSettings = {
    language: 'en',
    readUserMemory: false,
    block_cabinet_when_product_expired: true,
    ui_skus_sort_by: 'barcode',
    mediumExpiryLimitHours: 16,
    criticalExpiryLimitHours: 8,
};

const languages = ['en', 'fi', 'de', 'es', 'it', 'fr', 'hu', 'bg', 'lv', 'et', 'ro', 'sv', 'no', 'de-CH', 'da', 'cs', 'pt'];

export function CabinetSettings(props) {
    const { t, selectedCabinetSettings, isFetching, updateSelectedCabinetConfiguration, cabinets, locations, title, submitSettingsCopy } = props;
    const [copySettingsOpen, setCopySettingsOpen] = useState(false);
    const [updatedSettings, setUpdatedSettings] = useState({});

    useEffect(() => {
        setUpdatedSettings({});
    }, [selectedCabinetSettings]);

    let userItems = process.env.REACT_APP_CABINET_SETTINGS_USER_ITEMS || '';

    try {
        // convert from string "['detectTagQuality','ignoreBadTags','logo','payment_method_logo']"
        // to array ['detectTagQuality','ignoreBadTags','logo','payment_method_logo']
        userItems = JSON.parse(userItems.replace(/'/g, '"'));
    } catch (e) {
        userItems = [];
    }

    const updateSetting = (userItems = []) => {
        const toBeUpdated = {};
        let needsUpdate = false;

        Object.keys(updatedSettings).forEach(key => {
            if (!userItems.includes(key)) {
                return;
            }

            const setting = updatedSettings[key];
            // if a setting is new and also marked to be deleted, it is not added to the settings list to be updated.
            if (
                !(setting.deleted && setting.new && !selectedCabinetSettings[key]) &&
                (setting.deleted ||
                    setting.new ||
                    (typeof setting.value !== 'undefined' && setting.value !== null && setting.value !== selectedCabinetSettings[key]))
            ) {
                toBeUpdated[key] = setting.deleted ? null : typeof setting.value === 'string' ? setting.value.trim() : setting.value;
                needsUpdate = true;
            }
        });

        if (needsUpdate) {
            updateSelectedCabinetConfiguration(toBeUpdated);
            setUpdatedSettings({});
        }
    };

    const renderSettingItem = (key, value, isAdminSetting = true, disabled = false) => {
        const updatedSetting = updatedSettings[key];
        const deleted = updatedSetting && updatedSetting.deleted;
        const updated = !deleted && updatedSetting && typeof updatedSetting.value !== 'undefined' && updatedSetting.value !== value;
        const isNew = !deleted && updatedSetting && updatedSetting.new;

        const verifiedValue = updatedSetting && typeof updatedSetting.value !== 'undefined' ? updatedSetting.value : value;
        const userItems = process.env.REACT_APP_CABINET_SETTINGS_USER_ITEMS || [];

        const labelText = userItems.includes(key) ? t(`settings:${key}`) : key;

        const customFields = ['language', 'ui_skus_sort_by', 'timeZone', 'country'];

        // Get values to render default or selected settings for fields using the Select component
        let language = null;
        if (updatedSettings.language?.value) {
            language = updatedSettings.language.value;
        } else if (selectedCabinetSettings && selectedCabinetSettings.language) {
            language = selectedCabinetSettings?.language;
        } else {
            language = 'en';
        }

        const languageSelection = languages
            .filter(l => l === language)
            .map(l => {
                const langName = i18n.getNativeName(l);
                const capitalizedLang = langName.charAt(0).toUpperCase() + langName.slice(1);
                return {
                    label: capitalizedLang,
                    value: verifiedValue,
                };
            });

        const timeZone = updatedSettings.timeZone?.value ? updatedSettings.timeZone.value : selectedCabinetSettings?.timeZone;
        const timeZoneLabel = TimeZones.filter(tz => tz.value === timeZone).map(tz => tz.label);

        const country = updatedSettings.country?.vaplue ? updatedSettings.country.value : selectedCabinetSettings?.country;
        const countryLabel = ExtendedCountryList.filter(c => c.code === country).map(c => c.name);

        return (
            <div key={key} className={`d-flex align-items-center mb-2 ${deleted ? 'deleted' : ''} ${updated ? 'updated' : ''} ${isNew ? 'new' : ''}`}>
                {/* Key */}
                <div className="setting-label cabinet-label-setting">
                    <span>{labelText}</span>
                </div>

                {/* Value editor */}
                {isFetching ? (
                    <div>
                        <Spinner />
                    </div>
                ) : (
                    <div className="se-input-container ml-3">
                        {key === 'language' && (
                            <Select
                                onChange={newValue => {
                                    const uSettings = { ...updatedSettings };
                                    uSettings[key] = { ...updatedSettings[key], value: newValue.value };
                                    setUpdatedSettings(uSettings);
                                }}
                                value={languageSelection}
                                options={languages
                                    .map(lang => {
                                        const langName = i18n.getNativeName(lang);
                                        const capitalizedLang = langName.charAt(0).toUpperCase() + langName.slice(1);
                                        return {
                                            label: capitalizedLang,
                                            value: lang,
                                        };
                                    })
                                    .sort((a, b) => a.label.localeCompare(b.label))}
                            />
                        )}

                        {key === 'ui_skus_sort_by' && (
                            <Dropdown
                                onChange={newValue => {
                                    const uSettings = { ...updatedSettings };
                                    uSettings[key] = { ...updatedSettings[key], value: newValue.value };
                                    setUpdatedSettings(uSettings);
                                }}
                                value={verifiedValue}
                                options={[
                                    {
                                        value: 'name',
                                        label: t('name'),
                                    },
                                    {
                                        value: 'productcategory',
                                        label: t('productCategory'),
                                    },
                                    {
                                        value: 'supplier',
                                        label: t('supplier'),
                                    },
                                    {
                                        value: 'barcode',
                                        label: t('barcode'),
                                    },
                                ]}
                            />
                        )}

                        {key === 'timeZone' && (
                            <Select
                                onChange={newValue => {
                                    const uSettings = { ...updatedSettings };
                                    uSettings[key] = { ...updatedSettings[key], value: newValue.value };
                                    setUpdatedSettings(uSettings);
                                }}
                                value={{ label: timeZoneLabel, value: verifiedValue }}
                                options={TimeZones.map(timeZone => {
                                    return {
                                        label: timeZone.label,
                                        value: timeZone.value,
                                    };
                                })}
                            />
                        )}

                        {key === 'country' && (
                            <Select
                                onChange={newValue => {
                                    const uSettings = { ...updatedSettings };
                                    uSettings[key] = { ...updatedSettings[key], value: newValue.value };
                                    setUpdatedSettings(uSettings);
                                }}
                                value={{ label: countryLabel, value: verifiedValue }}
                                options={ExtendedCountryList.sort((a, b) => a.name.localeCompare(b.name)).map(country => {
                                    return {
                                        label: country.name,
                                        value: country.code,
                                    };
                                })}
                            />
                        )}

                        {/* Boolean settings */}
                        {typeof verifiedValue === 'boolean' && (
                            <Switch
                                // eslint-disable-next-line
                                checked={verifiedValue}
                                onColor="#67b419"
                                offColor="#d6d9df"
                                className="se-seeds-toggle setting-value"
                                disabled={deleted || disabled}
                                onChange={newValue => {
                                    const uSettings = { ...updatedSettings };
                                    uSettings[key] = { ...updatedSettings[key], value: newValue };
                                    setUpdatedSettings(uSettings);
                                }}
                            />
                        )}

                        {/* All other settings */}
                        {typeof verifiedValue !== 'boolean' && !customFields.includes(key) && (
                            <input
                                type="text"
                                placeholder="Input text"
                                className="se-input se-input--md setting-value"
                                disabled={deleted || disabled}
                                value={verifiedValue}
                                onChange={event => {
                                    const newValue =
                                        ['true', 'false'].indexOf(event.target.value.toLowerCase()) >= 0
                                            ? event.target.value.toLowerCase() === 'true'
                                            : event.target.value;
                                    const uSettings = { ...updatedSettings };
                                    uSettings[key] = { ...updatedSettings[key], value: newValue };
                                    setUpdatedSettings(uSettings);
                                }}
                            />
                        )}
                    </div>
                )}

                {/* Delete button */}
                {isAdminSetting && !isFetching && (
                    <Button
                        className={`se-btn se-btn--sm se-btn--secondary ml-3 ${deleted ? 'se-btn--negative white' : ''}`}
                        icon={deleted ? 'settings_backup_restore' : 'delete'}
                        onClick={() => {
                            const uSettings = { ...updatedSettings };
                            uSettings[key] = { ...updatedSettings[key], deleted: !(updatedSettings[key] && updatedSettings[key].deleted) };
                            setUpdatedSettings(uSettings);
                        }}
                    />
                )}

                {/* Reset button */}
                {/* The reset button is not suitable for the city as it also changes along with the country */}
                {updated && (
                    <Button
                        className={'se-btn se-btn--sm se-btn--secondary ml-3'}
                        onClick={() => {
                            const uSettings = { ...updatedSettings };

                            // If the country is reset, the city needs to be reset also
                            delete uSettings['country'];
                            if (key === 'country') {
                                delete uSettings['country'];
                            } else {
                                delete uSettings[key];
                            }

                            setUpdatedSettings(uSettings);
                        }}
                    >
                        <i className="fas fa-redo" />
                    </Button>
                )}
            </div>
        );
    };

    const getCorrectSettingsForModal = () => {
        // Add defaults to settings that are not set
        for (const key in defaultSettings) {
            if (!(key in selectedCabinetSettings)) {
                selectedCabinetSettings[key] = defaultSettings[key];
            }
        }

        const usedSettings = {};
        userItems.forEach(key => {
            usedSettings[key] = selectedCabinetSettings[key];
        });

        return usedSettings;
    };

    return (
        <div className="mt-4 mb-4">
            {(userItems || []).map(key => {
                let value = selectedCabinetSettings ? selectedCabinetSettings[key] : undefined;
                let disabled = false;
                if (value === undefined) {
                    if (defaultSettings[key] !== undefined) {
                        value = defaultSettings[key];
                    } else {
                        value = '';
                    }
                }
                if (key === 'block_cabinet_when_product_expired') {
                    if (updatedSettings['readUserMemory'] && updatedSettings['readUserMemory'].value !== undefined) {
                        if (updatedSettings['readUserMemory'].value === false) {
                            disabled = true;
                        }
                    } else if (selectedCabinetSettings && selectedCabinetSettings['readUserMemory'] !== true) {
                        disabled = true;
                    }
                }
                return renderSettingItem(key, value, false, disabled);
            })}

            <div className="d-flex justify-content-between text-right mt-4">
                <Button onClick={() => setCopySettingsOpen(true)}>
                    <i className="fas fa-copy mr-2" />
                    <span>{t('settings:settingsCopyButton')}</span>
                </Button>

                {/* Save button */}
                <Button
                    onClick={() => {
                        updateSetting(userItems);
                    }}
                >
                    {t('save')}
                </Button>
            </div>

            {copySettingsOpen && (
                <SettingsCopyModal
                    closeModal={() => setCopySettingsOpen(!copySettingsOpen)}
                    t={t}
                    submitSettingsCopy={submitSettingsCopy}
                    cabinets={cabinets}
                    locations={locations}
                    settingsType={title}
                    settings={getCorrectSettingsForModal(selectedCabinetSettings)}
                />
            )}
        </div>
    );
}
