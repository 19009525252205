import React, { Component } from 'react';
import { Dropdown, Button, Icon } from '@storaensods/seeds-react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { filter } from 'lodash';
import { signOutUser, handleGroupChange } from '../../actions/user.js';
import { fetchOrganisations } from '../../actions/organizations.js';
import { hideInventoryErrorAlarmIcon } from '../../actions/alarmIcon';
import { fetchOrganizationReleaseVersions } from '../../actions/releaseVersions.js';
import './navigation.css';
import project from '../../../package.json';
import icons from './styles/icons.svg';
import { store } from '../../index';
import {
    getSoftwareVersions as getSoftwareVersionsFromLocalStorage,
    getSoftwareVersionUpdateLastSeen,
    storeSoftwareVersionUpdateLastSeen,
    storeSoftwareVersions as storeSoftwareVersionsToLocalStorage,
} from '../../localStorage';
import moment from 'moment';
import { getUserNotifications, setNotificationsUnSeenStatus, getNotificationUnSeenStatus } from '../../notifications.js';
import { redirectToAuthorizationPage } from '../../actions/user.js';

/**
 * Navigation bar and header component
 */
export class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userDropdownOpen: false,
            groupDropdownOpen: false,
            languages: JSON.parse(process.env.REACT_APP_LANGUAGES),
            filteredGroups: props.user.profile.groups || [],
            routes: [],
            notifications: [],
            releaseVersions: [],
            releaseVersionsToBeUpdated: [],
            releaseVersionModalOpen: false,
            notificationsUnSeen: true,
            signOutConfirmationModalOpen: false,
        };
    }

    /** On component updated */
    async componentDidMount() {
        const { user } = this.props;
        let routes = [];
        let notifications = [];
        // filter routes based on user access in modules
        this.props.fetchOrganisations();
        this.props.fetchOrganizationReleaseVersions();
        notifications = getUserNotifications();

        routes = filter(routes, item => {
            return user.profile.modules.indexOf(item.module) !== -1;
        });

        this.setState({
            ...this.state,
            notifications: notifications ? notifications : [],
            notificationsUnSeen: getNotificationUnSeenStatus(),
            routes,
        });
    }

    filterGroup = event => {
        const inputValue = event.target.value.toLowerCase();
        let filteredGroups = this.props.user.profile.groups;
        if (inputValue !== '') {
            filteredGroups = filteredGroups.filter(g => g.Name.toLowerCase().includes(inputValue));
        }
        this.setState({ ...this.state, filteredGroups });
    };

    /**
     * Sign user out.
     */
    handleSignOut = () => {
        this.props.signOutUser();
    };

    hasSoftwareVersionsChanged = (localStorageVersions, newVersions) => {
        const versionsSame = localStorageVersions.every(element => newVersions.some(({ deviceId }) => deviceId === element.deviceId));
        if (versionsSame) return false;
        if (!versionsSame) return true;
    };

    componentDidUpdate(prevProps) {
        if (this.props.releaseVersions !== prevProps.releaseVersions) {
            let newReleaseVersionsWithinTimewindow;
            let newReleaseVersions;
            let noNewVersions;
            const oldSoftwareVersions = getSoftwareVersionsFromLocalStorage() ? JSON.parse(getSoftwareVersionsFromLocalStorage()) : null;

            newReleaseVersionsWithinTimewindow = this.props.releaseVersions.releaseVersions.filter((version, index) => {
                let newerVersions = version.newerVersions;
                let isNotificationAvailable = false;
                if (newerVersions) {
                    newerVersions.forEach(v => {
                        if (v.notificationStartTime && v.notificationEndTime && moment().isBetween(v.notificationStartTime, v.notificationEndTime)) {
                            isNotificationAvailable = true;
                        }
                    });
                }
                if (newerVersions.length) newReleaseVersions = true;
                if (newerVersions.length && isNotificationAvailable) return true;
                if (index === this.props.releaseVersions.releaseVersions.length - 1 && !newReleaseVersions && !newerVersions.length) {
                    noNewVersions = true;
                }
                return false;
            });

            if (newReleaseVersionsWithinTimewindow.length) {
                if (oldSoftwareVersions && this.hasSoftwareVersionsChanged(oldSoftwareVersions, newReleaseVersionsWithinTimewindow)) {
                    setNotificationsUnSeenStatus(true);
                }
                if (!oldSoftwareVersions) storeSoftwareVersionsToLocalStorage(JSON.stringify(newReleaseVersionsWithinTimewindow));

                this.setState({ releaseVersions: newReleaseVersionsWithinTimewindow, releaseVersionModalOpen: true });
            }
            if (newReleaseVersions) {
                this.setState({ notifications: [...this.state.notifications, 'softwareVersionNotification'] });
            } else if (!newReleaseVersions && noNewVersions) {
                this.setState({ notifications: [...this.state.notifications.filter(n => n !== 'softwareVersionNotification')] });
            }
        }
    }

    /**
     * Switches the language.
     * @param {*} lang Current selected language.
     */
    handleLanguageSelect = lang => {
        const { i18n } = this.props;
        // set application language
        i18n.changeLanguage(lang);
    };

    handleGroupSelect = group => {
        this.props.handleGroupChange(group);
    };

    toggleUserDropdown = () => {
        this.setState(prevState => ({
            userDropdownOpen: !prevState.userDropdownOpen,
        }));
    };

    toggleGroupDropdown = () => {
        const { hideInventoryErrorAlarmIcon } = this.props;
        hideInventoryErrorAlarmIcon();
        this.setState(prevState => ({
            groupDropdownOpen: !prevState.groupDropdownOpen,
        }));
    };

    LanguageDropdown = (t, languages, selectedLanguage) => (
        <div className="scp-header__menu-setting">
            <p className="scp-header__menu-setting-title">{t('header:pageLanguage')}</p>
            <div className="scp-header__menu-language">
                <div className="scp-header__menu-icon">
                    <svg>
                        <use xlinkHref={`${icons}#icon-world`}></use>
                    </svg>
                </div>
                <Dropdown
                    className="scp-header__menu-dropdown-language"
                    size="sm"
                    onSelect={({ value }) => {
                        this.props.i18n.changeLanguage(value);
                    }}
                    value={selectedLanguage}
                    options={languages.map(lang => {
                        const langName = this.props.i18n.getNativeName(lang);
                        const capitalizedLang = langName.charAt(0).toUpperCase() + langName.slice(1);
                        return {
                            label: capitalizedLang,
                            value: lang,
                            className: lang === selectedLanguage ? 'language-dropdown-item-active' : null,
                        };
                    })}
                />
                <div className="scp-header__menu-select">
                    <svg>
                        <use xlinkHref={`${icons}#icon-arrow-down`}></use>
                    </svg>
                </div>
            </div>
        </div>
    );

    GroupDropdown = (t, selectedGroup, usersGroups) => (
        <>
            <div className="scp-header__menu-icon">
                <svg>
                    <use xlinkHref={`${icons}#icon-office`}></use>
                </svg>
            </div>
            {/*Ternary operator for checking if goup (=organization) exists, if no back to authorization page + error message). */}

            {selectedGroup === null ? (
                store.dispatch(redirectToAuthorizationPage(selectedGroup === null))
            ) : (
                <div className="scp-header__menu-text">
                    <p className="mb-0">{selectedGroup.Name}</p>
                    {usersGroups.length > 1 && (
                        <svg>
                            <use xlinkHref={`${icons}#icon-arrow-down`}></use>
                        </svg>
                    )}
                </div>
            )}

            {usersGroups.length > 1 && (
                <div className="scp-header__menu-cta-popup scp-header__menu-cta-popup--groups animate__animated animate__fadeIn">
                    <div className="scp-header__menu-search">
                        <input type="text" placeholder={t('header:searchByOrganization')} onChange={e => this.filterGroup(e)} />
                        <svg>
                            <use xlinkHref={`${icons}#icon-search`}></use>
                        </svg>
                    </div>
                    <div className="scp-header__menu-groups">
                        {this.state.filteredGroups.map(group => {
                            return this.GroupInfo(group.Id, selectedGroup, group.Name);
                        })}
                    </div>
                </div>
            )}
        </>
    );

    GroupInfo = (Id, selectedGroup, Name) => {
        return (
            <div
                className={`scp-header__menu-groups-item ${selectedGroup.Id === Id ? 'scp-header__menu-groups-item--selected' : ''}`}
                onClick={() => this.props.handleGroupChange({ Id, Name })}
                key={Id}
            >
                <p>{Name}</p>
            </div>
        );
    };

    handleSoftwareUpdateRedirect = () => {
        this.setState({ releaseVersionModalOpen: false }, () => {
            this.props.history.push('/software-versions');
        });
    };

    handleSoftwareUpdateRemindLater = () => {
        this.setState({ releaseVersionModalOpen: false }, () => {
            const currentDateTime = new Date();
            storeSoftwareVersionUpdateLastSeen(currentDateTime);
        });
    };

    userHasSeenNotificationTodayAlready = () => {
        const lastSeenDate = getSoftwareVersionUpdateLastSeen();
        const dayFromLastSeen = moment(lastSeenDate).add('1', 'days');

        if (!lastSeenDate) return false;
        else return moment().isBefore(dayFromLastSeen);
    };

    NewReleaseVersionsModal = t => {
        if (this.state.releaseVersionModalOpen && !this.userHasSeenNotificationTodayAlready()) {
            return (
                <Modal
                    isOpen={true}
                    className="versionUpdateModal"
                    toggle={() => this.setState({ releaseVersionModalOpen: !this.state.releaseVersionModalOpen })}
                >
                    <ModalHeader className="versionUpdateModalHeader">
                        <Icon className="mr-2">info_outline</Icon> {t('softwareVersions:userNotificationTitle')}
                    </ModalHeader>
                    <ModalBody className="mt-3 p-3 text-center">
                        <div>{t('softwareVersions:userNotificationContent')}</div>
                        <div className="versionUpdateModalButtonContainer">
                            <Button
                                onClick={() => this.handleSoftwareUpdateRemindLater()}
                                className="updateVersionModalButton updateVersionModalButton__remindMeLater"
                            >
                                {t('header:remindMeLater')}
                            </Button>
                            <Button
                                onClick={() => this.handleSoftwareUpdateRedirect()}
                                className="updateVersionModalButton updateVersionModalButton__updateNow"
                            >
                                {t('header:updateNow')}{' '}
                                <svg>
                                    <use xlinkHref={`${icons}#icon-arrow-right`}></use>
                                </svg>
                            </Button>
                        </div>
                    </ModalBody>
                </Modal>
            );
        }
    };

    markNotificationsUnSeenStatus = status => {
        setNotificationsUnSeenStatus(status);
        this.setState({ notificationsUnSeen: status });
    };

    signOutConfirmationModal = t => {
        return (
            <Modal isOpen={this.state.signOutConfirmationModalOpen} centered={true}>
                <ModalBody>
                    <h5>{t('main:signOutConfirmationModalTitle')}</h5>
                    <p className="software-version-modal-body">{t('main:signOutConfirmationModalContent')}</p>
                </ModalBody>
                <ModalFooter className="signOutConfirmationModal__footer">
                    <Button
                        color="primary"
                        type="attention"
                        icon="done"
                        onClick={() => {
                            this.props.signOutUser();
                            this.setState({ signOutConfirmationModalOpen: false });
                        }}
                    >
                        {t('signOut')}
                    </Button>

                    <Button
                        color="secondary"
                        icon="close"
                        onClick={() => {
                            this.setState({ signOutConfirmationModalOpen: false });
                        }}
                    >
                        {t('main:cancel')}
                    </Button>
                </ModalFooter>
            </Modal>
        );
    };

    render() {
        const { t, i18n, user, userFullName, location } = this.props;
        const state = store.getState();
        const isAdmin = state && state.user && state.user.isAdmin;
        return (
            <>
                {!location.pathname.includes('software-versions') && this.NewReleaseVersionsModal(t)}
                {this.signOutConfirmationModal(t)}
                <div className="scp-header">
                    <div className="scp-header__trademark">
                        <img
                            className="scp-header__trademark-logo"
                            src="https://cdn.selflystore.com/images-general/logos/selfly-logo.svg"
                            alt="selfly-store-logo"
                        />
                        <h1 className="scp-header__trademark-title">{t('title') + ` ${project.version}`}</h1>
                    </div>
                    <div className="scp-header__menu">
                        <div className="scp-header__menu-cta">{this.GroupDropdown(t, user.group, user.profile.groups)}</div>
                        <div className="scp-header__menu-cta" onClick={() => this.setState({ signOutConfirmationModalOpen: true })}>
                            <div className="scp-header__menu-icon">
                                <svg>
                                    <use xlinkHref={`${icons}#icon-log-out`}></use>
                                </svg>
                            </div>
                            <div className="scp-header__menu-text scp-header__menu-text--logout">
                                <p className="mb-0">{userFullName ? userFullName : user.userName}</p>
                            </div>
                        </div>
                        <div className="scp-header__menu-cta">
                            <div className="scp-header__menu-notification">
                                <svg>
                                    <use xlinkHref={`${icons}#icon-bell`}></use>
                                </svg>
                                {this.state.notifications.length && this.state.notificationsUnSeen ? (
                                    <div className="scp-header__menu-notification-count">
                                        <span>
                                            {' '}
                                            <i className="fas fa-exclamation"></i>
                                        </span>
                                    </div>
                                ) : null}
                            </div>

                            <div
                                onMouseEnter={() => this.markNotificationsUnSeenStatus(false)}
                                className="scp-header__menu-cta-popup scp-header__menu-cta-popup--notification  animate__animated animate__fadeIn"
                            >
                                <div className="scp-header__menu-cta-popup-lists">
                                    {isAdmin && this.state.notifications.some(n => n === 'softwareVersionNotification') ? (
                                        <div className="scp-header__menu-cta-popup-item">
                                            <div className="header">
                                                <svg>
                                                    <use xlinkHref={`${icons}#icon-refresh1`}></use>
                                                </svg>
                                                <div>
                                                    <strong>{t('softwareVersions:softwareVersions')}</strong>
                                                    <p className="info m-0">{t('softwareVersions:notificationBell_newSoftwareVersions')}</p>
                                                    <Link to="/software-versions">
                                                        <div className="link">
                                                            <p className="m-0">
                                                                <b>{t('main:checkNow')}</b>
                                                            </p>
                                                            <svg>
                                                                <use xlinkHref={`${icons}#icon-arrow-right`}></use>
                                                            </svg>
                                                        </div>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        <div className="p-3"> {t('notifications:noNewNotifications')} </div>
                                    )}

                                    {this.state.notifications.map(notification => {
                                        if (notification === 'softwareVersionNotification') return null;
                                        else
                                            return (
                                                <div className="scp-header__menu-cta-popup-item">
                                                    <div className="header">
                                                        <div>
                                                            <strong>{notification.title}</strong>
                                                            <p className="info">{notification.content}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                    })}
                                </div>
                            </div>
                        </div>
                        <div className="scp-header__menu-cta">
                            <div className="scp-header__menu-icon scp-header__menu-icon--last">
                                <svg>
                                    <use xlinkHref={`${icons}#icon-params`}></use>
                                </svg>
                            </div>

                            <div className="scp-header__menu-cta-popup scp-header__menu-cta-popup--settings animate__animated animate__fadeIn">
                                {/**Select page language */}
                                {this.LanguageDropdown(t, this.state.languages, i18n.language)}
                                {/**Select Consumer application settings */}
                                {isAdmin && (
                                    <div className="scp-header__menu-setting">
                                        <Link to={{ pathname: '/settings-consumer-app' }}>
                                            <p className="scp-header__menu-setting-title">{t('mySelfly')}</p>
                                            <div className="scp-header__menu-setting-section">
                                                <svg>
                                                    <use xlinkHref={`${icons}#icon-phone`}></use>
                                                </svg>
                                                <p className="my-0">{t('header:configureMySelflyAndEreceipts')}</p>
                                                <svg>
                                                    <use xlinkHref={`${icons}#icon-arrow-right`}></use>
                                                </svg>
                                            </div>
                                        </Link>
                                    </div>
                                )}
                                {isAdmin && (
                                    <div className="scp-header__menu-setting">
                                        <Link to="/software-versions">
                                            <p className="scp-header__menu-setting-title">{t('softwareVersions:softwareVersions')}</p>
                                            <div className="scp-header__menu-setting-section">
                                                <svg>
                                                    <use xlinkHref={`${icons}#icon-refresh1`}></use>
                                                </svg>
                                                <p className="my-0">{t('softwareVersions:manageVersions')}</p>
                                                <svg>
                                                    <use xlinkHref={`${icons}#icon-arrow-right`}></use>
                                                </svg>
                                            </div>
                                        </Link>
                                    </div>
                                )}
                                {isAdmin && (
                                    <div className="scp-header__menu-setting">
                                        <Link to={{ pathname: '/automations' }}>
                                            <p className="scp-header__menu-setting-title">{t('automations')}</p>
                                            <div className="scp-header__menu-setting-section">
                                                <svg>
                                                    <use xlinkHref={`${icons}#automations`}></use>
                                                </svg>
                                                <p className="my-0">{t('automationInfo')}</p>
                                                <svg>
                                                    <use xlinkHref={`${icons}#icon-arrow-right`}></use>
                                                </svg>
                                            </div>
                                        </Link>
                                    </div>
                                )}
                                <div className="scp-header__menu-setting">
                                    <a href="https://www.selflystore.com/privacy/" className="scp-header__menu-setting-title">
                                        {t('main:privacyPolicy')}
                                    </a>
                                </div>
                                <div className="scp-header__menu-setting">
                                    <a href="https://www.storaenso.com/en/legal-notice" className="scp-header__menu-setting-title">
                                        {t('main:legalNotice')}
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default withRouter(
    connect(
        state => ({
            userFullName: state.user.profile.fullName,
            user: state.user,
            organizations: state.organizations,
            releaseVersions: state.releaseVersions,
        }),
        { signOutUser, handleGroupChange, hideInventoryErrorAlarmIcon, fetchOrganisations, fetchOrganizationReleaseVersions }
    )(translate('navigation')(Header))
);
