import React from "react";

import icons from './../../icons/icomoon.svg';

export const Loader = ({ message }) => {
    const displayText = message ? message : 'Fetching. Please wait...'
    return (
        <div className="cp-loader">
            <svg>
                <use xlinkHref={`${icons}#icon-spinner7`}></use>
            </svg>
            <div className="cp-loader__message">{displayText}</div>
        </div>
    );
};

export const LoaderError = ({message})=>{
    const displayText = message ? message : 'Error while fetching data'
    return (
        <div className="cp-loader cp-loader--error">
            <svg>
                <use xlinkHref={`${icons}#icon-error_outline`}></use>
            </svg>
            <p>{displayText}</p>
        </div>
    );
}

export const InfoBanner = ({message, className})=>{
    return(
        <div className={`cp-info-banner ${className ? className :''}`}>
            {message}
        </div>
    )
}

/**
 * 
 * @param {string } type save | add | close | cancel | update | delete
 * @param {string} text text to disply on the button(optional)
 * @param {string} className custom css class name(optional)
 * @param {Function} onClick callback function when button is clicked
 * @returns 
 */
export const Button =({type, text, className, onClick, mr, ml, mt, mb, disabled=false})=>{

    const buttonIcon= {
        'save': { icon:'icon-save', style:'cp-button--save'},
        'add': { icon:'icon-plus-circle', style:'cp-button--add'},
        'close':{ icon:'icon-close-outline', style:'cp-button--close'},
        'cancel':{ icon:'icon-close-outline', style:'cp-button--close'},
        'update':{ icon:'icon-update', style:'cp-button--update'},
        'delete':{ icon:'icon-trash-2', style:'cp-button--delete'},
        'export':{ icon:'icon-export', style:'cp-button--export'},
        'copy':{icon:'icon-copy', style:'cp-button--copy'}
    }

    const style = `cp-button ${buttonIcon[type].style}${mr ? ' cp-button-mr':''}${ml ? ' cp-button-ml':''}${mt ?' cp-button-mt':''}${mb ?' cp-button-mb':''} ${className? {className}:''} ${disabled ? 'cp-button--disabled':''}`

    return(
        <button disabled={disabled} className={style} onClick={()=>onClick()}>
            <svg>
                <use xlinkHref={`${icons}#${buttonIcon[type].icon}`}></use>
            </svg>
            <span>{text ? text : type}</span>
        </button>
    )
}

/**
 * 
 * @param {Array of objects} options [{text:'Custom':value:'value'}, {text:'Default':value:'default'}]
 * @param {string} selectedOption
 * @param {function} onClick a call back when toggle is clicked 
 * @returns 
 */
export const Toggle =({options, selectedOption, onClick})=>{
    
    return(
        <div className="cp-toggle">
            <span className={ selectedOption===options[0].value ? 'cp-toggle--active':'' } onClick={()=>onClick(options[0].value)} >{  options[0].text || 'one'}</span>
            <span className={ selectedOption===options[1].value ? 'cp-toggle--active':'' } onClick={()=>onClick(options[1].value)} >{  options[1].text || 'two'}</span>
        </div>
    )
}