import React, { Fragment, useEffect, useState } from 'react';
import { Popover, Input, Table } from 'reactstrap';
import { Checkbox } from '@storaensods/seeds-react';
import { orderBy } from 'lodash';

import Loader from './loader';
import { _13digitBarcode } from './../utils';
import SchemaControlBar from './schemaControlBar';
import icons from './../../../icons/icomoon.svg';
import RefillNoteModel from '../modals/refillNoteModel';
import ApiRequestModal from '../modals/ApiRequestModal';
import ConfirmationModal from '../modals/confirmationModal';
import MaxInventoryWarnModal from '../modals/maxInventoryWarnModal';
import { SettingsCopyModal } from '../../settings/settingsCopyModal';
import CopySchemaStatusModal from '../modals/copySchemaStatusModal';

const MAX_REFILL_INVENTORY_LENGTH = 200;

const SchemaProducts = ({
    cabinetRefillSchema,
    allProducts,
    updateCabinetRefillSchema,
    createRefillSchema,
    selectedCabinet,
    deleteRefillSchema,
    fetchRefillRules,
    fetchCabinetRefillRule,
    toggleViewMode,
    activeViewMode,
    t,
    cabinets,
    locations,
    copyRefillSchemaToOtherCabinets,
    allRefillSchemas,
    allRefillPlans,
    isAdmin,
}) => {
    const [schemaProductData, setSchemaProductData] = useState([]);
    const [allProductsData, setAllProductsData] = useState([]);
    const [inventoryLevelPopover, setInventoryLevelPopover] = useState(false);
    const [minimumLevelPopover, setMinimumLevelPopover] = useState(false);
    const [refillNoteModal, setRefillNoteModal] = useState({ isOpen: false, product: null, refillNote: '' });
    const [schemaRequestingModalOpen, setSchemaRequestingModalOpen] = useState(false);
    const [deleteConfirmationModelOpen, setDeleteConfirmationModelOpen] = useState(false);
    const [prestine, setPrestine] = useState(true);
    const [maxInvWarnModalOpen, setMaxInvWarnModalOpen] = useState(false);
    const [productSearchQuery, setProductSearchQuery] = useState(null);
    const [productSorting, setProductSorting] = useState({ type: null, direction: null });
    const [copySchemaModalOpen, setCopySchemaModalOpen] = useState(false);
    const [copySchemaStatus, setCopySchemaStatus] = useState({ status: '', message: '', open: 'false' });

    const { fetching, rule } = cabinetRefillSchema;

    useEffect(() => {
        if (fetching) return;
        if (!rule) return;

        const refillSchemaData = rule.productData || [];

        const productData = refillSchemaData.map(data => {
            let barcodeType = data.barcodeType;
            if(!barcodeType){
                const product = allProducts.find(p=>p.barcode === data.barcode)
                barcodeType = product?.barcodeType
            }

            return {
                ...data,
                barcode: _13digitBarcode(data.barcodeType, data.barcode),
                barcodeType,
                minimumInvAmount: data.minimumInvAmount ? data.minimumInvAmount : 0,
            };
        });

        setSchemaProductData(productData);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cabinetRefillSchema]);

    useEffect(() => {
        if (!allProducts.length) return;
        let products = [...allProducts];
        products = products
            .filter(product => product.status === 2)
            .map(product => {
                return { ...product, barcode: _13digitBarcode(product.barcodeType, product.barcode) };
            });

        setAllProductsData(products);
    }, [allProducts]);

    /**
     * closes the refill note modal and saves the refill note to the refill data
     * @param {*} product
     * @param {*} refillNote
     */
    const closeRefillNote = (product, refillNote) => {
        const productSchema = schemaProductData.find(schema => product.barcode === schema.barcode);
        let updateSchema = [...schemaProductData];

        if (!productSchema && refillNote.length) {
            updateSchema = [
                ...updateSchema,
                {
                    barcode: product.barcode,
                    barcodeType: product.barcodeType,
                    refillNote,
                    targetInvAmount: 0,
                    minimumInvAmount: 0,
                },
            ];
        } else {
            updateSchema = [
                ...updateSchema.map(data => {
                    if (data.barcode === product.barcode) {
                        return { ...data, refillNote };
                    }

                    return data;
                }),
            ];
            setPrestine(false);
        }

        setSchemaProductData(updateSchema);
        setRefillNoteModal(false);
    };

    /**
     * checks if the product is in the product schema
     * @param {*} product
     * @returns boolean
     */
    const checkIfProductRefillSchemaExists = product => {
        return schemaProductData.find(schema => product.barcode === schema.barcode) ? true : false;
    };

    const maxInvAmountReached = (type, updateSchema) => {
        let totalInvLength = 0;

        updateSchema.forEach(data => {
            totalInvLength = totalInvLength + data[type];
        });

        if (totalInvLength > MAX_REFILL_INVENTORY_LENGTH) {
            return true;
        } else {
            return false;
        }
    };

    const updateRefillSchemaData = (type, action, product) => {
        if (!isAdmin) return;

        let updateSchema = [...schemaProductData];

        switch (action) {
            case 'plus':
                if (!schemaProductData.find(schema => product.barcode === schema.barcode)) {
                    updateSchema = [
                        ...updateSchema,
                        {
                            barcode: product.barcode,
                            barcodeType: product.barcodeType,
                            refillNote: '',
                            targetInvAmount: 0,
                            minimumInvAmount: 0,
                            [type]: 1,
                        },
                    ];
                    break;
                }

                updateSchema = [
                    ...updateSchema.map(data => {
                        if (data.barcode === product.barcode) {
                            return { ...data, [type]: data[type] + 1 };
                        }

                        return data;
                    }),
                ];
                break;
            case 'minus':
                if (!schemaProductData.find(schema => product.barcode === schema.barcode)) {
                    break;
                }
                updateSchema = [
                    ...updateSchema.map(data => {
                        if (data.barcode === product.barcode) {
                            return { ...data, [type]: data[type] <= 0 ? 0 : data[type] - 1 };
                        }

                        return data;
                    }),
                ];
                break;
            default:
                break;
        }
        if (maxInvAmountReached(type, updateSchema)) {
            setMaxInvWarnModalOpen(true);
        } else {
            setPrestine(false);
            setSchemaProductData(updateSchema);
        }
    };

    /**
     * get the property values of refill schema - refillnote, targetInv and minTargetInveentory
     * @param {*} type
     * @param {*} product
     * @returns
     */
    const getRefillSchemaValue = (type, product) => {
        const productSchema = schemaProductData.find(schema => product.barcode === schema.barcode);
        const defaultValue = type === 'refillNote' ? '' : 0;
        if (!productSchema) return defaultValue;

        return productSchema[type] || defaultValue;
    };

    /**
     * select or deselect product for refill schema with checkbox
     * @param {*} product
     */
    const toggleRefillProductSelection = product => {
        const productSchema = schemaProductData.find(schema => product.barcode === schema.barcode);
        let updateSchema = [...schemaProductData];

        if (productSchema) {
            updateSchema = updateSchema.filter(schema => schema.barcode !== product.barcode);
        } else {
            updateSchema = [
                ...updateSchema,
                {
                    barcode: product.barcode,
                    barcodeType: product.barcodeType,
                    refillNote: '',
                    targetInvAmount: 0,
                    minimumInvAmount: 0,
                },
            ];
        }
        setPrestine(false);
        setSchemaProductData(updateSchema);
    };

    /**
     * when user changes the input value directly, update the state of schema data
     * @param {*} type minimumInvAmount | targetInvValue
     * @param {*} value input value
     * @param {*} product seleted product
     */
    const inputValueChange = (type, value, product) => {
        const productSchema = schemaProductData.find(schema => product.barcode === schema.barcode);
        let updateSchema = [...schemaProductData];

        if (productSchema) {
            updateSchema = updateSchema.map(schema => {
                if (productSchema.barcode === schema.barcode) {
                    return {
                        ...productSchema,
                        [type]: parseInt(value),
                    };
                }
                return schema;
            });
        } else {
            updateSchema = [
                ...updateSchema,
                {
                    barcode: product.barcode,
                    barcodeType: product.barcodeType,
                    refillNote: '',
                    targetInvAmount: 0,
                    minimumInvAmount: 0,
                    [type]: parseInt(value),
                },
            ];
        }

        if (maxInvAmountReached(type, updateSchema)) {
            setMaxInvWarnModalOpen(true);
        } else {
            setSchemaProductData(updateSchema);
        }
        setPrestine(false)
    };

    /**
     * send the updated stated of schema data to API for update or save
     * @returns
     */
    const sendRefillSchemaToApi = () => {
        let productDataToSave = [];
        //if there is a product sorting, sort the schema data before saving
        if (productSorting.type) {
            schemaProductData.forEach(schema => {
                const schemaProduct = allProductsData.find(product => product.barcode === schema.barcode);
                if (schemaProduct) {
                    productDataToSave.push({
                        ...schema,
                        name: schemaProduct.name,
                        productCategory: schemaProduct.productCategory,
                    });
                }
            });

            productDataToSave = orderBy(productDataToSave, productSorting.type, productSorting.direction);
            productDataToSave.map(schema => {
                delete schema.name;
                delete schema.productCategory;
                return schema;
            });
        } else {
            productDataToSave = [...schemaProductData];
        }

        const schemaId = rule.id;
        const deviceCode = selectedCabinet.deviceCode;
        if (!deviceCode) return;
        
        setSchemaRequestingModalOpen(true);
        //if ID is present, then we send UPDATE request
        //otherwise we create a new schema
        if (schemaId) {
            updateCabinetRefillSchema(
                {
                    productData: productDataToSave,
                },
                schemaId,
                deviceCode
            );
        } else {
            createRefillSchema({
                productData: productDataToSave,
                deviceCodes: [deviceCode],
            });
        }
    };

    /**
     * delete the selected schema data
     * @returns
     */
    const requestRefillSchemaDeletion = () => {
        const deviceCode = selectedCabinet.deviceCode;
        const schemaId = rule.id;
        if (!deviceCode || !schemaId) return;

        deleteRefillSchema(deviceCode);
        setTimeout(() => fetchRefillRules(), 2000);
        setDeleteConfirmationModelOpen(false);
    };

    /**
     * when sort icon is presses for product or category
     * @param {string} type product | category
     */
    const toggleSorting = type => {
        let sortingDirection = 'asc';
        //if first time cliked for sorting, set the value and return
        if (!productSorting.type) {
            setProductSorting({
                type,
                direction: sortingDirection,
            });
            return;
        }

        if (type === productSorting.type) {
            sortingDirection = productSorting.direction === 'asc' ? 'desc' : 'asc';
        }

        setProductSorting({
            type,
            direction: sortingDirection,
        });
    };

    const onSelectAllToggle = toggle => {
        // if true, add all the valid products to the schemadata
        let productData = [];
        if (toggle) {
            const products = [...allProductsData];
            productData = products.map(product => {
                const schema = schemaProductData.find(data => data.barcode === product.barcode);
                if (schema) {
                    return { ...schema };
                }

                return {
                    barcode: product.barcode,
                    barcodeType: product.barcodeType,
                    refillNote: '',
                    targetInvAmount: 0,
                    minimumInvAmount: 0,
                };
            });
        } else {
            // if toggle off, use original refill rule from props as selected product
            const refillSchemaData = rule.productData || [];

            productData = refillSchemaData.map(data => {
                return {
                    ...data,
                    barcode: _13digitBarcode(data.barcodeType, data.barcode),
                    minimumInvAmount: data.minimumInvAmount ? data.minimumInvAmount : 0,
                };
            });
        }
        setSchemaProductData(productData);
    };

    /**
     * filter out the cabinet that has a refill schema or an active refill plan
     * @returns
     */
    const getCabinetsWithoutSchemas = () => {
        if (allRefillSchemas.isFetching) return;

        const { refillRules } = allRefillSchemas || [];
        const { activePlans } = allRefillPlans || {};

        const activePlansDeviceCodes = Object.keys(activePlans) || [];

        return cabinets
            .filter(cabinet => !refillRules.find(schema => schema.deviceCode === cabinet.deviceCode))
            .filter(cabinet => cabinet.deviceCode !== selectedCabinet.deviceCode)
            .filter(cabinet => !activePlansDeviceCodes.includes(cabinet.deviceCode));
    };

    const copySchemaToOtherCabinets = (cabinetsToUpdate, type, options) => {
        if (!options.length) return;

        setCopySchemaStatus({ status: 'saving', isOpen: true });

        const { rule } = cabinetRefillSchema;
        const planogram = rule.planogram;
        const productData = rule.productData;

        let dataToUpdate = [];

        //copy both - planogram and productdata
        if (options.length === 2) {
            dataToUpdate.push({ planogram, productData });
        }

        if (options.length === 1 && options[0].key === 'planogram') {
            dataToUpdate.push({ planogram, productData });
        }

        const sendToAPI = async () => {
            const response = await copyRefillSchemaToOtherCabinets(cabinetsToUpdate, 'refillSchema', dataToUpdate);

            if (response.ok) {
                setCopySchemaStatus({ status: 'success', isOpen: true, message: t('schemaCopied') });
            } else {
                setCopySchemaStatus({ status: 'error', isOpen: true, message: response.error });
            }
        };

        sendToAPI();
    };

    const getProductsToView = () => {
        //if there is a product sorting in the state,
        //sort the product accordingly first
        let sortedProducts = [...allProductsData];

        if (productSorting.type && sortedProducts && sortedProducts.length) {
            if (productSorting.type === 'refillNote') {
                sortedProducts = sortedProducts.sort((a, b) => {
                    const rNote1 = getRefillSchemaValue('refillNote', a);
                    const rNote2 = getRefillSchemaValue('refillNote', b);
                    if ((rNote1 < rNote2 && rNote1 !== '') || rNote2 === '') {
                        return productSorting.direction === 'asc' ? -1 : 1;
                    } else {
                        return productSorting.direction === 'asc' ? 1 : -1;
                    }
                });
            } else {
                sortedProducts = orderBy(sortedProducts, productSorting.type, productSorting.direction);
            }
        }

        if (!productSearchQuery) return sortedProducts;

        const query = productSearchQuery.toString().toLowerCase();
        const filteredProducts = sortedProducts.filter(product => {
            return (
                product.name
                    .toString()
                    .toLowerCase()
                    .includes(query) ||
                product.barcode
                    .toString()
                    .toLowerCase()
                    .includes(query)
            );
        });
        return filteredProducts;
    };

    //if products are still being fetched, show the loader
    if (fetching) {
        return <Loader message={t('main:fetchingLoader')} />;
    }

    return (
        <Fragment>
            <ApiRequestModal
                isOpen={schemaRequestingModalOpen}
                data={cabinetRefillSchema}
                closeModal={() => {
                    setTimeout(() => fetchRefillRules(), 2000);

                    setSchemaRequestingModalOpen(false);
                }}
                successMessage={t('schemaSaved')}
                t={t}
            />
            <RefillNoteModel
                isOpen={refillNoteModal.isOpen}
                product={refillNoteModal.product}
                refillNote={refillNoteModal.refillNote}
                closeRefillNote={(product, refillNote) => closeRefillNote(product, refillNote)}
                t={t}
                isAdmin={isAdmin}
            />
            <ConfirmationModal
                isOpen={deleteConfirmationModelOpen}
                confirmDelete={() => requestRefillSchemaDeletion()}
                cancel={() => setDeleteConfirmationModelOpen(false)}
                message={t('deleteSchemaConfirmation')}
                t={t}
            />
            <MaxInventoryWarnModal isOpen={maxInvWarnModalOpen} cancel={() => setMaxInvWarnModalOpen(false)} t={t} />
            <SchemaControlBar
                saveRefillSchema={() => sendRefillSchemaToApi()}
                deleteRefillSchema={() => setDeleteConfirmationModelOpen(true)}
                schema={cabinetRefillSchema}
                prestine={prestine}
                searchQuery={query => setProductSearchQuery(query)}
                t={t}
                toggleViewMode={mode => toggleViewMode(mode)}
                activeViewMode={activeViewMode}
                openCopySchema={() => setCopySchemaModalOpen(true)}
                isAdmin={isAdmin}
            />
            <CopySchemaStatusModal
                isOpen={copySchemaStatus.isOpen}
                status={copySchemaStatus.status}
                message={copySchemaStatus.message}
                closeModal={() =>{                    
                    setCopySchemaStatus({ isOpen: false })
                    fetchRefillRules()
                }}
                t={t}
            />
            {copySchemaModalOpen && (
                <SettingsCopyModal
                    closeModal={() => setCopySchemaModalOpen(false)}
                    t={t}
                    submitSettingsCopy={copySchemaToOtherCabinets}
                    cabinets={{ cabinets: getCabinetsWithoutSchemas() }}
                    locations={locations}
                    settingsType="refillSchema"
                    settings={{ planogram: '' }}
                />
            )}
            <div className="refillschema__contents-products">
                <Table responsive>
                    <thead>
                        <tr>
                            <th>
                                <Checkbox size="md" id="all" onChange={e => onSelectAllToggle(e.target.checked)}>
                                    {t('main:all')}
                                </Checkbox>
                            </th>
                            <th>
                                <div className="refillschema__contents-sort">
                                    <span>{t('cabinetSettings:productName')}</span>
                                    <svg
                                        onClick={() => {
                                            toggleSorting('name');
                                        }}
                                    >
                                        <use xlinkHref={`${icons}#icon-transfer`}></use>
                                    </svg>
                                </div>
                            </th>
                            <th className="hide-in-mb">{t('main:barcode')}</th>
                            <th className="hide-in-medium">
                                <div className="refillschema__contents-sort">
                                    <span>{t('main:category')}</span>
                                    <svg
                                        onClick={() => {
                                            toggleSorting('productCategory.name');
                                        }}
                                    >
                                        <use xlinkHref={`${icons}#icon-transfer`}></use>
                                    </svg>
                                </div>
                            </th>
                            <th>
                                <div className="refillschema__contents-help">
                                    <span>{t('targetAmount')} </span>
                                    <svg id="tooltip-target">
                                        <use xlinkHref={`${icons}#icon-info`}></use>
                                    </svg>
                                    <Popover
                                        placement="bottom"
                                        isOpen={inventoryLevelPopover}
                                        target="tooltip-target"
                                        toggle={() => setInventoryLevelPopover(!inventoryLevelPopover)}
                                        trigger="hover"
                                        boundariesElement="window"
                                    >
                                        <div className="refillschema__contents-popover">
                                            <div>
                                                <strong>{t('targetAmount')}</strong>
                                                <p>{t('targetAmountHelpText')}</p>
                                            </div>
                                        </div>
                                    </Popover>
                                </div>
                            </th>
                            <th className="hide-in-medium">
                                <div className="refillschema__contents-help">
                                    <span>{t('minimumThreshold')} </span>
                                    <svg id="tooltip-minimum">
                                        <use xlinkHref={`${icons}#icon-info`}></use>
                                    </svg>
                                    <Popover
                                        placement="bottom"
                                        isOpen={minimumLevelPopover}
                                        target="tooltip-minimum"
                                        toggle={() => setMinimumLevelPopover(!minimumLevelPopover)}
                                        trigger="hover"
                                        boundariesElement="window"
                                    >
                                        <div className="refillschema__contents-popover">
                                            <div>
                                                <strong>{t('minimumThreshold')}</strong>
                                                <p>{t('minimumThresholdHelpText')}</p>
                                            </div>
                                        </div>
                                    </Popover>
                                </div>
                            </th>
                            <th className="hide-in-medium">
                                <div className="refillschema__contents-sort">
                                    <span>{t('refillNote')}</span>
                                    <svg
                                        onClick={() => {
                                            toggleSorting('refillNote');
                                        }}
                                    >
                                        <use xlinkHref={`${icons}#icon-transfer`}></use>
                                    </svg>
                                </div>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {getProductsToView().map(product => {
                            return (
                                <tr className={checkIfProductRefillSchemaExists(product) ? 'refillschema__contents--active' : ''} key={product.barcode}>
                                    <td>
                                        <Checkbox
                                            size="md"
                                            checked={checkIfProductRefillSchemaExists(product)}
                                            onChange={e => toggleRefillProductSelection(product)}
                                            id={product.barcode}
                                        />
                                    </td>
                                    <td>
                                        <img src={product.imageUrl} alt="" className="refillschema__contents-products-img" />
                                        <span className="refillschema__contents-products-name">{product.name} </span>
                                    </td>
                                    <td className="hide-in-mb">{product.barcode}</td>
                                    <td className="hide-in-medium">
                                        {' '}
                                        {product.productCategory ? (product.productCategory.name ? product.productCategory.name : '-') : '-'}
                                    </td>
                                    <td>
                                        <div className="refillschema__contents-target">
                                            <span onClick={() => updateRefillSchemaData('targetInvAmount', 'minus', product)}>-</span>
                                            <Input
                                                type="number"
                                                size="sm"
                                                value={getRefillSchemaValue('targetInvAmount', product)}
                                                onChange={e => inputValueChange('targetInvAmount', e.target.value, product)}
                                            />
                                            <span onClick={() => updateRefillSchemaData('targetInvAmount', 'plus', product)}>+</span>
                                        </div>
                                    </td>
                                    <td className="hide-in-medium">
                                        <div className="refillschema__contents-target">
                                            <span onClick={() => updateRefillSchemaData('minimumInvAmount', 'minus', product)}>-</span>
                                            <Input
                                                type="number"
                                                size="sm"
                                                value={getRefillSchemaValue('minimumInvAmount', product)}
                                                onChange={e => inputValueChange('minimumInvAmount', e.target.value, product)}
                                            />
                                            <span onClick={() => updateRefillSchemaData('minimumInvAmount', 'plus', product)}>+</span>
                                        </div>
                                    </td>
                                    <td className="hide-in-medium">
                                        <div
                                            className={`refillschema__contents-note ${
                                                getRefillSchemaValue('refillNote', product) ? ' refillschema__contents-note--active' : ''
                                            }`}
                                            onClick={() => {
                                                setRefillNoteModal({ isOpen: true, product, refillNote: getRefillSchemaValue('refillNote', product) });
                                            }}
                                        >
                                            <svg>
                                                <use xlinkHref={`${icons}#icon-clipboard-edit`}></use>
                                            </svg>
                                            <span>{t('editNote')}</span>
                                        </div>
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </Table>
            </div>
        </Fragment>
    );
};

export default SchemaProducts;
