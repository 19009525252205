import React, { useEffect, useState } from 'react';
import { TabItem } from '@storaensods/seeds-react';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { withRouter } from 'react-router';
import { groupBy, forEach, set } from 'lodash';

import { fetchCabinets, fetchLocations } from '../../actions/cabinets';
import { Loader, LoaderError } from './../commons/utils';
import ReportsAutomation from './tabs/reportsAutomation';
import NotificationAutomation from './tabs/notifications';
import RefillPlanAutomation from './tabs/refillPlanAutomation';
import { fetchRefillRules } from '../../actions/controlPanel';

const Automations = props => {
    const { fetchCabinets, fetchLocations, cabinets, organization, fetchRefillRules, refillSchemas, t } = props;

    const [loading, setLoading] = useState(true);
    const [hasErrors, setHasErrors] = useState(false);
    const [cabinetsByLocations, setCabinetsByLocations] = useState({});
    const [activeTab, setActiveTab] = useState('transactions');
    // eslint-disable-next-line
    const [currentOrganization, setCurrentOrganization] = useState(organization);

    //when component loads, fetch all necessary data
    useEffect(() => {
        let apiRequest = [];

        if (!cabinets.fetched) apiRequest.push(fetchCabinets());
        if (!cabinets.locationsFetched) apiRequest.push(fetchLocations());
        if (!refillSchemas.fetched && !refillSchemas.isFetching) apiRequest.push(fetchRefillRules());

        Promise.all(apiRequest)
            .then(() => setLoading(false))
            .catch(e => {
                setHasErrors(true);
                setLoading(false);
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // if organization is changed, refech the cabinets and locations
    useEffect(() => {
        if (currentOrganization === organization) return;
        setLoading(true);
        Promise.all([fetchCabinets(), fetchLocations(), fetchRefillRules()])
            .then(() => setLoading(false))
            .catch(e => {
                setHasErrors(true);
                setLoading(false);
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [organization]);

    // when cabinets load, arrarge then by location
    useEffect(() => {
        if (cabinets.isFetching) return;

        const allCabinets = cabinets.cabinets || [];
        const allLocations = cabinets.locations || [];
        const groupCabinets = groupBy(allCabinets, 'locationId');

        let locationNameMapping = {};

        // map cabinets to locationName.
        forEach(groupCabinets, (cabinets, locationId) => {
            if (locationId === 'null') {
                set(locationNameMapping, 'No location', cabinets);
            } else {
                const locationName = allLocations.find(location => location.id === locationId)?.name;
                if (locationName) {
                    set(locationNameMapping, locationName, cabinets);
                }
            }
        });
        setCabinetsByLocations(locationNameMapping);
    }, [cabinets]);

    if (loading) {
        return <Loader />;
    }

    if (hasErrors) {
        return <LoaderError />;
    }

    return (
        <div className="container-fluid automations">
            <div className="automations__tabs">
                <TabItem isActive={activeTab === 'transactions'} onClick={() => setActiveTab('transactions')}>
                    {t('automations:transactionReports')}
                </TabItem>
                <TabItem isActive={activeTab === 'refill'} onClick={() => setActiveTab('refill')}>
                    {t('automations:refillReports')}
                </TabItem>
                <TabItem isActive={activeTab === 'notifications'} onClick={() => setActiveTab('notifications')}>
                    {t('automations:notifications')}
                </TabItem>
                <TabItem isActive={activeTab === 'refillPlans'} onClick={() => setActiveTab('refillPlans')}>
                    {t('automations:refillPlan')}
                </TabItem>
            </div>
            {(() => {
                if (activeTab === 'transactions') {
                    return (
                        <ReportsAutomation
                            reportType="transactionReport"
                            cabinetsByLocations={cabinetsByLocations}
                            cancelPage={() => props.history.goBack()}
                            t={t}
                        />
                    );
                }

                if (activeTab === 'refill') {
                    return (
                        <ReportsAutomation
                            reportType="refillReport"
                            cabinetsByLocations={cabinetsByLocations}
                            cancelPage={() => props.history.goBack()}
                            t={t}
                        />
                    );
                }

                if (activeTab === 'notifications') {
                    return <NotificationAutomation cabinetsByLocations={cabinetsByLocations} cancelPage={() => props.history.goBack()} t={t} />;
                }

                if (activeTab === 'refillPlans') {
                    return (
                        <RefillPlanAutomation
                            cabinetsByLocations={cabinetsByLocations}
                            refillSchemas={refillSchemas.refillRules || []}
                            cancelPage={() => props.history.goBack()}
                            t={t}
                        />
                    );
                }
            })()}
        </div>
    );
};

export default withRouter(
    connect(
        state => ({
            cabinets: state.cabinets,
            organization: state.user.group.Name,
            refillSchemas: state.refillRules,
        }),
        {
            fetchCabinets,
            fetchLocations,
            fetchRefillRules,
        }
    )(translate()(Automations))
);
