import React, { Fragment, useState } from "react"
import { FormGroup, Label, Input, InputGroup, InputGroupText } from 'reactstrap';

import ProductTabs from "../segments/productTabs"
import TagSensitivity, {DEFAULT_TAG_SENSITIVITY_LEVEL} from "../../tagSensitivity";
import { InfoBanner } from "../../commons/utils";

const TagConfiguration =({activeTab, t, tabNavigation, productTagSensitivityLevel, productDataChange, submitProductToApi})=>{

    const [tagSensitivity, setTagSensitivity] = useState(productTagSensitivityLevel)
    const [configProductLevelSensitivity, setConfigProductLevelSenstivity] = useState(productTagSensitivityLevel === -1 ? false : true )

    /**
     * when user clicks checkbox on/off
     */
    const onToggleConfigTagSensitivity =()=>{
        const configSensitivity = !configProductLevelSensitivity
        if(configSensitivity){
            setTagSensitivity(DEFAULT_TAG_SENSITIVITY_LEVEL)
        }else{
            setTagSensitivity(-1)
        }

        setConfigProductLevelSenstivity(configSensitivity)
    }
    
    /**
     * when user clicks previous button, send the tagSensitivity valeu to parent component
     * @param {*} index 
     * @param {*} tabChange 
     */
    const navigateToNextTab = (index, tabChange) => { 
        productDataChange({ tagSensitivity: configProductLevelSensitivity ? tagSensitivity : -1 });
        tabNavigation(index, tabChange);
    };

    /**
     * A value of tag sensitivity
     * @param {*} value 0, 20, 40, 60, 80, 100
     */
    const onTagSensivityChanged =(value)=>{
       setTagSensitivity(parseInt(value))
    }

    /**
     * send the tagSensitivity valeu to parent component state and submit the product to API
     */
    const submitProduct=()=>{
        productDataChange({tagSensitivity: configProductLevelSensitivity ? tagSensitivity : -1 })
        submitProductToApi(true)
    }

    return(
        <Fragment>
            <ProductTabs tabChange={navigateToNextTab} activeTab={activeTab} t={t}/>
            <div className="row">
                <div className="col-12">
                    <h3 className="products__group-title">{t('tagSensitivitySetting')}</h3>
                    <FormGroup>
                       <InfoBanner message={t('productLevelSensitivityInfo')}/>
                    </FormGroup>
                </div>
                <div>
                    <FormGroup className="col-12">
                        <InputGroup>
                                <InputGroupText>
                                    <Input
                                        addon
                                        type="checkbox"
                                        name="tag-sensitivity"
                                        checked={configProductLevelSensitivity}
                                        onChange={onToggleConfigTagSensitivity}
                                    />
                                </InputGroupText>
                                <Label for="name" className="products__checkbox-text">
                                    {t('configTagSensitivity')}
                                </Label>
                        </InputGroup>
                    </FormGroup>
                </div>
            </div>
            {
                configProductLevelSensitivity && (
                    <div  className='products__tag-config'>
                        <TagSensitivity currentSensitivityLevel={tagSensitivity} t={t} changeSensitivity={onTagSensivityChanged}/>
                    </div>
                )
            }
            <div className="row">
                <div className="col">
                    <div className="products__cta-buttons">
                        <div className="products__cta-button products__cta-button--neutral" onClick={() => navigateToNextTab(-1)}>
                            ‹‹  {t('previous')}
                        </div>
                        <div className="products__cta-button products__cta-button--success" onClick={submitProduct}>
                            {t('save')}
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}
export default TagConfiguration