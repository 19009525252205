import React, { Fragment, useEffect, useState } from 'react';

import CardData from './card';
import WasteVsSalesPieChart from './wasteVsSalesPieChart';

const CardView = ({ selectedNav, activeSelectionItem, cardData, t }) => {
    const [salesData, setSalesData] = useState({
        totalValue: 0,
        totalCount: 0,
        currency: '',
    });
    const [wasteData, setWasteData] = useState({
        totalValue: 0,
        totalCount: 0,
        currency: '',
        imageUrl: '',
        productName: '',
        barcode: '',
    });

    useEffect(() => {
        const { waste, sales } = cardData;
        const selected = activeSelectionItem ? activeSelectionItem.title : '';

        //reset the sales and waste data on the component update
        setSalesData({ totalValue: 0, totalCount: 0, currency: '' });
        setWasteData({
            totalValue: 0,
            totalCount: 0,
            currency: '',
            imageUrl: '',
            productName: '',
            barcode: 0,
        });

        if (selectedNav === 'all') {
            let wasteValue = 0,
                wasteCount = 0,
                salesValue = 0,
                salesCount = 0,
                currency = '';

            Object.keys(waste).forEach(key => {
                waste[key].forEach(product => {
                    if (!currency) {
                        currency = product['expiredProducts.currency'];
                    }

                    wasteValue = wasteValue + parseInt(product['ExpiredProducts.count']) * parseFloat(product['ExpiredProducts.price']);
                    wasteCount = wasteCount + product['ExpiredProducts.count'];
                });
            });

            Object.keys(sales).forEach(key => {
                sales[key].forEach(product => {
                    if (!currency) {
                        currency = product['Transactions.currency'];
                    }
                    salesValue = salesValue + product['Transactions.orderSum'];
                    salesCount = salesCount + product['Transactions.soldCount'];
                });
            });

            setSalesData({
                totalValue: salesValue,
                totalCount: salesCount,
                currency,
            });
            setWasteData({
                totalCount: wasteCount,
                totalValue: wasteValue,
                currency,
            });
        }

        if (selectedNav !== 'all' && waste[selected]) {
            let totalCount = 0;
            let totalValue = 0;
            let currency = '';

            const products = waste[selected];
            products.forEach(product => {
                if (!currency) {
                    currency = product['ExpiredProducts.currency'];
                }
                totalValue = totalValue + parseInt(product['ExpiredProducts.count']) * parseFloat(product['ExpiredProducts.price']);
                totalCount = totalCount + product['ExpiredProducts.count'];
            });

            setWasteData({ totalCount, totalValue, currency });
        }

        if (selectedNav !== 'all' && sales[selected]) {
            let totalCount = 0;
            let totalValue = 0;
            let barcode = '';
            let productName = '';
            let imageUrl = '';
            let currency = '';

            const products = sales[selected];

            products.forEach(product => {
                if (product['Transactions.orderSum'] !== null) {
                    // note: ignoring the null values.
                    totalValue = totalValue + parseFloat(product['Transactions.orderSum']);
                }
                barcode = barcode || product['Transactions.barcode'];
                productName = productName || product['Transactions.productName'];
                imageUrl = imageUrl || product['Transactions.imageUrl'];
                currency = currency || product['Transactions.currency'];
                totalCount = totalCount + product['Transactions.count'];
            });

            setSalesData({
                totalValue,
                totalCount: products.length,
                barcode,
                productName,
                imageUrl,
                currency,
            });
        }
    }, [cardData, selectedNav, activeSelectionItem]);

    const getPieChartData = () => {
        return {
            data: [
                { name: 'waste', value: wasteData.totalValue },
                { name: 'sales', value: salesData.totalValue },
            ],
            currency: salesData.currency,
        };
    };

    return (
        <Fragment>
            <CardData salesData={salesData} wasteData={wasteData} selectedNav={selectedNav} t={t} />
            <WasteVsSalesPieChart data={getPieChartData()} />
        </Fragment>
    );
};

export default CardView;
