import React, { useState } from 'react';
import { translate } from 'react-i18next';
import { TabBar, TabItem } from '@storaensods/seeds-react';
import Report from '../report/report.js';

const canSeeTransactions = user => {
    const roles = user.profile.roles;
    const allowedRolesToSeeTransactions = ['Cabinet Operator', 'Administrator', 'Reporting Operator'];
    const canSeeTransactions = (Array.isArray(roles) ? roles.filter(role => allowedRolesToSeeTransactions.includes(role)) : []).length > 0;
    return canSeeTransactions;
};

const canSeeTemperature = user => {
    const roles = user.profile.roles;
    const allowedRolesToSeeTemperature = ['Cabinet Operator', 'Administrator', 'Reporting Operator'];
    const canSeeTemperature = (Array.isArray(roles) ? roles.filter(role => allowedRolesToSeeTemperature.includes(role)) : []).length > 0;
    return canSeeTemperature;
};

const Reports = ({ t, user }) => {
    const [activeTab, setActiveTab] = useState(canSeeTransactions(user) ? 'sales' : 'replenishments');

    const handleTabClick = tab => {
        setActiveTab(tab);
    };

    return (
        <div className="container-fluid">
            <TabBar className="mt-3 not-printable">
                {canSeeTransactions(user) ? (
                    <TabItem onClick={() => handleTabClick('sales')} isActive={activeTab === 'sales'}>
                        {t('reports:transactions')}
                    </TabItem>
                ) : (
                    <div style={{ display: 'none' }}></div>
                )}
                <TabItem onClick={() => handleTabClick('replenishments')} isActive={activeTab === 'replenishments'}>
                    {t('reports:replenishments')}
                </TabItem>
                {canSeeTemperature(user) ? (
                    <TabItem onClick={() => handleTabClick('temperature')} isActive={activeTab === 'temperature'}>
                        {t('reports:temperature')}
                    </TabItem>
                ) : (
                    <div style={{ display: 'none' }}></div>
                )}
            </TabBar>

            <div>
                {activeTab === 'sales' && canSeeTransactions(user) && <Report reportType="sales" />}
                {activeTab === 'replenishments' && <Report reportType="replenishments" />}
                {activeTab === 'temperature' && canSeeTemperature(user) && <Report reportType="temperature" />}
            </div>
        </div>
    );
};

export default translate('main')(Reports);
