import React, { useEffect, useState, Fragment } from 'react';
import { FormGroup, Label, Input, InputGroup, InputGroupText } from 'reactstrap';
import ProductTabs from '../segments/productTabs';

const ProductTypes = ({
    productTypes,
    selectedProduct,
    productDataChange,
    tabNavigation,
    validationErrors = {},
    activeTab,
    updateValidationError,
    t,
    isUpdating,
    submitProductToApi,
}) => {
    const [selectedProductType, setSelectedProductType] = useState(selectedProduct.productType);
    const [frozenFilter, setIsFrozenFilter] = useState(false);
    const [errors, setErrors] = useState(validationErrors);

    useEffect(() => {
        //make sure page always scrolls to top when tab changes
        window.scrollTo(0, 0);
    }, []);

    /**
     * show only frozen product types
     * @returns
     */
    const filterProductTypes = () => {
        if (frozenFilter) {
            return productTypes.filter(type => type.packageForFreezer === true);
        }
        return productTypes;
    };

    const navigateToNextTab = (index, tabChange, submitData = false) => {
        let dataToSave = { ...selectedProduct };
        dataToSave.productType = selectedProductType;
        productDataChange(dataToSave);
        updateValidationError(errors);
        tabNavigation(index, tabChange, submitData);
    };

    /**
     *  if there is any validation errors, show the errors
     * @returns DOM to visualize warnings
     */
    const getValidationWarningView = () => {
        const { productType } = errors;
        if (productType) {
            return (
                <div className="col products__errors">
                    <i className="fas fa-exclamation-circle"></i> <span>{t('packagingTypeValidationError')} </span>
                </div>
            );
        }
    };

    return (
        <Fragment>
            <ProductTabs tabChange={navigateToNextTab} activeTab={activeTab} t={t} />
            <div className="row">
                <div className="col-12">
                    <h3 className="products__group-title">{t('productType')}</h3>
                    <FormGroup>
                        <Label>*{t('selectProductType')}</Label>
                    </FormGroup>
                </div>
                {getValidationWarningView()}
                <div className="col-12">
                    <FormGroup>
                        <InputGroup>
                            <InputGroupText>
                                <Input
                                    addon
                                    aria-label="Checkbox for following text input"
                                    type="checkbox"
                                    onChange={() => setIsFrozenFilter(!frozenFilter)}
                                    id="frozenProduct"
                                />
                            </InputGroupText>
                            <Label for="frozenProduct" className="products__checkbox-text">
                                {t('showOnlyFrozen')}
                            </Label>
                        </InputGroup>
                    </FormGroup>
                </div>
                {filterProductTypes().map(type => {
                    return (
                        <div
                            className="col-md-6 col-xl-4"
                            key={type.productType}
                            onClick={() => {
                                setSelectedProductType(type.productType);
                                setErrors({});
                            }}
                        >
                            <div
                                className={`products__package ${type.productType === selectedProductType ? 'products__package--active' : ''}`}
                                style={{ height: '248px', overflowY: 'auto' }}
                            >
                                <div className="products__package-title">
                                    {' '}
                                    {type.productType?.split('-')[0]}-{type.title['en']}{' '}
                                </div>
                                <div className="products__package-contents">
                                    <img src={type.imageUrls[0]} alt="" />
                                    <div className="products__package-tags">
                                        <strong>Tag types</strong>
                                        <ul>
                                            {type.tagTypes &&
                                                type.tagTypes.map(tag => {
                                                    return <li key={tag}>{tag}</li>;
                                                })}
                                        </ul>
                                    </div>
                                    <FormGroup className="products__package-check">
                                        <InputGroup>
                                            <InputGroupText>
                                                <Input
                                                    addon
                                                    aria-label="Checkbox for following text input"
                                                    type="radio"
                                                    readOnly
                                                    checked={selectedProductType === type.productType}
                                                />
                                            </InputGroupText>
                                        </InputGroup>
                                    </FormGroup>
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>
            <div className="row">
                <div className="col">
                    <div className="products__cta-buttons">
                        <div className="products__cta-button products__cta-button--neutral" onClick={() => navigateToNextTab(-1)}>
                            ‹‹ {t('previous')}
                        </div>
                        {isUpdating && (
                            <div className="products__cta-button products__cta-button--success" onClick={() => navigateToNextTab(1, null, TreeWalker)}>
                                {t('save')}
                            </div>
                        )}
                        <div className="products__cta-button products__cta-button--positive" onClick={() => navigateToNextTab(1)}>
                            {t('next')} ››
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default ProductTypes;
