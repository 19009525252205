import {
    REQUEST_DISCOUNT_RULES,
    RECEIVE_DISCOUNT_RULES,
    RECEIVE_DISCOUNT_RULES_ERROR,
    REQUEST_CREATE_DISCOUNT_RULE,
    RECEIVE_CREATE_DISCOUNT_RULE,
    RECEIVE_CREATE_DISCOUNT_RULE_ERROR,
    REQUEST_DELETE_DISCOUNT_RULE,
    RECEIVE_DELETE_DISCOUNT_RULE,
    RECEIVE_DELETE_DISCOUNT_RULE_ERROR,
    RESET_DISCOUNT_RULES,
    REQUEST_UPDATE_DISCOUNT_RULE,
    RECEIVE_UPDATE_DISCOUNT_RULE_ERROR,
    RECEIVE_UPDATE_DISCOUNT_RULE,
    REQUEST_UPLOAD_BUNDLE_IMAGE,
    UPLOAD_BUNDLE_IMAGE_ERROR,
    UPLOAD_BUNDLE_IMAGE_SUCCESS,
    REQUEST_UPLOAD_SCHEDULED_IMAGE,
    UPLOAD_SCHEDULED_IMAGE_ERROR,
    UPLOAD_SCHEDULED_IMAGE_SUCCESS,
    REQUEST_UPLOAD_EXPIRY_IMAGE,
    UPLOAD_EXPIRY_IMAGE_ERROR,
    UPLOAD_EXPIRY_IMAGE_SUCCESS,
    RESET_EXPIRY_IMAGE,
    RESET_SCHEDULED_IMAGE,
    RESET_BUNDLE_IMAGE,
} from '../actions/discounts.js';

export const initState = {
    fetched: false, // if successfully requested discount rules
    fetching: false, // currently performing a request related to discount rules
    error: null, // string error if error occured during request else null
    bundleImageStoring: false,
    bundleImageStoringError: null,
    bundleImageUrl: '',
    expiryImageStoring: false,
    expiryImageStoringError: null,
    expiryImageUrl: '',
    scheuledImageStoring: false,
    scheduledImageStoringError: null,
    scheduledImageUrl: '',

    discountRules: [],
};

export default function discountRules(state = initState, action) {
    switch (action.type) {
        case REQUEST_DISCOUNT_RULES:
            return {
                ...state,
                fetching: true,
                fetched: false,
                error: null,
            };
        case RECEIVE_DISCOUNT_RULES:
            return {
                ...state,
                fetching: false,
                fetched: true,
                error: null,
                discountRules: action.discountRules,
                lastUpdate: action.receivedAt,
            };
        case RECEIVE_DISCOUNT_RULES_ERROR:
            console.error(action.error);
            return {
                ...state,
                fetching: false,
                fetched: false,
                error: action.error.toString(),
            };

        case REQUEST_CREATE_DISCOUNT_RULE:
            return {
                ...state,
                fetching: true,
                error: null,
            };

        case RECEIVE_CREATE_DISCOUNT_RULE:
            return {
                ...state,
                fetching: false,
                error: null,
                discountRules: [...state.discountRules, action.discountRule],
            };
        case RECEIVE_CREATE_DISCOUNT_RULE_ERROR:
            console.error(action.error);
            return {
                ...state,
                fetching: false,
                error: action.error.toString(),
            };
        case REQUEST_UPDATE_DISCOUNT_RULE:
            return {
                ...state,
                fetching: true,
                error: null,
            };
        case RECEIVE_UPDATE_DISCOUNT_RULE_ERROR:
            console.error(action.error);
            return {
                ...state,
                fetching: false,
                error: action.error.toString(),
            };
        case RECEIVE_UPDATE_DISCOUNT_RULE:
            return {
                ...state,
                fetching: false,
                error: null,
                discountRules: [
                    ...state.discountRules.map(discount => (discount.Id === action.updatedDiscountRule.Id ? action.updatedDiscountRule : discount)),
                ],
            };
        case REQUEST_DELETE_DISCOUNT_RULE:
            return {
                ...state,
                fetching: true,
                error: null,
            };
        case RECEIVE_DELETE_DISCOUNT_RULE:
            return {
                ...state,
                fetching: false,
                error: null,
                discountRules: [...state.discountRules.filter(discountRule => discountRule.Id !== action.id)],
            };
        case RECEIVE_DELETE_DISCOUNT_RULE_ERROR:
            console.error(action.error);
            return {
                ...state,
                fetching: false,
                error: action.error.toString(),
            };

        case REQUEST_UPLOAD_BUNDLE_IMAGE:
            return {
                ...state,
                bundleImageStoring: true,
                bundleImageUrl: '',
                bundleImageStoringError: null,
                uploadProgress: action.progress || 0,
            };
        case UPLOAD_BUNDLE_IMAGE_SUCCESS:
            return {
                ...state,
                bundleImageStoring: false,
                bundleImageUrl: action.url,
                bundleImageStoringError: null,
            };
        case UPLOAD_BUNDLE_IMAGE_ERROR:
            console.error(action.error);
            return {
                ...state,
                bundleImageStoring: false,
                bundleImageUrl: '',
                bundleImageStoringError: action.error.toString(),
            };
        case RESET_BUNDLE_IMAGE:
            return {
                ...state,
                bundleImageStoring: false,
                bundleImageUrl: '',
                bundleImageStoringError: '',
            };
        case REQUEST_UPLOAD_SCHEDULED_IMAGE:
            return {
                ...state,
                scheduledImageStoring: true,
                scheduledImageUrl: '',
                scheduledImageStoringError: null,
                uploadProgress: action.progress || 0,
            };
        case UPLOAD_SCHEDULED_IMAGE_SUCCESS:
            return {
                ...state,
                scheduledImageStoring: false,
                scheduledImageUrl: action.url,
                scheduledImageStoringError: null,
            };
        case UPLOAD_SCHEDULED_IMAGE_ERROR:
            console.error(action.error);
            return {
                ...state,
                scheduledImageStoring: false,
                scheduledImageUrl: '',
                scheduledImageStoringError: action.error.toString(),
            };
        case RESET_SCHEDULED_IMAGE:
            return {
                ...state,
                scheduledImageStoring: false,
                scheduledImageUrl: '',
                scheduledImageStoringError: '',
            };
        case REQUEST_UPLOAD_EXPIRY_IMAGE:
            return {
                ...state,
                expiryImageStoring: true,
                expiryImageUrl: '',
                expiryImageStoringError: null,
                uploadProgress: action.progress || 0,
            };
        case UPLOAD_EXPIRY_IMAGE_SUCCESS:
            return {
                ...state,
                expiryImageStoring: false,
                expiryImageUrl: action.url,
                expiryImageStoringError: null,
            };
        case UPLOAD_EXPIRY_IMAGE_ERROR:
            console.error(action.error);
            return {
                ...state,
                expiryImageStoring: false,
                expiryImageUrl: '',
                expiryImageStoringError: action.error.toString(),
            };
        case RESET_EXPIRY_IMAGE:
            return {
                ...state,
                expiryImageStoring: false,
                expiryImageUrl: '',
                expiryImageStoringError: '',
            };
        case RESET_DISCOUNT_RULES:
            return {
                ...initState,
            };
        default:
            return state;
    }
}
