import { TabBar, TabItem } from '@storaensods/seeds-react';
import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import {
    fetchDeviceStatus,
    fetchSelectedDeviceConfiguration,
    updateSelectedCabinetTagQualityMetrics,
    updateCabinetInfoManually,
    updateSelectedCabinetConfiguration,
    postSettingsCopyRequest,
    fetchCabinetRefillRule,
    updateRefillRule,
    createRefillRule,
    deleteRefillRule,
} from '../../actions/controlPanel';
import TagSettings from './tagSettings';
import ThemesAndLogos from './themesAndLogos';
import './settings.css';
import './toggle.css';
import { CabinetSettings } from './cabinetSettings';
import { AdminSettings } from './adminSettings';
import { fetchLocations } from '../../actions/cabinets';
import { showNotification } from '../toastNotification/toastNotification';
import { fetchProducts } from '../../actions/products';
import { SleepScheduling } from './sleepScheduling';

const Settings = props => {
    const {
        t,
        selectedCabinetSettings,
        fetchSelectedDeviceConfiguration,
        updateSelectedCabinetConfiguration,
        ads,
        user,
        fetchDeviceStatus,
        selectedEncodingDeviceCode,
        selectedCabinetDeviceCode,
        encodingDevices,
        cabinets,
        locations,
        postSettingsCopyRequest,
        updateSelectedCabinetTagQualityMetrics,
        fetchCabinetRefillRule,
    } = props;

    const [selectedTab, setSelectedTab] = useState('main:controls_tagQuality');

    const copySettingsRequest = async (cabinets, settingType, settingsArray) => {
        const response = await postSettingsCopyRequest(cabinets, settingType, settingsArray);

        if (response.ok) {
            showNotification(
                t('settings:settingsCopySuccessModalTitle'),
                t('settings:settingsCopySuccessModalContent', { cabinetsAmount: cabinets.length }),
                'success'
            );
        } else {
            showNotification(t('settings:settingsCopyErrorModalTitle'), response.message, 'error');
        }
    };

    useEffect(() => {
        if (selectedCabinetDeviceCode) {
            Promise.all(
                [fetchDeviceStatus(selectedCabinetDeviceCode), fetchSelectedDeviceConfiguration(), fetchCabinetRefillRule(selectedCabinetDeviceCode)].map(
                    async func => {
                        await func;
                        return Promise.resolve();
                    }
                )
            ).catch(error => {
                // Handle errors

                console.error('Error fetching data:', error);
                return Promise.reject();
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedCabinetDeviceCode]);

    return (
        <div className="container-fluid settings-container">
            {user && user.isAdmin && (
                <TabBar className="mb-3">
                    <TabItem onClick={() => setSelectedTab('main:controls_tagQuality')} isActive={selectedTab === 'main:controls_tagQuality'}>
                        {t('main:controls_tagQuality')}
                    </TabItem>
                    <TabItem onClick={() => setSelectedTab('settings:themeAndLogos')} isActive={selectedTab === 'settings:themeAndLogos'}>
                        {t('settings:themeAndLogos')}
                    </TabItem>
                    <TabItem onClick={() => setSelectedTab('settings:sleepScheduling')} isActive={selectedTab === 'settings:sleepScheduling'}>
                        {t('settings:sleepScheduling')}
                    </TabItem>
                    <TabItem onClick={() => setSelectedTab('main:cabinetSettings')} isActive={selectedTab === 'main:cabinetSettings'}>
                        {t('main:cabinetSettings')}
                    </TabItem>
                    <TabItem onClick={() => setSelectedTab('settings:adminSettings')} isActive={selectedTab === 'settings:adminSettings'}>
                        {t('settings:adminSettings')}
                    </TabItem>
                </TabBar>
            )}
            {user && user.isAdmin && (
                <div>
                    {selectedTab === 'main:controls_tagQuality' && (
                        <TagSettings
                            t={t}
                            selectedCabinetSettings={selectedCabinetSettings}
                            updateSelectedCabinetTagQualityMetrics={updateSelectedCabinetTagQualityMetrics}
                            cabinets={cabinets}
                            locations={locations}
                            title={selectedTab}
                            user={user}
                            submitSettingsCopy={copySettingsRequest}
                        />
                    )}
                    {selectedTab === 'settings:themeAndLogos' && (
                        <ThemesAndLogos
                            user={user}
                            ads={ads}
                            selectedCabinetSettings={selectedCabinetSettings}
                            t={t}
                            updateSelectedCabinetConfiguration={updateSelectedCabinetConfiguration}
                            title={selectedTab}
                            cabinets={cabinets}
                            locations={locations}
                            submitSettingsCopy={copySettingsRequest}
                        />
                    )}
                    {selectedTab === 'main:cabinetSettings' && (
                        <CabinetSettings
                            ads={ads}
                            t={t}
                            selectedCabinetSettings={selectedCabinetSettings}
                            updateSelectedCabinetConfiguration={updateSelectedCabinetConfiguration}
                            isRoot={user && user.isRoot}
                            fetchDeviceStatus={fetchDeviceStatus}
                            fetchSelectedDeviceConfiguration={fetchSelectedDeviceConfiguration}
                            selectedCabinetDeviceCode={selectedCabinetDeviceCode}
                            selectedEncodingDeviceCode={selectedEncodingDeviceCode}
                            encodingDevices={encodingDevices}
                            cabinets={cabinets}
                            locations={locations}
                            title={selectedTab}
                            user={user}
                            submitSettingsCopy={copySettingsRequest}
                        />
                    )}
                    {selectedTab === 'settings:adminSettings' && (
                        <AdminSettings
                            selectedCabinetSettings={selectedCabinetSettings}
                            updateSelectedCabinetConfiguration={updateSelectedCabinetConfiguration}
                            t={t}
                            isRoot={user && user.isRoot}
                            cabinets={cabinets}
                            locations={locations}
                            title={selectedTab}
                            user={user}
                            submitSettingsCopy={copySettingsRequest}
                        />
                    )}
                    {selectedTab === 'settings:sleepScheduling' && (
                        <div>
                            <SleepScheduling
                                selectedCabinetSettings={selectedCabinetSettings}
                                updateSelectedCabinetConfiguration={updateSelectedCabinetConfiguration}
                                t={t}
                                cabinets={cabinets}
                                locations={locations}
                                title={selectedTab}
                                user={user}
                                submitSettingsCopy={copySettingsRequest}
                            />
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default withRouter(
    connect(
        state => ({
            products: state.products,
            user: state.user,
            cabinets: state.cabinets,
            locations: state.cabinets.locations,
            encodingDevices: state.encodingDevices,
            inventory: state.inventory,
            ads: state.advertisements,
            controlPanel: state.controlPanel,
            selectedCabinetDeviceCode: state.cabinets.selectedCabinetDeviceCode,
            selectedEncodingDeviceCode: state.encodingDevices.selectedEncodingDeviceCode,
            selectedEncodingDeviceId: state.encodingDevices.selectedEncodingDeviceId,
            selectedCabinetDeviceId: state.cabinets.selectedCabinetDeviceId,
            selectedCabinetSettings: state.controlPanel.selectedCabinet.settings,
            refillRule: state.controlPanel.cabinetRefillRule,
        }),
        {
            fetchDeviceStatus,
            fetchSelectedDeviceConfiguration,
            updateSelectedCabinetTagQualityMetrics,
            updateCabinetInfoManually,
            updateSelectedCabinetConfiguration,
            fetchLocations,
            postSettingsCopyRequest,
            fetchProducts,
            fetchCabinetRefillRule,
            updateRefillRule,
            createRefillRule,
            deleteRefillRule,
        }
    )(translate('main')(Settings))
);
