import React, { Fragment, useState } from 'react';
import { Spinner, Divider, Dropdown, RadioButton } from '@storaensods/seeds-react';
import { Row, Col } from 'reactstrap';
import { DashboardDataCards } from './dashboardDataVisualization.js';
import { CubeBarChart, CubeBarChartCategorySide } from './cubeCharts.js';

const SalesDashboard = ({ dashboard, t, selectedCard, onCardClick, changeChartGranularity }) => {
    const [activeSelection, setActiveSelection] = useState(1);
    return (
        <Fragment>
            <Row className="pl-4 pr-4 pt-3">
                <Col xs="12" md="6" lg="4">
                    <div className="dashboard-chart-container">
                        <div className="text-center">
                            <h6>{t('sales')}</h6>
                        </div>
                        {dashboard.salesChartData.data.length === 0 ? (
                            <div className="dashboard-no-data">{t('noData')}</div>
                        ) : (
                            <CubeBarChart
                                data={dashboard.salesChartData.data}
                                yKey={dashboard.salesChartData.query.measures[0]}
                                zKey={dashboard.salesChartData.query.dimensions.length > 0 ? dashboard.salesChartData.query.dimensions[0] : null}
                                granularity={dashboard.salesChartData.query.timeDimensions[0].granularity}
                                startDate={
                                    dashboard.salesChartData.query.timeDimensions[0].dateRange
                                        ? dashboard.salesChartData.query.timeDimensions[0].dateRange[0]
                                        : null
                                }
                                endDate={
                                    dashboard.salesChartData.query.timeDimensions[0].dateRange
                                        ? dashboard.salesChartData.query.timeDimensions[0].dateRange[1]
                                        : null
                                }
                                chartHeight={400}
                                type="sales"
                                timeDimensions="Transactions.timestamp"
                            />
                        )}
                    </div>
                </Col>
                <Col xs="12" md="6" lg="4">
                    <div className="dashboard-chart-container">
                        <div className="text-center">
                            <h6>{t('transactions')}</h6>
                        </div>
                        {dashboard.transactionsChartData.data.length === 0 ? (
                            <div className="dashboard-no-data">{t('noData')}</div>
                        ) : (
                            <CubeBarChart
                                data={dashboard.transactionsChartData.data}
                                yKey={dashboard.transactionsChartData.query.measures[0]}
                                zKey={dashboard.transactionsChartData.query.dimensions.length > 0 ? dashboard.transactionsChartData.query.dimensions[0] : null}
                                granularity={dashboard.transactionsChartData.query.timeDimensions[0].granularity}
                                startDate={
                                    dashboard.transactionsChartData.query.timeDimensions[0].dateRange
                                        ? dashboard.transactionsChartData.query.timeDimensions[0].dateRange[0]
                                        : null
                                }
                                endDate={
                                    dashboard.transactionsChartData.query.timeDimensions[0].dateRange
                                        ? dashboard.transactionsChartData.query.timeDimensions[0].dateRange[1]
                                        : null
                                }
                                chartHeight={400}
                            />
                        )}
                    </div>
                </Col>
                <Col xs="12" md="6" lg="4">
                    <div className="dashboard-chart-container">
                        <div className="text-center">
                            <h6>{t('topProducts')}</h6>
                        </div>
                        {dashboard.productsSoldData.data.length === 0 ? (
                            <div className="dashboard-no-data">{t('noData')}</div>
                        ) : (
                            <Fragment>
                                <div style={{ display: 'flex' }}>
                                    <RadioButton
                                        id="1"
                                        onChange={() => setActiveSelection(1)}
                                        checked={activeSelection === 1}
                                        size="md"
                                        className="mr-3"
                                        label={t('salesCount')}
                                    />

                                    <RadioButton
                                        id="2"
                                        onChange={() => setActiveSelection(0)}
                                        checked={activeSelection === 0}
                                        size="md"
                                        label={t('orderSum')}
                                    />
                                </div>
                                <CubeBarChartCategorySide
                                    data={dashboard.productsSoldData.data.slice(0, 5)}
                                    xKey={dashboard.productsSoldData.query.dimensions[0]}
                                    yKey={dashboard.productsSoldData.query.measures[activeSelection]}
                                    zKey={dashboard.productsSoldData.query.dimensions.length > 0 ? dashboard.productsSoldData.query.dimensions[0] : null}
                                    chartHeight={200}
                                />
                            </Fragment>
                        )}
                    </div>
                </Col>
            </Row>
            <Divider />
            <Row>
                <Col xs="12" md="4" className="mb-4 mr-0 pr-0">
                    <DashboardDataCards t={t} dashboard={dashboard} selectedCard={selectedCard} onCardClick={onCardClick} />
                </Col>
                <Col xs="12" md="8" className="ml-0 pl-0">
                    {dashboard.isFetchingChart || dashboard.isErrorChart ? (
                        <div>
                            {dashboard.isFetchingChart ? (
                                <div className="d-flex justify-content-center">
                                    <Spinner />
                                </div>
                            ) : (
                                <div>{t('fetchingError')}</div>
                            )}
                        </div>
                    ) : (
                        <div className="dashboard-chart-container mr-4 pl-4">
                            <div className="text-center">
                                <h6>{t(selectedCard)}</h6>
                            </div>
                            <div className="small mb-3 mr-2 d-inline-block">{t('timeGrouping')} </div>
                            <div className="d-inline-block">
                                <Dropdown
                                    className="analytics-dropdown d-inline-block"
                                    value={t(dashboard.chartData.query.timeDimensions[0].granularity)}
                                    onSelect={({ value }) => changeChartGranularity(value)}
                                    options={[
                                        {
                                            label: t('year'),
                                            value: 'year',
                                        },
                                        {
                                            label: t('month'),
                                            value: 'month',
                                        },
                                        {
                                            label: t('week'),
                                            value: 'week',
                                        },
                                        {
                                            label: t('day'),
                                            value: 'day',
                                        },
                                        {
                                            label: t('hour'),
                                            value: 'hour',
                                        },
                                    ]}
                                />
                            </div>
                            <div className="d-flex justify-content-center">
                                {dashboard.chartData.data.length === 0 ? (
                                    <div className="dashboard-no-data">{t('noData')}</div>
                                ) : (
                                    <CubeBarChart
                                        data={dashboard.chartData.data}
                                        yKey={dashboard.chartData.query.measures[0]}
                                        zKey={dashboard.chartData.query.dimensions.length > 0 ? dashboard.chartData.query.dimensions[0] : null}
                                        granularity={dashboard.chartData.query.timeDimensions[0].granularity}
                                        startDate={
                                            dashboard.chartData.query.timeDimensions[0].dateRange
                                                ? dashboard.chartData.query.timeDimensions[0].dateRange[0]
                                                : null
                                        }
                                        endDate={
                                            dashboard.chartData.query.timeDimensions[0].dateRange
                                                ? dashboard.chartData.query.timeDimensions[0].dateRange[1]
                                                : null
                                        }
                                        type={selectedCard}
                                        timeDimensions={
                                            selectedCard === 'unpaidSales' || selectedCard === 'unpaidTransactions'
                                                ? 'TransactionDetails.timestamp'
                                                : 'Transactions.timestamp'
                                        }
                                    />
                                )}
                            </div>
                        </div>
                    )}
                </Col>
            </Row>
        </Fragment>
    );
};

export default SalesDashboard;
