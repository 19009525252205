import React, { Component } from 'react';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';
import TransactionDetail from './transactionDetail.js';
import FetchingAlert from '../../fetching/fetchingAlert.js';

class TransactionReport extends Component {
    constructor(props) {
        super(props);
        this.state = {
            refundFormOpened: false,
        };
    }

    /**
     * ???
     * @param {???} tabView
     */
    selectSalesReportTab = tabView => {
        this.setState({
            salesReportView: tabView,
        });
    };

    /**
     * ???
     * @param {???} tabView
     */
    selectCancelSalesReportTab = tabView => {
        this.setState({
            cancelSaleReportView: tabView,
        });
    };

    /**
     * Gets name of cabinet with given id. If no cabinet matched with name then id is returned.
     * @param {string} deviceCode Cabinet device code
     * @param array cabinets Array of cabinet objects
     */
    getCabinetName = (deviceCode, cabinets) => {
        if (cabinets) {
            const cabinet = cabinets.filter(cabinet => cabinet.deviceCode === deviceCode)[0];
            return cabinet ? cabinet.name : deviceCode;
        }
        return deviceCode;
    };

    render() {
        const { t, transactions, allRowsExpanded, setAllRowsExpanded } = this.props;

        if (transactions.isFetching || transactions.error || transactions.data.length === 0) {
            setAllRowsExpanded(false);
            return (
                <div style={{ paddingTop: '15px' }}>
                    <FetchingAlert
                        className="mt-3"
                        fetchingMessage={t('fetching')}
                        noDataMessage={t('noData')}
                        errorMessage={t('fetchingError')}
                        isError={transactions.error}
                        noData={transactions.data.length === 0 && !transactions.isFetching}
                    />
                </div>
            );
        }

        return (
            <React.Fragment>
                <div className="row">
                    <div className="col-md-12">
                        <TransactionDetail t={t} allRowsExpanded={allRowsExpanded} transactions={transactions.data} tableType="sales" showUnpaid />
                    </div>
                </div>
                <div className="row" />
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => ({
    cabinetDeviceCode: state.cabinets.selectedCabinetDeviceCode,
    cabinets: state.cabinets.cabinets,
});

export default connect(mapStateToProps, null)(translate('reports')(TransactionReport));
