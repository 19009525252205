import React, { Component } from 'react';
import { ListGroup, ListGroupItem, Row, Col } from 'reactstrap';
import 'react-table/react-table.css';
import { at, head, sumBy, filter, find } from 'lodash';
import SlidingPane from 'react-sliding-pane';
import { Icon, Button, Checkbox, Popover, TextArea } from '@storaensods/seeds-react';
import moment from 'moment';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { claimRefund } from '../../../actions/report';

/**
 * Represents the transaction items.
 */
class TransactionItems extends Component {
    constructor(props) {
        super(props);
        this.state = {
            refundFormOpened: false,
            refundReason: '',
        };
    }

    /**
     * Opens up the form for issuing the refund for the order.
     */
    handleIssueRefund() {
        // opend up the slider with the refund form
        this.setState({
            refundFormOpened: true,
            refunds: {},
        });
    }

    /**
     * Executes the action to claim the refund.
     */
    handleClaimRefund(orderId, refundItems) {
        const { dispatch, t, deviceCode } = this.props;
        const { refundReason } = this.state;

        // prepare refund items payload to the API call
        const preparedItems = refundItems.map(item => ({
            barcode: item.barcode,
            currency: item.currency,
            name: item.name,
            price: item.price,
            refundAmount: item.refundAmount,
        }));
        dispatch(claimRefund(deviceCode, orderId, preparedItems, refundReason, t));
    }

    /**
     * Handles the reason for refund edit.
     * @param {*} event
     */
    handleRefundReasonChange(event) {
        this.setState({
            refundReason: event.target.value,
        });
    }

    /**
     * Renders the refund form for the order.
     * @param {*} order Order
     * @param {boolean} unpaid if in unpaid table
     */
    getRefundForm(order, unpaid = false) {
        const { t, refundClaimLoading } = this.props;
        const { refundReason } = this.state;

        if (!Array.isArray(order.purchases.purchase)) {
            return null;
        }

        const checkedItems = order.purchases.purchase.filter(item => item.isChecked);
        return (
            <div>
                <h4>
                    {order.orderId} - {order.transactionDetails && order.transactionDetails.secondaryOrderId ? order.transactionDetails.secondaryOrderId : null}
                </h4>
                <p>{moment(order.timestamp).format('lll')}</p>
                {this.getListOfItems(
                    order.orderId,
                    {
                        purchases: order.purchases.purchase,
                        refunds: order.purchases.refundItems,
                        paymentMethod: order.paymentMethod,
                        status: order.status,
                        paidAmount: order.paidAmount || 0,
                        totalPrice: order.totalPrice,
                    },
                    false,
                    true,
                    true,
                    unpaid
                )}

                {/* reason */}
                <div>
                    <TextArea label={t('refundReason')} value={this.state.refundReason} onChange={this.handleRefundReasonChange.bind(this)} />
                </div>
                {/* / reason */}
                <hr className="mt-4 mb-4" />
                <div>
                    <Popover component={<Button disabled={!checkedItems.length || !refundReason}>{t('issueRefundButton')}</Button>}>
                        <Button
                            style={{ minWidth: 200 }}
                            onClick={() => this.handleClaimRefund(order.orderId, checkedItems)}
                            isLoading={refundClaimLoading > 0}
                        >
                            {!refundClaimLoading && t('confirmIssueRefundButton')}
                        </Button>
                    </Popover>
                </div>
            </div>
        );
    }

    /**
     * Handles the adding or removing the items to be refund
     * @param {*} shift
     */
    handleRefundItemCountChange(item, shift) {
        // shift item to the proviced items
        // ANTIPATTERN HERE: never change value, always create a new one
        // but sometimes it is OK, like here as we create new
        // properties at the transaction item object.
        // but this is not React way of doing things.

        // edge case if refund amount is 0, just add one.
        if (item.refundAmount === 0) {
            item.refundAmount = Math.max(0, item.refundAmount + shift);
            // force the component re-render
            this.setState({ refundFormOpened: true });
            return;
        }

        if (shift > 0) {
            item.refundAmount = Math.min(item.count, item.refundAmount + shift);
        } else {
            item.refundAmount = Math.max(0, Math.min(item.count, item.refundAmount + shift));
        }

        // force the component re-render
        this.setState({ refundFormOpened: true });
    }

    /**
     * Toggles the item to be refunded or not.
     * @param {*} item Item toggled to be selected.
     * @param {*} isChecked Is item checked for refund.
     */
    handleRefundProductCheck(item, event) {
        item.isChecked = event.target.checked;
        // force the component re-render
        this.setState({ refundFormOpened: true });
    }

    /**
     * Checks if this item was already refund and return true if so. False if item was not refund.
     * @param {*} item Item to check
     */
    checkIfItemWasRefund(item) {
        // get all refund records in the current order
        const { row } = this.props;
        const refundItems = head(at(row, 'original.purchases.refundItems'));

        if (!refundItems) {
            return false;
        }
        const matchingItem = find(refundItems, refundItem => refundItem.barcode === item.barcode);
        return matchingItem ? true : false; // if item was already refund, return it
    }

    getPaidElement(purchases, totalPrice, itemsData, unpaid = false, t) {
        const currency = head(purchases).currency || '';
        if (itemsData.status !== 'PARTIAL') {
            return <h5>{`${t('total')}: ${totalPrice.toFixed(2)} ${currency}`}</h5>;
        } else {
            const amountPaid = itemsData.paidAmount;
            if (!unpaid) {
                return (
                    <div>
                        <h5>{`${t('partiallyPaid')}: ${amountPaid.toFixed(2)} ${currency}`}</h5>
                        <h5>{`${t('total')}: ${totalPrice.toFixed(2)} ${currency}`}</h5>
                    </div>
                );
            } else {
                const amountOwed = totalPrice - amountPaid;
                return (
                    <div>
                        <h5>{`${t('amountOwed')}: ${amountOwed.toFixed(2)} ${currency}`}</h5>
                        <h5>{`${t('total')}: ${totalPrice.toFixed(2)} ${currency}`}</h5>
                    </div>
                );
            }
        }
    }

    PDFLink(orderId) {
        return `${process.env.REACT_APP_CONSUMER_APP_URL}?orderId=${orderId}&mode=4`;
    }

    /**
     * Renders the list of purchased items
     * @param {string} orderId
     * @param {*} purchases
     * @param {*} showButtons
     * @param {*} canSelectItem
     * @param {*} canEditQuantity
     
     */
    getListOfItems(
        orderId,
        itemsData,
        showButtons = false,
        canSelectItem = false,
        canEditQuantity = false,
        unpaid = false,
        customerId = null,
        transactionRef = null
    ) {
        const { row, t, tableType } = this.props;
        const { purchases, refunds, paymentMethod, totalPrice, uncharged } = itemsData;
        // payment methods that does support refund. !!! Only mobilepay support refund for now
        const refundSupportedPaymentMethods = ['MobilePay'];
        // check if we can make a refund for that order
        const refundPossible = refundSupportedPaymentMethods.includes(paymentMethod);

        // for each purchase set refundAmount to 0 by default
        if (!Array.isArray(purchases)) {
            return null;
        }
        purchases.forEach(item => {
            // ANTIPATTERN! never change properties, this is not React style
            // only here it is OK as we add new property
            item.refundAmount = item.refundAmount || 0;
        });

        return (
            <div className="w-100 mt-1 mb-4">
                <ListGroup className="list-group-flush">
                    {purchases.length > 0 &&
                        purchases.map((item, index) => (
                            <ListGroupItem key={item.barcode + index}>
                                <Row
                                    className={classNames('d-flex align-items-center', {
                                        'refund-form-product selected': item.isChecked,
                                    })}
                                >
                                    <Col xs="2">
                                        {!canEditQuantity && <div>{item.count}</div>}
                                        {canEditQuantity && (
                                            <div className="d-flex align-items-center">
                                                <button
                                                    disabled={this.checkIfItemWasRefund(item)}
                                                    className={classNames('btn btn-link p-1 button-refund-count', {
                                                        'btn-disabled': this.checkIfItemWasRefund(item),
                                                    })}
                                                    onClick={() => {
                                                        this.handleRefundItemCountChange(item, -1);
                                                    }}
                                                >
                                                    <i className="fas fa-minus fa-xs" />
                                                </button>
                                                <span>{item.refundAmount}</span>
                                                <button
                                                    disabled={this.checkIfItemWasRefund(item)}
                                                    className={classNames('btn btn-link p-1 button-refund-count', {
                                                        'btn-disabled': this.checkIfItemWasRefund(item),
                                                    })}
                                                    onClick={() => {
                                                        this.handleRefundItemCountChange(item, 1);
                                                    }}
                                                >
                                                    <i className="fas fa-plus fa-xs" />
                                                </button>
                                            </div>
                                        )}
                                    </Col>
                                    <Col xs="4">
                                        <div className="media d-flex align-items-center">
                                            {item.image && <img src={item.image} className="mr-4 transaction-details-image" alt={item.name} />}
                                            <div className="media-body">
                                                <span className="mt-0">{item.name}</span>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col xs="3">
                                        <div>{item.barcode}</div>
                                    </Col>
                                    <Col xs="3">
                                        <div>
                                            {(+item.price).toFixed(2)}&nbsp;{item.currency}
                                            {canSelectItem && (
                                                <span className="ml-3">
                                                    {!this.checkIfItemWasRefund(item) ? (
                                                        <Checkbox
                                                            checked={item.isChecked}
                                                            id={item.barcode}
                                                            onChange={value => this.handleRefundProductCheck(item, value)}
                                                        />
                                                    ) : (
                                                        <mark>
                                                            <small>{t('alreadyRefund')}</small>
                                                        </mark>
                                                    )}
                                                </span>
                                            )}
                                        </div>
                                    </Col>
                                </Row>
                            </ListGroupItem>
                        ))}
                    {uncharged &&
                        uncharged.length > 0 &&
                        uncharged.map((item, index) => (
                            <ListGroupItem key={item.barcode + index} className={'uncharged'}>
                                <Row
                                    className={classNames('d-flex align-items-center', {
                                        'refund-form-product selected': false,
                                    })}
                                >
                                    <Col xs="2"></Col>
                                    <Col xs="4">
                                        <div className="media d-flex align-items-center">
                                            <div className="media-body">
                                                <span className="mt-0">
                                                    {item.epc}
                                                    {' ('}
                                                    {item.reason}
                                                    {')'}
                                                </span>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col xs="3">
                                        <div>{item.barcode}</div>
                                    </Col>
                                    <Col xs="3">
                                        <div>{'0.00'}</div>
                                    </Col>
                                </Row>
                            </ListGroupItem>
                        ))}
                </ListGroup>

                {/* Total price and refund form */}
                {Array.isArray(purchases) && purchases.length > 0 && (
                    <div className="mt-3 mr-2 d-flex flex-row-reverse align-items-center" style={{ justifyContent: 'space-between' }}>
                        {/* Refund button */}
                        {showButtons && refundPossible && (
                            <Button
                                disabled={row.original.status !== 'DONE' || !refundPossible}
                                type="attention"
                                onClick={() => this.handleIssueRefund(row.original)}
                            >
                                {t('issueRefundButton')}
                            </Button>
                        )}
                        {/* /Refund button */}

                        {/* Message for refund impossible by credit card, if paymentMethod is null, it means own_webhook, we do not show text in this case */}
                        {/* Disabling this as there's no refund available anymore for none of the payment options! */}
                        {/* showButtons && !refundPossible && paymentMethod && paymentMethod != 'own_webhook' && (
                            <Alert className="mr-2 mb-0 w-50" color="secondary">
                                <i className="fas fa-exclamation-triangle" />
                                &nbsp;
                                {t('Refund impossible')}
                            </Alert>
                        )*/}
                        {/* / Message for refund impossible by credit card */}

                        <div className="text-left mr-4 ml-4">
                            {this.getPaidElement(purchases, totalPrice, itemsData, unpaid, t)}
                            {!canSelectItem && totalPrice > 0 && tableType === 'sales' && (
                                <a className="blue-link" href={this.PDFLink(orderId)}>
                                    {t('receiptLinkText')}
                                </a>
                            )}
                            {canSelectItem && (
                                <mark className="text-lead">
                                    {t('refundTotal')}:{' '}
                                    {sumBy(
                                        filter(purchases, p => p.isChecked),
                                        item => item.price * item.refundAmount
                                    ).toFixed(2)}
                                    &nbsp;
                                    {head(purchases).currency || ''}
                                </mark>
                            )}
                        </div>
                        {(customerId || transactionRef) && (
                            <div className="text-left mr-4 ml-4">
                                {customerId && (
                                    <div>
                                        <Icon>person</Icon> {customerId}
                                    </div>
                                )}
                                {transactionRef && (
                                    <div>
                                        <Icon>confirmation_number</Icon> {transactionRef}
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                )}
                {/* / Total price and refund form */}

                {/* Refunds */}
                {refunds && (
                    <div className="text-danger mt-4 mb-4 text-monospace refunded-items">
                        <h5 className="text-danger">{t('refundFormTitle')}</h5>
                        <ListGroup className="list-group-flush">
                            {refunds.map(item => (
                                <ListGroupItem key={item.barcode}>
                                    <Row
                                        className={classNames('d-flex align-items-center', {
                                            'refund-form-product selected': item.isChecked,
                                        })}
                                    >
                                        <Col xs="2">
                                            <div>{item.refundAmount}</div>
                                        </Col>
                                        <Col xs="4">
                                            <span className="mt-0">{item.name}</span>
                                        </Col>
                                        <Col xs="3">
                                            <div>{item.barcode}</div>
                                        </Col>
                                        <Col xs="3">
                                            <div>
                                                -{(+item.price).toFixed(2)}&nbsp;{item.currency}
                                            </div>
                                        </Col>
                                    </Row>
                                </ListGroupItem>
                            ))}
                        </ListGroup>
                        <div className="text-right mt-2">
                            <h5 className="text-danger">
                                {t('total')}: -{sumBy(refunds, item => item.price * item.refundAmount).toFixed(2)}
                                &nbsp;
                                {head(refunds).currency}
                            </h5>
                        </div>
                    </div>
                )}
                {/* / Refund */}
            </div>
        );
    }

    render() {
        const { row, t, showButtons = true, unpaid = false } = this.props;

        const purchases = head(at(row, 'original.purchases.purchase'));
        const refunds = head(at(row, 'original.purchases.refundItems'));
        const paymentMethod = head(at(row, 'original.paymentMethod'));
        const status = head(at(row, 'original.status'));
        const paidAmount = head(at(row, 'original.paidAmount')) || 0;
        const totalPrice = head(at(row, 'original.totalPrice')) || 0;
        const orderId = head(at(row, 'original.orderId')) || null;
        const uncharged = head(at(row, 'original.unchargedTags')) || null;
        const order = row.original;

        return (
            <div className="w-100 mt-1 mb-4">
                {this.getListOfItems(
                    orderId,
                    { purchases, totalPrice, refunds, paymentMethod, status, paidAmount, uncharged },
                    showButtons,
                    false,
                    false,
                    unpaid,
                    order.customerId,
                    order.transactionDetails && order.transactionDetails.transactionReference
                )}

                {/* Refund form */}
                <SlidingPane
                    width="50%"
                    isOpen={this.state.refundFormOpened}
                    onRequestClose={() => {
                        this.setState({ refundFormOpened: false });
                    }}
                    title={t('refundFormTitle')}
                >
                    {this.getRefundForm(order, unpaid)}
                </SlidingPane>
                {/* / Refund form */}
            </div>
        );
    }
}

export default connect(state => ({
    refundClaimLoading: state.loadingBar.refundClaim,
    deviceCode: state.cabinets.selectedCabinetDeviceCode,
    organizationSettings: state.organizationsSettings?.organizationSettings,
}))(TransactionItems);
