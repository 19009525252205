import React from 'react';

import { Card, Icon } from '@storaensods/seeds-react';

import './suppliers.css';

const SupplierCards = props => {
    return props.data.map(supplier => {
        return (
            <Card
                key={supplier.id}
                actions={
                    props.isAdmin && [
                        {
                            label: <Icon>edit</Icon>,
                            onClick: () => props.editAction(supplier),
                        },
                        {
                            label: <Icon>remove_circle_outline</Icon>,
                            onClick: () => props.deleteAction(supplier),
                            type: 'negative',
                        },
                    ]
                }
                className="SupplierCard"
            >
                <h5 className="SupplierCardHeader card-no-overflow">{supplier.name}</h5>
                <p className="card-no-overflow">{supplier.description}</p>
            </Card>
        );
    });
};

export default SupplierCards;
