import React, { Component } from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { Icon, Input } from '@storaensods/seeds-react';
import { debounce } from 'lodash';

import { cabinetSearchQueryUpdated } from '../../actions/cabinets';

/**
 * Search bar for the cabinet list
 */
class SearchBar extends Component {
    constructor(props) {
        super(props);
        const { cabinetSearchQueryUpdated } = this.props;
        this.search = debounce(query => {
            cabinetSearchQueryUpdated(query);
        }, 250);
    }

    handleSearchChange(event) {
        const query = event.target.value;
        this.search(query);
    }

    componentDidMount() {
        if (this.props.deviceSearchQuery && this.props.deviceSearchQuery !== '') {
            this.props.cabinetSearchQueryUpdated('');
        }
    }

    render() {
        const { t } = this.props;
        return (
            <div className="d-flex align-items-center mb-1 device-search">
                <Input placeholder={t('softwareVersions:searchDevices')} size="sm" onChange={this.handleSearchChange.bind(this)} className="mr-2" />
                <Icon className="text-muted">search</Icon>
            </div>
        );
    }
}

export default connect(
    state => ({
        deviceSearchQuery: state.cabinets.searchQuery,
    }),
    {
        cabinetSearchQueryUpdated,
    }
)(translate('main')(SearchBar));
