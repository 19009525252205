import React from 'react';
import { ModalBody, Modal, ModalFooter, Spinner } from 'reactstrap';

const ProductSaving = props => {
    const { requesting, errors, finished, savingProduct, closeModal, t } = props;

    return (
        <Modal isOpen={savingProduct} centered={true} size="lg" className="products__saving">
            <ModalBody className="products__saving-body">
                {requesting && (
                    <div className="products__saving-info">
                        <Spinner className="products__saving-spinner" />
                    </div>
                )}
                {finished && errors && (
                    <div className="products__saving-info products__saving-info--error">
                        <i className="fas fa-exclamation-circle"></i>
                        <span>{t('errorSaving')}. </span>
                    </div>
                )}
                {finished && !errors && (
                    <div className="products__saving-info">
                        <i className="fas fa-check-circle"></i>
                        <span>{t('successSaving')}. </span>
                    </div>
                )}
            </ModalBody>
            {finished && (
                <ModalFooter>
                    <div className="products__cta-button products__cta-button--neutral" onClick={closeModal}>
                        {t('close')}
                    </div>
                </ModalFooter>
            )}
        </Modal>
    );
};

export default ProductSaving;
