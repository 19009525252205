import React from 'react';
import { Row, Col } from 'reactstrap';
import icons from '../../navigation/styles/icons.svg';

function FeedbacksAndWishes({ data }) {
    const wishAndRatingRow = (feedback, key) =>
        feedback && (
            <Row key={key}>
                <Col className="p-3 consumer-response-wish" xs={9} sm={9} md={9} lg={9} xl={10}>
                    <svg className="wish-icon">
                        <use xlinkHref={`${icons}#icon-bubble`}></use>
                    </svg>
                    <span>{checkSentenceLength(feedback)}</span>
                </Col>
            </Row>
        );
    const checkSentenceLength = sentence => {
        const wordsArray = sentence ? sentence.split(' ') : null;
        if (!wordsArray || wordsArray.length <= 50) return sentence;
        let i = 0;
        let newSentence = '';
        do {
            newSentence = newSentence + ' ' + wordsArray[i];
            i++;
        } while (i < 50);

        return newSentence + '...';
    };
    return (
        <div className="consumer-response-feedback-scroll-container">
            {data && data.data ? data.data.map(d => wishAndRatingRow(d['ConsumerFeedback.wish'], d['ConsumerFeedback.created'])) : null}
        </div>
    );
}

export default FeedbacksAndWishes;
