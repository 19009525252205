import React from 'react';
import { RadioButton } from '@storaensods/seeds-react';

const RadioButtonGroup = ({ options, label, name, selectionChange, current, t }) => {
    return (
        <div className="radio-group">
            <label className="radio-group__label">{label}</label>
            <div className="radio-group__options">
                {options.map(option => {
                    return (
                        <RadioButton
                            id={option.label}
                            key={option.label}
                            label={t(option.label)}
                            name={name}
                            checked={option.value === current}
                            onChange={() => {
                                selectionChange(option.value);
                            }}
                        />
                    );
                })}
            </div>
        </div>
    );
};

export default RadioButtonGroup;
