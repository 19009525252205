import React from 'react';

import indicator from './loading.svg';
import './loading.css';

/**
 * Loading indicator.
 */
export default function Loading({ message, height }) {
    return (
        <div className="loading">
            <div
                className="loading-img"
                style={{
                    height: height || 50,
                    backgroundImage: `url(${indicator})`
                }}
            />
            <span>{message || ''}</span>
        </div>
    );
}
