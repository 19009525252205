const initState = {};
export default function inventory(state = initState, action) {
    switch (action.type) {
        case 'REQUEST_CABINET_INVENTORY':
            return {
                ...state,
                [action.code]: {
                    isFetching: true,
                    fetched: false,
                    isError: false,
                },
            };
        case 'RECEIVE_CABINET_INVENTORY':
            return {
                ...state,
                [action.code]: {
                    isFetching: false,
                    fetched: true,
                    isError: false,
                    inventory: action.inventory,
                    readTime: action.readTime,
                    lastUpdated: action.receivedAt,
                },
            };
        case 'RECEIVE_CABINET_INVENTORY_ERROR':
            console.error(action.error);
            return {
                ...state,
                [action.code]: {
                    isFetching: false,
                    fetched: true,
                    isError: true,
                    error: action.error.toString(),
                },
            };
        case 'RESET_CABINET_INVENTORY':
            return {
                ...initState,
            };
        default:
            return state;
    }
}
