import React, { useState, useEffect } from 'react';
import CabinetLists from '../segments/cabinetLists';
import { InfoBanner } from '../../commons/utils';
import { Input } from 'reactstrap';
import EmailLists from '../segments/emailLists';
import { Button } from '../../commons/utils';
import TimePicker from 'react-times';
import { createAutomation, updateAutomation, fetchAutomation, deleteAutomation } from '../../../actions/automations';
import { connect } from 'react-redux';
import AutomationSavedModal from '../modals/AutomationSavedModal';

/**
 *
 * @param {object} props
 * @param {object} props.cabinetsByLocations
 * @param {function} props.t
 * @param {function} props.cancelPage
 * @param {function} props.createAutomation
 * @param {function} props.fetchAutomation
 * @param {object} props.automation
 * @param {object} props.organization
 */
const NotificationAutomation = props => {
    const { cabinetsByLocations, t, cancelPage, createAutomation, updateAutomation, fetchAutomation, deleteAutomation } = props;

    const [selectedCabinets, setSelelctedCabinets] = useState({});
    const [noSaleHours, setNoSaleHour] = useState(5);
    const [emailAddresses, setEmailAddresses] = useState(['']);
    const [timeRange, setTimeRange] = useState({ start: '06:00', end: '22:00' });
    const [automationId, setAutomationId] = useState(null);
    const [isAutomationExist, setIsAutomationExist] = useState(false);
    const [automationSaveModalOpen, setAutomationSaveModalOpen] = useState(false);
    const [automationSaveData, setAutomationSaveData] = useState({});
    const [isSavedButtonActive, setIsSavedButtonActive] = useState(false);
    const [message, setMessage] = useState('');

    useEffect(() => {
        const initialization = [fetchAutomation('noSales')];
        Promise.all(
            initialization.map(async func => {
                const automationResponse = await func;
                if (automationResponse.error) {
                    console.error(automationResponse.error);
                    return Promise.reject(automationResponse.error);
                }
                if (automationResponse.automation.length === 0) {
                    return Promise.resolve();
                }
                setIsAutomationExist(true);
                setTimeRange({
                    start: `${automationResponse.automation[0].metadata.startingHour}:00`,
                    end: `${automationResponse.automation[0].metadata.endingHour}:00`,
                });
                onHourValueChange(null, automationResponse.automation[0].metadata.duration);
                setEmailAddresses(automationResponse.automation[0].toList);
                setSelelctedCabinets(
                    Object.entries(cabinetsByLocations).reduce((acc, [location, cabinets]) => {
                        const selectedCabinets = automationResponse.automation[0].deviceCodeList
                            .map(deviceCode => {
                                return cabinets.find(cabinet => cabinet.deviceCode === deviceCode);
                            })
                            .filter(cabinet => cabinet);
                        acc[location] = selectedCabinets;
                        return acc;
                    }, {})
                );
                setAutomationId(automationResponse.automation[0]._id);
                return Promise.resolve();
            })
        )
            .then(() => {})
            .catch(error => {
                // Handle errors
                console.error('Error fetching data:', error);
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onHourValueChange = (type, inputValue) => {
        let hour = noSaleHours;
        if (type) {
            hour = type === 'plus' ? noSaleHours + 1 : noSaleHours - 1;
        } else {
            hour = inputValue;
        }

        // min allowed hour is 2 and max is 23
        if (hour < 2 || hour > 23) return;

        setNoSaleHour(hour);
        setIsSavedButtonActive(true);
    };

    /**
     *
     * @param {*} value
     * @param {*} position
     */
    const emailAddressChange = (value, position) => {
        const allEmailAdresses = [];
        emailAddresses.forEach((email, index) => {
            if (index === position) {
                allEmailAdresses.push(value);
                return;
            }
            allEmailAdresses.push(email);
        });

        setEmailAddresses(allEmailAdresses);
        setIsSavedButtonActive(true);
    };

    /**
     *
     * @param {*} position index of the email address
     */
    const removeEmail = position => {
        const allEmailAdresses = [];
        emailAddresses.forEach((email, index) => {
            if (index !== position) {
                allEmailAdresses.push(email);
            }
        });
        setEmailAddresses(allEmailAdresses);
    };

    const onTimeRangeChange = ({ hour, minute }, type) => {
        const timeString = `${hour}:${minute}`;
        if (type === 'start') {
            setTimeRange({ start: timeString, end: timeRange.end });
        } else {
            setTimeRange({ start: timeRange.start, end: timeString });
        }
        setIsSavedButtonActive(true);
    };

    const onSaveClicked = async () => {
        setAutomationSaveData({
            saving: true,
        });
        setAutomationSaveModalOpen(true);
        const deviceCodes = Object.entries(selectedCabinets)
            .map(([location, cabinets]) => {
                return cabinets.map(cabinet => cabinet.deviceCode);
            })
            .flat();
        const occurencesCron = `0 0 * * * *`;
        const emailList = emailAddresses.filter(email => email !== '');
        const timeRangeStart = timeRange.start;
        const timeRangeEnd = timeRange.end;
        const sendMail = emailList.length > 0 ? true : false;

        const automationData = {
            name: 'No Sale Notification',
            type: 'noSales',
            occurences: occurencesCron,
            startTime: null,
            endTime: null,
            deviceCodeList: deviceCodes,
            status: 'active',
            metadata: {
                startingHour: parseInt(timeRangeStart),
                endingHour: parseInt(timeRangeEnd),
                duration: noSaleHours,
            },
            sendMail: sendMail,
            toList: emailList,
        };
        let result;
        if (!isAutomationExist) {
            result = await createAutomation(automationData);
        } else {
            result = await updateAutomation(automationId, automationData);
        }
        if (result.error) {
            setAutomationSaveData({ saving: false, error: true });
        } else {
            setAutomationId(result.automation._id);
            setIsAutomationExist(true);
            setAutomationSaveData({ saving: false, isSaved: true });
            setMessage(t('automations:automationSaved'));
        }
    };

    const onDeleteClicked = async () => {
        setAutomationSaveData({
            saving: true,
        });
        setAutomationSaveModalOpen(true);
        const result = await deleteAutomation(automationId);
        if (result.error) {
            setAutomationSaveData({ saving: false, error: true });
            setMessage(t('automations:automationDeleted'));
        } else {
            setIsAutomationExist(false);
            setTimeRange({ start: '06:00', end: '22:00' });
            onHourValueChange(null, 5);
            setEmailAddresses([]);
            setSelelctedCabinets({});
            setAutomationId('');
            setAutomationSaveData({ saving: false, isSaved: true });
            setMessage(t('automations:automationDeleted'));
        }
    };

    const handleCabinetSelection = cabinets => {
        setSelelctedCabinets(cabinets);
        if (Object.keys(cabinets).length > 0 &&
            Object.keys(cabinets).some(location => cabinets[location].length > 0)) 
        {
            setIsSavedButtonActive(true);
        }
        else {
            setIsSavedButtonActive(false);
        }
    };

    return (
        <div className="automations__container">
            <AutomationSavedModal
                isOpen={automationSaveModalOpen}
                data={automationSaveData}
                closeModal={() => {
                    setAutomationSaveModalOpen(false);
                }}
                successMessage={message}
                t={t}
            />
            <CabinetLists
                cabinetsByLocations={cabinetsByLocations}
                selectedCabinets={selectedCabinets}
                cabinetSelections={cabinets => handleCabinetSelection(cabinets)}
                t={t}
            />
            <div className="automations__contents">
                <InfoBanner message={t('automations:noSalesNotificationTitle')} />
                <div className="automations__contents-selects">
                    <p>{t('automations:noSaleTitle')}</p>
                    <label>({t('automations:minMaxTime')})</label>
                    <div className="automations__contents-hour">
                        <span onClick={() => onHourValueChange('minus')}>-</span>
                        <Input type="number" value={noSaleHours} onChange={e => onHourValueChange(null, e.target.value)} />
                        <span onClick={() => onHourValueChange('plus')}>+</span>
                    </div>

                    <p>{t('automations:timeRangeTitle')}</p>
                    <div className="automations__timerange">
                        <div className="automations__timerange-start">
                            <label>{t('automations:startTime')}</label>
                            <TimePicker
                                theme="classic"
                                timeMode="24"
                                timeConfig={{
                                    step: 1,
                                    unit: 'hour',
                                }}
                                time={timeRange.start}
                                onTimeChange={time => onTimeRangeChange(time, 'start')}
                            />
                        </div>
                        <div className="automations__timerange-end">
                            <label>{t('automations:endTime')}</label>
                            <TimePicker
                                theme="classic"
                                timeMode="24"
                                timeConfig={{
                                    step: 1,
                                    unit: 'hour',
                                }}
                                time={timeRange.end}
                                onTimeChange={time => onTimeRangeChange(time, 'end')}
                            />
                        </div>
                    </div>
                </div>
                <EmailLists
                    emailAddresses={emailAddresses}
                    emailAddressChange={(email, index) => emailAddressChange(email, index)}
                    removeEmail={index => removeEmail(index)}
                    addNewEmail={() =>
                        setEmailAddresses(prevState => {
                            return [...prevState, ''];
                        })
                    }
                    t={t}
                />
                <div className="automations__contents-actions">
                    <Button type="cancel" text={t('main:cancel')} onClick={() => cancelPage()} />
                    <Button
                        type="delete"
                        text={t('main:delete')}
                        disabled={!isAutomationExist}
                        onClick={() => {
                            onDeleteClicked();
                        }}
                        ml
                    />
                    <Button
                        type="save"
                        text={t('main:save')}
                        onClick={() => {
                            onSaveClicked();
                        }}
                        disabled={!isSavedButtonActive}
                        ml
                    />
                </div>
            </div>
        </div>
    );
};

export default connect(
    state => ({
        organization: state.user.group.Name,
    }),
    {
        createAutomation,
        updateAutomation,
        fetchAutomation,
        deleteAutomation,
    }
)(NotificationAutomation);
