import React, { Component } from 'react';
import Select from 'react-select';
import { Button, Dropdown } from '@storaensods/seeds-react';
import Toggle from 'react-toggle';
import { Row, Col } from 'reactstrap';
import { storeCustomQuerySelectorSetting, getCustomQuerySelectorSetting } from '../../localStorage.js';

const customStylesSelector = {
    control: (provided, state) => ({
        ...provided,
        borderRadius: '0px',
        borderColor: state.isFocused ? '#4DA8E1' : '#9599a3',
        padding: '1px',
        boxShadow: 'none',
        '&:hover': {
            borderColor: '#4DA8E1',
        },
    }),
    menu: provided => ({
        ...provided,
        borderRadius: '0px',
        border: '1px solid #4DA8E1',
    }),
    option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isFocused ? '#F8F8F9' : '#FFFFFF',
    }),
};

const allDateRanges = [
    'All time',
    'Today',
    'Yesterday',
    'This week',
    'This month',
    'This quarter',
    'This year',
    'Last 7 days',
    'Last 30 days',
    'Last week',
    'Last month',
    'Last quarter',
    'Last year',
];

const allGranularities = ['w/o grouping', 'hour', 'day', 'week', 'month', 'year'];

const allMeasures = [
    { value: 'Transactions.count', label: 'Transactions count' },
    { value: 'Transactions.itemsSoldCount', label: 'Items sold count' },
    { value: 'Transactions.orderSum', label: 'Order sum' },
    { value: 'Transactions.averageOrderSum', label: 'Average order sum' },
    { value: 'Transactions.averageItemsCount', label: 'Average items count' },
    { value: 'Transactions.minimumOrderSum', label: 'Minimum order sum' },
    { value: 'Transactions.maximumOrderSum', label: 'Maximum order sum' },
    { value: 'Transactions.customersCount', label: 'Customers count' },
    { value: 'Transactions.productsCount', label: 'Products count' },
    { value: 'Transactions.netOrderSum', label: 'Net order sum' },
    { value: 'Transactions.vatValueSum', label: 'VAT value sum' },
    { value: 'DeviceTemperatures.averageComputedTemperature', label: 'Average Temperature' },
    { value: 'DeviceTemperatures.minComputedTemperature', label: 'Minimum Temperature' },
    { value: 'DeviceTemperatures.maxComputedTemperature', label: 'Maximum Temperature' },
];

const allDimensions = [
    { value: 'Device.id', label: 'Cabinet id' },
    { value: 'Device.deviceCode', label: 'Cabinet device code' },
    { value: 'Device.deviceName', label: 'Cabinet name' },
    { value: 'Transactions.locationName', label: 'Location' },
    { value: 'Transactions.customerId', label: 'Customer id' },
    { value: 'Transactions.dayOfWeek', label: 'Day of week' },
    { value: 'Transactions.month', label: 'Month' },
    { value: 'Transactions.barcode', label: 'Barcode' },
    { value: 'Transactions.productName', label: 'Product name' },
    { value: 'Transactions.productCategoryName', label: 'Product Category' },
    { value: 'Transactions.soldCount', label: 'Sold count' },
    { value: 'Transactions.status', label: 'Status' },
    { value: 'Transactions.currency', label: 'Currency' },
    { value: 'Transactions.vatValue', label: 'VAT %' },
    { value: 'TransactionDetails.unpaidSales', label: 'Unpaid sales' },
    { value: 'TransactionDetails.unpaidTransactions', label: 'Unpaid transaction count' },
    { value: 'Transactions.paymentMethod', label: 'Payment method' },
    { value: 'InventorySnapshot.latest', label: 'Cabinet Inventory' },
];

export function cubeDataKeyToLabel(dataKey) {
    if (dataKey === 'Transactions.timestamp') return 'Timestamp';
    if (dataKey === 'DeviceHealth.timestamp') return 'Timestamp';
    if (dataKey === 'TransactionDetails.orderSum') return 'Total paid amount';
    if (dataKey === 'InventorySnapshot.readtime') return 'Timestamp';

    const dimensions = [
        ...allDimensions,
        { value: 'ProductType.name', label: 'Product Name' },
        { value: 'ProductPrice.price', label: 'Price' },
        { value: 'ProductType.code', label: 'Barcode' },
        { value: 'ProductPrice.currency', label: 'Currency' },
        { value: 'Location.name', label: 'Location' },
        { value: 'InventorySnapshot.expiryDate', label: 'Expiry Date' },
        { value: 'VatCategory.name', label: 'VAT Category' },
        { value: 'VatCategory.taxValue', label: 'Tax %' },
    ];

    const measures = [...allMeasures, { value: 'InventorySnapshot.count', label: 'Count' }, { value: 'InventorySnapshot.goodAntennaCount', label: 'Count' }];

    const foundDimension = dimensions.find(dimension => dimension.value === dataKey);
    if (foundDimension) return foundDimension.label;
    const foundMeasure = measures.find(measure => measure.value === dataKey);
    if (foundMeasure) return foundMeasure.label;
    return dataKey;
}

/**
 *
 * @param {boolean} fetching Is currently fetching custom query data
 * @param {function} runQuery
 * @param {function} t translations
 */
export default class CustomSelector extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dateRange: 'Last 7 days',
            granularity: 'w/o grouping',
            grouping: 'all',
            selectedLocations: [],
            selectedCabinets: [],
            measures: [],
            dimensions: [],
            filters: [],
            segments: [],
            queryChanged: true, // set to true as nothing is fetched on component mount
        };

        const prevState = getCustomQuerySelectorSetting();

        if (prevState) {
            this.state.dateRange = prevState.dateRange ? prevState.dateRange : this.state.dateRange;
            this.state.granularity = prevState.granularity ? prevState.granularity : this.state.granularity;
            this.state.grouping = prevState.grouping ? prevState.grouping : this.state.grouping;
            this.state.selectedLocations = prevState.selectedLocations ? prevState.selectedLocations : this.state.selectedLocations;
            this.state.selectedCabinets = prevState.selectedCabinets ? prevState.selectedCabinets : this.state.selectedCabinets;
            this.state.measures = prevState.measures ? prevState.measures : this.state.measures;
            this.state.dimensions = prevState.dimensions ? prevState.dimensions : this.state.dimensions;
        }
    }

    setDateRange = dateRange => this.setState(() => ({ dateRange, queryChanged: true }));
    setGranularity = granularity => this.setState(() => ({ granularity, queryChanged: true }));
    setGrouping = grouping => this.setState({ grouping, queryChanged: true });
    onMeasuresSelectorChange = selectedMeasures =>
        this.setState(() => ({
            measures: selectedMeasures ? selectedMeasures : [],
            queryChanged: true,
        }));
    onDimensionsSelectorChange = selectedDimensions => {
        this.setState(() => ({
            dimensions: selectedDimensions ? selectedDimensions : [],
            queryChanged: true,
        }));
    };

    onGroupingSelectorChange = items => {
        const { grouping, selectedCabinets, selectedLocations } = this.state;
        this.setState({
            selectedCabinets: grouping === 'cabinets' ? (items ? items : []) : selectedCabinets,
            selectedLocations: grouping === 'locations' ? (items ? items : []) : selectedLocations,
            queryChanged: true,
        });
    };
    runQuery = () => {
        const { measures, dimensions, dateRange, granularity, grouping, selectedCabinets, selectedLocations } = this.state;

        const valueMeasures = measures.map(measure => measure.value);
        const valueDimensions = dimensions.map(dimension => dimension.value);

        let valueFilters = [];
        if (grouping !== 'all') {
            if (grouping === 'cabinets' && selectedCabinets.length) {
                valueFilters = [
                    {
                        dimension: 'Device.deviceCode',
                        operator: 'equals',
                        values: selectedCabinets.map(device => device.value),
                    },
                ];
            } else if (grouping === 'locations' && selectedLocations.length) {
                valueFilters = [
                    {
                        dimension: 'Location.name',
                        operator: 'equals',
                        values: selectedLocations.map(location => location.value),
                    },
                ];
            }
        }

        this.props.runQuery(valueMeasures, valueDimensions, dateRange, granularity, valueFilters);
        this.setState(() => ({
            queryChanged: false,
        }));

        storeCustomQuerySelectorSetting(dateRange, granularity, grouping, selectedCabinets, selectedLocations, measures, dimensions);
    };

    queryIsValid = () => {
        const { measures, dimensions, queryChanged } = this.state;
        if (!queryChanged) return false;
        if (measures.length === 0 && dimensions.length === 0) return false;
        return true;
    };

    getSelectedGrouping = () => {
        const { grouping } = this.state;
        const { cabinets, locations } = this.props;
        let selectedGroupingArray = [];
        if (grouping === 'locations') {
            selectedGroupingArray = locations.map(location => {
                return { value: location.name, label: location.name };
            });
        } else {
            selectedGroupingArray = cabinets.map(device => {
                return { value: device.deviceCode, label: device.name };
            });
        }

        return selectedGroupingArray;
    };

    render() {
        const { dateRange, granularity, grouping, selectedLocations, selectedCabinets, measures, dimensions } = this.state;
        const { t, changePanel, exportData, response } = this.props;
        return (
            <Row>
                <Col xs="12" md="8" lg="9">
                    <div className="d-inline-block mr-3">
                        <div className="mb-1 small">{t('dateRange')}</div>
                        <Dropdown
                            className="analytics-dropdown"
                            value={t(dateRange)}
                            onSelect={({ value }) => this.setDateRange(value)}
                            options={allDateRanges.map(dateRange => ({
                                label: t(dateRange),
                                value: dateRange,
                            }))}
                        />
                    </div>
                    <div className="d-inline-block mr-3">
                        <div className="mb-1 small">{t('timeGrouping')}</div>
                        <Dropdown
                            className="analytics-dropdown"
                            value={t(granularity)}
                            onSelect={({ value }) => this.setGranularity(value)}
                            options={allGranularities.map(granularity => ({
                                label: t(granularity),
                                value: granularity,
                            }))}
                        />
                    </div>
                    <div className="d-inline-block mr-3" style={{ minWidth: '200px' }}>
                        <div className="mb-1 small">{t('group')}</div>
                        <Dropdown
                            className="analytics-dropdown"
                            value={t(grouping)}
                            onSelect={({ value }) => this.setGrouping(value)}
                            options={[
                                {
                                    value: 'all',
                                    label: t('all'),
                                },
                                {
                                    value: 'locations',
                                    label: t('locations'),
                                },
                                {
                                    value: 'cabinets',
                                    label: t('cabinets'),
                                },
                            ]}
                        />
                    </div>
                    {grouping !== 'all' && (
                        <div className="d-inline-block mr-3" style={{ minWidth: '300px', maxWidth: '500px' }}>
                            <div className="mb-1 small">{t(grouping)}</div>
                            <Select
                                value={grouping === 'cabinets' ? selectedCabinets : selectedLocations}
                                options={this.getSelectedGrouping()}
                                onChange={this.onGroupingSelectorChange}
                                isMulti
                                styles={customStylesSelector}
                            />
                        </div>
                    )}
                    <div className={grouping === 'all' ? 'd-inline-block' : 'mt-3'}>
                        <div className="d-inline-block mr-3" style={{ minWidth: '300px', maxWidth: '500px' }}>
                            <div className="mb-1 small">{t('measures')}</div>
                            <Select
                                defaultValue={measures}
                                options={allMeasures}
                                onChange={this.onMeasuresSelectorChange}
                                isMulti
                                styles={customStylesSelector}
                            />
                        </div>
                        <div className="d-inline-block mr-3" style={{ minWidth: '300px', maxWidth: '500px' }}>
                            <div className="mb-1 small">{t('dimensions')}</div>
                            <Select
                                defaultValue={dimensions}
                                options={allDimensions}
                                onChange={this.onDimensionsSelectorChange}
                                isMulti
                                styles={customStylesSelector}
                            />
                        </div>
                        <div className="d-inline-block mr-3 align-bottom">
                            <Button className="se-analytics-button-attention" onClick={this.runQuery} disabled={!this.queryIsValid()} icon="refresh">
                                {t('updateData')}
                            </Button>
                        </div>
                    </div>
                </Col>

                <Col xs="12" md="4" lg="3">
                    <div className="mr-2 float-right">
                        <div className="mb-2 mt-2 small">{t('viewMode')}</div>
                        <div className="d-inline-block">
                            <h6 style={{ color: '#a0a1a5' }}>{t('dashboard')}</h6>
                        </div>
                        <Toggle
                            className="d-inline-block ml-3 mr-3 dashboard-toggle"
                            checked={true}
                            icons={false}
                            onClick={changePanel.bind(this, 'dashboard')}
                            readOnly
                        />
                        <div className="d-inline-block">
                            <h6>{t('reportsTitle')}</h6>
                        </div>
                    </div>
                    <div className="float-right mt-4 mr-3">
                        <Button
                            onClick={() => exportData('analytics_report', response)}
                            className="se-analytics-button mb-2"
                            disabled={!response || !response.data || response.data.length === 0}
                            icon="file_download"
                        >
                            {t('exportExcel')}
                        </Button>
                    </div>
                </Col>
            </Row>
        );
    }
}
