/**
 * Set last seen software version update notification date time in LocalStorage
 * @param {date} lastSeenDateTime
 */
export function storeSoftwareVersionUpdateLastSeen(lastSeenDateTime) {
    window.localStorage.setItem('versionUpdateNotificationLastSeen', lastSeenDateTime);
}

/**
 * Get last seen software version update notification date time in LocalStorage
 */
export function getSoftwareVersionUpdateLastSeen() {
    return window.localStorage.getItem('versionUpdateNotificationLastSeen');
}

/**
 * Set software versions to local storage to enable comparison between new and old versions
 * @param {array} versions
 */
export function storeSoftwareVersions(versions) {
    window.localStorage.setItem('softwareVersions', versions);
}

/**
 * Get last seen software version update notification date time in LocalStorage
 */
export function getSoftwareVersions() {
    return window.localStorage.getItem('softwareVersions');
}
/**
 * Store selected cabinet device code in LocalStorage
 * @param {string} deviceCode
 */
export function storeSelectedCabinet(deviceCode) {
    window.localStorage.setItem('selectedCabinet', deviceCode);
}

/**
 * Store selected cabinet device Id in LocalStorage
 * @param {string} deviceCode
 */
export function storeSelectedCabinetId(deviceId) {
    window.localStorage.setItem('selectedCabinetId', deviceId);
}

/**
 * Get selected cabinet device code from LocalStorage
 * @returns {string} deviceCode
 */
export function getStoredSelectedCabinet() {
    return window.localStorage.getItem('selectedCabinet');
}

/**
 * Store selected cabinet device Id in LocalStorage
 * @param {string} deviceCode
 */
export function storeSelectedEncodingDeviceId(deviceId) {
    window.localStorage.setItem('selectedEncodingDeviceId', deviceId);
}

/**
 * Get selected cabinet device code from LocalStorage
 * @returns {string} deviceCode
 */
export function storeSelectedEncodingDevice() {
    return window.localStorage.getItem('selectedEncodingDevice');
}

/**
 * Get selected cabinet device id from LocalStorage
 * @returns {string} deviceCode
 */
export function getStoredSelectedCabinetId() {
    return window.localStorage.getItem('selectedCabinetId');
}

/**
 * Store selected group in LocalStorage
 * @param {string} group
 */
export function storeSelectedGroup(group) {
    window.localStorage.setItem('selectedGroup', JSON.stringify(group));
}

/**
 * Get selected group from LocalStorage
 * @returns {string} group
 */
export function getStoredSelectedGroup() {
    let group = window.localStorage.getItem('selectedGroup');
    if (group === '') {
        return group;
    }
    try {
        group = JSON.parse(group);
    } catch (e) {
        group = null;
    }
    return group;
}

/**
 * Store selected group in LocalStorage
 * @param {string} group
 */
export function storeDashboardSelectorSetting(dateRange, filter, selectedCard) {
    window.localStorage.setItem('dashboardDateRange', dateRange);
    window.localStorage.setItem('dashboardFilter', JSON.stringify(filter));
    window.localStorage.setItem('dashboardSelectedCard', selectedCard);
}

export function storeDashboardDefaultView(dashboardView) {
    window.localStorage.setItem('dashboardView', dashboardView);
}

export function getDashboardDefaultView() {
    const view = window.localStorage.getItem('dashboardView');

    if (view) {
        return view;
    }
}

/**
 * Get selected group from LocalStorage
 * @returns {string} group
 */
export function getDashboardSelectorSetting() {
    const dateRange = window.localStorage.getItem('dashboardDateRange');
    const filter = window.localStorage.getItem('dashboardFilter');
    const selectedCard = window.localStorage.getItem('dashboardSelectedCard');

    if (dateRange && selectedCard && filter) {
        return {
            dateRange,
            filter: JSON.parse(filter),
            selectedCard,
        };
    } else {
        return null;
    }
}

/**
 * Store custom query params in LocalStorage
 */
export function storeCustomQuerySelectorSetting(dateRange, granularity, filter, cabinets, locations, measures, dimensions, reportType, segments, userGroup) {
    window.localStorage.setItem('customQueryDateRange', dateRange);
    window.localStorage.setItem('customQueryGranularity', granularity);
    window.localStorage.setItem('customQueryFilter', filter);
    window.localStorage.setItem('customQueryCabinets', JSON.stringify(cabinets));
    window.localStorage.setItem('customQueryLocations', JSON.stringify(locations));
    window.localStorage.setItem('customQueryMeasures', JSON.stringify(measures));
    window.localStorage.setItem('customQueryDimensions', JSON.stringify(dimensions));
    window.localStorage.setItem('customQueryReportType', reportType);
    window.localStorage.setItem('customQuerySegments', segments);
    window.localStorage.setItem('customQueryUserGroup', userGroup);
}

/**
 * Get custom query params from LocalStorage
 */
export function getCustomQuerySelectorSetting() {
    const dateRange = window.localStorage.getItem('customQueryDateRange');
    const granularity = window.localStorage.getItem('customQueryGranularity');
    const filter = window.localStorage.getItem('customQueryFilter');
    const customQueryCabinets = window.localStorage.getItem('customQueryCabinets');
    const customQueryLocations = window.localStorage.getItem('customQueryLocations');
    const customQueryMeasuresString = window.localStorage.getItem('customQueryMeasures');
    const customQueryDimensionsString = window.localStorage.getItem('customQueryDimensions');
    const reportType = window.localStorage.getItem('customQueryReportType');
    const customQuerySegments = window.localStorage.getItem('customQuerySegments');
    const userGroup = window.localStorage.getItem('customQueryUserGroup');

    if (dateRange) {
        let measures = customQueryMeasuresString !== '' ? JSON.parse(customQueryMeasuresString) : null;
        let dimensions = customQueryDimensionsString !== '' ? JSON.parse(customQueryDimensionsString) : null;
        let cabinets = customQueryCabinets !== '' ? JSON.parse(customQueryCabinets) : null;
        let locations = customQueryLocations !== '' ? JSON.parse(customQueryLocations) : null;
        const segments = customQuerySegments !== '' ? customQuerySegments : null;

        // Additinal check if contains members
        // this is to override previous version of UI
        // storing in different formats
        if (measures && !measures.members) {
            measures = null;
        }
        if (dimensions && !dimensions.members) {
            dimensions = null;
        }
        if (cabinets && !cabinets.members) {
            cabinets = null;
        }
        if (locations && !locations.members) {
            locations = null;
        }

        return {
            dateRange,
            granularity,
            filter,
            cabinets,
            locations,
            measures,
            dimensions,
            reportType,
            segments,
            userGroup,
        };
    } else {
        return null;
    }
}

export function getCustomQueryUserPresets() {
    const presets = window.localStorage.getItem('customQueryUserPresets');

    return presets ? JSON.parse(presets) : [];
}

export function saveCustomQueryUserPreset(name, values) {
    const id = new Date().getTime();
    const currentUserPresets = getCustomQueryUserPresets();
    const newUserPreset = {
        id,
        name,
        query: { ...values },
    };

    window.localStorage.setItem('customQueryUserPresets', JSON.stringify([...currentUserPresets, newUserPreset]));
}

export function deleteCustomQueryUserPreset(id) {
    const currentUserPresets = getCustomQueryUserPresets();
    const filteredUserPresets = currentUserPresets.filter(preset => preset.id !== id);

    window.localStorage.setItem('customQueryUserPresets', JSON.stringify(filteredUserPresets));
}

export function resetCustomQueryFilter() {
    window.localStorage.setItem('customQueryFilter', '');
    window.localStorage.setItem('customQueryCabinets', '');
    window.localStorage.setItem('customQueryLocations', '');
}

export function storeRedirectPath(path) {
    window.localStorage.setItem('redirectPath', path);
}

export function getRedirectPath() {
    return window.localStorage.getItem('redirectPath');
}

/**
 *  remember last selcted waste type -> expired or discount
 */

export function storeWasteType(type) {
    window.localStorage.setItem('wasteType', type);
}
export function getWasteType() {
    return window.localStorage.getItem('wasteType');
}
