import React from "react";

import PopUpDialog from "./popUpDialog";
import TagSensitivityInfoText from "./tagSensitivityInfoText";

export const DEFAULT_TAG_SENSITIVITY_LEVEL = 60;
export const ALLOWED_SENSITIVITY_LEVELS = [0, 20, 40, 60, 80, 100];
export const sensitivityDescription = [
    {
        level:0,
        text:'settings:sensitivity_0'
    },
    {
        level:20,
        text:'settings:sensitivity_20'
    },
    {
        level:40,
        text:'settings:sensitivity_40'
    },
    {
        level:60,
        text:'settings:sensitivity_60'
    },
    {
        level:80,
        text:'settings:sensitivity_80'
    },
    {
        level:100,
        text:'settings:sensitivity_100'
    }
]

const TagSensitivity =({changeSensitivity, currentSensitivityLevel, t})=>{ 

    return (
        <div className="tag-sensitivity">
            <div className="tag-sensitivity__slider">
                <div className="tag-sensitivity__indicator">
                    {
                        ALLOWED_SENSITIVITY_LEVELS.map(level=>{
                            return <PopUpDialog key={level} index={level} changeSensitivity={(value)=>changeSensitivity(value)} t={t} currentSensitivityLevel={currentSensitivityLevel}/>
                        })
                    }
                </div>             
                <input type="range" min={0} max={100} step={20} onChange={(e)=>changeSensitivity(e.target.value)} value={currentSensitivityLevel}/>
                <TagSensitivityInfoText  t={t} tagSensitivityLevel={currentSensitivityLevel}/>
            </div>
        </div>
    )
}

export default TagSensitivity