import React, { Fragment } from 'react';
import icons from './../../../icons/icomoon.svg';

const SchemaControlBar = ({
    saveRefillSchema,
    deleteRefillSchema,
    schema,
    prestine,
    searchQuery,
    t,
    toggleViewMode,
    activeViewMode,
    productFilterChange,
    refillPlan,
    openCopySchema,
    isAdmin,
}) => {
    const { rule } = schema;

    return (
        <div className="refillschema__controller">
            <div className="refillschema__controller-toggle">
                {!refillPlan && (
                    <Fragment>
                        <span
                            className={`${
                                activeViewMode === 'inventory' ? 'refillschema__controller-toggle-inventory refillschema__controller-toggle--active' : ''
                            }`}
                            onClick={() => toggleViewMode('inventory')}
                        >
                            {t('inventory')}
                        </span>
                        <span
                            className={`${
                                activeViewMode === 'planogram' ? 'refillschema__controller-toggle-planogram refillschema__controller-toggle--active' : ''
                            }`}
                            onClick={() => toggleViewMode('planogram')}
                        >
                            {t('planogram')}
                        </span>
                    </Fragment>
                )}
            </div>

            <div className="refillschema__controller-btns">
                {activeViewMode === 'planogram' && (
                    <div className="refillschema__controller-filter">
                        <select
                            type="select"
                            onChange={e => {
                                productFilterChange(e.target.value);
                            }}
                        >
                            <option value="all">{t('allProducts')}</option>
                            <option value="schema">{t('schemaProducts')}</option>
                        </select>
                    </div>
                )}
                <div className="refillschema__controller-search">
                    <input type="text" placeholder={t('searchProducts')} onChange={e => searchQuery(e.target.value)} />
                    <svg>
                        <use xlinkHref={`${icons}#icon-search`}></use>
                    </svg>
                </div>
                {rule?.id && activeViewMode === 'inventory' && isAdmin && (
                    <div
                        className="refillschema__controller-action refillschema__controller-action-primary"
                        title={t('copyRefillSchema')}
                        onClick={openCopySchema}
                    >
                        <svg>
                            <use xlinkHref={`${icons}#icon-copy`}></use>
                        </svg>
                        <span>{t('main:copy')}</span>
                    </div>
                )}
                {rule?.id && isAdmin && (
                    <div className="refillschema__controller-action refillschema__controller-action-negative" onClick={() => deleteRefillSchema()}>
                        <svg>
                            <use xlinkHref={`${icons}#icon-trash-2`}></use>
                        </svg>
                        <span>{t('main:delete')}</span>
                    </div>
                )}
                {isAdmin && (
                    <div
                        className={`refillschema__controller-action refillschema__controller-action-positive ${
                            prestine ? 'refillschema__controller-action--inactive' : ''
                        }`}
                        onClick={() => {
                            if (prestine) return;
                            saveRefillSchema();
                        }}
                    >
                        <svg>
                            <use xlinkHref={`${icons}#icon-save`}></use>
                        </svg>
                        <span>{t('main:save')}</span>
                    </div>
                )}
            </div>
        </div>
    );
};

export default SchemaControlBar;
