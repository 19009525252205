import React, { useState, Fragment } from 'react';
import { Icon } from '@storaensods/seeds-react';
import { Popover, PopoverBody } from 'reactstrap';
import icons from './../icons.svg';

import './cabinetStatusIndicators.css';
import moment from 'moment';

/**
 *
 * @param {*} props
 * @returns DOM to indicate device status
 */
const DeviceSatatusIndicator = ({ isOnline, isDigiTwinOnline, rfidStatus, deviceCode, t, deviceType, deviceSubType, device, activeAlarms }) => {
    const [isPopOverOpen, setIsPopoverOpen] = useState(false);

    /**
     * @param {boolean} style
     * @returns css className/label translation
     */
    const getReaderStatus = style => {
        switch (rfidStatus) {
            case 'OK':
                return style ? '' : t('rfidOk');
            case 'READER_DOWN':
                return style ? 'popover-icon-rfid--offline' : t('readerDown');
            case 'ANTENNA_DOWN':
                return style ? 'popover-icon-rfid--anteena-error' : t('antennaDown');
            default:
                return style ? 'popover-icon-rfid--no-data' : t('rfidNoInfo');
        }
    };

    // taking deviceId as unique index.
    // replace some invalid chars with 0
    const getIndex = () => {
        let deviceId = device.id;
        deviceId = deviceId.replace(/[^a-zA-Z0-9]/g, 0);
        return deviceId;
    };

    const getDeviceStatusVerdict = () => {
        const hasOOSAlarm = activeAlarms.find(alarm => alarm.severity >= 0.99);
        if (!isOnline) {
            return <div className="popover-device-operational popover-device--offline">{t('offline')}</div>;
        } else if (rfidStatus === 'READER_DOWN' || hasOOSAlarm) {
            return <div className="popover-device-operational popover-device--no-service">{t('noService')}</div>;
        }

        return <div className="popover-device-operational">{t('operational')}</div>;
    };

    const getIndicator = () => {
        // check for any oos and critical alarms
        const hasOOSAlarm = activeAlarms.find(alarm => alarm.severity >= 0.99);
        const hasUrgentAlarms = activeAlarms.find(alarm => (alarm.severity >= 0.89) & (alarm.severity < 0.99));

        // if oos alarms
        if (hasOOSAlarm) {
            return (
                <button id={`info-${getIndex()}`} className="alarm-indicator alarm-indicator-circle alarm-indicator--oos">
                    <i className="fas fa-bell"></i>
                </button>
            );
        }

        // if urgentAlarms
        if (hasUrgentAlarms) {
            return (
                <button id={`info-${getIndex()}`} className="alarm-indicator alarm-indicator-circle  alarm-indicator--critical">
                    <i className="fas fa-bell"></i>
                </button>
            );
        }

        return <button className={`device-indicator ${isOnline ? 'device-online' : 'device-offline'}`} id={`info-${getIndex()}`} type="button"></button>;
    };

    const getAlarmList = () => {
        const oosAlarms = activeAlarms.filter(alarm => alarm.severity >= 0.99);
        const urgentAlarms = activeAlarms.filter(alarm => (alarm.severity >= 0.89) & (alarm.severity < 0.99));

        const allAlarms = [...oosAlarms, ...urgentAlarms];

        if (!allAlarms.length) {
            return <></>;
        }

        return (
            <div className="popover-alarm-list">
                <div className="popover-device-label">{t('activeAlarms')}</div>
                <ul>
                    {allAlarms.map(alarm => {
                        return (
                            <li key={alarm.device_Id}>
                                <button
                                    className={`alarm-indicator ${
                                        alarm.severity >= 0.99 ? 'alarm-indicator--oos' : 'alarm-indicator--critical'
                                    }  alarm-indicator--popover`}
                                >
                                    <i className="fas fa-bell"></i>
                                </button>
                                <span> {t(alarm.type)}</span>
                            </li>
                        );
                    })}
                </ul>
            </div>
        );
    };

    const getDeviceSubTypeModel = () => {
        let deviceModelType;
        if (deviceType === 'smartCabinet') {
            switch (deviceSubType) {
                case 'IF3Freezer':
                    deviceModelType = t('IF3Freezers');
                    break;
                case 'IF2':
                    deviceModelType = t('IF2Cabinets');
                    break;
                default:
                    deviceModelType = t('IF3Cabinets');
                    break;
            }
        } else if (deviceType === 'encodingStation') {
            deviceModelType = t('encodingStation');
        } else if (deviceType === 'selflyEncode') {
            deviceModelType = t('selflyEncode');
        } else {
            deviceModelType = '-';
        }

        return deviceModelType;
    };

    return (
        <div>
            {getIndicator()}
            <Popover
                flip
                isOpen={isPopOverOpen}
                target={`info-${getIndex()}`}
                toggle={() => setIsPopoverOpen(!isPopOverOpen)}
                trigger="hover"
                placement={'right'}
                boundariesElement="window"
            >
                <PopoverBody>
                    <div className="popover-content">
                        <div className="popover-device">
                            <DeviceSubTypeIcon deviceType={deviceType} deviceSubType={deviceSubType} />
                            <div>
                                <div
                                    className={`popover-device-type ${
                                        deviceSubType === 'IF2' || deviceType === 'encodingStation' || deviceType === 'selflyEncode'
                                            ? 'popover-device-type--blue'
                                            : ''
                                    }`}
                                >
                                    {getDeviceSubTypeModel()}
                                </div>
                                <div className="popover-device-name">{device.name}</div>
                            </div>
                        </div>
                        <div className="popover-device-status">
                            <div className="popover-device-button">
                                {isOnline && (
                                    <div className="popover-device-online">
                                        <Icon>power_settings_new</Icon>
                                        <span>{t('deviceOnline')}</span>
                                    </div>
                                )}
                                {!isOnline && (
                                    <div className="popover-device-offline">
                                        <Icon>power_settings_new</Icon>
                                        <span>{t('deviceOffline')}</span>
                                    </div>
                                )}
                                <div className="popover-device-rfid">
                                    <span className={`popover-icon-rfid ${getReaderStatus(true)}`}>
                                        <svg>
                                            <use xlinkHref={`${icons}#icon-radio`}></use>
                                        </svg>
                                    </span>
                                    <p>{getReaderStatus(false)}</p>
                                </div>
                            </div>
                        </div>
                        {getAlarmList()}
                        <div className="popover-device-verdict">
                            <div className="popover-device-label">{t('deviceStatus')}</div>
                            {getDeviceStatusVerdict()}
                        </div>
                        <div className="popover-timer">
                            <div className="popover-timer-icon">
                                <svg>
                                    <use xlinkHref={`${icons}#icon-clock`}></use>
                                </svg>
                            </div>
                            <div className="popover-timer-lable">
                                <div className="popover-device-label">{t('lastMonitored')}</div>
                                <small> {device.lastSeenOnline ? moment(device.lastSeenOnline).fromNow() : t('notAvailable')}</small>
                            </div>
                        </div>
                    </div>
                </PopoverBody>
            </Popover>
        </div>
    );
};

const DeviceSubTypeIcon = ({ deviceType, deviceSubType }) => {
    let cabinetIcon;

    switch (deviceSubType) {
        case 'IF2':
            cabinetIcon = 'device-icons IF2__icon';
            break;
        case 'IF3Freezer':
            cabinetIcon = 'device-icons IF3Freezer__icon';
            break;
        default:
            cabinetIcon = 'device-icons IF3__icon';
            break;
    }

    return (
        <Fragment>
            {(() => {
                if (deviceType === 'encodingStation') {
                    return <span className="device-icons encodingStation__icon"></span>;
                }
                if (deviceType === 'selflyEncode') {
                    return <span className="device-icons selflyEncode__icon"></span>;
                }
                return <span className={cabinetIcon}></span>;
            })()}
        </Fragment>
    );
};

export { DeviceSubTypeIcon, DeviceSatatusIndicator };
