import moment from 'moment';
import momentTimeZone from 'moment-timezone';
import { at, head } from 'lodash';

export const formatTransactionsForCSV = (transactions, cabinetName, allProducts=[]) => {
    let result = [];
    transactions.forEach(transaction => {
        const { orderId, customerId, timestamp, status, remainingSum, transactionDetails } = transaction;

        // need to export not only purchases, but rerunds too
        // union two arrays and make sure refund transactions are exported
        const allTransactions = [...(head(at(transaction, 'purchases.purchase')) || []), ...(head(at(transaction, 'refundItems')) || [])];

        const getProductCategory=(barcode)=>{
            const product = allProducts.find(product=>product.barcode === barcode)
            if(!product)return ''

            if(product.productCategory){
                return product.productCategory.name
            }else{
                return ''
            }
        }

        // process them all and add to summary
        const transactionResult = allTransactions.map(purchase => {
            const { barcode, count, refundAmount, price, currency, name, vatValue } = purchase;
            const finalPrice = (price * (count || -refundAmount)).toFixed(2);
            const normalizedVat = vatValue && vatValue !== 0 ? Number((vatValue / 100).toFixed(3)) : 0;
            return {
                cabinetName,
                timestamp: moment(timestamp).format('LLL'),
                orderId: transactionDetails && transactionDetails.secondaryOrderId ? transactionDetails.secondaryOrderId : orderId,
                customerId,
                barcode,
                name,
                // as we merged refund transactiosn and purchase transactions, use either count or refundAmound, whatever is given
                count: refundAmount || count,
                // price should be negative if this is refund item
                price: finalPrice,
                currency,
                category:getProductCategory(barcode),
                vatPercentage: vatValue || 0,
                vatValue: (finalPrice * normalizedVat) / (1 + normalizedVat),
                // status is refund if item is refund item
                status: !refundAmount ? status : 'REFUND',
                remainingSum: remainingSum || 0,
            };
        });
        result = [...result, ...transactionResult];
    });
    return result;
};

export const generateTransactionCSVHeaders = () => {
    return [
        { label: 'Cabinet Name', key: 'cabinetName' },
        { label: 'Time', key: 'timestamp' },
        { label: 'Order Id', key: 'orderId' },
        { label: 'Customer Id', key: 'customerId' },
        { label: 'Barcode', key: 'barcode' },
        { label: 'Name', key: 'name' },
        { label: 'Count', key: 'count' },
        { label: 'Price including VAT', key: 'price' },
        { label: 'Vat Percentage', key: 'vatPercentage' },
        { label: 'Vat value', key: 'vatValue' },
        { label: 'Currency', key: 'currency' },
        { label: 'Product category', key: 'category'},
        { label: 'Status', key: 'status' },
    ];
};

export const generateRefillPlanCSVHeaders = () => {
    return [
        { label: 'Cabinet Name', key: 'cabinetName' },
        { label: 'Location Name', key: 'locationName' },
        { label: 'Product', key: 'product' },
        { label: 'Barcode', key: 'barcode' },
        { label: 'Refill Amount', key: 'refillAmount' },
    ];
};

export const formatRefillPlansForXLSX = plans => {
    const excelExport = {
        filename: '',
        sheet: {
            data: [
                [
                    { value: 'Cabinet name', type: 'string' },
                    { value: 'Location name', type: 'string' },
                    { value: 'Product', type: 'string' },
                    { value: 'Barcode', type: 'string' },
                    { value: 'Refill amount', type: 'string' },
                    { value: 'Target inventory amount', type: 'string' },
                ],
            ],
        },
    };
    plans.forEach(plan => {
        const { cabinetName, locationName, barcode, product, refillAmount, targetInvAmount } = plan;
        excelExport.sheet.data.push([
            { value: cabinetName, type: 'string' },
            { value: locationName, type: 'string' },
            { value: product, type: 'string' },
            { value: barcode, type: 'string' },
            { value: refillAmount, type: 'number' },
            { value: targetInvAmount, type: 'number' },
        ]);
    });

    return excelExport;
};

export const formatTransactionsForXLSX = (transactions, cabinetName, allProducts) => {
    const excelExport = {
        filename: '',
        sheet: {
            data: [
                [
                    { value: 'Cabinet Name', type: 'string' },
                    { value: 'Time', type: 'string' },
                    { value: 'Order Id', type: 'string' },
                    { value: 'Customer Id', type: 'string' },
                    { value: 'Barcode', type: 'string' },
                    { value: 'Name', type: 'string' },
                    { value: 'Count', type: 'string' },
                    { value: 'Price including VAT', type: 'string' },
                    { value: 'Vat Percentage', type: 'string' },
                    { value: 'VAT value', type: 'string' },
                    { value: 'Currency', type: 'string' },
                    { value: 'Product category', type: 'string'},
                    { value: 'Status', type: 'string' },
                    { value: 'Remaining sum', type: 'string' },
                ],
            ],
        },
    };

    const formatedTransactions = formatTransactionsForCSV(transactions, cabinetName, allProducts);
    formatedTransactions.forEach(transaction => {
        const {
            cabinetName,
            timestamp,
            orderId,
            customerId,
            barcode,
            count,
            price,
            vatPercentage,
            vatValue,
            currency,
            name,
            status,
            remainingSum,
            category
        } = transaction;
        excelExport.sheet.data.push([
            { value: cabinetName, type: 'string' },
            { value: timestamp, type: 'string' },
            { value: orderId, type: 'string' },
            { value: customerId, type: 'string' },
            { value: barcode, type: 'string' },            
            { value: name, type: 'string' },
            { value: count, type: 'number' },
            { value: price, type: 'number' },
            { value: vatPercentage, type: 'number' },
            { value: vatValue, type: 'number' },
            { value: currency, type: 'string' },
            { value: category, type:'string'},
            { value: status, type: 'string' },
            { value: remainingSum, type: 'number' },
        ]);
    });

    return excelExport;
};

export const formatReplenishmentForCSV = (replenishmentData, cabinetName) =>
    [].concat(
        ...replenishmentData.map(record => {
            const { timestamp, user } = record;
            return (head(at(record, 'purchases.purchase')) || []).map(item => {
                const { name, productType, price, currency, count, itemReference, barcode } = item;
                return {
                    cabinetName,
                    timestamp: moment(timestamp).format('LLL'),
                    user,
                    itemReference,
                    barcode,
                    name,
                    productType,
                    count: count,
                    price: price.toFixed(2),
                    total: (count * price).toFixed(2),
                    currency,
                };
            });
        })
    );

export const generateReplenishmentCSVHeaders = () => {
    return [
        { label: 'Cabinet Name', key: 'cabinetName' },
        { label: 'Time', key: 'timestamp' },
        { label: 'User', key: 'user' },
        { label: 'Item Reference', key: 'itemReference' },
        { label: 'Barcode', key: 'barcode' },
        { label: 'Name', key: 'name' },
        { label: 'Product Type', key: 'productType' },
        { label: 'Count', key: 'count' },
        { label: 'Price including VAT', key: 'price' },
        { label: 'Total', key: 'total' },
        { label: 'Currency', key: 'currency' },
    ];
};

export const formatReplenishmentForXLSX = (replenishmentData, cabinetName) => {
    const excelExport = {
        filename: '',
        sheet: {
            data: [
                [
                    { value: 'Cabinet Name', type: 'string' },
                    { value: 'Time', type: 'string' },
                    { value: 'User', type: 'string' },
                    { value: 'Item Reference', type: 'string' },
                    { value: 'Barcode', type: 'string' },
                    { value: 'Name', type: 'string' },
                    { value: 'Product Type', type: 'string' },
                    { value: 'Count', type: 'string' },
                    { value: 'Price including VAT', type: 'string' },
                    { value: 'Total', type: 'string' },
                    { value: 'Currency', type: 'string' },
                ],
            ],
        },
    };

    const formatedTransactions = formatReplenishmentForCSV(replenishmentData, cabinetName);
    formatedTransactions.forEach(transaction => {
        const { cabinetName, timestamp, user, itemReference, barcode, name, productType, price, currency, count, total } = transaction;
        excelExport.sheet.data.push([
            { value: cabinetName, type: 'string' },
            { value: timestamp, type: 'string' },
            { value: user, type: 'string' },
            { value: itemReference, type: 'string' },
            { value: barcode, type: 'string' },
            { value: name, type: 'string' },
            { value: productType, type: 'string' },
            { value: count, type: 'number' },
            { value: price, type: 'number' },
            { value: total, type: 'number' },
            { value: currency, type: 'string' },
        ]);
    });
    return excelExport;
};

export const formatTemperatureForCSV = (temperatureData, cabinetName) => {
    const { average } = temperatureData;
    const granularity = average.query.timeDimensions[0].granularity || 'day';
    // Note! Cube returns the times already converted to client timezone, so we need to use them as they are without conversion!
    const clientTimeZone = 'Etc/UTC'; //Intl.DateTimeFormat().resolvedOptions().timeZone;

    const formatDateTime = dateTime => {
        dateTime = getLocalTime(dateTime, clientTimeZone);
        switch (granularity) {
            case 'minute':
                return moment(dateTime).format('YYYY-MM-DD LT');
            case 'hour':
                return moment(dateTime).format('YYYY-MM-DD LT');
            case 'day':
                return moment(dateTime).format('YYYY-MM-DD');
            case 'week':
                return moment(dateTime).format('GGGG-[Week-]WW');
            default:
                // return minute as default
                return moment(dateTime).format('YYYY-MM-DD LT');
        }
    };

    const temperatures = () => {
        return average.data.map(data => {
            return {
                date: data['DeviceTemperatures.timestamp'],
                averageTemp: data['DeviceTemperatures.averageComputedTemperature'],
            };
        });
    };

    return temperatures().map(temperature => {
        const { date, averageTemp } = temperature;
        return {
            cabinetName,
            timestamp: formatDateTime(date),
            averageTemp: averageTemp ? averageTemp.toFixed(2) : null,
            unit: 'Celsius',
        };
    });
};

export const getLocalTime = (dateString, clientTimeZone) => {
    const UTCTime = moment(dateString).format('YYYY-MM-DD HH:mm:ss');
    // convert to the browser time zone
    return momentTimeZone
        .utc(UTCTime)
        .tz(clientTimeZone)
        .format('YYYY-MM-DD HH:mm:ss');
};

export const generateTemperatureCSVHeaders = () => {
    return [
        { label: 'Cabinet Name', key: 'cabinetName' },
        { label: 'Timestamp', key: 'timestamp' },
        { label: 'Average Temperature', key: 'averageTemp' },
        { label: 'Unit', key: 'unit' },
    ];
};

export const formatTemperatureForXLSX = (temperatureData, cabinetName) => {
    const excelExport = {
        filename: '',
        sheet: {
            data: [
                [
                    { value: 'Cabinet Name', type: 'string' },
                    { value: 'Timestamp', type: 'string' },
                    { value: 'Average Temperature', type: 'string' },
                    { value: 'Unit', type: 'string' },
                ],
            ],
        },
    };

    const temperatures = formatTemperatureForCSV(temperatureData, cabinetName);
    temperatures.forEach(temperature => {
        const { cabinetName, timestamp, averageTemp, unit } = temperature;
        excelExport.sheet.data.push([
            { value: cabinetName, type: 'string' },
            { value: timestamp, type: 'string' },
            { value: averageTemp, type: 'string' },
            { value: unit, type: 'string' },
        ]);
    });
    return excelExport;
};

export const formattedProductsForCSV = productData => {
    const statusCodeToText = statusCode => {
        switch (statusCode) {
            case 1:
                return 'Draft';
            case 2:
                return 'Active';
            case 3:
                return 'Retired';
            case 4:
                return 'Deleted';
            default:
                break;
        }
    };
    return [].concat(
        ...productData.map(product => {
            const { status, owners } = product;
            const price = typeof product.price === 'string' ? parseInt(product.price, 10).toFixed(2) : product.price.toFixed(2);
            return {
                ...product,
                owner: owners?.[0],
                status: statusCodeToText(status),
                price,
            };
        })
    );
};

export const generateProductsCSVHeaders = () => {
    return [
        { label: 'Product Id', key: 'id' },
        { label: 'Image URL', key: 'imageUrl' },
        { label: 'Name', key: 'name' },
        { label: 'Product Type', key: 'productType' },
        { label: 'Barcode', key: 'barcode' },
        { label: 'Owner', key: 'owner' },
        { label: 'Price including VAT', key: 'price' },
        { label: 'Is Prebooked', key: 'isPrebooked' },
        { label: 'Time To Live Days', key: 'timeToLiveDays' },
    ];
};

export const formatProductsForXLSX = products => {
    const excelExport = {
        filename: '',
        sheet: {
            data: [
                [
                    { value: 'Product Id', type: 'string' },
                    { value: 'Image URL', type: 'string' },
                    { value: 'Barcode', type: 'string' },
                    { value: 'Name', type: 'string' },
                    { value: 'Product Type', type: 'string' },
                    { value: 'Price including VAT', type: 'string' },
                    { value: 'Currency', type: 'string' },
                    { value: 'Status', type: 'string' },
                    { value: 'Owner', type: 'string' },
                    { value: 'Is Prebooked', type: 'string' },
                    { value: 'Time To Live Days', type: 'string' },
                ],
            ],
        },
    };

    const formatedProducts = formattedProductsForCSV(products);
    formatedProducts.forEach(product => {
        const { id, imageUrl, barcode, status, productType, price, currency, name, owner, isPrebooked, timeToLiveDays } = product;
        excelExport.sheet.data.push([
            { value: id, type: 'string' },
            { value: imageUrl, type: 'string' },
            { value: barcode, type: 'string' },
            { value: name, type: 'string' },
            { value: productType, type: 'string' },
            { value: price, type: 'number' },
            { value: currency, type: 'string' },
            { value: status, type: 'string' },
            { value: owner, type: 'string' },
            { value: isPrebooked, type: 'string' },
            { value: timeToLiveDays, type: 'string' },
        ]);
    });
    return excelExport;
};
