import React, { useEffect, useState } from 'react';
import { Box, MenuItem, FormControl, Select } from '@mui/material';
import FilterAltIcon from '@mui/icons-material/FilterAlt';

export default function SingleSelectFilter({ defaultValue, options, handleFilter }) {
    const [selection, setSelection] = useState(defaultValue); // The defaultValue should be a value property of one of the options

    useEffect(() => {
        handleFilter(selection);
    }, [selection, handleFilter]);

    const handleChange = event => {
        setSelection(event.target.value);
    };

    return (
        <Box sx={{ minWidth: '15rem' }}>
            <FormControl fullWidth size="small">
                <Select
                    id={defaultValue}
                    IconComponent={FilterAltIcon}
                    value={selection}
                    onChange={handleChange}
                    sx={{
                        '.MuiOutlinedInput-notchedOutline': {
                            borderColor: '#9599a3',
                            borderRadius: '12px',
                        },
                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                            border: '1px solid #118bd6',
                        },
                        '&:hover .MuiOutlinedInput-notchedOutline': {
                            borderColor: 'powderblue',
                        },
                        '.MuiSelect-icon': {
                            transform: 'rotate(0deg)',
                        },
                    }}
                >
                    {options
                        ? options.map(option => (
                              <MenuItem key={option.label} value={option.value}>
                                  {option.label}
                              </MenuItem>
                          ))
                        : null}
                </Select>
            </FormControl>
        </Box>
    );
}
