import React from 'react';
import { TabBar, TabItem } from '@storaensods/seeds-react';

const TabMenu = ({ activeMenu, active, t }) => {
    return (
        <TabBar className="pl-4 not-printable">
            <TabItem isActive={active === 'products'} onClick={() => activeMenu('products')}>
                {t('products')}
            </TabItem>
            <TabItem isActive={active === 'locations'} onClick={() => activeMenu('locations')}>
                {t('locations')}
            </TabItem>
            <TabItem isActive={active === 'cabinets'} onClick={() => activeMenu('cabinets')}>
                {t('cabinets')}
            </TabItem>
        </TabBar>
    );
};

export default TabMenu;
