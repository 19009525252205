export const showInventoryErrorAlarmIcon = () => {
    return dispatch =>
        dispatch({
            type: 'SHOW_INVENTORY_ALARMICON',
        });
};

export const hideInventoryErrorAlarmIcon = () => {
    return dispatch =>
        dispatch({
            type: 'HIDE_INVENTORY_ALARMICON',
        });
};
