import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { fetchCabinets, fetchLocations } from '../../actions/cabinets.js';
import Reports from './reports';
import {
    fetchDashboardData,
    fetchDashboardChartData,
    fetchCustomCubeQuery,
    resetCustomQueryData,
    resetAnalyticsData,
    fetchWasteDashboardData,
    fetchDiscountDashboardData,
    fetchConsumerResponseDashboardData,
} from '../../actions/analytics.js';
import { Spinner } from '@storaensods/seeds-react';
import { Dashboard } from './dashboard.js';
//import { CustomQuery } from './customQuery.js';
import './analytics.css';
import './react-toggle.css';

/**
 * Analytics page component
 * @param {function} fetchCabinets Redux action
 * @param {object} cabinets Redux products state
 */
export class Analytics extends Component {
    constructor(props) {
        super(props);
        this.state = {
            panel: 'dashboard',
            view: 'sales',
        };
    }

    componentDidMount() {
        // cabinets have not been fetched
        if (!this.props.cabinets.fetched && !this.props.cabinets.isFetching) {
            this.props.fetchCabinets();
        }
        // if locations have not been fetched
        if (!this.props.cabinets.locationsFetched && !this.props.cabinets.locationsIsFetching) {
            this.props.fetchLocations();
        }
    }

    changePanel = panel => {
        return this.setState(prevState => ({
            ...prevState,
            panel: panel,
        }));
    };

    render() {
        const { t } = this.props;

        if (this.props.cabinets.isFetching) {
            return (
                <div className="d-flex justify-content-center dashboard-spinner">
                    <div>
                        <Spinner style={{ margin: '0 auto' }} />
                        {t('loading')}
                    </div>
                </div>
            );
        }

        return (
            <div className="container-fluid">
                {this.state.panel === 'dashboard' && (
                    <Dashboard
                        t={t}
                        fetchDashboardData={this.props.fetchDashboardData}
                        fetchDashboardChartData={this.props.fetchDashboardChartData}
                        dashboard={this.props.analytics.dashboard}
                        locations={this.props.cabinets.locations}
                        cabinets={this.props.cabinets.cabinets}
                        changePanel={this.changePanel}
                        fetchWasteDashboardData={this.props.fetchWasteDashboardData}
                        fetchDiscountDashboardData={this.props.fetchDiscountDashboardData}
                        fetchConsumerResponseDashboardData={this.props.fetchConsumerResponseDashboardData}
                        wasteDashboard={this.props.analytics.wasteDashboard}
                        consumerResponseDashboard={this.props.analytics.consumerResponseDashboard}
                        discountDashboardState={this.props.analytics.discountDashboard}
                        chart={this.props.chart}
                        resetAnalyticsData={this.props.resetAnalyticsData}
                    />
                )}

                {this.state.panel === 'customQuery' && (
                    <Fragment>
                        <Reports
                            t={t}
                            fetchCustomCubeQuery={this.props.fetchCustomCubeQuery}
                            custom={this.props.analytics.custom}
                            resetCustomQueryData={this.props.resetCustomQueryData}
                            changePanel={this.changePanel}
                            cabinets={this.props.cabinets.cabinets}
                            locations={this.props.cabinets.locations}
                        />
                        {/*
                        <CustomQuery
                            t={t}
                            fetchCustomCubeQuery={this.props.fetchCustomCubeQuery}
                            custom={this.props.analytics.custom}
                            resetCustomQueryData={this.props.resetCustomQueryData}
                            changePanel={this.changePanel}
                            cabinets={this.props.cabinets.cabinets}
                            locations={this.props.cabinets.locations}
                        />
                        */}
                    </Fragment>
                )}
            </div>
        );
    }
}

export default withRouter(
    connect(
        state => ({
            cabinets: state.cabinets,
            analytics: state.analytics,
            chart: state.analytics.chart,
        }),
        {
            fetchCabinets,
            fetchLocations,
            fetchDashboardData,
            fetchDashboardChartData,
            fetchCustomCubeQuery,
            resetCustomQueryData,
            resetAnalyticsData,
            fetchWasteDashboardData,
            fetchDiscountDashboardData,
            fetchConsumerResponseDashboardData,
        }
    )(translate('main')(Analytics))
);
