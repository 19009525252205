import {
    createProductCategory as createProductCategoryHttpRequest,
    fetchProductCategories as fetchProductCategoriesHttpRequest,
    updateProductCategory as updateProductCategoryHttpRequest,
    deleteProductCategory as deleteProductCategoryHttpRequest,
} from '../api.js';

export const REQUEST_PRODUCT_CATEGORIES = 'REQUEST_PRODUCT_CATEGORIES';
export const RECEIVE_PRODUCT_CATEGORIES = 'RECEIVE_PRODUCT_CATEGORIES';
export const RECEIVE_PRODUCT_CATEGORIES_ERROR = 'RECEIVE_PRODUCT_CATEGORIES_ERROR';

export const REQUEST_CREATE_PRODUCT_CATEGORY = 'REQUEST_CREATE_PRODUCT_CATEGORY';
export const RECEIVE_CREATE_PRODUCT_CATEGORY = 'RECEIVE_CREATE_PRODUCT_CATEGORY';
export const RECEIVE_CREATE_PRODUCT_CATEGORY_ERROR = 'RECEIVE_CREATE_PRODUCT_CATEGORY_ERROR';

export const REQUEST_UPDATE_PRODUCT_CATEGORY = 'REQUEST_UPDATE_PRODUCT_CATEGORY';
export const RECEIVE_UPDATE_PRODUCT_CATEGORY = 'RECEIVE_UPDATE_PRODUCT_CATEGORY';
export const RECEIVE_UPDATE_PRODUCT_CATEGORY_ERROR = 'RECEIVE_UPDATE_PRODUCT_CATEGORY_ERROR';

export const REQUEST_DELETE_PRODUCT_CATEGORY = 'REQUEST_DELETE_PRODUCT_CATEGORY';
export const RECEIVE_DELETE_PRODUCT_CATEGORY = 'RECEIVE_DELETE_PRODUCT_CATEGORY';
export const RECEIVE_DELETE_PRODUCT_CATEGORY_ERROR = 'RECEIVE_DELETE_PRODUCT_CATEGORY_ERROR';

export const DISMISS_PRODUCT_CATEGORY_RESULT = 'DISMISS_PRODUCT_CATEGORY_RESULT';

export const RESET_PRODUCT_CATEGORIES = 'RESET_PRODUCT_CATEGORIES';

export const SEARCH_PRODUCT_CATEGORY_QUERY = 'SEARCH_PRODUCT_CATEGORY_QUERY';

/**
 * Request product Categories Redux action creator
 */
function requestProductCategories() {
    return {
        type: REQUEST_PRODUCT_CATEGORIES,
    };
}

/**
 * Receive product categories Redux action creator
 * @param {array} productCategories Array of product categories received from root api
 */
function receiveProductCategories(productCategories) {
    return {
        type: RECEIVE_PRODUCT_CATEGORIES,
        productCategories,
    };
}

/**
 * Receive product categories error Redux action creator
 * @param {Error} error The error object created in api.js
 */
function receiveProductCategoriesError(error) {
    return {
        type: RECEIVE_PRODUCT_CATEGORIES_ERROR,
        error: error,
    };
}

/**
 * Thunk action for fetching product categories
 */
export function fetchProductCategories() {
    return (dispatch, getState) => {
        dispatch(requestProductCategories());

        const user = getState().user;
        return fetchProductCategoriesHttpRequest(user.group, user.accessToken)
            .then(response => dispatch(receiveProductCategories(response)))
            .catch(error => dispatch(receiveProductCategoriesError(error)));
    };
}

/**
 * Request create product category Redux action creator
 */
function requestCreateProductCategory() {
    return {
        type: REQUEST_CREATE_PRODUCT_CATEGORY,
    };
}

/**
 * Receive create product category Redux action creator
 * @param {object} productCategory The new product category object that is created. Coming from root API.
 */
function receiveCreateProductCategory(productCategory) {
    return {
        type: RECEIVE_CREATE_PRODUCT_CATEGORY,
        productCategory,
    };
}

/**
 * Receive product category error Redux action creator
 * @param {Error} error The Error object received from api.js while creating a new product category
 */
function receiveCreateProductCategoryError(error) {
    return {
        type: RECEIVE_CREATE_PRODUCT_CATEGORY_ERROR,
        error,
    };
}

/**
 * Thunk action for creating a new product category
 * @param {object} productCategory The new product category object to be created in db.
 */
export function createProductCategory(productCategory) {
    return (dispatch, getState) => {
        dispatch(requestCreateProductCategory());

        const user = getState().user;
        return createProductCategoryHttpRequest(productCategory, user.group, user.accessToken)
            .then(createdProductCategory => dispatch(receiveCreateProductCategory(createdProductCategory)))
            .catch(error => dispatch(receiveCreateProductCategoryError(error)));
    };
}

/**
 * Request update product category Redux action creator
 */
function requestUpdateProductCategory() {
    return {
        type: REQUEST_UPDATE_PRODUCT_CATEGORY,
    };
}

/**
 * Receive update product category Redux action creator
 * @param {object} productCategory The updated product category object received from root api
 */
function receiveUpdateProductCategories(productCategory) {
    return {
        type: RECEIVE_UPDATE_PRODUCT_CATEGORY,
        productCategory,
    };
}

/**
 * Receive update product category error Redux action creator
 * @param {Error} error The error object received from api.js while handling update product category
 */
function receiveUpdateProductCategoryError(error) {
    return {
        type: RECEIVE_UPDATE_PRODUCT_CATEGORY_ERROR,
        error,
    };
}

/**
 * Thunk action for updating a product category
 * @param {string} id The id of the product category which needs to be updated
 * @param {object} productCategoryToBeUpdated The product category object to be updated
 */
export function updateProductCategory(id, productCategoryToBeUpdated) {
    return (dispatch, getState) => {
        dispatch(requestUpdateProductCategory());

        const user = getState().user;
        return updateProductCategoryHttpRequest(id, productCategoryToBeUpdated, user.group, user.accessToken)
            .then(updatedProductCategory => dispatch(receiveUpdateProductCategories(updatedProductCategory)))
            .catch(error => dispatch(receiveUpdateProductCategoryError(error)));
    };
}

/**
 * Request inactive product category Redux action creator
 */
function requestDeleteProductCategory() {
    return {
        type: REQUEST_DELETE_PRODUCT_CATEGORY,
    };
}

/**
 * Receive inactive product category Redux action creator
 * @param {string} id Th eid of the deleted vat category
 */
function receiveDeleteProductCategories(id) {
    return {
        type: RECEIVE_DELETE_PRODUCT_CATEGORY,
        id,
    };
}

/**
 * Receive inactive product category error Redux action creator
 * @param {Error} error The error received from api.js. Normally received when the product category is associated with a product and root api rejects the request.
 */
function receiveDeleteProductCategoryError(error) {
    return {
        type: RECEIVE_DELETE_PRODUCT_CATEGORY_ERROR,
        error,
    };
}

/**
 * Thunk action for inactivating a vat category
 * @param {number} id The id of the product category to be inactivated
 */
export function deleteProductCategory(id) {
    return (dispatch, getState) => {
        dispatch(requestDeleteProductCategory());

        const user = getState().user;
        return deleteProductCategoryHttpRequest(id, user.group, user.accessToken)
            .then(id => dispatch(receiveDeleteProductCategories(id)))
            .catch(error => dispatch(receiveDeleteProductCategoryError(error)));
    };
}

/**
 * Dismiss results of product category requests Redux action creator
 */
export function dismissProductCategoryResults() {
    return {
        type: DISMISS_PRODUCT_CATEGORY_RESULT,
    };
}

/**
 * Resets product categories state to initial state
 */
function resetProductCategories() {
    return {
        type: RESET_PRODUCT_CATEGORIES,
    };
}

/**
 * Thunk action creator for refreshing product categories state
 */
export function refreshProductCategories() {
    return dispatch => {
        dispatch(resetProductCategories());
        dispatch(fetchProductCategories());
    };
}

/**
 * Thunk action for handling product category search
 */
export function productCategorySearchQuery(query) {
    return dispatch => {
        dispatch({
            type: SEARCH_PRODUCT_CATEGORY_QUERY,
            query,
        });
    };
}
