import React, { useState, useEffect, useRef } from 'react';
import { Button, Modal, Input } from '@storaensods/seeds-react';
import { Row, Col } from 'reactstrap';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { fetchVatCategories, createVatCategory, updateVatCategory, deleteVatCategory, dismissVatCategoryResults } from '../../actions/vatCategories';
import FetchingAlert from '../fetching/fetchingAlert.js';
import DeletePrompt from '../deletePrompt/DeletePrompt.jsx';
import { showNotification } from '../toastNotification/toastNotification.js';
import VatCategorySearchbar from './vatCategorySearchbar';
import VatCards from './VatCards';

import './vatCategories.css';

/**
 * The Entry point of the VAT category CRUD UI
 * @param {function} props.t i18n translator function
 * @param {object} props.vatCategories The Redux state for VAT categories CRUD
 * @param {Boolean} props.isAdmin From Redux state. Indicates if the user has admin privileges
 * @param {function} props.fetchVatCategories From Redux state. Thunk action for fetching VAT categories
 * @param {function} props.createVatCategory From Redux state. Thunk action for creating a new VAT category
 * @param {function} props.updateVatCategory From Redux state. Thunk action for updating a VAT category
 * @param {function} props.deleteVatCategory From Redux state. Thunk action for deleting a vat category
 * @param {function} props.dismissVatCategoryResults From Redux state. Dismisses the VAT category results.
 */
export function VatCategories(props) {
    const {
        t,
        vatCategories,
        searchQuery,
        isAdmin,
        fetchVatCategories,
        createVatCategory,
        updateVatCategory,
        deleteVatCategory,
        dismissVatCategoryResults,
    } = props;

    // Hooks for activating new/edit VT category modal and set its mode to 'NEW' or 'EDIT'
    const [activateModal, setActivateModal] = useState(false);
    const [mode, setModalMode] = useState();

    // Hooks for setting items to be deleted or edited
    const [editItem, setEditItem] = useState(null);
    const [deleteItem, setDeleteItem] = useState(null);

    // Hook for showing confirm delete prompt
    const [showDeletePrompt, setShowDeletePrompt] = useState(false);

    /**
     * Gets the VAT categories and handles the filtering for the search bar
     */
    function getVatCategoryItems() {
        if (vatCategories) {
            if (vatCategories.vatCategories) {
                const selectedVatCategories = vatCategories.vatCategories.filter(category => {
                    if (!searchQuery) {
                        return true;
                    } else {
                        return (category.name || '').toLowerCase().indexOf(searchQuery.toLowerCase()) !== -1;
                    }
                });
                return selectedVatCategories;
            }
        }
    }

    const vatCategoryItems = getVatCategoryItems();

    // UseEffect hook for fetching the data after component is mounted in case the data is not loaded yet
    useEffect(() => {
        if (!vatCategoryItems) {
            fetchVatCategories();
        }
    }, [vatCategoryItems, fetchVatCategories]);

    // The hook to monitor deleteItem value and open the prompt.
    // deleteItem value will be set if the delete icon is clicked on the ui.
    useEffect(() => {
        if (deleteItem) {
            setShowDeletePrompt(true);
        }
    }, [deleteItem]);

    /**
     * Call back function for cancel button in confirm delete item prompt.
     * resets the deleteItem value and closes the prompt
     */
    function onDeleteCanceled() {
        setDeleteItem(null);
        setShowDeletePrompt(false);
    }

    /**
     * Call back function for confirm button in confirm delete prompt.
     * sends a Thunk action to delete the item, resets the deleteItem value and closes the prompt.
     */
    function onDeleteConfirmed() {
        deleteVatCategory(deleteItem.id);
        setDeleteItem(null);
        setShowDeletePrompt(false);
    }

    /**
     * Call back function to pass to Toastify toast and react on its changes.
     * In this case, it sends a redux action to dismiss the results of the request.
     * @param {number} state The state of the toast returned from Toastify toasts. Will be '1' when opened and '0' when closed.
     */
    function onNotificationToastChanged(state) {
        if (state === 1) {
            dismissVatCategoryResults();
        }
    }

    /**
     * Call back function to open the modal for a new product
     */
    function openModal() {
        setModalMode('NEW');
        setActivateModal(true);
    }

    return (
        <div className="vat-categories">
            <div>
                <VatCategoryModal
                    setActive={setActivateModal}
                    active={activateModal}
                    t={t}
                    mode={mode}
                    editItem={{ ...editItem }} // Creating a new object every time so if an item clicked twice in a row, the shallow comparisons between two objects, happening in the hooks, does not make bugs.
                    setEditItem={setEditItem}
                    createVatCategory={createVatCategory}
                    updateVatCategory={updateVatCategory}
                />
            </div>
            <div>
                <DeletePrompt
                    showDeletePrompt={showDeletePrompt}
                    onDeleteClicked={onDeleteConfirmed}
                    onCancelClicked={onDeleteCanceled}
                    message={t('vatCategoryPromptMessage')}
                    t={t}
                />
            </div>
            {vatCategories.isRequestingFinished &&
                vatCategories.isError &&
                showNotification(
                    t('vatErrorRequesting'),
                    vatCategories.isDeletingFailed ? t('vatDeleteRejected') : t('vatRequestRejected'),
                    'error',
                    onNotificationToastChanged
                )}
            {vatCategories.isRequestingFinished && !vatCategories.isError && showNotification(null, t('requestSuccess'), 'success', onNotificationToastChanged)}
            <Row className="my-3">
                <Col className="catalog-top-row">
                    <VatCategorySearchbar />
                    <div className="catalog-top-row-end-elements hide-in-mb">
                        <Button className="catalog-top-row-element" type="positive" icon="add_circle" onClick={openModal} disabled={!isAdmin}>
                            {t('createNewVatCategory')}
                        </Button>
                    </div>
                </Col>
            </Row>
            {vatCategories.isFetching || !vatCategories.vatCategories || (vatCategories.vatCategories && vatCategories.vatCategories.length === 0) ? (
                <FetchingAlert
                    fetchingMessage={t('fetchingVatCategories')}
                    noDataMessage={t('noVatCategories')}
                    errorMessage={t('vatCategoriesFetchError')}
                    isError={vatCategories.isError}
                    noData={vatCategories.vatCategories && vatCategories.vatCategories.length === 0 && !vatCategories.isFetching}
                />
            ) : vatCategoryItems.length ? (
                <VatCategoryList
                    t={t}
                    vatCategoryList={vatCategoryItems}
                    setModalMode={setModalMode}
                    setActivateModal={setActivateModal}
                    setEditItem={setEditItem}
                    setDeleteItem={setDeleteItem}
                    isAdmin={isAdmin}
                />
            ) : (
                <div className="no-results">{t('noResults')}</div>
            )}
        </div>
    );
}

/**
 * Uses SEEDS data tables to show the list of VAT categories
 * @param {function} props.t i18n translator function
 * @param {Array} props.vatCategoryList The list of VAT category items
 * @param {function} props.setModalMode React hook function to set the modal mode, 'EDIT' or 'NEW'
 * @param {function} props.setActivateModal React hook function to open the modal
 * @param {function} props.setEditItem React hook function to set the item to be edited
 * @param {function} props.setDeleteItem React hook function to set the item to be deleted
 * @param {Boolean} props.isAdmin Determins if the user has Create/Update/Delete permissions
 */
function VatCategoryList(props) {
    const { vatCategoryList, setModalMode, setActivateModal, setEditItem, setDeleteItem, isAdmin } = props;

    /**
     * Call back function to react if the edit icon in front of an item is clicked
     * Sets the item to be edited, sets the modal mode and opens the modal
     * @param {object} item The item to be edited
     */
    function onEditButtonClicked(item) {
        setEditItem(item);
        setModalMode('EDIT');
        setActivateModal(true);
    }

    /**
     * Call back function to react when the delete icon is clicked  in front of an item.
     * Sets the item to be deleted using the react hook
     * @param {object} item The item to be deleted
     */
    function onDeleteButtonClicked(item) {
        setDeleteItem({ ...item });
    }

    return (
        <div className="VATCardContainer">
            <VatCards isAdmin={isAdmin} data={vatCategoryList} deleteAction={onDeleteButtonClicked} editAction={onEditButtonClicked} />
        </div>
    );
}

/**
 * The modal for entering new VAT category information or edit an existing one
 * @param {function} props.t i18n translator function
 * @param {Boolean} props.active Indicates if the modal should be active (open) or not
 * @param {function} props.setActive React hook function to activate/deactivate the modal
 * @param {String} props.mode Indicated the mode of the modal. cab be 'NEW' or 'EDIT'
 * @param {Object} props.editItem The VAT category to be edited
 * @param {function} props.setEditItem React hook function to set the item to be edited
 * @param {function} props.createVatCategory Thunk action function for creating a new VAT category
 * @param {function} props.updateVatCategory Thunk action function for updating a VAT category
 */
function VatCategoryModal(props) {
    const { t, active, setActive, mode, editItem, setEditItem, createVatCategory, updateVatCategory } = props;

    // React hooks to set the values of tax categories and show their value in the input components
    const [vatCategoryName, setVatCategoryName] = useState('');
    const [vatCategoryValue, setVatCategoryValue] = useState('');
    const formattedVatCategoryValue = useRef();

    // React hooks to set the validity of entered data for name and taxValue of entered data
    const [vatCategoryNameValid, setVatCategoryNameValid] = useState(false);
    const [vatCategoryValueValid, setVatCategoryValueValid] = useState(false);

    // React hook to enable/disable saving changes in case enetered data is valid/invalid
    const [dontSave, setDontSave] = useState(false);

    /**
     * The Hook for checking validity of the entered data
     */
    useEffect(() => {
        if (
            typeof vatCategoryName === 'string' &&
            vatCategoryName.length > 2 &&
            vatCategoryName.length < 51 &&
            isNaN(Number(vatCategoryName)) // Not to allow a number for the name
        ) {
            setVatCategoryNameValid(true);
        } else {
            setVatCategoryNameValid(false);
        }

        // Ensure correct decimal point format
        formattedVatCategoryValue.current = vatCategoryValue.replace(',', '.');

        if (
            formattedVatCategoryValue.current.length > 0 &&
            !isNaN(Number(formattedVatCategoryValue.current)) &&
            0 <= Number(formattedVatCategoryValue.current) &&
            Number(formattedVatCategoryValue.current) <= 100 &&
            !formattedVatCategoryValue.current.includes('e') // for e1+1 in not NAN!
        ) {
            setVatCategoryValueValid(true);
        } else {
            setVatCategoryValueValid(false);
        }
    }, [vatCategoryName, vatCategoryValue]);

    /**
     * The hook for setting the values in value hooks in case a new edit item is received
     */
    useEffect(() => {
        setVatCategoryName(editItem.name || '');
        setVatCategoryValue(editItem.taxValue ? editItem.taxValue.toString() : '');
    }, [editItem]);

    /**
     * Call back function to be called when save button is clicked in the modal
     * in case the input values are not valid, it will set dontSave to true and the color of the button turns red and does not react.
     */
    function onSaveButtonClicked() {
        if (!vatCategoryNameValid || !vatCategoryValueValid) {
            setDontSave(true);
            return;
        }

        // get value and form the body
        const itemBody = {
            name: vatCategoryName,
            taxValue: formattedVatCategoryValue.current,
        };

        // send them to be saved
        if (mode === 'NEW') {
            // send to create
            createVatCategory(itemBody);
        } else if (mode === 'EDIT') {
            //send to update
            updateVatCategory(editItem.id, itemBody);
        }
        // Close modal an clean up values
        onModalClosed();
    }

    /**
     * Cleans up the name and tax value from the states hooks and closes the modal
     */
    function onModalClosed() {
        setVatCategoryName('');
        setVatCategoryValue('');
        setEditItem(null);
        setDontSave(false);
        setActive(false);
    }

    return (
        <div>
            <Modal
                active={active}
                actions={[
                    {
                        label: t('save'),
                        onClick: () => onSaveButtonClicked(),
                        type: dontSave ? 'negative' : 'primary',
                    },
                ]}
                onClose={() => onModalClosed()}
                title={mode === 'NEW' ? t('newVatCategoryModalTitle') : mode === 'NEW' ? t('editVatCategoryModalTitle') : t('vatCategory')}
            >
                <div className="vat-category-modal">
                    <div className="vat-name-input">
                        <span>{t('vatCategoryName')}</span>
                        <Input
                            helpText={t('requiredText') + '3 - 50'}
                            placeholder="Food"
                            valid={vatCategoryNameValid}
                            invalid={!vatCategoryNameValid}
                            onChange={event => setVatCategoryName(event.target.value)}
                            value={vatCategoryName}
                        ></Input>
                    </div>
                    <div className="vat-value-input">
                        <span>{t('vatCategoryValue')}</span>
                        <Input
                            helpText={t('requiredPercentage')}
                            placeholder="10%"
                            valid={vatCategoryValueValid}
                            invalid={!vatCategoryValueValid}
                            onChange={event => setVatCategoryValue(event.target.value)}
                            value={vatCategoryValue}
                        ></Input>
                    </div>
                </div>
            </Modal>
        </div>
    );
}

export default connect(
    state => ({
        vatCategories: state.vatCategories,
        searchQuery: state.vatCategories.searchQuery,
        isAdmin: state && state.user && state.user.isAdmin,
    }),
    {
        fetchVatCategories,
        createVatCategory,
        updateVatCategory,
        deleteVatCategory,
        dismissVatCategoryResults,
    }
)(translate('main')(VatCategories));
