import React, { Fragment, useEffect, useState } from 'react';

import SchemaControlBar from './schemaControlBar';
import { Button, InfoBanner } from '../../commons/utils';
import { _13digitBarcode } from './../utils';
import icons from './../../../icons/icomoon.svg';
import PlanogramImageStacking from '../modals/planogramImageStacking';
import ConfirmationModal from '../modals/confirmationModal';
import ApiRequestModal from '../modals/ApiRequestModal';
import PlanogramImageModal from '../modals/planogramImageModal';

const MAX_CABINET_SHELVES_COUNT = 8;
const MAX_CABINET_SLOT_COUNT = 8;

const Planogram = ({
    toggleViewMode,
    activeViewMode,
    t,
    cabinetRefillSchema,
    planogramProductData,
    allProducts,
    updateCabinetRefillSchema,
    selectedCabinet,
    fetchRefillRules,
    showViewModeToggle,
    refillPlan,
    allowEditing = true,
    saveRefillPlanogram,
    planogramImageInStore,
    uploadPlanogramImage,
    changeRefillPlanogramImageData,
    isAdmin
    })=>{
    const [planogramData, setPlanogramData] = useState({});
    const [allProductsData, setAllProductsData] = useState([]);
    const [imageStackingModal, setImageStackingModal] = useState({ open: false, slotData: {}, shelfNumber: null });
    const [productFilter, setProductFilter] = useState('all');
    const [prestine, setPrestine] = useState(true);
    const [productSearchQuery, setProductSearchQuery] = useState('');
    const [savePlanogramModal, setSavePlanogramModal] = useState(false);
    const [deletePlanogramModal, setDeletePlanogramModal] = useState(false);
    const [openPlanogramImageModal, setOpenPlanogramImagemModal] = useState(false);
    const [planogramImageUrl, setPlanogramImageUrl] = useState(null);

    useEffect(() => {
        if (!allProducts.length) return;
        let products = [...allProducts];
        products = products
            .filter(product => product.status === 2)
            .map(product => {
                return { ...product, barcode: _13digitBarcode(product.barcodeType, product.barcode) };
            });

        setAllProductsData(products);
    }, [allProducts]);

    useEffect(()=>{
        setPlanogramData(planogramProductData)
    },[planogramProductData])

    useEffect(()=>{ 
        const {rule} = cabinetRefillSchema
        const imageUrl = rule.planogramImageUrl
        setPlanogramImageUrl(imageUrl)
    },[cabinetRefillSchema])
        
    /**
     *
     * @param {string} action plus | minus
     * @returns
     */
    const onShelvesCountChange = action => {
        if (!isAdmin) return;
        let data = { ...planogramData };
        if (action === 'plus') {
            if (data.shelvesCount >= MAX_CABINET_SHELVES_COUNT) return;
            data.shelvesCount += 1;
            data.shelves.push({
                shelf: data.shelvesCount,
                slotCount: 5,
                productData: [],
            });
        } else {
            if (data.shelvesCount === 1) return;
            //always remove the last shelf in array
            //remove all the products, if have been placed in the shelf already
            data.shelves = data.shelves.filter(shelf => shelf.shelf !== data.shelvesCount);
            data.shelvesCount -= 1;
        }
        setPrestine(false);
        setPlanogramData(data);
    };

    /**
     *
     * @param {*} shelfNumber 1|2|3...8
     * @param {*} action 'plus' | 'minus'
     * @returns
     */
    const onSlotCountChange = (shelfNumber, action) => {
        if (!isAdmin) return;

        let data = { ...planogramData };
        let selectedShelf = data.shelves.find(shelf => shelf.shelf === shelfNumber);
        if (action === 'plus') {
            if (selectedShelf.slotCount >= MAX_CABINET_SLOT_COUNT) return;
            selectedShelf.slotCount += 1;
        } else {
            if (selectedShelf.slotCount === 0) return;

            //remove the last slot and product data from the shelf
            const filteredSlotsAfterRemoval = selectedShelf.productData.filter(slotData => slotData.slot !== selectedShelf.slotCount);

            selectedShelf.productData = filteredSlotsAfterRemoval;
            selectedShelf.slotCount -= 1;
        }
        setPrestine(false);
        setPlanogramData(data);
    };

    const onProductDragged = (e, product) => {
        e.dataTransfer.setData('text/plain', JSON.stringify(product));
    };

    /**
     *
     * @param {*} e drag and drop event
     * @param {*} shelf the shelf number where product is dropped
     * @param {*} slot the slot number with in the shelf
     * @returns
     */
    const onProductDropped = (e, shelf, slot) => {
        if (!isAdmin) return;
        const transferData = e.dataTransfer.getData('text/plain');
        if (!transferData) return;

        const productDataDragged = JSON.parse(transferData);
        let data = { ...planogramData };
        let productData = data.shelves.find(s => s.shelf === shelf)?.productData;
        if (!productData.length || !productData) {
            productData.push({ slot, products: [productDataDragged.id] });
        } else {
            const selectedSlot = productData.find(s => s.slot === slot);

            if (selectedSlot) {
                if (selectedSlot.products && selectedSlot.products.length >= 8) {
                    return;
                }

                selectedSlot.products.push(productDataDragged.id);
            } else {
                productData.push({ slot, products: [productDataDragged.id] });
            }
        }
        setPrestine(false);
        setPlanogramData(data);
    };

    /**
     *
     * @param {*} e drag-and-drop event
     * @param {*} shelf the shelf which on which drag happened
     * @param {*} slot the slot that was dragged
     */
    const onCabinetSlotDragged = (e, shelf, slot) => {
        if (!isAdmin) return;
        e.dataTransfer.setData('text/plain', JSON.stringify({ shelf, slot }));
    };

    /**
     * when user drags slot and drop on a product
     * @param {*} e drang-and-drop event
     * @param {*} product product upon which the product was dropped
     * @returns
     */
    const slotDropOnProduct = (e, product) => {
        if (!isAdmin) return;
        const transferData = e.dataTransfer.getData('text/plain');
        if (!transferData) return;

        const { shelf, slot } = JSON.parse(transferData);
        const data = { ...planogramData };
        let selectedShelf = data.shelves.find(shelfData => shelfData.shelf === shelf);
        if (!selectedShelf) return;
        const selectedSlot = selectedShelf.productData.find(slotData => slotData.slot === slot);
        if (!selectedSlot) {
            selectedShelf.productData.push({ slot, products: [product.id] });
        } else {
            selectedShelf.productData = selectedShelf.productData.map(slotData => {
                if (slotData.slot !== slot) return slotData;

                return { slot, products: [...slotData.products, product.id] };
            });
        }
        setPrestine(false);
        setPlanogramData(data);
    };

    /**
     * when delete icon is clicked on slot
     * @param {*} shelf
     * @param {*} slot
     */
    const removeProductFromSlot = (shelf, slot) => {
        if (!isAdmin) return;
        if (!allowEditing) return;

        setPrestine(false);
        const data = { ...planogramData };

        let selectedShelf = data.shelves.find(shelfData => shelfData.shelf === shelf);
        const filterOutDeletedSlot = selectedShelf.productData.filter(slotData => slotData.slot !== slot);
        selectedShelf.productData = filterOutDeletedSlot;

        setPlanogramData(data);
    };

    /**
     * look for search query or allProducts || schema products filter and send the filtered product to view
     * @returns array of products
     */
    const getProductsForView = () => {
        if (productFilter === 'all' && !productSearchQuery) return allProductsData;

        let productsToShow = [];
        if (productFilter === 'schema') {
            const { rule } = cabinetRefillSchema;
            const schemaProductData = rule.productData || [];
            schemaProductData.forEach(schemaProduct => {
                const product = allProductsData.find(p => p.barcode === _13digitBarcode(schemaProduct.barcodeType, schemaProduct.barcode));
                if (product) {
                    productsToShow.push({
                        name: product.name,
                        barcode: product.barcode,
                        imageUrl: product.imageUrl,
                        id: product.id,
                    });
                }
            });
        } else {
            productsToShow = [...allProductsData];
        }

        if (!productSearchQuery) return productsToShow;

        const query = productSearchQuery.toString().toLowerCase();
        const filteredProducts = productsToShow.filter(product => {
            return (
                product.name
                    .toString()
                    .toLowerCase()
                    .includes(query) ||
                product.barcode
                    .toString()
                    .toLowerCase()
                    .includes(query)
            );
        });

        return filteredProducts;
    };

    /**
     * updates refill shema with planogram data. removes planogram if the request type is delete
     * @param {*} requestType save | delete
     * @returns
     */
    const sendPlanogramToApi = (requestType = 'save') => {
        const { rule } = cabinetRefillSchema;
        if (!rule) return;

        // if it's save reqest, show the confirmation modal
        if (requestType === 'save') {
            setSavePlanogramModal(true);
        }

        const productData = rule.productData || [];
        const schemaId = rule.id;
        const deviceCode = selectedCabinet.deviceCode;

        updateCabinetRefillSchema(
            {
                productData,
                planogram: requestType === 'save' ? planogramData : undefined,
                ...(planogramImageUrl && { planogramImageUrl }),
            },
            schemaId,
            deviceCode
        );
    };

    return (
        <Fragment>
            <ApiRequestModal
                isOpen={savePlanogramModal}
                data={cabinetRefillSchema}
                closeModal={()=>{
                    fetchRefillRules();
                    setSavePlanogramModal(false);
                }}
                successMessage={t('refill:planogramSaved')}
                t={t}
            />
            <PlanogramImageStacking
                isOpen={imageStackingModal.open}
                close={() => {
                    setImageStackingModal({ open: false, slotData: {}, shelfNumber: null });
                }}
                cabinetSlotData={imageStackingModal.slotData}
                shelfNumber={imageStackingModal.shelfNumber}
                allProducts={allProductsData}
            />
            <PlanogramImageModal
                isOpen={openPlanogramImageModal}
                closeModal={() => setOpenPlanogramImagemModal(false)}
                t={t}
                planogramImageInStore={planogramImageInStore}
                uploadPlanogramImage={uploadPlanogramImage}
                planogramData={planogramData}
                planogramImageUrl={planogramImageUrl}
                planogramImageData={imageUrl => {
                    setPrestine(false);
                    if (refillPlan) {
                        changeRefillPlanogramImageData(imageUrl);
                    } else {
                        setPlanogramImageUrl(imageUrl);
                    }
                }}
            />
            {allowEditing && (
                <SchemaControlBar
                    saveRefillSchema={() => {
                        if (refillPlan) {
                            saveRefillPlanogram(planogramData);
                        } else {
                            sendPlanogramToApi('save');
                        }
                    }}
                    deleteRefillSchema={() => setDeletePlanogramModal(true)}
                    schema={cabinetRefillSchema}
                    prestine={prestine}
                    searchQuery={query => setProductSearchQuery(query)}
                    t={t}
                    toggleViewMode={mode => {
                        toggleViewMode(mode);
                    }}
                    activeViewMode={activeViewMode}
                    showProductFilter={true}
                    productFilterChange={type => setProductFilter(type)}
                    showViewModeToggle={showViewModeToggle}
                    refillPlan={refillPlan}
                    isAdmin={isAdmin}
                />
            )}

            <ConfirmationModal
                isOpen={deletePlanogramModal}
                cancel={() => setDeletePlanogramModal(false)}
                message={t('deletePlanogram')}
                t={t}
                confirmDelete={() => sendPlanogramToApi('delete')}
            />

            <div className="planogram">
                <div className="planogram__figure">
                    {allowEditing && (
                        <Fragment>
                            <div>{t('refill:numberOfShelves')}</div>
                            <div className="planogram__setting">
                                <div className="planogram__setting-btn" onClick={() => onShelvesCountChange('minus')}>
                                    -
                                </div>
                                <input type="number" name="shelf count" value={planogramData.shelvesCount || 1} disabled />
                                <div className="planogram__setting-btn" onClick={() => onShelvesCountChange('plus')}>
                                    +
                                </div>
                            </div>
                        </Fragment>
                    )}

                    <div className="planogram__cabinet">
                        <div className="planogram__cabinet-screen">{t('refill:screen')}</div>
                        <div className="planogram__cabinet-shelves">
                            {(() => {
                                const allShelves = [];
                                for (let i = 1; i < planogramData.shelvesCount + 1; i++) {
                                    allShelves.push(
                                        <div className="planogram__cabinet-shelf" key={`helf-${i}`}>
                                            {allowEditing && (
                                                <Fragment>
                                                    <div
                                                        className="planogram__cabinet-shelf-btn"
                                                        onClick={() => {
                                                            onSlotCountChange(i, 'minus');
                                                        }}
                                                    >
                                                        -
                                                    </div>
                                                    <div
                                                        className="planogram__cabinet-shelf-btn"
                                                        onClick={() => {
                                                            onSlotCountChange(i, 'plus');
                                                        }}
                                                    >
                                                        +
                                                    </div>
                                                </Fragment>
                                            )}

                                            <div className="planogram__slots">
                                                {(() => {
                                                    const allSlots = [];
                                                    const shelf = planogramData.shelves.find(shelf => shelf.shelf === i);
                                                    for (let j = 1; j < shelf.slotCount + 1; j++) {
                                                        if (!shelf.productData.length) {
                                                            allSlots.push(
                                                                <div
                                                                    className="planogram__slots-item"
                                                                    draggable={true}
                                                                    key={`slot-${i}-${j}`}
                                                                    onDrop={e => onProductDropped(e, i, j)}
                                                                    onDragOver={e => e.preventDefault()}
                                                                    onDragStart={e => onCabinetSlotDragged(e, i, j)}
                                                                >
                                                                    {j}
                                                                </div>
                                                            );
                                                        } else {
                                                            const selectedSlot = shelf.productData.find(productSlot => productSlot.slot === j);
                                                            if (!selectedSlot) {
                                                                allSlots.push(
                                                                    <div
                                                                        className="planogram__slots-item"
                                                                        draggable={true}
                                                                        key={`slot-${i}-${j}`}
                                                                        onDrop={e => onProductDropped(e, i, j)}
                                                                        onDragOver={e => e.preventDefault()}
                                                                        onDragStart={e => onCabinetSlotDragged(e, i, j)}
                                                                    >
                                                                        {j}
                                                                    </div>
                                                                );
                                                            } else {
                                                                //only one product in the a slot
                                                                if (selectedSlot.products.length === 1) {
                                                                    const selectedProduct = allProductsData.find(
                                                                        product => product.id === selectedSlot.products[0]
                                                                    );
                                                                    allSlots.push(
                                                                        <div
                                                                            className="planogram__slots-item"
                                                                            draggable={true}
                                                                            key={`slot-${i}-${j}`}
                                                                            onDrop={e => onProductDropped(e, i, j)}
                                                                            onDragOver={e => e.preventDefault()}
                                                                            title={selectedProduct.name}
                                                                        >
                                                                            {selectedProduct.imageUrl ? (
                                                                                <img src={selectedProduct.imageUrl} alt="" />
                                                                            ) : (
                                                                                <svg className="planogram__slots-item-cube">
                                                                                    <use xlinkHref={`${icons}#icon-cube`}></use>
                                                                                </svg>
                                                                            )}
                                                                            {allowEditing && (
                                                                                <svg
                                                                                    className="planogram__slots-item-close"
                                                                                    onClick={() => removeProductFromSlot(i, j, selectedProduct)}
                                                                                >
                                                                                    <use xlinkHref={`${icons}#icon-trash-2`}></use>
                                                                                </svg>
                                                                            )}
                                                                        </div>
                                                                    );
                                                                } else {
                                                                    //mulitple products in the slot, show only the last added
                                                                    //show stack icon
                                                                    const lastAddedBarcode = selectedSlot.products[selectedSlot.products.length - 1];
                                                                    const selectedProduct = allProductsData.find(product => product.id === lastAddedBarcode);
                                                                    allSlots.push(
                                                                        <div
                                                                            className="planogram__slots-item"
                                                                            draggable={true}
                                                                            key={`slot-${i}-${j}`}
                                                                            onDrop={e => onProductDropped(e, i, j)}
                                                                            onDragOver={e => e.preventDefault()}
                                                                        >
                                                                            {selectedProduct.imageUrl ? (
                                                                                <img src={selectedProduct.imageUrl} alt="" />
                                                                            ) : (
                                                                                <svg className="planogram__slots-item-cube">
                                                                                    <use xlinkHref={`${icons}#icon-cube`}></use>
                                                                                </svg>
                                                                            )}

                                                                            <svg
                                                                                className="planogram__slots-item-stack"
                                                                                onClick={() =>
                                                                                    setImageStackingModal({
                                                                                        open: true,
                                                                                        slotData: selectedSlot,
                                                                                        shelfNumber: i,
                                                                                    })
                                                                                }
                                                                            >
                                                                                <use xlinkHref={`${icons}#icon-stack`}></use>
                                                                            </svg>
                                                                            {allowEditing && (
                                                                                <svg
                                                                                    className="planogram__slots-item-close"
                                                                                    onClick={() => removeProductFromSlot(i, j, selectedProduct)}
                                                                                >
                                                                                    <use xlinkHref={`${icons}#icon-trash-2`}></use>
                                                                                </svg>
                                                                            )}
                                                                            <span
                                                                                className="planogram__slots-item-count"
                                                                                onClick={() =>
                                                                                    setImageStackingModal({
                                                                                        open: true,
                                                                                        slotData: selectedSlot,
                                                                                        shelfNumber: i,
                                                                                    })
                                                                                }
                                                                            >
                                                                                {selectedSlot.products.length}
                                                                            </span>
                                                                        </div>
                                                                    );
                                                                }
                                                            }
                                                        }
                                                    }
                                                    return allSlots;
                                                })()}
                                            </div>
                                        </div>
                                    );
                                }
                                return allShelves
                            })()
                        }
                        </div> 
                        {
                            (()=>{
                                const {rule} = cabinetRefillSchema
                                let shouldShowPlanogramImage = (!refillPlan && rule.id && rule.planogram && Object.keys(rule.planogram)) || (refillPlan && allowEditing);
                               
                                if(shouldShowPlanogramImage){
                                    return(
                                        <div className="planogram__image">
                                            <InfoBanner 
                                                message={t('refill:planogramImageInfo')}
                                            />                                    
                                            <img src={planogramImageUrl} alt=""/>  
                                            {
                                                isAdmin && (
                                                    <Button type="add" text= {`${planogramImageUrl ? t('refill:editPlanogramImage'):t('refill:addPlanogramImage')}`} onClick={()=>setOpenPlanogramImagemModal(true)}/> 
                                                )
                                            }                                
                                            
                                        </div>
                                    )
                                }
                            })()                            
                        }
                    </div>
                </div>
                {allowEditing && (
                    <div className="planogram__products">
                        <div className="planogram__products-container">
                            <InfoBanner message={t('dragInPlanogram')} className="planogram__products-banner" />
                            <div className="planogram__products-items">
                                {getProductsForView().map(product => {
                                    return (
                                        <div
                                            className="planogram__products-item"
                                            onDragStart={e => onProductDragged(e, product)}
                                            onDragOver={e => e.preventDefault()}
                                            draggable={true}
                                            onDrop={e => slotDropOnProduct(e, product)}
                                            key={product.barcode}
                                        >
                                            {product.imageUrl ? (
                                                <img src={product.imageUrl} alt="" />
                                            ) : (
                                                <svg className="planogram__slots-item-noimg">
                                                    <use xlinkHref={`${icons}#icon-cube`}></use>
                                                </svg>
                                            )}

                                            <div className="planogram__products-barcode">{product.barcode}</div>
                                            <div className="planogram__products-name">{product.name}</div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </Fragment>
    );
};

export default Planogram;
