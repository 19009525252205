import React, { useState, Fragment, useEffect } from 'react';
import { Input, Icon, Button, Tag, Card, Modal, NavigationBar, TabItem } from '@storaensods/seeds-react';
import { Row, Col } from 'reactstrap';
import Toggle from 'react-toggle';

import ImageUpload from '../imageUpload/imageUpload';
import { InfoBanner, Toggle as CustomToggle } from '../commons/utils';

/**
 * Discount rule stops React component
 * @param {object} props Props
 * @param {object} props.rule Whole rule object
 * @param {object} props.cabinets Redux state object of cabinets
 * @param {object} props.products Redux state object of products
 * @param {object} props.productCategories Redux state object of product categories
 * @param {Function} props.removeScheduledDiscount No remove button displayed if not set or null
 * @param {Function} props.toggleDiscountStatus Function to activate/deactivate discount
 * @param {Function} [props.removeStop] Function to remove single rule stop
 * @param {function} props.t i18n translation function
 * @param {Boolean} props.isAdmin Flag for if user is admin
 * @param {Boolean} props.preview Flag for if preview mode is on
 * @param {function} props.handleDiscountEdit Function to handle discount's edit
 */
export default function DiscountRuleStops(props) {
    const { t, rule, preview, toggleDiscountStatus, handleDiscountEdit, imageUrl, imageMarkedAsDeleted, isAdmin } = props;
    const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
    const [deleteActive, setDeleteActive] = useState(false);
    const [detailsOpen, setDetailsOpen] = useState(false);
    // discount stops sorted by time before expiry and with hours and minutes before expiry
    const discountStops = rule.DiscountData.discountStops
        .sort((stopA, stopB) => {
            // arrange longer time before expiry to come before
            return stopB.minsBeforeExpiry - stopA.minsBeforeExpiry;
        })
        .map(stop => {
            let minutes = stop.minsBeforeExpiry;
            let hours = Math.floor(stop.minsBeforeExpiry / 60);
            let days = Math.floor(hours / 24);

            hours = hours - days * 24;
            minutes = minutes - days * 24 * 60 - hours * 60;
            return {
                ...stop,
                daysBeforeExpiry: days,
                hoursBeforeExpiry: hours,
                leftoverMinsBeforeExpiry: minutes,
            };
        });

    const handleDiscountStatusChange = () => {
        let Status = rule?.Status === 1 ? 2 : 1;
        if (deleteActive) Status = 3;
        const payload = preview
            ? Status
            : {
                  ...rule,
                  Status,
              };
        toggleDiscountStatus(payload);
        setConfirmationDialogOpen(false);
    };

    const handleDiscountDelete = () => {
        setDeleteActive(true);
        setConfirmationDialogOpen(true);
    };

    const getModalTitle = () => {
        if (!deleteActive) {
            switch (rule.Status) {
                case 1:
                    return t('sureDeactivateDiscountRule');
                case 2:
                    return t('sureActivateDiscountRule');
                default:
                    break;
            }
        } else return t('sureDeleteDiscountRule');
    };

    if (imageUrl || discountStops.length > 0) {
        const getCorrectImagePath = () => {
            if (preview && imageUrl) return imageUrl;
            else if (!preview && rule.DiscountData.imageUrl) return rule.DiscountData.imageUrl;
            else if (preview && rule.DiscountData.imageUrl && !imageMarkedAsDeleted) return rule.DiscountData.imageUrl;
            else if (preview && imageMarkedAsDeleted && imageMarkedAsDeleted.id === rule.Id) return null;
        };
        return (
            <Card
                key={rule.Id}
                title={rule?.Name}
                subtitle={
                    <Fragment>
                        {!rule.version && isAdmin && (
                            <div className="mr-2">
                                <Button icon="edit" type="secondary" onClick={() => handleDiscountEdit(rule)}>
                                    {t('edit')}
                                </Button>
                            </div>
                        )}
                        {!preview && isAdmin && (
                            <div>
                                <Button icon="delete" type="secondary" onClick={() => handleDiscountDelete()}>
                                    {t('delete')}
                                </Button>
                            </div>
                        )}
                    </Fragment>
                }
            >
                <div className="mt-2 DiscountContainerCard expiryDiscountContainerCard">
                    <Modal
                        actions={[
                            {
                                label: t('confirm'),
                                onClick: () => handleDiscountStatusChange(),
                            },
                            {
                                label: t('cancel'),
                                onClick: () => setConfirmationDialogOpen(false),
                            },
                        ]}
                        active={confirmationDialogOpen}
                        title={getModalTitle()}
                        onClose={() => setConfirmationDialogOpen(false)}
                    >
                        <h2>{rule?.Name}</h2>
                    </Modal>
                    <div className={`ruleStopContainer pb-1 pt-3 pl-3 pr-1 limitedDiscountContainerWidth`}>
                        <div>
                            <Row>
                                <Col lg="4" md="4" className={`expiryDiscountLeftSide text-center ${getCorrectImagePath() ? 'my-auto' : 'mt-3'}`}>
                                    {getCorrectImagePath() ? (
                                        <div className="expiryDiscountImageContainer">
                                            <img src={getCorrectImagePath()} alt="" />
                                        </div>
                                    ) : null}
                                    {!rule.version && isAdmin && (
                                        <Row>
                                            <Col lg="12" className="text-center mt-1 d-flex">
                                                <Toggle
                                                    className={`${rule?.Status === 2 ? 'deactivatedDiscountToggle' : 'expiryDiscountToggle'}`}
                                                    icons={false}
                                                    checked={rule && rule.Status === 1 ? true : false}
                                                    onChange={() => (preview ? handleDiscountStatusChange() : setConfirmationDialogOpen(true))}
                                                />
                                            </Col>
                                        </Row>
                                    )}
                                </Col>
                                <Col lg={8} md={6}>
                                    <div className={`${!preview ? 'mainView' : ''} expiryScrollContainer`}>
                                        {discountStops.map((stop, i) => (
                                            <Fragment key={stop.id}>
                                                {stop.permanent ? (
                                                    <p>{t('permanent')}</p>
                                                ) : (
                                                    <Col lg="auto" md="auto" className="d-flex pb-2">
                                                        <Col lg="auto" md="auto" className="ruleStopDiscountAmountCol pl-1 pr-2">
                                                            <h4 className="discountHeader">{stop?.discountAmount} %</h4>
                                                        </Col>
                                                        <Col lg="auto" md="auto" className="my-auto">
                                                            <Icon size="lg" md="auto" className="ruleStopClockIcon">
                                                                access_time
                                                            </Icon>
                                                        </Col>
                                                        <Col
                                                            lg="auto"
                                                            md="auto"
                                                            className={`pl-0 pr-0 my-auto ${props.removeStop ? 'ruleStopDiscountAmountCol' : ''}`}
                                                        >
                                                            <div>
                                                                {stop?.daysBeforeExpiry || 0} d {stop?.hoursBeforeExpiry || 0} h{' '}
                                                                {stop?.leftoverMinsBeforeExpiry || 0} m{' '}
                                                            </div>
                                                            <Col className="pl-0">{t('beforeExpiry')}</Col>
                                                        </Col>
                                                        {props.removeStop && props.isAdmin && (
                                                            <Col
                                                                lg="auto"
                                                                md="auto"
                                                                className="my-auto pl-2 pr-1"
                                                                style={{ cursor: 'pointer' }}
                                                                onClick={() => props.removeStop(stop.minsBeforeExpiry)}
                                                            >
                                                                <Icon>delete</Icon>
                                                            </Col>
                                                        )}
                                                    </Col>
                                                )}
                                                {i !== discountStops.length - 1 && (
                                                    <Row>
                                                        <Col className="ml-4">
                                                            {' '}
                                                            <Icon className="ml-3 discountArrow">arrow_downward</Icon>
                                                        </Col>
                                                    </Row>
                                                )}
                                            </Fragment>
                                        ))}
                                    </div>

                                    <Col lg="6" md="7" className={` ${preview ? 'invisible' : 'mt-1 d-flex'}`}>
                                        <Button size="sm" className="discountDetailsBtn" onClick={() => setDetailsOpen(!detailsOpen)} type="positive">
                                            {' '}
                                            {t('translations:seeDetails')}{' '}
                                            <Icon className="align-middle">{detailsOpen ? 'arrow_drop_up' : 'arrow_drop_down'}</Icon>
                                        </Button>
                                    </Col>
                                </Col>
                            </Row>
                        </div>

                        {detailsOpen && (
                            <Row>
                                <div className="discountDetailContainer p-3">
                                    <div>
                                        <span>{t('locations')}: </span>
                                        {rule.LocationIds && typeof rule.LocationIds === 'object' && rule.LocationIds.length > 0 ? (
                                            rule.LocationIds.map(locationId => {
                                                const location = props.cabinets.locations.find(location => location.id === locationId);
                                                if (!location) {
                                                    return (
                                                        <Tag className="mr-2" type="negative">
                                                            {locationId} ({t('doesNotExist')})
                                                        </Tag>
                                                    );
                                                } else {
                                                    return (
                                                        <Tag className="mr-2" type="positive">
                                                            {location.name}
                                                        </Tag>
                                                    );
                                                }
                                            })
                                        ) : (
                                            <Tag>{t('all')}</Tag>
                                        )}
                                    </div>
                                    <div>
                                        <span>{t('cabinets')}: </span>
                                        {rule.DeviceCodes && typeof rule.DeviceCodes === 'object' && rule.DeviceCodes.length > 0 ? (
                                            rule.DeviceCodes.map(deviceCode => {
                                                const cabinet = props.cabinets.cabinets.find(cabinet => cabinet.deviceCode === deviceCode);
                                                if (!cabinet) {
                                                    return (
                                                        <Tag className="mr-2" key={deviceCode} type="negative">
                                                            {deviceCode} ({t('doesNotExist')})
                                                        </Tag>
                                                    );
                                                } else {
                                                    return (
                                                        <Tag className="mr-2" key={deviceCode} type="positive">
                                                            {cabinet.name}
                                                        </Tag>
                                                    );
                                                }
                                            })
                                        ) : (
                                            <Tag>{t('all')}</Tag>
                                        )}
                                    </div>
                                    <div>
                                        <span>{t('products')}: </span>
                                        {rule.ProductIds && typeof rule.ProductIds === 'object' && rule.ProductIds.length > 0 ? (
                                            rule.ProductIds.map(productId => {
                                                const product = props.products.products.find(product => product.id === productId);
                                                if (!product) {
                                                    return (
                                                        <Tag className="mr-2" key={productId} type="negative">
                                                            {productId} ({t('doesNotExist')})
                                                        </Tag>
                                                    );
                                                } else {
                                                    return (
                                                        <Tag className="mr-2" key={productId} type="positive">
                                                            {product.name}
                                                        </Tag>
                                                    );
                                                }
                                            })
                                        ) : (
                                            <Tag>{t('all')}</Tag>
                                        )}
                                    </div>
                                    <div>
                                        <span>{t('productCategoriesTitle')}: </span>
                                        {rule.ProductCategoryIds && typeof rule.ProductCategoryIds === 'object' && rule.ProductCategoryIds.length > 0 ? (
                                            rule.ProductCategoryIds.map(pcId => {
                                                const product = props.productCategories.productCategories.find(productCategory => productCategory.id === pcId);
                                                if (!product) {
                                                    return (
                                                        <Tag className="mr-2" key={pcId} type="negative">
                                                            {pcId} ({t('doesNotExist')})
                                                        </Tag>
                                                    );
                                                } else {
                                                    return (
                                                        <Tag className="mr-2" key={pcId} type="positive">
                                                            {product.name}
                                                        </Tag>
                                                    );
                                                }
                                            })
                                        ) : (
                                            <Tag>{t('all')}</Tag>
                                        )}
                                    </div>
                                </div>
                            </Row>
                        )}
                    </div>
                </div>
            </Card>
        );
    } else {
        return null;
    }
}

/**
 * Add discount rule stop form React component
 * @param {object} props Properties of function
 * @param {function} props.t i18n translator function
 * @param {Array<object>} props.discountStops
 * @param {string} expiryImageUrl URL of last pushed image
 * @param {string} props.formMessage Optional informative message to display on form
 */
const getImageUrl = props => {
    if (props.rule?.DiscountData?.imageUrl) return props.rule.DiscountData.imageUrl;
    else if (!props.rule?.DiscountData?.imageUrl && props.expiryImageUrl) return props.expiryImageUrl;
    else return null;
};
export function DiscountRuleStopForm(props) {
    const { t, addNewStop, rule, resetExpiryImage, markExpiryImageAsDeleted, handleUserRestrictToggle, userRestricted } = props;
    const defaultNewStop = {
        id: 'new',
        discountAmount: 0,
        minsBeforeExpiry: 0,
        days: 0,
        mins: 0,
        hours: 0,
    };
    const [activeTab, setActiveTab] = useState('expiryIntervals');
    const [expiryImageStoring, setExpiryImageStoring] = useState(props.expiryImageStoring ? props.expiryImageStoring : false);
    const [expiryImageUrl, setExpiryImageUrl] = useState(getImageUrl(props));
    const [expiryImageCropping, toggleExpiryImageCropping] = useState(false);
    const [ruleStops, setRuleStops] = useState([]);
    const [newRuleStop, setNewRuleStop] = useState(defaultNewStop);
    const [selectedRuleStop, setSelectedRuleStop] = useState(null);

    useEffect(() => {
        if (props.discountStops.length > 0 && props.discountStops[0]) {
            let stops = [];
            props.discountStops.forEach(stop => {
                let mins = stop.minsBeforeExpiry;
                let hours = Math.floor(stop.minsBeforeExpiry / 60);
                let days = Math.floor(hours / 24);

                hours = hours - days * 24;
                mins = mins - days * 24 * 60 - hours * 60;
                stops.push({
                    id: stop.id,
                    discountAmount: stop.discountAmount,
                    minsBeforeExpiry: stop.minsBeforeExpiry,
                    hoursBeforeExpiry: hours,
                    daysBeforeExpiry: days,
                    days,
                    hours,
                    mins,
                });
            });
            stops = stops.sort((stopA, stopB) => {
                // arrange longer time before expiry to come before
                return stopB.minsBeforeExpiry - stopA.minsBeforeExpiry;
            });
            setRuleStops(stops);
        }
    }, [props.discountStops]);

    useEffect(() => {
        if (props.expiryImageUrl) {
            setExpiryImageStoring(false);
            setExpiryImageUrl(props.expiryImageUrl);
        }
    }, [props.expiryImageUrl]);

    const handlePercentageInputChange = (event, discountStop) => {
        let inputValue = event.target.value;
        if (inputValue === '') {
            inputValue = '0';
        }

        if (isNaN(inputValue) || isNaN(parseFloat(inputValue))) {
            return; // invalid change
        }
        const percentage = parseFloat(inputValue);
        const existingRuleStop = discountStop.id === selectedRuleStop?.id;

        if (percentage < 0) {
            existingRuleStop
                ? setSelectedRuleStop({ ...selectedRuleStop, discountAmount: undefined })
                : setNewRuleStop({ ...newRuleStop, discountAmount: undefined }); // min 0 %
        } else if (percentage > 100) {
            existingRuleStop ? setSelectedRuleStop({ ...selectedRuleStop, discountAmount: 100 }) : setNewRuleStop({ ...newRuleStop, discountAmount: 100 }); // max 100 %
        } else {
            existingRuleStop
                ? setSelectedRuleStop({ ...selectedRuleStop, discountAmount: percentage })
                : setNewRuleStop({ ...newRuleStop, discountAmount: percentage });
        }
    };
    const handleDiscountStopTimeDaysChange = (event, discountStop) => {
        let inputValue = event.target.value;
        if (inputValue === '') {
            inputValue = '0';
        }

        if (isNaN(inputValue) || isNaN(parseInt(inputValue))) {
            return;
        }
        let newDays = parseInt(inputValue);
        if (newDays < 0) newDays = 0; // min 0
        if (newDays === discountStop.newDays) return; // no change

        const daysMinsDiff = (newDays - discountStop.days) * 1440; // days change to minutes change
        const existingRuleStop = discountStop.id === selectedRuleStop?.id;
        existingRuleStop
            ? setSelectedRuleStop({ ...selectedRuleStop, days: newDays, minsBeforeExpiry: discountStop.minsBeforeExpiry + daysMinsDiff })
            : setNewRuleStop({ ...newRuleStop, days: newDays, minsBeforeExpiry: discountStop.minsBeforeExpiry + daysMinsDiff });
    };

    const handleDiscountStopTimeHoursChange = (event, discountStop) => {
        let inputValue = event.target.value;
        if (inputValue === '') {
            inputValue = '0';
        }

        if (isNaN(inputValue) || isNaN(parseInt(inputValue))) {
            return;
        }

        let newHours = parseInt(inputValue);
        if (newHours < 0) newHours = 0; // min 0
        if (newHours > 23) newHours = 23; // max 23
        let daysOff = discountStop.minsBeforeExpiry - discountStop.days * 1440;
        let hoursOnly = Math.floor(daysOff / 60);
        const minsDiff = (newHours - hoursOnly) * 60; // hours change to minutes change
        const existingRuleStop = discountStop.id === selectedRuleStop?.id;
        existingRuleStop
            ? setSelectedRuleStop({ ...selectedRuleStop, hours: newHours, minsBeforeExpiry: discountStop.minsBeforeExpiry + minsDiff })
            : setNewRuleStop({ ...newRuleStop, hours: newHours, minsBeforeExpiry: discountStop.minsBeforeExpiry + minsDiff });
    };

    const handleDiscountStopTimeMinsChange = (event, discountStop) => {
        let inputValue = event.target.value;
        if (inputValue === '') {
            inputValue = '0';
        }

        if (isNaN(inputValue) || isNaN(parseInt(inputValue))) {
            return; // invalid change
        }

        let newMins = parseInt(inputValue);
        if (newMins < 0) newMins = 0; // min 0
        if (newMins > 59) newMins = 59; // max 59
        const minsDiff = newMins - discountStop.mins;
        const existingRuleStop = discountStop.id === selectedRuleStop?.id;
        existingRuleStop
            ? setSelectedRuleStop({ ...selectedRuleStop, mins: newMins, minsBeforeExpiry: discountStop.minsBeforeExpiry + minsDiff })
            : setNewRuleStop({ ...newRuleStop, mins: newMins, minsBeforeExpiry: discountStop.minsBeforeExpiry + minsDiff });
    };

    const uploadDiscountImage = file => {
        setExpiryImageStoring(true);
        markExpiryImageAsDeleted(null);
        return props.uploadExpiryImage(file);
    };
    const markImageAsDeleted = rule => {
        setExpiryImageUrl(null);
        resetExpiryImage();
        markExpiryImageAsDeleted(rule.Id);
    };

    // discount stop form
    return (
        <div>
            <Row>
                <Col xs="12">{props.formMessage ? <p style={{ color: '#CF142B' }}>{props.formMessage}</p> : null}</Col>
            </Row>

            {/* edit tab */}
            <Row className="mb-4">
                <NavigationBar>
                    <TabItem isActive={activeTab === 'expiryIntervals'} onClick={() => setActiveTab('expiryIntervals')}>
                        {t('expiryIntervals')}
                    </TabItem>
                    <TabItem isActive={activeTab === 'discountImage'} onClick={() => setActiveTab('discountImage')}>
                        {t('discountImage')}
                    </TabItem>
                    <TabItem isActive={activeTab === 'userRestrict'} onClick={() => setActiveTab('userRestrict')}>
                        {t('userRestrictTitle')}
                    </TabItem>
                </NavigationBar>
            </Row>

            {/* End of edit tab */}

            {activeTab === 'expiryIntervals' && (
                <Row className="expiryIntervalRow">
                    <Col className="expiryScrollContainer" lg={5} md={4}>
                        {ruleStops.map((stop, i) => {
                            return (
                                <Fragment key={stop.id}>
                                    {stop.permanent ? (
                                        <p>{t('permanent')}</p>
                                    ) : (
                                        <Col lg="auto" md="auto" className="d-flex pb-3">
                                            <Col lg="auto" md="auto" className="ruleStopDiscountAmountCol pl-1 pr-2">
                                                <h4 className="discountHeader">{stop?.discountAmount} %</h4>
                                            </Col>
                                            <Col lg="auto" md="auto" className="my-auto">
                                                <Icon size="lg" md="auto" className="ruleStopClockIcon">
                                                    access_time
                                                </Icon>
                                            </Col>
                                            <Col lg="auto" md="auto" className={`pl-0 pr-0 my-auto ${props.removeStop ? 'ruleStopDiscountAmountCol' : ''}`}>
                                                <div>
                                                    {stop?.days || 0} d {stop?.hours || 0} h {stop?.mins || 0} m{' '}
                                                </div>
                                                <Col className="pl-0">{t('beforeExpiry')}</Col>
                                            </Col>
                                            {props.isAdmin && (
                                                <Col
                                                    lg="auto"
                                                    md="auto"
                                                    className={`my-auto pl-2 pr-1 ${selectedRuleStop?.id === stop.id ? 'selectedRuleStopForEdit' : ''}`}
                                                    style={{ cursor: 'pointer' }}
                                                    onClick={() => setSelectedRuleStop(stop)}
                                                >
                                                    <Icon>edit</Icon>
                                                </Col>
                                            )}
                                            {props.removeStop && props.isAdmin && (
                                                <Col
                                                    lg="auto"
                                                    md="auto"
                                                    className="my-auto pl-2 pr-1"
                                                    style={{ cursor: 'pointer' }}
                                                    onClick={() => props.removeStop(stop.minsBeforeExpiry)}
                                                >
                                                    <Icon>delete</Icon>
                                                </Col>
                                            )}
                                            {i !== ruleStops.length - 1 ? <Icon className="ml-3 discountArrow">arrow_downward</Icon> : null}{' '}
                                        </Col>
                                    )}
                                </Fragment>
                            );
                        })}
                    </Col>
                    <Col lg="7" className="expiryModalFormNewExpiryContainer">
                        <Row>
                            <Col xs="12" lg="7" md="7" className="discountAmountContainer">
                                <Input
                                    label={t('discountAmount') + ' (%)'}
                                    placeholder="%"
                                    value={selectedRuleStop ? selectedRuleStop.discountAmount.toString() : newRuleStop.discountAmount}
                                    onChange={e => handlePercentageInputChange(e, selectedRuleStop ? selectedRuleStop : newRuleStop)}
                                />
                            </Col>
                        </Row>

                        <Row>
                            <Col lg="auto" className="my-2">
                                <span>
                                    <strong>{t('timeBeforeExpiry')}:</strong>
                                </span>
                            </Col>
                        </Row>
                        <Row className="expiryFormTimeRow">
                            <Col xs="auto" md="4" lg="3">
                                <div class="input-group mb-3">
                                    <input
                                        type="number"
                                        class="form-control"
                                        placeholder="0"
                                        value={selectedRuleStop?.days >= 0 ? selectedRuleStop.days.toString() : newRuleStop.days}
                                        onChange={e => handleDiscountStopTimeDaysChange(e, selectedRuleStop ? selectedRuleStop : newRuleStop)}
                                    />
                                    <span class="input-group-text" id="basic-addon2">
                                        {t('days')}
                                    </span>
                                </div>
                            </Col>
                            <Col xs="auto" md="4" lg="3">
                                <div class="input-group mb-3">
                                    <input
                                        type="number"
                                        class="form-control"
                                        placeholder="0"
                                        value={selectedRuleStop?.hours >= 0 ? selectedRuleStop.hours.toString() : newRuleStop.hours}
                                        onChange={e => handleDiscountStopTimeHoursChange(e, selectedRuleStop ? selectedRuleStop : newRuleStop)}
                                    />
                                    <span class="input-group-text" id="basic-addon2">
                                        {t('hours')}
                                    </span>
                                </div>
                            </Col>
                            <Col xs="auto" md="4" lg="3">
                                <div class="input-group mb-3">
                                    <input
                                        type="number"
                                        class="form-control"
                                        placeholder="0"
                                        value={selectedRuleStop?.mins >= 0 ? selectedRuleStop.mins.toString() : newRuleStop.mins}
                                        onChange={e => handleDiscountStopTimeMinsChange(e, selectedRuleStop ? selectedRuleStop : newRuleStop)}
                                    />
                                    <span class="input-group-text" id="basic-addon2">
                                        {t('minutes')}
                                    </span>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg="auto">
                                <Button
                                    style={{ fontSize: '14px' }}
                                    onClick={() => {
                                        setNewRuleStop(defaultNewStop);
                                        addNewStop(
                                            selectedRuleStop
                                                ? { imageUrl: expiryImageUrl, selectedRuleStop }
                                                : {
                                                      selectedRuleStop: {
                                                          ...newRuleStop,
                                                          id:
                                                              Date.now().toString(36) +
                                                              Math.random()
                                                                  .toString(36)
                                                                  .substring(2),
                                                      },
                                                  },
                                            selectedRuleStop ? 'update' : 'add'
                                        );
                                    }}
                                >
                                    {selectedRuleStop ? 'Update' : 'Add new'}
                                </Button>
                            </Col>
                            {selectedRuleStop && (
                                <Col lg="auto">
                                    <Button
                                        type="secondary"
                                        style={{ fontSize: '14px' }}
                                        onClick={() => {
                                            setSelectedRuleStop(null);
                                        }}
                                    >
                                        {t('cancel')}
                                    </Button>
                                </Col>
                            )}
                        </Row>
                    </Col>
                </Row>
            )}
            {activeTab === 'discountImage' && (
                <Row className="expiryImageUploadContainer">
                    <div className="logoEditContainer bundleImageUploadContainer d-flex mb-2 justify-content-between">
                        <div className="">
                            <div className="scp-settings__inputs-item">
                                <div className="logoEditContainer d-flex mb-2 justify-content-between">
                                    <ImageUpload
                                        className="se-input-container"
                                        freeAspectRatio={false}
                                        targetWidth={parseInt(process.env.REACT_APP_BUNDLE_IMAGE_WIDTH) || 1920}
                                        targetHeight={parseInt(process.env.REACT_APP_BUNDLE_IMAGE_HEIGHT) || 850}
                                        imageUrl={expiryImageUrl}
                                        storing={expiryImageStoring}
                                        uploadImage={img => uploadDiscountImage(img)}
                                        removeImage={() => markImageAsDeleted(rule)}
                                        toggleCropping={() => toggleExpiryImageCropping(!expiryImageCropping)}
                                        t={props.t}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </Row>
            )}
            {activeTab === 'userRestrict' && (
                <div>
                    <InfoBanner message={t('userRestrictHelperText')} />
                    <Row className="expiryUserDiscount mt-4">
                        <Col lg="12" className="text-center mt-1 d-flex">
                            <CustomToggle
                                // className={userRestricted ? 'activatedDiscount': 'deactivatedDiscountToggle' }

                                options={[
                                    { text: t('userRestrict'), value: true },
                                    { text: t('userNotRestrict'), value: false },
                                ]}
                                selectedOption={userRestricted}
                                onClick={() => handleUserRestrictToggle()}
                            />
                        </Col>
                    </Row>
                </div>
            )}
        </div>
    );
}
