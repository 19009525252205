import React from 'react';
import { Modal, ModalBody, ModalFooter } from 'reactstrap';

import { Button } from '../../commons/utils';
import icons from './../../../icons/icomoon.svg';

const PlanogramImageStacking = ({ isOpen, close, cabinetSlotData, shelfNumber, allProducts }) => {
    const products = cabinetSlotData.products || [];

    return (
        <Modal isOpen={isOpen} centered={true} size="md" className="planogram__modal">
            <div className="planogram__modal-header">
                <label>Shelf:</label>
                {shelfNumber} |<label>Slot: </label> {cabinetSlotData.slot}
            </div>
            <ModalBody className="planogram__modal-body">
                <div className="planogram__modal-direction planogram__modal-direction--back">
                    <span>Back</span>
                    <svg>
                        <use xlinkHref={`${icons}#icon-arrow-right`}></use>
                    </svg>
                    <span>Front</span>
                </div>
                <div className="planogram__modal-images">
                    {products.map((id, index) => {
                        const selectedProduct = allProducts.find(product => product.id === id);
                        if (!selectedProduct.imageUrl) {
                            return (
                                <svg style={{ left: `${index * 60}px`, width: `${80 + index * 6}px`, height: `${80 + index * 6}px` }} key={id}>
                                    <use xlinkHref={`${icons}#icon-cube`}></use>
                                </svg>
                            );
                        } else {
                            return (
                                <img
                                    src={selectedProduct.imageUrl}
                                    alt=""
                                    style={{ left: `${index * 60}px`, width: `${80 + index * 6}px`, height: `${80 + index * 6}px` }}
                                    title={`${selectedProduct.name} ( ${selectedProduct.barcode} )`}
                                    key={id}
                                />
                            );
                        }
                    })}
                </div>
                <ul className="planogram__modal-names">
                    {products.map((id, index) => {
                        const selectedProduct = allProducts.find(product => product.id === id);
                        return (
                            <li>
                                {index + 1}. {selectedProduct.name}
                            </li>
                        );
                    })}
                </ul>
            </ModalBody>
            <ModalFooter>
                <Button type="close" onClick={() => close()} />
            </ModalFooter>
        </Modal>
    );
};

export default PlanogramImageStacking;
