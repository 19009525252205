import { t } from 'i18next';
import { groupBy } from 'lodash';
import pdfMake from 'pdfmake/build/pdfmake';
import moment from 'moment';

pdfMake.fonts = {
    Roboto: {
        normal: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf',
        bold: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf',
        italics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf',
        bolditalics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf',
    },
};
pdfMake.tableLayouts = {
    exampleLayout: {
        hLineWidth: function(i, node) {
            if (i === 0) {
                return 0;
            }
            if (i === node.table.body.length) return 1;
            return i === node.table.headerRows ? 2 : 1;
        },
        vLineWidth: function(i) {
            return 0;
        },
        hLineColor: function(i) {
            return i === 1 ? 'black' : '#aaa';
        },
        paddingLeft: function(i) {
            return i === 0 ? 0 : 10;
        },
        paddingRight: function(i, node) {
            return i === node.table.widths.length - 1 ? 0 : 8;
        },
        paddingTop: function(i) {
            return i === 0 ? 0 : 10;
        },
        paddingBottom: function(i, node) {
            return i === node.table.widths.length - 1 || i === 0 ? 0 : 10;
        },
    },
};

const dateToday = moment().format('DD.MM.YYYY');

/**
 * a function to create planogram table in PDF
 * @param {*} data 
 * @param {*} planogramProducts 
 * @returns 
 */
const getPlanogramTable=(data, planogramProducts)=>{    
    const {shelvesCount, shelves} = data.planogram
    const totalCabinetWidth = 500;
    let maxSlotsInShelves = 0;
    
    // determine the max slots to plot on the table
    shelves.forEach(shelf=>{
        if(shelf.slotCount>maxSlotsInShelves)maxSlotsInShelves = shelf.slotCount
    })

    // SCREEN layout in the table.
    const getCabinetScreenLayout=()=>{
        const screenLayout=[]
        for(let i=0; i<maxSlotsInShelves; i++){
            if(i===0){
                screenLayout.push( {text:t('refill:screen'), alignment:'center', colSpan:maxSlotsInShelves, style:'screenLayout'})
            }else{
                screenLayout.push({})
            }
        }
        return screenLayout;
    }

    // width of each table slot/cell in planogram
    const getTableWidths=()=>{
        const tableCellWidth = Math.floor(totalCabinetWidth / maxSlotsInShelves);
        const widths = []
        for(let i=0; i<maxSlotsInShelves; i++){
            widths.push(tableCellWidth)
        }

       return widths
    }

    /**
     * map products into the planogram table cells,
     * @returns 
     */
    const getShelvesProducts = ()=>{
        const shelvesProductContents = [];
        for(let i=0; i<shelvesCount; i++){
            const shelf = shelves.find(shelf=>shelf.shelf === i+1)
            //if no shelf, then its shelf without slots.
            if(!shelf){
                const shelfWithoutSlots = []
                for(let i=0; i<maxSlotsInShelves; i++){
                    if(i===0){
                        shelfWithoutSlots.push({text:'screen', alignment:'center', colSpan:maxSlotsInShelves, style:'emtpyshelf'})
                    }else{
                        shelfWithoutSlots.push({})
                    }
                }
                shelvesProductContents.push(shelfWithoutSlots)
            }else{
                const slotContent=[]
                
                const slotCountDifference = maxSlotsInShelves - shelf.slotCount;

                for(let j=0; j<shelf.slotCount; j++){
                    const productData = shelf.productData;

                    //colSpan the missing slot
                    const slot = productData.find(s =>  s.slot === j+1)
                    // if no product on slot
                    if(!slot){
                        slotContent.push({text:j+1, alignment:'center', style:'noImageCell'})
                    }else{
                        const {products} = slot
                        
                        if(!products.length){
                            slotContent.push({text:j+1, alignment:'center', style:"tableCell"})
                        }else{
                            //if there are multiple products in a slot, display only the last product added
                            // mark the slot with a fill color for indication
                            if(products.length>1){
                               const productId = slot.products[products.length-1]
                               const product = planogramProducts.find(product=>product.id ===productId)
                               if(product.imageUrl){
                                    slotContent.push({image:product.imageUrl, width:40, fillColor:'#ff9408', alignment:'center'})
                               }else{
                                    slotContent.push({text:product.barcode, fontSize:8, alignment:'center'})
                               }
                                
                            }else{
                                const productId = slot.products[0]
                                const product = planogramProducts.find(product=>product.id ===productId)
                                
                                if(product.imageUrl){
                                    slotContent.push({image:product.imageUrl, width:40, alignment:'center'})
                               }else{
                                    slotContent.push({text:product.name, fontSize:8, alignment:'center'})
                               }
                            }
                        }
                    }
                }
                // if some shelf has less slots than others, indicate the missing slots in the table
                if(slotCountDifference){
                   
                    for(let k=0; k < slotCountDifference; k++){
                        if(k===0){
                            slotContent.push({text:' ', fillColor:"#E8E8E8", colSpan:slotCountDifference})
                        }else{
                            slotContent.push({})
                        }
                    }
                }

                shelvesProductContents.push(slotContent)
            }
        }

        return shelvesProductContents
    }

    return {
        style:'planogramTable',
        table:{
            widths:getTableWidths(),
            body:[getCabinetScreenLayout(), ...getShelvesProducts()]
        },
        layout: {
            hLineWidth: function (i, node) {
                return (i === 0 || i === node.table.body.length) ? 2 : 1;
            },
            vLineWidth: function (i, node) {
                return (i === 0 || i === node.table.widths.length) ? 2 : 1;
            },
            hLineColor: function (i, node) {
                return '#515151';
            },
            vLineColor: function (i, node) {
                return '#cccccc';
            },
            vLineStyle: function (i, node) {
                if (i === 0 || i === node.table.widths.length) {
                    return null;
                }
                return {dash: {length: 2}};
                
            }
        }
    }
}

/**
 * if a planogram has mulitple products in a slot, give summary of the products and slot in the bottom of the planogram
 * @param {*} data 
 * @param {*} planogramProducts 
 * @returns 
 */
const getMultipleProdutsInSlotInfo =(data, planogramProducts)=>{
    const {shelves} = data.planogram
    const multipleProductsSlots = []

    for(let shelf of shelves){
        const productData = shelf.productData;
        productData.forEach(slot=>{
            if(slot.products && slot.products.length>1){
                multipleProductsSlots.push({text:`Shelf:${shelf.shelf}, Slot:${slot.slot}`, style:"slotStacking"})
                const slotProducts =[]                

                slot.products.forEach(productId=>{                   
                    const product = planogramProducts.find(p=>p.id === productId)
                    if(product){
                        slotProducts.push(product.name)
                    }
                })
                let conactProductNames='';
                slotProducts.forEach((productName,index)=>{
                    conactProductNames = `${conactProductNames + (index>0 ? ', ':' ') + productName }`
                })

               multipleProductsSlots.push({text:conactProductNames})

            }
        })
    }
    return multipleProductsSlots;
}

/**
 * A PDF template for the creating refill plan table, planogram table, planogram image.
 * @param {*} planProducts refill plan products
 * @param {*} organization name of the the organisation
 * @param {*} planogramsData planogram
 * @param {*} planogramProducts all products in a planogram
 * @param {*} planogramImages all planogram images, if exists
 * @returns 
 */
const pdfModel = (planProducts, organization, planogramsData, planogramProducts, planogramImages) => {
    const groupByCabinets = groupBy(planProducts, 'cabinetName');

    const getSummaryView = () => {
        const groupByProducts = groupBy(planProducts, 'product');

        if (Object.keys(groupByCabinets).length <= 1) return;

        const summaryTableData = [
            { text: organization, style: 'header', headlineLevel: 1 },
            { text: t('refill:refillSummary'), style: 'subheader' },
            { text: `${dateToday}`, style: 'subheaderDate' },
            {
                text: t('refill:refillPDFSummaryText'),
                style: 'paragraph',
            },
            {
                style: 'planTable',
                table: {
                    headerRows: 1,
                    widths: ['*', 90, 75, 100, 100],
                    body: [
                        [
                            { text: t('cabinetSettings:productName'), style: 'tableHeader' },
                            { text: t('main:barcode'), style: 'tableHeader' },
                            { text: t('main:category'), style: 'tableHeader' },
                            { text: t('refill:add'), style: 'tableHeader' },
                            { text: t('refill:remove'), style: 'tableHeader' },
                        ],

                        ...Object.keys(groupByProducts).map(product => {
                            let addItems = 0,
                                removeItems = 0;

                            groupByProducts[product].forEach(item => {
                                if (item.refillAmount > 0) {
                                    addItems = addItems + item.refillAmount;
                                } else if (item.refillAmount < 0) {
                                    removeItems = removeItems + item.refillAmount;
                                }
                            });

                            return [
                                product,
                                groupByProducts[product][0]?.barcode,
                                groupByProducts[product][0].category ? groupByProducts[product][0].category : '-',
                                addItems,
                                removeItems * -1,
                            ];
                        }),
                        [
                            { text: t('cabinetSettings:productName'), style: 'tableHeader' },
                            { text: t('main:barcode'), style: 'tableHeader' },
                            { text: t('main:category'), style: 'tableHeader' },
                            { text: t('refill:add'), style: 'tableHeader' },
                            { text: t('refill:remove'), style: 'tableHeader' },
                        ],
                    ],
                },
            },
        ];

        return summaryTableData;
    };

    const getPDFHeader=(cabinet, index, type)=>{
        return [
            { text: organization, style: 'header', headlineLevel: (index === 0 && type==="refillPlan") ? 0 : 1 },
            {
                text: `${t('main:cabinet')}: ${cabinet}, ${t('main:location')}: ${
                    groupByCabinets[cabinet][0]?.locationName ? groupByCabinets[cabinet][0]?.locationName : '-'
                }`,
                style: 'subheader',
            },
            { text: `${dateToday}`, style: 'subheaderDate' },
            {
                text: type==='refillPlan' ? t('refill:refillPDFText') : type==='refillPlanogram' ? t('refill:planogramPDFText') : t('refill:planogramImagePDFText') ,
                style: 'paragraph',
            },
        ]
    }

    return {
        pageMargins: [20, 20, 20, 20],
        content: [
            ...Object.keys(groupByCabinets).map((cabinet, index) => {
                const refillplanogram = planogramsData.find(planogram=>cabinet === planogram.cabinetName);
                const refillPlanogramImage = planogramImages.find(image=>cabinet === image.cabinetName);

                return [
                    ...getPDFHeader(cabinet, index, 'refillPlan'),
                    {
                        style: 'planTable',
                        table: {
                            headerRows: 1,
                            widths: ['*', 90, 75, 50, 50, 100],
                            body: [
                                [
                                    { text: t('cabinetSettings:productName'), style: 'tableHeader' },
                                    { text: t('main:barcode'), style: 'tableHeader' },
                                    { text: t('main:category'), style: 'tableHeader' },
                                    { text: t('refill:refillAmount'), style: 'tableHeader' },
                                    { text: t('refill:refillRuleTargetAmountShort'), style: 'tableHeader' },
                                    { text: t('refill:refillNote'), style: 'tableHeader' },
                                ],
                                ...groupByCabinets[cabinet].map(product => {
                                    return [
                                        product.product || '',
                                        product.barcode,
                                        product.category ? product.category : '-',
                                        product.refillAmount,
                                        product.targetInvAmount,
                                        product.refillNote ? product.refillNote : product.refillNote,
                                    ];
                                }),
                                [
                                    { text: t('cabinetSettings:productName'), style: 'tableHeader' },
                                    { text: t('main:barcode'), style: 'tableHeader' },
                                    { text: t('main:category'), style: 'tableHeader' },
                                    { text: t('refill:refillAmount'), style: 'tableHeader' },
                                    { text: t('refill:refillRuleTargetAmountShort'), style: 'tableHeader' },
                                    { text: t('refill:refillNote'), style: 'tableHeader' },
                                ],
                            ],
                        },
                    },
                    refillplanogram ? getPDFHeader(cabinet, index, 'refillPlanogram'):'',
                    refillplanogram ? getPlanogramTable(refillplanogram, planogramProducts) :'',
                    refillplanogram ? getMultipleProdutsInSlotInfo(refillplanogram, planogramProducts) : '',
                    refillPlanogramImage ? getPDFHeader(cabinet, index, 'planogramImage'):'',
                    refillPlanogramImage ? { image:refillPlanogramImage.imageUrl, width:500} :''
                ];
            }),
            getSummaryView(),
        ],

        pageBreakBefore: function(currentNode, followingNodesOnPage, nodesOnNextPage, previousNodesOnPage) {
            return currentNode.headlineLevel === 1;
        },

        styles: {
            header: {
                fontSize: 24,
                bold: true,
                alignment: 'center',
            },
            subheader: {
                alignment: 'center',
                fontSize: 12,
                margin: [0, 1],
                bold: true,
            },
            subheaderDate: {
                alignment: 'center',
                fontSize: 10,
                margin: [0, 1],
            },
            planTable: {
                fontSize: 10,
            },
            tableHeader: {
                bold: true,
                fontSize: 12,
                color: '#202020',
            },
            paragraph: {
                fontSize: 10,
                margin: [0, 30, 0, 5],
            },
            screenLayout:{
                fontSize: 14,
                bold: true,
                margin: [20, 20, 20, 20],
                fillColor:"#E8E8E8"
            },
            emtpyshelf:{
                margin: [10, 10, 10, 10],
            },
            noImageCell:{
               color:"#515151",
               fontSize:12
            },
            tableCell:{
                margin:[10]
            },
            planogramTable:{
                margin: [0, 10]
            },

            slotStacking:{
                fontSize:12,
                bold:true,
                margin: [0, 5]
            }
        },
    };
};

const generatePDF = (products, organization, planogramData, planogramProducts, planogramImages) => {    
    pdfMake.createPdf(pdfModel(products, organization, planogramData, planogramProducts, planogramImages)).open();
};

export default generatePDF;
