import {
    REQUEST_PRODUCT_CATEGORIES,
    RECEIVE_PRODUCT_CATEGORIES,
    RECEIVE_PRODUCT_CATEGORIES_ERROR,
    REQUEST_CREATE_PRODUCT_CATEGORY,
    RECEIVE_CREATE_PRODUCT_CATEGORY,
    RECEIVE_CREATE_PRODUCT_CATEGORY_ERROR,
    REQUEST_UPDATE_PRODUCT_CATEGORY,
    RECEIVE_UPDATE_PRODUCT_CATEGORY,
    RECEIVE_UPDATE_PRODUCT_CATEGORY_ERROR,
    REQUEST_DELETE_PRODUCT_CATEGORY,
    RECEIVE_DELETE_PRODUCT_CATEGORY,
    RECEIVE_DELETE_PRODUCT_CATEGORY_ERROR,
    DISMISS_PRODUCT_CATEGORY_RESULT,
    RESET_PRODUCT_CATEGORIES,
    SEARCH_PRODUCT_CATEGORY_QUERY,
} from '../actions/productCategories.js';

export const initState = {
    isFetching: false,
    isError: false,
    fetched: false,
    isRequesting: false,
    isRequestingFinished: false,
    isDeletingFailed: false,
    productCategories: null,
    error: '',
    searchQuery: '',
};

export default function productCategories(state = initState, action) {
    switch (action.type) {
        case REQUEST_PRODUCT_CATEGORIES:
            return {
                ...state,
                isFetching: true,
                isError: false,
                fetched: false,
            };
        case RECEIVE_PRODUCT_CATEGORIES:
            return {
                ...state,
                isFetching: false,
                isError: false,
                productCategories: action.productCategories,
                fetched: true,
            };
        case RECEIVE_PRODUCT_CATEGORIES_ERROR:
            console.log(action.error);
            return {
                ...state,
                isFetching: false,
                isError: true,
                error: action.error.toString(),
            };
        case REQUEST_CREATE_PRODUCT_CATEGORY:
            return {
                ...state,
                isRequesting: true,
                isRequestingFinished: false,
                error: '',
            };
        case RECEIVE_CREATE_PRODUCT_CATEGORY:
            return {
                ...state,
                isRequesting: false,
                isRequestingFinished: true,
                error: '',
                productCategories: [action.productCategory, ...state.productCategories],
            };
        case RECEIVE_CREATE_PRODUCT_CATEGORY_ERROR:
            console.log(action.error);
            return {
                ...state,
                isRequesting: false,
                isRequestingFinished: true,
                isError: true,
                error: action.error.toString(),
            };

        case REQUEST_UPDATE_PRODUCT_CATEGORY:
            return {
                ...state,
                isRequesting: true,
                isRequestingFinished: false,
                error: '',
            };
        case RECEIVE_UPDATE_PRODUCT_CATEGORY:
            return {
                ...state,
                isRequesting: false,
                isRequestingFinished: true,
                error: '',
                productCategories: [
                    action.productCategory,
                    ...state.productCategories.filter(productCategory => productCategory.id !== action.productCategory.id),
                ],
            };
        case RECEIVE_UPDATE_PRODUCT_CATEGORY_ERROR:
            console.log(action.error);
            return {
                ...state,
                isRequesting: false,
                isRequestingFinished: true,
                isError: true,
                error: action.error.toString(),
            };

        case REQUEST_DELETE_PRODUCT_CATEGORY:
            return {
                ...state,
                isRequesting: true,
                isRequestingFinished: false,
                error: '',
            };
        case RECEIVE_DELETE_PRODUCT_CATEGORY:
            return {
                ...state,
                isRequesting: false,
                isRequestingFinished: true,
                error: '',
                productCategories: [...state.productCategories.filter(productCategory => productCategory.id !== action.id)],
            };
        case RECEIVE_DELETE_PRODUCT_CATEGORY_ERROR:
            console.log(action.error);
            return {
                ...state,
                isRequesting: false,
                isRequestingFinished: true,
                isError: true,
                isDeletingFailed: true,
                error: action.error.toString(),
            };
        case DISMISS_PRODUCT_CATEGORY_RESULT:
            return {
                ...state,
                isRequesting: false,
                isRequestingFinished: false,
                isError: false,
                isDeletingFailed: false,
                error: '',
            };
        case RESET_PRODUCT_CATEGORIES:
            return {
                ...initState,
            };
        case SEARCH_PRODUCT_CATEGORY_QUERY:
            return {
                ...state,
                searchQuery: action.query,
            };
        default:
            return state;
    }
}
