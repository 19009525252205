import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { Button, Modal } from '@storaensods/seeds-react';
import { Row, Col } from 'reactstrap';
import './settings.css';
import './cabinetSettings.css';
import './cabinetTheming.css';
import QRCode from 'qrcode.react';
import LogoSetting from './logoSetting';
import CabinetTheming from './cabinetTheming';
import { SettingsCopyModal } from './settingsCopyModal';
import { fetchAds } from '../../actions/advertisements.js';
import { uploadThemeImage, uploadPostPurchaseThemeImage } from '../../actions/theming.js';

const ThemesAndLogos = props => {
    const {
        t,
        updateSelectedCabinetConfiguration,
        selectedCabinetSettings,
        ads,
        cabinets,
        locations,
        title,
        user,
        submitSettingsCopy,
        fetchAds,
        uploadThemeImage,
        themingImage,
        uploadPostPurchaseThemeImage,
    } = props;
    const [defaultThemeConfirmationModalOpen, setDefaultThemeConfirmationModalOpen] = useState(false);
    const [selectedTheme, setSelectedTheme] = useState('default');
    const [themingModalOpen, setThemingModalOpen] = useState(false);
    const [copySettingsOpen, setCopySettingsOpen] = useState(false);

    useEffect(() => {
        if (!ads.isFetching && !ads.isError) {
            fetchAds();
        }

        const theme = selectedCabinetSettings?.cabinetUiTheme ? JSON.parse(selectedCabinetSettings.cabinetUiTheme) : null;
        if (theme) {
            setSelectedTheme(theme.theme);
        } else {
            setSelectedTheme('default');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedCabinetSettings]);

    const getThemeValue = selector => {
        if (selectedTheme === 'default' && selector === 'default') return true;
        else if (selectedTheme === 'custom' && selector === 'default') return false;
        else if (selectedTheme === 'default' && selector === 'custom') return false;
        else if (selectedTheme === 'custom' && selector === 'custom') return true;
    };

    const renderLogoSetting = (key, targetHeight, targetWidth, freeAspectRatio = true) => {
        const labelText = t(`settings:${key}`);
        return (
            <div key={key} className="d-flex align-items-center mb-2">
                {/* Key */}
                <div className="setting-label">
                    <span>{labelText}</span>
                    {key === 'footer_instruction_logo' && <p className="recommneded-size">({t('settings:logoHelperText')})</p>}
                </div>
                <LogoSetting logo={key} targetWidth={targetWidth} targetHeight={targetHeight} freeAspectRatio={freeAspectRatio} />
            </div>
        );
    };

    const getCorrectSettingsForModal = () => {
        const usedSettings = {
            cabinetUiTheme: selectedCabinetSettings.cabinetUiTheme,
            logo: selectedCabinetSettings.logo,
            payment_method_logo: selectedCabinetSettings.payment_method_logo,
            footer_instruction_logo: selectedCabinetSettings.footer_instruction_logo,
        };
        return usedSettings;
    };

    return (
        <div className="px-4">
            <Row className="my-3 themingContainer">
                <>
                    <Modal active={defaultThemeConfirmationModalOpen} onClose={() => setDefaultThemeConfirmationModalOpen(false)}>
                        <div className="confirm-model">
                            <h5> {t('cabinetSettings:defaultRevertModalMessage')}</h5>
                            <div className="confirm-model-buttons">
                                <Button onClick={() => setDefaultThemeConfirmationModalOpen(false)} size="md">
                                    {t('cancel')}
                                </Button>
                                <Button
                                    onClick={() => {
                                        updateSelectedCabinetConfiguration({
                                            cabinetUiTheme: { ...JSON.parse(selectedCabinetSettings.cabinetUiTheme), theme: 'default' },
                                        });
                                        setDefaultThemeConfirmationModalOpen(false);
                                        setSelectedTheme('default');
                                    }}
                                    size="md"
                                    type="positive"
                                >
                                    {t('confirm')}
                                </Button>
                            </div>
                        </div>
                    </Modal>
                    <Col xs="12" md="12" lg="12" className="pl-0">
                        <h5>
                            <i className="material-icons se-icon  se-icon--md text-muted mr-2">palette</i>
                            {t('cabinetSettings:cabinetTheme')}
                        </h5>
                    </Col>
                    <Col xs="12" md="12" lg="12" className="my-2 pl-0">
                        <div className="infoTextContainer">
                            <span>{t('settings:themingInfoText')}</span>
                        </div>
                    </Col>
                    <Col xs="12" md="5" lg="5" className="justify-content-around align-items-center my-2">
                        <div className="se-radio-btn se-radio-btn--md mb-2">
                            <input
                                type="radio"
                                id="defaultThemeRadioBtn"
                                checked={getThemeValue('default')}
                                onChange={() => {
                                    const theme = selectedCabinetSettings?.cabinetUiTheme ? JSON.parse(selectedCabinetSettings.cabinetUiTheme) : null;
                                    if (!theme || (theme && theme.theme === 'default')) setSelectedTheme('default');
                                    if (theme && theme.theme === 'custom' && selectedTheme === 'custom') {
                                        setDefaultThemeConfirmationModalOpen(true);
                                    }
                                }}
                            />
                            <label htmlFor="defaultThemeRadioBtn">
                                <span>{t('cabinetSettings:default')}</span>
                            </label>
                        </div>
                        <div className="se-radio-btn se-radio-btn--md ">
                            <input type="radio" id="customThemeRadioBtn" checked={getThemeValue('custom')} onChange={() => setSelectedTheme('custom')} />
                            <label htmlFor="customThemeRadioBtn">
                                <span>{t('cabinetSettings:customTheme')}</span>
                            </label>
                        </div>
                    </Col>
                    <Col xs="12" md="3" lg="3">
                        {getThemeValue('custom') && (
                            <Button onClick={() => setThemingModalOpen(!themingModalOpen)}>{t('cabinetSettings:editAndSaveTheme')}</Button>
                        )}
                    </Col>
                </>
                <hr />
            </Row>
            <Row>
                <Col xs="12" md="12" lg="12" className="pl-0">
                    <h5>
                        <i className="fas fa-images mr-2 text-muted" />
                        {t('settings:cabinetLogos')}
                    </h5>
                </Col>
                <Col xs="12" md="12" lg="12" className="my-2 pl-0">
                    <div className="infoTextContainer">
                        <span>{t('settings:cabinetLogoInfoText')}</span>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col className="d-flex justify-content-end flex-column" xs="9" md="9" lg="9" xl="4">
                    <Row className="flex-row justify-content-start">
                        <h5>{t('main:preview')}</h5>
                    </Row>
                    <Row className="flex-row justify-content-start">
                        <div className="preview">
                            <div className="previewScreen d-flex flex-column justify-content-end">
                                <div>
                                    <img className="previewAd" alt="" src={ads.ads && ads.ads.length ? ads.ads[0].url : ''} />
                                </div>
                                <div className="d-flex flex-row justify-content-between">
                                    <img
                                        className="previewLogo"
                                        alt=""
                                        src={
                                            selectedCabinetSettings
                                                ? selectedCabinetSettings.logo
                                                : null || selectedCabinetSettings
                                                ? selectedCabinetSettings.payment_method_logo
                                                : null
                                        }
                                    />
                                    <div className="previewInstruction"></div>
                                    <QRCode size={20} className="previewLogo printable card-img-top" value={'http://www.selflystore.com'} />
                                </div>
                            </div>
                        </div>
                    </Row>
                </Col>
                <Col xs="12" md="12" lg="12" xl="8">
                    <h5>{t('main:logoSettings')}</h5>
                    {renderLogoSetting('logo')}
                    {renderLogoSetting('payment_method_logo')}
                    {renderLogoSetting('footer_instruction_logo', 180, 180, false)}
                </Col>
            </Row>
            {themingModalOpen && user.isAdmin && (
                <CabinetTheming
                    open={themingModalOpen}
                    theme={selectedCabinetSettings?.cabinetUiTheme}
                    t={t}
                    updateSelectedCabinetConfiguration={updateSelectedCabinetConfiguration}
                    toggleThemingModal={() => setThemingModalOpen(!themingModalOpen)}
                    themingImage={themingImage}
                    uploadThemeImage={uploadThemeImage}
                    uploadPostPurchaseThemeImage={uploadPostPurchaseThemeImage}
                />
            )}
            <Button className="mb-4" onClick={() => setCopySettingsOpen(true)}>
                <i className="fas fa-copy mr-2" />
                <span>{t('settings:settingsCopyButton')}</span>
            </Button>
            {copySettingsOpen && (
                <SettingsCopyModal
                    closeModal={() => setCopySettingsOpen(!copySettingsOpen)}
                    t={t}
                    submitSettingsCopy={submitSettingsCopy}
                    cabinets={cabinets}
                    locations={locations}
                    settingsType={title}
                    settings={getCorrectSettingsForModal(selectedCabinetSettings)}
                />
            )}
        </div>
    );
};

export default withRouter(
    connect(
        state => ({
            ads: state.advertisements,
            themingImage: state.theming,
        }),
        { fetchAds, uploadThemeImage, uploadPostPurchaseThemeImage }
    )(translate('main')(ThemesAndLogos))
);
