import React, { useEffect, useState } from 'react';
import { Icon, Input } from '@storaensods/seeds-react';

import { DeviceSubTypeIcon, DeviceSatatusIndicator } from '../cabinetStatusIndicators/cabinetStatusIndicators.jsx';

export default function DeviceItem(props) {
    const { isCabinetNameDuplicated, device, updateCabinetName, t, isAdmin, alarms } = props;

    const [showEditCabinet, setShowEditCabinet] = useState(false);
    const [startEditingCabinetName, setStartEditingCabinetName] = useState(false);
    const [cabinetEditItem, setCabinetEditItem] = useState(null);
    const [cabinetNameEditValue, setCabinetNameEditValue] = useState(null);
    const [isCabinetNameShort, setIsCabinetNameShort] = useState(false);
    const [cabinetNameDuplicated, setCabinetNameDuplicated] = useState(false);

    useEffect(() => {
        if (cabinetEditItem) {
            setCabinetNameEditValue(cabinetEditItem.name);
        }
    }, [cabinetEditItem]);

    useEffect(() => {
        if (cabinetNameEditValue !== null) {
            if (cabinetNameEditValue.length < 1) {
                setIsCabinetNameShort(true);
            } else {
                setIsCabinetNameShort(false);
            }

            if (cabinetEditItem && cabinetEditItem.name !== cabinetNameEditValue) {
                if (isCabinetNameDuplicated(cabinetNameEditValue)) {
                    setCabinetNameDuplicated(true);
                } else {
                    setCabinetNameDuplicated(false);
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cabinetNameEditValue]);

    // When edit icon clicked
    const startEditingCabinet = device => {
        setCabinetEditItem(device);
        setStartEditingCabinetName(true);
    };

    // when X icon clicked to cancel editing
    const cancelEditingCabinetName = () => {
        setCabinetEditItem(null);
        setStartEditingCabinetName(false);
    };

    // checks if the device name changed and if so, will save the changes
    const handleChangeCabinetName = () => {
        if (cabinetEditItem.name !== cabinetNameEditValue.trim()) {
            updateCabinetName(cabinetEditItem.deviceCode, cabinetNameEditValue.trim());
        }
        setStartEditingCabinetName(false);
    };

    const handleShowCabinetEditIcon = device => {
        if (device.deviceCode && isAdmin) {
            setShowEditCabinet(true);
        }
    };

    const getAlarms = () => {
        const activeAlarms = alarms.alarms.filter(alarm => alarm.device_Id === device.id && alarm.state === 'ACTIVE' && alarm.severity >= 0.89);
        return activeAlarms;
    };

    return (
        <div
            className="d-flex justify-content-between device-item"
            onMouseEnter={() => {
                handleShowCabinetEditIcon(device);
            }}
            onMouseLeave={() => setShowEditCabinet(false)}
        >
            <div className="device-item-name-and-indicators device-name-editable">
                <DeviceSubTypeIcon deviceType={device.deviceType} deviceSubType={device.deviceSubType} />
                {startEditingCabinetName ? (
                    <Input
                        placeholder={device.name}
                        value={cabinetNameEditValue}
                        size="sm"
                        className="device-name-edit-input"
                        onChange={event => {
                            setCabinetNameEditValue(event.target.value);
                        }}
                        invalid={isCabinetNameShort || cabinetNameDuplicated}
                        helpText={isCabinetNameShort ? t('invalidCabinetNameTooShort') : cabinetNameDuplicated ? t('invalidCabinetNameDuplicated') : null}
                    />
                ) : (
                    <span className={`${!device.isOnline ? 'device-name-editable--offline' : ''}`}>{device.name}</span>
                )}
                {startEditingCabinetName ? (
                    <div className="edit-device-icons">
                        <Icon
                            className={isCabinetNameShort || cabinetNameDuplicated ? 'device-edit-confirm-icon-disable' : 'device-edit-confirm-icon-enable'}
                            style={{ cursor: 'pointer' }}
                            onClick={() => !(isCabinetNameShort || cabinetNameDuplicated) && handleChangeCabinetName()}
                        >
                            check
                        </Icon>

                        <Icon className="device-edit-cancel-icon" style={{ cursor: 'pointer' }} onClick={() => cancelEditingCabinetName()}>
                            clear
                        </Icon>
                    </div>
                ) : (
                    <Icon
                        style={{ cursor: 'pointer' }}
                        className={`ml-2 device-edit-start-icon ${showEditCabinet ? '' : 'se-hide-important'}`}
                        onClick={() => startEditingCabinet(device)}
                    >
                        edit
                    </Icon>
                )}
            </div>
            <div className="text-muted drag-indicator">
                <DeviceSatatusIndicator
                    isOnline={device.isOnline}
                    isDigiTwinOnline={device.isDigiTwinOnline}
                    rfidStatus={device.rfidStatus}
                    deviceCode={device.deviceCode}
                    deviceType={device.deviceType}
                    deviceSubType={device.deviceSubType}
                    t={t}
                    index={device.id}
                    device={device}
                    activeAlarms={getAlarms()}
                />
            </div>
        </div>
    );
}
