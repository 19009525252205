import React, { useState, useEffect } from 'react';
import { PieChart, Pie, Sector, ResponsiveContainer, Cell } from 'recharts';
import { groupBy } from 'lodash';

import { chartColors } from './../../cubeCharts';

const DiscountPieChart = ({ discountProducts }) => {
    const [chartData, setChartData] = useState([]);

    useEffect(() => {
        if (discountProducts && discountProducts.hasOwnProperty('data')) {
            const { data } = discountProducts;
            const chartProducts = [];

            const products = data.filter(product => {
                return (product['Transactions.isDiscounted'] || product['Transactions.isDiscounted'] === 'true') && product['Transactions.discountAmount'];
            });

            const productsByDiscountName = groupBy(products, 'Transactions.discountName');
            Object.keys(productsByDiscountName).forEach(key => {
                const discountStops = groupBy(productsByDiscountName[key], 'Transactions.discountAmount');

                Object.keys(discountStops).forEach(stop => {
                    let discountSalesAmount = 0,
                        currency = '';
                    const name = key;
                    discountStops[stop].forEach(product => {
                        if (!currency) {
                            currency = product['Transactions.currency'];
                        }
                        discountSalesAmount = discountSalesAmount + parseFloat(product['Transactions.oneUnitPrice']) * product['Transactions.soldCount'];
                    });
                    chartProducts.push({ name, value: discountSalesAmount, currency });
                });
            });
            setChartData(chartProducts);
        }
    }, [discountProducts]);

    const [activeIndex, setActiveIndex] = useState(0);

    const renderActiveShape = props => {
        const RADIAN = Math.PI / 180;
        const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent, value } = props;
        const sin = Math.sin(-RADIAN * midAngle);
        const cos = Math.cos(-RADIAN * midAngle);
        const sx = cx + (outerRadius + 10) * cos;
        const sy = cy + (outerRadius + 10) * sin;
        const mx = cx + (outerRadius + 30) * cos;
        const my = cy + (outerRadius + 30) * sin;
        const ex = mx + (cos >= 0 ? 1 : -1) * 22;
        const ey = my;
        const textAnchor = cos >= 0 ? 'start' : 'end';

        return (
            <g>
                {/* HIDE FOR NOW
                    <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
                        {payload.name}
                    </text>
                    */}
                <Sector cx={cx} cy={cy} innerRadius={innerRadius} outerRadius={outerRadius} startAngle={startAngle} endAngle={endAngle} fill={fill} />
                <Sector cx={cx} cy={cy} startAngle={startAngle} endAngle={endAngle} innerRadius={outerRadius + 6} outerRadius={outerRadius + 10} fill={fill} />
                <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
                <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
                <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="#333">
                    {`${payload.name}`} {`${value ? value.toFixed(2) : 0}`}
                    {payload.currency}
                </text>
                <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor} fill="#999">
                    {`(${(percent * 100).toFixed(2)}%)`}
                </text>
            </g>
        );
    };

    const onPieEnter = (data, index) => {
        setActiveIndex(index);
    };

    return (
        <ResponsiveContainer width="100%" height={400}>
            <PieChart>
                <Pie
                    activeIndex={activeIndex}
                    activeShape={renderActiveShape}
                    data={chartData}
                    dataKey="value"
                    cx={300}
                    cy={200}
                    innerRadius={60}
                    outerRadius={120}
                    fill="#118BD6"
                    onMouseEnter={onPieEnter}
                >
                    {chartData.map((entry, index) => (
                        <Cell fill={chartColors[index % chartColors.length]} key={entry} />
                    ))}
                </Pie>
            </PieChart>
        </ResponsiveContainer>
    );
};

export default DiscountPieChart;
