import 'whatwg-fetch';
import React, { Component } from 'react';
import { Route, Redirect, Switch } from 'react-router';
import Modal from 'react-modal';
import Header from './components/navigation/header.js';
import Navigation from './components/navigation/navigation.js';
import MobileNavigation from './components/navigation/mobileNavigation.jsx';
import ProductCatalog from './components/productCatalog/productCatalog.jsx';
import Advertisements from './components/advertisements/advertisements.js';
import DeviceManagement from './components/deviceManagement/deviceManagement.js';
import Analytics from './components/analytics/analytics.js';
import Discounts from './components/discounts/discounts.jsx';
import ConsumerAppSettings from './components/navigation/settings/consumerApp.jsx';
import ProductCreateAndEdit from './components/products/productCreateAndUpdate.jsx';

import './App.css';
import './styles/main.scss';
import { NotificationContainer } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import 'animate.css/animate.min.css';
import '@fortawesome/fontawesome-free/css/all.css';
import { getRedirectPath } from './localStorage.js';
import { ToastNotification } from './components/toastNotification/toastNotification.js';
import { store } from './index';
import SoftwareVersions from './components/navigation/softwareVersions/softwareVersions.jsx';

import RefillPlans from './components/refillPlans/index.jsx';
import Automations from './components/automations/index.jsx';

export default class App extends Component {
    /**
     * We need it for mpdal forms screen readers.
     */
    componentDidMount() {
        Modal.setAppElement(this.el);
    }

    render() {
        const state = store.getState();
        const isAdmin = state && state.user && state.user.isAdmin;
        const isReportingOperator = state && state.user && state.user.isReportingOperator;
        const isAdminOrReportingOperator = isAdmin || isReportingOperator;
        const isRefillOperator =  state.user && state.user.isRefillOperator;

        return (
            <div className="App" style={{ display: 'flex', flexDirection: 'column' }} ref={ref => (this.el = ref)}>
                <div className="navigation">
                    <Header />
                    <Navigation />
                </div>
                <div className="navigation--mobile">
                    <MobileNavigation />
                </div>

                {/** Main content */}
                <div style={{ display: 'flex', flexGrow: '1', maxWidth: '100vw', overflowY: 'auto' }}>
                    <ToastNotification />
                    {/* Describe routes here  */}
                    <Switch>
                        <Route path="/devices" component={DeviceManagement} />
                        {isAdminOrReportingOperator && <Route path="/analytics" component={Analytics} />}
                        {isAdminOrReportingOperator && <Route path="/advertisements" component={Advertisements} />}
                        {isAdmin && <Route path="/products/create" component={ProductCreateAndEdit} />}
                        {isAdmin && <Route path="/products/:id" component={ProductCreateAndEdit} />}
                        <Route path="/products" component={ProductCatalog} />
                        <Route path="/products/*" component={ProductCatalog} />
                        {isAdminOrReportingOperator && <Route path="/discounts" component={Discounts} />}
                        {isAdmin && <Route path="/settings-consumer-app" component={ConsumerAppSettings} />}
                        {isAdmin && <Route path="/software-versions" component={SoftwareVersions} />}
                        {isAdmin && <Route path="/automations" component={Automations}/>}
                        {(isRefillOperator || isAdminOrReportingOperator) && <Route path="/refill-plans" component={RefillPlans} />}
                        <Route path="*" render={() => <Redirect to={getRedirectPath() || (isAdmin ? '/analytics' : '/devices')} />} />
                    </Switch>
                    {/* / Default route need to redirect to the dashboard <Devices /> */}
                </div>
                <NotificationContainer />
                {/** / Main Content end */}
                {/* <Footer /> */}
            </div>
        );
    }
}
