const initState = {
    dashboard: {
        fetched: false,
        isFetching: false,
        isError: false,
    },
    custom: {
        fetched: false,
        isFetching: false,
        isError: false,
    },
    wasteDashboard: {
        fetched: false,
        isFetching: false,
        isError: false,
    },
    consumerResponseDashboard: {
        fetched: false,
        isFetching: false,
        isError: false,
    },
    discountDashboard: {
        fetched: false,
        isFetching: false,
        isError: false,
    },
    chart: {
        searchQuery: '',
    },
};

export default function analytics(state = initState, action) {
    switch (action.type) {
        case 'REQUEST_DASHBOARD_DATA':
            return {
                ...state,
                dashboard: {
                    isFetching: true,
                    fetched: false,
                    isError: false,
                    dateRange: action.dateRange,
                },
            };
        case 'RECEIVE_DASHBOARD_DATA':
            return {
                ...state,
                dashboard: {
                    ...state.dashboard,
                    isFetching: false,
                    fetched: true,
                    isError: false,
                    isFetchingChart: false,
                    isErrorChart: false,
                    summaryData: action.summaryData,
                    summaryDataTransactionDetails: action.summaryDataTransactionDetails,
                    totalCanceled: action.totalCanceled,
                    chartData: action.chartData,
                    salesChartData: action.salesChartData,
                    transactionsChartData: action.transactionsChartData,
                    productsSoldData: action.productsSoldData,
                    totalOrderSum: action.totalOrderSum,
                    totalUnpaidAmount: action.totalUnpaidAmount,
                    totalUnpaidCount: action.totalUnpaidCount,
                },
            };
        case 'RECEIVE_DASHBOARD_DATA_ERROR':
            console.error(action.error);
            return {
                ...state,
                dashboard: {
                    isFetching: false,
                    fetched: false,
                    isError: true,
                    error: action.error,
                },
            };
        case 'RECEIVE_CONSUMER_RESPONSE_DASHBOARD_ERROR':
            return {
                ...state,
                consumerResponseDashboard: {
                    isFetching: false,
                    fetched: false,
                    isError: true,
                    consumerResponses: null,
                    consumerResponseRatings: null,
                },
            };
        case 'RECEIVE_CONSUMER_RESPONSE_DASHBOARD_DATA':
            return {
                ...state,
                consumerResponseDashboard: {
                    fetched: true,
                    isFetching: false,
                    isError: false,
                    consumerResponses: action.consumerResponses,
                    consumerResponseRatings: action.consumerResponseRatings,
                },
            };

        case 'REQUEST_CONSUMER_RESPONSE_DASHBOARD_DATA':
            return {
                ...state,
                consumerResponseDashboard: {
                    fetched: false,
                    isFetching: true,
                    isError: false,
                    consumerResponses: null,
                    consumerResponseRatings: null,
                },
            };
        case 'RECEIVE_WASTE_DASHBOARD_ERROR':
            return {
                ...state,
                wasteDashboard: {
                    isFetching: false,
                    fetched: false,
                    isError: true,
                    error: action.error,
                    expiredProducts: null,
                    totalSales: null,
                },
            };
        case 'REQUEST_WASTE_DASHBOARD_DATA':
            return {
                ...state,
                wasteDashboard: {
                    fetched: false,
                    isFetching: true,
                    isError: false,
                    expiredProducts: null,
                    totalSales: null,
                },
            };
        case 'RECEIVE_WASTE_DASHBOARD_DATA':
            return {
                ...state,
                wasteDashboard: {
                    fetched: true,
                    isFetching: false,
                    isError: false,
                    expiredProducts: action.expiredProducts,
                    totalSales: action.totalSales,
                },
            };
        case 'REQUEST_DISCOUNT_DASHBOARD_DATA':
            return {
                ...state,
                discountDashboard: {
                    fetched: false,
                    isFetching: true,
                    isError: false,
                },
            };
        case 'RECEIVE_DISCOUNT_DASHBOARD_DATA':
            return {
                ...state,
                discountDashboard: {
                    fetched: true,
                    isFetching: false,
                    isError: false,
                    discountProducts: action.data,
                },
            };
        case 'RECEIVE_DISCOUNT_DASHBOARD_ERROR':
            return {
                ...state,
                discountDashboard: {
                    fetched: false,
                    isFetching: false,
                    isError: true,
                    error: action.error,
                },
            };
        case 'REQUEST_DASHBOARD_CHART_DATA':
            return {
                ...state,
                dashboard: {
                    ...state.dashboard,
                    isFetchingChart: true,
                    isErrorChart: false,
                },
            };
        case 'RECEIVE_DASHBOARD_CHART_DATA':
            return {
                ...state,
                dashboard: {
                    ...state.dashboard,
                    isFetchingChart: false,
                    isErrorChart: false,
                    chartData: action.data,
                },
            };
        case 'RECEIVE_DASHBOARD_CHART_DATA_ERROR':
            console.error(action.error);
            return {
                ...state,
                dashboard: {
                    ...state.dashboard,
                    isFetchingChart: false,
                    isErrorChart: true,
                },
            };
        case 'REQUEST_CUSTOM_QUERY_DATA':
            return {
                ...state,
                custom: {
                    isFetching: true,
                    fetched: false,
                    isError: false,
                },
            };
        case 'RECEIVE_CUSTOM_QUERY_DATA':
            return {
                ...state,
                custom: {
                    ...state.custom,
                    isFetching: false,
                    fetched: true,
                    isError: false,
                    cubeResponse: action.cubeResponse,
                },
            };
        case 'RECEIVE_CUSTOM_QUERY_DATA_ERROR':
            console.error(action.error);
            return {
                ...state,
                custom: {
                    isFetching: false,
                    fetched: false,
                    isError: true,
                    error: action.error,
                },
            };
        case 'RESET_CUSTOM_QUERY_DATA':
            return {
                ...state,
                custom: {
                    fetched: false,
                    isFetching: false,
                    isError: false,
                },
            };
        case 'RESET_ANALYTICS_DATA':
            return {
                ...state,
                dashboard: {
                    fetched: false,
                    isFetching: false,
                    isError: false,
                },
                custom: {
                    fetched: false,
                    isFetching: false,
                    isError: false,
                },
                wasteDashboard: {
                    fetched: false,
                    isFetching: false,
                    isError: false,
                },
                consumerResponseDashboard: {
                    fetched: false,
                    isFetching: false,
                    isError: false,
                },
                discountDashboard: {
                    fetched: false,
                    isFetching: false,
                    isError: false,
                },
                chart: {
                    searchQuery: '',
                },
            };
        case 'ANALYTICS_SEARCH_QUERY':
            return {
                ...state,
                chart: {
                    searchQuery: action.query,
                },
            };
        default:
            return state;
    }
}
