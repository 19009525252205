import { fetchProductLabels as fetchProductLabelsHttpRequest } from '../api.js';

export const REQUEST_PRODUCT_LABELS = 'REQUEST_PRODUCT_LABELS';
export const RECEIVE_PRODUCT_LABELS = 'RECEIVE_PRODUCT_LABELS';
export const RECEIVE_PRODUCT_LABELS_ERROR = 'RECEIVE_PRODUCT_LABELS_ERROR';

export const RESET_PRODUCT_LABELS = 'RESET_PRODUCT_LABELS';

/**
 * Request product labels Redux action creator
 */
function requestProductLabels() {
    return {
        type: REQUEST_PRODUCT_LABELS,
    };
}

/**
 * Receive product labels Redux action creator
 * @param {array} productLabels Array of product labels received from root api
 */
function receiveProductLabels(productLabels) {
    return {
        type: RECEIVE_PRODUCT_LABELS,
        productLabels,
    };
}

/**
 * Receive product labels error Redux action creator
 * @param {Error} error The error object created in api.js
 */
function receiveProductLabelsError(error) {
    return {
        type: RECEIVE_PRODUCT_LABELS_ERROR,
        error: error,
    };
}

/**
 * Thunk action for fetching product labels
 */
export function fetchProductLabels() {
    return (dispatch, getState) => {
        dispatch(requestProductLabels());

        const user = getState().user;
        return fetchProductLabelsHttpRequest(user.group, user.accessToken)
            .then(response => dispatch(receiveProductLabels(response)))
            .catch(error => dispatch(receiveProductLabelsError(error)));
    };
}

/**
 * Resets product labels state to initial state
 */
function resetProductLabels() {
    return {
        type: RESET_PRODUCT_LABELS,
    };
}

/**
 * Thunk action creator for refreshing product labels state
 */
export function refreshProductLabels() {
    return dispatch => {
        dispatch(resetProductLabels());
        dispatch(fetchProductLabels());
    };
}
