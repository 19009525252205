export const tabs = [
    { index: 1, title: 'basicDetail', mandatory: true },
    { index: 2, title: 'packaging', mandatory: true },
    { index: 3, title: 'additionalDetail', mandatory: false },
    { index: 4, title: 'classification', mandatory: true },
    { index: 5, title: 'tagSensitivity', mandatory: false },
];

export const barcodeTypes = [
    { value: 'EAN-13', title: 'EAN-13', barcodeLength: 13, helpText: 'barcodeHelpText12' },
    { value: 'EAN-8', title: 'EAN-8', barcodeLength: 8, helpText: 'barcodeHelpText7' },
    { value: 'UPC-A', title: 'UPC-A', barcodeLength: 12, helpText: 'barcodeHelpText11' },
];

export const classifications = [
    {
        index: 5,
        icon: 'spoon-knife',
        title: 'Grocery and household',
        subClasses: [
            {
                index: 123,
                title: 'Fresh produce',
                options: [
                    {
                        index: 1089,
                        title: 'Fruits',
                    },
                    {
                        index: 1090,
                        title: 'Vegetables',
                    },
                    {
                        index: 1091,
                        title: 'Herbs',
                    },
                ],
            },
            {
                index: 124,
                title: 'Prepared food',
                options: [
                    {
                        index: 1092,
                        title: 'Salads',
                    },
                    {
                        index: 1093,
                        title: 'Ready-made meals',
                    },
                    {
                        index: 1094,
                        title: 'Wraps and sandwiches',
                    },
                    {
                        index: 1095,
                        title: 'Pizzas',
                    },
                ],
            },
            {
                index: 125,
                title: 'Dessert',
                options: [
                    {
                        index: 1096,
                        title: 'Ice-cream',
                    },
                    {
                        index: 1097,
                        title: 'Bakery and cakes',
                    },
                ],
            },
            {
                index: 126,
                title: 'Canned goods',
                options: [
                    {
                        index: 1098,
                        title: 'Soup',
                    },
                    {
                        index: 1099,
                        title: 'Vegetables',
                    },
                    {
                        index: 1100,
                        title: 'Fruits',
                    },
                ],
            },
            {
                index: 127,
                title: 'Beverages',
                options: [
                    {
                        index: 1101,
                        title: 'Soft drinks',
                    },
                    {
                        index: 1102,
                        title: 'Juices',
                    },
                    {
                        index: 1103,
                        title: 'Coffee, tea and similar',
                    },
                    {
                        index: 1104,
                        title: 'Alcohol drinks',
                    },
                    {
                        index: 1105,
                        title: 'Smoothies',
                    },
                    {
                        index: 1152,
                        title: 'Energy drinks',
                    },
                    {
                        index: 1153,
                        title: 'Water',
                    },
                    {
                        index: 1154,
                        title: 'Vitamin and hydration drinks',
                    },
                ],
            },
            {
                index: 128,
                title: 'Snacks',
                options: [
                    {
                        index: 1106,
                        title: 'Chips',
                    },
                    {
                        index: 1107,
                        title: 'Nuts',
                    },
                    {
                        index: 1108,
                        title: 'Crackers',
                    },
                    {
                        index: 1155,
                        title: 'Chocolates, biscuits and sweets',
                    },
                ],
            },
            {
                index: 129,
                title: 'Cleaning supplies',
                options: [
                    {
                        index: 1109,
                        title: 'Detergents',
                    },
                    {
                        index: 1110,
                        title: 'Wipes',
                    },
                    {
                        index: 1111,
                        title: 'Brooms',
                    },
                ],
            },
            {
                index: 130,
                title: 'Pet Care',
                options: [
                    {
                        index: 1112,
                        title: 'Pet food',
                    },
                    {
                        index: 1113,
                        title: 'Toys',
                    },
                    {
                        index: 1114,
                        title: 'Grooming',
                    },
                ],
            },
        ],
    },
    {
        index: 1,
        icon: 'apparel',
        title: 'Apparel and fashion',
        subClasses: [
            {
                index: 100,
                title: "Men's clothing",
                options: [
                    {
                        index: 1000,
                        title: 'Shirts',
                    },
                    {
                        index: 1001,
                        title: 'Pants',
                    },
                    {
                        index: 1002,
                        title: 'Jackets',
                    },
                    {
                        index: 1003,
                        title: 'Suits',
                    },
                    {
                        index: 1004,
                        title: 'Footwear',
                    },
                    {
                        index: 1005,
                        title: 'Hats, scarves, gloves and similar',
                    },
                    {
                        index: 1006,
                        title: "Belts and other men's accessories",
                    },
                    {
                        index: 1007,
                        title: 'Sportswear',
                    },
                ],
            },
            {
                index: 101,
                title: "Women's Clothing",
                options: [
                    {
                        index: 1008,
                        title: 'Shirts',
                    },
                    {
                        index: 1009,
                        title: 'Pants',
                    },
                    {
                        index: 1010,
                        title: 'Jackets',
                    },
                    {
                        index: 1011,
                        title: 'Suits',
                    },
                    {
                        index: 1012,
                        title: 'Footwear',
                    },
                    {
                        index: 1013,
                        title: 'Hats, scarves, gloves and similar',
                    },
                    {
                        index: 1014,
                        title: "Belts and other women's accessories",
                    },
                    {
                        index: 1015,
                        title: 'Sportswear',
                    },
                ],
            },
            {
                index: 102,
                title: "Children's Clothing",
                options: [
                    {
                        index: 1016,
                        title: 'Shirts',
                    },
                    {
                        index: 1017,
                        title: 'Pants',
                    },
                    {
                        index: 1018,
                        title: 'Jackets',
                    },
                    {
                        index: 1019,
                        title: 'Suits',
                    },
                    {
                        index: 1020,
                        title: 'Footwear',
                    },
                    {
                        index: 1021,
                        title: 'Hats, scarves, gloves and similar',
                    },
                    {
                        index: 1022,
                        title: 'Belts and other accessories',
                    },
                    {
                        index: 1023,
                        title: 'Sportswear',
                    },
                ],
            },
        ],
    },
    {
        index: 2,
        icon: 'electrical_services',
        title: 'Electronics',
        subClasses: [
            {
                index: 103,
                title: 'Computers and laptops',
                options: [
                    {
                        index: 1024,
                        title: 'Desktops',
                    },
                    {
                        index: 1025,
                        title: 'Laptops',
                    },
                    {
                        index: 1026,
                        title: 'Computer accessories',
                    },
                ],
            },
            {
                index: 104,
                title: 'Mobile phone and tablets',
                options: [
                    {
                        index: 1027,
                        title: 'Smartphones',
                    },
                    {
                        index: 1028,
                        title: 'Tablets',
                    },
                    {
                        index: 1029,
                        title: 'Cases',
                    },
                    {
                        index: 1030,
                        title: 'Chargers',
                    },
                    {
                        index: 1031,
                        title: 'Screen protectors',
                    },
                    {
                        index: 1032,
                        title: 'Phone accesories',
                    },
                ],
            },
            {
                index: 105,
                title: 'Camera and photography',
                options: [
                    {
                        index: 1033,
                        title: 'DSLRs',
                    },
                    {
                        index: 1034,
                        title: 'Point-And-Shoot Cameras',
                    },
                    {
                        index: 1035,
                        title: 'Lenses',
                    },
                    {
                        index: 1036,
                        title: 'Camera Accessories',
                    },
                ],
            },
            {
                index: 106,
                title: 'Audio and headphones',
                options: [
                    {
                        index: 1037,
                        title: 'Headphones',
                    },
                    {
                        index: 1038,
                        title: 'Speakers',
                    },
                    {
                        index: 1039,
                        title: 'Ear-buds',
                    },
                ],
            },
            {
                index: 107,
                title: 'Home appliances',
                options: [
                    {
                        index: 1040,
                        title: 'Small appliances',
                    },
                    {
                        index: 1041,
                        title: 'Home appliance accessories',
                    },
                ],
            },
            {
                index: 108,
                title: 'Wearable technology',
                options: [
                    {
                        index: 1042,
                        title: 'Smart watches',
                    },
                    {
                        index: 1043,
                        title: 'Fitness trackers',
                    },
                    {
                        index: 1044,
                        title: 'VR headsets',
                    },
                ],
            },
            {
                index: 109,
                title: 'Video games',
                options: [
                    {
                        index: 1045,
                        title: 'Gaming consoles',
                    },
                    {
                        index: 1046,
                        title: 'Games',
                    },
                    {
                        index: 1047,
                        title: 'Gaming accessories',
                    },
                ],
            },
            {
                index: 110,
                title: 'Office electronics',
                options: [
                    {
                        index: 1048,
                        title: 'Printers',
                    },
                    {
                        index: 1049,
                        title: 'Scanners',
                    },
                    {
                        index: 1050,
                        title: 'Copiers',
                    },
                ],
            },
        ],
    },
    {
        index: 3,
        icon: 'home',
        title: 'Home and furniture',
        subClasses: [
            {
                index: 111,
                title: 'Furniture and accessories',
                options: [
                    {
                        index: 1051,
                        title: 'Living room',
                    },
                    {
                        index: 1052,
                        title: 'Bedroom',
                    },
                    {
                        index: 1053,
                        title: 'Dining room',
                    },
                    {
                        index: 1054,
                        title: 'Outdoor',
                    },
                ],
            },
            {
                index: 112,
                title: 'Home decor',
                options: [],
            },
            {
                index: 113,
                title: 'Kitchenware',
                options: [
                    {
                        index: 1055,
                        title: 'Cookware',
                    },
                    {
                        index: 1056,
                        title: 'Cutlery',
                    },
                    {
                        index: 1057,
                        title: 'Bakeware',
                    },
                ],
            },
            {
                index: 114,
                title: 'Bedding and linen',
                options: [
                    {
                        index: 1058,
                        title: 'Bed sheets',
                    },
                    {
                        index: 1059,
                        title: 'Pillows',
                    },
                    {
                        index: 1060,
                        title: 'Comforters',
                    },
                ],
            },
            {
                index: 115,
                title: 'Lightings',
                options: [
                    {
                        index: 1061,
                        title: 'Lamps',
                    },
                    {
                        index: 1062,
                        title: 'Ceiling lights',
                    },
                    {
                        index: 1063,
                        title: 'Outdoor lighting',
                    },
                ],
            },
            {
                index: 116,
                title: 'Storage solutions',
                options: [
                    {
                        index: 1064,
                        title: 'Shelves',
                    },
                    {
                        index: 1065,
                        title: 'Cabinets',
                    },
                    {
                        index: 1066,
                        title: 'Bins and boxes',
                    },
                ],
            },
        ],
    },
    {
        index: 4,
        icon: 'shower',
        title: 'Beauty and personal care',
        subClasses: [
            {
                index: 117,
                title: 'Skincare',
                options: [
                    {
                        index: 1067,
                        title: 'Cleansers',
                    },
                    {
                        index: 1068,
                        title: 'Moisturizers',
                    },
                    {
                        index: 1069,
                        title: 'Serums',
                    },
                    {
                        index: 1070,
                        title: 'Sunscreen',
                    },
                    {
                        index: 1071,
                        title: 'Face masks',
                    },
                ],
            },
            {
                index: 118,
                title: 'Hair care',
                options: [
                    {
                        index: 1072,
                        title: 'Shampoo',
                    },
                    {
                        index: 1073,
                        title: 'Conditioner',
                    },
                    {
                        index: 1074,
                        title: 'Styling products',
                    },
                ],
            },
            {
                index: 119,
                title: 'Make-up',
                options: [
                    {
                        index: 1075,
                        title: 'Foundations',
                    },
                    {
                        index: 1076,
                        title: 'Lipstick',
                    },
                    {
                        index: 1077,
                        title: 'Eye make-up',
                    },
                ],
            },
            {
                index: 120,
                title: 'Fragrances',
                options: [
                    {
                        index: 1078,
                        title: 'Perfumes',
                    },
                    {
                        index: 1079,
                        title: 'Colognes',
                    },
                    {
                        index: 1080,
                        title: 'Body sprays',
                    },
                ],
            },
            {
                index: 121,
                title: 'Personal hygiene',
                options: [
                    {
                        index: 1081,
                        title: 'Bath and shower',
                    },
                    {
                        index: 1082,
                        title: 'Deodorants',
                    },
                    {
                        index: 1083,
                        title: 'Dental care',
                    },
                    {
                        index: 1084,
                        title: 'Condoms',
                    },
                ],
            },
            {
                index: 122,
                title: 'Health and wellbeings',
                options: [
                    {
                        index: 1085,
                        title: 'Medicines',
                    },
                    {
                        index: 1086,
                        title: 'Vitamins',
                    },
                    {
                        index: 1087,
                        title: 'Supplements',
                    },
                    {
                        index: 1088,
                        title: 'First aid',
                    },
                ],
            },
        ],
    },
    {
        index: 6,
        icon: 'sports_kabaddi',
        title: 'Entertainment and sports',
        subClasses: [
            {
                index: 131,
                title: 'Bookstore',
                options: [
                    {
                        index: 1115,
                        title: 'Albums',
                    },
                    {
                        index: 1116,
                        title: 'Books',
                    },
                    {
                        index: 1117,
                        title: 'Movies and TV shows',
                    },
                ],
            },
            {
                index: 132,
                title: 'Sporting goods',
                options: [
                    {
                        index: 1118,
                        title: 'Ball',
                    },
                    {
                        index: 1119,
                        title: 'Bats',
                    },
                    {
                        index: 1120,
                        title: 'Rackets',
                    },
                    {
                        index: 1121,
                        title: 'Water toys',
                    },
                    {
                        index: 1122,
                        title: 'Fan accessories',
                    },
                ],
            },
            {
                index: 133,
                title: 'Outdoor equipment',
                options: [
                    {
                        index: 1123,
                        title: 'Camping and hiking',
                    },
                    {
                        index: 1124,
                        title: 'Backpacks',
                    },
                ],
            },
            {
                index: 134,
                title: 'Fitness equipment',
                options: [
                    {
                        index: 1125,
                        title: 'Weights',
                    },
                    {
                        index: 1127,
                        title: 'Yoga accessories',
                    },
                ],
            },
            {
                index: 135,
                title: 'Other sports',
                options: [
                    {
                        index: 1128,
                        title: 'Helmets',
                    },
                ],
            },
            {
                index: 136,
                title: 'Toys and games',
                options: [
                    {
                        index: 1129,
                        title: 'Children toys',
                    },
                    {
                        index: 1130,
                        title: 'Board games',
                    },
                    {
                        index: 1131,
                        title: 'Action figures and collectables',
                    },
                    {
                        index: 1132,
                        title: 'Educational toys and learning kits',
                    },
                    {
                        index: 1133,
                        title: 'Puzzles and jigsaw',
                    },
                ],
            },
        ],
    },
    {
        index: 7,
        icon: 'tools',
        title: 'Tools and utilities',
        subClasses: [
            {
                index: 137,
                title: 'Automotive',
                options: [
                    {
                        index: 1134,
                        title: 'Engine parts',
                    },
                    {
                        index: 1135,
                        title: 'Filters',
                    },
                    {
                        index: 1136,
                        title: 'Brakes',
                    },
                    {
                        index: 1137,
                        title: 'Car care products',
                    },
                    {
                        index: 1138,
                        title: 'Car audio',
                    },
                ],
            },
            {
                index: 138,
                title: 'Tools',
                options: [
                    {
                        index: 1139,
                        title: 'Diagonistic tools',
                    },
                    {
                        index: 1140,
                        title: 'Hand tools',
                    },
                    {
                        index: 1141,
                        title: 'Power tools',
                    },
                ],
            },
        ],
    },
    {
        index: 8,
        icon: 'monitor',
        title: 'Office supplies and stationary',
        subClasses: [
            {
                index: 139,
                title: 'Writing instruments',
                options: [
                    {
                        index: 1142,
                        title: 'Pens',
                    },
                    {
                        index: 1143,
                        title: 'Pencils',
                    },
                    {
                        index: 1144,
                        title: 'Markers',
                    },
                ],
            },
            {
                index: 140,
                title: 'Paper products',
                options: [
                    {
                        index: 1145,
                        title: 'Notebooks',
                    },
                    {
                        index: 1146,
                        title: 'Printer paper',
                    },
                    {
                        index: 1147,
                        title: 'Stationery sets',
                    },
                ],
            },
            {
                index: 141,
                title: 'Organizational tools',
                options: [
                    {
                        index: 1148,
                        title: 'Organizers',
                    },
                    {
                        index: 1149,
                        title: 'Calendars',
                    },
                    {
                        index: 1150,
                        title: 'Dividers',
                    },
                    {
                        index: 1151,
                        title: 'Packages and bags',
                    },
                ],
            },
        ],
    },
    {
        index: 0,
        icon: 'ungroup',
        title: 'General/Others',
    },
];
