import {
    fetchAllOrganizationsCabinetReleases as fetchAllOrganizationsCabinetReleasesHttpRequest,
    updateOneOrMoreDeviceSoftwareVersions as updateOneOrMoreDeviceSoftwareVersionsHttpRequest,
    removeReleaseUpdateScheduling as removeReleaseUpdateSchedulingHttpRequest,
} from '../api.js';

export const REQUEST_ORGANIZATION_RELEASE_VERSIONS = 'REQUEST_ORGANIZATION_RELEASE_VERSIONS';
export const RECEIVE_ORGANIZATION_RELEASE_VERSIONS = 'RECEIVE_ORGANIZATION_RELEASE_VERSIONS';
export const RECEIVE_ORGANIZATION_RELEASE_VERSIONS_ERROR = 'RECEIVE_ORGANIZATION_RELEASE_VERSIONS_ERROR';
export const REQUEST_ONE_OR_MORE_DEVICE_UPDATE = 'REQUEST_ONE_OR_MORE_DEVICE_UPDATE';
export const RECEIVE_DEVICE_UPDATE_RESPONSE = 'RECEIVE_DEVICE_UPDATE_RESPONSE';
export const RECEIVE_DEVICE_UPDATE_RESPONSE_ERROR = 'RECEIVE_DEVICE_UPDATE_RESPONSE_ERROR';

export const RECEIVE_UPDATE_SCHEDULING_REMOVAL = 'RECEIVE_UPDATE_SCHEDULING_REMOVAL';
export const RECEIVE_UPDATE_SCHEDULING_REMOVAL_ERROR = 'RECEIVE_UPDATE_SCHEDULING_REMOVAL_ERROR';
export const REQUEST_UPDATE_SCHEDULING_REMOVAL = 'REQUEST_UPDATE_SCHEDULING_REMOVAL';

export const DISMISS_SUPPLIER_RESULT = 'DISMISS_SUPPLIER_RESULT';

export const RESET_ORGANIZATION_RELEASE_VERSIONS = 'RESET_ORGANIZATION_RELEASE_VERSIONS';

export const SEARCH_SUPPLIER_QUERY = 'SEARCH_SUPPLIER_QUERY';

/**
 * Request organization release versions Redux action creator
 */
function requestOrganizationReleaseVersions() {
    return {
        type: REQUEST_ORGANIZATION_RELEASE_VERSIONS,
    };
}
/**
 * Request device release version update Redux action creator
 */
function requestOneOrMoreDeviceUpdate() {
    return {
        type: REQUEST_ONE_OR_MORE_DEVICE_UPDATE,
    };
}

/**
 * Receive suppliers Redux action creator
 * @param {array} organizationReleaseVersions Array of suppliers received from root api
 */
function receiveOrganizationReleaseVersions(organizationReleaseVersions) {
    return {
        type: RECEIVE_ORGANIZATION_RELEASE_VERSIONS,
        organizationReleaseVersions,
    };
}
/**
 * Receive device update status Redux action creator
 * @param {object} deviceUpdateResponse Status object of device update
 */
function receiveUpdateSchedulingRemoval(removeResponse) {
    return {
        type: RECEIVE_UPDATE_SCHEDULING_REMOVAL,
        removeResponse,
    };
}

/**
 * Receive device update status Redux action creator
 * @param {object} deviceUpdateResponseError Error status object of device update
 */
function receiveUpdateSchedulingRemovalError(removeResponseError) {
    return {
        type: RECEIVE_UPDATE_SCHEDULING_REMOVAL_ERROR,
        removeResponseError,
    };
}

/**
 * Request device release version update Redux action creator
 */
function requestUpdateSchedulingRemoval() {
    return {
        type: REQUEST_UPDATE_SCHEDULING_REMOVAL,
    };
}

/**
 * Receive device update status Redux action creator
 * @param {object} deviceUpdateResponse Status object of device update
 */
function receiveDeviceUpdate(deviceUpdateResponse) {
    return {
        type: RECEIVE_DEVICE_UPDATE_RESPONSE,
        deviceUpdateResponse,
    };
}

/**
 * Receive device update status Redux action creator
 * @param {object} deviceUpdateResponseError Error status object of device update
 */
function receiveDeviceUpdateError(deviceUpdateResponseError) {
    return {
        type: RECEIVE_DEVICE_UPDATE_RESPONSE_ERROR,
        deviceUpdateResponseError,
    };
}

/**
 * Receive supplier error Redux action creator
 * @param {Error} error The error object created in api.js
 */
function receiveOrganizationReleaseVersionsError(error) {
    return {
        type: RECEIVE_ORGANIZATION_RELEASE_VERSIONS_ERROR,
        error: error,
    };
}

/**
 * Thunk action for fetching suppliers
 */
export function fetchOrganizationReleaseVersions() {
    return (dispatch, getState) => {
        dispatch(requestOrganizationReleaseVersions());

        const user = getState().user;
        return fetchAllOrganizationsCabinetReleasesHttpRequest(user.accessToken, user.group)
            .then(response => dispatch(receiveOrganizationReleaseVersions(response)))
            .catch(error => dispatch(receiveOrganizationReleaseVersionsError(error)));
    };
}

/**
 * Resets org release versions state to initial state
 */
function resetOrganizationReleaseVersions() {
    return {
        type: RESET_ORGANIZATION_RELEASE_VERSIONS,
    };
}

export function updateOneOrMoreDeviceUpdate(releaseData) {
    return (dispatch, getState) => {
        dispatch(requestOneOrMoreDeviceUpdate());
        const user = getState().user;
        return updateOneOrMoreDeviceSoftwareVersionsHttpRequest(user.group, releaseData, user.accessToken)
            .then(response => dispatch(receiveDeviceUpdate(response)))
            .catch(error => dispatch(receiveDeviceUpdateError(error)));
    };
}
export function removeUpdateScheduling(scheduleId) {
    return (dispatch, getState) => {
        dispatch(requestUpdateSchedulingRemoval());
        const user = getState().user;
        return removeReleaseUpdateSchedulingHttpRequest(user.group, scheduleId, user.accessToken)
            .then(response => dispatch(receiveUpdateSchedulingRemoval(response)))
            .catch(error => dispatch(receiveUpdateSchedulingRemovalError(error)));
    };
}

/**
 * Thunk action creator for refreshing org release versions state
 */
export function refreshOrganizationReleaseVersions() {
    return dispatch => {
        dispatch(resetOrganizationReleaseVersions());
        dispatch(fetchOrganizationReleaseVersions());
    };
}
