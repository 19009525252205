import React from 'react';

import { Card, Icon } from '@storaensods/seeds-react';

import './vatCategories.css';

const VatCards = props => {
    return props.data.map(vat => {
        return (
            <Card
                key={vat.id}
                actions={
                    props.isAdmin && [
                        {
                            label: <Icon>edit</Icon>,
                            onClick: () => props.editAction(vat),
                        },
                        {
                            label: <Icon>remove_circle_outline</Icon>,
                            onClick: () => props.deleteAction(vat),
                            type: 'negative',
                        },
                    ]
                }
                className="VATCard"
            >
                <h5 className="vatCardHeader card-no-overflow">{vat.name}</h5>
                <p className="card-no-overflow">{vat.taxValue}%</p>
            </Card>
        );
    });
};

export default VatCards;
