import React from 'react';
import { Spinner, Modal, ModalBody, ModalFooter } from 'reactstrap';
import icons from './../../../icons/icomoon.svg';
import { Button } from '../../commons/utils';

const CopySchemaStatusModal = ({ status, isOpen, closeModal, message, t }) => {
    return (
        <Modal isOpen={isOpen} centered={true} size="lg">
            <ModalBody className="refillschema__modal refillschema__modal-request">
                {status === 'saving' && (
                    <div className="refillschema__modal-spinner">
                        <Spinner />
                        <div>{t('refill:savingData')}...</div>
                    </div>
                )}
                {status === 'error' && (
                    <div className="refillschema__modal-info refillschema__modal-info--warn">
                        <svg>
                            <use xlinkHref={`${icons}#icon-error_outline`}></use>
                        </svg>
                        <div>{message}</div>
                    </div>
                )}
                {status === 'success' && (
                    <div className="refillschema__modal-info refillschema__modal-info--success">
                        <svg>
                            <use xlinkHref={`${icons}#icon-check-alt`}></use>
                        </svg>
                        <div> {message ? message : t('main:dataSaved')}</div>
                    </div>
                )}
            </ModalBody>
            <ModalFooter>
                <Button type="close" onClick={() => closeModal()} />
            </ModalFooter>
        </Modal>
    );
};

export default CopySchemaStatusModal;
