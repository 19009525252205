import { v4 as uuidv4 } from 'uuid';
import {
    fetchCabinets as fetchCabinetsHttpRequest,
    setCabinetLocation as setCabinetLocationHttpRequest,
    fetchLocations as fetchLocationsHttpRequest,
    createLocation as createLocationHttpRequest,
    updateLocation as updateLocationHttpRequest,
    updateCabinetName as updateCabinetNameHttpRequest,
} from '../api.js';
import { storeSelectedCabinet, getStoredSelectedCabinet, storeSelectedCabinetId, getStoredSelectedCabinetId } from '../localStorage.js';

export const REQUEST_CABINETS = 'REQUEST_CABINETS';
export const RECEIVE_CABINETS = 'RECEIVE_CABINETS';
export const RECEIVE_CABINETS_ERROR = 'RECEIVE_CABINETS_ERROR';
export const SELECT_CABINET = 'SELECT_CABINET';
export const RESET_CABINETS = 'RESET_CABINETS';
export const REQUEST_SET_CABINET_LOCATION = 'REQUEST_SET_CABINET_LOCATION';
export const SET_CABINET_LOCATION_SUCCESS = 'SET_CABINET_LOCATION_SUCCESS';
export const SET_CABINET_LOCATION_ERROR = 'SET_CABINET_LOCATION_ERROR';
export const CABINET_SEARCH_QUERY_UPDATED = 'CABINET_SEARCH_QUERY_UPDATED';
export const REQUEST_LOCATIONS = 'REQUEST_LOCATIONS';
export const RECEIVE_LOCATIONS = 'RECEIVE_LOCATIONS';
export const RECEIVE_LOCATIONS_ERROR = 'RECEIVE_LOCATIONS_ERROR';
export const REQUEST_CREATE_LOCATION = 'REQUEST_CREATE_LOCATION';
export const CREATE_LOCATION_SUCCESS = 'CREATE_LOCATION_SUCCESS';
export const CREATE_LOCATION_ERROR = 'CREATE_LOCATION_ERROR';
export const REQUEST_RETIRE_LOCATION = 'REQUEST_RETIRE_LOCATION';
export const RETIRE_LOCATION_ERROR = 'RETIRE_LOCATION_ERROR';
export const REQUEST_UPDATE_LOCATION = 'REQUEST_UPDATE_LOCATION';
export const UPDATE_LOCATION_ERROR = 'UPDATE_LOCATION_ERROR';
export const REQUEST_UPDATE_CABINET_NAME = 'REQUEST_UPDATE_CABINET_NAME';
export const UPDATE_CABINET_NAME_ERROR = 'UPDATE_CABINET_NAME_ERROR';

export const CLEAR_STATE_ERROR = 'CLEAR_STATE_ERROR';

/**
 * Request cabinets Redux action creator
 */
function requestCabinets() {
    return {
        type: REQUEST_CABINETS,
    };
}

/**
 * Receive cabinets Redux action creator
 * @param {array} cabinets
 */
function receiveCabinets(cabinets, selectedCabinetDeviceCode, selectedCabinetDeviceId) {
    return {
        type: RECEIVE_CABINETS,
        cabinets,
        receivedAt: Date.now(),
        selectedCabinetDeviceCode,
        selectedCabinetDeviceId,
    };
}

/**
 * Receive cabinets error Redux action creator
 * @param {Error} error
 */
function receiveCabinetsError(error) {
    return {
        type: RECEIVE_CABINETS_ERROR,
        error,
    };
}

/**
 * Request locations Redux action creator
 */
function requestLocations() {
    return {
        type: REQUEST_LOCATIONS,
    };
}

/**
 * Receive locations Redux action creator
 * @param {array} locations
 */
function receiveLocations(locations) {
    return {
        type: RECEIVE_LOCATIONS,
        locations,
        receivedAt: Date.now(),
    };
}

/**
 * Receive locations error Redux action creator
 * @param {Error} error
 */
function receiveLocationsError(error) {
    return {
        type: RECEIVE_LOCATIONS_ERROR,
        error,
    };
}

/**
 * Reset to init state
 */
function resetCabinets() {
    return {
        type: RESET_CABINETS,
    };
}

/**
 * Clear state error message and isError
 */
function clearRetireLocationError() {
    return {
        type: CLEAR_STATE_ERROR,
    };
}

/**
 * Sets a cabinet as active selected cabinet
 * @param {string} selectedCabinetDeviceCode Cabinet device code to select
 */
export function selectCabinet(selectedCabinetDeviceCode, selectedCabinetDeviceId = null) {
    storeSelectedCabinet(selectedCabinetDeviceCode);
    storeSelectedCabinetId(selectedCabinetDeviceId);
    return {
        type: SELECT_CABINET,
        selectedCabinetDeviceCode,
        selectedCabinetDeviceId,
    };
}

export function refreshCabinets() {
    return dispatch => {
        dispatch(resetCabinets());
        dispatch(fetchCabinets());
    };
}

/**
 * Request set cabinet location Redux action creator
 */
function requestSetCabinetLocation(deviceCode, locationId) {
    return {
        type: REQUEST_SET_CABINET_LOCATION,
        deviceCode,
        locationId,
    };
}

/**
 * Successfull set cabinet location in backend Redux action creator
 */
function setCabinetLocationSuccess(deviceCode) {
    return {
        type: SET_CABINET_LOCATION_SUCCESS,
        deviceCode,
    };
}

/**
 * Error while setting cabinet location error Redux action creator
 * @param {Error} error
 */
function setCabinetLocationError(error, deviceCode) {
    return {
        type: SET_CABINET_LOCATION_ERROR,
        error,
        deviceCode,
    };
}

/**
 * Request create location Redux action creator
 */
function requestCreateLocation(locationId, locationName) {
    return {
        type: REQUEST_CREATE_LOCATION,
        locationId,
        locationName,
    };
}

/**
 * Successfull create location in backend Redux action creator
 */
function createLocationSuccess() {
    return {
        type: CREATE_LOCATION_SUCCESS,
    };
}

/**
 * Error while creating location error Redux action creator
 * @param {Error} error
 */
function createLocationError(error) {
    return {
        type: CREATE_LOCATION_ERROR,
        error,
    };
}

/**
 * Request retire location Redux action creator
 */
function requestRetireLocation(locationCode) {
    return {
        type: REQUEST_RETIRE_LOCATION,
        locationCode,
    };
}

/**
 * Error while retiring location error Redux action creator
 * @param {Error} error
 */
function retireLocationError(error) {
    return {
        type: RETIRE_LOCATION_ERROR,
        error,
    };
}

/**
 * Request update location Redux action creator
 */
function requestUpdateLocation(locationCode, updateFields) {
    return {
        type: REQUEST_UPDATE_LOCATION,
        locationCode,
        updateFields,
    };
}

/**
 * Error while updating location error Redux action creator
 * @param {Error} error
 */
function updateLocationError(error) {
    return {
        type: UPDATE_LOCATION_ERROR,
        error,
    };
}

/**
 * Request update cabinet name Redux action creator
 */
function requestUpdateCabinetName(deviceCode, updateFields) {
    return {
        type: REQUEST_UPDATE_CABINET_NAME,
        deviceCode,
        ...updateFields,
    };
}

/**
 * Error while updating location error Redux action creator
 * @param {Error} error
 */
function updateCabinetNameError(error) {
    return {
        type: UPDATE_CABINET_NAME_ERROR,
        error,
    };
}
/**
 * Thunk action creator for fetching cabinets
 */
export function fetchCabinets() {
    return (dispatch, getState) => {
        // update state to inform API call started
        dispatch(requestCabinets());

        // fetch products then update state
        const user = getState().user;
        return fetchCabinetsHttpRequest(user.accessToken, user.group)
            .then(cabinets => {
                // get selected cabinet from localStorage if set
                const storedSelectedCabinet = getStoredSelectedCabinet();
                const storedSelectedCabinetId = getStoredSelectedCabinetId();

                // check if selected cabinet from localStorage exists
                if (storedSelectedCabinet != null && cabinets.filter(cabinet => cabinet.deviceCode === storedSelectedCabinet).length > 0) {
                    return dispatch(receiveCabinets(cabinets, storedSelectedCabinet, storedSelectedCabinetId));
                } else {
                    dispatch(receiveCabinets(cabinets, null, null));
                }
            })
            .catch(error => dispatch(receiveCabinetsError(error)));
    };
}

/**
 * Thunk action creator for fetching locations
 */
export function fetchLocations() {
    return (dispatch, getState) => {
        // update state to inform API call started
        dispatch(requestLocations());

        // fetch locations then update state
        const user = getState().user;
        return fetchLocationsHttpRequest(user.accessToken, user.group.Id)
            .then(response => {
                return dispatch(receiveLocations(response));
            })
            .catch(error => dispatch(receiveLocationsError(error)));
    };
}

/**
 * Thunk action creator for setting cabinet location
 */
export function setCabinetLocation(deviceCode, locationId) {
    return (dispatch, getState) => {
        // update state to inform API call started
        dispatch(requestSetCabinetLocation(deviceCode, locationId));

        // fetch products then update state
        const user = getState().user;
        return setCabinetLocationHttpRequest(deviceCode, locationId, user.accessToken, user.group)
            .then(() => dispatch(setCabinetLocationSuccess(deviceCode)))
            .catch(error => dispatch(setCabinetLocationError(error, deviceCode)));
    };
}

/**
 * Notifies that user changed search cabinet query.
 * @param {*} query Query.
 */
export function cabinetSearchQueryUpdated(query) {
    return dispatch => {
        dispatch({
            type: CABINET_SEARCH_QUERY_UPDATED,
            searchQuery: query,
        });
    };
}

/**
 * Thunk action creator for creating location
 */
export function createLocation(locationName) {
    return (dispatch, getState) => {
        // generate random location id
        const locationId = uuidv4();

        // update state to inform API call started
        dispatch(requestCreateLocation(locationId, locationName));

        // create location then update state
        const user = getState().user;
        return createLocationHttpRequest(locationId, locationName, user.accessToken, user.group.Id)
            .then(() => dispatch(createLocationSuccess()))
            .catch(error => dispatch(createLocationError(error)));
    };
}

/**
 * Thunk action creator for retiring location
 */
export function retireLocation(locationCode) {
    return async (dispatch, getState) => {
        try {
            const user = getState().user;
            const result = await updateLocationHttpRequest(locationCode, { status: 'Retired' }, user.accessToken, user.group.Id);

            dispatch(requestRetireLocation(locationCode));
            return result;
        } catch (error) {
            return dispatch(retireLocationError(error));
        }
    };
}

/**
 * Thunk action creator for clearing error
 */
export function clearStateError() {
    return dispatch => {
        return dispatch(clearRetireLocationError());
    };
}

/**
 * Thunk action creator for updating parent location
 * @param locationCode
 * @param parentLocationCode
 */
export function updateLocationParent(locationCode, parentLocationCode) {
    return (dispatch, getState) => {
        // update state to inform API call started
        dispatch(requestUpdateLocation(locationCode, { parentLocationCode }));

        //change the parent location and then update state
        const user = getState().user;
        return updateLocationHttpRequest(locationCode, { parentLocationCode }, user.accessToken, user.group.Id).catch(error =>
            dispatch(updateLocationError(error))
        );
    };
}

/**
 * Thunk action creator for updating locations' name
 * @param locationCode
 * @param name
 */
export function updateLocationName(locationCode, name, parentLocationCode) {
    return (dispatch, getState) => {
        // update state to inform API call started
        dispatch(
            requestUpdateLocation(locationCode, {
                name: name,
                parentLocationCode: parentLocationCode,
            })
        );

        //change the parent location and then update state
        const user = getState().user;
        return updateLocationHttpRequest(locationCode, { name: name, parentLocationCode: parentLocationCode }, user.accessToken, user.group.Id).catch(error =>
            dispatch(updateLocationError(error))
        );
    };
}

/**
 * Thunk action creator for updating cabinets' names
 * @param cabinetCode
 * @param name
 */
export function updateCabinetName(cabinetCode, name) {
    return (dispatch, getState) => {
        // update state to inform API call started
        dispatch(
            requestUpdateCabinetName(cabinetCode, {
                name,
            })
        );

        //change the parent location and then update state
        const user = getState().user;
        return updateCabinetNameHttpRequest(cabinetCode, { name }, user.accessToken).catch(error => dispatch(updateCabinetNameError(error)));
    };
}
