import React, { Fragment, useEffect, useState } from 'react';
import { Spinner, TabItem, TabBar, List } from '@storaensods/seeds-react';
import { Row, Col } from 'reactstrap';
import { groupBy } from 'lodash';

import AnalyticsSearchBar from './analyticsSearchBar';
import ExpiredProductsBarChart from './expired/expiredProductsBarChart';
import TopExpiredProductsBarChart from './expired/topExpiredProductsBarChart';
import CardView from './expired/cardView/';
import NavBar from './expired/navBar';
import { storeWasteType } from './../../../localStorage';

const WasteDashboard = props => {
    const { chart, dashboardState, fetchWasteDashboardData, wasteDashboardData, t } = props;

    const [expiredProducts, setExpiredProducts] = useState({ data: [] });
    const [salesTrasactions, setSalesTransactions] = useState({ data: [] });
    const [selectedNav, setSelectedNav] = useState('all');
    const [selectionItems, setSelectionsItems] = useState([]);
    const [activeSelectionItem, setactiveSelectionItem] = useState({ title: '' });
    const [cardData, setCardData] = useState({ sales: {}, waste: {} });
    const [wasteCategory, setWasteCategory] = useState('Expired');

    const wasteCastegories = [{ name: t('expired'), value: 'Expired' }];

    // when component loads, fetch the data for the waste dashboard
    useEffect(() => {
        const { isError, isFetching, fetched } = wasteDashboardData;
        const { dateRange, filter } = dashboardState;

        storeWasteType('Expired');

        if (!isFetching && !fetched && !isError) {
            fetchWasteDashboardData(dateRange, filter);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // when dashboard data changes/fetched, get the expired products, sales and discounts
    useEffect(() => {
        const { isError, fetched, expiredProducts, totalSales } = wasteDashboardData;

        if (fetched && !isError) {
            setExpiredProducts(expiredProducts);
            // const expiredByBarcode = groupBy(totalSales.data, product=>product['Device.deviceName'])
            setSalesTransactions(totalSales);
        }
    }, [wasteDashboardData]);

    // List the products with search query applied
    useEffect(() => {
        const { searchQuery } = chart;

        let waste = {},
            sales = {};

        switch (selectedNav) {
            case 'products':
            case 'all':
                waste = groupBy(expiredProducts.data, product => product['ExpiredProducts.productName']);
                sales = groupBy(salesTrasactions.data, product => product['Transactions.productName']);
                break;
            case 'locations':
                waste = groupBy(expiredProducts.data, product => (product['Device.region'] ? product['Device.region'] : 'Default Location'));
                sales = groupBy(salesTrasactions.data, product =>
                    product['Transactions.locationName'] ? product['Transactions.locationName'] : 'Default Location'
                );
                break;
            case 'cabinets':
                waste = groupBy(expiredProducts.data, product => product['Device.deviceName']);
                sales = groupBy(salesTrasactions.data, product => product['Device.deviceName']); // Query response is getting modified in rootapi
                break;
            default:
                waste = groupBy(expiredProducts.data, product => product['ExpiredProducts.productName']);
                sales = groupBy(salesTrasactions.data, product => product['Transactions.productName']);
                break;
        }

        // combine sales and expired unique keys
        const objectKeys = [...Object.keys(sales), ...Object.keys(waste).filter(key => !Object.keys(sales).includes(key))];

        const searchListArray = objectKeys
            .filter(key => {
                return key.toLowerCase().indexOf(searchQuery.toLowerCase()) !== -1;
            })
            .map(item => {
                return { title: item === 'null' ? 'Default Location' : item }; // NOTE: if deviceName is null, Default Location gets rendered
            });

        setCardData({ sales, waste });
        setSelectionsItems(searchListArray);
        setactiveSelectionItem(searchListArray[0]);
    }, [selectedNav, chart, salesTrasactions, expiredProducts]);

    return (
        <Fragment>
            {wasteDashboardData.isError && <div>{t('fetchingError')}</div>}

            {!wasteDashboardData.fetched && !wasteDashboardData.isError && (
                <div className="d-flex justify-content-center pt-3">
                    <Spinner />
                </div>
            )}

            {wasteDashboardData.fetched && expiredProducts && (
                <Fragment>
                    <TabBar className="pl-4 not-printable" style={{ background: '#fff' }}>
                        {wasteCastegories.map(category => (
                            <TabItem
                                key={category.value}
                                isActive={category.value === wasteCategory}
                                onClick={() => {
                                    storeWasteType(category.value);
                                    setWasteCategory(category.value);
                                }}
                            >
                                {t(category.name)}
                            </TabItem>
                        ))}
                    </TabBar>
                    {wasteCategory === 'Expired' && (
                        <Fragment>
                            <Row className="pl-4 pr-4 pt-3">
                                <ExpiredProductsBarChart t={t} data={expiredProducts} />
                                <TopExpiredProductsBarChart t={t} data={expiredProducts.data} />
                            </Row>
                            <Row className="pl-4 pr-4 pt-2">
                                <Col xs="12" md="12" lg="12">
                                    <div className="pt-4 sales-waste-title">
                                        <h5>{t('salesVsWasteAmount')}</h5>
                                    </div>
                                    <NavBar t={t} selectedNav={nav => setSelectedNav(nav)} active={selectedNav} />
                                </Col>
                            </Row>
                            <Row className="dashboard-chart-container">
                                {selectedNav !== 'all' && (
                                    <Col xs="12" md="6" lg="3" className="chart-list">
                                        <AnalyticsSearchBar />
                                        <List
                                            data={selectionItems}
                                            dataKey="title"
                                            onItemClick={(event, item) => {
                                                setactiveSelectionItem(item);
                                            }}
                                            activeItem={activeSelectionItem ? activeSelectionItem.title : selectionItems.length ? selectionItems[0].title : ''}
                                        />
                                    </Col>
                                )}
                                <Col xs="12" md={selectedNav === 'all' ? '12' : '6'} lg={selectedNav === 'all' ? '12' : '9'}>
                                    {selectedNav !== 'all' && (
                                        <div className="card-notification">
                                            <strong>{activeSelectionItem ? activeSelectionItem.title : '-'}</strong>
                                        </div>
                                    )}
                                    <CardView
                                        expiredProducts={expiredProducts}
                                        salesTrasactions={salesTrasactions}
                                        selectedNav={selectedNav}
                                        selectionItems={selectionItems}
                                        activeSelectionItem={activeSelectionItem}
                                        cardData={cardData}
                                        t={t}
                                    />
                                </Col>
                            </Row>
                        </Fragment>
                    )}
                </Fragment>
            )}
        </Fragment>
    );
};

export default WasteDashboard;
