import { fetchProductTypes as fetchProductTypesHttpRequest } from '../api.js';

export const REQUEST_PRODUCT_TYPES = 'REQUEST_PRODUCT_TYPES';
export const RECEIVE_PRODUCT_TYPES = 'RECEIVE_PRODUCT_TYPES';
export const RECEIVE_PRODUCT_TYPES_ERROR = 'RECEIVE_PRODUCT_TYPES_ERROR';

/**
 * Request product types Redux action creator
 */
function requestProductTypes() {
    return {
        type: REQUEST_PRODUCT_TYPES,
    };
}

/**
 * Receive product types Redux action creator
 * @param {array} productTypes
 */
function receiveProductTypes(productTypes) {
    return {
        type: RECEIVE_PRODUCT_TYPES,
        productTypes,
        receivedAt: Date.now(),
    };
}

/**
 * Receive product types error Redux action creator
 * @param {Error} error
 */
function receiveProductTypesError(error) {
    return {
        type: RECEIVE_PRODUCT_TYPES_ERROR,
        error: error,
    };
}

/**
 * Thunk action creator for fetching product types
 */
export function fetchProductTypes() {
    return function(dispatch, getState) {
        // update state to inform API call started
        dispatch(requestProductTypes());

        // fetch product types then update state
        const accessKey = getState().user.accessToken;
        return fetchProductTypesHttpRequest(accessKey)
            .then(response => dispatch(receiveProductTypes(response)))
            .catch(error => dispatch(receiveProductTypesError(error)));
    };
}
