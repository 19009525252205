import React, { useState, useEffect, Fragment } from 'react';
import { ModalBody, Modal as ReactstrapModal, Button as RButton, Row, Col, ModalHeader, ModalFooter } from 'reactstrap';
import { Button, Icon, Accordion, Input as SEInput, Input } from '@storaensods/seeds-react';
import { PhotoshopPicker } from 'react-color';
import icons from './theming/icons.svg';
import './cabinetTheming.css';
import ImageUpload from '../imageUpload/imageUpload';
import { Toggle } from '../commons/utils';

export default function CabinetTheming(props) {
    const { t, theme, toggleThemingModal, updateSelectedCabinetConfiguration, open, themingImage, uploadThemeImage, uploadPostPurchaseThemeImage } = props;
    const [confirmationModal, toggleConfirmationModal] = useState(false);
    const [screenImage, setScreenImage] = useState({ prestine: true, imgUrl: themingImage.imgUrl });
    const [postPurchaseThemeImage, setPostPurchaseThemeImage] = useState({ prestine: true, imgUrl: themingImage.postPurchaseThemeImage.imgUrl });
    const [toggleCropping, setToggleCropping] = useState(false);
    const [imageUploadModelOpen, setImageUploadModelOpen] = useState(false);
    const [postPurchaseScreenOption, setPostPurchaseScreenOption] = useState('default');
    const [postPurchaseImageModalOpen, setPostPurchaseImageModalOpen] = useState(false);
    const [postPurchaseText, setPostPurchaseText] = useState('');

    const [theming, setTheming] = useState(
        {
            confirmationModalOpen: false,
            colors: [
                {
                    type: 'primary',
                    label: t('cabinetSettings:primaryColor'),
                    subLabel: t('cabinetSettings:primary'),
                    value: '#007BC7',
                    colorPickerOpen: false,
                },
                {
                    type: 'positive',
                    label: t('cabinetSettings:positiveColors'),
                    subLabel: t('cabinetSettings:positive'),
                    value: '#548D1C',
                    colorPickerOpen: false,
                },
                {
                    type: 'attention',
                    label: t('cabinetSettings:attentionColors'),
                    subLabel: t('cabinetSettings:attention'),
                    value: '#ffaa00',
                    colorPickerOpen: false,
                },
                {
                    type: 'negative',
                    label: t('cabinetSettings:negativeColors'),
                    value: '#e50b40',
                    colorPickerOpen: false,
                    /*subColors: [
                    { type: 'negative', label: t('cabinetSettings:negative'), ,  },
                    { type: 'negative-light', label: t('cabinetSettings:negativeLight'), value: '#e5738e', colorPickerOpen: false },
                ]*/
                },
                {
                    type: 'fontColors',
                    label: t('cabinetSettings:fontColors'),
                    colorPickerOpen: false,
                    subColors: [
                        { type: 'font-white', value: '#ffffff', colorPickerOpen: false, label: t('cabinetSettings:white') },
                        { type: 'font-dark', value: '#202020', colorPickerOpen: false, label: t('cabinetSettings:dark') },
                        { type: 'font-default', value: '#515151', colorPickerOpen: false, label: t('cabinetSettings:default') },
                        { type: 'font-light', value: '#767676', colorPickerOpen: false, label: t('cabinetSettings:light') },
                    ],
                },
                {
                    type: 'shadesColors',
                    label: t('cabinetSettings:borderColors'),
                    subColors: [
                        { type: 'shade', label: t('cabinetSettings:border'), value: '#CCCCCC', colorPickerOpen: false },
                        { type: 'shadow', label: t('cabinetSettings:shadow'), value: '#a8a8a8', colorPickerOpen: false },
                    ],
                },
                {
                    type: 'background',
                    label: t('cabinetSettings:screenBackground'),
                    subColors: [
                        { type: 'background', label: t('cabinetSettings:backgroundColor'), value: '#ffffff', colorPickerOpen: false },
                        { type: 'background-image', label: t('cabinetSettings:backgroundImage'), value: null, colorPickerOpen: false },
                    ],
                    subLabel: t('cabinetSettings:background'),
                    value: '#ffffff',
                    colorPickerOpen: false,
                },
                {
                    type: 'footer',
                    label: t('cabinetSettings:footerBackground'),
                    value: '#ffffff',
                    colorPickerOpen: false,
                    subLabel: t('cabinetSettings:backgroundColor'),
                },
                {
                    type: 'cards',
                    label: t('cabinetSettings:cardsBackground'),
                    value: '#ffffff',
                    colorPickerOpen: false,
                    subLabel: t('cabinetSettings:backgroundColor'),
                },
            ],
            activePage: 1,
        },
        []
    );

    useEffect(() => {
        if (theme) {
            const parsedTheme = JSON.parse(theme);
            const colors = theming.colors.map(color => {
                const matchingSetting = parsedTheme.colors[color.type];

                if (color.type === 'background') {
                    if (matchingSetting['--c-background-image']) {
                        setScreenImage({ prestine: false, imgUrl: matchingSetting['--c-background-image'] });
                    }
                }
                if (matchingSetting && Object.keys(matchingSetting).length > 1) {
                    return {
                        ...color,
                        subColors: color.subColors.map((sColor, i) => {
                            return { ...sColor, value: Object.values(matchingSetting)[i] };
                        }),
                    };
                } else if (matchingSetting && Object.keys(matchingSetting).length === 1) return { ...color, value: Object.values(matchingSetting)[0] };
                else return color;
            });

            if (parsedTheme.colors.postPurchase) {
                setPostPurchaseScreenOption(parsedTheme.colors.postPurchase.mode || 'default');
                setPostPurchaseThemeImage({ prestine: true, imgUrl: parsedTheme.colors.postPurchase.imgUrl });
                setPostPurchaseText(parsedTheme.colors.postPurchase.text);
            }
            setTheming({ ...theming, colors });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [theme]);

    useEffect(() => {
        //ignore if componenet loaded for the first time
        if (!screenImage.prestine) {
            setScreenImage({ prestine: false, imgUrl: themingImage.imgUrl });
        }
        if (!postPurchaseThemeImage.prestine) {
            setPostPurchaseThemeImage({ prestine: false, imgUrl: themingImage.postPurchaseThemeImage.imgUrl });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [themingImage]);

    const handleColorPicker = (colorField, action, color = undefined, subColorProperty = undefined) => {
        let colors = [...theming.colors];
        let colorObj = colors.find(c => c.type === colorField);
        switch (action) {
            case 'cancel':
                if (subColorProperty) {
                    const subColorObj = colorObj.subColors.find(c => c.type === subColorProperty);
                    subColorObj.value = subColorObj.oldValue;
                    subColorObj.colorPickerOpen = false;
                    colorObj = {
                        ...colorObj,
                        subColors: [
                            ...colorObj.subColors.map(c => {
                                return c.type === subColorProperty ? subColorObj : c;
                            }),
                        ],
                    };
                } else {
                    colorObj.value = colorObj.oldValue;
                    colorObj.colorPickerOpen = false;
                }

                break;
            case 'close':
            case 'accept':
                if (subColorProperty) {
                    const subColorObj = colorObj.subColors.find(c => c.type === subColorProperty);
                    subColorObj.colorPickerOpen = false;
                    colorObj = {
                        ...colorObj,
                        colorPickerOpen: false,
                        subColors: [
                            ...colorObj.subColors.map(c => {
                                return c.type === subColorProperty ? subColorObj : { ...c, colorPickerOpen: false };
                            }),
                        ],
                    };
                } else {
                    colorObj.colorPickerOpen = false;
                }

                break;
            case 'setColor':
                if (subColorProperty) {
                    const subColorObj = colorObj.subColors.find(c => c.type === subColorProperty);
                    subColorObj.value = color.hex;
                    colorObj = {
                        ...colorObj,
                        subColors: [
                            ...colorObj.subColors.map(c => {
                                return c.type === subColorProperty ? subColorObj : c;
                            }),
                        ],
                    };
                } else {
                    colorObj.value = color.hex;
                }

                break;
            case 'open':
                colors = colors.map(c => {
                    return {
                        ...c,
                        subColors: c.subColors
                            ? c.subColors.map(s => {
                                  return { ...s, colorPickerOpen: false };
                              })
                            : undefined,
                        colorPickerOpen: false,
                    };
                });
                if (subColorProperty) {
                    const subColorObj = colorObj.subColors.find(c => c.type === subColorProperty);
                    subColorObj.oldValue = subColorObj.value;
                    subColorObj.colorPickerOpen = true;
                    colorObj = {
                        ...colorObj,
                        subColors: [
                            ...colorObj.subColors.map(c => {
                                return c.type === subColorProperty ? subColorObj : { ...c, colorPickerOpen: false };
                            }),
                        ],
                        colorPickerOpen: false,
                    };
                } else {
                    colorObj.oldValue = colorObj.value;
                    colorObj.subColors = colorObj.subColors
                        ? colorObj.subColors.map(c => {
                              return { ...c, colorPickerOpen: false };
                          })
                        : undefined;
                    colorObj.colorPickerOpen = true;
                }
                break;
            default:
                return;
        }
        setTheming({
            ...theming,
            colors: [
                ...colors.map(c => {
                    return c.type === colorField ? colorObj : c;
                }),
            ],
        });
    };
    const changePage = direction => {
        let activePage;
        const currentPage = theming.activePage;
        if (direction === 'next') {
            if (currentPage < 5) activePage = currentPage + 1;
            if (currentPage >= 5) return;
        }
        if (direction === 'prev') {
            if (currentPage > 1) activePage = currentPage - 1;
            if (currentPage <= 1) return;
        }

        setTheming({ ...theming, activePage });
    };

    const getColor = (color, propertyForSubcolor = false) => {
        let colorObj = theming.colors.find(c => c.type === color);
        if (colorObj && color === 'background') {
            const bgColor = colorObj.subColors.find(c => c.type === 'background').value;

            if (screenImage.imgUrl) {
                return `url(${screenImage.imgUrl})`;
            } else {
                return bgColor;
            }
        } else {
            if (propertyForSubcolor) {
                return colorObj.subColors.find(c => c.type === propertyForSubcolor)?.value;
            } else return colorObj.value;
        }
    };

    const saveTheming = () => {
        let themeSettingString = {
            theme: 'custom',
            colors: theming.colors.reduce((obj, c) => {
                const { type, value, subColors } = c;
                if (subColors) {
                    let subColorObj = {};
                    subColors.forEach(subColor => {
                        const subType = subColor.type;
                        let subValue = subColor.value;

                        if (subType === 'background-image') {
                            if (!screenImage.prestine) {
                                subValue = screenImage.imgUrl;
                            } else if (screenImage.prestine && theme) {
                                const parsedTheme = JSON.parse(theme);
                                subValue = parsedTheme.colors['background']['--c-background-image']
                                    ? parsedTheme.colors['background']['--c-background-image']
                                    : '';
                            }
                        }

                        Object.assign(subColorObj, { [`--c-${subType}`]: subValue });
                    });
                    obj[type] = subColorObj;
                } else obj[type] = { [`--c-${type}`]: value };
                return obj;
            }, {}),
        };

        if (!postPurchaseThemeImage.prestine) {
            themeSettingString.colors['postPurchase'] = {
                mode: postPurchaseScreenOption,
                imgUrl: themingImage.postPurchaseThemeImage.imgUrl || '',
                text: postPurchaseText,
            };
        } else if (postPurchaseThemeImage.prestine && theme) {
            const parsedTheme = JSON.parse(theme);
            themeSettingString.colors['postPurchase'] = {
                mode: postPurchaseScreenOption,
                imgUrl: parsedTheme.colors['postPurchase'] ? parsedTheme.colors['postPurchase'].imgUrl : '',
                text: postPurchaseText,
            };
        }

        updateSelectedCabinetConfiguration({ cabinetUiTheme: themeSettingString });
        toggleThemingModal();
    };

    /**
     *
     * @returns Confirmation modal UI for saving the theme
     */
    const confimationModal = () => {
        return (
            <ReactstrapModal isOpen={true} centered size="xl" className="theming-confirmationModal" toggle={() => toggleConfirmationModal(!confirmationModal)}>
                <ModalHeader>{t('cabinetSettings:confirmationModalHeader')}</ModalHeader>
                <ModalBody className="mt-3 p-3 text-center">{t('cabinetSettings:confirmationModalContent')}</ModalBody>
                <ModalFooter>
                    <Button onClick={() => toggleConfirmationModal(!confirmationModal)} className="cancelBtn">
                        {t('cancel')}
                    </Button>
                    <Button onClick={() => saveTheming()} className="saveBtn">
                        {t('save')}
                    </Button>
                </ModalFooter>
            </ReactstrapModal>
        );
    };

    /**
     *
     * @returns Model for uploading the image
     */
    const imageUploadModel = () => {
        return (
            <ReactstrapModal isOpen={true} centered={true} size="lg" className="theming-imageModal" toggle={() => {}}>
                <ModalHeader>{t('cabinetSettings:screenImage')}</ModalHeader>
                <ModalBody>
                    <ImageUpload
                        className="se-input-container"
                        freeAspectRatio={false}
                        targetWidth={1920}
                        targetHeight={850}
                        imageUrl={screenImage.imgUrl}
                        storing={themingImage.uploading}
                        uploadImage={img => {
                            setScreenImage({ prestine: false, imgUrl: screenImage.imgUrl });
                            return uploadThemeImage(img);
                        }}
                        removeImage={() => setScreenImage({ prestine: false, imgUrl: null })}
                        toggleCropping={() => setToggleCropping(!toggleCropping)}
                        t={t}
                    />
                </ModalBody>
                <ModalFooter>
                    <Button onClick={() => setImageUploadModelOpen(false)} type="secondary">
                        {t('close')}
                    </Button>
                </ModalFooter>
            </ReactstrapModal>
        );
    };

    /**
     *
     * @returns Model for uploading the image
     */
    const postPurchaseImageUploadModal = () => {
        return (
            <ReactstrapModal isOpen={true} centered={true} size="lg" className="theming-imageModal" toggle={() => {}}>
                <ModalHeader>{t('cabinetSettings:customPostPurchaseImage')}</ModalHeader>
                <ModalBody>
                    <ImageUpload
                        className="se-input-container"
                        freeAspectRatio={false}
                        targetWidth={1920}
                        targetHeight={1080}
                        imageUrl={postPurchaseThemeImage.imgUrl}
                        storing={themingImage.postPurchaseThemeImage.uploading}
                        uploadImage={img => {
                            setPostPurchaseThemeImage({ prestine: false, imgUrl: screenImage.imgUrl });
                            return uploadPostPurchaseThemeImage(img);
                        }}
                        removeImage={() => setPostPurchaseThemeImage({ prestine: false, imgUrl: null })}
                        toggleCropping={() => setToggleCropping(!toggleCropping)}
                        t={t}
                    />
                </ModalBody>
                <ModalFooter>
                    <Button onClick={() => setPostPurchaseImageModalOpen(false)} type="secondary">
                        {t('close')}
                    </Button>
                </ModalFooter>
            </ReactstrapModal>
        );
    };

    /**
     *
     * @param {*} value the color or image
     * @returns style for the image or hext color DOM
     */
    const getBackgroundStyle = value => {
        if (theming.activePage === 5) {
            return;
        }

        if (value.startsWith('#')) {
            return { background: value };
        } else {
            return { backgroundImage: value, backgroundSize: 'cover', backgroundRepeat: 'no-repeat' };
        }
    };

    /**
     * preview window for theming background
     * @returns
     */
    const getThemingBackgroundStyle = () => {
        if (postPurchaseScreenOption === 'default') {
            return { background: getColor('background') };
        } else {
            if (postPurchaseThemeImage.imgUrl) {
                return { background: `url(${postPurchaseThemeImage.imgUrl})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat' };
            } else {
                return { background: getColor('background') };
            }
        }
    };

    const ThemingModal = () => {
        const { activePage } = theming;
        return (
            <ReactstrapModal isOpen={open} autoFocus={open} className="themingModal" toggle={() => toggleThemingModal()}>
                {confirmationModal && confimationModal()}
                {imageUploadModelOpen && imageUploadModel()}
                {postPurchaseImageModalOpen && postPurchaseImageUploadModal()}
                <ModalBody className="mt-3 p-3 text-center d-inline-flex">
                    <div className="sideBar">
                        <div className="sideBarTitleContainer">
                            <Icon className="mr-2">palette</Icon> <span> {t('cabinetSettings:themeSettings')}</span>
                        </div>
                        <div className="colorsContainer">
                            {theming.colors.map((colorObj, i) => {
                                return (
                                    <Accordion onClick={() => handleColorPicker(colorObj.type, 'close')} title={colorObj.label} key={colorObj.type}>
                                        {' '}
                                        <Row>
                                            {!colorObj.subColors && (
                                                <Fragment>
                                                    <Col lg={7} md={7} className="pr-1">
                                                        <SEInput label={colorObj.subLabel} value={colorObj.value} />
                                                    </Col>
                                                    <Col lg={1} md={1} className="pl-0">
                                                        <RButton
                                                            className="colorPicker--colorPreview"
                                                            onClick={() => handleColorPicker(colorObj.type, 'open')}
                                                            style={{ backgroundColor: colorObj.value }}
                                                        />
                                                    </Col>
                                                </Fragment>
                                            )}
                                            {colorObj.subColors &&
                                                colorObj.subColors.map(sColor => {
                                                    if (sColor.type === 'background-image') {
                                                        return (
                                                            <Row className="screen-image mt-2" key={sColor.type}>
                                                                <Col md={12} className="pr-1 mt-2">
                                                                    {t(sColor.label)}
                                                                </Col>
                                                                {screenImage.imgUrl && (
                                                                    <Col md={12} className="screen-image-icon">
                                                                        <img src={screenImage.imgUrl} alt="" />
                                                                    </Col>
                                                                )}
                                                                {screenImage.imgUrl ? (
                                                                    <Col md={12}>
                                                                        <Button onClick={() => setImageUploadModelOpen(true)} className="mt-2">
                                                                            {t('cabinetSettings:editImage')}
                                                                        </Button>
                                                                    </Col>
                                                                ) : (
                                                                    <Col md={12}>
                                                                        <Button onClick={() => setImageUploadModelOpen(true)} className="mt-2">
                                                                            {t('cabinetSettings:uploadImage')}
                                                                        </Button>
                                                                    </Col>
                                                                )}
                                                            </Row>
                                                        );
                                                    } else {
                                                        return (
                                                            <Fragment key={sColor.type}>
                                                                <Col lg={7} md={7} className="pr-1 mt-2">
                                                                    {' '}
                                                                    <SEInput label={sColor.label} value={sColor.value} />
                                                                </Col>
                                                                <Col lg={1} md={1} className="pl-0">
                                                                    {' '}
                                                                    <RButton
                                                                        className="colorPicker--colorPreview"
                                                                        onClick={() => handleColorPicker(colorObj.type, 'open', undefined, sColor.type)}
                                                                        style={{ backgroundColor: sColor.value }}
                                                                    />
                                                                </Col>
                                                                {sColor.colorPickerOpen && (
                                                                    <div className=" colorPickerContainer">
                                                                        <PhotoshopPicker
                                                                            header={`${t('cabinetSettings:colorPicker')}: ${colorObj.label} - ${sColor.label}`}
                                                                            onAccept={() => handleColorPicker(colorObj.type, 'accept', undefined, sColor.type)}
                                                                            onCancel={() => handleColorPicker(colorObj.type, 'cancel', undefined, sColor.type)}
                                                                            color={sColor.value}
                                                                            onChange={value => handleColorPicker(colorObj.type, 'setColor', value, sColor.type)}
                                                                        />
                                                                    </div>
                                                                )}
                                                            </Fragment>
                                                        );
                                                    }
                                                })}
                                            {colorObj.colorPickerOpen && (
                                                <div className=" colorPickerContainer">
                                                    <PhotoshopPicker
                                                        header={`${t('cabinetSettings:colorPicker')}: ${colorObj.label}`}
                                                        onAccept={() => handleColorPicker(colorObj.type, 'accept')}
                                                        onCancel={() => handleColorPicker(colorObj.type, 'cancel')}
                                                        color={colorObj.value}
                                                        onChange={value => handleColorPicker(colorObj.type, 'setColor', value)}
                                                    />
                                                </div>
                                            )}
                                        </Row>
                                    </Accordion>
                                );
                            })}
                            <Accordion title={t('cabinetSettings:postPurchaseScreen')} key="post-purhcase">
                                <Row className="post-purchase-image">
                                    <Col className="post-purchase-toggle">
                                        <Toggle
                                            options={[
                                                { text: t('cabinetSettings:default'), value: 'default' },
                                                { text: t('cabinetSettings:custom'), value: 'custom' },
                                            ]}
                                            selectedOption={postPurchaseScreenOption}
                                            onClick={selected => setPostPurchaseScreenOption(selected)}
                                        />
                                    </Col>
                                    {postPurchaseScreenOption === 'custom' && (
                                        <Fragment>
                                            {postPurchaseThemeImage.imgUrl && (
                                                <Fragment>
                                                    <Col md={12} className="post-screen-image-icon mt-3">
                                                        <label>{t('cabinetSettings:backgroundImage')}</label>
                                                        <img src={postPurchaseThemeImage.imgUrl} alt="" />
                                                    </Col>
                                                    <Col md={12}>
                                                        <Button onClick={() => setPostPurchaseImageModalOpen(true)} className="mt-2">
                                                            {t('cabinetSettings:editImage')}
                                                        </Button>
                                                    </Col>
                                                    <Col md={12} className="post-purchase-text mt-10">
                                                        <label>{t('cabinetSettings:postPurchaseText')}</label>
                                                        <Input
                                                            placeholder="this is the post text"
                                                            className="post-purchase-text"
                                                            onChange={e => setPostPurchaseText(e.target.value)}
                                                            value={postPurchaseText}
                                                        />
                                                    </Col>
                                                </Fragment>
                                            )}
                                            {!postPurchaseThemeImage.imgUrl && (
                                                <Col md={12}>
                                                    <Button onClick={() => setPostPurchaseImageModalOpen(true)} className="mt-2">
                                                        {t('cabinetSettings:uploadImage')}
                                                    </Button>
                                                </Col>
                                            )}
                                        </Fragment>
                                    )}
                                </Row>
                            </Accordion>

                            <div className="p-3 mt-2">
                                <Button onClick={() => toggleConfirmationModal(!confirmationModal)} className="colorPickerSaveBtn">
                                    {' '}
                                    {t('saveChanges')}{' '}
                                </Button>
                            </div>
                        </div>
                    </div>
                    <div className="previewContainer">
                        <div className="previewHeader">
                            <div>
                                <i className="far fa-eye" /> {t('preview')}{' '}
                            </div>
                            <div className="paginationFooter d-inline-flex">
                                <i className="fas fa-arrow-circle-left pe-auto" onClick={() => changePage('prev')}></i>
                                <i className={`${activePage === 1 ? 'fas' : 'far'} fa-circle`}></i>
                                <i className={`${activePage === 2 ? 'fas' : 'far'} fa-circle`}></i>
                                <i className={`${activePage === 3 ? 'fas' : 'far'} fa-circle`}></i>
                                <i className={`${activePage === 4 ? 'fas' : 'far'} fa-circle`}></i>
                                <i className={`${activePage === 5 ? 'fas' : 'far'} fa-circle`}></i>
                                <i className="fas fa-arrow-circle-right" onClick={() => changePage('next')}></i>
                            </div>
                            <div role="button">
                                <i className="fas fa-times" onClick={() => toggleThemingModal()} />
                            </div>
                        </div>
                        <div style={getBackgroundStyle(getColor('background'))} className="previewContent">
                            {[1, 2, 3].includes(activePage) && (
                                <>
                                    <Row className="preview--productsRow ">
                                        <div className="previewContent--singleProduct--container">
                                            <div
                                                className="previewContent--singleProduct"
                                                style={{
                                                    background: getColor('cards'),
                                                    borderColor: getColor('shadesColors', 'shade'),
                                                    boxShadow: `0 0 20px ${getColor('shadesColors', 'shadow')}`,
                                                }}
                                            >
                                                <svg>
                                                    <use xlinkHref={`${icons}#icon-cube`} />
                                                </svg>
                                                <div className="previewContent--singleProduct--footer">
                                                    <div style={{ color: getColor('fontColors', 'font-dark') }}>
                                                        <span className="currency">€</span>
                                                        <span className="value">4</span>
                                                        <span className="currencyDecimal">00</span>
                                                    </div>
                                                    <div style={{ color: getColor('fontColors', 'font-default') }}>{t('cabinetSettings:productName')}</div>
                                                </div>
                                            </div>

                                            <div
                                                className="previewContent--singleProduct isDiscounted"
                                                style={{
                                                    background: getColor('cards'),
                                                    borderColor: getColor('shadesColors', 'shade'),
                                                    boxShadow: `0 0 20px ${getColor('shadesColors', 'shadow')}`,
                                                }}
                                            >
                                                <svg>
                                                    <use xlinkHref={`${icons}#icon-cube`} />
                                                </svg>
                                                <div style={{ color: getColor('negative') }} className="previewContent--singleProduct-beforeDiscountPrice">
                                                    <span className="currency">€</span>
                                                    <span className="value">5</span>
                                                    <hr style={{ borderColor: getColor('negative') }} className="previewContent-strikeThrough" />
                                                    <span className="currencyDecimal">00</span>
                                                </div>

                                                <div className="previewContent--singleProduct--footer" style={{ color: getColor('positive') }}>
                                                    <div>
                                                        <span className="currency">€</span>
                                                        <span className="value">4</span>
                                                        <span className="currencyDecimal">00</span>
                                                    </div>
                                                    <div style={{ color: getColor('fontColors', 'font-default') }}>{t('cabinetSettings:productName')}</div>
                                                </div>
                                            </div>
                                            <div
                                                className="previewContent--discountBubble"
                                                style={{
                                                    color: getColor('fontColors', 'font-white'),
                                                    backgroundColor: getColor('positive'),
                                                    boxShadow: `0 0 20px ${getColor('shadesColors', 'shadow')}`,
                                                }}
                                            >
                                                -20%
                                            </div>
                                        </div>
                                    </Row>
                                    <Row
                                        className="previewFooter"
                                        style={{ boxShadow: `0 0 5px ${getColor('shadesColors', 'shadow')}`, backgroundColor: getColor('footer') }}
                                    >
                                        {activePage === 2 && (
                                            <Col
                                                lg={12}
                                                md={12}
                                                className="w-100 expiringProductsDiv"
                                                style={{ backgroundColor: getColor('attention'), color: getColor('fontColors', 'font-default') }}
                                            >
                                                <span>
                                                    <svg style={{ fill: getColor('fontColors', 'font-default') }}>
                                                        <use xlinkHref={`${icons}#icon-notification`} />
                                                    </svg>
                                                    {t('cabinetSettings:expiringText')}
                                                </span>
                                            </Col>
                                        )}
                                        {activePage === 3 && (
                                            <Col
                                                lg={12}
                                                md={12}
                                                className="w-100 expiringProductsDiv"
                                                style={{ backgroundColor: getColor('negative'), color: getColor('fontColors', 'font-white') }}
                                            >
                                                <span>
                                                    <svg style={{ fill: getColor('fontColors', 'font-white') }}>
                                                        <use xlinkHref={`${icons}#icon-notification`} />
                                                    </svg>{' '}
                                                    {t('cabinetSettings:expiredText')}
                                                </span>
                                            </Col>
                                        )}
                                        {/* {activePage === 1 && <Col lg={12} md={12} className="w-100 expiringProductsDiv placeholderDiv"></Col>}*/}
                                        <Col
                                            style={{ color: getColor('fontColors', 'font-light'), borderColor: getColor('shadesColors', 'shade') }}
                                            lg={2}
                                            md={2}
                                            className="footer-left"
                                        >
                                            {t('cabinetSettings:cabinetName')}
                                        </Col>
                                        <Col className="footer-mid" style={{ borderColor: getColor('shadesColors', 'shade') }}>
                                            <div className="footer-mid-content">
                                                <div
                                                    className="footer-mid-info"
                                                    style={{ color: getColor('fontColors', 'font-white'), backgroundColor: getColor('primary') }}
                                                >
                                                    {t('cabinetSettings:footerTextPlaceholder')}
                                                </div>
                                            </div>
                                        </Col>
                                        <Col lg={2} md={2} style={{ borderColor: getColor('shadesColors', 'shade') }} className="footer-right"></Col>
                                    </Row>
                                </>
                            )}

                            {activePage === 4 && (
                                <>
                                    <Row className="previewContent-bundleContainer">
                                        <Col lg={10} md={10}>
                                            <div className="previewContent-bundleTitle">
                                                <div
                                                    className="previewContent-bundleTitle-text"
                                                    style={{ backgroundColor: getColor('positive'), color: getColor('fontColors', 'font-white') }}
                                                >
                                                    {t('cabinetSettings:bundleDiscounts')}
                                                </div>
                                                <div className="previewContent-bundleTitle-offer" style={{ backgroundColor: getColor('positive') }}>
                                                    <svg>
                                                        <use xlinkHref={`${icons}#icon-local_offer`} />
                                                    </svg>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col
                                            className="previewContent-bundleItemContainer"
                                            lg={10}
                                            md={10}
                                            style={{
                                                boxShadow: `0 0 20px ${getColor('shadesColors', 'shadow')}`,
                                                borderColor: getColor('shadesColors', 'shade'),
                                                background: getColor('cards'),
                                            }}
                                        >
                                            <div className="previewContent--singleProduct isDiscounted border-0 previewContent-bundleProduct">
                                                <svg>
                                                    <use xlinkHref={`${icons}#icon-cube`} />
                                                </svg>
                                                <div style={{ color: getColor('negative') }} className="previewContent--singleProduct-beforeDiscountPrice ">
                                                    <span className="currency">€</span>
                                                    <span className="value">5</span>
                                                    <hr
                                                        style={{ borderColor: getColor('negative') }}
                                                        className="previewContent-strikeThrough previewcontent-bundleStrikethrough"
                                                    />
                                                    <span className="currencyDecimal">00</span>
                                                </div>
                                                <div style={{ color: getColor('fontColors', 'font-default') }}>{t('cabinetSettings:productName')}</div>
                                            </div>

                                            <div
                                                className="previewContent--singleProduct-joiner"
                                                style={{ color: getColor('fontColors', 'font-light'), borderColor: getColor('fontColors', 'font-default') }}
                                            >
                                                +
                                            </div>
                                            <div className="previewContent--singleProduct isDiscounted border-0 previewContent-bundleProduct">
                                                <svg>
                                                    <use xlinkHref={`${icons}#icon-cube`} />
                                                </svg>
                                                <div style={{ color: getColor('negative') }} className="previewContent--singleProduct-beforeDiscountPrice">
                                                    <span className="currency">€</span>
                                                    <span className="value">5</span>
                                                    <hr
                                                        style={{ borderColor: getColor('negative') }}
                                                        className="previewContent-strikeThrough previewcontent-bundleStrikethrough"
                                                    />
                                                    <span className="currencyDecimal">00</span>
                                                </div>
                                                <div style={{ color: getColor('fontColors', 'font-default') }}>{t('cabinetSettings:productName')}</div>
                                            </div>
                                            <div
                                                className="previewContent--bundleDiscountBubble"
                                                style={{
                                                    color: getColor('fontColors', 'font-white'),
                                                    backgroundColor: getColor('positive'),
                                                    boxShadow: `0 0 20px ${getColor('shadesColors', 'shadow')}`,
                                                }}
                                            >
                                                -20%
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row
                                        className="previewFooter"
                                        style={{ boxShadow: `0 0 15px ${getColor('shadesColors', 'shadow')}`, backgroundColor: getColor('footer') }}
                                    >
                                        <Col
                                            style={{ borderColor: getColor('shadesColors', 'shade'), color: getColor('fontColors', 'font-light') }}
                                            lg={2}
                                            md={2}
                                            className="footer-left"
                                        >
                                            {t('cabinetSettings:cabinetName')}
                                        </Col>
                                        <Col className="footer-mid" style={{ borderColor: getColor('shadesColors', 'shade') }}>
                                            <div className="footer-mid-content">
                                                <div
                                                    className="footer-mid-info"
                                                    style={{ backgroundColor: getColor('primary'), color: getColor('fontColors', 'font-white') }}
                                                >
                                                    {t('cabinetSettings:footerTextPlaceholder')}
                                                </div>
                                            </div>
                                        </Col>
                                        <Col lg={2} md={2} style={{ borderColor: getColor('shadesColors', 'shade') }} className="footer-right"></Col>
                                    </Row>
                                </>
                            )}
                            {activePage === 5 && (
                                <div className="post-purchase-view" style={getThemingBackgroundStyle()}>
                                    <div className="post-purchase-screen-text">
                                        {' '}
                                        <strong>{postPurchaseText}</strong>
                                    </div>
                                    {postPurchaseScreenOption === 'default' && (
                                        <Fragment>
                                            <div className="price-bubble" style={{ backgroundColor: getColor('positive') }}>
                                                <div className="price-currency">€</div>
                                                <div className="price-amount">5</div>
                                            </div>
                                            <h3 style={{ color: getColor('fontColors', 'font-dark') }}>{t('cabinetSettings:transactionComplete')}</h3>
                                        </Fragment>
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                </ModalBody>
            </ReactstrapModal>
        );
    };

    return <>{ThemingModal()}</>;
}
