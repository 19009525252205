import {
    REQUEST_DEVICE_STATUS,
    RECEIVE_CABINET_STATUS,
    RECEIVE_CABINET_STATUS_ERROR,
    RECEIVE_ES_DEVICE_STATUS,
    SEND_LOCK_DOOR_COMMAND,
    SEND_UNLOCK_DOOR_COMMAND,
    RECEIVE_LOCK_DOOR_COMMAND_STATUS,
    RECEIVE_UNLOCK_DOOR_COMMAND_STATUS,
    CLEAR_LOCK_DOOR_COMMAND_STATUS,
    CLEAR_UNLOCK_DOOR_COMMAND_STATUS,
    SELECTED_CABINET_SETTINGS_LOAD_FAILED,
    SELECTED_CABINET_SETTINGS_LOADED,
    SELECTED_CABINET_FETCHING_CABINET_SETTINGS,
    TAG_QUALITY_METRICS_UPDATED,
    REQUEST_UPLOAD_LOGO_IMAGE,
    UPLOAD_LOGO_IMAGE_SUCCESS,
    UPLOAD_LOGO_IMAGE_ERROR,
    REMOVE_LOGO_IMAGE,
    REQUEST_DEVICE_SOFTWARE_VERSION,
    REQUEST_DEVICE_SOFTWARE_VERSION_SUCCESS,
    REQUEST_DEVICE_SOFTWARE_VERSION_FAILED,
    UPDATE_DEVICE_SOFTWARE_VERSION,
    UPDATE_DEVICE_SOFTWARE_VERSION_SUCCESS,
    UPDATE_DEVICE_SOFTWARE_VERSION_FAILED,
    SEND_DEVICE_REBOOT_COMMAND,
    RECEIVE_DEVICE_REBOOT_COMMAND_STATUS,
    CLEAR_DEVICE_REBOOT_COMMAND_STATUS,
    SEND_MAINTENANCE_START_COMMAND,
    SEND_MAINTENANCE_END_COMMAND,
    RECEIVE_MAINTENANCE_START_COMMAND_STATUS,
    RECEIVE_MAINTENANCE_END_COMMAND_STATUS,
    CLEAR_START_MAINTENANCE_COMMAND_STATUS,
    CLEAR_END_MAINTENANCE_COMMAND_STATUS,
    SEND_UPDATE_CABINET_SETTINGS_MANUALLY_COMMAND,
    RECEIVE_UPDATE_CABINET_SETTINGS_MANUALLY_COMMAND,
    RECEIVE_REFILL_RULE_CREATED,
    RECEIVE_REFILL_RULE_CREATED_ERROR,
    REQUEST_REFILL_CREATE_RULE,
    RECEIVE_REFILL_RULE_UPDATED,
    REQUEST_REFILL_DELETE_RULE,
    RECEIVE_REFILL_RULE_DELETED,
    RESET_REFILL_RULES,
    REQUEST_DEVICE_REFILL_RULE,
    RECEIVE_DEVICE_REFILL_RULE,
    ERROR_DEVICE_REFILL_RULE,
    REQUEST_REFILL_UPDATE_RULE,
    RECEIVE_REFILL_RULE_UPDATED_ERROR,
    REQUEST_PLANOGRAM_IMAGE_UPLOADING,
    REQUEST_PLANOGRAM_IMAGE_UPLOADED,
    REQUEST_PLANOGRAM_IMAGE_UPLOAD_ERROR,
    RESET_PLANOGRAM_IMAGE
} from '../actions/controlPanel.js';

const defaultState = {
    selectedCabinet: {
        fetched: false,
        isFetching: false,
        isError: false,
        cabinet: {},
        settings: {},
    },
    selectedEncodingDevice: {
        fetched: false,
        isFetching: false,
        isError: false,
        encodingDevice: {},
        settings: {},
    },
    softwareVersions: {
        currentDevice: null,
        allVersions: [],
        fetched: false,
        isFetching: false,
        isError: false,
    },
    refillRules: {
        refillRules: null,
        fetched: false,
        isFetching: false,
        isError: false,
    },
    cabinetRefillRule: {
        fetching: false,
        isError: false,
        fetched: false,
        saving: false,
        isSaved: false,
        deleting: false,
        isDeleted: false,
        rule: {},
    },
    cabinetPlanogramImage:{
        uploading:false,
        uploaded:false,
        imageUrl:null,
        error:null
    },
    lockDoorCommand: { inProgress: false, status: null },
    unlockDoorCommand: { inProgress: false, status: null },
    rebootDeviceCommand: { inProgress: false, status: null },
    maintenanceStartCommand: { inProgress: false, status: null },
    maintenanceEndCommand: { inProgress: false, status: null },
    updateCabinetInfoCommand: { inProgress: false, status: null },
};

export default function inventory(state = defaultState, action) {
    switch (action.type) {
        case REQUEST_DEVICE_STATUS:
            return {
                ...state,
                selectedCabinet: {
                    isFetching: true,
                    isError: false,
                    fetched: false,
                    cabinet: null,
                },
                selectedEncodingDevice: {
                    isFetching: true,
                    isError: false,
                    fetched: false,
                    encodingDevice: null,
                },
            };
        case RECEIVE_CABINET_STATUS:
            return {
                ...state,
                selectedEncodingDevice: {
                    ...state.selectedEncodingDevice,
                    isFetching: false,
                },
                selectedCabinet: {
                    ...state.selectedCabinet,
                    isFetching: false,
                    isError: false,
                    fetched: true,
                    cabinet: action.cabinet,
                    lastUpdated: action.receivedAt,
                },
            };
        case RECEIVE_ES_DEVICE_STATUS:
            return {
                ...state,
                selectedCabinet: {
                    ...state.selectedCabinet,
                    isFetching: false,
                },
                selectedEncodingDevice: {
                    ...state.selectedCabinet,
                    isFetching: false,
                    isError: false,
                    fetched: true,
                    encodingDevice: action.encodingDevice,
                    lastUpdated: action.receivedAt,
                },
            };
        case RECEIVE_CABINET_STATUS_ERROR:
            return {
                ...state,
                selectedCabinet: {
                    ...state.selectedCabinet,
                    isFetching: false,
                    isError: true,
                    fetched: true,
                    error: action.error.toString(),
                },
            };
        case SEND_LOCK_DOOR_COMMAND:
            return {
                ...state,
                lockDoorCommand: {
                    inProgress: true,
                    status: null,
                },
            };
        case SEND_UNLOCK_DOOR_COMMAND:
            return {
                ...state,
                unlockDoorCommand: {
                    inProgress: true,
                    status: null,
                },
            };
        case SEND_DEVICE_REBOOT_COMMAND:
            return {
                ...state,
                rebootDeviceCommand: {
                    inProgress: true,
                    status: null,
                },
            };
        case SEND_MAINTENANCE_START_COMMAND:
            return {
                ...state,
                maintenanceStartCommand: {
                    inProgress: true,
                    status: null,
                },
            };
        case SEND_MAINTENANCE_END_COMMAND:
            return {
                ...state,
                maintenanceEndCommand: {
                    inProgress: true,
                    status: null,
                },
            };
        case RECEIVE_MAINTENANCE_START_COMMAND_STATUS:
            return {
                ...state,
                maintenanceStartCommand: {
                    inProgress: false,
                    status: action.status,
                },
            };
        case RECEIVE_MAINTENANCE_END_COMMAND_STATUS:
            return {
                ...state,
                maintenanceEndCommand: {
                    inProgress: false,
                    status: action.status,
                },
            };
        case CLEAR_END_MAINTENANCE_COMMAND_STATUS:
            return {
                ...state,
                maintenanceEndCommand: {
                    inProgress: false,
                    status: null,
                },
            };
        case CLEAR_START_MAINTENANCE_COMMAND_STATUS:
            return {
                ...state,
                maintenanceStartCommand: {
                    inProgress: false,
                    status: null,
                },
            };
        case RECEIVE_LOCK_DOOR_COMMAND_STATUS:
            return {
                ...state,
                lockDoorCommand: {
                    inProgress: false,
                    status: action.status,
                },
            };
        case RECEIVE_UNLOCK_DOOR_COMMAND_STATUS:
            return {
                ...state,
                unlockDoorCommand: {
                    inProgress: false,
                    status: action.status,
                },
            };
        case RECEIVE_DEVICE_REBOOT_COMMAND_STATUS:
            return {
                ...state,
                rebootDeviceCommand: {
                    inProgress: false,
                    status: 201,
                },
            };
        case CLEAR_LOCK_DOOR_COMMAND_STATUS:
            return {
                ...state,
                lockDoorCommand: {
                    inProgress: false,
                    status: null,
                },
            };
        case CLEAR_UNLOCK_DOOR_COMMAND_STATUS:
            return {
                ...state,
                unlockDoorCommand: {
                    inProgress: false,
                    status: null,
                },
            };
        case CLEAR_DEVICE_REBOOT_COMMAND_STATUS:
            return {
                ...state,
                rebootDeviceCommand: {
                    inProgress: false,
                    status: null,
                },
            };
        case SELECTED_CABINET_SETTINGS_LOADED: {
            const processedSettings = {};
            const settings = action.settings || {};
            Object.keys(action.settings).forEach(key => {
                processedSettings[key] =
                    ['true', 'false'].indexOf(settings[key]) >= 0
                        ? // eslint-disable-next-line
                          settings[key] == 'true'
                        : settings[key];
            });
            return {
                ...state,
                selectedCabinet: {
                    ...state.selectedCabinet,
                    settings: processedSettings,
                    fetchingSettings: false,
                },
            };
        }
        case SELECTED_CABINET_SETTINGS_LOAD_FAILED:
            return {
                ...state,
                selectedCabinet: {
                    ...state.selectedCabinet,
                    settings: {},
                    fetchingSettings: false,
                    settingsError: true,
                },
            };
        case SELECTED_CABINET_FETCHING_CABINET_SETTINGS:
            return {
                ...state,
                selectedCabinet: {
                    ...state.selectedCabinet,
                    fetchingSettings: true,
                },
            };
        case TAG_QUALITY_METRICS_UPDATED:
            return {
                ...state,
                selectedCabinet: {
                    ...state.selectedCabinet,
                    settings: {
                        ...state.selectedCabinet.settings,
                        detectTagQuality: action.data.detectTagQuality,
                        ignoreBadTags: action.data.ignoreBadTags,
                        acceptablePercentageOfBadTags: action.data.acceptablePercentageOfBadTags,
                        chargeSoldTags: action.data.chargeSoldTags,
                        chargeUnreplenishedTags: action.data.chargeUnreplenishedTags,
                        markAllTagsUnsold: action.data.markAllTagsUnsold,
                        cabinetTagSensitivityLevel:action.data.cabinetTagSensitivityLevel
                    },
                },
            };
        case REQUEST_UPLOAD_LOGO_IMAGE:
            return {
                ...state,
                selectedCabinet: {
                    ...state.selectedCabinet,
                    imageStoring: true,
                    imageUrl: '',
                    imageStoringError: null,
                },
            };
        case UPLOAD_LOGO_IMAGE_SUCCESS:
            return {
                ...state,
                selectedCabinet: {
                    ...state.selectedCabinet,
                    imageStoring: false,
                    imageUrl: action.url,
                    imageStoringError: null,
                },
            };
        case UPLOAD_LOGO_IMAGE_ERROR:
            console.error(action.error);
            return {
                ...state,
                selectedCabinet: {
                    ...state.selectedCabinet,
                    imageStoring: false,
                    imageUrl: '',
                    imageStoringError: action.error.toString(),
                },
            };
        case REMOVE_LOGO_IMAGE:
            return {
                ...state,
                selectedCabinet: {
                    ...state.selectedCabinet,
                    imageStoring: false,
                    imageUrl: '',
                },
            };
        case REQUEST_DEVICE_SOFTWARE_VERSION:
            return {
                ...state,
                softwareVersions: {
                    ...state.softwareVersions,
                    isFetching: true,
                    fetched: false,
                    current: '',
                    allVersions: [],
                },
            };
        case REQUEST_DEVICE_SOFTWARE_VERSION_SUCCESS:
            return {
                ...state,
                softwareVersions: {
                    isFetching: false,
                    fetched: true,
                    current: action.currentDevice.release,
                    allVersions: action.allVersions,
                    deviceType: action.deviceType,
                },
            };
        case REQUEST_DEVICE_SOFTWARE_VERSION_FAILED:
            return {
                ...state,
                softwareVersions: {
                    isFetching: false,
                    fetched: true,
                    isError: true,
                    current: '',
                    allVersions: [],
                },
            };
        case UPDATE_DEVICE_SOFTWARE_VERSION:
            return {
                ...state,
                softwareVersions: {
                    ...state.softwareVersions,
                    isFetching: true,
                    fetched: false,
                    isError: false,
                },
            };

        case UPDATE_DEVICE_SOFTWARE_VERSION_SUCCESS:
            return {
                ...state,
                softwareVersions: {
                    ...state.softwareVersions,
                    isFetching: false,
                    fetched: true,
                    isError: false,
                    current: action.currentDevice.tags.release || null,
                },
            };
        case UPDATE_DEVICE_SOFTWARE_VERSION_FAILED:
            return {
                ...state,
                softwareVersions: {
                    ...state.softwareVersions,
                    isFetching: false,
                    fetched: true,
                    isError: true,
                },
            };
        case SEND_UPDATE_CABINET_SETTINGS_MANUALLY_COMMAND:
            return {
                ...state,
                updateCabinetInfoCommand: {
                    inProgress: true,
                    status: null,
                },
            };
        case RECEIVE_UPDATE_CABINET_SETTINGS_MANUALLY_COMMAND:
            return {
                ...state,
                updateCabinetInfoCommand: {
                    inProgress: false,
                    status: action.status,
                },
            };

        case REQUEST_REFILL_CREATE_RULE: {
            return {
                ...state,
                cabinetRefillRule: {
                    fetching: false,
                    error: null,
                    saving: true,
                    isSaved: false,
                    isDeleted: false,
                    deleting: false,
                    rule: { ...state.cabinetRefillRule?.rule },
                },
            };
        }
        case RECEIVE_REFILL_RULE_CREATED: {
            return {
                ...state,
                cabinetRefillRule: {
                    fetching: false,
                    fetched: false,
                    error: null,
                    saving: false,
                    isSaved: true,
                    isDeleted: false,
                    deleting: false,
                    rule: { ...action.refillRule.data },
                },
            };
        }
        case REQUEST_REFILL_UPDATE_RULE: {
            return {
                ...state,
                cabinetRefillRule: {
                    ...state.cabinetRefillRule,
                    saving: true,
                    isSaved: false,
                    isDeleted: false,
                    deleting: false,
                    rule: {
                        ...state.cabinetRefillRule.rule,
                    },
                },
            };
        }
        case RECEIVE_REFILL_RULE_UPDATED: {
            return {
                ...state,
                cabinetRefillRule: {
                    fetching: false,
                    fetched: false,
                    error: null,
                    saving: false,
                    isSaved: true,
                    deleting: false,
                    isDeleted: false,
                    rule: {
                        id: state.cabinetRefillRule.rule.id,
                        productData: action.refillRule,
                        planogram:action.planogram,
                        planogramImageUrl:action.planogramImageUrl
                    },
                },

            };
        }
        case RECEIVE_REFILL_RULE_CREATED_ERROR: {
            return {
                ...state,
                cabinetRefillRule: {
                    fetched: false,
                    fetching: false,
                    saving: false,
                    isSaved: false,
                    deleting: false,
                    isDeleted: false,
                    error: action.error.toString(),
                    rule: {
                        ...state.cabinetRefillRule.rule,
                    },
                },
            };
        }
        case REQUEST_REFILL_DELETE_RULE: {
            return {
                ...state,
                cabinetRefillRule: {
                    fetching: false,
                    fetched: false,
                    error: null,
                    saving: false,
                    isSaved: false,
                    deleting: true,
                    isDeleted: false,
                },
            };
        }
        case RECEIVE_REFILL_RULE_DELETED: {
            return {
                ...state,
                cabinetRefillRule: {
                    fetching: false,
                    fetched: false,
                    error: null,
                    saving: false,
                    isSaved: false,
                    deleting: false,
                    isDeleted: true,
                    rule: {},
                },
            };
        }
        case RESET_REFILL_RULES: {
            return {
                ...state,
                selectedCabinet: {
                    ...state.selectedCabinet,
                },
            };
        }
        case REQUEST_DEVICE_REFILL_RULE: {
            return {
                ...state,
                cabinetRefillRule: {
                    fetching: true,
                    fetched: false,
                    error: null,
                    saving: false,
                    isSaved: false,
                    deleting: false,
                    isDeleted: false,
                    rule: {},
                },
            };
        }
        case RECEIVE_DEVICE_REFILL_RULE: {
            return {
                ...state,
                cabinetRefillRule: {
                    fetching: false,
                    fetched: true,
                    error: null,
                    saving: false,
                    isSaved: false,
                    deleting: false,
                    isDeleted: false,
                    rule: action.refillRule && Array.isArray(action.refillRule) && action.refillRule.length === 0 ? {} : action.refillRule,
                },
            };
        }

        case ERROR_DEVICE_REFILL_RULE:
        case RECEIVE_REFILL_RULE_UPDATED_ERROR: {
            return {
                ...state,
                cabinetRefillRule: {
                    fetching: false,
                    fetched: false,
                    saving: false,
                    isSaved: false,
                    deleting: false,
                    isDeleted: false,
                    error: action.error.toString(),
                    rule: {
                        ...state.cabinetRefillRule.rule,
                    },
                },
            };
        }

        case REQUEST_PLANOGRAM_IMAGE_UPLOADING: {
            return {
                ...state,
                cabinetPlanogramImage:{
                    uploading:true,
                    uploaded:false,
                    imageUrl:null,
                    error:false
                }
            }
        }

        case REQUEST_PLANOGRAM_IMAGE_UPLOADED: {
            return {
                ...state,
                cabinetPlanogramImage:{
                    uploading:false,
                    uploaded:true,
                    imageUrl:action.imageUrl,
                    error:false
                }
            }
        }

        case REQUEST_PLANOGRAM_IMAGE_UPLOAD_ERROR:{
            return {
                ...state,
                cabinetPlanogramImage:{
                    uploading:false,
                    uploaded:false,
                    imageUrl:null,
                    error:action.error
                }
            }
        }
        case RESET_PLANOGRAM_IMAGE:{
            return{
                ...state,
                cabinetPlanogramImage:{
                    uploading:false,
                    uploaded:false,
                    imageUrl:null,
                    error:null
                }
            }
        }

        default:
            return state;
    }
}
