import React from 'react';
import { Modal, ModalBody, ModalFooter } from 'reactstrap';

import icons from './../../../icons/icomoon.svg';

const MaxInventoryWarnModal = ({ isOpen, cancel,t }) => {
    return (
        <Modal isOpen={isOpen} centered={true} size="lg">
            <ModalBody className="refillschema__modal refillschema__modal-request">
                <div className="refillschema__modal-info refillschema__modal-info--warn">
                    <svg>
                        <use xlinkHref={`${icons}#icon-error_outline`}></use>
                    </svg>
                    <div>{t('maxAllowedInventory')} </div>
                </div>
            </ModalBody>
            <ModalFooter>
                <div className="refillschema__modal-btn" onClick={() => cancel()}>
                    {t('main:close')}
                </div>
            </ModalFooter>
        </Modal>
    );
};

export default MaxInventoryWarnModal;
