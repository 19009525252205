import { Button, Checkbox, Icon } from '@storaensods/seeds-react';
import React, { useState, Fragment } from 'react';
import { Col, Modal, ModalBody, Row, Table } from 'reactstrap';

export function SettingsCopyModal(props) {
    const { t, settingsType, settings, cabinets, locations, closeModal, submitSettingsCopy } = props;
    const exclusivePages = ['refillRuleTitle', 'settings:sleepScheduling'];

    // Format the settings into key and value pairs for settings that are not the refill rule and set the setting type
    // The settingType must be 'refillRule' for the refill rule setting and can be null for all other settings
    let settingsArray = null;
    let settingType = null;
    if (settingsType === 'refillRuleTitle') {
        settingsArray = settings;
        settingType = 'refillRule';
    } else {
        // Make array to set all settings as selected by default
        settingsArray = Object.entries(settings).map(([key, value]) => (value === undefined ? { key, value: '' } : { key, value: String(value) }));
    }

    const [activeLocationsAccordions, setActiveLocationsAccordions] = useState([]);
    const [selectedSettings, setSelectedSettings] = useState(settingsArray);
    const [selectedCabinets, setSelectedCabinets] = useState([]);

    const openAccordion = locationId => {
        if (activeLocationsAccordions.includes(locationId)) setActiveLocationsAccordions([...activeLocationsAccordions.filter(a => a !== locationId)]);
        else setActiveLocationsAccordions([...activeLocationsAccordions, locationId]);
    };

    const selectCabinetForCopy = (cabinet, checked, all = false, loc = null) => {
        if (checked) {
            let array = [...selectedCabinets];
            if (all) {
                array = [
                    ...array,
                    ...cabinets.cabinets
                        .filter(c => c.locationId === loc.id)
                        .map(c => {
                            return { id: c.id, deviceCode: c.deviceCode, locationId: c.locationId };
                        }),
                ];
            } else {
                array = [...array, { id: cabinet.id, deviceCode: cabinet.deviceCode, locationId: cabinet.locationId }];
            }
            setSelectedCabinets(array);
        } else {
            let array = [...selectedCabinets];
            if (all) {
                array = [...array.filter(cabinet => cabinet.locationId !== loc.id)];
            } else {
                array = [...selectedCabinets.filter(s => s.id !== cabinet.id)];
            }

            setSelectedCabinets(array);
        }
    };

    const selectSettingForCopy = (settingObj, checked, all = false) => {
        if (checked) {
            let array = [...selectedSettings];
            if (all) {
                array = [
                    ...Object.keys(settings).map(key => {
                        if (settings[key] === undefined) {
                            return { key, value: '' };
                        } else {
                            return { key, value: settings[key]?.toString() };
                        }
                    }),
                ];
            } else {
                array = [...array, settingObj];
            }
            setSelectedSettings(array);
        } else {
            let array = [...selectedSettings];
            if (all) {
                array = [];
            } else {
                array = [...selectedSettings.filter(s => s.key !== settingObj.key)];
            }

            setSelectedSettings(array);
        }
    };

    return (
        <Modal isOpen autoFocus className="settingsCopyModal">
            <ModalBody className="p-4">
                <Row className="settingsCopyModalMainContainer">
                    {!exclusivePages.includes(settingsType) ? (
                        <Col lg="4" className="settingsCopySectionContainer">
                            <Row>
                                <div className="infoTextContainer">
                                    {settingsType === 'refillSchema' ? t('navigation:refillManagement') : t(`${settingsType}`)}
                                </div>
                            </Row>
                            <Row className="p-2">
                                <div className="settingsCopyTableContainer">
                                    <Table className="settingCopyTable p-2">
                                        <thead>
                                            <tr>
                                                {settingsType !== 'refillSchema' && (
                                                    <Fragment>
                                                        <th>
                                                            <Checkbox
                                                                checked={Object.keys(settings).every(s => selectedSettings.some(setting => setting.key === s))}
                                                                onChange={event => selectSettingForCopy({}, event.target.checked, true)}
                                                                id={`setting_copy_all_checkbox`}
                                                            />
                                                        </th>
                                                        <th>{t('settings:key')}</th>
                                                        <th>{t('settings:value')}</th>
                                                    </Fragment>
                                                )}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {Object.keys(settings).map((setting, i) => {
                                                const isUrl = urlString => {
                                                    try {
                                                        return Boolean(new URL(urlString));
                                                    } catch (e) {
                                                        return false;
                                                    }
                                                };

                                                const generateThemeLogosTableTd = setting => {
                                                    switch (setting) {
                                                        case 'cabinetUiTheme':
                                                            let theme = settings?.cabinetUiTheme ? JSON.parse(settings.cabinetUiTheme).theme : 'default';
                                                            return (
                                                                <>
                                                                    <td>{t('settings:theming')}</td>
                                                                    <td>{theme}</td>
                                                                </>
                                                            );
                                                        case 'logo':
                                                        case 'payment_method_logo':
                                                        case 'footer_instruction_logo':
                                                            if (isUrl(settings[setting])) {
                                                                return (
                                                                    <>
                                                                        <td>{t(`settings:${setting}`)}</td>
                                                                        <td>
                                                                            <img src={settings[setting]} alt="" />
                                                                        </td>
                                                                    </>
                                                                );
                                                            } else {
                                                                return (
                                                                    <>
                                                                        <td>{t(`settings:${setting}`)}</td>
                                                                        <td>{settings[setting]}</td>
                                                                    </>
                                                                );
                                                            }

                                                        default:
                                                            return (
                                                                <>
                                                                    <td>{t(`settings:${setting}`)}</td>
                                                                    <td>{settings[setting]}</td>
                                                                </>
                                                            );
                                                    }
                                                };

                                                const displayBooleanValue = setting => {
                                                    if (setting === 'false') return t('settings:no');
                                                    else if (setting === 'true') return t('settings:yes');
                                                };

                                                const getTranslation = (setting, settingType) => {
                                                    if (setting === 'cabinetTagSensitivityLevel') {
                                                        return t('settings:tagSensitivity');
                                                    } else if (settingType === 'main:controls_tagQuality') {
                                                        if (setting === 'ignoreBadTags') return t('controls_chargeBadTags');
                                                        else return t(`controls_${setting}`);
                                                    } else {
                                                        return t(`settings:${setting}`);
                                                    }
                                                };

                                                if (settingsType === 'settings:themeAndLogos') {
                                                    return (
                                                        <tr key={`setting_${setting}_${i}_text`}>
                                                            <td>
                                                                <Checkbox
                                                                    onChange={event =>
                                                                        selectSettingForCopy({ key: setting, value: settings[setting] }, event.target.checked)
                                                                    }
                                                                    checked={selectedSettings.some(s => s.key === setting)}
                                                                    id={`setting_${setting}_${i}_checkbox`}
                                                                />
                                                            </td>
                                                            {generateThemeLogosTableTd(setting)}
                                                        </tr>
                                                    );
                                                } else if (settingsType === 'refillSchema') {
                                                    return (
                                                        <Fragment>
                                                            <tr key={`setting_${setting}_${i}_text`} style={{ borderBottom: '1px solid #cccccc' }}>
                                                                <td style={{ display: 'flex', opacity: '.6' }}>
                                                                    <Checkbox checked={true} disabled />
                                                                    <p>{t('refill:refillSchemas')}</p>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td style={{ display: 'flex' }}>
                                                                    <Checkbox
                                                                        onChange={event =>
                                                                            selectSettingForCopy(
                                                                                { key: setting, value: settings[setting] },
                                                                                event.target.checked
                                                                            )
                                                                        }
                                                                        checked={selectedSettings.some(s => s.key === setting)}
                                                                        id={`setting_${setting}_${i}_checkbox`}
                                                                    />
                                                                    <p>{t('refill:copyPlanogram')}</p>
                                                                </td>
                                                            </tr>
                                                        </Fragment>
                                                    );
                                                } else {
                                                    return (
                                                        <tr key={`setting_${setting}_${i}_text`}>
                                                            <td>
                                                                <Checkbox
                                                                    onChange={event =>
                                                                        selectSettingForCopy({ key: setting, value: settings[setting] }, event.target.checked)
                                                                    }
                                                                    checked={selectedSettings.some(s => s.key === setting)}
                                                                    id={`setting_${setting}_${i}_checkbox`}
                                                                />
                                                            </td>
                                                            <td>{getTranslation(setting, settingsType)}</td>
                                                            <td>
                                                                {(() => {
                                                                    if (setting === 'cabinetTagSensitivityLevel') {
                                                                        return settings[setting] / 20;
                                                                    } else {
                                                                        return typeof settings[setting] === 'boolean'
                                                                            ? displayBooleanValue(JSON.stringify(settings[setting]))
                                                                            : settings[setting];
                                                                    }
                                                                })()}
                                                            </td>
                                                        </tr>
                                                    );
                                                }
                                            })}
                                        </tbody>
                                    </Table>
                                </div>
                            </Row>
                        </Col>
                    ) : null}
                    <Col lg="7" className="settingsCopySectionContainer customWidthSettingsCopyContainer">
                        <Row>
                            <div className="infoTextContainer">{t('settings:settingsCopyInfoHeader')}</div>
                        </Row>
                        <div className="p-3 mt-3 settingsCopyTableContainer">
                            {[{ name: t('main:no region'), id: null }, ...locations]
                                .filter(loc => cabinets.cabinets.some(c => c.locationId === loc.id))
                                .map(loc => {
                                    const devicesInLocation = locationId => cabinets.cabinets.filter(c => c.locationId === locationId);
                                    return (
                                        <Fragment key={loc.id}>
                                            <Row>
                                                <div
                                                    onClick={() => openAccordion(loc.id)}
                                                    className={`p-2 cabinetSelect settingsCopyModalLocationRow ${
                                                        activeLocationsAccordions.includes(loc.id) ? 'bg-light' : ''
                                                    }`}
                                                >
                                                    <span className="mx-2">
                                                        {activeLocationsAccordions.includes(loc.id) ? (
                                                            <i className="fas fa-caret-down" />
                                                        ) : (
                                                            <i className="fas fa-caret-right" />
                                                        )}{' '}
                                                    </span>
                                                    <span className="mx-2">
                                                        <Icon>location_city</Icon>
                                                    </span>
                                                    {loc.name}
                                                </div>
                                            </Row>
                                            {activeLocationsAccordions.includes(loc.id) && (
                                                <Row>
                                                    <div>
                                                        <span className="pl-3">
                                                            <Checkbox
                                                                id={`${loc.id}_copy_checkbox`}
                                                                onChange={e => {
                                                                    selectCabinetForCopy({}, e.target.checked, true, loc);
                                                                }}
                                                                checked={devicesInLocation(loc.id).every(d => selectedCabinets.some(s => s.id === d.id))}
                                                            />
                                                            {t('selectAllCabinets')}
                                                        </span>

                                                        {cabinets.cabinets
                                                            .filter(c => c.locationId === loc.id)
                                                            .map(c => {
                                                                return (
                                                                    <div key={`${c.id}_${c.name}`} className="pt-1 pl-5">
                                                                        <span className="mr-2">
                                                                            <Checkbox
                                                                                id={`${c.name}_${c.id}_copy_checkbox`}
                                                                                onChange={e => selectCabinetForCopy(c, e.target.checked)}
                                                                                checked={selectedCabinets.some(sc => sc.id === c.id)}
                                                                            />
                                                                        </span>
                                                                        <span>{c.name}</span>
                                                                    </div>
                                                                );
                                                            })}
                                                    </div>
                                                </Row>
                                            )}
                                        </Fragment>
                                    );
                                })}
                        </div>
                    </Col>
                </Row>
                <Row className="justify-content-end">
                    <Button className="mr-2" type="secondary" onClick={() => closeModal()}>
                        {t('close')}
                    </Button>
                    <Button
                        disabled={selectedSettings.length === 0 || selectedCabinets.length === 0}
                        onClick={() => {
                            submitSettingsCopy(selectedCabinets, settingType, selectedSettings);
                            closeModal();
                        }}
                        type="primary"
                    >
                        <i className="fas fa-copy mr-2" />
                        <span>{t('settings:settingsCopyButton')}</span>
                    </Button>
                </Row>
            </ModalBody>
        </Modal>
    );
}
