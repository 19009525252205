import React, { useEffect, useState, Fragment } from 'react';
import { FormGroup, Label } from 'reactstrap';
import { Select } from '@storaensods/seeds-react';

import { classifications } from './../config';
import ProductTabs from './../segments/productTabs';
import icons from './../icons.svg';

const Classification = ({ productLabels, productDataChange, tabNavigation, submitProductToApi, selectedProduct, activeTab, t, isUpdating, validationErrors}) => {
    const [productClassification, setProductClassification] = useState({ topClass: null, subClass: null, option: null });
    const [newLabel, setNewLabel] = useState({});
    const [labelsData, setLabelsData] = useState(selectedProduct.labels);
    const [errors, setErrors] = useState(validationErrors);

    useEffect(() => {
        const selectedProductClass = selectedProduct.productClass;
        // if product class is not set, make [null,null,null] as the default selection
        // A validation will catch the null data and throws error on submission
        if (!selectedProductClass || !Array.isArray(selectedProductClass) || selectedProductClass.length === 0) {
            setProductClassification({ topClass: null, subClass: null, option: null });
        } else {
            setProductClassification({ topClass: selectedProductClass[0], subClass: selectedProductClass[1], option: selectedProductClass[2] });
        }
    }, [selectedProduct.productClass]);

    const togglePrdocutClassification = layers => {
        setProductClassification({
            topClass: Number(layers[0]),
            subClass: Number(layers[1]),
            option: Number(layers[2]),
        });
        setErrors({})
    };

    const submitProduct = () => {
        const productClass = [productClassification.topClass, productClassification.subClass, productClassification.option];
        productDataChange({ productClass, labels: labelsData.filter(label => label) });
        submitProductToApi(true);
    };

    const navigateToNextTab = (index, tabChange) => {
        const productClass = [productClassification.topClass, productClassification.subClass, productClassification.option];
        productDataChange({ productClass, labels: labelsData });
        tabNavigation(index, tabChange);
    };

    /**
     *  if there is any validation errors, show the errors
     * @returns DOM to visualize warnings
     */
    const getValidationWarningView = () => {
        const { productClass } = errors;
        if (productClass) {
            return (
                <div className="col products__errors">
                    <i className="fas fa-exclamation-circle"></i> <span>{t('productClassValidationError')} </span>
                </div>
            );
        }
    };

    return (
        <Fragment>
            <ProductTabs tabChange={navigateToNextTab} activeTab={activeTab} t={t} />
            <div className="row">
                {/**left side column */}
                <div className="col-md-6 products__layout-left">
                    <div className="col-12 products__group">
                        {getValidationWarningView()}
                        <h3 className="products__group-title">{t('productClassification')}</h3>
                        <FormGroup>
                            <Label>{t('productClassLabel')}</Label>
                            <div className="products__categorization">
                                <div className="accordion">
                                    {classifications.map(classification => {
                                        return (
                                            <div className="accordion__item" key={classification.index}>
                                                <label
                                                    htmlFor={classification.index}
                                                    onClick={() => togglePrdocutClassification([classification.index, 0, 0])}
                                                    className={
                                                        productClassification.topClass === classification.index
                                                            ? 'accordion__header accordion__header--active'
                                                            : 'accordion__header'
                                                    }
                                                >
                                                    <div>
                                                        <input
                                                            type="radio"
                                                            name="top-level"
                                                            id={classification.index}
                                                            readOnly
                                                            checked={productClassification.topClass === classification.index}
                                                        />
                                                        <span>{classification.title}</span>
                                                    </div>

                                                    <span className="accordion__icon">
                                                        <svg role="img" aria-label="Tools">
                                                            <use xlinkHref={`${icons}#icon-${classification.icon}`} />
                                                        </svg>
                                                    </span>
                                                </label>

                                                {classification.subClasses &&
                                                    classification.subClasses.map(sub => {
                                                        return (
                                                            <ul
                                                                className={
                                                                    classification.index === productClassification.topClass
                                                                        ? 'accordion__sublevel accordion__sublevel--open'
                                                                        : 'accordion__sublevel'
                                                                }
                                                                key={sub.index}
                                                            >
                                                                <li>
                                                                    <label
                                                                        htmlFor={sub.index}
                                                                        onClick={() => togglePrdocutClassification([classification.index, sub.index, 0])}
                                                                        className={
                                                                            sub.index === productClassification.subClass
                                                                                ? 'accordion__subheader accordion__subheader--active'
                                                                                : 'accordion__subheader'
                                                                        }
                                                                    >
                                                                        <input
                                                                            type="radio"
                                                                            name="sublevel"
                                                                            id={sub.index}
                                                                            readOnly
                                                                            checked={sub.index === productClassification.subClass}
                                                                        />
                                                                        <span>{sub.title}</span>
                                                                    </label>
                                                                    <ul
                                                                        className={
                                                                            sub.index === productClassification.subClass
                                                                                ? 'accordion__options--open'
                                                                                : 'accordion__options'
                                                                        }
                                                                    >
                                                                        {sub.options &&
                                                                            sub.options.map(option => {
                                                                                return (
                                                                                    <li key={option.index}>
                                                                                        <label
                                                                                            htmlFor={option.index}
                                                                                            onClick={() =>
                                                                                                togglePrdocutClassification([
                                                                                                    classification.index,
                                                                                                    sub.index,
                                                                                                    option.index,
                                                                                                ])
                                                                                            }
                                                                                        >
                                                                                            {' '}
                                                                                            <input
                                                                                                type="radio"
                                                                                                name="option"
                                                                                                id={option.index}
                                                                                                readOnly
                                                                                                checked={option.index === productClassification.option}
                                                                                            />
                                                                                            <span>{option.title}</span>
                                                                                        </label>
                                                                                    </li>
                                                                                );
                                                                            })}
                                                                    </ul>
                                                                </li>
                                                            </ul>
                                                        );
                                                    })}
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        </FormGroup>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="col-12 products__group">
                        <h3 className="products__group-title">{t('productLabels')}</h3>
                        <FormGroup>
                            <Label for="labels">{t('productLabelsHelpText')}</Label>
                        </FormGroup>
                        <FormGroup>
                            <Label for="labels"></Label>
                            <Select
                                placeholder={t('selectOrtype')}
                                isMulti
                                onChange={labelArray => setLabelsData(labelArray.map(data => data.value))}
                                onInputChange={e => {
                                    setNewLabel({ label: e, value: e });
                                }}
                                value={labelsData.filter(label => label).map(label => ({ label: label, value: label }))}
                                options={[
                                    ...productLabels.filter(label => label).map(label => ({ label: label, value: label })),
                                    ...(newLabel.value ? [{ label: newLabel.label + ` (${t('newLabel')})`, value: newLabel.label }] : []),
                                ]}
                            />
                        </FormGroup>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <div className="products__cta-buttons">
                        <div className="products__cta-button products__cta-button--neutral" onClick={() => navigateToNextTab(-1)}>
                            ‹‹ {t('previous')}
                        </div>
                        {
                            isUpdating && (
                                <div className="products__cta-button products__cta-button--success" onClick={submitProduct}>
                                    {t('save')}
                                </div>
                            )
                        }                        
                        <div className="products__cta-button products__cta-button--positive" onClick={() => navigateToNextTab(1)}>
                            {t('next')} ››
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default Classification;
