import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { Alert } from 'reactstrap';
import InventoryList from './inventoryList.js';

/**
 * Inventory page component
 */
export class Inventory extends Component {
    render() {
        const { t, cabinet, selectedCabinetDeviceCode } = this.props;

        return (
            <div className="container-fluid">
                {selectedCabinetDeviceCode != null ? (
                    <InventoryList cabinet={cabinet} />
                ) : (
                    <Alert color="light" className="float-left">
                        <div className="h-100 d-flex align-items-center">
                            {t('noCabinetSelected')}
                        </div>
                    </Alert>
                )}
            </div>
        );
    }
}

export default withRouter(
    connect(state => ({
        selectedCabinetDeviceCode: state.cabinets.selectedCabinetDeviceCode,
        cabinet: state.cabinets.cabinets.find(
            cabinet => cabinet.deviceCode === state.cabinets.selectedCabinetDeviceCode
        ),
    }))(translate('main')(Inventory))
);
