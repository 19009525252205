import React, { useEffect, useState } from 'react';

import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { Button } from '../../commons/utils';
import ImageUpload from '../../imageUpload/imageUpload';

const PlanogramImageModal = ({ isOpen, closeModal, t, planogramImageInStore, uploadPlanogramImage, planogramImageData, planogramImageUrl }) => {
    const { uploading } = planogramImageInStore;
    const [toggleCropping, setToggleCropping] = useState(true);
    const [imageData, setImageData] = useState({ imageUrl: null });

    useEffect(() => {
        setImageData({ imageUrl: planogramImageInStore.imageUrl || planogramImageUrl });
    }, [planogramImageInStore.imageUrl, planogramImageUrl]);

    return (
        <Modal isOpen={isOpen} centered={true} size="lg" className="planogram__image-modal">
            <ModalHeader>Refill planogram image</ModalHeader>
            <ModalBody>
                <ImageUpload
                    className="se-input-container"
                    freeAspectRatio={true}
                    targetWidth={1920}
                    targetHeight={1080}
                    imageUrl={imageData.imageUrl}
                    storing={uploading}
                    uploadImage={img => {
                        return uploadPlanogramImage(img);
                    }}
                    removeImage={() => setImageData({ imageUrl: null })}
                    toggleCropping={() => setToggleCropping(!toggleCropping)}
                    t={t}
                />
            </ModalBody>
            <ModalFooter>
                <Button
                    type="close"
                    text={t('main:close')}
                    onClick={() => {
                        planogramImageData(imageData.imageUrl);
                        closeModal();
                    }}
                />
            </ModalFooter>
        </Modal>
    );
};

export default PlanogramImageModal;
