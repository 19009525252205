import {
    createRefillPlan as createRefillPlanHttpRequest,
    updateRefillPlan as updateRefillPlanHttpRequest,
    fetchRefillPlans as fetchRefillPlansHttpRequest,
    deleteRefillPlan as deleteRefillPlanSuggestionsHttpRequest,
    postRefillPlanSuggestions as postRefillPlanSuggestionsHttpRequest,
} from '../api';

export const REQUEST_REFILL_PLANS = 'REQUEST_REFILL_PLANS';
export const RECEIVE_REFILL_PLANS = 'RECEIVE_REFILL_PLANS';
export const RECEIVE_REFILL_PLANS_ERROR = 'RECEIVE_REFILL_PLANS_ERROR';

export const REQUEST_CREATE_REFILL_PLAN = 'REQUEST_CREATE_REFILL_PLAN';
export const RECEIVE_CREATE_REFILL_PLAN = 'RECEIVE_CREATE_REFILL_PLAN';
export const RECEIVE_CREATE_REFILL_PLAN_ERROR = 'RECEIVE_CREATE_REFILL_PLAN_ERROR';

export const REQUEST_CREATE_REFILL_PLAN_SUGGESTIONS = 'REQUEST_CREATE_REFILL_PLAN_SUGGESTIONS';

export const RECEIVE_CREATE_REFILL_PLAN_SUGGESTIONS = 'RECEIVE_CREATE_REFILL_PLAN_SUGGESTIONS';
export const RECEIVE_CREATE_REFILL_PLAN_SUGGESTIONS_ERROR = 'RECEIVE_CREATE_REFILL_PLAN_SUGGESTIONS_ERROR';

export const REQUEST_UPDATE_REFILL_PLAN = 'REQUEST_UPDATE_REFILL_PLAN';
export const RECEIVE_UPDATE_REFILL_PLAN = 'RECEIVE_UPDATE_REFILL_PLAN';
export const RECEIVE_UPDATE_REFILL_PLAN_ERROR = 'RECEIVE_UPDATE_REFILL_PLAN_ERROR';

export const REQUEST_DELETE_REFILL_PLAN = 'REQUEST_DELETE_REFILL_PLAN';
export const RECEIVE_DELETE_REFILL_PLAN = 'RECEIVE_DELETE_REFILL_PLAN';
export const RECEIVE_DELETE_REFILL_PLAN_ERROR = 'RECEIVE_DELETE_REFILL_PLAN_ERROR';

export const DISMISS_REFILL_PLAN_RESULT = 'DISMISS_REFILL_PLAN_RESULT';

export const RESET_REFILL_PLANS = 'RESET_REFILL_PLANS';

/**
 * Request VAT Categories Redux action creator
 */
function requestRefillPlans() {
    return {
        type: REQUEST_REFILL_PLANS,
    };
}

/**
 * Request VAT Categories Redux action creator
 */
function requestCreateRefillPlanSuggestions() {
    return {
        type: REQUEST_CREATE_REFILL_PLAN_SUGGESTIONS,
    };
}

/**
 * Receive VAT categories Redux action creator
 * @param {array} vatCategories Array of VAT categories received from root api
 */
function receiveRefillPlanSuggestions(deviceCount, totalLength, refillPlanSuggestions) {
    refillPlanSuggestions = refillPlanSuggestions.filter(s => s.productData);

    return {
        type: RECEIVE_CREATE_REFILL_PLAN_SUGGESTIONS,
        deviceCount,
        totalLength,
        refillPlanSuggestions: refillPlanSuggestions.filter(s => s.productData),
        receivedAt: Date.now(),
    };
}

/**
 * Receive VAT categories error Redux action creator
 * @param {Error} error The error object created in api.js
 */
function receiveRefillPlansSuggestionsError(error) {
    return {
        type: RECEIVE_CREATE_REFILL_PLAN_SUGGESTIONS_ERROR,
        error: error,
    };
}

/**
 * Request create Refill plan Redux action creator
 */
function requestCreaterefillPlan() {
    return {
        type: REQUEST_CREATE_REFILL_PLAN,
    };
}

/**
 * Receive VAT categories Redux action creator
 * @param {array} vatCategories Array of VAT categories received from root api
 */
function receiveRefillPlans(refillPlans) {
    return {
        type: RECEIVE_REFILL_PLANS,
        refillPlans,
        receivedAt: Date.now(),
    };
}

/**
 * Receive VAT categories error Redux action creator
 * @param {Error} error The error object created in api.js
 */
function receiveRefillPlansError(error) {
    return {
        type: RECEIVE_REFILL_PLANS_ERROR,
        error: error,
    };
}

/**
 * Receive create Refill plan Redux action creator
 * @param {object} refillPlan The new Refill plan object that is created. Coming from root API.
 */
function receiveCreateRefillPlan(refillPlan) {
    return {
        type: RECEIVE_CREATE_REFILL_PLAN,
        refillPlan,
        receivedAt: Date.now(),
    };
}

/**
 * Receive create Refill plan Redux action creator
 * @param {object} refillPlan The new Refill plan object that is created. Coming from root API.
 */
function receiveUpdateRefillPlan(refillPlan) {
    return {
        type: RECEIVE_UPDATE_REFILL_PLAN,
        refillPlan,
        receivedAt: Date.now(),
    };
}

/**
 * Receive Refill plan error Redux action creator
 * @param {Error} error The Error object received from api.js while creating a new Refill plan
 */
function receiveCreateRefillPlanError(error) {
    return {
        type: RECEIVE_CREATE_REFILL_PLAN_ERROR,
        error,
    };
}

/**
 * Thunk action for creating a new Refill plan
 * @param {object} refillPlan The new Refill plan object to be created in db.
 */
export function refillPlanCabinetSuggestions(cabinetArray) {
    return (dispatch, getState) => {
        const user = getState().user;

        dispatch(requestCreateRefillPlanSuggestions());

        const deviceCodes = [...cabinetArray];
        const ARRARY_CHUNK_SIZE = 5;

        let deviceCodeChunk = [];
        for (let i = 0; i < deviceCodes.length; i = i + ARRARY_CHUNK_SIZE) {
            deviceCodeChunk.push(deviceCodes.slice(i, i + ARRARY_CHUNK_SIZE));
        }

        for (let i = 0; i < deviceCodeChunk.length; i++) {
            postRefillPlanSuggestionsHttpRequest(deviceCodeChunk[i], user.group, user.accessToken)
                .then(refillPlanSuggestions => {
                    // if the last of the cabinet chunck array is complete,
                    // request has finished.
                    dispatch(receiveRefillPlanSuggestions(deviceCodeChunk[i].length, deviceCodes.length, refillPlanSuggestions));
                })
                .catch(error => dispatch(receiveRefillPlansSuggestionsError(error)));
        }
    };
}

/**
 * Thunk action for creating a new Refill plan
 * @param {object} refillPlan The new Refill plan object to be created in db.
 */
export function createRefillPlan(refillPlan) {
    return (dispatch, getState) => {
        dispatch(requestCreaterefillPlan());

        const user = getState().user;
        return createRefillPlanHttpRequest(refillPlan, user.group, user.accessToken)
            .then(createdRefillPlan => dispatch(receiveCreateRefillPlan(createdRefillPlan)))
            .catch(error => dispatch(receiveCreateRefillPlanError(error)));
    };
}

/**
 * Request update refill plan Redux action creator
 */
function requestUpdateRefillPlan() {
    return {
        type: REQUEST_UPDATE_REFILL_PLAN,
    };
}
/**
 * Request update refill plan Redux action creator
 */
function requestDeleteRefillPlan(id) {
    return {
        type: REQUEST_DELETE_REFILL_PLAN,
        id,
    };
}

/**
 * Receive update refill plan Redux action creator
 * @param {object} refillPlan The updated Refill plan object received from root api
 */
function receiveDeleteRefillPlan(response) {
    return {
        type: RECEIVE_DELETE_REFILL_PLAN,
        response,
    };
}
/**
 * Receive update Refill plan error Redux action creator
 * @param {Error} error The error object received from api.js while handling update Refill plan
 */
function receiveDeleteRefillPlanError(error) {
    return {
        type: RECEIVE_DELETE_REFILL_PLAN_ERROR,
        error,
    };
}

/**
 * Receive update Refill plan error Redux action creator
 * @param {Error} error The error object received from api.js while handling update Refill plan
 */
function receiveUpdateRefillPlanError(error) {
    return {
        type: RECEIVE_UPDATE_REFILL_PLAN_ERROR,
        error,
    };
}

export function fetchRefillPlans() {
    return (dispatch, getState) => {
        dispatch(requestRefillPlans());
        const user = getState().user;

        return fetchRefillPlansHttpRequest(user.accessToken, user.group)
            .then(response => dispatch(receiveRefillPlans(response)))
            .catch(error => dispatch(receiveRefillPlansError(error)));
    };
}

/**
 * Thunk action for updating a Refill plan
 * @param {string} id The id of the Refill plan which needs to be updated
 * @param {object} refillPlanToBeUpdated The Refill plan object to be updated
 */
export function updateRefillPlan(id, refillPlanToBeUpdated) {
    return (dispatch, getState) => {
        dispatch(requestUpdateRefillPlan());

        const user = getState().user;
        return updateRefillPlanHttpRequest(user.accessToken, user.group, refillPlanToBeUpdated, id)
            .then(updatedRefillPlan => dispatch(receiveUpdateRefillPlan(updatedRefillPlan)))
            .catch(error => dispatch(receiveUpdateRefillPlanError(error)));
    };
}

/**
 * Thunk action for deleting a Refill plan
 * @param {array} ids The id of the Refill plan which needs to be updated
 */
export function deleteRefillPlan(plansIds) {
    return (dispatch, getState) => {
        const user = getState().user;

        for (let i = 0; i < plansIds.length; i++) {
            dispatch(requestDeleteRefillPlan(plansIds[i]));
            deleteRefillPlanSuggestionsHttpRequest(user.accessToken, user.group, plansIds[i])
                .then(response => {
                    if (i + 1 === plansIds.length) {
                        dispatch(receiveDeleteRefillPlan(response));
                    }
                })
                .catch(error => dispatch(receiveDeleteRefillPlanError(error)));
        }

        /*
        return deleteRefillPlanSuggestionsHttpRequest(user.accessToken, user.group, id)
            .then(response => dispatch(receiveDeleteRefillPlan(response)))
            .catch(error => dispatch(receiveDeleteRefillPlanError(error)));
            */
    };
}

/**
 * Dismiss results of Refill plan requests Redux action creator
 */
export function dismissRefillPlanResults() {
    return {
        type: DISMISS_REFILL_PLAN_RESULT,
    };
}

/**
 * Resets refill plans state to initial state
 */
function resetRefillPlans() {
    return {
        type: RESET_REFILL_PLANS,
    };
}

/**
 * Thunk action creator for refreshing refill plans state
 */
export function refreshRefillPlans() {
    return dispatch => {
        dispatch(resetRefillPlans());
        dispatch(fetchRefillPlans());
    };
}
