import {
    createVatCategory as createVatCategoryHttpRequest,
    fetchVatCategories as fetchVatCategoriesHttpRequest,
    updateVatCategory as updateVatCategoryHttpRequest,
    deleteVatCategory as deleteVatCategoryHttpRequest,
} from '../api';

export const REQUEST_VAT_CATEGORIES = 'REQUEST_VAT_CATEGORIES';
export const RECEIVE_VAT_CATEGORIES = 'RECEIVE_VAT_CATEGORIES';
export const RECEIVE_VAT_CATEGORIES_ERROR = 'RECEIVE_VAT_CATEGORIES_ERROR';

export const REQUEST_CREATE_VAT_CATEGORY = 'REQUEST_CREATE_VAT_CATEGORY';
export const RECEIVE_CREATE_VAT_CATEGORY = 'RECEIVE_CREATE_VAT_CATEGORY';
export const RECEIVE_CREATE_VAT_CATEGORY_ERROR = 'RECEIVE_CREATE_VAT_CATEGORY_ERROR';

export const REQUEST_UPDATE_VAT_CATEGORY = 'REQUEST_UPDATE_VAT_CATEGORY';
export const RECEIVE_UPDATE_VAT_CATEGORY = 'RECEIVE_UPDATE_VAT_CATEGORY';
export const RECEIVE_UPDATE_VAT_CATEGORY_ERROR = 'RECEIVE_UPDATE_VAT_CATEGORY_ERROR';

export const REQUEST_DELETE_VAT_CATEGORY = 'REQUEST_DELETE_VAT_CATEGORY';
export const RECEIVE_DELETE_VAT_CATEGORY = 'RECEIVE_DELETE_VAT_CATEGORY';
export const RECEIVE_DELETE_VAT_CATEGORY_ERROR = 'RECEIVE_DELETE_VAT_CATEGORY_ERROR';

export const DISMISS_VAT_CATEGORY_RESULT = 'DISMISS_VAT_CATEGORY_RESULT';

export const RESET_VAT_CATEGORIES = 'RESET_VAT_CATEGORIES';

export const SEARCH_VAT_CATEGORY_QUERY = 'SEARCH_VAT_CATEGORY_QUERY';

/**
 * Request VAT Categories Redux action creator
 */
function requestVatCategories() {
    return {
        type: REQUEST_VAT_CATEGORIES,
    };
}

/**
 * Receive VAT categories Redux action creator
 * @param {array} vatCategories Array of VAT categories received from root api
 */
function receiveVatCategories(vatCategories) {
    return {
        type: RECEIVE_VAT_CATEGORIES,
        vatCategories,
        receivedAt: Date.now(),
    };
}

/**
 * Receive VAT categories error Redux action creator
 * @param {Error} error The error object created in api.js
 */
function receiveVatCategoriesError(error) {
    return {
        type: RECEIVE_VAT_CATEGORIES_ERROR,
        error: error,
    };
}

/**
 * Thunk action for fetching VAT categories
 */
export function fetchVatCategories() {
    return (dispatch, getState) => {
        dispatch(requestVatCategories());

        const user = getState().user;
        return fetchVatCategoriesHttpRequest(user.group, user.accessToken)
            .then(response => dispatch(receiveVatCategories(response)))
            .catch(error => dispatch(receiveVatCategoriesError(error)));
    };
}

/**
 * Request create VAT category Redux action creator
 */
function requestCreateVatCategory() {
    return {
        type: REQUEST_CREATE_VAT_CATEGORY,
    };
}

/**
 * Receive create VAT category Redux action creator
 * @param {object} vatCategory The new VAT category object that is created. Coming from root API.
 */
function receiveCreateVatCategory(vatCategory) {
    return {
        type: RECEIVE_CREATE_VAT_CATEGORY,
        vatCategory,
        receivedAt: Date.now(),
    };
}

/**
 * Receive VAT category error Redux action creator
 * @param {Error} error The Error object received from api.js while creating a new VAT category
 */
function receiveCreateVatCategoryError(error) {
    return {
        type: RECEIVE_CREATE_VAT_CATEGORY_ERROR,
        error,
    };
}

/**
 * Thunk action for creating a new VAT category
 * @param {object} vatCategory The new VAT category object to be created in db.
 */
export function createVatCategory(vatCategory) {
    return (dispatch, getState) => {
        dispatch(requestCreateVatCategory());

        const user = getState().user;
        return createVatCategoryHttpRequest(vatCategory, user.group, user.accessToken)
            .then(createdVatCategory => dispatch(receiveCreateVatCategory(createdVatCategory)))
            .catch(error => dispatch(receiveCreateVatCategoryError(error)));
    };
}

/**
 * Request update VAT category Redux action creator
 */
function requestUpdateVatCategory() {
    return {
        type: REQUEST_UPDATE_VAT_CATEGORY,
    };
}

/**
 * Receive update VAT category Redux action creator
 * @param {object} vatCategory The updated VAT category object received from root api
 */
function receiveUpdateVatCategories(vatCategory) {
    return {
        type: RECEIVE_UPDATE_VAT_CATEGORY,
        vatCategory,
        receivedAt: Date.now(),
    };
}

/**
 * Receive update VAT category error Redux action creator
 * @param {Error} error The error object received from api.js while handling update VAT category
 */
function receiveUpdateVatCategoryError(error) {
    return {
        type: RECEIVE_UPDATE_VAT_CATEGORY_ERROR,
        error,
    };
}

/**
 * Thunk action for updating a VAT category
 * @param {string} id The id of the VAT category which needs to be updated
 * @param {object} vatCategoryToBeUpdated The VAT category object to be updated
 */
export function updateVatCategory(id, vatCategoryToBeUpdated) {
    return (dispatch, getState) => {
        dispatch(requestUpdateVatCategory());

        const user = getState().user;
        return updateVatCategoryHttpRequest(id, vatCategoryToBeUpdated, user.group, user.accessToken)
            .then(updatedVatCategory => dispatch(receiveUpdateVatCategories(updatedVatCategory)))
            .catch(error => dispatch(receiveUpdateVatCategoryError(error)));
    };
}

/**
 * Request delete VAT category Redux action creator
 */
function requestDeleteVatCategory() {
    return {
        type: REQUEST_DELETE_VAT_CATEGORY,
    };
}

/**
 * Receive delete VAT category Redux action creator
 * @param {string} id Th eid of the deleted vat category
 */
function receiveDeleteVatCategories(id) {
    return {
        type: RECEIVE_DELETE_VAT_CATEGORY,
        id,
        receivedAt: Date.now(),
    };
}

/**
 * Receive delete VAT category error Redux action creator
 * @param {Error} error The error received from api.js. Normally received when the VAT category is associated with a product and root api rejects the request.
 */
function receiveDeleteVatCategoryError(error) {
    return {
        type: RECEIVE_DELETE_VAT_CATEGORY_ERROR,
        error,
    };
}

/**
 * Thunk action for deleting a vat category
 * @param {id} id The id of the VAT category to be deleted
 */
export function deleteVatCategory(id) {
    return (dispatch, getState) => {
        dispatch(requestDeleteVatCategory());

        const user = getState().user;
        return deleteVatCategoryHttpRequest(id, user.group, user.accessToken)
            .then(id => dispatch(receiveDeleteVatCategories(id)))
            .catch(error => dispatch(receiveDeleteVatCategoryError(error)));
    };
}

/**
 * Dismiss results of VAT category requests Redux action creator
 */
export function dismissVatCategoryResults() {
    return {
        type: DISMISS_VAT_CATEGORY_RESULT,
    };
}

/**
 * Resets VAT categories state to initial state
 */
function resetVatCategories() {
    return {
        type: RESET_VAT_CATEGORIES,
    };
}

/**
 * Thunk action creator for refreshing VAT categories state
 */
export function refreshVatCategories() {
    return dispatch => {
        dispatch(resetVatCategories());
        dispatch(fetchVatCategories());
    };
}

/**
 * Thunk action for handling VAT category search
 */
export function vatCategorySearchQuery(query) {
    return dispatch => {
        dispatch({
            type: SEARCH_VAT_CATEGORY_QUERY,
            query,
        });
    };
}
