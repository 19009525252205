import React, { Component } from 'react';
import moment from 'moment';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import { at, head } from 'lodash';
import { getDateAndTime, atCurry, renderHeaderPosition, getPaymentMethodAndStatus } from './tableUtils';
import TransactionDetailItems from './transactionItems.js';

import './transactionDetail.css';

class TransactionDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            refundFormOpened: false,
            allRowsExpanded: false,
            expandedRows: [],
        };
    }

    getSubComponent(row, tableType, unpaid = false) {
        const { t } = this.props;
        return <TransactionDetailItems row={row} t={t} showButtons={tableType !== 'replenishment'} unpaid={unpaid} tableType={tableType} />;
    }

    componentDidUpdate(prevProps) {
        if (this.props.allRowsExpanded !== prevProps.allRowsExpanded) {
            if (this.props.allRowsExpanded)
                this.setState({ allRowsExpanded: this.props.allRowsExpanded, expandedRows: this.props.transactions.map(() => true) });
            else this.setState({ allRowsExpanded: this.props.allRowsExpanded, expandedRows: [] });
        }
    }

    render() {
        const { transactions, tableType, t, unpaid = false } = this.props;
        const data = transactions
            .sort((a, b) => moment(b.timestamp).diff(a.timestamp))
            .map(d => ({
                ...d,
                totalPrice: Number(head(at(d, 'purchases.totalSum'))),
                amount: `${Number(head(at(d, 'purchases.totalSum'))).toFixed(2)} ${head(at(d, 'purchases.purchase[0].currency'))}`,
            }));

        /** Gets the total sum of the order */
        const getTotalSum = data => {
            const totalPrice = (data.purchases && data.purchases.totalSum) || 0;

            if (data.status !== 'PARTIAL') {
                return <div className="text-wrap">{parseFloat(totalPrice, 10)}</div>;
            } else if (!unpaid) {
                return (
                    <div className="text-wrap">
                        {data.paidAmount ? parseFloat(data.paidAmount.toFixed(2), 10) : 0}
                        <b>{`(${t('reports:total')} ${totalPrice.toFixed(2)})`}</b>
                    </div>
                );
            } else {
                const amountOwed = totalPrice - data.paidAmount;
                return (
                    <div className="text-wrap">
                        {parseFloat(amountOwed.toFixed(2), 10)}
                        <b>{`(${t('reports:total')} ${totalPrice.toFixed(2)})`}</b>
                    </div>
                );
            }
        };

        const getOrderId = order => {
            return (
                <div>
                    <div className="text-wrap">
                        {order.transactionDetails && order.transactionDetails.secondaryOrderId ? order.transactionDetails.secondaryOrderId : order.orderId}
                    </div>
                    {order.discountedUserId ? (
                        <em className="d-flex">
                            {t('reports:userId')}: {order.discountedUserId}
                        </em>
                    ) : null}
                </div>
            );
        };

        let colDefination = [];
        if (tableType === 'sales') {
            colDefination = [
                {
                    id: 'orderId',
                    Header: renderHeaderPosition(t('reports:order'), 'left'),
                    accessor: getOrderId,
                },
                {
                    id: 'totalSum',
                    Header: renderHeaderPosition(t('reports:amount'), 'left'),
                    accessor: getTotalSum,
                },
                {
                    id: 'timestamp',
                    Header: renderHeaderPosition(t('reports:time'), 'left'),
                    accessor: atCurry('timestamp'),
                    Cell: getDateAndTime,
                },
                {
                    id: 'paymentMethod',
                    Header: '',
                    Cell: data => getPaymentMethodAndStatus(data, t),
                },
            ];
        } else if (tableType === 'replenishment') {
            colDefination = [
                {
                    id: 'timestamp',
                    Header: renderHeaderPosition(t('reports:time'), 'left'),
                    accessor: atCurry('timestamp'),
                    Cell: getDateAndTime,
                },
                {
                    id: 'user',
                    Header: renderHeaderPosition(t('reports:user'), 'left'),
                    accessor: atCurry('user'),
                },
            ];
        }
        return (
            <div>
                <ReactTable
                    expanded={this.state.expandedRows}
                    onExpandedChange={newExpanded => {
                        this.setState({
                            ...this.state,
                            expandedRows: newExpanded,
                        });
                    }}
                    data={data}
                    columns={colDefination}
                    defaultPageSize={10}
                    minRows={0}
                    className="-striped -highlight customSize"
                    SubComponent={row => this.getSubComponent(row, tableType, unpaid)}
                />
            </div>
        );
    }
}

export default TransactionDetail;
