import {
    REQUEST_ORGANIZATIONS_SETTINGS,
    RECEIVE_ORGANIZATIONS_SETTINGS_ERROR,
    RECEIVE_ORGANIZATIONS_SETTINGS,
    REQUEST_ORGANIZATIONS_SETTINGS_UPDATE,
    RECEIVE_ORGANIZATIONS_SETTINGS_UPDATE_ERROR,
    RECEIVE_ORGANIZATIONS_SETTINGS_UPDATE,
    RECEIVE_UPLOAD_ORGANIZATION_LOGO_IMAGE_SUCCESS,
    RECEIVE_UPLOAD_ORGANIZATION_LOGO_IMAGE_ERROR,
    REQUEST_ORGANIZATION_UPLOAD_LOGO_IMAGE,
    REQUEST_ORGANIZATION_REMOVE_LOGO_IMAGE,
    REVERT_ORGANIZATION_REMOVE_LOGO_IMAGE_STATE,
    RESET_ORG_SETTINGS,
} from '../actions/organizations.js';

export const initState = {
    isFetching: false,
    isError: false,
    isRequesting: false,
    isRequestingFinished: false,
    isDeletingFailed: false,
    organizationSettings: null,
};

export default function organizations(state = initState, action) {
    switch (action.type) {
        case REQUEST_ORGANIZATION_UPLOAD_LOGO_IMAGE: {
            return {
                ...state,
                organizationSettings: {
                    ...state.organizationSettings,
                    organizationLogoStoring: true,
                },
            };
        }
        case RECEIVE_UPLOAD_ORGANIZATION_LOGO_IMAGE_SUCCESS: {
            return {
                ...state,
                organizationSettings: {
                    ...state.organizationSettings,
                    organizationLogoStoring: false,
                    oldOrganizationLogoUrl: '',
                    organizationLogoUrl: action.url,
                    imageStoringError: '',
                },
                lastUpdate: action.receivedAt,
            };
        }
        case RECEIVE_UPLOAD_ORGANIZATION_LOGO_IMAGE_ERROR: {
            return {
                ...state,
                organizationSettings: {
                    ...state.organizationSettings,
                    organizationLogoStoring: false,
                    organizationLogoUrl: '',
                    imageStoringError: action.error.toString(),
                },
                lastUpdate: action.receivedAt,
            };
        }
        case REQUEST_ORGANIZATION_REMOVE_LOGO_IMAGE: {
            return {
                ...state,
                organizationSettings: {
                    ...state.organizationSettings,
                    oldOrganizationLogoUrl: state.organizationSettings?.organizationLogoUrl,
                    organizationLogoUrl: '',
                },
            };
        }
        case REVERT_ORGANIZATION_REMOVE_LOGO_IMAGE_STATE: {
            return {
                ...state,
                organizationSettings: {
                    ...state.organizationSettings,
                    organizationLogoUrl: state.organizationSettings?.oldOrganizationLogoUrl,
                },
            };
        }
        case REQUEST_ORGANIZATIONS_SETTINGS:
            return {
                ...state,
                isFetching: true,
                isError: false,
            };
        case RECEIVE_ORGANIZATIONS_SETTINGS:
            return {
                ...state,
                isFetching: false,
                isError: false,
                organizationSettings: action.organizationSettings,
                lastUpdate: action.receivedAt,
            };
        case RECEIVE_ORGANIZATIONS_SETTINGS_ERROR:
            console.log(action.error);
            return {
                ...state,
                isFetching: false,
                isError: true,
                error: action.error.toString(),
            };
        case REQUEST_ORGANIZATIONS_SETTINGS_UPDATE:
            return {
                ...state,
                isFetching: true,
                isError: false,
            };
        case RECEIVE_ORGANIZATIONS_SETTINGS_UPDATE:
            return {
                ...state,
                isFetching: false,
                isError: false,
                organizationSettings: action.organizationSettings,
                lastUpdate: action.receivedAt,
            };
        case RECEIVE_ORGANIZATIONS_SETTINGS_UPDATE_ERROR:
            console.log(action.error);
            return {
                ...state,
                isFetching: false,
                isError: true,
                error: action.error.toString(),
            };
        case RESET_ORG_SETTINGS:
            return {
                ...initState,
            };
        default:
            return state;
    }
}
