import { ExcelConverter } from 'pdfmake-to-excel';
import { groupBy } from 'lodash';
import moment from 'moment';

const refillPlanExcel = (refillPlan, organization, t) => {
    const groupByCabinets = groupBy(refillPlan, 'cabinetName');
    const dateToday = moment().format('DD.MM.YYYY');

    const getCabinetRefillPlan = cabinetName => {
        if (!cabinetName) return [];
        const cabinetData = groupByCabinets[cabinetName];
        if (!cabinetData) return [];

        const refillInfo = [
            [
                {
                    text: organization,
                    colSpan: 2,
                },
            ],
            [
                {
                    text: `${t('main:cabinet')}: ${cabinetName}`,
                    colSpan: 2,
                },
            ],
            [
                {
                    text: `${t('main:location')}: ${cabinetData[0]?.locationName ? cabinetData[0]?.locationName : '-'}`,
                    colSpan: 2,
                },
            ],
            [
                {
                    text: dateToday,
                    colSpan: 2,
                },
            ],
            [
                {
                    text: '',
                    colSpan: 2,
                },
            ],
            [
                {
                    text: '',
                    colSpan: 2,
                },
            ],
        ];

        const excelHeader = [
            {
                text: t('cabinetSettings:productName'),
            },
            {
                text: t('main:barcode'),
            },
            {
                text: t('main:category'),
            },
            {
                text: t('refill:refillAmount'),
            },
            {
                text: t('refill:refillRuleTargetAmountShort'),
            },
            {
                text: t('refill:refillNote'),
                colSpan: 2,
            },
        ];

        const data = cabinetData.map(product => {
            return [
                {
                    text: product.product,
                },
                {
                    text: product.barcode,
                },
                {
                    text: product.category ? product.category : '-',
                },
                {
                    text: product.refillAmount,
                },
                {
                    text: product.targetInvAmount,
                },
                {
                    text: product.refillNote,
                    colSpan: 2,
                },
            ];
        });

        return [...refillInfo, excelHeader, ...data];
    };

    const getSummaryView = () => {
        if (Object.keys(groupByCabinets).length <= 1) return;
        const groupByProducts = groupBy(refillPlan, 'product');

        const summaryInfo = [
            [
                {
                    text: organization,
                    colSpan: 2,
                },
            ],
            [
                {
                    text: t('refill:refillSummary'),
                    colSpan: 2,
                },
            ],
            [
                {
                    text: dateToday,
                    colSpan: 2,
                },
            ],
            [
                {
                    text: '',
                    colSpan: 2,
                },
            ],
            [
                {
                    text: '',
                    colSpan: 2,
                },
            ],
        ];

        const summaryHeader = [
            {
                text: t('cabinetSettings:productName'),
            },
            {
                text: t('main:barcode'),
            },
            {
                text: t('main:category'),
            },
            {
                text: t('refill:addItems'),
            },
            {
                text: t('refill:removeItems'),
            },
        ];

        const summaryData = Object.keys(groupByProducts).map(product => {
            let addItems = 0,
                removeItems = 0;

            groupByProducts[product].forEach(item => {
                if (item.refillAmount > 0) {
                    addItems = addItems + item.refillAmount;
                } else if (item.refillAmount < 0) {
                    removeItems = removeItems + item.refillAmount;
                }
            });

            return [
                { text: product },
                { text: groupByProducts[product][0]?.barcode },
                { text: groupByProducts[product][0].category ? groupByProducts[product][0].category : '-' },
                { text: addItems },
                { text: removeItems * -1 },
            ];
        });

        return [...summaryInfo, summaryHeader, ...summaryData];
    };

    const excelData = new ExcelConverter(
        `refillPlan - ${dateToday}`,
        {
            title: 'Cabinet Refill Plan',
            data: [
                ...Object.keys(groupByCabinets).map(cabinetName => {
                    return { sheetName: cabinetName, sheetData: getCabinetRefillPlan(cabinetName) };
                }),
                ...(Object.keys(groupByCabinets).length > 1 ? [{ sheetName: t('refillPlan:summary'), sheetData: getSummaryView() }] : []),
            ],
        },
        {
            defaultOptions: { defaultColWidth: 35 },
        }
    );

    excelData.downloadExcel();
};

export default refillPlanExcel;
