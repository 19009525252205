import React, { Fragment, useEffect, useState } from 'react';
import moment from 'moment';
import { groupBy, set, map, sumBy } from 'lodash';

import Loader from './loader';
import { _13digitBarcode } from '../utils';
import icons from './../../../icons/icomoon.svg';
import { Table } from 'reactstrap';
import RefillNoteModel from '../modals/refillNoteModel';
import { getHelperTitleText } from '../utils';
import ApiRequestModal from '../modals/ApiRequestModal';
import InfoBanner from './InfoBanner';

const RefillSuggestions = ({
    refillPlans,
    locations,
    cabinets,
    allProducts,
    cancelRefillSuggestionView,
    createRefillPlan,
    refetchRefillPlans,
    t,
    getTransactions,
    cabinetTransactionReport,
    fetchCabinetInventory,
    cabinetInventory,
    isAdmin,
}) => {
    const { currentProgress, isFetching, totalLength, suggestion } = refillPlans.refillSuggestion || {};

    const [suggestionData, setSuggestionData] = useState({});
    const [selectedDevice, setSelectedDevice] = useState(null);
    const [refillNoteModal, setRefillNoteModal] = useState({ isOpen: false, product: null, refillNote: '' });
    const [openCreatePlanModal, setOpenCreatePlanModal] = useState(false);
    const [cabinetTransactions, setCabinetTransaction] = useState({});

    useEffect(() => {
        if (isFetching) return;

        const groupByCabinetCodes = groupBy(suggestion, 'deviceCode');
        const mappedRefillSuggestion = {};

        Object.keys(groupByCabinetCodes).forEach(deviceCode => {
            const refillRuleId = groupByCabinetCodes[deviceCode][0]?.refillRuleId;
            const productData = [];
            groupByCabinetCodes[deviceCode].forEach(schema => productData.push(schema.productData || []));
            const cabinet = cabinets.find(cabinet => cabinet.deviceCode === deviceCode);
            if (!cabinet) return;
            const cabinetName = cabinet.name;
            let locationName;
            if (!cabinet.locationId) {
                locationName = 'noLocation';
            } else {
                locationName = locations.find(location => location.id === cabinet.locationId)?.name;
            }

            set(mappedRefillSuggestion, deviceCode, { refillRuleId, productData, cabinetName, locationName });
        });

        setSuggestionData(mappedRefillSuggestion);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [refillPlans.refillSuggestion]);

    useEffect(() => {
        if (cabinetTransactionReport.isFetching) return;
        const purchases = cabinetTransactionReport.data || [];

        if (!purchases.length) {
            setCabinetTransaction({ ...cabinetTransactions, [selectedDevice]: [] });
            return;
        }

        //check if the data has already been saved in state,
        //if already in state, no need to calclutate payload again
        if (cabinetTransactions[selectedDevice]) return;

        let purchasedBarcodes = [];

        purchases.forEach(data => {
            const purchasedProducts = data.purchases?.purchase || [];
            if (!purchasedProducts.length) return;

            purchasedProducts.forEach(product => {
                purchasedBarcodes.push({
                    barcode: product.barcode,
                    count: product.count,
                });
            });
        });

        const groupedBarcode = groupBy(purchasedBarcodes, 'barcode');
        const totalPurchases = map(groupedBarcode, (products, barcode) => {
            return {
                barcode,
                totalCount: sumBy(products, product => product.count),
            };
        });

        setCabinetTransaction({
            ...cabinetTransactions,
            [selectedDevice]: totalPurchases,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cabinetTransactionReport]);

    const handleRefillDataChange = (type, action, product, value) => {
        if (!selectedDevice) return;
        const selectedSchema = { ...suggestionData[selectedDevice] };
        if (!selectedSchema) return;

        const productData = selectedSchema.productData.find(data => data.barcode === product.barcode);
        if (!productData) return;

        let updateProductData = {};

        if (type === 'refillNote') {
            updateProductData = {
                ...productData,
                refillNote: value,
            };
        } else {
            updateProductData = {
                ...productData,
                [type]: action === 'plus' ? product[type] + 1 : product[type] - 1,
            };
        }
        setSuggestionData({
            ...suggestionData,
            [selectedDevice]: {
                ...selectedSchema,
                productData: selectedSchema.productData.map(data => {
                    if (data.barcode === product.barcode) {
                        return updateProductData;
                    }
                    return data;
                }),
            },
        });
        if (type === 'refillNote') {
            setRefillNoteModal({ isOpen: false, product: null, refillNote: '' });
        }
    };

    /**
     * an API request to add new refill plans
     */
    const apiRequestToAddNewActiveRefillPlans = () => {
        setOpenCreatePlanModal(true);
        const newSuggestions = { ...suggestionData };
        Object.keys(newSuggestions).forEach(deviceCode => {
            delete newSuggestions[deviceCode].locationName;
            delete newSuggestions[deviceCode].cabinetName;
        });

        createRefillPlan(newSuggestions);
    };

    /**
     *
     * @param {*} deviceCode
     * @returns
     */
    const toggleSchemaTab = deviceCode => {
        if (deviceCode === selectedDevice) {
            setSelectedDevice(null);
            return;
        }

        if (!cabinetTransactions[deviceCode]) {
            const from = moment()
                .subtract(7, 'days')
                .startOf('day')
                .toDate();
            const today = moment().toDate();
            getTransactions(deviceCode, from, today);
        }

        if (!cabinetInventory[deviceCode]) {
            fetchCabinetInventory(deviceCode);
        }

        setSelectedDevice(deviceCode);
    };

    /**
     * get the prduct
     * @param {*} deviceCode
     * @param {*} product
     * @returns
     */
    const getProductInventory = (deviceCode, product) => {
        const deviceInventory = cabinetInventory[deviceCode];
        if (!deviceInventory) return '-';

        if (deviceInventory.isFetching)
            return (
                <svg className="activeplans__spinner--small">
                    <use xlinkHref={`${icons}#icon-spinner7`}></use>
                </svg>
            );

        const inventory = deviceInventory.inventory;
        if (!inventory) return '-';
        if (!inventory.length) return 0;

        // group the inventory by barcode,
        const inventoryByBarcode = groupBy(inventory, 'barcode');
        return inventoryByBarcode[product.barcode] ? inventoryByBarcode[product.barcode].length : 0;
    };

    /**
     * How many items were sold for each product.
     * @param {*} deviceCode
     * @param {*} product
     * @returns
     */
    const getProductSalesCount = (deviceCode, product) => {
        if (cabinetTransactionReport.isFetching) {
            return (
                <svg className="activeplans__spinner--small">
                    <use xlinkHref={`${icons}#icon-spinner7`}></use>
                </svg>
            );
        }

        const purchaseData = cabinetTransactions[deviceCode];
        if (!purchaseData) return '-';

        const productSales = purchaseData.find(data => _13digitBarcode(data.barcodeType, data.barcode) === product.barcode);
        if (!productSales) return 0;
        return productSales.totalCount;
    };

    const getOnProgressMessage = () => {
        return (
            <div>
                <div>{t('generatingPlans')}</div>
                <div>
                    <strong>
                        {' '}
                        {currentProgress} / {totalLength} {t('complete')}
                    </strong>
                </div>
            </div>
        );
    };

    if (isFetching) {
        return <Loader message={getOnProgressMessage()} />;
    }

    return (
        <div className="activeplans__products">
            <RefillNoteModel
                isOpen={refillNoteModal.isOpen}
                product={refillNoteModal.product}
                refillNote={refillNoteModal.refillNote}
                closeRefillNote={(product, text) => {
                    handleRefillDataChange('refillNote', null, product, text);
                }}
                t={t}
                isAdmin={isAdmin}
            />
            <ApiRequestModal
                isOpen={openCreatePlanModal}
                data={refillPlans}
                closeModal={() => {
                    setOpenCreatePlanModal(false);
                    refetchRefillPlans();
                }}
                successMessage={t('refillPlanSaved')}
                t={t}
            />
            <div className="refillschema__controller">
                <InfoBanner message={t('reviewSuggestion')} />

                <div className="refillschema__controller-btns">
                    <div
                        className="refillschema__controller-action refillschema__controller-action-neutral"
                        onClick={() => cancelRefillSuggestionView()}
                        title={getHelperTitleText('add', t)}
                    >
                        <svg>
                            <use xlinkHref={`${icons}#icon-close-outline`}></use>
                        </svg>
                        <span>{t('main:cancel')}</span>
                    </div>
                    <div
                        className="refillschema__controller-action refillschema__controller-action-positive"
                        onClick={() => apiRequestToAddNewActiveRefillPlans()}
                    >
                        <svg>
                            <use xlinkHref={`${icons}#icon-save`}></use>
                        </svg>
                        <span>{t('main:save')}</span>
                    </div>
                </div>
            </div>
            {Object.keys(suggestionData).map(deviceCode => {
                return (
                    <Fragment key={`suggestion-${deviceCode}`}>
                        <div className={`activeplans__item ${selectedDevice === deviceCode ? ' activeplans__item--active' : ''}`}>
                            <div onClick={() => toggleSchemaTab(deviceCode)} className="activeplans__item-header">
                                <svg className="activeplans__item-drope">
                                    <use xlinkHref={`${icons}#icon-keyboard_arrow_right`}></use>
                                </svg>
                                <svg>
                                    <use xlinkHref={`${icons}#icon-location`}></use>
                                </svg>
                                <span className="activeplans__item-name">
                                    {t(`main:${suggestionData[deviceCode].locationName}`)} | {suggestionData[deviceCode].cabinetName}
                                </span>
                            </div>
                        </div>
                        <div className={`activeplans__table ${deviceCode === selectedDevice ? 'activeplans__table--active' : ''}`}>
                            <Table responsive>
                                <thead>
                                    <tr>
                                        <th className="activeplans__table-left">Product</th>
                                        <th className="hide-in-mb">{t('main:barcode')}</th>
                                        <th>{t('inventoryChange')}</th>
                                        <th className="hide-in-medium">{t('refill:currentInventory')}</th>
                                        <th className="hide-in-medium">{t('refill:lastSevenDaySales')}</th>
                                        <th className="hide-in-sm">{t('refillNote')}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {suggestionData[deviceCode].productData.map(product => {
                                        const inventoryProduct = getProductInventory(deviceCode, product);
                                        const salesCount = getProductSalesCount(deviceCode, product);

                                        return (
                                            <tr key={product.barcode}>
                                                <td className="activeplans__table-left">
                                                    <img src={product.imageUrl} alt="" className="activeplans__table-img" />
                                                    <span className="activeplans__table-name">{product.name}</span>
                                                </td>
                                                <td className="hide-in-mb">{product.barcode}</td>
                                                <td>
                                                    <div className="activeplans__table-invchange activeplans__table-invchange--active">
                                                        <button onClick={() => handleRefillDataChange('amountToRefill', 'minus', product)}>-</button>
                                                        <span
                                                            className={
                                                                product.amountToRefill > 0
                                                                    ? 'activeplans__table-invchange-positive'
                                                                    : product.amountToRefill === 0
                                                                    ? 'activeplans__table-invchange-neutral'
                                                                    : 'activeplans__table-invchange-negetive'
                                                            }
                                                        >
                                                            {' '}
                                                            {product.amountToRefill}{' '}
                                                        </span>
                                                        <button onClick={() => handleRefillDataChange('amountToRefill', 'plus', product)}>+</button>
                                                    </div>
                                                </td>
                                                <td className="hide-in-medium">
                                                    <span className={inventoryProduct > 0 ? 'activeplans__table-invchange-value' : ''}>
                                                        {' '}
                                                        {inventoryProduct}{' '}
                                                    </span>
                                                </td>
                                                <td className="hide-in-medium">
                                                    <span className={salesCount > 0 ? 'activeplans__table-invchange-value' : ''}>{salesCount} </span>
                                                </td>

                                                <td className="hide-in-sm">
                                                    <div
                                                        className={`activeplans__table-refillnote ${
                                                            product.refillNote ? 'refillschema__contents-note--active' : ''
                                                        }`}
                                                        onClick={() => setRefillNoteModal({ isOpen: true, product, refillNote: product.refillNote })}
                                                    >
                                                        <Fragment>
                                                            <svg>
                                                                <use xlinkHref={`${icons}#icon-clipboard-edit`}></use>
                                                            </svg>
                                                            <span>{t('editNote')}</span>
                                                        </Fragment>
                                                    </div>
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </Table>
                        </div>
                    </Fragment>
                );
            })}
        </div>
    );
};

export default RefillSuggestions;
