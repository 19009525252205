import i18n from 'i18next';
import { locale } from 'moment';
import LanguageDetector from 'i18next-browser-languagedetector';
import ISO6391 from 'iso-639-1/build';

import 'moment/locale/fi';
import 'moment/locale/en-gb';
import 'moment/locale/sv';
import 'moment/locale/es';
import 'moment/locale/de';
import 'moment/locale/fr';
import 'moment/locale/nb';
import 'moment/locale/it';
import 'moment/locale/hu';
import 'moment/locale/lv';
import 'moment/locale/et';
import 'moment/locale/de-ch';
import 'moment/locale/da';
import 'moment/locale/cs';
import 'moment/locale/pt';

i18n.getNativeName = lang => {
    return lang === 'de-CH' ? 'Schweizerdeutsch' : ISO6391.getNativeName(lang);
};

i18n.on('languageChanged', lng => {
    if (lng === 'no') {
        locale('nb');
    } else {
        locale(lng);
    }
});
i18n.use(LanguageDetector).init({
    // we init with resources
    resources: {
        en: {
            dateRangeFilter: {
                lastMonth: 'last 30 days',
                lastTwoWeek: 'last 14 days',
                lastWeek: 'last 7 days',
                selectDateRangeMsg: 'Selected date range',
                today: 'today',
                yesterday: 'yesterday',
            },
            main: {
                activateSuccess: 'Product activated successfully',
                activationDate: 'Activation date',
                active: 'Active',
                addAdvertisement: 'Add new advertisement',
                addDiscountRule: 'Add discount rule',
                addDiscountStop: 'Add expiry interval',
                addNewLocation: 'Pick a new location',
                addProduct: 'Add new product',
                addRegion: 'Add location',
                addRegionPrice: 'Add location price',
                addScheduledDiscount: 'Add scheduled discount',
                addBundleDiscount: 'Add bundle discount',
                bundledDiscounts: 'Bundle discounts',
                bundleItemsLengthExceeded: 'Maximum selection of products (3) has exceeded. Remove some of your selections to continue.',
                bundleMaxProductLimitInfo: 'Select max. three (3) products or product categories',
                bundleFixedAmountExceededOriginal: "Bundle's fixed price exceeds the original price sum. Check selected products.",
                bundleFixedAmountNotAllowedToBeZero: "Bundle's fixed price must be over 0",
                bundlePercentageNotAllowedToBeFull: "Bundle's percentage discount must be under 100",
                bundlePercentageNotAllowedToBeZero: "Bundle's percentage discount must be over 0",
                bundleItemDiscountPriceAmountExceededOriginal: 'New discounted price exceeds original price. Check new price again.',
                bundleItemPriceMessage: 'Each pricing rule applies to only 1 item even if multiple of the same item are selected',
                bundleItemFixedAmountExceededOriginal: "Product's fixed price exceeds original product's price. Check fixed price again.",
                createDiscountRule: 'Create discount rule',
                deviceType: 'Device type',
                discountUpdateTitle: 'Discount update',
                discountCreateTitle: 'Discount create',
                discountDeleteTitle: 'Discount removal',
                discountDeleteContent: 'Discount has been removed',
                discountStatusUpdated: "Discount's status has been updated",
                discountUpdated: 'Discount has been updated',
                discountCreated: 'Discount has been created',
                discountSettings: 'Discount settings',
                discountImage: "Discount's optional image",
                editDiscountStop: 'Edit expiry interval',
                editBundleConfiguration: 'Edit bundle configuration',
                editExpiryConfiguration: "Edit expiry discount's configuration",
                encodingStation: 'Encoding station',
                everyDay: 'Everyday',
                scheduledDiscountDateInfoLabel: 'Please leave the start and end date empty if you want to make the discount permanent',
                starts: 'Starts',
                ends: 'Ends',
                error: 'Error',
                errorRetiringLocationMsg: 'Failed to retire location. Please remove linked devices, product prices and advertisements first.',
                fetchingDiscountRules: 'Fetching discount rules',
                free: 'Free',
                missingImage: 'Missing ad image',
                next: 'Next',
                back: 'Back',
                noDiscountRules: 'No discount rules found',
                scheduledDiscountsAutomaticDeactivationInfo:
                    'Discounts where date range is exceeded, are automatically deactivated. If you want make them active, edit date range to be valid.',
                scheduledDiscounts: 'Scheduled discounts',
                schedulingStartHourIsOverEndHour: 'Start time cannot be after end time',
                schedulingEndHourIsUnderStartHour: 'End time cannot be before start time',
                selectDiscountRuleProductCategories: 'Select product categories affected by discount rule',
                selectBundleProductsAmount: 'Select amount for: ',
                selectedBundleProductCategories: 'Selected bundle product categories',
                setDiscountDays: 'Set discount days',
                setBundleDiscount: 'Set either percentage discount amount or fixed price for bundle',
                setBundleItemDiscount: 'Set one of these for one item: percentage discount amount,fixed price or free',
                setBundleProducts: 'Set products or categories for bundle',
                setAmountMoreThanOne: 'In case of single item bundle, set amount more than one',
                setBundleDiscountType: 'Set discount type and amount for it',
                sureActivateDiscountRule: 'Are you sure you want to activate discount rule?',
                sureDeactivateDiscountRule: 'Are you sure you want to deactivate discount rule?',
                sureDeleteDiscountRule: 'Are you sure you want to delete discount rule?',
                timeRange: 'Time range',
                editExpiryDiscountRule: 'Edit expiry discount rule',
                editScheduledDiscountRule: 'Edit scheduled discount rule',
                editBundleDiscountRule: 'Edit bundle discount rule',
                releaseNotes: 'Release notes',
                seeMore: 'See more',
                scheduledDiscountTimeRangeLabel: 'Active time',
                scheduledDiscountDateRangeLabel: "Discount's date range",
                weekDayAbrvMonday: 'Mon',
                weekDayAbrvTuesday: 'Tue',
                weekDayAbrvWednesday: 'Wed',
                weekDayAbrvThursday: 'Thu',
                weekDayAbrvFriday: 'Fri',
                weekDayAbrvSaturday: 'Sat',
                weekDayAbrvSunday: 'Sun',
                recurrence: 'Recurrence',
                addScheduledDiscountStop: 'Add scheduled discount',
                editScheduledDiscountStop: 'Edit scheduled discount',
                pieces: 'pcs',
                expiryDiscountNavLabel: 'Expiry',
                scheduledDiscountNavLabel: 'Scheduled',
                bundleDiscountNavLabel: 'Bundle',
                amount: 'Amount',
                scheduling: 'Scheduling',
                pricing: 'Pricing',
                percentageBundleDiscountInputPlaceholder: '% discount for bundle',
                fixedPriceBundleDiscountInputPlaceholder: 'Fixed price for bundle',
                applyBundleLevelLabel: 'Apply bundle level',
                applyItemLevelLabel: 'Apply item level',
                productFixedPrice: "Product's fixed price",
                percentageDiscount: '% discount',
                fixedPriceDiscount: 'Fixed price',
                freeDiscount: 'Free',
                advertisementsFetchError: 'An error occurred while fetching advertisements. Make sure ad blocker is turned off',
                advertisementsTitle: 'Advertisements',
                all: 'All',
                'All time': 'All time',
                allProducts: 'All products without grouping',
                allLocations: 'All locations',
                allCabinets: 'All cabinets',
                antennaDown: 'Some of the RFID antennas are down',
                antennaError: 'Error is cabinet antennas',
                antennaNoInfo: 'No information is available for cabinet antennas',
                antennaOk: 'All Antennas up and running',
                antennaPartial: 'Some of antennas are disabled',
                archiveSuccess: 'Product archived successfully',
                averageItems: 'Average items per transaction',
                averageSales: 'Average sales per transaction',
                barcode: 'Barcode',
                barcodeType: 'Barcode Type',
                barcodeCannotBeChanged: 'Barcode cannot be changed',
                barcodeTypeCannotBeChanged: 'Barcode type cannot be changed',
                barcodeHelpText12: 'Enter 12 digits of the barcode, the last digit is appended automatically',
                barcodeHelpText11: 'Enter 11 digits of the barcode, the last digit is appended automatically',
                barcodeHelpText7: 'Enter 7 digits of the barcode, the last digit is appended automatically',
                beforeExpiry: 'before expiry',
                cabinetDown: 'Cabinet is down',
                cabinetIsEmpty: 'Cabinet is empty',
                deviceOffline: 'Device is offline',
                deviceOnline: 'Device is online',
                cabinets: 'Cabinets',
                cabinet: 'Cabinet',
                cabinetSettings: 'Cabinet settings',
                cabinetTheming: 'Cabinet theming',
                cancel: 'Cancel',
                cancelledSales: 'Cancelled sales',
                cancelledTransactions: 'Cancelled transactions',
                categories: 'Categories',
                change: 'Change',
                clear: 'Clear',
                close: 'Close',
                clickEditButtonRegionalPrices: 'In order to edit location prices, click on "Edit" product button.',
                clickEditLocationPrices: 'Local prices, click on product price to expand.',
                clickToExpandLocationPrices: 'Local prices, click on product price to expand.',
                closeDoor: 'Close the door',
                rebootDevice: 'Reboot the device',
                commandSentSuccessFully: 'Command Sent Successfully',
                companyLogo: 'Company logo',
                confirm: 'Confirm',
                confirmDelete: 'Are you sure you want to delete the product? This operation cannot be undone!',
                confirmDisable: 'Are you sure you want to archive the product?',
                confirmEnable: 'Are you sure you want to make the product active?',
                consumerResponses: 'User Feedback',
                controlPanelTitle: 'Control Panel',
                controls_acceptablePercentageOfBadTags: 'Acceptable percentage of bad tags before cabinet goes to out of service mode',
                controls_markTagsGoodQuality: 'Mark all tags good quality',
                controls_markTagsGoodQuality_btn: 'Mark tags good quality',
                controls_clearTagHistory: 'Clear tag history',
                controls_clearRemoveReplenishmentData: 'Clear replenishment data',
                controls_companyAndPaymentLogo: 'Company and payment logo',
                controls_detectTagQuality: 'Detect tags quality',
                controls_door: 'Door control',
                controls_device: 'Device control',
                controls_chargeBadTags: 'Charge bad tags',
                controls_refill: 'Refill',
                controls_tagQuality: 'Tag settings',
                controls_tagQualityData: 'Tag quality data',
                controls_replenishmentData: 'Replenishment data',
                controls_soldTags: 'Sold tags',
                controls_tagHistory: 'Tag history',
                controls_markAllTagsReplenished: 'Mark all tags replenished',
                controls_markAllTagsUnsold: 'Mark all tags unsold',
                controls_markAlTagsReplenished_btn: 'Mark tags replenished',
                controls_markAllTagsUnsold_btn: 'Mark tags unsold',
                controls_chargeSoldTags: 'Charge sold tags',
                controls_chargeUnreplenishedTags: 'Charge unreplenished tags',
                controls_tagsWarn: 'Clearing tag history removes all data of tags. Please click mark all tags replenished after this action',
                create: 'Create',
                createAdTitle: 'New advertisement',
                createdAd: 'Advertisement created',
                createExpiryDiscountRule: 'Create expiry discount rule',
                createScheduledDiscountRule: 'Create scheduled discount rule',
                createBundleDiscountRule: 'Create bundle discount rule',
                createNewVatCategory: 'New VAT Category',
                createProductTitle: 'New product',
                creatingAd: 'Creating Advertisement',
                creatingProduct: 'Creating product',
                createNewProductCategory: 'Create a new product category',
                createNewSupplier: 'Add new supplier',
                currency: 'Currency',
                customQuery: 'Custom query',
                dashboard: 'Dashboard',
                dateRange: 'Date range',
                dataAlredyFetchedTitle: 'Data already fetched',
                dataAlreadyFetchedDescription: 'Please chose different parameters',
                day: 'Day',
                days: 'Days',
                deactivationDate: 'Deactivation date',
                default: 'Default',
                delete: 'Delete',
                deleteError: 'Error : the product could not be deleted',
                deleteSuccess: 'Product deleted successfully',
                description: 'Description',
                deviceManagement: 'Device Management',
                digitwinOffline: 'Device digitwin is offline',
                digitwinOnline: 'Device digitwin is online',
                dimensions: 'Dimensions',
                disable: 'Archive',
                discountAmount: 'Discount amount',
                discountPrice: 'Discount price',
                discountRuleAffects: 'Discount rule affects',
                discountRuleName: 'Discount rule name',
                discountRuleStops: 'Expiry intervals',
                discounts: 'Discounts',
                discountSalesData: 'Discount Sales Data',
                discountSalesAmount: 'Discount sales Sum',
                discountTimeBeforeExpiryExists: 'Earlier expiry interval already exists',
                discountVsNormalSales: 'Discount vs normal sales',
                dismiss: 'Dismiss',
                doesNotExist: 'Does not exist',
                doorCloseConfirm: 'Are you sure you want to close the door?',
                doorCloseSuccess: 'Lock the door successfully',
                doorOpenConfirm: 'Are you sure you want to open the door?',
                doorOpenReason: 'Why are you opening door?',
                doorOpenSuccess: 'Door opened successfully',
                rebootDeviceSuccess: 'Device rebooted successfully',
                deviceRebootConfirm: 'Are you sure you want to reboot the device?',
                edit: 'Edit',
                editCompanyLogo: 'Edit company logo',
                editDiscountRule: 'Edit discount rule',
                editPaymentMethodLogo: 'Edit payment method logo',
                editVatCategoryModalTitle: 'Edit VAT Category',
                editProductCategoryModalTitle: 'Edit product category',
                editSupplierModalTitle: 'Edit supplier',
                enable: 'Make Active',
                error_401: 'Not authenticated',
                error_403: 'Not authorized to execute commands',
                error_500: 'Unexpected server error',
                errorCreatingAd: 'Error creating advertisement',
                errorCreatingProduct: 'Error creating product',
                errorFetchingCabinetStatus: 'Error in Fetching Cabinet Status',
                errorFetchingInventory: 'Error fetching cabinet inventory',
                errorFetchingReport: 'Error fetching reports',
                errorFetchingSettings: 'Error fetching settings',
                errorUpdatingProduct: 'Error updating product',
                expandToSeeBadTags: 'Expand the list to see tags that were detected as bad tags. Bad tags need to be removed from the cabinet.',
                expandToSeeSoldItems: 'Expand the list to see the items which are already sold, but appeared in inventory again.',
                expandToSeeReplenishedOutOfInventory:
                    'Expand the list to see the products which have been placed into the cabinet in process other than inventory replenishment',
                expired: 'Expired',
                expiredCount: 'Expired count',
                expiredProductsCount: 'Number of expired products',
                expiredSum: 'Expired sum',
                expiring: 'Expiring',
                expiryDate: 'Last sale day',
                exportExcel: 'Export Excel',
                fetchAdvertisements: 'Fetching advertisements',
                fetchingCabinets: 'Fetching cabinets',
                fetchingCabinetsError: 'An error occurred while fetching cabinets',
                fetchingCabinetStatus: 'Fetching Cabinet Status',
                fetchingError: 'An error occurred while fetching',
                fetchingInventory: 'Fetching cabinet inventory',
                fetchingProducts: 'Fetching products',
                fetchingReport: 'Fetching reports',
                fetchingVatCategories: 'Fetching VAT categories',
                fetchingProductCategories: 'Fetching product categories',
                fillForm: 'Fill in form',
                freshFood: 'This is an expiring product',
                freezerPackage: 'Frozen product',
                generalFetchError: 'Fetching error',
                generalRequestReject: 'Request error',
                get: 'Get',
                group: 'Group',
                holdOn: 'Hold on',
                hour: 'Hour',
                hours: 'hours',
                hoursBeforeExpiry: 'Hours before expiry',
                daysBeforeExpiry: 'Days before expiry',
                image: 'Image',
                imageOrVideo: 'Select an image or a video',
                inActive: 'Inactive',
                individualCabinets: 'Individual cabinets',
                individualCabinetsOnly: 'Individual cabinets only',
                invalidBarcodeOnlyDigits: 'Barcode must only contain digits',
                invalidBarcodeTooLong: 'Barcode is too long',
                invalidBarcodeTooShort: 'Barcode is too short',
                invalidCabinetNameTooShort: 'Cabinet name is too short',
                invalidCabinetNameDuplicated: 'Cabinet name is already existing',
                invalidLocationNameTooShort: 'Location name is too short',
                invalidLocationNameDuplicated: 'Location name is duplicated',
                invalidPercentage: 'Invalid value',
                invalidPrice: 'Invalid price',
                invalidProductBarcode: 'Invalid product barcode',
                invalidTitle: 'Invalid title',
                invalidBundleItems: 'Bundle contains invalid items',
                inventoryLength: 'Inventory length',
                inventoryNotReported: 'Cabinet has not reported its inventory',
                inventoryTitle: 'Cabinet inventory',
                'Inventory History': 'Inventory History',
                itemReplenishedOutOfInventoryProcess: 'This product or tag have been placed in the cabinet in a process other than replenishment process',
                itemSoldOn: 'Item sold on',
                'Last 30 days': 'Last 30 days',
                'Last 7 days': 'Last 7 days',
                'Last month': 'Last month',
                'Last quarter': 'Last quarter',
                'Last week': 'Last week',
                'Last year': 'Last year',
                lastUpdate: 'Last update',
                lifetime: 'Lifetime',
                location: 'Location',
                locations: 'Locations',
                logoEditor: 'Logo Editor',
                logoSettings: 'Logo Settings',
                loading: 'Loading...',
                measure: 'Measure',
                measures: 'Measures',
                minsBeforeExpiry: 'Minutes before expiry',
                minutes: 'minutes',
                month: 'Month',
                name: 'Name',
                newVatCategoryModalTitle: 'New VAT Category',
                newProductCategoryModalTitle: 'New product category',
                newSupplierModalTitle: 'New supplier',
                'no region': '(no location)',
                noAdvertisements: 'No advertisements',
                noCabinets: 'No cabinets',
                noCabinetSelected: 'No cabinet selected',
                noLogo: 'No logo',
                noData: 'No data',
                noDescription: 'No description',
                noEvents: 'No events!',
                noIndividualCabinets: 'No individual cabinets',
                noLocationPrices: 'No local prices are defined for this product',
                none: 'None',
                noPaymentMethodLogo: 'No payment method logo',
                noProducts: 'No products',
                noProductsRemoved: 'No products removed',
                noProductTypeSelected: 'No package type selected',
                normalPrice: 'Normal price',
                normalSalesAmount: 'Normal sales sum',
                notAccessibleForYou:
                    'Create/Update feature is enabled only for Administrators and Cabinet Operators. To get access, contact your supervisor or system administrator.',
                notDefined: 'Not Defined',
                noTitle: 'No title',
                noVatCategories: 'No VAT category record found',
                noProductCategories: 'No product category record found',
                noInventoryOnSelectedDate: 'No Inventory was reported for the date you selected. please try with different date.',
                openDoor: 'Open the door',
                orderSum: 'Order sum',
                paymentMethodLogo: 'Payment method logo',
                percentage: 'Percentage',
                permanent: 'Permanent',
                preview: 'Preview',
                price: 'Price',
                print: 'Print',
                productCreated: 'Product created',
                products: 'Products',
                productsFetchError: 'An error occurred while fetching products',
                productsTitle: 'Product catalog',
                productType: 'Package Type',
                productUpdated: 'Product updated',
                quantity: 'Quantity',
                readerDown: 'The RFID reader is down',
                region: 'Location',
                regions: 'Locations',
                removedAt: 'Removed at',
                removeImage: 'Remove image',
                removeVideo: 'Remove Video',
                refillQRCodeInfo: 'Scan the QR code to open the refill application or follow',
                refillQRCodeTitle: 'Refill QR Code',
                refillRuleTitle: 'Refill rule',
                reportsTitle: 'Reports',
                requestSuccess: 'Changes done successfully',
                requiredPercentage: 'Required. Percentage value without percentage sign.',
                requiredText: 'Required length of characters: ',
                retireLocation: 'Retire location',
                rfidNoInfo: 'No information available about RFID reader and antennas',
                rfidOk: 'RFID reader and antennas are working as expected.',
                sales: 'Sales',
                salesCount: 'Sales count',
                salesVsWasteAmount: 'Sales vs Waste Amount',
                save: 'Save',
                search: 'Search',
                selectCabinet: 'Select a cabinet',
                selectDiscountRuleCabinets: 'Select cabinets affected by discount rule',
                selectDiscountRuleLocations: 'Select locations affected by discount rule',
                selectDiscountRuleProducts: 'Select products affected by discount rule',
                selectProductType: 'Please select the package type for your product',
                setDiscountAmount: 'Set discount amount',
                setDiscountTimeBeforeExpiry: 'Set expiry interval',
                settingConfirmMessage: 'Are you sure to save these settings? If you are not sure, do not confirm it',
                showDisable: 'Archived products',
                summary: 'Summary',
                sureRemoveDiscountRule: 'Are you sure you want to remove discount rule?',
                tagIsBad: 'Tag is bad, need to be removed from the cabinet.',
                tagsRemovedReport: 'Tags removed report',
                taxValue: 'Tax value',
                'This month': 'This month',
                'This quarter': 'This quarter',
                'This week': 'This week',
                'This year': 'This year',
                thisLink: ' this link. ',
                timeGrouping: 'Time grouping',
                title: 'Title',
                Today: 'Today',
                topExpiredProducts: 'Top Expired Products',
                topProducts: 'Top Products',
                total: 'Total',
                totalValue: 'Total Value',
                transactions: 'Transactions',
                ttlInDays: 'Product time to live (lifetime) in days',
                ttlsAffected: 'Products with time to live (lifetime) affected',
                unexpectedError: 'Unexpected Error',
                uniqueCustomers: 'Unique customers',
                unknown: 'Unknown',
                update: 'Update',
                updateAdError: 'Error updating advertisement',
                updateAdTitle: 'Update advertisement',
                updatedAd: 'Advertisement updated',
                updateCabinetSettings: 'Synchronize data to cabinet',
                updateCabinetSettingsInfo: 'Are you sure you want to update cabinet settings?',
                updateData: 'Update data',
                updateProductTitle: 'Update product',
                updatingAd: 'Updating advertisement',
                updatingProduct: 'Updating product',
                uploading: 'Uploading',
                uploadingImage: 'Uploading image',
                use: 'Use',
                'w/o grouping': 'No grouping',
                waste: 'Waste',
                vatCategoriesFetchError: 'Error in fetching VAT categories',
                vatCategoriesTitle: 'VAT Categories',
                vatCategory: 'VAT Category',
                vatCategoryItems: 'Items',
                vatCategoryName: 'VAT category name',
                vatCategoryPromptMessage: 'Are you sure you want to delete the VAT category',
                vatCategoryValue: 'Tax value percentage',
                vatDeleteRejected: 'Deleting VAT category rejected. Check associated products.',
                vatErrorRequesting: 'Error in processing request',
                vatRequestRejected: 'Request Rejected',
                vats: 'VATs',
                productCategoriesFetchError: 'Error in fetching product categories',
                productCategoriesTitle: 'Product categories',
                productCategory: 'Product category',
                productCategoryItems: 'Categories',
                productCategoryName: 'Product category name',
                productCategoryPromptMessage: 'Are you sure you want to delete the product category?',
                productCategoryDescription: 'Product category description',
                productCategoryHelpTextDescription: 'E.g. Category for salads',
                productCategoryHelpTextName: 'E.g. Salads',
                productCategoryDeleteRejected: 'Deleting product category rejected. Check associated products!',
                week: 'Week',
                viewMode: 'View',
                year: 'Year',
                Yesterday: 'Yesterday',
                groupBy: 'Group By',
                filterBy: 'Filter By',
                reportType: 'Report Type',
                custom: 'Custom',
                preDefined: 'Predefined',
                selected: 'Selected',
                metricsInfo: 'Metrics (sum, maximum, minimum...) are calculated for all selected dimensions/resources',
                dimensionsInfo: 'Metrics will be fetched for selected dimensions/resources',
                metrics: 'Metrics',
                controlsSoftwareVersion: 'Software version',
                currentSoftwareVersion: 'Current software version',
                availableSoftwareVersion: 'Available software version',
                invalidLifetime: 'Invalid lifetime value',
                unpaidSales: 'Unpaid sales',
                unpaidTransactions: 'Unpaid transactions',
                softwareUpdateConfirmation: 'Are you sure you want to update the software version to the selected device(s)?',
                softwareUpdateWarn:
                    'Updating the software version will send a command to the device(s) to fetch the update now or at the selected time. Selected device(s) will install the update next time they are online after the trigger. Installation may take time depending on the connection speed. Device(s) will reboot after the installation.',
                releaseVersion: 'Release versions',
                releaseDate: 'Release date',
                latestRelease: 'Latest release',
                currentVersion: 'Current version',
                softwareUpdateSuccess: 'The software version has been updated',
                prebooking: 'Pre-booking',
                prebookedProduct: 'Pre-booked product',
                prebookedProducts: 'Pre-booked products',
                activeProducts: 'Active products',
                startMaintenanceMode: 'Start maintenance mode',
                endMaintenanceMode: 'End maintenance mode',
                startMaintenanceConfirm: 'Are you sure you want to start maintenance mode for the cabinet?',
                endMaintenanceConfirm: 'Are you sure you want to end maintenance mode for the cabinet?',
                startMaintenanceSuccess: 'Maintenance mode started.',
                endMaintenanceSuccess: 'Maintenance mode ended.',
                noResults: 'No results',
                suppliers: 'Suppliers',
                supplier: 'Supplier',
                supplierDeleteRejected: 'Deleting supplier rejected. Check associated products!',
                supplierPromptMessage: 'Are you sure you want to delete the supplier?',
                fetchingSuppliers: 'Fetching suppliers',
                noSuppliers: 'No supplier record found',
                suppliersFetchError: 'Error in fetching suppliers',
                supplierName: 'Supplier name',
                supplierHelpTextName: 'E.g., a brand name',
                supplierDescription: 'Supplier description',
                supplierHelpTextDescription: "E.g., a brand's description",
                wishes: 'Wishes',
                checkNow: 'Check now',
                startTimeOn: 'Start time on',
                endTimeOn: 'End time on',
                signOutConfirmationModalTitle: 'Sign out confirmation',
                signOutConfirmationModalContent: 'Are you sure you want to sign out?',
                startDate: 'Start date',
                endDate: 'End date',
                scheduledDiscountApplyAllDays: 'Applying for all days',
                scheduledDiscountApplySelectedDays: 'Applying for selected days',
                discountCustomImageTitle: 'Discount image (optional)',
                discountCustomImageModalHeader: 'Image for discount',
                saveChanges: 'Save changes',
                updateTagsQualitySettingsTitle: 'Tag quality settings',
                updateTagsQualitySettingsMessage: 'Updated tag quality settings',
                IF3Cabinets: 'Model 3 Fridges',
                IF2Cabinets: 'Model 2 Fridges',
                IF3Freezers: 'Model 3 Freezers',
                encodingStations: 'Encoding Stations',
                if2: 'Model 2 Fridge',
                if3: 'Model 3 Fridge',
                if3Freezer: 'Model 3 Freezer',
                legalNotice: 'Legal notice',
                privacyPolicy: 'Privacy policy',
                scheduledDiscountDateMissingError: 'Both dates are needed if start or end date is set',
                scheduledDiscountDuplicateTimeError: 'The times cannot be the same if the dates are the same',
                now: 'Now',
                minimum: 'Minimum',
                maximum: 'Maximum',
                discardChangesConfirmationText: 'Are you sure you want to discard the changes?',
                exports: 'Exports',
                selectAllCabinets: 'Select all cabinets',
                expiryIntervals: 'Expiry intervals',
                timeBeforeExpiry: 'Time before expiry',
                apply: 'Apply',
                yes: 'Yes',
                no: 'No',
                productOnScreenBannerText: 'On-screen info banner text',
                productOnScreenBannerAdditionalInfo:
                    'Banner text is a single line text that appears below the product image and concisely conveys key features, health appeals or any additional information about the product',
                productOnScreenBannerExampleText: 'Lactose free',
                UI_IS_DOWN: 'Cabinet display is not working',
                READER_IS_DOWN: 'The RFID reader is down',
                ANTENOR_DEVICE_DISCONNECTED: 'Payment device is disconnected',
                EXPIRED_PRODUCT: 'Expired products in the cabinet',
                CLOUD_SERVICE_DOWN: 'Cloud service down',
                ANTENNA_DISCONNECTED: 'Antenna Disconnected',
                TAG_CHANGES: 'Too many bad tags in the cabinet',
                ANTENNA_DOWN: 'RFID antenna in cabinet is not working',
                READER_CONFIG_ERROR: 'RFID reader configuration failed and it is not working',
                activeAlarms: 'Active alarms',
                deviceStatus: 'Device status',
                lastMonitored: 'Last monitored',
                noService: 'Out of service',
                operational: 'Operational',
                offline: 'Offline',
                locationsSelectHelp: 'When a location is added, its child locations will also be added.',
                select: 'Select...',
                labels: 'Labels',
                productDetails: 'Product details',
                invalidProductName: 'Product name should be 1 to 125 characters long',
                standardPrice: 'Standard price',
                locationPrices: 'Location prices',
                addNew: 'Add new',
                productExpiry: 'Product Expiry',
                productLifeTime: 'Product life time',
                invalidTTL: 'The product lifetime should be an integer from 0 to 32767',
                productImage: 'Product image',
                uploadImage: 'Upload image',
                pleaseUploadImage: 'Please upload an image',
                productValidationError: 'Please fix the following error(s) before saving the product data',
                productSupplier: 'Product supplier',
                frozenProductLable: 'This is a frozen product',
                prebookingLabel: 'This is a pre-booking product',
                previous: 'Previous',
                bannerText: 'Banner text',
                packagingTypeValidationError: 'Please select a package type for the product',
                showOnlyFrozen: 'Show only frozen products',
                productClassification: 'Product classification',
                productClassLabel:
                    'Please select the appropriate class for the product. This enables Selfly Insights to provide more relevant product information.',
                productLabels: 'Product labels',
                productLabelsHelpText: 'Please add optional labels to your product. This will allow you to filter your products in product catalog.',
                newLabel: 'New Label',
                productClassValidationError: 'Please select a classification for your product from the options below',
                tagSensitivity: 'Tag quality sensitivity',
                tagSensitivitySetting: 'Tag quality sensitivity setting',
                configTagSensitivity: 'Configure tag quality sensitivity for the product',
                productLevelSensitivityInfo:
                    'The readability of an RFID tag attached to a product can be influenced by various factors, including packaging materials, metallic surfaces, moisture, and even the product contents themselves. These factors may sometimes cause intermittent tag signals, known as flickering. The cabinet processes such flickering based on its sensitivity configuration settings. If you are unsure which sensitivity level is appropriate for this product, you can skip this section.',
                errorSaving: 'Error while saving product data',
                successSaving: 'Product data saved successfully',
                basicDetail: 'Basics',
                additionalDetail: 'Additional details',
                packaging: 'Package Type',
                classification: 'Classification',
                selectOrtype: 'Select or type...',
                max25Chars: 'max 25 characters',
                noVatForProductCreation: 'Please create a VAT category before adding new products',
                lifeTimeExplanation: 'How many days this product is valid for sale. (0 days) means until the end of the current day',
                selflyEncode: 'Selfly Encode',
                userRestrict: 'Recognized consumers only',
                userRestrictTitle: 'Eligible consumers',
                userNotRestrict: 'All consumers',
                userRestrictHelperText:
                    'Recognized consumers option means the discount will only be applied to consumers that are already registered for discount eligibility!',
                searchCabinets: 'Search cabinets',
                selectAll: 'Select all',
                monday: 'Monday',
                tuesday: 'Tuesday',
                wednesday: 'Wednesday',
                thursday: 'Thursday',
                friday: 'Friday',
                saturday: 'Saturday',
                sunday: 'Sunday',
                fetchingLoader: 'Fetching. Please wait...',
                category: 'Category',
                okay: 'Ok',
                noLocation: 'No location',
                copy: 'Copy',
                pleaseConfirm: 'Please confirm',
                saveQuery: 'Save this query',
                saveQueryPlaceholder: 'e.g. "All Item Sold Count"',
                saveQuerySuccess: 'Query is saved.',
                yourSavedQueries: 'Your saved queries',
                selectQuery: 'Select query',
                noQuery: 'There is no saved query yet',
                deleteAdConfirm: 'Are you sure you want to delete this ad?',
                dataSaved: 'Data saved successfully',
            },
            refill: {
                refillRuleApplyRuleDropdownText: 'Apply this rule also to other cabinets',
                refillPlanGenerate: 'Generate refill plan',
                refillPlanEditPlan: 'Edit plan',
                refillAmount: 'Refill amount',
                refillRuleTargetAmount: 'Target inventory level',
                refillRuleTargetAmountShort: 'Target',
                refillPlanActivePlans: 'Active plans',
                refillPlanCompletedPlans: 'Completed plans',
                refillNote: 'Refill note',
                refillNoteHelp: 'Product refill note (max 125 characters)',
                refillPDFText:
                    'Below is the refill plan for the cabinet. Please make sure to mark the refill plan complete in refill application after the you have finished the refill.',
                refillPDFSummaryText: 'Below is the summary of refill plans with products to add and remove for all the selected cabinets.',
                refillSummary: 'Refill summary',
                addItems: 'Add products',
                removeItems: 'Remove products',
                inventoryChange: 'Inventory change',
                planogramPDFText: 'Planogram for the cabinet refill strategy.',
                planogramImagePDFText: 'Custom image for the cabinet refill strategy.',
                refillSchemas: 'Refill templates',
                refillPlans: 'Refill plans',
                refillSchemaTitle:
                    'Set the target and minimum inventory amounts as a template for selected cabinets. These templates will be used for generating the Refill plans',
                refillPlansTitle: 'View all active plans. You can edit or remove existing active plans, or add new refill plans as needed.',
                refillSchemaSubText: 'Please select a cabinet to create or update the refill template',
                noRefillSchema: 'No refill template has been defined for this cabinet yet',
                createRefillSchema: 'Create a new refill template',
                noDevice: 'No device found',
                schemaSaved: 'Refill template saved successfully',
                deleteSchemaConfirmation: 'Are you sure you want to delete the refill template?',
                targetAmount: 'Target amount',
                targetAmountHelpText: 'The target inventory quantity for each product in the cabinet after the refill.',
                minimumThreshold: 'Minimum threshold',
                minimumThresholdHelpText:
                    'Specify the minimum inventory level for each product in the cabinet. If the quantity falls below the defined threshold, you will receive an alarm notification. Setting the threshold to 0 will disable notifications.',
                editNote: 'Edit note',
                refillNoteTitle: 'Write a refill note for the product.',
                refillNoteCharacters: 'characters',
                noRefillPlan:
                    'There are currently no active refill plans. Please add new refill plans for your cabinets or create refill template for the cabinets first.',
                planSaved: 'Refill plan saved successfully',
                deleteConfirmation: 'Are you sure you want to delete the selected refill plan data ?',
                editPlan: 'Edit plan',
                currentInventory: 'Current inventory',
                lastSevenDaySales: 'Last seven days sales',
                inventory: 'Inventory',
                planogram: 'Planogram',
                copyRefillSchema: 'Copy template to other cabinets',
                generatingPlans: 'Generating refill plans. Please wait...',
                generatePlans: 'Generate refill plans',
                complete: 'complete',
                selectCabinetsToAddPlans: 'Please select cabinets to generate refill plans.',
                reviewSuggestion:
                    'Below are the suggested refill plans for the selected cabinets. Please review, edit if necessary, and save them to activate these plans',
                noSchema: 'No cabinets currently have a refill template. Please add a refill template to your cabinets to generate refill plans.',
                searchProducts: 'Search products',
                maxAllowedInventory: 'Max allowed inventory is 200.',
                savingData: 'Saving data. Please wait',
                errorSavingData: 'Error saving the data.',
                refillPlanSaved: 'Refill plans generated successfully.',
                exportPDFHelp: 'Export selected plans to PDF file.',
                exportExcelHelp: 'Export selected plans to excel file.',
                addPlanHelp: 'Add new refill plans.',
                deletePlanHelp: 'Delete selected refill plans.',
                generatePlansHelp: 'Generate refill plans for the selected cabinets.',
                planogramSaved: 'Planogram saved successfully.',
                schemaCopied: 'The refill template was successfully synchronized with the other cabinets.',
                allProducts: 'All products',
                schemaProducts: 'Refill template products',
                deletePlanogram: 'Are you sure you want to delete the planogram?',
                dragInPlanogram: 'Please drag and drop products onto the designated cabinet shelves, or drag the cabinet slot over the product.',
                noPlanogramInSchema: 'A planogram has not been defined for the cabinet yet',
                noPlanogramInPlan: 'No planogram data is available for the cabinet. Please create a planogram for the cabinet in the refill template tab first',
                copyPlanogram: 'Copy refill planogram along with the refill template',
                planogramImageInfo: 'In addition to the planogram, you can also add custom planogram image.',
                addPlanogramImage: 'Add planogram image',
                editPlanogramImage: 'Edit planogram image',
                numberOfShelves: 'Number of shelves',
                addNewRefillPlan: 'Add new plans',
                qrcodeTitle: 'A QR code will be generated for the selected cabinet to initiate the refill process',
                qrcodeSubText: 'Please select a cabinet where you would like to initiate the refill process',
                qrcodeScan: 'Please scan the QR code to refill, or click the button to open the refill application',
                qrcodeOpenApplication: 'Open refill application',
                createRefillPlanogram: 'Create refill planogram',
                editPlanogram:'Edit planogram'
                
            },
            navigation: {
                advertisements: 'Advertisements',
                analytics: 'Analytics',
                controlPanel: 'Control Panel',
                deviceManagement: 'Device Management',
                discounts: 'Discounts',
                fetching: 'Fetching',
                group: 'Group',
                inventory: 'Cabinet inventory',
                language: 'Language',
                productCatalog: 'Product catalog',
                productCategories: 'Product categories',
                reports: 'Reports',
                settings: 'User settings',
                signOut: 'Sign out',
                title: 'Selfly Cloud Portal',
                vatCategories: 'VAT Categories',
                community: 'Selfly Community',
                refillManagement: 'Refill management',
                automations: 'Automations',
                mySelfly: 'MySelfly settings',
                automationInfo: 'Manage automated actions (BETA)',
            },
            reports: {
                alreadyRefund: 'was refund',
                amount: 'Amount',
                average: 'Average',
                barcode: 'Barcode',
                confirmIssueRefundButton: 'Confirm refund now',
                current: 'Current',
                export: 'Export',
                fetching: 'Fetching',
                fetchingError: 'An error occurred while fetching',
                image: 'Image',
                issueRefundButton: 'R E F U N D',
                maximum: 'Maximum',
                minimum: 'Minimum',
                name: 'Name',
                noData: 'No data',
                note: 'Note',
                order: 'Transaction',
                paymentWasDoneByCreditCard: 'Payment was done by credit card',
                price: 'Price',
                ProductReport: 'Product Report',
                quantity: 'Quantity',
                'Refund impossible': 'Payment was done by credit card. Contact the credit card terminal issuer for the refund operation.',
                refundFormTitle: 'Refund',
                refundReason: 'Reason for refund',
                refundSuccess: 'Sum {{sum}} was refund successfully',
                refundTotal: 'Refund sum',
                replenishments: 'Refills',
                replenishmentsTitle: 'Refill Report',
                SaleTransaction: 'Sales Transaction',
                summary: 'Summary',
                SummaryReport: 'Sales Summary',
                temperature: 'Temperature',
                temperatureTitle: 'Temperature Report',
                temperatureValueNotification: 'Temperature values are the hourly average values of cabinet temperature.',
                time: 'Time',
                total: 'Total',
                transactions: 'Transactions',
                user: 'User',
                paidTransactions: 'Paid transactions',
                unpaidTransactions: 'Unpaid transactions',
                partiallyPaid: 'Partially paid',
                failedTransactions: 'Failed transactions',
                amountOwed: 'Amount owed',
                receiptLinkText: 'Get receipt from here',
                noPurchase: 'No purchase',
                other: 'Other',
                userId: 'User ID:',
            },
            settings: {
                block_cabinet_when_product_expired: 'Block cabinet for expired products',
                criticalExpiryLimitHours: 'Hours before critical expiry alert',
                language: 'Language',
                logo: 'Company logo',
                mediumExpiryLimitHours: 'Hours before medium expiry alert',
                payment_method_logo: 'Payment method logo',
                footer_instruction_logo: 'Footer instruction image',
                readUserMemory: 'Fetch product expiry data',
                ui_skus_sort_by: 'Product sort order in cabinet display',
                timeZone: 'Time zone',
                country: 'Country',
                city: 'City',
                tagSettings: 'Tag settings',
                themeAndLogos: 'Theme & logos',
                advancedSettings: 'Advanced settings',
                themingInfoText: 'You can customize the theme of your cabinet screen',
                cabinetLogos: 'Cabinet logos',
                cabinetLogoInfoText: 'Upload logos that appear on the cabinet screen',
                logoHelperText: 'Please use 180px X 180px image',
                settingsCopyInfoHeader: 'Select cabinets you would like to copy the settings to',
                settingsCopyButton: 'Copy settings to selected cabinets',
                settingsCopySuccessModalTitle: 'Settings copying',
                settingsCopySuccessModalContent: 'Settings copied to {{cabinetsAmount}} cabinet(s) succesfully',
                theming: 'Theming',
                adminSettings: 'Admin settings',
                settingsCopyErrorModalTitle: 'Settings copying error',
                key: 'Setting',
                value: 'Value',
                sleepScheduling: 'Scheduled standby',
                sensitivityLevel: 'Sensitivity level',
                sensitivity_0:
                    'The cabinet disregards tag flickering and consistently marks all tags as valid, which may occasionally result in mischarging of items.',
                sensitivity_20: 'The cabinet is tolerant of up to three bad readings for a tag within 50 scans and will still mark the tag as valid.',
                sensitivity_40: 'The cabinet is tolerant of up to two bad readings for a tag within 60 scans and will still mark the tag as valid.',
                sensitivity_60:
                    'If a tag flickers frequently but maintains stability over an extended period, the cabinet tolerates this and reads the tag as valid.',
                sensitivity_80: 'If a tag flickers momentarily but remains stable for an extended period, it will be considered as valid tag.',
                sensitivity_100:
                    'The cabinet is highly intolerant to tag flickering. Even a single bad reading can cause a tag to be marked as invalid for an extended period, which may result in potential losses.',
                sensitivityInfo:
                    "Tag quality sensitivity of a cabinet refers to how effectively the cabinet handles inconsistent tag readings or tag flickering. Please be mindful of the sensitivity level you choose, as it can impact the cabinet's performance. If unsure which level is appropriate, it is recommended to leave the setting at the default level of 3.",
            },
            signIn: {
                signInButton: 'Sign in',
                signInExpiredError: 'Your session has been expired. Sign in again',
                signInMessage: 'You need to be signed in to continue. Click the button below to sign in.',
                noOrganizationsError: 'No organizations linked to this username. Try another username.',
            },
            translations: {
                add: 'Add',
                closed: 'Closed',
                delete: 'Delete',
                device: 'Device',
                edit: 'Edit',
                off: 'OFF',
                offline: 'Offline',
                on: 'ON',
                online: 'Online',
                opened: 'Open',
                save: 'Save',
                seeDetails: 'Details',
                settings: 'Settings',
                unknown: 'Unknown',
            },
            consumerApp: {
                enableConsumerFeedback: 'Enable user feedback on MySelfly',
                enablePurchaseReceipts: 'Enable e-receipts on MySelfly',
                enableSelflyTrademarkForReceipts: 'Allow Selfy Store footer in e-receipts',
                enableSelflyTrademarkForReceiptsHelpText: 'Enabling this will add Selfly Store logo and details on e-receipt PDF!',
                organizationFormHelpText: 'Please provide the e-receipt issuer details.',
                organizationFormValidationHelpText: "* Required fields for e-receipt are Issuer organization's name, business id, address and country.",
                organizationName: 'Organization name',
                organizationLogo: 'Organization logo',
                businessId: 'Business ID',
                city: 'City',
                email: 'Email',
                website: 'Website',
                postalCode: 'Postal code',
                street: 'Street address',
                phoneNumber: 'Phone number',
                addressHeader: '* Address information',
                contactDetails: 'Contact details',
                organization: '* Issuer Organization',
                organizationSettingsUpdatedTitle: 'Organization settings update',
                organizationSettingsUpdatedContent: 'Organization settings updated successfully',
                organizationSettingsMissingFields: 'Mandatory fields for e-receipt missing. Please fill before saving.',
                organizationSettingsMissingFieldsTitle: 'Form validation error',
            },
            softwareVersions: {
                updateNotificationTitle: 'Software version update',
                updateNotificationContent: 'Software version(s) updated successfully',
                selectAVersionPlaceholder: 'Select a version',
                searchDevices: 'Search devices',
                updateSelected: 'Update selected',
                softwareVersions: 'Software versions',
                softwareVersion: 'Software version',
                notSupportedDeviceNote: 'Version {{selectedVersion.version}} is not supported on this device',
                userNotificationContent: 'A new software version has been released for your devices. Please update your devices to get latest features.',
                userNotificationTitle: 'A new software version released',
                notificationBell_newSoftwareVersions: 'Updates available for your devices',
                manageVersions: 'Manage device software versions',
                filterLocationsPlaceholder: 'Filter by locations',
                allVersionNumbers: 'All releases',
                noVersionsForUpdating: 'No versions for updating',
                scheduledUpdateNote: 'Version {{version}} is scheduled to update at {{scheduled}}',
                scheduleRemoveNotificationTitle: 'Software version scheduling',
                scheduleRemoveNotificationContent: "Software version's scheduled update removed successfully",
                scheduleRemoveNotificationModalContent: 'Are you sure you want to remove scheduled update for this device?',
            },
            header: {
                searchByOrganization: 'Search by organization',
                pageLanguage: 'Language',
                configureMySelflyAndEreceipts: 'Configure MySelfly settings',
                remindMeLater: 'Remind me later',
                updateNow: 'Update now',
            },
            notifications: {
                noNewNotifications: 'No new notifications',
            },
            cabinetSettings: {
                cabinetTheme: 'Cabinet theme',
                editAndSaveTheme: 'Edit and save theme',
                colorPicker: 'Color picker',
                customTheme: 'Custom theme',
                themeSettings: 'Theme settings',
                primary: 'Primary',
                negative: 'Negative',
                attention: 'Attention',
                positive: 'Positive',
                background: 'Background',
                dark: 'Dark',
                light: 'Light',
                white: 'White',
                default: 'Default',
                negativeLight: 'Negative light',
                primaryColor: 'Primary color',
                positiveColors: 'Positive colors',
                negativeColors: 'Negative colors',
                secondaryColors: 'Secondary colors',
                attentionColors: 'Attention colors',
                screenBackground: 'Screen background',
                borderColors: 'Borders - Shadows',
                shadow: 'Shadow',
                border: 'Border',
                fontColors: 'Font colors',
                footerTextPlaceholder: 'Footer text',
                bundleDiscounts: 'Combine and save',
                expiringText: 'Expiring products. Please check expiry date before purchasing',
                expiredText: 'Expired products. Please check expiry date before purchasing',
                cabinetName: 'Cabinet name',
                productName: 'Product name',
                confirmationModalHeader: 'Are you sure you want to save the customized theme?',
                confirmationModalContent:
                    'Customizing the theme will affect the visual look of your cabinet screen. Please make sure that the visual quality of the screen is not compromised with the changes.',
                defaultRevertModalMessage: 'Are you sure you want to change the cabinet theme to default ?',
                backgroundColor: 'Background color',
                backgroundImage: 'Background image',
                footerBackground: 'Footer background',
                cardsBackground: 'Card background',
                screenImage: "Image for cabinet screen's background",
                uploadImage: 'Upload image',
                editImage: 'Edit image',
                transactionComplete: 'Transaction complete',
                customPostPurchaseImage: 'Please upload an image for the custom post purchase screen',
                postPurchaseScreen: 'Post purchase screen',
                custom: 'custom',
                postPurchaseText: 'Post purchase display text',
            },
            automations: {
                transactionReports: 'Transaction reports',
                refillReports: 'Refill reports',
                notifications: 'Notifications',
                refillPlan: 'Refill plans',
                days: 'Days',
                months: 'Months',
                weeks: 'Weeks',
                configuration: 'Configuration',
                transactionReportTitle:
                    'Transaction reports for the selected cabinets will be automatically generated and sent to the email addresses provided below',
                refillReportTitle: 'Refill reports for the selected cabinets will be automatically generated and sent to the email addresses provided below',
                noSalesNotificationTitle:
                    'Notifications will be sent automatically for the email addresses provided below in case of no sales in the defined intervals',
                frequencyTitle: 'How often do you want the report to be generated ?',
                emailNotificationTitle: 'Please add the email addresses below where you would like the report or notification to be sent',
                refillPlanGenerationTitle: 'Refill plans will be automatically generated for the selected cabinets according to the provided schedule',
                addNewEmail: 'Add new e-mail',
                noSalesBanner: 'You will receive an email notification if the selected cabinets do not make any sales during the configured time period',
                noSaleTitle: 'Please specify the time duration (in hours) of no sales in the selected cabinets before a notification is sent',
                minMaxTime: 'Min is 2 and max is 23 hours',
                timeRangeTitle: 'Please specify the time range for the cabines, during which no-sales situation will be checked',
                startTime: 'Start time',
                endTime: 'End time',
                refillPlanTitle:
                    'The refill plan for the selected cabinets will be automatically generated. Please schedule below the specific time when you would like the refill plan to be created.',
                refillPlanTime: 'Please select the time for the refill plan to be generated',
                everyDay: 'Every day',
                everyWeek: 'Every week',
                everyMonth: 'Every month',
                selectDays: 'Select days',
                automationSaved: 'Automation settings saved successfully',
                automationDeleted: 'Automation settings deleted successfully',
            },
            sleepScheduling: {
                timeRangeTitle: 'Please specify the time range during which this cabinet will go into standby mode.',
                startTime: 'Start time',
                endTime: 'End time',
                selectDays: 'Select days',
                duration: 'Duration (Hours)',
            },
        },
        fi: {
            dateRangeFilter: {
                lastMonth: '30 viimeistä päivää',
                lastTwoWeek: '14 viimeistä päivää',
                lastWeek: '7 viimeistä päivää',
                selectDateRangeMsg: 'Valittu aikaväli',
                today: 'tänään',
                yesterday: 'eilen',
            },
            main: {
                activateSuccess: 'Tuotteen aktivointi onnistui',
                activationDate: 'Aktivointipäivämäärä',
                active: 'Aktiivinen',
                addAdvertisement: 'Lisää uusi mainos',
                addDiscountRule: 'Lisää alennussääntö',
                addDiscountStop: 'Lisää vanhenemisväli',
                addNewLocation: 'Valitse uusi sijainti',
                addProduct: 'Lisää uusi tuote',
                addRegion: 'Lisää sijainti',
                addRegionPrice: 'Lisää sijainnin hinta',
                addScheduledDiscount: 'Lisää ajastettu alennus',
                addBundleDiscount: 'Lisää yhteisalennus',
                bundledDiscounts: 'Yhteisalennukset',
                bundleItemsLengthExceeded: 'Valittujen tuotteiden yläraja (3) on ylitetty. Poista valittuja tuotteita, jotta voit jatkaa.',
                bundleMaxProductLimitInfo: 'Valitse enintään kolme (3) tuotetta tai tuotekategoriaa',
                bundleFixedAmountExceededOriginal: 'Yhteisalennuksen kiinteä hinta ylittää alkuperäisen hinnan. Tarkista valitut tuotteet.',
                bundleFixedAmountNotAllowedToBeZero: 'Yhteisalennuksen kiinteän hinnan on oltava yli 0',
                bundlePercentageNotAllowedToBeFull: 'Yhteisalennuksen prosenttialennuksen tulee olla alle 100',
                bundlePercentageNotAllowedToBeZero: 'Yhteisalennuksen prosenttialennuksen tulee olla yli 0',
                bundleItemDiscountPriceAmountExceededOriginal: 'Uusi alennushinta ylittää alkuperäisen hinnan. Tarkista uusi hinta uudestaan.',
                bundleItemPriceMessage: 'Jokainen hinnoittelusääntö koskee vain yhtä tuotetta, vaikka monta kappaletta samoja tuotteita on valittuna',
                bundleItemFixedAmountExceededOriginal: 'Tuotteen kiinteä hinta ylittää alkuperäisen tuotehinnan. Tarkista kiinteä hinta uudestaan.',
                createDiscountRule: 'Luo alennussääntö',
                deviceType: 'Laitetyyppi',
                discountUpdateTitle: 'Alennuksen päivitys',
                discountCreateTitle: 'Alennuksen luonti',
                discountDeleteTitle: 'Alennuksen poisto',
                discountDeleteContent: 'Alennus on poistettu',
                discountStatusUpdated: 'Alennuksen tila on päivitetty',
                discountUpdated: 'Alennus on päivitetty',
                discountCreated: 'Alennus on luotu',
                discountSettings: 'Alennusasetukset',
                discountImage: 'Valinnainen alennuksen kuva',
                editDiscountStop: 'Muokkaa vanhenemisväliä',
                editBundleConfiguration: 'Muokkaa yhteisalennuksen määrityksiä',
                editExpiryConfiguration: 'Muokkaa vanhenemisalennuksen määritystä',
                encodingStation: 'Koodausasema',
                everyDay: 'Päivittäin',
                scheduledDiscountDateInfoLabel: 'Jätä alkamis- ja päättymispäivä tyhjiksi, jos haluat tehdä alennuksesta pysyvän',
                starts: 'Alkaa',
                ends: 'Päättyy',
                error: 'Virhe',
                errorRetiringLocationMsg: 'Sijainnin poisto epäonnistui. Poista ensin liitetyt laitteet, tuotteiden hinnat ja mainokset.',
                fetchingDiscountRules: 'Haetaan alennussääntöjä',
                free: 'Ilmainen',
                missingImage: 'Mainoksen kuva puuttuu',
                next: 'Seuraava',
                back: 'Takaisin',
                noDiscountRules: 'Alennussääntöjä ei löytynyt',
                scheduledDiscountsAutomaticDeactivationInfo:
                    'Alennukset poistetaan automaattisesti käytöstä, jos niiden aikaväli on ylitetty. Jos haluat aktivoida ne, muokkaa aikaväliä.',
                scheduledDiscounts: 'Ajastetut alennukset',
                schedulingStartHourIsOverEndHour: 'Aloitusaika ei voi olla lopetusajan jälkeen',
                schedulingEndHourIsUnderStartHour: 'Lopetusaika ei voi olla ennen aloitusaikaa',
                selectDiscountRuleProductCategories: 'Valitse tuotekategoriat, joihin alennussääntö vaikuttaa',
                selectBundleProductsAmount: 'Valitse summa seuraavalle: ',
                selectedBundleProductCategories: 'Valitut tuotekategoriat',
                setDiscountDays: 'Valitse alennuspäivät',
                setBundleDiscount: 'Aseta joko prosenttialennussumma tai kiinteä hinta yhteisalennukselle',
                setBundleItemDiscount: 'Aseta jokin näistä yhdelle tuotteelle: prosenttialennussumma, kiinteä hinta tai ilmainen',
                setBundleProducts: 'Määritä yhteisalennuksen tuotteet tai kategoriat',
                setAmountMoreThanOne: 'Jos yhteisalennuksessa on vain yksi tuote, määrän on oltava yli yksi',
                setBundleDiscountType: 'Aseta alennuksen tyyppi ja summa',
                sureActivateDiscountRule: 'Haluatko varmasti ottaa alennussäännön käyttöön?',
                sureDeactivateDiscountRule: 'Haluatko varmasti poistaa alennussäännön käytöstä?',
                sureDeleteDiscountRule: 'Haluatko varmasti poistaa alennussäännön?',
                timeRange: 'Aikaväli',
                editExpiryDiscountRule: 'Muokkaa alennussääntöä',
                editScheduledDiscountRule: 'Muokkaa ajastettua alennussääntöä',
                editBundleDiscountRule: 'Muokkaa yhteisalennussääntöä',
                releaseNotes: 'Julkaisutiedot',
                seeMore: 'Näytä lisää',
                scheduledDiscountTimeRangeLabel: 'Aktiivinen aika',
                scheduledDiscountDateRangeLabel: 'Alennuksen aikaväli',
                weekDayAbrvMonday: 'Ma',
                weekDayAbrvTuesday: 'Ti',
                weekDayAbrvWednesday: 'Ke',
                weekDayAbrvThursday: 'To',
                weekDayAbrvFriday: 'Pe',
                weekDayAbrvSaturday: 'La',
                weekDayAbrvSunday: 'Su',
                recurrence: 'Toistuvuus',
                addScheduledDiscountStop: 'Lisää ajastettu alennus',
                editScheduledDiscountStop: 'Muokkaa ajastettua alennusta',
                pieces: 'kpl',
                expiryDiscountNavLabel: 'Vanhentuminen',
                scheduledDiscountNavLabel: 'Ajastetut',
                bundleDiscountNavLabel: 'Yhteisalennus',
                amount: 'Määrä',
                scheduling: 'Ajastus',
                pricing: 'Hinnoittelu',
                percentageBundleDiscountInputPlaceholder: 'Yhteisalennuksen prosenttialennus',
                fixedPriceBundleDiscountInputPlaceholder: 'Yhteisalennuksen kiinteä hinta',
                applyBundleLevelLabel: 'Käytä yhteisalennustasoa',
                applyItemLevelLabel: 'Käytä tuotetasoa',
                productFixedPrice: 'Tuotteen kiinteä hinta',
                percentageDiscount: 'Prosenttialennus',
                fixedPriceDiscount: 'Kiinteä hinta',
                freeDiscount: 'Ilmainen',
                advertisementsFetchError: 'Mainosten hakemisessa tapahtui virhe. Varmista, että mainosesto on pois päältä',
                advertisementsTitle: 'Mainokset',
                all: 'Kaikki',
                'All time': 'Koko aika',
                allProducts: 'Kaikki tuotteet ilman ryhmittelyä',
                allLocations: 'Kaikki sijainnit',
                allCabinets: 'Kaikki kaapit',
                antennaDown: 'Jotkin RFID-antennit eivät ole toiminnassa',
                antennaError: 'Virhe kaapin antenneissa',
                antennaNoInfo: 'Kaapin antenneista ei ole saatavilla tietoja',
                antennaOk: 'Kaikki antennit ovat toiminnassa',
                antennaPartial: 'Jotkin antennit eivät ole käytettävissä',
                archiveSuccess: 'Tuotteen arkistointi onnistui',
                averageItems: 'Tuotteiden keskimäärä tapahtumaa kohden',
                averageSales: 'Myynnin keskiarvo tapahtumaa kohden',
                barcode: 'Viivakoodi',
                barcodeType: 'Viivakoodityyppi',
                barcodeCannotBeChanged: 'Viivakoodia ei voi muuttaa',
                barcodeTypeCannotBeChanged: 'Viivakoodityyppiä ei voi muuttaa',
                barcodeHelpText12: 'Kirjoita viivakoodin 12 ensimmäistä numeroa, viimeinen numero lisätään automaattisesti',
                barcodeHelpText11: 'Kirjoita viivakoodin 11 ensimmäistä numeroa, viimeinen numero lisätään automaattisesti',
                barcodeHelpText7: 'Kirjoita viivakoodin 7 ensimmäistä numeroa, viimeinen numero lisätään automaattisesti',
                beforeExpiry: 'ennen vanhentumista',
                cabinetDown: 'Kaappi ei ole toiminnassa',
                cabinetIsEmpty: 'Kaappi on tyhjä',
                deviceOffline: 'Laite on offline-tilassa',
                deviceOnline: 'Laite on online-tilassa',
                cabinets: 'Kaapit',
                cabinet: 'Kaappi',
                cabinetSettings: 'Kaapin asetukset',
                cabinetTheming: 'Kaapin teema',
                cancel: 'Peruuta',
                cancelledSales: 'Peruutetut myynnit',
                cancelledTransactions: 'Peruutetut ostotapahtumat',
                categories: 'Kategoriat',
                change: 'Muuta',
                clear: 'Tyhjennä',
                close: 'Sulje',
                clickEditButtonRegionalPrices: 'Voit muokata sijaintihintoja klikkaamalla Muokkaa-tuotepainiketta.',
                clickEditLocationPrices: 'Paikalliset hinnat, laajenna klikkaamalla tuotteen hintaa.',
                clickToExpandLocationPrices: 'Paikalliset hinnat, laajenna klikkaamalla tuotteen hintaa.',
                closeDoor: 'Sulje ovi',
                rebootDevice: 'Käynnistä laite uudelleen',
                commandSentSuccessFully: 'Komennon lähettäminen onnistui',
                companyLogo: 'Yrityslogo',
                confirm: 'Vahvista',
                confirmDelete: 'Haluatko varmasti poistaa tuotteen? Tätä toimintoa ei voi peruuttaa!',
                confirmDisable: 'Haluatko varmasti arkistoida tuotteen?',
                confirmEnable: 'Haluatko varmasti tehdä tuotteesta aktiivisen?',
                consumerResponses: 'Käyttäjäpalaute',
                controlPanelTitle: 'Ohjauspaneeli',
                controls_acceptablePercentageOfBadTags: 'Virheellisten tunnisteiden hyväksytty prosenttiosuus ennen kaapin poistamista käytöstä',
                controls_markTagsGoodQuality: 'Merkitse kaikki tunnisteet hyvälaatuisiksi',
                controls_markTagsGoodQuality_btn: 'Merkitse tunnisteet hyvälaatuisiksi',
                controls_clearTagHistory: 'Tyhjennä tunnistehistoria',
                controls_clearRemoveReplenishmentData: 'Tyhjennä täyttötiedot',
                controls_companyAndPaymentLogo: 'Yrityksen ja maksutavan logo',
                controls_detectTagQuality: 'Tunnista tunnisteiden laatu',
                controls_door: 'Oven ohjaus',
                controls_device: 'Laiteohjaus',
                controls_chargeBadTags: 'Laskuta virheelliset tunnisteet',
                controls_refill: 'Täydennys',
                controls_tagQuality: 'Tunnisteiden asetukset',
                controls_tagQualityData: 'Tunnisteiden laatutiedot',
                controls_replenishmentData: 'Tunnisteiden täyttötiedot',
                controls_soldTags: 'Myydyt tunnisteet',
                controls_tagHistory: 'Tunnisteiden historia',
                controls_markAllTagsReplenished: 'Merkitse kaikki tunnisteet täytetyiksi',
                controls_markAllTagsUnsold: 'Merkitse kaikki tunnisteet myymättömiksi',
                controls_markAlTagsReplenished_btn: 'Merkitse tunnisteet täytetyiksi',
                controls_markAllTagsUnsold_btn: 'Merkitse tunnisteet myymättömiksi',
                controls_chargeSoldTags: 'Laskuta aikaisemmin myydyt tunnisteet',
                controls_chargeUnreplenishedTags: 'Laskuta tunnisteet millä ei ole täyttötietoa',
                controls_tagsWarn:
                    'Tunnisteiden historian poistaminen poistaa kaiken tiedon tunnisteista. Tunnisteet tulee merkitä täytetyiksi tämän toiminnon jälkeen.',
                create: 'Luo',
                createAdTitle: 'Uusi mainos',
                createdAd: 'Mainos luotu',
                createExpiryDiscountRule: 'Luo vanhentumisen alennussääntö',
                createScheduledDiscountRule: 'Luo ajastettu alennussääntö',
                createBundleDiscountRule: 'Luo alennussääntö',
                createNewVatCategory: 'Uusi ALV-kategoria',
                createProductTitle: 'Uusi tuote',
                creatingAd: 'Luodaan mainosta',
                creatingProduct: 'Luodaan tuotetta',
                createNewProductCategory: 'Luo uusi tuotekategoria',
                createNewSupplier: 'Lisää uusi toimittaja',
                currency: 'Valuutta',
                customQuery: 'Mukautettu haku',
                dashboard: 'Yhteenveto',
                dateRange: 'Ajanjakso',
                dataAlredyFetchedTitle: 'Tietojen haku valmis',
                dataAlreadyFetchedDescription: 'Valitse toisenlaiset asetukset',
                day: 'Päivä',
                days: 'Päivää',
                deactivationDate: 'Käytöstäpoiston päivä',
                default: 'Oletusarvo',
                delete: 'Poista',
                deleteError: 'Virhe: tuotetta ei voitu poistaa ',
                deleteSuccess: 'Tuote poistettu',
                description: 'Kuvaus',
                deviceManagement: 'Laitehallinta',
                digitwinOffline: 'Laiten digitaalinen kaksonen on offline-tilassa',
                digitwinOnline: 'Laiten digitaalinen kaksonen on online-tilassa',
                dimensions: 'Mitat',
                disable: 'Arkistoi',
                discountAmount: 'Alennuksen määrä',
                discountPrice: 'Alennettu hinta',
                discountRuleAffects: 'Alennussääntö vaikuttaa',
                discountRuleName: 'Alennussäännön nimi',
                discountRuleStops: 'Vanhenemisvälit',
                discounts: 'Alennukset',
                discountSalesData: 'Alennusmyyntitiedot',
                discountSalesAmount: 'Alennusmyynnin summa',
                discountTimeBeforeExpiryExists: 'Aiempi vanhenemisväli on jo olemassa',
                discountVsNormalSales: 'Alennus vs. tavallinen myynti',
                dismiss: 'Ohita',
                doesNotExist: 'Ei ole olemassa',
                doorCloseConfirm: 'Haluatko varmasti sulkea oven?',
                doorCloseSuccess: 'Oven lukitseminen onnistui',
                doorOpenConfirm: 'Haluatko varmasti avata oven?',
                doorOpenReason: 'Miksi ovi avataan?',
                doorOpenSuccess: 'Ovi avattu',
                rebootDeviceSuccess: 'Laite käynnistetty uudelleen',
                deviceRebootConfirm: 'Haluatko varmasti käynnistää laitteen uudelleen?',
                edit: 'Muokkaa',
                editCompanyLogo: 'Muokkaa yrityslogoa',
                editDiscountRule: 'Muokkaa alennussääntöä',
                editPaymentMethodLogo: 'Muokkaa maksutavan logoa',
                editVatCategoryModalTitle: 'Muokkaa ALV-kategoriaa',
                editProductCategoryModalTitle: 'Muokkaa tuotekategoriaa',
                editSupplierModalTitle: 'Muokkaa toimittajaa',
                enable: 'Tee aktiiviseksi',
                error_401: 'Ei todennettu',
                error_403: 'Ei todennettu komentojen suorittamiseksi',
                error_500: 'Odottamaton palvelinvirhe',
                errorCreatingAd: 'Virhe mainoksen luomisessa',
                errorCreatingProduct: 'Virhe tuotteen luomisessa',
                errorFetchingCabinetStatus: 'Virhe kaapin tilan hakemisessa',
                errorFetchingInventory: 'Virhe kaapin inventaarion hakemisessa',
                errorFetchingReport: 'Virhe raporttien hakemisessa',
                errorFetchingSettings: 'Virhe asetusten hakemisessa',
                errorUpdatingProduct: 'Virhe tuotteen päivittämisessä',
                expandToSeeBadTags: 'Laajenna luettelo, jotta näet virheellisiksi tunnistetut tunnisteet. Virheelliset tunnisteet on poistettava kaapista.',
                expandToSeeSoldItems: 'Laajenna luettelo, jotta näet tuotteet, jotka on jo myyty mutta näkyvät jälleen kaapissa.',
                expandToSeeReplenishedOutOfInventory: 'Laajenna luettelo, jotta näet tuotteet, jotka on asetettu kaappiin täydennysprosessin ulkopuolella',
                expired: 'Vanhentunut',
                expiredCount: 'Vanhentuneiden määrä',
                expiredProductsCount: 'Vanhentuneiden tuotteiden lukumäärä',
                expiredSum: 'Vanhentuneiden summa',
                expiring: 'Vanhentuu',
                expiryDate: 'Viimeinen myyntipäivä',
                exportExcel: 'Vie Excel',
                fetchAdvertisements: 'Haetaan mainoksia',
                fetchingCabinets: 'Haetaan kaappeja',
                fetchingCabinetsError: 'Kaappien hakemisessa tapahtui virhe',
                fetchingCabinetStatus: 'Haetaan kaapin tilaa',
                fetchingError: 'Hakemisessa tapahtui virhe',
                fetchingInventory: 'Haetaan kaapin inventaariota',
                fetchingProducts: 'Haetaan tuotteita',
                fetchingReport: 'Haetaan raportteja',
                fetchingVatCategories: 'Haetaan ALV-kategoriaa',
                fetchingProductCategories: 'Haetaan tuotekategoriaa',
                fillForm: 'Täytä lomake',
                freshFood: 'Tämä on vanheneva tuote',
                freezerPackage: 'Pakaste',
                generalFetchError: 'Hakuvirhe',
                generalRequestReject: 'Pyyntövirhe',
                get: 'Hae',
                group: 'Ryhmä',
                holdOn: 'Odota',
                hour: 'Tunti',
                hours: 'tuntia',
                hoursBeforeExpiry: 'Tuntia ennen vanhentumista',
                daysBeforeExpiry: 'Päivää ennen vanhentumista',
                image: 'Kuva',
                imageOrVideo: 'Valitse kuva tai video',
                inActive: 'Ei aktiivinen',
                individualCabinets: 'Yksittäiset kaapit',
                individualCabinetsOnly: 'Vain yksittäiset kaapit',
                invalidBarcodeOnlyDigits: 'Viivakoodi voi sisältää pelkästään numeroita',
                invalidBarcodeTooLong: 'Viivakoodi on liian pitkä',
                invalidBarcodeTooShort: 'Viivakoodi on liian lyhyt',
                invalidCabinetNameTooShort: 'Kaapin nimi on liian lyhyt',
                invalidCabinetNameDuplicated: 'Kaapin nimi on jo olemassa',
                invalidLocationNameTooShort: 'Sijainnin nimi on liian lyhyt',
                invalidLocationNameDuplicated: 'Sijainnin nimi on jo käytössä',
                invalidPercentage: 'Virheellinen arvo',
                invalidPrice: 'Virheellinen hinta',
                invalidProductBarcode: 'Tuotteen viivakoodi on virheellinen',
                invalidTitle: 'Virheellinen otsikko',
                invalidBundleItems: 'Yhteisalennuksessa on virheellisiä tuotteita',
                inventoryLength: 'Inventaarion määrä',
                inventoryNotReported: 'Kaappi ei ole ilmoittanut inventaariotansa',
                inventoryTitle: 'Kaapin inventaario',
                'Inventory History': 'Inventaariohistoria',
                itemReplenishedOutOfInventoryProcess: 'Tämä tuote tai tunniste on asetettu kaappiin täydennysprosessin ulkopuolella',
                itemSoldOn: 'Tuote myyty',
                'Last 30 days': '30 viimeistä päivää',
                'Last 7 days': '7 viimeistä päivää',
                'Last month': 'Viime kuukausi',
                'Last quarter': 'Viime neljännesvuosi',
                'Last week': 'Viime viikko',
                'Last year': 'Viime vuosi',
                lastUpdate: 'Viimeisin päivitys',
                lifetime: 'Elinkaari',
                location: 'Sijainti',
                locations: 'Sijainnit',
                logoEditor: 'Logon muokkaus',
                logoSettings: 'Logoasetukset',
                loading: 'Ladataan...',
                measure: 'Mitta',
                measures: 'Mittaukset',
                minsBeforeExpiry: 'Minuuttia ennen vanhentumista',
                minutes: 'minuuttia',
                month: 'Kuukausi',
                name: 'Nimi',
                newVatCategoryModalTitle: 'Uusi ALV-kategoria',
                newProductCategoryModalTitle: 'Uusi tuotekategoria',
                newSupplierModalTitle: 'Uusi toimittaja',
                'no region': '(ei sijaintia)',
                noAdvertisements: 'Ei mainoksia',
                noCabinets: 'Ei kaappeja',
                noCabinetSelected: 'Ei valittua kaappia',
                noLogo: 'Ei logoa',
                noData: 'Ei dataa',
                noDescription: 'Ei kuvausta',
                noEvents: 'Ei tapahtumia!',
                noIndividualCabinets: 'Ei yksittäisiä kaappeja',
                noLocationPrices: 'Tälle tuotteelle ei ole määritetty paikallisia hintoja',
                none: 'Ei ole',
                noPaymentMethodLogo: 'Ei maksutavan logoa',
                noProducts: 'Ei tuotteita',
                noProductsRemoved: 'Ei poistettuja tuotteita',
                noProductTypeSelected: 'Pakkaustyyppiä ei ole valittu',
                normalPrice: 'Tavallinen hinta',
                normalSalesAmount: 'Tavallisen myynnin summa',
                notAccessibleForYou:
                    'Luonti-/päivitysominaisuus on käytössä vain järjestelmänvalvojille ja kaappien operaattoreille. Ota yhteys esimieheesi tai järjestelmänvalvojaan, jotta saat käyttöoikeuden.',
                notDefined: 'Ei määritetty',
                noTitle: 'Ei otsikkoa',
                noVatCategories: 'ALV-kategoriatietuetta ei löytynyt',
                noProductCategories: 'Tuotekategoriatietuetta ei löytynyt',
                noInventoryOnSelectedDate: 'Inventaariota ei ole raportoitu valitsemallasi päivällä. Yritä uudelleen eri päivämäärällä.',
                openDoor: 'Avaa ovi',
                orderSum: 'Tilauksen summa',
                paymentMethodLogo: 'Maksutavan logo',
                percentage: 'Prosenttiosuus',
                permanent: 'Pysyvä',
                preview: 'Esikatselu',
                price: 'Hinta',
                print: 'Tulosta',
                productCreated: 'Tuote luotu',
                products: 'Tuotteet',
                productsFetchError: 'Virhe tuotteiden haussa',
                productsTitle: 'Tuoteluettelo',
                productType: 'Pakkaustyyppi',
                productUpdated: 'Tuote päivitetty',
                quantity: 'Määrä',
                readerDown: 'RFID-lukija ei ole toiminnassa',
                region: 'Sijainti',
                regions: 'Sijainnit',
                removedAt: 'Poistettu',
                removeImage: 'Poista kuva',
                removeVideo: 'Poista video',
                refillQRCodeInfo: 'Skannaa QR-koodi, jotta voit avata täydennyssovelluksen, tai seuraa',
                refillQRCodeTitle: 'Täydennys QR-koodi',
                refillRuleTitle: 'Täyttösääntö',
                reportsTitle: 'Raportit',
                requestSuccess: 'Muutokset tehty',
                requiredPercentage: 'Pakollinen. Prosenttiarvo ilman prosenttimerkkiä.',
                requiredText: 'Merkkien vaadittu pituus: ',
                retireLocation: 'Poista sijainti käytöstä',
                rfidNoInfo: 'Ei tietoja RFID-lukijasta ja antenneista',
                rfidOk: 'RFID-lukija ja antennat toimivat odotetusti.',
                sales: 'Myynti',
                salesCount: 'Myyntimäärä',
                salesVsWasteAmount: 'Myynti vs. jätemäärä',
                save: 'Tallenna',
                search: 'Hae',
                selectCabinet: 'Valitse kaappi ',
                selectDiscountRuleCabinets: 'Valitse kaapit, joihin alennussääntö vaikuttaa',
                selectDiscountRuleLocations: 'Valitse sijainnit, joihin alennussääntö vaikuttaa',
                selectDiscountRuleProducts: 'Valitse tuotteet, joihin alennussääntö vaikuttaa',
                selectProductType: 'Valitse tuotteellesi pakkaustyyppi',
                setDiscountAmount: 'Määritä alennussumma',
                setDiscountTimeBeforeExpiry: 'Aseta vanhenemisväli',
                settingConfirmMessage: 'Haluatko varmasti tallentaa nämä asetukset? Jos et ole varma, älä vahvista sitä',
                showDisable: 'Arkistoidut tuotteet',
                summary: 'Yhteenveto',
                sureRemoveDiscountRule: 'Haluatko varmasti poistaa alennussäännön?',
                tagIsBad: 'Tunniste on virheellinen, se on poistettava kaapista.',
                tagsRemovedReport: 'Poistettujen tunnisteiden raportti',
                taxValue: 'Veromäärä',
                'This month': 'Tämä kuukausi',
                'This quarter': 'Tämä neljännesvuosi',
                'This week': 'Tämä viikko',
                'This year': 'Tämä vuosi',
                thisLink: ' tätä linkkiä. ',
                timeGrouping: 'Aikaryhmittely',
                title: 'Otsikko',
                Today: 'Tänään',
                topExpiredProducts: 'Yleisimmät vanhentuneet tuotteet',
                topProducts: 'Huipputuotteet',
                total: 'Yhteensä',
                totalValue: 'Kokonaisarvo',
                transactions: 'Ostotapahtumat',
                ttlInDays: 'Tuotteen elinkaari päivinä',
                ttlsAffected: 'Tuotteet, joiden elinkaareen sääntö vaikuttaa',
                unexpectedError: 'Odottamaton virhe',
                uniqueCustomers: 'Yksittäiset asiakkaat',
                unknown: 'Tuntematon',
                update: 'Päivitä',
                updateAdError: 'Virhe mainoksen päivittämisessä',
                updateAdTitle: 'Päivitä mainos',
                updatedAd: 'Mainos päivitetty',
                updateCabinetSettings: 'Synkronoi tiedot kaappiin',
                updateCabinetSettingsInfo: 'Haluatko varmasti päivittää kaappiasetukset?',
                updateData: 'Päivitä tiedot',
                updateProductTitle: 'Päivitä tuote',
                updatingAd: 'Päivitetään mainosta',
                updatingProduct: 'Päivitetään tuotetta',
                uploading: 'Ladataan',
                uploadingImage: 'Ladataan kuvaa',
                use: 'Käytä',
                'w/o grouping': 'Ei ryhmittelyä',
                waste: 'Jätteet',
                vatCategoriesFetchError: 'Virhe ALV-kategorien haussa',
                vatCategoriesTitle: 'ALV-kategoriat',
                vatCategory: 'ALV-kategoria',
                vatCategoryItems: 'Tuotteet',
                vatCategoryName: 'ALV-kategorian nimi',
                vatCategoryPromptMessage: 'Haluatko varmasti poistaa ALV-kategorian',
                vatCategoryValue: 'Veroarvoprosentti',
                vatDeleteRejected: 'ALV-kategorian poisto hylätty. Tarkista asiaan liittyvät tuotteet.',
                vatErrorRequesting: 'Virhe pyynnön käsittelyssä',
                vatRequestRejected: 'Pyyntö hylätty',
                vats: 'ALV:t',
                productCategoriesFetchError: 'Virhe tuotekategorien haussa',
                productCategoriesTitle: 'Tuotekategoriat',
                productCategory: 'Tuotekategoria',
                productCategoryItems: 'Kategoriat',
                productCategoryName: 'Tuotekategorian nimi',
                productCategoryPromptMessage: 'Haluatko varmasti poistaa tuotekategorian?',
                productCategoryDescription: 'Tuotekategorian kuvaus',
                productCategoryHelpTextDescription: 'Esim. kategoria salaateille',
                productCategoryHelpTextName: 'Esim. salaatit',
                productCategoryDeleteRejected: 'Tuotekategorian poisto hylätty. Tarkista asiaan liittyvät tuotteet.',
                week: 'Viikko',
                viewMode: 'Näkymä',
                year: 'Vuosi',
                Yesterday: 'Eilen',
                groupBy: 'Ryhmittelyperuste',
                filterBy: 'Suodatusperuste',
                reportType: 'Raporttityyppi',
                custom: 'Mukautettu',
                preDefined: 'Ennalta määritelty',
                selected: 'Valittu',
                metricsInfo: 'Mittarit (summa, yläraja, alaraja…) lasketaan kaikille valituille mitoille/resursseille',
                dimensionsInfo: 'Mittarit haetaan valituille mitoille/resursseille',
                metrics: 'Mitat',
                controlsSoftwareVersion: 'Ohjelmistoversio',
                currentSoftwareVersion: 'Nykyinen ohjelmistoversio',
                availableSoftwareVersion: 'Saatavilla oleva ohjelmistoversio',
                invalidLifetime: 'Virheellinen eliniän arvo',
                unpaidSales: 'Maksamaton myynti',
                unpaidTransactions: 'Maksamattomat myyntitapahtumat',
                softwareUpdateConfirmation: 'Haluatko varmasti päivittää ohjelmistoversion valituille laitteille?',
                softwareUpdateWarn:
                    'Ohjelmistoversio päivitetään siten, että laitteille lähetetään komento hakea päivitys nyt tai valittuna ajankohtana. Valitut laitteet asentavat päivityksen ollessaan seuraavan kerran yhteydessä verkkoon. Asennus voi kestää jonkin aikaa yhteysnopeudesta riippuen. Laitteet käynnistyvät asennuksen jälkeen uudelleen.',
                releaseVersion: 'Ohjelmistoversiot',
                releaseDate: 'Julkaisupäivä',
                latestRelease: 'Uusin ohjelmisto',
                currentVersion: 'Tämänhetkinen versio',
                softwareUpdateSuccess: 'Ohjelmistoversio on päivitetty',
                prebooking: 'Ennakkovaraus',
                prebookedProduct: 'Ennakkovarattu tuote',
                prebookedProducts: 'Ennakkovaratut tuotteet',
                activeProducts: 'Aktiiviset tuotteet',
                startMaintenanceMode: 'Aloita huoltotila',
                endMaintenanceMode: 'Lopeta huoltotila',
                startMaintenanceConfirm: 'Haluatko varmasti aloittaa kaapin huoltotilan?',
                endMaintenanceConfirm: 'Haluatko varmasti lopettaa kaapin huoltotilan?',
                startMaintenanceSuccess: 'Huoltotila aloitettu. ',
                endMaintenanceSuccess: 'Huoltotila lopetettu. ',
                noResults: 'Ei tuloksia',
                suppliers: 'Toimittajat',
                supplier: 'Toimittaja',
                supplierDeleteRejected: 'Toimittajan poisto hylättiin. Tarkista asiaan liittyvät tuotteet!',
                supplierPromptMessage: 'Haluatko varmasti poistaa toimittajan?',
                fetchingSuppliers: 'Haetaan toimittajia',
                noSuppliers: 'Toimittajatietoja ei löytynyt',
                suppliersFetchError: 'Virhe toimittajien haussa',
                supplierName: 'Toimittajan nimi',
                supplierHelpTextName: 'Esimerkiksi brändin nimi',
                supplierDescription: 'Toimittajan kuvaus',
                supplierHelpTextDescription: 'Esimerkiksi brändin kuvaus',
                wishes: 'Toiveet',
                checkNow: 'Tarkista nyt',
                startTimeOn: 'Aloitusaika',
                endTimeOn: 'Päättymisaika',
                signOutConfirmationModalTitle: 'Uloskirjautumisen vahvistus',
                signOutConfirmationModalContent: 'Haluatko varmasti kirjautua ulos?',
                startDate: 'Alkamispäivä',
                endDate: 'Päättymispäivä',
                scheduledDiscountApplyAllDays: 'Koskee kaikkia päiviä',
                scheduledDiscountApplySelectedDays: 'Koskee valittuja päiviä',
                discountCustomImageTitle: 'Alennuskuva (valinnainen)',
                discountCustomImageModalHeader: 'Alennuksen kuva',
                saveChanges: 'Tallenna muutokset',
                updateTagsQualitySettingsTitle: 'Tunnisteen laatuasetukset',
                updateTagsQualitySettingsMessage: 'Päivitetyt tunnisteen laatuasetukset',
                IF3Cabinets: 'Mallin 3 jääkaapit',
                IF2Cabinets: 'Mallin 2 jääkaapit',
                IF3Freezers: 'Mallin 3 pakastimet',
                encodingStations: 'Koodausasemat',
                if2: 'Mallin 2 jääkaappi',
                if3: 'Mallin 3 jääkaappi',
                if3Freezer: 'Mallin 3 pakastin',
                legalNotice: 'Oikeudellinen huomautus',
                privacyPolicy: 'Tietosuojakäytäntö',
                scheduledDiscountDateMissingError: 'Molemmat päivämäärät tarvitaan, jos alkamis- tai päättymispäivä on asetettu',
                scheduledDiscountDuplicateTimeError: 'Kellonajat eivät voi olla samat, jos päivämäärät ovat samat',
                now: 'Nyt',
                minimum: 'Alaraja',
                maximum: 'Yläraja',
                discardChangesConfirmationText: 'Haluatko varmasti hylätä muutokset?',
                exports: 'Viennit',
                selectAllCabinets: 'Valitse kaikki kaapit',
                expiryIntervals: 'Vanhenemisvälit',
                timeBeforeExpiry: 'Aika ennen vanhenemista',
                apply: 'Käytä',
                yes: 'Kyllä',
                no: 'Ei',
                productOnScreenBannerText: 'Näytöllä näkyvä tietopalkkiteksti',
                productOnScreenBannerAdditionalInfo:
                    'Palkkiteksti on tuotekuvan alapuolella näkyvä yksirivinen teksti, joka kertoo lyhyesti tuotteen tärkeimmät ominaisuudet, terveyteen liittyvät ilmoitukset tai mahdolliset lisätiedot',
                productOnScreenBannerExampleText: 'Laktoositon',
                UI_IS_DOWN: 'Kaappinäyttö ei toimi',
                READER_IS_DOWN: 'RFID-lukija ei toimi',
                ANTENOR_DEVICE_DISCONNECTED: 'Maksu-laite ei ole yhteydessä',
                EXPIRED_PRODUCT: 'Kaapissa on vanhentuneita tuotteita',
                CLOUD_SERVICE_DOWN: 'Pilvipalvelu ei ole käytettävissä',
                ANTENNA_DISCONNECTED: 'Antenni on irrotettu',
                TAG_CHANGES: 'Liian monta huonoa tagia kaapissa',
                ANTENNA_DOWN: 'RFID-antenni kaapissa ei toimi',
                READER_CONFIG_ERROR: 'RFID-lukijan konfiguraatio epäonnistui eikä se toimi',
                activeAlarms: 'Aktiiviset hälytykset',
                deviceStatus: 'Laitteen tila',
                lastMonitored: 'Viimeksi valvottu',
                noService: 'Ei palvelua',
                operational: 'Toiminnassa',
                offline: 'Offline',
                locationsSelectHelp: 'Kun sijainti lisätään, sen alisijainnit lisätään myös.',
                select: 'Valitse...',
                labels: 'Merkit',
                productDetails: 'Tuotetiedot',
                invalidProductName: 'Tuotteen nimen tulee olla 1-125 merkkiä pitkä',
                standardPrice: 'Vakiohinta',
                locationPrices: 'Paikkakohtaiset hinnat',
                addNew: 'Lisää uusi',
                productExpiry: 'Tuotteen vanhenemispäivä',
                productLifeTime: 'Tuotteen elinikä',
                invalidTTL: 'Tuotteen elinajan tulee olla kokonaisluku väliltä 0-32767',
                productImage: 'Tuotekuva',
                uploadImage: 'Lataa kuva',
                pleaseUploadImage: 'Lataa kuva',
                productValidationError: 'Korjaa seuraavat virheet ennen tuotteen tallentamista',
                productSupplier: 'Tuotteen toimittaja',
                frozenProductLable: 'Tämä on pakastettu tuote',
                prebookingLabel: 'Tämä on ennakkovaraustuote',
                previous: 'Edellinen',
                bannerText: 'Banneriteksti',
                packagingTypeValidationError: 'Valitse tuotteen pakkaustyyppi',
                showOnlyFrozen: 'Näytä vain pakastetuotteet',
                productClassification: 'Tuotteen luokitus',
                productClassLabel: 'Valitse tuotteen asianmukainen luokka. Tämä mahdollistaa Selfly Insightsin tarjoamaan osuvampia tuotetietoja.',
                productLabels: 'Tuotemerkit',
                productLabelsHelpText: 'Lisää tuotteeseesi valinnaisia tunnisteita. Tämä mahdollistaa tuotteiden suodattamisen tuotekatalogissa.',
                newLabel: 'Uusi merkki',
                productClassValidationError: 'Valitse tuotteellesi luokitus alla olevista vaihtoehdoista',
                tagSensitivity: 'Tunnisteen laadun herkkyys',
                tagSensitivitySetting: 'Tunnisteen laadun herkkyysasetus',
                configTagSensitivity: 'Määritä tuotteen tunnisteen laadun herkkyys',
                productLevelSensitivityInfo:
                    'RFID-tunnisteen luettavuuteen tuotteessa voi vaikuttaa useita tekijöitä, kuten pakkausmateriaalit, metallipinnat, kosteus ja itse tuotteen sisältö. Nämä tekijät voivat joskus aiheuttaa tunnisteen ajoittaista signaalin vilkkumista. Kaappi käsittelee tällaista vilkkumista herkkyysasetusten perusteella. Jos et ole varma, mikä herkkyystaso sopii tälle tuotteelle, voit ohittaa tämän osion.',
                errorSaving: 'Virhe tallennettaessa tuotetietoja',
                successSaving: 'Tuotetiedot tallennettu onnistuneesti',
                basicDetail: 'Perustiedot',
                additionalDetail: 'Lisätiedot',
                packaging: 'Pakkaustyyppi',
                classification: 'Luokitus',
                selectOrtype: 'Valitse tai kirjoita...',
                max25Chars: 'enintään 25 merkkiä',
                noVatForProductCreation: 'Luo ensin ALV-kategoria ennen uusien tuotteiden lisäämistä',
                lifeTimeExplanation: 'Kuinka monta päivää tämä tuote on myynnissä. (0 päivää) merkitsee nykyisen päivän loppuun asti.',
                selflyEncode: 'Selfly Encode',
                userRestrict: 'Vain tunnistetut kuluttajat',
                userRestrictTitle: 'Oikeutetut kuluttajat',
                userNotRestrict: 'Kaikki kuluttajat',
                userRestrictHelperText:
                    'Tunnistetut kuluttajat -vaihtoehto tarkoittaa, että alennus myönnetään vain kuluttajille, jotka ovat jo rekisteröityneet alennusoikeuden saajiksi!',
                searchCabinets: 'Etsi kaappeja',
                selectAll: 'Valitse kaikki',
                monday: 'Maanantai',
                tuesday: 'Tiistai',
                wednesday: 'Keskiviikko',
                thursday: 'Torstai',
                friday: 'Perjantai',
                saturday: 'Lauantai',
                sunday: 'Sunnuntai',
                fetchingLoader: 'Haetaan. Odota hetki...',
                category: 'Kategoria',
                okay: 'Ok',
                noLocation: 'Ei sijaintia',
                copy: 'Kopioi',
                pleaseConfirm: 'Vahvista, ole hyvä',
                saveQuery: 'Tallenna kysely',
                saveQueryPlaceholder: 'esim. "Kaikkien tuotteiden myyntimäärä"',
                saveQuerySuccess: 'Kysely on tallennettu.',
                yourSavedQueries: 'Tallentamasi kyselyt',
                selectQuery: 'Valitse kysely',
                noQuery: 'Ei tallennettuja kyselyitä vielä',
                deleteAdConfirm: 'Oletko varma, että haluat poistaa tämän mainoksen?',
                dataSaved: 'Tiedot tallennettu onnistuneesti',
            },
            refill: {
                refillRuleApplyRuleDropdownText: 'Käytä tätä sääntöä myös muille kaapeille',
                refillPlanGenerate: 'Luo täyttösuunnitelma',
                refillPlanEditPlan: 'Muokkaa suunnitelmaa',
                refillAmount: 'Täyttömäärä',
                refillRuleTargetAmount: 'Varaston tavoitetaso',
                refillRuleTargetAmountShort: 'Tavoite',
                refillPlanActivePlans: 'Aktiiviset suunnitelmat',
                refillPlanCompletedPlans: 'Valmiit suunnitelmat',
                refillNote: 'Täydennysmerkintä',
                refillNoteHelp: 'Tuotteen täydennysmerkintä (enintään 125 merkkiä)',
                refillPDFText:
                    'Alla on kaapin täydennysuunnitelma. Varmista, että merkitset täydennysuunnitelman täydennyssovelluksessa suoritetuksi täydennyksen päätyttyä.',
                refillPDFSummaryText: 'Alla on yhteenveto täydennysuunnitelmista lisättäville ja poistettaville tuotteille kaikille valituille kaapeille.',
                refillSummary: 'Täydennysyhteenveto',
                addItems: 'Lisää tuotteita',
                removeItems: 'Poista tuotteita',
                inventoryChange: 'Inventaarimuutos',
                planogramPDFText: 'Planogram kaapin täyttöstrategiaan.',
                planogramImagePDFText: 'Mukautettu kuva kaapin täyttöstrategiaan.',
                refillSchemas: 'Täyttömallit',
                refillPlans: 'Täyttösuunnitelmat',
                refillSchemaTitle: 'Aseta tavoite- ja minimivarastomäärät valittujen kaappien malliksi. Näitä malleja käytetään täyttösuunnitelmien luomiseen.',
                refillPlansTitle:
                    'Näytä kaikki aktiiviset suunnitelmat. Voit muokata tai poistaa olemassa olevia aktiivisia suunnitelmia tai lisätä uusia täyttösuunnitelmia tarpeen mukaan.',
                refillSchemaSubText: 'Valitse kaappi luodaksesi tai päivittääksesi täyttömallin.',
                noRefillSchema: 'Tälle kaapille ei ole vielä määritetty täyttömallia.',
                createRefillSchema: 'Luo uusi täyttömalli',
                noDevice: 'Laitetta ei löytynyt',
                schemaSaved: 'Täyttömalli tallennettu onnistuneesti',
                deleteSchemaConfirmation: 'Oletko varma, että haluat poistaa täyttömallin?',
                targetAmount: 'Tavoitemäärä',
                targetAmountHelpText: 'Täyttämisen jälkeen tavoitevarastomäärä jokaiselle tuotteelle kaapissa.',
                minimumThreshold: 'Minimiraja',
                minimumThresholdHelpText:
                    'Määritä jokaiselle tuotteelle kaapin minimivarastotaso. Jos määrä laskee rajan alapuolelle, saat hälytysilmoituksen. Asettamalla rajan 0:aan, ilmoitukset poistetaan käytöstä.',
                editNote: 'Muokkaa muistiinpanoa',
                refillNoteTitle: 'Kirjoita täyttömuistio tuotteelle.',
                refillNoteCharacters: 'merkkiä',
                noRefillPlan:
                    'Tällä hetkellä ei ole aktiivisia täyttösuunnitelmia. Lisää uusia täyttösuunnitelmia kaapeillesi tai luo ensin täyttömalli kaapeille.',
                planSaved: 'Täyttösuunnitelma tallennettu onnistuneesti',
                deleteConfirmation: 'Oletko varma, että haluat poistaa valitut täyttösuunnitelmatiedot?',
                editPlan: 'Muokkaa suunnitelmaa',
                currentInventory: 'Nykyinen varasto',
                lastSevenDaySales: 'Viimeisten seitsemän päivän myynti',
                inventory: 'Varasto',
                planogram: 'Planogram',
                copyRefillSchema: 'Kopioi malli muihin kaappeihin',
                generatingPlans: 'Luodaan täyttösuunnitelmia. Odota...',
                generatePlans: 'Luo täyttösuunnitelmia',
                complete: 'valmis',
                selectCabinetsToAddPlans: 'Valitse kaapit täyttösuunnitelmien luomista varten.',
                reviewSuggestion:
                    'Alla on ehdotetut täyttösuunnitelmat valituille kaapeille. Tarkista, muokkaa tarvittaessa ja tallenna ne aktivoidaksesi nämä suunnitelmat.',
                noSchema: 'Yhdelläkään kaapilla ei ole tällä hetkellä täyttömallia. Lisää täyttömalli kaapeihisi täyttösuunnitelmien luomista varten.',
                searchProducts: 'Etsi tuotteita',
                maxAllowedInventory: 'Suurin sallittu varasto on 200.',
                savingData: 'Tallennetaan tietoja. Odota',
                errorSavingData: 'Tietojen tallentamisessa tapahtui virhe.',
                refillPlanSaved: 'Täyttösuunnitelmat luotu onnistuneesti.',
                exportPDFHelp: 'Vie valitut suunnitelmat PDF-tiedostoon.',
                exportExcelHelp: 'Vie valitut suunnitelmat Excel-tiedostoon.',
                addPlanHelp: 'Lisää uusia täyttösuunnitelmia.',
                deletePlanHelp: 'Poista valitut täyttösuunnitelmat.',
                generatePlansHelp: 'Luo täyttösuunnitelmia valituille kaapeille.',
                planogramSaved: 'Planogram tallennettu onnistuneesti.',
                schemaCopied: 'Täyttömalli synkronoitu onnistuneesti muihin kaappeihin.',
                allProducts: 'Kaikki tuotteet',
                schemaProducts: 'Täyttömallituotteet',
                deletePlanogram: 'Oletko varma, että haluat poistaa planogramin?',
                dragInPlanogram: 'Vedä ja pudota tuotteet määrättyihin kaapin hyllyihin tai vedä kaapin paikka tuotteen päälle.',
                noPlanogramInSchema: 'Planogramia ei ole vielä määritetty kaapille.',
                noPlanogramInPlan: 'Kaapille ei ole saatavilla planogram-tietoja. Luo ensin planogram täyttömallivälilehdessä.',
                copyPlanogram: 'Kopioi täyttöplanogram täyttömallin kanssa',
                planogramImageInfo: 'Voit lisätä mukautetun planogram-kuvan planogramin lisäksi.',
                addPlanogramImage: 'Lisää planogram-kuva',
                editPlanogramImage: 'Muokkaa planogram-kuvaa',
                numberOfShelves: 'Hyllyjen määrä',
                addNewRefillPlan: 'Lisää uusia suunnitelmia',
                qrcodeTitle: 'QR-koodi luodaan valitulle kaapille täyttöprosessin aloittamiseksi.',
                qrcodeSubText: 'Valitse kaappi, jolle haluat aloittaa täyttöprosessin.',
                qrcodeScan: 'Skannaa QR-koodi täyttämiseksi tai napsauta painiketta avataksesi täyttösovellus.',
                qrcodeOpenApplication: 'Avaa täyttösovellus',
                createRefillPlanogram: 'Luo täyttöplanogram',
            },
            navigation: {
                advertisements: 'Mainokset',
                analytics: 'Analytiikka',
                controlPanel: 'Ohjauspaneeli',
                deviceManagement: 'Laitehallinta',
                discounts: 'Alennukset',
                fetching: 'Haetaan',
                group: 'Ryhmä',
                inventory: 'Kaapin inventaario',
                language: 'Kieli',
                productCatalog: 'Tuoteluettelo',
                productCategories: 'Tuotekategoria',
                reports: 'Raportit',
                settings: 'Käyttäjäasetukset',
                signOut: 'Kirjaudu ulos',
                title: 'Selfly Cloud Portal',
                vatCategories: 'ALV-kategoriat',
                community: 'Selfly Community',
                refillManagement: 'Täydennysjohtaminen',
                automations: 'Automaatio',
                mySelfly: 'MySelfly-asetukset',
                automationInfo: 'Hallitse automatisoituja toimintoja (BETA)',
            },
            reports: {
                alreadyRefund: 'hyvitettiin',
                amount: 'Määrä',
                average: 'Keskiarvo',
                barcode: 'Viivakoodi',
                confirmIssueRefundButton: 'Vahvista hyvitys nyt',
                current: 'Nykyinen',
                export: 'Vie',
                fetching: 'Haetaan',
                fetchingError: 'Virhe haussa',
                image: 'Kuva',
                issueRefundButton: 'HYVITYS',
                maximum: 'Yläraja',
                minimum: 'Alaraja',
                name: 'Nimi',
                noData: 'Ei tietoja',
                note: 'Huomautus',
                order: 'Tapahtuma',
                paymentWasDoneByCreditCard: 'Maksu suoritettu luottokortilla',
                price: 'Hinta',
                ProductReport: 'Tuoteraportti',
                quantity: 'Määrä',
                'Refund impossible': 'Maksu suoritettiin luottokortilla. Ota yhteys luottokorttipäätteen palveluntarjoajaan maksun hyvittämiseksi.',
                refundFormTitle: 'Hyvitys',
                refundReason: 'Hyvityksen syy',
                refundSuccess: ' {{sum}} hyvitettiin',
                refundTotal: 'Hyvityssumma',
                replenishments: 'Täytöt',
                replenishmentsTitle: 'Täyttöraportti',
                SaleTransaction: 'Myyntitapahtuma',
                summary: 'Yhteenveto',
                SummaryReport: 'Myynnin yhteenveto',
                temperature: 'Lämpötila',
                temperatureTitle: 'Lämpötilaraportti',
                temperatureValueNotification: 'Lämpötila-arvot ovat tuntikeskiarvoja kaapin lämpötilasta.',
                time: 'Aika',
                total: 'Yhteensä',
                transactions: 'Myyntitapahtumat',
                user: 'Käyttäjä',
                paidTransactions: 'Maksetut myyntitapahtumat',
                unpaidTransactions: 'Maksamattomat myyntitapahtumat',
                partiallyPaid: 'Osa maksettu',
                failedTransactions: 'Epäonnistuneet tapahtumat',
                amountOwed: 'Maksamaton summa',
                receiptLinkText: 'Hanki kuitti täältä',
                noPurchase: 'Ei ostoa',
                other: 'Muu',
                userId: 'Käyttäjä ID:',
            },
            settings: {
                block_cabinet_when_product_expired: 'Estä vanhentuneet tuotteet kaapissa',
                criticalExpiryLimitHours: 'Tuntien määrä ennen kriittistä vanhentumishälytystä',
                language: 'Kieli',
                logo: 'Yrityksen logo',
                mediumExpiryLimitHours: 'Tuntien määrä ennen keskitason vanhentumishälytystä',
                payment_method_logo: 'Maksutavan logo',
                footer_instruction_logo: 'Alatunnisteen ohjekuva',
                readUserMemory: 'Hae tuotteen vanhentumistiedot',
                ui_skus_sort_by: 'Tuotteiden lajittelujärjestys kaapin näytöllä',
                timeZone: 'Aikavyöhyke',
                country: 'Maa',
                city: 'Paikkakunta',
                tagSettings: 'Tunnisteasetukset',
                themeAndLogos: 'Teema ja logot',
                advancedSettings: 'Lisäasetukset',
                themingInfoText: 'Voit mukauttaa kaappisi näytön teemaa',
                cabinetLogos: 'Kaapin logot',
                cabinetLogoInfoText: 'Lataa logot, jotka näkyvät kaapin näytöllä',
                logoHelperText: 'Käytä 180 x 180 pikselin kokoista kuvaa',
                settingsCopyInfoHeader: 'Valitse kaapit, joihin haluat kopioida asetukset',
                settingsCopyButton: 'Kopioi asetukset valittuihin kaappeihin',
                settingsCopySuccessModalTitle: 'Asetusten kopiointi',
                settingsCopySuccessModalContent: 'Asetukset kopioitu {{cabinetsAmount}} kaappiin',
                theming: 'Teema',
                adminSettings: 'Ylläpitäjän asetukset',
                settingsCopyErrorModalTitle: 'Asetusten kopiointivirhe',
                key: 'Asetus',
                value: 'Arvo',
                sleepScheduling: 'Aikataulutettu lepotila',
                sensitivityLevel: 'Herkkyystaso',
                sensitivity_0:
                    'Kaappi sivuuttaa tagin välkkymisen ja merkitsee kaikki tagit jatkuvasti kelvollisiksi, mikä voi toisinaan johtaa väärään veloitukseen.',
                sensitivity_20: 'Kaappi sallii tagille enintään kolme virheellistä lukemaa 50 skannauksen aikana ja merkitsee tagin silti kelvolliseksi.',
                sensitivity_40: 'Kaappi sallii tagille enintään kaksi virheellistä lukemaa 60 skannauksen aikana ja merkitsee tagin silti kelvolliseksi.',
                sensitivity_60: 'Jos tagi välkkyy usein, mutta pysyy vakaana pidemmän aikaa, kaappi sietää tätä ja lukee tagin kelvolliseksi.',
                sensitivity_80: 'Jos tagi välkkyy hetkellisesti mutta pysyy vakaana pitkän ajan, sitä pidetään kelvollisena.',
                sensitivity_100:
                    'Kaappi ei siedä tagin välkkymistä. Jo yksi virheellinen lukema voi aiheuttaa, että tagi merkitään pitkäksi aikaa kelvottomaksi, mikä voi aiheuttaa tappioita.',
                sensitivityInfo:
                    'Kaapin tagin laadun herkkyys viittaa siihen, kuinka tehokkaasti kaappi käsittelee epäjohdonmukaisia tagin lukemia tai välkkymistä. Ole tarkkana herkkyystason valinnassa, sillä se voi vaikuttaa kaapin suorituskykyyn. Jos olet epävarma sopivasta tasosta, on suositeltavaa pitää asetukset oletustasolla 3.',
            },
            signIn: {
                signInButton: 'Kirjaudu sisään',
                signInExpiredError: 'Istunto on vanhentunut. Kirjaudu takaisin sisään',
                signInMessage: 'Sinun on kirjauduttava sisään, jotta voit jatkaa. Kirjaudu sisään klikkaamalla alla olevaa painiketta',
                noOrganizationsError: 'Tähän käyttäjänimeen ei ole linkitetty organisaatioita. Kokeile toista käyttäjänimeä.',
            },
            translations: {
                add: 'Lisää',
                closed: 'Suljettu',
                delete: 'Poista',
                device: 'Laite',
                edit: 'Muokkaa',
                off: 'POIS PÄÄLTÄ',
                offline: 'Offline-tilassa',
                on: 'PÄÄLLÄ',
                online: 'ONLINE-TILASSA',
                opened: 'Auki',
                save: 'Tallenna',
                seeDetails: 'Tiedot',
                settings: 'Asetukset',
                unknown: 'Tuntematon',
            },
            consumerApp: {
                enableConsumerFeedback: 'Ota käyttäjäpalaute käyttöön MySelflyssä',
                enablePurchaseReceipts: 'Ota sähköiset kuitit käyttöön MySelflyssä',
                enableSelflyTrademarkForReceipts: 'Ota Selfly Store alatunniste käyttöön sähköisissä kuiteissa',
                enableSelflyTrademarkForReceiptsHelpText: 'Kun tämä on käytössä, Selfly Storen logo ja tiedot lisätään sähköisiin PDF-kuitteihin!',
                organizationFormHelpText: 'Anna sähköisen kuitin laatineen organisaation tiedot.',
                organizationFormValidationHelpText:
                    '* Sähköisissä kuiteissa pakollisia kenttiä ovat kuitin laatineen organisaation nimi, yritystunnus, osoite ja maa.',
                organizationName: 'Organisaation nimi',
                organizationLogo: 'Organisaation logo',
                businessId: 'Yritystunnus',
                city: 'Paikkakunta',
                email: 'Sähköpostiosoite',
                website: 'Sivusto',
                postalCode: 'Postinumero',
                street: 'Katuosoite',
                phoneNumber: 'Puhelinnumero',
                addressHeader: '* Osoitetiedot',
                contactDetails: 'Yhteystiedot',
                organization: '* Kuitin laatinut organisaatio',
                organizationSettingsUpdatedTitle: 'Organisaatioasetusten päivitys',
                organizationSettingsUpdatedContent: 'Organisaatioasetukset päivitetty',
                organizationSettingsMissingFields: 'Sähköisen kuitin pakollisia kenttiä puuttuu. Täytä tiedot ennen tallentamista.',
                organizationSettingsMissingFieldsTitle: 'Lomakkeen vahvistusvirhe',
            },
            softwareVersions: {
                updateNotificationTitle: 'Ohjelmistoversion päivitys',
                updateNotificationContent: 'Ohjelmistoversion tai -versioiden päivitys onnistui',
                selectAVersionPlaceholder: 'Valitse versio',
                searchDevices: 'Etsi laitteita',
                updateSelected: 'Päivitä valitut',
                softwareVersions: 'Ohjelmistoversiot',
                softwareVersion: 'Ohjelmistoversio',
                notSupportedDeviceNote: 'Tämä laite ei tue versiota {{selectedVersion.version}}',
                userNotificationContent: 'Laitteillesi on julkaistu uusi ohjelmistoversio. Päivitä laitteesi, jotta saat käyttöösi uusimmat ominaisuudet.',
                userNotificationTitle: 'Uusi ohjelmistoversio on julkaistu',
                notificationBell_newSoftwareVersions: 'Laitteillesi on saatavilla päivityksiä',
                manageVersions: 'Hallinnoi laitteen ohjelmistoversioita',
                filterLocationsPlaceholder: 'Suodata sijainnin mukaan',
                allVersionNumbers: 'Kaikki julkaisut',
                noVersionsForUpdating: 'Ei päivitettäviä versioita',
                scheduledUpdateNote: 'Versio {{version}} päivitetään {{scheduled}}',
                scheduleRemoveNotificationTitle: 'Ohjelmistoversion ajastus',
                scheduleRemoveNotificationContent: 'Ohjelmistoversion ajastettu päivitys on poistettu',
                scheduleRemoveNotificationModalContent: 'Haluatko varmasti poistaa ajastetun päivityksen tältä laitteelta?',
            },
            header: {
                searchByOrganization: 'Hae organisaation mukaan',
                pageLanguage: 'Kieli',
                configureMySelflyAndEreceipts: 'Määritä MySelfly-asetuksia',
                remindMeLater: 'Muistuta minua myöhemmin',
                updateNow: 'Päivitä nyt',
            },
            notifications: {
                noNewNotifications: 'Ei uusia ilmoituksia',
            },
            cabinetSettings: {
                cabinetTheme: 'Kaapin teema',
                editAndSaveTheme: 'Muokkaa teemaa ja tallenna',
                colorPicker: 'Värinvalitsin',
                customTheme: 'Mukautettu teema',
                themeSettings: 'Teeman asetukset',
                primary: 'Ensisijainen',
                negative: 'Negatiivinen',
                attention: 'Huomio',
                positive: 'Positiivinen',
                background: 'Tausta',
                dark: 'Tumma',
                light: 'Vaalea',
                white: 'Valkoinen',
                default: 'Oletus',
                negativeLight: 'Negatiivinen valo',
                primaryColor: 'Ensisijainen väri',
                positiveColors: 'Positiiviset värit',
                negativeColors: 'Negatiiviset värit',
                secondaryColors: 'Toissijaiset värit',
                attentionColors: 'Huomiovärit',
                screenBackground: 'Näytön tausta',
                borderColors: 'Reunat – varjot',
                shadow: 'Varjo',
                border: 'Reuna',
                fontColors: 'Fontin värit',
                footerTextPlaceholder: 'Alatunnisteen teksti',
                bundleDiscounts: 'Yhdistä ja säästä',
                expiringText: 'Vanhenevat tuotteet. Tarkista vanhentumispäivä ennen ostoa',
                expiredText: 'Vanhentuneet tuotteet. Tarkista vanhentumispäivä ennen ostoa',
                cabinetName: 'Kaapin nimi',
                productName: 'Tuotteen nimi',
                confirmationModalHeader: 'Haluatko varmasti tallentaa mukautetun teeman?',
                confirmationModalContent:
                    'Teeman mukauttaminen vaikuttaa kaapin näytön visuaaliseen ilmeeseen. Varmista, ettei näytön visuaalinen laatu heikkene muutosten vuoksi.',
                defaultRevertModalMessage: 'Haluatko varmasti vaihtaa kaapin teeman oletusteemaksi?',
                backgroundColor: 'Taustaväri',
                backgroundImage: 'Taustakuva',
                footerBackground: 'Alatunnisteen tausta',
                cardsBackground: 'Korttien tausta',
                screenImage: 'Kuva kaapin näytön taustaksi',
                uploadImage: 'Lataa kuva',
                editImage: 'Muokkaa kuvaa',
                transactionComplete: 'Tapahtuma valmis',
                customPostPurchaseImage: 'Lähetä kuva mukautetulle oston jälkeiselle näytölle',
                postPurchaseScreen: 'Oston jälkeinen näyttö',
                custom: 'mukautettu',
                postPurchaseText: 'Oston jälkeinen näyttöteksti',
            },
            automations: {
                transactionReports: 'Tapahtumaraportit',
                refillReports: 'Täydennysraportit',
                notifications: 'Ilmoitukset',
                refillPlan: 'Täydennys suunnitelmat',
                days: 'Päivät',
                months: 'Kuukaudet',
                weeks: 'Viikot',
                configuration: 'Määritys',
                transactionReportTitle: 'Valittujen kaappien tapahtumaraportit luodaan automaattisesti ja lähetetään alla annettuihin sähköpostiosoitteisiin',
                refillReportTitle: 'Valittujen kaappien täydennysraportit luodaan automaattisesti ja lähetetään alla annettuihin sähköpostiosoitteisiin',
                noSalesNotificationTitle:
                    'Ilmoitukset lähetetään automaattisesti alla annettuihin sähköpostiosoitteisiin, jos myyntiä ei ole määritellyillä aikaväleillä',
                frequencyTitle: 'Kuinka usein haluat raportin luotavan?',
                emailNotificationTitle: 'Lisää alla sähköpostiosoitteet, joihin haluat raportin tai ilmoituksen lähetettävän',
                refillPlanGenerationTitle: 'Täydennys suunnitelmat luodaan automaattisesti valituille kaapeille annettujen aikataulujen mukaan',
                addNewEmail: 'Lisää uusi sähköposti',
                noSalesBanner: 'Saat sähköposti-ilmoituksen, jos valitut kaapit eivät tee myyntiä määritellyn aikajakson aikana',
                noSaleTitle: 'Määritä aikaväli (tunneissa), jolloin ei ole myyntiä valituissa kaapeissa ennen ilmoituksen lähettämistä',
                minMaxTime: 'Minimi on 2 ja maksimi 23 tuntia',
                timeRangeTitle: 'Määritä aikaväli kaapeille, jolloin ei-myyntitilannetta tarkastellaan',
                startTime: 'Alkuaika',
                endTime: 'Loppuaika',
                refillPlanTitle:
                    'Valittujen kaappien täydennys suunnitelma luodaan automaattisesti. Määritä alla tarkka aika, jolloin haluat täydennys suunnitelman luotavan.',
                refillPlanTime: 'Valitse aika täydennys suunnitelman luomiseksi',
                everyDay: 'Joka päivä',
                everyWeek: 'Joka viikko',
                everyMonth: 'Joka kuukausi',
                selectDays: 'Valitse päivät',
                automationSaved: 'Automaatioasetukset tallennettu onnistuneesti',
                automationDeleted: 'Automaatioasetukset poistettu onnistuneesti',
            },
            sleepScheduling: {
                timeRangeTitle: 'Määritä aikaväli, jolloin tämä kaappi siirtyy lepotilaan.',
                startTime: 'Alkuaika',
                endTime: 'Loppuaika',
                selectDays: 'Valitse päivät',
                duration: 'Kesto (tunnit)',
            },
        },
        sv: {
            dateRangeFilter: {
                lastMonth: 'senaste 30 dagarna',
                lastTwoWeek: 'senaste 14 dagarna',
                lastWeek: 'senaste 7 dagarna',
                selectDateRangeMsg: 'Valt datumintervall',
                today: 'idag',
                yesterday: 'igår',
            },
            main: {
                activateSuccess: 'Produkten har aktiverats',
                activationDate: 'Aktiveringsdatum',
                active: 'Aktiv',
                addAdvertisement: 'Lägg till reklam',
                addDiscountRule: 'Lägg till rabattregel',
                addDiscountStop: 'Lägg till utgångsintervall',
                addNewLocation: 'Välj ny plats',
                addProduct: 'Lägg till produkt',
                addRegion: 'Lägg till plats',
                addRegionPrice: 'Lägg till platspris',
                addScheduledDiscount: 'Lägg till schemalagd rabatt',
                addBundleDiscount: 'Lägg till paketrabatter',
                bundledDiscounts: 'Paketrabatter',
                bundleItemsLengthExceeded: 'Maximalt antal produkter (3) har uppnåtts. Ta bort några av de valda produkterna för att fortsätta.',
                bundleMaxProductLimitInfo: 'Välj högst tre (3) produkter eller produktkategorier ',
                bundleFixedAmountExceededOriginal: 'Paketpriset överstiger ursprungspris. Vänligen se över valda produkter.',
                bundleFixedAmountNotAllowedToBeZero: 'Paketets fasta pris måste vara över 0',
                bundlePercentageNotAllowedToBeFull: 'Paketets procentrabatt måste vara under 100',
                bundlePercentageNotAllowedToBeZero: 'Paketets procentrabatt måste vara över 0',
                bundleItemDiscountPriceAmountExceededOriginal: 'Det rabatterade priset överstiger det ursprungliga priset. Se över det nya priset igen. ',
                bundleItemPriceMessage: 'Varje prissättningsregel gäller endast en vara, även om flera av samma vara har valts',
                bundleItemFixedAmountExceededOriginal: 'Produktens fasta pris överstiger ursprungspris. Vänligen se över det fasta priset igen.',
                createDiscountRule: 'Skapa rabattregel',
                deviceType: 'Enhetstyp',
                discountUpdateTitle: 'Uppdatera rabatt',
                discountCreateTitle: 'Skapa rabatt',
                discountDeleteTitle: 'Ta bort rabatt',
                discountDeleteContent: 'Rabatt har tagits bort',
                discountStatusUpdated: 'Rabattens status har uppdaterats',
                discountUpdated: 'Rabatten har uppdaterats',
                discountCreated: 'Rabatten har skapats',
                discountSettings: 'Rabattinställningar',
                discountImage: 'Rabattens valfria bild',
                editDiscountStop: 'Redigera utgångsintervall',
                editBundleConfiguration: 'Ändra paketkonfiguration',
                editExpiryConfiguration: 'Redigera utgångsrabattens konfiguration',
                encodingStation: 'Kodningsstation',
                everyDay: 'Varje dag',
                scheduledDiscountDateInfoLabel: 'Lämna start- och slutdatum tomma om du vill göra rabatten permanent',
                starts: 'Startar',
                ends: 'Slutar',
                error: 'Fel',
                errorRetiringLocationMsg: 'Det gick inte att ta bort plats. Ta bort länkade enheter, produktpriser och annonser först.',
                fetchingDiscountRules: 'Hämtar rabattkonfiguration',
                free: 'Gratis',
                missingImage: 'Reklambild saknas',
                next: 'Nästa',
                back: 'Tillbaka',
                noDiscountRules: 'Inga rabattregler hittades',
                scheduledDiscountsAutomaticDeactivationInfo:
                    'Rabatter där datumintervallet överskrids avaktiveras automatiskt. Om du vill göra dem aktiva, redigera datumintervallet så att det är giltigt.',
                scheduledDiscounts: 'Schemalagda rabatter',
                schedulingStartHourIsOverEndHour: 'Starttiden kan inte vara efter sluttiden',
                schedulingEndHourIsUnderStartHour: 'Sluttiden kan inte vara före starttiden',
                selectDiscountRuleProductCategories: 'Välj produktkategorier som påverkas av rabattregeln',
                selectBundleProductsAmount: 'Välj en summa för: ',
                selectedBundleProductCategories: 'Välj produktkategorier för paket',
                setDiscountDays: 'Välj dagar för rabatt',
                setBundleDiscount: 'Ange antingen procentuell rabatt eller fast pris för paketet',
                setBundleItemDiscount: 'Ange något av följande för en vara: procentuell rabatt, fast pris eller gratis',
                setBundleProducts: 'Välj produkter eller kategorier för paket',
                setAmountMoreThanOne: 'Vid paket med en enda artikel, ställ in fler än ett',
                setBundleDiscountType: 'Ställ in rabatttyp och belopp för den',
                sureActivateDiscountRule: 'Är du säker på att du vill aktivera rabattregeln?',
                sureDeactivateDiscountRule: 'Är du säker på att du vill avaktivera rabattregeln?',
                sureDeleteDiscountRule: 'Är du säker på att du vill ta bort rabattregeln?',
                timeRange: 'Tidsintervall',
                editExpiryDiscountRule: 'Ändra rabattregel',
                editScheduledDiscountRule: 'Redigera schemalagd rabattregel',
                editBundleDiscountRule: 'Redigera regel för paketrabatt',
                releaseNotes: 'Releaseinformation',
                seeMore: 'Se mer',
                scheduledDiscountTimeRangeLabel: 'Aktiv tid',
                scheduledDiscountDateRangeLabel: 'Rabattens datumintervall',
                weekDayAbrvMonday: 'Mån',
                weekDayAbrvTuesday: 'Tis',
                weekDayAbrvWednesday: 'Ons',
                weekDayAbrvThursday: 'Tors',
                weekDayAbrvFriday: 'Fre',
                weekDayAbrvSaturday: 'Lör',
                weekDayAbrvSunday: 'Sön',
                recurrence: 'Upprepning',
                addScheduledDiscountStop: 'Lägg till schemalagd rabatt',
                editScheduledDiscountStop: 'Redigera schemalagd rabatt',
                pieces: 'stk',
                expiryDiscountNavLabel: 'Löper ut',
                scheduledDiscountNavLabel: 'Schemalagd',
                bundleDiscountNavLabel: 'Paket',
                amount: 'Summa',
                scheduling: 'Schemaläggning',
                pricing: 'Prissättning',
                percentageBundleDiscountInputPlaceholder: '% rabatt för paket',
                fixedPriceBundleDiscountInputPlaceholder: 'Fast pris för paket',
                applyBundleLevelLabel: 'Använd paketnivå',
                applyItemLevelLabel: 'Använd artikelnivå',
                productFixedPrice: 'Produktens fasta pris',
                percentageDiscount: '% rabatt',
                fixedPriceDiscount: 'Fast pris',
                freeDiscount: 'Gratis',
                advertisementsFetchError: 'Kunde inte hämta reklam på grund av ett fel. Se till att annonsblockeraren är avstängd',
                advertisementsTitle: 'Reklam',
                all: 'Alla',
                'All time': 'All tid',
                allProducts: 'Alla produkter utan gruppering',
                allLocations: 'Alla platser',
                allCabinets: 'Alla skåp',
                antennaDown: 'Några av RFID-antennerna fungerar inte',
                antennaError: 'Fel i RFID-antennerna',
                antennaNoInfo: 'Ingen information om RFID-antennerna',
                antennaOk: 'Alla RFID-antenner är igång',
                antennaPartial: 'Några RFID-antenner är inte igång',
                archiveSuccess: 'Produkten arkiverades',
                averageItems: 'Genomsnittliga varor köpta per transaktion',
                averageSales: 'Genomsnittlig försäljning per transaktion',
                barcode: 'Streckkod',
                barcodeType: 'Streckkodstyp',
                barcodeCannotBeChanged: 'Streckoden kan inte ändras',
                barcodeTypeCannotBeChanged: 'Streckkodstyp kan inte ändras',
                barcodeHelpText12: 'Skriv de 12 första sifforna i streckkoden. Sista numret fylls automatiskt',
                barcodeHelpText11: 'Skriv de 11 första sifforna i streckkoden. Sista numret fylls automatiskt',
                barcodeHelpText7: 'Skriv de 7 första sifforna i streckkoden. Sista numret fylls automatiskt',
                beforeExpiry: 'före utgång',
                cabinetDown: 'Skåpet är inte uppkopplat',
                cabinetIsEmpty: 'Skåpet är tomt',
                deviceOffline: 'Enheten är offline',
                deviceOnline: 'Enheten är online',
                cabinets: 'Skåp',
                cabinet: 'Skåp',
                cabinetSettings: 'Skåpinställningar',
                cabinetTheming: 'Skåpstema',
                cancel: 'Avbryt',
                cancelledSales: 'Avbrutna försäljningar',
                cancelledTransactions: 'Avbrutna transaktioner',
                categories: 'Kategorier',
                change: 'Ändra',
                clear: 'Rensa',
                close: 'Stäng',
                clickEditButtonRegionalPrices: 'Ändra platspriser genom att klicka på produktknappen "Ändra".',
                clickEditLocationPrices: 'Klicka på produktens pris för att se lokala priser.',
                clickToExpandLocationPrices: 'Klicka på produktens pris för att se lokala priser.',
                closeDoor: 'Stäng dörren',
                rebootDevice: 'Starta om enheten',
                commandSentSuccessFully: 'Kommandot har skickats',
                companyLogo: 'Företagslogotyp',
                confirm: 'Bekräfta',
                confirmDelete: 'Är du säker på att du vill ta bort produkten? Denna åtgärd kan inte ångras!',
                confirmDisable: 'Är du säker på att du vill arkivera produkten ?',
                confirmEnable: 'Är du säker på att du vill göra produkten aktiv?',
                consumerResponses: 'Användarfeedback',
                controlPanelTitle: 'Kontrollpanel',
                controls_acceptablePercentageOfBadTags: 'Acceptabel andel av dåliga taggar innan skåpet går ur driftläge',
                controls_markTagsGoodQuality: 'Markera alla RFID-taggar med god kvalitet',
                controls_markTagsGoodQuality_btn: 'Markera RFID-taggar med god kvalitet',
                controls_clearTagHistory: 'Rensa tagghistorik',
                controls_clearRemoveReplenishmentData: 'Rensa påfyllnadsdata',
                controls_companyAndPaymentLogo: 'Företags- och betalningslogga',
                controls_detectTagQuality: 'Detektera RFID-taggarnas kvalitet',
                controls_door: 'Dörrkontroll',
                controls_device: 'Enhetskontroll',
                controls_chargeBadTags: 'Debitera dåliga RFID-taggar',
                controls_refill: 'Påfyllning',
                controls_tagQuality: 'Tagginställningar',
                controls_tagQualityData: 'Kvalitetsdata för RFID-taggar',
                controls_replenishmentData: 'Data om påfyllning',
                controls_soldTags: 'Sålda RFID-taggar',
                controls_tagHistory: 'Tagghistorik',
                controls_markAllTagsReplenished: 'Markera alla RFID-taggar påfyllda',
                controls_markAllTagsUnsold: 'Markera alla RFID-taggar osålda',
                controls_markAlTagsReplenished_btn: 'Markera RFID-taggar påfyllda',
                controls_markAllTagsUnsold_btn: 'Markera RFID-taggar osålda',
                controls_chargeSoldTags: 'Debitera sålda RFID-taggar',
                controls_chargeUnreplenishedTags: 'Debitera ej påfyllda RFID-taggar',
                controls_tagsWarn: 'Rensning av tagghistorik tar bort all data för RFID-taggar. Klicka på markera alla RFID-taggar påfyllda efter denna åtgärd',
                create: 'Skapa',
                createAdTitle: 'Ny reklam',
                createdAd: 'Reklam skapad',
                createExpiryDiscountRule: 'Skapa utgång för rabattregel',
                createScheduledDiscountRule: 'Skapa schemalagd rabattregel',
                createBundleDiscountRule: 'Skapa rabattregel',
                createNewVatCategory: 'Ny momskategori',
                createProductTitle: 'Ny produkt',
                creatingAd: 'Skapar reklam',
                creatingProduct: 'Skapar produkt',
                createNewProductCategory: 'Skapa en ny produktkategori',
                createNewSupplier: 'Lägg till ny leverantör',
                currency: 'Valuta',
                customQuery: 'Anpassad sökning',
                dashboard: 'Översikt',
                dateRange: 'Datumintervall',
                dataAlredyFetchedTitle: 'Data har redan hämtats',
                dataAlreadyFetchedDescription: 'Välj olika parametrar',
                day: 'Dag',
                days: 'Dagar',
                deactivationDate: 'Avaktiveringsdatum',
                default: 'Standard',
                delete: 'Radera',
                deleteError: 'Fel: produkten kunde inte raderas',
                deleteSuccess: 'Produkten har tagits bort',
                description: 'Beskrivning',
                deviceManagement: 'Enhetshantering',
                digitwinOffline: 'Enhets digitwin är offline',
                digitwinOnline: 'Enhets digitwin är online',
                dimensions: 'Mått',
                disable: 'Arkiv',
                discountAmount: 'Rabattbelopp',
                discountPrice: 'Rabattpris',
                discountRuleAffects: 'Rabattregeln påverkar',
                discountRuleName: 'Rabattregelns namn',
                discountRuleStops: 'Utgångsintervall',
                discounts: 'Rabatter',
                discountSalesData: 'Rabattförsäljningsdata',
                discountSalesAmount: 'Summa för rabattförsäljning',
                discountTimeBeforeExpiryExists: 'Tidigare utgångsintervall finns redan',
                discountVsNormalSales: 'Rabatt vs normal försäljning',
                dismiss: 'Ok',
                doesNotExist: 'Existerar inte',
                doorCloseConfirm: 'Är du säker att du vill stänga dörren?',
                doorCloseSuccess: 'Dörren har låsts',
                doorOpenConfirm: 'Är du säker att du vill öppna dörren?',
                doorOpenReason: 'Varför öppnas dörren?',
                doorOpenSuccess: 'Dörren har öppnats',
                rebootDeviceSuccess: 'Enheten har startats om',
                deviceRebootConfirm: 'Är du säkert at du vill starta om enheten?',
                edit: 'Ändra',
                editCompanyLogo: 'Redigera företagslogga',
                editDiscountRule: 'Redigera rabattregel',
                editPaymentMethodLogo: 'Redigera logga för betalningsmetod',
                editVatCategoryModalTitle: 'Redigera momskategori',
                editProductCategoryModalTitle: 'Redigera produktkategori',
                editSupplierModalTitle: 'Redigera leverantör',
                enable: 'Gör aktiv',
                error_401: 'Inte autentiserad',
                error_403: 'Inte autentiserad för att använda kommandot',
                error_500: 'Oväntat server fel',
                errorCreatingAd: 'Kunde inte skapa reklam på grund av ett fel',
                errorCreatingProduct: 'Kunde inte skapa produkt på grund av ett fel',
                errorFetchingCabinetStatus: 'Kunde inte hämta skåpstatus på grund av fel',
                errorFetchingInventory: 'Kunde inte hämta inventarielistan på grund av ett fel',
                errorFetchingReport: 'Kunde inte hämta rapporter på grund av ett fel',
                errorFetchingSettings: 'Kunde inte hämta inställningar på grund av ett fel',
                errorUpdatingProduct: 'Kunde inte uppdatera produkt på grund av ett fel',
                expandToSeeBadTags: 'Expandera listan för att se taggar som har upptäckts som dåliga. Dåliga taggar måste tas bort från skåpet.',
                expandToSeeSoldItems: 'Expandera listan för att se de produkter som redan sålts men har återkommit i inventeringen.',
                expandToSeeReplenishedOutOfInventory: 'Expandera listan för att se de produkter som har placerats i skåpet utanför skåpets påfyllningsprocess',
                expired: 'Utgånget',
                expiredCount: 'Antal utgångna',
                expiredProductsCount: 'Antal utgångna produkter',
                expiredSum: 'Summa för utgångna',
                expiring: 'Löper ut',
                expiryDate: 'Sista försäljningsdagen',
                exportExcel: 'Ladda ner Excel-ark',
                fetchAdvertisements: 'Hämtar reklam',
                fetchingCabinets: 'Hämtar skåp',
                fetchingCabinetsError: 'Kunde inte hämta skåpen på grund av ett fel',
                fetchingCabinetStatus: 'Hämtar skåpstatus',
                fetchingError: 'Fel vid hämtning av data',
                fetchingInventory: 'Hämtar inventarielistan',
                fetchingProducts: 'Hämtar produkter',
                fetchingReport: 'Hämtar rapporter',
                fetchingVatCategories: 'Hämtar momskategorier',
                fetchingProductCategories: 'Hämtar produktkategorier',
                fillForm: 'Fyll i formuläret',
                freshFood: 'Detta är en utgående produkt',
                freezerPackage: 'Fryst produkt',
                generalFetchError: 'Hämtningsfel',
                generalRequestReject: 'Fel vid begäran',
                get: 'Hämta',
                group: 'Grupp',
                holdOn: 'Vänta',
                hour: 'Timme',
                hours: 'timmar',
                hoursBeforeExpiry: 'Timmar före utgång',
                daysBeforeExpiry: 'Dagar innan utgången',
                image: 'Bild',
                imageOrVideo: 'Välj bild eller video',
                inActive: 'Inaktiv',
                individualCabinets: 'Enskilda skåp',
                individualCabinetsOnly: 'Endast enskilda skåp',
                invalidBarcodeOnlyDigits: 'Streckkoden kan bara innehålla siffor',
                invalidBarcodeTooLong: 'Streckkoden är för lång',
                invalidBarcodeTooShort: 'Streckkoden är för kort',
                invalidCabinetNameTooShort: 'Skåpets namn är för kort',
                invalidCabinetNameDuplicated: 'Skåpets namn finns redan',
                invalidLocationNameTooShort: 'Platsens namn är för kort',
                invalidLocationNameDuplicated: 'Platsens namn finns redan',
                invalidPercentage: 'Ogiltigt värde',
                invalidPrice: 'Ogiltigt pris',
                invalidProductBarcode: 'Produktens streckkod är ogiltig',
                invalidTitle: 'Titeln är ogiltig',
                invalidBundleItems: 'Paketet innehåller ogiltiga artiklar',
                inventoryLength: 'Lagerantal',
                inventoryNotReported: 'Skåpet har inte rapporterat inventariet',
                inventoryTitle: 'Inventarielista',
                'Inventory History': 'Inventariehistorik',
                itemReplenishedOutOfInventoryProcess: 'Denna produkt eller tagg har placerats i skåpet i en annan process än påfyllningsprocessen',
                itemSoldOn: 'Produkten såldes den',
                'Last 30 days': 'Senaste 30 dagarna',
                'Last 7 days': 'Senaste 7 dagarna',
                'Last month': 'Förra månaden',
                'Last quarter': 'Förra kvartalet',
                'Last week': 'Förra veckan',
                'Last year': 'Förra året',
                lastUpdate: 'Senaste uppdateringen',
                lifetime: 'Livstid',
                location: 'Plats',
                locations: 'Regioner',
                logoEditor: 'Logotypredigerare',
                logoSettings: 'Logotypinställningar',
                loading: 'Läser in…',
                measure: 'Mått',
                measures: 'Mått',
                minsBeforeExpiry: 'Minuter före utgång',
                minutes: 'minuter',
                month: 'Månad',
                name: 'Namn',
                newVatCategoryModalTitle: 'Ny momskategori',
                newProductCategoryModalTitle: 'Ny produktkategori',
                newSupplierModalTitle: 'Ny leverantör',
                'no region': '(ingen region)',
                noAdvertisements: 'Ingen reklam',
                noCabinets: 'Inga skåp',
                noCabinetSelected: 'Inget valt skåp',
                noLogo: 'Ingen logga',
                noData: 'Inga data',
                noDescription: 'Ingen beskrivining',
                noEvents: 'Inga händelser!',
                noIndividualCabinets: 'Inga enskilda skåp',
                noLocationPrices: 'Produkten har inte lokala priser',
                none: 'Ingen',
                noPaymentMethodLogo: 'Ingen logga för betalningsmetod',
                noProducts: 'Inga produkter',
                noProductsRemoved: 'Inga produkter har tagits bort från skåpet',
                noProductTypeSelected: 'Pakettyp har inte valts',
                normalPrice: 'Ordinarie pris',
                normalSalesAmount: 'Summa för ordinarie försäljning',
                notAccessibleForYou:
                    'Funktionen Skapa/Uppdatera är endast aktiverad för administratörer och operatörer. Kontakta din chef eller systemadministratör för att använda funktionen.',
                notDefined: 'Inte definierad',
                noTitle: 'Ingen titel',
                noVatCategories: 'Hittade ingen post för momskategori',
                noProductCategories: 'Hittade ingen post för produktkategori',
                noInventoryOnSelectedDate: 'Inventarie rapporterades inte för det valda datumet. Prova ett annat datum.',
                openDoor: 'Öppna dörren',
                orderSum: 'Orderbelopp',
                paymentMethodLogo: 'Logga för betalningsmetod',
                percentage: 'Procent',
                permanent: 'Permanent',
                preview: 'Förhandsvisning',
                price: 'Pris',
                print: 'Skriv ut',
                productCreated: 'Ny produkt skapad',
                products: 'Produkterna',
                productsFetchError: 'Kunde inte hämta produkterna på grund av ett fel',
                productsTitle: 'Produktkatalog',
                productType: 'Pakettyp',
                productUpdated: 'Produkt uppdaterat',
                quantity: 'Kvantitet',
                readerDown: 'RFID-läsare fungerar inte',
                region: 'Plats',
                regions: 'Platser',
                removedAt: 'Borttagen',
                removeImage: 'Ta bort bild',
                removeVideo: 'Ta bort video',
                refillQRCodeInfo: 'Skanna QR-koden för att öppna påfyllningsapplikationen eller följ',
                refillQRCodeTitle: 'QR-kod för påfyllning',
                refillRuleTitle: 'Påfyllningsregel',
                reportsTitle: 'Rapporter',
                requestSuccess: 'Ändringarna har genomförts',
                requiredPercentage: 'Procentvärde utan procenttecken krävs.',
                requiredText: 'Krävd längd på tecken: ',
                retireLocation: 'Avyttra plats',
                rfidNoInfo: 'Ingen information från RFID-läsare och antenner',
                rfidOk: 'RFID-läsare och antenner fungerar som förväntat.',
                sales: 'Försäljning ',
                salesCount: 'Försäljningsantal',
                salesVsWasteAmount: 'Försäljning mot avfallsmängd',
                save: 'Spara',
                search: 'Sök',
                selectCabinet: 'Välj ett skåp',
                selectDiscountRuleCabinets: 'Välj skåpen som påverkas av rabattregel',
                selectDiscountRuleLocations: 'Välj platserna som påverkas av rabattregel',
                selectDiscountRuleProducts: 'Välj produkterna som påverkas av rabattregel',
                selectProductType: 'Vänligen välj förpackningstyp för din produkt',
                setDiscountAmount: 'Fyll i rabattpelopp',
                setDiscountTimeBeforeExpiry: 'Ange utgångsintervall',
                settingConfirmMessage: 'Är ni säkra att ni vill spara dessa inställningar?',
                showDisable: 'Arkiverade produkter',
                summary: 'Sammanfattning',
                sureRemoveDiscountRule: 'Är ni säkra att ni vill radera rabattregeln?',
                tagIsBad: 'RFID-taggen är dålig och måste tas bort från skåpet.',
                tagsRemovedReport: 'Rapport för borttagna taggar',
                taxValue: 'Momsbelopp',
                'This month': 'Den här månaden',
                'This quarter': 'Detta kvartal',
                'This week': 'Denna vecka',
                'This year': 'Det här året',
                thisLink: ' den här länken. ',
                timeGrouping: 'Tidsgruppering',
                title: 'Titel',
                Today: 'Idag',
                topExpiredProducts: 'De mest utgångna produkterna',
                topProducts: 'Topprodukter',
                total: 'Total',
                totalValue: 'Totala värdet',
                transactions: 'Transaktioner',
                ttlInDays: 'Produktens livslängd i dagar',
                ttlsAffected: 'Produkter med längslängd som påverkas',
                unexpectedError: 'Oväntat fel',
                uniqueCustomers: 'Unika kunder',
                unknown: 'Okänd',
                update: 'Uppdatera',
                updateAdError: 'Kunde inte uppdatera reklam på grund av ett fel',
                updateAdTitle: 'Uppdatera reklam',
                updatedAd: 'Reklam uppdaterad',
                updateCabinetSettings: 'Synkronisera data till skåpet',
                updateCabinetSettingsInfo: 'Är du säker på att du vill uppdatera skåpinställningarna?',
                updateData: 'Uppdatera data',
                updateProductTitle: 'Uppdatera produkt',
                updatingAd: 'Uppdaterar reklam',
                updatingProduct: 'Uppdaterar produkt',
                uploading: 'Laddar upp',
                uploadingImage: 'Laddar upp bild',
                use: 'Använd',
                'w/o grouping': 'Ingen gruppering',
                waste: 'Avfall',
                vatCategoriesFetchError: 'Kunde inte hämta momskategorierna på grund av ett fel',
                vatCategoriesTitle: 'Momskategorier',
                vatCategory: 'Momskategori',
                vatCategoryItems: 'Artiklar',
                vatCategoryName: 'Momskategorinamn',
                vatCategoryPromptMessage: 'Är ni säkra att ni vill radera momskategorin',
                vatCategoryValue: 'Momsprocent',
                vatDeleteRejected: 'Radering av momskategori nekades. Kontrollera tillhörande produkter.',
                vatErrorRequesting: 'Fel i behandlingsbegäran',
                vatRequestRejected: 'Begäran avslogs',
                vats: 'Moms',
                productCategoriesFetchError: 'Fel vid hämtning av produktkategorier',
                productCategoriesTitle: 'Produktkategorier',
                productCategory: 'Produktkategori',
                productCategoryItems: 'Kategorier',
                productCategoryName: 'Produktkategorinamn',
                productCategoryPromptMessage: 'Är du säker på att du vill ta bort produktkategorin?',
                productCategoryDescription: 'Produktkategoribeskrivning',
                productCategoryHelpTextDescription: 'Till exempel kategori för sallader',
                productCategoryHelpTextName: 'Till exempel sallader',
                productCategoryDeleteRejected: 'Radering av produktkategori nekades. Kontrollera tillhörande produkter!',
                week: 'Vecka',
                viewMode: 'Se',
                year: 'År',
                Yesterday: 'Igår',
                groupBy: 'Gruppera efter',
                filterBy: 'Filtrera efter',
                reportType: 'Rapporttyp',
                custom: 'Anpassat',
                preDefined: 'Fördefinierad',
                selected: 'Vald',
                metricsInfo: 'Mätvärden (summa, maximum, minimum...) beräknas för alla valda mått/resurser',
                dimensionsInfo: 'Mätvärden beräknas för valda mått/resurser',
                metrics: 'Mätvärden',
                controlsSoftwareVersion: 'Programversion',
                currentSoftwareVersion: 'Nuvarande programversion',
                availableSoftwareVersion: 'Tillgängliga programvaruversioner',
                invalidLifetime: 'Ogiltigt livslängdsvärde',
                unpaidSales: 'Obetald försäljning',
                unpaidTransactions: 'Obetalda transaktioner',
                softwareUpdateConfirmation: 'Är du säker på att du vill uppdatera programvaruversionen på de valda enheterna?',
                softwareUpdateWarn:
                    'Om du uppdaterar programvaruversionen skickas ett kommando till enheterna att hämta uppdateringen nu eller vid den valda tidpunkten. De valda enheterna installerar uppdateringen nästa gång de är online, efter utlösaren har aktiverats. Installationen kan ta lite tid beroende hur snabb uppkopplingen är. Enheten startas om efter installationen.',
                releaseVersion: 'Programversionerna',
                releaseDate: 'Utgivningsdatum',
                latestRelease: 'Nyaste programversionen',
                currentVersion: 'Aktuella programversionen',
                softwareUpdateSuccess: 'Programversionen har uppdaterats',
                prebooking: 'Förbokning',
                prebookedProduct: 'Förbokade produkt',
                prebookedProducts: 'Förbokade produkter',
                activeProducts: 'Aktiva produkter',
                startMaintenanceMode: 'Starta underhållsläge',
                endMaintenanceMode: 'Avsluta underhållsläge',
                startMaintenanceConfirm: 'Är du säkert att du vill starta underhållsläge?',
                endMaintenanceConfirm: 'Är du säkert att du vill avsluta underhållsläge?',
                startMaintenanceSuccess: 'Underhållsläge startades för skåpet',
                endMaintenanceSuccess: 'Underhållsläget avslutades för skåpet',
                noResults: 'Inga resultat',
                suppliers: 'Leverantörer',
                supplier: 'Leverantör',
                supplierDeleteRejected: 'Radering av leverantör nekades. Kontrollera tillhörande produkter!',
                supplierPromptMessage: 'Är du säker på att du vill ta bort leverantören?',
                fetchingSuppliers: 'Hämtar leverantörer',
                noSuppliers: 'Inget leverantörsregister hittades',
                suppliersFetchError: 'Fel vid hämtning av leverantörer',
                supplierName: 'Leverantörsnamn',
                supplierHelpTextName: 'Till exempel ett varumärke',
                supplierDescription: 'Leverantörsbeskrivning',
                supplierHelpTextDescription: 'Till exempel ett varumärkes beskrivning',
                wishes: 'Önskemål',
                checkNow: 'Kolla nu',
                startTimeOn: 'Starttid på',
                endTimeOn: 'Sluttid på',
                signOutConfirmationModalTitle: 'Logga ut, bekräftelse',
                signOutConfirmationModalContent: 'Är du säker på att du vill logga ut?',
                startDate: 'Startdatum',
                endDate: 'Slutdatum',
                scheduledDiscountApplyAllDays: 'Tillämpar för alla dagar',
                scheduledDiscountApplySelectedDays: 'Tillämpar för valda dagar',
                discountCustomImageTitle: 'Rabattbild (valfritt)',
                discountCustomImageModalHeader: 'Bild för rabatt',
                saveChanges: 'Spara ändringar',
                updateTagsQualitySettingsTitle: 'Inställningar för taggkvalitet',
                updateTagsQualitySettingsMessage: 'Uppdaterade inställningar för taggkvalitet',
                IF3Cabinets: 'Modell 3 kylskåp',
                IF2Cabinets: 'Modell 2 kylskåp',
                IF3Freezers: 'Modell 3 frysar',
                encodingStations: 'Kodningsstationer',
                if2: 'Modell 2 kylskåp',
                if3: 'Modell 3 kylskåp',
                if3Freezer: 'Modell 3 frys',
                legalNotice: 'Juridiskt meddelande',
                privacyPolicy: 'Integritetspolicy',
                scheduledDiscountDateMissingError: 'Båda datumen behövs om start- eller slutdatum är inställt',
                scheduledDiscountDuplicateTimeError: 'Tiderna kan inte vara desamma om datumen är samma',
                now: 'Nu',
                minimum: 'Minst',
                maximum: 'Högst',
                discardChangesConfirmationText: 'Är du säker på att du vill kasta ändringarna?',
                exports: 'Exporter',
                selectAllCabinets: 'Välj alla skåp',
                expiryIntervals: 'Utgångsintervall',
                timeBeforeExpiry: 'Tid före utgång',
                apply: 'Tillämpa',
                yes: 'Ja',
                no: 'Nej',
                productOnScreenBannerText: 'Informationsbannertext på skärmen',
                productOnScreenBannerAdditionalInfo:
                    'Bannertext är en text på en rad som visas under produktbilden och som på ett kortfattat sätt förmedlar nyckelfunktioner, hälsofördelar eller annan ytterligare information om produkten',
                productOnScreenBannerExampleText: 'Laktosfri',
                UI_IS_DOWN: 'Skåpdisplayen fungerar inte',
                READER_IS_DOWN: 'RFID-läsaren är nere',
                ANTENOR_DEVICE_DISCONNECTED: 'Betalningsenheten är frånkopplad',
                EXPIRED_PRODUCT: 'Utgångna produkter i skåpet',
                CLOUD_SERVICE_DOWN: 'Nedkopplad molntjänst',
                ANTENNA_DISCONNECTED: 'Antennen är frånkopplad',
                TAG_CHANGES: 'För många felaktiga taggar i skåpet',
                ANTENNA_DOWN: 'RFID-antennen i skåpet fungerar inte',
                READER_CONFIG_ERROR: 'Konfigurationen för RFID-läsaren misslyckades och fungerar inte',
                activeAlarms: 'Aktiva larm',
                deviceStatus: 'Enhetens status',
                lastMonitored: 'Senast övervakad',
                noService: 'Utan service',
                operational: 'Operativ',
                offline: 'Offline',
                locationsSelectHelp: 'När en plats läggs till kommer dess underordnade platser också att läggas till.',
                select: 'Välj...',
                labels: 'Markeringar',
                productDetails: 'Produktdetaljer',
                invalidProductName: 'Produktnamn bör vara 1 till 125 tecken långt',
                standardPrice: 'Standardpris',
                locationPrices: 'Platspriser',
                addNew: 'Lägg till ny',
                productExpiry: 'Produktens utgångsdatum',
                productLifeTime: 'Produktlivstid',
                invalidTTL: 'Produktens livslängd måste vara ett heltal mellan 0-32767',
                productImage: 'Produktbild',
                uploadImage: 'Ladda upp bild',
                pleaseUploadImage: 'Ladda upp bild',
                productValidationError: 'Åtgärda följande fel innan du sparar produkten',
                productSupplier: 'Produktleverantör',
                frozenProductLable: 'Detta är en fryst produkt',
                prebookingLabel: 'Detta är en förbokningsprodukt',
                previous: 'Föregående',
                bannerText: 'Bannertext',
                packagingTypeValidationError: 'Välj produktförpackningstyp',
                showOnlyFrozen: 'Visa endast frysta produkter',
                productClassification: 'Produktklassificering',
                productClassLabel:
                    'Vänligen välj rätt klass för produkten. Detta gör det möjligt för Selfly Insights att tillhandahålla mer relevant produktinformation.',
                productLabels: 'Produktetiketter',
                productLabelsHelpText:
                    'Vänligen lägg till valfria etiketter till din produkt. Detta gör att du kan filtrera dina produkter i produktkatalogen.',
                newLabel: 'Ny etikett',
                productClassValidationError: 'Vänligen välj en klassificering för din produkt från alternativen nedan',
                tagSensitivity: 'Taggens känslighetsnivå',
                tagSensitivitySetting: 'Inställning för taggens känslighet',
                configTagSensitivity: 'Konfigurera taggens känslighet för produkten',
                productLevelSensitivityInfo:
                    'Läsbarheten för en RFID-tagg som är fäst vid en produkt kan påverkas av olika faktorer, inklusive förpackningsmaterial, metalliska ytor, fukt och själva produktens innehåll. Dessa faktorer kan ibland orsaka intermittent taggsignal, känd som blinkande. Skåpet bearbetar sådant blinkande baserat på sina känslighetsinställningar. Om du är osäker på vilken känslighetsnivå som är lämplig för denna produkt kan du hoppa över detta avsnitt.',
                errorSaving: 'Fel när produktdata skulle sparas',
                successSaving: 'Produktinformation har sparats',
                basicDetail: 'Grundläggande detaljer',
                additionalDetail: 'Ytterligare detaljer',
                packaging: 'Förpackningstyp',
                classification: 'Klassificering',
                selectOrtype: 'Välj eller skriv...',
                max25Chars: 'max 25 tecken',
                noVatForProductCreation: 'Skapa en momskategori innan du lägger till nya produkter',
                lifeTimeExplanation: 'Hur många dagar är den här produkten på rea. (0 dagar) betyder fram till slutet av den aktuella dagen.',
                selflyEncode: 'Selfly Encode',
                userRestrict: 'Endast erkända konsumenter',
                userRestrictTitle: 'Berättigade konsumenter',
                userNotRestrict: 'Alla konsumenter',
                userRestrictHelperText:
                    'Alternativet erkända konsumenter innebär att rabatten endast kommer att tillämpas på konsumenter som redan är registrerade för rabattberättigande!',
                searchCabinets: 'Sök skåp',
                selectAll: 'Välj alla',
                monday: 'Måndag',
                tuesday: 'Tisdag',
                wednesday: 'Onsdag',
                thursday: 'Torsdag',
                friday: 'Fredag',
                saturday: 'Lördag',
                sunday: 'Söndag',
                fetchingLoader: 'Hämtar. Vänta...',
                category: 'Kategori',
                okay: 'Okej',
                noLocation: 'Ingen plats',
                copy: 'Kopiera',
                pleaseConfirm: 'Vänligen bekräfta',
                saveQuery: 'Spara denna fråga',
                saveQueryPlaceholder: 't.ex. "Alla sålda artiklar"',
                saveQuerySuccess: 'Frågan är sparad.',
                yourSavedQueries: 'Dina sparade frågor',
                selectQuery: 'Välj fråga',
                noQuery: 'Det finns inga sparade frågor än',
                deleteAdConfirm: 'Är du säker på att du vill radera denna annons?',
                dataSaved: 'Data sparades framgångsrikt',
            },
            refill: {
                refillRuleApplyRuleDropdownText: 'Tillämpa denna regel även på andra skåp',
                refillPlanGenerate: 'Skapa påfyllningsplan',
                refillPlanEditPlan: 'Redigera plan',
                refillAmount: 'Påfyllningsmängd',
                refillRuleTargetAmount: 'Mållagersaldo',
                refillRuleTargetAmountShort: 'Mål',
                refillPlanActivePlans: 'Aktiva planer',
                refillPlanCompletedPlans: 'Färdiga planer',
                refillNote: 'Påfyllningsanteckning',
                refillNoteHelp: 'Produktpåfyllningsanteckning (max 125 tecken)',
                refillPDFText:
                    'Nedan finns påfyllningsplanen för skåpet. Se till att markera påfyllningsplanen som slutförd i påfyllningsapplikationen när du har avslutat påfyllningen.',
                refillPDFSummaryText: 'Nedan finns en sammanfattning av påfyllningsplaner med produkter att lägga till och ta bort för alla valda skåp.',
                refillSummary: 'Påfyllningssammanfattning',
                addItems: 'Lägg till produkter',
                removeItems: 'Ta bort produkter',
                inventoryChange: 'Inventarieförändring',
                planogramPDFText: 'Planogram för strategin för påfyllning av skåpet.',
                planogramImagePDFText: 'Anpassad bild för strategin för påfyllning av skåpet.',
                refillSchemas: 'Påfyllningsmallar',
                refillPlans: 'Påfyllningsplaner',
                refillSchemaTitle:
                    'Ange mål- och minimilagernivåer som en mall för valda skåp. Dessa mallar kommer att användas för att skapa påfyllningsplaner.',
                refillPlansTitle:
                    'Visa alla aktiva planer. Du kan redigera eller ta bort befintliga aktiva planer, eller lägga till nya påfyllningsplaner vid behov.',
                refillSchemaSubText: 'Välj ett skåp för att skapa eller uppdatera påfyllningsmallen.',
                noRefillSchema: 'Ingen påfyllningsmall har definierats för detta skåp ännu.',
                createRefillSchema: 'Skapa en ny påfyllningsmall',
                noDevice: 'Ingen enhet hittades',
                schemaSaved: 'Påfyllningsmallen sparades framgångsrikt',
                deleteSchemaConfirmation: 'Är du säker på att du vill ta bort påfyllningsmallen?',
                targetAmount: 'Målmängd',
                targetAmountHelpText: 'Mängden mål lager för varje produkt i skåpet efter påfyllning.',
                minimumThreshold: 'Minimigräns',
                minimumThresholdHelpText:
                    'Ange den minsta lagernivån för varje produkt i skåpet. Om kvantiteten faller under den definierade gränsen får du ett larmmeddelande. Att ställa in gränsen på 0 inaktiverar aviseringar.',
                editNote: 'Redigera anteckning',
                refillNoteTitle: 'Skriv en påfyllningsanteckning för produkten.',
                refillNoteCharacters: 'tecken',
                noRefillPlan:
                    'Det finns för närvarande inga aktiva påfyllningsplaner. Lägg till nya påfyllningsplaner för dina skåp eller skapa först en påfyllningsmall för skåpen.',
                planSaved: 'Påfyllningsplanen sparades framgångsrikt',
                deleteConfirmation: 'Är du säker på att du vill ta bort de valda påfyllningsplanuppgifterna?',
                editPlan: 'Redigera plan',
                currentInventory: 'Nuvarande lager',
                lastSevenDaySales: 'Försäljning de senaste sju dagarna',
                inventory: 'Lager',
                planogram: 'Planogram',
                copyRefillSchema: 'Kopiera mallen till andra skåp',
                generatingPlans: 'Genererar påfyllningsplaner. Vänta...',
                generatePlans: 'Generera påfyllningsplaner',
                complete: 'komplett',
                selectCabinetsToAddPlans: 'Vänligen välj skåp för att generera påfyllningsplaner.',
                reviewSuggestion:
                    'Nedan visas de föreslagna påfyllningsplanerna för de valda skåpen. Granska, redigera vid behov och spara dem för att aktivera dessa planer.',
                noSchema: 'Inga skåp har för närvarande en påfyllningsmall. Lägg till en påfyllningsmall för dina skåp för att generera påfyllningsplaner.',
                searchProducts: 'Sök produkter',
                maxAllowedInventory: 'Maximalt tillåtet lager är 200.',
                savingData: 'Sparar data. Vänta...',
                errorSavingData: 'Fel vid sparning av data.',
                refillPlanSaved: 'Påfyllningsplanerna genererades framgångsrikt.',
                exportPDFHelp: 'Exportera valda planer till PDF-fil.',
                exportExcelHelp: 'Exportera valda planer till Excel-fil.',
                addPlanHelp: 'Lägg till nya påfyllningsplaner.',
                deletePlanHelp: 'Ta bort valda påfyllningsplaner.',
                generatePlansHelp: 'Generera påfyllningsplaner för de valda skåpen.',
                planogramSaved: 'Planogram sparades framgångsrikt.',
                schemaCopied: 'Påfyllningsmallen synkroniserades framgångsrikt med de andra skåpen.',
                allProducts: 'Alla produkter',
                schemaProducts: 'Påfyllningsmallens produkter',
                deletePlanogram: 'Är du säker på att du vill ta bort planogrammet?',
                dragInPlanogram: 'Vänligen dra och släpp produkter på de tilldelade skåpshyllorna, eller dra skåpsplatsen över produkten.',
                noPlanogramInSchema: 'Ett planogram har ännu inte definierats för skåpet.',
                noPlanogramInPlan: 'Inga planogramdata är tillgängliga för skåpet. Vänligen skapa ett planogram för skåpet i påfyllningsmallfliken först.',
                copyPlanogram: 'Kopiera påfyllningsplanogram tillsammans med påfyllningsmallen.',
                planogramImageInfo: 'Förutom planogrammet kan du också lägga till en anpassad planogrambild.',
                addPlanogramImage: 'Lägg till planogrambild',
                editPlanogramImage: 'Redigera planogrambild',
                numberOfShelves: 'Antal hyllor',
                addNewRefillPlan: 'Lägg till nya planer',
                qrcodeTitle: 'En QR-kod genereras för det valda skåpet för att starta påfyllningsprocessen.',
                qrcodeSubText: 'Välj ett skåp där du vill starta påfyllningsprocessen.',
                qrcodeScan: 'Skanna QR-koden för att fylla på, eller klicka på knappen för att öppna påfyllningsapplikationen.',
                qrcodeOpenApplication: 'Öppna påfyllningsapplikation',
                createRefillPlanogram: 'Skapa påfyllningsplanogram',
            },
            navigation: {
                advertisements: 'Reklam',
                analytics: 'Analys',
                controlPanel: 'Kontrollpanel',
                deviceManagement: 'Enhetshantering',
                discounts: 'Rabatter',
                fetching: 'Hämtar',
                group: 'Grupp',
                inventory: 'Inventarielista',
                language: 'Språk',
                productCatalog: 'Produktkatalog',
                productCategories: 'Produktkategorier',
                reports: 'Rapporter',
                settings: 'Användarinställningar',
                signOut: 'Logga ut',
                title: 'Selfly Cloud Portal',
                vatCategories: 'Momskategorier',
                community: 'Selfly Community',
                refillManagement: 'Påfyllnadsadministration',
                automations: 'Automatiseringar',
                mySelfly: 'Mina Selfly-inställningar',
                automationInfo: 'Hantera automatiserade åtgärder (BETA)',
            },
            reports: {
                alreadyRefund: 'återbetalades',
                amount: 'Mängd',
                average: 'Genomsnitt',
                barcode: 'Streckkod',
                confirmIssueRefundButton: 'Bekräfta återbetalning nu',
                current: 'Nuvarande',
                export: 'Exportera',
                fetching: 'Hämtar',
                fetchingError: 'Kunde inte hämta på grund av fel',
                image: 'Bild',
                issueRefundButton: 'Återbetalning',
                maximum: 'Maximal',
                minimum: 'Minimum',
                name: 'Namn',
                noData: 'Inga händelser',
                note: 'Anteckning',
                order: 'Transaktion',
                paymentWasDoneByCreditCard: 'Betalning gjord med kreditkort',
                price: 'Pris',
                ProductReport: 'Produktrapport',
                quantity: 'Mängd',
                'Refund impossible': 'Betalning gjord med kreditkort. Kontakta kreditkortets terminalleverantör för återbetalning',
                refundFormTitle: 'Återbetalning',
                refundReason: 'Anledning till återbetalning',
                refundSuccess: ' {{sum}} återbetalades till kunden',
                refundTotal: 'Återbetalningssumma',
                replenishments: 'Påfyllningar',
                replenishmentsTitle: 'Påfyllningsrapport',
                SaleTransaction: 'Försäljningstransaktion',
                summary: 'Sammanfattning',
                SummaryReport: 'Försäljningssammanfattning',
                temperature: 'Temperatur',
                temperatureTitle: 'Temperaturrapport',
                temperatureValueNotification: 'Temperaturvärdena är skåpets genomsnittliga temperatur per timme.',
                time: 'Tid',
                total: 'Total',
                transactions: 'Transaktioner',
                user: 'Användare',
                paidTransactions: 'Betalda transaktioner',
                unpaidTransactions: 'Obetalda transaktioner',
                partiallyPaid: 'Delvis betald',
                failedTransactions: 'Misslyckade transaktioner',
                amountOwed: 'Utestående belopp',
                receiptLinkText: 'Hämta kvitto härifrån',
                noPurchase: 'Inget köp',
                other: 'Övrig',
                userId: 'Användar-ID:',
            },
            settings: {
                block_cabinet_when_product_expired: 'Blockera skåpet för utgångna produkter',
                criticalExpiryLimitHours: 'Timmar före larm om kritisk utgång',
                language: 'Språk',
                logo: 'Företagslogga',
                mediumExpiryLimitHours: 'Timmar före larm om medelallvarlig utgång',
                payment_method_logo: 'Logga för betalningsmetod',
                footer_instruction_logo: 'Bild på sidfotsinstruktioner',
                readUserMemory: 'Hämta produkternas utgångsinformation',
                ui_skus_sort_by: 'Produktsorteringsordning i skåpsdisplayen',
                timeZone: 'Tidszon',
                country: 'Land',
                city: 'Ort',
                tagSettings: 'Tagginställningar',
                themeAndLogos: 'Tema och logotyper',
                advancedSettings: 'Avancerade inställningar',
                themingInfoText: 'Du kan anpassa temat för din skåpskärm',
                cabinetLogos: 'Skåplogotyper',
                cabinetLogoInfoText: 'Ladda upp logotyper som visas på skåpets skärm',
                logoHelperText: 'Använd en bild som är 180x180 px',
                settingsCopyInfoHeader: 'Välj skåp som du vill kopiera inställningarna till',
                settingsCopyButton: 'Kopiera inställningar till valda skåp',
                settingsCopySuccessModalTitle: 'Kopiering av inställningar',
                settingsCopySuccessModalContent: 'Inställningarna har kopierats till {{cabinetsAmount}} skåp',
                theming: 'Teman',
                adminSettings: 'Admininställningar',
                settingsCopyErrorModalTitle: 'Fel vid kopiering av inställningar',
                key: 'Inställning',
                value: 'Värde',
                sleepScheduling: 'Schemalagd standby',
                sensitivityLevel: 'Känslighetsnivå',
                sensitivity_0:
                    'Skåpet ignorerar taggens flimrande och markerar konsekvent alla taggar som giltiga, vilket ibland kan leda till felaktig debitering.',
                sensitivity_20: 'Skåpet tolererar upp till tre felaktiga avläsningar för en tagg under 50 skanningar och markerar ändå taggen som giltig.',
                sensitivity_40: 'Skåpet tolererar upp till två felaktiga avläsningar för en tagg under 60 skanningar och markerar ändå taggen som giltig.',
                sensitivity_60: 'Om en tagg flimrar ofta men förblir stabil under en längre period tolererar skåpet detta och markerar taggen som giltig.',
                sensitivity_80: 'Om en tagg flimrar tillfälligt men förblir stabil under en längre period kommer den att betraktas som giltig.',
                sensitivity_100:
                    'Skåpet är mycket intolerant mot flimrande taggar. Även en enda felavläsning kan göra att en tagg markeras som ogiltig under en längre tid, vilket kan leda till potentiella förluster.',
                sensitivityInfo:
                    'Skåpets taggkvalitetskänslighet hänvisar till hur effektivt skåpet hanterar inkonsekventa taggavläsningar eller flimrande taggar. Var uppmärksam på den känslighetsnivå du väljer, eftersom det kan påverka skåpets prestanda. Om du är osäker på vilken nivå som är lämplig rekommenderas att lämna inställningen på standardnivån 3.',
            },
            signIn: {
                signInButton: 'Logga in',
                signInExpiredError: 'Din session har löpt ut. Logga in igen',
                signInMessage: 'Du måste vara inloggad för att fortsätta',
                noOrganizationsError: 'Inga organisationer är kopplade till det här användarnamnet. Prova ett annat användarnamn.',
            },
            translations: {
                add: 'Lägg till',
                closed: 'Stängd',
                delete: 'Radera',
                device: 'Enhet',
                edit: 'Ändra',
                off: 'OFF',
                offline: 'Offline',
                on: 'ON',
                online: 'Online',
                opened: 'Öppen',
                save: 'Spara',
                seeDetails: 'Detaljer',
                settings: 'Inställningar',
                unknown: 'Okänd',
            },
            consumerApp: {
                enableConsumerFeedback: 'Aktivera användarfeedback på MySelfly',
                enablePurchaseReceipts: 'Aktivera e-kvitton på MySelfly',
                enableSelflyTrademarkForReceipts: 'Tillåt Selflys Stores sidfot i e-kvitton',
                enableSelflyTrademarkForReceiptsHelpText: 'Om du aktiverar detta läggs Selfly Stores logotyp och uppgifter till i e-kvittofilen!',
                organizationFormHelpText: 'Ange uppgifter om e-kvittots utfärdare.',
                organizationFormValidationHelpText:
                    '* Obligatoriska fält för e-kvitton är utfärdarens organisationsnamn, organisationsnummer, adress och land.',
                organizationName: 'Organisationsnamn',
                organizationLogo: 'Organisationens logotyp',
                businessId: 'Organisationsnummer',
                city: 'Ort',
                email: 'E-postadress',
                website: 'Webbplats',
                postalCode: 'Postnummer',
                street: 'Gatuadress',
                phoneNumber: 'Telefonnummer',
                addressHeader: '* Adressinformation',
                contactDetails: 'Kontaktuppgifter',
                organization: '* Utfärdarens organisation',
                organizationSettingsUpdatedTitle: 'Uppdatera organisationsinställningarna',
                organizationSettingsUpdatedContent: 'Organisationsinställningarna har uppdaterats',
                organizationSettingsMissingFields: 'Obligatoriska fält för e-kvitton saknas. Fyll i innan du sparar.',
                organizationSettingsMissingFieldsTitle: 'Valideringsfel för formulär',
            },
            softwareVersions: {
                updateNotificationTitle: 'Uppdatering av programversion',
                updateNotificationContent: 'Programvaruversioner har uppdaterats',
                selectAVersionPlaceholder: 'Välj en version',
                searchDevices: 'Sök enheter',
                updateSelected: 'Uppdatering vald',
                softwareVersions: 'Programvaruversioner',
                softwareVersion: 'Programvaruversion',
                notSupportedDeviceNote: 'Version {{selectedVersion.version}} stöds inte på den här enheten',
                userNotificationContent: 'En ny programvaruversion har släppts för dina enheter. Uppdatera dina enheter för att få de senaste funktionerna.',
                userNotificationTitle: 'En ny programvaruversion har släppts',
                notificationBell_newSoftwareVersions: 'Uppdateringar är tillgängliga för dina enheter',
                manageVersions: 'Hantera enhetsprogramvaruversioner',
                filterLocationsPlaceholder: 'Filtrera efter platser',
                allVersionNumbers: 'Alla versioner',
                noVersionsForUpdating: 'Inga versioner för uppdatering',
                scheduledUpdateNote: 'Version {{version}} är planerad att uppdateras {{scheduled}}',
                scheduleRemoveNotificationTitle: 'Schemaläggning av programvaruversion',
                scheduleRemoveNotificationContent: 'Programvaruversionens schemalagda uppdatering har tagits bort',
                scheduleRemoveNotificationModalContent: 'Är du säker på att du vill ta bort den schemalagda uppdateringen för den här enheten?',
            },
            header: {
                searchByOrganization: 'Sök efter organisation',
                pageLanguage: 'Språk',
                configureMySelflyAndEreceipts: 'Konfigurera MySelfly-inställningar',
                remindMeLater: 'Påminn mig senare',
                updateNow: 'Uppdatera nu',
            },
            notifications: {
                noNewNotifications: 'Inga nya aviseringar',
            },
            cabinetSettings: {
                cabinetTheme: 'Skåpstema',
                editAndSaveTheme: 'Redigera och spara tema',
                colorPicker: 'Färgväljare',
                customTheme: 'Anpassat tema',
                themeSettings: 'Temainställningar',
                primary: 'Primär',
                negative: 'Negativ',
                attention: 'Uppmärksamma',
                positive: 'Positiv',
                background: 'Bakgrund',
                dark: 'Mörk',
                light: 'Ljus',
                white: 'Vit',
                default: 'Standard',
                negativeLight: 'Negativt ljus',
                primaryColor: 'Grundfärg',
                positiveColors: 'Positiva färger',
                negativeColors: 'Negativa färger',
                secondaryColors: 'Sekundärfärger',
                attentionColors: 'Uppmärksamma, färger',
                screenBackground: 'Skärmbakgrund',
                borderColors: 'Kantlinjer – skuggor',
                shadow: 'Skugga',
                border: 'Kantlinje',
                fontColors: 'Typsnittsfärger',
                footerTextPlaceholder: 'Sidfotstext',
                bundleDiscounts: 'Kombinera och spara',
                expiringText: 'Produkter som går ut. Kontrollera utgångsdatumet före ditt köp',
                expiredText: 'Utgångna produkter. Kontrollera utgångsdatumet före ditt köp',
                cabinetName: 'Skåpets namn',
                productName: 'Produktnamn',
                confirmationModalHeader: 'Är du säker på att du vill spara det anpassade temat?',
                confirmationModalContent:
                    'Att anpassa temat kommer att påverka det visuella utseendet på din skåpskärm. Se till att den visuella kvaliteten på skärmen inte påverkas av ändringarna.',
                defaultRevertModalMessage: 'Är du säker på att du vill ändra skåpstemat till standard?',
                backgroundColor: 'Bakgrundsfärg',
                backgroundImage: 'Bakgrundsbild',
                footerBackground: 'Sidfotsbakgrund',
                cardsBackground: 'Kortets bakgrund',
                screenImage: 'Bild för skåpets skärm',
                uploadImage: 'Ladda upp bild',
                editImage: 'Redigera bild',
                transactionComplete: 'Transaktion slutförd',
                customPostPurchaseImage: 'Vänligen ladda upp en bild för den anpassade skärmen efter köp',
                postPurchaseScreen: 'Skärm efter köp',
                custom: 'anpassad',
                postPurchaseText: 'Text för visning efter köp',
            },
            automations: {
                transactionReports: 'Transaktionsrapporter',
                refillReports: 'Påfyllnadsrapporter',
                notifications: 'Notifieringar',
                refillPlan: 'Påfyllnadsplaner',
                days: 'Dagar',
                months: 'Månader',
                weeks: 'Veckor',
                configuration: 'Konfiguration',
                transactionReportTitle:
                    'Transaktionsrapporter för de valda skåpen kommer att genereras automatiskt och skickas till de angivna e-postadresserna nedan',
                refillReportTitle:
                    'Påfyllnadsrapporter för de valda skåpen kommer att genereras automatiskt och skickas till de angivna e-postadresserna nedan',
                noSalesNotificationTitle:
                    'Notifieringar kommer att skickas automatiskt till de angivna e-postadresserna nedan om det inte sker några försäljningar under de angivna intervallen',
                frequencyTitle: 'Hur ofta vill du att rapporten ska genereras?',
                emailNotificationTitle: 'Vänligen lägg till de e-postadresser nedan där du vill att rapporten eller notifieringen ska skickas',
                refillPlanGenerationTitle: 'Påfyllnadsplaner kommer att genereras automatiskt för de valda skåpen enligt det angivna schemat',
                addNewEmail: 'Lägg till ny e-post',
                noSalesBanner: 'Du kommer att få en e-postnotifiering om de valda skåpen inte gör några försäljningar under den konfigurerade tidsperioden',
                noSaleTitle: 'Vänligen ange tidsperioden (i timmar) för inga försäljningar i de valda skåpen innan en notifiering skickas',
                minMaxTime: 'Min är 2 och max är 23 timmar',
                timeRangeTitle: 'Vänligen ange tidsintervallet för skåpen, under vilket ingen försäljning kommer att kontrolleras',
                startTime: 'Starttid',
                endTime: 'Sluttid',
                refillPlanTitle:
                    'Påfyllnadsplanen för de valda skåpen kommer att genereras automatiskt. Vänligen schemalägg nedan den specifika tiden när du vill att påfyllnadsplanen ska skapas.',
                refillPlanTime: 'Vänligen välj tiden för när påfyllnadsplanen ska genereras',
                everyDay: 'Varje dag',
                everyWeek: 'Varje vecka',
                everyMonth: 'Varje månad',
                selectDays: 'Välj dagar',
                automationSaved: 'Automatiseringsinställningar sparades framgångsrikt',
                automationDeleted: 'Automatiseringsinställningar raderades framgångsrikt',
            },
            sleepScheduling: {
                timeRangeTitle: 'Vänligen ange tidsintervallet under vilket detta skåp kommer att gå in i standby-läge.',
                startTime: 'Starttid',
                endTime: 'Sluttid',
                selectDays: 'Välj dagar',
                duration: 'Varaktighet (Timmar)',
            },
        },
        de: {
            dateRangeFilter: {
                lastMonth: 'letzte 30 Tage',
                lastTwoWeek: 'letzte 14 Tage',
                lastWeek: 'letzte 7 Tage',
                selectDateRangeMsg: 'Ausgewählter Datumsbereich',
                today: 'heute',
                yesterday: 'gestern',
            },
            main: {
                activateSuccess: 'Das Produkt wurde erfolgreich aktiviert.',
                activationDate: 'Aktivierungsdatum',
                active: 'Aktiv',
                addAdvertisement: 'Neue Werbeeinblendung hinzufügen',
                addDiscountRule: 'Rabattaktion hinzufügen',
                addDiscountStop: 'MHD-Zeitraum hinzufügen',
                addNewLocation: 'Neuen Aufstellort auswählen',
                addProduct: 'Neues Produkt hinzufügen',
                addRegion: 'Aufstellort festlegen',
                addRegionPrice: 'Lokalen Preis hinzufügen',
                addScheduledDiscount: 'Geplanten Rabatt hinzufügen',
                addBundleDiscount: 'Paketrabatt hinzufügen',
                bundledDiscounts: 'Paketrabatte',
                bundleItemsLengthExceeded: 'Die maximale Auswahl an Produkten (3) wurde überschritten. Entfernen Sie Produkte, um fortzufahren.',
                bundleMaxProductLimitInfo: 'Wählen Sie max. drei (3) Produkte oder Produktkategorien aus.',
                bundleFixedAmountExceededOriginal: 'Der Festpreis des Pakets übersteigt die ursprüngliche Preissumme. Prüfen Sie die ausgewählten Produkte.',
                bundleFixedAmountNotAllowedToBeZero: 'Der Paketpreis muss über 0 liegen.',
                bundlePercentageNotAllowedToBeFull: 'Der Rabattprozentsatz des Pakets muss unter 100 liegen.',
                bundlePercentageNotAllowedToBeZero: 'Der Rabattprozentsatz des Pakets muss über 0 liegen.',
                bundleItemDiscountPriceAmountExceededOriginal: 'Der neue reduzierte Preis übersteigt den ursprünglichen Preis. Prüfen Sie den neuen Preis.',
                bundleItemPriceMessage: 'Jede Preisregel gilt nur für 1 Artikel, auch wenn mehrere gleiche Artikel ausgewählt werden.',
                bundleItemFixedAmountExceededOriginal: 'Der Festpreis des Produkts übersteigt den ursprünglichen Produktpreis. Prüfen Sie den Festpreis.',
                createDiscountRule: 'Rabattregel erstellen',
                deviceType: 'Gerätetyp',
                discountUpdateTitle: 'Rabatt aktualisieren',
                discountCreateTitle: 'Rabatt erstellen',
                discountDeleteTitle: 'Rabatt löschen',
                discountDeleteContent: 'Der Rabatt wurde gelöscht.',
                discountStatusUpdated: 'Der Rabattstatus wurde aktualisiert.',
                discountUpdated: 'Der Rabatt wurde aktualisiert.',
                discountCreated: 'Der Rabatt wurde erstellt.',
                discountSettings: 'Rabatt-Einstellungen',
                discountImage: 'Optionales Rabattbild',
                editDiscountStop: 'MHD-Zeitraum bearbeiten',
                editBundleConfiguration: 'Paket-Konfiguration bearbeiten',
                editExpiryConfiguration: 'Verfallsrabatteinstellung bearbeiten',
                encodingStation: 'Kodierstation',
                everyDay: 'Täglich',
                scheduledDiscountDateInfoLabel: 'Bitte lassen Sie das Anfangs- und Enddatum leer, wenn Sie den Rabatt dauerhaft gewähren möchten.',
                starts: 'Beginnt',
                ends: 'Endet',
                error: 'Fehler',
                errorRetiringLocationMsg:
                    'Der Standort konnte nicht deaktiviert werden. Bitte entfernen Sie zunächst verlinkte Geräte, Produktpreise und Werbung.',
                fetchingDiscountRules: 'Die Rabattaktionen werden abgerufen.',
                free: 'Kostenlos',
                missingImage: 'Fehlendes Werbebild',
                next: 'Weiter',
                back: 'Zurück',
                noDiscountRules: 'Keine Rabattaktionen gefunden',
                scheduledDiscountsAutomaticDeactivationInfo:
                    'Rabatte, bei denen der Zeitraum überschritten wurde, werden automatisch deaktiviert. Um Sie zu aktivieren, bearbeiten Sie den gültigen Datumsbereich.',
                scheduledDiscounts: 'Geplante Rabatte',
                schedulingStartHourIsOverEndHour: 'Der Startzeitpunkt darf nicht nach dem Endzeitpunkt liegen.',
                schedulingEndHourIsUnderStartHour: 'Der Endzeitpunkt darf nicht vor dem Startzeitpunkt liegen.',
                selectDiscountRuleProductCategories: 'Wählen Sie die von der Rabattaktion betroffenen Produktkategorien aus.',
                selectBundleProductsAmount: 'Betrag auswählen für: ',
                selectedBundleProductCategories: 'Ausgewählte Paket-Produktkategorien',
                setDiscountDays: 'Rabatttage festlegen',
                setBundleDiscount: 'Prozentualen Rabattbetrag oder Festpreis für das Paket festlegen',
                setBundleItemDiscount: 'Wählen Sie eins aus: prozentualen Rabattbetrag, Festpreis oder kostenlos',
                setBundleProducts: 'Produkte oder Kategorien für das Paket festlegen',
                setAmountMoreThanOne: 'Legen Sei bei einem Paket mit einem Artikel eine Menge von mehr als eins fest.',
                setBundleDiscountType: 'Legen Sie die Rabattart und den Betrag fest.',
                sureActivateDiscountRule: 'Möchten Sie die Rabattaktion wirklich aktivieren?',
                sureDeactivateDiscountRule: 'Möchten Sie die Rabattaktion wirklich deaktivieren?',
                sureDeleteDiscountRule: 'Möchten Sie die Rabattaktion wirklich löschen?',
                timeRange: 'Zeitspanne',
                editExpiryDiscountRule: 'Rabattaktion bearbeiten',
                editScheduledDiscountRule: 'Geplante Rabattregel bearbeiten',
                editBundleDiscountRule: 'Bündelrabattregel bearbeiten',
                releaseNotes: 'Versionshinweise',
                seeMore: 'Weitere anzeigen',
                scheduledDiscountTimeRangeLabel: 'Aktive Zeit',
                scheduledDiscountDateRangeLabel: 'Datumsbereich des Rabatts',
                weekDayAbrvMonday: 'Mo',
                weekDayAbrvTuesday: 'Di',
                weekDayAbrvWednesday: 'Mi',
                weekDayAbrvThursday: 'Do',
                weekDayAbrvFriday: 'Fr',
                weekDayAbrvSaturday: 'Sa',
                weekDayAbrvSunday: 'So',
                recurrence: 'Wiederholung',
                addScheduledDiscountStop: 'Geplanten Rabatt hinzufügen',
                editScheduledDiscountStop: 'Geplanten Rabatt bearbeiten',
                pieces: 'Stück',
                expiryDiscountNavLabel: 'MHD',
                scheduledDiscountNavLabel: 'Geplant',
                bundleDiscountNavLabel: 'Paket',
                amount: 'Menge',
                scheduling: 'Planung',
                pricing: 'Preisgestaltung',
                percentageBundleDiscountInputPlaceholder: '% Rabatt für das Paket',
                fixedPriceBundleDiscountInputPlaceholder: 'Festpreis für das Paket',
                applyBundleLevelLabel: 'Paketebene anwenden',
                applyItemLevelLabel: 'Artikelebene anwenden',
                productFixedPrice: 'Festpreis des Produkts',
                percentageDiscount: '% Rabatt',
                fixedPriceDiscount: 'Festpreis',
                freeDiscount: 'Kostenlos',
                advertisementsFetchError:
                    'Beim Herunterladen der Werbeeinblendungen ist ein Fehler aufgetreten. Stellen Sie sicher, dass der "Ad-Blocker" abgeschaltet ist.',
                advertisementsTitle: 'Werbeeinblendungen',
                all: 'Alle',
                'All time': 'Ganze Zeit',
                allProducts: 'Alle Produkte ohne Gruppierung',
                allLocations: 'Alle Aufstellorte',
                allCabinets: 'Alle Kühlschränke',
                antennaDown: 'Einige RFID-Antennen sind außer Betrieb.',
                antennaError: 'Fehlfunktion bei den Kühlschrankantennen',
                antennaNoInfo: 'Keine Information über die Kühlschrankantennen verfügbar',
                antennaOk: 'Alle Antennen sind funktionsfähig.',
                antennaPartial: 'Einige Antennen sind deaktiviert.',
                archiveSuccess: 'Das Produkt wurde erfolgreich archiviert.',
                averageItems: 'Durchschnittliche Anzahl an Produkten pro Transaktion',
                averageSales: 'Durchschnittlicher Verkaufswert pro Transaktion',
                barcode: 'Barcode',
                barcodeType: 'Barcode-Typ',
                barcodeCannotBeChanged: 'Der Barcode kann nicht geändert werden.',
                barcodeTypeCannotBeChanged: 'Der Barcode-Typ kann nicht geändert werden.',
                barcodeHelpText12: 'Geben Sie den zwölfstelligen Barcode ein; die letzte Ziffer wird automatisch erzeugt.',
                barcodeHelpText11: 'Geben Sie den elfstelligen Barcode ein; die letzte Ziffer wird automatisch erzeugt.',
                barcodeHelpText7: 'Geben Sie den siebenstelligen Barcode ein; die letzte Ziffer wird automatisch erzeugt.',
                beforeExpiry: 'vor dem MHD',
                cabinetDown: 'Der Kühlschrank ist außer Betrieb.',
                cabinetIsEmpty: 'Der Kühlschrank ist leer.',
                deviceOffline: 'Das Gerät ist offline',
                deviceOnline: 'Das Gerät ist online',
                cabinets: 'Kühlschränke',
                cabinet: 'Kühlschrank',
                cabinetSettings: 'Kühlschrankeinstellungen',
                cabinetTheming: 'Schrankthema',
                cancel: 'Abbrechen',
                cancelledSales: 'Abgebrochene Käufe',
                cancelledTransactions: 'Abgebrochene Transaktionen',
                categories: 'Kategorien',
                change: 'Ändern',
                clear: 'Löschen',
                close: 'Schließen',
                clickEditButtonRegionalPrices: 'Klicken Sie auf "Bearbeiten", um die Preise am Aufstellort zu ändern.',
                clickEditLocationPrices: 'Klicken Sie auf den Produktpreis, um die lokalen Preise anzuzeigen.',
                clickToExpandLocationPrices: 'Klicken Sie auf den Produktpreis, um die lokalen Preise anzuzeigen.',
                closeDoor: 'Schließen Sie die Kühlschranktür.',
                rebootDevice: 'Starten Sie das Gerät neu.',
                commandSentSuccessFully: 'Der Befehl wurde erfolgreich gesendet.',
                companyLogo: 'Firmenlogo',
                confirm: 'Bestätigen',
                confirmDelete: 'Möchten Sie das Produkt wirklich löschen? Diese Aktion kann nicht rückgängig gemacht werden!',
                confirmDisable: 'Möchten Sie das Produkt wirklich archivieren?',
                confirmEnable: 'Möchten Sie das Produkt wirklich aktivieren?',
                consumerResponses: 'Benutzerfeedback',
                controlPanelTitle: 'Bedienfeld',
                controls_acceptablePercentageOfBadTags: 'Zulässiger Prozentsatz fehlerhafter RFID-Tags, bevor der Schrank außer Betrieb geht.',
                controls_markTagsGoodQuality: 'Alle Tags als qualitativ gut markieren',
                controls_markTagsGoodQuality_btn: 'Tags mit guter Qualität markieren',
                controls_clearTagHistory: 'Verlaufsdaten der RFID-Tags löschen',
                controls_clearRemoveReplenishmentData: 'Nachfülldaten löschen',
                controls_companyAndPaymentLogo: 'Logo der Firma und der Bezahlmethode',
                controls_detectTagQuality: 'RFID-Tag-Qualität ermitteln',
                controls_door: 'Türsteuerung',
                controls_device: 'Gerätesteuerung',
                controls_chargeBadTags: 'Schlechte Tags belasten',
                controls_refill: 'Nachfüllen',
                controls_tagQuality: 'RFID Tag-Einstellungen',
                controls_tagQualityData: 'RFID Tag-Qualitätsdaten',
                controls_replenishmentData: 'Nachfülldaten',
                controls_soldTags: 'Verkaufte Tags',
                controls_tagHistory: 'RFID Tag-Verlauf',
                controls_markAllTagsReplenished: 'Alle nachgefüllten Tags markieren',
                controls_markAllTagsUnsold: 'Alle unverkauften Tags markieren',
                controls_markAlTagsReplenished_btn: 'Nachgefüllte Tags markieren',
                controls_markAllTagsUnsold_btn: 'Unverkaufte Tags markieren',
                controls_chargeSoldTags: 'Verkaufte Tags belasten',
                controls_chargeUnreplenishedTags: 'Nicht nachgefüllte Tags belasten',
                controls_tagsWarn:
                    'Beim Löschen der Tag-Historie werden alle Daten der Tags entfernt. Bitte klicken Sie auf "Alle Tags nach dieser Aktion als wiederaufgefüllt markieren',
                create: 'Erstellen',
                createAdTitle: 'Neue Werbeeinblendung',
                createdAd: 'Die Werbeeinblendung wurde erstellt.',
                createExpiryDiscountRule: 'Verfallsrabattregel erstellen',
                createScheduledDiscountRule: 'Geplante Rabattregel erstellen',
                createBundleDiscountRule: 'Rabattaktion erstellen',
                createNewVatCategory: 'Neue MwSt.-Kategorie',
                createProductTitle: 'Neues Produkt',
                creatingAd: 'Werbeeinblendung wird erstellt',
                creatingProduct: 'Produkt wird erstellt',
                createNewProductCategory: 'Neue Produktkategorie erstellen',
                createNewSupplier: 'Neuen Anbieter hinzufügen',
                currency: 'Währung',
                customQuery: 'Kundenanfrage',
                dashboard: 'Dashboard',
                dateRange: 'Datumsbereich',
                dataAlredyFetchedTitle: 'Die Daten wurden bereits abgerufen.',
                dataAlreadyFetchedDescription: 'Wählen Sie unterschiedliche Parameter aus.',
                day: 'Tag',
                days: 'Tage',
                deactivationDate: 'Deaktivierungstag',
                default: 'Standardeinstellung',
                delete: 'Löschen',
                deleteError: 'Fehler: Das Produkt konnte nicht gelöscht werden.',
                deleteSuccess: 'Das Produkt wurde erfolgreich gelöscht.',
                description: 'Beschreibung',
                deviceManagement: 'Gerätemanagement',
                digitwinOffline: 'Der digitale Zwilling des Geräts ist offline.',
                digitwinOnline: 'Der digitale Zwilling des Geräts ist online.',
                dimensions: 'Abmessungen',
                disable: 'Archivieren',
                discountAmount: 'Rabattwert',
                discountPrice: 'Rabattpreis',
                discountRuleAffects: 'Die Rabattaktion betrifft',
                discountRuleName: 'Name der Rabattaktion',
                discountRuleStops: 'MHD-Zeiträume',
                discounts: 'Rabatte',
                discountSalesData: 'Rabatt-Verkaufsdaten',
                discountSalesAmount: 'Rabatt-Verkaufssumme',
                discountTimeBeforeExpiryExists: 'Es gibt bereits einen früheren MHD-Zeitraum',
                discountVsNormalSales: 'Rabatt im Vergleich zum normalen Preis',
                dismiss: 'Verwerfen',
                doesNotExist: 'Existiert nicht',
                doorCloseConfirm: 'Möchten Sie die Tür wirklich schließen?',
                doorCloseSuccess: 'Die Tür wurde erfolgreich verriegelt.',
                doorOpenConfirm: 'Möchten Sie die Tür wirklich öffnen?',
                doorOpenReason: 'Warum öffnen Sie die Tür?',
                doorOpenSuccess: 'Die Tür wurde erfolgreich geöffnet.',
                rebootDeviceSuccess: 'Das Gerät wurde erfolgreich neu gestartet.',
                deviceRebootConfirm: 'Möchten Sie das Gerät wirklich neu starten?',
                edit: 'Bearbeiten',
                editCompanyLogo: 'Firmenlogo bearbeiten',
                editDiscountRule: 'Rabattregel bearbeiten',
                editPaymentMethodLogo: 'Logo der Bezahlmethode ändern',
                editVatCategoryModalTitle: 'MwSt.-Kategorie ändern',
                editProductCategoryModalTitle: 'Produktkategorie bearbeiten',
                editSupplierModalTitle: 'Anbieter bearbeiten',
                enable: 'Aktivieren',
                error_401: 'Nicht authentifiziert',
                error_403: 'Keine Berechtigung zum Ausführen des Befehls',
                error_500: 'Unerwarteter Server-Fehler',
                errorCreatingAd: 'Fehler beim Erstellen der Werbeeinblendung',
                errorCreatingProduct: 'Fehler bei der Produkterstellung',
                errorFetchingCabinetStatus: 'Fehler beim Abrufen des Kühlschrankstatus',
                errorFetchingInventory: 'Fehler beim Abrufen des Kühlschrankbestands',
                errorFetchingReport: 'Fehler beim Abrufen der Berichte',
                errorFetchingSettings: 'Fehler beim Abrufen der Einstellungen',
                errorUpdatingProduct: 'Fehler beim Aktualisieren des Produkts',
                expandToSeeBadTags:
                    'Vergrößern Sie die Liste, um fehlerhafte RFID-Tags anzuzeigen. Fehlerhafte RFID-Tags müssen aus dem Kühlschrank entfernt werden.',
                expandToSeeSoldItems: 'Vergrößern Sie die Liste, um bereits verkaufte Produkte, die jedoch erneut im Bestand auftauchen, anzuzeigen.',
                expandToSeeReplenishedOutOfInventory:
                    'Vergrößern Sie die Liste, um die Produkte anzuzeigen, die ohne Bestandsauffüllung in den Schrank gestellt wurden.',
                expired: 'Abgelaufen',
                expiredCount: 'Abgelaufene Produkte',
                expiredProductsCount: 'Anzahl der abgelaufenen Produkte',
                expiredSum: 'Summe der abgelaufenen Produkte',
                expiring: 'Kurz vor dem MHD',
                expiryDate: 'Letzter Verkaufstag',
                exportExcel: 'In Excel-Datei exportieren',
                fetchAdvertisements: 'Werbeeinblendungen werden abgerufen',
                fetchingCabinets: 'Kühlschränke werden abgerufen',
                fetchingCabinetsError: 'Fehler beim Abrufen der Kühlschränke',
                fetchingCabinetStatus: 'Kühlschrankstatus wird abgerufen',
                fetchingError: 'Fehler beim Abrufen',
                fetchingInventory: 'Kühlschrankbestand wird abgerufen',
                fetchingProducts: 'Produkte werden abgerufen',
                fetchingReport: 'Berichte werden abgerufen',
                fetchingVatCategories: 'MwSt.-Kategorien werden abgerufen',
                fetchingProductCategories: 'Produktkategorien werden abgerufen',
                fillForm: 'Formular ausfüllen',
                freshFood: 'Dies ist ein ablaufendes Produkt',
                freezerPackage: 'Tiefkühlprodukt',
                generalFetchError: 'Fehler beim Abrufen',
                generalRequestReject: 'Anfrage-Fehler',
                get: 'Holen',
                group: 'Gruppe',
                holdOn: 'Warten',
                hour: 'Stunde',
                hours: 'Stunden',
                hoursBeforeExpiry: 'Stunden vor MHD',
                daysBeforeExpiry: 'Tage vor Ablauf der Mindesthaltbarkeit',
                image: 'Bild',
                imageOrVideo: 'Wählen Sie ein Bild oder Video aus.',
                inActive: 'Inaktiv',
                individualCabinets: 'Einzelne Kühlschränke',
                individualCabinetsOnly: 'Nur einzelne Kühlschränke',
                invalidBarcodeOnlyDigits: 'Der Barcode darf nur numerische Ziffern enthalten.',
                invalidBarcodeTooLong: 'Der Barcode ist zu lang.',
                invalidBarcodeTooShort: 'Der Barcode ist zu kurz.',
                invalidCabinetNameTooShort: 'Der Kühlschrankname ist zu kurz.',
                invalidCabinetNameDuplicated: 'Der Kühlschrankname existiert bereits.',
                invalidLocationNameTooShort: 'Der Name des Aufstellorts ist zu kurz.',
                invalidLocationNameDuplicated: 'Der Ort existiert bereits.',
                invalidPercentage: 'Ungültiger Wert',
                invalidPrice: 'Ungültiger Preis',
                invalidProductBarcode: 'Ungültiger Produkt-Barcode',
                invalidTitle: 'Ungültiger Titel',
                invalidBundleItems: 'Das Paket enthält ungültige Artikel.',
                inventoryLength: 'Lagerbestand',
                inventoryNotReported: 'Der Kühlschrank hat den Lagerbestand nicht gemeldet.',
                inventoryTitle: 'Kühlschrankbestand',
                'Inventory History': 'Lagerbestandsverlauf',
                itemReplenishedOutOfInventoryProcess: 'Dieses Produkt bzw. dieser RFID-Tag wurde nicht in einem Nachfüllverfahren in den Schrank gestellt.',
                itemSoldOn: 'Artikel weiterverkauft',
                'Last 30 days': 'Letzte 30 Tage',
                'Last 7 days': 'Letzte 7 Tage',
                'Last month': 'Letzter Monat',
                'Last quarter': 'Letztes Quartal',
                'Last week': 'Letzte Woche',
                'Last year': 'Letztes Jahr',
                lastUpdate: 'Letzte Aktualisierung',
                lifetime: 'Haltbarkeit',
                location: 'Aufstellort',
                locations: 'Aufstellorte',
                logoEditor: 'Logo Editor',
                logoSettings: 'Logo-Einstellungen',
                loading: 'Wird geladen …',
                measure: 'Messen',
                measures: 'Messungen',
                minsBeforeExpiry: 'Minuten vor Ablauf des MHD',
                minutes: 'Minuten',
                month: 'Monat',
                name: 'Name',
                newVatCategoryModalTitle: 'Neue MwSt.-Kategorie',
                newProductCategoryModalTitle: 'Neue Produktkategorie',
                newSupplierModalTitle: 'Neuer Anbieter',
                'no region': '(kein Aufstellort)',
                noAdvertisements: 'Keine Werbeeinblendungen',
                noCabinets: 'Keine Kühlschränke',
                noCabinetSelected: 'Es wurde kein Kühlschrank ausgewählt.',
                noLogo: 'Kein Logo',
                noData: 'Keine Daten',
                noDescription: 'Keine Beschreibung',
                noEvents: 'Keine Ereignisse!',
                noIndividualCabinets: 'Keine einzelnen Kühlschränke',
                noLocationPrices: 'Für dieses Produkt sind keine lokalen Preise festgelegt.',
                none: 'Keine',
                noPaymentMethodLogo: 'Kein Logo für die Zahlungsmethode',
                noProducts: 'Keine Produkte',
                noProductsRemoved: 'Es wurden Produkte entfernt.',
                noProductTypeSelected: 'Es wurde kein Pakettyp ausgewählt.',
                normalPrice: 'Normaler Preis',
                normalSalesAmount: 'Normaler Gesamtpreis',
                notAccessibleForYou:
                    'Dieses Feature ist nur für Administratoren und Kühlschrankbetreiber freigeschalten. Kontaktieren Sie den Systemadministrator, um Zugriff zu erhalten.',
                notDefined: 'Nicht definiert',
                noTitle: 'Kein Titel',
                noVatCategories: 'Keine Daten zur MwSt.-Kategorie gefunden',
                noProductCategories: 'Keine Daten zur Produktkategorie gefunden',
                noInventoryOnSelectedDate: 'Für das von Ihnen ausgewählte Datum wurde kein Bestand gemeldet. Versuchen Sie es mit einem anderen Datum.',
                openDoor: 'Öffnen Sie die Tür.',
                orderSum: 'Bestellsumme',
                paymentMethodLogo: 'Logo der Bezahlmethode',
                percentage: 'Prozent',
                permanent: 'Dauerhaft',
                preview: 'Vorschau',
                price: 'Preis',
                print: 'Drucken',
                productCreated: 'Das Produkt wurde erstellt.',
                products: 'Produkte',
                productsFetchError: 'Fehler beim Abrufen der Produkte',
                productsTitle: 'Produktkatalog',
                productType: 'Pakettyp',
                productUpdated: 'Das Produkt wurde aktualisiert.',
                quantity: 'Anzahl',
                readerDown: 'Das RFID-Lesegerät ist außer Betrieb.',
                region: 'Aufstellort',
                regions: 'Aufstellorte',
                removedAt: 'Entfernt von',
                removeImage: 'Bild entfernen',
                removeVideo: 'Video entfernen',
                refillQRCodeInfo: 'Scannen Sie den QR-Code, um die Nachfüllanwendung zu öffnen oder zu verfolgen.',
                refillQRCodeTitle: 'QR-Code nachfüllen',
                refillRuleTitle: 'Nachfüllregel',
                reportsTitle: 'Berichte',
                requestSuccess: 'Die Änderungen wurden erfolgreich durchgeführt.',
                requiredPercentage: 'Erforderlich. Prozentwert ohne Prozentzeichen',
                requiredText: 'Erforderliche Zeichenlänge: ',
                retireLocation: 'Aufstellort deaktivieren',
                rfidNoInfo: 'Es sind keine Informationen über das RFID-Lesegerät und die Antennen verfügbar.',
                rfidOk: 'Das RFID-Lesegerät und die Antennen funktionieren ordnungsgemäß.',
                sales: 'Verkäufe',
                salesCount: 'Verkaufszahl',
                salesVsWasteAmount: 'Verkaufs- im Vergleich zur Ausschussmenge',
                save: 'Speichern',
                search: 'Suchen',
                selectCabinet: 'Wählen Sie einen Kühlschrank aus.',
                selectDiscountRuleCabinets: 'Wählen Sie Kühlschränke aus, die von der Rabattaktion betroffen sind.',
                selectDiscountRuleLocations: 'Wählen Sie Aufstellorte aus, die von der Rabattaktion betroffen sind.',
                selectDiscountRuleProducts: 'Wählen Sie Produkte aus, die von der Rabattaktion betroffen sind.',
                selectProductType: 'Bitte wählen Sie den Verpackungstyp für Ihr Produkt aus',
                setDiscountAmount: 'Legen Sie den Rabattwert fest.',
                setDiscountTimeBeforeExpiry: 'MHD-Zeitraum festlegen',
                settingConfirmMessage: 'Möchten Sie diese Einstellungen wirklich speichern? Wenn Sie sich nicht sicher sind, drücken sie nicht auf Bestätigen.',
                showDisable: 'Archivierte Produkte',
                summary: 'Zusammenfassung',
                sureRemoveDiscountRule: 'Möchten Sie die Rabattaktion wirklich löschen?',
                tagIsBad: 'Der RFID-Tag ist fehlerhaft und muss aus dem Kühlschrank entfernt werden.',
                tagsRemovedReport: 'Bericht über entfernte RFID-Tags',
                taxValue: 'MwSt.-Wert',
                'This month': 'Dieser Monat',
                'This quarter': 'Dieses Quartal',
                'This week': 'Diese Woche',
                'This year': 'Dieses Jahr',
                thisLink: ' diesen Link.',
                timeGrouping: 'Gruppierung nach Zeit',
                title: 'Titel',
                Today: 'Heute',
                topExpiredProducts: 'Top abgelaufene Produkte',
                topProducts: 'Top Produkte',
                total: 'Gesamt',
                totalValue: 'Gesamtwert',
                transactions: 'Transaktionen',
                ttlInDays: 'Haltbarkeit des Produkts in Tagen',
                ttlsAffected: 'Betroffene Produkte mit MHD',
                unexpectedError: 'Unerwarteter Fehler',
                uniqueCustomers: 'Einzelne Kunden',
                unknown: 'Unbekannt ',
                update: 'Update',
                updateAdError: 'Fehler beim Aktualisieren der Werbeeinblendung',
                updateAdTitle: 'Werbeeinblendung aktualisieren',
                updatedAd: 'Die Werbeeinblendung wurde aktualisiert.',
                updateCabinetSettings: 'Daten mit dem Schrank synchronisieren',
                updateCabinetSettingsInfo: 'Möchten Sie die Schrankeinstellungen wirklich aktualisieren?',
                updateData: 'Daten aktualisieren',
                updateProductTitle: 'Produkt aktualisieren',
                updatingAd: 'Werbeeinblendung aktualisieren',
                updatingProduct: 'Das Produkt wird aktualisiert …',
                uploading: 'Hochladen ',
                uploadingImage: 'Das Bild wird hochgeladen',
                use: 'Verwenden',
                'w/o grouping': 'Keine Gruppierung',
                waste: 'Ausschuss',
                vatCategoriesFetchError: 'Fehler beim Abrufen der MwSt.-Kategorien',
                vatCategoriesTitle: 'MwSt.-Kategorien',
                vatCategory: 'MwSt.-Kategorie',
                vatCategoryItems: 'Artikel',
                vatCategoryName: 'MwSt.-Kategoriebezeichnung',
                vatCategoryPromptMessage: 'Möchten Sie die MwSt.-Kategorie wirklich löschen?',
                vatCategoryValue: 'MwSt.-Wert in Prozent',
                vatDeleteRejected: 'Die MwSt.-Kategorie konnte nicht gelöscht werden. Prüfen Sie die verknüpften Produkte.',
                vatErrorRequesting: 'Fehler beim Bearbeiten der Anfrage',
                vatRequestRejected: 'Anfrage zurückgewiesen',
                vats: 'MwSt.',
                productCategoriesFetchError: 'Fehler beim Abrufen der Produktkategorien',
                productCategoriesTitle: 'Produktkategorien',
                productCategory: 'Produktkategorie',
                productCategoryItems: 'Kategorien',
                productCategoryName: 'Name der Produktkategorie',
                productCategoryPromptMessage: 'Möchten Sie die Produktkategorie wirklich löschen?',
                productCategoryDescription: 'Beschreibung der Produktkategorie',
                productCategoryHelpTextDescription: 'z.B. Kategorie Salate',
                productCategoryHelpTextName: 'z.B. Salate',
                productCategoryDeleteRejected: 'Die Produktkategorie konnte nicht gelöscht werden. Prüfen Sie die verknüpften Produkte.',
                week: 'Woche',
                viewMode: 'Ansicht',
                year: 'Jahr',
                Yesterday: 'Gestern',
                groupBy: 'Gruppieren nach',
                filterBy: 'Filtern nach',
                reportType: 'Berichttyp',
                custom: 'Benutzerdefiniert',
                preDefined: 'Vordefiniert',
                selected: 'Ausgewählt',
                metricsInfo: 'Metriken (Summe, Maximum, Minimum...) werden für alle ausgewählten Abmessungen/Quellen berechnet.',
                dimensionsInfo: 'Metriken werden für die ausgewählten Abmessungen/Quellen abgerufen.',
                metrics: 'Metriken',
                controlsSoftwareVersion: 'Softwareversion',
                currentSoftwareVersion: 'Aktuelle Softwareversion',
                availableSoftwareVersion: 'Verfügbare Softwareversion',
                invalidLifetime: 'Ungültiges MHD',
                unpaidSales: 'Unbezahlte Verkäufe',
                unpaidTransactions: 'Unbezahlte Transaktionen',
                softwareUpdateConfirmation: 'Möchten Sie wirklich eine Aktualisierung der Softwareversion auf den ausgewählten Geräten durchführen?',
                softwareUpdateWarn:
                    'Beim Aktualisieren der Softwareversion wird ein Befehl an die entsprechenden Geräte gesendet, das Update sofort oder zu einer ausgewählten Zeit herunterzuladen. Das Update wird auf den ausgewählten Geräten installiert, sobald diese nach dem Befehl online sind. Die Installation kann je nach Verbindungsgeschwindigkeit einige Zeit in Anspruch nehmen. Die Geräte werden nach der Installation neu gestartet.',
                releaseVersion: 'Verfügbare Softwareversionen',
                releaseDate: 'Datum der Veröffentlichung',
                latestRelease: 'Letzte Veröffentlichung',
                currentVersion: 'Aktuelle Softwareversion',
                softwareUpdateSuccess: 'Die Softwareversion wurde aktualisiert.',
                prebooking: 'Vorbestellung',
                prebookedProduct: 'Vorbestelltes Produkt',
                prebookedProducts: 'Vorbestellte Produkte',
                activeProducts: 'Aktive Produkte',
                startMaintenanceMode: 'Wartungsmodus starten',
                endMaintenanceMode: 'Wartungsmodus beenden',
                startMaintenanceConfirm: 'Möchten Sie den Wartungsmodus des Kühlschranks wirklich starten?',
                endMaintenanceConfirm: 'Möchten Sie den Wartungsmodus des Kühlschranks wirklich beenden?',
                startMaintenanceSuccess: 'Der Wartungsmodus wurde gestartet.',
                endMaintenanceSuccess: 'Der Wartungsmodus wurde beendet.',
                noResults: 'Keine Ergebnisse',
                suppliers: 'Lieferanten',
                supplier: 'Lieferant',
                supplierDeleteRejected: 'Der Lieferant konnte nicht gelöscht werden. Prüfen Sie die verknüpften Produkte!',
                supplierPromptMessage: 'Möchten Sie den Lieferanten wirklich löschen?',
                fetchingSuppliers: 'Lieferanten werden abgerufen',
                noSuppliers: 'Kein Lieferantendatensatz gefunden',
                suppliersFetchError: 'Fehler beim Abrufen der Lieferanten',
                supplierName: 'Name des Lieferanten',
                supplierHelpTextName: 'z.B. ein Markenname',
                supplierDescription: 'Beschreibung des Lieferanten',
                supplierHelpTextDescription: 'z.B. Beschreibung einer Marke',
                wishes: 'Wünsche',
                checkNow: 'Jetzt prüfen',
                startTimeOn: 'Startzeit am',
                endTimeOn: 'Endzeit am',
                signOutConfirmationModalTitle: 'Abmeldung bestätigen',
                signOutConfirmationModalContent: 'Möchten Sie sich wirklich abmelden?',
                startDate: 'Startdatum',
                endDate: 'Enddatum',
                scheduledDiscountApplyAllDays: 'An allen Tagen gültig',
                scheduledDiscountApplySelectedDays: 'An ausgewählten Tagen gültig',
                discountCustomImageTitle: 'Rabattbild (optional)',
                discountCustomImageModalHeader: 'Rabattbild',
                saveChanges: 'Änderungen speichern',
                updateTagsQualitySettingsTitle: 'Tag-Qualitätseinstellungen',
                updateTagsQualitySettingsMessage: 'Aktualisierte Tag-Qualitätseinstellungen',
                IF3Cabinets: 'Modell 3 Kühlschränke',
                IF2Cabinets: 'Modell 2 Kühlschränke',
                IF3Freezers: 'Modell 3 Gefrierschränke',
                encodingStations: 'Kodierstationen',
                if2: 'Modell 2 Kühlschrank',
                if3: 'Modell 3 Kühlschrank',
                if3Freezer: 'Modell 3 Gefrierschrank',
                legalNotice: 'Rechtlicher Hinweis',
                privacyPolicy: 'Datenschutzbestimmungen',
                scheduledDiscountDateMissingError: 'Beide Daten werden benötigt, wenn Start- oder Enddatum festgelegt werden.',
                scheduledDiscountDuplicateTimeError: 'Die Uhrzeit darf nicht gleich sein, wenn das Datum gleich ist.',
                now: 'Jetzt',
                minimum: 'Minimum',
                maximum: 'Maximum',
                discardChangesConfirmationText: 'Möchten Sie die Änderungen wirklich speichern?',
                exports: 'Exporte',
                selectAllCabinets: 'Alle Schränke auswählen',
                expiryIntervals: 'MHD-Zeiträume',
                timeBeforeExpiry: 'Zeit vor Ablauf der Mindesthaltbarkeit',
                apply: 'Anwenden',
                yes: 'Ja',
                no: 'Nein',
                productOnScreenBannerText: 'Info-Bannertext auf dem Bildschirm',
                productOnScreenBannerAdditionalInfo:
                    'Der Bannertext ist ein einzeiliger Text, der unterhalb des Produktbildes erscheint und die wichtigsten Merkmale, Gesundheitshinweise oder weitere Informationen zu dem Produkt enthält.',
                productOnScreenBannerExampleText: 'Laktosefrei',
                UI_IS_DOWN: 'Die Anzeige des Schranks funktioniert nicht',
                READER_IS_DOWN: 'Der RFID-Leser ist nicht aktiv',
                ANTENOR_DEVICE_DISCONNECTED: 'Das Zahlungsgerät ist nicht verbunden',
                EXPIRED_PRODUCT: 'Abgelaufene Produkte im Schrank',
                CLOUD_SERVICE_DOWN: 'Cloud-Dienst nicht verfügbar',
                ANTENNA_DISCONNECTED: 'Antenne getrennt',
                TAG_CHANGES: 'Zu viele fehlerhafte Tags im Schrank',
                ANTENNA_DOWN: 'RFID-Antenne im Schrank funktioniert nicht',
                READER_CONFIG_ERROR: 'Die Konfiguration des RFID-Lesers ist fehlgeschlagen und er funktioniert nicht',
                activeAlarms: 'Aktive Alarme',
                deviceStatus: 'Gerätestatus',
                lastMonitored: 'Zuletzt überwacht',
                noService: 'Außer Betrieb',
                operational: 'Betriebsbereit',
                offline: 'Offline',
                locationsSelectHelp: 'Wenn ein Standort hinzugefügt wird, werden auch seine untergeordneten Standorte hinzugefügt.',
                select: 'Wählen...',
                labels: 'Etiketten',
                productDetails: 'Produktdetails',
                invalidProductName: 'Der Produktname sollte 1 bis 125 Zeichen lang sein',
                standardPrice: 'Standardpreis',
                locationPrices: 'Standortpreise',
                addNew: 'Neu hinzufügen',
                productExpiry: 'Produktablauf',
                productLifeTime: 'Lebensdauer des Produkts',
                invalidTTL: 'Die Produktlebensdauer sollte eine Ganzzahl von 0 bis 32767 sein',
                productImage: 'Produktbild',
                uploadImage: 'Bild hochladen',
                pleaseUploadImage: 'Bitte laden Sie ein Bild hoch',
                productValidationError: 'Bitte beheben Sie die folgenden Fehler, bevor Sie die Produktdaten speichern',
                productSupplier: 'Produktlieferant',
                frozenProductLable: 'Dies ist ein gefrorenes Produkt',
                prebookingLabel: 'Dies ist ein Vorreservierungsprodukt',
                previous: 'Vorherige',
                bannerText: 'Bannertext',
                packagingTypeValidationError: 'Bitte wählen Sie einen Verpackungstyp für das Produkt aus',
                showOnlyFrozen: 'Nur Tiefkühlprodukte anzeigen',
                productClassification: 'Produktklassifizierung',
                productClassLabel:
                    'Bitte wählen Sie die entsprechende Klasse für das Produkt. Dies ermöglicht Selfly Insights, relevantere Produktinformationen bereitzustellen.',
                productLabels: 'Produktetiketten',
                productLabelsHelpText:
                    'Bitte fügen Sie Ihrem Produkt optionale Etiketten hinzu. Dies ermöglicht es Ihnen, Ihre Produkte im Produktkatalog zu filtern.',
                newLabel: 'Neues Etikett',
                productClassValidationError: 'Bitte wählen Sie eine Klassifizierung für Ihr Produkt aus den unten stehenden Optionen',
                tagSensitivity: 'Etikettenqualitätsempfindlichkeit',
                tagSensitivitySetting: 'Einstellung der Etikettenqualitätsempfindlichkeit',
                configTagSensitivity: 'Konfigurieren der Etikettenqualitätsempfindlichkeit für das Produkt',
                productLevelSensitivityInfo:
                    'Die Lesbarkeit eines an einem Produkt angebrachten RFID-Tags kann durch verschiedene Faktoren beeinflusst werden, einschließlich Verpackungsmaterialien, Metalloberflächen, Feuchtigkeit und sogar durch den Inhalt des Produkts selbst. Diese Faktoren können gelegentlich zu intermittierenden Tag-Signalen führen, bekannt als Flimmern. Der Schrank verarbeitet solches Flimmern basierend auf seinen Empfindlichkeitseinstellungen. Wenn Sie unsicher sind, welche Empfindlichkeitsstufe für dieses Produkt geeignet ist, können Sie diesen Abschnitt überspringen.',
                errorSaving: 'Fehler beim Speichern der Produktdaten',
                successSaving: 'Produktdaten erfolgreich gespeichert',
                basicDetail: 'Grundlagen',
                additionalDetail: 'Zusätzliche Details',
                packaging: 'Verpackungstyp',
                classification: 'Klassifizierung',
                selectOrtype: 'Auswählen oder eingeben...',
                max25Chars: 'maximal 25 Zeichen',
                noVatForProductCreation: 'Bitte erstellen Sie eine Mehrwertsteuerkategorie, bevor Sie neue Produkte hinzufügen',
                lifeTimeExplanation: 'Wie viele Tage ist dieses Produkt zum Verkauf gültig. (0 Tage) bedeutet bis zum Ende des aktuellen Tages.',
                selflyEncode: 'Selfly Encode',
                userRestrict: 'Nur erkannte Verbraucher',
                userRestrictTitle: 'Berechtigte Verbraucher',
                userNotRestrict: 'Alle Verbraucher',
                userRestrictHelperText:
                    "Die Option 'erkannte Verbraucher' bedeutet, dass der Rabatt nur auf Verbraucher angewendet wird, die bereits für die Rabattberechtigung registriert sind!",
                searchCabinets: 'Schränke suchen',
                selectAll: 'Alle auswählen',
                monday: 'Montag',
                tuesday: 'Dienstag',
                wednesday: 'Mittwoch',
                thursday: 'Donnerstag',
                friday: 'Freitag',
                saturday: 'Samstag',
                sunday: 'Sonntag',
                fetchingLoader: 'Abrufen. Bitte warten...',
                category: 'Kategorie',
                okay: 'Ok',
                noLocation: 'Kein Standort',
                copy: 'Kopieren',
                pleaseConfirm: 'Bitte bestätigen',
                saveQuery: 'Abfrage speichern',
                saveQueryPlaceholder: 'z.B. "Verkaufszahlen aller Artikel"',
                saveQuerySuccess: 'Die Abfrage wurde gespeichert.',
                yourSavedQueries: 'Ihre gespeicherten Abfragen',
                selectQuery: 'Abfrage auswählen',
                noQuery: 'Es gibt noch keine gespeicherte Abfrage',
                deleteAdConfirm: 'Sind Sie sicher, dass Sie diese Anzeige löschen möchten?',
                dataSaved: 'Daten erfolgreich gespeichert',
            },
            refill: {
                refillRuleApplyRuleDropdownText: 'Diese Regel auch für andere Schränke anwenden.',
                refillPlanGenerate: 'Nachfüllplan generieren',
                refillPlanEditPlan: 'Nachfüllplan bearbeiten',
                refillAmount: 'Nachfüllmenge',
                refillRuleTargetAmount: 'Ziellagerbestand',
                refillRuleTargetAmountShort: 'Ziel',
                refillPlanActivePlans: 'Aktive Pläne',
                refillPlanCompletedPlans: 'Abgeschlossene Pläne',
                refillNote: 'Auffüllhinweis',
                refillNoteHelp: 'Produkt-Auffüllhinweis (maximal 125 Zeichen)',
                refillPDFText:
                    'Nachfolgend finden Sie den Auffüllplan für den Schrank. Stellen Sie sicher, dass Sie den Auffüllplan nach Abschluss der Auffüllung in der Auffüllanwendung als abgeschlossen markieren.',
                refillPDFSummaryText:
                    'Nachfolgend finden Sie eine Zusammenfassung der Auffüllpläne mit Produkten zum Hinzufügen und Entfernen für alle ausgewählten Schränke.',
                refillSummary: 'Auffüllzusammenfassung',
                addItems: 'Produkte hinzufügen',
                removeItems: 'Produkte entfernen',
                inventoryChange: 'Bestandsänderung',
                planogramPDFText: 'Planogramm für die Schrankauffüllungsstrategie.',
                planogramImagePDFText: 'Benutzerdefiniertes Bild für die Schrankauffüllungsstrategie.',
                refillSchemas: 'Auffüllvorlagen',
                refillPlans: 'Auffüllpläne',
                refillSchemaTitle:
                    'Legen Sie die Ziel- und Mindestbestandsmengen als Vorlage für die ausgewählten Schränke fest. Diese Vorlagen werden zur Erstellung der Auffüllpläne verwendet.',
                refillPlansTitle:
                    'Alle aktiven Pläne anzeigen. Sie können vorhandene aktive Pläne bearbeiten oder entfernen oder bei Bedarf neue Auffüllpläne hinzufügen.',
                refillSchemaSubText: 'Bitte wählen Sie einen Schrank aus, um die Auffüllvorlage zu erstellen oder zu aktualisieren.',
                noRefillSchema: 'Für diesen Schrank wurde noch keine Auffüllvorlage definiert.',
                createRefillSchema: 'Neue Auffüllvorlage erstellen',
                noDevice: 'Kein Gerät gefunden',
                schemaSaved: 'Auffüllvorlage erfolgreich gespeichert',
                deleteSchemaConfirmation: 'Sind Sie sicher, dass Sie die Auffüllvorlage löschen möchten?',
                targetAmount: 'Zielmenge',
                targetAmountHelpText: 'Die Zielbestandsmenge für jedes Produkt im Schrank nach der Auffüllung.',
                minimumThreshold: 'Mindestschwelle',
                minimumThresholdHelpText:
                    'Geben Sie das Mindestbestandsniveau für jedes Produkt im Schrank an. Wenn die Menge unter die festgelegte Schwelle fällt, erhalten Sie eine Alarmbenachrichtigung. Das Festlegen der Schwelle auf 0 deaktiviert die Benachrichtigungen.',
                editNote: 'Notiz bearbeiten',
                refillNoteTitle: 'Schreiben Sie eine Auffüllnotiz für das Produkt.',
                refillNoteCharacters: 'Zeichen',
                noRefillPlan:
                    'Derzeit gibt es keine aktiven Auffüllpläne. Bitte fügen Sie neue Auffüllpläne für Ihre Schränke hinzu oder erstellen Sie zuerst eine Auffüllvorlage für die Schränke.',
                planSaved: 'Auffüllplan erfolgreich gespeichert',
                deleteConfirmation: 'Sind Sie sicher, dass Sie die ausgewählten Auffülldaten löschen möchten?',
                editPlan: 'Plan bearbeiten',
                currentInventory: 'Aktueller Bestand',
                lastSevenDaySales: 'Verkäufe der letzten sieben Tage',
                inventory: 'Inventar',
                planogram: 'Planogramm',
                copyRefillSchema: 'Vorlage auf andere Schränke kopieren',
                generatingPlans: 'Auffüllpläne werden erstellt. Bitte warten...',
                generatePlans: 'Auffüllpläne erstellen',
                complete: 'komplett',
                selectCabinetsToAddPlans: 'Bitte wählen Sie Schränke aus, um Auffüllpläne zu erstellen.',
                reviewSuggestion:
                    'Unten finden Sie die vorgeschlagenen Auffüllpläne für die ausgewählten Schränke. Bitte überprüfen Sie diese, bearbeiten Sie sie bei Bedarf und speichern Sie sie, um diese Pläne zu aktivieren.',
                noSchema:
                    'Derzeit hat keiner der Schränke eine Auffüllvorlage. Bitte fügen Sie eine Auffüllvorlage für Ihre Schränke hinzu, um Auffüllpläne zu erstellen.',
                searchProducts: 'Produkte suchen',
                maxAllowedInventory: 'Maximal zulässiger Bestand ist 200.',
                savingData: 'Daten werden gespeichert. Bitte warten...',
                errorSavingData: 'Fehler beim Speichern der Daten.',
                refillPlanSaved: 'Auffüllpläne erfolgreich erstellt.',
                exportPDFHelp: 'Exportieren Sie ausgewählte Pläne in eine PDF-Datei.',
                exportExcelHelp: 'Exportieren Sie ausgewählte Pläne in eine Excel-Datei.',
                addPlanHelp: 'Neue Auffüllpläne hinzufügen.',
                deletePlanHelp: 'Ausgewählte Auffüllpläne löschen.',
                generatePlansHelp: 'Auffüllpläne für die ausgewählten Schränke erstellen.',
                planogramSaved: 'Planogramm erfolgreich gespeichert.',
                schemaCopied: 'Die Auffüllvorlage wurde erfolgreich mit den anderen Schränken synchronisiert.',
                allProducts: 'Alle Produkte',
                schemaProducts: 'Auffüllvorlagenprodukte',
                deletePlanogram: 'Sind Sie sicher, dass Sie das Planogramm löschen möchten?',
                dragInPlanogram: 'Bitte ziehen Sie Produkte in die vorgesehenen Schrankfächer oder ziehen Sie das Schrankfach über das Produkt.',
                noPlanogramInSchema: 'Für den Schrank wurde noch kein Planogramm definiert.',
                noPlanogramInPlan: 'Für den Schrank sind keine Planogrammdaten verfügbar. Bitte erstellen Sie zunächst ein Planogramm im Auffüllvorlagen-Tab.',
                copyPlanogram: 'Auffüllplanogramm zusammen mit der Auffüllvorlage kopieren.',
                planogramImageInfo: 'Zusätzlich zum Planogramm können Sie auch ein benutzerdefiniertes Planogrammbild hinzufügen.',
                addPlanogramImage: 'Planogrammbild hinzufügen',
                editPlanogramImage: 'Planogrammbild bearbeiten',
                numberOfShelves: 'Anzahl der Regale',
                addNewRefillPlan: 'Neue Pläne hinzufügen',
                qrcodeTitle: 'Ein QR-Code wird für den ausgewählten Schrank generiert, um den Auffüllprozess zu starten.',
                qrcodeSubText: 'Bitte wählen Sie einen Schrank aus, für den Sie den Auffüllprozess starten möchten.',
                qrcodeScan: 'Bitte scannen Sie den QR-Code zum Auffüllen oder klicken Sie auf die Schaltfläche, um die Auffüllanwendung zu öffnen.',
                qrcodeOpenApplication: 'Auffüllanwendung öffnen',
                createRefillPlanogram: 'Auffüllplanogramm erstellen',
            },
            navigation: {
                advertisements: 'Werbeeinblendungen',
                analytics: 'Auswertungen',
                controlPanel: 'Bedienfeld',
                deviceManagement: 'Gerätemanager',
                discounts: 'Rabatte',
                fetching: 'Abruf',
                group: 'Gruppe',
                inventory: 'Kühlschrankbestand',
                language: 'Sprache',
                productCatalog: 'Produktkatalog',
                productCategories: 'Produktkategorien',
                reports: 'Berichte',
                settings: 'Benutzereinstellungen',
                signOut: 'Abmelden',
                title: 'Selfly Cloud Portal',
                vatCategories: 'MwSt.-Kategorien',
                community: 'Selfly Community',
                refillManagement: 'Nachfüllverwaltung',
                automations: 'Automatisierungen',
                mySelfly: 'MySelfly-Einstellungen',
                automationInfo: 'Automatisierte Aktionen verwalten (BETA)',
            },
            reports: {
                alreadyRefund: 'wurde erstattet',
                amount: 'Betrag',
                average: 'Durchschnitt',
                barcode: 'Barcode',
                confirmIssueRefundButton: 'Erstattungsbestätigung',
                current: 'Aktuell',
                export: 'Exportieren',
                fetching: 'Abrufen',
                fetchingError: 'Beim Abrufen ist ein Fehler aufgetreten',
                image: 'Bild',
                issueRefundButton: 'ERSTATTUNG',
                maximum: 'Maximum',
                minimum: 'Minimum',
                name: 'Name',
                noData: 'Keine Daten',
                note: 'Hinweis',
                order: 'Transaktion',
                paymentWasDoneByCreditCard: 'Die Zahlung erfolgte per Kreditkarte.',
                price: 'Preis',
                ProductReport: 'Produktbericht',
                quantity: 'Anzahl',
                'Refund impossible': 'Die Zahlung erfolgte per Kreditkarte. Kontaktieren Sie den Kreditkartendienstleister, um eine Erstattung einzuleiten.',
                refundFormTitle: 'Erstattung',
                refundReason: 'Grund für die Erstattung',
                refundSuccess: 'Der Betrag {{sum}} wurde erfolgreich erstattet.',
                refundTotal: 'Erstattungsbetrag',
                replenishments: 'Nachfüllen',
                replenishmentsTitle: 'Nachfüllbericht',
                SaleTransaction: 'Verkaufstransaktion',
                summary: 'Zusammenfassung',
                SummaryReport: 'Verkaufszusammenfassung',
                temperature: 'Temperatur',
                temperatureTitle: 'Temperaturbericht',
                temperatureValueNotification: 'Es werden die stündlichen Durchschnittstemperaturen des Kühlschranks angezeigt.',
                time: 'Zeit',
                total: 'Gesamt',
                transactions: 'Transaktionen',
                user: 'Benutzer',
                paidTransactions: 'Bezahlte Transaktionen',
                unpaidTransactions: 'Unbezahlte Transaktionen',
                partiallyPaid: 'Teilweise bezahlt',
                failedTransactions: 'Gescheiterte Transaktionen',
                amountOwed: 'Ausstehender Betrag',
                receiptLinkText: 'Erhalten Sie hier Ihre Quittung',
                noPurchase: 'Kein Kauf',
                other: 'Andere',
                userId: 'Benutzer-ID:',
            },
            settings: {
                block_cabinet_when_product_expired: 'Kühlschrank wegen abgelaufener Produkte sperren',
                criticalExpiryLimitHours: 'Warnung Stunden vor kritischem Ablauf der Haltbarkeit',
                language: 'Sprache',
                logo: 'Firmenlogo',
                mediumExpiryLimitHours: 'Warnung Stunden vor mittlerem Ablauf der Haltbarkeit',
                payment_method_logo: 'Logo der Zahlungsmethode',
                footer_instruction_logo: 'Fußzeilenbild',
                readUserMemory: 'MHD des Produkts abrufen',
                ui_skus_sort_by: 'Sortierordnung des Produkts in der Kühlschrankanzeige',
                timeZone: 'Zeitzone',
                country: 'Land',
                city: 'Ort',
                tagSettings: 'Tag-Einstellungen',
                themeAndLogos: 'Thema & Logos',
                advancedSettings: 'Erweiterte Einstellungen',
                themingInfoText: 'Sie können das Thema Ihres Schrankbildschirms anpassen',
                cabinetLogos: 'Schrank-Logos',
                cabinetLogoInfoText: 'Hochladen von Logos, die auf dem Schrankbildschirm erscheinen',
                logoHelperText: 'Verwenden Sie ein 180px X 180px großes Bild.',
                settingsCopyInfoHeader: 'Wählen Sie Schränke aus, in die Sie die Einstellungen kopieren möchten.',
                settingsCopyButton: 'Einstellungen in ausgewählte Schränke kopieren',
                settingsCopySuccessModalTitle: 'Einstellungen werden kopiert',
                settingsCopySuccessModalContent: 'Einstellungen erfolgreich in {{cabinetsAmount}} Schrank(en) kopiert',
                theming: 'Theming',
                adminSettings: 'Admin-Einstellungen',
                settingsCopyErrorModalTitle: 'Fehler beim Kopieren der Einstellungen',
                key: 'Einstellung',
                value: 'Wert',
                sleepScheduling: 'Geplanter Standby',
                sensitivityLevel: 'Empfindlichkeitsstufe',
                sensitivity_0:
                    'Der Schrank ignoriert das Flackern des Tags und markiert konsequent alle Tags als gültig, was gelegentlich zu falschen Abrechnungen führen kann.',
                sensitivity_20: 'Der Schrank toleriert bis zu drei fehlerhafte Lesevorgänge eines Tags bei 50 Scans und markiert das Tag trotzdem als gültig.',
                sensitivity_40: 'Der Schrank toleriert bis zu zwei fehlerhafte Lesevorgänge eines Tags bei 60 Scans und markiert das Tag trotzdem als gültig.',
                sensitivity_60: 'Wenn ein Tag häufig flackert, aber über längere Zeit stabil bleibt, toleriert der Schrank dies und liest das Tag als gültig.',
                sensitivity_80: 'Wenn ein Tag kurzzeitig flackert, aber über längere Zeit stabil bleibt, wird es als gültig betrachtet.',
                sensitivity_100:
                    'Der Schrank ist sehr empfindlich gegenüber Flackern des Tags. Bereits ein einziger fehlerhafter Lesevorgang kann dazu führen, dass ein Tag für längere Zeit als ungültig markiert wird, was zu potenziellen Verlusten führen kann.',
                sensitivityInfo:
                    'Die Tag-Qualitätsempfindlichkeit eines Schranks bezieht sich darauf, wie effektiv der Schrank inkonsistente Tag-Lesungen oder Flackern handhabt. Bitte achten Sie auf die gewählte Empfindlichkeitsstufe, da dies die Leistung des Schranks beeinflussen kann. Wenn Sie unsicher sind, welches Niveau geeignet ist, wird empfohlen, die Einstellung auf dem Standardniveau 3 zu belassen.',
            },
            signIn: {
                signInButton: 'Anmelden',
                signInExpiredError: 'Ihre Sitzung ist abgelaufen. Melden Sie sich erneut an.',
                signInMessage: 'Sie müssen angemeldet sein, um fortzufahren. Klicken Sie auf die unten stehende Taste, um sich anzumelden.',
                noOrganizationsError: 'Unter diesem Benutzernamen gibt es kein Unternehmen. Versuchen Sie es mit einem anderen Benutzernamen.',
            },
            translations: {
                add: 'Hinzufügen',
                closed: 'Geschlossen',
                delete: 'Löschen',
                device: 'Gerät',
                edit: 'Bearbeiten',
                off: 'AUS',
                offline: 'Offline',
                on: 'EIN',
                online: 'Online',
                opened: 'Öffnen',
                save: 'Speichern',
                seeDetails: 'Details',
                settings: 'Einstellungen',
                unknown: 'Unbekannt ',
            },
            consumerApp: {
                enableConsumerFeedback: 'Benutzerfeedback in MySelfly aktivieren',
                enablePurchaseReceipts: 'E-Quittungen in MySelfly aktivieren',
                enableSelflyTrademarkForReceipts: 'Selfly-Store-Fußzeile in E-Quittungen erlauben',
                enableSelflyTrademarkForReceiptsHelpText:
                    'Wenn diese Funktion aktiviert ist, wird das Selfly Store Logo und weitere Details auf der E-Quittung (PDF) hinzugefügt!',
                organizationFormHelpText: 'Geben Sie die Details des Ausstellers der E-Quittung an.',
                organizationFormValidationHelpText:
                    '* Erforderliche Felder für die E-Quittung sind der Name des Unternehmens, die Geschäfts-ID, die Adresse und das Land des Ausstellers.',
                organizationName: 'Name des Unternehmens',
                organizationLogo: 'Logo des Unternehmens',
                businessId: 'Geschäfts-ID',
                city: 'Ort',
                email: 'E-Mail',
                website: 'Webseite',
                postalCode: 'PLZ',
                street: 'Straße',
                phoneNumber: 'Telefonnummer',
                addressHeader: '* Adresseninformationen',
                contactDetails: 'Kontaktdaten',
                organization: '* Unternehmen des Ausstellers',
                organizationSettingsUpdatedTitle: 'Aktualisierung der Unternehmenseinstellungen',
                organizationSettingsUpdatedContent: 'Die Unternehmenseinstellungen wurden aktualisiert.',
                organizationSettingsMissingFields: 'Es fehlt ein obligatorisches Feld für die E-Quittung. Füllen Sie es vor dem Speichern aus.',
                organizationSettingsMissingFieldsTitle: 'Fehler beim Prüfen des Formulars.',
            },
            softwareVersions: {
                updateNotificationTitle: 'Aktualisierung der Softwareversion',
                updateNotificationContent: 'Die Softwareversion(en) wurde(n) erfolgreich aktualisiert.',
                selectAVersionPlaceholder: 'Wählen Sie eine Version.',
                searchDevices: 'Geräte suchen',
                updateSelected: 'Update ausgewählt',
                softwareVersions: 'Softwareversionen',
                softwareVersion: 'Softwareversion',
                notSupportedDeviceNote: 'Diese Version {{selectedVersion.version}} wird auf diesem Gerät nicht unterstützt.',
                userNotificationContent:
                    'Für Ihre Geräte wurde eine neue Softwareversion veröffentlicht. Bitte aktualisieren Sie Ihre Geräte, um die neuesten Funktionen zu erhalten.',
                userNotificationTitle: 'Eine neue Softwareversion ist verfügbar.',
                notificationBell_newSoftwareVersions: 'Verfügbare Updates für Ihre Geräte',
                manageVersions: 'Gerätesoftwareversionen verwalten',
                filterLocationsPlaceholder: 'Nach Orten filtern',
                allVersionNumbers: 'Alle Veröffentlichungen',
                noVersionsForUpdating: 'Keine Versionen zum Aktualisieren vorhanden.',
                scheduledUpdateNote: 'Die Version {{version}} wird voraussichtlich am {{scheduled}} aktualisiert.',
                scheduleRemoveNotificationTitle: 'Softwareversion planen',
                scheduleRemoveNotificationContent: 'Die geplante Aktualisierung der Softwareversion wurde erfolgreich gelöscht.',
                scheduleRemoveNotificationModalContent: 'Möchten Sie die geplante Aktualisierung für dieses Gerät wirklich löschen?',
            },
            header: {
                searchByOrganization: 'Nach Unternehmen suchen',
                pageLanguage: 'Sprache',
                configureMySelflyAndEreceipts: 'MySelfly-Einstellungen konfigurieren',
                remindMeLater: 'Erinnere mich später',
                updateNow: 'Jetzt aktualisieren',
            },
            notifications: {
                noNewNotifications: 'Keine neuen Benachrichtigungen',
            },
            cabinetSettings: {
                cabinetTheme: 'Schrankthema',
                editAndSaveTheme: 'Thema bearbeiten und speichern',
                colorPicker: 'Farbauswahl',
                customTheme: 'Benutzerdefiniertes Thema',
                themeSettings: 'Thema-Einstellungen',
                primary: 'Primär',
                negative: 'Negativ',
                attention: 'Achtung',
                positive: 'Positiv',
                background: 'Hintergrund',
                dark: 'Dunkel',
                light: 'Hell',
                white: 'Weiß',
                default: 'Standard',
                negativeLight: 'Negativlicht',
                primaryColor: 'Primäre Farbe',
                positiveColors: 'Positive Farben',
                negativeColors: 'Negative Farben',
                secondaryColors: 'Sekundäre Farben',
                attentionColors: 'Warnfarben',
                screenBackground: 'Bildschirmhintergrund',
                borderColors: 'Ränder - Schatten',
                shadow: 'Schatten',
                border: 'Rand',
                fontColors: 'Schriftfarben',
                footerTextPlaceholder: 'Fußzeilentext',
                bundleDiscounts: 'Kombinieren und speichern',
                expiringText: 'Ablaufende Produkte. Prüfen Sie das Mindesthaltbarkeitsdatum vor dem Kauf.',
                expiredText: 'Abgelaufene Produkte. Prüfen Sie das Mindesthaltbarkeitsdatum vor dem Kauf.',
                cabinetName: 'Name des Schranks',
                productName: 'Name des Produkts',
                confirmationModalHeader: 'Möchten Sie das angepasste Thema wirklich speichern?',
                confirmationModalContent:
                    'Die Anpassung des Themas wirkt sich auf die Darstellung Ihres Schrankbildschirms aus. Achten Sie darauf, dass die Anzeigequalität des Bildschirms durch die Änderungen nicht beeinträchtigt wird.',
                defaultRevertModalMessage: 'Möchten Sie das Schrankthema wirklich auf die Standardeinstellung zurücksetzen?',
                backgroundColor: 'Hintergrundfarbe',
                backgroundImage: 'Hintergrundbild',
                footerBackground: 'Fußzeilenhintergrund',
                cardsBackground: 'Kartenhintergrund',
                screenImage: 'Bild für Schirmhintergrund des Schranks',
                uploadImage: 'Bild hochladen',
                editImage: 'Bild bearbeiten',
                transactionComplete: 'Transaktion abgeschlossen',
                customPostPurchaseImage: 'Bitte laden Sie ein Bild für den benutzerdefinierten Bildschirm nach dem Kauf hoch',
                postPurchaseScreen: 'Bildschirm nach dem Kauf',
                custom: 'benutzerdefiniert',
                postPurchaseText: 'Anzeigetext nach dem Kauf',
            },
            automations: {
                transactionReports: 'Transaktionsberichte',
                refillReports: 'Nachfüllberichte',
                notifications: 'Benachrichtigungen',
                refillPlan: 'Nachfüllpläne',
                days: 'Tage',
                months: 'Monate',
                weeks: 'Wochen',
                configuration: 'Konfiguration',
                transactionReportTitle:
                    'Transaktionsberichte für die ausgewählten Automaten werden automatisch generiert und an die unten angegebenen E-Mail-Adressen gesendet',
                refillReportTitle:
                    'Nachfüllberichte für die ausgewählten Automaten werden automatisch generiert und an die unten angegebenen E-Mail-Adressen gesendet',
                noSalesNotificationTitle:
                    'Benachrichtigungen werden automatisch an die unten angegebenen E-Mail-Adressen gesendet, falls innerhalb der definierten Intervalle keine Verkäufe getätigt werden',
                frequencyTitle: 'Wie oft soll der Bericht generiert werden?',
                emailNotificationTitle: 'Bitte fügen Sie unten die E-Mail-Adressen hinzu, an die der Bericht oder die Benachrichtigung gesendet werden soll',
                refillPlanGenerationTitle: 'Nachfüllpläne werden automatisch für die ausgewählten Automaten gemäß dem angegebenen Zeitplan generiert',
                addNewEmail: 'Neue E-Mail hinzufügen',
                noSalesBanner:
                    'Sie erhalten eine E-Mail-Benachrichtigung, wenn die ausgewählten Automaten während des konfigurierten Zeitraums keine Verkäufe tätigen',
                noSaleTitle:
                    'Bitte geben Sie die Zeitdauer (in Stunden) ohne Verkäufe in den ausgewählten Automaten an, bevor eine Benachrichtigung gesendet wird',
                minMaxTime: 'Min. 2 und max. 23 Stunden',
                timeRangeTitle: 'Bitte geben Sie den Zeitrahmen für die Automaten an, in dem die keine-Verkäufe-Situation überprüft wird',
                startTime: 'Startzeit',
                endTime: 'Endzeit',
                refillPlanTitle:
                    'Der Nachfüllplan für die ausgewählten Automaten wird automatisch generiert. Bitte planen Sie unten die genaue Zeit, zu der der Nachfüllplan erstellt werden soll.',
                refillPlanTime: 'Bitte wählen Sie die Uhrzeit für die Generierung des Nachfüllplans aus',
                everyDay: 'Jeden Tag',
                everyWeek: 'Jede Woche',
                everyMonth: 'Jeden Monat',
                selectDays: 'Tage auswählen',
                automationSaved: 'Automatisierungseinstellungen erfolgreich gespeichert',
                automationDeleted: 'Automatisierungseinstellungen erfolgreich gelöscht',
            },
            sleepScheduling: {
                timeRangeTitle: 'Bitte geben Sie den Zeitrahmen an, in dem dieser Automat in den Standby-Modus geht.',
                startTime: 'Startzeit',
                endTime: 'Endzeit',
                selectDays: 'Tage auswählen',
                duration: 'Dauer (Stunden)',
            },
        },
        fr: {
            dateRangeFilter: {
                lastMonth: 'les 30 derniers jours',
                lastTwoWeek: 'les 14 derniers jours',
                lastWeek: 'les 7 derniers jours',
                selectDateRangeMsg: 'Plage de données sélectionnée',
                today: "aujourd'hui",
                yesterday: 'hier',
            },
            main: {
                activateSuccess: 'Produit activé avec succès',
                activationDate: "Date d'activation",
                active: 'Actif',
                addAdvertisement: 'Ajouter une nouvelle publicité',
                addDiscountRule: 'Ajouter un paramètre de réduction ',
                addDiscountStop: 'Ajouter un intervalle d’expiration',
                addNewLocation: 'Choisissez un nouveau lieu',
                addProduct: 'Ajouter un nouveau produit',
                addRegion: 'Ajouter un lieu',
                addRegionPrice: 'Ajouter le prix du lieu',
                addScheduledDiscount: 'Ajouter une réduction planifiée',
                addBundleDiscount: 'Ajouter une formule',
                bundledDiscounts: 'Formules',
                bundleItemsLengthExceeded: 'La sélection maximale de produits (3) est dépassée. Supprimez certaines de vos sélections pour continuer',
                bundleMaxProductLimitInfo: 'Sélectionnez max. trois (3) produits ou catégories de produits',
                bundleFixedAmountExceededOriginal: 'Le prix fixe de la formule dépasse la somme des prix originaux. Vérifiez les produits sélectionnés.',
                bundleFixedAmountNotAllowedToBeZero: 'Le prix fixe de la formule doit être supérieur à 0',
                bundlePercentageNotAllowedToBeFull: 'Le pourcentage de réduction de la formule doit être inférieur à 100',
                bundlePercentageNotAllowedToBeZero: 'Le pourcentage de réduction de la formule doit être supérieur à 0',
                bundleItemDiscountPriceAmountExceededOriginal: "Le nouveau prix réduit dépasse le prix d'origine. Vérifiez à nouveau le nouveau prix",
                bundleItemPriceMessage: "Chaque règle de prix ne s'applique qu'à un seul produit, même si plusieurs produits du même article sont sélectionnés",
                bundleItemFixedAmountExceededOriginal: "Le prix fixe du produit dépasse le prix du produit d'origine. Vérifiez à nouveau le prix fixe.",
                createDiscountRule: 'Créer une règle de remise',
                deviceType: "Type d'appareil",
                discountUpdateTitle: 'Mise à jour de la réduction',
                discountCreateTitle: 'Création de la réduction',
                discountDeleteTitle: 'Suppression de la réduction',
                discountDeleteContent: 'La réduction a été supprimée',
                discountStatusUpdated: 'Le statut de la réduction a été mis à jour',
                discountUpdated: 'La réduction a été mise à jour',
                discountCreated: 'La réduction a été créée',
                discountSettings: 'Paramètres de remise',
                discountImage: 'L’image faculative de la remise',
                editDiscountStop: 'Modifier l’intervalle d’expiration',
                editBundleConfiguration: 'Modifier la configuration de la formule',
                editExpiryConfiguration: 'Modifier la configuration de la remise d’expiration',
                encodingStation: 'Station de codage',
                everyDay: 'Tous les jours',
                scheduledDiscountDateInfoLabel: 'Veuillez laisser les dates de début et de fin vides si vous souhaitez rendre la remise permanente',
                starts: 'Débute',
                ends: 'Termine',
                error: 'Erreur',
                errorRetiringLocationMsg:
                    "Échec du retrait de l'emplacement. Veuillez d'abord supprimer les appareils liés, les prix des produits et les publicités.",
                fetchingDiscountRules: 'Récupération des règles de réduction',
                free: 'Gratuit',
                missingImage: 'Image publicitaire manquante',
                next: 'Suivant',
                back: 'Retour',
                noDiscountRules: 'Aucune règle de réduction trouvée',
                scheduledDiscountsAutomaticDeactivationInfo:
                    "Les remises pour lesquelles la plage de dates est dépassée sont automatiquement désactivées. Si vous voulez les rendre actives, modifiez la plage de dates pour qu'elle soit valide",
                scheduledDiscounts: 'Réductions planifiées',
                schedulingStartHourIsOverEndHour: "L'heure de début ne peut pas être après l'heure de fin",
                schedulingEndHourIsUnderStartHour: "L'heure de fin ne peut pas être avant l'heure de début",
                selectDiscountRuleProductCategories: 'Sélectionnez les catégories de produits concernées par la règle de réduction',
                selectBundleProductsAmount: 'Sélectionnez le montant pour ',
                selectedBundleProductCategories: 'Catégories de produits de formule sélectionnées',
                setDiscountDays: 'Définir les jours de réduction',
                setBundleDiscount: 'Définir un pourcentage de réduction ou un prix fixe pour la formule',
                setBundleItemDiscount: 'Définir un pourcentage de réduction ou un prix fixe pour un article',
                setBundleProducts: 'Définir les produits ou les catégories pour la formule',
                setAmountMoreThanOne: 'En cas de formule à un seul article, définissez le montant de plusieurs',
                setBundleDiscountType: 'Définir le type et le montant de la réduction',
                sureActivateDiscountRule: 'Etes-vous sûr de vouloir activer la règle de réduction ?',
                sureDeactivateDiscountRule: 'Etes-vous sûr de vouloir désactiver la règle de réduction ?',
                sureDeleteDiscountRule: 'Etes-vous sûr de vouloir supprimer la règle de réduction ?',
                timeRange: 'Plage de temps',
                editExpiryDiscountRule: 'Modifier la règle de réduction',
                editScheduledDiscountRule: 'Modifier la règle de remise planifiée',
                editBundleDiscountRule: 'Modifier la règle de remise groupée',
                releaseNotes: 'Notes de version',
                seeMore: 'Voir plus',
                scheduledDiscountTimeRangeLabel: 'Temps actif',
                scheduledDiscountDateRangeLabel: 'Plage de dates de la réduction',
                weekDayAbrvMonday: 'lun',
                weekDayAbrvTuesday: 'mar',
                weekDayAbrvWednesday: 'mer',
                weekDayAbrvThursday: 'jeu',
                weekDayAbrvFriday: 'ven',
                weekDayAbrvSaturday: 'sam',
                weekDayAbrvSunday: 'dim',
                recurrence: 'Récurrence',
                addScheduledDiscountStop: 'Ajouter une remise planifiée',
                editScheduledDiscountStop: 'Modifier une remise planifiée',
                pieces: 'pcs',
                expiryDiscountNavLabel: 'Expiration',
                scheduledDiscountNavLabel: 'Planifiée',
                bundleDiscountNavLabel: 'Formule',
                amount: 'Montant',
                scheduling: 'Planification',
                pricing: 'Prix',
                percentageBundleDiscountInputPlaceholder: 'Réduction en % pour la formule',
                fixedPriceBundleDiscountInputPlaceholder: 'Prix fixe pour la formule',
                applyBundleLevelLabel: 'Appliquer le niveau de la formule',
                applyItemLevelLabel: "Appliquer le niveau de l'article",
                productFixedPrice: 'Prix fixe du produit',
                percentageDiscount: '% de réduction',
                fixedPriceDiscount: 'Prix fixe',
                freeDiscount: 'Gratuit',
                advertisementsFetchError:
                    "Une erreur s'est produite lors de la récupération des publicités. Assurez-vous que le bloqueur de publicité est désactivé",
                advertisementsTitle: 'Annonces publicitaires',
                all: 'Tous',
                'All time': 'Tout le temps',
                allProducts: 'Tous les produits sans regroupement',
                allLocations: 'Tous les lieux',
                allCabinets: 'Toutes les armoires',
                antennaDown: 'Certaines antennes RFID sont en panne',
                antennaError: "L'erreur concerne les antennes du frigo connecté",
                antennaNoInfo: "Aucune information n'est disponible pour les antennes du frigo connecté",
                antennaOk: 'Toutes les antennes sont opérationnelles',
                antennaPartial: 'Certaines des antennes sont désactivées',
                archiveSuccess: 'Produit archivé avec succès',
                averageItems: "Nombre moyen d'articles par transaction",
                averageSales: 'Ventes moyennes par transaction',
                barcode: 'Code à barres',
                barcodeType: 'Type de code à barres',
                barcodeCannotBeChanged: 'Le code à barres ne peut pas être modifié',
                barcodeTypeCannotBeChanged: 'Le type de code à barres ne peut pas être modifié',
                barcodeHelpText12: 'Saisissez les 12 chiffres du code à barres, le dernier chiffre est ajouté automatiquement',
                barcodeHelpText11: 'Saisissez les 11 chiffres du code à barres, le dernier chiffre est ajouté automatiquement',
                barcodeHelpText7: 'Saisissez les 7 chiffres du code à barres, le dernier chiffre est ajouté automatiquement',
                beforeExpiry: 'avant la fin de DLC ',
                cabinetDown: 'Le frigo connecté est en panne',
                cabinetIsEmpty: 'Le frigo connecté est vide',
                deviceOffline: "L'appareil est hors ligne",
                deviceOnline: "L'appareil est en ligne",
                cabinets: 'Frigos connectés',
                cabinet: 'Frigo connecté',
                cabinetSettings: 'Paramètres du frigo connecté',
                cabinetTheming: "Thème de l'armoire",
                cancel: 'Annuler',
                cancelledSales: 'Ventes annulées',
                cancelledTransactions: 'Opérations annulées',
                categories: 'Catégories',
                change: 'Changer',
                clear: 'Clair',
                close: 'Fermer',
                clickEditButtonRegionalPrices: 'Pour modifier les prix de localisation, cliquez sur le bouton "Modifier" du produit.',
                clickEditLocationPrices: "Prix locaux, cliquez sur le prix du produit pour l'agrandir.",
                clickToExpandLocationPrices: "Prix locaux, cliquez sur le prix du produit pour l'agrandir.",
                closeDoor: 'Fermer la porte',
                rebootDevice: 'Redémarrez appareil',
                commandSentSuccessFully: 'Commandement envoyé avec succès',
                companyLogo: 'Logo de la société',
                confirm: 'Confirmer',
                confirmDelete: 'Êtes-vous sûr de vouloir supprimer le produit ? Cette opération ne peut pas être annulée !',
                confirmDisable: 'Etes-vous sûr de vouloir archiver le produit ?',
                confirmEnable: 'Etes-vous sûr de vouloir rendre le produit actif ?',
                consumerResponses: 'Commentaires utilisateur',
                controlPanelTitle: 'Panneau de contrôle',
                controls_acceptablePercentageOfBadTags: 'Pourcentage acceptable de mauvais tags avant que le frigo connecté ne se mette en mode hors service',
                controls_markTagsGoodQuality: 'Marquer tous les tags de bonne qualité',
                controls_markTagsGoodQuality_btn: 'Marquer les tags de bonne qualité',
                controls_clearTagHistory: "Effacer l'historique des tags",
                controls_clearRemoveReplenishmentData: 'Effacer les données de réapprovisionnement',
                controls_companyAndPaymentLogo: "Logo de l'entreprise et du paiement",
                controls_detectTagQuality: 'Détecter la qualité des tags',
                controls_door: 'Contrôle des portes',
                controls_device: 'Contrôle de l’appareil',
                controls_chargeBadTags: 'Facturer les mauvais tags',
                controls_refill: 'Recharge',
                controls_tagQuality: 'Paramètres des tags',
                controls_tagQualityData: 'Données sur la qualité des tags',
                controls_replenishmentData: 'Données de réapprovisionnement',
                controls_soldTags: 'Tags vendus',
                controls_tagHistory: 'Historique des tags',
                controls_markAllTagsReplenished: 'Marquer tous les tags réapprovisionnés',
                controls_markAllTagsUnsold: 'Marquer tous les tags invendus',
                controls_markAlTagsReplenished_btn: 'Marquer les tags réapprovisionnés',
                controls_markAllTagsUnsold_btn: 'Marquer les tags invendus',
                controls_chargeSoldTags: 'Facturer les tags vendus',
                controls_chargeUnreplenishedTags: 'Facturer les tags non réapprovisionnés',
                controls_tagsWarn:
                    'Effacer l\'historique des tags supprime toutes les données sur les tags. Veuillez cliquer sur "marquer tous les tags réapprovisionnés" après cette action.',
                create: 'Créer',
                createAdTitle: 'Nouvelle annonce',
                createdAd: 'Publicité créée',
                createExpiryDiscountRule: "Créer une règle de remise d'expiration",
                createScheduledDiscountRule: 'Créer une règle de remise planifiée',
                createBundleDiscountRule: 'Créer une règle de remise',
                createNewVatCategory: 'Nouvelle catégorie de TVA',
                createProductTitle: 'Nouveau produit',
                creatingAd: 'Créer une publicité',
                creatingProduct: 'Créer un produit',
                createNewProductCategory: 'Créer une nouvelle catégorie de produits',
                createNewSupplier: 'Ajouter un nouveau fournisseur',
                currency: 'Monnaie',
                customQuery: 'Requête personnalisée',
                dashboard: 'Tableau de bord',
                dateRange: 'Fourchette de dates',
                dataAlredyFetchedTitle: 'Données déjà récupérées',
                dataAlreadyFetchedDescription: 'Veuillez choisir des paramètres différents',
                day: 'Journée',
                days: 'Journées',
                deactivationDate: 'Date de désactivation',
                default: 'Par défaut',
                delete: 'Supprimer',
                deleteError: "Erreur : le produit n'a pas pu être supprimé ",
                deleteSuccess: 'Produit supprimé avec succès',
                description: 'Description',
                deviceManagement: 'Gestion des dispositifs',
                digitwinOffline: " L'appareil Digitwin est hors ligne",
                digitwinOnline: " L'appareil Digitwin est en ligne",
                dimensions: 'Dimensions',
                disable: 'Archives',
                discountAmount: "Montant de l'escompte",
                discountPrice: 'Prix réduit',
                discountRuleAffects: 'La règle du rabais affecte',
                discountRuleName: 'Nom de la règle de remise',
                discountRuleStops: 'Intervalles d’expiration',
                discounts: 'Réductions',
                discountSalesData: 'Ventes avec remises',
                discountSalesAmount: 'Somme des ventes avec remises',
                discountTimeBeforeExpiryExists: 'Un intervalle d’expiration antérieur existe déjà',
                discountVsNormalSales: 'Ventes avec remises par rapport aux ventes normales',
                dismiss: 'Renvoi',
                doesNotExist: "N'existe pas",
                doorCloseConfirm: 'Vous êtes sûr de vouloir fermer la porte ?',
                doorCloseSuccess: 'Fermez la porte avec succès',
                doorOpenConfirm: 'Vous êtes sûr de vouloir ouvrir la porte ?',
                doorOpenReason: 'Pourquoi ouvrez-vous la porte ?',
                doorOpenSuccess: 'Porte ouverte avec succès',
                rebootDeviceSuccess: 'L’appareil a redémarré avec succès',
                deviceRebootConfirm: 'Êtes-vous sûr de vouloir redémarrer appareil ?',
                edit: 'Edit',
                editCompanyLogo: 'Modifier le logo de la société',
                editDiscountRule: 'Modifier la règle de remise',
                editPaymentMethodLogo: 'Modifier le logo du mode de paiement',
                editVatCategoryModalTitle: 'Modifier la catégorie TVA',
                editProductCategoryModalTitle: 'Modifier la catégorie de produit',
                editSupplierModalTitle: 'Editer le fournisseur',
                enable: 'Rendre actif',
                error_401: 'Non authentifié',
                error_403: 'Non autorisé à exécuter des commandes',
                error_500: 'Erreur inattendue du serveur',
                errorCreatingAd: 'Publicité créant une erreur',
                errorCreatingProduct: 'Produit créant des erreurs',
                errorFetchingCabinetStatus: "Erreur dans l'obtention du statut de membre du frigo connecté",
                errorFetchingInventory: "Erreur d'inventaire du frigo connecté",
                errorFetchingReport: "Rapports d'erreurs",
                errorFetchingSettings: 'Erreurs de réglage',
                errorUpdatingProduct: 'Erreur de mise à jour du produit',
                expandToSeeBadTags:
                    'Élargissez la liste pour voir si les tags qui ont été détectés comme défectueux. Les tags défectueux doivent être retirés du frigo connecté.',
                expandToSeeSoldItems: "Développez la liste pour voir les articles qui sont déjà vendus, mais qui sont réapparus dans l'inventaire.",
                expandToSeeReplenishedOutOfInventory:
                    'Développez la liste pour voir les produits qui ont été placés dans le frigo connecté en dehors du réapprovisionnement.',
                expired: 'Expiré',
                expiredCount: 'Compte expiré',
                expiredProductsCount: 'Nombre de produits périmés',
                expiredSum: 'Somme périmée',
                expiring: 'Expiration',
                expiryDate: 'Dernier jour de vente',
                exportExcel: 'Exporter Excel',
                fetchAdvertisements: 'Aller chercher des publicités',
                fetchingCabinets: 'Aller chercher les frigos connectés',
                fetchingCabinetsError: "Une erreur s'est produite lors de la récupération des frigos connectés",
                fetchingCabinetStatus: 'Voir le statut du frigo connecté ',
                fetchingError: "Une erreur s'est produite lors de la récupération",
                fetchingInventory: "Récupérer l'inventaire du frigo connecté",
                fetchingProducts: 'Aller chercher des produits',
                fetchingReport: 'Récupération de rapports',
                fetchingVatCategories: 'Recherche des catégories de TVA',
                fetchingProductCategories: 'Obtenir des catégories de produits',
                fillForm: 'Remplir le formulaire',
                freshFood: "Il s'agit d'un produit périssable",
                freezerPackage: 'Produit congelé',
                generalFetchError: 'Erreur lors de la récupération',
                generalRequestReject: 'Erreur de demande',
                get: 'Consultez le site',
                group: 'Groupe',
                holdOn: 'Attendez',
                hour: 'Heure',
                hours: 'heures',
                hoursBeforeExpiry: "Heures avant l'expiration",
                daysBeforeExpiry: 'Jours avant expiration',
                image: 'Image',
                imageOrVideo: 'Sélectionnez une image ou une vidéo',
                inActive: 'Inactif',
                individualCabinets: 'Frigos connectés individuels',
                individualCabinetsOnly: 'Frigos connectés individuels uniquement',
                invalidBarcodeOnlyDigits: 'Le code à barres ne doit contenir que des chiffres',
                invalidBarcodeTooLong: 'Le code à barres est trop long',
                invalidBarcodeTooShort: 'Le code à barres est trop court',
                invalidCabinetNameTooShort: 'Le nom du frigo connecté est trop court',
                invalidCabinetNameDuplicated: 'Le nom du frigo connecté existe déjà',
                invalidLocationNameTooShort: "Le nom de l'emplacement est trop court",
                invalidLocationNameDuplicated: "Le nom de l'emplacement existe déjà",
                invalidPercentage: 'Valeur invalide',
                invalidPrice: 'Prix invalide',
                invalidProductBarcode: 'Code à barres du produit non valide',
                invalidTitle: 'Titre invalide',
                invalidBundleItems: 'Contient des éléments non valides',
                inventoryLength: "Longueur de l'inventaire",
                inventoryNotReported: "Le frigo connecté n'a pas fait état de son inventaire",
                inventoryTitle: 'Inventaire du frigo connecté ',
                'Inventory History': "Historique d'inventaire",
                itemReplenishedOutOfInventoryProcess: 'Ce produit ou ce tag a été placé dans le frigo connecté à un autre moment que le réapprovisionnement.',
                itemSoldOn: 'Article vendu le',
                'Last 30 days': 'Les 30 derniers jours',
                'Last 7 days': 'Les 7 derniers jours',
                'Last month': 'Le mois dernier',
                'Last quarter': 'Dernier trimestre',
                'Last week': 'La semaine dernière',
                'Last year': "L'année dernière",
                lastUpdate: 'Dernière mise à jour',
                lifetime: 'Durée de vie ',
                location: 'Lieu',
                locations: 'Sites',
                logoEditor: 'Éditeur du logo',
                logoSettings: 'Paramètres du logo',
                loading: 'Chargement…',
                measure: 'Mesure',
                measures: 'Mesures',
                minsBeforeExpiry: "Quelques minutes avant l'expiration",
                minutes: 'procès-verbal',
                month: 'Mois',
                name: 'Nom',
                newVatCategoryModalTitle: 'Nouvelle catégorie de TVA',
                newProductCategoryModalTitle: 'Nouvelle catégorie de produits',
                newSupplierModalTitle: 'Nouveau fournisseur',
                'no region': '(pas de localisation)',
                noAdvertisements: 'Pas de publicité',
                noCabinets: 'Pas de frigos connectés',
                noCabinetSelected: 'Aucun frigo connecté sélectionné',
                noLogo: 'Pas de logo',
                noData: 'Pas de données',
                noDescription: 'Pas de description',
                noEvents: "Pas d'événements !",
                noIndividualCabinets: 'Pas de frigo connecté individuel',
                noLocationPrices: "Aucun prix local n'est défini pour ce produit",
                none: 'Aucune',
                noPaymentMethodLogo: 'Pas de logo de mode de paiement',
                noProducts: 'Pas de produits',
                noProductsRemoved: "Aucun produit n'a été retiré",
                noProductTypeSelected: "Aucun type d'emballage sélectionné",
                normalPrice: 'Prix normal',
                normalSalesAmount: 'Somme des ventes normales',
                notAccessibleForYou:
                    "La fonction de création/mise à jour n'est activée que pour les administrateurs et les opérateurs du frigo connecté. Pour y accéder, contactez votre superviseur ou l'administrateur du système.",
                notDefined: 'Non défini',
                noTitle: 'Pas de titre',
                noVatCategories: 'Aucun enregistrement de catégorie de TVA trouvé',
                noProductCategories: 'Aucun enregistrement de catégorie de produit trouvé',
                noInventoryOnSelectedDate:
                    "Aucun inventaire n'a été signalé pour la date que vous avez sélectionnée. Veuillez essayer avec une date différente.",
                openDoor: 'Ouvrez la porte',
                orderSum: 'Montant de la commande',
                paymentMethodLogo: 'Logo du mode de paiement',
                percentage: 'Pourcentage',
                permanent: 'Permanent',
                preview: 'Aperçu',
                price: 'Prix',
                print: 'Imprimer',
                productCreated: 'Produit créé',
                products: 'Produits',
                productsFetchError: "Une erreur s'est produite lors de la récupération des produits",
                productsTitle: 'Catalogue des produits',
                productType: "Type d'emballage",
                productUpdated: 'Produit mis à jour',
                quantity: 'Quantité',
                readerDown: 'Le lecteur RFID est en panne',
                region: 'Lieu',
                regions: 'Sites',
                removedAt: 'Supprimée à',
                removeImage: "Supprimer l'image",
                removeVideo: 'Supprimer la vidéo',
                refillQRCodeInfo: 'Scannez le code QR pour ouvrir la demande de recharge ou suivez',
                refillQRCodeTitle: 'Code QR de la recharge',
                refillRuleTitle: 'Règle de réapprovisionnement',
                reportsTitle: 'Rapports',
                requestSuccess: 'Des changements réalisés avec succès',
                requiredPercentage: 'Obligatoire. Valeur du pourcentage sans signe de pourcentage.',
                requiredText: 'Longueur requise des caractères: ',
                retireLocation: 'Lieu de retraite',
                rfidNoInfo: 'Aucune information disponible sur le lecteur et les antennes RFID',
                rfidOk: 'Le lecteur et les antennes RFID fonctionnent comme prévu.',
                sales: 'Ventes',
                salesCount: 'Nombre des ventes',
                salesVsWasteAmount: 'Ventes contre quantité de déchets',
                save: 'Sauvegarder',
                search: 'Recherchez',
                selectCabinet: 'Sélectionnez un frigo connecté',
                selectDiscountRuleCabinets: 'Sélectionner les frigos connectés  concernés par la règle de remise',
                selectDiscountRuleLocations: 'Sélectionnez les lieux concernés par la règle de remise',
                selectDiscountRuleProducts: 'Sélectionnez les produits concernés par la règle de remise',
                selectProductType: "Veuillez sélectionner le type d'emballage pour votre produit",
                setDiscountAmount: 'Fixer le montant de la remise',
                setDiscountTimeBeforeExpiry: 'Définir l’intervalle d’expiration',
                settingConfirmMessage: "Êtes-vous sûr de sauvegarder ces paramètres ? Si vous n'êtes pas sûr, ne le confirmez pas",
                showDisable: 'Produits archivés',
                summary: 'Résumé',
                sureRemoveDiscountRule: 'Êtes-vous sûr de vouloir supprimer la règle de rabais ?',
                tagIsBad: 'Le tag est mauvais, il doit être retiré du frigo connecté.',
                tagsRemovedReport: 'Rapport sur les tags retirés',
                taxValue: 'Valeur fiscale',
                'This month': 'Ce mois-ci',
                'This quarter': 'Ce trimestre',
                'This week': 'Cette semaine',
                'This year': 'Cette année',
                thisLink: ' ce lien. ',
                timeGrouping: 'Groupement horaire',
                title: 'Titre',
                Today: "Aujourd'hui",
                topExpiredProducts: 'Principaux produits périmés',
                topProducts: 'Principaux produits',
                total: 'Total',
                totalValue: 'Valeur totale',
                transactions: 'Transactions',
                ttlInDays: 'Durée de vie du produit (durée de vie) en jours',
                ttlsAffected: 'Produits dont le temps de vie (à vie) est affecté',
                unexpectedError: 'Erreur inattendue',
                uniqueCustomers: 'Clients uniques',
                unknown: 'Inconnu',
                update: 'Mise à jour',
                updateAdError: 'Erreur de mise à jour de la publicité',
                updateAdTitle: "Mise à jour de l'annonce",
                updatedAd: 'Annonce mise à jour',
                updateCabinetSettings: "Synchroniser les données avec l'armoire",
                updateCabinetSettingsInfo: "Voulez-vous vraiment mettre à jour les paramètres de l'armoire ?",
                updateData: 'Mise à jour des données',
                updateProductTitle: 'Mise à jour du produit',
                updatingAd: 'Mise à jour de la publicité',
                updatingProduct: 'Mise à jour du produit',
                uploading: 'Téléchargement',
                uploadingImage: "Chargement d'une image",
                use: 'Utilisez',
                'w/o grouping': 'Pas de regroupement',
                waste: 'Déchets',
                vatCategoriesFetchError: 'Erreur dans la recherche des catégories de TVA',
                vatCategoriesTitle: 'Catégories de TVA',
                vatCategory: 'Catégorie TVA',
                vatCategoryItems: 'Articles',
                vatCategoryName: 'Nom de la catégorie TVA',
                vatCategoryPromptMessage: 'Êtes-vous sûr de vouloir supprimer la catégorie TVA',
                vatCategoryValue: 'Pourcentage de la valeur fiscale',
                vatDeleteRejected: 'Suppression de la catégorie TVA rejetée. Vérifier les produits associés.',
                vatErrorRequesting: 'Erreur dans le traitement de la demande',
                vatRequestRejected: 'Demande rejetée',
                vats: 'TVA',
                productCategoriesFetchError: "Erreur lors de l'obtention des catégories de produits",
                productCategoriesTitle: 'Catégories de Produits',
                productCategory: 'Catégorie de Produit',
                productCategoryItems: 'Catégories',
                productCategoryName: 'Nom de la catégorie de produits',
                productCategoryPromptMessage: 'Voulez-vous vraiment supprimer la catégorie de produits ?',
                productCategoryDescription: 'Description de la catégorie de produit',
                productCategoryHelpTextDescription: 'Par exemple, catégorie pour les salades',
                productCategoryHelpTextName: 'Par exemple les salades',
                productCategoryDeleteRejected: 'La suppression de la catégorie de produit a été refusée. Découvrez les produits associés !',
                week: 'Semaine',
                viewMode: 'Voir',
                year: 'Année',
                Yesterday: 'Hier',
                groupBy: 'Grouper par',
                filterBy: 'Filtrer par',
                reportType: 'Type de rapport',
                custom: 'Personnalisé',
                preDefined: 'Prédéfini',
                selected: 'Sélectionné',
                metricsInfo: 'Les indicateurs de performance (somme, maximum, minimum...) sont calculés pour tous les champs sélectionnés.',
                dimensionsInfo: 'Les indicateurs de performance seront récupérés pour les champs sélectionnés.',
                metrics: 'Indicateurs de performance ',
                controlsSoftwareVersion: 'Version du logiciel',
                currentSoftwareVersion: 'Version actuelle du logiciel',
                availableSoftwareVersion: 'Version du logiciel disponible',
                invalidLifetime: 'Durée de vie invalide',
                unpaidSales: 'Ventes impayées',
                unpaidTransactions: 'Transactions impayées',
                softwareUpdateConfirmation: 'Souhaitez-vous vraiment mettre à jour la version du logiciel sur le ou les appareils sélectionnés ?',
                softwareUpdateWarn:
                    'La mise à jour de la version du logiciel enverra une commande au ou aux appareils pour récupérer la mise à jour maintenant ou à l’heure sélectionnée. Le ou les appareils sélectionnés installeront la mise à jour la prochaine fois qu’ils seront en ligne après le déclenchement. L’installation peut prendre du temps en fonction de la vitesse de connexion. Le ou les appareils redémarreront après l’installation.',
                releaseVersion: 'Version du logiciel',
                releaseDate: 'Date de sortie',
                latestRelease: 'Dernière version',
                currentVersion: 'Version actuelle',
                softwareUpdateSuccess: 'La version du logiciel a été mise à jour',
                prebooking: 'Pré-réservation',
                prebookedProduct: 'Produit pré-réservé',
                prebookedProducts: 'Produits pré-réservés',
                activeProducts: 'Produits actifs',
                startMaintenanceMode: 'Démarrer le mode maintenance du frigo connecté',
                endMaintenanceMode: 'Fin du mode maintenance du frigo connecté',
                startMaintenanceConfirm: 'Etes-vous sûr de vouloir démarrer le mode maintenance du frigo connecté?',
                endMaintenanceConfirm: 'Etes-vous sûr de vouloir terminer le mode maintenance du frigo connecté?',
                startMaintenanceSuccess: 'Début du mode maintenance pour le frigo connecté',
                endMaintenanceSuccess: 'Fin du mode maintenance pour le frigo connecté',
                noResults: 'Aucun résultat',
                suppliers: 'Fournisseurs',
                supplier: 'Fournisseur',
                supplierDeleteRejected: 'Retirer le fournisseur rejeté. Vérifiez les produits associés !',
                supplierPromptMessage: 'Êtes-vous sûr de vouloir supprimer le fournisseur ?',
                fetchingSuppliers: 'Obtenir des fournisseurs',
                noSuppliers: 'Aucune fiche fournisseur trouvée',
                suppliersFetchError: "Erreur dans l'obtention des fournisseurs",
                supplierName: 'Nom du fournisseur',
                supplierHelpTextName: 'Par exemple, un nom de marque',
                supplierDescription: 'Description du fournisseur',
                supplierHelpTextDescription: "Par exemple, la description d'une marque",
                wishes: 'Souhaits',
                checkNow: 'Vérifier maintenant',
                startTimeOn: 'Heure de début à',
                endTimeOn: 'Heure de fin à',
                signOutConfirmationModalTitle: 'Confirmation de déconnexion',
                signOutConfirmationModalContent: 'Voulez-vous vraiment vous déconnecter ?',
                startDate: 'Date de début',
                endDate: 'Date de fin',
                scheduledDiscountApplyAllDays: 'Appliquer pour tous les jours',
                scheduledDiscountApplySelectedDays: 'Appliquer pour les jours sélectionnés',
                discountCustomImageTitle: 'Image de la remise (facultatif)',
                discountCustomImageModalHeader: 'Image pour la remise',
                saveChanges: 'Enregistrer les modifications',
                updateTagsQualitySettingsTitle: "Paramètres de qualité d'étiquette",
                updateTagsQualitySettingsMessage: "Paramètres de qualité d'étiquette mis à jour",
                IF3Cabinets: 'Réfrigérateurs modèle 3',
                IF2Cabinets: 'Réfrigérateurs modèle 2',
                IF3Freezers: 'Congélateurs modèle 3',
                encodingStations: 'Stations de codage',
                if2: 'Réfrigérateur modèle 2',
                if3: 'Réfrigérateur modèle 3',
                if3Freezer: 'Congélateur modèle 3',
                legalNotice: 'Mentions légales',
                privacyPolicy: 'Politique de confidentialité',
                scheduledDiscountDateMissingError: 'Les deux dates sont nécessaires si la date de début ou de fin est définie',
                scheduledDiscountDuplicateTimeError: 'Les heures ne peuvent pas être les mêmes si les dates sont les mêmes',
                now: 'Maintenant',
                minimum: 'Minimum',
                maximum: 'Maximum',
                discardChangesConfirmationText: 'Êtes-vous sûr de vouloir annuler les modifications ?',
                exports: 'Exportations',
                selectAllCabinets: 'Sélectionner toutes les armoires',
                expiryIntervals: 'Intervalles d’expiration',
                timeBeforeExpiry: 'Délai avant expiration',
                apply: 'Appliquer',
                yes: 'Oui',
                no: 'Non',
                productOnScreenBannerText: 'Texte de la bannière d’information à l’écran',
                productOnScreenBannerAdditionalInfo:
                    'Le texte de la bannière est un texte d’une ligne qui apparaît sous l’image du produit et qui transmet de manière concise les principales caractéristiques, les bénéfices pour la santé ou toute autre information complémentaire sur le produit',
                productOnScreenBannerExampleText: 'Sans lactose',
                UI_IS_DOWN: "L'affichage du cabinet ne fonctionne pas",
                READER_IS_DOWN: 'Le lecteur RFID est hors service',
                ANTENOR_DEVICE_DISCONNECTED: 'Le dispositif de paiement est déconnecté',
                EXPIRED_PRODUCT: 'Produits expirés dans le cabinet',
                CLOUD_SERVICE_DOWN: 'Service cloud indisponible',
                ANTENNA_DISCONNECTED: 'Antenne déconnectée',
                TAG_CHANGES: 'Trop de mauvais tags dans le cabinet',
                ANTENNA_DOWN: "L'antenne RFID dans le cabinet ne fonctionne pas",
                READER_CONFIG_ERROR: 'Échec de la configuration du lecteur RFID et il ne fonctionne pas',
                activeAlarms: 'Alarmes actives',
                deviceStatus: "État de l'appareil",
                lastMonitored: 'Dernière surveillance',
                noService: 'Hors service',
                operational: 'Opérationnel',
                offline: 'Offline',
                locationsSelectHelp: "Lorsqu'un emplacement est ajouté, ses emplacements enfants seront également ajoutés.",
                select: 'Sélectionner...',
                labels: 'Étiquettes',
                productDetails: 'Détails du produit',
                invalidProductName: 'Le nom du produit doit comporter entre 1 et 125 caractères',
                standardPrice: 'Prix standard',
                locationPrices: 'Prix de localisation',
                addNew: 'Ajouter un nouveau',
                productExpiry: 'Expiration du produit',
                productLifeTime: 'Durée de vie du produit',
                invalidTTL: 'La durée de vie du produit doit être un entier compris entre 0 et 32767',
                productImage: 'Image du produit',
                uploadImage: "Télécharger l'image",
                pleaseUploadImage: 'Veuillez télécharger une image',
                productValidationError: "Veuillez corriger la ou les erreurs suivantes avant d'enregistrer les données du produit",
                productSupplier: 'Fournisseur de produits',
                frozenProductLable: 'Ceci est un produit surgelé',
                prebookingLabel: 'Ceci est un produit de pré-réservation',
                previous: 'Précédent',
                bannerText: 'Texte de la bannière',
                packagingTypeValidationError: "Veuillez sélectionner un type d'emballage pour le produit",
                showOnlyFrozen: 'Afficher uniquement les produits surgelés',
                productClassification: 'Classification du produit',
                productClassLabel:
                    'Veuillez sélectionner la classe appropriée pour le produit. Cela permet à Selfly Insights de fournir des informations sur le produit plus pertinentes.',
                productLabels: 'Étiquettes de produits',
                productLabelsHelpText:
                    'Veuillez ajouter des étiquettes facultatives à votre produit. Cela vous permettra de filtrer vos produits dans le catalogue de produits.',
                newLabel: 'Nouvelle étiquette',
                productClassValidationError: 'Veuillez sélectionner une classification pour votre produit parmi les options ci-dessous',
                tagSensitivity: "Sensibilité de qualité de l'étiquette",
                tagSensitivitySetting: "Réglage de la sensibilité de l'étiquette",
                configTagSensitivity: "Configurer la sensibilité de l'étiquette pour le produit",
                productLevelSensitivityInfo:
                    "La lisibilité d'une étiquette RFID attachée à un produit peut être influencée par divers facteurs, notamment les matériaux d'emballage, les surfaces métalliques, l'humidité, et même le contenu du produit. Ces facteurs peuvent parfois entraîner des signaux intermittents de l'étiquette, appelés scintillements. Le cabinet traite ces scintillements en fonction de ses paramètres de sensibilité. Si vous n'êtes pas sûr du niveau de sensibilité approprié pour ce produit, vous pouvez ignorer cette section.",
                errorSaving: "Erreur lors de l'enregistrement des données du produit",
                successSaving: 'Données du produit enregistrées avec succès',
                basicDetail: 'Bases',
                additionalDetail: 'Détails supplémentaires',
                packaging: 'Type de colis',
                classification: 'Classement',
                selectOrtype: 'Sélectionner ou saisir...',
                max25Chars: 'maximum 25 caractères',
                noVatForProductCreation: "Veuillez créer une catégorie de TVA avant d'ajouter de nouveaux produits",
                lifeTimeExplanation: "Combien de jours ce produit est valable à la vente. (0 jours) signifie jusqu'à la fin de la journée en cours",
                selflyEncode: 'Selfly Encode',
                userRestrict: 'Seulement les consommateurs reconnus',
                userRestrictTitle: 'Consommateurs éligibles',
                userNotRestrict: 'Tous les consommateurs',
                userRestrictHelperText:
                    "L'option consommateurs reconnus signifie que la réduction ne sera appliquée qu'aux consommateurs déjà inscrits pour l'éligibilité à la réduction!",
                searchCabinets: 'Rechercher des armoires',
                selectAll: 'Tout sélectionner',
                monday: 'Lundi',
                tuesday: 'Mardi',
                wednesday: 'Mercredi',
                thursday: 'Jeudi',
                friday: 'Vendredi',
                saturday: 'Samedi',
                sunday: 'Dimanche',
                fetchingLoader: 'Récupération. Veuillez patienter...',
                category: 'Catégorie',
                okay: 'Ok',
                noLocation: 'Pas de lieu',
                copy: 'Copier',
                pleaseConfirm: 'Veuillez confirmer',
                saveQuery: 'Enregistrer cette requête',
                saveQueryPlaceholder: 'ex. "Nombre total d\'articles vendus"',
                saveQuerySuccess: 'Requête enregistrée.',
                yourSavedQueries: 'Vos requêtes enregistrées',
                selectQuery: 'Sélectionner une requête',
                noQuery: 'Aucune requête enregistrée pour le moment',
                deleteAdConfirm: 'Êtes-vous sûr de vouloir supprimer cette annonce ?',
                dataSaved: 'Données enregistrées avec succès',
            },
            refill: {
                refillRuleApplyRuleDropdownText: 'Cette règle s’applique également aux autres armoires',
                refillPlanGenerate: 'Générer un plan de réapprovisionnement',
                refillPlanEditPlan: 'Modifier le plan',
                refillAmount: 'Quantité de réapprovisionnement',
                refillRuleTargetAmount: 'Niveau d’inventaire cible',
                refillRuleTargetAmountShort: 'Cible',
                refillPlanActivePlans: 'Plans actifs',
                refillPlanCompletedPlans: 'Plans achevés',
                refillNote: 'Note de remplissage',
                refillNoteHelp: 'Note de remplissage du produit (maximum 125 caractères)',
                refillPDFText:
                    "Ci-dessous se trouve le plan de remplissage pour le placard. Assurez-vous de marquer le plan de remplissage comme terminé dans l'application de remplissage après avoir terminé le remplissage.",
                refillPDFSummaryText:
                    'Ci-dessous se trouve le résumé des plans de remplissage avec les produits à ajouter et à supprimer pour tous les placards sélectionnés.',
                refillSummary: 'Résumé de remplissage',
                addItems: 'Ajouter des produits',
                removeItems: 'Supprimer des produits',
                inventoryChange: "Changement d'inventaire",
                planogramPDFText: 'Planogramme pour la stratégie de réapprovisionnement du cabinet.',
                planogramImagePDFText: 'Image personnalisée pour la stratégie de réapprovisionnement du cabinet.',
                refillSchemas: 'Modèles de réapprovisionnement',
                refillPlans: 'Plans de réapprovisionnement',
                refillSchemaTitle:
                    'Définissez les quantités cibles et minimales de stock en tant que modèle pour les armoires sélectionnées. Ces modèles seront utilisés pour générer les plans de réapprovisionnement.',
                refillPlansTitle:
                    'Voir tous les plans actifs. Vous pouvez modifier ou supprimer les plans actifs existants, ou ajouter de nouveaux plans de réapprovisionnement si nécessaire.',
                refillSchemaSubText: 'Veuillez sélectionner une armoire pour créer ou mettre à jour le modèle de réapprovisionnement.',
                noRefillSchema: "Aucun modèle de réapprovisionnement n'a encore été défini pour cette armoire.",
                createRefillSchema: 'Créer un nouveau modèle de réapprovisionnement',
                noDevice: 'Aucun appareil trouvé',
                schemaSaved: 'Modèle de réapprovisionnement enregistré avec succès',
                deleteSchemaConfirmation: 'Êtes-vous sûr de vouloir supprimer le modèle de réapprovisionnement ?',
                targetAmount: 'Quantité cible',
                targetAmountHelpText: "La quantité de stock cible pour chaque produit dans l'armoire après le réapprovisionnement.",
                minimumThreshold: 'Seuil minimum',
                minimumThresholdHelpText:
                    "Spécifiez le niveau de stock minimum pour chaque produit dans l'armoire. Si la quantité tombe en dessous du seuil défini, vous recevrez une notification d'alarme. Définir le seuil à 0 désactivera les notifications.",
                editNote: 'Modifier la note',
                refillNoteTitle: 'Écrire une note de réapprovisionnement pour le produit.',
                refillNoteCharacters: 'caractères',
                noRefillPlan:
                    "Il n'y a actuellement aucun plan de réapprovisionnement actif. Veuillez ajouter de nouveaux plans de réapprovisionnement pour vos armoires ou créer un modèle de réapprovisionnement pour les armoires en premier.",
                planSaved: 'Plan de réapprovisionnement enregistré avec succès',
                deleteConfirmation: 'Êtes-vous sûr de vouloir supprimer les données de réapprovisionnement sélectionnées ?',
                editPlan: 'Modifier le plan',
                currentInventory: 'Stock actuel',
                lastSevenDaySales: 'Ventes des sept derniers jours',
                inventory: 'Inventaire',
                planogram: 'Planogramme',
                copyRefillSchema: "Copier le modèle vers d'autres armoires",
                generatingPlans: 'Génération des plans de réapprovisionnement. Veuillez patienter...',
                generatePlans: 'Générer des plans de réapprovisionnement',
                complete: 'complet',
                selectCabinetsToAddPlans: 'Veuillez sélectionner des armoires pour générer des plans de réapprovisionnement.',
                reviewSuggestion:
                    'Voici les plans de réapprovisionnement proposés pour les armoires sélectionnées. Veuillez les examiner, les modifier si nécessaire, et les enregistrer pour activer ces plans.',
                noSchema:
                    "Aucune armoire ne dispose actuellement d'un modèle de réapprovisionnement. Veuillez ajouter un modèle de réapprovisionnement à vos armoires pour générer des plans de réapprovisionnement.",
                searchProducts: 'Rechercher des produits',
                maxAllowedInventory: "L'inventaire maximal autorisé est de 200.",
                savingData: 'Sauvegarde des données. Veuillez patienter...',
                errorSavingData: 'Erreur lors de la sauvegarde des données.',
                refillPlanSaved: 'Plans de réapprovisionnement générés avec succès.',
                exportPDFHelp: 'Exporter les plans sélectionnés au format PDF.',
                exportExcelHelp: 'Exporter les plans sélectionnés au format Excel.',
                addPlanHelp: 'Ajouter de nouveaux plans de réapprovisionnement.',
                deletePlanHelp: 'Supprimer les plans de réapprovisionnement sélectionnés.',
                generatePlansHelp: 'Générer des plans de réapprovisionnement pour les armoires sélectionnées.',
                planogramSaved: 'Planogramme enregistré avec succès.',
                schemaCopied: 'Le modèle de réapprovisionnement a été synchronisé avec succès avec les autres armoires.',
                allProducts: 'Tous les produits',
                schemaProducts: 'Produits du modèle de réapprovisionnement',
                deletePlanogram: 'Êtes-vous sûr de vouloir supprimer le planogramme ?',
                dragInPlanogram:
                    "Veuillez glisser-déposer des produits sur les étagères désignées de l'armoire, ou glisser l'emplacement de l'armoire sur le produit.",
                noPlanogramInSchema: "Aucun planogramme n'a encore été défini pour cette armoire.",
                noPlanogramInPlan:
                    "Aucune donnée de planogramme n'est disponible pour l'armoire. Veuillez d'abord créer un planogramme pour l'armoire dans l'onglet modèle de réapprovisionnement.",
                copyPlanogram: 'Copier le planogramme de réapprovisionnement avec le modèle de réapprovisionnement.',
                planogramImageInfo: 'En plus du planogramme, vous pouvez également ajouter une image de planogramme personnalisée.',
                addPlanogramImage: 'Ajouter une image de planogramme',
                editPlanogramImage: "Modifier l'image de planogramme",
                numberOfShelves: "Nombre d'étagères",
                addNewRefillPlan: 'Ajouter de nouveaux plans',
                qrcodeTitle: "Un code QR sera généré pour l'armoire sélectionnée afin de lancer le processus de réapprovisionnement.",
                qrcodeSubText: 'Veuillez sélectionner une armoire où vous souhaitez lancer le processus de réapprovisionnement.',
                qrcodeScan: "Veuillez scanner le code QR pour réapprovisionner, ou cliquer sur le bouton pour ouvrir l'application de réapprovisionnement.",
                qrcodeOpenApplication: "Ouvrir l'application de réapprovisionnement",
                createRefillPlanogram: 'Créer un planogramme de réapprovisionnement',
            },
            navigation: {
                advertisements: 'Annonces publicitaires',
                analytics: 'Analytique',
                controlPanel: 'Panneau de contrôle',
                deviceManagement: 'Gestion des dispositifs',
                discounts: 'Réductions',
                fetching: 'Aller chercher',
                group: 'Groupe',
                inventory: 'Inventaire du frigo connecté',
                language: 'Langue',
                productCatalog: 'Catalogue des produits',
                productCategories: 'Catégories de Produits',
                reports: 'Rapports',
                settings: 'Paramètres utilisateur',
                signOut: 'Déconnexion',
                title: 'Portail "Selfly Cloud"',
                vatCategories: 'Catégories de TVA',
                community: 'Selfly Community',
                refillManagement: 'Gestion du réapprovisionnement',
                automations: 'Automatisations',
                mySelfly: 'Paramètres MySelfly',
                automationInfo: 'Gérer les actions automatisées (BETA)',
            },
            reports: {
                alreadyRefund: 'a été remboursé',
                amount: 'Montant',
                average: 'Moyenne',
                barcode: 'Code à barres',
                confirmIssueRefundButton: 'Confirmer le remboursement maintenant',
                current: 'En cours',
                export: 'Exporter',
                fetching: 'Aller chercher',
                fetchingError: "Une erreur s'est produite lors de la récupération",
                image: 'Image',
                issueRefundButton: 'R E F U N D',
                maximum: 'Maximum',
                minimum: 'Au minimum',
                name: 'Nom',
                noData: 'Pas de données',
                note: 'Remarque',
                order: 'Transaction',
                paymentWasDoneByCreditCard: 'Le paiement a été effectué par carte de crédit',
                price: 'Prix',
                ProductReport: 'Rapport sur les produits',
                quantity: 'Quantité',
                "Refund impossible'":
                    "Le paiement a été effectué par carte de crédit. Contactez l'émetteur du terminal de carte de crédit pour l'opération de remboursement.",
                refundFormTitle: 'Remboursement',
                refundReason: 'Motif du remboursement',
                refundSuccess: 'La somme {{sum}} a été remboursée avec succès',
                refundTotal: 'Montant du remboursement',
                replenishments: 'Recharges',
                replenishmentsTitle: 'Rapport de recharge',
                SaleTransaction: 'Transaction de vente',
                summary: 'Résumé',
                SummaryReport: 'Résumé des ventes',
                temperature: 'Température',
                temperatureTitle: 'Rapport sur la température',
                temperatureValueNotification: 'Les valeurs de la température sont les valeurs moyennes horaires de la température du frigo',
                time: 'Heure',
                total: 'Total',
                transactions: 'Transactions',
                user: 'Utilisateur',
                paidTransactions: 'Transactions payées',
                unpaidTransactions: 'Transactions impayées',
                partiallyPaid: 'Partiellement payé',
                failedTransactions: 'Transactions échouées',
                amountOwed: 'Montant dû',
                receiptLinkText: 'Obtenir un reçu ici',
                noPurchase: 'Aucun achat',
                other: 'Autre',
                userId: 'ID Utilisateur :',
            },
            settings: {
                block_cabinet_when_product_expired: 'Armoire à bloc pour les produits périmés',
                criticalExpiryLimitHours: "Heures avant l'alerte d'expiration critique",
                language: 'Langue',
                logo: 'Logo de la société',
                mediumExpiryLimitHours: "Heures avant l'alerte d'expiration moyenne",
                payment_method_logo: 'Logo du mode de paiement',
                footer_instruction_logo: "Image d'instructions de pied de page",
                readUserMemory: 'Obtenir des données sur la péremption des produits',
                ui_skus_sort_by: 'Ordre de tri des produits dans l’affichage de l’armoire',
                timeZone: 'Fuseau horaire',
                country: 'Pays',
                city: 'Ville',
                tagSettings: 'Paramètres des étiquettes',
                themeAndLogos: 'Thème et logos',
                advancedSettings: 'Paramètres avancés',
                themingInfoText: 'Vous pouvez personnaliser le thème de l’écran de votre armoire',
                cabinetLogos: 'Logos du cabinet',
                cabinetLogoInfoText: 'Télécharger les logos qui apparaissent sur l’écran de l’armoire',
                logoHelperText: 'Veuillez utiliser une image de 180 px x 180 px',
                settingsCopyInfoHeader: 'Sélectionnez les armoires dans lesquelles vous souhaitez copier les paramètres',
                settingsCopyButton: 'Copier les paramètres dans les armoires sélectionnées',
                settingsCopySuccessModalTitle: 'Paramètres de copie',
                settingsCopySuccessModalContent: 'Les paramètres ont été copiés sur {{cabinetsAmount}} armoire(s) avec succès',
                theming: 'Thème',
                adminSettings: 'Paramètres administrateur',
                settingsCopyErrorModalTitle: 'Erreur de copie des paramètres',
                key: 'Réglage',
                value: 'Valeur',
                sleepScheduling: 'Veille programmée',
                sensitivityLevel: 'Niveau de sensibilité',
                sensitivity_0:
                    "L'armoire ignore le scintillement de l'étiquette et marque systématiquement toutes les étiquettes comme valides, ce qui peut parfois entraîner une facturation incorrecte.",
                sensitivity_20: "L'armoire tolère jusqu'à trois mauvaises lectures d'une étiquette sur 50 scans et marque toujours l'étiquette comme valide.",
                sensitivity_40: "L'armoire tolère jusqu'à deux mauvaises lectures d'une étiquette sur 60 scans et marque toujours l'étiquette comme valide.",
                sensitivity_60:
                    "Si une étiquette scintille fréquemment mais reste stable sur une période prolongée, l'armoire tolère cela et considère l'étiquette comme valide.",
                sensitivity_80:
                    'Si une étiquette scintille momentanément mais reste stable sur une longue période, elle sera considérée comme une étiquette valide.',
                sensitivity_100:
                    "L'armoire est très intolérante au scintillement des étiquettes. Même une seule lecture incorrecte peut faire en sorte qu'une étiquette soit marquée comme invalide pendant une période prolongée, ce qui peut entraîner des pertes potentielles.",
                sensitivityInfo:
                    "La sensibilité à la qualité des étiquettes d'une armoire se réfère à la manière dont l'armoire gère les lectures incohérentes des étiquettes ou leur scintillement. Veuillez faire attention au niveau de sensibilité que vous choisissez, car cela peut affecter les performances de l'armoire. Si vous n'êtes pas sûr du niveau approprié, il est recommandé de laisser le réglage au niveau par défaut de 3.",
            },
            signIn: {
                signInButton: "S'identifier",
                signInExpiredError: 'Votre session est terminée. Connectez-vous à nouveau',
                signInMessage: 'Vous devez être connecté pour continuer. Cliquez sur le bouton ci-dessous pour vous connecter',
                noOrganizationsError: 'Aucune organisation liée à ce nom d’utilisateur. Essayez un autre nom d’utilisateur.',
            },
            translations: {
                add: 'Ajouter',
                closed: 'Fermé',
                delete: 'Supprimer',
                device: 'Dispositif',
                edit: 'Modifier',
                off: 'OFF',
                offline: 'Hors ligne',
                on: 'ON',
                online: 'En ligne',
                opened: 'Ouvrez',
                save: 'Sauvegarder',
                seeDetails: 'Détails',
                settings: 'Paramètres',
                unknown: 'Inconnu',
            },
            consumerApp: {
                enableConsumerFeedback: 'Activer les commentaires utilisateur sur MySelfly',
                enablePurchaseReceipts: 'Activer les reçus électroniques sur MySelfly',
                enableSelflyTrademarkForReceipts: 'Autoriser le pied de page Selfy Store dans les reçus électroniques',
                enableSelflyTrademarkForReceiptsHelpText:
                    'L’activation de cette option ajoutera le logo et des détails Selfly Store sur le PDF du reçu électronique !',
                organizationFormHelpText: 'Veuillez fournir les coordonnées de l’émetteur du reçu électronique.',
                organizationFormValidationHelpText:
                    '* Les champs obligatoires pour le reçu électronique sont le nom de l’organisation émettrice, le numéro d’entreprise, l’adresse et le pays.',
                organizationName: 'Nom de l’organisation',
                organizationLogo: 'Logo de l’organisation',
                businessId: 'ID d’entreprise',
                city: 'Ville',
                email: 'E-mail',
                website: 'Site Web',
                postalCode: 'Code postal',
                street: 'Adresse postale',
                phoneNumber: 'Numéro de téléphone',
                addressHeader: '* Informations sur l’adresse',
                contactDetails: 'Détails du contact',
                organization: '* Organisation émettrice',
                organizationSettingsUpdatedTitle: 'Mise à jour des paramètres de l’organisation',
                organizationSettingsUpdatedContent: 'Les paramètres de l’organisation ont bien été mis à jour',
                organizationSettingsMissingFields: 'Champs obligatoires pour le reçu électronique manquants. Veuillez remplir avant d’enregistrer.',
                organizationSettingsMissingFieldsTitle: 'Erreur de validation du formulaire',
            },
            softwareVersions: {
                updateNotificationTitle: 'Mise à jour de la version logicielle',
                updateNotificationContent: 'Version(s) logiciel(les) mise(s) à jour avec succès',
                selectAVersionPlaceholder: 'Sélectionner une version',
                searchDevices: 'Rechercher des appareils',
                updateSelected: 'Mise à jour sélectionnée',
                softwareVersions: 'Versions logicielles',
                softwareVersion: 'Version logicielle',
                notSupportedDeviceNote: "La version {{selectedVersion.version}} n'est pas prise en charge sur cet appareil",
                userNotificationContent:
                    'Une nouvelle version logicielle a été publiée pour vos appareils. Veuillez mettre à jour vos appareils pour obtenir les dernières fonctionnalités.',
                userNotificationTitle: 'Une nouvelle version logicielle publiée',
                notificationBell_newSoftwareVersions: 'Mises à jour disponibles pour vos appareils',
                manageVersions: "Gérer les versions logicielles de l'appareil",
                filterLocationsPlaceholder: 'Filtrer par lieux',
                allVersionNumbers: 'Toutes les versions',
                noVersionsForUpdating: 'Aucune version à mettre à jour',
                scheduledUpdateNote: 'La version {{version}} sera mise à jour à {{scheduled}}',
                scheduleRemoveNotificationTitle: 'Planification de la version logicielle',
                scheduleRemoveNotificationContent: 'La mise à jour programmée de la version logicielle a bien été supprimée',
                scheduleRemoveNotificationModalContent: 'Voulez-vous vraiment supprimer la mise à jour planifiée pour cet appareil ?',
            },
            header: {
                searchByOrganization: 'Rechercher par organisation',
                pageLanguage: 'Langue',
                configureMySelflyAndEreceipts: 'Configurer les paramètres MySelfly',
                remindMeLater: 'Rappelez-moi plus tard',
                updateNow: 'Mettez à jour maintenant',
            },
            notifications: {
                noNewNotifications: 'Aucune nouvelle notification',
            },
            cabinetSettings: {
                cabinetTheme: "Thème de l'armoire",
                editAndSaveTheme: 'Modifier et enregistrer le thème',
                colorPicker: 'Sélecteur de couleurs',
                customTheme: 'Thème personnalisé',
                themeSettings: 'Paramètres du thème',
                primary: 'Principal',
                negative: 'Négatif',
                attention: 'Attention',
                positive: 'Positif',
                background: 'Arrière-plan',
                dark: 'Sombre',
                light: 'Clair',
                white: 'Blanc',
                default: 'Par défaut',
                negativeLight: 'Lumière négative',
                primaryColor: 'Couleur primaire',
                positiveColors: 'Couleurs positives',
                negativeColors: 'Couleurs négatives',
                secondaryColors: 'Couleurs secondaires',
                attentionColors: "Couleurs d'attention",
                screenBackground: "Arrière-plan de l'écran",
                borderColors: 'Bords - Ombres',
                shadow: 'Ombre',
                border: 'Bord',
                fontColors: 'Couleurs de police',
                footerTextPlaceholder: 'Texte de pied de page',
                bundleDiscounts: 'Combiner et économiser',
                expiringText: "Produits en voie d'expiration. Veuillez vérifier la date d'expiration avant d'acheter",
                expiredText: 'Produits expirés. Veuillez vérifier la date d’expiration avant d’acheter',
                cabinetName: "Nom de l'armoire",
                productName: 'Nom du produit',
                confirmationModalHeader: 'Voulez-vous vraiment enregistrer le thème personnalisé ?',
                confirmationModalContent:
                    "La personnalisation du thème affectera l'aspect visuel de l'écran de votre armoire. Veuillez vous assurer que la qualité visuelle de l'écran n'est pas compromise par les modifications.",
                defaultRevertModalMessage: "Voulez-vous vraiment modifier le thème de l'armoire par défaut ?",
                backgroundColor: 'Couleur de fond',
                backgroundImage: 'Image de fond',
                footerBackground: 'Arrière-plan du pied de page',
                cardsBackground: 'Arrière-plan de la carte',
                screenImage: "Image d'arrière-plan de l'écran du cabinet",
                uploadImage: 'Télécharger une image',
                editImage: "Éditer l'image",
                transactionComplete: 'Transaction complète',
                customPostPurchaseImage: "Veuillez télécharger une image pour l'écran personnalisé après achat",
                postPurchaseScreen: 'Écran après achat',
                custom: 'personnalisé',
                postPurchaseText: "Texte d'affichage après achat",
            },
            automations: {
                transactionReports: 'Rapports de transaction',
                refillReports: 'Rapports de remplissage',
                notifications: 'Notifications',
                refillPlan: 'Plans de remplissage',
                days: 'Jours',
                months: 'Mois',
                weeks: 'Semaines',
                configuration: 'Configuration',
                transactionReportTitle:
                    'Les rapports de transaction pour les armoires sélectionnées seront automatiquement générés et envoyés aux adresses e-mail fournies ci-dessous',
                refillReportTitle:
                    'Les rapports de remplissage pour les armoires sélectionnées seront automatiquement générés et envoyés aux adresses e-mail fournies ci-dessous',
                noSalesNotificationTitle:
                    'Les notifications seront envoyées automatiquement aux adresses e-mail fournies ci-dessous en cas de non-ventes dans les intervalles définis',
                frequencyTitle: 'À quelle fréquence souhaitez-vous que le rapport soit généré ?',
                emailNotificationTitle: 'Veuillez ajouter les adresses e-mail ci-dessous où vous souhaitez que le rapport ou la notification soit envoyé',
                refillPlanGenerationTitle: 'Les plans de remplissage seront automatiquement générés pour les armoires sélectionnées selon le calendrier fourni',
                addNewEmail: 'Ajouter un nouvel e-mail',
                noSalesBanner:
                    'Vous recevrez une notification par e-mail si les armoires sélectionnées ne réalisent aucune vente pendant la période configurée',
                noSaleTitle: "Veuillez spécifier la durée (en heures) sans ventes dans les armoires sélectionnées avant qu'une notification ne soit envoyée",
                minMaxTime: 'Min est 2 et max est 23 heures',
                timeRangeTitle: 'Veuillez spécifier la plage horaire pour les armoires, pendant laquelle la situation de non-vente sera vérifiée',
                startTime: 'Heure de début',
                endTime: 'Heure de fin',
                refillPlanTitle:
                    'Le plan de remplissage pour les armoires sélectionnées sera automatiquement généré. Veuillez planifier ci-dessous le moment spécifique où vous souhaitez que le plan de remplissage soit créé.',
                refillPlanTime: "Veuillez sélectionner l'heure à laquelle le plan de remplissage doit être généré",
                everyDay: 'Chaque jour',
                everyWeek: 'Chaque semaine',
                everyMonth: 'Chaque mois',
                selectDays: 'Sélectionner des jours',
                automationSaved: "Paramètres d'automatisation enregistrés avec succès",
                automationDeleted: "Paramètres d'automatisation supprimés avec succès",
            },
            sleepScheduling: {
                timeRangeTitle: 'Veuillez spécifier la plage horaire pendant laquelle cette armoire passera en mode veille.',
                startTime: 'Heure de début',
                endTime: 'Heure de fin',
                selectDays: 'Sélectionner des jours',
                duration: 'Durée (Heures)',
            },
        },
        es: {
            dateRangeFilter: {
                lastMonth: 'últimos 30 días',
                lastTwoWeek: 'últimos 14 días',
                lastWeek: 'últimos 7 días',
                selectDateRangeMsg: 'Intervalo de fechas seleccionado',
                today: 'Hoy',
                yesterday: 'Ayer',
            },
            main: {
                activateSuccess: 'Producto activado correctamente',
                activationDate: 'Fecha de activación',
                active: 'Activo',
                addAdvertisement: 'Añadir nuevo anuncio',
                addDiscountRule: 'Añadir regla de descuento',
                addDiscountStop: 'Añadir intervalo de caducidad',
                addNewLocation: 'Elegir una nueva ubicación',
                addProduct: 'Agregar nuevo producto',
                addRegion: 'Agregar ubicación',
                addRegionPrice: 'Agregar precio para ubicación',
                addScheduledDiscount: 'Añadir periodo del descuento',
                addBundleDiscount: 'Añadir descuento combo',
                bundledDiscounts: 'Descuentos combo',
                bundleItemsLengthExceeded: 'Se ha superado la selección máxima de productos (3). Elimine alguna de sus selecciones para continuar',
                bundleMaxProductLimitInfo: 'Seleccione máx. tres (3) productos o categorías de productos',
                bundleFixedAmountExceededOriginal: 'El precio fijado para el combo supera la suma del precio original. Compruebe los productos seleccionados.',
                bundleFixedAmountNotAllowedToBeZero: 'El precio fijado para el combo debe ser mayor que 0',
                bundlePercentageNotAllowedToBeFull: 'El porcentaje de descuento para el combo debe ser menor que 100',
                bundlePercentageNotAllowedToBeZero: 'El porcentaje de descuento para el combo debe ser mayor que 0',
                bundleItemDiscountPriceAmountExceededOriginal: 'El nuevo precio con descuento supera el precio original. Compruebe de nuevo el precio.',
                bundleItemPriceMessage: 'Cada regla de precios se aplica solo a 1 artículo, incluso si se seleccionan varias unidades del mismo artículo',
                bundleItemFixedAmountExceededOriginal:
                    'El precio fijado para el producto excede el precio original del producto. Compruebe de nuevo el precio fijado.',
                createDiscountRule: 'Crear regla de descuento',
                deviceType: 'Tipo de dispositivo',
                discountUpdateTitle: 'Actualizar descuento',
                discountCreateTitle: 'Crear descuento',
                discountDeleteTitle: 'Retirar descuento',
                discountDeleteContent: 'El descuento se ha retirado',
                discountStatusUpdated: 'Estado del descuento actualizado',
                discountUpdated: 'Descuento actualizado',
                discountCreated: 'Se ha creado el descuento',
                discountSettings: 'Ajustes de descuento',
                discountImage: 'Imagen opcional del descuento',
                editDiscountStop: 'Editar intervalo de caducidad',
                editBundleConfiguration: 'Editar configuración del combo',
                editExpiryConfiguration: 'Editar la configuración del vencimiento de los descuentos',
                encodingStation: 'Estación de codificación',
                everyDay: 'Todos los días',
                scheduledDiscountDateInfoLabel: 'Deje en blanco las fechas de inicio y fin si desea que el descuento sea permanente',
                starts: 'Inicio',
                ends: 'Fin',
                error: 'Error',
                errorRetiringLocationMsg:
                    'Fallo al retirar la ubicación. Primero elimine los dispositivos vinculados, los precios de los productos y los anuncios.',
                fetchingDiscountRules: 'Obteniendo las reglas de descuento',
                free: 'Gratis',
                missingImage: 'Falta imagen del anuncio',
                next: 'Siguiente',
                back: 'Atrás',
                noDiscountRules: 'No se han encontrado reglas de descuento',
                scheduledDiscountsAutomaticDeactivationInfo:
                    'Los descuentos en los que se excede el rango de fechas se desactivan automáticamente. Si desea activarlos, edite el rango de fechas para que sean válidos',
                scheduledDiscounts: 'Descuentos programados',
                schedulingStartHourIsOverEndHour: 'La hora de inicio no puede ser posterior a la hora de finalización',
                schedulingEndHourIsUnderStartHour: 'La hora de finalización no puede ser anterior a la hora de inicio',
                selectDiscountRuleProductCategories: 'Seleccione las categorías de productos afectadas por la regla de descuento',
                selectBundleProductsAmount: 'Seleccione la cantidad para ',
                selectedBundleProductCategories: 'Categorías producto de combo seleccionado',
                setDiscountDays: 'Fijar días de descuento',
                setBundleDiscount: 'Fijar un porcentaje de descuento o un precio fijo para el combo',
                setBundleItemDiscount: 'Fijar un porcentaje de descuento o un precio fijo para un artículo',
                setBundleProducts: 'Fijar productos o categorías para el combo',
                setAmountMoreThanOne: 'En caso de combo de un solo artículo, establezca una cantidad superior a una unidad',
                setBundleDiscountType: 'Fijar el tipo de descuento y su cantidad',
                sureActivateDiscountRule: '¿Seguro que quiere activar la regla de descuento?',
                sureDeactivateDiscountRule: '¿Seguro que quiere desactivar la regla de descuento?',
                sureDeleteDiscountRule: '¿Seguro que quiere eliminar la regla de descuento?',
                timeRange: 'Rango de tiempo',
                editExpiryDiscountRule: 'Editar regla de descuento',
                editScheduledDiscountRule: 'Editar regla de descuento programado',
                editBundleDiscountRule: 'Editar regla de descuento por lotes',
                releaseNotes: 'Notas de lanzamiento',
                seeMore: 'Ver más',
                scheduledDiscountTimeRangeLabel: 'Tiempo activo',
                scheduledDiscountDateRangeLabel: 'Rango de fechas del descuento',
                weekDayAbrvMonday: 'Lu',
                weekDayAbrvTuesday: 'Ma',
                weekDayAbrvWednesday: 'Mi',
                weekDayAbrvThursday: 'Ju',
                weekDayAbrvFriday: 'Vi',
                weekDayAbrvSaturday: 'Sa',
                weekDayAbrvSunday: 'Do',
                recurrence: 'Recurrencia',
                addScheduledDiscountStop: 'Añadir descuento programado',
                editScheduledDiscountStop: 'Editar descuento programado',
                pieces: 'Unid.',
                expiryDiscountNavLabel: 'Caducidad',
                scheduledDiscountNavLabel: 'Programado',
                bundleDiscountNavLabel: 'Combo',
                amount: 'Cantidad',
                scheduling: 'Programación',
                pricing: 'Precios',
                percentageBundleDiscountInputPlaceholder: '% de descuento del combo',
                fixedPriceBundleDiscountInputPlaceholder: 'Precio fijo del combo',
                applyBundleLevelLabel: 'Aplicar a nivel combo',
                applyItemLevelLabel: 'Aplicar a nivel artículo',
                productFixedPrice: 'Precio fijo del producto',
                percentageDiscount: '% descuento',
                fixedPriceDiscount: 'Precio fijo',
                freeDiscount: 'Gratis',
                advertisementsFetchError: 'Se produjo un error al obtener anuncios. Asegúrese de que el bloqueador de anuncios esté desactivado.',
                advertisementsTitle: 'Anuncios',
                all: 'Todos',
                'All time': 'Todo el tiempo',
                allProducts: 'Todos los productos sin agrupar',
                allLocations: 'Todas las ubicaciones',
                allCabinets: 'Todos los armarios',
                antennaDown: 'Algunas de las antenas RFID no funcionan',
                antennaError: 'Error en las antenas de la nevera',
                antennaNoInfo: 'Información no disponible sobre las antenas de la nevera',
                antennaOk: 'Todas las antenas ok y funcionando',
                antennaPartial: 'Algunas de las antenas están deshabilitadas',
                archiveSuccess: 'Producto archivado con éxito',
                averageItems: 'Promedio de artículos por transacción',
                averageSales: 'Promedio de ventas por transacción',
                barcode: 'Código de barras',
                barcodeType: 'Tipo de código de barras',
                barcodeCannotBeChanged: 'El código de barras no se puede cambiar',
                barcodeTypeCannotBeChanged: 'El tipo de código de barras no se puede cambiar',
                barcodeHelpText12: 'Introducir 12 dígitos del código de barras, el último dígito se añade automáticamente',
                barcodeHelpText11: 'Introducir 11 dígitos del código de barras, el último dígito se añade automáticamente',
                barcodeHelpText7: 'Introducir 7 dígitos del código de barras, el último dígito se añade automáticamente',
                beforeExpiry: 'Antes de vencimiento',
                cabinetDown: 'La nevera está fuera de servicio',
                cabinetIsEmpty: 'La nevera está vacía',
                deviceOffline: 'El dispositivo está desconectada',
                deviceOnline: 'El dispositivo está en línea',
                cabinets: 'Neveras',
                cabinet: 'Nevera',
                cabinetSettings: 'Configuración de la nevera',
                cabinetTheming: 'Tematización de armarios',
                cancel: 'Cancelar',
                cancelledSales: 'Ventas canceladas',
                cancelledTransactions: 'Transacciones canceladas',
                categories: 'Categorías',
                change: 'Cambiar',
                clear: 'Limpiar',
                close: 'Cerrar',
                clickEditButtonRegionalPrices: 'Con el fin de editar precios por ubicación, haga clic en el botón "Editar" del producto.',
                clickEditLocationPrices: 'Precios locales, haga clic en el precio del producto para expandir.',
                clickToExpandLocationPrices: 'Precios locales, haga clic en el precio del producto para expandir.',
                closeDoor: 'Cerrar la puerta',
                rebootDevice: 'Reiniciar el dispositivo',
                commandSentSuccessFully: 'Comando enviado con éxito',
                companyLogo: 'Logo de la compañía',
                confirm: 'Confirmar',
                confirmDelete: '¿Seguro que desea eliminar el producto? ¡Esta operación no se puede deshacer!',
                confirmDisable: '¿Seguro que desea archivar el producto?',
                confirmEnable: '¿Seguro que desea activar el producto?',
                consumerResponses: 'Comentarios del usuario',
                controlPanelTitle: 'Panel de control',
                controls_acceptablePercentageOfBadTags: 'Porcentaje aceptable de etiquetas defectuosas antes de que la nevera pase al modo fuera de servicio',
                controls_markTagsGoodQuality: 'Marcar todas las etiquetas como de buena calidad',
                controls_markTagsGoodQuality_btn: 'Marcar etiquetas como de buena calidad',
                controls_clearTagHistory: 'Borrar historial de etiquetas',
                controls_clearRemoveReplenishmentData: 'Borrar datos de reposición',
                controls_companyAndPaymentLogo: 'Logo de la compañía y el pago',
                controls_detectTagQuality: 'Detectar calidad de las etiquetas',
                controls_door: 'Control de la puerta',
                controls_device: 'Control del dispositivo',
                controls_chargeBadTags: 'Cobrar etiquetas marcados como mala calidad',
                controls_refill: 'Reponer',
                controls_tagQuality: 'Configuración etiquetas',
                controls_tagQualityData: 'Datos de calidad de la etiqueta',
                controls_replenishmentData: 'Datos de reposición',
                controls_soldTags: 'Etiquetas vendidas',
                controls_tagHistory: 'Historial de etiquetas',
                controls_markAllTagsReplenished: 'Marcar todos las etiquetas como repuestos',
                controls_markAllTagsUnsold: 'Marcar todos las etiquetas como no vendidos',
                controls_markAlTagsReplenished_btn: 'Marcar etiquetas como repuestos',
                controls_markAllTagsUnsold_btn: 'Marcar etiquetas como no vendidos',
                controls_chargeSoldTags: 'Cobrar etiquetas vendidos',
                controls_chargeUnreplenishedTags: 'Cobrar etiquetas no repuestos',
                controls_tagsWarn:
                    'Al borrar el historial de etiquetas se eliminan todos los datos de las etiquetas. Por favor, haga clic en marcar todas las etiquetas reabastecidas después de esta acción.',
                create: 'Crear',
                createAdTitle: 'Nuevo anuncio',
                createdAd: 'Anuncio creado',
                createExpiryDiscountRule: 'Crear regla de descuento por caducidad',
                createScheduledDiscountRule: 'Crear regla de descuento programado',
                createBundleDiscountRule: 'Crear regla de descuento',
                createNewVatCategory: 'Nueva categoría de IVA',
                createProductTitle: 'Nuevo producto',
                creatingAd: 'Creando anuncio',
                creatingProduct: 'Creando producto',
                createNewProductCategory: 'Crear una nueva categoría de producto',
                createNewSupplier: 'Añadir nuevo proveedor',
                currency: 'Moneda',
                customQuery: 'Consulta personalizada',
                dashboard: 'Panel de control',
                dateRange: 'Rango de fechas',
                dataAlredyFetchedTitle: 'Fecha ya buscada',
                dataAlreadyFetchedDescription: 'Seleccione parámetros diferentes',
                day: 'Día',
                days: 'Días',
                deactivationDate: 'Fecha de desactivación',
                default: 'Por defecto',
                delete: 'Borrar',
                deleteError: 'Error: el producto no se puede eliminar',
                deleteSuccess: 'Producto eliminado correctamente',
                description: 'Descripción',
                deviceManagement: 'Gestión de dispositivos',
                digitwinOffline: 'El dispositivo digitwin está desconectada',
                digitwinOnline: 'El dispositivo digitwin está en línea',
                dimensions: 'Dimensiones',
                disable: 'Archivar',
                discountAmount: 'Importe descuento',
                discountPrice: 'Precio descuento',
                discountRuleAffects: 'Regla descuento afecta',
                discountRuleName: 'Nombre de la regla de descuento',
                discountRuleStops: 'Intervalos de caducidad',
                discounts: 'Descuentos',
                discountSalesData: 'Datos de ventas con descuento',
                discountSalesAmount: 'Suma de ventas con descuento',
                discountTimeBeforeExpiryExists: 'Ya existe un intervalo de caducidad anterior',
                discountVsNormalSales: 'Ventas con descuento vs ventas normales',
                dismiss: 'Descartar',
                doesNotExist: 'No existe',
                doorCloseConfirm: '¿Seguro que desea cerrar la puerta?',
                doorCloseSuccess: 'Puerta cerrada con éxito',
                doorOpenConfirm: '¿Seguro que desea abrir la puerta?',
                doorOpenReason: '¿Por qué está abriendo la puerta?',
                doorOpenSuccess: 'Puerta abierta con éxito',
                rebootDeviceSuccess: 'Dispositivo reiniciado con éxito',
                deviceRebootConfirm: '¿Seguro que desea reiniciar el dispositivo?',
                edit: 'Editar',
                editCompanyLogo: 'Editar logotipo de la compañía',
                editDiscountRule: 'Editar regla de descuento',
                editPaymentMethodLogo: 'Editar logotipo del método de pago',
                editVatCategoryModalTitle: 'Editar categoría de IVA ',
                editProductCategoryModalTitle: 'Editar categoría de producto',
                editSupplierModalTitle: 'Editar proveedor',
                enable: 'Activar',
                error_401: 'No autentificado',
                error_403: 'No autorizado para ejecutar comandos',
                error_500: 'Error inesperado del servidor',
                errorCreatingAd: 'Error al crear el anuncio',
                errorCreatingProduct: 'Error al crear el producto',
                errorFetchingCabinetStatus: 'Error al obtener estado nevera',
                errorFetchingInventory: 'Error al obtener inventario nevera',
                errorFetchingReport: 'Error al obtener informes',
                errorFetchingSettings: 'Error al obtener configuración',
                errorUpdatingProduct: 'Error al actualizar el producto',
                expandToSeeBadTags:
                    'Ampliar la lista para ver las etiquetas que fueron detectadas como dañadas. Las etiquetas dañadas deben retirarse de la nevera.',
                expandToSeeSoldItems: 'Amplíe la lista para ver los artículos que ya están vendidos pero han vuelto a aparecer en el inventario.',
                expandToSeeReplenishedOutOfInventory:
                    'Amplíe la lista para ver productos que se han introducido en la nevera en un proceso distinto a la reposición por parte del operario.',
                expired: 'Caducado',
                expiredCount: 'Recuento de caducados',
                expiredProductsCount: 'Número de productos caducados',
                expiredSum: 'Suma caducados',
                expiring: 'Caducando',
                expiryDate: 'Último día de venta',
                exportExcel: 'Exportar a Excel',
                fetchAdvertisements: 'Obteniendo anuncios',
                fetchingCabinets: 'Obteniendo neveras',
                fetchingCabinetsError: 'Se produjo un error al obtener neveras',
                fetchingCabinetStatus: 'Obteniendo estado de neveras',
                fetchingError: 'Se produjo un error al obtener ',
                fetchingInventory: 'Obteniendo inventario de nevera',
                fetchingProducts: 'Obteniendo productos',
                fetchingReport: 'Obteniendo informes',
                fetchingVatCategories: 'Obteniendo categorías de IVA',
                fetchingProductCategories: 'Obtener categorías de productos',
                fillForm: 'Rellenar el formulario',
                freshFood: 'Este es un producto que caduca',
                freezerPackage: 'Producto congelado',
                generalFetchError: 'Error al recuperar',
                generalRequestReject: 'Solicitud de error',
                get: 'Obtener',
                group: 'Grupo',
                holdOn: 'Esperar',
                hour: 'Hora',
                hours: 'horas',
                hoursBeforeExpiry: 'Horas antes de caducar',
                daysBeforeExpiry: 'Días antes de la caducidad',
                image: 'Imagen',
                imageOrVideo: 'Seleccione una imagen o un video',
                inActive: 'Inactivo',
                individualCabinets: 'Neveras individuales',
                individualCabinetsOnly: 'Solo neveras individuales',
                invalidBarcodeOnlyDigits: 'El código de barras solo debe contener dígitos',
                invalidBarcodeTooLong: 'El código de barras es demasiado largo',
                invalidBarcodeTooShort: 'El código de barras es demasiado corto',
                invalidCabinetNameTooShort: 'El nombre de la nevera es demasiado corto',
                invalidCabinetNameDuplicated: 'El nombre de la nevera ya existe',
                invalidLocationNameTooShort: 'El nombre de la ubicación es demasiado corto',
                invalidLocationNameDuplicated: 'El nombre de la ubicación ya existe',
                invalidPercentage: 'Valor no valido',
                invalidPrice: 'Precio incorrecto',
                invalidProductBarcode: 'El código de barras del producto no es válido',
                invalidTitle: 'El título no es válido',
                invalidBundleItems: 'El combo contiene elementos no válidos',
                inventoryLength: 'Duración de inventario',
                inventoryNotReported: 'La nevera no ha enviado su inventario',
                inventoryTitle: 'Inventario de la nevera',
                'Inventory History': 'Historial del inventario',
                itemReplenishedOutOfInventoryProcess: 'Este producto o etiqueta ha sido colocado en la nevera en un proceso distinto al de reposición',
                itemSoldOn: 'Artículo vendido en',
                'Last 30 days': 'Los últimos 30 días',
                'Last 7 days': 'Los últimos 7 días',
                'Last month': 'El mes pasado',
                'Last quarter': 'El último trimestre',
                'Last week': 'La semana pasada',
                'Last year': 'El año pasado',
                lastUpdate: 'Última actualización',
                lifetime: 'Duración',
                location: 'Ubicación',
                locations: 'Ubicaciones',
                logoEditor: 'Editor de logo',
                logoSettings: 'Ajustes del logo',
                loading: 'Cargando...',
                measure: 'Medida',
                measures: 'Medidas',
                minsBeforeExpiry: 'Minutos antes de caducar',
                minutes: 'minutos',
                month: 'Mes',
                name: 'Nombre',
                newVatCategoryModalTitle: 'Nueva Categoría de IVA',
                newProductCategoryModalTitle: 'Nueva categoría de producto',
                newSupplierModalTitle: 'Nuevo proveedor',
                'no region': '(Sin ubicación)',
                noAdvertisements: 'Sin publicidad',
                noCabinets: 'No hay neveras',
                noCabinetSelected: 'No hay ninguna nevera seleccionada',
                noLogo: 'Sin logo',
                noData: 'Sin datos',
                noDescription: 'Sin descripción',
                noEvents: '¡No hay eventos!',
                noIndividualCabinets: 'No hay neveras individuales',
                noLocationPrices: 'No hay precios locales definidos para este producto',
                none: 'Ninguno',
                noPaymentMethodLogo: 'Ningún logotipo de método de pago',
                noProducts: 'Sin productos',
                noProductsRemoved: 'Sin productos retirados',
                noProductTypeSelected: 'Sin tipo de paquete seleccionado',
                normalPrice: 'Precio normal',
                normalSalesAmount: 'Total ventas normales',
                notAccessibleForYou:
                    'La función crear/actualizar solo está habilitada para administradores y operadores. Para tener acceso, póngase en contacto con el administrador del sistema.',
                notDefined: 'No definido',
                noTitle: 'Sin título',
                noVatCategories: 'Sin registro sobre categoría de IVA',
                noProductCategories: 'No se encontraron registros de categorías de productos',
                noInventoryOnSelectedDate: 'No se informó de ningún inventario para la fecha seleccionada. Pruebe con una fecha diferente.',
                openDoor: 'Abra la puerta',
                orderSum: 'Total compra',
                paymentMethodLogo: 'Logo método de pago',
                percentage: 'Porcentaje',
                permanent: 'Permanente',
                preview: 'Vista previa',
                price: 'Precio',
                print: 'Imprimir',
                productCreated: 'Producto creado',
                products: 'Productos',
                productsFetchError: 'Se produjo un error al obtener productos',
                productsTitle: 'Catálogo de productos',
                productType: 'Tipo de paquete',
                productUpdated: 'Producto actualizado',
                quantity: 'Cantidad',
                readerDown: 'Lector RFID no accesible',
                region: 'Ubicación',
                regions: 'Ubicaciones',
                removedAt: 'Retirado en',
                removeImage: 'Retirar imagen',
                removeVideo: 'Retirar vídeo',
                refillQRCodeInfo: 'Escanear el código QR para abrir app de reposición o seguir',
                refillQRCodeTitle: 'Código QR reposición',
                refillRuleTitle: 'Regla de reposición',
                reportsTitle: 'Informes',
                requestSuccess: 'Cambios realizados con éxito',
                requiredPercentage: 'Obligatorio. Valor porcentual sin signo de porcentaje.',
                requiredText: 'Longitud requerida de caracteres: ',
                retireLocation: 'Retirar ubicación',
                rfidNoInfo: 'No hay información disponible sobre lector y antenas RFID',
                rfidOk: 'El lector y las antenas RFID funcionan correctamente.',
                sales: 'Ventas',
                salesCount: 'Cantidad Ventas',
                salesVsWasteAmount: 'Importe Ventas vs desperdicio',
                save: 'Guardar',
                search: 'Buscar',
                selectCabinet: 'Seleccionar una nevera',
                selectDiscountRuleCabinets: 'Seleccione las neveras afectadas por la regla de descuento',
                selectDiscountRuleLocations: 'Seleccione las ubicaciones afectadas por la regla de descuento',
                selectDiscountRuleProducts: 'Seleccione los productos afectados por la regla de descuento',
                selectProductType: 'Por favor, seleccione el tipo de empaque para su producto',
                setDiscountAmount: 'Introduzca descuento',
                setDiscountTimeBeforeExpiry: 'Fijar intervalo de caducidad',
                settingConfirmMessage: '¿Seguro que desea guardar esta configuración? Si no lo está, no confirme',
                showDisable: 'Productos archivados',
                summary: 'Resumen',
                sureRemoveDiscountRule: '¿Seguro que desea eliminar la regla de descuento?',
                tagIsBad: 'Etiqueta dañada, es necesario retirarla de la nevera',
                tagsRemovedReport: 'Informe de etiquetas retiradas',
                taxValue: 'Valor impuestos',
                'This month': 'Este mes',
                'This quarter': 'Este trimestre',
                'This week': 'Esta semana',
                'This year': 'Este año',
                thisLink: 'este enlace.',
                timeGrouping: 'Tiempo agrupación',
                title: 'Título',
                Today: 'Hoy',
                topExpiredProducts: 'Productos Top caducados',
                topProducts: 'Productos Top',
                total: 'Total',
                totalValue: 'Valor total',
                transactions: 'Transacciones',
                ttlInDays: 'Vida útil de producto (duración) en días',
                ttlsAffected: 'Productos con fecha limite (duración) afectados',
                unexpectedError: 'Error inesperado',
                uniqueCustomers: 'Clientes únicos',
                unknown: 'Desconocido',
                update: 'Actualizar',
                updateAdError: 'Error al actualizar el anuncio',
                updateAdTitle: 'Actualizar anuncio',
                updatedAd: 'Anuncio actualizado',
                updateCabinetSettings: 'Sincronizar datos con el armario',
                updateCabinetSettingsInfo: '¿Seguro que desea actualizar los ajustes del armario?',
                updateData: 'Actualizar datos',
                updateProductTitle: 'Actualizar producto',
                updatingAd: 'Actualizando anuncio',
                updatingProduct: 'Actualizando producto',
                uploading: 'Cargando',
                uploadingImage: 'Cargando imagen',
                use: 'Usar',
                'w/o grouping': 'Sin agrupación',
                waste: 'Desperdicio',
                vatCategoriesFetchError: 'Error obteniendo categorías de IVA',
                vatCategoriesTitle: 'Categorías de IVA',
                vatCategory: 'Categoría de IVA',
                vatCategoryItems: 'Productos',
                vatCategoryName: 'Nombre de categoría de IVA',
                vatCategoryPromptMessage: '¿Seguro que desea eliminar la categoría de IVA?',
                vatCategoryValue: 'Porcentaje de impuesto',
                vatDeleteRejected: 'Eliminación de categoría de IVA rechazada. Compruebe los productos asociados.',
                vatErrorRequesting: 'Error en solicitud de proceso',
                vatRequestRejected: 'Solicitud rechazada',
                vats: 'IVAs',
                productCategoriesFetchError: 'Error al obtener categorías de productos',
                productCategoriesTitle: 'Categorías de producto',
                productCategory: 'Categoría de producto',
                productCategoryItems: 'Categorías',
                productCategoryName: 'Nombre de la categoría del producto',
                productCategoryPromptMessage: '¿Está seguro de que desea eliminar la categoría de producto?',
                productCategoryDescription: 'Descripción de la categoría del producto',
                productCategoryHelpTextDescription: 'Por ejemplo, categoría para ensaladas',
                productCategoryHelpTextName: 'Por ejemplo, ensaladas',
                productCategoryDeleteRejected: 'Se rechazó la eliminación de la categoría de producto. Consulte los productos relacionados.',
                week: 'Semana',
                viewMode: 'Vista',
                year: 'Año',
                Yesterday: 'Ayer',
                groupBy: 'Agrupar por',
                filterBy: 'Filtrar por',
                reportType: 'Tipo de informe',
                custom: 'Personalizado',
                preDefined: 'Predefinido',
                selected: 'Seleccionado',
                metricsInfo: 'Las métricas (suma, máximo, mínimo...) se calculan para todas las dimensiones/recursos seleccionados',
                dimensionsInfo: 'Se obtendrán métricas de las dimensiones/recursos seleccionados',
                metrics: 'Métricas',
                controlsSoftwareVersion: 'Versión del software',
                currentSoftwareVersion: 'Versión actual del software',
                availableSoftwareVersion: 'Versión del software disponible',
                invalidLifetime: 'El valor de la vida útil no es válido',
                unpaidSales: 'Ventas no cobradas',
                unpaidTransactions: 'Transacciones no cobradas',
                softwareUpdateConfirmation: '¿Seguro que desea actualizar la versión del software del/de los dispositivo(s) seleccionado(s)?',
                softwareUpdateWarn:
                    'Al actualizar la versión del software se enviará un comando al/a los dispositivo(s) para obtener la actualización al instante o a la hora seleccionada. La actualización se instalará en el/los dispositivo(s) seleccionado(s) la próxima vez que esté(n) en línea tras la activación. La instalación puede llevar un cierto tiempo, dependiendo de la velocidad de conexión. El/Los dispositivo(s) se reiniciará(n) tras la instalación.',
                releaseVersion: 'Versiones lanzadas',
                releaseDate: 'Fecha lanzamiento',
                latestRelease: 'Último lanzamiento',
                currentVersion: 'Versión actual',
                softwareUpdateSuccess: 'Se ha actualizado la versión del software',
                prebooking: 'Pre-reserva',
                prebookedProduct: 'Producto pre-reservado',
                prebookedProducts: 'Productos pre-reservados',
                activeProducts: 'Productos Activos',
                startMaintenanceMode: 'Iniciar el modo de mantenimiento de la nevera',
                endMaintenanceMode: 'Finalizar el modo de mantenimiento de la nevera',
                startMaintenanceConfirm: '¿Está seguro que desea iniciar el modo de mantenimiento de la nevera?',
                endMaintenanceConfirm: '¿Está seguro que desea finalizar el modo de mantenimiento de la nevera?',
                startMaintenanceSuccess: 'Modo de mantenimiento de la nevera iniciado',
                endMaintenanceSuccess: 'Modo de mantenimiento de la nevera finalizado',
                noResults: 'Sin resultados',
                suppliers: 'Proveedores',
                supplier: 'Proveedor',
                supplierDeleteRejected: 'Eliminando proveedor rechazado. Compruebe los productos asociados.',
                supplierPromptMessage: '¿Seguro que desea eliminar el proveedor?',
                fetchingSuppliers: 'Obteniendo proveedores',
                noSuppliers: 'No se han encontrado registros de proveedores',
                suppliersFetchError: 'Error en la obtención de proveedores',
                supplierName: 'Nombre del proveedor',
                supplierHelpTextName: 'P. ej. un nombre de marca',
                supplierDescription: 'Descripción del proveedor',
                supplierHelpTextDescription: 'P. ej. una descripción de la marca',
                wishes: 'Peticiones ',
                checkNow: 'Comprobar ahora',
                startTimeOn: 'Hora de inicio en',
                endTimeOn: 'Hora de finalización en',
                signOutConfirmationModalTitle: 'Confirmación de cierre de sesión',
                signOutConfirmationModalContent: '¿Seguro que desea cerrar la sesión?',
                startDate: 'Fecha de inicio',
                endDate: 'Fecha de finalización',
                scheduledDiscountApplyAllDays: 'Aplicación todos los días',
                scheduledDiscountApplySelectedDays: 'Aplicación en días seleccionados',
                discountCustomImageTitle: 'Imagen de descuento (opcional)',
                discountCustomImageModalHeader: 'Imagen para descuento',
                saveChanges: 'Guardar cambios',
                updateTagsQualitySettingsTitle: 'Ajustes de calidad de las etiquetas',
                updateTagsQualitySettingsMessage: 'Ajustes de calidad de las etiquetas actualizados',
                IF3Cabinets: 'Frigoríficos modelo 3',
                IF2Cabinets: 'Frigoríficos modelo 2',
                IF3Freezers: 'Congeladores modelo 3',
                encodingStations: 'Estaciones de codificación',
                if2: 'Frigorífico modelo 2',
                if3: 'Frigorífico modelo 3',
                if3Freezer: 'Congelador modelo 3',
                legalNotice: 'Aviso legal',
                privacyPolicy: 'Política de privacidad',
                scheduledDiscountDateMissingError: 'Ambas fechas son necesarias si se fija la fecha de inicio o de finalización',
                scheduledDiscountDuplicateTimeError: 'Las horas no pueden coincidir si las fechas coinciden',
                now: 'Ahora',
                minimum: 'Mínimo',
                maximum: 'Máximo',
                discardChangesConfirmationText: '¿Seguro que desea desechar los cambios?',
                exports: 'Exportaciones',
                selectAllCabinets: 'Seleccionar todos los armarios',
                expiryIntervals: 'Intervalos de caducidad',
                timeBeforeExpiry: 'Tiempo antes del vencimiento',
                apply: 'Aplicar',
                yes: 'Sí',
                no: 'No',
                productOnScreenBannerText: 'Texto del banner informativo en pantalla',
                productOnScreenBannerAdditionalInfo:
                    'El texto del banner es un texto de una línea que aparece debajo de la imagen del producto y transmite de forma concisa las características clave, los beneficios para la salud o cualquier información adicional sobre el producto.',
                productOnScreenBannerExampleText: 'Sin lactosa',
                UI_IS_DOWN: 'La pantalla del gabinete no está funcionando',
                READER_IS_DOWN: 'El lector RFID está fuera de servicio',
                ANTENOR_DEVICE_DISCONNECTED: 'El dispositivo de pago está desconectado',
                EXPIRED_PRODUCT: 'Productos vencidos en el gabinete',
                CLOUD_SERVICE_DOWN: 'Servicio en la nube no disponible',
                ANTENNA_DISCONNECTED: 'Antena desconectada',
                TAG_CHANGES: 'Demasiadas etiquetas incorrectas en el gabinete',
                ANTENNA_DOWN: 'La antena RFID en el gabinete no está funcionando',
                READER_CONFIG_ERROR: 'Fallo en la configuración del lector RFID y no está funcionando',
                activeAlarms: 'Alarmas activas',
                deviceStatus: 'Estado del dispositivo',
                lastMonitored: 'Última supervisión',
                noService: 'Fuera de servicio',
                operational: 'Operativo',
                offline: 'Offline',
                locationsSelectHelp: 'Cuando se agrega una ubicación, también se agregarán sus ubicaciones secundarias.',
                select: 'Seleccionar...',
                labels: 'Etiquetas',
                productDetails: 'Detalles del producto',
                invalidProductName: 'El nombre del producto debe tener entre 1 y 125 caracteres',
                standardPrice: 'Precio estándar',
                locationPrices: 'Precios según ubicación',
                addNew: 'Agregar nuevo',
                productExpiry: 'Vencimiento del producto',
                productLifeTime: 'Vida útil del producto',
                invalidTTL: 'La vida útil del producto debe ser un número entero de 0 a 32767',
                productImage: 'Imagen del producto',
                uploadImage: 'Subir imagen',
                pleaseUploadImage: 'Por favor, sube una imagen',
                productValidationError: 'Por favor, corrige los siguientes errores antes de guardar los datos del producto',
                productSupplier: 'Proveedor del producto',
                frozenProductLable: 'Este es un producto congelado',
                prebookingLabel: 'Este es un producto de reserva previa',
                previous: 'Anterior',
                bannerText: 'Texto del banner',
                packagingTypeValidationError: 'Por favor, selecciona un tipo de paquete para el producto',
                showOnlyFrozen: 'Mostrar solo productos congelados',
                productClassification: 'Clasificación del producto',
                productClassLabel:
                    'Seleccione la clase apropiada para el producto. Esto permite a Selfly Insights proporcionar información más relevante sobre el producto.',
                productLabels: 'Etiquetas del producto',
                productLabelsHelpText:
                    'Por favor, añada etiquetas opcionales a su producto. Esto le permitirá filtrar sus productos en el catálogo de productos.',
                newLabel: 'Nueva etiqueta',
                productClassValidationError: 'Seleccione una clasificación para su producto de las opciones a continuación',
                tagSensitivity: 'Sensibilidad de calidad de la etiqueta',
                tagSensitivitySetting: 'Configuración de sensibilidad de calidad de la etiqueta',
                configTagSensitivity: 'Configurar la sensibilidad de la etiqueta para el producto',
                productLevelSensitivityInfo:
                    'La legibilidad de una etiqueta RFID adherida a un producto puede verse influenciada por varios factores, como materiales de embalaje, superficies metálicas, humedad, e incluso el propio contenido del producto. Estos factores pueden a veces causar señales intermitentes en la etiqueta, conocidas como parpadeo. El gabinete procesa dicho parpadeo en función de su configuración de sensibilidad. Si no está seguro de qué nivel de sensibilidad es adecuado para este producto, puede omitir esta sección.',
                errorSaving: 'Error al guardar los datos del producto',
                successSaving: 'Datos del producto guardados con éxito',
                basicDetail: 'Detalles básicos',
                additionalDetail: 'Detalles adicionales',
                packaging: 'Tipo de paquete',
                classification: 'Clasificación',
                selectOrtype: 'Selecciona o escribe...',
                max25Chars: 'máximo 25 caracteres',
                noVatForProductCreation: 'Por favor, crea una categoría de IVA antes de agregar nuevos productos',
                lifeTimeExplanation: 'Cuántos días es válido este producto para la venta. (0 días) significa hasta el final del día actual',
                selflyEncode: 'Selfly Encode',
                userRestrict: 'Solo consumidores reconocidos',
                userRestrictTitle: 'Consumidores elegibles',
                userNotRestrict: 'Todos los consumidores',
                userRestrictHelperText:
                    'La opción de consumidores reconocidos significa que el descuento solo se aplicará a los consumidores que ya están registrados para la elegibilidad del descuento!',
                searchCabinets: 'Buscar armarios',
                selectAll: 'Seleccionar todo',
                monday: 'Lunes',
                tuesday: 'Martes',
                wednesday: 'Miércoles',
                thursday: 'Jueves',
                friday: 'Viernes',
                saturday: 'Sábado',
                sunday: 'Domingo',
                fetchingLoader: 'Cargando. Por favor espera...',
                category: 'Categoría',
                okay: 'Ok',
                noLocation: 'Sin ubicación',
                copy: 'Copiar',
                pleaseConfirm: 'Por favor confirma',
                saveQuery: 'Guardar esta consulta',
                saveQueryPlaceholder: 'p.ej. "Cantidad total de artículos vendidos"',
                saveQuerySuccess: 'Consulta guardada.',
                yourSavedQueries: 'Tus consultas guardadas',
                selectQuery: 'Seleccionar consulta',
                noQuery: 'Aún no hay consultas guardadas',
                deleteAdConfirm: '¿Estás seguro de que quieres eliminar este anuncio?',
                dataSaved: 'Datos guardados con éxito',
            },
            refill: {
                refillRuleApplyRuleDropdownText: 'Aplicar esta regla también a otros armarios',
                refillPlanGenerate: 'Generar plan de reposición',
                refillPlanEditPlan: 'Editar plan',
                refillAmount: 'Cantidad de reposición',
                refillRuleTargetAmount: 'Nivel de inventario objetivo',
                refillRuleTargetAmountShort: 'Objetivo',
                refillPlanActivePlans: 'Planes activos',
                refillPlanCompletedPlans: 'Planes finalizados',
                refillNote: 'Nota de reabastecimiento',
                refillNoteHelp: 'Nota de reabastecimiento del producto (máximo 125 caracteres)',
                refillPDFText:
                    'A continuación se muestra el plan de reabastecimiento para el armario. Asegúrese de marcar el plan de reabastecimiento como completo en la aplicación de reabastecimiento después de haber terminado el reabastecimiento.',
                refillPDFSummaryText:
                    'A continuación se muestra el resumen de los planes de reabastecimiento con los productos a añadir y eliminar para todos los armarios seleccionados.',
                refillSummary: 'Resumen de reabastecimiento',
                addItems: 'Agregar productos',
                removeItems: 'Eliminar productos',
                inventoryChange: 'Cambio de inventario',
                planogramPDFText: 'Planograma para la estrategia de reposición del gabinete.',
                planogramImagePDFText: 'Imagen personalizada para la estrategia de reposición del gabinete.',
                refillSchemas: 'Plantillas de reposición',
                refillPlans: 'Planes de reposición',
                refillSchemaTitle:
                    'Establezca las cantidades objetivo y mínimas de inventario como plantilla para los gabinetes seleccionados. Estas plantillas se utilizarán para generar los planes de reposición.',
                refillPlansTitle:
                    'Ver todos los planes activos. Puede editar o eliminar los planes activos existentes o agregar nuevos planes de reposición según sea necesario.',
                refillSchemaSubText: 'Por favor, seleccione un gabinete para crear o actualizar la plantilla de reposición.',
                noRefillSchema: 'Aún no se ha definido una plantilla de reposición para este gabinete.',
                createRefillSchema: 'Crear una nueva plantilla de reposición',
                noDevice: 'No se encontró ningún dispositivo',
                schemaSaved: 'Plantilla de reposición guardada con éxito',
                deleteSchemaConfirmation: '¿Está seguro de que desea eliminar la plantilla de reposición?',
                targetAmount: 'Cantidad objetivo',
                targetAmountHelpText: 'La cantidad objetivo de inventario para cada producto en el gabinete después de la reposición.',
                minimumThreshold: 'Umbral mínimo',
                minimumThresholdHelpText:
                    'Especifique el nivel mínimo de inventario para cada producto en el gabinete. Si la cantidad cae por debajo del umbral definido, recibirá una notificación de alarma. Establecer el umbral en 0 desactivará las notificaciones.',
                editNote: 'Editar nota',
                refillNoteTitle: 'Escriba una nota de reposición para el producto.',
                refillNoteCharacters: 'caracteres',
                noRefillPlan:
                    'Actualmente no hay planes de reposición activos. Agregue nuevos planes de reposición para sus gabinetes o cree primero una plantilla de reposición para los gabinetes.',
                planSaved: 'Plan de reposición guardado con éxito',
                deleteConfirmation: '¿Está seguro de que desea eliminar los datos seleccionados del plan de reposición?',
                editPlan: 'Editar plan',
                currentInventory: 'Inventario actual',
                lastSevenDaySales: 'Ventas de los últimos siete días',
                inventory: 'Inventario',
                planogram: 'Planograma',
                copyRefillSchema: 'Copiar plantilla a otros gabinetes',
                generatingPlans: 'Generando planes de reposición. Espere, por favor...',
                generatePlans: 'Generar planes de reposición',
                complete: 'completo',
                selectCabinetsToAddPlans: 'Seleccione gabinetes para generar planes de reposición.',
                reviewSuggestion:
                    'A continuación se muestran los planes de reposición sugeridos para los gabinetes seleccionados. Revísalos, edítalos si es necesario, y guárdalos para activar estos planes.',
                noSchema:
                    'Actualmente no hay ningún gabinete con una plantilla de reposición. Agregue una plantilla de reposición a sus gabinetes para generar planes de reposición.',
                searchProducts: 'Buscar productos',
                maxAllowedInventory: 'El inventario máximo permitido es 200.',
                savingData: 'Guardando datos. Espere, por favor...',
                errorSavingData: 'Error al guardar los datos.',
                refillPlanSaved: 'Planes de reposición generados con éxito.',
                exportPDFHelp: 'Exportar los planes seleccionados a un archivo PDF.',
                exportExcelHelp: 'Exportar los planes seleccionados a un archivo Excel.',
                addPlanHelp: 'Agregar nuevos planes de reposición.',
                deletePlanHelp: 'Eliminar los planes de reposición seleccionados.',
                generatePlansHelp: 'Generar planes de reposición para los gabinetes seleccionados.',
                planogramSaved: 'Planograma guardado con éxito.',
                schemaCopied: 'La plantilla de reposición se sincronizó correctamente con los otros gabinetes.',
                allProducts: 'Todos los productos',
                schemaProducts: 'Productos de la plantilla de reposición',
                deletePlanogram: '¿Está seguro de que desea eliminar el planograma?',
                dragInPlanogram:
                    'Arrastre y suelte los productos en los estantes designados del gabinete o arrastre el espacio del gabinete sobre el producto.',
                noPlanogramInSchema: 'Aún no se ha definido un planograma para este gabinete.',
                noPlanogramInPlan:
                    'No hay datos de planograma disponibles para el gabinete. Primero cree un planograma para el gabinete en la pestaña de plantilla de reposición.',
                copyPlanogram: 'Copiar planograma de reposición junto con la plantilla de reposición.',
                planogramImageInfo: 'Además del planograma, también puede agregar una imagen de planograma personalizada.',
                addPlanogramImage: 'Agregar imagen de planograma',
                editPlanogramImage: 'Editar imagen de planograma',
                numberOfShelves: 'Número de estantes',
                addNewRefillPlan: 'Agregar nuevos planes',
                qrcodeTitle: 'Se generará un código QR para el gabinete seleccionado para iniciar el proceso de reposición.',
                qrcodeSubText: 'Seleccione un gabinete donde le gustaría iniciar el proceso de reposición.',
                qrcodeScan: 'Por favor escanee el código QR para reponer, o haga clic en el botón para abrir la aplicación de reposición.',
                qrcodeOpenApplication: 'Abrir aplicación de reposición',
                createRefillPlanogram: 'Crear planograma de reposición',
            },
            navigation: {
                advertisements: 'Anuncios',
                analytics: 'Análisis',
                controlPanel: 'Panel de control',
                deviceManagement: 'Gestión de dispositivos',
                discounts: 'Descuentos',
                fetching: 'Obteniendo',
                group: 'Grupo',
                inventory: 'Inventario de nevera',
                language: 'Idioma',
                productCatalog: 'Catálogo de productos',
                productCategories: 'Categorías de producto',
                reports: 'Informes',
                settings: 'Ajustes de usuario',
                signOut: 'Desconectar',
                title: 'Portal Selfly en la nube',
                vatCategories: 'Categorías de IVA',
                community: 'Selfly Community',
                refillManagement: 'Gestión de recargas',
                automations: 'Automatizaciones',
                mySelfly: 'Configuraciones de MySelfly',
                automationInfo: 'Gestionar acciones automatizadas (BETA)',
            },
            reports: {
                alreadyRefund: 'fue reembolsado',
                amount: 'Cantidad',
                average: 'Promedio',
                barcode: 'Código de barras',
                confirmIssueRefundButton: 'Confirmar reembolso ahora',
                current: 'Actual',
                export: 'Exportar',
                fetching: 'Obteniendo',
                fetchingError: 'Se produjo un error al obtener',
                image: 'Imagen',
                issueRefundButton: 'REEMBOLSO',
                maximum: 'Máximo',
                minimum: 'Mínimo',
                name: 'Nombre',
                noData: 'Sin datos',
                note: 'Nota',
                order: 'Transacción',
                paymentWasDoneByCreditCard: 'El pago se realizó mediante tarjeta de crédito',
                price: 'Precio',
                ProductReport: 'Informe de producto',
                quantity: 'Cantidad',
                "Refund impossible'":
                    'El pago se realizó mediante tarjeta de crédito. Contacte con la entidad emisora de la tarjeta de crédito para gestionar la operación de reembolso.',
                refundFormTitle: 'Reembolso',
                refundReason: 'Motivo de reembolso',
                refundSuccess: 'Total {{sum}} fue reembolsado con éxito',
                refundTotal: 'Total reembolso',
                replenishments: 'Reposiciones',
                replenishmentsTitle: 'Informe de reposición',
                SaleTransaction: 'Transacción de ventas',
                summary: 'Resumen',
                SummaryReport: 'Resumen de ventas',
                temperature: 'Temperatura',
                temperatureTitle: 'Informe de temperatura',
                temperatureValueNotification: 'Las temperaturas son los valores promedio por hora de la temperatura de la nevera.',
                time: 'Hora',
                total: 'Total',
                transactions: 'Transacciones',
                user: 'Usuario',
                paidTransactions: 'Transacciones pagadas',
                unpaidTransactions: 'Transacciones no pagadas',
                partiallyPaid: 'Parcialmente pagadas',
                failedTransactions: 'Transacciones fallidas',
                amountOwed: 'Cantidad adeudada',
                receiptLinkText: 'Obtenga aquí su recibo',
                noPurchase: 'Ninguna compra',
                other: 'Otra',
                userId: 'ID de usuario:',
            },
            settings: {
                block_cabinet_when_product_expired: 'Bloquear nevera por productos caducados',
                criticalExpiryLimitHours: 'Horas antes de alerta crítica por caducidad',
                language: 'Idioma',
                logo: 'Logo compañía',
                mediumExpiryLimitHours: 'Horas antes de alerta media por caducidad',
                payment_method_logo: 'Logo método de pago',
                footer_instruction_logo: 'Imagen de instrucciones a pie de página',
                readUserMemory: 'Obtener datos sobre la caducidad del producto',
                ui_skus_sort_by: 'Orden de productos en pantalla de armario',
                timeZone: 'Huso horario',
                country: 'País',
                city: 'Ciudad',
                tagSettings: 'Configuración de etiquetas',
                themeAndLogos: 'Tema y logotipos',
                advancedSettings: 'Ajustes avanzados',
                themingInfoText: 'Puede personalizar el tema de la pantalla de su armario',
                cabinetLogos: 'Logotipos de los armarios',
                cabinetLogoInfoText: 'Cargar logotipos que aparecen en la pantalla del armario',
                logoHelperText: 'Utilice una imagen de 180 X 180 píxeles',
                settingsCopyInfoHeader: 'Seleccione los armarios en los que desee copiar los ajustes',
                settingsCopyButton: 'Copiar los ajustes en los armarios seleccionados',
                settingsCopySuccessModalTitle: 'Copia de ajustes',
                settingsCopySuccessModalContent: 'Ajustes copiados con éxito en {{cabinetsAmount}} armario(s)',
                theming: 'Tematización',
                adminSettings: 'Configuración de administrador',
                settingsCopyErrorModalTitle: 'Error al copiar configuraciones',
                key: 'Configuración',
                value: 'Valor',
                sleepScheduling: 'Modo de espera programado',
                sensitivityLevel: 'Nivel de sensibilidad',
                sensitivity_0:
                    'El gabinete ignora el parpadeo de la etiqueta y marca de manera consistente todas las etiquetas como válidas, lo que podría ocasionar cargos incorrectos de vez en cuando.',
                sensitivity_20: 'El gabinete tolera hasta tres lecturas incorrectas de una etiqueta en 50 escaneos y aún así marca la etiqueta como válida.',
                sensitivity_40: 'El gabinete tolera hasta dos lecturas incorrectas de una etiqueta en 60 escaneos y aún así marca la etiqueta como válida.',
                sensitivity_60:
                    'Si una etiqueta parpadea con frecuencia pero se mantiene estable durante un período prolongado, el gabinete tolera esto y lee la etiqueta como válida.',
                sensitivity_80:
                    'Si una etiqueta parpadea momentáneamente pero permanece estable durante un período prolongado, se considerará una etiqueta válida.',
                sensitivity_100:
                    'El gabinete es muy intolerante al parpadeo de etiquetas. Incluso una sola lectura incorrecta puede hacer que una etiqueta sea marcada como inválida durante un período prolongado, lo que puede ocasionar pérdidas potenciales.',
                sensitivityInfo:
                    'La sensibilidad de calidad de la etiqueta de un gabinete se refiere a la efectividad del gabinete al manejar lecturas inconsistentes de etiquetas o el parpadeo de las mismas. Tenga en cuenta el nivel de sensibilidad elegido, ya que puede afectar el rendimiento del gabinete. Si no está seguro de cuál es el nivel adecuado, se recomienda dejar la configuración en el nivel predeterminado de 3.',
            },
            signIn: {
                signInButton: 'Iniciar sesión',
                signInExpiredError: 'Su sesión ha terminado. Acceder de nuevo',
                signInMessage: 'Debe iniciar sesión para continuar. Haga clic en botón de abajo para iniciar sesión',
                noOrganizationsError: 'No hay organizaciones vinculadas a este nombre de usuario. Pruebe con otro nombre de usuario.',
            },
            translations: {
                add: 'Agregar',
                closed: 'Cerrado',
                delete: 'Borrar',
                device: 'Dispositivo',
                edit: 'Editar',
                off: 'OFF',
                offline: 'Sin conexión',
                on: 'ON',
                online: 'En línea',
                opened: 'Abierto',
                save: 'Guardar',
                seeDetails: 'Detalles',
                settings: 'Ajustes',
                unknown: 'Desconocido',
            },
            consumerApp: {
                enableConsumerFeedback: 'Habilitar los comentarios del usuario en MySelfly',
                enablePurchaseReceipts: 'Habilitar los recibos electrónicos en MySelfly',
                enableSelflyTrademarkForReceipts: 'Habilitar pie de Selfy Store en los recibos electrónicos',
                enableSelflyTrademarkForReceiptsHelpText: 'Al hacerlo se incorporarán el logotipo y los datos de Selfy Store a PDF del recibo electrónico.',
                organizationFormHelpText: 'Indique los datos del emisor del recibo electrónico.',
                organizationFormValidationHelpText:
                    '* Los campos obligatorios del recibo electrónico son el nombre de la empresa emisora, el ID de la empresa, la dirección y el país.',
                organizationName: 'Nombre de la empresa',
                organizationLogo: 'Logotipo de la empresa',
                businessId: 'ID de la empresa',
                city: 'Ciudad',
                email: 'Correo electrónico',
                website: 'Sitio web',
                postalCode: 'Código postal',
                street: 'Dirección postal',
                phoneNumber: 'N.º de teléfono',
                addressHeader: '* Información de dirección',
                contactDetails: 'Datos de contacto',
                organization: '* Empresa emisora',
                organizationSettingsUpdatedTitle: 'Actualización de los ajustes de la organización',
                organizationSettingsUpdatedContent: 'Ajustes de la organización actualizados con éxito',
                organizationSettingsMissingFields: 'Faltan campos obligatorios del recibo electrónico. Rellénelos antes de guardar.',
                organizationSettingsMissingFieldsTitle: 'Error de validación del formulario',
            },
            softwareVersions: {
                updateNotificationTitle: 'Actualización de la versión de software',
                updateNotificationContent: 'Versión(es) de software actualizada(s) correctamente',
                selectAVersionPlaceholder: 'Seleccione una versión',
                searchDevices: 'Buscar dispositivos',
                updateSelected: 'Actualización seleccionada',
                softwareVersions: 'Versiones de software',
                softwareVersion: 'Versión de software',
                notSupportedDeviceNote: 'La versión {{selectedVersion.version}} no es compatible con este dispositivo',
                userNotificationContent:
                    'Hay disponible una nueva versión de software para sus dispositivos. Actualice sus dispositivos para disfrutar de las últimas funciones.',
                userNotificationTitle: 'Hay disponible una nueva versión de software',
                notificationBell_newSoftwareVersions: 'Actualizaciones disponibles para sus dispositivos',
                manageVersions: 'Gestionar las versiones de software de los dispositivos',
                filterLocationsPlaceholder: 'Filtrar por ubicaciones',
                allVersionNumbers: 'Todas las ediciones',
                noVersionsForUpdating: 'No hay versiones que actualizar',
                scheduledUpdateNote: 'La versión {{version}} está programada para actualizarse a las {{scheduled}}',
                scheduleRemoveNotificationTitle: 'Programación de la versión de software',
                scheduleRemoveNotificationContent: 'La actualización programada de la versión de software se ha eliminado correctamente',
                scheduleRemoveNotificationModalContent: '¿Seguro que desea eliminar la actualización programada para este dispositivo?',
            },
            header: {
                searchByOrganization: 'Buscar por organización',
                pageLanguage: 'Idioma',
                configureMySelflyAndEreceipts: 'Configurar ajustes de MySelfly',
                remindMeLater: 'Recuérdame más tarde',
                updateNow: 'Actualizar ahora',
            },
            notifications: {
                noNewNotifications: 'No hay nuevas notificaciones',
            },
            cabinetSettings: {
                cabinetTheme: 'Tema del armario',
                editAndSaveTheme: 'Editar y guardar tema',
                colorPicker: 'Selector de color',
                customTheme: 'Tema personalizado',
                themeSettings: 'Configuración del tema',
                primary: 'Principal',
                negative: 'Negativo',
                attention: 'Atención',
                positive: 'Positivo',
                background: 'Fondo',
                dark: 'Oscuro',
                light: 'Claro',
                white: 'Blanco',
                default: 'Predeterminado',
                negativeLight: 'Luz negativa',
                primaryColor: 'Color primario',
                positiveColors: 'Colores positivos',
                negativeColors: 'Colores negativos',
                secondaryColors: 'Colores secundarios',
                attentionColors: 'Colores de atención',
                screenBackground: 'Fondo de pantalla',
                borderColors: 'Bordes - Sombras',
                shadow: 'Sombra',
                border: 'Borde',
                fontColors: 'Colores de fuente',
                footerTextPlaceholder: 'Texto a pie de página',
                bundleDiscounts: 'Combinar y guardar',
                expiringText: 'Productos que caducan. Compruebe la fecha de caducidad antes de comprar',
                expiredText: 'Productos caducados. Compruebe la fecha de caducidad antes de comprar',
                cabinetName: 'Nombre del armario',
                productName: 'Nombre del producto',
                confirmationModalHeader: '¿Seguro que desea guardar el tema personalizado?',
                confirmationModalContent:
                    'La personalización del tema afectará al aspecto visual de la pantalla de su armario. Por favor, asegúrese de que la calidad visual de la pantalla no se vea comprometida con los cambios.',
                defaultRevertModalMessage: '¿Seguro que desea recuperar el tema predeterminado para el armario?',
                backgroundColor: 'Color de fondo',
                backgroundImage: 'Imagen de fondo',
                footerBackground: 'Fondo del pie de página',
                cardsBackground: 'Fondo de la tarjeta',
                screenImage: 'Imagen para la pantalla del gabinete',
                uploadImage: 'Subir imagen',
                editImage: 'Editar imagen',
                transactionComplete: 'Transacción completada',
                customPostPurchaseImage: 'Por favor, sube una imagen para la pantalla personalizada de post compra',
                postPurchaseScreen: 'Pantalla de post compra',
                custom: 'personalizado',
                postPurchaseText: 'Texto de visualización de post compra',
            },
            automations: {
                transactionReports: 'Informes de transacciones',
                refillReports: 'Informes de recarga',
                notifications: 'Notificaciones',
                refillPlan: 'Planes de recarga',
                days: 'Días',
                months: 'Meses',
                weeks: 'Semanas',
                configuration: 'Configuración',
                transactionReportTitle:
                    'Los informes de transacciones para los armarios seleccionados se generarán automáticamente y se enviarán a las direcciones de correo electrónico proporcionadas a continuación',
                refillReportTitle:
                    'Los informes de recarga para los armarios seleccionados se generarán automáticamente y se enviarán a las direcciones de correo electrónico proporcionadas a continuación',
                noSalesNotificationTitle:
                    'Las notificaciones se enviarán automáticamente a las direcciones de correo electrónico proporcionadas a continuación en caso de no haber ventas en los intervalos definidos',
                frequencyTitle: '¿Con qué frecuencia deseas que se genere el informe?',
                emailNotificationTitle:
                    'Por favor, añade las direcciones de correo electrónico a continuación donde te gustaría que se enviara el informe o notificación',
                refillPlanGenerationTitle: 'Los planes de recarga se generarán automáticamente para los armarios seleccionados según el horario proporcionado',
                addNewEmail: 'Añadir nuevo correo electrónico',
                noSalesBanner:
                    'Recibirás una notificación por correo electrónico si los armarios seleccionados no realizan ventas durante el período de tiempo configurado',
                noSaleTitle: 'Por favor, especifica la duración (en horas) de no ventas en los armarios seleccionados antes de que se envíe una notificación',
                minMaxTime: 'Mínimo es 2 y máximo es 23 horas',
                timeRangeTitle: 'Por favor, especifica el rango de tiempo para los armarios, durante el cual se verificará la situación de no ventas',
                startTime: 'Hora de inicio',
                endTime: 'Hora de finalización',
                refillPlanTitle:
                    'El plan de recarga para los armarios seleccionados se generará automáticamente. Por favor, programa a continuación la hora específica en que te gustaría que se creara el plan de recarga.',
                refillPlanTime: 'Por favor, selecciona la hora para que se genere el plan de recarga',
                everyDay: 'Cada día',
                everyWeek: 'Cada semana',
                everyMonth: 'Cada mes',
                selectDays: 'Seleccionar días',
                automationSaved: 'Configuración de automatización guardada con éxito',
                automationDeleted: 'Configuración de automatización eliminada con éxito',
            },
            sleepScheduling: {
                timeRangeTitle: 'Por favor, especifica el rango de tiempo durante el cual este armario entrará en modo de espera.',
                startTime: 'Hora de inicio',
                endTime: 'Hora de finalización',
                selectDays: 'Seleccionar días',
                duration: 'Duración (Horas)',
            },
        },
        no: {
            dateRangeFilter: {
                lastMonth: 'siste 30 dagene',
                lastTwoWeek: 'siste 14 dagene',
                lastWeek: 'siste 7 dagene',
                selectDateRangeMsg: 'Valgt datoperiode',
                today: 'i dag',
                yesterday: 'i går',
            },
            main: {
                activateSuccess: 'Produktet ble aktivert',
                activationDate: 'Aktiveringsdato',
                active: 'Aktiv',
                addAdvertisement: 'Legg til ny annonse',
                addDiscountRule: 'Legg til rabattregel',
                addDiscountStop: 'Legg til utløpsintervall',
                addNewLocation: 'Velg et nytt sted',
                addProduct: 'Legg til nytt produkt',
                addRegion: 'Legg til plassering',
                addRegionPrice: 'Legg til stedspris',
                addScheduledDiscount: 'Legg til planlagt rabatt',
                addBundleDiscount: 'Legg til pakkerabatt',
                bundledDiscounts: 'Buntrebatter',
                bundleItemsLengthExceeded: 'Maksimalt valg av produkter (3) er overskredet. Fjern noen av valgene dine for å fortsette.',
                bundleMaxProductLimitInfo: 'Velg maks. tre (3) produkter eller produktategorier ',
                bundleFixedAmountExceededOriginal: 'Buntens faste pris overstiger den opprinnelige prissummen. Sjekk utvalgte produkter.',
                bundleFixedAmountNotAllowedToBeZero: 'Buntens faste pris må være over 0.',
                bundlePercentageNotAllowedToBeFull: 'Buntens prosentrabatt må være under 100.',
                bundlePercentageNotAllowedToBeZero: 'Buntens prosentrabatt må være over 0.',
                bundleItemDiscountPriceAmountExceededOriginal: 'Ny rabattert pris overstiger originalpris. Sjekk ny pris på nytt.',
                bundleItemPriceMessage: 'Hver prisregel gjelder kun for 1 vare selv om flere av samme vare er valgt',
                bundleItemFixedAmountExceededOriginal: 'Produktets faste pris overstiger orginalproduktets pris. Sjekk fast pris igjen.',
                createDiscountRule: 'Opprett rabattregel',
                deviceType: 'Enhetstype',
                discountUpdateTitle: 'Rabattoppdatering',
                discountCreateTitle: 'Rabattoppretting',
                discountDeleteTitle: 'Rabattfjerning',
                discountDeleteContent: 'Rabatten er fjernet',
                discountStatusUpdated: 'Rabattens status er oppdatert',
                discountUpdated: 'Rabatten er oppdatert',
                discountCreated: 'Rabatt er opprettet',
                discountSettings: 'Rabattinnstillinger',
                discountImage: 'Rabattens valgfrie bilde»,',
                editDiscountStop: 'Rediger utløpsintervall',
                editBundleConfiguration: 'Rediger buntkonfigurasjon',
                editExpiryConfiguration: 'Rediger utløpsrabattens konfigurasjon»,',
                encodingStation: 'Kodestasjon',
                everyDay: 'Hverdag',
                scheduledDiscountDateInfoLabel: 'La start- og sluttdatoene stå tomme hvis du vil gjøre rabatten permanent',
                starts: 'Starter',
                ends: 'Slutter',
                error: 'Feil',
                errorRetiringLocationMsg: 'Kunne ikke trekke tilbake stedet. Fjern tilknyttede enheter, produktpriser og annonser først.',
                fetchingDiscountRules: 'Henter rabattregler',
                free: 'Gratis',
                missingImage: 'Mangler annonsebilde',
                next: 'Neste',
                back: 'Tilbake',
                noDiscountRules: 'Finner ingen rabattregler',
                scheduledDiscountsAutomaticDeactivationInfo:
                    'Rabatter der datoperioden overskrides, deaktiveres automatisk. Hvis du vil gjøre dem aktive, må du redigere datoperioden for å være gyldig.',
                scheduledDiscounts: 'Planlagte rabatter',
                schedulingStartHourIsOverEndHour: 'Starttiden kan ikke være etter sluttid',
                schedulingEndHourIsUnderStartHour: 'Sluttiden kan ikke være før starttid',
                selectDiscountRuleProductCategories: 'Velg produktkategorier som påvirkes av rabattregelen',
                selectBundleProductsAmount: 'Velg beløp for: ',
                selectedBundleProductCategories: 'Utvalgte produktkategorier for bunter',
                setDiscountDays: 'Angi rabattdager',
                setBundleDiscount: 'Angi enten prosentvis rabattbeløp eller fast pris for bunten',
                setBundleItemDiscount: 'Angi en av disse for ett produkt: prosentvis rabattbeløp, fast pris eller gratis',
                setBundleProducts: 'Angi produkter eller kategorier for bunt',
                setAmountMoreThanOne: 'Ved enkeltvarebunt, angi beløp mer enn én',
                setBundleDiscountType: 'Angi rabatttype og beløp for den',
                sureActivateDiscountRule: 'Er du sikker på at du vil aktivere rabattregelen?',
                sureDeactivateDiscountRule: 'Er du sikker på at du vil deaktivere rabattregelen?',
                sureDeleteDiscountRule: 'Er du sikker på at du vil slette rabattregelen?',
                timeRange: 'Tidsperiode',
                editExpiryDiscountRule: 'Rediger rabattregel',
                editScheduledDiscountRule: 'Rediger planlagt rabattregel',
                editBundleDiscountRule: 'Rediger regel for pakkerabatt',
                releaseNotes: 'Utgivelsesnotater',
                seeMore: 'Se mer',
                scheduledDiscountTimeRangeLabel: 'Aktiv tidspunkt',
                scheduledDiscountDateRangeLabel: 'Rabattens datoperiode',
                weekDayAbrvMonday: 'Man',
                weekDayAbrvTuesday: 'Tirs',
                weekDayAbrvWednesday: 'Ons',
                weekDayAbrvThursday: 'Tor',
                weekDayAbrvFriday: 'Fre',
                weekDayAbrvSaturday: 'Lør',
                weekDayAbrvSunday: 'Søn',
                recurrence: 'Tilbakevendende',
                addScheduledDiscountStop: 'Legg til planlagt rabatt',
                editScheduledDiscountStop: 'Rediger planlagt rabatt',
                pieces: 'stk',
                expiryDiscountNavLabel: 'Utløp',
                scheduledDiscountNavLabel: 'Planlagt',
                bundleDiscountNavLabel: 'Bunt',
                amount: 'Beløp',
                scheduling: 'Planlegging',
                pricing: 'Prissetting',
                percentageBundleDiscountInputPlaceholder: '% rabatt for bunt',
                fixedPriceBundleDiscountInputPlaceholder: 'Fast pris for bunt',
                applyBundleLevelLabel: 'Bruk buntnivå',
                applyItemLevelLabel: 'Bruk varenivå',
                productFixedPrice: 'Produktets faste pris',
                percentageDiscount: '% rabatt',
                fixedPriceDiscount: 'Fast pris',
                freeDiscount: 'Gratis',
                advertisementsFetchError: 'Det oppstod en feil under opplasting av annonser. Forsikre deg om at annonseblokkering er slått av',
                advertisementsTitle: 'Annonser',
                all: 'Alle',
                'All time': 'Hele tiden',
                allProducts: 'Alle produkter uten gruppering',
                allLocations: 'Alle steder',
                allCabinets: 'Alle skap',
                antennaDown: 'Noen av RFID-antennene er nede',
                antennaError: 'Feil er på skapantennene',
                antennaNoInfo: 'Ingen informasjon er tilgjengelig for skapantennene',
                antennaOk: 'Alle antennene virker',
                antennaPartial: 'Noen av antenenne er deaktiverte',
                archiveSuccess: 'Produktet ble arkivert',
                averageItems: 'Gjennomsnittlige varer per transaksjon',
                averageSales: 'Gjennomsnittlige salg per transaksjon',
                barcode: 'Strekkode',
                barcodeType: 'Strekkodetype',
                barcodeCannotBeChanged: 'Strekkode kan ikke endres',
                barcodeTypeCannotBeChanged: 'Strekkodetypen kan ikke endres',
                barcodeHelpText12: 'Angi 12 sifre av strekkoden, det siste sifferet legges automatisk til',
                barcodeHelpText11: 'Angi 11 sifre av strekkoden, det siste sifferet legges automatisk til',
                barcodeHelpText7: 'Angi 7 sifre av strekkoden, det siste sifferet legges automatisk til',
                beforeExpiry: 'før utløpsdato',
                cabinetDown: 'Skapet er nede',
                cabinetIsEmpty: 'Skapet er tomt',
                deviceOffline: 'Enheten er frakoblet',
                deviceOnline: 'Enheten er tilkoblet',
                cabinets: 'Skap',
                cabinet: 'Skap',
                cabinetSettings: 'Skapinnstillinger',
                cabinetTheming: 'Skaptema',
                cancel: 'Avbryt',
                cancelledSales: 'Kansellerte salg',
                cancelledTransactions: 'Kansellerte transaksjoner',
                categories: 'Kategorier',
                change: 'Endring',
                clear: 'Klar',
                close: 'Lukk',
                clickEditButtonRegionalPrices: 'For å redigere stedspriser, klikk på "Rediger" produktknappen',
                clickEditLocationPrices: 'Lokale priser, klikk på produktpris for å utvide.',
                clickToExpandLocationPrices: 'Lokale priser, klikk på produktpris for å utvide.',
                closeDoor: 'Lukk døren',
                rebootDevice: 'Start enheten på nytt',
                commandSentSuccessFully: 'Vellykket sendt kommando ',
                companyLogo: 'Firmalogo',
                confirm: 'Bekrefte',
                confirmDelete: 'Er du sikker på at du vil slette produktet? Denne handlingen kan ikke angres!',
                confirmDisable: 'Er du sikker på at du vil arkivere produktet?',
                confirmEnable: 'Er du sikker på at du vil gjøre produktet aktivt?',
                consumerResponses: 'Tilbakemelding fra bruker',
                controlPanelTitle: 'Kontrollpanel',
                controls_acceptablePercentageOfBadTags: 'Akseptabel prosentandel av dårlige koder før skapet går ut av tjenestemodus',
                controls_markTagsGoodQuality: 'Merk alle tagger som god kvalitet',
                controls_markTagsGoodQuality_btn: 'Merk tagger som god kvalitet',
                controls_clearTagHistory: 'Slett taggeloggen',
                controls_clearRemoveReplenishmentData: 'Slett påfyllingsdata',
                controls_companyAndPaymentLogo: 'Firma- og betalingslogo',
                controls_detectTagQuality: 'Oppdag tagkvalitet',
                controls_door: 'Dørkontroll',
                controls_device: 'Enhetskontroll',
                controls_chargeBadTags: 'Endre dårlige tagger',
                controls_refill: 'Fylle på',
                controls_tagQuality: 'Tag-instillinger',
                controls_tagQualityData: 'Tag Kvalitetsdata',
                controls_replenishmentData: 'Påfyllingsdata',
                controls_soldTags: 'Solgte tagger',
                controls_tagHistory: 'Tag historie',
                controls_markAllTagsReplenished: 'Merk alle tagger som påfyllt',
                controls_markAllTagsUnsold: 'Merk alle tagger som usolgte',
                controls_markAlTagsReplenished_btn: 'Merk tagger som påfyllt',
                controls_markAllTagsUnsold_btn: 'Merk tagger som usolgt',
                controls_chargeSoldTags: 'Endre solgte tagger',
                controls_chargeUnreplenishedTags: 'Endre upåfylte tagger',
                controls_tagsWarn:
                    'Når du tømmer tag-historikken, fjernes alle data fra tagger. Vennligst merk alle tagger etterfyllt etter dette med et klikk',
                create: 'Skape',
                createAdTitle: 'Ny annonse',
                createdAd: 'Annonse opprettet',
                createExpiryDiscountRule: 'Opprett regel for utløpsrabatt',
                createScheduledDiscountRule: 'Opprett regel for planlagt rabatt',
                createBundleDiscountRule: 'Opprett rabattregel',
                createNewVatCategory: 'Ny MVA-kategori',
                createProductTitle: 'Nytt produkt',
                creatingAd: 'Oppretter annonse',
                creatingProduct: 'Oppretter produkt',
                createNewProductCategory: 'Opprett en ny produkt kategori',
                createNewSupplier: 'Legg til ny leverandør',
                currency: 'Valuta',
                customQuery: 'Tilpasset søk',
                dashboard: 'Dashbord',
                dateRange: 'Datointervall',
                dataAlredyFetchedTitle: 'Data allerede hentet',
                dataAlreadyFetchedDescription: 'Velg andre parametre',
                day: 'Dag',
                days: 'Dager',
                deactivationDate: 'Deaktiveringsdato',
                default: 'Standard',
                delete: 'Slett',
                deleteError: 'Feil: produktet kunne ikke slettes',
                deleteSuccess: 'Produktet ble slettet',
                description: 'Beskrivelse',
                deviceManagement: 'Enhetsadministrasjon',
                digitwinOffline: 'Enhetens digitale tvilling er frakoblet',
                digitwinOnline: 'Enhetens digitale tvilling er tilkoblet',
                dimensions: 'Dimensjoner',
                disable: 'Arkiv',
                discountAmount: 'Rabattbeløp',
                discountPrice: 'Rabattpris',
                discountRuleAffects: 'Rabattregelen påvirker',
                discountRuleName: 'Rabattregelnavn',
                discountRuleStops: 'Utløpsintervaller',
                discounts: 'Rabatter',
                discountSalesData: 'Rabattsalgsdata',
                discountSalesAmount: 'Rabatt salgssum',
                discountTimeBeforeExpiryExists: 'Tidligere utløpsintervall finnes allerede',
                discountVsNormalSales: 'Rabatt mot normalt salg',
                dismiss: 'Avvise',
                doesNotExist: 'Eksisterer ikke',
                doorCloseConfirm: 'Er du sikker på at du vil lukke døren?',
                doorCloseSuccess: 'Vennligst lås døren',
                doorOpenConfirm: 'Er du sikker på at du vil åpne døren?',
                doorOpenReason: 'Hvorfor åpner du døren?',
                doorOpenSuccess: 'Døren ble åpnet',
                rebootDeviceSuccess: 'Enheten ble startet på nytt',
                deviceRebootConfirm: 'Er du sikker på at du vil starte enheten på nytt?',
                edit: 'Rediger',
                editCompanyLogo: 'Rediger firmalogoen',
                editDiscountRule: 'Rediger rabattregel',
                editPaymentMethodLogo: 'Rediger betalingsmetodelogoen',
                editVatCategoryModalTitle: 'Rediger MVA-kategori',
                editProductCategoryModalTitle: 'Rediger produktkategori',
                editSupplierModalTitle: 'Rediger leverandør',
                enable: 'Gjør aktiv',
                error_401: 'Ikke godkjent',
                error_403: 'Ikke autorisert til å utføre kommandoer',
                error_500: 'Uventet serverfeil',
                errorCreatingAd: 'Feil ved annonseoppretting',
                errorCreatingProduct: 'Feil ved produktoppretting',
                errorFetchingCabinetStatus: 'Feil under henting av skapstatus',
                errorFetchingInventory: 'Feil ved henting av skapbeholdning',
                errorFetchingReport: 'Feil ved henting av rapporter',
                errorFetchingSettings: 'Feil ved henting av innstillinger',
                errorUpdatingProduct: 'Feil ved produktoppdatering',
                expandToSeeBadTags: 'Utvid listen for å se hvilke tagger som ble oppdaget som dårlige. Dårlige tagger må fjernes fra skapet. ',
                expandToSeeSoldItems: 'Utvid listen for å se varene som allerede er solgt, men som dukket opp på lager igjen.',
                expandToSeeReplenishedOutOfInventory: 'Utvid listen for å se produktene som er plassert i skapet uten påfyllingsprosess',
                expired: 'Utløpt',
                expiredCount: 'Antall utløpt',
                expiredProductsCount: 'Antall utgåtte produkter',
                expiredSum: 'Utløpt sum',
                expiring: 'Utløper',
                expiryDate: 'Siste salgsdag',
                exportExcel: 'Eksporterer Excel',
                fetchAdvertisements: 'Henter annonser',
                fetchingCabinets: 'Henter skap',
                fetchingCabinetsError: 'Det oppstod en feil under henting av skap',
                fetchingCabinetStatus: 'Henter skapstatus',
                fetchingError: 'Det oppstod en feil under henting',
                fetchingInventory: 'Henter skapbeholdning',
                fetchingProducts: 'Henter produkter',
                fetchingReport: 'Henter rapporter',
                fetchingVatCategories: 'Henter MVA-kategorier',
                fetchingProductCategories: 'Henter produkt kategorier',
                fillForm: 'Fyll ut skjemaet',
                freshFood: 'Dette er et utløpende produkt',
                freezerPackage: 'Fryseprodukt',
                generalFetchError: 'Kunne ikke hente',
                generalRequestReject: 'Be om feil',
                get: 'Få',
                group: 'Gruppe',
                holdOn: 'Vent litt',
                hour: 'Time',
                hours: 'timer',
                hoursBeforeExpiry: 'Timer før utløp',
                daysBeforeExpiry: 'Dager før utløp',
                image: 'Bilde',
                imageOrVideo: 'Velg et bilde eller en video',
                inActive: 'Inaktiv',
                individualCabinets: 'Individuelle skap',
                individualCabinetsOnly: 'Bare individuelle skap',
                invalidBarcodeOnlyDigits: 'Strekkoden må kun inneholde sifre',
                invalidBarcodeTooLong: 'Strekkoden er for lang',
                invalidBarcodeTooShort: 'Strekkoden er for kort',
                invalidCabinetNameTooShort: 'Skapsnavnet er for kort',
                invalidCabinetNameDuplicated: 'Skapsnavnet eksisterer allerede',
                invalidLocationNameTooShort: 'Stedsnavnet er for kort',
                invalidLocationNameDuplicated: 'Stedsnavnet er duplisert',
                invalidPercentage: 'Ugyldig verdi',
                invalidPrice: 'Ugyldig pris',
                invalidProductBarcode: 'Ugyldig produktstrekkode',
                invalidTitle: 'Ugyldig tittel',
                invalidBundleItems: 'Bunten inneholder ugyldige elementer',
                inventoryLength: 'Lagerlenge',
                inventoryNotReported: 'Skapet har ikke rapportert inventaret',
                inventoryTitle: 'Skapbeholdning',
                'Inventory History': 'Lagerhistorikk',
                itemReplenishedOutOfInventoryProcess: 'Dette produktet eller taggen er plassert i skapet i en annen prosess enn påfyllingsprosess',
                itemSoldOn: 'Varen selges på',
                'Last 30 days': 'Siste 30 dagene',
                'Last 7 days': 'Siste 7 dagene',
                'Last month': 'Forrige måned',
                'Last quarter': 'Siste kvartal',
                'Last week': 'Forrige uke',
                'Last year': 'I fjor',
                lastUpdate: 'Siste oppdatering',
                lifetime: 'Livstid',
                location: 'Plassering',
                locations: 'Plasseringer',
                logoEditor: 'Rediger logo',
                logoSettings: 'Logoinnstillinger',
                loading: 'Laster inn…',
                measure: 'Måle',
                measures: 'Målinger',
                minsBeforeExpiry: 'Minutter før utløp',
                minutes: 'minutter',
                month: 'Måned',
                name: 'Navn',
                newVatCategoryModalTitle: 'Ny MVA-kategori',
                newProductCategoryModalTitle: 'Ny produktkategori',
                newSupplierModalTitle: 'Ny leverandør',
                'no region': '(ingen plassering)',
                noAdvertisements: 'Ingen annonser',
                noCabinets: 'Ingen skap',
                noCabinetSelected: 'Ingen skap valgt',
                noLogo: 'Ingen logo',
                noData: 'Ingen data',
                noDescription: 'Ingen beskrivelse',
                noEvents: 'Ingen arrangementer!',
                noIndividualCabinets: 'Ingen individuelle skap',
                noLocationPrices: 'Ingen lokale priser er definert for dette produktet',
                none: 'Ingen',
                noPaymentMethodLogo: 'Ingen logo tilknyttet betalingsmåte',
                noProducts: 'Ingen produkter',
                noProductsRemoved: 'Ingen produkter fjernet',
                noProductTypeSelected: 'Ingen pakketype valgt',
                normalPrice: 'Vanlig pris',
                normalSalesAmount: 'Vanlig salgssum',
                notAccessibleForYou:
                    'Opprett/oppdater funksjonen er bare aktivert for administratorer og operatører. Kontakt din veileder eller systemadministrator for å få tilgang.',
                notDefined: 'Ikke definert',
                noTitle: 'Uten tittel',
                noVatCategories: 'Ingen historikk om MVA-kategori funnet',
                noProductCategories: 'Fant ingen produkt kategori oppføringer',
                noInventoryOnSelectedDate: 'Ingen lager rapportert for datoen du valgte, prøv ny dato.',
                openDoor: 'Åpne døren',
                orderSum: 'Bestillingssum',
                paymentMethodLogo: 'Betalingsmetodelogo',
                percentage: 'Prosentdel',
                permanent: 'Fast',
                preview: 'Forhåndsvisning',
                price: 'Pris',
                print: 'Skriv ut',
                productCreated: 'Produkt opprettet',
                products: 'Produkter',
                productsFetchError: 'Det oppstod en feil under henting av produkter',
                productsTitle: 'Produktkatalog',
                productType: 'Pakketype',
                productUpdated: 'Produktet er oppdatert',
                quantity: 'Mengde',
                readerDown: 'RFID-leseren er nede',
                region: 'Plassering',
                regions: 'Plasseringer',
                removedAt: 'Fjernet klokken',
                removeImage: 'Fjern bildet',
                removeVideo: 'Fjern video',
                refillQRCodeInfo: 'Skann QR-koden for å åpne påfyllingsprogrammet eller følg',
                refillQRCodeTitle: 'Påfyll QR-kode',
                refillRuleTitle: 'Påfyllingsregel',
                reportsTitle: 'Rapporter',
                requestSuccess: 'Endringene er gjennomført',
                requiredPercentage: 'Påkrevd. Prosentverdi uten prosenttegn.',
                requiredText: 'Påkrevd lengde på tegn: ',
                retireLocation: 'Legg ned lokasjon',
                rfidNoInfo: 'Ingen informasjon tilgjengelig om RFID-leser og antenner',
                rfidOk: 'RFID-leser og antenner fungerer som de skal.',
                sales: 'Salg',
                salesCount: 'Salgsantall',
                salesVsWasteAmount: 'Salg mot svinn',
                save: 'Lagre',
                search: 'Søk',
                selectCabinet: 'Velg et skap',
                selectDiscountRuleCabinets: 'Velg skap som er berørt av rabattregelen',
                selectDiscountRuleLocations: 'Velg steder som er berørt av rabattregelen',
                selectDiscountRuleProducts: 'Velg produkter som er berørt av rabattregelen',
                selectProductType: 'Vennligst velg pakningstype for produktet ditt',
                setDiscountAmount: 'Angi rabattbeløp',
                setDiscountTimeBeforeExpiry: 'Angi eutløpsintervall',
                settingConfirmMessage: 'Er du sikker på at du vil lagre disse innstillingene? Hvis du ikke er sikker, må du ikke bekrefte det',
                showDisable: 'Arkiverte produkter',
                summary: 'Sammendrag',
                sureRemoveDiscountRule: 'Er du sikker på at du vil fjerne rabattregelen?',
                tagIsBad: 'Taggen er dårlig og må fjernes fra skapet.',
                tagsRemovedReport: 'Tagger fjernet rapport',
                taxValue: 'MVA-verdi',
                'This month': 'Denne måneden',
                'This quarter': 'Dette kvartalet',
                'This week': 'Denne uken',
                'This year': 'Dette året',
                thisLink: ' denne koblingen.',
                timeGrouping: 'Tid gruppering',
                title: 'Tittel',
                Today: 'I dag',
                topExpiredProducts: 'Topp utløpte produkter',
                topProducts: 'Topp produkter',
                total: 'Total',
                totalValue: 'Totalsum',
                transactions: 'Transaksjoner',
                ttlInDays: 'Produktets levetid i dager',
                ttlsAffected: 'Produkter med levetid påvirket',
                unexpectedError: 'Uventet feil',
                uniqueCustomers: 'Unike kunder',
                unknown: 'Ukjent',
                update: 'Oppdater',
                updateAdError: 'Feil ved oppdatering av annonsen',
                updateAdTitle: 'Oppdater annonsen',
                updatedAd: 'Annonse oppdatert',
                updateCabinetSettings: 'Synkroniser data til skap',
                updateCabinetSettingsInfo: 'Er du sikker på at du vil oppdatere skapinnstillingene?',
                updateData: 'Oppdater data',
                updateProductTitle: 'Oppdater produkt',
                updatingAd: 'Oppdaterer annonsen',
                updatingProduct: 'Oppdaterer produkt',
                uploading: 'Laster opp',
                uploadingImage: 'Laster opp bilde',
                use: 'Bruk',
                'w/o grouping': 'Ingen gruppering',
                waste: 'Svinn',
                vatCategoriesFetchError: 'Feil ved henting av MVA-kategorier',
                vatCategoriesTitle: 'MVA-kategorier',
                vatCategory: 'MVA-kategori',
                vatCategoryItems: 'Produkter',
                vatCategoryName: 'Mva-kategorinavn',
                vatCategoryPromptMessage: 'Er du sikker på at du vil slette MVA-kategorien',
                vatCategoryValue: 'Prosent av  MVA verdi',
                vatDeleteRejected: 'Sletting av MVA-kategori avvist. Sjekk tilknyttede produkter.',
                vatErrorRequesting: 'Feil under behandling av forespørsel',
                vatRequestRejected: 'Forespørsel avvist',
                vats: 'MVA',
                productCategoriesFetchError: 'Feil ved henting av produktkategorier',
                productCategoriesTitle: 'Produktkategorier',
                productCategory: 'Produktkategori',
                productCategoryItems: 'Kategorier',
                productCategoryName: 'Navn på produktkategori',
                productCategoryPromptMessage: 'Er du sikker på at du vil slette produktkategorien?',
                productCategoryDescription: 'Beskrivelse av produktkategori',
                productCategoryHelpTextDescription: 'For eksempel, salatkategori',
                productCategoryHelpTextName: 'For eksempel, salater',
                productCategoryDeleteRejected: 'Fjerningen av produktkategorien ble avvist. Sjekk de relaterte produktene!',
                week: 'Uke',
                viewMode: 'Vis',
                year: 'År',
                Yesterday: 'I går',
                groupBy: 'Gruppe etter',
                filterBy: 'Filtrer etter',
                reportType: 'Rapporttype',
                custom: 'Tilpasset',
                preDefined: 'Forhåndsdefinert',
                selected: 'Valgt',
                metricsInfo: 'Beregninger (sum, maksimum, minimum ...) beregnes for alle valgte dimensjoner/ressurser',
                dimensionsInfo: 'Beregninger blir hentet for valgte dimensjoner/ressurser',
                metrics: 'Beregninger',
                controlsSoftwareVersion: 'Programvareversjon',
                currentSoftwareVersion: 'Nåværende programvareversjon',
                availableSoftwareVersion: 'Tilgjengelig programvareversjon',
                invalidLifetime: 'Ugyldig levetidsverdi',
                unpaidSales: 'Ubetalte salg',
                unpaidTransactions: 'Ubetalte transaksjoner',
                softwareUpdateConfirmation: 'Er du sikker på at du vil oppdatere programvareversjonen til valgt(e) enhet(er)?',
                softwareUpdateWarn:
                    'Oppdatering av programvareversjonen vil sende en kommando til enheten(e) for å hente oppdateringen nå eller på det valgte tidspunktet. Valgt(e) enhet(er) vil installere oppdateringen neste gang den(de) er tilkoblet etter utløseren. Installasjonen kan ta tid avhengig av tilkoblingshastigheten. Enhet(e) vil starte på nytt etter installasjonen.',
                releaseVersion: 'Utgivelsesversjoner',
                releaseDate: 'Utgivelsesdato',
                latestRelease: 'Siste utgivelse',
                currentVersion: 'Gjeldende versjon',
                softwareUpdateSuccess: 'Programvareversjonen er oppdatert',
                prebooking: 'Forhåndsbestilling',
                prebookedProduct: 'Forhåndsbestilt produkt',
                prebookedProducts: 'Forhåndsbestilte produkter',
                activeProducts: 'Aktive produkter',
                startMaintenanceMode: 'Start skapets vedlikeholdsmodus',
                endMaintenanceMode: 'Avslutt vedlikeholdsmodus for skapet',
                startMaintenanceConfirm: 'Er du sikker på at du vil starte vedlikeholdsmodus for skapet?',
                endMaintenanceConfirm: 'Er du sikker på at du vil avslutte vedlikeholdsmodus for skapet?',
                startMaintenanceSuccess: 'Vedlikeholdsmodus startet for skapet',
                endMaintenanceSuccess: 'Vedlikeholdsmodusen avsluttet for skapet',
                noResults: 'Ingen resultater',
                suppliers: 'Leverandører',
                supplier: 'Leverandør',
                supplierDeleteRejected: 'Sletting av leverandør avvist. Sjekk tilknyttede produkter!',
                supplierPromptMessage: 'Er du sikker på at du vil slette leverandøren?',
                fetchingSuppliers: 'Henter leverandører',
                noSuppliers: 'Ingen leverandøroppføring funnet',
                suppliersFetchError: 'Feil ved henting av leverandører',
                supplierName: 'Leverandørnavn',
                supplierHelpTextName: 'For eksempel, et merkevarenavn',
                supplierDescription: 'Leverandørbeskrivelse',
                supplierHelpTextDescription: 'For eksempel, en merkevarebeskrivelse',
                wishes: 'Ønsker',
                checkNow: 'Sjekk nå',
                startTimeOn: 'Starttid den',
                endTimeOn: 'Sluttid den',
                signOutConfirmationModalTitle: 'Utloggingsbekreftelse',
                signOutConfirmationModalContent: 'Er du sikker på at du vil logge av?',
                startDate: 'Startdato',
                endDate: 'Sluttdato',
                scheduledDiscountApplyAllDays: 'Bruker for alle dager',
                scheduledDiscountApplySelectedDays: 'Bruker for utvalgte dager',
                discountCustomImageTitle: 'Rabattbilde (valgfritt)',
                discountCustomImageModalHeader: 'Bilde for rabatt',
                saveChanges: 'Lagre endringer',
                updateTagsQualitySettingsTitle: 'Innstillinger for merkekvalitet',
                updateTagsQualitySettingsMessage: 'Oppdaterte innstillinger for merkekvalitet',
                IF3Cabinets: 'Modell 3 Kjøleskap',
                IF2Cabinets: 'Modell 2 Kjøleskap',
                IF3Freezers: 'Modell 3 Frysere',
                encodingStations: 'Kodestasjoner',
                if2: 'Modell 2 Kjøleskap',
                if3: 'Modell 3 Kjøleskap',
                if3Freezer: 'Modell 3 Fryser',
                legalNotice: 'Juridisk merknad',
                privacyPolicy: 'Personvernerklæring',
                scheduledDiscountDateMissingError: 'Begge datoene kreves hvis start- eller sluttdato er angitt',
                scheduledDiscountDuplicateTimeError: 'Tidene kan ikke være de samme hvis datoene er de samme',
                now: 'Nå',
                minimum: 'Minimum',
                maximum: 'Maksimum',
                discardChangesConfirmationText: 'Er du sikker på at du vil fjerne endringene?',
                exports: 'Eksport',
                selectAllCabinets: 'Velg alle kabinettene',
                expiryIntervals: 'Utløpsintervaller',
                timeBeforeExpiry: 'Tid før utløp',
                apply: 'Bruk',
                yes: 'Ja',
                no: 'Nei',
                productOnScreenBannerText: 'Infobannertekst på skjermen',
                productOnScreenBannerAdditionalInfo:
                    'Bannertekst er en én-linjes tekst som vises under produktbildet og konsist formidler nøkkelfunksjoner, helseappeller eller tilleggsinformasjon om produktet',
                productOnScreenBannerExampleText: 'Laktosefri',
                UI_IS_DOWN: 'Skapdisplayen fungerer ikke',
                READER_IS_DOWN: 'RFID-leseren er nede',
                ANTENOR_DEVICE_DISCONNECTED: 'Betalingsenheten er frakoblet',
                EXPIRED_PRODUCT: 'Utløpte produkter i skapet',
                CLOUD_SERVICE_DOWN: 'Skytjeneste nede',
                ANTENNA_DISCONNECTED: 'Antenne frakoblet',
                TAG_CHANGES: 'For mange feilaktige koder i skapet',
                ANTENNA_DOWN: 'RFID-antenne i skapet fungerer ikke',
                READER_CONFIG_ERROR: 'Konfigurering av RFID-leser mislyktes, og den fungerer ikke',
                activeAlarms: 'Aktive alarmer',
                deviceStatus: 'Enhetens status',
                lastMonitored: 'Sist overvåket',
                noService: 'Uten service',
                operational: 'Operasjonell',
                offline: 'Offline',
                locationsSelectHelp: 'Når en lokasjon legges til, vil også dens underordnede lokasjoner legges til.',
                select: 'Plukke ut...',
                labels: 'Etiketter',
                productDetails: 'Produktdetaljer',
                invalidProductName: 'Produktnavnet må være mellom 1 og 125 tegn langt',
                standardPrice: 'Standardpris',
                locationPrices: 'Lokasjonspriser',
                addNew: 'Legg til ny',
                productExpiry: 'Produktutløp',
                productLifeTime: 'Produktets levetid',
                invalidTTL: 'Produktets levetid må være et heltall fra 0 til 32767',
                productImage: 'Produktbilde',
                uploadImage: 'Last opp bilde',
                pleaseUploadImage: 'Vennligst last opp et bilde',
                productValidationError: 'Vennligst rett opp følgende feil før du lagrer produktdata',
                productSupplier: 'Produktsupplier',
                frozenProductLable: 'Dette er et frossent produkt',
                prebookingLabel: 'Dette er et forhåndsbestillingsprodukt',
                previous: 'Forrige',
                bannerText: 'Bannertekst',
                packagingTypeValidationError: 'Vennligst velg en pakketyp for produktet',
                showOnlyFrozen: 'Vis kun frosne produkter',
                productClassification: 'Produktklassifisering',
                productClassLabel: 'Vennligst velg riktig klasse for produktet. Dette gjør det mulig for Selfly Insights å gi mer relevant produktinformasjon.',
                productLabels: 'Produktetiketter',
                productLabelsHelpText:
                    'Vennligst legg til valgfrie etiketter til produktet ditt. Dette vil tillate deg å filtrere produktene dine i produktkatalogen.',
                newLabel: 'Ny etikett',
                productClassValidationError: 'Vennligst velg en klassifisering for produktet ditt fra alternativene nedenfor',
                tagSensitivity: 'Tag-sensitivitet',
                tagSensitivitySetting: 'Innstilling av tag-sensitivitet',
                configTagSensitivity: 'Konfigurer tag-sensitivitet for produktet',
                productLevelSensitivityInfo:
                    'Lesbarheten til en RFID-tagg festet til et produkt kan påvirkes av forskjellige faktorer, inkludert emballasjematerialer, metalloverflater, fuktighet og til og med produktets eget innhold. Disse faktorene kan noen ganger føre til intermitterende tag-signaler, kjent som flimring. Skapet behandler slik flimring basert på sine følsomhetsinnstillinger. Hvis du er usikker på hvilket følsomhetsnivå som er riktig for dette produktet, kan du hoppe over denne delen.',
                errorSaving: 'Feil ved lagring av produktdata',
                successSaving: 'Produktdata lagret vellykket',
                basicDetail: 'Grunnleggende detaljer',
                additionalDetail: 'Tilleggsdetaljer',
                packaging: 'Pakketyp',
                classification: 'Klassifisering',
                selectOrtype: 'Velg eller skriv...',
                max25Chars: 'maks 25 tegn',
                noVatForProductCreation: 'Vennligst opprett en MVA-kategori før du legger til nye produkter',
                lifeTimeExplanation: 'Hvor mange dager dette produktet er gyldig for salg. (0 dager) betyr til slutten av inneværende dag',
                selflyEncode: 'Selfly Encode',
                userRestrict: 'Kun anerkjente forbrukere',
                userRestrictTitle: 'Kvalifiserte forbrukere',
                userNotRestrict: 'Alle forbrukere',
                userRestrictHelperText:
                    'Alternativet anerkjente forbrukere betyr at rabatten bare vil gjelde for forbrukere som allerede er registrert for rabattilgjengelighet!',
                searchCabinets: 'Søk i skap',
                selectAll: 'Velg alle',
                monday: 'Mandag',
                tuesday: 'Tirsdag',
                wednesday: 'Onsdag',
                thursday: 'Torsdag',
                friday: 'Fredag',
                saturday: 'Lørdag',
                sunday: 'Søndag',
                fetchingLoader: 'Henter. Vennligst vent...',
                category: 'Kategori',
                okay: 'Ok',
                noLocation: 'Ingen plassering',
                copy: 'Kopier',
                pleaseConfirm: 'Vennligst bekreft',
                saveQuery: 'Lagre denne spørringen',
                saveQueryPlaceholder: 'f.eks. "Totalt antall solgte varer"',
                saveQuerySuccess: 'Spørringen er lagret.',
                yourSavedQueries: 'Dine lagrede spørringer',
                selectQuery: 'Velg spørring',
                noQuery: 'Det er ingen lagrede spørringer ennå',
                deleteAdConfirm: 'Er du sikker på at du vil slette denne annonsen?',
                dataSaved: 'Data lagret vellykket',
            },
            refill: {
                refillRuleApplyRuleDropdownText: 'Bruk denne regelen også på andre kabinett',
                refillPlanGenerate: 'Generer påfyllingsplan',
                refillPlanEditPlan: 'Rediger plan',
                refillAmount: 'Påfyllingsmengde',
                refillRuleTargetAmount: 'Mål beholdningsnivå',
                refillRuleTargetAmountShort: 'Mål',
                refillPlanActivePlans: 'Aktive planer',
                refillPlanCompletedPlans: 'Fullførte planer',
                refillNote: 'Påfyllingsnotat',
                refillNoteHelp: 'Påfyllingsnotat for produktet (maksimalt 125 tegn)',
                refillPDFText:
                    'Nedenfor er påfyllingsplanen for skapet. Forsikre deg om at du markerer påfyllingsplanen som fullført i påfyllingsapplikasjonen etter at du har fullført påfyllingen.',
                refillPDFSummaryText: 'Nedenfor er sammendraget av påfyllingsplaner med produkter å legge til og fjerne for alle valgte skap.',
                refillSummary: 'Påfyllingssammendrag',
                addItems: 'Legg til produkter',
                removeItems: 'Fjern produkter',
                inventoryChange: 'Lagerendring',
                planogramPDFText: 'Planogram for påfyllingsstrategi for skapet.',
                planogramImagePDFText: 'Tilpasset bilde for påfyllingsstrategi for skapet.',
                refillSchemas: 'Påfyllingsmaler',
                refillPlans: 'Påfyllingsplaner',
                refillSchemaTitle: 'Sett mål- og minimumsmengder som en mal for valgte skap. Disse malene vil bli brukt til å generere påfyllingsplanene.',
                refillPlansTitle:
                    'Se alle aktive planer. Du kan redigere eller fjerne eksisterende aktive planer, eller legge til nye påfyllingsplaner etter behov.',
                refillSchemaSubText: 'Velg et skap for å opprette eller oppdatere påfyllingsmalen.',
                noRefillSchema: 'Ingen påfyllingsmal er definert for dette skapet ennå.',
                createRefillSchema: 'Opprett en ny påfyllingsmal',
                noDevice: 'Ingen enhet funnet',
                schemaSaved: 'Påfyllingsmal lagret vellykket',
                deleteSchemaConfirmation: 'Er du sikker på at du vil slette påfyllingsmalen?',
                targetAmount: 'Målmengde',
                targetAmountHelpText: 'Målmengden av lager for hvert produkt i skapet etter påfylling.',
                minimumThreshold: 'Minimumsterskel',
                minimumThresholdHelpText:
                    'Angi minimum lagerbeholdning for hvert produkt i skapet. Hvis mengden faller under den definerte terskelen, vil du motta et alarmvarsel. Å sette terskelen til 0 vil deaktivere varsler.',
                editNote: 'Rediger notat',
                refillNoteTitle: 'Skriv et påfyllingsnotat for produktet.',
                refillNoteCharacters: 'tegn',
                noRefillPlan:
                    'Det er for tiden ingen aktive påfyllingsplaner. Vennligst legg til nye påfyllingsplaner for skapene dine, eller opprett først en påfyllingsmal for skapene.',
                planSaved: 'Påfyllingsplan lagret vellykket',
                deleteConfirmation: 'Er du sikker på at du vil slette de valgte påfyllingsplandataene?',
                editPlan: 'Rediger plan',
                currentInventory: 'Nåværende lagerbeholdning',
                lastSevenDaySales: 'Salg de siste syv dagene',
                inventory: 'Lager',
                planogram: 'Planogram',
                copyRefillSchema: 'Kopier mal til andre skap',
                generatingPlans: 'Genererer påfyllingsplaner. Vennligst vent...',
                generatePlans: 'Generer påfyllingsplaner',
                complete: 'fullført',
                selectCabinetsToAddPlans: 'Velg skap for å generere påfyllingsplaner.',
                reviewSuggestion:
                    'Nedenfor er de foreslåtte påfyllingsplanene for de valgte skapene. Vennligst gjennomgå, rediger om nødvendig, og lagre dem for å aktivere disse planene.',
                noSchema:
                    'Ingen skap har for øyeblikket en påfyllingsmal. Vennligst legg til en påfyllingsmal til skapene dine for å generere påfyllingsplaner.',
                searchProducts: 'Søk produkter',
                maxAllowedInventory: 'Maks tillatt lager er 200.',
                savingData: 'Lagrer data. Vennligst vent...',
                errorSavingData: 'Feil ved lagring av data.',
                refillPlanSaved: 'Påfyllingsplaner generert vellykket.',
                exportPDFHelp: 'Eksporter valgte planer til PDF-fil.',
                exportExcelHelp: 'Eksporter valgte planer til Excel-fil.',
                addPlanHelp: 'Legg til nye påfyllingsplaner.',
                deletePlanHelp: 'Slett valgte påfyllingsplaner.',
                generatePlansHelp: 'Generer påfyllingsplaner for de valgte skapene.',
                planogramSaved: 'Planogram lagret vellykket.',
                schemaCopied: 'Påfyllingsmalen ble synkronisert med de andre skapene.',
                allProducts: 'Alle produkter',
                schemaProducts: 'Påfyllingsmalprodukter',
                deletePlanogram: 'Er du sikker på at du vil slette planogrammet?',
                dragInPlanogram: 'Vennligst dra og slipp produkter på de utpekte skapreolene, eller dra skapplassen over produktet.',
                noPlanogramInSchema: 'Et planogram er ennå ikke definert for dette skapet.',
                noPlanogramInPlan: 'Ingen planogramdata er tilgjengelig for skapet. Vennligst opprett et planogram for skapet i påfyllingsmaltabben først.',
                copyPlanogram: 'Kopier påfyllingsplanogram sammen med påfyllingsmalen.',
                planogramImageInfo: 'I tillegg til planogrammet kan du også legge til et tilpasset planogrambilde.',
                addPlanogramImage: 'Legg til planogrambilde',
                editPlanogramImage: 'Rediger planogrambilde',
                numberOfShelves: 'Antall hyller',
                addNewRefillPlan: 'Legg til nye planer',
                qrcodeTitle: 'En QR-kode vil bli generert for det valgte skapet for å starte påfyllingsprosessen.',
                qrcodeSubText: 'Vennligst velg et skap der du vil starte påfyllingsprosessen.',
                qrcodeScan: 'Vennligst skann QR-koden for å fylle på, eller klikk på knappen for å åpne påfyllingsapplikasjonen.',
                qrcodeOpenApplication: 'Åpne påfyllingsapplikasjon',
                createRefillPlanogram: 'Opprett påfyllingsplanogram',
            },
            navigation: {
                advertisements: 'Annonser',
                analytics: 'Analyse',
                controlPanel: 'Kontrollpanel',
                deviceManagement: 'Enhetsadministrasjon',
                discounts: 'Rabatter',
                fetching: 'Henter',
                group: 'Gruppe',
                inventory: 'Skapbeholdning',
                language: 'Språk',
                productCatalog: 'Produktkatalog',
                productCategories: 'Produktkategorier',
                reports: 'Rapporter',
                settings: 'Brukerinnstillinger',
                signOut: 'Logg ut',
                title: 'Selfly Cloud Portal',
                vatCategories: 'MVA-kategorier',
                community: 'Selfly Community',
                refillManagement: 'Påfyllingsadministrasjon',
                automations: 'Automatiseringer',
                mySelfly: 'Mine MySelfly-innstillinger',
                automationInfo: 'Administrer automatiserte handlinger (BETA)',
            },
            reports: {
                alreadyRefund: 'ble refundert',
                amount: 'Beløp',
                average: 'Gjennomsnitt',
                barcode: 'Strekkode',
                confirmIssueRefundButton: 'Bekreft refusjon nå',
                current: 'Nåværende',
                export: 'Eksport',
                fetching: 'Henter',
                fetchingError: 'Det oppstod en feil under henting',
                image: 'Bilde',
                issueRefundButton: 'R E F U S J O N',
                maximum: 'Maksimum',
                minimum: 'Minimum',
                name: 'Navn',
                noData: 'Ingen data',
                note: 'Merk',
                order: 'Transaksjon',
                paymentWasDoneByCreditCard: 'Betaling ble gjort med kredittkort',
                price: 'Pris',
                ProductReport: 'Produktrapport',
                quantity: 'Antall',
                'Refund impossible': 'Betaling ble gjort med kredittkort. Kontakt kortterminalutstederen for tilbakebetaling.',
                refundFormTitle: 'Refusjon',
                refundReason: 'Refusjonsårsak',
                refundSuccess: 'Sum {{sum}} ble refundert',
                refundTotal: 'Refusjonssum',
                replenishments: 'Påfyll',
                replenishmentsTitle: 'Påfyllingsrapport',
                SaleTransaction: 'Salgstransaksjon',
                summary: 'Sammendrag',
                SummaryReport: 'Salgssammendrag',
                temperature: 'Temperatur',
                temperatureTitle: 'Temperaturrapport',
                temperatureValueNotification: 'Temperaturverdi er gjennomsnittlig temperatur pr. time i skapet. ',
                time: 'Tid',
                total: 'Total',
                transactions: 'Transaksjoner',
                user: 'Bruker',
                paidTransactions: 'Betalte transaksjoner',
                unpaidTransactions: 'Ubetalte transaksjoner',
                partiallyPaid: 'Delvis betalt',
                failedTransactions: 'Mislykkede transaksjoner',
                amountOwed: 'Skyldig beløp',
                receiptLinkText: 'Hent kvitteringen her',
                noPurchase: 'Ingen kjøp',
                other: 'Annen',
                userId: 'Bruker ID:',
            },
            settings: {
                block_cabinet_when_product_expired: 'Blokker skapet på grunn av utgåtte produkter',
                criticalExpiryLimitHours: 'Timer før kritisk utløpsvarsel',
                language: 'Språk',
                logo: 'Logo',
                mediumExpiryLimitHours: 'Timer før middels utløpsvarsel',
                payment_method_logo: 'Betalingsmetodelogo',
                footer_instruction_logo: 'Bunntekst instruksjonsbilde',
                readUserMemory: 'Hent utløpsdata for produktet',
                ui_skus_sort_by: 'Sorteringsrekkefølge av produkter i skapdisplay',
                timeZone: 'Tidssone',
                country: 'Land',
                city: 'By',
                tagSettings: 'Tag-innstillinger',
                themeAndLogos: 'Tema og logoer',
                advancedSettings: 'Avanserte innstillinger',
                themingInfoText: 'Du kan tilpasse temaet forkabinettskjermen',
                cabinetLogos: 'Kabinettlogoer',
                cabinetLogoInfoText: 'Last opp logoer som vises på kabinettskjermen',
                logoHelperText: 'Bruk 180 px X 180 px bilde',
                settingsCopyInfoHeader: 'Velg kabinettene du ønsker å kopiere innstillingene til',
                settingsCopyButton: 'Kopier innstillinger til valgte kabinett',
                settingsCopySuccessModalTitle: 'Kopiering av innstillinger',
                settingsCopySuccessModalContent: 'Innstillingene er kopiert til {{cabinetsAmount}}-kabinett',
                theming: 'Tema',
                adminSettings: 'Admininnstillinger',
                settingsCopyErrorModalTitle: 'Feil ved kopiering av innstillinger',
                key: 'Innstilling',
                value: 'Verdi',
                sleepScheduling: 'Planlagt standby',
                sensitivityLevel: 'Følsomhetsnivå',
                sensitivity_0:
                    'Skapet ignorerer merkeflimmer og markerer konsekvent alle merker som gyldige, noe som noen ganger kan føre til feil belastning.',
                sensitivity_20: 'Skapet tolererer opptil tre feilavlesninger for et merke innen 50 skanninger og vil fortsatt merke merket som gyldig.',
                sensitivity_40: 'Skapet tolererer opptil to feilavlesninger for et merke innen 60 skanninger og vil fortsatt merke merket som gyldig.',
                sensitivity_60: 'Hvis et merke flimrer ofte, men forblir stabilt over lengre tid, tolererer skapet dette og leser merket som gyldig.',
                sensitivity_80: 'Hvis et merke flimrer kortvarig, men forblir stabilt over lengre tid, vil det bli betraktet som et gyldig merke.',
                sensitivity_100:
                    'Skapet er svært intolerant overfor merkeflimmer. Selv en enkelt dårlig avlesning kan føre til at et merke blir markert som ugyldig i en lengre periode, noe som kan resultere i potensielle tap.',
                sensitivityInfo:
                    'Skapets følsomhet for merkevarekvalitet refererer til hvor effektivt skapet håndterer inkonsistente merkeavlesninger eller merkeflimmer. Vær oppmerksom på følsomhetsnivået du velger, da det kan påvirke skapets ytelse. Hvis du er usikker på hvilket nivå som er passende, anbefales det å la innstillingen stå på standardnivå 3.',
            },
            signIn: {
                signInButton: 'Logg inn',
                signInExpiredError: 'Økten din er utløpt. Logg inn på nytt',
                signInMessage: 'Du må være pålogget for å fortsette. Klikk på knappen nedenfor for å logge på',
                noOrganizationsError: 'Ingen organisasjon knyttet til dette brukernavnet. Prøv et annnet brukernavn.',
            },
            translations: {
                add: 'Legg til',
                closed: 'Lukket',
                delete: 'Slett',
                device: 'Enhet',
                edit: 'Redigere',
                off: 'AV',
                offline: 'Frakoblet',
                on: 'PÅ',
                online: 'Pålogget',
                opened: 'Åpen',
                save: 'Lagre',
                seeDetails: 'Detaljer',
                settings: 'Innstillinger',
                unknown: 'Ukjent',
            },
            consumerApp: {
                enableConsumerFeedback: 'Aktiver tilbakemelding fra bruker på MySelfly',
                enablePurchaseReceipts: 'Aktiver e-kvitteringer på MySelfly',
                enableSelflyTrademarkForReceipts: 'Tillat Selfy Store-bunntekst i e-kvitteringer',
                enableSelflyTrademarkForReceiptsHelpText: 'Aktivering av dette vil legge til Selfly Store-logo og detaljer på e-kvitteringen på PDF!',
                organizationFormHelpText: 'Oppgi e-kvitteringsutstederens detaljer.',
                organizationFormValidationHelpText: '* Obligatoriske felter for e-kvittering er utstederorganisasjonens navn, bedrifts-ID, adresse og land.',
                organizationName: 'Organisasjonsnavn',
                organizationLogo: 'Organisasjonslogo',
                businessId: 'Bedrifts-ID',
                city: 'By',
                email: 'E-postadresse',
                website: 'Nettside',
                postalCode: 'Postnummer',
                street: 'Gateadresse',
                phoneNumber: 'Telefonnummer',
                addressHeader: '* Adresseopplysninger',
                contactDetails: 'Kontaktdetaljer',
                organization: '* Utstederorganisasjon',
                organizationSettingsUpdatedTitle: 'Oppdatering av organisasjonsinnstillinger',
                organizationSettingsUpdatedContent: 'Organisasjonsinnstillingene er oppdaterte',
                organizationSettingsMissingFields: 'Obligatoriske felt for e-kvittering mangler. Fyll ut før du lagrer.',
                organizationSettingsMissingFieldsTitle: 'Skjemavalideringsfeil',
            },
            softwareVersions: {
                updateNotificationTitle: 'Oppdatering av programvareversjon',
                updateNotificationContent: 'Programvareversjon(er) ble oppdatert',
                selectAVersionPlaceholder: 'Velg en versjon',
                searchDevices: 'Søk etter enheter',
                updateSelected: 'Oppdatering er valgt',
                softwareVersions: 'Programvareversjoner',
                softwareVersion: 'Programvareversjon',
                notSupportedDeviceNote: 'Versjonen {{selectedVersion.version}} støttes ikke på denne enheten',
                userNotificationContent: 'En ny programvareversjon er utgitt for enhetene. Oppdater enhetene for å få de nyeste funksjonene.',
                userNotificationTitle: 'En ny programvareversjon er utgitt',
                notificationBell_newSoftwareVersions: 'Tilgjengelige oppdateringer for enhetene',
                manageVersions: 'Styr enhetens programvareversjoner',
                filterLocationsPlaceholder: 'Filtrer etter steder',
                allVersionNumbers: 'Alle utgivelser',
                noVersionsForUpdating: 'Ingen oppdateringsversjoner',
                scheduledUpdateNote: 'Versjonen {{version}} skal etter planen oppdateres klokken {{scheduled}}',
                scheduleRemoveNotificationTitle: 'Planlegging av programvareversjon',
                scheduleRemoveNotificationContent: 'Programvareversjonens planlagte oppdatering ble fjernet',
                scheduleRemoveNotificationModalContent: 'Er du sikker på at du vil fjerne planlagt oppdatering for denne enheten?',
            },
            header: {
                searchByOrganization: 'Søk etter organisasjon',
                pageLanguage: 'Språk',
                configureMySelflyAndEreceipts: 'Konfigurer MySelfly-innstillinger',
                remindMeLater: 'Minn meg på det senere',
                updateNow: 'Oppdater nå',
            },
            notifications: {
                noNewNotifications: 'Ingen nye varsler',
            },
            cabinetSettings: {
                cabinetTheme: 'Skaptema',
                editAndSaveTheme: 'Rediger og lagre tema',
                colorPicker: 'Fargevelger',
                customTheme: 'Tilpasset tema',
                themeSettings: 'Temainnstillinger',
                primary: 'Primær',
                negative: 'Negativt',
                attention: 'Merk følgende',
                positive: 'Positivt',
                background: 'Bakgrunn',
                dark: 'Mørk',
                light: 'Lys',
                white: 'Hvit',
                default: 'Standard',
                negativeLight: 'Negativt lys',
                primaryColor: 'Primærfarge',
                positiveColors: 'Positive farger',
                negativeColors: 'Negative farger',
                secondaryColors: 'Sekundærfarger',
                attentionColors: 'Oppmerksomhetsfarger',
                screenBackground: 'Skjermbakgrunn',
                borderColors: 'Rammer - Skygger',
                shadow: 'Skygge',
                border: 'Ramme',
                fontColors: 'Fontfarger',
                footerTextPlaceholder: 'Bunntekst',
                bundleDiscounts: 'Kombiner og spar penger',
                expiringText: 'Varer som utløper. Sjekk utløpsdato før kjøp.',
                expiredText: 'Utløpte varer Sjekk utløpsdato før kjøp.',
                cabinetName: 'Skapnavn',
                productName: 'Produktnavn',
                confirmationModalHeader: 'Er du sikker på at du vil lagre det tilpassede temaet?',
                confirmationModalContent:
                    'Et tilpasset tema vil påvirke skapdørens visuelle utseende. Sørg for at endringene ikke kompromitterer dørens visuelle kvalitet.',
                defaultRevertModalMessage: 'Er du sikker på at du vil endre skaptema til standard?',
                backgroundColor: 'Bakgrunnsfarge',
                backgroundImage: 'Bakgrunnsbilde',
                footerBackground: 'Bunntekstbakgrunn',
                cardsBackground: 'Bakgrunn for kort',
                screenImage: 'Bilde for skapets skjerm',
                uploadImage: 'Last opp bilde',
                editImage: 'Rediger bilde',
                transactionComplete: 'Transaksjon fullført',
                customPostPurchaseImage: 'Vennligst last opp et bilde for den tilpassede etterkjøpsskjermen',
                postPurchaseScreen: 'Skjerm etter kjøp',
                custom: 'tilpasset',
                postPurchaseText: 'Visningstekst etter kjøp',
            },
            automations: {
                transactionReports: 'Transaksjonsrapporter',
                refillReports: 'Påfyllingsrapporter',
                notifications: 'Varsler',
                refillPlan: 'Påfyllingsplaner',
                days: 'Dager',
                months: 'Måneder',
                weeks: 'Uker',
                configuration: 'Konfigurasjon',
                transactionReportTitle: 'Transaksjonsrapporter for de valgte skapene vil bli automatisk generert og sendt til e-postadressene oppgitt nedenfor',
                refillReportTitle: 'Påfyllingsrapporter for de valgte skapene vil bli automatisk generert og sendt til e-postadressene oppgitt nedenfor',
                noSalesNotificationTitle:
                    'Varsler vil bli sendt automatisk til e-postadressene oppgitt nedenfor ved manglende salg i de definerte intervallene',
                frequencyTitle: 'Hvor ofte vil du at rapporten skal genereres?',
                emailNotificationTitle: 'Vennligst legg til e-postadressene nedenfor hvor du ønsker at rapporten eller varslingen skal sendes',
                refillPlanGenerationTitle: 'Påfyllingsplaner vil bli automatisk generert for de valgte skapene i henhold til den oppgitte planen',
                addNewEmail: 'Legg til ny e-post',
                noSalesBanner: 'Du vil motta en e-postvarsling hvis de valgte skapene ikke har noe salg i den konfigurerte tidsperioden',
                noSaleTitle: 'Vennligst spesifiser tidsvarigheten (i timer) for ingen salg i de valgte skapene før varslingen sendes',
                minMaxTime: 'Min er 2 og max er 23 timer',
                timeRangeTitle: 'Vennligst spesifiser tidsintervallet for skapene, der situasjonen med ingen salg vil bli sjekket',
                startTime: 'Starttid',
                endTime: 'Sluttid',
                refillPlanTitle:
                    'Påfyllingsplanen for de valgte skapene vil bli automatisk generert. Vennligst planlegg nedenfor den spesifikke tiden når du ønsker at påfyllingsplanen skal opprettes.',
                refillPlanTime: 'Vennligst velg tiden for når påfyllingsplanen skal genereres',
                everyDay: 'Hver dag',
                everyWeek: 'Hver uke',
                everyMonth: 'Hver måned',
                selectDays: 'Velg dager',
                automationSaved: 'Automatiseringsinnstillinger lagret vellykket',
                automationDeleted: 'Automatiseringsinnstillinger slettet vellykket',
            },
            sleepScheduling: {
                timeRangeTitle: 'Vennligst spesifiser tidsintervallet der dette skapet vil gå i standby-modus.',
                startTime: 'Starttid',
                endTime: 'Sluttid',
                selectDays: 'Velg dager',
                duration: 'Varighet (Timer)',
            },
        },
        it: {
            dateRangeFilter: {
                lastMonth: 'ultimi 30 giorni',
                lastTwoWeek: 'ultimi 14 giorni',
                lastWeek: 'ultimi 7 giorni',
                selectDateRangeMsg: 'Intervallo di date selezionato',
                today: 'oggi',
                yesterday: 'ieri',
            },
            main: {
                activateSuccess: 'Prodotto attivato con successo',
                activationDate: 'Data di attivazione',
                active: 'Attivo',
                addAdvertisement: 'Aggiungi nuova pubblicità',
                addDiscountRule: 'Aggiungi regola di sconto',
                addDiscountStop: 'Aggiungi intervallo di scadenza',
                addNewLocation: 'Scegli un nuovo luogo',
                addProduct: 'Aggiungi un nuovo prodotto',
                addRegion: 'Aggiungi luogo',
                addRegionPrice: 'Aggiungi prezzo per luogo',
                addScheduledDiscount: 'Aggiungi sconto programmato',
                addBundleDiscount: 'Aggiungere sconto pacchetto ',
                bundledDiscounts: 'Sconti pacchetti',
                bundleItemsLengthExceeded:
                    'Il numero massimo di prodotti selezionati (3) è stato superato. Rimuovere alcuni dei prodotti selezionati per continuare.',
                bundleMaxProductLimitInfo: 'Selezionare non più di tre (3) prodotti o categorie di prodotti',
                bundleFixedAmountExceededOriginal:
                    'Il prezzo fisso del pacchetto ha superato la somma del prezzo originale. Controllare i prodotti selezionati',
                bundleFixedAmountNotAllowedToBeZero: 'Il prezzo fisso del pacchetto deve essere maggiore di 0',
                bundlePercentageNotAllowedToBeFull: 'Lo sconto percentuale del pacchetto deve essere inferiore a 100',
                bundlePercentageNotAllowedToBeZero: 'Lo sconto percentuale del pacchetto deve essere maggiore di 0',
                bundleItemDiscountPriceAmountExceededOriginal: 'Il nuovo prezzo scontato ha superato il prezzo originale. Controlla ancora il nuovo prezzo',
                bundleItemPriceMessage:
                    'Ciascuna regola di prezzo è applicata a 1 solo prodotto anche se sono stati selezionati più esemplari dello stesso prodotto',
                bundleItemFixedAmountExceededOriginal: 'Il prezzo fisso del prodotto è maggiore del suo prezzo originale. Controlla di nuovo il prezzo fisso.',
                createDiscountRule: 'Crea una regola di sconto',
                deviceType: 'Tipo di dispositivo',
                discountUpdateTitle: "Aggiornamento dell'offerta",
                discountCreateTitle: 'Creazione di una nuova offerta ',
                discountDeleteTitle: 'Rimozione una offerta',
                discountDeleteContent: 'Lo sconto è stato rimosso ',
                discountStatusUpdated: 'Lo stato dello sconto è stato aggiornato',
                discountUpdated: 'Lo sconto è stato aggiornato',
                discountCreated: 'Lo sconto è stato creato',
                discountSettings: 'Impostazioni sconto',
                discountImage: 'Immagine facoltativa dello sconto',
                editDiscountStop: 'Modifica intervallo di scadenza',
                editBundleConfiguration: 'Modifica configurazione pacchetto',
                editExpiryConfiguration: 'Modifica la configurazione della sconto a scadenza',
                encodingStation: 'Stazione di codifica',
                everyDay: 'Ogni giorno',
                scheduledDiscountDateInfoLabel: 'Si prega di lasciare vuote le date di inizio e di fine se si desidera rendere permanente lo sconto',
                starts: 'Inizia',
                ends: 'Finisce',
                error: 'Errore',
                errorRetiringLocationMsg:
                    'Errore nel ritiro della posizione. Rimuovere prima i dispositivi collegati, i prezzi dei prodotti e gli annunci pubblicitari.',
                fetchingDiscountRules: 'Recupero della regole di sconto',
                free: 'Gratuito ',
                missingImage: 'Immagine pubblicitaria mancante ',
                next: 'Avanti',
                back: 'Indietro',
                noDiscountRules: 'Nessuna regola di sconto trovata',
                scheduledDiscountsAutomaticDeactivationInfo:
                    "Gli sconti per i quali l'intervallo di date è stato superato vengono automaticamente disattivati. Se vuoi attivarli, modifica l'intervallo di date, affinché sia valido.",
                scheduledDiscounts: 'Sconti programmati',
                schedulingStartHourIsOverEndHour: "L'ora di inizio non può essere successiva all'ora di fine ",
                schedulingEndHourIsUnderStartHour: "L'ora di fine non può essere precedente all'ora di inizio",
                selectDiscountRuleProductCategories: 'Selezionare le categorie di prodotto soggette alla regola di sconto',
                selectBundleProductsAmount: 'Seleziona ammontare per: ',
                selectedBundleProductCategories: 'Categorie di prodotto del pacchetto selezionato',
                setDiscountDays: 'Imposta giorni dello sconto',
                setBundleDiscount: 'Imposta percentuale dello sconto o il prezzo fisso totale del pacchetto',
                setBundleItemDiscount: 'Imposta uno dei seguenti per un prodotto: percentuale dello sconto, prezzo fisso o gratuito',
                setBundleProducts: 'Imposta prodotti o categorie per il pacchetto',
                setAmountMoreThanOne: 'In caso di un pacchetto con un singolo articolo, impostare un importo maggiore di uno',
                setBundleDiscountType: 'Imposta il tipo di sconto e il relativo importo',
                sureActivateDiscountRule: 'Sei sicuro che vuoi attivare la regola di sconto?',
                sureDeactivateDiscountRule: 'Sei sicuro che vuoi disattivare la regola di sconto?',
                sureDeleteDiscountRule: 'Sei sicuro che vuoi eliminare la regola di sconto?',
                timeRange: 'Intervallo di tempo',
                editExpiryDiscountRule: 'Modifica regola di sconto',
                editScheduledDiscountRule: 'Modifica la regola dello sconto programmato',
                editBundleDiscountRule: 'Modifica la regola dello sconto per gruppi di prodotti',
                releaseNotes: 'Note di rilascio',
                seeMore: 'Vedi piu dettagli',
                scheduledDiscountTimeRangeLabel: 'Tempo attivo',
                scheduledDiscountDateRangeLabel: 'Intervallo di date dello sconto',
                weekDayAbrvMonday: 'Lu',
                weekDayAbrvTuesday: 'Ma',
                weekDayAbrvWednesday: 'Me',
                weekDayAbrvThursday: 'Gio',
                weekDayAbrvFriday: 'Ve',
                weekDayAbrvSaturday: 'Sa',
                weekDayAbrvSunday: 'Do',
                recurrence: 'Ricorrenza',
                addScheduledDiscountStop: 'Aggiungi sconto programmato',
                editScheduledDiscountStop: 'Modifica sconto programmato',
                pieces: 'pezzi',
                expiryDiscountNavLabel: 'Scadenza',
                scheduledDiscountNavLabel: 'Programmato',
                bundleDiscountNavLabel: 'Pacchetto ',
                amount: 'Ammontare',
                scheduling: 'Programmazione',
                pricing: 'Tariffazione',
                percentageBundleDiscountInputPlaceholder: '% di sconto per pacchetto ',
                fixedPriceBundleDiscountInputPlaceholder: 'Prezzo fisso per pacchetto',
                applyBundleLevelLabel: 'Applica livello pacchetto',
                applyItemLevelLabel: 'Applica livello prodotto',
                productFixedPrice: 'Prezzo fisso del prodotto',
                percentageDiscount: '% sconto',
                fixedPriceDiscount: 'Prezzo fisso ',
                freeDiscount: 'Gratuito ',
                advertisementsFetchError: "Si è verificato un errore durante il recupero delle pubblicità. Assicurati che l'ad-blocker sia disattivato",
                advertisementsTitle: 'Pubblicità',
                all: 'Tutto',
                'All time': 'Sempre',
                allProducts: 'Tutti i prodotti senza raggruppamento',
                allLocations: 'Tutti i luoghi',
                allCabinets: 'Tutti i frigoriferi',
                antennaDown: 'Alcune antenne RFID non funzionano',
                antennaError: "L'errore riguarda le antenne del frigo",
                antennaNoInfo: 'Non ci sono informazioni disponibili sulle antenne del frigo',
                antennaOk: 'Tutte le antenne sono connesse e funzionanti',
                antennaPartial: 'Alcune antenne sono disattivate',
                archiveSuccess: 'Prodotto archiviato con successo',
                averageItems: 'Numero medio di prodotti per transazione',
                averageSales: 'Vendite medie per transazione',
                barcode: 'Codice a barre',
                barcodeType: 'Tipo di codice a barre',
                barcodeCannotBeChanged: 'Il codice a barre non può essere cambiato',
                barcodeTypeCannotBeChanged: 'Il tipo di codice a barre non può essere cambiato',
                barcodeHelpText12: "Inserisci le 12 cifre del codice a barre; l'ultima cifra è aggiunta automaticamente",
                barcodeHelpText11: "Inserisci le 11 cifre del codice a barre; l'ultima cifra è aggiunta automaticamente",
                barcodeHelpText7: "Inserisci le 7 cifre del codice a barre; l'ultima cifra è aggiunta automaticamente",
                beforeExpiry: 'prima della scadenza',
                cabinetDown: 'Il frigo non funziona',
                cabinetIsEmpty: 'Il frigo è vuoto',
                deviceOffline: 'Il dispositivo è offline',
                deviceOnline: 'Il dispositivo è online',
                cabinets: 'Frigoriferi',
                cabinet: 'Frigo',
                cabinetSettings: 'Impostazioni del frigo',
                cabinetTheming: 'Tematizzazione del frigo',
                cancel: 'Cancella',
                cancelledSales: 'Vendite cancellate',
                cancelledTransactions: 'Transazioni cancellate',
                categories: 'Categorie',
                change: 'Cambia',
                clear: 'Pulisci',
                close: 'Chiudi',
                clickEditButtonRegionalPrices: 'Per modificare i prezzi relativi ai luoghi, clicca sul pulsante "Modifica" del prodotto',
                clickEditLocationPrices: 'Prezzi locali; fare click sul prezzo del prodotto per espandere.',
                clickToExpandLocationPrices: 'Prezzi locali; fare click sul prezzo del prodotto per espandere.',
                closeDoor: 'Chiudere la porta',
                rebootDevice: 'Riavvia il dispositivo',
                commandSentSuccessFully: 'Comando inviato con successo',
                companyLogo: "Logo dell'azienda",
                confirm: 'Conferma',
                confirmDelete: 'Sei sicuro di voler eliminare il prodotto? Questa operazione non può essere annullata!',
                confirmDisable: 'Sei sicuro di voler archiviare il prodotto?',
                confirmEnable: 'Sei sicuro di voler attivare il prodotto?',
                consumerResponses: 'Feedback utente',
                controlPanelTitle: 'Pannello di controllo',
                controls_acceptablePercentageOfBadTags: 'Percentuale accettabile di tag non validi prima che il frigo passi in modalità fuori servizio',
                controls_markTagsGoodQuality: 'Marcare tutti I tag come di buona qualità',
                controls_markTagsGoodQuality_btn: 'Marcare I tag di buona qualità',
                controls_clearTagHistory: 'Cancella la cronologia dei tag',
                controls_clearRemoveReplenishmentData: 'Cancella la cronologia dei rifornimenti',
                controls_companyAndPaymentLogo: "Logo dell'azienda e di pagamento",
                controls_detectTagQuality: 'Rileva la qualità dei tag',
                controls_door: 'Controllo della porta',
                controls_device: 'Controllo del dispositivo',
                controls_chargeBadTags: 'Addebitare tag errati',
                controls_refill: 'Ricarica',
                controls_tagQuality: 'Impostare i parametri tag',
                controls_tagQualityData: 'Dati sulla qualità dei tag',
                controls_replenishmentData: 'Dati di rifornimento',
                controls_soldTags: 'Tag Venduti',
                controls_tagHistory: 'Cronologia dei tag',
                controls_markAllTagsReplenished: 'Marcare tutti i tag come riforniti',
                controls_markAllTagsUnsold: 'Marcare tutti i tag come non venduti',
                controls_markAlTagsReplenished_btn: 'Marcare i tag riforniti',
                controls_markAllTagsUnsold_btn: 'Marcare tutti i tag non venduti',
                controls_chargeSoldTags: 'Addebitare tag venduti',
                controls_chargeUnreplenishedTags: 'Addebitare tag non riforniti',
                controls_tagsWarn:
                    'La cancellazione della cronologia dei tag rimuove tutti I dati relativi a i tag. Fare click su “marcare tutti i tag riforniti dopo questa azione.',
                create: 'Crea',
                createAdTitle: 'Nuova pubblicità',
                createdAd: 'Pubblicità creata',
                createExpiryDiscountRule: 'Crea una regola di sconto per scadenza',
                createScheduledDiscountRule: 'Crea una regola di sconto programmato',
                createBundleDiscountRule: 'Crea regola di sconto',
                createNewVatCategory: 'Nuova categoria IVA',
                createProductTitle: 'Nuovo prodotto',
                creatingAd: 'Creazione pubblicità in corso',
                creatingProduct: 'Creazione prodotto in corso',
                createNewProductCategory: 'Crea una nuova categoria di prodotti',
                createNewSupplier: 'Aggiungi un nuovo fornitore',
                currency: 'Valuta',
                customQuery: 'Ricerca personalizzata',
                dashboard: 'Pannello di controllo',
                dateRange: 'Intervallo di date',
                dataAlredyFetchedTitle: 'Dati già recuperati',
                dataAlreadyFetchedDescription: 'Si prega di scegliere parametri diversi',
                day: 'Giorno',
                days: 'Giorni',
                deactivationDate: 'Data di disattivazione',
                default: 'Predefinito',
                delete: 'Elimina',
                deleteError: 'Errore: il prodotto non può essere eliminato',
                deleteSuccess: 'Prodotto eliminato con successo',
                description: 'Descrizione',
                deviceManagement: 'Gestione del dispositivo',
                digitwinOffline: 'Il dispositivo digitwin è offline',
                digitwinOnline: 'Il dispositivo digitwin è online',
                dimensions: 'Dimensioni',
                disable: 'Archivio',
                discountAmount: 'Totale sconto',
                discountPrice: 'Prezzo scontato',
                discountRuleAffects: 'La regola di sconto influisce',
                discountRuleName: 'Nome della regola di sconto',
                discountRuleStops: 'Intervalli di scadenza',
                discounts: 'Sconti',
                discountSalesData: 'Dati sulle vendite scontate',
                discountSalesAmount: 'Somma delle vendite scontate',
                discountTimeBeforeExpiryExists: 'L’intervallo di scadenza precedente esiste già',
                discountVsNormalSales: 'Sconto vs vendite normali',
                dismiss: 'Respingere',
                doesNotExist: 'Non esiste',
                doorCloseConfirm: 'Sei sicuro di voler chiudere la porta?',
                doorCloseSuccess: 'Chiudi la porta con successo',
                doorOpenConfirm: 'Sei sicuro di voler aprire la porta?',
                doorOpenReason: 'Perché stai aprendo la porta?',
                doorOpenSuccess: 'La porta è stata aperta',
                rebootDeviceSuccess: 'Il dispositivo è stato riavviato',
                deviceRebootConfirm: 'Sei sicuro di voler riavviare il dispositivo?',
                edit: 'Modifica',
                editCompanyLogo: "Modifica il logo dell'azienda",
                editDiscountRule: 'Modifica regola di sconto',
                editPaymentMethodLogo: 'Modifica il logo del metodo di pagamento',
                editVatCategoryModalTitle: 'Modifica la categoria IVA',
                editProductCategoryModalTitle: 'Modifica la categoria del prodotto',
                editSupplierModalTitle: 'Modifica fornitore',
                enable: 'Attiva',
                error_401: 'Non autenticato',
                error_403: 'Non autorizzato a eseguire comandi',
                error_500: 'Errore del server imprevisto',
                errorCreatingAd: 'Errore durante la creazione della pubblicità',
                errorCreatingProduct: 'Errore durante la creazione del prodotto',
                errorFetchingCabinetStatus: 'Errore durante il recupero dello stato del frigo',
                errorFetchingInventory: "Errore durante il recupero dell'inventario del frigo",
                errorFetchingReport: 'Errore durante il recupero dei report',
                errorFetchingSettings: 'Errore durante il recupero delle impostazioni',
                errorUpdatingProduct: "Errore durante l'aggiornamento del prodotto",
                expandToSeeBadTags: "Espandi l'elenco per visualizzare i tag rilevati come tag non validi. I tag non validi devono essere rimossi dal frigo.",
                expandToSeeSoldItems: "Espandi la lista per vedere i prodotti che sono già stati venduti, ma sono apparsi di nuovo nell'inventario",
                expandToSeeReplenishedOutOfInventory:
                    "Espandi l'elenco per vedere i prodotti che sono stati inseriti nel frigo durante il rifornimento dell’ inventario",
                expired: 'Scaduto',
                expiredCount: 'Conto scaduto',
                expiredProductsCount: 'Numero di prodotti scaduti',
                expiredSum: 'Somma scaduta',
                expiring: 'In scadenza',
                expiryDate: 'Ultimo giorno di vendita',
                exportExcel: 'Esporta Excel',
                fetchAdvertisements: 'Recupero di pubblicità in corso',
                fetchingCabinets: 'Recupero dei frigo in corso',
                fetchingCabinetsError: 'Si è verificato un errore durante il recupero dei frigo',
                fetchingCabinetStatus: 'Recupero dello stato del frigo in corso',
                fetchingError: 'Si è verificato un errore durante il recupero',
                fetchingInventory: "Recupero dell'inventario del frigo in corso",
                fetchingProducts: 'Recupero dei prodotti in corso',
                fetchingReport: 'Recupero report in corso',
                fetchingVatCategories: 'Recupero delle categorie IVA in corso',
                fetchingProductCategories: 'Recupero delle categorie di prodotti in corso',
                fillForm: 'Compila il modulo',
                freshFood: 'Questo è un prodotto in scadenza',
                freezerPackage: 'Prodotto congelato',
                generalFetchError: 'Recupero non riuscito',
                generalRequestReject: 'Errore nella richiesta',
                get: 'Ottieni',
                group: 'Gruppo',
                holdOn: 'Attendi',
                hour: 'Ora',
                hours: 'Ore',
                hoursBeforeExpiry: 'Ore prima della scxadenza',
                daysBeforeExpiry: 'Giorni prima della scadenza',
                image: 'Immagine',
                imageOrVideo: "Seleziona un'immagine o un video",
                inActive: 'Inattivo',
                individualCabinets: 'Frigo singoli',
                individualCabinetsOnly: 'Solo frigo singoli',
                invalidBarcodeOnlyDigits: 'Il codice a barre deve contenere solo cifre',
                invalidBarcodeTooLong: 'Il codice a barre è troppo lungo',
                invalidBarcodeTooShort: 'Il codice a barre è troppo corto',
                invalidCabinetNameTooShort: 'Il nome del frigo è troppo corto',
                invalidCabinetNameDuplicated: 'Il nome del frigo è già esistente',
                invalidLocationNameTooShort: 'Il nome del luogo è troppo corto',
                invalidLocationNameDuplicated: 'Il nome del luogo esiste già',
                invalidPercentage: 'Valore errato',
                invalidPrice: 'Prezzo non valido',
                invalidProductBarcode: 'Codice a barre del prodotto non valido',
                invalidTitle: 'Titolo non valido',
                invalidBundleItems: 'Il pacchetto contiene prodotti non validi',
                inventoryLength: 'Lunghezza inventario',
                inventoryNotReported: 'Il frigo non ha segnalato il suo inventario',
                inventoryTitle: 'Inventario del frigo',
                'Inventory History': "Cronologia dell'inventario",
                itemReplenishedOutOfInventoryProcess: 'Questo prodotto o tag è stato inserito nel frigo in un processo diverso da quello di rifornimento',
                itemSoldOn: 'Prodotto venduto su',
                'Last 30 days': 'Ultimi 30 giorni',
                'Last 7 days': 'Ultimi 7 giorni',
                'Last month': 'Ultimo mese',
                'Last quarter': 'Ultimo trimestre',
                'Last week': 'Ultima settimana',
                'Last year': 'Ultimo anno',
                lastUpdate: 'Ultimo aggiornamento',
                lifetime: 'Durata',
                location: 'Luogo',
                locations: 'Luoghi',
                logoEditor: 'Editor di logo',
                logoSettings: 'Impostazioni logo',
                loading: 'Caricamento in corso…',
                measure: 'Misura',
                measures: 'Misure',
                minsBeforeExpiry: 'Minuti prima della scadenza',
                minutes: 'minuti',
                month: 'Mese',
                name: 'Nome',
                newVatCategoryModalTitle: 'Nuova categoria IVA',
                newProductCategoryModalTitle: 'Nuova categoria di prodotti',
                newSupplierModalTitle: 'Nuovo fornitore',
                'no region': '(nessun luogo)',
                noAdvertisements: 'Nessuna pubblicità',
                noCabinets: 'Nessun frigo',
                noCabinetSelected: 'Nessun frigo selezionato',
                noLogo: 'Nessun logo',
                noData: 'Nessun dato',
                noDescription: 'Nessuna descrizione',
                noEvents: 'Nessun evento!',
                noIndividualCabinets: 'Nessun frigo individuale',
                noLocationPrices: 'Nessun prezzo locale è stato definito per questo prodotto',
                none: 'Nessuno',
                noPaymentMethodLogo: 'Nessun logo del metodo di pagamento',
                noProducts: 'Nessun prodotto',
                noProductsRemoved: 'Nessun prodotto rimosso',
                noProductTypeSelected: 'Nessun tipo di pacchetto selezionato',
                normalPrice: 'Prezzo normale',
                normalSalesAmount: 'Somma delle vendite normali',
                notAccessibleForYou:
                    "La funzione Crea/Aggiorna è abilitata solo per gli amministratori e gli operatori del frigo. Per ottenere l'accesso, contattare il proprio supervisore o amministratore di sistema.",
                notDefined: 'Non definito',
                noTitle: 'Nessun titolo',
                noVatCategories: 'Nessun record di categoria IVA trovato',
                noProductCategories: 'Nessun record di categorie di prodotti trovato',
                noInventoryOnSelectedDate: "Nessun inventario è stato riportato per la data selezionata. Riprova con un'altra data.",
                openDoor: 'Apri la porta',
                orderSum: "Somma dell'ordine",
                paymentMethodLogo: 'Logo del metodo di pagamento',
                percentage: 'Percentuale',
                permanent: 'Permanente',
                preview: 'Anteprima',
                price: 'Prezzo',
                print: 'Stampa',
                productCreated: 'Prodotto creato',
                products: 'Prodotti',
                productsFetchError: 'Si è verificato un errore durante il recupero dei prodotti',
                productsTitle: 'Catalogo dei prodotti',
                productType: 'Tipo di confezione',
                productUpdated: 'Prodotto aggiornato',
                quantity: 'Quantità',
                readerDown: 'Il lettore RFID è inattivo',
                region: 'Luogo',
                regions: 'Luoghi',
                removedAt: 'Rimosso in',
                removeImage: 'Rimuovi immagine',
                removeVideo: 'Rimuovi video',
                refillQRCodeInfo: "Scansiona il codice QR per aprire l'applicazione di ricarica o segui",
                refillQRCodeTitle: 'Ricarica il codice QR',
                refillRuleTitle: 'Regola di ricarica',
                reportsTitle: 'Report',
                requestSuccess: 'Modifiche eseguite con successo',
                requiredPercentage: 'Necessario. Valore percentuale senza segno di percentuale.',
                requiredText: 'Lunghezza richiesta dei caratteri: ',
                retireLocation: 'Ritira il luogo',
                rfidNoInfo: 'Nessuna informazione disponibile su antenne e lettore RFID',
                rfidOk: 'Il lettore e le antenne RFID funzionano come previsto.',
                sales: 'Vendite',
                salesCount: 'Conto vendite',
                salesVsWasteAmount: 'Vendite vs quantità respinta',
                save: 'Salva',
                search: 'Cerca',
                selectCabinet: 'Seleziona un armadio',
                selectDiscountRuleCabinets: 'Seleziona i frigo interessati dalla regola dello sconto',
                selectDiscountRuleLocations: 'Seleziona i luoghi interessati dalla regola di sconto',
                selectDiscountRuleProducts: 'Seleziona i prodotti interessati dalla regola di sconto',
                selectProductType: 'Si prega di selezionare il tipo di confezione per il tuo prodotto',
                setDiscountAmount: "Imposta l'importo dello sconto",
                setDiscountTimeBeforeExpiry: 'Imposta intervallo di scadenza',
                settingConfirmMessage: 'Sei sicuro di voler salvare queste impostazioni? Se non sei sicuro, non confermarlo',
                showDisable: 'Prodotti archiviati',
                summary: 'Riepilogo',
                sureRemoveDiscountRule: 'Sei sicuro di voler rimuovere la regola dello sconto?',
                tagIsBad: 'Il tag non è valido; deve essere rimosso dal frigo',
                tagsRemovedReport: 'Report sui tag rimossi',
                taxValue: 'Valore fiscale',
                'This month': 'Questo mese',
                'This quarter': 'Questo trimestre',
                'This week': 'Questa settimana',
                'This year': "Quest'anno",
                thisLink: ' questo link.',
                timeGrouping: 'Raggruppamento temporale',
                title: 'Titolo',
                Today: 'Oggi',
                topExpiredProducts: 'Principali prodotti scaduti',
                topProducts: 'Prodotti principali',
                total: 'Totale',
                totalValue: 'Valore totale',
                transactions: 'Transazioni',
                ttlInDays: 'Tempo di vita del prodotto (durata) in giorni',
                ttlsAffected: 'Prodotti con tempo di vita (durata) coinvolti',
                unexpectedError: 'Errore inaspettato',
                uniqueCustomers: 'Clienti unici',
                unknown: 'Sconosciuto',
                update: 'Aggiornamento',
                updateAdError: "Errore durante l'aggiornamento della pubblicità",
                updateAdTitle: 'Aggiorna pubblicità',
                updatedAd: 'Pubblicità aggiornata',
                updateCabinetSettings: 'Sincronizza i dati con il frigo',
                updateCabinetSettingsInfo: 'Sei sicuro di voler aggiornare le impostazioni del frigo?',
                updateData: 'Aggiorna i dati',
                updateProductTitle: 'Aggiorna prodotto',
                updatingAd: 'Aggiornamento della pubblicità in corso',
                updatingProduct: 'Aggiornamento del prodotto in corso',
                uploading: 'Caricamento in corso',
                uploadingImage: 'Caricamento immagine in corso',
                use: 'Uso',
                'w/o grouping': 'Nessun raggruppamento',
                waste: 'Spreco',
                vatCategoriesFetchError: 'Errore durante il recupero delle categorie IVA',
                vatCategoriesTitle: 'Categorie IVA',
                vatCategory: 'Categoria IVA',
                vatCategoryItems: 'Prodotti',
                vatCategoryName: 'Nome categoria IVA',
                vatCategoryPromptMessage: 'Sei sicuro di voler eliminare la categoria IVA',
                vatCategoryValue: 'Percentuale del valore fiscale',
                vatDeleteRejected: 'Eliminazione della categoria IVA rifiutata. Controlla i prodotti associati.',
                vatErrorRequesting: "Errore nell'elaborazione della richiesta",
                vatRequestRejected: 'Richiesta rifiutata',
                vats: 'IVA',
                productCategoriesFetchError: 'Errore durante il recupero delle categorie di prodotti',
                productCategoriesTitle: 'Categorie di prodotto',
                productCategory: 'Categoria di prodotto',
                productCategoryItems: 'Categorie',
                productCategoryName: 'Nome della categoria del prodotto',
                productCategoryPromptMessage: 'Sei sicuro di voler eliminare la categoria del prodotto?',
                productCategoryDescription: 'Descrizione della categoria del prodotto',
                productCategoryHelpTextDescription: 'Ad es. categoria per insalate',
                productCategoryHelpTextName: 'Ad es. insalate',
                productCategoryDeleteRejected: 'La rimozione della categoria di prodotto è stata respinta. Controlla i prodotti correlati!',
                week: 'Settimana',
                viewMode: 'Vista',
                year: 'Anno',
                Yesterday: 'Ieri',
                groupBy: 'Raggruppa per',
                filterBy: 'Filtra per',
                reportType: 'Tipo di report',
                custom: 'Personalizzato',
                preDefined: 'Predefinito',
                selected: 'Selezionato',
                metricsInfo: 'I valori (somma, massimo, minimo…) sono calcolati per tutte le dimensioni/risorse selezionate',
                dimensionsInfo: 'I valori saranno recuperati per le dimensioni/risorse selezionate',
                metrics: 'Valori',
                controlsSoftwareVersion: 'Versione del software',
                currentSoftwareVersion: 'Attuale versione del software',
                availableSoftwareVersion: 'Versione del software disponibile',
                invalidLifetime: 'Valore di durata non valido',
                unpaidSales: 'Vendite non pagate',
                unpaidTransactions: 'Transazioni non pagate',
                softwareUpdateConfirmation: 'Siete sicuri di voler aggiornare la versione del software per i dispositivi selezionati?',
                softwareUpdateWarn:
                    "L'aggiornamento della versione del software invierà un comando ai dispositivi per ottenere l'aggiornamento subito o all'ora selezionata. I dispositivi selezionati installeranno l'aggiornamento la prossima volta che saranno online dopo l'attivazione. L'installazione può richiedere del tempo a seconda della velocità della connessione disponibile. Dopo l'installazione i dispositivi si riavvieranno.",
                releaseVersion: 'Versioni rilasciate',
                releaseDate: 'Data di rilacio',
                latestRelease: 'Ultimo rilascio',
                currentVersion: 'Versione attuale',
                softwareUpdateSuccess: 'La versione del software è stata aggiornata',
                prebooking: 'Pre-prenotazione',
                prebookedProduct: 'Prodotto pre-prenotato',
                prebookedProducts: 'Prodotti pre-prenotati',
                activeProducts: 'Prodotti attivi',
                startMaintenanceMode: 'Avvia modalità di manutenzione',
                endMaintenanceMode: 'Termina modalità di manutenzione',
                startMaintenanceConfirm: 'Sei sicuro di voler avviare la modalità di manutenzione del frigo?',
                endMaintenanceConfirm: 'Sei sicuro di voler terminare la modalità di manutenzione del frigo?',
                startMaintenanceSuccess: 'È stata avviata la modalità di manutenzione del frigo.',
                endMaintenanceSuccess: 'È stata terminata la modalità di manutenzione del frigo.',
                noResults: 'Nessun risultato',
                suppliers: 'Fornitori',
                supplier: 'Fornitore',
                supplierDeleteRejected: 'Eliminazione fornitore rifiutata. Controlla i prodotti associati!',
                supplierPromptMessage: 'Sei sicuro di volere eliminare il fornitore?',
                fetchingSuppliers: 'Recupero fornitori in corso',
                noSuppliers: 'Nessun record fornitore trovato',
                suppliersFetchError: 'Errore nel recupero fornitori',
                supplierName: 'Nome del fornitore',
                supplierHelpTextName: 'Ad es. un nome di marchio',
                supplierDescription: 'Descrizione del fornitore',
                supplierHelpTextDescription: 'Ad es. una descrizione di marchio',
                wishes: 'Desideri',
                checkNow: 'Controlla ora',
                startTimeOn: 'Ora di inizio attiva',
                endTimeOn: 'Ora di fine attiva',
                signOutConfirmationModalTitle: 'Conferma di uscita',
                signOutConfirmationModalContent: 'Sei sicuro di voler uscire?',
                startDate: "Data d'inizio",
                endDate: 'Data di fine',
                scheduledDiscountApplyAllDays: 'Applicazione per tutti i giorni',
                scheduledDiscountApplySelectedDays: 'Applicazione per giorni selezionati',
                discountCustomImageTitle: 'Immagine dello sconto (facoltativa)',
                discountCustomImageModalHeader: 'Immagine per lo sconto',
                saveChanges: 'Salva le modifiche',
                updateTagsQualitySettingsTitle: 'Impostazioni di qualità dei tag',
                updateTagsQualitySettingsMessage: 'Impostazioni di qualità dei tag aggiornate',
                IF3Cabinets: 'Frigoriferi modello 3',
                IF2Cabinets: 'Frigoriferi modello 2',
                IF3Freezers: 'Congelatori modello 3',
                encodingStations: 'Stazioni di codifica',
                if2: 'Frigorifero modello 2',
                if3: 'Frigorifero modello 3',
                if3Freezer: 'Congelatore modello 3',
                legalNotice: 'Avviso legale',
                privacyPolicy: 'Politica sulla riservatezza',
                scheduledDiscountDateMissingError: 'Entrambe le date sono necessarie se è impostata la data di inizio o di fine',
                scheduledDiscountDuplicateTimeError: 'Gli orari non possono essere gli stessi se le date sono le stesse',
                now: 'Ora',
                minimum: 'Minimo',
                maximum: 'Massimo',
                discardChangesConfirmationText: 'Annullare le modifiche?',
                exports: 'Esportazioni',
                selectAllCabinets: 'Seleziona tutte le vetrinette',
                expiryIntervals: 'Intervalli di scadenza',
                timeBeforeExpiry: 'Tempo prima della scadenza',
                apply: 'Applica',
                yes: 'Sì',
                no: 'No',
                productOnScreenBannerText: 'Testo del banner informativo sullo schermo',
                productOnScreenBannerAdditionalInfo:
                    "Il testo del banner è un testo di una riga che appare sotto l'immagine del prodotto e illustra in maniera conciso le caratteristiche principali, i richiami sulla salute o qualunque informazione aggiuntiva sul prodotto",
                productOnScreenBannerExampleText: 'Senza lattosio',
                UI_IS_DOWN: 'Il display del mobile non funziona',
                READER_IS_DOWN: 'Il lettore RFID non è operativo',
                ANTENOR_DEVICE_DISCONNECTED: 'Il dispositivo di pagamento è disconnesso',
                EXPIRED_PRODUCT: 'Prodotti scaduti nel mobile',
                CLOUD_SERVICE_DOWN: 'Servizio cloud non disponibile',
                ANTENNA_DISCONNECTED: 'Antenna disconnessa',
                TAG_CHANGES: 'Troppe etichette errate nel mobile',
                ANTENNA_DOWN: "L'antenna RFID nel mobile non funziona",
                READER_CONFIG_ERROR: 'Configurazione del lettore RFID fallita e non è operativo',
                activeAlarms: 'Allarmi attivi',
                deviceStatus: 'Stato del dispositivo',
                lastMonitored: 'Ultima monitorata',
                noService: 'Fuori servizio',
                operational: 'Operativo',
                offline: 'Offline',
                locationsSelectHelp: 'Quando viene aggiunta una posizione, verranno aggiunte anche le sue posizioni figlie.',
                select: 'Selezionare...',
                labels: 'Etichette',
                productDetails: 'Dettagli del prodotto',
                invalidProductName: 'Il nome del prodotto deve essere lungo da 1 a 125 caratteri',
                standardPrice: 'Prezzo standard',
                locationPrices: 'Prezzi per località',
                addNew: 'Aggiungi nuovo',
                productExpiry: 'Scadenza del prodotto',
                productLifeTime: 'Durata del prodotto',
                invalidTTL: 'La durata del prodotto deve essere un numero intero da 0 a 32767',
                productImage: 'Immagine del prodotto',
                uploadImage: 'Carica immagine',
                pleaseUploadImage: "Per favore, carica un'immagine",
                productValidationError: 'Per favore, correggi i seguenti errori prima di salvare i dati del prodotto',
                productSupplier: 'Fornitore del prodotto',
                frozenProductLable: 'Questo è un prodotto congelato',
                prebookingLabel: 'Questo è un prodotto in prenotazione',
                previous: 'Precedente',
                bannerText: 'Testo del banner',
                packagingTypeValidationError: 'Per favore, seleziona un tipo di pacchetto per il prodotto',
                showOnlyFrozen: 'Mostra solo prodotti congelati',
                productClassification: 'Classificazione del prodotto',
                productClassLabel:
                    'Si prega di selezionare la classe appropriata per il prodotto. Questo consente a Selfly Insights di fornire informazioni più pertinenti sul prodotto.',
                productLabels: 'Etichette del prodotto',
                productLabelsHelpText:
                    'Si prega di aggiungere etichette opzionali al prodotto. Questo ti permetterà di filtrare i tuoi prodotti nel catalogo dei prodotti.',
                newLabel: 'Nuova etichetta',
                productClassValidationError: 'Seleziona una classificazione per il tuo prodotto tra le opzioni sottostanti',
                tagSensitivity: 'Sensibilità della qualità dell’etichetta',
                tagSensitivitySetting: 'Impostazione della sensibilità della qualità dell’etichetta',
                configTagSensitivity: 'Configurare la sensibilità della qualità dell’etichetta per il prodotto',
                productLevelSensitivityInfo:
                    "La leggibilità di un’etichetta RFID applicata a un prodotto può essere influenzata da vari fattori, inclusi materiali di imballaggio, superfici metalliche, umidità e persino il contenuto del prodotto stesso. Questi fattori possono talvolta causare segnali intermittenti della tag, noti come sfarfallio. L'armadio elabora tale sfarfallio in base alle sue impostazioni di sensibilità. Se non sei sicuro di quale livello di sensibilità sia appropriato per questo prodotto, puoi saltare questa sezione.",
                errorSaving: 'Errore durante il salvataggio dei dati del prodotto',
                successSaving: 'Dati del prodotto salvati con successo',
                basicDetail: 'Dettagli di base',
                additionalDetail: 'Dettagli aggiuntivi',
                packaging: 'Tipo di pacchetto',
                classification: 'Classificazione',
                selectOrtype: 'Seleziona o digita...',
                max25Chars: 'massimo 25 caratteri',
                noVatForProductCreation: 'Per favore, crea una categoria IVA prima di aggiungere nuovi prodotti',
                lifeTimeExplanation: 'Quanti giorni questo prodotto è valido per la vendita. (0 giorni) significa fino alla fine della giornata corrente',
                selflyEncode: 'Selfly Encode',
                userRestrict: 'Solo consumatori riconosciuti',
                userRestrictTitle: 'Consumatori idonei',
                userNotRestrict: 'Tutti i consumatori',
                userRestrictHelperText:
                    "L'opzione consumatori riconosciuti significa che lo sconto sarà applicato solo ai consumatori già registrati per l'idoneità allo sconto!",
                searchCabinets: 'Cerca armadietti',
                selectAll: 'Seleziona tutto',
                monday: 'Lunedì',
                tuesday: 'Martedì',
                wednesday: 'Mercoledì',
                thursday: 'Giovedì',
                friday: 'Venerdì',
                saturday: 'Sabato',
                sunday: 'Domenica',
                fetchingLoader: 'Caricamento. Attendere prego...',
                category: 'Categoria',
                okay: 'Ok',
                noLocation: 'Nessuna posizione',
                copy: 'Copia',
                pleaseConfirm: 'Conferma, per favore',
                saveQuery: 'Salva questa query',
                saveQueryPlaceholder: 'es. "Numero totale di articoli venduti"',
                saveQuerySuccess: 'Query salvata.',
                yourSavedQueries: 'Le tue query salvate',
                selectQuery: 'Seleziona query',
                noQuery: 'Non ci sono ancora query salvate',
                deleteAdConfirm: 'Sei sicuro di voler eliminare questo annuncio?',
                dataSaved: 'Dati salvati con successo',
            },
            refill: {
                refillRuleApplyRuleDropdownText: 'Applica questa regola anche ad altre vetrinette',
                refillPlanGenerate: 'Genera piano di ricarica',
                refillPlanEditPlan: 'Modifica piano',
                refillAmount: 'Importo ricarica',
                refillRuleTargetAmount: 'Livello di inventario target',
                refillRuleTargetAmountShort: 'Target',
                refillPlanActivePlans: 'Piani attivi',
                refillPlanCompletedPlans: 'Piani completati',
                refillNote: 'Nota di ricarica',
                refillNoteHelp: 'Nota di ricarica del prodotto (massimo 125 caratteri)',
                refillPDFText:
                    "Di seguito è riportato il piano di ricarica per l'armadio. Assicurati di contrassegnare il piano di ricarica come completato nell'applicazione di ricarica dopo aver completato la ricarica.",
                refillPDFSummaryText:
                    'Di seguito è riportato il riepilogo dei piani di ricarica con i prodotti da aggiungere e rimuovere per tutti gli armadi selezionati.',
                refillSummary: 'Sommario di ricarica',
                addItems: 'Aggiungi prodotti',
                removeItems: 'Rimuovi prodotti',
                inventoryChange: 'Modifica inventario',
                planogramPDFText: 'Planogramma per la strategia di rifornimento del cabinet.',
                planogramImagePDFText: 'Immagine personalizzata per la strategia di rifornimento del cabinet.',
                refillSchemas: 'Modelli di rifornimento',
                refillPlans: 'Piani di rifornimento',
                refillSchemaTitle:
                    'Imposta gli importi target e minimi di inventario come modello per i cabinet selezionati. Questi modelli saranno utilizzati per generare i Piani di rifornimento',
                refillPlansTitle:
                    'Visualizza tutti i piani attivi. Puoi modificare o rimuovere i piani attivi esistenti, o aggiungere nuovi piani di rifornimento se necessario.',
                refillSchemaSubText: 'Seleziona un cabinet per creare o aggiornare il modello di rifornimento',
                noRefillSchema: 'Nessun modello di rifornimento è stato ancora definito per questo cabinet',
                createRefillSchema: 'Crea un nuovo modello di rifornimento',
                noDevice: 'Nessun dispositivo trovato',
                schemaSaved: 'Modello di rifornimento salvato con successo',
                deleteSchemaConfirmation: 'Sei sicuro di voler eliminare il modello di rifornimento?',
                targetAmount: 'Importo target',
                targetAmountHelpText: 'La quantità target di inventario per ciascun prodotto nel cabinet dopo il rifornimento.',
                minimumThreshold: 'Soglia minima',
                minimumThresholdHelpText:
                    'Specifica il livello minimo di inventario per ciascun prodotto nel cabinet. Se la quantità scende al di sotto della soglia definita, riceverai una notifica di allerta. Impostando la soglia a 0 disabiliterai le notifiche.',
                editNote: 'Modifica nota',
                refillNoteTitle: 'Scrivi una nota di rifornimento per il prodotto.',
                refillNoteCharacters: 'caratteri',
                noRefillPlan:
                    'Attualmente non ci sono piani di rifornimento attivi. Aggiungi nuovi piani di rifornimento per i tuoi cabinet o crea prima un modello di rifornimento per i cabinet.',
                planSaved: 'Piano di rifornimento salvato con successo',
                deleteConfirmation: 'Sei sicuro di voler eliminare i dati del piano di rifornimento selezionato?',
                editPlan: 'Modifica piano',
                currentInventory: 'Inventario attuale',
                lastSevenDaySales: 'Vendite degli ultimi sette giorni',
                inventory: 'Inventario',
                planogram: 'Piano del piano',
                copyRefillSchema: 'Copia modello in altri cabinet',
                generatingPlans: 'Generazione dei piani di rifornimento. Attendere prego...',
                generatePlans: 'Genera piani di rifornimento',
                complete: 'completo',
                selectCabinetsToAddPlans: 'Seleziona i cabinet per generare i piani di rifornimento.',
                reviewSuggestion:
                    'Di seguito sono riportati i piani di rifornimento suggeriti per i cabinet selezionati. Si prega di rivedere, modificare se necessario e salvarli per attivare questi piani.',
                noSchema:
                    'Nessun cabinet ha attualmente un modello di rifornimento. Si prega di aggiungere un modello di rifornimento ai propri cabinet per generare piani di rifornimento.',
                searchProducts: 'Cerca prodotti',
                maxAllowedInventory: "L'inventario massimo consentito è 200.",
                savingData: 'Salvataggio dei dati. Attendere prego',
                errorSavingData: 'Errore nel salvataggio dei dati.',
                refillPlanSaved: 'Piani di rifornimento generati con successo.',
                exportPDFHelp: 'Esporta i piani selezionati in file PDF.',
                exportExcelHelp: 'Esporta i piani selezionati in file Excel.',
                addPlanHelp: 'Aggiungi nuovi piani di rifornimento.',
                deletePlanHelp: 'Elimina i piani di rifornimento selezionati.',
                generatePlansHelp: 'Genera piani di rifornimento per i cabinet selezionati.',
                planogramSaved: 'Piano del piano salvato con successo.',
                schemaCopied: 'Il modello di rifornimento è stato sincronizzato con successo con gli altri cabinet.',
                allProducts: 'Tutti i prodotti',
                schemaProducts: 'Prodotti del modello di rifornimento',
                deletePlanogram: 'Sei sicuro di voler eliminare il planogramma?',
                dragInPlanogram: 'Trascina e rilascia i prodotti sugli scaffali designati del cabinet, o trascina lo slot del cabinet sopra il prodotto.',
                noPlanogramInSchema: 'Non è stato ancora definito un planogramma per il cabinet',
                noPlanogramInPlan:
                    'Nessun dato del planogramma è disponibile per il cabinet. Si prega di creare prima un planogramma per il cabinet nella scheda del modello di rifornimento',
                copyPlanogram: 'Copia il planogramma di rifornimento insieme al modello di rifornimento',
                planogramImageInfo: "Oltre al planogramma, puoi anche aggiungere un'immagine personalizzata del planogramma.",
                addPlanogramImage: 'Aggiungi immagine del planogramma',
                editPlanogramImage: 'Modifica immagine del planogramma',
                numberOfShelves: 'Numero di scaffali',
                addNewRefillPlan: 'Aggiungi nuovi piani',
                qrcodeTitle: 'Un codice QR sarà generato per il cabinet selezionato per avviare il processo di rifornimento',
                qrcodeSubText: 'Seleziona un cabinet dove desideri avviare il processo di rifornimento',
                qrcodeScan: "Scansiona il codice QR per rifornire, oppure fai clic sul pulsante per aprire l'applicazione di rifornimento",
                qrcodeOpenApplication: "Apri l'applicazione di rifornimento",
                createRefillPlanogram: 'Crea planogramma di rifornimento',
            },
            navigation: {
                advertisements: 'Pubblicità',
                analytics: 'Analitica',
                controlPanel: 'Pannello di controllo',
                deviceManagement: 'Gestione del dispositivo',
                discounts: 'Sconti',
                fetching: 'Recupero',
                group: 'Gruppo',
                inventory: 'Inventario del frigo',
                language: 'Lingua',
                productCatalog: 'Catalogo dei prodotti',
                productCategories: 'Categorie di prodotto',
                reports: 'Report',
                settings: 'Impostazioni utente',
                signOut: 'Disconnessione',
                title: 'Portale cloud Selfly',
                vatCategories: 'Categorie IVA',
                community: 'Selfly Community',
                refillManagement: 'Gestione rifornimenti',
                automations: 'Automazioni',
                mySelfly: 'Impostazioni MySelfly',
                automationInfo: 'Gestisci azioni automatizzate (BETA)',
            },
            reports: {
                alreadyRefund: 'è stato rimborsato',
                amount: 'Quantità',
                average: 'Media',
                barcode: 'Codice a barre',
                confirmIssueRefundButton: 'Conferma il rimborso ora',
                current: 'Attuale',
                export: 'Esporta',
                fetching: 'Recupero',
                fetchingError: 'Si è verificato un errore durante il recupero',
                image: 'Immagine',
                issueRefundButton: 'R I M B O R S O',
                maximum: 'Massimo',
                minimum: 'Minimo',
                name: 'Nome',
                noData: 'Nessun dato',
                note: 'Nota',
                order: 'Transazione',
                paymentWasDoneByCreditCard: 'Il pagamento è stato effettuato con carta di credito',
                price: 'Prezzo',
                ProductReport: 'Report sul prodotto',
                quantity: 'Quantità',
                "Refund impossible'":
                    "Il pagamento è stato effettuato con carta di credito. Contattare l'emittente finale della carta di credito per l'operazione di rimborso.",
                refundFormTitle: 'Rimborso',
                refundReason: 'Motivo del rimborso',
                refundSuccess: 'La somma {{sum}} è stata rimborsata correttamente',
                refundTotal: 'Importo del rimborso',
                replenishments: 'Ricariche',
                replenishmentsTitle: 'Report di ricarica',
                SaleTransaction: 'Transazione di vendita',
                summary: 'Sommario',
                SummaryReport: 'Sommario delle vendite',
                temperature: 'Temperatura',
                temperatureTitle: 'Report sulla temperatura',
                temperatureValueNotification: 'I valori di temperatura sono i valori medi orari della temperatura del frigo.',
                time: 'Ora',
                total: 'Totale',
                transactions: 'Transazioni',
                user: 'Utente',
                paidTransactions: 'Transazioni pagate',
                unpaidTransactions: 'Transazioni non pagate',
                partiallyPaid: 'Parzialmente pagato',
                failedTransactions: 'Transazioni fallite',
                amountOwed: 'Importo dovuto',
                receiptLinkText: 'Prendi la ricevuta da qui',
                noPurchase: 'Nessun acquisto',
                other: 'Altra',
                userId: 'ID utente:',
            },
            settings: {
                block_cabinet_when_product_expired: 'Frigo bloccato per prodotti scaduti',
                criticalExpiryLimitHours: "Ore prima dell'avviso di scadenza critica",
                language: 'Lingua',
                logo: 'Logo della società',
                mediumExpiryLimitHours: "Ore prima dell'avviso di scadenza media",
                payment_method_logo: 'Logo del metodo di pagamento',
                footer_instruction_logo: 'Immagine delle istruzioni a piè di pagina',
                readUserMemory: 'Recupera la data di scadenza del prodotto',
                ui_skus_sort_by: 'Ordine dei tipi di prodotti sul display del frigo',
                timeZone: 'Fuso orario',
                country: 'Paese',
                city: 'Città',
                tagSettings: 'Impostazioni etichette',
                themeAndLogos: 'Tema e loghi',
                advancedSettings: 'Impostazioni avanzate',
                themingInfoText: 'Puoi personalizzare il tema dello schermo della vetrinetta',
                cabinetLogos: 'Loghi della vetrinetta',
                cabinetLogoInfoText: 'Carica i loghi che appaiono sullo schermo della vetrinetta',
                logoHelperText: "Utilizza un'immagine di 180×180 pixel",
                settingsCopyInfoHeader: 'Seleziona le vetrinette in cui copiare le impostazioni',
                settingsCopyButton: 'Copia le impostazioni nelle vetrinette selezionate',
                settingsCopySuccessModalTitle: 'Copia delle impostazioni',
                settingsCopySuccessModalContent: 'Impostazioni copiate correttamente in {{cabinetsAmount}} vetrinette',
                theming: 'Temi',
                adminSettings: 'Impostazioni amministratore',
                settingsCopyErrorModalTitle: 'Errore nella copia delle impostazioni',
                key: 'Impostazione',
                value: 'Valore',
                sleepScheduling: 'Standby programmato',
                sensitivityLevel: 'Livello di sensibilità',
                sensitivity_0:
                    "L'armadio ignora il lampeggiamento dell'etichetta e segna costantemente tutte le etichette come valide, il che può occasionalmente portare a errori di addebito.",
                sensitivity_20: "L'armadio tollera fino a tre letture errate per un'etichetta in 50 scansioni e segna comunque l'etichetta come valida.",
                sensitivity_40: "L'armadio tollera fino a due letture errate per un'etichetta in 60 scansioni e segna comunque l'etichetta come valida.",
                sensitivity_60:
                    "Se un'etichetta lampeggia frequentemente ma rimane stabile per un periodo prolungato, l'armadio lo tollera e legge l'etichetta come valida.",
                sensitivity_80: "Se un'etichetta lampeggia brevemente ma rimane stabile per un periodo prolungato, verrà considerata come valida.",
                sensitivity_100:
                    "L'armadio è altamente intollerante al lampeggiamento dell'etichetta. Anche una sola lettura errata può far sì che un'etichetta venga segnata come non valida per un periodo prolungato, il che può comportare potenziali perdite.",
                sensitivityInfo:
                    "La sensibilità alla qualità delle etichette di un armadio si riferisce a quanto efficacemente l'armadio gestisce letture incoerenti delle etichette o il lampeggiamento. Si prega di fare attenzione al livello di sensibilità scelto, poiché può influenzare le prestazioni dell'armadio. Se non si è sicuri di quale livello sia appropriato, si consiglia di lasciare l'impostazione al livello predefinito di 3.",
            },
            signIn: {
                signInButton: 'Accedi',
                signInExpiredError: 'La tua sessione è scaduta. Accedi di nuovo',
                signInMessage: 'Devi essere registrato per continuare. Fare clic sul pulsante in basso per accedere',
                noOrganizationsError: 'Nessuna organizzazione collegata a questo nome utente. Provare con un altro nome utente.',
            },
            translations: {
                add: 'Aggiungi',
                closed: 'Chiuso',
                delete: 'Elimina',
                device: 'Dispositivo',
                edit: 'Modifica',
                off: 'OFF',
                offline: 'Offline',
                on: 'ON',
                online: 'Online',
                opened: 'Aperto',
                save: 'Salva',
                seeDetails: 'Dettagli',
                settings: 'Impostazioni',
                unknown: 'Sconosciuto',
            },
            consumerApp: {
                enableConsumerFeedback: 'Abilita il feedback degli utenti su MySelfly',
                enablePurchaseReceipts: 'Abilita le ricevute elettroniche su MySelfly',
                enableSelflyTrademarkForReceipts: 'Consenti il piè di pagina di Selfly Store nelle ricevute elettroniche',
                enableSelflyTrademarkForReceiptsHelpText:
                    'In questo modo nei PDF delle ricevute elettroniche saranno aggiunti il logo e i dati di Selfly Store!',
                organizationFormHelpText: 'Indica i dati dell’emittente della ricevuta elettronica.',
                organizationFormValidationHelpText:
                    "* I campi obbligatori per la ricevuta elettronica sono il nome dell'organizzazione, l’ID aziendale, l’indirizzo e il paese.",
                organizationName: 'Nome organizzazione',
                organizationLogo: 'Logo organizzazione',
                businessId: 'ID aziendale',
                city: 'Città',
                email: 'E-mail',
                website: 'Sito web',
                postalCode: 'Codice postale',
                street: 'Indirizzo',
                phoneNumber: 'Numero di telefono',
                addressHeader: '* Dati dell’indirizzo',
                contactDetails: 'Dati di contatto',
                organization: '* Organizzazione dell’emittente',
                organizationSettingsUpdatedTitle: 'Aggiorna le impostazioni dell’organizzazione',
                organizationSettingsUpdatedContent: 'Impostazioni dell’organizzazione aggiornate',
                organizationSettingsMissingFields: 'Campi obbligatori per la ricevuta elettronica mancanti. Compilarli prima di salvare.',
                organizationSettingsMissingFieldsTitle: 'Errore di convalida modulo',
            },
            softwareVersions: {
                updateNotificationTitle: 'Aggiornamento della versione del software',
                updateNotificationContent: 'Versioni del software aggiornate correttamente',
                selectAVersionPlaceholder: 'Seleziona una versione',
                searchDevices: 'Cerca dispositivi',
                updateSelected: 'Aggiorna selezionato',
                softwareVersions: 'Versioni software',
                softwareVersion: 'Versione software',
                notSupportedDeviceNote: 'La versione {{selectedVersion.version}} non è supportata su questo dispositivo',
                userNotificationContent:
                    'È stata rilasciata una nuova versione del software per i tuoi dispositivi. Aggiorna i tuoi dispositivi per ottenere le funzionalità più recenti.',
                userNotificationTitle: 'Rilasciata una nuova versione del software',
                notificationBell_newSoftwareVersions: 'Aggiornamenti disponibili per i tuoi dispositivi',
                manageVersions: 'Gestisci le versioni del software del dispositivo',
                filterLocationsPlaceholder: 'Filtra per località',
                allVersionNumbers: 'Tutte le versioni',
                noVersionsForUpdating: 'Nessuna versione per aggiornamento',
                scheduledUpdateNote: "La versione {{version}} è programmata per l'aggiornamento alle {{scheduled}}",
                scheduleRemoveNotificationTitle: 'Pianificazione della versione del software',
                scheduleRemoveNotificationContent: "L'aggiornamento pianificato della versione del software è stato rimosso correttamente",
                scheduleRemoveNotificationModalContent: "Siete sicuri di voler eliminare l'aggiornamento pianificato per questo dispositivo?",
            },
            header: {
                searchByOrganization: 'Cerca per organizzazione',
                pageLanguage: 'Lingua',
                configureMySelflyAndEreceipts: 'Configura le impostazioni di MySelfly',
                remindMeLater: 'Ricordamelo più tardi',
                updateNow: 'Aggiorna ora',
            },
            notifications: {
                noNewNotifications: 'Nessuna nuova notifica',
            },
            cabinetSettings: {
                cabinetTheme: 'Tema del frigo',
                editAndSaveTheme: 'Modifica e salva il tema',
                colorPicker: 'Selezionatore del colore',
                customTheme: 'Tema personalizzato',
                themeSettings: 'Impostazioni tema',
                primary: 'Primario',
                negative: 'Negativo',
                attention: 'Attenzione',
                positive: 'Positivo',
                background: 'Sfondo',
                dark: 'Buio',
                light: 'Leggero',
                white: 'Bianco',
                default: 'Predefinito',
                negativeLight: 'Leggero in negativo',
                primaryColor: 'Colore primario',
                positiveColors: 'Colori in positivo',
                negativeColors: 'Colori in negativo',
                secondaryColors: 'Colori secondari',
                attentionColors: 'Colori di attenzione',
                screenBackground: 'Sfondo dello schermo',
                borderColors: 'Bordi - Ombre',
                shadow: 'Ombra',
                border: 'Bordo',
                fontColors: 'Colori dei caratteri',
                footerTextPlaceholder: 'Testo del piè di pagina',
                bundleDiscounts: 'Combina e salva',
                expiringText: 'Prodotti in scadenza. Controlla la data di scadenza prima dell’acquisto.',
                expiredText: 'Prodotti scaduti. Controlla la data di scadenza prima dell’acquisto.',
                cabinetName: 'Nome del frigo',
                productName: 'Nome del prodotto',
                confirmationModalHeader: 'Sei sicuro di voler salvare il tema personalizzato?',
                confirmationModalContent:
                    "La personalizzazione del tema influenzerà l'aspetto visivo dello schermo del tuo frigo. Assicurati che la qualità visiva dello schermo non sia compromessa dalle modifiche.",
                defaultRevertModalMessage: 'Sei sicuro di voler ripotare il tema del frigo a quello predefinito?',
                backgroundColor: 'Colore di sfondo',
                backgroundImage: 'Immagine di sfondo',
                footerBackground: 'Sfondo del piè di pagina',
                cardsBackground: 'Sfondo della carta',
                screenImage: "Immagine per lo sfondo dello schermo dell'armadio",
                uploadImage: 'Carica immagine',
                editImage: 'Modifica immagine',
                transactionComplete: 'Transazione completata',
                customPostPurchaseImage: "Si prega di caricare un'immagine per lo schermo personalizzato post acquisto",
                postPurchaseScreen: 'Schermo post acquisto',
                custom: 'personalizzato',
                postPurchaseText: 'Testo di visualizzazione post acquisto',
            },
            automations: {
                transactionReports: 'Rapporti sulle transazioni',
                refillReports: 'Rapporti di riempimento',
                notifications: 'Notifiche',
                refillPlan: 'Piani di riempimento',
                days: 'Giorni',
                months: 'Mesi',
                weeks: 'Settimane',
                configuration: 'Configurazione',
                transactionReportTitle:
                    'I rapporti sulle transazioni per i mobili selezionati verranno generati automaticamente e inviati agli indirizzi email forniti qui sotto',
                refillReportTitle:
                    'I rapporti di riempimento per i mobili selezionati verranno generati automaticamente e inviati agli indirizzi email forniti qui sotto',
                noSalesNotificationTitle:
                    'Le notifiche verranno inviate automaticamente agli indirizzi email forniti qui sotto in caso di nessuna vendita nei periodi definiti',
                frequencyTitle: 'Con quale frequenza desideri che venga generato il rapporto?',
                emailNotificationTitle: 'Si prega di aggiungere gli indirizzi email qui sotto a cui desideri che il rapporto o la notifica venga inviato',
                refillPlanGenerationTitle: 'I piani di riempimento verranno generati automaticamente per i mobili selezionati secondo il programma fornito',
                addNewEmail: 'Aggiungi nuova email',
                noSalesBanner: 'Riceverai una notifica email se i mobili selezionati non effettuano vendite durante il periodo di tempo configurato',
                noSaleTitle: 'Si prega di specificare la durata (in ore) di nessuna vendita nei mobili selezionati prima che venga inviata una notifica',
                minMaxTime: 'Min è 2 e max è 23 ore',
                timeRangeTitle:
                    "Si prega di specificare l'intervallo di tempo per i mobili, durante il quale verrà controllata la situazione di nessuna vendita",
                startTime: 'Ora di inizio',
                endTime: 'Ora di fine',
                refillPlanTitle:
                    "Il piano di riempimento per i mobili selezionati verrà generato automaticamente. Si prega di programmare qui sotto l'ora specifica in cui desideri che venga creato il piano di riempimento.",
                refillPlanTime: "Si prega di selezionare l'ora per la generazione del piano di riempimento",
                everyDay: 'Ogni giorno',
                everyWeek: 'Ogni settimana',
                everyMonth: 'Ogni mese',
                selectDays: 'Seleziona giorni',
                automationSaved: 'Impostazioni di automazione salvate con successo',
                automationDeleted: 'Impostazioni di automazione eliminate con successo',
            },
            sleepScheduling: {
                timeRangeTitle: "Si prega di specificare l'intervallo di tempo durante il quale questo mobile entrerà in modalità standby.",
                startTime: 'Ora di inizio',
                endTime: 'Ora di fine',
                selectDays: 'Seleziona giorni',
                duration: 'Durata (Ore)',
            },
        },
        hu: {
            dateRangeFilter: {
                lastMonth: 'utolsó 30 nap',
                lastTwoWeek: 'utolsó 14 nap',
                lastWeek: 'utolsó 7 nap',
                selectDateRangeMsg: 'Kiválasztott dátumtartomány',
                today: 'ma',
                yesterday: 'tegnap',
            },
            main: {
                activateSuccess: 'Termék sikeresen aktiválva',
                activationDate: 'Aktiválás dátuma',
                active: 'Aktív',
                addAdvertisement: 'Új hirdetés hozzáadása',
                addDiscountRule: 'Kedvezményszabály hozzáadása',
                addDiscountStop: 'Lejárati időköz hozzáadása',
                addNewLocation: 'Válasszon új helyszínt',
                addProduct: 'Új termék hozzáadása',
                addRegion: 'Helyszín hozzáadása',
                addRegionPrice: 'Helyszín árazás hozzáadása',
                addScheduledDiscount: 'Ütemezett kedvezmény hozzáadása',
                addBundleDiscount: 'Csomagkedvezmény hozzáadása',
                bundledDiscounts: 'Csomag kedvezmények',
                bundleItemsLengthExceeded: 'Túllépte a termékek maximális választékát (3). A folytatáshoz távolítson el néhány kijelölést.',
                bundleMaxProductLimitInfo: 'Válasszon legfeljebb három (3) terméket vagy termékkategóriát',
                bundleFixedAmountExceededOriginal: 'A csomag fix ára meghaladja az eredeti árösszeget. Ellenőrizze a kiválasztott termékeket.',
                bundleFixedAmountNotAllowedToBeZero: 'A csomag fix árának 0 felett kell lennie',
                bundlePercentageNotAllowedToBeFull: 'A csomag százalékos kedvezményének 100 alatt kell lennie',
                bundlePercentageNotAllowedToBeZero: 'A csomag százalékos kedvezményének 100 felett kell lennie',
                bundleItemDiscountPriceAmountExceededOriginal: 'Az új akciós ár meghaladja az eredeti árat. Ellenőrizze újra az új árat.',
                bundleItemPriceMessage: 'Minden árképzési szabály csak 1 cikkre vonatkozik, még akkor is, ha ugyanabból a cikkből több van kiválasztva',
                bundleItemFixedAmountExceededOriginal: 'A termék fix ára meghaladja a termék eredeti árát. Ellenőrizze újból a fix árat.',
                createDiscountRule: 'Kedvezményszabály létrehozása',
                deviceType: 'Eszköz típusa',
                discountUpdateTitle: 'Kedvezmény frissítés',
                discountCreateTitle: 'Kedvezmény létrehozása',
                discountDeleteTitle: 'Kedvezmény eltávolítása',
                discountDeleteContent: 'Kedvezmény eltávolítva',
                discountStatusUpdated: 'Kedvezmény állapota frissült',
                discountUpdated: 'Kedvezmény frissítve',
                discountCreated: 'Kedvezmény létrejött',
                discountSettings: 'Kedvezmény beállítások',
                discountImage: 'Kedvezmény opcionális képe',
                editDiscountStop: 'Lejárati időköz szerkesztése',
                editBundleConfiguration: 'Kötegkonfiguráció szerkesztése',
                editExpiryConfiguration: 'Lejárati kedvezmény konfigurációjának szerkesztése ',
                encodingStation: 'Kódolóállomás',
                everyDay: 'Minden nap',
                scheduledDiscountDateInfoLabel: 'Kérjük, hagyja üresen az indítási és befejezési dátumot, ha azt szeretné, hogy a kedvezmény tartós legyen',
                starts: 'Indul',
                ends: 'Befejeződik',
                error: 'Hiba',
                errorRetiringLocationMsg:
                    'Nem sikerült visszavonni a helyet. Kérjük, először távolítsa el a kapcsolódó eszközöket, termékárakat és hirdetéseket.',
                fetchingDiscountRules: 'Kedvezményszabályok lekérése',
                free: 'Díjmentes',
                missingImage: 'Hiányzik a hirdetés képe',
                next: 'Következő',
                back: 'Vissza',
                noDiscountRules: 'Nem található kedvezményszabály',
                scheduledDiscountsAutomaticDeactivationInfo:
                    'A dátumtartomány túllépése esetén a kedvezmények automatikusan deaktiválódnak. Ha aktívvá szeretné tenni őket, módosítsa a dátumtartományt érvényesnek.',
                scheduledDiscounts: 'Ütemezett kedvezmények',
                schedulingStartHourIsOverEndHour: 'A kezdési időpont nem lehet a befejezés időpontja után',
                schedulingEndHourIsUnderStartHour: 'A befejezés időpontja nem lehet a kezdési időpont előtt',
                selectDiscountRuleProductCategories: 'Válassza ki a kedvezményszabály által érintett termékkategóriákat',
                selectBundleProductsAmount: 'Válassza ki az összeget ',
                selectedBundleProductCategories: 'Kiválasztott termékkategóriák',
                setDiscountDays: 'Állítsa be a kedvezményes napokat',
                setBundleDiscount: 'Állítson be százalékos kedvezményt vagy rögzített árat a csomaghoz',
                setBundleItemDiscount: 'Állítson be százalékos kedvezmény összeget vagy fix árat egy cikkhez',
                setBundleProducts: 'Termékek vagy kategóriák beállítása a köteghez',
                setAmountMoreThanOne: 'Egy tételes köteg esetén adjon meg egynél többet',
                setBundleDiscountType: 'Állítsa be a kedvezmény típusát és összegét',
                sureActivateDiscountRule: 'Biztosan aktiválja a kedvezményszabályt?',
                sureDeactivateDiscountRule: 'Biztosan kikapcsolja a kedvezményszabályt?',
                sureDeleteDiscountRule: 'Biztosan törölni szeretné a kedvezményszabályt?',
                timeRange: 'Időtartomány',
                editExpiryDiscountRule: 'Kedvezményszabály szerkesztése',
                editScheduledDiscountRule: 'Ütemezett kedvezményszabály szerkesztése',
                editBundleDiscountRule: 'Csoportos kedvezményszabály szerkesztése',
                releaseNotes: 'Kiadási megjegyzések',
                seeMore: 'Továbbiak megtekintése',
                scheduledDiscountTimeRangeLabel: 'Aktív idő',
                scheduledDiscountDateRangeLabel: 'Kedvezmény dátumtartománya',
                weekDayAbrvMonday: 'H',
                weekDayAbrvTuesday: 'K',
                weekDayAbrvWednesday: 'Sze',
                weekDayAbrvThursday: 'Cs',
                weekDayAbrvFriday: 'P',
                weekDayAbrvSaturday: 'Sz',
                weekDayAbrvSunday: 'V',
                recurrence: 'Ismétlődés',
                addScheduledDiscountStop: 'Ütemezett kedvezmény hozzáadása',
                editScheduledDiscountStop: 'Ütemezett kedvezmény szerkesztése',
                pieces: 'db',
                expiryDiscountNavLabel: 'Lejárat',
                scheduledDiscountNavLabel: 'Ütemezett',
                bundleDiscountNavLabel: 'Köteg',
                amount: 'Összeg',
                scheduling: 'Ütemezés',
                pricing: 'Árazás',
                percentageBundleDiscountInputPlaceholder: '% kedvezmény a kötegre',
                fixedPriceBundleDiscountInputPlaceholder: 'Fix ár a kötegért',
                applyBundleLevelLabel: 'Kötegszint alkalmazása',
                applyItemLevelLabel: 'Tételszint alkalmazása',
                productFixedPrice: 'Termék fix ára',
                percentageDiscount: '% kedvezmény',
                fixedPriceDiscount: 'Fix ár',
                freeDiscount: 'Díjmentes',
                advertisementsFetchError: 'Hiba történt a hirdetés előhívása során. Kérjük, kapcsoljon ki minden hirdetésblokkolót',
                advertisementsTitle: 'Hirdetések',
                all: 'Mind',
                'All time': 'Mindig',
                allProducts: 'Minden termék csoportosítás nélkül',
                allLocations: 'Minden helyszín',
                allCabinets: 'Minden szekrény',
                antennaDown: 'Néhány RFID-antenna átmenetileg nem érhető el',
                antennaError: 'Hiba a hűtő antennáiban',
                antennaNoInfo: 'Nincs elérhető információ a hűtő antennáiról',
                antennaOk: 'Minden antenna működőképes',
                antennaPartial: 'Néhány antenna le lett tiltva',
                archiveSuccess: 'Termék sikeresen archiválva',
                averageItems: 'Átlagos termék egy tranzakcióban',
                averageSales: 'Átlagos eladás egy tranzakcióban',
                barcode: 'Vonalkód',
                barcodeType: 'Vonalkód típusa',
                barcodeCannotBeChanged: 'A vonalkódot nem lehet módosítani',
                barcodeTypeCannotBeChanged: 'A vonalkód típusa nem módosítható',
                barcodeHelpText12: 'Adja meg a vonalkód 12 számjegyét, az utolsó számjegy automatikusan lesz hozzárendelve',
                barcodeHelpText11: 'Adja meg a vonalkód 11 számjegyét, az utolsó számjegy automatikusan lesz hozzárendelve',
                barcodeHelpText7: 'Adja meg a vonalkód 7 számjegyét, az utolsó számjegy automatikusan lesz hozzárendelve',
                beforeExpiry: 'lejárat előtt',
                cabinetDown: 'Hűtő üzemképtelen',
                cabinetIsEmpty: 'Hűtő üres',
                deviceOffline: 'Az eszköz offline',
                deviceOnline: 'Az eszköz online',
                cabinets: 'Hűtők',
                cabinet: 'Hűtő',
                cabinetSettings: 'Hűtő beállítások',
                cabinetTheming: 'A szekrény tematikája',
                cancel: 'Megszakít',
                cancelledSales: 'Megszakított eladás',
                cancelledTransactions: 'Megszakított tranzakciók',
                categories: 'Kategóriák',
                change: 'Módosítás',
                clear: 'Törlés',
                close: 'Bezárás',
                clickEditButtonRegionalPrices: 'A helyszíni árak megváltoztatásához kattintson a "Módosítás" gombra',
                clickEditLocationPrices: 'Helyi árazás, kattintson a termék árára a továbbiakért',
                clickToExpandLocationPrices: 'Helyi árazás, kattintson a termék árára a továbbiakért',
                closeDoor: 'Csukja be az ajtót',
                rebootDevice: 'Indítsa újra az eszközt',
                commandSentSuccessFully: 'Utasítás sikeresen elküldve',
                companyLogo: 'Céges logó',
                confirm: 'Megerősítés',
                confirmDelete: 'Biztos benne, hogy törölni szeretné a terméket? Ez a művelet nem vonható vissza!',
                confirmDisable: 'Biztos benne, hogy archiválni szeretné a terméket?',
                confirmEnable: 'Biztos benne, hogy aktívvá szeretné tenni a terméket?',
                consumerResponses: 'Felhasználói visszajelzések',
                controlPanelTitle: 'Vezérlőpult',
                controls_acceptablePercentageOfBadTags: 'A rossz címkék elfogadható százaléka, mielőtt a hűtő üzemen kívüli állapotba kerülne',
                controls_markTagsGoodQuality: 'Jelölje meg az összes címkét jó minőségben',
                controls_markTagsGoodQuality_btn: 'Jelölje meg a címkéket jó minőségben',
                controls_clearTagHistory: 'Címkeelőzmények törlése',
                controls_clearRemoveReplenishmentData: 'Újrafeltöltési adatok törlése',
                controls_companyAndPaymentLogo: 'Cég és fizetési logó',
                controls_detectTagQuality: 'Címkeminőség észlelése',
                controls_door: 'Ajtóvezérlés',
                controls_device: 'Eszközvezérlés',
                controls_chargeBadTags: 'Számla rossz címkék',
                controls_refill: 'Újratöltés',
                controls_tagQuality: 'Címkebeállítások',
                controls_tagQualityData: 'Címkeminőségi adatok',
                controls_replenishmentData: 'Töltse fel az adatokat',
                controls_soldTags: 'Eladott címkék',
                controls_tagHistory: 'Címkeelőzmények',
                controls_markAllTagsReplenished: 'Jelölje meg az összes címkét feltöltöttként',
                controls_markAllTagsUnsold: 'Jelölje meg az összes címkét eladatlannak',
                controls_markAlTagsReplenished_btn: 'Jelölje meg a címkéket feltöltve',
                controls_markAllTagsUnsold_btn: 'Jelölje meg a címkéket eladatlannak',
                controls_chargeSoldTags: 'Számla eladott címkék',
                controls_chargeUnreplenishedTags: 'Számlázzon feltöltetlen címkéket',
                controls_tagsWarn:
                    'A címkeelőzmények törlése eltávolítja a címkék összes adatát. Kérjük, kattintson az összes címke megjelölése feltöltöttként gombra a művelet után.',
                create: 'Létrehozás',
                createAdTitle: 'Új hirdetés ',
                createdAd: 'Hirdetés létrehozva',
                createExpiryDiscountRule: 'Lejárati kedvezményszabály létrehozása',
                createScheduledDiscountRule: 'Ütemezett kedvezményszabály létrehozása',
                createBundleDiscountRule: 'Leárazási szabály létrehozása',
                createNewVatCategory: 'Új ÁFA kategória',
                createProductTitle: 'Új termék ',
                creatingAd: 'Hirdetés létrehozása',
                creatingProduct: 'Termék létrehozása',
                createNewProductCategory: 'Új termékkategória létrehozása',
                createNewSupplier: 'Új beszállító hozzáadása',
                currency: 'Pénznem',
                customQuery: 'Egyedi lekérdezés',
                dashboard: 'Információs panel',
                dateRange: 'Dátum intervallum',
                dataAlredyFetchedTitle: 'Adatok már lekérdezve',
                dataAlreadyFetchedDescription: 'Kérjük, válasszon eltérő paramétereket',
                day: 'Nap',
                days: 'Napok',
                deactivationDate: 'Deaktiválás dátuma',
                default: 'Alapbeállítás',
                delete: 'Töröl',
                deleteError: 'Hiba: a termék törlése nem lehetséges',
                deleteSuccess: 'Termék sikeresen törölve',
                description: 'Leírás',
                deviceManagement: 'Eszköz kezelése',
                digitwinOffline: 'Az eszköz digitwin offline',
                digitwinOnline: 'Az eszköz digitwin online',
                dimensions: 'Méretek',
                disable: 'Archívum',
                discountAmount: 'Kedvezmény mennyiség',
                discountPrice: 'Kedvezmény összeg',
                discountRuleAffects: 'Kedvezményszabály befolyásolja',
                discountRuleName: 'Kedvezményszabály neve',
                discountRuleStops: 'Lejárati időközök',
                discounts: 'Kedvezmények',
                discountSalesData: 'Akciós értékesítési adatok',
                discountSalesAmount: 'Vásárlás kedvezményeinek összege',
                discountTimeBeforeExpiryExists: 'Már létezik korábbi lejárati időköz',
                discountVsNormalSales: 'Kedvezmény kontra normál eladások',
                dismiss: 'Figyelmen kívül hagy',
                doesNotExist: 'Nem létezik',
                doorCloseConfirm: 'Biztos benne, hogy be szeretné csukni az ajtót?',
                doorCloseSuccess: 'Ajtózár bezárása sikeres',
                doorOpenConfirm: 'Biztos benne, hogy ki szeretné nyitni az ajtót?',
                doorOpenReason: 'Miért szeretné kinyitni az ajtót?',
                doorOpenSuccess: 'Az ajtó sikeresen kinyílt',
                rebootDeviceSuccess: 'Az eszköz sikeresen újraindult',
                deviceRebootConfirm: 'Biztosan újra szeretné indítani az eszközt?',
                edit: 'Módosítás',
                editCompanyLogo: 'Céges logó módosítása',
                editDiscountRule: 'Kedvezményszabály szerkesztése',
                editPaymentMethodLogo: 'Fizetési logó megváltoztatása',
                editVatCategoryModalTitle: 'ÁFA kategória megváltoztatása',
                editProductCategoryModalTitle: 'Termékkategória módosítása',
                editSupplierModalTitle: 'Szállító szerkesztése',
                enable: 'Kiválasztás aktívként',
                error_401: 'Nincs engedélyezve',
                error_403: 'Nincs felhatalmazva a parancs végrehajtására',
                error_500: 'Váratlan szerverhiba',
                errorCreatingAd: 'Hiba a hirdetés létrehozásában',
                errorCreatingProduct: 'Hiba a termék létrehozásában',
                errorFetchingCabinetStatus: 'Hiba a hűtő státuszának lekérésében',
                errorFetchingInventory: 'Hiba a hűtő terméklistájának lekérésében',
                errorFetchingReport: 'Hiba a beszámoló lekérésében',
                errorFetchingSettings: 'Hiba a beállítások lekérésében',
                errorUpdatingProduct: 'Hiba a termékek frissítésében',
                expandToSeeBadTags: 'Nagyítsa ki a listát, hogy lássa, mely címkék hibásak. A rossz címkéket el kell távolítani a hűtőből',
                expandToSeeSoldItems: 'Nagyítsa ki a listát, hogy lássa, melyek a már eladott, de a készletbe újból bekerült termékek.',
                expandToSeeReplenishedOutOfInventory:
                    'Nagyítsa ki a listát, hogy megtekintse azokat a termékeket, amelyeket raktárkészlet-feltöltési folyamat nélkül tettek a hűtőbe',
                expired: 'Lejárt',
                expiredCount: 'Lejártak darabszáma',
                expiredProductsCount: 'Lejárt termékek száma',
                expiredSum: 'Lejártak összege',
                expiring: 'Hamarosan lejár',
                expiryDate: 'Utolsó eladási nap',
                exportExcel: 'Excel tábla kiexportálása',
                fetchAdvertisements: 'Hirdetések lekérdezése',
                fetchingCabinets: 'Hűtők lekérdezése',
                fetchingCabinetsError: 'Hiba történt a hűtők lekérdezése közben',
                fetchingCabinetStatus: 'Hűtők státuszának lekérdezése',
                fetchingError: 'Hiba történt a lekérdezés során',
                fetchingInventory: 'Hűtő készlet lekérdezése',
                fetchingProducts: 'Termékek lekérdezése',
                fetchingReport: 'Beszámoló lekérdezése',
                fetchingVatCategories: 'ÁFA kategóriák lekérdezése',
                fetchingProductCategories: 'Termékkategóriák visszakeresése',
                fillForm: 'Kitöltési nyomtatvány',
                freshFood: 'Ez egy lejáró termék',
                freezerPackage: 'Fagyasztott termék',
                generalFetchError: 'Hiba a visszakeresésben',
                generalRequestReject: 'Hiba a kérésben',
                get: 'Kap(ni)',
                group: 'Csoport(osítani)',
                holdOn: 'Várakozni',
                hour: 'Óra',
                hours: 'Órák',
                hoursBeforeExpiry: 'Órák lejárat előtt',
                daysBeforeExpiry: 'Napok a lejárat előtt',
                image: 'Kép',
                imageOrVideo: 'Válasszon képet vagy videót',
                inActive: 'Inaktív',
                individualCabinets: 'Egyéni hűtők',
                individualCabinetsOnly: 'Csak egyéni hűtők',
                invalidBarcodeOnlyDigits: 'A vonalkód csak számokat tartalmazhat',
                invalidBarcodeTooLong: 'A vonalkód túl hosszú',
                invalidBarcodeTooShort: 'Vonalkód túl rövid',
                invalidCabinetNameTooShort: 'Hűtő neve túl rövid',
                invalidCabinetNameDuplicated: 'Hűtő neve már létezik',
                invalidLocationNameTooShort: 'Helyszín neve túl rövid',
                invalidLocationNameDuplicated: 'Helyszín neve duplikálva',
                invalidPercentage: 'Helytelen érték',
                invalidPrice: 'Érvénytelen ár',
                invalidProductBarcode: 'Érvénytelen vonalkód',
                invalidTitle: 'Érvénytelen cím',
                invalidBundleItems: 'Érvénytelen elemeket tartalmaz',
                inventoryLength: 'Készlet hossza',
                inventoryNotReported: 'A hűtő nem jelentette le a készletét',
                inventoryTitle: 'Hűtő készlet ',
                'Inventory History': 'Készlet előzmények',
                itemReplenishedOutOfInventoryProcess: 'Ezt a hatékonyságot vagy címkét meg kell változtatni a helyszíni hűtő töltési folyamata során',
                itemSoldOn: 'Termék eladva:',
                'Last 30 days': 'Elmúlt 30 nap',
                'Last 7 days': 'Elmúlt 7 nap',
                'Last month': 'Elmúlt hónap',
                'Last quarter': 'Elmúlt negyedév',
                'Last week': 'Elmúlt hét',
                'Last year': 'Elmúlt év',
                lastUpdate: 'Utolsó frissítés',
                lifetime: 'Élettartam',
                location: 'Helyszín',
                locations: 'Helyszínek',
                logoEditor: 'Logó szerkesztő',
                logoSettings: 'Logó beállítások',
                loading: 'Betöltés...',
                measure: 'Méret',
                measures: 'Méretek',
                minsBeforeExpiry: 'Percek lejárat előtt',
                minutes: 'Percek ',
                month: 'Hónap',
                name: 'Név',
                newVatCategoryModalTitle: 'Új ÁFA kategória',
                newProductCategoryModalTitle: 'Új termékkategória',
                newSupplierModalTitle: 'Új beszállító',
                'no region': '(Nincs helyszín)',
                noAdvertisements: 'Nincs hirdetés',
                noCabinets: 'Nincs hűtő',
                noCabinetSelected: 'Nincs hűtő kiválasztva',
                noLogo: 'Nincs logó',
                noData: 'Nincs adat',
                noDescription: 'Nincs leírás',
                noEvents: 'Nincs esemény!',
                noIndividualCabinets: 'Nincs egyéni hűtő',
                noLocationPrices: 'Nincs helyi árazás meghatározva a terméknek',
                none: 'Nincs',
                noPaymentMethodLogo: 'Nincs fizetési mód logó',
                noProducts: 'Nincs termék',
                noProductsRemoved: 'Nincsenek eltávolított termékek',
                noProductTypeSelected: 'Nincs kiválasztva csomagtípus',
                normalPrice: 'Normál ár',
                normalSalesAmount: 'Normál eladási összeg',
                notAccessibleForYou:
                    'A Létrehozás / frissítés tulajdonság csak az Adminisztrátorok és a Hűtő operátorok számára elérhető. Hozzáférésért vegye fel a kapcsolatot a vezetőjével, vagy az adminisztrátorral',
                notDefined: 'Nincs definiálva',
                noTitle: 'Nincs cím',
                noVatCategories: 'Nincs ÁFA kategória feljegyzés',
                noProductCategories: 'Nem található termékkategória rekord',
                noInventoryOnSelectedDate: 'Nincs készletjelentés a kiválasztott dátumra. Kérjük, próbálja meg egy másik dátummal.',
                openDoor: 'Nyissa ki az ajtót',
                orderSum: 'Rendelés összege',
                paymentMethodLogo: 'Fizetési mód logó',
                percentage: 'Százalék',
                permanent: 'Végleges',
                preview: 'Előnézet',
                price: 'Ár',
                print: 'Nyomtat',
                productCreated: 'Termék létrehozva',
                products: 'Termékek lekérdezése',
                productsFetchError: 'Hiba történt a termékek lekérdezése közben',
                productsTitle: 'Termékkatalógus',
                productType: 'Csomagtípus',
                productUpdated: 'Termék frissítve',
                quantity: 'Mennyiség',
                readerDown: 'RFID-olvasó üzemen kívül',
                region: 'Helyszín',
                regions: 'Helyszínek',
                removedAt: 'Eltávolítva ekkor',
                removeImage: 'Kép eltávolítása',
                removeVideo: 'Videó eltávolítása',
                refillQRCodeInfo: 'Szkennelje be a QR-kódot az újratöltési applikáció megnyitásához, vagy kövesse az utasítást',
                refillQRCodeTitle: 'Újratöltési QR-kód',
                refillRuleTitle: 'Újratöltési szabály',
                reportsTitle: 'Jelentések',
                requestSuccess: 'A változtatások sikeresen megtörténtek',
                requiredPercentage: 'Szükséges. Százalék érték a százalék jel nélkül',
                requiredText: 'A karakterek kötelező hossza: ',
                retireLocation: 'Hely bezárása',
                rfidNoInfo: 'Nincs elérhető információ az RFID-olvasóról és antennákról',
                rfidOk: 'Az RFID-olvasó és antennák rendeltetésszerűen működnek',
                sales: 'Eladások',
                salesCount: 'Eladások száma',
                salesVsWasteAmount: 'Eladás kontra Leírás összege',
                save: 'Mentés',
                search: 'Keresés',
                selectCabinet: 'Válasszon ki egy hűtőt',
                selectDiscountRuleCabinets: 'Válassza ki a kedvezményszabály által érintett hűtőket',
                selectDiscountRuleLocations: 'Válassza ki a kedvezményszabály által érintett helyszíneket',
                selectDiscountRuleProducts: 'Válassza ki a kedvezményszabály által érintett termékeket',
                selectProductType: 'Kérjük, válassza ki a csomagolási típust a termékhez',
                setDiscountAmount: 'Adja meg a leértékelés összegét',
                setDiscountTimeBeforeExpiry: 'Lejárati időköz beállítása',
                settingConfirmMessage: 'Biztos benne, hogy el szeretné menteni ezeket a beállításokat? Ha nem biztos benne, ne erősítse meg',
                showDisable: 'Archivált termékek',
                summary: 'Összefoglaló',
                sureRemoveDiscountRule: 'Biztos benne, hogy el szeretné távolítani a kedvezményszabályt?',
                tagIsBad: 'Hibás címke, távolítsa el a hűtőből',
                tagsRemovedReport: 'Eltávolított címkék jelentése',
                taxValue: 'Adó érték',
                'This month': 'Ebben a hónapban',
                'This quarter': 'Ebben a negyedévben',
                'This week': 'Ezen a héten',
                'This year': 'Idén',
                thisLink: ' ez a link.',
                timeGrouping: 'Idő csoportosítás',
                title: 'Cím',
                Today: 'Ma',
                topExpiredProducts: 'Legtöbbször lejárt termékek',
                topProducts: 'Legnépszerűbb termékek',
                total: 'Összesen',
                totalValue: 'Érték összesen',
                transactions: 'Tranzakciók',
                ttlInDays: 'Termék élettartama napokban',
                ttlsAffected: 'Élettartammal érintett termékek',
                unexpectedError: 'Váratlan hiba',
                uniqueCustomers: 'Egyedi vásárlók',
                unknown: 'Ismeretlen',
                update: 'Frissítés',
                updateAdError: 'Hiba a hirdetés frissítése közben',
                updateAdTitle: 'Hirdetés frissítése',
                updatedAd: 'Hirdetés frissítve',
                updateCabinetSettings: 'Adatok szinkronizálása a szekrénybe',
                updateCabinetSettingsInfo: 'Biztosan frissíti a szekrény beállításait?',
                updateData: 'Adatok frissítése',
                updateProductTitle: 'Termék frissítése',
                updatingAd: 'Hirdetés frissítése folyamatban',
                updatingProduct: 'Termék frissítése folyamatban',
                uploading: 'Feltöltés folyamatban',
                uploadingImage: 'Kép feltöltése folyamatban',
                use: 'Használat',
                'w/o grouping': 'Nincs csoportosítás',
                waste: 'Hulladék',
                vatCategoriesFetchError: 'Hiba az ÁFA kategória lekérdezésében',
                vatCategoriesTitle: 'ÁFA kategóriák',
                vatCategory: 'ÁFA kategória',
                vatCategoryItems: 'Tételek',
                vatCategoryName: 'ÁFA-kategória neve',
                vatCategoryPromptMessage: 'Biztos benne, hogy törölni szeretné az ÁFA kategóriát',
                vatCategoryValue: 'Adó érték százalék',
                vatDeleteRejected: 'ÁFA kategória törlése elutasítva. Ellenőrizze a kapcsolódó termékeket',
                vatErrorRequesting: 'Hiba a kérés teljesítése közben',
                vatRequestRejected: 'Kérés elutasítva',
                vats: 'ÁFA-k',
                productCategoriesFetchError: 'Hiba történt a termékkategóriák lekérésekor',
                productCategoriesTitle: 'Termékkategóriák',
                productCategory: 'Termékkategória',
                productCategoryItems: 'Kategóriák',
                productCategoryName: 'Termékkategória neve',
                productCategoryPromptMessage: 'Biztosan törli a termékkategóriát?',
                productCategoryDescription: 'Termékkategória leírása',
                productCategoryHelpTextDescription: 'Például a saláták kategóriája',
                productCategoryHelpTextName: 'Például saláták',
                productCategoryDeleteRejected: 'Termékkategória eltávolítása elutasítva. Ellenőrizze a kapcsolódó termékeket!',
                week: 'Hét',
                viewMode: 'Nézet',
                year: 'Év',
                Yesterday: 'Tegnap',
                groupBy: 'Csoportosítás valami szerint',
                filterBy: 'Szűrés valami szerint',
                reportType: 'Jelentés típusa',
                custom: 'Egyedi',
                preDefined: 'Előre meghatározott',
                selected: 'Kiválasztott',
                metricsInfo: 'Metrikák (összesen, maximum, minimum...) az összes kijelölt kiterjedésre/ erőforrásra lesznek kalkulálva',
                dimensionsInfo: 'Metrikák a kijelölt kiterjedésre / erőforrásra lesznek lekérdezve',
                metrics: 'Metrikák',
                controlsSoftwareVersion: 'Szoftververzió',
                currentSoftwareVersion: 'Jelenlegi szoftververzió',
                availableSoftwareVersion: 'Elérhető szoftververzió',
                invalidLifetime: 'Érvénytelen élettartam érték',
                unpaidSales: 'Kifizetetlen eladás',
                unpaidTransactions: 'Kifizetetlen tranzakció',
                softwareUpdateConfirmation: 'Biztosan frissíteni szeretné a kiválasztott eszköz(ök) szoftververzióját?',
                softwareUpdateWarn:
                    'A szoftververzió frissítése utasítást küld az eszköz(ök)nek a frissítés lekérésére most vagy a kiválasztott időpontban. A kiválasztott eszköz(ök) telepíti(k) a frissítést, miután online állapotba kerül(nek) az elindítást követően. A kapcsolat sebességétől függően a telepítés időt vehet igénybe. A telepítés után az eszköz(ök) újraindul(nak).',
                releaseVersion: 'Kiadott verziók',
                releaseDate: 'Kiadási dátum',
                latestRelease: 'Legutolsó kiadás',
                currentVersion: 'Mostani verzió',
                softwareUpdateSuccess: 'A szoftver verziója frissült',
                prebooking: 'Előfoglalás',
                prebookedProduct: 'Előre lefoglalt termék',
                prebookedProducts: 'Előre lefoglalt termékek',
                activeProducts: 'Aktív termékek',
                startMaintenanceMode: 'Karbantartási mód elindítása',
                endMaintenanceMode: 'Karbantartási mód befejezése',
                startMaintenanceConfirm: 'Biztosan elindítja a hűtő karbantartási módját?',
                endMaintenanceConfirm: 'Biztosan befejezi a hűtő karbantartási módját?',
                startMaintenanceSuccess: 'Elindult a hűtő karbantartási módja',
                endMaintenanceSuccess: 'A hűtő karbantartási módja véget ért',
                noResults: 'Nincs eredmény',
                suppliers: 'Beszállítók',
                supplier: 'Beszállító',
                supplierDeleteRejected: 'Beszállító törlése elutasítva. Ellenőrizze a kapcsolódó termékeket!',
                supplierPromptMessage: 'Biztosan törli a beszállítót?',
                fetchingSuppliers: 'Beszállítók lekérése',
                noSuppliers: 'Nem található beszállítói rekord',
                suppliersFetchError: 'Hiba a beszállítók lekérésekor',
                supplierName: 'Beszállító neve',
                supplierHelpTextName: 'Például egy márkanév',
                supplierDescription: 'Beszállító leírása',
                supplierHelpTextDescription: 'Pl. egy márka leírása',
                wishes: 'Kívánságok',
                checkNow: 'Ellenőrizés most',
                startTimeOn: 'Indulás ideje be',
                endTimeOn: 'Befejezés ideje be',
                signOutConfirmationModalTitle: 'Kijelentkezés megerősítése',
                signOutConfirmationModalContent: 'Biztosan kijelentkezik?',
                startDate: 'Indítás dátuma',
                endDate: 'Befejezés dátuma',
                scheduledDiscountApplyAllDays: 'Jelentkezés minden napra',
                scheduledDiscountApplySelectedDays: 'Jelentkezés kiválasztott napokra',
                discountCustomImageTitle: 'Kedvezmény képe (nem kötelező)',
                discountCustomImageModalHeader: 'Kedvezmény képe',
                saveChanges: 'Módosítások mentése',
                updateTagsQualitySettingsTitle: 'Címke minőségi beállításai',
                updateTagsQualitySettingsMessage: 'Frissített címkeminőségi beállítások',
                IF3Cabinets: '3. modellű hűtők',
                IF2Cabinets: '2. modellű hűtők',
                IF3Freezers: '3. modellű fagyasztók',
                encodingStations: 'Kódoló állomások',
                if2: '2. modellű hűtő',
                if3: '3. modellű hűtő',
                if3Freezer: '3. modellű fagyasztó',
                legalNotice: 'Jogi nyilatkozat',
                privacyPolicy: 'Adatvédelmi szabályzat',
                scheduledDiscountDateMissingError: 'Mindkét dátumra szükség van, ha az indítási vagy befejezési dátum be van állítva',
                scheduledDiscountDuplicateTimeError: 'Az időpontok nem lehetnek azonosak, ha a dátumok azonosak',
                now: 'Most',
                minimum: 'Minimum',
                maximum: 'Maximum',
                discardChangesConfirmationText: 'Biztosan elveti a módosításokat?',
                exports: 'Exportálások',
                selectAllCabinets: 'Az összes szekrény kiválasztása',
                expiryIntervals: 'Lejárati időközök',
                timeBeforeExpiry: 'Lejárat előtti idő',
                apply: 'Alkalmaz',
                yes: 'Igen',
                no: 'Nem',
                productOnScreenBannerText: 'A képernyőn megjelenő információs banner szövege',
                productOnScreenBannerAdditionalInfo:
                    'A banner szövege egy egysoros szöveg, amely a termék képe alatt jelenik meg, és tömören közvetíti a termék főbb jellemzőit, egészségügyi vonzerejét vagy a termékkel kapcsolatos bármilyen további információt',
                productOnScreenBannerExampleText: 'Laktózmentes',
                UI_IS_DOWN: 'Az iratszekrény kijelzője nem működik',
                READER_IS_DOWN: 'Az RFID-olvasó nem működik',
                ANTENOR_DEVICE_DISCONNECTED: 'A fizetési eszköz nincs csatlakoztatva',
                EXPIRED_PRODUCT: 'Lejárt termékek az iratszekrényben',
                CLOUD_SERVICE_DOWN: 'Felhőszolgáltatás nem elérhető',
                ANTENNA_DISCONNECTED: 'Antenna leválasztva',
                TAG_CHANGES: 'Túl sok rossz címke az iratszekrényben',
                ANTENNA_DOWN: 'Az RFID-antenna az iratszekrényben nem működik',
                READER_CONFIG_ERROR: 'Az RFID-olvasó konfigurációja sikertelen, és nem működik',
                activeAlarms: 'Aktív riasztások',
                deviceStatus: 'Eszköz állapota',
                lastMonitored: 'Utolsó ellenőrzött',
                noService: 'Nincs szolgáltatás',
                operational: 'Üzemképes',
                offline: 'Offline',
                locationsSelectHelp: 'Amikor egy helyet hozzáadnak, az alhelyek is hozzá lesznek adva.',
                select: 'Válassza ki...',
                labels: 'Címkék',
                productDetails: 'Termék részletei',
                invalidProductName: 'A termék neve 1 és 125 karakter hosszú legyen',
                standardPrice: 'Normál ár',
                locationPrices: 'Helyszíni árak',
                addNew: 'Új hozzáadása',
                productExpiry: 'Termék lejárata',
                productLifeTime: 'Termék élettartama',
                invalidTTL: 'A termék élettartama 0 és 32767 közötti egész szám legyen',
                productImage: 'Termék kép',
                uploadImage: 'Kép feltöltése',
                pleaseUploadImage: 'Kérjük, töltsön fel egy képet',
                productValidationError: 'Kérjük, javítsa ki a következő hibákat, mielőtt elmenti a termék adatait',
                productSupplier: 'Termék szállító',
                frozenProductLable: 'Ez egy fagyasztott termék',
                prebookingLabel: 'Ez egy előrendelhető termék',
                previous: 'Előző',
                bannerText: 'Banner szöveg',
                packagingTypeValidationError: 'Kérjük, válasszon csomagolási típust a termékhez',
                showOnlyFrozen: 'Csak fagyasztott termékek megjelenítése',
                productClassification: 'Termék besorolása',
                productClassLabel:
                    'Kérjük, válassza ki a termék megfelelő osztályát. Ez lehetővé teszi, hogy a Selfly Insights relevánsabb termékinformációkat nyújtson.',
                productLabels: 'Termék címkék',
                productLabelsHelpText: 'Kérjük, adjon hozzá opcionális címkéket a termékéhez. Ez lehetővé teszi a termékek szűrését a termékkatalógusban.',
                newLabel: 'Új címke',
                productClassValidationError: 'Kérjük, válasszon terméke számára egy besorolást az alábbi lehetőségek közül',
                tagSensitivity: 'Címke minőségi érzékenysége',
                tagSensitivitySetting: 'Címke minőségi érzékenységi beállítás',
                configTagSensitivity: 'A címke minőségi érzékenységének konfigurálása a termékhez',
                productLevelSensitivityInfo:
                    'A termékre helyezett RFID címke olvashatóságát különböző tényezők befolyásolhatják, mint például a csomagolóanyagok, fémfelületek, nedvesség és maga a termék tartalma. Ezek a tényezők időnként szakaszos címkejeleket okozhatnak, amelyeket villódzásként ismerünk. A szekrény ezt a villódzást az érzékenységi beállítások alapján kezeli. Ha nem biztos benne, hogy melyik érzékenységi szint megfelelő ehhez a termékhez, kihagyhatja ezt a részt.',
                errorSaving: 'Hiba történt a termék adatainak mentése közben',
                successSaving: 'A termék adatai sikeresen elmentve',
                basicDetail: 'Alapvető részletek',
                additionalDetail: 'További részletek',
                packaging: 'Csomagolási típus',
                classification: 'Besorolás',
                selectOrtype: 'Válasszon vagy írjon...',
                max25Chars: 'max 25 karakter',
                noVatForProductCreation: 'Kérjük, hozzon létre ÁFA kategóriát, mielőtt új termékeket ad hozzá',
                lifeTimeExplanation: 'Hány napig érvényes ez a termék eladásra. (0 nap) azt jelenti, hogy a nap végéig',
                selflyEncode: 'Selfly Encode',
                userRestrict: 'Csak elismert fogyasztók',
                userRestrictTitle: 'Jogosult fogyasztók',
                userNotRestrict: 'Minden fogyasztó',
                userRestrictHelperText:
                    'Az elismert fogyasztók opció azt jelenti, hogy a kedvezmény csak olyan fogyasztók számára alkalmazható, akik már regisztráltak kedvezményre jogosultságért!',
                searchCabinets: 'Szekrények keresése',
                selectAll: 'Összes kiválasztása',
                monday: 'Hétfő',
                tuesday: 'Kedd',
                wednesday: 'Szerda',
                thursday: 'Csütörtök',
                friday: 'Péntek',
                saturday: 'Szombat',
                sunday: 'Vasárnap',
                fetchingLoader: 'Betöltés. Kérem várjon...',
                category: 'Kategória',
                okay: 'Ok',
                noLocation: 'Nincs hely',
                copy: 'Másolás',
                pleaseConfirm: 'Kérjük, erősítse meg',
                saveQuery: 'Lekérdezés mentése',
                saveQueryPlaceholder: 'pl. "Összes eladott termék száma"',
                saveQuerySuccess: 'A lekérdezés elmentve.',
                yourSavedQueries: 'Mentett lekérdezéseid',
                selectQuery: 'Lekérdezés kiválasztása',
                noQuery: 'Még nincs mentett lekérdezés',
                deleteAdConfirm: 'Biztosan törölni szeretné ezt a hirdetést?',
                dataSaved: 'Adatok sikeresen mentve',
            },
            refill: {
                refillRuleApplyRuleDropdownText: 'Alkalmazza ezt a szabályt más szekrényekre is',
                refillPlanGenerate: 'Újratöltési terv létrehozása',
                refillPlanEditPlan: 'Terv szerkesztése',
                refillAmount: 'Újratöltési mennyiség',
                refillRuleTargetAmount: 'Célkészletszint',
                refillRuleTargetAmountShort: 'Cél',
                refillPlanActivePlans: 'Aktív tervek',
                refillPlanCompletedPlans: 'Befejezett tervek',
                refillNote: 'Újratöltési megjegyzés',
                refillNoteHelp: 'Termék újratöltési megjegyzés (legfeljebb 125 karakter)',
                refillPDFText:
                    'Lent látható a szekrény újratöltési terve. Kérjük, győződjön meg arról, hogy a feltöltési tervet teljesítette a feltöltési alkalmazásban, miután befejezte a feltöltést.',
                refillPDFSummaryText:
                    'Lent látható az újratöltési tervek összefoglalója az összes kiválasztott szekrényhez hozzáadandó és eltávolítandó termékekkel.',
                refillSummary: 'Újratöltés összefoglaló',
                addItems: 'Termékek hozzáadása',
                removeItems: 'Termékek eltávolítása',
                inventoryChange: 'Készletváltozás',
                planogramPDFText: 'Planogram a szekrény újratöltési stratégiájához.',
                planogramImagePDFText: 'Egyedi kép a szekrény újratöltési stratégiájához.',
                refillSchemas: 'Újratöltési sablonok',
                refillPlans: 'Újratöltési tervek',
                refillSchemaTitle:
                    'Állítsd be a cél- és minimális készletmennyiségeket a kiválasztott szekrények sablonjaként. Ezeket a sablonokat az újratöltési tervek generálásához használják.',
                refillPlansTitle:
                    'Nézd meg az összes aktív tervet. Szerkesztheted vagy eltávolíthatod a meglévő aktív terveket, vagy új újratöltési terveket adhatsz hozzá szükség szerint.',
                refillSchemaSubText: 'Kérlek válassz egy szekrényt az újratöltési sablon létrehozásához vagy frissítéséhez.',
                noRefillSchema: 'Még nem definiáltak újratöltési sablont erre a szekrényre.',
                createRefillSchema: 'Új újratöltési sablon létrehozása',
                noDevice: 'Nincs eszköz találva',
                schemaSaved: 'Újratöltési sablon sikeresen mentve',
                deleteSchemaConfirmation: 'Biztosan törölni szeretnéd az újratöltési sablont?',
                targetAmount: 'Célmennyiség',
                targetAmountHelpText: 'A célkészlet mennyisége minden termék esetében a szekrényben az újratöltés után.',
                minimumThreshold: 'Minimális küszöb',
                minimumThresholdHelpText:
                    'Állítsd be a minimális készlet szintet minden termékhez a szekrényben. Ha a mennyiség a meghatározott küszöb alá csökken, riasztó értesítést kapsz. A küszöb 0-ra állítása letiltja az értesítéseket.',
                editNote: 'Megjegyzés szerkesztése',
                refillNoteTitle: 'Írj egy újratöltési megjegyzést a termékhez.',
                refillNoteCharacters: 'karakter',
                noRefillPlan:
                    'Jelenleg nincsenek aktív újratöltési tervek. Kérlek, adj hozzá új újratöltési terveket a szekrényedhez, vagy először hozz létre újratöltési sablont a szekrényekhez.',
                planSaved: 'Újratöltési terv sikeresen mentve',
                deleteConfirmation: 'Biztosan törölni szeretnéd a kiválasztott újratöltési terv adatait?',
                editPlan: 'Terv szerkesztése',
                currentInventory: 'Jelenlegi készlet',
                lastSevenDaySales: 'Az utolsó hét nap eladása',
                inventory: 'Készlet',
                planogram: 'Planogram',
                copyRefillSchema: 'Sablon másolása más szekrényekhez',
                generatingPlans: 'Újratöltési tervek generálása. Kérlek, várj...',
                generatePlans: 'Újratöltési tervek generálása',
                complete: 'kész',
                selectCabinetsToAddPlans: 'Kérlek, válassz szekrényeket az újratöltési tervek generálásához.',
                reviewSuggestion:
                    'Az alábbiakban a javasolt újratöltési tervek találhatók a kiválasztott szekrényekhez. Kérlek, nézd át, szerkeszd, ha szükséges, és mentsd el őket a tervek aktiválásához.',
                noSchema:
                    'Jelenleg egyik szekrénynek sincs újratöltési sablonja. Kérlek, adj hozzá újratöltési sablont a szekrényedhez az újratöltési tervek generálásához.',
                searchProducts: 'Termékek keresése',
                maxAllowedInventory: 'A maximálisan megengedett készlet 200.',
                savingData: 'Adatok mentése. Kérlek, várj',
                errorSavingData: 'Hiba az adatok mentésekor.',
                refillPlanSaved: 'Újratöltési tervek sikeresen generálva.',
                exportPDFHelp: 'Exportálja a kiválasztott terveket PDF fájlba.',
                exportExcelHelp: 'Exportálja a kiválasztott terveket Excel fájlba.',
                addPlanHelp: 'Új újratöltési tervek hozzáadása.',
                deletePlanHelp: 'A kiválasztott újratöltési tervek törlése.',
                generatePlansHelp: 'Újratöltési tervek generálása a kiválasztott szekrényekhez.',
                planogramSaved: 'Planogram sikeresen mentve.',
                schemaCopied: 'Az újratöltési sablon sikeresen szinkronizálva lett a többi szekrénnyel.',
                allProducts: 'Minden termék',
                schemaProducts: 'Újratöltési sablon termékek',
                deletePlanogram: 'Biztosan törölni szeretnéd a planogramot?',
                dragInPlanogram: 'Kérlek, húzd és ejtsd a termékeket a kijelölt szekrénypolcokra, vagy húzd a szekrény nyílását a termék fölé.',
                noPlanogramInSchema: 'Még nem definiáltak planogramot a szekrényhez.',
                noPlanogramInPlan:
                    'Nincs elérhető planogram adat a szekrényhez. Kérlek, először hozz létre planogramot a szekrényhez az újratöltési sablon lapon.',
                copyPlanogram: 'Másold a planogramot az újratöltési sablonnal együtt',
                planogramImageInfo: 'A planogram mellett egyedi planogram képet is hozzáadhatsz.',
                addPlanogramImage: 'Planogram kép hozzáadása',
                editPlanogramImage: 'Planogram kép szerkesztése',
                numberOfShelves: 'Polcok száma',
                addNewRefillPlan: 'Új tervek hozzáadása',
                qrcodeTitle: 'QR-kód generálódik a kiválasztott szekrényhez az újratöltési folyamat kezdeményezéséhez.',
                qrcodeSubText: 'Kérlek, válassz egy szekrényt, ahol szeretnéd kezdeményezni az újratöltési folyamatot.',
                qrcodeScan: 'Kérlek, olvasd be a QR-kódot az újratöltéshez, vagy kattints a gombra az újratöltési alkalmazás megnyitásához.',
                qrcodeOpenApplication: 'Újratöltési alkalmazás megnyitása',
                createRefillPlanogram: 'Újratöltési planogram létrehozása',
            },
            navigation: {
                advertisements: 'Hirdetések',
                analytics: 'Elemzések',
                controlPanel: 'Vezérlőpult',
                deviceManagement: 'Eszköz kezelése',
                discounts: 'Kedvezmények',
                fetching: 'Lekérdezés ',
                group: 'Csoport',
                inventory: 'Hűtő készlet',
                language: 'Nyelv',
                productCatalog: 'Termékkatalógus',
                productCategories: 'Termékkategóriák',
                reports: 'Jelentések',
                settings: 'Felhasználói beállítások',
                signOut: 'Kijelentkezés',
                title: 'Selfly Felhő Portál',
                vatCategories: 'ÁFA kategóriák',
                community: 'Selfly Community',
                refillManagement: 'Újratöltési kezelés',
                automations: 'Automatizálások',
                mySelfly: 'MySelfly beállítások',
                automationInfo: 'Automatizált műveletek kezelése (BETA)',
            },
            reports: {
                alreadyRefund: 'vissza lett térítve',
                amount: 'Összeg',
                average: 'Átlag',
                barcode: 'Vonalkód',
                confirmIssueRefundButton: 'Erősítse meg a visszatérítést',
                current: 'Jelenlegi',
                export: 'Exportálás',
                fetching: 'Lekérdezés',
                fetchingError: 'Hiba történt a lekérdezés közben',
                image: 'Kép',
                issueRefundButton: 'VISSZATÉRÍTÉS',
                maximum: 'Maximum',
                minimum: 'Minimum',
                name: 'Név',
                noData: 'Nincs adat',
                note: 'Megjegyzés',
                order: 'Tranzakció',
                paymentWasDoneByCreditCard: 'A fizetés hitelkártyával történt',
                price: 'Ár',
                ProductReport: 'Termék jelentés',
                quantity: 'Mennyiség',
                'Refund impossible': 'A fizetés hitelkártyával történt. Visszatérítésért lépjen kapcsolatba a kártya terminál kibocsájtójával',
                refundFormTitle: 'Visszatérítés',
                refundReason: 'Visszatérítés oka',
                refundSuccess: '{{sum}} összeg vissza lett térítve',
                refundTotal: 'Visszatérített összeg',
                replenishments: 'Újratöltések',
                replenishmentsTitle: 'Újratöltési jelentés',
                SaleTransaction: 'Eladási tranzakció',
                summary: 'Összegzés',
                SummaryReport: 'Eladási összegzés',
                temperature: 'Hőmérséklet',
                temperatureTitle: 'Hőmérséklet jelentés',
                temperatureValueNotification: 'A hőmérséklet értékek a hűtő óránkénti hőmérséklet értékeinek az átlagai ',
                time: 'Idő',
                total: 'Összesen',
                transactions: 'Tranzakció',
                user: 'Felhasználó',
                paidTransactions: 'Fizetett tranzakciók',
                unpaidTransactions: 'Fizetetlen tranzakciók',
                partiallyPaid: 'Részben fizetett',
                failedTransactions: 'Sikertelen tranzakciók',
                amountOwed: 'Hátralévő összeg',
                receiptLinkText: 'Innen kérje a nyugtát',
                noPurchase: 'Nincs vásárlás',
                other: 'Egyéb',
                userId: 'Felhasználói azonosító:',
            },
            settings: {
                block_cabinet_when_product_expired: 'Hűtő blokkolása lejárt termékek miatt',
                criticalExpiryLimitHours: 'Órák a kritikus lejárati értesítés előtt',
                language: 'Nyelv',
                logo: 'Céges logó',
                mediumExpiryLimitHours: 'Órák a közepes mértékű lejárati értesítés előtt',
                payment_method_logo: 'Fizetési mód logó',
                footer_instruction_logo: 'Láblécutasítás képe',
                readUserMemory: 'Termék lejárati adatok lekérdezése',
                ui_skus_sort_by: 'Termékrendezési sorrend a szekrény kijelzőjén',
                timeZone: 'Időzóna',
                country: 'Ország',
                city: 'Város',
                tagSettings: 'Címke beállítások',
                themeAndLogos: 'Téma és logók',
                advancedSettings: 'Speciális beállítások',
                themingInfoText: 'Testreszabhatja szekrénye képernyőjének témáját',
                cabinetLogos: 'Szekrénylogók',
                cabinetLogoInfoText: 'Töltse fel a szekrény képernyőjén megjelenő logókat',
                logoHelperText: 'Használjon 180px X 180px képet',
                settingsCopyInfoHeader: 'Válassza ki azokat a szekrényeket, amelyekbe át szeretné másolni a beállításokat',
                settingsCopyButton: 'Beállítások másolása a kiválasztott szekrényekbe',
                settingsCopySuccessModalTitle: 'Beállítások másolása',
                settingsCopySuccessModalContent: 'Beállítások sikeresen másolva {{cabinetsAmount}} szekrénybe',
                theming: 'Témázás',
                adminSettings: 'Adminisztrátori beállítások',
                settingsCopyErrorModalTitle: 'Beállítások másolási hiba',
                key: 'Beállítás',
                value: 'Érték',
                sleepScheduling: 'Ütemezett készenlét',
                sensitivityLevel: 'Érzékenységi szint',
                sensitivity_0:
                    'A szekrény figyelmen kívül hagyja a címke villogását, és következetesen érvényesnek jelöli az összes címkét, ami esetenként téves számlázást eredményezhet.',
                sensitivity_20: 'A szekrény legfeljebb három hibás leolvasást tűr egy címkénél 50 beolvasás alatt, és még mindig érvényesnek jelöli a címkét.',
                sensitivity_40: 'A szekrény legfeljebb két hibás leolvasást tűr egy címkénél 60 beolvasás alatt, és még mindig érvényesnek jelöli a címkét.',
                sensitivity_60: 'Ha egy címke gyakran villog, de hosszabb ideig stabil marad, a szekrény ezt tolerálja, és érvényesnek olvassa.',
                sensitivity_80: 'Ha egy címke rövid ideig villog, de hosszabb ideig stabil marad, érvényes címkének minősül.',
                sensitivity_100:
                    'A szekrény nagyon érzékeny a címke villogására. Akár egyetlen hibás leolvasás is okozhatja, hogy a címkét hosszabb ideig érvénytelennek jelölik, ami veszteségeket okozhat.',
                sensitivityInfo:
                    'A szekrény címkeminőség-érzékenysége arra vonatkozik, hogy mennyire hatékonyan kezeli az inkonzisztens címkeleolvasásokat vagy a címke villogását. Kérjük, figyeljen az érzékenységi szintre, mivel ez befolyásolhatja a szekrény teljesítményét. Ha nem biztos benne, melyik szint megfelelő, javasolt az alapértelmezett 3-as szinten hagyni a beállítást.',
            },
            signIn: {
                signInButton: 'Bejelentkezés',
                signInExpiredError: 'Időtúllépés. Jelentkezzen be újra',
                signInMessage: 'Be kell jelentkeznie a folytatáshoz. Kattintson az alábbi gombra a bejelentkezéshez',
                noOrganizationsError: 'Nincs ehhez a felhasználónévhez kapcsolódó szervezet. Próbálkozzon másik felhasználónévvel.',
            },
            translations: {
                add: 'Hozzáadás',
                closed: 'Zárva',
                delete: 'Törlés',
                device: 'Eszköz',
                edit: 'Szerkesztés',
                off: 'KIKAPCSOLVA',
                offline: 'Offline',
                on: 'BEKAPCSOLVA',
                online: 'Online',
                opened: 'Nyitva',
                save: 'Mentés',
                seeDetails: 'Részletek',
                settings: 'Beállítások',
                unknown: 'Ismeretlen',
            },
            consumerApp: {
                enableConsumerFeedback: 'Felhasználói visszajelzés engedélyezése a MySelfly rendszeren',
                enablePurchaseReceipts: 'E-nyugták engedélyezése a MySelfly rendszeren',
                enableSelflyTrademarkForReceipts: 'A Selfy Store lábléc engedélyezése az e-nyugtákon',
                enableSelflyTrademarkForReceiptsHelpText: 'Ennek engedélyezése hozzáadja a Selfly Store logót és a részleteket az e-nyugta PDF-hez!',
                organizationFormHelpText: 'Kérjük, adja meg az e-nyugta kibocsátójának adatait.',
                organizationFormValidationHelpText: '* Az e-nyugta kötelező mezői a Kibocsátó szervezetének neve, cégazonosítója, címe és országa.',
                organizationName: 'Szervezet neve',
                organizationLogo: 'Szervezet logója',
                businessId: 'Cég azonosítója',
                city: 'Város',
                email: 'E-mail',
                website: 'Webhely',
                postalCode: 'Irányítószám',
                street: 'Utca, házszám',
                phoneNumber: 'Telefonszám',
                addressHeader: '* Cím információ',
                contactDetails: 'Elérhetőség',
                organization: '* Kibocsátó szervezete',
                organizationSettingsUpdatedTitle: 'Szervezeti beállítások frissítése',
                organizationSettingsUpdatedContent: 'Szervezeti beállítások frissítése sikeres',
                organizationSettingsMissingFields: 'Az e-nyugta kötelező mezői hiányoznak. Kérjük, töltse ki a mentés előtt.',
                organizationSettingsMissingFieldsTitle: 'Űrlapérvényesítési hiba',
            },
            softwareVersions: {
                updateNotificationTitle: 'Szoftververzió frissítése',
                updateNotificationContent: 'Szoftververzió(k) sikeresen frissítve',
                selectAVersionPlaceholder: 'Verzió kiválasztása',
                searchDevices: 'Eszközök keresése',
                updateSelected: 'Frissítés kiválasztva',
                softwareVersions: 'Szoftververziók',
                softwareVersion: 'Szoftververzió',
                notSupportedDeviceNote: 'A(z) {{selectedVersion.version}} verzió nem támogatott ezen az eszközön',
                userNotificationContent: 'Új szoftververzió jelent meg az eszközökhöz. Frissítse eszközeit a legújabb funkciók eléréséhez.',
                userNotificationTitle: 'Megjelent az új szoftververzió',
                notificationBell_newSoftwareVersions: 'Az eszközökhöz elérhető frissítések',
                manageVersions: 'Eszközszoftver-verziók kezelése',
                filterLocationsPlaceholder: 'Szűrés helyszínek szerint',
                allVersionNumbers: 'Összes kiadás',
                noVersionsForUpdating: 'Nincsenek frissítendő verziók',
                scheduledUpdateNote: 'A(z) {{version}} verzió frissítésének tervezett időpontja: {{scheduled}}',
                scheduleRemoveNotificationTitle: 'Szoftververziók ütemezése',
                scheduleRemoveNotificationContent: 'A szoftververzió ütemezett frissítése sikeresen eltávolítva',
                scheduleRemoveNotificationModalContent: 'Biztosan eltávolítja az eszköz ütemezett frissítését?',
            },
            header: {
                searchByOrganization: 'Keresés szervezet szerint',
                pageLanguage: 'Nyelv',
                configureMySelflyAndEreceipts: 'MySelfly beállítások konfigurálása',
                remindMeLater: 'Emlékeztess később',
                updateNow: 'Frissítse most',
            },
            notifications: {
                noNewNotifications: 'Nincsenek új értesítések',
            },
            cabinetSettings: {
                cabinetTheme: 'Szekrény téma',
                editAndSaveTheme: 'Téma szerkesztése és mentése',
                colorPicker: 'Színválasztó',
                customTheme: 'Egyéni téma',
                themeSettings: 'Téma beállítások',
                primary: 'Elsődleges',
                negative: 'Negatív',
                attention: 'Figyelem',
                positive: 'Pozitív',
                background: 'Háttér',
                dark: 'Sötét',
                light: 'Világos',
                white: 'Fehér',
                default: 'Alapértelmezett',
                negativeLight: 'Negatív fény',
                primaryColor: 'Elsődleges szín',
                positiveColors: 'Pozitív színek',
                negativeColors: 'Negatív színek',
                secondaryColors: 'Másodlagos színek',
                attentionColors: 'Figyelemfelhívó színek',
                screenBackground: 'Képernyő háttere',
                borderColors: 'Szegélyek - Árnyékok',
                shadow: 'Árnyék',
                border: 'Szegély',
                fontColors: 'Betűszínek',
                footerTextPlaceholder: 'Lábléc szövege',
                bundleDiscounts: 'Kombinálás és mentés',
                expiringText: 'Lejáró termékek. Kérjük, vásárlás előtt ellenőrizze a lejárati dátumot.',
                expiredText: 'Lejárt termékek. Kérjük, vásárlás előtt ellenőrizze a lejárati dátumot.',
                cabinetName: 'Szekrény neve',
                productName: 'Termék neve',
                confirmationModalHeader: 'Biztosan menti a testreszabott témát?',
                confirmationModalContent:
                    'A téma testreszabása befolyásolja a szekrény képernyőjének vizuális megjelenését. Ügyeljen arra, hogy a képernyő vizuális minősége ne sérüljön a változásokkal.',
                defaultRevertModalMessage: 'Biztosan alapértelmezésre szeretné állítani a szekrény témáját?',
                backgroundColor: 'Háttérszín',
                backgroundImage: 'Háttérkép',
                footerBackground: 'Lábléc háttér',
                cardsBackground: 'Kártya háttér',
                screenImage: 'Kép a szekrény képernyőjének háttérképeként',
                uploadImage: 'Kép feltöltése',
                editImage: 'Kép szerkesztése',
                transactionComplete: 'Tranzakció befejeződött',
                customPostPurchaseImage: 'Kérjük, töltsön fel egy képet a testreszabott vásárlás utáni képernyőhöz',
                postPurchaseScreen: 'Vásárlás utáni képernyő',
                custom: 'testreszabott',
                postPurchaseText: 'Vásárlás utáni megjelenítési szöveg',
            },
            automations: {
                transactionReports: 'Tranzakciós jelentések',
                refillReports: 'Utántöltési jelentések',
                notifications: 'Értesítések',
                refillPlan: 'Utántöltési tervek',
                days: 'Napok',
                months: 'Hónapok',
                weeks: 'Hét',
                configuration: 'Konfiguráció',
                transactionReportTitle:
                    'A kiválasztott szekrények tranzakciós jelentése automatikusan generálva lesz, és az alábbi e-mail címekre elküldésre kerül.',
                refillReportTitle:
                    'A kiválasztott szekrények utántöltési jelentése automatikusan generálva lesz, és az alábbi e-mail címekre elküldésre kerül.',
                noSalesNotificationTitle:
                    'Értesítések automatikusan elküldésre kerülnek az alábbi e-mail címekre, ha a megadott időszakokban nem történik értékesítés.',
                frequencyTitle: 'Milyen gyakran szeretné, hogy a jelentés generálódjon?',
                emailNotificationTitle: 'Kérjük, adja meg az alábbi e-mail címeket, ahová szeretné, hogy a jelentést vagy értesítést elküldjük.',
                refillPlanGenerationTitle: 'Az utánöltési tervek automatikusan generálódnak a kiválasztott szekrények számára a megadott ütemezés szerint.',
                addNewEmail: 'Új e-mail hozzáadása',
                noSalesBanner: 'Értesítést fog kapni, ha a kiválasztott szekrények nem végeznek eladást a beállított időszakban.',
                noSaleTitle: 'Kérjük, adja meg az időtartamot (órákban) az értékesítések hiányában a kiválasztott szekrényekben, mielőtt értesítést küldene.',
                minMaxTime: 'Minimális 2, maximális 23 óra.',
                timeRangeTitle: 'Kérjük, adja meg az időintervallumot a szekrények számára, amikor az értékesítési helyzetet ellenőrzik.',
                startTime: 'Kezdési idő',
                endTime: 'Befejezési idő',
                refillPlanTitle:
                    'A kiválasztott szekrények utánöltési terve automatikusan generálva lesz. Kérjük, ütemezze alább azt az időpontot, amikor szeretné, hogy az utánöltési terv elkészüljön.',
                refillPlanTime: 'Kérjük, válassza ki az időt az utánöltési terv generálásához.',
                everyDay: 'Minden nap',
                everyWeek: 'Minden hét',
                everyMonth: 'Minden hónapban',
                selectDays: 'Napok kiválasztása',
                automationSaved: 'Az automatizálási beállítások sikeresen mentve',
                automationDeleted: 'Az automatizálási beállítások sikeresen törölve',
            },
            sleepScheduling: {
                timeRangeTitle: 'Kérjük, adja meg azt az időintervallumot, amely alatt ez a szekrény készenléti üzemmódba kerül.',
                startTime: 'Kezdési idő',
                endTime: 'Befejezési idő',
                selectDays: 'Napok kiválasztása',
                duration: 'Időtartam (órák)',
            },
        },
        lv: {
            dateRangeFilter: {
                lastMonth: 'pēdējās 30 dienas',
                lastTwoWeek: 'pēdējās 14 dienas',
                lastWeek: 'pēdējās 7 dienas',
                selectDateRangeMsg: 'Atlasītais datumu diapazons',
                today: 'šodien',
                yesterday: 'vakar',
            },
            main: {
                activateSuccess: 'Izdevās aktivizēt produktu',
                activationDate: 'Aktivizācijas datums',
                active: 'Aktīvs',
                addAdvertisement: 'Pievienot jaunu reklāmu',
                addDiscountRule: 'Pievienot atlaides noteikumu',
                addDiscountStop: 'Pievienojiet derīguma termiņa beigu intervālu',
                addNewLocation: 'Izvēlēties jaunu atrašanās vietu',
                addProduct: 'Pievienot jaunu produktu',
                addRegion: 'Pievienot atrašanās vietu',
                addRegionPrice: 'Pievienot atrašanās vietas cenu',
                addScheduledDiscount: 'Pievienot plānoto atlaidi',
                addBundleDiscount: 'Pievienot komplekta atlaidi',
                bundledDiscounts: 'Komplekta atlaides',
                bundleItemsLengthExceeded: 'Pārsniegta produktu maksimālā izvēle (3). Lai turpinātu, noņemiet dažas atlases.',
                bundleMaxProductLimitInfo: 'Atlasiet ne vairāk kā trīs (3) produktus vai produktu kategorijas',
                bundleFixedAmountExceededOriginal: 'Komplekta fiksētā cena pārsniedz sākotnējās cenas summu. Pārbaudiet atlasītos produktus.',
                bundleFixedAmountNotAllowedToBeZero: 'Komplekta fiksētajai cenai jāpārsniedz 0',
                bundlePercentageNotAllowedToBeFull: 'Komplekta procentu atlaidei jābūt zem 100',
                bundlePercentageNotAllowedToBeZero: 'Komplekta procentu atlaidei jāpārsniedz 0',
                bundleItemDiscountPriceAmountExceededOriginal: 'Jaunā atlaižu cena pārsniedz sākotnējo cenu. Vēlreiz pārbaudiet jauno cenu.',
                bundleItemPriceMessage:
                    'Katrs cenu noteikšanas noteikums attiecas tikai uz 1 preci, pat ja ir atlasītas vairākas vienas un tās pašas preces vienības',
                bundleItemFixedAmountExceededOriginal: 'Produkta fiksētā cena pārsniedz tā sākotnējo cenu. Vēlreiz pārbaudiet fiksēto cenu.',
                createDiscountRule: 'Izveidot atlaides kārtulu',
                deviceType: 'Ierīces veids',
                discountUpdateTitle: 'Atlaides atjauninājums',
                discountCreateTitle: 'Izveidot atlaidi',
                discountDeleteTitle: 'Atlaižu noņemšana',
                discountDeleteContent: 'Atlaide ir noņemta',
                discountStatusUpdated: 'Atlaides statuss ir atjaunināts',
                discountUpdated: 'Atlaide ir atjaunināta',
                discountCreated: 'Atlaide ir izveidota',
                discountSettings: 'Atlaižu iestatījumi',
                discountImage: 'Atlaides neobligātais attēls',
                editDiscountStop: 'Rediģējiet derīguma termiņa beigu intervālu',
                editBundleConfiguration: 'Rediģēt komplekta konfigurāciju',
                editExpiryConfiguration: 'rediģēt termiņa atlaides konfigurāciju',
                encodingStation: 'Kodēšanas stacija',
                everyDay: 'Katru dienu',
                scheduledDiscountDateInfoLabel: 'Ja vēlaties, lai atlaide būtu pastāvīga, lūdzu, atstājiet tukšu sākuma un beigu datumu',
                starts: 'Sākums',
                ends: 'Beigas',
                error: 'Kļūda',
                errorRetiringLocationMsg: 'Neizdevās noņemt atrašanās vietu. Lūdzu, vispirms noņemiet saistītās ierīces, produktu cenas un reklāmas.',
                fetchingDiscountRules: 'Atlaižu noteikumu iegūšana',
                free: 'Bez maksas',
                missingImage: 'Nav reklāmas attēla',
                next: 'Nākamais',
                back: 'Atpakaļ',
                noDiscountRules: 'Atlaižu noteikumi nav atrasti',
                scheduledDiscountsAutomaticDeactivationInfo:
                    'Ja tiek pārsniegts datumu diapazons, atlaides tiek automātiski deaktivizētas. Ja vēlaties tās aktivizēt, rediģējiet datumu diapazonu, lai tas būtu derīgs.',
                scheduledDiscounts: 'Plānotās atlaides',
                schedulingStartHourIsOverEndHour: 'Sākuma laiks nevar būt pēc beigu laika',
                schedulingEndHourIsUnderStartHour: 'Beigu laiks nevar būt pirms sākuma laika',
                selectDiscountRuleProductCategories: 'Atlasiet produktu kategorijas, uz kurām attiecas atlaides noteikums',
                selectBundleProductsAmount: 'Izvēlieties summu par ',
                selectedBundleProductCategories: 'Atlasītās komplekta produktu kategorijas',
                setDiscountDays: 'Iestatiet atlaižu dienas',
                setBundleDiscount: 'Komplektam iestatiet procentuālo atlaides summu vai fiksētu cenu',
                setBundleItemDiscount: 'Iestatiet vienai precei procentuālo atlaides summu, fiksētu cenu vai bez maksas',
                setBundleProducts: 'Iestatiet produktus vai kategorijas komplektam',
                setAmountMoreThanOne: 'Viena preču komplekta gadījumā iestatiet daudzumu, kas ir vairāk nekā viens',
                setBundleDiscountType: 'Iestatiet atlaides veidu un tās summu',
                sureActivateDiscountRule: 'Vai tiešām aktivizēt atlaižu kārtulu?',
                sureDeactivateDiscountRule: 'Vai tiešām deaktivizēt atlaižu kārtulu?',
                sureDeleteDiscountRule: 'Vai tiešām dzēst atlaižu kārtulu?',
                timeRange: 'Laika diapazons',
                editExpiryDiscountRule: 'Rediģēt atlaides kārtulu',
                editScheduledDiscountRule: 'Rediģēt ieplānotās atlaides kārtulu',
                editBundleDiscountRule: 'Rediģēt komplekta atlaides kārtulu',
                releaseNotes: 'Izlaiduma piezīmes',
                seeMore: 'Redzēt vairāk',
                scheduledDiscountTimeRangeLabel: 'Aktīvais laiks',
                scheduledDiscountDateRangeLabel: 'Atlaides datumu diapazons',
                weekDayAbrvMonday: 'Pirmd.',
                weekDayAbrvTuesday: 'Otrd.',
                weekDayAbrvWednesday: 'Tr.',
                weekDayAbrvThursday: 'Cet.',
                weekDayAbrvFriday: 'Piektd.',
                weekDayAbrvSaturday: 'Sestd.',
                weekDayAbrvSunday: 'Sv.',
                recurrence: 'Atkārtošanās',
                addScheduledDiscountStop: 'Pievienojiet plānoto atlaidi',
                editScheduledDiscountStop: 'Rediģējiet plānoto atlaidi',
                pieces: 'gab.',
                expiryDiscountNavLabel: 'Derīguma termiņš',
                scheduledDiscountNavLabel: 'Plānots',
                bundleDiscountNavLabel: 'Komplekts',
                amount: 'Summa',
                scheduling: 'Plānošana',
                pricing: 'Cenu noteikšana',
                percentageBundleDiscountInputPlaceholder: '% atlaide komplektam',
                fixedPriceBundleDiscountInputPlaceholder: 'Fiksēta cena komplektam',
                applyBundleLevelLabel: 'Lietot komplekta līmeni',
                applyItemLevelLabel: 'Lietot vienības līmeni',
                productFixedPrice: 'Produkta fiksētā cena',
                percentageDiscount: '% atlaide',
                fixedPriceDiscount: 'Fiksētā cena',
                freeDiscount: 'Bez maksas',
                advertisementsFetchError: 'Ienesot reklāmas, radās kļūda. Pārliecinieties, vai reklāmu bloķētājs ir izslēgts',
                advertisementsTitle: 'Reklāmas',
                all: 'Viss',
                'All time': 'Viss laiks',
                allProducts: 'Visi produkti bez grupēšanas',
                allLocations: 'Visas atrašanās vietas',
                allCabinets: 'Visas vitrīnas',
                antennaDown: 'Dažas RFID antenas nedarbojas',
                antennaError: 'Vitrīnas antenu kļūda',
                antennaNoInfo: 'Informācija par vitrīnas antenām nav pieejama',
                antennaOk: 'Darbojas visas antenas',
                antennaPartial: 'Dažas antenas ir atspējotas',
                archiveSuccess: 'Produkts sekmīgi arhivēts',
                averageItems: 'Pozīcijas vidēji vienā darījumā',
                averageSales: 'Vidējais pārdošanas apjoms vienā darījumā',
                barcode: 'Svītrkods',
                barcodeType: 'Svītrkoda veids',
                barcodeCannotBeChanged: 'Svītrkodu nevar mainīt',
                barcodeTypeCannotBeChanged: 'Svītrkoda veidu nevar mainīt',
                barcodeHelpText12: 'Ievadiet 12 svītrkoda ciparus, pēdējais cipars tiek pievienots automātiski',
                barcodeHelpText11: 'Ievadiet 11 svītrkoda ciparus, pēdējais cipars tiek pievienots automātiski',
                barcodeHelpText7: 'Ievadiet 7 svītrkoda ciparus, pēdējais cipars tiek pievienots automātiski',
                beforeExpiry: 'pirms derīguma termiņa beigām',
                cabinetDown: 'Vitrīna nedarbojas',
                cabinetIsEmpty: 'Vitrīna ir tukša',
                deviceOffline: 'Ierīce ir bezsaistē',
                deviceOnline: 'Ierīce ir tiešsaistē',
                cabinets: 'Vitrīnas',
                cabinet: 'Vitrīna',
                cabinetSettings: 'Vitrīnas iestatījumi',
                cabinetTheming: 'Vitrīnas dizains',
                cancel: 'Atcelt',
                cancelledSales: 'Atceltās pārdošanas',
                cancelledTransactions: 'Atceltie darījumi',
                categories: 'Kategorijas',
                change: 'Mainīt',
                clear: 'Notīrīt',
                close: 'Aizvērt',
                clickEditButtonRegionalPrices: 'Lai rediģētu atrašanās vietu cenas, noklikšķiniet uz produkta pogas Rediģēt.',
                clickEditLocationPrices: 'Vietējās cenas, noklikšķiniet uz produkta cenas, lai izvērstu.',
                clickToExpandLocationPrices: 'Vietējās cenas, noklikšķiniet uz produkta cenas, lai izvērstu.',
                closeDoor: 'Aizveriet durvis',
                rebootDevice: 'Atsāknējiet ierīci',
                commandSentSuccessFully: 'Komanda nosūtīta sekmīgi',
                companyLogo: 'Uzņēmuma logo',
                confirm: 'Apstiprināt',
                confirmDelete: 'Vai tiešām dzēst produktu? Šo darbību nevar atsaukt!',
                confirmDisable: 'Vai tiešām arhivēt produktu?',
                confirmEnable: 'Vai tiešām aktivizēt produktu?',
                consumerResponses: 'Lietotāja atsauksmes',
                controlPanelTitle: 'Vadības panelis',
                controls_acceptablePercentageOfBadTags: 'Pieļaujamais slikto birku procentuālais daudzums pirms vitrīnas izslēgšanas no darba režīma',
                controls_markTagsGoodQuality: 'Atzīmējiet visas birkas labā kvalitātē',
                controls_markTagsGoodQuality_btn: 'Atzīmējiet birkas labas kvalitātes',
                controls_clearTagHistory: 'Notīrīt birku vēsturi',
                controls_clearRemoveReplenishmentData: 'Notīrīt papildināšanas datus',
                controls_companyAndPaymentLogo: 'Uzņēmuma un maksājuma logotips',
                controls_detectTagQuality: 'Noteikt birku kvalitāti',
                controls_door: 'Durvju vadība',
                controls_device: 'Ierīces vadība',
                controls_chargeBadTags: 'Rēķina sliktas atzīmes',
                controls_refill: 'Uzpildīt atkārtoti',
                controls_tagQuality: 'Birku iestatījumi',
                controls_tagQualityData: 'Birku kvalitātes dati',
                controls_replenishmentData: 'Papildināšanas dati',
                controls_soldTags: 'Pārdotas birkas',
                controls_tagHistory: 'Birku vēsture',
                controls_markAllTagsReplenished: 'Atzīmējiet visas birkas kā papildinātas',
                controls_markAllTagsUnsold: 'Atzīmēt visas birkas kā nepārdotas',
                controls_markAlTagsReplenished_btn: 'Atzīmējiet birkas kā papildinātas',
                controls_markAllTagsUnsold_btn: 'Atzīmējiet birkas kā nepārdotas',
                controls_chargeSoldTags: 'Rēķina pārdotās birkas',
                controls_chargeUnreplenishedTags: 'Rēķina nepapildinātās birkas',
                controls_tagsWarn:
                    'Notīrot birku vēsturi, tiek noņemti visi birku dati. Lūdzu, pēc šīs darbības noklikšķiniet uz atzīmēt visus birkas kā papildinātas',
                create: 'Izveidot',
                createAdTitle: 'Jauna reklāma',
                createdAd: 'Izveidotā reklāma',
                createExpiryDiscountRule: 'Izveidot atlaides beigu termiņa kārtulu',
                createScheduledDiscountRule: 'Izveidot plānotās atlaides kārtulu',
                createBundleDiscountRule: 'Izveidot atlaides kārtulu',
                createNewVatCategory: 'Jauna PVN kategorija',
                createProductTitle: 'Jauns produkts',
                creatingAd: 'Reklāmas izveidošana',
                creatingProduct: 'Produkta izveidošana',
                createNewProductCategory: 'Izveidot jaunu produktu kategoriju',
                createNewSupplier: 'Pievienot jaunu piegādātāju',
                currency: 'Valūta',
                customQuery: 'Pielāgots vaicājums',
                dashboard: 'Informācijas panelis',
                dateRange: 'Datumu diapazons',
                dataAlredyFetchedTitle: 'Dati jau ienesti',
                dataAlreadyFetchedDescription: 'Lūdzu, izvēlieties citus parametrus',
                day: 'Diena',
                days: 'Dienas',
                deactivationDate: 'Deaktivizācijas datums',
                default: 'Noklusējums',
                delete: 'Dzēst',
                deleteError: 'Kļūda: produktu nevarēja dzēst',
                deleteSuccess: 'Produkts sekmīgi dzēsts',
                description: 'Apraksts',
                deviceManagement: 'Ierīces pārvaldība',
                digitwinOffline: 'Ierīces digitwin ir bezsaistē',
                digitwinOnline: 'Ierīces digitwin ir tiešsaistē',
                dimensions: 'Izmēri',
                disable: 'Arhīvs',
                discountAmount: 'Atlaides summa',
                discountPrice: 'Atlaides cena',
                discountRuleAffects: 'Atlaides kārtula ietekmē',
                discountRuleName: 'Atlaides kārtulas nosaukums',
                discountRuleStops: 'Derīguma termiņa beigu intervāli',
                discounts: 'Atlaides',
                discountSalesData: 'Atlaižu pārdošanas dati',
                discountSalesAmount: 'Atlaides pārdošanas summa',
                discountTimeBeforeExpiryExists: 'Agrāks derīguma termiņa beigu intervāls jau pastāv',
                discountVsNormalSales: 'Atlaide salīdzinājumā ar parasto pārdošanu',
                dismiss: 'Atmest',
                doesNotExist: 'Nepastāv',
                doorCloseConfirm: 'Vai tiešām aizvērt durvis?',
                doorCloseSuccess: 'Sekmīgi aizslēgt durvis',
                doorOpenConfirm: 'Vai tiešām atvērt durvis?',
                doorOpenReason: 'Kāpēc jūs atverat durvis?',
                doorOpenSuccess: 'Durvis veiksmīgi atvērtas',
                rebootDeviceSuccess: 'Ierīce veiksmīgi restartēta',
                deviceRebootConfirm: 'Vai tiešām atsāknēt ierīci?',
                edit: 'Rediģēt',
                editCompanyLogo: 'Rediģēt uzņēmuma logotipu',
                editDiscountRule: 'Rediģēt atlaides kārtulu',
                editPaymentMethodLogo: 'Rediģēt maksājuma veida logotipu',
                editVatCategoryModalTitle: 'Rediģēt PVN kategoriju',
                editProductCategoryModalTitle: 'Rediģēt produktu kategoriju',
                editSupplierModalTitle: 'Rediģēt piegādātāju',
                enable: 'Aktivizēt',
                error_401: 'Nav autentificēts',
                error_403: 'Nav pilnvarots izpildīt komandas',
                error_500: 'Negaidīta servera kļūda',
                errorCreatingAd: 'Veidojot reklāmu, radās kļūda',
                errorCreatingProduct: 'Veidojot produktu, radās kļūda',
                errorFetchingCabinetStatus: 'Ienesot vitrīnas statusu, radās kļūda',
                errorFetchingInventory: 'Ienesot vitrīnas inventāru, radās kļūda',
                errorFetchingReport: 'Ienesot atskaites, radās kļūda',
                errorFetchingSettings: 'Ienesot iestatījumus, radās kļūda',
                errorUpdatingProduct: 'Atjauninot produktu, radās kļūda',
                expandToSeeBadTags: 'Izvērsiet sarakstu, lai redzētu birkas, kas tika noteiktas kā bojātas. Nederīgās birkas ir jāizņem no vitrīnas.',
                expandToSeeSoldItems: 'Izvērsiet sarakstu, lai redzētu preces, kas jau ir pārdotas, bet ir atkal parādījušās inventārā.',
                expandToSeeReplenishedOutOfInventory:
                    'Izvērsiet sarakstu, lai redzētu produktus, kas ir ievietoti vitrīnā bez inventāra papildināšanas procesa',
                expired: 'Beidzies derīguma termiņš',
                expiredCount: 'Daudzums ar beigušos derīguma termiņu',
                expiredProductsCount: 'Produktu ar beigušos derīguma termiņu skaits',
                expiredSum: 'Preču, kurām beidzies derīguma termiņš, summa',
                expiring: 'Beidzas derīguma termiņš',
                expiryDate: 'Pēdējā izpārdošanas diena',
                exportExcel: 'Eksportēt Excel ',
                fetchAdvertisements: 'Reklāmu ienešana',
                fetchingCabinets: 'Vitrīnu datu ienešana',
                fetchingCabinetsError: 'Ienesot vitrīnas datus, radās kļūda',
                fetchingCabinetStatus: 'Vitrīnas statusa ienešana',
                fetchingError: 'Ienesot radās kļūda',
                fetchingInventory: 'Vitrīnas inventāra ienešana',
                fetchingProducts: 'Produktu ienešana',
                fetchingReport: 'Atskaišu ienešana',
                fetchingVatCategories: 'PVN kategoriju ienešana',
                fetchingProductCategories: 'Produktu kategoriju ienešana',
                fillForm: 'Aizpildiet veidlapu',
                freshFood: 'Šis ir beidzams produkts',
                freezerPackage: 'Sasaldēts produkts',
                generalFetchError: 'Ienešanas kļūda',
                generalRequestReject: 'Pieprasījuma kļūda',
                get: 'Iegūt',
                group: 'Grupa',
                holdOn: 'Uzgaidiet',
                hour: 'Stunda',
                hours: 'stundas',
                hoursBeforeExpiry: 'Stundas līdz derīguma termiņa beigām',
                daysBeforeExpiry: 'Dienas līdz termiņa beigām',
                image: 'Attēls',
                imageOrVideo: 'Atlasīt attēlu vai video',
                inActive: 'Neaktīvs',
                individualCabinets: 'Atsevišķas vitrīnas',
                individualCabinetsOnly: 'Tikai atsevišķas vitrīnas',
                invalidBarcodeOnlyDigits: 'Svītrkods drīkst saturēt tikai ciparus',
                invalidBarcodeTooLong: 'Pārāk garš svītrkods',
                invalidBarcodeTooShort: 'Pārāk īss svītrkods',
                invalidCabinetNameTooShort: 'Pārāk īss vitrīnas nosaukum',
                invalidCabinetNameDuplicated: 'Šāds vitrīnas nosaukums jau pastāv',
                invalidLocationNameTooShort: 'Atrašanās vietas nosaukums ir pārāk īss',
                invalidLocationNameDuplicated: 'Dublēts atrašanās vietas nosaukums',
                invalidPercentage: 'Nederīga vērtība',
                invalidPrice: 'Nederīga cena',
                invalidProductBarcode: 'Nederīgs produkta svītrkods',
                invalidTitle: 'Nederīgs nosaukums',
                invalidBundleItems: 'Komplektā ir nederīgas preces',
                inventoryLength: 'Inventāra garums',
                inventoryNotReported: 'Vitrīna nav ziņojusi par savu inventāru',
                inventoryTitle: 'Vitrīnas inventārs',
                'Inventory History': 'Inventāra vēsture',
                itemReplenishedOutOfInventoryProcess: 'Šis produkts vai birka ir ievietota vitrīnā citā (nes papildināšanas) procesā',
                itemSoldOn: 'Prece pārdota',
                'Last 30 days': 'Pēdējās 30 dienas',
                'Last 7 days': 'Pēdējās 7 dienas',
                'Last month': 'Pēdējais mēnesis',
                'Last quarter': 'Pēdējais ceturksnis',
                'Last week': 'Pēdējā nedēļa',
                'Last year': 'Pēdējais gads',
                lastUpdate: 'Pēdējais atjauninājums',
                lifetime: 'Darbmūžs',
                location: 'Atrašanās vieta',
                locations: 'Atrašanās vietas',
                logoEditor: 'Logotipa redaktors',
                logoSettings: 'Logotipa iestatījumi',
                loading: 'Ielādē…',
                measure: 'Mērs',
                measures: 'Mēri',
                minsBeforeExpiry: 'Minūtes līdz derīguma termiņa beigām',
                minutes: 'minūtes',
                month: 'Mēnesis',
                name: 'Nosaukums',
                newVatCategoryModalTitle: 'Jauna PVN kategorija',
                newProductCategoryModalTitle: 'Jauna produktu kategorija',
                newSupplierModalTitle: 'Jauns piegādātājs',
                'no region': '(nav atrašanās vietas)',
                noAdvertisements: 'Nav reklāmu',
                noCabinets: 'Nav vitrīnu',
                noCabinetSelected: 'Nav atlasīta neviena vitrīna',
                noLogo: 'Nav logotipa',
                noData: 'Nav datu',
                noDescription: 'Nav apraksta',
                noEvents: 'Nav notikumu!',
                noIndividualCabinets: 'Nav atsevišķu vitrīnu',
                noLocationPrices: 'Šim produktam nav noteiktas vietējās cenas',
                none: 'Nav',
                noPaymentMethodLogo: 'Nav maksājuma veida logotipa',
                noProducts: 'Nav produktu',
                noProductsRemoved: 'Neviens produkts nav noņemts',
                noProductTypeSelected: 'Nav atlasīts iepakojuma veids',
                normalPrice: 'Standarta cena',
                normalSalesAmount: 'Standarta pārdošanas summa',
                notAccessibleForYou:
                    'Izveides/atjaunināšanas funkcija ir iespējota tikai administratoriem un vitrīnas operatoriem. Lai iegūtu piekļuvi, sazinieties ar pārraugu vai sistēmas administratoru.',
                notDefined: 'Nav definēts',
                noTitle: 'Nav nosaukuma',
                noVatCategories: 'PVN kategorijas ieraksts nav atrasts',
                noProductCategories: 'Produktu kategorijas ieraksts nav atrasts',
                noInventoryOnSelectedDate: 'Jūsu atlasītajam datumam nav ziņojuma par inventāru, lūdzu, izmēģiniet citu datumu.',
                openDoor: 'Atvērt durvis',
                orderSum: 'Pasūtījuma summa',
                paymentMethodLogo: 'Maksājuma veida logotips',
                percentage: 'Procenti',
                permanent: 'Pastāvīgs',
                preview: 'Priekšskatījums',
                price: 'Cena',
                print: 'Drukāt',
                productCreated: 'Produkts izveidots',
                products: 'Produkti',
                productsFetchError: 'Ienesot produktus, radās kļūda',
                productsTitle: 'Produktu katalogs',
                productType: 'Iepakojuma veids',
                productUpdated: 'Produkts atjaunināts',
                quantity: 'Daudzums',
                readerDown: 'RFID lasītājs nedarbojas',
                region: 'Atrašanās vieta',
                regions: 'Atrašanās vietas',
                removedAt: 'Noņemts',
                removeImage: 'Noņemt attēlu',
                removeVideo: 'Noņemt video',
                refillQRCodeInfo: 'Noskenējiet QR kodu, lai atvērtu uzpildīšanas lietojumprogrammu, vai sekojiet',
                refillQRCodeTitle: 'Atkārtoti ievadiet QR kodu',
                reportsTitle: 'Atskaites',
                refillRuleTitle: 'uzpildes kārtula',
                requestSuccess: 'Izmaiņas veiktas sekmīgi',
                requiredPercentage: 'Obligāti. Procentuālā vērtība bez procentu zīmes.',
                requiredText: 'Nepieciešamais rakstzīmju garums: ',
                retireLocation: 'Noņemt atrašanās vietu',
                rfidNoInfo: 'Nav pieejama informācija par RFID lasītāju un antenām',
                rfidOk: 'RFID lasītājs un antenas nedarbojas kā paredzēts.',
                sales: 'Pārdošanas',
                salesCount: 'Pārdošanu skaits',
                salesVsWasteAmount: 'Pārdošanas-zaudējumu attiecība',
                save: 'Saglabāt ',
                search: 'Meklēt',
                selectCabinet: 'Atlasīt vitrīnu',
                selectDiscountRuleCabinets: 'Atlasīt vitrīnas, kuras ietekmē atlaižu kārtula',
                selectDiscountRuleLocations: 'Atlasīt atrašanās vietas, kuras ietekmē atlaižu kārtula',
                selectDiscountRuleProducts: 'Atlasīt produktus, kurus ietekmē atlaižu kārtula',
                selectProductType: 'Lūdzu, izvēlieties produktam iepakojuma veidu',
                setDiscountAmount: 'Iestatīt atlaides summu',
                setDiscountTimeBeforeExpiry: 'Iestatiet derīguma termiņa beigu intervālu',
                settingConfirmMessage: 'Vai tiešām saglabāt šos iestatījumus? Ja neesat pārliecināts, neapstipriniet',
                showDisable: 'Arhivētie produkti',
                summary: 'Kopsavilkums',
                sureRemoveDiscountRule: 'Vai tiešām noņemt atlaižu kārtulu?',
                tagIsBad: 'Birka ir bojāta, jāizņem no vitrīnas.',
                tagsRemovedReport: 'Noņemto birku atskaite',
                taxValue: 'Nodokļa vērtība',
                'This month': 'Šis mēnesis',
                'This quarter': 'Šis ceturksnis',
                'This week': 'Šī nedēļa',
                'This year': 'Šis gads',
                thisLink: 'šī saite.',
                timeGrouping: 'Laika grupēšana',
                title: 'Nosaukums',
                Today: 'Šodien',
                topExpiredProducts: 'Populārākie produkti ar beigušos derīguma termiņu',
                topProducts: 'Populārākie produkti',
                total: 'Kopā',
                totalValue: 'Kopējā vērtība',
                transactions: 'Darījumi',
                ttlInDays: 'Produkta atlikušais realizācijas termiņš dienās',
                ttlsAffected: 'Produkti ar ietekmētu realizācijas termiņu',
                unexpectedError: 'Neparedzēta kļūda',
                uniqueCustomers: 'Unikālie klienti',
                unknown: 'Nezināms',
                update: 'Atjauninājums',
                updateAdError: 'Atjauninot reklāmu, radās kļūda',
                updateAdTitle: 'Atjaunināt reklāmu',
                updatedAd: 'Reklāma atjaunināta',
                updateCabinetSettings: 'Sinhronizēt datus ar vitrīnu',
                updateCabinetSettingsInfo: 'Vai tiešām atjaunināt vitrīnas iestatījumus?',
                updateData: 'Atjaunināt datus',
                updateProductTitle: 'Atjaunināt produktu',
                updatingAd: 'Reklāmas atjaunināšana',
                updatingProduct: 'Atjaunināt produktu',
                uploading: 'Augšupielāde',
                uploadingImage: 'Attēla augšupielāde',
                use: 'Izmantot',
                'w/o grouping': 'Nav grupēšanas',
                waste: 'Atkritumi',
                vatCategoriesFetchError: 'Ienesot PVN kategorijas, radās kļūda',
                vatCategoriesTitle: 'PVN kategorijas',
                vatCategory: 'PVN kategorija',
                vatCategoryItems: 'Preces',
                vatCategoryName: 'PVN kategorijas nosaukums',
                vatCategoryPromptMessage: 'Vai tiešām dzēst PVN kategoriju',
                vatCategoryValue: 'Nodokļa vērtības procenti',
                vatDeleteRejected: 'PVN kategorijas dzēšana noraidīta. Pārbaudiet saistītos produktus.',
                vatErrorRequesting: 'Apstrādājot pieprasījumu, radās kļūda',
                vatRequestRejected: 'Pieprasījums noraidīts',
                vats: 'PVN',
                productCategoriesFetchError: 'Ienesot produktu kategorijas, radās kļūda',
                productCategoriesTitle: 'Produktu kategorijas',
                productCategory: 'Produkta kategorija',
                productCategoryItems: 'Kategorijas',
                productCategoryName: 'Produktu kategorijas nosaukums',
                productCategoryPromptMessage: 'Vai tiešām dzēst produktu kategoriju?',
                productCategoryDescription: 'Produktu kategorijas apraksts',
                productCategoryHelpTextDescription: 'Piemēram, salātu kategorija',
                productCategoryHelpTextName: 'Piemēram, salāti',
                productCategoryDeleteRejected: 'Produktu kategorijas dzēšana noraidīta. Pārbaudiet saistītos produktus!',
                week: 'Nedēļa',
                viewMode: 'Skats',
                year: 'Gads',
                Yesterday: 'Vakar',
                groupBy: 'Grupēt pēc',
                filterBy: 'Filtrēt pēc',
                reportType: 'Atskaites tips',
                custom: 'Pielāgot',
                preDefined: 'Iepriekš definēts',
                selected: 'Atlasīts',
                metricsInfo: 'Metrika (summa, maksimums, minimums...) tiek aprēķināta atlasītajiem izmēriem/resursiem',
                dimensionsInfo: 'Metrika tiks ienesta atlasītajiem izmēriem/resursiem',
                metrics: 'Metrika',
                controlsSoftwareVersion: 'Programmatūras versija',
                currentSoftwareVersion: 'Pašreizējā programmatūras versija',
                availableSoftwareVersion: 'Pieejamā programmatūras versija',
                invalidLifetime: 'Nederīga kalpošanas laika vērtība',
                unpaidSales: 'Neapmaksātās pārdošanas',
                unpaidTransactions: 'Neapmaksātie darījumi',
                softwareUpdateConfirmation: 'Vai vēlaties atjaunināt programmatūras versiju atlasītajai(-ām) ierīcei(-ām)?',
                softwareUpdateWarn:
                    'Atjauninot programmatūras versiju, ierīcei(-ēm) tiks nosūtīta komanda paņemt atjauninājumu tūlīt vai atlasītajā laikā. Atlasītā(-s) ierīce(-s) instalēs atjauninājumu, nākamreiz nonākot tiešsaistē pēc aktivizēšanas. Instalēšanas ilgums ir atkarīgs no savienojuma ātruma. Pēc instalēšanas ierīce(-s) tiks atsāknēta(-s).',
                releaseVersion: 'Izlaiduma versijas',
                releaseDate: 'Izlaiduma datums',
                latestRelease: 'Jaunākais izlaidums',
                currentVersion: 'Pašreizējā versija',
                softwareUpdateSuccess: 'Programmatūras versija ir atjaunināta',
                prebooking: 'Iepriekšēja rezervācija',
                prebookedProduct: 'Iepriekš rezervēts produkts',
                prebookedProducts: 'Iepriekš rezervēti produkti',
                activeProducts: 'Aktīvie produkti',
                startMaintenanceMode: 'Sākt vitrīnas apkopes režīmu',
                endMaintenanceMode: 'Beigt vitrīnas apkopes režīmu',
                startMaintenanceConfirm: 'Vai tiešām sākt vitrīnas apkopes režīmu?',
                endMaintenanceConfirm: 'Vai tiešām beigt vitrīnas apkopes režīmu?',
                startMaintenanceSuccess: 'Sākts apkopes režīms.',
                endMaintenanceSuccess: 'Apkopes režīms beidzies.',
                noResults: 'Nav rezultātu',
                suppliers: 'Piegādātāji',
                supplier: 'Piegādātājs',
                supplierDeleteRejected: 'Piegādātāja dzēšana noraidīta. Pārbaudiet saistītos produktus!',
                supplierPromptMessage: 'Vai tiešām dzēst piegādātāju?',
                fetchingSuppliers: 'Piegādātāju ienešana',
                noSuppliers: 'Nav atrasts neviens piegādātāja ieraksts',
                suppliersFetchError: 'Ienesot piegādātājus, radās kļūda',
                supplierName: 'Piegādātāja nosaukums',
                supplierHelpTextName: 'Piemēram, zīmola nosaukums',
                supplierDescription: 'Piegādātāja apraksts',
                supplierHelpTextDescription: 'Piemēram, zīmola apraksts',
                wishes: 'Vēlmes',
                checkNow: 'Pārbaudīt tūlīt',
                startTimeOn: 'Sākuma laiks',
                endTimeOn: 'Beigu laiks',
                signOutConfirmationModalTitle: 'Izrakstīšanās apstiprinājums',
                signOutConfirmationModalContent: 'Vai tiešām vēlaties izrakstīties?',
                startDate: 'Sākuma datums',
                endDate: 'Beigu datums',
                scheduledDiscountApplyAllDays: 'Lietot visām dienām',
                scheduledDiscountApplySelectedDays: 'Lietot atlasītajām dienām',
                discountCustomImageTitle: 'Atlaides attēls (nav obligāts)',
                discountCustomImageModalHeader: 'Attēls atlaidei',
                saveChanges: 'Saglabāt izmaiņas',
                updateTagsQualitySettingsTitle: 'Birku kvalitātes iestatījumi',
                updateTagsQualitySettingsMessage: 'Atjaunināti birku kvalitātes iestatījumi',
                IF3Cabinets: '3. modelis — ledusskapji',
                IF2Cabinets: '2. modelis — ledusskapji',
                IF3Freezers: '3. modelis — saldētavas',
                encodingStations: 'Kodēšanas stacijas',
                if2: '2. modelis — ledusskapis',
                if3: '3. modelis — ledusskapis',
                if3Freezer: '3. modelis — saldētava',
                legalNotice: 'Juridisks paziņojums',
                privacyPolicy: 'Privātuma politika',
                scheduledDiscountDateMissingError: 'Ja ir iestatīts sākuma vai beigu datums, nepieciešami abi datumi',
                scheduledDiscountDuplicateTimeError: 'Vienādu datumu gadījumā nevar būt vienādi laiki',
                now: 'Tūlīt',
                minimum: 'Minimums',
                maximum: 'Maksimums',
                discardChangesConfirmationText: 'Vai tiešām atmest izmaiņas?',
                exports: 'Eksports',
                selectAllCabinets: 'Atlasīt visas vitrīnas',
                expiryIntervals: 'Derīguma termiņa beigu intervāli',
                timeBeforeExpiry: 'Laiks līdz derīguma termiņa beigām',
                apply: 'Piemērot',
                yes: 'Jā',
                no: 'Nē',
                productOnScreenBannerText: 'Ekrānā redzamā informācijas reklāmkaroga teksts',
                productOnScreenBannerAdditionalInfo:
                    'Reklāmkaroga teksts ir vienas rindas teksts, kas ir redzams zem produkta attēla un kodolīgi atspoguļo galvenās funkcijas, ieguvumus veselībai vai jebkādu papildinformāciju par produktu',
                productOnScreenBannerExampleText: 'Nesatur laktozi',
                UI_IS_DOWN: 'Skapja displejs nedarbojas',
                READER_IS_DOWN: 'RFID lasītājs ir izslēgts',
                ANTENOR_DEVICE_DISCONNECTED: 'Maksājuma ierīce ir atvienota',
                EXPIRED_PRODUCT: 'Skapī ir beigušies termiņi derīgajiem produktiem',
                CLOUD_SERVICE_DOWN: 'Mākoņpakalpojums ir izslēgts',
                ANTENNA_DISCONNECTED: 'Antena atvienota',
                TAG_CHANGES: 'Pārāk daudz sliktu tagu skapī',
                ANTENNA_DOWN: 'RFID antena skapī nedarbojas',
                READER_CONFIG_ERROR: 'RFID lasītāja konfigurācijas kļūda, tas nedarbojas',
                activeAlarms: 'Aktīvās trauksmes',
                deviceStatus: 'Ierīces statuss',
                lastMonitored: 'Pēdējā uzraudzītā',
                noService: 'Nav pieejams serviss',
                operational: 'Operatīvs',
                offline: 'Offline',
                locationsSelectHelp: 'Pievienojot atrašanās vietu, tiks pievienotas arī tās apakšvietas.',
                select: 'Izvēlieties...',
                labels: 'Etiķetes',
                productDetails: 'Produkta detaļas',
                invalidProductName: 'Produkta nosaukumam jābūt no 1 līdz 125 rakstzīmēm',
                standardPrice: 'Standarta cena',
                locationPrices: 'Cenas pēc atrašanās vietas',
                addNew: 'Pievienot jaunu',
                productExpiry: 'Produkta derīguma termiņš',
                productLifeTime: 'Produkta derīguma laiks',
                invalidTTL: 'Produkta derīguma laikam jābūt veselu skaitļu robežās no 0 līdz 32767',
                productImage: 'Produkta attēls',
                uploadImage: 'Augšupielādēt attēlu',
                pleaseUploadImage: 'Lūdzu, augšupielādējiet attēlu',
                productValidationError: 'Lūdzu, izlabojiet šādas kļūdas pirms produkta datu saglabāšanas',
                productSupplier: 'Produkta piegādātājs',
                frozenProductLable: 'Šis ir saldēts produkts',
                prebookingLabel: 'Šis ir iepriekš rezervējams produkts',
                previous: 'Iepriekšējais',
                bannerText: 'Banera teksts',
                packagingTypeValidationError: 'Lūdzu, izvēlieties produkta iepakojuma veidu',
                showOnlyFrozen: 'Rādīt tikai saldētus produktus',
                productClassification: 'Produkta klasifikācija',
                productClassLabel: 'Lūdzu, izvēlieties atbilstošo produktu klasi. Tas ļauj Selfly Insights sniegt atbilstošāku informāciju par produktu.',
                productLabels: 'Produkta etiķetes',
                productLabelsHelpText: 'Lūdzu, pievienojiet savam produktam izvēles etiķetes. Tas ļaus filtrēt jūsu produktus produktu katalogā.',
                newLabel: 'Jauna etiķete',
                productClassValidationError: 'Lūdzu, izvēlieties produkta klasifikāciju no zemāk esošajām opcijām',
                tagSensitivity: 'Birkas kvalitātes jutīgums',
                tagSensitivitySetting: 'Birkas kvalitātes jutīguma iestatījums',
                configTagSensitivity: 'Konfigurējiet produkta birkas kvalitātes jutīgumu',
                productLevelSensitivityInfo:
                    'RFID birkas nolasāmību, kas piestiprināta produktam, var ietekmēt dažādi faktori, piemēram, iepakojuma materiāli, metāliskas virsmas, mitrums un pat paša produkta saturs. Šie faktori dažkārt var izraisīt neregulārus birkas signālus, kas pazīstami kā mirgošana. Skapis apstrādā šo mirgošanu, pamatojoties uz jutīguma konfigurācijas iestatījumiem. Ja neesat pārliecināts, kura jutīguma līmenis ir piemērots šim produktam, šo sadaļu varat izlaist.',
                errorSaving: 'Kļūda produkta datu saglabāšanā',
                successSaving: 'Produkta dati veiksmīgi saglabāti',
                basicDetail: 'Pamata detaļas',
                additionalDetail: 'Papildu detaļas',
                packaging: 'Iepakojuma veids',
                classification: 'Klasifikācija',
                selectOrtype: 'Izvēlieties vai rakstiet...',
                max25Chars: 'maks. 25 rakstzīmes',
                noVatForProductCreation: 'Lūdzu, izveidojiet PVN kategoriju pirms jaunu produktu pievienošanas',
                lifeTimeExplanation: 'Cik dienas šis produkts ir derīgs pārdošanai. (0 dienas) nozīmē līdz pašreizējās dienas beigām',
                selflyEncode: 'Selfly Encode',
                userRestrict: 'Tikai atzīti patērētāji',
                userRestrictTitle: 'Tiesīgi patērētāji',
                userNotRestrict: 'Visi patērētāji',
                userRestrictHelperText:
                    "Opcija 'atzīti patērētāji' nozīmē, ka atlaide tiks piemērota tikai tiem patērētājiem, kas jau ir reģistrēti atlaides saņemšanai!",
                searchCabinets: 'Meklēt skapjus',
                selectAll: 'Izvēlēties visu',
                monday: 'Pirmdiena',
                tuesday: 'Otrdiena',
                wednesday: 'Trešdiena',
                thursday: 'Ceturtdiena',
                friday: 'Piektdiena',
                saturday: 'Sestdiena',
                sunday: 'Svētdiena',
                fetchingLoader: 'Ielādē. Lūdzu, uzgaidiet...',
                category: 'Kategorija',
                okay: 'Ok',
                noLocation: 'Nav atrašanās vietas',
                copy: 'Kopēt',
                pleaseConfirm: 'Lūdzu, apstipriniet',
                saveQuery: 'Saglabāt šo vaicājumu',
                saveQueryPlaceholder: 'piem., "Visu pārdoto preču skaits"',
                saveQuerySuccess: 'Vaicājums ir saglabāts.',
                yourSavedQueries: 'Jūsu saglabātie vaicājumi',
                selectQuery: 'Izvēlieties vaicājumu',
                noQuery: 'Vēl nav saglabāts neviens vaicājums',
                deleteAdConfirm: 'Vai tiešām vēlaties dzēst šo sludinājumu?',
                dataSaved: 'Dati veiksmīgi saglabāti',
            },
            refill: {
                refillRuleApplyRuleDropdownText: 'Piemērot šo kārtulu arī citām vitrīnām',
                refillPlanGenerate: 'Ģenerēt uzpildes plānu',
                refillPlanEditPlan: 'Rediģēt plānu',
                refillAmount: 'Uzpildes apjoms',
                refillRuleTargetAmount: 'Mērķa krājumu līmenis',
                refillRuleTargetAmountShort: 'Mērķis',
                refillPlanActivePlans: 'Aktīvie plāni',
                refillPlanCompletedPlans: 'Pabeigtie plāni',
                refillNote: 'Pildīšanas piezīme',
                refillNoteHelp: 'Produkta pildīšanas piezīme (maksimāli 125 rakstzīmes)',
                refillPDFText:
                    'Zemāk ir skapis pildīšanas plāns. Lūdzu, pārliecinieties, vai pēc pildīšanas esat pabeidzis pildīšanas plānu pildīšanas lietotnē.',
                refillPDFSummaryText: 'Zemāk ir pildīšanas plānu kopsavilkums ar produktiem, kas jāpievieno un jānoņem visiem atlasītajiem skapjiem.',
                refillSummary: 'Pildīšanas kopsavilkums',
                addItems: 'Pievienot produktus',
                removeItems: 'Noņemt produktus',
                inventoryChange: 'Inventāra izmaiņas',
                planogramPDFText: 'Planograms kabineta papildināšanas stratēģijai.',
                planogramImagePDFText: 'Pielāgots attēls kabineta papildināšanas stratēģijai.',
                refillSchemas: 'Papildināšanas veidnes',
                refillPlans: 'Papildināšanas plāni',
                refillSchemaTitle:
                    'Iestatiet mērķa un minimālo krājumu daudzumu kā veidni izvēlētajiem skapjiem. Šīs veidnes tiks izmantotas papildināšanas plānu ģenerēšanai.',
                refillPlansTitle:
                    'Skatīt visus aktīvos plānus. Jūs varat rediģēt vai noņemt esošos aktīvos plānus vai pievienot jaunus papildināšanas plānus pēc nepieciešamības.',
                refillSchemaSubText: 'Lūdzu, izvēlieties skapi, lai izveidotu vai atjauninātu papildināšanas veidni.',
                noRefillSchema: 'Šim kabinetam vēl nav definēta papildināšanas veidne.',
                createRefillSchema: 'Izveidot jaunu papildināšanas veidni.',
                noDevice: 'Ierīce nav atrasta.',
                schemaSaved: 'Papildināšanas veidne veiksmīgi saglabāta.',
                deleteSchemaConfirmation: 'Vai tiešām vēlaties dzēst papildināšanas veidni?',
                targetAmount: 'Mērķa daudzums',
                targetAmountHelpText: 'Mērķa krājumu daudzums katram produktam skapī pēc papildināšanas.',
                minimumThreshold: 'Minimālais slieksnis',
                minimumThresholdHelpText:
                    'Norādiet minimālo krājumu līmeni katram produktam skapī. Ja daudzums samazinās zem definētā sliekšņa, jūs saņemsiet trauksmes paziņojumu. Sliekšņa iestatīšana uz 0 atspējos paziņojumus.',
                editNote: 'Rediģēt piezīmi',
                refillNoteTitle: 'Uzrakstiet papildināšanas piezīmi produktam.',
                refillNoteCharacters: 'rakstzīmes',
                noRefillPlan:
                    'Pašlaik nav aktīvu papildināšanas plānu. Lūdzu, pievienojiet jaunus papildināšanas plānus saviem skapjiem vai vispirms izveidojiet papildināšanas veidni.',
                planSaved: 'Papildināšanas plāns veiksmīgi saglabāts.',
                deleteConfirmation: 'Vai tiešām vēlaties dzēst izvēlēto papildināšanas plānu datus?',
                editPlan: 'Rediģēt plānu',
                currentInventory: 'Pašreizējie krājumi',
                lastSevenDaySales: 'Pēdējo septiņu dienu pārdošana',
                inventory: 'Krājumi',
                planogram: 'Planograms',
                copyRefillSchema: 'Kopēt veidni uz citiem skapjiem',
                generatingPlans: 'Papildināšanas plānu ģenerēšana. Lūdzu, uzgaidiet...',
                generatePlans: 'Ģenerēt papildināšanas plānus',
                complete: 'pabeigts',
                selectCabinetsToAddPlans: 'Lūdzu, izvēlieties skapjus, lai ģenerētu papildināšanas plānus.',
                reviewSuggestion:
                    'Zemāk ir ieteiktie papildināšanas plāni izvēlētajiem skapjiem. Lūdzu, pārskatiet, rediģējiet, ja nepieciešams, un saglabājiet tos, lai aktivizētu šos plānus.',
                noSchema:
                    'Pašlaik nevienam skapim nav papildināšanas veidnes. Lūdzu, pievienojiet papildināšanas veidni saviem skapjiem, lai ģenerētu papildināšanas plānus.',
                searchProducts: 'Meklēt produktus',
                maxAllowedInventory: 'Maksimālais atļautais krājumu daudzums ir 200.',
                savingData: 'Datu saglabāšana. Lūdzu, uzgaidiet.',
                errorSavingData: 'Kļūda, saglabājot datus.',
                refillPlanSaved: 'Papildināšanas plāni veiksmīgi ģenerēti.',
                exportPDFHelp: 'Eksportēt izvēlētos plānus PDF failā.',
                exportExcelHelp: 'Eksportēt izvēlētos plānus Excel failā.',
                addPlanHelp: 'Pievienot jaunus papildināšanas plānus.',
                deletePlanHelp: 'Dzēst izvēlētos papildināšanas plānus.',
                generatePlansHelp: 'Ģenerēt papildināšanas plānus izvēlētajiem skapjiem.',
                planogramSaved: 'Planograms veiksmīgi saglabāts.',
                schemaCopied: 'Papildināšanas veidne veiksmīgi sinhronizēta ar citiem skapjiem.',
                allProducts: 'Visi produkti',
                schemaProducts: 'Papildināšanas veidnes produkti',
                deletePlanogram: 'Vai tiešām vēlaties dzēst planogramu?',
                dragInPlanogram: 'Lūdzu, velciet un nometiet produktus uz norādītajām skapja plauktiem vai velciet skapja slotu virs produkta.',
                noPlanogramInSchema: 'Planograms vēl nav definēts šim skapim.',
                noPlanogramInPlan: 'Nav pieejami planograma dati šim skapim. Lūdzu, vispirms izveidojiet planogramu skapim papildināšanas veidņu cilnē.',
                copyPlanogram: 'Kopēt papildināšanas planogramu kopā ar papildināšanas veidni.',
                planogramImageInfo: 'Papildus planogramai varat pievienot arī pielāgotu planograma attēlu.',
                addPlanogramImage: 'Pievienot planograma attēlu.',
                editPlanogramImage: 'Rediģēt planograma attēlu.',
                numberOfShelves: 'Plauktu skaits',
                addNewRefillPlan: 'Pievienot jaunus plānus.',
                qrcodeTitle: 'Izvēlētajam skapim tiks ģenerēts QR kods, lai uzsāktu papildināšanas procesu.',
                qrcodeSubText: 'Lūdzu, izvēlieties skapi, kurā vēlaties uzsākt papildināšanas procesu.',
                qrcodeScan: 'Lūdzu, noskenējiet QR kodu, lai papildinātu, vai noklikšķiniet uz pogas, lai atvērtu papildināšanas lietotni.',
                qrcodeOpenApplication: 'Atvērt papildināšanas lietotni.',
                createRefillPlanogram: 'Izveidot papildināšanas planogramu.',
            },
            navigation: {
                advertisements: 'Reklāmas',
                analytics: 'Analītika',
                controlPanel: 'Vadības panelis',
                deviceManagement: 'Ierīces pārvaldība',
                discounts: 'Atlaides',
                fetching: 'Ienešana',
                group: 'Grupa',
                inventory: 'Vitrīnas inventārs',
                language: 'Valoda',
                productCatalog: 'Produktu katalogs',
                productCategories: 'Produktu kategorijas',
                reports: 'Atskaites',
                settings: 'Lietotāja iestatījumi',
                signOut: 'Izrakstīties',
                title: 'Selfly Cloud portāls',
                vatCategories: 'PVN kategorijas',
                community: 'Selfly Community',
                refillManagement: 'Papildināšanas pārvaldība',
                automations: 'Automatizācijas',
                mySelfly: 'MySelfly iestatījumi',
                automationInfo: 'Pārvaldīt automatizētās darbības (BETA)',
            },
            reports: {
                alreadyRefund: 'tika atmaksāts',
                amount: 'Daudzums',
                average: 'Vidējais',
                barcode: 'Svītrkods',
                confirmIssueRefundButton: 'Tūlīt apstiprināt atmaksu',
                current: 'Valūta',
                export: 'Eksportēt',
                fetching: 'Ienešana',
                fetchingError: 'Ienešanas laikā radās kļūda',
                image: 'Attēls',
                issueRefundButton: 'ATMAKSA',
                maximum: 'Maksimums',
                minimum: 'Minimums',
                name: 'Nosaukums',
                noData: 'Nav datu',
                note: 'Piezīme',
                order: 'Darījums',
                paymentWasDoneByCreditCard: 'Maksājums tika veikts ar kredītkarti',
                price: 'Cena',
                ProductReport: 'Produkta atskaite',
                quantity: 'Daudzums',
                "Refund impossible'":
                    'Maksājums tika veikts ar kredītkarti. Lai veiktu atmaksāšanas darbību, sazinieties ar kredītkartes termināļa nodrošinātāju.',
                refundFormTitle: 'Atmaksāšana',
                refundReason: 'Atmaksāšanas iemesls',
                refundSuccess: 'Summa {{sum}} sekmīgi atmaksāta',
                refundTotal: 'Atmaksas summa',
                replenishments: 'Uzpildīšanas',
                replenishmentsTitle: 'Uzpildīšanas atskaite',
                SaleTransaction: 'Pārdošanas darījums',
                summary: 'Kopsavilkums',
                SummaryReport: 'Pārdošanas kopsavilkums',
                temperature: 'Temperatūra',
                temperatureTitle: 'Temperatūras atskaite',
                temperatureValueNotification: 'Temperatūras vērtības ir vitrīnas temperatūras stundas vidējās vērtības.',
                time: 'Laiks',
                total: 'Kopā',
                transactions: 'Darījumi',
                user: 'Lietotājs',
                paidTransactions: 'Apmaksātie darījumi',
                unpaidTransactions: 'Neapmaksātie darījumi',
                partiallyPaid: 'Daļēji apmaksāts',
                failedTransactions: 'Nesekmīgi darījumi',
                amountOwed: 'Parāda summa',
                receiptLinkText: 'Saņemiet rēķinu šeit',
                noPurchase: 'Nav pirkuma',
                other: 'Cits',
                userId: 'Lietotāja ID:',
            },
            settings: {
                block_cabinet_when_product_expired: 'Bloķēt vitrīnu produktiem ar beigušos derīguma termiņu',
                criticalExpiryLimitHours: 'Stundas pirms brīdinājuma par kritisku derīguma termiņu',
                language: 'Valoda',
                logo: 'Uzņēmuma logotips',
                mediumExpiryLimitHours: 'Stundas pirms vidējā termiņa beigu brīdinājuma',
                payment_method_logo: 'Maksājuma veida logotips',
                footer_instruction_logo: 'Vēres instrukcijas attēls',
                readUserMemory: 'Ienest produkta derīguma termiņa datus',
                ui_skus_sort_by: 'Produktu šķirošanas kārtība vitrīnas displejā',
                timeZone: 'Laika josla',
                country: 'Valsts',
                city: 'Pilsēta',
                tagSettings: 'Birku iestatījumi',
                themeAndLogos: 'Dizains un logotipi',
                advancedSettings: 'Papildu iestatījumi',
                themingInfoText: 'Jūs varat pielāgot sava vitrīnas ekrāna dizainu',
                cabinetLogos: 'Vitrīnas logotipi',
                cabinetLogoInfoText: 'Augšupielādējiet logotipus, kas ir redzami vitrīnas ekrānā',
                logoHelperText: 'Lūdzu, izmantojiet 180 x 180 pikseļu attēlu',
                settingsCopyInfoHeader: 'Atlasiet vitrīnas, uz kurām kopēt iestatījumus',
                settingsCopyButton: 'Kopēt iestatījumus uz atlasītajām vitrīnām',
                settingsCopySuccessModalTitle: 'Iestatījumu kopēšana',
                settingsCopySuccessModalContent: 'Iestatījumi sekmīgi nokopēti uz {{cabinetsAmount}} vitrīnām',
                theming: 'Tēma',
                adminSettings: 'Administratora iestatījumi',
                settingsCopyErrorModalTitle: 'Kļūda iestatījumu kopēšanā',
                key: 'Iestatījums',
                value: 'Vērtība',
                sleepScheduling: 'Plānotā gaidīšanas režīms',
                sensitivityLevel: 'Jutīguma līmenis',
                sensitivity_0: 'Skapis ignorē birkas mirgošanu un konsekventi atzīmē visas birkas kā derīgas, kas varētu izraisīt kļūdainu maksāšanu.',
                sensitivity_20: 'Skapis pieļauj līdz trīs kļūdainiem nolasījumiem vienai birkai 50 skenējumos un joprojām atzīmē birku kā derīgu.',
                sensitivity_40: 'Skapis pieļauj līdz diviem kļūdainiem nolasījumiem vienai birkai 60 skenējumos un joprojām atzīmē birku kā derīgu.',
                sensitivity_60: 'Ja birka bieži mirgo, bet ilgstoši saglabā stabilitāti, skapis to pieņem un uzskata birku par derīgu.',
                sensitivity_80: 'Ja birka īslaicīgi mirgo, bet ilgstoši paliek stabila, tā tiks uzskatīta par derīgu birku.',
                sensitivity_100:
                    'Skapis ir ļoti neiecietīgs pret birkas mirgošanu. Pat viens kļūdains nolasījums var izraisīt, ka birka tiek atzīmēta kā nederīga uz ilgu laiku, kas var izraisīt potenciālus zaudējumus.',
                sensitivityInfo:
                    'Skapja birkas kvalitātes jutīgums attiecas uz to, cik efektīvi skapis apstrādā nekonsekventus birku nolasījumus vai mirgošanu. Lūdzu, ņemiet vērā izvēlēto jutīguma līmeni, jo tas var ietekmēt skapja darbību. Ja neesat pārliecināts, kurš līmenis ir piemērots, ieteicams atstāt iestatījumu pēc noklusējuma uz 3. līmeni.',
            },
            signIn: {
                signInButton: 'Pierakstīties',
                signInExpiredError: 'Jūsu sesijas derīgums ir beidzies. Pierakstieties vēlreiz.',
                signInMessage: 'Lai turpinātu, ir jāpierakstās. Lai pierakstītos, noklikšķiniet uz zemāk redzamās pogas',
                noOrganizationsError: 'Ar šo lietotājvārdu nav saistīta neviena organizācija. Izmēģiniet citu lietotājvārdu.',
            },
            translations: {
                add: 'Pievienot',
                closed: 'Aizvērts',
                delete: 'Dzēst',
                device: 'Ierīce',
                edit: 'Rediģēt',
                off: 'Izslēgts',
                offline: 'Bezsaistē',
                on: 'Ieslēgts',
                online: 'Tiešsaistē',
                opened: 'Atvērt',
                save: 'Saglabāt ',
                seeDetails: 'Detaļas',
                settings: 'Iestatījumi',
                unknown: 'Nezināms',
            },
            consumerApp: {
                enableConsumerFeedback: 'Iespējojiet lietotāja atsauksmes par MySelfly',
                enablePurchaseReceipts: 'Iespējojiet e-rēķinu saņemšanu pakalpojumā MySelfly',
                enableSelflyTrademarkForReceipts: 'Atļaujiet Selfy Store kājeni e-rēķinos',
                enableSelflyTrademarkForReceiptsHelpText: 'Ieslēdzot šo iespēju, e-rēķina PDF tiks pievienots Selfly Store logotips un informācija!',
                organizationFormHelpText: 'Lūdzu, norādiet e-rēķina izdevēja informāciju.',
                organizationFormValidationHelpText: '* Obligātie lauki e-rēķinam ir izdevējas organizācijas nosaukums, uzņēmuma ID, adrese un valsts.',
                organizationName: 'Organizācijas nosaukums',
                organizationLogo: 'Organizācijas logotips',
                businessId: 'Uzņēmuma ID',
                city: 'Pilsēta',
                email: 'E-pasts',
                website: 'Tīmekļa vietne',
                postalCode: 'Pasta indekss',
                street: 'Ielas adrese',
                phoneNumber: 'Tālruņa numurs',
                addressHeader: '* Adreses informācija',
                contactDetails: 'Kontaktinformācija',
                organization: '* Izdevēja organizācija',
                organizationSettingsUpdatedTitle: 'Organizācijas iestatījumu atjaunināšana',
                organizationSettingsUpdatedContent: 'Organizācijas iestatījumi veiksmīgi atjaunināti',
                organizationSettingsMissingFields: 'Trūkst obligātie lauki e-rēķinam. Lūdzu, aizpildiet pirms saglabāšanas.',
                organizationSettingsMissingFieldsTitle: 'Veidlapas apstiprināšanas kļūda',
            },
            softwareVersions: {
                updateNotificationTitle: 'Programmatūras versijas atjauninājums',
                updateNotificationContent: 'Programmatūras versija(-s) sekmīgi atjaunināta(-s)',
                selectAVersionPlaceholder: 'Atlasīt versiju',
                searchDevices: 'Meklēt ierīces',
                updateSelected: 'Atjaunināt atlasīto',
                softwareVersions: 'Programmatūras versijas',
                softwareVersion: 'Programmatūras versija',
                notSupportedDeviceNote: 'Versija {{selectedVersion.version}} šajā ierīcē netiek atbalstīta',
                userNotificationContent:
                    'Jūsu ierīcēm ir izlaista jauna programmatūras versija. Lūdzu, atjauniniet savas ierīces, lai iegūtu jaunākās funkcijas.',
                userNotificationTitle: 'Izlaista jauna programmatūras versija',
                notificationBell_newSoftwareVersions: 'Jūsu ierīcēm pieejamie atjauninājumi',
                manageVersions: 'Pārvaldīt ierīces programmatūras versijas',
                filterLocationsPlaceholder: 'Filtrēt pēc atrašanās vietām',
                allVersionNumbers: 'Visi izlaidumi',
                noVersionsForUpdating: 'Nav versiju atjaunināšanai',
                scheduledUpdateNote: 'Versiju {{version}} ir plānots atjaunināt {{scheduled}}',
                scheduleRemoveNotificationTitle: 'Programmatūras versiju grafiks',
                scheduleRemoveNotificationContent: 'Programmatūras versijas plānotais atjauninājums sekmīgi noņemts',
                scheduleRemoveNotificationModalContent: 'Vai tiešām noņemt šai ierīcei plānoto atjauninājumu?',
            },
            header: {
                searchByOrganization: 'Meklējiet pēc organizācijas',
                pageLanguage: 'Valoda',
                configureMySelflyAndEreceipts: 'Konfigurējiet MySelfly iestatījumus',
                remindMeLater: 'Atgādini man vēlāk',
                updateNow: 'Atjaunināt tagad',
            },
            notifications: {
                noNewNotifications: 'Nav jaunu paziņojumu',
            },
            cabinetSettings: {
                cabinetTheme: 'Vitrīnas dizains',
                editAndSaveTheme: 'Rediģēt un saglabāt dizainu',
                colorPicker: 'Krāsu izvēle',
                customTheme: 'Pielāgot dizainu',
                themeSettings: 'Dizaina iestatījumi',
                primary: 'Primārais',
                negative: 'Negatīvs',
                attention: 'Uzmanībai',
                positive: 'Pozitīvs',
                background: 'Fons',
                dark: 'Tumšs',
                light: 'Gaišs',
                white: 'Balts',
                default: 'Noklusējums',
                negativeLight: 'Negatīva gaisma',
                primaryColor: 'Primārā krāsa',
                positiveColors: 'Pozitīvās krāsas',
                negativeColors: 'Negatīvās krāsas',
                secondaryColors: 'Sekundārās krāsas',
                attentionColors: 'Krāsas uzmanības pievēršanai',
                screenBackground: 'Ekrāna fons',
                borderColors: 'Apmales — ēnas',
                shadow: 'Ēna',
                border: 'Apmale',
                fontColors: 'Fonta krāsas',
                footerTextPlaceholder: 'Vēres teksts',
                bundleDiscounts: 'Kombinēt un saglabāt',
                expiringText: 'Produkti, kuriem beidzas derīguma termiņš. Pirms iegādes, lūdzu, pārbaudiet derīguma termiņu',
                expiredText: 'Produkti ar beigušos derīguma termiņu. Pirms iegādes, lūdzu, pārbaudiet derīguma termiņu',
                cabinetName: 'Vitrīnas nosaukums',
                productName: 'Produkta nosaukums',
                confirmationModalHeader: 'Vai tiešām saglabāt pielāgoto dizainu?',
                confirmationModalContent:
                    'Dizaina pielāgošana ietekmēs jūsu vitrīnas ekrāna vizuālo izskatu. Lūdzu, pārliecinieties, vai izmaiņas neietekmē ekrāna vizuālo kvalitāti.',
                defaultRevertModalMessage: 'Vai tiešām mainīt vitrīnas dizainu uz noklusējumu?',
                backgroundColor: 'Fona krāsa',
                backgroundImage: 'Fona attēls',
                footerBackground: 'Apgala fona krāsa',
                cardsBackground: 'Kartes fona krāsa',
                screenImage: 'Attēls skapja ekrāna fona attēlam',
                uploadImage: 'Augšupielādēt attēlu',
                editImage: 'Rediģēt attēlu',
                transactionComplete: 'Transakcija pabeigta',
                customPostPurchaseImage: 'Lūdzu, augšupielādējiet attēlu pielāgotajam pēc pirkuma ekrānam',
                postPurchaseScreen: 'Pēc pirkuma ekrāns',
                custom: 'pielāgots',
                postPurchaseText: 'Pēc pirkuma attēlojamais teksts',
            },
            automations: {
                transactionReports: 'Transakciju atskaites',
                refillReports: 'Uzpildes atskaites',
                notifications: 'Paziņojumi',
                refillPlan: 'Uzpildes plāni',
                days: 'Dienas',
                months: 'Mēneši',
                weeks: 'Nedēļas',
                configuration: 'Konfigurācija',
                transactionReportTitle:
                    'Transakciju atskaites izvēlētajiem skapjiem tiks automātiski ģenerētas un nosūtītas uz zemāk norādītajām e-pasta adresēm',
                refillReportTitle: 'Uzpildes atskaites izvēlētajiem skapjiem tiks automātiski ģenerētas un nosūtītas uz zemāk norādītajām e-pasta adresēm',
                noSalesNotificationTitle:
                    'Paziņojumi tiks automātiski nosūtīti uz zemāk norādītajām e-pasta adresēm gadījumā, ja definētajos intervālos nav pārdošanas',
                frequencyTitle: 'Cik bieži vēlaties ģenerēt atskaiti?',
                emailNotificationTitle: 'Lūdzu, pievienojiet zemāk e-pasta adreses, uz kurām vēlaties nosūtīt atskaiti vai paziņojumu',
                refillPlanGenerationTitle: 'Uzpildes plāni tiks automātiski ģenerēti izvēlētajiem skapjiem saskaņā ar norādīto grafiku',
                addNewEmail: 'Pievienot jaunu e-pastu',
                noSalesBanner: 'Jūs saņemsiet e-pasta paziņojumu, ja izvēlētie skapji nenotiks pārdošana norādītajā laika periodā',
                noSaleTitle: 'Lūdzu, norādiet laika ilgumu (stundās) bez pārdošanas izvēlētajos skapjos, pirms tiek nosūtīts paziņojums',
                minMaxTime: 'Minimālais ir 2 un maksimālais ir 23 stundas',
                timeRangeTitle: 'Lūdzu, norādiet laika diapazonu, kurā tiks pārbaudīta pārdošanas neveiksmes situācija skapjiem',
                startTime: 'Sākuma laiks',
                endTime: 'Beigu laiks',
                refillPlanTitle:
                    'Uzpildes plāns izvēlētajiem skapjiem tiks automātiski ģenerēts. Lūdzu, norādiet zemāk konkrēto laiku, kad vēlaties izveidot uzpildes plānu.',
                refillPlanTime: 'Lūdzu, izvēlieties laiku, kad tiks ģenerēts uzpildes plāns',
                everyDay: 'Katru dienu',
                everyWeek: 'Katru nedēļu',
                everyMonth: 'Katru mēnesi',
                selectDays: 'Izvēlēties dienas',
                automationSaved: 'Automatizācijas iestatījumi veiksmīgi saglabāti',
                automationDeleted: 'Automatizācijas iestatījumi veiksmīgi dzēsti',
            },
            sleepScheduling: {
                timeRangeTitle: 'Lūdzu, norādiet laika diapazonu, kurā šis skapis pāries gaidīšanas režīmā.',
                startTime: 'Sākuma laiks',
                endTime: 'Beigu laiks',
                selectDays: 'Izvēlēties dienas',
                duration: 'Ilgums (stundas)',
            },
        },
        et: {
            dateRangeFilter: {
                lastMonth: 'viimased 30 päeva',
                lastTwoWeek: 'viimased 14 päeva',
                lastWeek: 'viimased 7 päeva',
                selectDateRangeMsg: 'Vali ajavahemik',
                today: 'täna',
                yesterday: 'eile',
            },
            main: {
                activateSuccess: 'Toode on aktiveeritud',
                activationDate: 'Aktiveerimise kuupäev',
                active: 'Aktiivne',
                addAdvertisement: 'Lisa uus reklaam',
                addDiscountRule: 'Lisa allahindluse reegel',
                addDiscountStop: 'Lisa aegumisintervall',
                addNewLocation: 'Vali uus asukoht',
                addProduct: 'Lisa toode',
                addRegion: 'Lisa asukoht',
                addRegionPrice: 'Lisa asukoha hind',
                addScheduledDiscount: 'Lisa planeeritud allahindlus',
                addBundleDiscount: 'Lisa komplekti allahindlus',
                bundledDiscounts: 'Komplekti allahindlused',
                bundleItemsLengthExceeded: 'Maksimaalne toodete arv (3) on ületatud. Jätkamiseks eemalda tooteid. ',
                bundleMaxProductLimitInfo: 'Valige max kolm (3) toodet või tootekategooriat',
                bundleFixedAmountExceededOriginal: 'Komplekti fikseeritud hind ületab tavahinda. Kontrolli valitud tooteid. ',
                bundleFixedAmountNotAllowedToBeZero: 'Komplekti fikseeritud hind peab olema suurem kui 0',
                bundlePercentageNotAllowedToBeFull: 'Komplekti allahindlusprotsent peab olema alla 100',
                bundlePercentageNotAllowedToBeZero: 'Kogumi allahindlusprotsent peab olema suurem kui 0',
                bundleItemDiscountPriceAmountExceededOriginal: 'Allahindlusega hind ületab tavahinda. Kontrolli hinda. ',
                bundleItemPriceMessage: 'Iga hinnareegel kehtib ainult ühele kaubale, isegi kui valitud on mitu sama tüüpi toodet',
                bundleItemFixedAmountExceededOriginal: 'Toote fikseeritud hind ületab originaaltoote hinda. Kontrollige fikseeritud hinda.',
                createDiscountRule: 'Loo allahindluse reegel',
                deviceType: 'Seadme tüüp',
                discountUpdateTitle: 'Allahindluse uuendamine',
                discountCreateTitle: 'Allahindluse määramine',
                discountDeleteTitle: 'Allahindluse eemaldamine',
                discountDeleteContent: 'Allahindlus on eemaldatud',
                discountStatusUpdated: 'Allahindluse olekut on uuendatud',
                discountUpdated: 'Allahindlust on uuendatud',
                discountCreated: 'Allahindlus on määratud',
                discountSettings: 'Soodustuse seaded',
                discountImage: 'Soodustuse valikuline pilt',
                editDiscountStop: 'Muuda aegumisintervalli',
                editBundleConfiguration: 'Muuda komplekti',
                editExpiryConfiguration: 'Muuda aegumise allahindluse konfiguratsiooni',
                encodingStation: 'Kodeerimisjaam',
                everyDay: 'Iga päev',
                scheduledDiscountDateInfoLabel: 'Kui soovite muuta allahindluse püsivaks, jätke algus- ja lõppkuupäev tühjaks,',
                starts: 'Algab',
                ends: 'Lõpeb',
                error: 'Viga',
                errorRetiringLocationMsg: 'Asukoha eemaldamine ebaõnnestus. Kõigepealt eemaldage lingitud seadmed, toodete hinnad ja reklaamid.',
                fetchingDiscountRules: 'Allahindluse reeglite laadimine',
                free: 'Tasuta',
                missingImage: 'Kuulutuse pilt puudub',
                next: 'Edasi',
                back: 'Tagasi',
                noDiscountRules: 'Allahindluse reegleid ei leitud',
                scheduledDiscountsAutomaticDeactivationInfo:
                    'Allahindlused, mille lõppkuupäev on ületatud, deaktiveeritakse automaatselt. Aktiveerimiseks muuda kuupäevade vahemikku. ',
                scheduledDiscounts: 'Plaanilised allahindlused',
                schedulingStartHourIsOverEndHour: 'Algusaeg ei saa olla peale lõpptähtaega. ',
                schedulingEndHourIsUnderStartHour: 'Lõpptähtaeg ei saa olla enne algusaega. ',
                selectDiscountRuleProductCategories: 'Vali tootekategooriad, mis kuuluvad allahindlusreegli alla. ',
                selectBundleProductsAmount: 'Vali kogus ',
                selectedBundleProductCategories: 'Valitud komplekti tootekategooriad. ',
                setDiscountDays: 'Määra allahindluse päevad',
                setBundleDiscount: 'Määra allahindlusprotsent või komplekti fikseeritud hind. ',
                setBundleItemDiscount: 'Määra allahindlusprotsent või toote fikseeritud hind. ',
                setBundleProducts: 'Määra tooted või kategooriad komplektile. ',
                setAmountMoreThanOne: 'Ühe kaubakomplekti puhul määrake summa rohkemaks kui üks',
                setBundleDiscountType: 'Määra allahindluse tüüp ja kogus.',
                sureActivateDiscountRule: 'Kas soovid allahindluse reegli aktiveerida?',
                sureDeactivateDiscountRule: 'Kas soovid allahindluse reegli deaktiveerida?',
                sureDeleteDiscountRule: 'Kas soovid allahindluse reegli kustutada?',
                timeRange: 'Ajavahemik',
                editExpiryDiscountRule: 'Muuda allahindluse reeglit',
                editScheduledDiscountRule: 'Muuda ajastatud allahindluse reeglit',
                editBundleDiscountRule: 'Muuda komplekti allahindluse reeglit',
                releaseNotes: 'Väljalaskemärkmed',
                seeMore: 'Vaata rohkem',
                scheduledDiscountTimeRangeLabel: 'Aktiivne aeg',
                scheduledDiscountDateRangeLabel: 'Allahindluse päevad',
                weekDayAbrvMonday: 'Esmaspäev',
                weekDayAbrvTuesday: 'Teisipäev',
                weekDayAbrvWednesday: 'Kolmapäev',
                weekDayAbrvThursday: 'Neljapäev',
                weekDayAbrvFriday: 'Reede',
                weekDayAbrvSaturday: 'Laupäev',
                weekDayAbrvSunday: 'Pühapäev',
                recurrence: 'Korduv',
                addScheduledDiscountStop: 'Lisa ajastatud allahindlus',
                editScheduledDiscountStop: 'Muuda ajastatud allahindlust',
                pieces: 'tk',
                expiryDiscountNavLabel: 'Säilivus',
                scheduledDiscountNavLabel: 'Planeeritud',
                bundleDiscountNavLabel: 'Komplekt',
                amount: 'Kogus',
                scheduling: 'Planeerimine',
                pricing: 'Hinnastus',
                percentageBundleDiscountInputPlaceholder: 'Komplekti allahindlusprotsent',
                fixedPriceBundleDiscountInputPlaceholder: 'Komplekti fikseeritud hind',
                applyBundleLevelLabel: 'Rakenda komplekti hind',
                applyItemLevelLabel: 'Rakenda toote hind',
                productFixedPrice: 'Toote fikseeritud hind',
                percentageDiscount: 'Allahindlusprotsent',
                fixedPriceDiscount: 'Fikseeritud hind',
                freeDiscount: 'Tasuta',
                advertisementsFetchError: 'Reklaamide toomine ebaõnnestus. Veendu, et reklaamiblokeerija on välja lülitatud',
                advertisementsTitle: 'Reklaamid',
                all: 'Kõik',
                'All time': 'Kogu aeg',
                allProducts: 'Kõik tooted ilma rühmitamata',
                allLocations: 'Kõik asukohad',
                allCabinets: 'Kõik kapid',
                antennaDown: 'Osa RFID-antenne on maas',
                antennaError: 'Viga on kapi antennides',
                antennaNoInfo: 'Kapi antennide kohta teave puudub',
                antennaOk: 'Kõik antennid on töökorras',
                antennaPartial: 'Mõned antennid on välja lülitatud',
                archiveSuccess: 'Toode on arhiveeritud',
                averageItems: 'Keskmine toodete arv tehingu kohta',
                averageSales: 'Keskmine müük tehingu kohta',
                barcode: 'Ribakood',
                barcodeType: 'Ribakoodi tüüp',
                barcodeCannotBeChanged: 'Ribakoodi ei saa muuta',
                barcodeTypeCannotBeChanged: 'Ribakoodi tüüpi ei saa muuta',
                barcodeHelpText12: 'Sisestage ribakoodi 12 numbrit, viimane number lisatakse automaatselt',
                barcodeHelpText11: 'Sisestage ribakoodi 11 numbrit, viimane number lisatakse automaatselt',
                barcodeHelpText7: 'Sisestage ribakoodi 7 numbrit, viimane number lisatakse automaatselt',
                beforeExpiry: 'enne aegumist',
                cabinetDown: 'Kapp ei tööta',
                cabinetIsEmpty: 'Kapp on tühi',
                deviceOffline: 'Seade on võrgust väljas',
                deviceOnline: 'Seade on võrgus',
                cabinets: 'Kapid',
                cabinet: 'Kapp',
                cabinetSettings: 'Kapi seaded',
                cabinetTheming: 'Kapi teema',
                cancel: 'Tühista',
                cancelledSales: 'Tühistatud müügid',
                cancelledTransactions: 'Tühistatud tehingud',
                categories: 'Kategooriad',
                change: 'Muuda',
                clear: 'Puhasta',
                close: 'Sulge',
                clickEditButtonRegionalPrices: 'Asukohahindade muutmiseks klõpsake toote nuppu "Muuda".',
                clickEditLocationPrices: 'Kohalikud hinnad, laiendamiseks klõpsake toote hinnal.',
                clickToExpandLocationPrices: 'Kohalikud hinnad, laiendamiseks klõpsake toote hinnal.',
                closeDoor: 'Sulge uks',
                rebootDevice: 'Taaskäivita seade',
                commandSentSuccessFully: 'Käsk on edukalt saadetud',
                companyLogo: 'Firma logo',
                confirm: 'Kinnita',
                confirmDelete: 'Olete kindel, et soovite toote kustutada? Seda toimingut ei saa tagasi võtta!',
                confirmDisable: 'Olete kindel, et soovite toote arhiveerida?',
                confirmEnable: 'Olete kindel, et soovite toote aktiveerida?',
                consumerResponses: 'Kasutajate tagasiside',
                controlPanelTitle: 'Juhtpaneel',
                controls_acceptablePercentageOfBadTags: 'Halbade siltide vastuvõetav protsent, enne kui kapp teenistusrežiimist väljub',
                controls_markTagsGoodQuality: 'Märkige kõik sildid hea kvaliteediga',
                controls_markTagsGoodQuality_btn: 'Märkige sildid hea kvaliteediga',
                controls_clearTagHistory: 'Kustuta siltide ajalugu',
                controls_clearRemoveReplenishmentData: 'Tühjenda täiendamise andmed',
                controls_companyAndPaymentLogo: 'Firma ja makseviisi logo',
                controls_detectTagQuality: 'Tuvasta siltide kvaliteet',
                controls_door: 'Ukse kontroll',
                controls_device: 'Seadme juhtimine',
                controls_chargeBadTags: 'Arve halvad sildid',
                controls_refill: 'Täida',
                controls_tagQuality: 'Sildi seaded',
                controls_tagQualityData: 'Sildi kvaliteediandmed',
                controls_replenishmentData: 'Täiendusandmed',
                controls_soldTags: 'Müüdud sildid',
                controls_tagHistory: 'Sildi ajalugu',
                controls_markAllTagsReplenished: 'Märkige kõik sildid täiendatuks',
                controls_markAllTagsUnsold: 'Märgi kõik sildid müümata',
                controls_markAlTagsReplenished_btn: 'Märgi sildid täiendatuks',
                controls_markAllTagsUnsold_btn: 'Märgi sildid müümata',
                controls_chargeSoldTags: 'Arve müüdud sildid',
                controls_chargeUnreplenishedTags: 'Arve täiendamata sildid',
                controls_tagsWarn:
                    'Siltide ajaloo kustutamine eemaldab kõik siltide andmed. Pärast seda toimingut klõpsake nuppu Märgi kõik sildid täiendatuks',
                create: 'Loo',
                createAdTitle: 'Uus reklaam',
                createdAd: 'Reklaam loodud',
                createExpiryDiscountRule: 'Aegumissoodustuse reegli loomine',
                createScheduledDiscountRule: 'Loo ajastatud allahindluse reegel',
                createBundleDiscountRule: 'Loo allahindluse reegel',
                createNewVatCategory: 'Uus maksukategooria',
                createProductTitle: 'Uus toode',
                creatingAd: 'Loon reklaami',
                creatingProduct: 'Loon toodet',
                createNewProductCategory: 'Loo uus tootekategooria',
                createNewSupplier: 'Lisa uus tarnija',
                currency: 'Valuuta',
                customQuery: 'Kohandatud päring',
                dashboard: 'Ülevaade',
                dateRange: 'Kuupäevade vahemik',
                dataAlredyFetchedTitle: 'Andmed on juba leitud',
                dataAlreadyFetchedDescription: 'Valige erinevad parameetrid',
                day: 'Päev',
                days: 'Päeva',
                deactivationDate: 'Deaktiveerimise kuupäev',
                default: 'Vaikimisi',
                delete: 'Kustuta',
                deleteError: 'Viga: toodet ei saanud kustutada',
                deleteSuccess: 'Toode on kustutatud',
                description: 'Kirjeldus',
                deviceManagement: 'Seadme haldus',
                digitwinOffline: 'Seadme digitwin pole võrgus',
                digitwinOnline: 'Seadme digitwin on võrgus',
                dimensions: 'Mõõdud',
                disable: 'Arhiiv',
                discountAmount: 'Soodustuse kogus',
                discountPrice: 'Soodushind',
                discountRuleAffects: 'Soodustuse reegli mõju',
                discountRuleName: 'Soodustuse reegli nimi',
                discountRuleStops: 'Aegumisintervallid',
                discounts: 'Soodustused',
                discountSalesData: 'Soodusmüügi andmed',
                discountSalesAmount: 'Soodusmüükide summa',
                discountTimeBeforeExpiryExists: 'Varasem aegumisvahemik on juba olemas',
                discountVsNormalSales: 'Allahindlused vs tavamüügid',
                dismiss: 'Tühista',
                doesNotExist: 'Ei leidu',
                doorCloseConfirm: 'Oled kindel, et soovid ukse sulgeda?',
                doorCloseSuccess: 'Ukse lukustamine õnnestus',
                doorOpenConfirm: 'Oled kindel, et soovid ukse avada?',
                doorOpenReason: 'Miks soovid ust avada?',
                doorOpenSuccess: 'Uks avanes edukalt',
                rebootDeviceSuccess: 'Seadme taaskäivitamine õnnestus',
                deviceRebootConfirm: 'Kas soovid seadme taaskäivitada?',
                edit: 'Muuda',
                editCompanyLogo: 'Muuda firma logo',
                editDiscountRule: 'Muuda allahindluse reeglit',
                editPaymentMethodLogo: 'Muuda makseviisi logo',
                editVatCategoryModalTitle: 'Muuda maksukategooriat',
                editProductCategoryModalTitle: 'Muuda tootekategooriat',
                editSupplierModalTitle: 'Muuda tarnijat',
                enable: 'Tee aktiivseks',
                error_401: 'Autentimata',
                error_403: 'Puudub õigus käskude andmiseks',
                error_500: 'Serveri viga',
                errorCreatingAd: 'Viga reklaami loomisel',
                errorCreatingProduct: 'Viga toote loomisel',
                errorFetchingCabinetStatus: 'Viga kapi oleku toomisel',
                errorFetchingInventory: 'Viga kapi inventuuri toomisel',
                errorFetchingReport: 'Viga aruandluse toomisel',
                errorFetchingSettings: 'Viga seadete toomisel',
                errorUpdatingProduct: 'Viga toote uuendamisel',
                expandToSeeBadTags: 'Laienda loendit, et näha silte, mis tuvastati halbadena. Halvad sildid tuleb kapist eemaldada.',
                expandToSeeSoldItems: 'Laienda loendit, et näha tooteid, mis on juba müüdud, kuid ilmusid uuesti varudesse.',
                expandToSeeReplenishedOutOfInventory: 'Laienda loendit, et näha tooteid, mis on kappi asetatud muul viisil kui varude täiendamine',
                expired: 'Aegunud',
                expiredCount: 'Aegunud arv',
                expiredProductsCount: 'Aregunud toodete arv',
                expiredSum: 'Aegunud summa',
                expiring: 'Aegumine',
                expiryDate: 'Viimane müügipäev',
                exportExcel: 'Ekspordi Excelisse',
                fetchAdvertisements: 'Toon reklaame',
                fetchingCabinets: 'Toon kappe',
                fetchingCabinetsError: 'Kappide toomisel ilmnes viga',
                fetchingCabinetStatus: 'Kapi oleku toomine',
                fetchingError: 'Toomise ajal ilmnes viga',
                fetchingInventory: 'Toon kapi inventuuri',
                fetchingProducts: 'Toon tooteid',
                fetchingReport: 'Toon aruandeid',
                fetchingVatCategories: 'Toon maksukategooriaid',
                fetchingProductCategories: 'Tootekategooriate omandamine',
                fillForm: 'Täida vorm',
                freshFood: 'See on aeguv toode',
                freezerPackage: 'Külmutatud toode',
                generalFetchError: 'Ebaõnnestumine alla laadida',
                generalRequestReject: 'Taotlus ebaõnnestus',
                get: 'Hangi',
                group: 'Grupp',
                holdOn: 'Oota',
                hour: 'tund',
                hours: 'tundi',
                hoursBeforeExpiry: 'tundi enne aegumist',
                daysBeforeExpiry: 'Päevi enne aegumist',
                image: 'Pilt',
                imageOrVideo: 'Vali pilt või video',
                inActive: 'Deakitveeritud',
                individualCabinets: 'Individuaalsed kapid',
                individualCabinetsOnly: 'Ainult individuaalsed kapid',
                invalidBarcodeOnlyDigits: 'Ribakood peab sisaldama ainult numbreid',
                invalidBarcodeTooLong: 'Ribakood on liiga pikk',
                invalidBarcodeTooShort: 'Ribakood on liiga lühike',
                invalidCabinetNameTooShort: 'Kapi nimi on liiga lühike',
                invalidCabinetNameDuplicated: 'Kapi nimi on juba olemas',
                invalidLocationNameTooShort: 'Asukoha nimi on liiga lühike',
                invalidLocationNameDuplicated: 'Asukoha nimi on dubleeritud',
                invalidPercentage: 'Kehtetu väärtus',
                invalidPrice: 'Kehtetu hind',
                invalidProductBarcode: 'Kehtetu ribakood',
                invalidTitle: 'Kehtetu pealkiri',
                invalidBundleItems: 'Sisaldab kehtetuid üksusi',
                inventoryLength: 'Inventuuri pikkus',
                inventoryNotReported: 'Kapp ei ole inventuuri saatnud',
                inventoryTitle: 'Kapi inventuur',
                'Inventory History': 'Varude ajalugu',
                itemReplenishedOutOfInventoryProcess: 'See toode või silt on kappi asetatud muul viisil kui täiendamise protsess',
                itemSoldOn: 'Toode müüdud',
                'Last 30 days': 'Viimased 30 päeva',
                'Last 7 days': 'Viimased 7 päeva',
                'Last month': 'Eelmine kuu',
                'Last quarter': 'Eelmine kvartal',
                'Last week': 'Eelmine nädal',
                'Last year': 'Eelmine aasta',
                lastUpdate: 'Viimane uuendus',
                lifetime: 'Eluaeg',
                location: 'Asukoht',
                locations: 'Asukohad',
                logoEditor: 'Logo muutja',
                logoSettings: 'Logo seaded',
                loading: 'Laadimine...',
                measure: 'Mõõda',
                measures: 'Mõõdud',
                minsBeforeExpiry: 'Minutit enne aegumist',
                minutes: 'minutid',
                month: 'Kuu',
                name: 'Nimi',
                newVatCategoryModalTitle: 'Uus maksukategooria',
                newProductCategoryModalTitle: 'Uus tootekategooria',
                newSupplierModalTitle: 'Uus tarnija',
                'no region': '(pole asukohta)',
                noAdvertisements: 'Pole reklaame',
                noCabinets: 'Pole kappe',
                noCabinetSelected: 'Kappi pole valitud',
                noLogo: 'Logo puudub',
                noData: 'Andmed puuduvad',
                noDescription: 'Kirjeldus puudub',
                noEvents: 'Üritused puuduvad!',
                noIndividualCabinets: 'Eraldi kapid puuduvad',
                noLocationPrices: 'Selle toote kohalike hindasid ei ole määratud',
                none: 'Ei ole',
                noPaymentMethodLogo: 'Makseviisi logo puudub',
                noProducts: 'Tooteid ei ole',
                noProductsRemoved: 'Ühtegi toodet ei liigutatud',
                noProductTypeSelected: 'Paketi tüüpi pole valitud',
                normalPrice: 'Tavahind',
                normalSalesAmount: 'Tavamüükide summa',
                notAccessibleForYou:
                    'Funktsioon Loo / Uuenda on lubatud ainult administraatoritel ja kapioperaatoritel. Juurdepääsu saamiseks pöörduge oma adminni või süsteemiadminni poole.',
                notDefined: 'Ei ole defineeritud',
                noTitle: 'Pealkiri puudub',
                noVatCategories: 'Maksukategooria andmeid ei leita',
                noProductCategories: 'Tootekategooria kirjet ei leitud',
                noInventoryOnSelectedDate: 'Valitud kuupäeval ei esitatud ühtegi laoseisu. Proovige mõnda muud kuupäeva.',
                openDoor: 'Ava uks',
                orderSum: 'Tellimuse summa',
                paymentMethodLogo: 'Makseviisi logo',
                percentage: 'Protsent',
                permanent: 'Püsiv',
                preview: 'Eelvaade',
                price: 'Hind',
                print: 'Trüki',
                productCreated: 'Toode loodud',
                products: 'Tooted',
                productsFetchError: 'Toodete toomisel ilmnes viga',
                productsTitle: 'Tootekataloog',
                productType: 'Pakendi tüüp',
                productUpdated: 'Toode on uuendatud',
                quantity: 'Kogus',
                readerDown: 'RFID-lugeja ei tööta',
                region: 'Asukoht',
                regions: 'Asukohad',
                removedAt: 'Eemaldatud',
                removeImage: 'Eemalda pilt',
                removeVideo: 'Eemalda video',
                refillQRCodeInfo: 'Täitmisrakenduse avamiseks või järgimiseks skannige QR-kood',
                refillQRCodeTitle: 'Täida QR-kood',
                refillRuleTitle: 'Täitmisreegel',
                reportsTitle: 'Aruanded',
                requestSuccess: 'Muudatused tehtud',
                requiredPercentage: 'Nõutud. Protsendi väärtus ilma protsendimärgita.',
                requiredText: 'Nõutav märkide pikkus: ',
                retireLocation: 'Keela asukoht',
                rfidNoInfo: 'RFID-lugeja ja antennide kohta teave puudub',
                rfidOk: 'RFID-luger ja antennid on töökorras.',
                sales: 'Müügid',
                salesCount: 'Müükide arv',
                salesVsWasteAmount: 'Müükide vs Kao kogus',
                save: 'Salvesta',
                search: 'Otsi',
                selectCabinet: 'Vali kapp',
                selectDiscountRuleCabinets: 'Vali allahindlusreegliga kapid',
                selectDiscountRuleLocations: 'Vali allahindlusreegliga asukohad',
                selectDiscountRuleProducts: 'Vali allahindlusreegliga tooted',
                selectProductType: 'Palun valige oma toote jaoks pakendi tüüp',
                setDiscountAmount: 'Määra allahindluse summa',
                setDiscountTimeBeforeExpiry: 'Määra aegumisintervall',
                settingConfirmMessage: 'Kas soovid salvestada? Kui ei, siis ära salvesta',
                showDisable: 'Arhiveeritud tooteid',
                summary: 'Kokkuvõte',
                sureRemoveDiscountRule: 'Kas soovid allahindluse reegli eemaldada?',
                tagIsBad: 'Kehtetu silt, võta see kapist ära.',
                tagsRemovedReport: 'Eemaldatud siltide raport',
                taxValue: 'Maksuväärtus',
                'This month': 'Sel kuul',
                'This quarter': 'Sel kvartalil',
                'This week': 'Sel nädalal',
                'This year': 'Sel aastal',
                thisLink: 'see viide.',
                timeGrouping: 'Aja grupeerimine',
                title: 'Pealkiri',
                Today: 'Täna',
                topExpiredProducts: 'Top aegunud tooted',
                topProducts: 'Top tooted',
                total: 'Kokku',
                totalValue: 'Koguväärtus',
                transactions: 'Tehingud',
                ttlInDays: 'Toote elutsükkel päevades',
                ttlsAffected: 'Tooted, mille elutsüklit reegel mõjutab',
                unexpectedError: 'Ootamatu viga',
                uniqueCustomers: 'Kliendid',
                unknown: 'Teadmata',
                update: 'Uuenda',
                updateAdError: 'Reklaami uuendamisel tekkis viga',
                updateAdTitle: 'Uuenda reklaami',
                updatedAd: 'Reklaam uuendatud',
                updateCabinetSettings: 'Sünkrooni andmed kapiga',
                updateCabinetSettingsInfo: 'Kas soovite kapi seadistusi muuta?',
                updateData: 'Uuenda andmeid',
                updateProductTitle: 'Uuenda tooteid',
                updatingAd: 'Uuendan reklaame',
                updatingProduct: 'Uuendan tooteid',
                uploading: 'Laen üles',
                uploadingImage: 'Laen pilti',
                use: 'Kasuta',
                'w/o grouping': 'Pole grupeeritud',
                waste: 'Kadu',
                vatCategoriesFetchError: 'Maksukategooria toomisel tekkis viga',
                vatCategoriesTitle: 'Maksukategooriad',
                vatCategory: 'Maksukategooria',
                vatCategoryItems: 'Kaubad',
                vatCategoryName: 'KM kategooria nimi',
                vatCategoryPromptMessage: 'Kas soovid maksukategooria kustutada',
                vatCategoryValue: 'Maksumäär',
                vatDeleteRejected: 'Maksukategooria kustutamine ebaõnnestus. Kontrolli seotud tooteid.',
                vatErrorRequesting: 'Taotluse töötlemisel tekkis viga',
                vatRequestRejected: 'Taotlus lükati tagasi',
                vats: 'Käibemaks',
                productCategoriesFetchError: 'Tootekategooriate toomisel ilmnes viga',
                productCategoriesTitle: 'Tootekategooriad',
                productCategory: 'Tootekategooria',
                productCategoryItems: 'Kategooriad',
                productCategoryName: 'Tootekategooria nimi',
                productCategoryPromptMessage: 'Kas soovid tootekategooria kustutada?',
                productCategoryDescription: 'Tootekategooria kirjeldus',
                productCategoryHelpTextDescription: 'Näiteks salatikategooria',
                productCategoryHelpTextName: 'Näiteks salatid',
                productCategoryDeleteRejected: 'Tootekategooria eemaldamine lükati tagasi. Tutvu seotud toodetega!',
                week: 'nädal',
                viewMode: 'Vaade',
                year: 'aasta',
                Yesterday: 'eile',
                groupBy: 'Grupi järgi',
                filterBy: 'Filtri järgi',
                reportType: 'Aruande tüüp',
                custom: 'Kohandatud',
                preDefined: 'Eelnevalt määratletud',
                selected: 'Valitud',
                metricsInfo: 'Mõõdikud (summa, max, min...) arvutatakse kõigi valitud mõõtmete / ressursside jaoks',
                dimensionsInfo: 'Valitud mõõtude / ressursside jaoks tuuakse mõõdikud',
                metrics: 'Mõõduikud',
                controlsSoftwareVersion: 'Tarkvara versioon',
                currentSoftwareVersion: 'Praegune tarkvaraversioon',
                availableSoftwareVersion: 'Saadaolev tarkvaraversioon',
                invalidLifetime: 'Kehtetu elutsükli väärtus',
                unpaidSales: 'Maksmata müügid',
                unpaidTransactions: 'Maksmata tehingud',
                softwareUpdateConfirmation: 'Kas soovite värskendada valitud seadme(te) tarkvaraversiooni?',
                softwareUpdateWarn:
                    'Tarkvaraversiooni värskendamine saadab seadme(te)le käsu värskenduse toomiseks kohe või valitud ajal. Valitud seade(seadmed) installivad värskenduse järgmine kord, kui nad on pärast käivitust võrgus. Paigaldamine võib sõltuvalt ühenduse kiirusest veidi aega võtta. Seade(seadmed) taaskäivitatakse pärast installimist.',
                releaseVersion: 'Väljalaske versioonid',
                releaseDate: 'Väljalaske kuupäev',
                latestRelease: 'Viimane väljalase',
                currentVersion: 'Praegune versioon',
                softwareUpdateSuccess: 'Tarkvaraversiooni on uuendatud',
                prebooking: 'Eelbroneering',
                prebookedProduct: 'Ettetellitud toode',
                prebookedProducts: 'Ettetellitud tooted',
                activeProducts: 'Aktiivsed tooted',
                startMaintenanceMode: 'Käivita kapi hooldusrežiim',
                endMaintenanceMode: 'Lõpeta kapi hooldusrežiim',
                startMaintenanceConfirm: 'Kas soovid kapi hooldusrežiimi käivitada?',
                endMaintenanceConfirm: 'Kas soovid kapi hooldusrežiimi lõpetada?',
                startMaintenanceSuccess: 'Kapi hooldusrežiim algas',
                endMaintenanceSuccess: 'Kapi hooldusrežiim lõpp',
                noResults: 'Tulemused puuduvad',
                suppliers: 'Tarnijad',
                supplier: 'Tarnija',
                supplierDeleteRejected: 'Tarnija kustutamine lükati tagasi. Kontrollige seotud tooteid!',
                supplierPromptMessage: 'Kas soovid tarnija kustutada?',
                fetchingSuppliers: 'Tarnijate toomine',
                noSuppliers: 'Tarnija kirjet ei leitud',
                suppliersFetchError: 'Viga tarnijate toomisel',
                supplierName: 'Tarnija nimi',
                supplierHelpTextName: 'Näiteks kaubamärgi nimi',
                supplierDescription: 'Tarnija kirjeldus',
                supplierHelpTextDescription: 'Näiteks kaubamärgi kirjeldus',
                wishes: 'Soovid',
                checkNow: 'Kontrolli kohe',
                startTimeOn: 'Algusaeg',
                endTimeOn: 'Lõpuaeg',
                signOutConfirmationModalTitle: 'Väljalogimise kinnitus',
                signOutConfirmationModalContent: 'Kas soovite välja logida?',
                startDate: 'Alguskuupäev',
                endDate: 'Lõppkuupäev',
                scheduledDiscountApplyAllDays: 'Rakendamine kõigile päevadele',
                scheduledDiscountApplySelectedDays: 'Rakendamine valitud päevadele',
                discountCustomImageTitle: 'Allahindluse pilt (valikuline)',
                discountCustomImageModalHeader: 'Allahindluse pilt',
                saveChanges: 'Salvesta muudatused',
                updateTagsQualitySettingsTitle: 'Sildi kvaliteedisätted',
                updateTagsQualitySettingsMessage: 'Uuendatud sildikvaliteedi sätted',
                IF3Cabinets: 'Mudel 3 külmikud',
                IF2Cabinets: 'Mudel 2 külmikud',
                IF3Freezers: 'Mudel 3 sügavkülmikud',
                encodingStations: 'Jaamade kodeerimine',
                if2: 'Mudel 2 külmik',
                if3: 'Mudel 3 külmik',
                if3Freezer: 'Mudel 3 sügavkülmik',
                legalNotice: 'Õigusalane teatis',
                privacyPolicy: 'Privaatsuspõhimõtted',
                scheduledDiscountDateMissingError: 'Mõlemad kuupäevad on vajalikud, kui algus- või lõppkuupäev on määratud',
                scheduledDiscountDuplicateTimeError: 'Kellaajad ei saa olla samad, kui kuupäevad on samad',
                now: 'Kohe',
                minimum: 'Minimaalne',
                maximum: 'Maksimaalne',
                discardChangesConfirmationText: 'Kas soovite muudatustest loobuda?',
                exports: 'Ekspordid',
                selectAllCabinets: 'Vali kõik kapid',
                expiryIntervals: 'Aegumisintervallid',
                timeBeforeExpiry: 'Aeg enne aegumist',
                apply: 'Kohalda',
                yes: 'Jah',
                no: 'Ei',
                productOnScreenBannerText: 'Ekraanil kuvatava bänneri tekst',
                productOnScreenBannerAdditionalInfo:
                    'Bänneri tekst on ühe-realine tekst, mis kuvatakse toote pildi all ja mis edastab lühidalt põhifunktsioone, tervisekaebusi või muud tootega seotud lisateavet',
                productOnScreenBannerExampleText: 'Laktoosivaba',
                UI_IS_DOWN: 'Kapi kuva ei tööta',
                READER_IS_DOWN: 'RFID lugeja on väljas',
                ANTENOR_DEVICE_DISCONNECTED: 'Maksevahend on ühendamata',
                EXPIRED_PRODUCT: 'Aegunud tooted kapis',
                CLOUD_SERVICE_DOWN: 'Pilveteenus on maas',
                ANTENNA_DISCONNECTED: 'Antenn on lahti ühendatud',
                TAG_CHANGES: 'Liiga palju halbu silte kapis',
                ANTENNA_DOWN: 'RFID-antenn kapis ei tööta',
                READER_CONFIG_ERROR: 'RFID lugeja konfiguratsioon ebaõnnestus ja ei tööta',
                activeAlarms: 'Aktiivsed häired',
                deviceStatus: 'Seadme staatus',
                lastMonitored: 'Viimati jälgitud',
                noService: 'Teenuseta',
                operational: 'Töökorras',
                offline: 'Offline',
                locationsSelectHelp: 'Kui lisatakse asukoht, lisatakse ka selle alamkohad.',
                select: 'Valige...',
                labels: 'Sildid',
                productDetails: 'Toote üksikasjad',
                invalidProductName: 'Toote nimi peab olema 1 kuni 125 tähemärki pikk',
                standardPrice: 'Standardhind',
                locationPrices: 'Asukoha hinnad',
                addNew: 'Lisa uus',
                productExpiry: 'Toote aegumine',
                productLifeTime: 'Toote eluaeg',
                invalidTTL: 'Toote eluea pikkus peab olema täisarv vahemikus 0 kuni 32767',
                productImage: 'Toote pilt',
                uploadImage: 'Laadi pilt üles',
                pleaseUploadImage: 'Palun laadi pilt üles',
                productValidationError: 'Paranda järgmised vead enne toote andmete salvestamist',
                productSupplier: 'Toote tarnija',
                frozenProductLable: 'See on külmutatud toode',
                prebookingLabel: 'See on ettetellimistoode',
                previous: 'Eelmine',
                bannerText: 'Reklaamteksti',
                packagingTypeValidationError: 'Vali toote pakenditüüp',
                showOnlyFrozen: 'Näita ainult külmutatud tooteid',
                productClassification: 'Toote klassifikatsioon',
                productClassLabel: 'Palun valige tootele sobiv klass. See võimaldab Selfly Insightsil pakkuda asjakohasemat tooteteavet.',
                productLabels: 'Toote sildid',
                productLabelsHelpText: 'Palun lisage oma tootele valikulised sildid. See võimaldab teil oma tooteid tooteloendis filtreerida.',
                newLabel: 'Uus silt',
                productClassValidationError: 'Palun valige oma tootele allolevate valikute hulgast klassifikatsioon',
                tagSensitivity: 'Sildi kvaliteeditundlikkus',
                tagSensitivitySetting: 'Sildi kvaliteeditundlikkuse seadistus',
                configTagSensitivity: 'Toote sildi kvaliteeditundlikkuse seadistamine',
                productLevelSensitivityInfo:
                    'Tootele kinnitatud RFID-sildi loetavust võivad mõjutada erinevad tegurid, sealhulgas pakendimaterjalid, metallpinnad, niiskus ja isegi toote enda sisu. Need tegurid võivad mõnikord põhjustada sildisignaali ajutist katkestust, mida tuntakse vilkumisena. Kapp töötleb seda vilkumist oma tundlikkuse seadete põhjal. Kui te pole kindel, milline tundlikkuse tase sobib sellele tootele, võite selle jaotise vahele jätta.',
                errorSaving: 'Viga toote andmete salvestamisel',
                successSaving: 'Toote andmed edukalt salvestatud',
                basicDetail: 'Põhiandmed',
                additionalDetail: 'Täiendavad üksikasjad',
                packaging: 'Pakenditüüp',
                classification: 'Klassifikatsioon',
                selectOrtype: 'Vali või kirjuta...',
                max25Chars: 'maks. 25 tähemärki',
                noVatForProductCreation: 'Loo enne uute toodete lisamist KM-kategooria',
                lifeTimeExplanation: 'Mitu päeva on see toode müügiks kehtiv. (0 päeva) tähendab kuni praeguse päeva lõpuni',
                selflyEncode: 'Selfly Encode',
                userRestrict: 'Ainult tunnustatud tarbijad',
                userRestrictTitle: 'Õigustatud tarbijad',
                userNotRestrict: 'Kõik tarbijad',
                userRestrictHelperText:
                    'Tunnustatud tarbijate valik tähendab, et allahindlust rakendatakse ainult neile tarbijatele, kes on juba registreeritud allahindluse saamiseks!',
                searchCabinets: 'Otsi kappe',
                selectAll: 'Vali kõik',
                monday: 'Esmaspäev',
                tuesday: 'Teisipäev',
                wednesday: 'Kolmapäev',
                thursday: 'Neljapäev',
                friday: 'Reede',
                saturday: 'Laupäev',
                sunday: 'Pühapäev',
                fetchingLoader: 'Laadimine. Palun oodake...',
                category: 'Kategooria',
                okay: 'Ok',
                noLocation: 'Asukohta pole',
                copy: 'Kopeeri',
                pleaseConfirm: 'Palun kinnitage',
                saveQuery: 'Salvesta see päring',
                saveQueryPlaceholder: 'nt. "Kõikide müüdud toodete arv"',
                saveQuerySuccess: 'Päring on salvestatud.',
                yourSavedQueries: 'Teie salvestatud päringud',
                selectQuery: 'Valige päring',
                noQuery: 'Salvestatud päringuid veel pole',
                deleteAdConfirm: 'Oled kindel, et soovid selle reklaami kustutada?',
                dataSaved: 'Andmed salvestatud edukalt',
            },
            refill: {
                refillRuleApplyRuleDropdownText: 'Kohalda seda reeglit ka teistele kappidele',
                refillPlanGenerate: 'Loo täitmisplaan',
                refillPlanEditPlan: 'Plaani muutmine',
                refillAmount: 'Täitmise summa',
                refillRuleTargetAmount: 'Sihtvarude tase',
                refillRuleTargetAmountShort: 'Siht',
                refillPlanActivePlans: 'Aktiivsed plaanid',
                refillPlanCompletedPlans: 'Lõpetatud plaanid',
                refillNote: 'Täitmisalus',
                refillNoteHelp: 'Toote täitmise märkus (kuni 125 tähemärki)',
                refillPDFText: 'Allpool on kapi täitmiskava. Palun veenduge, et pärast täitmist täidaksite täitmiskava täitmise taotluses.',
                refillPDFSummaryText: 'Allpool on täitmiskavade kokkuvõte kõigi valitud kappidele lisamiseks ja eemaldamiseks mõeldud toodetega.',
                refillSummary: 'Täitmise kokkuvõte',
                addItems: 'Lisa tooted',
                removeItems: 'Eemalda tooted',
                inventoryChange: 'Varude muutus',
                planogramPDFText: 'Kapi täitestrateegia planogramm.',
                planogramImagePDFText: 'Kapi täitestrateegia kohandatud pilt.',
                refillSchemas: 'Täitemallid',
                refillPlans: 'Täiteplaanid',
                refillSchemaTitle: 'Määrake valitud kappidele siht- ja minimaalne varude hulk mallina. Neid malle kasutatakse täiteplaanide koostamiseks.',
                refillPlansTitle:
                    'Vaadake kõiki aktiivseid plaane. Võite redigeerida või eemaldada olemasolevaid plaane või lisada uusi täiteplaane vastavalt vajadusele.',
                refillSchemaSubText: 'Palun valige kapp, et luua või uuendada täitemalli.',
                noRefillSchema: 'Selle kapi jaoks pole veel täitemalli määratud.',
                createRefillSchema: 'Loo uus täitemall',
                noDevice: 'Seadet ei leitud',
                schemaSaved: 'Täitemall salvestatud edukalt.',
                deleteSchemaConfirmation: 'Kas olete kindel, et soovite täitemalli kustutada?',
                targetAmount: 'Sihtkogus',
                targetAmountHelpText: 'Sihtvarude kogus iga toote jaoks kapis pärast täitmist.',
                minimumThreshold: 'Minimaalne künnis',
                minimumThresholdHelpText:
                    'Määrake iga toote minimaalne varude tase kapis. Kui kogus langeb alla määratud künnise, saate alarmiteavituse. Künnise seadmine väärtusele 0 keelab teavitused.',
                editNote: 'Redigeeri märkust',
                refillNoteTitle: 'Kirjutage toote täitemärkus.',
                refillNoteCharacters: 'tähemärki',
                noRefillPlan: 'Hetkel pole aktiivseid täiteplaane. Palun lisage oma kappidele uued täiteplaanid või looge esmalt täitemall.',
                planSaved: 'Täiteplaan salvestatud edukalt.',
                deleteConfirmation: 'Kas olete kindel, et soovite valitud täiteplaane kustutada?',
                editPlan: 'Redigeeri plaani',
                currentInventory: 'Praegune varu',
                lastSevenDaySales: 'Viimase seitsme päeva müük',
                inventory: 'Varud',
                planogram: 'Planogramm',
                copyRefillSchema: 'Kopeeri mall teistesse kappidesse',
                generatingPlans: 'Täiteplaanide genereerimine. Palun oodake...',
                generatePlans: 'Genereeri täiteplaane',
                complete: 'valmis',
                selectCabinetsToAddPlans: 'Palun valige kapid, mille jaoks täiteplaane luua.',
                reviewSuggestion:
                    'Allpool on esitatud soovituslikud täiteplaanid valitud kappidele. Palun vaadake need üle, vajadusel redigeerige ja salvestage nende plaanide aktiveerimiseks.',
                noSchema: 'Hetkel pole ükski kapp täitemalli määratud. Palun lisage kappidele täitemallid, et koostada täiteplaane.',
                searchProducts: 'Otsi tooteid',
                maxAllowedInventory: 'Maksimaalne lubatud varu on 200.',
                savingData: 'Andmete salvestamine. Palun oodake.',
                errorSavingData: 'Andmete salvestamisel ilmnes viga.',
                refillPlanSaved: 'Täiteplaanid on edukalt loodud.',
                exportPDFHelp: 'Eksportige valitud plaanid PDF-failiks.',
                exportExcelHelp: 'Eksportige valitud plaanid Excel-failiks.',
                addPlanHelp: 'Lisage uusi täiteplaane.',
                deletePlanHelp: 'Kustutage valitud täiteplaanid.',
                generatePlansHelp: 'Genereerige täiteplaane valitud kappidele.',
                planogramSaved: 'Planogramm edukalt salvestatud.',
                schemaCopied: 'Täitemall on edukalt sünkroniseeritud teiste kappidega.',
                allProducts: 'Kõik tooted',
                schemaProducts: 'Täitemalli tooted',
                deletePlanogram: 'Kas olete kindel, et soovite planogrammi kustutada?',
                dragInPlanogram: 'Palun lohistage tooted määratud kapi riiulitele või lohistage kapi pesa tootele.',
                noPlanogramInSchema: 'Kapile pole veel planogrammi määratud.',
                noPlanogramInPlan: 'Kapi jaoks pole saadaval planogrammi andmeid. Palun looge kapile planogramm täitemalli vahekaardil.',
                copyPlanogram: 'Kopeeri täiteplanogramm koos täitemalliga.',
                planogramImageInfo: 'Lisaks planogrammile saate lisada ka kohandatud planogrammi pildi.',
                addPlanogramImage: 'Lisa planogrammi pilt',
                editPlanogramImage: 'Muuda planogrammi pilti',
                numberOfShelves: 'Riiulite arv',
                addNewRefillPlan: 'Lisa uusi plaane',
                qrcodeTitle: 'Valitud kapile genereeritakse QR-kood täiteprotsessi alustamiseks.',
                qrcodeSubText: 'Palun valige kapp, milles soovite täiteprotsessi alustada.',
                qrcodeScan: 'Palun skaneerige QR-kood täitmiseks või klõpsake nuppu, et avada täite rakendus.',
                qrcodeOpenApplication: 'Ava täiterakendus',
                createRefillPlanogram: 'Loo täiteplanogramm',
            },
            navigation: {
                advertisements: 'Reklaamid',
                analytics: 'Analüüs',
                controlPanel: 'Juhtpaneel',
                deviceManagement: 'Seadmehaldus',
                discounts: 'Soodustused',
                fetching: 'Toomine',
                group: 'Grupp',
                inventory: 'Kapi inventuur',
                language: 'Keel',
                productCatalog: 'Tootekataloog',
                productCategories: 'Tootekategooriad',
                reports: 'Aruanded',
                settings: 'Kasutaja seaded',
                signOut: 'Logi välja',
                title: 'Selfly pilveportaal',
                vatCategories: 'Maksukategooriad',
                community: 'Selfly Community',
                refillManagement: 'Täitehaldus',
                automations: 'Automatiseerimine',
                mySelfly: 'Minu Selfly seadistused',
                automationInfo: 'Halda automatiseeritud toiminguid (BETA)',
            },
            reports: {
                alreadyRefund: 'tagasimakse',
                amount: 'Summa',
                average: 'Keskmine',
                barcode: 'Ribakood',
                confirmIssueRefundButton: 'Kinnita tagasimakse',
                current: 'Praegune',
                export: 'Eksport',
                fetching: 'Otsimine',
                fetchingError: 'Otsingu viga',
                image: 'Pilt',
                issueRefundButton: 'Tagasimakse',
                maximum: 'Maksimum',
                minimum: 'Minimum',
                name: 'Nimi',
                noData: 'Andmeid pole',
                note: 'Märkus',
                order: 'Tehing',
                paymentWasDoneByCreditCard: 'Makse tehti kaardiga',
                price: 'Hind',
                ProductReport: 'Tootearuanne',
                quantity: 'Kogus',
                'Refund impossible': 'Maksmine toimus krediitkaardiga. Tagasimakse saamiseks pöörduge krediitkaardi terminali väljaandja poole.',
                refundFormTitle: 'Tagasimakse',
                refundReason: 'Tagasimakse põhjus',
                refundSuccess: 'Toimus tagasimakse summas {{sum}}',
                refundTotal: 'Tagasimakse summa',
                replenishments: 'Täitmised',
                replenishmentsTitle: 'Täitmiste aruanne',
                SaleTransaction: 'Müügitehingud',
                summary: 'Kokkuvõte',
                SummaryReport: 'Müükide kokkuvõte',
                temperature: 'Temperatuur',
                temperatureTitle: 'Temperatuuride aruanne',
                temperatureValueNotification: 'Temperatuuriandmed on kapi temperatuuri keskmised väärtused tunnis.',
                time: 'Aeg',
                total: 'Kokku',
                transactions: 'Tehingud',
                user: 'Kasutaja',
                paidTransactions: 'Makstud tehingud',
                unpaidTransactions: 'Maksmata tehingud',
                partiallyPaid: 'Osaliselt makstud',
                failedTransactions: 'Ebaõnnestunud tehingud',
                amountOwed: 'Võlgnetav summa',
                receiptLinkText: 'Hankige kviitung siit',
                noPurchase: 'Ost ei ole',
                other: 'Muud',
                userId: 'Kasutaja ID:',
            },
            settings: {
                block_cabinet_when_product_expired: 'Sulge kapp vältimaks aegunud toodete müüki',
                criticalExpiryLimitHours: 'Tundide arv enne olulist aegumishoiatust',
                language: 'Keel',
                logo: 'Firma logo',
                mediumExpiryLimitHours: 'Tundide arv enne keskmist aegumishoiatust',
                payment_method_logo: 'Makseviisi logo',
                footer_instruction_logo: 'Jaluse juhise pilt',
                readUserMemory: 'Otsin toote aegumisandmeid',
                ui_skus_sort_by: 'Toodete sorteerimise järjekord kapiekraanil',
                timeZone: 'Ajavöönd',
                country: 'Riik',
                city: 'Linn',
                tagSettings: 'Sildi seaded',
                themeAndLogos: 'Teema ja logod',
                advancedSettings: 'Täpsemad seaded',
                themingInfoText: 'Saate kohandada oma kapi ekraani kujundust',
                cabinetLogos: 'Lapi logod',
                cabinetLogoInfoText: 'Laadi üles kapi ekraanile ilmuvad logod',
                logoHelperText: 'Kasutage 180px x 180px pilti',
                settingsCopyInfoHeader: 'Valige kapid, kuhu soovite seaded kopeerida',
                settingsCopyButton: 'Kopeeri seaded valitud kappidesse',
                settingsCopySuccessModalTitle: 'Seadete kopeerimine',
                settingsCopySuccessModalContent: 'Seaded kopeeriti {{cabinetsAmount}} kappi',
                theming: 'Teemad',
                adminSettings: 'Admini sätted',
                settingsCopyErrorModalTitle: 'Seadete kopeerimise viga',
                key: 'Seadistus',
                value: 'Väärtus',
                sleepScheduling: 'Planeeritud ooteaeg',
                sensitivityLevel: 'Tundlikkuse tase',
                sensitivity_0: 'Kapp eirab sildi vilkumist ja märgib järjekindlalt kõik sildid kehtivaks, mis võib mõnikord põhjustada vale arvestust.',
                sensitivity_20: 'Kapp talub kuni kolme vale loendust sildi kohta 50 skaneeringu jooksul ja märgib sildi siiski kehtivaks.',
                sensitivity_40: 'Kapp talub kuni kahte vale loendust sildi kohta 60 skaneeringu jooksul ja märgib sildi siiski kehtivaks.',
                sensitivity_60: 'Kui silt vilgub sageli, kuid püsib pikema aja jooksul stabiilsena, aktsepteerib kapp seda ja loeb sildi kehtivaks.',
                sensitivity_80: 'Kui silt vilgub hetkeks, kuid jääb pikema aja jooksul stabiilseks, peetakse seda kehtivaks sildiks.',
                sensitivity_100:
                    'Kapp on sildi vilkumise suhtes väga sallimatu. Isegi üks vale loendus võib põhjustada sildi pikema aja jooksul kehtetuks märkimise, mis võib põhjustada võimalikke kaotusi.',
                sensitivityInfo:
                    'Kapi sildi kvaliteedi tundlikkus viitab sellele, kui tõhusalt kapp käsitleb ebaühtlaseid sildi lugemisi või sildi vilkumist. Pange tähele valitud tundlikkuse taset, kuna see võib mõjutada kapi jõudlust. Kui te pole kindel, milline tase on sobiv, soovitatakse jätta seadistus vaikimisi tasemele 3.',
            },
            signIn: {
                signInButton: 'Logi sisse',
                signInExpiredError: 'Sessioon on aegunud. Logi uuesti sisse',
                signInMessage: 'Jätkamiseks peate olema sisse logitud. Sisselogimiseks klõpsake allolevat nuppu',
                noOrganizationsError: 'Selle kasutajanimega pole lingitud ühtegi ettevõtet. Proovige teist kasutajanime.',
            },
            translations: {
                add: 'Lisa',
                closed: 'Suletud',
                delete: 'Kustuta',
                device: 'Seade',
                edit: 'Muuda',
                off: 'VÄLJAS',
                offline: 'Võrgust väljas',
                on: 'SEES',
                online: 'Võrgus',
                opened: 'Avatud',
                save: 'Salvesta',
                seeDetails: 'Andmed',
                settings: 'Seaded',
                unknown: 'Teadmata',
            },
            consumerApp: {
                enableConsumerFeedback: 'Luba kasutajate tagasiside MySelfly kohta',
                enablePurchaseReceipts: 'Luba MySelfly e-kviitungid',
                enableSelflyTrademarkForReceipts: 'Luba e-kviitungites Selfy Store’i jalus',
                enableSelflyTrademarkForReceiptsHelpText: 'Selle lubamisel lisatakse e-kviitungi PDF-ile Selfly Store’i logo ja andmed!',
                organizationFormHelpText: 'Sisestage e-kviitungi väljaandja andmed.',
                organizationFormValidationHelpText: '* E-kviitungi kohustuslikud väljad on Väljaandja ettevõtte nimi, ettevõtte reg nr, aadress ja riik.',
                organizationName: 'Ettevõtte nimi',
                organizationLogo: 'Ettevõtte logo',
                businessId: 'Ettevõtte reg nr',
                city: 'Linn',
                email: 'E-post',
                website: 'Veebisait',
                postalCode: 'Postiindeks',
                street: 'Tänava aadress',
                phoneNumber: 'Telefoninumber',
                addressHeader: '* Aadressiteave',
                contactDetails: 'Kontaktandmed',
                organization: '* Väljaandja ettevõte',
                organizationSettingsUpdatedTitle: 'Ettevõtte seadete ajakohastamine',
                organizationSettingsUpdatedContent: 'Ettevõtte seadete ajakohastamine õnnestus',
                organizationSettingsMissingFields: 'E-kviitungi kohustuslikud väljad puuduvad. Palun täitke enne salvestamist.',
                organizationSettingsMissingFieldsTitle: 'Vormi kinnitamise viga',
            },
            softwareVersions: {
                updateNotificationTitle: 'Tarkvaraversiooni värskendus',
                updateNotificationContent: 'Tarkvaraversiooni(de) uuendamine õnnestus',
                selectAVersionPlaceholder: 'Vali versioon',
                searchDevices: 'Otsi seadmeid',
                updateSelected: 'Uuenda valitud',
                softwareVersions: 'Tarkvaraversioonid',
                softwareVersion: 'Tarkvaraversioon',
                notSupportedDeviceNote: 'See seade ei toeta versiooni {{selectedVersion.version}}',
                userNotificationContent: 'Teie seadmetele on välja antud uus tarkvaraversioon. Uusimate funktsioonide saamiseks värskendage oma seadmeid',
                userNotificationTitle: 'Välja antud uus tarkvaraversioon',
                notificationBell_newSoftwareVersions: 'Teie seadmete jaoks saadaolevad värskendused',
                manageVersions: 'Seadme tarkvaraversioonide haldamine',
                filterLocationsPlaceholder: 'Filtreeri asukohtade järgi',
                allVersionNumbers: 'Kõik väljaanded',
                noVersionsForUpdating: 'Värskendatavaid versioone pole',
                scheduledUpdateNote: 'Versiooni {{version}} värskendatakse {{scheduled}}',
                scheduleRemoveNotificationTitle: 'Tarkvaraversiooni ajastus',
                scheduleRemoveNotificationContent: 'Tarkvaraversiooni ajastatud värskenduse eemaldamine õnnestus',
                scheduleRemoveNotificationModalContent: 'Kas soovite selle seadme ajastatud värskenduse eemaldada?',
            },
            header: {
                searchByOrganization: 'Otsi ettevõtte järgi',
                pageLanguage: 'Keel',
                configureMySelflyAndEreceipts: 'MySelfly seadistamine',
                remindMeLater: 'Tuleta mulle hiljem meelde',
                updateNow: 'Uuendage kohe',
            },
            notifications: {
                noNewNotifications: 'Uusi teateid pole',
            },
            cabinetSettings: {
                cabinetTheme: 'Kapi teema',
                editAndSaveTheme: 'Muuda ja salvesta teema',
                colorPicker: 'Värvivalija',
                customTheme: 'Kohandatud teema',
                themeSettings: 'Kujunduse seaded',
                primary: 'Esmane',
                negative: 'Negatiivne',
                attention: 'Tähelepanu',
                positive: 'Positiivne',
                background: 'Taust',
                dark: 'Tume',
                light: 'Hele',
                white: 'Valge',
                default: 'Vaikimisi',
                negativeLight: 'Negatiivne valgus',
                primaryColor: 'Esmane värv',
                positiveColors: 'Positiivsed värvid',
                negativeColors: 'Negatiivsed värvid',
                secondaryColors: 'Teisesed värvid',
                attentionColors: 'Tähelepanu värvid',
                screenBackground: 'Ekraani taust',
                borderColors: 'Äärised – varjud',
                shadow: 'Vari',
                border: 'Ääris',
                fontColors: 'Fondi värvid',
                footerTextPlaceholder: 'Jaluse tekst',
                bundleDiscounts: 'Kombineeri ja salvesta',
                expiringText: 'Aeguvad tooted. Enne ostmist kontrollige aegumiskuupäeva.',
                expiredText: 'Aegunud tooted. Enne ostmist kontrollige aegumiskuupäeva.',
                cabinetName: 'Kapi nimi',
                productName: 'Toote nimi',
                confirmationModalHeader: 'Kas soovite kohandatud teema salvestada?',
                confirmationModalContent:
                    'Teema kohandamine mõjutab kapi ekraani visuaalset välimust. Veenduge, et muudatused ei kahjustaks ekraani visuaalset kvaliteeti.',
                defaultRevertModalMessage: 'Kas soovite muuta kapikujunduse vaikeväärtuseks?',
                backgroundColor: 'Taustavärv',
                backgroundImage: 'Taustapilt',
                footerBackground: 'Jaluse taust',
                cardsBackground: 'Kaardi taust',
                screenImage: 'Pilt kapi ekraani taustaks',
                uploadImage: 'Laadi üles pilt',
                editImage: 'Muuda pilti',
                transactionComplete: 'Tehing lõpetatud',
                customPostPurchaseImage: 'Palun laadige üles pilt kohandatud järelostu ekraani jaoks',
                postPurchaseScreen: 'Järelostu ekraan',
                custom: 'kohandatud',
                postPurchaseText: 'Järelostu kuvamise tekst',
            },
            automations: {
                transactionReports: 'Tehingu aruanded',
                refillReports: 'Täiendamise aruanded',
                notifications: 'Teavitused',
                refillPlan: 'Täiendamise plaanid',
                days: 'Päevad',
                months: 'Kuu',
                weeks: 'Nädalad',
                configuration: 'Konfiguratsioon',
                transactionReportTitle: 'Valitud kapid genereeritakse automaatselt tehingu aruanded ja saadetakse allpool toodud e-posti aadressidele.',
                refillReportTitle: 'Valitud kapid genereeritakse automaatselt täiendamise aruanded ja saadetakse allpool toodud e-posti aadressidele.',
                noSalesNotificationTitle:
                    'Teavitused saadetakse automaatselt allpool toodud e-posti aadressidele juhul, kui määratud intervallides pole müüki.',
                frequencyTitle: 'Kui tihti soovite aruande genereerida?',
                emailNotificationTitle: 'Palun lisage allpool e-posti aadressid, kuhu soovite, et aruanne või teavitus saadetaks.',
                refillPlanGenerationTitle: 'Täiendamise plaanid genereeritakse automaatselt valitud kappide jaoks vastavalt antud ajakavale.',
                addNewEmail: 'Lisa uus e-post',
                noSalesBanner: 'Te saate e-kirja teavituse, kui valitud kapid ei tee määratud ajavahemikus müüki.',
                noSaleTitle: 'Palun määrake ajavahemik (tundides), mille jooksul ei ole valitud kappides müüki, enne kui teavitust saadetakse.',
                minMaxTime: 'Minimaalne on 2 ja maksimaalne 23 tundi.',
                timeRangeTitle: 'Palun määrake kapide jaoks ajavahemik, mille jooksul kontrollitakse müügita olukorda.',
                startTime: 'Algusaeg',
                endTime: 'Lõppaeg',
                refillPlanTitle:
                    'Valitud kappide jaoks genereeritakse automaatselt täiendamise plaan. Palun planeerige allpool konkreetne aeg, millal soovite täiendamise plaani koostada.',
                refillPlanTime: 'Palun valige aeg täiendamise plaani genereerimiseks.',
                everyDay: 'Iga päev',
                everyWeek: 'Iga nädal',
                everyMonth: 'Iga kuu',
                selectDays: 'Vali päevad',
                automationSaved: 'Automatiseerimise seaded on edukalt salvestatud',
                automationDeleted: 'Automatiseerimise seaded on edukalt kustutatud',
            },
            sleepScheduling: {
                timeRangeTitle: 'Palun määrake ajavahemik, mille jooksul see kapp läheb ooterežiimile.',
                startTime: 'Algusaeg',
                endTime: 'Lõppaeg',
                selectDays: 'Vali päevad',
                duration: 'Kestus (tunnid)',
            },
        },
        ro: {
            dateRangeFilter: {
                lastMonth: 'ultimele 30 de zile',
                lastTwoWeek: 'ultimele 14 zile',
                lastWeek: 'ultimele 7 zile',
                selectDateRangeMsg: 'Interval de date selectat',
                today: 'astăzi',
                yesterday: 'ieri',
            },
            main: {
                activateSuccess: 'Produs activat cu succes',
                activationDate: 'Data activării',
                active: 'Activ',
                addAdvertisement: 'Adăugați o nouă reclamă',
                addDiscountRule: 'Adăugați regula de reducere',
                addDiscountStop: 'Adăugați interval de expirare',
                addNewLocation: 'Alegeți o nouă locație',
                addProduct: 'Adăugați un nou produs',
                addRegion: 'Adăugați locația',
                addRegionPrice: 'Adăugați prețul pentru locație',
                addScheduledDiscount: 'Adăugați o reducere programată',
                addBundleDiscount: 'Adăugați o reducere pentru un grup de produse',
                bundledDiscounts: 'Reduceri pentru grupuri de produse',
                bundleItemsLengthExceeded: 'Selecția maximă de produse (3) a fost depășită. Eliminați unele dintre selecțiile dvs. pentru a putea continua.',
                bundleMaxProductLimitInfo: 'Selectați max. trei (3) produse sau categorii de produse',
                bundleFixedAmountExceededOriginal: 'Prețul grupului de produse depășește suma prețului inițial. Verificați produsele selectate.',
                bundleFixedAmountNotAllowedToBeZero: 'Prețul grupului de produse trebuie să fie mai mare decât 0',
                bundlePercentageNotAllowedToBeFull: 'Reducerea în procente a grupului de produse trebuie să fie mai mică decât 100',
                bundlePercentageNotAllowedToBeZero: 'Reducerea în procente a grupului de produse trebuie să fie mai mare decât 0',
                bundleItemDiscountPriceAmountExceededOriginal: 'Prețul redus depășește prețul inițial. Verificați din nou prețul.',
                bundleItemPriceMessage: 'Fiecare regulă de stabilire a prețului se aplică unui singur articol, chiar daca sunt selectate mai multe articole.',
                bundleItemFixedAmountExceededOriginal: 'Prețul fix al produsului depășește prețul original al produsului. Verificați prețul fix din nou.',
                createDiscountRule: 'Creați regula pentru discount',
                deviceType: 'Tip dispozitiv',
                discountUpdateTitle: 'Actualizare reducere',
                discountCreateTitle: 'Creare reducere',
                discountDeleteTitle: 'Eliminare reducere',
                discountDeleteContent: 'Reducerea a fost eliminată',
                discountStatusUpdated: 'Statutul reducerii a fost actualizat',
                discountUpdated: 'Reducerea a fost actualizată',
                discountCreated: 'Reducerea a fost creată',
                discountSettings: 'Setări discount',
                discountImage: 'Imagine opțională discount',
                editDiscountStop: 'Editați interval de expirare',
                editBundleConfiguration: 'Editați configurația grupului de produse',
                editExpiryConfiguration: 'Modificare configurație expirare discount',
                encodingStation: 'Stație de codificare',
                everyDay: 'Zilnic',
                scheduledDiscountDateInfoLabel: 'Dacă doriți ca discountul să fie permanent, lăsați necompletate datele de început și de sfârșit',
                starts: 'Început',
                ends: 'Sfârșit',
                error: 'Eroare',
                errorRetiringLocationMsg:
                    'Nu s-a reușit retragerea locației. Vă rugăm să eliminați mai întâi dispozitivele conectate, prețurile produselor și reclamele.',
                fetchingDiscountRules: 'Obținerea regulilor de reducere',
                free: 'Gratuit',
                missingImage: 'Lipsește imaginea anunțului',
                next: 'Următorul',
                back: 'Înapoi',
                noDiscountRules: 'Nu au fost găsite reguli de reducere',
                scheduledDiscountsAutomaticDeactivationInfo:
                    'În cazul în care intervalul de date este depășit, reducerile sunt dezactivate automat. Daca doriți să le activați, editați intervalul de date pentru a fi valabil.',
                scheduledDiscounts: 'Reduceri planificate',
                schedulingStartHourIsOverEndHour: 'Ora de început nu poate fi după ora de sfârșit',
                schedulingEndHourIsUnderStartHour: 'Ora de sfârșit nu poate fi înainte de ora de început',
                selectDiscountRuleProductCategories: 'Selectați categoriile de produse afectate de regula de reducere',
                selectBundleProductsAmount: 'Selectați valoarea pentru: ',
                selectedBundleProductCategories: 'Categorii de produse selectate pentru grupul de produse',
                setDiscountDays: 'Setați zilele de reducere',
                setBundleDiscount: 'Setați fie valoarea procentuală a reducerii, fie prețul fix pentru grupul de produse',
                setBundleItemDiscount: 'Setați fie valoarea procentuală a reducerii, fie prețul fix pentru un articol, fie gratuit',
                setBundleProducts: 'Setați produse sau categoriile de produs pentru grupul de produse',
                setAmountMoreThanOne: 'În cazul grupului cu un singur articol, setați sumă mai mult de unul',
                setBundleDiscountType: 'Setați tipul de reducere și valoarea acesteia',
                sureActivateDiscountRule: 'Sigur doriți să activați regula de reducere?',
                sureDeactivateDiscountRule: 'Sigur doriți să dezactivați regula de reducere?',
                sureDeleteDiscountRule: 'Sigur doriți să ștergeți regula de reducere?',
                timeRange: 'Intervalul de timp',
                editExpiryDiscountRule: 'Editați regula de reducere',
                editScheduledDiscountRule: 'Editați regula pentru discountul programat',
                editBundleDiscountRule: 'Editați regula de discount pentru grupul de produse',
                releaseNotes: 'Note privind versiunea',
                seeMore: 'Vezi mai multe',
                scheduledDiscountTimeRangeLabel: 'Timp activ',
                scheduledDiscountDateRangeLabel: 'Intervalul de date al reducerilor',
                weekDayAbrvMonday: 'Luni',
                weekDayAbrvTuesday: 'Marți',
                weekDayAbrvWednesday: 'Miercuri',
                weekDayAbrvThursday: 'Joi',
                weekDayAbrvFriday: 'Vineri',
                weekDayAbrvSaturday: 'Sâmbătă',
                weekDayAbrvSunday: 'Duminică',
                recurrence: 'Recurentă',
                addScheduledDiscountStop: 'Adăugați discount programat',
                editScheduledDiscountStop: 'Editați discount programat',
                pieces: 'buc',
                expiryDiscountNavLabel: 'Expirare',
                scheduledDiscountNavLabel: 'Programat',
                bundleDiscountNavLabel: 'Grup de produse',
                amount: 'Suma',
                scheduling: 'Programare',
                pricing: 'Stabilirea prețului',
                percentageBundleDiscountInputPlaceholder: '% reducere pentru grupul de produse',
                fixedPriceBundleDiscountInputPlaceholder: 'Preț fix pentru grupul de produse',
                applyBundleLevelLabel: 'Aplicați nivelul grupului de produse',
                applyItemLevelLabel: 'Aplică la nivel de produs',
                productFixedPrice: 'Preț fix al produsului',
                percentageDiscount: '% reducere',
                fixedPriceDiscount: 'Preț fix',
                freeDiscount: 'Gratuit',
                advertisementsFetchError: 'S-a produs o eroare în timpul preluării reclamelor. Asigurați-vă că blocajul reclamelor este dezactivat',
                advertisementsTitle: 'Reclame',
                all: 'Toate',
                'All time': 'Toate timpurile',
                allProducts: 'Toate produsele fără grupare',
                allLocations: 'Toate locațiile',
                allCabinets: 'Toate vitrinele',
                antennaDown: 'Unele dintre antenele RFID nu funcționează',
                antennaError: 'Eroarea este de la antenele dispozitivului',
                antennaNoInfo: 'Nu sunt informații disponibile referitoare la antenele dispozitivului',
                antennaOk: 'Toate antenele funcționează',
                antennaPartial: 'Anumite antene sunt dezactivate',
                archiveSuccess: 'Produsul a fost arhivat cu succes',
                averageItems: 'Număr mediu de articole per tranzacție',
                averageSales: 'Vânzări medii per tranzacție',
                barcode: 'Cod de bare',
                barcodeType: 'Tip cod de bare',
                barcodeCannotBeChanged: 'Codul de bare nu poate fi modificat',
                barcodeTypeCannotBeChanged: 'Tipul codului de bare nu poate fi modificat',
                barcodeHelpText12: 'Introduceți 12 cifre ale codului de bare, ultima cifră este adăugată automat',
                barcodeHelpText11: 'Introduceți 11 cifre ale codului de bare, ultima cifră este adăugată automat',
                barcodeHelpText7: 'Introduceți 7 cifre ale codului de bare, ultima cifră este adăugată automat',
                beforeExpiry: 'înainte de expirare',
                cabinetDown: 'Dispozitivul nu funcționează',
                cabinetIsEmpty: 'Nu sunt produse în acest dispozitiv',
                deviceOffline: 'Dispozitivul nu are conexiune la internet',
                deviceOnline: 'Dispozitivul are conexiune la internet',
                cabinets: 'Dispozitive',
                cabinet: 'Dispozitiv',
                cabinetSettings: 'Setările dispozitivului',
                cabinetTheming: 'Tematica dispozitivului',
                cancel: 'Anulat',
                cancelledSales: 'Vânzări anulate',
                cancelledTransactions: 'Tranzacții anulate',
                categories: 'Categorii',
                change: 'Schimbă',
                clear: 'Șterge',
                close: 'Închide',
                clickEditButtonRegionalPrices: 'Pentru a edita preturile locației, faceți clic pe butonul de „Editare”.',
                clickEditLocationPrices: 'Prețuri diferite, faceți clic pe prețul produsului pentru a-l extinde.',
                clickToExpandLocationPrices: 'Prețuri diferite, faceți clic pe prețul produsului pentru a-l extinde.',
                closeDoor: 'Închideți ușa',
                rebootDevice: 'Reporniți dispozitivul',
                commandSentSuccessFully: 'Comandă trimisă cu succes',
                companyLogo: 'Sigla companiei',
                confirm: 'Confirmă',
                confirmDelete: 'Sigur doriți să ștergeți produsul? Această operațiune nu poate fi anulată!',
                confirmDisable: 'Sigur doriți să arhivați produsul?',
                confirmEnable: 'Sigur doriți să faceți produsul activ?',
                consumerResponses: 'Părere utilizator',
                controlPanelTitle: 'Panoul de comandă',
                controls_acceptablePercentageOfBadTags: 'Procentul acceptabil de etichete greșite înainte ca dispozitivul să treacă în modul de mentenanță',
                controls_markTagsGoodQuality: 'Marcați toate etichetele de bună calitate',
                controls_markTagsGoodQuality_btn: 'Marcați etichetele de bună calitate',
                controls_clearTagHistory: 'Ștergerea datelor privind istoricul etichetelor',
                controls_clearRemoveReplenishmentData: 'Ștergerea datelor privind istoricul de reaprovizionare',
                controls_companyAndPaymentLogo: 'Sigla companiei și a metodei de plată',
                controls_detectTagQuality: 'Detectează calitatea etichetelor',
                controls_door: 'Control ușă',
                controls_device: 'Control dispozitiv',
                controls_chargeBadTags: 'Facturați etichete greșite',
                controls_refill: 'Reumplere',
                controls_tagQuality: 'Setări etichete',
                controls_tagQualityData: 'Date de calitate a etichetei',
                controls_replenishmentData: 'Date de reaprovizionare',
                controls_soldTags: 'Etichete vândute',
                controls_tagHistory: 'Istoricul etichetelor',
                controls_markAllTagsReplenished: 'Marcați toate etichetele completate',
                controls_markAllTagsUnsold: 'Marcați toate etichetele nevândute',
                controls_markAlTagsReplenished_btn: 'Marcați etichetele completate',
                controls_markAllTagsUnsold_btn: 'Marcați etichetele nevândute',
                controls_chargeSoldTags: 'Facturați etichetele vândute',
                controls_chargeUnreplenishedTags: 'Facturați etichetele nereumplute',
                controls_tagsWarn:
                    'Ștergerea istoricului etichetelor elimină toate datele etichetelor. Faceți clic pe marcați toate etichetele completate după această acțiune',
                create: 'Creează',
                createAdTitle: 'Reclamă nouă',
                createdAd: 'Reclamă creată',
                createExpiryDiscountRule: 'Creați regula de expirare pentru discount',
                createScheduledDiscountRule: 'Creați regula pentru discountul programat',
                createBundleDiscountRule: 'Creează regula promoției',
                createNewVatCategory: 'Categorie TVA nouă',
                createProductTitle: 'Produs nou',
                creatingAd: 'Crearea unei reclame',
                creatingProduct: 'Crearea unui produs',
                createNewProductCategory: 'Crearea unei noi categorii de produse',
                createNewSupplier: 'Adăugați un nou furnizor',
                currency: 'Monedă',
                customQuery: 'Interogare personalizată',
                dashboard: 'Tabloul de bord',
                dateRange: 'Intervalul de timp',
                dataAlredyFetchedTitle: 'Date deja preluate',
                dataAlreadyFetchedDescription: 'Vă rugăm să selectați parametri diferiți',
                day: 'Zi',
                days: 'Zile',
                deactivationDate: 'Data dezactivării',
                default: 'Implicit',
                delete: 'Șterge',
                deleteError: 'Eroare: produsul nu a putut fi șters',
                deleteSuccess: 'Produsul a fost șters cu succes',
                description: 'Descriere',
                deviceManagement: 'Gestionarea dispozitivului',
                digitwinOffline: 'Dispozitivul digitwin nu este conectat la internet',
                digitwinOnline: 'Dispozitivul digitwin este conectat la internet',
                dimensions: 'Dimensiuni',
                disable: 'Arhivă',
                discountAmount: 'Valoarea reducerii',
                discountPrice: 'Preț cu reducere',
                discountRuleAffects: 'Regula de reducere afectează',
                discountRuleName: 'Denumirea regulii de reducere',
                discountRuleStops: 'Intervale de expirare',
                discounts: 'Reduceri',
                discountSalesData: 'Date privind vânzările cu reduceri',
                discountSalesAmount: 'Suma vânzărilor cu reducere',
                discountTimeBeforeExpiryExists: 'Intervalul de expirare anterior există deja',
                discountVsNormalSales: 'Reduceri vs. vânzări normale',
                dismiss: 'Respinge',
                doesNotExist: 'Nu există',
                doorCloseConfirm: 'Sigur doriți să închideți ușa?',
                doorCloseSuccess: 'Ușa a fost închisă cu succes',
                doorOpenConfirm: 'Sigur doriți să deschideți ușa?',
                doorOpenReason: 'De ce doriți să deschideți ușa?',
                doorOpenSuccess: 'Ușă deschisă cu succes',
                rebootDeviceSuccess: 'Dispozitiv repornit cu succes',
                deviceRebootConfirm: 'Sigur doriți să reporniți dispozitivul?',
                edit: 'Editează',
                editCompanyLogo: 'Editează sigla companiei',
                editDiscountRule: 'Editați regula pentru discount',
                editPaymentMethodLogo: 'Editează sigla modalității de plata',
                editVatCategoryModalTitle: 'Editează categoria TVA',
                editProductCategoryModalTitle: 'Editează categoria de produse',
                editSupplierModalTitle: 'Editează furnizorul',
                enable: 'Activează',
                error_401: 'Nu este autentificat',
                error_403: 'Nu sunteți autorizat să executați comenzi',
                error_500: 'Eroare neașteptată de server',
                errorCreatingAd: 'Eroare în crearea reclamei',
                errorCreatingProduct: 'Eroare în crearea produsului',
                errorFetchingCabinetStatus: 'Eroare la preluarea stării dispozitivului',
                errorFetchingInventory: 'Eroare la preluarea inventarului dispozitivului',
                errorFetchingReport: 'Eroare la preluarea rapoartelor',
                errorFetchingSettings: 'Eroare la preluarea setărilor',
                errorUpdatingProduct: 'Eroare în actualizarea produsului',
                expandToSeeBadTags:
                    'Extindeți lista pentru a vedea etichetele care au fost detectate ca etichete greșite. Acestea trebuie eliminate din dispozitiv.',
                expandToSeeSoldItems: 'Extindeți lista pentru a vedea articolele care sunt deja vândute, dar care au apărut din nou în inventar.',
                expandToSeeReplenishedOutOfInventory:
                    'Extindeți lista pentru a vedea produsele care au fost plasate în dispozitiv, altele decât reaprovizionarea inventarului',
                expired: 'Expirate',
                expiredCount: 'Număr de produse expirate',
                expiredProductsCount: 'Numărul produselor expirate',
                expiredSum: 'Suma produselor expirate',
                expiring: 'Expiră',
                expiryDate: 'Ultima zi de vânzări',
                exportExcel: 'Exportați in Excel',
                fetchAdvertisements: 'Obținerea reclamelor',
                fetchingCabinets: 'Obținerea dispozitivelor',
                fetchingCabinetsError: 'A apărut o eroare în timpul preluării dispozitivelor',
                fetchingCabinetStatus: 'Obținerea statutului dispozitivului',
                fetchingError: 'A apărut o eroare în timpul preluării',
                fetchingInventory: 'Obținerea inventarului',
                fetchingProducts: 'Obținerea produselor',
                fetchingReport: 'Obținerea rapoartelor',
                fetchingVatCategories: 'Obținerea categoriilor TVA',
                fetchingProductCategories: 'Obținerea categoriilor de produse',
                fillForm: 'Completați formularul',
                freshFood: 'Acesta este un produs care expiră',
                freezerPackage: 'Produs congelat',
                generalFetchError: 'Eroare de preluare',
                generalRequestReject: 'Eroare de solicitare',
                get: 'Preluare',
                group: 'Grup',
                holdOn: 'Așteptați',
                hour: 'Ora',
                hours: 'ore',
                hoursBeforeExpiry: 'Ore înaintea expirării',
                daysBeforeExpiry: 'Zile înainte de expirare',
                image: 'Imagine',
                imageOrVideo: 'Selectați o imagine sau un video',
                inActive: 'Inactiv',
                individualCabinets: 'Dispozitive individuale',
                individualCabinetsOnly: 'Doar dispozitive individuale',
                invalidBarcodeOnlyDigits: 'Codul de bare trebuie să conțină doar numere',
                invalidBarcodeTooLong: 'Codul de bare este prea lung',
                invalidBarcodeTooShort: 'Codul de bare este prea scurt',
                invalidCabinetNameTooShort: 'Denumirea dispozitivului este prea scurtă',
                invalidCabinetNameDuplicated: 'Denumirea dispozitivului este duplicat',
                invalidLocationNameTooShort: 'Denumirea locației este prea scurtă',
                invalidLocationNameDuplicated: 'Denumirea locației este duplicat',
                invalidPercentage: 'Valoare incorectă',
                invalidPrice: 'Preț incorect',
                invalidProductBarcode: 'Cod de bare incorect',
                invalidTitle: 'Titlul incorect',
                invalidBundleItems: 'Conține articole nevalide',
                inventoryLength: 'Mărimea inventarului',
                inventoryNotReported: 'Dispozitivul nu a raportat inventarul curent',
                inventoryTitle: 'Inventarul dispozitivului',
                'Inventory History': 'Istoricul inventarului',
                itemReplenishedOutOfInventoryProcess:
                    'Acest produs sau această etichetă au fost plasate în interiorul dispozitivului în cadrul unui alt proces decât cel de reaprovizionare',
                itemSoldOn: 'Produs vândut la',
                'Last 30 days': 'Ultimele 30 de zile',
                'Last 7 days': 'Ultimele 7 zile',
                'Last month': 'Luna precedentă',
                'Last quarter': 'Trimestrul precedent',
                'Last week': 'Săptămâna precedentă',
                'Last year': 'Anul precedent',
                lastUpdate: 'Ultima actualizare',
                lifetime: 'Termen de valabilitate',
                location: 'Locație',
                locations: 'Locații',
                logoEditor: 'Editare siglă',
                logoSettings: 'Setări siglă',
                loading: 'Se încarcă...',
                measure: 'Măsură',
                measures: 'Măsuri',
                minsBeforeExpiry: 'Minute înainte de expirare',
                minutes: 'minute',
                month: 'Luna',
                name: 'Nume',
                newVatCategoryModalTitle: 'Categorie nouă de TVA',
                newProductCategoryModalTitle: 'Categorie nouă de produs',
                newSupplierModalTitle: 'Furnizor nou',
                'no region': '(locația nu există)',
                noAdvertisements: 'Nu există reclama',
                noCabinets: 'Niciun dispozitiv',
                noCabinetSelected: 'Nu a fost selectat niciun dispozitiv',
                noLogo: 'Nu există sigla',
                noData: 'Nu există date',
                noDescription: 'Fără descriere',
                noEvents: 'Niciun eveniment!',
                noIndividualCabinets: 'Nu există dispozitive individuale',
                noLocationPrices: 'Nu sunt definite prețuri locale pentru acest produs',
                none: 'Nu există',
                noPaymentMethodLogo: 'Fără sigla metodei de plată',
                noProducts: 'Niciun produs',
                noProductsRemoved: 'Niciun produs nu a fost eliminat',
                noProductTypeSelected: 'Nu este selectat niciun tip de ambalaj',
                normalPrice: 'Preț normal',
                normalSalesAmount: 'Suma normală a vânzărilor',
                notAccessibleForYou:
                    'Funcția de creare/actualizare este activată numai pentru administratori și operatori de dispozitive. Pentru a obține acces, contactați supervizorul sau administratorul de sistem.',
                notDefined: 'Nedefinit',
                noTitle: 'Fără titlu',
                noVatCategories: 'Nu a fost găsită nicio înregistrare de TVA a produsului',
                noProductCategories: 'Nu a fost găsită nicio înregistrare de categorie a produsului',
                noInventoryOnSelectedDate: 'Nu s-a raportat niciun inventar pentru data selectată. Vă rugăm să încercați o dată diferită.',
                openDoor: 'Deschideți ușa',
                orderSum: 'Suma comenzilor',
                paymentMethodLogo: 'Sigla metodei de plată',
                percentage: 'Procent',
                permanent: 'Permanent',
                preview: 'Previzualizare',
                price: 'Preț',
                print: 'Listează',
                productCreated: 'Produs creat',
                products: 'Produse',
                productsFetchError: 'A apărut o eroare în timpul preluării produselor',
                productsTitle: 'Catalog produse',
                productType: 'Tip ambalaj',
                productUpdated: 'Produs actualizat',
                quantity: 'Cantitate',
                readerDown: 'Cititorul RFID nu funcționează',
                region: 'Locație',
                regions: 'Locații',
                removedAt: 'Șters la',
                removeImage: 'Ștergeți imaginea',
                removeVideo: 'Ștergeți video',
                refillQRCodeInfo: 'Scanați codul QR pentru a deschide aplicația de reumplere sau accesați',
                refillQRCodeTitle: 'Codul QR pentru reumplerea dispozitivului',
                refillRuleTitle: 'Regulă reumplere',
                reportsTitle: 'Rapoarte',
                requestSuccess: 'Modificări efectuate cu succes',
                requiredPercentage: 'Valoarea procentuală este necesară dar fără semnul procentual.',
                requiredText: 'Lungimea necesară a caracterelor: ',
                retireLocation: 'Închiderea locației',
                rfidNoInfo: 'Nu sunt disponibile informații despre cititorul și antenele RFID',
                rfidOk: 'Cititorul RFID și antenele funcționează conform așteptărilor.',
                sales: 'Vânzări',
                salesCount: 'Numărul vânzărilor',
                salesVsWasteAmount: 'Vânzări în raport cu cantitatea de produse expirate',
                save: 'Salvare',
                search: 'Căutare',
                selectCabinet: 'Selectați un dispozitiv',
                selectDiscountRuleCabinets: 'Selectați dispozitivele afectate de regula de reducere',
                selectDiscountRuleLocations: 'Selectați locațiile afectate de regula de reducere',
                selectDiscountRuleProducts: 'Selectați produsele afectate de regula de reducere',
                selectProductType: 'Vă rugăm să selectați tipul de ambalaj pentru produsul dvs',
                setDiscountAmount: 'Setați valoarea reducerii',
                setDiscountTimeBeforeExpiry: 'Stabiliți interval de expirare',
                settingConfirmMessage: 'Sigur doriți să salvați aceste setări? Dacă nu sunteți sigur, nu confirmați.',
                showDisable: 'Produse arhivate',
                summary: 'Sumar',
                sureRemoveDiscountRule: 'Sigur doriți să eliminați regula de reducere?',
                tagIsBad: 'Eticheta este stricată, trebuie să fie scoasă din dispozitiv.',
                tagsRemovedReport: 'Raport etichete înlăturate',
                taxValue: 'Valoare TVA',
                'This month': 'Luna curentă',
                'This quarter': 'Trimestrul curent',
                'This week': 'Săptămâna curentă',
                'This year': 'Anul curent',
                thisLink: 'acest link.',
                timeGrouping: 'Grupare în funcție de timp',
                title: 'Titlu',
                Today: 'Astăzi',
                topExpiredProducts: 'Clasament produse expirate',
                topProducts: 'Clasament produse',
                total: 'Total',
                totalValue: 'Valoare totală',
                transactions: 'Tranzacții',
                ttlInDays: 'Durata termenului de valabilitate în zile',
                ttlsAffected: 'Produse cu data de expirare afectate',
                unexpectedError: 'Eroare neașteptată   ',
                uniqueCustomers: 'Clienți unici',
                unknown: 'Necunoscut',
                update: 'Actualizare',
                updateAdError: 'Eroare de actualizare a reclamei',
                updateAdTitle: 'Actualizare reclamă',
                updatedAd: 'Reclamă actualizată',
                updateCabinetSettings: 'Sincronizați datele cu dispozitivul',
                updateCabinetSettingsInfo: 'Sigur doriți să actualizați setările dispozitivului?',
                updateData: 'Actualizare date',
                updateProductTitle: 'Actualizare produs',
                updatingAd: 'Se actualizează reclama',
                updatingProduct: 'Se actualizează produsul',
                uploading: 'Se încarcă',
                uploadingImage: 'Se încarcă imaginea',
                use: 'Utilizați',
                'w/o grouping': 'Fără grupare',
                waste: 'Produse expirate',
                vatCategoriesFetchError: 'Eroare la preluarea categoriilor de TVA',
                vatCategoriesTitle: 'Categorii TVA',
                vatCategory: 'Categorie TVA',
                vatCategoryItems: 'Articole',
                vatCategoryName: 'Nume categorie TVA',
                vatCategoryPromptMessage: 'Sigur doriți să ștergeți categoria de TVA',
                vatCategoryValue: 'Procentul valorii de impozitare',
                vatDeleteRejected: 'Eliminarea categoriei de TVA este respinsă. Verificați produsele asociate acesteia.',
                vatErrorRequesting: 'Eroare în procesarea solicitării',
                vatRequestRejected: 'Cerere respinsă',
                vats: 'TVA',
                productCategoriesFetchError: 'Eroare la preluarea categoriilor de produse',
                productCategoriesTitle: 'Categoriile de produs',
                productCategory: 'Categoria de produs',
                productCategoryItems: 'Categorii',
                productCategoryName: 'Numele categoriei de produse',
                productCategoryPromptMessage: 'Sigur doriți să ștergeți categoria de produse?',
                productCategoryDescription: 'Descrierea categoriei',
                productCategoryHelpTextDescription: 'ex. Categorie pentru salate',
                productCategoryHelpTextName: 'ex. Salate',
                productCategoryDeleteRejected: 'Eliminarea categoriei de produse este respinsă. Verificați produsele asociate acesteia!',
                week: 'Săptămâna',
                viewMode: 'Vizualizare',
                year: 'An',
                Yesterday: 'Ieri',
                groupBy: 'Grupează după',
                filterBy: 'Sortează după',
                reportType: 'Tip raport',
                custom: 'Personalizat',
                preDefined: 'Predefinit',
                selected: 'Selectat',
                metricsInfo: 'Formulele de calcul (suma, maxim, minim...) vor fi folosite pentru unitățile de măsură selectate',
                dimensionsInfo: 'Formula de calcul va fi folosită pentru unitățile de măsură selectate',
                metrics: 'Formula de calcul',
                controlsSoftwareVersion: 'Versiunea software',
                currentSoftwareVersion: 'Versiunea curentă de software',
                availableSoftwareVersion: 'Versiunea de software disponibilă',
                invalidLifetime: 'Valoare invalidă a duratei de viață',
                unpaidSales: 'Vânzări neplătite',
                unpaidTransactions: 'Tranzacții neplătite',
                softwareUpdateConfirmation: 'Sigur doriți să actualizați versiunea software a dispozitivului (dispozitivelor) selectat(e)?',
                softwareUpdateWarn:
                    'Actualizarea versiunii software va trimite o comandă dispozitivului (dispozitivelor), pentru a prelua actualizarea acum sau la ora selectată. Dispozitivul (dispozitivele) selectat(e) vor instala actualizarea la următoarea conectare online, după comanda actualizării. În funcție de viteza conexiunii, instalarea poate dura. După repornire, dispozitivul (dispozitivele) va (vor) reporni.',
                releaseVersion: 'Versiuni lansate',
                releaseDate: 'Data lansării',
                latestRelease: 'Ultima versiune',
                currentVersion: 'Versiunea curentă',
                softwareUpdateSuccess: 'Versiunea de software a fost actualizată',
                prebooking: 'Rezervare prealabilă',
                prebookedProduct: 'Produs rezervat',
                prebookedProducts: 'Produse rezervate',
                activeProducts: 'Produse active',
                startMaintenanceMode: 'Pornire mod mentenanță',
                endMaintenanceMode: 'Oprire mod mentenanță',
                startMaintenanceConfirm: 'Sigur doriți să porniți modul de mentenanță pentru acest dispozitiv?',
                endMaintenanceConfirm: 'Sigur doriți să opriți modul de mentenanță pentru acest dispozitiv?',
                startMaintenanceSuccess: 'Modul de mentenanță a fost pornit.',
                endMaintenanceSuccess: 'Modul de mentenanță a fost oprit.',
                noResults: 'Nu există rezultate',
                suppliers: 'Furnizori',
                supplier: 'Furnizor',
                supplierDeleteRejected: 'Ștergerea furnizorului este respinsă. Verificați produsele asociate acestuia!',
                supplierPromptMessage: 'Sigur doriți să ștergeți furnizorul?',
                fetchingSuppliers: 'Căutarea furnizorilor',
                noSuppliers: 'Nu s-a găsit nicio înregistrare de furnizor',
                suppliersFetchError: 'Eroare la preluarea furnizorilor',
                supplierName: 'Numele furnizorului',
                supplierHelpTextName: 'De exemplu, un nume de marcă',
                supplierDescription: 'Descrierea furnizorului',
                supplierHelpTextDescription: 'De exemplu, descrierea unei mărci',
                wishes: 'Dorințe',
                checkNow: 'Verificați acum',
                startTimeOn: 'Oră de începere activată',
                endTimeOn: 'Oră de terminare activată',
                signOutConfirmationModalTitle: 'Confirmare deconectare',
                signOutConfirmationModalContent: 'Sigur doriți să vă deconectați?',
                startDate: 'Dată de începere',
                endDate: 'Dată de terminare',
                scheduledDiscountApplyAllDays: 'Se aplică pentru toate zilele',
                scheduledDiscountApplySelectedDays: 'Se aplică pentru zilele selectate',
                discountCustomImageTitle: 'Imagine discount (opțional)',
                discountCustomImageModalHeader: 'Imagine pentru discount',
                saveChanges: 'Salvați modificările',
                updateTagsQualitySettingsTitle: 'Setări de calitate etichetă',
                updateTagsQualitySettingsMessage: 'Setări actualizate pentru calitatea etichetei',
                IF3Cabinets: 'Frigidere model 3',
                IF2Cabinets: 'Frigidere model 2',
                IF3Freezers: 'Congelatoare model 3',
                encodingStations: 'Stații de codare',
                if2: 'Frigider model 2',
                if3: 'Frigider model 3',
                if3Freezer: 'Congelator model 3',
                legalNotice: 'Aviz juridic',
                privacyPolicy: 'Politica de confidențialitate',
                scheduledDiscountDateMissingError: 'Dacă este setată data de începere sau de terminare sunt necesare ambele date',
                scheduledDiscountDuplicateTimeError: 'Dacă datele sunt aceleași, orele nu pot fi aceleași',
                now: 'Acum',
                minimum: 'Minim',
                maximum: 'Maxim',
                discardChangesConfirmationText: 'Sigur doriți să anulați modificările?',
                exports: 'Exporturi',
                selectAllCabinets: 'Selectați toate vitrinele',
                expiryIntervals: 'Intervale de expirare',
                timeBeforeExpiry: 'Timp înainte de expirare',
                apply: 'Aplicați',
                yes: 'Da',
                no: 'Nu',
                productOnScreenBannerText: 'Text banner cu informații pe ecran',
                productOnScreenBannerAdditionalInfo:
                    'Textul bannerului este un text pe un rând, care apare sub imaginea produsului și transmite concis caracteristici cheie, apeluri la sănătate sau orice informații suplimentare despre produs',
                productOnScreenBannerExampleText: 'Fără lactoză',
                UI_IS_DOWN: 'Ecranul dulapului nu funcționează',
                READER_IS_DOWN: 'Cititorul RFID este inactiv',
                ANTENOR_DEVICE_DISCONNECTED: 'Dispozitivul de plată este deconectat',
                EXPIRED_PRODUCT: 'Produse expirate în dulap',
                CLOUD_SERVICE_DOWN: 'Serviciu de cloud indisponibil',
                ANTENNA_DISCONNECTED: 'Antena deconectată',
                TAG_CHANGES: 'Prea multe etichete defecte în dulap',
                ANTENNA_DOWN: 'Antena RFID în dulap nu funcționează',
                READER_CONFIG_ERROR: 'Configurarea cititorului RFID a eșuat și nu funcționează',
                activeAlarms: 'Alarme active',
                deviceStatus: 'Starea dispozitivului',
                lastMonitored: 'Ultima monitorizare',
                noService: 'Fără serviciu',
                operational: 'Operațional',
                offline: 'Offline',
                locationsSelectHelp: 'Când se adaugă o locație, vor fi adăugate și locațiile sale subordonate.',
                select: 'Selectați...',
                labels: 'Etichete',
                productDetails: 'Detalii produs',
                invalidProductName: 'Numele produsului trebuie să aibă între 1 și 125 de caractere',
                standardPrice: 'Preț standard',
                locationPrices: 'Prețuri pe locație',
                addNew: 'Adaugă nou',
                productExpiry: 'Data expirării produsului',
                productLifeTime: 'Durata de viață a produsului',
                invalidTTL: 'Durata de viață a produsului trebuie să fie un număr întreg între 0 și 32767',
                productImage: 'Imagine produs',
                uploadImage: 'Încarcă imaginea',
                pleaseUploadImage: 'Vă rugăm să încărcați o imagine',
                productValidationError: 'Vă rugăm să corectați următoarele erori înainte de a salva datele produsului',
                productSupplier: 'Furnizor produs',
                frozenProductLable: 'Acesta este un produs congelat',
                prebookingLabel: 'Acesta este un produs de pre-rezervare',
                previous: 'Precedent',
                bannerText: 'Text banner',
                packagingTypeValidationError: 'Vă rugăm să selectați un tip de ambalaj pentru produs',
                showOnlyFrozen: 'Afișați doar produsele congelate',
                productClassification: 'Clasificare produs',
                productClassLabel:
                    'Vă rugăm să selectați clasa corespunzătoare pentru produs. Acest lucru permite Selfly Insights să furnizeze informații mai relevante despre produs.',
                productLabels: 'Etichete produs',
                productLabelsHelpText:
                    'Vă rugăm să adăugați etichete opționale produsului dvs. Acest lucru vă va permite să filtrați produsele în catalogul de produse.',
                newLabel: 'Etichetă nouă',
                productClassValidationError: 'Vă rugăm să selectați o clasificare pentru produsul dumneavoastră din opțiunile de mai jos',
                tagSensitivity: 'Sensibilitatea calității etichetei',
                tagSensitivitySetting: 'Setarea sensibilității calității etichetei',
                configTagSensitivity: 'Configurați sensibilitatea calității etichetei pentru produs',
                productLevelSensitivityInfo:
                    'Citibilitatea unei etichete RFID atașate la un produs poate fi influențată de diverși factori, inclusiv materiale de ambalare, suprafețe metalice, umiditate și chiar conținutul produsului. Acești factori pot cauza uneori semnale intermitente ale etichetei, cunoscute sub denumirea de pâlpâire. Cabinetul procesează această pâlpâire pe baza setărilor de configurare a sensibilității. Dacă nu sunteți sigur care nivel de sensibilitate este potrivit pentru acest produs, puteți sări peste această secțiune.',
                errorSaving: 'Eroare la salvarea datelor produsului',
                successSaving: 'Datele produsului au fost salvate cu succes',
                basicDetail: 'Detalii de bază',
                additionalDetail: 'Detalii suplimentare',
                packaging: 'Tip ambalaj',
                classification: 'Clasificare',
                selectOrtype: 'Selectați sau tastați...',
                max25Chars: 'max 25 de caractere',
                noVatForProductCreation: 'Vă rugăm să creați o categorie de TVA înainte de a adăuga produse noi',
                lifeTimeExplanation: 'Câte zile este acest produs valabil pentru vânzare. (0 zile) înseamnă până la sfârșitul zilei curente',
                selflyEncode: 'Selfly Encode',
                userRestrict: 'Doar consumatori recunoscuți',
                userRestrictTitle: 'Consumatori eligibili',
                userNotRestrict: 'Toți consumatorii',
                userRestrictHelperText:
                    'Opțiunea consumatori recunoscuți înseamnă că reducerea va fi aplicată doar consumatorilor deja înregistrați pentru eligibilitatea reducerii!',
                searchCabinets: 'Căutare dulapuri',
                selectAll: 'Selectează tot',
                monday: 'Luni',
                tuesday: 'Marți',
                wednesday: 'Miercuri',
                thursday: 'Joi',
                friday: 'Vineri',
                saturday: 'Sâmbătă',
                sunday: 'Duminică',
                fetchingLoader: 'Se încarcă. Vă rugăm să așteptați...',
                category: 'Categorie',
                okay: 'Ok',
                noLocation: 'Nicio locație',
                copy: 'Copiază',
                pleaseConfirm: 'Vă rugăm să confirmați',
                saveQuery: 'Salvează această interogare',
                saveQueryPlaceholder: 'ex. „Numărul total de articole vândute”',
                saveQuerySuccess: 'Interogarea a fost salvată.',
                yourSavedQueries: 'Interogările tale salvate',
                selectQuery: 'Selectați interogarea',
                noQuery: 'Nu există încă nicio interogare salvată',
                deleteAdConfirm: 'Ești sigur că vrei să ștergi acest anunț?',
                dataSaved: 'Date salvate cu succes',
            },
            refill: {
                refillRuleApplyRuleDropdownText: 'Aplicați această regulă și la alte vitrine',
                refillPlanGenerate: 'Generați planul de reumplere',
                refillPlanEditPlan: 'Modificare plan',
                refillAmount: 'Cantitate reumplere',
                refillRuleTargetAmount: 'Nivel inventar dorit',
                refillRuleTargetAmountShort: 'Ţintă',
                refillPlanActivePlans: 'Planuri active',
                refillPlanCompletedPlans: 'Planuri finalizate',
                refillNote: 'Notă de reumplere',
                refillNoteHelp: 'Notă de reumplere a produsului (maxim 125 caractere)',
                refillPDFText:
                    'Mai jos este planul de reumplere pentru dulap. Asigurați-vă că marcați planul de reumplere complet în aplicația de reumplere după ce ați terminat reumplerea.',
                refillPDFSummaryText: 'Mai jos este rezumatul planurilor de reumplere cu produse de adăugat și de eliminat pentru toate dulapurile selectate.',
                refillSummary: 'Rezumatul reumplerii',
                addItems: 'Adăugare produse',
                removeItems: 'Eliminare produse',
                inventoryChange: 'Schimbare inventar',
                planogramPDFText: 'Planogram pentru strategia de reumplere a dulapului.',
                planogramImagePDFText: 'Imagine personalizată pentru strategia de reumplere a dulapului.',
                refillSchemas: 'Șabloane de reumplere',
                refillPlans: 'Planuri de reumplere',
                refillSchemaTitle:
                    'Setați cantitățile țintă și minime de inventar ca șablon pentru dulapurile selectate. Aceste șabloane vor fi utilizate pentru generarea planurilor de reumplere.',
                refillPlansTitle:
                    'Vizualizați toate planurile active. Puteți edita sau elimina planurile active existente sau adăuga noi planuri de reumplere, după cum este necesar.',
                refillSchemaSubText: 'Vă rugăm să selectați un dulap pentru a crea sau actualiza șablonul de reumplere.',
                noRefillSchema: 'Nu a fost definit încă niciun șablon de reumplere pentru acest dulap.',
                createRefillSchema: 'Creează un nou șablon de reumplere',
                noDevice: 'Nu s-a găsit niciun dispozitiv',
                schemaSaved: 'Șablonul de reumplere a fost salvat cu succes.',
                deleteSchemaConfirmation: 'Sunteți sigur că doriți să ștergeți șablonul de reumplere?',
                targetAmount: 'Cantitate țintă',
                targetAmountHelpText: 'Cantitatea de inventar țintă pentru fiecare produs din dulap după reumplere.',
                minimumThreshold: 'Prag minim',
                minimumThresholdHelpText:
                    'Specificați nivelul minim de inventar pentru fiecare produs din dulap. Dacă cantitatea scade sub pragul definit, veți primi o notificare de alarmă. Setarea pragului la 0 va dezactiva notificările.',
                editNote: 'Editează notă',
                refillNoteTitle: 'Scrieți o notă de reumplere pentru produs.',
                refillNoteCharacters: 'caractere',
                noRefillPlan:
                    'În prezent, nu există planuri active de reumplere. Vă rugăm să adăugați noi planuri de reumplere pentru dulapurile dumneavoastră sau să creați mai întâi un șablon de reumplere pentru dulapuri.',
                planSaved: 'Planul de reumplere a fost salvat cu succes.',
                deleteConfirmation: 'Sunteți sigur că doriți să ștergeți datele planului de reumplere selectat?',
                editPlan: 'Editează plan',
                currentInventory: 'Inventar curent',
                lastSevenDaySales: 'Vânzări în ultimele șapte zile',
                inventory: 'Inventar',
                planogram: 'Planogram',
                copyRefillSchema: 'Copiază șablonul în alte dulapuri',
                generatingPlans: 'Generarea planurilor de reumplere. Vă rugăm să așteptați...',
                generatePlans: 'Generați planuri de reumplere',
                complete: 'complet',
                selectCabinetsToAddPlans: 'Vă rugăm să selectați dulapuri pentru a genera planuri de reumplere.',
                reviewSuggestion:
                    'Mai jos sunt planurile de reumplere sugerate pentru dulapurile selectate. Vă rugăm să le revizuiți, să le editați dacă este necesar și să le salvați pentru a activa aceste planuri.',
                noSchema:
                    'Niciun dulap nu are în prezent un șablon de reumplere. Vă rugăm să adăugați un șablon de reumplere în dulapurile dumneavoastră pentru a genera planuri de reumplere.',
                searchProducts: 'Căutați produse',
                maxAllowedInventory: 'Inventarul maxim permis este 200.',
                savingData: 'Se salvează datele. Vă rugăm să așteptați.',
                errorSavingData: 'Eroare la salvarea datelor.',
                refillPlanSaved: 'Planurile de reumplere au fost generate cu succes.',
                exportPDFHelp: 'Exportați planurile selectate în fișier PDF.',
                exportExcelHelp: 'Exportați planurile selectate în fișier Excel.',
                addPlanHelp: 'Adăugați noi planuri de reumplere.',
                deletePlanHelp: 'Ștergeți planurile de reumplere selectate.',
                generatePlansHelp: 'Generați planuri de reumplere pentru dulapurile selectate.',
                planogramSaved: 'Planogramul a fost salvat cu succes.',
                schemaCopied: 'Șablonul de reumplere a fost sincronizat cu succes cu celelalte dulapuri.',
                allProducts: 'Toate produsele',
                schemaProducts: 'Produse din șablonul de reumplere',
                deletePlanogram: 'Sunteți sigur că doriți să ștergeți planogramul?',
                dragInPlanogram:
                    'Vă rugăm să trageți și să plasați produsele pe rafturile designate ale dulapului sau să trageți slotul dulapului peste produs.',
                noPlanogramInSchema: 'Un planogram nu a fost definit încă pentru dulap.',
                noPlanogramInPlan:
                    'Nu sunt disponibile date de planogram pentru dulap. Vă rugăm să creați mai întâi un planogram pentru dulap în fila de șablon de reumplere.',
                copyPlanogram: 'Copiați planogramul de reumplere împreună cu șablonul de reumplere.',
                planogramImageInfo: 'Pe lângă planogram, puteți adăuga și o imagine personalizată a planogramului.',
                addPlanogramImage: 'Adăugați imaginea planogramului',
                editPlanogramImage: 'Editează imaginea planogramului',
                numberOfShelves: 'Număr de rafturi',
                addNewRefillPlan: 'Adăugați planuri noi',
                qrcodeTitle: 'Un cod QR va fi generat pentru dulapul selectat pentru a iniția procesul de reumplere.',
                qrcodeSubText: 'Vă rugăm să selectați un dulap unde doriți să inițiați procesul de reumplere.',
                qrcodeScan: 'Vă rugăm să scanați codul QR pentru a reumple, sau faceți clic pe buton pentru a deschide aplicația de reumplere.',
                qrcodeOpenApplication: 'Deschideți aplicația de reumplere.',
                createRefillPlanogram: 'Creează planogram de reumplere',
            },
            navigation: {
                advertisements: 'Reclame',
                analytics: 'Rapoarte',
                controlPanel: 'Panoul de comandă',
                deviceManagement: 'Gestionarea dispozitivelor',
                discounts: 'Reduceri',
                fetching: 'Preluare',
                group: 'Grup',
                inventory: 'Inventarul dispozitivului',
                language: 'Limba',
                productCatalog: 'Catalog de produse',
                productCategories: 'Categorii de produse',
                reports: 'Rapoarte',
                settings: 'Setările utilizatorului',
                signOut: 'Deconectare',
                title: 'Selfly Cloud Portal',
                vatCategories: 'Categorii TVA',
                community: 'Selfly Community',
                refillManagement: 'Gestionarea reumplerii',
                automations: 'Automatizări',
                mySelfly: 'Setările MySelfly',
                automationInfo: 'Gestionați acțiunile automate (BETA)',
            },
            reports: {
                alreadyRefund: 'a fost returnat',
                amount: 'Suma',
                average: 'Medie',
                barcode: 'Cod de bare',
                confirmIssueRefundButton: 'Confirmați rambursarea ',
                current: 'Curent',
                export: 'Exportă',
                fetching: 'Preluare',
                fetchingError: 'A apărut o eroare în timpul preluării',
                image: 'Imagine',
                issueRefundButton: 'R A M B U R S A R E',
                maximum: 'Maxim',
                minimum: 'Minim',
                name: 'Nume',
                noData: 'Nu există date',
                note: 'Notă',
                order: 'Tranzacție',
                paymentWasDoneByCreditCard: 'Plata a fost efectuată prin card de credit',
                price: 'Preț',
                ProductReport: 'Raport privind produsul',
                quantity: 'Cantitate',
                "Refund impossible'":
                    'Plata a fost efectuată prin card de credit. Contactați emitentul terminalului de card de credit pentru operațiunea de rambursare.',
                refundFormTitle: 'Rambursare',
                refundReason: 'Motivul returnării produsului',
                refundSuccess: 'Suma {{sum}} a fost rambursată cu succes',
                refundTotal: 'Sumă rambursată',
                replenishments: 'Reumplere',
                replenishmentsTitle: 'Raport de reumplere',
                SaleTransaction: 'Tranzacție de vânzare',
                summary: 'Sumar',
                SummaryReport: 'Sumarul vânzărilor',
                temperature: 'Temperatură',
                temperatureTitle: 'Raport de temperatură',
                temperatureValueNotification: 'Valorile de temperatură sunt valorile medii orare ale temperaturii din interiorul dispozitivului.',
                time: 'Data',
                total: 'Total',
                transactions: 'Tranzacții',
                user: 'Utilizator',
                paidTransactions: 'Tranzacții plătite',
                unpaidTransactions: 'Tranzacții neplătite',
                partiallyPaid: 'Plătit parțial',
                failedTransactions: 'Tranzacții nereușite',
                amountOwed: 'Suma datorată',
                receiptLinkText: 'Luați chitanța de aici',
                noPurchase: 'Nicio achiziție',
                other: 'Alte',
                userId: 'ID utilizator:',
            },
            settings: {
                block_cabinet_when_product_expired: 'Blocare dispozitiv în momentul expirării produselor',
                criticalExpiryLimitHours: 'Ore înaintea alertei critice de expirare',
                language: 'Limba',
                logo: 'Sigla companiei',
                mediumExpiryLimitHours: 'Ore înaintea alertei medii de expirare',
                payment_method_logo: 'Logo-ul modalității de plată',
                footer_instruction_logo: 'Imagine cu instrucțiuni în subsol',
                readUserMemory: 'Preluarea datelor de expirare a produselor',
                ui_skus_sort_by: 'Ordinea sortării produselor în afișajul vitrinei',
                timeZone: 'Fus orar',
                country: 'Țară',
                city: 'Oraș',
                tagSettings: 'Setări etichetă',
                themeAndLogos: 'Temă și logo-uri',
                advancedSettings: 'Setări avansate',
                themingInfoText: 'Puteți personaliza tema ecranului vitrinei',
                cabinetLogos: 'Logo-uri vitrină',
                cabinetLogoInfoText: 'Încărcați logo-urile care apar pe ecranul vitrinei',
                logoHelperText: 'Utilizați imagine 180 px X 180 px',
                settingsCopyInfoHeader: 'Selectați vitrinele în care doriți să copiați setările',
                settingsCopyButton: 'Copiați setările în vitrinele selectate',
                settingsCopySuccessModalTitle: 'Se copiază setările',
                settingsCopySuccessModalContent: 'Setările au fost copiate cu succes în {{cabinetsAmount}} vitrine',
                theming: 'Tematizare',
                adminSettings: 'Setări de administrator',
                settingsCopyErrorModalTitle: 'Eroare la copierea setărilor',
                key: 'Setare',
                value: 'Valoare',
                sleepScheduling: 'Standby programat',
                sensitivityLevel: 'Nivel de sensibilitate',
                sensitivity_0:
                    'Cabinetul ignoră pâlpâirea etichetei și marchează constant toate etichetele ca fiind valide, ceea ce poate duce ocazional la taxare incorectă a articolelor.',
                sensitivity_20:
                    'Cabinetul tolerează până la trei citiri greșite pentru o etichetă în 50 de scanări și va marca în continuare eticheta ca fiind validă.',
                sensitivity_40:
                    'Cabinetul tolerează până la două citiri greșite pentru o etichetă în 60 de scanări și va marca în continuare eticheta ca fiind validă.',
                sensitivity_60:
                    'Dacă o etichetă pâlpâie frecvent, dar menține stabilitatea pe o perioadă lungă, cabinetul tolerează acest lucru și citește eticheta ca fiind validă.',
                sensitivity_80: 'Dacă o etichetă pâlpâie momentan, dar rămâne stabilă pentru o perioadă lungă, va fi considerată o etichetă validă.',
                sensitivity_100:
                    'Cabinetul este foarte intolerant față de pâlpâirea etichetelor. Chiar și o singură citire greșită poate face ca o etichetă să fie marcată ca nevalidă pentru o perioadă lungă, ceea ce poate duce la pierderi potențiale.',
                sensitivityInfo:
                    'Sensibilitatea calității etichetei unui cabinet se referă la cât de eficient gestionează cabinetul citirile inconsistente ale etichetelor sau pâlpâirea etichetelor. Vă rugăm să fiți atenți la nivelul de sensibilitate pe care îl alegeți, deoarece poate afecta performanța cabinetului. Dacă nu sunteți sigur care nivel este potrivit, se recomandă să lăsați setarea la nivelul implicit de 3.',
            },
            signIn: {
                signInButton: 'Autentificare',
                signInExpiredError: 'Sesiunea dvs. a expirat. Conectați-vă din nou',
                signInMessage: 'Trebuie să vă autentificați pentru a continua. Faceți clic pe butonul de mai jos pentru a vă autentifica',
                noOrganizationsError: 'Acest nume de utilizator nu are asociată nicio organizație. Încercați alt nume de utilizator.',
            },
            translations: {
                add: 'Adăugare',
                closed: 'Închis',
                delete: 'Ștergere',
                device: 'Dispozitiv',
                edit: 'Editare',
                off: 'Oprit',
                offline: 'Offline',
                on: 'Pornit',
                online: 'Online',
                opened: 'Deschis',
                save: 'Salvare',
                seeDetails: 'Detalii',
                settings: 'Setări',
                unknown: 'Necunoscut',
            },
            consumerApp: {
                enableConsumerFeedback: 'Activați părerile utilizatorilor pe MySelfly',
                enablePurchaseReceipts: 'Activați chitanțele electronice pe MySelfly',
                enableSelflyTrademarkForReceipts: 'Permiteți subsol cu Magazin Selfy în chitanțele electronice',
                enableSelflyTrademarkForReceiptsHelpText: 'Activarea acestuia va adăuga logo-ul Magazinului Selfly și detalii pe chitanța electronică PDF!',
                organizationFormHelpText: 'Vă rugăm să furnizați detaliile emitentului chitanței electronice.',
                organizationFormValidationHelpText:
                    '* Câmpurile obligatorii pentru chitanța electronică sunt numele organizației emitente, ID-ul companiei, adresa și țara.',
                organizationName: 'Nume organizație',
                organizationLogo: 'Logo organizație',
                businessId: 'ID companie',
                city: 'Oraș',
                email: 'E-mail',
                website: 'Site web',
                postalCode: 'Cod poștal',
                street: 'Adresă stradă',
                phoneNumber: 'Număr telefon',
                addressHeader: '* Informații adresă',
                contactDetails: 'Detalii contact',
                organization: '* Organizație emitent',
                organizationSettingsUpdatedTitle: 'Actualizare setări organizație',
                organizationSettingsUpdatedContent: 'Setările organizației au fost actualizate cu succes',
                organizationSettingsMissingFields: 'Câmpuri obligatorii pentru chitanța electronică lipsesc. Vă rugăm să le completați înainte de a salva.',
                organizationSettingsMissingFieldsTitle: 'Eroare validare formular',
            },
            softwareVersions: {
                updateNotificationTitle: 'Actualizare versiune software',
                updateNotificationContent: 'Versiunile software au fost actualizate cu succes',
                selectAVersionPlaceholder: 'Selectați o versiune',
                searchDevices: 'Căutați dispozitive',
                updateSelected: 'Actualizare selectată',
                softwareVersions: 'Versiuni software',
                softwareVersion: 'Versiune software',
                notSupportedDeviceNote: 'Versiunea {{selectedVersion.version}} nu este acceptată pe acest dispozitiv',
                userNotificationContent:
                    'A fost lansată o versiune software nouă pentru dispozitivele dvs. Pentru a beneficia de cele mai recente funcții, actualizați dispozitivele.',
                userNotificationTitle: 'A fost lansată o versiune software nouă',
                notificationBell_newSoftwareVersions: 'Actualizări disponibile pentru dispozitivele dvs.',
                manageVersions: 'Gestionați versiunile software ale dispozitivului',
                filterLocationsPlaceholder: 'Filtrați în funcție de locații',
                allVersionNumbers: 'Toate versiunile',
                noVersionsForUpdating: 'Nu există versiuni pentru actualizare',
                scheduledUpdateNote: 'Versiunea {{version}} este programată să se actualizeze la {{scheduled}}',
                scheduleRemoveNotificationTitle: 'Se programează versiunea software',
                scheduleRemoveNotificationContent: 'Actualizarea programată a versiunii software a fost eliminată cu succes',
                scheduleRemoveNotificationModalContent: 'Sigur doriți să eliminați actualizarea programată pentru acest dispozitiv?',
            },
            header: {
                searchByOrganization: 'Căutare după organizație',
                pageLanguage: 'Limbă',
                configureMySelflyAndEreceipts: 'Configurați setările MySelfly',
                remindMeLater: 'Amintestemi mai tarziu',
                updateNow: 'Actualizează acum',
            },
            notifications: {
                noNewNotifications: 'Fără notificări noi',
            },
            cabinetSettings: {
                cabinetTheme: 'Temă dispozitiv',
                editAndSaveTheme: 'Editați și salvați tema',
                colorPicker: 'Selector de culoare',
                customTheme: 'Temă personalizată',
                themeSettings: 'Setări temă',
                primary: 'Primar',
                negative: 'Negativ',
                attention: 'Atenție',
                positive: 'Pozitiv',
                background: 'Fundal',
                dark: 'Întuneric',
                light: 'Lumină',
                white: 'Alb',
                default: 'Id implicit',
                negativeLight: 'Lumină negativă',
                primaryColor: 'Culoare primară',
                positiveColors: 'Culori pozitive',
                negativeColors: 'Culori negative',
                secondaryColors: 'Culori secundare',
                attentionColors: 'Culori de atenționare',
                screenBackground: 'Fundal ecran',
                borderColors: 'Margini - umbre',
                shadow: 'Umbră',
                border: 'Margine',
                fontColors: 'Culori font',
                footerTextPlaceholder: 'Text subsol',
                bundleDiscounts: 'Combinați și salvați',
                expiringText: 'Produse care expiră. Verificați data expirării înainte de a cumpăra',
                expiredText: 'Produse expirate. Verificați data expirării înainte de a cumpăra',
                cabinetName: 'Denumire dispozitiv',
                productName: 'Denumire produs',
                confirmationModalHeader: 'Sigur doriți să salvați tema personalizată?',
                confirmationModalContent:
                    'Personalizarea temei va afecta aspectul vizual al ecranului dispozitivului. Asigurați-vă că modificările nu compromit calitatea vizuală a ecranului.',
                defaultRevertModalMessage: 'Sigur doriți să schimbați tema dispozitivului la cea implicită?',
                backgroundColor: 'Culoare de fundal',
                backgroundImage: 'Imagine de fundal',
                footerBackground: 'Fundal subsol',
                cardsBackground: 'Fundal carte',
                screenImage: 'Imagine pentru ecranul dulapului',
                uploadImage: 'Încărcați imaginea',
                editImage: 'Editați imaginea',
                transactionComplete: 'Tranzacție completă',
                customPostPurchaseImage: 'Vă rugăm să încărcați o imagine pentru ecranul personalizat de după achiziție',
                postPurchaseScreen: 'Ecran de după achiziție',
                custom: 'personalizat',
                postPurchaseText: 'Text de afișare după achiziție',
            },
            automations: {
                transactionReports: 'Rapoarte de tranzacție',
                refillReports: 'Rapoarte de reumplere',
                notifications: 'Notificări',
                refillPlan: 'Planuri de reumplere',
                days: 'Zile',
                months: 'Luni',
                weeks: 'Săptămâni',
                configuration: 'Configurație',
                transactionReportTitle:
                    'Rapoartele de tranzacție pentru cabinele selectate vor fi generate automat și trimise la adresele de email furnizate mai jos',
                refillReportTitle:
                    'Rapoartele de reumplere pentru cabinele selectate vor fi generate automat și trimise la adresele de email furnizate mai jos',
                noSalesNotificationTitle:
                    'Notificările vor fi trimise automat pentru adresele de email furnizate mai jos în cazul în care nu sunt vânzări în intervalele definite',
                frequencyTitle: 'Cât de des doriți să fie generat raportul?',
                emailNotificationTitle: 'Vă rugăm să adăugați adresele de email mai jos la care doriți să fie trimis raportul sau notificarea',
                refillPlanGenerationTitle: 'Planurile de reumplere vor fi generate automat pentru cabinele selectate conform programului furnizat',
                addNewEmail: 'Adăugați un email nou',
                noSalesBanner: 'Veți primi o notificare prin email dacă cabinele selectate nu fac vânzări în perioada de timp configurată',
                noSaleTitle: 'Vă rugăm să specificați durata de timp (în ore) fără vânzări în cabinele selectate înainte de a fi trimisă o notificare',
                minMaxTime: 'Min este 2 și max este 23 de ore',
                timeRangeTitle: 'Vă rugăm să specificați intervalul de timp pentru cabine, în timpul căruia situația fără vânzări va fi verificată',
                startTime: 'Ora de început',
                endTime: 'Ora de sfârșit',
                refillPlanTitle:
                    'Planul de reumplere pentru cabinele selectate va fi generat automat. Vă rugăm să programați mai jos timpul specific în care doriți să fie creat planul de reumplere.',
                refillPlanTime: 'Vă rugăm să selectați timpul pentru generarea planului de reumplere',
                everyDay: 'În fiecare zi',
                everyWeek: 'În fiecare săptămână',
                everyMonth: 'În fiecare lună',
                selectDays: 'Selectați zilele',
                automationSaved: 'Setările de automatizare au fost salvate cu succes',
                automationDeleted: 'Setările de automatizare au fost șterse cu succes',
            },
            sleepScheduling: {
                timeRangeTitle: 'Vă rugăm să specificați intervalul de timp în care acest cabinet va intra în modul de așteptare.',
                startTime: 'Ora de început',
                endTime: 'Ora de sfârșit',
                selectDays: 'Selectați zilele',
                duration: 'Durată (ore)',
            },
        },
        bg: {
            dateRangeFilter: {
                lastMonth: 'последните 30 дни',
                lastTwoWeek: 'последните 14 дни',
                lastWeek: 'последните 7 дни',
                selectDateRangeMsg: 'Избран период от време',
                today: 'днес',
                yesterday: 'утре',
            },
            main: {
                activateSuccess: 'Продуктът е активиран успешно',
                activationDate: 'Дата на активация',
                active: 'Активен',
                addAdvertisement: 'Добавете нова реклама',
                addDiscountRule: 'Добавете правило за намаление',
                addDiscountStop: 'Добавете интервал за изтичане на валидност',
                addNewLocation: 'Изберете нова локация',
                addProduct: 'Добавете нов продукт',
                addRegion: 'Добавете локация',
                addRegionPrice: 'Добавете цена на локация',
                addScheduledDiscount: 'Добавете планирано намаление',
                addBundleDiscount: 'Добавете намаление за количество',
                bundledDiscounts: 'Намаление за количество',
                bundleItemsLengthExceeded: 'Максималният брой на избрани продукти (3) е прехвърлен. Премахнете някои от избраните, за да продължите.',
                bundleMaxProductLimitInfo: 'Изберете максимум три (3) продукта или продуктови категории',
                bundleFixedAmountExceededOriginal: 'Фиксираната сума за голяма пратка надвишава оригинална сума. Проверете избраните продукти.',
                bundleFixedAmountNotAllowedToBeZero: 'Фиксираната цена за голям брой поръчани стоки трябва да надвишава 0',
                bundlePercentageNotAllowedToBeFull: 'Процентът намаление за голяма поръчка трябва да е под 100',
                bundlePercentageNotAllowedToBeZero: 'Процентът намаление за голяма поръчка трябва да е над 0',
                bundleItemDiscountPriceAmountExceededOriginal: 'Новата намалена цена надвишава оригиналната цена. Проверете новата цена отново.',
                bundleItemPriceMessage: 'Всяко правило за ценообразуване се прилага само за 1 артикул, дори ако са избрани няколко от същия артикул',
                bundleItemFixedAmountExceededOriginal:
                    'Фиксираната цена на продукта превишава цената на оригиналния продукт. Проверете фиксираната цена отново.',
                createDiscountRule: 'Създай правило за намаление',
                deviceType: 'Тип устройство',
                discountUpdateTitle: 'Намаление актуализация',
                discountCreateTitle: 'Намаление създаване',
                discountDeleteTitle: 'Намаление премахване',
                discountDeleteContent: 'Намалението е премахнато',
                discountStatusUpdated: 'Статусът на намалението е актуализиран',
                discountUpdated: 'Намалението е актуализирано',
                discountCreated: 'Намалението е създадено',
                discountSettings: 'Настройки за отстъпка',
                discountImage: 'Незадължително изображение на отстъпката',
                editDiscountStop: 'Редактиране на интервала за изтичане',
                editBundleConfiguration: 'Редактиране на конфигурацията на голяма поръчка',
                editExpiryConfiguration: 'Редактиране на конфигурацията на отстъпката при изтичане',
                encodingStation: 'Кодираща станция',
                everyDay: 'Всеки ден',
                scheduledDiscountDateInfoLabel: 'Моля, оставете началната и крайната дата празни, ако искате да направите отстъпката постоянна',
                starts: 'Начало',
                ends: 'Край',
                error: 'Грешка',
                errorRetiringLocationMsg:
                    'Неуспешно изтегляне на местоположението. Моля, първо премахнете свързаните устройства, цените на продуктите и рекламите.',
                fetchingDiscountRules: 'Получаване на правила за отстъпки',
                free: 'Безплатно',
                missingImage: 'Липсващо рекламно изображение',
                next: 'Следващ',
                back: 'Назад',
                noDiscountRules: 'Не са намерени правила за намаление',
                scheduledDiscountsAutomaticDeactivationInfo:
                    'Отстъпките, при които е надхвърлен интервалът от дати, се деактивират автоматично. Ако искате да ги направите активни, редактирайте интервала от дати, за да бъде валиден.',
                scheduledDiscounts: 'Планирани намаления',
                schedulingStartHourIsOverEndHour: 'Началното време не може да бъде след крайното време',
                schedulingEndHourIsUnderStartHour: 'Крайното време не може да бъде преди началното време',
                selectDiscountRuleProductCategories: 'Изберете продуктови категории, които са повлияни от правилото за намаление',
                selectBundleProductsAmount: 'Изберете сума за: ',
                selectedBundleProductCategories: 'Избраните продуктови категории за поръчки на едро',
                setDiscountDays: 'Настройте дни за намаление',
                setBundleDiscount: 'Задайте процентна отстъпка или фиксирана цена за пакета',
                setBundleItemDiscount: 'Задайте една от тези опции за един артикул: процент отстъпка, фиксирана цена или безплатно',
                setBundleProducts: 'Задайте продукти или категории за пакети',
                setAmountMoreThanOne: 'В случай на пакет с един продукт, задайте количеството да е повече от едно',
                setBundleDiscountType: 'Задайте тип намаление и бройката за него',
                sureActivateDiscountRule: 'Сигурни ли сте, че искате да активирате правилото за намаление?',
                sureDeactivateDiscountRule: 'Сигурни ли сте, че искате да деактивирате правилото за намаление?',
                sureDeleteDiscountRule: 'Сигурни ли сте, че искате да изтриете правилото за намаление?',
                timeRange: 'Времеви интервал',
                editExpiryDiscountRule: 'Редактиране на правилото за намаление',
                editScheduledDiscountRule: 'Редактиране на правилото за планираното намаление',
                editBundleDiscountRule: 'Редактиране на правилото за пакетно намаление',
                releaseNotes: 'Бележки за пускане',
                seeMore: 'Виж още',
                scheduledDiscountTimeRangeLabel: 'Активно време',
                scheduledDiscountDateRangeLabel: 'Интервалът от дати на намалението ',
                weekDayAbrvMonday: 'Пон',
                weekDayAbrvTuesday: 'Вт',
                weekDayAbrvWednesday: 'Ср',
                weekDayAbrvThursday: 'Четв',
                weekDayAbrvFriday: 'Пет',
                weekDayAbrvSaturday: 'Съб',
                weekDayAbrvSunday: 'Нед',
                recurrence: 'Повторение',
                addScheduledDiscountStop: 'Добавете планирано намаление',
                editScheduledDiscountStop: 'Редактиране на планираното намаление',
                pieces: 'брой',
                expiryDiscountNavLabel: 'Изтичане',
                scheduledDiscountNavLabel: 'Планирано',
                bundleDiscountNavLabel: 'Пакет',
                amount: 'Брой',
                scheduling: 'Планиране',
                pricing: 'Ценообразуване',
                percentageBundleDiscountInputPlaceholder: '% намаление за пакет',
                fixedPriceBundleDiscountInputPlaceholder: 'Фиксирана цена за пакет',
                applyBundleLevelLabel: 'Приложи ниво на пакет',
                applyItemLevelLabel: 'Приложи ниво на артикул',
                productFixedPrice: 'Фиксирана цена на продукта',
                percentageDiscount: '% намаление',
                fixedPriceDiscount: 'Фиксирана цена',
                freeDiscount: 'Безплатно',
                advertisementsFetchError: 'Възникна грешка при изтеглянето на реклами. Уверете се, че блокирането е изключено',
                advertisementsTitle: 'Реклами',
                all: 'Всички',
                'All time': 'Всяко време',
                allProducts: 'Всички продукти без групиране',
                allLocations: 'Всички локации',
                allCabinets: 'Всички шкафове',
                antennaDown: 'Някои от RFID антените не работят',
                antennaError: 'Грешката е в антените на шкафа',
                antennaNoInfo: 'Няма информация за антените на шкафа',
                antennaOk: 'Всички антени работят',
                antennaPartial: 'Някои от антените са изключени',
                archiveSuccess: 'Продуктът е архивиран успешно',
                averageItems: 'Среден брой артикули на транзакция',
                averageSales: 'Среден брой продажби на транзакция',
                barcode: 'Баркод',
                barcodeType: 'Тип баркод',
                barcodeCannotBeChanged: 'Баркодът не може да бъде променян',
                barcodeTypeCannotBeChanged: 'Типът на баркода не може да бъде променян',
                barcodeHelpText12: 'Въведете 12 цифри от баркода, като последната цифра се добавя автоматично.',
                barcodeHelpText11: 'Въведете 11 цифри от баркода, като последната цифра се добавя автоматично.',
                barcodeHelpText7: 'Въведете 7 цифри от баркода, като последната цифра се добавя автоматично.',
                beforeExpiry: 'Преди изтичане на давност',
                cabinetDown: 'Шкафът не работи',
                cabinetIsEmpty: 'Шкафът е празен',
                deviceOffline: 'Устройството е извън мрежата',
                deviceOnline: 'Устройството е онлайн',
                cabinets: 'Шкафове',
                cabinet: 'Шкаф',
                cabinetSettings: 'Настройки на шкафа',
                cabinetTheming: 'Тематично оформление на шкафа',
                cancel: 'Отмени',
                cancelledSales: 'Отменени продажби',
                cancelledTransactions: 'Отменени транзакции',
                categories: 'Категории',
                change: 'Промени',
                clear: 'Изтрий',
                close: 'Затвори',
                clickEditButtonRegionalPrices: 'За да редактирате цените на локациите, кликнете върху бутона „Edit“ (Редактиране на продукта).',
                clickEditLocationPrices: 'Локални цени, кликнете на цената на продукта, за да разширите.',
                clickToExpandLocationPrices: 'Локални цени, кликнете на цената на продукта, за да разширите.',
                closeDoor: 'Затворете вратата',
                rebootDevice: 'Рестартиране на устройството',
                commandSentSuccessFully: 'Командата е изпратена успешно',
                companyLogo: 'Лого на компанията',
                confirm: 'Потвърди',
                confirmDelete: 'Сигурни ли сте, че искате да изтриете продукта? Това действие не може да бъде отменено!',
                confirmDisable: 'Сигурни ли сте, че искате да архивирате продукта?',
                confirmEnable: 'Сигурни ли сте, че искате да направите продукта активен?',
                consumerResponses: 'Обратна връзка от ползвателите',
                controlPanelTitle: 'Контролен панел',
                controls_acceptablePercentageOfBadTags: 'Приемлив процент лоши маркери, преди шкафът да премине в режим на извеждане от експлоатация',
                controls_markTagsGoodQuality: 'Маркиране на всички тагове с добро качество',
                controls_markTagsGoodQuality_btn: 'Маркиране на тагове с добро качество',
                controls_clearTagHistory: 'Изтрий историята на маркерите',
                controls_clearRemoveReplenishmentData: 'Изтрий данните за попълване',
                controls_companyAndPaymentLogo: 'Лого на компанията и за плащане',
                controls_detectTagQuality: 'Открий качеството на маркерите',
                controls_door: 'Контрол на вратата',
                controls_device: 'Контрол на устройството',
                controls_chargeBadTags: 'Таксирайте лошите тагове',
                controls_refill: 'Напълни отново',
                controls_tagQuality: 'Настройки на маркери',
                controls_tagQualityData: 'Данни за качество на маркери',
                controls_replenishmentData: 'Данни за попълване',
                controls_soldTags: 'Продадени тагове',
                controls_tagHistory: 'История на маркери',
                controls_markAllTagsReplenished: 'Маркиране на всички тагове като попълнени',
                controls_markAllTagsUnsold: 'Маркиране на всички етикети като непродадени',
                controls_markAlTagsReplenished_btn: 'Маркиране на тагове като попълнени',
                controls_markAllTagsUnsold_btn: 'Маркиране на етикети като непродадени',
                controls_chargeSoldTags: 'Таксувайте продадените тагове',
                controls_chargeUnreplenishedTags: 'Таксувайте непрепълнените етикети',
                controls_tagsWarn:
                    'Изчистването на хронологията на таговете премахва всички данни на таговете. Моля, щракнете върху маркиране на всички тагове като попълнени след това действие',
                create: 'Създай',
                createAdTitle: 'Нова реклама',
                createdAd: 'Рекламата е създадена',
                createExpiryDiscountRule: 'Създай правило за изтичане на намалението',
                createScheduledDiscountRule: 'Създай правило за планирано намаление',
                createBundleDiscountRule: 'Създай правило за намаление',
                createNewVatCategory: 'Нова категория на ДДС',
                createProductTitle: 'Нов продукт',
                creatingAd: 'Създаване на реклама',
                creatingProduct: 'Създаване на продукт',
                createNewProductCategory: 'Създай нова продуктова категория',
                createNewSupplier: 'Добави нов доставчик',
                currency: 'Валута',
                customQuery: 'Запитване на клиент',
                dashboard: 'Информационно табло',
                dateRange: 'Интервал на дати',
                dataAlredyFetchedTitle: 'Датата вече свалена',
                dataAlreadyFetchedDescription: 'Моля, изберете различни параметри',
                day: 'Ден',
                days: 'Дни',
                deactivationDate: 'Дата на деактивация',
                default: 'По подразбиране',
                delete: 'Изтрий',
                deleteError: 'Грешка: продуктът не може да бъде изтрит ',
                deleteSuccess: 'Продуктът е изтрит успешно',
                description: 'Описание',
                deviceManagement: 'Управление на уреда',
                digitwinOffline: 'Устройството digitwin е офлайн',
                digitwinOnline: 'Устройството digitwin е онлайн',
                dimensions: 'Рамери',
                disable: 'Архив',
                discountAmount: 'Стойност на намалението',
                discountPrice: 'Цена на намалението',
                discountRuleAffects: 'Правилото за отстъпка засяга',
                discountRuleName: 'Име на правилото за отстъпка',
                discountRuleStops: 'Интервали за изтичане',
                discounts: 'Намаления',
                discountSalesData: 'Данни за продажби с намаления',
                discountSalesAmount: 'Суми на продажби с намаления',
                discountTimeBeforeExpiryExists: 'По-ранни интервали за изтичане aвече съществуват',
                discountVsNormalSales: 'Продажби с намаление vs. Нормални продажби',
                dismiss: 'Премахни',
                doesNotExist: 'Не съществува',
                doorCloseConfirm: 'Сигурни ли сте, че искате да затворите вратата?',
                doorCloseSuccess: 'Заключването на вратата е успешно',
                doorOpenConfirm: 'Сигурни ли сте, че искате да отворите вратата?',
                doorOpenReason: 'Защо отваряте вратата?',
                doorOpenSuccess: 'Вратата е отворена успешно',
                rebootDeviceSuccess: 'Устройството е рестартирано успешно',
                deviceRebootConfirm: 'Сигурни ли сте, че искате да рестартирате устройството?',
                edit: 'Редактирай',
                editCompanyLogo: 'Редактирай логото на компанията',
                editDiscountRule: 'Редактиране на правилото за намаление',
                editPaymentMethodLogo: 'Редактирай логото за начин на плащане',
                editVatCategoryModalTitle: 'Редактирай категорията ДДС',
                editProductCategoryModalTitle: 'Редактирай продуктовата категория',
                editSupplierModalTitle: 'Редактирай доставчик',
                enable: 'Направи активен',
                error_401: 'Не е верифициран',
                error_403: 'Не е оторизиран да извършва команди',
                error_500: 'Неочаквана сървърна грешка',
                errorCreatingAd: 'Грешка при създаване на реклама',
                errorCreatingProduct: 'Грешка при създаването на продукт',
                errorFetchingCabinetStatus: 'Грешка при свалянето на статуса на шкафа',
                errorFetchingInventory: 'Грешка при свалянето на инвентара на шкафа',
                errorFetchingReport: 'Грешка при свалянето на доклади',
                errorFetchingSettings: 'Грешка при свалянето на настройки',
                errorUpdatingProduct: 'Грешка при актуализацията на продукт',
                expandToSeeBadTags:
                    'Разширете списъка, за да видите маркери, които са разпознати като лоши маркери. Лоши маркери трябва да бъдат премахнати от шкафа.',
                expandToSeeSoldItems: 'Разширете списъка, за да видите артикулите, които вече са продадени, но са се появили отново в инвентара.',
                expandToSeeReplenishedOutOfInventory:
                    'Разширете списъка, за да видите продуктите, които са били поставени в шкафа в процес, различен от попълване на инвентара.',
                expired: 'Изтекли',
                expiredCount: 'Брой изтекли',
                expiredProductsCount: 'Брой на изтекли продукти',
                expiredSum: 'Сума изтекли',
                expiring: 'Изтичащи',
                expiryDate: 'Дата на последна продажба',
                exportExcel: 'Изтегли в Ексел',
                fetchAdvertisements: 'Сваляне на реклами',
                fetchingCabinets: 'Сваляне на шкафове',
                fetchingCabinetsError: 'Възникна грешка при изтеглянето на шкафове',
                fetchingCabinetStatus: 'Сваляне на статус на шкаф',
                fetchingError: 'Възникна грешка при изтеглянето',
                fetchingInventory: 'Сваляне на инвентара на шкафа',
                fetchingProducts: 'Сваляне на продукти',
                fetchingReport: 'Сваляне на доклади',
                fetchingVatCategories: 'Сваляне на категории ДДС',
                fetchingProductCategories: 'Сваляне на продуктови категории',
                fillForm: 'Формуляр за попълване',
                freshFood: 'Това е изтичащият продукт',
                freezerPackage: 'Замразен продукт',
                generalFetchError: 'Грешка при сваляне',
                generalRequestReject: 'Изискайте грешка',
                get: 'Вземете',
                group: 'Група',
                holdOn: 'Изчакайте',
                hour: 'Час',
                hours: 'часове',
                hoursBeforeExpiry: 'Часове преди изтичане',
                daysBeforeExpiry: 'Дни преди изтичане',
                image: 'Изображение',
                imageOrVideo: 'Изберете изображение или видео',
                inActive: 'Неактивен',
                individualCabinets: 'Индивидуални шкафове',
                individualCabinetsOnly: 'Само индивидуални шкафове',
                invalidBarcodeOnlyDigits: 'Баркодът трябва да съдържа само цифри',
                invalidBarcodeTooLong: 'Баркодът е твърде дълъг',
                invalidBarcodeTooShort: 'Баркодът е твърде къс',
                invalidCabinetNameTooShort: 'Името на шкафа е твърде късо',
                invalidCabinetNameDuplicated: 'Името на шкафа вече съществува',
                invalidLocationNameTooShort: 'Името на локацията е твърде късо',
                invalidLocationNameDuplicated: 'Името на локацията се дублира',
                invalidPercentage: 'Невалидна стойност',
                invalidPrice: 'Невалидна цена',
                invalidProductBarcode: 'Невалиден баркод на продукта',
                invalidTitle: 'Невалидно заглавие',
                invalidBundleItems: 'Пакетът съдържа невалидни артикули',
                inventoryLength: 'Дължина на инвентара',
                inventoryNotReported: 'Шкафът не е докладвал инвентара си',
                inventoryTitle: 'Инвентар на шкаф',
                'Inventory History': 'История на инвентара',
                itemReplenishedOutOfInventoryProcess: 'Този продукт или маркер са били поставени в шкафа в процес, различен от процеса на попълване.',
                itemSoldOn: 'Артикулът е продаден на',
                'Last 30 days': 'Последните 30 дни',
                'Last 7 days': 'Последните 7 дни',
                'Last month': 'Миналия месец',
                'Last quarter': 'Последната четвърт',
                'Last week': 'Миналата седмица',
                'Last year': 'Миналата година',
                lastUpdate: 'Последна актуализация',
                lifetime: 'Жизнен цикъл',
                location: 'Локация',
                locations: 'Локации',
                logoEditor: 'Редактор на лого',
                logoSettings: 'Настройки на лого',
                loading: 'Зареждане...',
                measure: 'Измерване',
                measures: 'Измервания',
                minsBeforeExpiry: 'Минути преди изтичане',
                minutes: 'минути',
                month: 'Месец',
                name: 'Име',
                newVatCategoryModalTitle: 'Нова категория на ДДС',
                newProductCategoryModalTitle: 'Нова продуктова категория',
                newSupplierModalTitle: 'Нов доставчик',
                'no region': '(няма локация)',
                noAdvertisements: 'Няма реклами',
                noCabinets: 'Няма шкафове',
                noCabinetSelected: 'Не е избран шкаф',
                noLogo: 'Няма лого',
                noData: 'Няма данни',
                noDescription: 'Няма описание',
                noEvents: 'Няма събития!',
                noIndividualCabinets: 'Няма индивидуални шкафове',
                noLocationPrices: 'Не са дефинирани локални цени за този продукт',
                none: 'Няма',
                noPaymentMethodLogo: 'Няма лого за начин на плащане',
                noProducts: 'Няма продукти',
                noProductsRemoved: 'Не са премахнати никакви продукти',
                noProductTypeSelected: 'Не е избран тип на опаковка',
                normalPrice: 'Нормална цена',
                normalSalesAmount: 'Нормална продажна цена',
                notAccessibleForYou:
                    'Функцията за създаване/актуализиране е разрешена само за Администратори и Оператори на шкафове. За да получите достъп, обърнете се към Вашия ръководител или системен администратор.',
                notDefined: 'Не е дефиниран',
                noTitle: 'Няма заглавие',
                noVatCategories: 'Не е намерен запис за категория на ДДС',
                noProductCategories: 'Не е намерен запис за продуктова категория',
                noInventoryOnSelectedDate: 'За избраната от Вас дата не е отчетен инвентар, моля, опитайте с друга дата.',
                openDoor: 'Отворете вратата',
                orderSum: 'Сума на поръчка',
                paymentMethodLogo: 'Лого за начин на плащане',
                percentage: 'Процент',
                permanent: 'Постоянен',
                preview: 'Преглед',
                price: 'Цена',
                print: 'Печат',
                productCreated: 'Продуктът е създаден',
                products: 'Продукти',
                productsFetchError: 'Възникна грешка при качването на продуктите',
                productsTitle: 'Продуктов каталог',
                productType: 'Тип пакет',
                productUpdated: 'Актуализиран продукт',
                quantity: 'Количчество',
                readerDown: 'Четецът RFID не работи',
                region: 'Локация',
                regions: 'Локации',
                removedAt: 'Премахнат на',
                removeImage: 'Премахни изображения',
                removeVideo: 'Премахни видео',
                refillQRCodeInfo: 'Сканирай QR кода, за да отвориш приложението за допълване или последвай',
                refillQRCodeTitle: 'QR код за допълване',
                refillRuleTitle: 'Правило за презареждане',
                reportsTitle: 'Доклади',
                requestSuccess: 'Промените са направени успешно',
                requiredPercentage: 'Изискано. Процентова стойност без знак за проценти.',
                requiredText: 'Изисквана дължина на знаците: ',
                retireLocation: 'Премахни локацията',
                rfidNoInfo: 'Няма налична информация за RFID четеца и антени',
                rfidOk: 'RFID четецът и антените работят както се очаква.',
                sales: 'Продажби',
                salesCount: 'Брой продажби',
                salesVsWasteAmount: 'Стойност продажби срещу брак',
                save: 'Запази',
                search: 'Търсене',
                selectCabinet: 'Изберете шкаф',
                selectDiscountRuleCabinets: 'Изберете шкафове, които са повлияни от правилото за намаление',
                selectDiscountRuleLocations: 'Изберете локации, които са повлияни от правилото за намаление',
                selectDiscountRuleProducts: 'Изберете продукти, които са повлияни от правилото за намаление',
                selectProductType: 'Моля, изберете тип опаковка за вашия продукт',
                setDiscountAmount: 'Задайте стойност на намалението',
                setDiscountTimeBeforeExpiry: 'Задайте интервали за изтичане',
                settingConfirmMessage: 'Сигурни ли сте, че искате да запазите тези настройки? Ако не сте сигурни, не потвърждавайте',
                showDisable: 'Архивирани продукти',
                summary: 'Обобщение',
                sureRemoveDiscountRule: 'Сигурни ли сте, че искате да премахнете правилото за намаление?',
                tagIsBad: 'Маркерът е лош, трябва да бъде премахнат от шкафа.',
                tagsRemovedReport: 'Доклад за премахнати маркери',
                taxValue: 'Данъчна стойност',
                'This month': 'Този месец',
                'This quarter': 'Таи четвърт',
                'This week': 'Тази седмица',
                'This year': 'Тази година',
                thisLink: ' тази връзка. ',
                timeGrouping: 'Групиране на време',
                title: 'Заглавие',
                Today: 'Днес',
                topExpiredProducts: 'Топ на изтекли продукти',
                topProducts: 'Топ продукти',
                total: 'Обща сума',
                totalValue: 'Обща стойност',
                transactions: 'Транзакции',
                ttlInDays: 'Време за живот (жизнен цикъл) на продукта в дни',
                ttlsAffected: 'Засегнати продукти с време за живот (жизнен цикъл)',
                unexpectedError: 'Неочаквана грешка',
                uniqueCustomers: 'Уникални клиенти',
                unknown: 'Непознат',
                update: 'Актуализация',
                updateAdError: 'Грешка при актуализацията на реклама',
                updateAdTitle: 'Актуализиране на реклама',
                updatedAd: 'Рекламата е актуализирана',
                updateCabinetSettings: 'Синхронизиране на данни към шкафа',
                updateCabinetSettingsInfo: 'Сигурни ли сте, че искате да актуализирате настройките на шкафа?',
                updateData: 'Актуализиране на данни',
                updateProductTitle: 'Актуализиране на продукт',
                updatingAd: 'Актуализиране на реклама',
                updatingProduct: 'Актуализиране на продукт',
                uploading: 'Качване',
                uploadingImage: 'Качване на изображение',
                use: 'Използване',
                'w/o grouping': 'Няма групиране',
                waste: 'Боклук',
                vatCategoriesFetchError: 'Грешка при качване на категории ДДС',
                vatCategoriesTitle: 'ДДС категории',
                vatCategory: 'Категория ДДС',
                vatCategoryItems: 'Артикули',
                vatCategoryName: 'ДДС име на категория',
                vatCategoryPromptMessage: 'Сигурни ли сте, че искате да изтриете ДДС категорията',
                vatCategoryValue: 'Данъчна стойност в процент',
                vatDeleteRejected: 'Отказано е изтриването на категорията ДДС. Провери свързани продукти.',
                vatErrorRequesting: 'Грешка при обработка на заявката',
                vatRequestRejected: 'Заявката е отхвърлена',
                vats: 'ДДС',
                productCategoriesFetchError: 'Грешка при качване на продуктови категории',
                productCategoriesTitle: 'Категории на продукта',
                productCategory: 'Категория на продукта',
                productCategoryItems: 'Категории',
                productCategoryName: 'Име на категория на продукта',
                productCategoryPromptMessage: 'Сигурни ли сте, че искате да изтриете категорията на продукта?',
                productCategoryDescription: 'Описание на категорията на продукта',
                productCategoryHelpTextDescription: 'Напр. категория за салати',
                productCategoryHelpTextName: 'Напр. салати',
                productCategoryDeleteRejected: 'Отказано е изтриването на категорията на продукта. Провери свързани продукти!',
                week: 'Седмица',
                viewMode: 'Изглед',
                year: 'Година',
                Yesterday: 'Вчера',
                groupBy: 'Групиране според',
                filterBy: 'Филтриране според',
                reportType: 'Тип доклад',
                custom: 'Митница',
                preDefined: 'Предварително зададен',
                selected: 'Избран',
                metricsInfo: 'Изчисляват се показатели (сума, максимум, минимум...) за всички избрани измерения/ресурси',
                dimensionsInfo: 'Показателите ще бъдат качени за избраните измерения/ресурси',
                metrics: 'Показатели',
                controlsSoftwareVersion: 'Версия на софтуера',
                currentSoftwareVersion: 'Настояща версия на софтуера',
                availableSoftwareVersion: 'Налична версия на софтуера',
                invalidLifetime: 'Невалидна стойност на жизнен цикъл',
                unpaidSales: 'Неплатени продажби',
                unpaidTransactions: 'Неплатени транзакции',
                softwareUpdateConfirmation: 'Наистина ли искате да актуализирате версията на софтуера за избраното устройство?',
                softwareUpdateWarn:
                    'Актуализирането на версията на софтуера ще изпрати команда до устройството (устройствата) за получаване на актуализацията сега или в избраното време. Избраното(ите) устройство(а) ще инсталира(т) актуализацията следващия път, когато е онлайн след задействането. Инсталирането може да отнеме време в зависимост от скоростта на връзката. След инсталацията устройството(ата) ще се рестартира(т).',
                releaseVersion: 'Допуснати версии',
                releaseDate: 'Дата на допускане',
                latestRelease: 'Последни версии',
                currentVersion: 'Настояща версия',
                softwareUpdateSuccess: 'Версията на софтуера беше актуализирана',
                prebooking: 'Предварително резервиране',
                prebookedProduct: 'Предварително резервиран продукт',
                prebookedProducts: 'Предварително резервирани продукти',
                activeProducts: 'Активни продукти',
                startMaintenanceMode: 'Стартирай режим за поддръжка',
                endMaintenanceMode: 'Прекрати режим за поддръжка',
                startMaintenanceConfirm: 'Сигурни ли сте, че искате да стартирате режим за поддръжка за шкафа?',
                endMaintenanceConfirm: 'Сигурни ли сте, че искате да прекратите режим за поддръжка за шкафа?',
                startMaintenanceSuccess: 'Режим за поддръжка е стартиран.',
                endMaintenanceSuccess: 'Режим за поддръжка е прекратен.',
                noResults: 'Няма резултати',
                suppliers: 'Доставчици',
                supplier: 'Доставчик',
                supplierDeleteRejected: 'Изтриването на доставчика е отхвърлено. Провери свързани продукти!',
                supplierPromptMessage: 'Сигурни ли сте, че искате да изтриете доставчика?',
                fetchingSuppliers: 'Качване на доставчици',
                noSuppliers: 'Не е намерен запис за доставчика',
                suppliersFetchError: 'Грешка при качване на доставчици',
                supplierName: 'Име на доставчика',
                supplierHelpTextName: 'Напр. име на марка',
                supplierDescription: 'Описание на доставчика',
                supplierHelpTextDescription: 'Напр. описание на марка',
                wishes: 'Желания',
                checkNow: 'Проверете сега',
                startTimeOn: 'Начален час',
                endTimeOn: 'Краен час',
                signOutConfirmationModalTitle: 'Потвърждение за излизане',
                signOutConfirmationModalContent: 'Сигурни ли сте, че искате да излезете?',
                startDate: 'Начална дата',
                endDate: 'Крайна дата',
                scheduledDiscountApplyAllDays: 'Кандидатстване за всички дни',
                scheduledDiscountApplySelectedDays: 'Кандидатстване за избрани дни',
                discountCustomImageTitle: 'Изображение за отстъпка (по избор)',
                discountCustomImageModalHeader: 'Изображение за отстъпка',
                saveChanges: 'Запазете промените',
                updateTagsQualitySettingsTitle: 'Настройки за качество на етикета',
                updateTagsQualitySettingsMessage: 'Актуализирани настройки за качество на етикети',
                IF3Cabinets: 'Модел 3 Хладилници',
                IF2Cabinets: 'Модел 2 Хладилници',
                IF3Freezers: 'Модел 3 Фризери',
                encodingStations: 'Кодиращи станции',
                if2: 'Хладилник модел 2',
                if3: 'Хладилник модел 3',
                if3Freezer: 'Модел 3 Фризер',
                legalNotice: 'Правна информация',
                privacyPolicy: 'Политика за поверителност',
                scheduledDiscountDateMissingError: 'И двете дати са необходими, ако е зададена начална или крайна дата',
                scheduledDiscountDuplicateTimeError: 'Часовете не могат да бъдат еднакви, ако датите са еднакви',
                now: 'Сега',
                minimum: 'Минимум',
                maximum: 'Максимум',
                discardChangesConfirmationText: 'Сигурни ли сте, че искате да изтриете промените?',
                exports: 'Експорти',
                selectAllCabinets: 'Изберете всички шкафове',
                expiryIntervals: 'Интервали за изтичане',
                timeBeforeExpiry: 'Време преди изтичане',
                apply: 'Приложи',
                yes: 'Да',
                no: 'Не',
                productOnScreenBannerText: 'Текст на информационен банер на екрана',
                productOnScreenBannerAdditionalInfo:
                    'Текстът на банера е текст от един ред, който се появява под изображението на продукта и кратко предава ключови характеристики, здравни призиви или всякаква допълнителна информация за продукта',
                productOnScreenBannerExampleText: 'Без лактоза',
                UI_IS_DOWN: 'Екранът на шкафа не работи',
                READER_IS_DOWN: 'RFID четецът е изключен',
                ANTENOR_DEVICE_DISCONNECTED: 'Платежното устройство е изключено',
                EXPIRED_PRODUCT: 'Изтекли продукти в шкафа',
                CLOUD_SERVICE_DOWN: 'Облак услугата е изключена',
                ANTENNA_DISCONNECTED: 'Антената е изключена',
                TAG_CHANGES: 'Твърде много лоши етикети в шкафа',
                ANTENNA_DOWN: 'RFID антената в шкафа не работи',
                READER_CONFIG_ERROR: 'Конфигурацията на RFID четеца се провали и не работи',
                activeAlarms: 'Активни аларми',
                deviceStatus: 'Статус на устройството',
                lastMonitored: 'Последно наблюдавано',
                noService: 'Извън обслужване',
                operational: 'Оперативен',
                offline: 'Offline',
                locationsSelectHelp: 'Когато се добави местоположение, ще бъдат добавени и неговите детски местоположения.',
                select: 'Изберете...',
                labels: 'Етикети',
                productDetails: 'Детайли на продукта',
                invalidProductName: 'Името на продукта трябва да е от 1 до 125 символа',
                standardPrice: 'Стандартна цена',
                locationPrices: 'Цени по местоположение',
                addNew: 'Добави нов',
                productExpiry: 'Срок на годност на продукта',
                productLifeTime: 'Срок на годност на продукта',
                invalidTTL: 'Срокът на годност на продукта трябва да е цяло число от 0 до 32767',
                productImage: 'Изображение на продукта',
                uploadImage: 'Качете изображение',
                pleaseUploadImage: 'Моля, качете изображение',
                productValidationError: 'Моля, коригирайте следните грешки преди да запазите данните за продукта',
                productSupplier: 'Доставчик на продукта',
                frozenProductLable: 'Това е замразен продукт',
                prebookingLabel: 'Това е продукт за предварителна резервация',
                previous: 'Предишен',
                bannerText: 'Текст на банера',
                packagingTypeValidationError: 'Моля, изберете тип опаковка за продукта',
                showOnlyFrozen: 'Показване само на замразени продукти',
                productClassification: 'Класификация на продукта',
                productClassLabel:
                    'Моля, изберете подходящия клас за продукта. Това позволява на Selfly Insights да предоставя по-релевантна информация за продукта.',
                productLabels: 'Етикети на продукта',
                productLabelsHelpText:
                    'Моля, добавете опционални етикети към вашия продукт. Това ще ви позволи да филтрирате продуктите си в каталога на продуктите.',
                newLabel: 'Нов етикет',
                productClassValidationError: 'Моля, изберете класификация за вашия продукт от опциите по-долу',
                tagSensitivity: 'Чувствителност на качеството на етикета',
                tagSensitivitySetting: 'Настройка на чувствителността на качеството на етикета',
                configTagSensitivity: 'Конфигурирайте чувствителността на качеството на етикета за продукта',
                productLevelSensitivityInfo:
                    'Четливостта на RFID етикет, прикрепен към продукт, може да бъде повлияна от различни фактори, включително опаковъчни материали, метални повърхности, влага и дори самото съдържание на продукта. Тези фактори понякога могат да причинят прекъсващи сигнали на етикета, известни като трептене. Шкафът обработва това трептене въз основа на своите настройки за чувствителност. Ако не сте сигурни кое ниво на чувствителност е подходящо за този продукт, можете да пропуснете този раздел.',
                errorSaving: 'Грешка при запазване на данните за продукта',
                successSaving: 'Данните за продукта са запазени успешно',
                basicDetail: 'Основни детайли',
                additionalDetail: 'Допълнителни детайли',
                packaging: 'Тип опаковка',
                classification: 'Класификация',
                selectOrtype: 'Изберете или напишете...',
                max25Chars: 'макс 25 символа',
                noVatForProductCreation: 'Моля, създайте категория ДДС преди да добавите нови продукти',
                lifeTimeExplanation: 'Колко дни е валиден този продукт за продажба. (0 дни) означава до края на текущия ден',
                selflyEncode: 'Selfly Encode',
                userRestrict: 'Само разпознати потребители',
                userRestrictTitle: 'Допустими потребители',
                userNotRestrict: 'Всички потребители',
                userRestrictHelperText:
                    "Опцията 'разпознати потребители' означава, че отстъпката ще се прилага само за потребители, които вече са регистрирани за право на отстъпка!",
                searchCabinets: 'Търсене на шкафове',
                selectAll: 'Избери всички',
                monday: 'Понеделник',
                tuesday: 'Вторник',
                wednesday: 'Сряда',
                thursday: 'Четвъртък',
                friday: 'Петък',
                saturday: 'Събота',
                sunday: 'Неделя',
                fetchingLoader: 'Зареждане. Моля, изчакайте...',
                category: 'Категория',
                okay: 'Ок',
                noLocation: 'Няма местоположение',
                copy: 'Копирай',
                pleaseConfirm: 'Моля, потвърдете',
                saveQuery: 'Запазете тази заявка',
                saveQueryPlaceholder: 'напр. „Общ брой продадени артикули“',
                saveQuerySuccess: 'Заявката е запазена.',
                yourSavedQueries: 'Вашите запазени заявки',
                selectQuery: 'Изберете заявка',
                noQuery: 'Все още няма запазени заявки',
                deleteAdConfirm: 'Сигурни ли сте, че искате да изтриете тази реклама?',
                dataSaved: 'Данните са успешно запазени',
            },
            refill: {
                refillRuleApplyRuleDropdownText: 'Приложете това правило и към други шкафове',
                refillPlanGenerate: 'Генерирайте план за презареждане',
                refillPlanEditPlan: 'Редактиране на плана',
                refillAmount: 'Сума за попълване',
                refillRuleTargetAmount: 'Целево ниво на инвентара',
                refillRuleTargetAmountShort: 'Цел',
                refillPlanActivePlans: 'Активни планове',
                refillPlanCompletedPlans: 'Завършени планове',
                refillNote: 'Бележка за пълнене',
                refillNoteHelp: 'Бележка за пълнене на продукта (максимум 125 знака)',
                refillPDFText:
                    'По-долу е планът за пълнене на шкафа. Моля, уверете се, че сте отбелязали плана за пълнене като завършен в приложението за пълнене след като сте завършили пълненето.',
                refillPDFSummaryText: 'По-долу е резюмето на плановете за пълнене с продукти за добавяне и премахване за всички избрани шкафове.',
                refillSummary: 'Резюме на пълненето',
                addItems: 'Добавяне на продукти',
                removeItems: 'Премахване на продукти',
                inventoryChange: 'Промяна на инвентара',
                planogramPDFText: 'Планограма за стратегията за презареждане на шкафовете.',
                planogramImagePDFText: 'Персонализирано изображение за стратегията за презареждане на шкафовете.',
                refillSchemas: 'Шаблони за презареждане',
                refillPlans: 'Планове за презареждане',
                refillSchemaTitle:
                    'Задайте целевите и минималните количества инвентар като шаблон за избраните шкафове. Тези шаблони ще бъдат използвани за генериране на плановете за презареждане.',
                refillPlansTitle:
                    'Прегледайте всички активни планове. Можете да редактирате или премахнете съществуващите активни планове или да добавите нови планове за презареждане, ако е необходимо.',
                refillSchemaSubText: 'Моля, изберете шкаф, за да създадете или актуализирате шаблона за презареждане.',
                noRefillSchema: 'Все още не е определен шаблон за презареждане за този шкаф.',
                createRefillSchema: 'Създайте нов шаблон за презареждане',
                noDevice: 'Не е намерено устройство',
                schemaSaved: 'Шаблонът за презареждане е запазен успешно',
                deleteSchemaConfirmation: 'Сигурни ли сте, че искате да изтриете шаблона за презареждане?',
                targetAmount: 'Целева сума',
                targetAmountHelpText: 'Целевото количество инвентар за всеки продукт в шкафа след презареждането.',
                minimumThreshold: 'Минимален праг',
                minimumThresholdHelpText:
                    'Уточнете минималното ниво на инвентар за всеки продукт в шкафа. Ако количеството падне под определения праг, ще получите уведомление за аларма. Настройването на прага на 0 ще деактивира уведомленията.',
                editNote: 'Редактиране на бележка',
                refillNoteTitle: 'Напишете бележка за презареждане за продукта.',
                refillNoteCharacters: 'символи',
                noRefillPlan:
                    'В момента няма активни планове за презареждане. Моля, добавете нови планове за презареждане за вашите шкафове или създайте шаблон за презареждане за шкафовете първо.',
                planSaved: 'Планът за презареждане е запазен успешно',
                deleteConfirmation: 'Сигурни ли сте, че искате да изтриете данните на избрания план за презареждане?',
                editPlan: 'Редактирайте плана',
                currentInventory: 'Текущ инвентар',
                lastSevenDaySales: 'Продажби през последните седем дни',
                inventory: 'Инвентар',
                planogram: 'Планограма',
                copyRefillSchema: 'Копирайте шаблона в други шкафове',
                generatingPlans: 'Генериране на планове за презареждане. Моля, изчакайте...',
                generatePlans: 'Генерирайте планове за презареждане',
                complete: 'завършено',
                selectCabinetsToAddPlans: 'Моля, изберете шкафове, за да генерирате планове за презареждане.',
                reviewSuggestion:
                    'По-долу са предложените планове за презареждане за избраните шкафове. Моля, прегледайте, редактирайте, ако е необходимо, и ги запазете, за да активирате тези планове.',
                noSchema:
                    'В момента нито един шкаф няма шаблон за презареждане. Моля, добавете шаблон за презареждане към вашите шкафове, за да генерирате планове за презареждане.',
                searchProducts: 'Търсене на продукти',
                maxAllowedInventory: 'Максимално допустимият инвентар е 200.',
                savingData: 'Запазване на данни. Моля, изчакайте',
                errorSavingData: 'Грешка при запазването на данните.',
                refillPlanSaved: 'Плановете за презареждане са генерирани успешно.',
                exportPDFHelp: 'Експорт на избрани планове в PDF файл.',
                exportExcelHelp: 'Експорт на избрани планове в Excel файл.',
                addPlanHelp: 'Добавете нови планове за презареждане.',
                deletePlanHelp: 'Изтрийте избраните планове за презареждане.',
                generatePlansHelp: 'Генерирайте планове за презареждане за избраните шкафове.',
                planogramSaved: 'Планограмата е запазена успешно.',
                schemaCopied: 'Шаблонът за презареждане е синхронизиран успешно с другите шкафове.',
                allProducts: 'Всички продукти',
                schemaProducts: 'Продукти от шаблона за презареждане',
                deletePlanogram: 'Сигурни ли сте, че искате да изтриете планограмата?',
                dragInPlanogram: 'Моля, плъзнете и пуснете продуктите върху определените рафтове на шкафа или плъзнете слота на шкафа върху продукта.',
                noPlanogramInSchema: 'Планограма не е била определена за шкафа до момента.',
                noPlanogramInPlan:
                    'Няма налични данни за планограмата за шкафа. Моля, създайте планограма за шкафа в раздела за шаблона за презареждане първо.',
                copyPlanogram: 'Копирайте планограмата за презареждане заедно с шаблона за презареждане',
                planogramImageInfo: 'В допълнение към планограмата, можете също да добавите персонализирано изображение на планограмата.',
                addPlanogramImage: 'Добавете изображение на планограмата',
                editPlanogramImage: 'Редактирайте изображението на планограмата',
                numberOfShelves: 'Брой рафтове',
                addNewRefillPlan: 'Добавете нови планове',
                qrcodeTitle: 'QR код ще бъде генериран за избрания шкаф, за да се инициира процесът на презареждане.',
                qrcodeSubText: 'Моля, изберете шкаф, където искате да инициирате процеса на презареждане.',
                qrcodeScan: 'Моля, сканирайте QR кода, за да презаредите, или кликнете върху бутона, за да отворите приложението за презареждане.',
                qrcodeOpenApplication: 'Отворете приложението за презареждане',
                createRefillPlanogram: 'Създайте планограма за презареждане',
            },
            navigation: {
                advertisements: 'Реклами',
                analytics: 'Анализи',
                controlPanel: 'Контролен панел',
                deviceManagement: 'Управление на уреда',
                discounts: 'Намаления',
                fetching: 'Качване',
                group: 'Група',
                inventory: 'Инвентар на шкаф',
                language: 'Език',
                productCatalog: 'Продуктов каталог',
                productCategories: 'Категория на продукта',
                reports: 'Доклади',
                settings: 'Настройки на ползвателя',
                signOut: 'Излизане',
                title: 'Selfly Cloud Portal',
                vatCategories: 'ДДС категории',
                community: 'Selfly Community',
                refillManagement: 'Управление на презареждането',
                automations: 'Автоматизации',
                mySelfly: 'Настройки MySelfly',
                automationInfo: 'Управление на автоматизирани действия (BETA)',
            },
            reports: {
                alreadyRefund: 'беше изплатено обратно',
                amount: 'Брой',
                average: 'Средно',
                barcode: 'Баркод',
                confirmIssueRefundButton: 'Потвърдете връщането сега',
                current: 'Настоящ',
                export: 'Сваляне',
                fetching: 'Качване',
                fetchingError: 'Възникна грешка при изтеглянето',
                image: 'Изображение',
                issueRefundButton: 'В Р Ъ Щ А Н Е',
                maximum: 'Максимум',
                minimum: 'Минимум',
                name: 'Име',
                noData: 'Няма данни',
                note: 'Бележка',
                order: 'Транзакция',
                paymentWasDoneByCreditCard: 'Плащането е извършено с кредитна карта',
                price: 'Цена',
                ProductReport: 'Продуктов доклад',
                quantity: 'Количчество',
                "Refund impossible'":
                    'Плащането е извършено с кредитна карта. Свържете се с институцията, издала кредитната карта, за да извършите връщането на сумата.',
                refundFormTitle: 'Връщане на сумата',
                refundReason: 'Причина за връщане на сумата',
                refundSuccess: 'Сумата {{sum}} беше върната успешно',
                refundTotal: 'Сума за връщане',
                replenishments: 'Напълни отново',
                replenishmentsTitle: 'Доклад за допълванията',
                SaleTransaction: 'Транзакция за продажба',
                summary: 'Обобщение',
                SummaryReport: 'Обобщение продажби',
                temperature: 'Температура',
                temperatureTitle: 'Доклад за температура',
                temperatureValueNotification: 'Стойностите на температурата са средночасовите стойности на температурата в шкафа.',
                time: 'Време',
                total: 'Обща сума',
                transactions: 'Транзакции',
                user: 'Ползвател',
                paidTransactions: 'Платени транзакции',
                unpaidTransactions: 'Неплатени транзакции',
                partiallyPaid: 'Платено частично',
                failedTransactions: 'Неуспешни транзакции',
                amountOwed: 'Дължима сума',
                receiptLinkText: 'Вземете касовата бележка от тук',
                noPurchase: 'Без покупка',
                other: 'други',
                userId: 'Потребителски идентификатор:',
            },
            settings: {
                block_cabinet_when_product_expired: 'Блокирайте шкафа за изтекли продукти',
                criticalExpiryLimitHours: 'Часове до критичната аларма за изтичане',
                language: 'Език',
                logo: 'Лого на компанията',
                mediumExpiryLimitHours: 'Часове до средната аларма за изтичане',
                payment_method_logo: 'Лого за начин на плащане',
                footer_instruction_logo: 'Изображение с инструкции в долния колонтитул',
                readUserMemory: 'Свалете датите на изтичане на продуктите',
                ui_skus_sort_by: 'Ред на сортиране на продуктите в дисплея на шкафа',
                timeZone: 'Времева зона',
                country: 'Държава',
                city: 'Град',
                tagSettings: 'Настройки на етикети',
                themeAndLogos: 'Теми и лога',
                advancedSettings: 'Разширени настройки',
                themingInfoText: 'Можете да персонализирате темата на екрана на вашия шкаф',
                cabinetLogos: 'Лого на шкафа',
                cabinetLogoInfoText: 'Качете лога, които се появяват на екрана на шкафа',
                logoHelperText: 'Моля, използвайте изображение с размери 180 х 180 пиксела',
                settingsCopyInfoHeader: 'Изберете шкафове, в които искате да копирате настройките',
                settingsCopyButton: 'Копиране на настройки в избрани шкафове',
                settingsCopySuccessModalTitle: 'Копиране на настройките',
                settingsCopySuccessModalContent: 'Настройките са копирани успешно в {{cabinetsAmount}} шкафа(ове)',
                theming: 'Темизация',
                adminSettings: 'Администраторски настройки',
                settingsCopyErrorModalTitle: 'Грешка при копиране на настройките',
                key: 'Настройка',
                value: 'Стойност',
                sleepScheduling: 'Планирано спане',
                sensitivityLevel: 'Ниво на чувствителност',
                sensitivity_0:
                    'Шкафът игнорира трептенето на етикета и последователно отбелязва всички етикети като валидни, което понякога може да доведе до неправилно таксуване на артикули.',
                sensitivity_20: 'Шкафът допуска до три неправилни четения за етикет в рамките на 50 сканирания и все още ще маркира етикета като валиден.',
                sensitivity_40: 'Шкафът допуска до две неправилни четения за етикет в рамките на 60 сканирания и все още ще маркира етикета като валиден.',
                sensitivity_60: 'Ако етикетът трепти често, но остава стабилен за продължителен период, шкафът го приема и го отчита като валиден.',
                sensitivity_80: 'Ако етикетът трепти за кратко, но остава стабилен за продължителен период, той ще се счита за валиден етикет.',
                sensitivity_100:
                    'Шкафът е много непоносим към трептенето на етикетите. Дори едно неправилно четене може да доведе до това етикетът да бъде отбелязан като невалиден за продължителен период, което може да доведе до потенциални загуби.',
                sensitivityInfo:
                    'Чувствителността към качеството на етикетите на шкафа се отнася до това колко ефективно шкафът обработва непостоянните четения на етикетите или трептенето на етикетите. Моля, внимавайте при избора на ниво на чувствителност, тъй като това може да повлияе на производителността на шкафа. Ако не сте сигурни кое ниво е подходящо, се препоръчва да оставите настройката на нивото по подразбиране – 3.',
            },
            signIn: {
                signInButton: 'Влизане',
                signInExpiredError: 'Вашата сесия е изтекла. Влезте отново',
                signInMessage: 'Трябва да сте влезли в профила си, за да продължите. Кликнете върху бутона по-долу, за да влезете в профила си',
                noOrganizationsError: 'Няма организация свързана с това потребителско име. Опитайте с друго потребителско име.',
            },
            translations: {
                add: 'Добавете',
                closed: 'Затворен',
                delete: 'Изтрий',
                device: 'Устройство',
                edit: 'Редактирай',
                off: 'OFF',
                offline: 'Offline',
                on: 'ON',
                online: 'Online',
                opened: 'Отворен',
                save: 'Запази',
                seeDetails: 'Детайли',
                settings: 'Настройки',
                unknown: 'Непознат',
            },
            consumerApp: {
                enableConsumerFeedback: 'Позволете даване на обратна връзка от ползвателите на MySelfly',
                enablePurchaseReceipts: 'Позволете електронни касови бележки на MySelfly',
                enableSelflyTrademarkForReceipts: 'Разрешете прозорец с лого на Selfy Store върху електронната касова бележка',
                enableSelflyTrademarkForReceiptsHelpText:
                    'Ако позволите това, ще се добави прозорец с лого на Selfy Store и данни за фирмата върху касовата бележка в ПДФ!',
                organizationFormHelpText: 'Моля, дайте данни за издателя на електронната касова бележка.',
                organizationFormValidationHelpText:
                    '* Задължителните полета за електронната касова бележка са: име на организацията-издател, идентификационен номер на фирмата, адрес и държава.',
                organizationName: 'Име на организацията',
                organizationLogo: 'Лого на организацията',
                businessId: 'Бизнес идентификатор',
                city: 'Град',
                email: 'Имейл',
                website: 'Страница в интернет',
                postalCode: 'Пощенски код',
                street: 'Адрес',
                phoneNumber: 'Телефонен номер',
                addressHeader: '* Адресна информация',
                contactDetails: 'Данни за контакт',
                organization: '* Организация-издател',
                organizationSettingsUpdatedTitle: 'Актуализиране на настройките на организацията',
                organizationSettingsUpdatedContent: 'Актуализирането на настройките на организацията е успешно',
                organizationSettingsMissingFields: 'Липсват задължителни полета за електронната касова бележка. Моля, попълнете преди да запазите.',
                organizationSettingsMissingFieldsTitle: 'Грешка при валидиране на формуляра',
            },
            softwareVersions: {
                updateNotificationTitle: 'Актуализация на версията на софтуера',
                updateNotificationContent: 'Версията(ите) на софтуера са актуализирани успешно',
                selectAVersionPlaceholder: 'Изберете версия',
                searchDevices: 'Търсене на устройства',
                updateSelected: 'Избрано актуализиране',
                softwareVersions: 'Версии на софтуера',
                softwareVersion: 'Версия на софтуера',
                notSupportedDeviceNote: 'Версията {{selectedVersion.version}} не се поддържа на това устройство',
                userNotificationContent:
                    'Издадена е нова версия на софтуера за вашите устройства. Моля, актуализирайте устройствата си, за да получите най-новите функции.',
                userNotificationTitle: 'Издадена е нова версия на софтуера',
                notificationBell_newSoftwareVersions: 'Налични актуализации за Вашите устройства',
                manageVersions: 'Управлявайте версиите на софтуера на устройството',
                filterLocationsPlaceholder: 'Филтриране по местоположения',
                allVersionNumbers: 'Всички издания',
                noVersionsForUpdating: 'Няма версии за актуализиране',
                scheduledUpdateNote: 'Версия {{version}} е планирана за актуализиране на {{scheduled}}',
                scheduleRemoveNotificationTitle: 'Планиране на версията на софтуера',
                scheduleRemoveNotificationContent: 'Насрочената актуализация на софтуерната версия е премахната успешно',
                scheduleRemoveNotificationModalContent: 'Сигурни ли сте, че искате да премахнете планираната актуализация за това устройство?',
            },
            header: {
                searchByOrganization: 'Търси според организацията',
                pageLanguage: 'Език',
                configureMySelflyAndEreceipts: 'Конфигурирайте настройките в MySelfly',
                remindMeLater: 'Напомни ми по-късно',
                updateNow: 'Актуализирайте сега',
            },
            notifications: {
                noNewNotifications: 'Няма нови известия',
            },
            cabinetSettings: {
                cabinetTheme: 'Тема на шкафа',
                editAndSaveTheme: 'Редактирайте и запазете темата',
                colorPicker: 'Средство за избиране на цвят',
                customTheme: 'Персонализирана тема',
                themeSettings: 'Настройки на темата',
                primary: 'Първичен',
                negative: 'Отрицателна',
                attention: 'Внимание',
                positive: 'Положителен',
                background: 'Заден план',
                dark: 'Тъмно',
                light: 'Светлина',
                white: 'Бяло',
                default: 'По подразбиране',
                negativeLight: 'Негативна светлина',
                primaryColor: 'Основен цвят',
                positiveColors: 'Позитивни цветове',
                negativeColors: 'Негативни цветове',
                secondaryColors: 'Вторични цветове',
                attentionColors: 'Цветове за привличане на вниманието',
                screenBackground: 'Фон на екрана',
                borderColors: 'Граници - сенки',
                shadow: 'Сянка',
                border: 'Граница',
                fontColors: 'Цветове на шрифта',
                footerTextPlaceholder: 'Текст в долния колонтитул',
                bundleDiscounts: 'Комбинирайте и спестете',
                expiringText: 'Продукти с изтичащ срок на годност. Моля, проверете първо датата на изтичане преди да закупите',
                expiredText: 'Продукти с изтекъл срок на годност. Моля, проверете първо датата на изтичане преди да закупите.',
                cabinetName: 'Име на шкафа',
                productName: 'Име на продукта',
                confirmationModalHeader: 'Сигурни ли сте, че искате да запазите персонализираната тема?',
                confirmationModalContent:
                    'Персонализирането на темата ще повлияе на визуалния вид на екрана на Вашия шкаф. Моля, уверете се, че визуалното качество на екрана не е компрометирано с промените.',
                defaultRevertModalMessage: 'Сигурни ли сте, че искате да промените темата на шкафа по подразбиране?',
                backgroundColor: 'Цвят на фона',
                backgroundImage: 'Фоново изображение',
                footerBackground: 'Фон на подвала',
                cardsBackground: 'Фон на картата',
                screenImage: 'Изображение за фона на екрана на шкафа',
                uploadImage: 'Качване на изображение',
                editImage: 'Редактиране на изображението',
                transactionComplete: 'Транзакцията е завършена',
                customPostPurchaseImage: 'Моля, качете изображение за екрана след покупка',
                postPurchaseScreen: 'Екран след покупка',
                custom: 'персонализиран',
                postPurchaseText: 'Текст за показване след покупка',
            },
            automations: {
                transactionReports: 'Доклади за транзакции',
                refillReports: 'Доклади за презареждане',
                notifications: 'Уведомления',
                refillPlan: 'Планове за презареждане',
                days: 'Дни',
                months: 'Месеци',
                weeks: 'Седмици',
                configuration: 'Конфигурация',
                transactionReportTitle:
                    'Докладите за транзакции за избраните шкафове ще бъдат автоматично генерирани и изпратени на предоставените по-долу имейл адреси',
                refillReportTitle:
                    'Докладите за презареждане за избраните шкафове ще бъдат автоматично генерирани и изпратени на предоставените по-долу имейл адреси',
                noSalesNotificationTitle:
                    'Уведомленията ще бъдат изпращани автоматично на предоставените по-долу имейл адреси в случай на липса на продажби в определените интервали',
                frequencyTitle: 'Колко често искате да се генерира докладът?',
                emailNotificationTitle: 'Моля, добавете имейл адресите по-долу, на които искате да бъде изпратен докладът или уведомлението',
                refillPlanGenerationTitle:
                    'Плановете за презареждане ще бъдат автоматично генерирани за избраните шкафове в съответствие с предоставения график',
                addNewEmail: 'Добавете нов имейл',
                noSalesBanner: 'Ще получите уведомление по имейл, ако избраните шкафове не направят продажби през конфигурирания период',
                noSaleTitle: 'Моля, посочете времевия интервал (в часове) на липса на продажби в избраните шкафове, преди да бъде изпратено уведомление',
                minMaxTime: 'Минимум е 2 и максимум е 23 часа',
                timeRangeTitle: 'Моля, посочете времевия интервал за шкафовете, през който ще бъде проверявана ситуацията с липса на продажби',
                startTime: 'Начален час',
                endTime: 'Краен час',
                refillPlanTitle:
                    'Планът за презареждане за избраните шкафове ще бъде автоматично генериран. Моля, планирайте по-долу конкретното време, когато искате да бъде създаден планът за презареждане.',
                refillPlanTime: 'Моля, изберете времето за генериране на плана за презареждане',
                everyDay: 'Всеки ден',
                everyWeek: 'Всяка седмица',
                everyMonth: 'Всеки месец',
                selectDays: 'Изберете дни',
                automationSaved: 'Настройките за автоматизация бяха запазени успешно',
                automationDeleted: 'Настройките за автоматизация бяха изтрити успешно',
            },
            sleepScheduling: {
                timeRangeTitle: 'Моля, посочете времевия интервал, по време на който този шкаф ще премине в режим на готовност.',
                startTime: 'Начален час',
                endTime: 'Краен час',
                selectDays: 'Изберете дни',
                duration: 'Продължителност (Часове)',
            },
        },
        'de-CH': {
            dateRangeFilter: {
                lastMonth: 'letzte 30 Tage',
                lastTwoWeek: 'letzte 14 Tage',
                lastWeek: 'letzte 7 Tage',
                selectDateRangeMsg: 'Ausgewählter Datumsbereich',
                today: 'heute',
                yesterday: 'gestern',
            },
            main: {
                activateSuccess: 'Das Produkt wurde erfolgreich aktiviert.',
                activationDate: 'Aktivierungsdatum',
                active: 'Aktiv',
                addAdvertisement: 'Neue Werbeeinblendung hinzufügen',
                addDiscountRule: 'Rabattaktion hinzufügen',
                addDiscountStop: 'MHD-Zeitraum hinzufügen',
                addNewLocation: 'Neuen Aufstellort auswählen',
                addProduct: 'Neues Produkt hinzufügen',
                addRegion: 'Aufstellort festlegen',
                addRegionPrice: 'Lokalen Preis hinzufügen',
                addScheduledDiscount: 'Geplanten Rabatt hinzufügen',
                addBundleDiscount: 'Paketrabatt hinzufügen',
                bundledDiscounts: 'Paketrabatte',
                bundleItemsLengthExceeded: 'Die maximale Auswahl an Produkten (3) wurde überschritten. Entfernen Sie Produkte, um fortzufahren.',
                bundleMaxProductLimitInfo: 'Wählen Sie max. drei (3) Produkte oder Produktkategorien aus.',
                bundleFixedAmountExceededOriginal: 'Der Festpreis des Pakets übersteigt die ursprüngliche Preissumme. Prüfen Sie die ausgewählten Produkte.',
                bundleFixedAmountNotAllowedToBeZero: 'Der Paketpreis muss über 0 liegen.',
                bundlePercentageNotAllowedToBeFull: 'Der Rabattprozentsatz des Pakets muss unter 100 liegen.',
                bundlePercentageNotAllowedToBeZero: 'Der Rabattprozentsatz des Pakets muss über 0 liegen.',
                bundleItemDiscountPriceAmountExceededOriginal: 'Der neue reduzierte Preis übersteigt den ursprünglichen Preis. Prüfen Sie den neuen Preis.',
                bundleItemPriceMessage: 'Jede Preisregel gilt nur für 1 Artikel, auch wenn mehrere gleiche Artikel ausgewählt werden.',
                bundleItemFixedAmountExceededOriginal: 'Der Festpreis des Produkts übersteigt den ursprünglichen Produktpreis. Prüfen Sie den Festpreis.',
                createDiscountRule: 'Rabattregel erstellen',
                deviceType: 'Gerätetyp',
                discountUpdateTitle: 'Rabatt aktualisieren',
                discountCreateTitle: 'Rabatt erstellen',
                discountDeleteTitle: 'Rabatt löschen',
                discountDeleteContent: 'Der Rabatt wurde gelöscht.',
                discountStatusUpdated: 'Der Rabattstatus wurde aktualisiert.',
                discountUpdated: 'Der Rabatt wurde aktualisiert.',
                discountCreated: 'Der Rabatt wurde erstellt.',
                discountSettings: 'Rabatt-Einstellungen',
                discountImage: 'Optionales Rabattbild',
                editDiscountStop: 'MHD-Zeitraum bearbeiten',
                editBundleConfiguration: 'Paket-Konfiguration bearbeiten',
                editExpiryConfiguration: 'Verfallsrabatteinstellung bearbeiten',
                encodingStation: 'Kodierstation',
                everyDay: 'Täglich',
                scheduledDiscountDateInfoLabel: 'Bitte lassen Sie das Anfangs- und Enddatum leer, wenn Sie den Rabatt dauerhaft gewähren möchten.',
                starts: 'Beginnt',
                ends: 'Endet',
                error: 'Fehler',
                errorRetiringLocationMsg:
                    'Der Standort konnte nicht deaktiviert werden. Bitte entfernen Sie zunächst verlinkte Geräte, Produktpreise und Werbung.',
                fetchingDiscountRules: 'Die Rabattaktionen werden abgerufen.',
                free: 'Kostenlos',
                missingImage: 'Fehlendes Werbebild',
                next: 'Weiter',
                back: 'Zurück',
                noDiscountRules: 'Keine Rabattaktionen gefunden',
                scheduledDiscountsAutomaticDeactivationInfo:
                    'Rabatte, bei denen der Zeitraum überschritten wurde, werden automatisch deaktiviert. Um Sie zu aktivieren, bearbeiten Sie den gültigen Datumsbereich.',
                scheduledDiscounts: 'Geplante Rabatte',
                schedulingStartHourIsOverEndHour: 'Der Startzeitpunkt darf nicht nach dem Endzeitpunkt liegen.',
                schedulingEndHourIsUnderStartHour: 'Der Endzeitpunkt darf nicht vor dem Startzeitpunkt liegen.',
                selectDiscountRuleProductCategories: 'Wählen Sie die von der Rabattaktion betroffenen Produktkategorien aus.',
                selectBundleProductsAmount: 'Betrag auswählen für: ',
                selectedBundleProductCategories: 'Ausgewählte Paket-Produktkategorien',
                setDiscountDays: 'Rabatttage festlegen',
                setBundleDiscount: 'Prozentualen Rabattbetrag oder Festpreis für das Paket festlegen',
                setBundleItemDiscount: 'Wählen Sie eins aus: prozentualen Rabattbetrag, Festpreis oder kostenlos',
                setBundleProducts: 'Produkte oder Kategorien für das Paket festlegen',
                setAmountMoreThanOne: 'Legen Sei bei einem Paket mit einem Artikel eine Menge von mehr als eins fest.',
                setBundleDiscountType: 'Legen Sie die Rabattart und den Betrag fest.',
                sureActivateDiscountRule: 'Möchten Sie die Rabattaktion wirklich aktivieren?',
                sureDeactivateDiscountRule: 'Möchten Sie die Rabattaktion wirklich deaktivieren?',
                sureDeleteDiscountRule: 'Möchten Sie die Rabattaktion wirklich löschen?',
                timeRange: 'Zeitspanne',
                editExpiryDiscountRule: 'Rabattaktion bearbeiten',
                editScheduledDiscountRule: 'Geplante Rabattregel bearbeiten',
                editBundleDiscountRule: 'Bündelrabattregel bearbeiten',
                releaseNotes: 'Versionshinweise',
                seeMore: 'Weitere anzeigen',
                scheduledDiscountTimeRangeLabel: 'Aktive Zeit',
                scheduledDiscountDateRangeLabel: 'Datumsbereich des Rabatts',
                weekDayAbrvMonday: 'Mo',
                weekDayAbrvTuesday: 'Di',
                weekDayAbrvWednesday: 'Mi',
                weekDayAbrvThursday: 'Do',
                weekDayAbrvFriday: 'Fr',
                weekDayAbrvSaturday: 'Sa',
                weekDayAbrvSunday: 'So',
                recurrence: 'Wiederholung',
                addScheduledDiscountStop: 'Geplanten Rabatt hinzufügen',
                editScheduledDiscountStop: 'Geplanten Rabatt bearbeiten',
                pieces: 'Stück',
                expiryDiscountNavLabel: 'MHD',
                scheduledDiscountNavLabel: 'Geplant',
                bundleDiscountNavLabel: 'Paket',
                amount: 'Menge',
                scheduling: 'Planung',
                pricing: 'Preisgestaltung',
                percentageBundleDiscountInputPlaceholder: '% Rabatt für das Paket',
                fixedPriceBundleDiscountInputPlaceholder: 'Festpreis für das Paket',
                applyBundleLevelLabel: 'Paketebene anwenden',
                applyItemLevelLabel: 'Artikelebene anwenden',
                productFixedPrice: 'Festpreis des Produkts',
                percentageDiscount: '% Rabatt',
                fixedPriceDiscount: 'Festpreis',
                freeDiscount: 'Kostenlos',
                advertisementsFetchError:
                    'Beim Herunterladen der Werbeeinblendungen ist ein Fehler aufgetreten. Stellen Sie sicher, dass der "Ad-Blocker" abgeschaltet ist.',
                advertisementsTitle: 'Werbeeinblendungen',
                all: 'Alle',
                'All time': 'Ganze Zeit',
                allProducts: 'Alle Produkte ohne Gruppierung',
                allLocations: 'Alle Aufstellorte',
                allCabinets: 'Alle Kühlschränke',
                antennaDown: 'Einige RFID-Antennen sind ausser Betrieb.',
                antennaError: 'Fehlfunktion bei den Kühlschrankantennen',
                antennaNoInfo: 'Keine Information über die Kühlschrankantennen verfügbar',
                antennaOk: 'Alle Antennen sind funktionsfähig.',
                antennaPartial: 'Einige Antennen sind deaktiviert.',
                archiveSuccess: 'Das Produkt wurde erfolgreich archiviert.',
                averageItems: 'Durchschnittliche Anzahl an Produkten pro Transaktion',
                averageSales: 'Durchschnittlicher Verkaufswert pro Transaktion',
                barcode: 'Barcode',
                barcodeType: 'Barcode-Typ',
                barcodeCannotBeChanged: 'Der Barcode kann nicht geändert werden.',
                barcodeTypeCannotBeChanged: 'Der Barcode-Typ kann nicht geändert werden.',
                barcodeHelpText12: 'Geben Sie den zwölfstelligen Barcode ein; die letzte Ziffer wird automatisch erzeugt.',
                barcodeHelpText11: 'Geben Sie den elfstelligen Barcode ein; die letzte Ziffer wird automatisch erzeugt.',
                barcodeHelpText7: 'Geben Sie den siebenstelligen Barcode ein; die letzte Ziffer wird automatisch erzeugt.',
                beforeExpiry: 'vor dem MHD',
                cabinetDown: 'Der Kühlschrank ist ausser Betrieb.',
                cabinetIsEmpty: 'Der Kühlschrank ist leer.',
                deviceOffline: 'Das Gerät ist offline',
                deviceOnline: 'Das Gerät ist online',
                cabinets: 'Kühlschränke',
                cabinet: 'Kühlschrank',
                cabinetSettings: 'Kühlschrankeinstellungen',
                cabinetTheming: 'Schrankthema',
                cancel: 'Abbrechen',
                cancelledSales: 'Abgebrochene Käufe',
                cancelledTransactions: 'Abgebrochene Transaktionen',
                categories: 'Kategorien',
                change: 'Ändern',
                clear: 'Löschen',
                close: 'Schliessen',
                clickEditButtonRegionalPrices: 'Klicken Sie auf "Bearbeiten", um die Preise am Aufstellort zu ändern.',
                clickEditLocationPrices: 'Klicken Sie auf den Produktpreis, um die lokalen Preise anzuzeigen.',
                clickToExpandLocationPrices: 'Klicken Sie auf den Produktpreis, um die lokalen Preise anzuzeigen.',
                closeDoor: 'Schliessen Sie die Kühlschranktür.',
                rebootDevice: 'Starten Sie das Gerät neu.',
                commandSentSuccessFully: 'Der Befehl wurde erfolgreich gesendet.',
                companyLogo: 'Firmenlogo',
                confirm: 'Bestätigen',
                confirmDelete: 'Möchten Sie das Produkt wirklich löschen? Diese Aktion kann nicht rückgängig gemacht werden!',
                confirmDisable: 'Möchten Sie das Produkt wirklich archivieren?',
                confirmEnable: 'Möchten Sie das Produkt wirklich aktivieren?',
                consumerResponses: 'Benutzerfeedback',
                controlPanelTitle: 'Bedienfeld',
                controls_acceptablePercentageOfBadTags: 'Zulässiger Prozentsatz fehlerhafter RFID-Tags, bevor der Schrank ausser Betrieb geht.',
                controls_markTagsGoodQuality: 'Alle Tags als qualitativ gut markieren',
                controls_markTagsGoodQuality_btn: 'Tags mit guter Qualität markieren',
                controls_clearTagHistory: 'Verlaufsdaten der RFID-Tags löschen',
                controls_clearRemoveReplenishmentData: 'Nachfülldaten löschen',
                controls_companyAndPaymentLogo: 'Logo der Firma und der Bezahlmethode',
                controls_detectTagQuality: 'RFID-Tag-Qualität ermitteln',
                controls_door: 'Türsteuerung',
                controls_device: 'Gerätesteuerung',
                controls_chargeBadTags: 'Schlechte Tags belasten',
                controls_refill: 'Nachfüllen',
                controls_tagQuality: 'RFID Tag-Einstellungen',
                controls_tagQualityData: 'RFID Tag-Qualitätsdaten',
                controls_replenishmentData: 'Nachfülldaten',
                controls_soldTags: 'Verkaufte Tags',
                controls_tagHistory: 'RFID Tag-Verlauf',
                controls_markAllTagsReplenished: 'Alle nachgefüllten Tags markieren',
                controls_markAllTagsUnsold: 'Alle unverkauften Tags markieren',
                controls_markAlTagsReplenished_btn: 'Nachgefüllte Tags markieren',
                controls_markAllTagsUnsold_btn: 'Unverkaufte Tags markieren',
                controls_chargeSoldTags: 'Verkaufte Tags belasten',
                controls_chargeUnreplenishedTags: 'Nicht nachgefüllte Tags belasten',
                controls_tagsWarn:
                    'Beim Löschen der Tag-Historie werden alle Daten der Tags entfernt. Bitte klicken Sie auf "Alle Tags nach dieser Aktion als wiederaufgefüllt markieren',
                create: 'Erstellen',
                createAdTitle: 'Neue Werbeeinblendung',
                createdAd: 'Die Werbeeinblendung wurde erstellt.',
                createExpiryDiscountRule: 'Verfallsrabattregel erstellen',
                createScheduledDiscountRule: 'Geplante Rabattregel erstellen',
                createBundleDiscountRule: 'Rabattaktion erstellen',
                createNewVatCategory: 'Neue MwSt.-Kategorie',
                createProductTitle: 'Neues Produkt',
                creatingAd: 'Werbeeinblendung wird erstellt',
                creatingProduct: 'Produkt wird erstellt',
                createNewProductCategory: 'Neue Produktkategorie erstellen',
                createNewSupplier: 'Neuen Anbieter hinzufügen',
                currency: 'Währung',
                customQuery: 'Kundenanfrage',
                dashboard: 'Dashboard',
                dateRange: 'Datumsbereich',
                dataAlredyFetchedTitle: 'Die Daten wurden bereits abgerufen.',
                dataAlreadyFetchedDescription: 'Wählen Sie unterschiedliche Parameter aus.',
                day: 'Tag',
                days: 'Tage',
                deactivationDate: 'Deaktivierungstag',
                default: 'Standardeinstellung',
                delete: 'Löschen',
                deleteError: 'Fehler: Das Produkt konnte nicht gelöscht werden.',
                deleteSuccess: 'Das Produkt wurde erfolgreich gelöscht.',
                description: 'Beschreibung',
                deviceManagement: 'Gerätemanagement',
                digitwinOffline: 'Der digitale Zwilling des Geräts ist offline',
                digitwinOnline: 'Der digitale Zwilling des Geräts ist online',
                dimensions: 'Abmessungen',
                disable: 'Archivieren',
                discountAmount: 'Rabattwert',
                discountPrice: 'Rabattpreis',
                discountRuleAffects: 'Die Rabattaktion betrifft',
                discountRuleName: 'Name der Rabattaktion',
                discountRuleStops: 'MHD-Zeiträume',
                discounts: 'Rabatte',
                discountSalesData: 'Rabatt-Verkaufsdaten',
                discountSalesAmount: 'Rabatt-Verkaufssumme',
                discountTimeBeforeExpiryExists: 'Es gibt bereits einen früheren MHD-Zeitraum',
                discountVsNormalSales: 'Rabatt im Vergleich zum normalen Preis',
                dismiss: 'Verwerfen',
                doesNotExist: 'Existiert nicht',
                doorCloseConfirm: 'Möchten Sie die Tür wirklich schliessen?',
                doorCloseSuccess: 'Die Tür wurde erfolgreich verriegelt.',
                doorOpenConfirm: 'Möchten Sie die Tür wirklich öffnen?',
                doorOpenReason: 'Warum öffnen Sie die Tür?',
                doorOpenSuccess: 'Die Tür wurde erfolgreich geöffnet.',
                rebootDeviceSuccess: 'Das Gerät wurde erfolgreich neu gestartet.',
                deviceRebootConfirm: 'Möchten Sie das Gerät wirklich neu starten?',
                edit: 'Bearbeiten',
                editCompanyLogo: 'Firmenlogo bearbeiten',
                editDiscountRule: 'Rabattregel bearbeiten',
                editPaymentMethodLogo: 'Logo der Bezahlmethode ändern',
                editVatCategoryModalTitle: 'MwSt.-Kategorie ändern',
                editProductCategoryModalTitle: 'Produktkategorie bearbeiten',
                editSupplierModalTitle: 'Anbieter bearbeiten',
                enable: 'Aktivieren',
                error_401: 'Nicht authentifiziert',
                error_403: 'Keine Berechtigung zum Ausführen des Befehls',
                error_500: 'Unerwarteter Server-Fehler',
                errorCreatingAd: 'Fehler beim Erstellen der Werbeeinblendung',
                errorCreatingProduct: 'Fehler bei der Produkterstellung',
                errorFetchingCabinetStatus: 'Fehler beim Abrufen des Kühlschrankstatus',
                errorFetchingInventory: 'Fehler beim Abrufen des Kühlschrankbestands',
                errorFetchingReport: 'Fehler beim Abrufen der Berichte',
                errorFetchingSettings: 'Fehler beim Abrufen der Einstellungen',
                errorUpdatingProduct: 'Fehler beim Aktualisieren des Produkts',
                expandToSeeBadTags:
                    'Vergrössern Sie die Liste, um fehlerhafte RFID-Tags anzuzeigen. Fehlerhafte RFID-Tags müssen aus dem Kühlschrank entfernt werden.',
                expandToSeeSoldItems: 'Vergrössern Sie die Liste, um bereits verkaufte Produkte, die jedoch erneut im Bestand auftauchen, anzuzeigen.',
                expandToSeeReplenishedOutOfInventory:
                    'Vergrössern Sie die Liste, um die Produkte anzuzeigen, die ohne Bestandsauffüllung in den Schrank gestellt wurden.',
                expired: 'Abgelaufen',
                expiredCount: 'Abgelaufene Produkte',
                expiredProductsCount: 'Anzahl der abgelaufenen Produkte',
                expiredSum: 'Summe der abgelaufenen Produkte',
                expiring: 'Kurz vor dem MHD',
                expiryDate: 'Letzter Verkaufstag',
                exportExcel: 'In Excel-Datei exportieren',
                fetchAdvertisements: 'Werbeeinblendungen werden abgerufen',
                fetchingCabinets: 'Kühlschränke werden abgerufen',
                fetchingCabinetsError: 'Fehler beim Abrufen der Kühlschränke',
                fetchingCabinetStatus: 'Kühlschrankstatus wird abgerufen',
                fetchingError: 'Fehler beim Abrufen',
                fetchingInventory: 'Kühlschrankbestand wird abgerufen',
                fetchingProducts: 'Produkte werden abgerufen',
                fetchingReport: 'Berichte werden abgerufen',
                fetchingVatCategories: 'MwSt.-Kategorien werden abgerufen',
                fetchingProductCategories: 'Produktkategorien werden abgerufen',
                fillForm: 'Formular ausfüllen',
                freshFood: 'Dies ist ein ablaufendes Produkt',
                freezerPackage: 'Tiefkühlprodukt',
                generalFetchError: 'Fehler beim Abrufen',
                generalRequestReject: 'Anfrage-Fehler',
                get: 'Holen',
                group: 'Gruppe',
                holdOn: 'Warten',
                hour: 'Stunde',
                hours: 'Stunden',
                hoursBeforeExpiry: 'Stunden vor MHD',
                daysBeforeExpiry: 'Tage vor Ablauf der Mindesthaltbarkeit',
                image: 'Bild',
                imageOrVideo: 'Wählen Sie ein Bild oder Video aus.',
                inActive: 'Inaktiv',
                individualCabinets: 'Einzelne Kühlschränke',
                individualCabinetsOnly: 'Nur einzelne Kühlschränke',
                invalidBarcodeOnlyDigits: 'Der Barcode darf nur numerische Ziffern enthalten.',
                invalidBarcodeTooLong: 'Der Barcode ist zu lang.',
                invalidBarcodeTooShort: 'Der Barcode ist zu kurz.',
                invalidCabinetNameTooShort: 'Der Kühlschrankname ist zu kurz.',
                invalidCabinetNameDuplicated: 'Der Kühlschrankname existiert bereits.',
                invalidLocationNameTooShort: 'Der Name des Aufstellorts ist zu kurz.',
                invalidLocationNameDuplicated: 'Der Ort existiert bereits.',
                invalidPercentage: 'Ungültiger Wert',
                invalidPrice: 'Ungültiger Preis',
                invalidProductBarcode: 'Ungültiger Produkt-Barcode',
                invalidTitle: 'Ungültiger Titel',
                invalidBundleItems: 'Das Paket enthält ungültige Artikel.',
                inventoryLength: 'Lagerbestand',
                inventoryNotReported: 'Der Kühlschrank hat den Lagerbestand nicht gemeldet.',
                inventoryTitle: 'Kühlschrankbestand',
                'Inventory History': 'Lagerbestandsverlauf',
                itemReplenishedOutOfInventoryProcess: 'Dieses Produkt bzw. dieser RFID-Tag wurde nicht in einem Nachfüllverfahren in den Schrank gestellt.',
                itemSoldOn: 'Artikel weiterverkauft',
                'Last 30 days': 'Letzte 30 Tage',
                'Last 7 days': 'Letzte 7 Tage',
                'Last month': 'Letzter Monat',
                'Last quarter': 'Letztes Quartal',
                'Last week': 'Letzte Woche',
                'Last year': 'Letztes Jahr',
                lastUpdate: 'Letzte Aktualisierung',
                lifetime: 'Haltbarkeit',
                location: 'Aufstellort',
                locations: 'Aufstellorte',
                logoEditor: 'Logo Editor',
                logoSettings: 'Logo-Einstellungen',
                loading: 'Wird geladen …',
                measure: 'Messen',
                measures: 'Messungen',
                minsBeforeExpiry: 'Minuten vor Ablauf des MHD',
                minutes: 'Minuten',
                month: 'Monat',
                name: 'Name',
                newVatCategoryModalTitle: 'Neue MwSt.-Kategorie',
                newProductCategoryModalTitle: 'Neue Produktkategorie',
                newSupplierModalTitle: 'Neuer Anbieter',
                'no region': '(kein Aufstellort)',
                noAdvertisements: 'Keine Werbeeinblendungen',
                noCabinets: 'Keine Kühlschränke',
                noCabinetSelected: 'Es wurde kein Kühlschrank ausgewählt.',
                noLogo: 'Kein Logo',
                noData: 'Keine Daten',
                noDescription: 'Keine Beschreibung',
                noEvents: 'Keine Ereignisse!',
                noIndividualCabinets: 'Keine einzelnen Kühlschränke',
                noLocationPrices: 'Für dieses Produkt sind keine lokalen Preise festgelegt.',
                none: 'Keine',
                noPaymentMethodLogo: 'Kein Logo für die Zahlungsmethode',
                noProducts: 'Keine Produkte',
                noProductsRemoved: 'Es wurden Produkte entfernt.',
                noProductTypeSelected: 'Es wurde kein Pakettyp ausgewählt.',
                normalPrice: 'Normaler Preis',
                normalSalesAmount: 'Normaler Gesamtpreis',
                notAccessibleForYou:
                    'Dieses Feature ist nur für Administratoren und Kühlschrankbetreiber freigeschalten. Kontaktieren Sie den Systemadministrator, um Zugriff zu erhalten.',
                notDefined: 'Nicht definiert',
                noTitle: 'Kein Titel',
                noVatCategories: 'Keine Daten zur MwSt.-Kategorie gefunden',
                noProductCategories: 'Keine Daten zur Produktkategorie gefunden',
                noInventoryOnSelectedDate: 'Für das von Ihnen ausgewählte Datum wurde kein Bestand gemeldet. Versuchen Sie es mit einem anderen Datum.',
                openDoor: 'Öffnen Sie die Tür.',
                orderSum: 'Bestellsumme',
                paymentMethodLogo: 'Logo der Bezahlmethode',
                percentage: 'Prozent',
                permanent: 'Dauerhaft',
                preview: 'Vorschau',
                price: 'Preis',
                print: 'Drucken',
                productCreated: 'Das Produkt wurde erstellt.',
                products: 'Produkte',
                productsFetchError: 'Fehler beim Abrufen der Produkte',
                productsTitle: 'Produktkatalog',
                productType: 'Pakettyp',
                productUpdated: 'Das Produkt wurde aktualisiert.',
                quantity: 'Anzahl',
                readerDown: 'Das RFID-Lesegerät ist ausser Betrieb.',
                region: 'Aufstellort',
                regions: 'Aufstellorte',
                removedAt: 'Entfernt von',
                removeImage: 'Bild entfernen',
                removeVideo: 'Video entfernen',
                refillQRCodeInfo: 'Scannen Sie den QR-Code, um die Nachfüllanwendung zu öffnen oder zu verfolgen.',
                refillQRCodeTitle: 'QR-Code nachfüllen',
                refillRuleTitle: 'Nachfüllregel',
                reportsTitle: 'Berichte',
                requestSuccess: 'Die Änderungen wurden erfolgreich durchgeführt.',
                requiredPercentage: 'Erforderlich. Prozentwert ohne Prozentzeichen',
                requiredText: 'Erforderliche Zeichenlänge: ',
                retireLocation: 'Aufstellort deaktivieren',
                rfidNoInfo: 'Es sind keine Informationen über das RFID-Lesegerät und die Antennen verfügbar.',
                rfidOk: 'Das RFID-Lesegerät und die Antennen funktionieren ordnungsgemäss.',
                sales: 'Verkäufe',
                salesCount: 'Verkaufszahl',
                salesVsWasteAmount: 'Verkaufs- im Vergleich zur Ausschussmenge',
                save: 'Speichern',
                search: 'Suchen',
                selectCabinet: 'Wählen Sie einen Kühlschrank aus.',
                selectDiscountRuleCabinets: 'Wählen Sie Kühlschränke aus, die von der Rabattaktion betroffen sind.',
                selectDiscountRuleLocations: 'Wählen Sie Aufstellorte aus, die von der Rabattaktion betroffen sind.',
                selectDiscountRuleProducts: 'Wählen Sie Produkte aus, die von der Rabattaktion betroffen sind.',
                selectProductType: 'Bitte wählen Sie den Verpackungstyp für Ihr Produkt aus',
                setDiscountAmount: 'Legen Sie den Rabattwert fest.',
                setDiscountTimeBeforeExpiry: 'MHD-Zeitraum festlegen',
                settingConfirmMessage: 'Möchten Sie diese Einstellungen wirklich speichern? Wenn Sie sich nicht sicher sind, drücken sie nicht auf Bestätigen.',
                showDisable: 'Archivierte Produkte',
                summary: 'Zusammenfassung',
                sureRemoveDiscountRule: 'Möchten Sie die Rabattaktion wirklich löschen?',
                tagIsBad: 'Der RFID-Tag ist fehlerhaft und muss aus dem Kühlschrank entfernt werden.',
                tagsRemovedReport: 'Bericht über entfernte RFID-Tags',
                taxValue: 'MwSt.-Wert',
                'This month': 'Dieser Monat',
                'This quarter': 'Dieses Quartal',
                'This week': 'Diese Woche',
                'This year': 'Dieses Jahr',
                thisLink: ' diesen Link.',
                timeGrouping: 'Gruppierung nach Zeit',
                title: 'Titel',
                Today: 'Heute',
                topExpiredProducts: 'Top abgelaufene Produkte',
                topProducts: 'Top Produkte',
                total: 'Gesamt',
                totalValue: 'Gesamtwert',
                transactions: 'Transaktionen',
                ttlInDays: 'Haltbarkeit des Produkts in Tagen',
                ttlsAffected: 'Betroffene Produkte mit MHD',
                unexpectedError: 'Unerwarteter Fehler',
                uniqueCustomers: 'Einzelne Kunden',
                unknown: 'Unbekannt ',
                update: 'Update',
                updateAdError: 'Fehler beim Aktualisieren der Werbeeinblendung',
                updateAdTitle: 'Werbeeinblendung aktualisieren',
                updatedAd: 'Die Werbeeinblendung wurde aktualisiert.',
                updateCabinetSettings: 'Daten mit dem Schrank synchronisieren',
                updateCabinetSettingsInfo: 'Möchten Sie die Schrankeinstellungen wirklich aktualisieren?',
                updateData: 'Daten aktualisieren',
                updateProductTitle: 'Produkt aktualisieren',
                updatingAd: 'Werbeeinblendung aktualisieren',
                updatingProduct: 'Das Produkt wird aktualisiert …',
                uploading: 'Hochladen ',
                uploadingImage: 'Das Bild wird hochgeladen',
                use: 'Verwenden',
                'w/o grouping': 'Keine Gruppierung',
                waste: 'Ausschuss',
                vatCategoriesFetchError: 'Fehler beim Abrufen der MwSt.-Kategorien',
                vatCategoriesTitle: 'MwSt.-Kategorien',
                vatCategory: 'MwSt.-Kategorie',
                vatCategoryItems: 'Artikel',
                vatCategoryName: 'MwSt.-Kategoriebezeichnung',
                vatCategoryPromptMessage: 'Möchten Sie die MwSt.-Kategorie wirklich löschen?',
                vatCategoryValue: 'MwSt.-Wert in Prozent',
                vatDeleteRejected: 'Die MwSt.-Kategorie konnte nicht gelöscht werden. Prüfen Sie die verknüpften Produkte.',
                vatErrorRequesting: 'Fehler beim Bearbeiten der Anfrage',
                vatRequestRejected: 'Anfrage zurückgewiesen',
                vats: 'MwSt.',
                productCategoriesFetchError: 'Fehler beim Abrufen der Produktkategorien',
                productCategoriesTitle: 'Produktkategorien',
                productCategory: 'Produktkategorie',
                productCategoryItems: 'Kategorien',
                productCategoryName: 'Name der Produktkategorie',
                productCategoryPromptMessage: 'Möchten Sie die Produktkategorie wirklich löschen?',
                productCategoryDescription: 'Beschreibung der Produktkategorie',
                productCategoryHelpTextDescription: 'z.B. Kategorie Salate',
                productCategoryHelpTextName: 'z.B. Salate',
                productCategoryDeleteRejected: 'Die Produktkategorie konnte nicht gelöscht werden. Prüfen Sie die verknüpften Produkte.',
                week: 'Woche',
                viewMode: 'Ansicht',
                year: 'Jahr',
                Yesterday: 'Gestern',
                groupBy: 'Gruppieren nach',
                filterBy: 'Filtern nach',
                reportType: 'Berichttyp',
                custom: 'Benutzerdefiniert',
                preDefined: 'Vordefiniert',
                selected: 'Ausgewählt',
                metricsInfo: 'Metriken (Summe, Maximum, Minimum...) werden für alle ausgewählten Abmessungen/Quellen berechnet.',
                dimensionsInfo: 'Metriken werden für die ausgewählten Abmessungen/Quellen abgerufen.',
                metrics: 'Metriken',
                controlsSoftwareVersion: 'Softwareversion',
                currentSoftwareVersion: 'Aktuelle Softwareversion',
                availableSoftwareVersion: 'Verfügbare Softwareversion',
                invalidLifetime: 'Ungültiges MHD',
                unpaidSales: 'Unbezahlte Verkäufe',
                unpaidTransactions: 'Unbezahlte Transaktionen',
                softwareUpdateConfirmation:
                    'Sind Sie sicher, dass Sie eine Aktualisierung der Softwareversion auf den ausgewählten Geräten durchführen möchten?',
                softwareUpdateWarn:
                    'Beim Aktualisieren der Softwareversion wird ein Befehl an die entsprechenden Geräte gesendet, um das Update sofort oder zu einem festgelegten Zeitpunkt herunterzuladen. Das Update wird auf den ausgewählten Geräten installiert, sobald diese nach dem Befehl online sind. Die Installation kann je nach Verbindungsgeschwindigkeit einige Zeit in Anspruch nehmen. Die Geräte werden nach der Installation neu gestartet.',
                releaseVersion: 'Verfügbare Softwareversionen',
                releaseDate: 'Datum der Veröffentlichung',
                latestRelease: 'Letzte Veröffentlichung',
                currentVersion: 'Aktuelle Softwareversion',
                softwareUpdateSuccess: 'Die Softwareversion wurde aktualisiert.',
                prebooking: 'Vorbestellung',
                prebookedProduct: 'Vorbestelltes Produkt',
                prebookedProducts: 'Vorbestellte Produkte',
                activeProducts: 'Aktive Produkte',
                startMaintenanceMode: 'Wartungsmodus starten',
                endMaintenanceMode: 'Wartungsmodus beenden',
                startMaintenanceConfirm: 'Möchten Sie den Wartungsmodus des Kühlschranks wirklich starten?',
                endMaintenanceConfirm: 'Möchten Sie den Wartungsmodus des Kühlschranks wirklich beenden?',
                startMaintenanceSuccess: 'Der Wartungsmodus wurde gestartet.',
                endMaintenanceSuccess: 'Der Wartungsmodus wurde beendet.',
                noResults: 'Keine Ergebnisse',
                suppliers: 'Lieferanten',
                supplier: 'Lieferant',
                supplierDeleteRejected: 'Der Lieferant konnte nicht gelöscht werden. Prüfen Sie die verknüpften Produkte!',
                supplierPromptMessage: 'Möchten Sie den Lieferanten wirklich löschen?',
                fetchingSuppliers: 'Lieferanten werden abgerufen',
                noSuppliers: 'Kein Lieferantendatensatz gefunden',
                suppliersFetchError: 'Fehler beim Abrufen der Lieferanten',
                supplierName: 'Name des Lieferanten',
                supplierHelpTextName: 'z.B. ein Markenname',
                supplierDescription: 'Beschreibung des Lieferanten',
                supplierHelpTextDescription: 'z.B. Beschreibung einer Marke',
                wishes: 'Wünsche',
                checkNow: 'Jetzt prüfen',
                startTimeOn: 'Startzeit am',
                endTimeOn: 'Endzeit am',
                signOutConfirmationModalTitle: 'Abmeldung bestätigen',
                signOutConfirmationModalContent: 'Möchten Sie sich wirklich abmelden?',
                startDate: 'Startdatum',
                endDate: 'Enddatum',
                scheduledDiscountApplyAllDays: 'An allen Tagen gültig',
                scheduledDiscountApplySelectedDays: 'An ausgewählten Tagen gültig',
                discountCustomImageTitle: 'Rabattbild (optional)',
                discountCustomImageModalHeader: 'Rabattbild',
                saveChanges: 'Änderungen speichern',
                updateTagsQualitySettingsTitle: 'Tag-Qualitätseinstellungen',
                updateTagsQualitySettingsMessage: 'Aktualisierte Tag-Qualitätseinstellungen',
                IF3Cabinets: 'Modell 3 Kühlschränke',
                IF2Cabinets: 'Modell 2 Kühlschränke',
                IF3Freezers: 'Modell 3 Gefrierschränke',
                encodingStations: 'Kodierstationen',
                if2: 'Modell 2 Kühlschrank',
                if3: 'Modell 3 Kühlschrank',
                if3Freezer: 'Modell 3 Gefrierschrank',
                legalNotice: 'Rechtlicher Hinweis',
                privacyPolicy: 'Datenschutzbestimmungen',
                scheduledDiscountDateMissingError: 'Beide Daten werden benötigt, wenn Start- oder Enddatum festgelegt werden.',
                scheduledDiscountDuplicateTimeError: 'Die Uhrzeit darf nicht gleich sein, wenn das Datum gleich ist.',
                now: 'Jetzt',
                minimum: 'Minimum',
                maximum: 'Maximum',
                discardChangesConfirmationText: 'Möchten Sie die Änderungen wirklich speichern?',
                exports: 'Exporte',
                selectAllCabinets: 'Alle Schränke auswählen',
                expiryIntervals: 'MHD-Zeiträume',
                timeBeforeExpiry: 'Zeit vor Ablauf der Mindesthaltbarkeit',
                apply: 'Anwenden',
                yes: 'Ja',
                no: 'Nein',
                productOnScreenBannerText: 'Info-Bannertext auf dem Bildschirm',
                productOnScreenBannerAdditionalInfo:
                    'Der Bannertext ist ein einzeiliger Text, der unterhalb des Produktbildes erscheint und die wichtigsten Merkmale, Gesundheitshinweise oder weitere Informationen zu dem Produkt enthält.',
                productOnScreenBannerExampleText: 'Laktosefrei',
                UI_IS_DOWN: 'Schrankanzeige funktioniert nicht',
                READER_IS_DOWN: 'RFID-Leser ist ausgeschaltet',
                ANTENOR_DEVICE_DISCONNECTED: 'Zahlungsgerät ist nicht verbunden',
                EXPIRED_PRODUCT: 'Abgelaufene Produkte im Schrank',
                CLOUD_SERVICE_DOWN: 'Cloud-Dienst nicht verfügbar',
                ANTENNA_DISCONNECTED: 'Antenne ist abgetrennt',
                TAG_CHANGES: 'Zu viele defekte Tags im Schrank',
                ANTENNA_DOWN: 'RFID-Antenne im Schrank funktioniert nicht',
                READER_CONFIG_ERROR: 'Konfiguration des RFID-Lesers fehlgeschlagen und er funktioniert nicht',
                activeAlarms: 'Aktive Alarme',
                deviceStatus: 'Gerätestatus',
                lastMonitored: 'Zuletzt überwacht',
                noService: 'Ausser Betrieb',
                operational: 'Operativ',
                offline: 'Offline',
                locationsSelectHelp: 'Wenn ein Standort hinzugefügt wird, werden auch seine untergeordneten Standorte hinzugefügt.',
                select: 'Wählen...',
                labels: 'Etikette',
                productDetails: 'Produktdetails',
                invalidProductName: 'Der Produktname sollte zwischen 1 und 125 Zeichen lang sein',
                standardPrice: 'Standardpreis',
                locationPrices: 'Standortpreise',
                addNew: 'Neu hinzufügen',
                productExpiry: 'Produktablauf',
                productLifeTime: 'Produktlebensdauer',
                invalidTTL: 'Die Produktlebensdauer sollte eine ganze Zahl von 0 bis 32767 sein',
                productImage: 'Produktbild',
                uploadImage: 'Bild hochladen',
                pleaseUploadImage: 'Bitte laden Sie ein Bild hoch',
                productValidationError: 'Bitte beheben Sie die folgenden Fehler, bevor Sie die Produktdaten speichern',
                productSupplier: 'Produktlieferant',
                frozenProductLable: 'Das ist ein Tiefkühlprodukt',
                prebookingLabel: 'Das ist ein Vorbestellungsprodukt',
                previous: 'Zurück',
                bannerText: 'Bannertext',
                packagingTypeValidationError: 'Bitte wählen Sie eine Verpackungsart für das Produkt',
                showOnlyFrozen: 'Nur Tiefkühlprodukte anzeigen',
                productClassification: 'Produktklassifikation',
                productClassLabel:
                    'Bitte wählen Sie die entsprechende Klasse für das Produkt. Dies ermöglicht es Selfly Insights, relevantere Produktinformationen bereitzustellen.',
                productLabels: 'Produktetiketten',
                productLabelsHelpText: 'Bitte fügen Sie Ihrem Produkt optionale Etiketten hinzu. Dadurch können Sie Ihre Produkte im Produktkatalog filtern.',
                newLabel: 'Neue Etikette',
                productClassValidationError: 'Bitte wählen Sie eine Klassifizierung für Ihr Produkt aus den unten stehenden Optionen',
                tagSensitivity: 'Etikettenqualitätsempfindlichkeit',
                tagSensitivitySetting: 'Einstellung der Etikettenqualitätsempfindlichkeit',
                configTagSensitivity: 'Konfigurieren der Etikettenqualitätsempfindlichkeit für das Produkt',
                productLevelSensitivityInfo:
                    'Die Lesbarkeit eines an einem Produkt angebrachten RFID-Tags kann durch verschiedene Faktoren beeinflusst werden, einschließlich Verpackungsmaterialien, Metalloberflächen, Feuchtigkeit und sogar durch den Inhalt des Produkts selbst. Diese Faktoren können gelegentlich zu intermittierenden Tag-Signalen führen, bekannt als Flimmern. Der Schrank verarbeitet solches Flimmern basierend auf seinen Empfindlichkeitseinstellungen. Wenn Sie unsicher sind, welche Empfindlichkeitsstufe für dieses Produkt geeignet ist, können Sie diesen Abschnitt überspringen.',
                errorSaving: 'Fehler beim Speichern der Produktdaten',
                successSaving: 'Produktdaten erfolgreich gespeichert',
                basicDetail: 'Grundlegende Details',
                additionalDetail: 'Zusätzliche Details',
                packaging: 'Verpackungsart',
                classification: 'Klassifikation',
                selectOrtype: 'Wählen oder schreiben...',
                max25Chars: 'max. 25 Zeichen',
                noVatForProductCreation: 'Bitte erstellen Sie eine Mehrwertsteuerkategorie, bevor Sie neue Produkte hinzufügen',
                lifeTimeExplanation: 'Wie viele Tage ist dieses Produkt zum Verkauf gültig. (0 Tage) bedeutet bis zum Ende des aktuellen Tages',
                selflyEncode: 'Selfly Encode',
                userRestrict: 'Nur erkannte Verbraucher',
                userRestrictTitle: 'Berechtigte Verbraucher',
                userNotRestrict: 'Alle Verbraucher',
                userRestrictHelperText:
                    "Die Option 'erkannte Verbraucher' bedeutet, dass der Rabatt nur auf Verbraucher angewendet wird, die bereits für die Rabattberechtigung registriert sind!",
                searchCabinets: 'Schränke suchen',
                selectAll: 'Alle auswählen',
                monday: 'Montag',
                tuesday: 'Dienstag',
                wednesday: 'Mittwoch',
                thursday: 'Donnerstag',
                friday: 'Freitag',
                saturday: 'Samstag',
                sunday: 'Sonntag',
                fetchingLoader: 'Abrufen. Bitte warten...',
                category: 'Kategorie',
                okay: 'Ok',
                noLocation: 'Kein Standort',
                copy: 'Kopieren',
                pleaseConfirm: 'Bitte bestätigen',
                saveQuery: 'Abfrage speichern',
                saveQueryPlaceholder: 'z.B. "Verkaufszahlen aller Artikel"',
                saveQuerySuccess: 'Die Abfrage wurde gespeichert.',
                yourSavedQueries: 'Ihre gespeicherten Abfragen',
                selectQuery: 'Abfrage auswählen',
                noQuery: 'Es gibt noch keine gespeicherte Abfrage',
                deleteAdConfirm: 'Sind Sie sicher, dass Sie diese Anzeige löschen möchten?',
                dataSaved: 'Daten erfolgreich gespeichert',
            },
            refill: {
                refillRuleApplyRuleDropdownText: 'Diese Regel auch für andere Schränke anwenden.',
                refillPlanGenerate: 'Nachfüllplan generieren',
                refillPlanEditPlan: 'Nachfüllplan bearbeiten',
                refillAmount: 'Nachfüllmenge',
                refillRuleTargetAmount: 'Ziellagerbestand',
                refillRuleTargetAmountShort: 'Ziel',
                refillPlanActivePlans: 'Aktive Pläne',
                refillPlanCompletedPlans: 'Abgeschlossene Pläne',
                refillNote: 'Auffüllhinweis',
                refillNoteHelp: 'Produkt-Auffüllhinweis (maximal 125 Zeichen)',
                refillPDFText:
                    'Nachfolgend finden Sie den Auffüllplan für den Schrank. Stellen Sie sicher, dass Sie den Auffüllplan nach Abschluss der Auffüllung in der Auffüllanwendung als abgeschlossen markieren.',
                refillPDFSummaryText:
                    'Nachfolgend finden Sie eine Zusammenfassung der Auffüllpläne mit Produkten zum Hinzufügen und Entfernen für alle ausgewählten Schränke.',
                refillSummary: 'Auffüllzusammenfassung',
                addItems: 'Produkte hinzufügen',
                removeItems: 'Produkte entfernen',
                inventoryChange: 'Bestandsänderung',
                planogramPDFText: 'Planogramm für die Schrankauffüllungsstrategie.',
                planogramImagePDFText: 'Benutzerdefiniertes Bild für die Schrankauffüllungsstrategie.',
                refillSchemas: 'Auffüllvorlagen',
                refillPlans: 'Auffüllpläne',
                refillSchemaTitle:
                    'Legen Sie die Ziel- und Mindestbestandsmengen als Vorlage für die ausgewählten Schränke fest. Diese Vorlagen werden zur Erstellung der Auffüllpläne verwendet.',
                refillPlansTitle:
                    'Alle aktiven Pläne anzeigen. Sie können vorhandene aktive Pläne bearbeiten oder entfernen oder bei Bedarf neue Auffüllpläne hinzufügen.',
                refillSchemaSubText: 'Bitte wählen Sie einen Schrank aus, um die Auffüllvorlage zu erstellen oder zu aktualisieren.',
                noRefillSchema: 'Für diesen Schrank wurde noch keine Auffüllvorlage definiert.',
                createRefillSchema: 'Neue Auffüllvorlage erstellen',
                noDevice: 'Kein Gerät gefunden',
                schemaSaved: 'Auffüllvorlage erfolgreich gespeichert',
                deleteSchemaConfirmation: 'Sind Sie sicher, dass Sie die Auffüllvorlage löschen möchten?',
                targetAmount: 'Zielmenge',
                targetAmountHelpText: 'Die Zielbestandsmenge für jedes Produkt im Schrank nach der Auffüllung.',
                minimumThreshold: 'Mindestschwelle',
                minimumThresholdHelpText:
                    'Geben Sie das Mindestbestandsniveau für jedes Produkt im Schrank an. Wenn die Menge unter die festgelegte Schwelle fällt, erhalten Sie eine Alarmbenachrichtigung. Das Festlegen der Schwelle auf 0 deaktiviert die Benachrichtigungen.',
                editNote: 'Notiz bearbeiten',
                refillNoteTitle: 'Schreiben Sie eine Auffüllnotiz für das Produkt.',
                refillNoteCharacters: 'Zeichen',
                noRefillPlan:
                    'Derzeit gibt es keine aktiven Auffüllpläne. Bitte fügen Sie neue Auffüllpläne für Ihre Schränke hinzu oder erstellen Sie zuerst eine Auffüllvorlage für die Schränke.',
                planSaved: 'Auffüllplan erfolgreich gespeichert',
                deleteConfirmation: 'Sind Sie sicher, dass Sie die ausgewählten Auffülldaten löschen möchten?',
                editPlan: 'Plan bearbeiten',
                currentInventory: 'Aktueller Bestand',
                lastSevenDaySales: 'Verkäufe der letzten sieben Tage',
                inventory: 'Inventar',
                planogram: 'Planogramm',
                copyRefillSchema: 'Vorlage auf andere Schränke kopieren',
                generatingPlans: 'Auffüllpläne werden erstellt. Bitte warten...',
                generatePlans: 'Auffüllpläne erstellen',
                complete: 'komplett',
                selectCabinetsToAddPlans: 'Bitte wählen Sie Schränke aus, um Auffüllpläne zu erstellen.',
                reviewSuggestion:
                    'Unten finden Sie die vorgeschlagenen Auffüllpläne für die ausgewählten Schränke. Bitte überprüfen Sie diese, bearbeiten Sie sie bei Bedarf und speichern Sie sie, um diese Pläne zu aktivieren.',
                noSchema:
                    'Derzeit hat keiner der Schränke eine Auffüllvorlage. Bitte fügen Sie eine Auffüllvorlage für Ihre Schränke hinzu, um Auffüllpläne zu erstellen.',
                searchProducts: 'Produkte suchen',
                maxAllowedInventory: 'Maximal zulässiger Bestand ist 200.',
                savingData: 'Daten werden gespeichert. Bitte warten...',
                errorSavingData: 'Fehler beim Speichern der Daten.',
                refillPlanSaved: 'Auffüllpläne erfolgreich erstellt.',
                exportPDFHelp: 'Exportieren Sie ausgewählte Pläne in eine PDF-Datei.',
                exportExcelHelp: 'Exportieren Sie ausgewählte Pläne in eine Excel-Datei.',
                addPlanHelp: 'Neue Auffüllpläne hinzufügen.',
                deletePlanHelp: 'Ausgewählte Auffüllpläne löschen.',
                generatePlansHelp: 'Auffüllpläne für die ausgewählten Schränke erstellen.',
                planogramSaved: 'Planogramm erfolgreich gespeichert.',
                schemaCopied: 'Die Auffüllvorlage wurde erfolgreich mit den anderen Schränken synchronisiert.',
                allProducts: 'Alle Produkte',
                schemaProducts: 'Auffüllvorlagenprodukte',
                deletePlanogram: 'Sind Sie sicher, dass Sie das Planogramm löschen möchten?',
                dragInPlanogram: 'Bitte ziehen Sie Produkte in die vorgesehenen Schrankfächer oder ziehen Sie das Schrankfach über das Produkt.',
                noPlanogramInSchema: 'Für den Schrank wurde noch kein Planogramm definiert.',
                noPlanogramInPlan: 'Für den Schrank sind keine Planogrammdaten verfügbar. Bitte erstellen Sie zunächst ein Planogramm im Auffüllvorlagen-Tab.',
                copyPlanogram: 'Auffüllplanogramm zusammen mit der Auffüllvorlage kopieren.',
                planogramImageInfo: 'Zusätzlich zum Planogramm können Sie auch ein benutzerdefiniertes Planogrammbild hinzufügen.',
                addPlanogramImage: 'Planogrammbild hinzufügen',
                editPlanogramImage: 'Planogrammbild bearbeiten',
                numberOfShelves: 'Anzahl der Regale',
                addNewRefillPlan: 'Neue Pläne hinzufügen',
                qrcodeTitle: 'Ein QR-Code wird für den ausgewählten Schrank generiert, um den Auffüllprozess zu starten.',
                qrcodeSubText: 'Bitte wählen Sie einen Schrank aus, für den Sie den Auffüllprozess starten möchten.',
                qrcodeScan: 'Bitte scannen Sie den QR-Code zum Auffüllen oder klicken Sie auf die Schaltfläche, um die Auffüllanwendung zu öffnen.',
                qrcodeOpenApplication: 'Auffüllanwendung öffnen',
                createRefillPlanogram: 'Auffüllplanogramm erstellen',
            },
            navigation: {
                advertisements: 'Werbeeinblendungen',
                analytics: 'Auswertungen',
                controlPanel: 'Bedienfeld',
                deviceManagement: 'Gerätemanager',
                discounts: 'Rabatte',
                fetching: 'Abruf',
                group: 'Gruppe',
                inventory: 'Kühlschrankbestand',
                language: 'Sprache',
                productCatalog: 'Produktkatalog',
                productCategories: 'Produktkategorien',
                reports: 'Berichte',
                settings: 'Benutzereinstellungen',
                signOut: 'Abmelden',
                title: 'Selfly Cloud Portal',
                vatCategories: 'MwSt.-Kategorien',
                community: 'Selfly Community',
                refillManagement: 'Nachfüllverwaltung',
                automations: 'Automatisierungen',
                mySelfly: 'MySelfly-Einstellungen',
                automationInfo: 'Automatisierte Aktionen verwalten (BETA)',
            },
            reports: {
                alreadyRefund: 'wurde erstattet',
                amount: 'Betrag',
                average: 'Durchschnitt',
                barcode: 'Barcode',
                confirmIssueRefundButton: 'Erstattungsbestätigung',
                current: 'Aktuell',
                export: 'Exportieren',
                fetching: 'Abrufen',
                fetchingError: 'Beim Abrufen ist ein Fehler aufgetreten',
                image: 'Bild',
                issueRefundButton: 'ERSTATTUNG',
                maximum: 'Maximum',
                minimum: 'Minimum',
                name: 'Name',
                noData: 'Keine Daten',
                note: 'Hinweis',
                order: 'Transaktion',
                paymentWasDoneByCreditCard: 'Die Zahlung erfolgte per Kreditkarte.',
                price: 'Preis',
                ProductReport: 'Produktbericht',
                quantity: 'Anzahl',
                'Refund impossible': 'Die Zahlung erfolgte per Kreditkarte. Kontaktieren Sie den Kreditkartendienstleister, um eine Erstattung einzuleiten.',
                refundFormTitle: 'Erstattung',
                refundReason: 'Grund für die Erstattung',
                refundSuccess: 'Der Betrag {{sum}} wurde erfolgreich erstattet.',
                refundTotal: 'Erstattungsbetrag',
                replenishments: 'Nachfüllen',
                replenishmentsTitle: 'Nachfüllbericht',
                SaleTransaction: 'Verkaufstransaktion',
                summary: 'Zusammenfassung',
                SummaryReport: 'Verkaufszusammenfassung',
                temperature: 'Temperatur',
                temperatureTitle: 'Temperaturbericht',
                temperatureValueNotification: 'Es werden die stündlichen Durchschnittstemperaturen des Kühlschranks angezeigt.',
                time: 'Zeit',
                total: 'Gesamt',
                transactions: 'Transaktionen',
                user: 'Benutzer',
                paidTransactions: 'Bezahlte Transaktionen',
                unpaidTransactions: 'Unbezahlte Transaktionen',
                partiallyPaid: 'Teilweise bezahlt',
                failedTransactions: 'Gescheiterte Transaktionen',
                amountOwed: 'Ausstehender Betrag',
                receiptLinkText: 'Erhalten Sie hier Ihre Quittung',
                noPurchase: 'Kein Kauf',
                other: 'Andere',
                userId: 'Benutzer-ID:',
            },
            settings: {
                block_cabinet_when_product_expired: 'Kühlschrank wegen abgelaufener Produkte sperren',
                criticalExpiryLimitHours: 'Warnung Stunden vor kritischem Ablauf der Haltbarkeit',
                language: 'Sprache',
                logo: 'Firmenlogo',
                mediumExpiryLimitHours: 'Warnung Stunden vor mittlerem Ablauf der Haltbarkeit',
                payment_method_logo: 'Logo der Zahlungsmethode',
                footer_instruction_logo: 'Fusszeilenbild',
                readUserMemory: 'MHD des Produkts abrufen',
                ui_skus_sort_by: 'Sortierordnung des Produkts in der Kühlschrankanzeige',
                timeZone: 'Zeitzone',
                country: 'Land',
                city: 'Ort',
                tagSettings: 'Tag-Einstellungen',
                themeAndLogos: 'Thema & Logos',
                advancedSettings: 'Erweiterte Einstellungen',
                themingInfoText: 'Sie können das Thema Ihres Schrankbildschirms anpassen',
                cabinetLogos: 'Schrank-Logos',
                cabinetLogoInfoText: 'Hochladen von Logos, die auf dem Schrankbildschirm erscheinen',
                logoHelperText: 'Verwenden Sie ein 180px x 180px grosses Bild.',
                settingsCopyInfoHeader: 'Wählen Sie Schränke aus, in die Sie die Einstellungen kopieren möchten.',
                settingsCopyButton: 'Einstellungen in ausgewählte Schränke kopieren',
                settingsCopySuccessModalTitle: 'Einstellungen werden kopiert',
                settingsCopySuccessModalContent: 'Einstellungen erfolgreich in {{cabinetsAmount}} Schrank(en) kopiert',
                theming: 'Theming',
                adminSettings: 'Admin-Einstellungen',
                settingsCopyErrorModalTitle: 'Fehler beim Kopieren der Einstellungen',
                key: 'Einstellung',
                value: 'Wert',
                sleepScheduling: 'Geplante Standby',
                sensitivityLevel: 'Empfindlichkeitsstufe',
                sensitivity_0:
                    'Der Schrank ignoriert das Flackern des Tags und markiert konsequent alle Tags als gültig, was gelegentlich zu falschen Abrechnungen führen kann.',
                sensitivity_20: 'Der Schrank toleriert bis zu drei fehlerhafte Lesevorgänge eines Tags bei 50 Scans und markiert das Tag trotzdem als gültig.',
                sensitivity_40: 'Der Schrank toleriert bis zu zwei fehlerhafte Lesevorgänge eines Tags bei 60 Scans und markiert das Tag trotzdem als gültig.',
                sensitivity_60: 'Wenn ein Tag häufig flackert, aber über längere Zeit stabil bleibt, toleriert der Schrank dies und liest das Tag als gültig.',
                sensitivity_80: 'Wenn ein Tag kurzzeitig flackert, aber über längere Zeit stabil bleibt, wird es als gültig betrachtet.',
                sensitivity_100:
                    'Der Schrank ist sehr empfindlich gegenüber Flackern des Tags. Bereits ein einziger fehlerhafter Lesevorgang kann dazu führen, dass ein Tag für längere Zeit als ungültig markiert wird, was zu potenziellen Verlusten führen kann.',
                sensitivityInfo:
                    'Die Tag-Qualitätsempfindlichkeit eines Schranks bezieht sich darauf, wie effektiv der Schrank inkonsistente Tag-Lesungen oder Flackern handhabt. Bitte achten Sie auf die gewählte Empfindlichkeitsstufe, da dies die Leistung des Schranks beeinflussen kann. Wenn Sie unsicher sind, welches Niveau geeignet ist, wird empfohlen, die Einstellung auf dem Standardniveau 3 zu belassen.',
            },
            signIn: {
                signInButton: 'Anmelden',
                signInExpiredError: 'Ihre Sitzung ist abgelaufen. Melden Sie sich erneut an.',
                signInMessage: 'Sie müssen angemeldet sein, um fortzufahren. Klicken Sie auf die unten stehende Taste, um sich anzumelden.',
                noOrganizationsError: 'Unter diesem Benutzernamen gibt es kein Unternehmen. Versuchen Sie es mit einem anderen Benutzernamen.',
            },
            translations: {
                add: 'Hinzufügen',
                closed: 'Geschlossen',
                delete: 'Löschen',
                device: 'Gerät',
                edit: 'Bearbeiten',
                off: 'AUS',
                offline: 'Offline',
                on: 'EIN',
                online: 'Online',
                opened: 'Öffnen',
                save: 'Speichern',
                seeDetails: 'Details',
                settings: 'Einstellungen',
                unknown: 'Unbekannt ',
            },
            consumerApp: {
                enableConsumerFeedback: 'Benutzerfeedback in MySelfly aktivieren',
                enablePurchaseReceipts: 'E-Quittungen in MySelfly aktivieren',
                enableSelflyTrademarkForReceipts: 'Selfly-Store-Fusszeile in E-Quittungen erlauben',
                enableSelflyTrademarkForReceiptsHelpText:
                    'Wenn diese Funktion aktiviert ist, wird das Selfly Store Logo und weitere Details auf der E-Quittung (PDF) hinzugefügt!',
                organizationFormHelpText: 'Geben Sie die Details des Ausstellers der E-Quittung an.',
                organizationFormValidationHelpText:
                    '* Erforderliche Felder für die E-Quittung sind der Name des Unternehmens, die Geschäfts-ID, die Adresse und das Land des Ausstellers.',
                organizationName: 'Name des Unternehmens',
                organizationLogo: 'Logo des Unternehmens',
                businessId: 'Geschäfts-ID',
                city: 'Ort',
                email: 'E-Mail',
                website: 'Webseite',
                postalCode: 'PLZ',
                street: 'Strasse',
                phoneNumber: 'Telefonnummer',
                addressHeader: '* Adresseninformationen',
                contactDetails: 'Kontaktdaten',
                organization: '* Unternehmen des Ausstellers',
                organizationSettingsUpdatedTitle: 'Aktualisierung der Unternehmenseinstellungen',
                organizationSettingsUpdatedContent: 'Die Unternehmenseinstellungen wurden aktualisiert.',
                organizationSettingsMissingFields: 'Es fehlt ein obligatorisches Feld für die E-Quittung. Füllen Sie es vor dem Speichern aus.',
                organizationSettingsMissingFieldsTitle: 'Fehler beim Prüfen des Formulars.',
            },
            softwareVersions: {
                updateNotificationTitle: 'Aktualisierung der Softwareversion',
                updateNotificationContent: 'Die Softwareversion(en) wurde(n) erfolgreich aktualisiert.',
                selectAVersionPlaceholder: 'Wählen Sie eine Version.',
                searchDevices: 'Geräte suchen',
                updateSelected: 'Update ausgewählt',
                softwareVersions: 'Softwareversionen',
                softwareVersion: 'Softwareversion',
                notSupportedDeviceNote: 'Diese Version {{selectedVersion.version}} wird auf diesem Gerät nicht unterstützt.',
                userNotificationContent:
                    'Für Ihre Geräte wurde eine neue Softwareversion veröffentlicht. Bitte aktualisieren Sie Ihre Geräte, um die neuesten Funktionen zu erhalten.',
                userNotificationTitle: 'Eine neue Softwareversion ist verfügbar.',
                notificationBell_newSoftwareVersions: 'Verfügbare Updates für Ihre Geräte',
                manageVersions: 'Gerätesoftwareversionen verwalten',
                filterLocationsPlaceholder: 'Nach Orten filtern',
                allVersionNumbers: 'Alle Veröffentlichungen',
                noVersionsForUpdating: 'Keine Versionen zum Aktualisieren vorhanden.',
                scheduledUpdateNote: 'Die Version {{version}} wird voraussichtlich am {{scheduled}} aktualisiert.',
                scheduleRemoveNotificationTitle: 'Softwareversion planen',
                scheduleRemoveNotificationContent: 'Die geplante Aktualisierung der Softwareversion wurde erfolgreich gelöscht.',
                scheduleRemoveNotificationModalContent: 'Möchten Sie die geplante Aktualisierung für dieses Gerät wirklich löschen?',
            },
            header: {
                searchByOrganization: 'Nach Unternehmen suchen',
                pageLanguage: 'Sprache',
                configureMySelflyAndEreceipts: 'MySelfly-Einstellungen konfigurieren',
                remindMeLater: 'Erinnere mich später',
                updateNow: 'Jetzt aktualisieren',
            },
            notifications: {
                noNewNotifications: 'Keine neuen Benachrichtigungen',
            },
            cabinetSettings: {
                cabinetTheme: 'Schrankthema',
                editAndSaveTheme: 'Thema bearbeiten und speichern',
                colorPicker: 'Farbauswahl',
                customTheme: 'Benutzerdefiniertes Thema',
                themeSettings: 'Thema-Einstellungen',
                primary: 'Primär',
                negative: 'Negativ',
                attention: 'Achtung',
                positive: 'Positiv',
                background: 'Hintergrund',
                dark: 'Dunkel',
                light: 'Hell',
                white: 'Weiss',
                default: 'Standard',
                negativeLight: 'Negativlicht',
                primaryColor: 'Primäre Farbe',
                positiveColors: 'Positive Farben',
                negativeColors: 'Negative Farben',
                secondaryColors: 'Sekundäre Farben',
                attentionColors: 'Warnfarben',
                screenBackground: 'Bildschirmhintergrund',
                borderColors: 'Ränder - Schatten',
                shadow: 'Schatten',
                border: 'Rand',
                fontColors: 'Schriftfarben',
                footerTextPlaceholder: 'Fusszeilentext',
                bundleDiscounts: 'Kombinieren und speichern',
                expiringText: 'Ablaufende Produkte. Prüfen Sie das Mindesthaltbarkeitsdatum vor dem Kauf.',
                expiredText: 'Abgelaufene Produkte. Prüfen Sie das Mindesthaltbarkeitsdatum vor dem Kauf.',
                cabinetName: 'Name des Schranks',
                productName: 'Name des Produkts',
                confirmationModalHeader: 'Möchten Sie das angepasste Thema wirklich speichern?',
                confirmationModalContent:
                    'Die Anpassung des Themas wirkt sich auf die Darstellung Ihres Schrankbildschirms aus. Achten Sie darauf, dass die Anzeigequalität des Bildschirms durch die Änderungen nicht beeinträchtigt wird.',
                defaultRevertModalMessage: 'Möchten Sie das Schrankthema wirklich auf die Standardeinstellung zurücksetzen?',
                backgroundColor: 'Hintergrundfarbe',
                backgroundImage: 'Hintergrundbild',
                footerBackground: 'Fußzeilenhintergrund',
                cardsBackground: 'Kartenhintergrund',
                screenImage: 'Bild für Schirmhintergrund des Schranks',
                uploadImage: 'Bild hochladen',
                editImage: 'Bild bearbeiten',
                transactionComplete: 'Transaktion abgeschlossen',
                customPostPurchaseImage: 'Bitte lade ein Bild für den benutzerdefinierten Bildschirm nach dem Kauf hoch',
                postPurchaseScreen: 'Bildschirm nach dem Kauf',
                custom: 'benutzerdefiniert',
                postPurchaseText: 'Anzeige-Text nach dem Kauf',
            },
            automations: {
                transactionReports: 'Transaktionsberichte',
                refillReports: 'Nachfüllberichte',
                notifications: 'Benachrichtigungen',
                refillPlan: 'Nachfüllpläne',
                days: 'Tage',
                months: 'Monate',
                weeks: 'Wochen',
                configuration: 'Konfiguration',
                transactionReportTitle:
                    'Transaktionsberichte für die ausgewählten Automaten werden automatisch generiert und an die unten angegebenen E-Mail-Adressen gesendet',
                refillReportTitle:
                    'Nachfüllberichte für die ausgewählten Automaten werden automatisch generiert und an die unten angegebenen E-Mail-Adressen gesendet',
                noSalesNotificationTitle:
                    'Benachrichtigungen werden automatisch an die unten angegebenen E-Mail-Adressen gesendet, falls innerhalb der definierten Intervalle keine Verkäufe getätigt werden',
                frequencyTitle: 'Wie oft soll der Bericht generiert werden?',
                emailNotificationTitle: 'Bitte fügen Sie unten die E-Mail-Adressen hinzu, an die der Bericht oder die Benachrichtigung gesendet werden soll',
                refillPlanGenerationTitle: 'Nachfüllpläne werden automatisch für die ausgewählten Automaten gemäß dem angegebenen Zeitplan generiert',
                addNewEmail: 'Neue E-Mail hinzufügen',
                noSalesBanner:
                    'Sie erhalten eine E-Mail-Benachrichtigung, wenn die ausgewählten Automaten während des konfigurierten Zeitraums keine Verkäufe tätigen',
                noSaleTitle:
                    'Bitte geben Sie die Zeitdauer (in Stunden) ohne Verkäufe in den ausgewählten Automaten an, bevor eine Benachrichtigung gesendet wird',
                minMaxTime: 'Min. 2 und max. 23 Stunden',
                timeRangeTitle: 'Bitte geben Sie den Zeitrahmen für die Automaten an, in dem die keine-Verkäufe-Situation überprüft wird',
                startTime: 'Startzeit',
                endTime: 'Endzeit',
                refillPlanTitle:
                    'Der Nachfüllplan für die ausgewählten Automaten wird automatisch generiert. Bitte planen Sie unten die genaue Zeit, zu der der Nachfüllplan erstellt werden soll.',
                refillPlanTime: 'Bitte wählen Sie die Uhrzeit für die Generierung des Nachfüllplans aus',
                everyDay: 'Jeden Tag',
                everyWeek: 'Jede Woche',
                everyMonth: 'Jeden Monat',
                selectDays: 'Tage auswählen',
                automationSaved: 'Automatisierungseinstellungen erfolgreich gespeichert',
                automationDeleted: 'Automatisierungseinstellungen erfolgreich gelöscht',
            },
            sleepScheduling: {
                timeRangeTitle: 'Bitte geben Sie den Zeitrahmen an, in dem dieser Automat in den Standby-Modus geht.',
                startTime: 'Startzeit',
                endTime: 'Endzeit',
                selectDays: 'Tage auswählen',
                duration: 'Dauer (Stunden)',
            },
        },
        da: {
            dateRangeFilter: {
                lastMonth: 'sidste 30 dage',
                lastTwoWeek: 'sidste 14 dage',
                lastWeek: 'sidste 7 dage',
                selectDateRangeMsg: 'Valgt datointerval',
                today: 'i dag',
                yesterday: 'i går',
            },
            main: {
                activateSuccess: 'Produktet blev aktiveret',
                activationDate: 'Aktiveringsdato',
                active: 'Aktiv',
                addAdvertisement: 'Tilføj ny annonce',
                addDiscountRule: 'Tilføj rabatregel',
                addDiscountStop: 'Tilføj udløbsinterval',
                addNewLocation: 'Vælg en ny placering',
                addProduct: 'Tilføj nyt produkt',
                addRegion: 'Tilføj placering',
                addRegionPrice: 'Tilføj pris for placering',
                addScheduledDiscount: 'Tilføj planlagt rabat',
                addBundleDiscount: 'Tilføj samlerabat',
                bundledDiscounts: 'Samlerabatter',
                bundleItemsLengthExceeded: 'Det maksimale antal produkter (3) er overskredet. Fjern nogle af dine markeringer for at fortsætte.',
                bundleMaxProductLimitInfo: 'Vælg maks. tre (3) produkter eller produktkategorier',
                bundleFixedAmountExceededOriginal: 'Varekombinationens faste pris overstiger det oprindelige prisbeløb. Kontroller de valgte produkter.',
                bundleFixedAmountNotAllowedToBeZero: 'Varekombinationens faste pris skal være over 0',
                bundlePercentageNotAllowedToBeFull: 'Varekombinationens procentvise rabat skal være under 100',
                bundlePercentageNotAllowedToBeZero: 'Varekombinationens procentvise rabat skal være over 0',
                bundleItemDiscountPriceAmountExceededOriginal: 'Den nye nedsatte pris er højere end den oprindelige pris. Kontroller den nye pris igen.',
                bundleItemPriceMessage: 'Hver prisregel gælder kun for 1 vare, selv om flere af den samme vare er valgt',
                bundleItemFixedAmountExceededOriginal: 'Produktets faste pris er højere end det oprindelige produkts pris. Kontroller den faste pris igen.',
                createDiscountRule: 'Opret rabatregel',
                deviceType: 'Enhedstype',
                discountUpdateTitle: 'Opdatering af rabatter',
                discountCreateTitle: 'Oprettelse af rabat',
                discountDeleteTitle: 'Fjernelse af rabat',
                discountDeleteContent: 'Rabatten er blevet fjernet',
                discountStatusUpdated: 'Rabattens status er blevet opdateret',
                discountUpdated: 'Rabatten er blevet opdateret',
                discountCreated: 'Der er oprettet en rabat',
                discountSettings: 'Rabatindstillinger',
                discountImage: 'Valgfrit billede for rabat',
                editDiscountStop: 'Rediger udløbsinterval',
                editBundleConfiguration: 'Rediger varekonfiguration',
                editExpiryConfiguration: 'Rediger konfiguration for udløb af rabat',
                encodingStation: 'Kodningsstation',
                everyDay: 'Hver dag',
                scheduledDiscountDateInfoLabel: 'Lad start- og slutdato være tomme, hvis du ønsker at gøre rabatten permanent',
                starts: 'Starter',
                ends: 'Slutter',
                error: 'Fejl',
                errorRetiringLocationMsg: 'Det er ikke lykkedes at trække stedet tilbage. Fjern tilknyttede enheder, produktpriser og annoncer først.',
                fetchingDiscountRules: 'Henter rabatregler',
                free: 'Gratis',
                missingImage: 'Manglende annoncebillede',
                next: 'Næste',
                back: 'Tilbage',
                noDiscountRules: 'Der blev ikke fundet nogen rabatregler',
                scheduledDiscountsAutomaticDeactivationInfo:
                    'Rabatter, hvor datointervallet er overskredet, deaktiveres automatisk. Hvis du vil gøre dem aktive, skal du redigere datointervallet, så det er gyldigt.',
                scheduledDiscounts: 'Planlagte rabatter',
                schedulingStartHourIsOverEndHour: 'Starttidspunktet kan ikke være efter sluttidspunktet',
                schedulingEndHourIsUnderStartHour: 'Sluttidspunktet kan ikke være før starttidspunktet',
                selectDiscountRuleProductCategories: 'Vælg produktkategorier, der er omfattet af rabatreglen',
                selectBundleProductsAmount: 'Vælg beløb for:',
                selectedBundleProductCategories: 'Udvalgte produktkategorier i varekombination',
                setDiscountDays: 'Angiv rabatdage',
                setBundleDiscount: 'Indstil enten en procentvis rabat eller en fast pris for varekombination',
                setBundleItemDiscount: 'Angiv en af disse for én vare: procentvis rabat, fast pris eller gratis',
                setBundleProducts: 'Angiv produkter eller kategorier for varekombination',
                setAmountMoreThanOne: 'Hvis der er tale om en varekombination med en enkelt vare, skal der fastsættes et beløb på mere end én',
                setBundleDiscountType: 'Angiv rabattype og beløb for den',
                sureActivateDiscountRule: 'Er du sikker på, at du vil aktivere rabatreglen?',
                sureDeactivateDiscountRule: 'Er du sikker på, at du vil deaktivere rabatreglen?',
                sureDeleteDiscountRule: 'Er du sikker på, at du vil slette rabatreglen?',
                timeRange: 'Tidsinterval',
                editExpiryDiscountRule: 'Rediger regel om udløb af rabat',
                editScheduledDiscountRule: 'Rediger regel om planlagt rabat',
                editBundleDiscountRule: 'Rediger reglen om rabat for varekombination',
                releaseNotes: 'Udgivelsesbemærkninger',
                seeMore: 'Se mere',
                scheduledDiscountTimeRangeLabel: 'Aktiv tid',
                scheduledDiscountDateRangeLabel: 'Rabattens datointerval',
                weekDayAbrvMonday: 'Man',
                weekDayAbrvTuesday: 'Tir',
                weekDayAbrvWednesday: 'Ons',
                weekDayAbrvThursday: 'Tor',
                weekDayAbrvFriday: 'Fre',
                weekDayAbrvSaturday: 'Lør',
                weekDayAbrvSunday: 'Søn',
                recurrence: 'Gentagelse',
                addScheduledDiscountStop: 'Tilføj planlagt rabat',
                editScheduledDiscountStop: 'Rediger planlagt rabat',
                pieces: 'stk.',
                expiryDiscountNavLabel: 'Udløbsdato',
                scheduledDiscountNavLabel: 'Planlagt',
                bundleDiscountNavLabel: 'Varekombination',
                amount: 'Beløb',
                scheduling: 'Planlægning',
                pricing: 'Prisfastsættelse',
                percentageBundleDiscountInputPlaceholder: '% rabat for varekombination',
                fixedPriceBundleDiscountInputPlaceholder: 'Fast pris for varekombination',
                applyBundleLevelLabel: 'Anvend varekombinationsniveau',
                applyItemLevelLabel: 'Anvende enkeltvare-niveau',
                productFixedPrice: 'Produktets faste pris',
                percentageDiscount: '% rabat',
                fixedPriceDiscount: 'Fast pris',
                freeDiscount: 'Gratis',
                advertisementsFetchError: 'Der opstod en fejl under hentning af annoncer. Sørg for, at annonceblokering er slået fra',
                advertisementsTitle: 'Annoncer',
                all: 'Alle',
                'All time': 'Hele tiden',
                allProducts: 'Alle produkter uden gruppering',
                allLocations: 'Alle steder',
                allCabinets: 'Alle skabe',
                antennaDown: 'Nogle af RFID-antennerne er nede',
                antennaError: 'Fejlen ligger i skabets antenner',
                antennaNoInfo: 'Der foreligger ingen oplysninger om skabets antenner',
                antennaOk: 'Alle antenner er oppe og fungerer',
                antennaPartial: 'Nogle af antennerne er deaktiveret',
                archiveSuccess: 'Produktet er arkiveret',
                averageItems: 'Gennemsnitligt antal varer pr. transaktion',
                averageSales: 'Gennemsnitlig omsætning pr. transaktion',
                barcode: 'Stregkode',
                barcodeType: 'Stregkodetype',
                barcodeCannotBeChanged: 'Stregkoden kan ikke ændres',
                barcodeTypeCannotBeChanged: 'Stregkodetypen kan ikke ændres',
                barcodeHelpText12: 'Indtast 12 cifre af stregkoden, det sidste ciffer tilføjes automatisk',
                barcodeHelpText11: 'Indtast 11 cifre af stregkoden, det sidste ciffer tilføjes automatisk',
                barcodeHelpText7: 'Indtast 7 cifre af stregkoden, det sidste ciffer tilføjes automatisk',
                beforeExpiry: 'før udløb',
                cabinetDown: 'Skabet er nede',
                cabinetIsEmpty: 'Skabet er tomt',
                deviceOffline: 'Enheden er offline',
                deviceOnline: 'Enheden er online',
                cabinets: 'Skabe',
                cabinet: 'Skab',
                cabinetSettings: 'Indstillinger for skab',
                cabinetTheming: 'Skabstemaer',
                cancel: 'Annuller',
                cancelledSales: 'Annulleret salg',
                cancelledTransactions: 'Annullerede transaktioner',
                categories: 'Kategorier',
                change: 'Skift',
                clear: 'Ryd',
                close: 'Luk',
                clickEditButtonRegionalPrices: 'Klik på produktknappen "Rediger" for at redigere priser for placeringen.',
                clickEditLocationPrices: 'Lokale priser, klik på produktprisen for at udvide.',
                clickToExpandLocationPrices: 'Lokale priser, klik på produktprisen for at udvide.',
                closeDoor: 'Luk døren',
                rebootDevice: 'Genstart enheden',
                commandSentSuccessFully: 'Kommando afsendt',
                companyLogo: 'Virksomhedens logo',
                confirm: 'Bekræft',
                confirmDelete: 'Er du sikker på, at du vil slette produktet? Denne handling kan ikke fortrydes!',
                confirmDisable: 'Er du sikker på, at du vil arkivere produktet?',
                confirmEnable: 'Er du sikker på, at du vil gøre produktet aktivt?',
                consumerResponses: 'Feedback fra brugere',
                controlPanelTitle: 'Kontrolpanel',
                controls_acceptablePercentageOfBadTags: 'Acceptabel procentdel af dårlige tags, før skabet går i tilstanden  ude af drift',
                controls_markTagsGoodQuality: 'Mærk alle tags i god kvalitet',
                controls_markTagsGoodQuality_btn: 'Marker tags som god kvalitet',
                controls_clearTagHistory: 'Ryd tag-historik',
                controls_clearRemoveReplenishmentData: 'Ryd genopfyldningsdata',
                controls_companyAndPaymentLogo: 'Logo for virksomhed og betaling',
                controls_detectTagQuality: 'Registrerer kvaliteten af tags',
                controls_door: 'Dørkontrol',
                controls_device: 'Enhedskontrol',
                controls_chargeBadTags: 'Fakturer de dårlige tags',
                controls_refill: 'Refill',
                controls_tagQuality: 'Tag-indstillinger',
                controls_tagQualityData: 'Tag kvalitetsdata',
                controls_replenishmentData: 'Genopfyldningsdata',
                controls_soldTags: 'Solgte tags',
                controls_tagHistory: 'Tag historie',
                controls_markAllTagsReplenished: 'Marker alle tags som genopfyldt',
                controls_markAllTagsUnsold: 'Marker alle tags som usolgte',
                controls_markAlTagsReplenished_btn: 'Markér tags som genopfyldt',
                controls_markAllTagsUnsold_btn: 'Markér tags som usolgte',
                controls_chargeSoldTags: 'Oplad solgt tags',
                controls_chargeUnreplenishedTags: 'Oplad ikke-genopfyldte tags',
                controls_tagsWarn: 'Rydning af taghistorik fjerner alle data fra tags. Klik venligst på marker alle tags genopfyldt efter denne handling',
                create: 'Opret',
                createAdTitle: 'Ny annonce',
                createdAd: 'Annonce oprettet',
                createExpiryDiscountRule: 'Opret regel om udløb af rabat',
                createScheduledDiscountRule: 'Opret regel om planlagt rabat',
                createBundleDiscountRule: 'Opret regel om rabat for varekombination',
                createNewVatCategory: 'Ny momskategori',
                createProductTitle: 'Nyt produkt',
                creatingAd: 'Opretter annonce',
                creatingProduct: 'Opretter produkt',
                createNewProductCategory: 'Opretter en ny produktkategori',
                createNewSupplier: 'Tilføj ny leverandør',
                currency: 'Valuta',
                customQuery: 'Brugerdefineret forespørgsel',
                dashboard: 'Dashboard',
                dateRange: 'Datointerval',
                dataAlredyFetchedTitle: 'Data allerede hentet',
                dataAlreadyFetchedDescription: 'Vælg venligst nogle andre parametre',
                day: 'Dag',
                days: 'Dage',
                deactivationDate: 'Deaktiveringsdato',
                default: 'Standard',
                delete: 'Slet',
                deleteError: 'Fejl ',
                deleteSuccess: 'Produkt slettet',
                description: 'Beskrivelse',
                deviceManagement: 'Enhedsstyring',
                digitwinOffline: 'Enheden digitwin er offline',
                digitwinOnline: 'Enheden digitwin er online',
                dimensions: 'Mål',
                disable: 'Arkiv',
                discountAmount: 'Rabatbeløb',
                discountPrice: 'Rabatpris',
                discountRuleAffects: 'Rabatreglen påvirker',
                discountRuleName: 'Rabatreglens navn',
                discountRuleStops: 'Udløbsintervaller',
                discounts: 'Rabatter',
                discountSalesData: 'Data om rabatsalg',
                discountSalesAmount: 'Sum af rabatsalg ',
                discountTimeBeforeExpiryExists: 'Der findes allerede et tidligere udløbsinterval',
                discountVsNormalSales: 'Rabat vs. normalt salg',
                dismiss: 'Afvis',
                doesNotExist: 'Findes ikke',
                doorCloseConfirm: 'Er du sikker på, at du vil lukke døren?',
                doorCloseSuccess: 'Lås døren',
                doorOpenConfirm: 'Er du sikker på, at du vil åbne døren?',
                doorOpenReason: 'Hvorfor åbner du døren?',
                doorOpenSuccess: 'Døren blev åbnet',
                rebootDeviceSuccess: 'Enheden er genstartet',
                deviceRebootConfirm: 'Er du sikker på, at du vil genstarte enheden?',
                edit: 'Rediger',
                editCompanyLogo: 'Rediger virksomhedens logo',
                editDiscountRule: 'Rediger rabatregel',
                editPaymentMethodLogo: 'Rediger logo for betalingsmetode',
                editVatCategoryModalTitle: 'Rediger momskategori',
                editProductCategoryModalTitle: 'Rediger produktkategori',
                editSupplierModalTitle: 'Rediger leverandør',
                enable: 'Gør aktiv',
                error_401: 'Ikke godkendt',
                error_403: 'Ikke autoriseret til at udføre kommandoer',
                error_500: 'Uventet serverfejl',
                errorCreatingAd: 'Fejl ved oprettelse af annonce',
                errorCreatingProduct: 'Fejl ved oprettelse af produkt',
                errorFetchingCabinetStatus: 'Fejl ved hentning af skabsstatus',
                errorFetchingInventory: 'Fejl ved hentning af skabsbeholdning',
                errorFetchingReport: 'Fejl ved hentning af rapporter',
                errorFetchingSettings: 'Fejl ved hentning af indstillinger',
                errorUpdatingProduct: 'Fejl ved opdatering af produkt',
                expandToSeeBadTags: 'Udvid listen for at se de tags, der blev registreret som dårlige tags. Dårlige tags skal fjernes fra skabet.',
                expandToSeeSoldItems: 'Udvid listen for at se de varer, der allerede er solgt, men som er tilbage på lager igen.',
                expandToSeeReplenishedOutOfInventory:
                    'Udvid listen for at se de produkter, der er blevet placeret i skabet i en anden proces end lageropfyldning',
                expired: 'Udløbet',
                expiredCount: 'Udløbet antal',
                expiredProductsCount: 'Antal udløbne produkter',
                expiredSum: 'Udløbet sum',
                expiring: 'Udløber',
                expiryDate: 'Sidste salgsdag',
                exportExcel: 'Eksporter til Excel',
                fetchAdvertisements: 'Henter annoncer',
                fetchingCabinets: 'Henter skabe',
                fetchingCabinetsError: 'Der opstod en fejl under hentning af skabe',
                fetchingCabinetStatus: 'Hentning af skabsstatus',
                fetchingError: 'Der opstod en fejl under hentning af',
                fetchingInventory: 'Henter skabsbeholdning',
                fetchingProducts: 'Henter produkter',
                fetchingReport: 'Henter rapporter',
                fetchingVatCategories: 'Henter momskategorier',
                fetchingProductCategories: 'Henter produktkategorier',
                fillForm: 'Udfyld formularen',
                freshFood: 'Dette er en udløbende vare',
                freezerPackage: 'Frosset produkt',
                generalFetchError: 'Fejl ved hentning',
                generalRequestReject: 'Fejl ved anmodning',
                get: 'Hent',
                group: 'Gruppér',
                holdOn: 'Hold fast',
                hour: 'Timer',
                hours: 'timer',
                hoursBeforeExpiry: 'Timer til udløbsdato',
                daysBeforeExpiry: 'Dage til udløbsdato',
                image: 'Billede',
                imageOrVideo: 'Vælg et billede eller en video',
                inActive: 'Inaktiv',
                individualCabinets: 'Individuelle skabe',
                individualCabinetsOnly: 'Kun individuelle skabe',
                invalidBarcodeOnlyDigits: 'Stregkoden må kun indeholde tal',
                invalidBarcodeTooLong: 'Stregkoden er for lang',
                invalidBarcodeTooShort: 'Stregkoden er for kort',
                invalidCabinetNameTooShort: 'Skabets navn er for kort',
                invalidCabinetNameDuplicated: 'Skabets navn findes allerede',
                invalidLocationNameTooShort: 'Stednavnet er for kort',
                invalidLocationNameDuplicated: 'Stednavnet er duplikeret',
                invalidPercentage: 'Ugyldig værdi',
                invalidPrice: 'Ugyldig pris',
                invalidProductBarcode: 'Ugyldig produktstregkode',
                invalidTitle: 'Ugyldig titel',
                invalidBundleItems: 'Varekombination indeholder ugyldige elementer',
                inventoryLength: 'Lageropgørelsens længde',
                inventoryNotReported: 'Skabet har ikke indberettet sin lagerbeholdning',
                inventoryTitle: 'Skabsbeholdning',
                'Inventory History': 'Lagerhistorik',
                itemReplenishedOutOfInventoryProcess: 'Dette produkt eller tag er blevet placeret i skabet i en anden proces end lageropfyldningsprocessen',
                itemSoldOn: 'Varen blev solgt på',
                'Last 30 days': 'Sidste 30 dage',
                'Last 7 days': 'Sidste 7 dage',
                'Last month': 'Sidste måned',
                'Last quarter': 'Sidste kvartal',
                'Last week': 'Sidste uge',
                'Last year': 'Sidste år',
                lastUpdate: 'Sidste opdatering',
                lifetime: 'Levetid',
                location: 'Sted',
                locations: 'Steder',
                logoEditor: 'Logo-editor',
                logoSettings: 'Indstillinger for logo',
                loading: 'Indlæser...',
                measure: 'Mål',
                measures: 'Mål',
                minsBeforeExpiry: 'Minutter til udløbsdato',
                minutes: 'minutter',
                month: 'Måned',
                name: 'Navn',
                newVatCategoryModalTitle: 'Ny momskategori',
                newProductCategoryModalTitle: 'Ny produktkategori',
                newSupplierModalTitle: 'Ny leverandør',
                'no region': '(Intet sted)',
                noAdvertisements: 'Ingen annonce',
                noCabinets: 'Ingen skabe',
                noCabinetSelected: 'Intet skab valgt',
                noLogo: 'Intet logo',
                noData: 'Ingen data',
                noDescription: 'Ingen beskrivelse',
                noEvents: 'Ingen begivenheder!',
                noIndividualCabinets: 'Ingen individuelle skabe',
                noLocationPrices: 'Der er ikke fastsat lokale priser for dette produkt',
                none: 'Ingen',
                noPaymentMethodLogo: 'Intet logo for betalingsmetode',
                noProducts: 'Ingen produkter',
                noProductsRemoved: 'Ingen produkter er fjernet',
                noProductTypeSelected: 'Der er ikke valgt nogen pakketype',
                normalPrice: 'Normal pris',
                normalSalesAmount: 'Normal salgssum',
                notAccessibleForYou:
                    'Funktionen Opret/Opdater kun aktiveret for administratorer og skabsoperatører. Du kan få adgang ved at kontakte din leder eller systemadministrator.',
                notDefined: 'Ikke defineret',
                noTitle: 'Ingen titel',
                noVatCategories: 'Der er ikke fundet nogen registrering af momskategori',
                noProductCategories: 'Der er ikke fundet nogen registrering af produktkategori',
                noInventoryOnSelectedDate: 'Der blev ikke rapporteret nogen lagerbeholdning for den valgte dato. Prøv venligst med en anden dato.',
                openDoor: 'Åbn døren',
                orderSum: 'Bestillingsbeløb',
                paymentMethodLogo: 'Logo for betalingsmetode',
                percentage: 'Procentdel',
                permanent: 'Permanent',
                preview: 'Forhåndsvisning',
                price: 'Pris',
                print: 'Udskriv',
                productCreated: 'Produkt oprettet',
                products: 'Produkter',
                productsFetchError: 'Der opstod en fejl under hentning af produkter',
                productsTitle: 'Produktkatalog',
                productType: 'Pakketype',
                productUpdated: 'Produkt opdateret',
                quantity: 'Mængde',
                readerDown: 'RFID-læseren er nede',
                region: 'Sted',
                regions: 'Steder',
                removedAt: 'Fjernet på',
                removeImage: 'Fjern billede',
                removeVideo: 'Fjern video',
                refillQRCodeInfo: 'Scan QR-koden for at åbne genopfyldningsapplikationen eller følg',
                refillQRCodeTitle: 'Refill QR-kode',
                refillRuleTitle: 'Genopfyldningsregel',
                reportsTitle: 'Rapporter',
                requestSuccess: 'Ændringer gennemført',
                requiredPercentage: 'Påkrævet. Procentværdi uden procenttegn.',
                requiredText: 'Påkrævet længde af tegn: ',
                retireLocation: 'Træk sted tilbage',
                rfidNoInfo: 'Der foreligger ingen oplysninger om RFID-læser og antenner',
                rfidOk: 'RFID-læser og antenner fungerer som forventet.',
                sales: 'Salg',
                salesCount: 'Antal solgt',
                salesVsWasteAmount: 'Salg vs. affaldsmængde',
                save: 'Gem',
                search: 'Søg',
                selectCabinet: 'Vælg et skab',
                selectDiscountRuleCabinets: 'Vælg skabe, der påvirkes af rabatreglen',
                selectDiscountRuleLocations: 'Vælg steder, der påvirkes af rabatreglen',
                selectDiscountRuleProducts: 'Vælg produkter, der påvirkes af rabatreglen',
                selectProductType: 'Vælg venligst pakningstype til dit produkt',
                setDiscountAmount: 'Angiv rabatbeløb',
                setDiscountTimeBeforeExpiry: 'Angiv udløbsinterval',
                settingConfirmMessage: 'Er du sikker på, at du vil gemme disse indstillinger? Hvis du ikke er sikker, skal du ikke bekræfte',
                showDisable: 'Arkiverede produkter',
                summary: 'Resumé',
                sureRemoveDiscountRule: 'Er du sikker på, at du vil fjerne rabatreglen?',
                tagIsBad: 'Tag er dårligt, det skal fjernes fra skabet.',
                tagsRemovedReport: 'Rapport om fjernede tags',
                taxValue: 'Skatteværdi',
                'This month': 'Denne måned',
                'This quarter': 'Dette kvartal',
                'This week': 'Denne uge',
                'This year': 'I år',
                thisLink: ' dette link.',
                timeGrouping: 'Tidsmæssig gruppering',
                title: 'Titel',
                Today: 'i dag',
                topExpiredProducts: 'De mest udløbne produkter',
                topProducts: 'Topprodukter',
                total: 'I alt',
                totalValue: 'Samlet værdi',
                transactions: 'Transaktioner',
                ttlInDays: 'Produktets levetid i dage',
                ttlsAffected: 'Produkter, hvor levetiden påvirkes',
                unexpectedError: 'Uventet fejl',
                uniqueCustomers: 'Unikke kunder',
                unknown: 'Ukendt',
                update: 'Opdater',
                updateAdError: 'Fejl ved opdatering af annoncen',
                updateAdTitle: 'Opdater annoncen',
                updatedAd: 'Annonce opdateret',
                updateCabinetSettings: 'Synkroniser data til skab',
                updateCabinetSettingsInfo: 'Er du sikker på, at du vil opdatere skabsindstillingerne?',
                updateData: 'Opdater data',
                updateProductTitle: 'Opdater produkt',
                updatingAd: 'Opdater annonce',
                updatingProduct: 'Opdaterer produkt',
                uploading: 'Overfører',
                uploadingImage: 'Overfører billede',
                use: 'Brug',
                'w/o grouping': 'Ingen gruppering',
                waste: 'Affald',
                vatCategoriesFetchError: 'Fejl ved hentning af momskategorier',
                vatCategoriesTitle: 'Momskategorier',
                vatCategory: 'Momskategori',
                vatCategoryItems: 'Varer',
                vatCategoryName: 'Navn på momskategori',
                vatCategoryPromptMessage: 'Er du sikker på, at du vil slette momskategorien',
                vatCategoryValue: 'Skatteværdi i procent',
                vatDeleteRejected: 'Sletning af momskategori afvist. Kontroller tilknyttede produkter.',
                vatErrorRequesting: 'Fejl ved behandlingen af anmodningen',
                vatRequestRejected: 'Anmodning afvist',
                vats: 'Moms',
                productCategoriesFetchError: 'Fejl ved hentning af produktkategorier',
                productCategoriesTitle: 'Produktkategorier',
                productCategory: 'Produktkategori',
                productCategoryItems: 'Kategorier',
                productCategoryName: 'Navn på produktkategori',
                productCategoryPromptMessage: 'Er du sikker på, at du vil slette produktkategorien?',
                productCategoryDescription: 'Beskrivelse af produktkategori',
                productCategoryHelpTextDescription: 'F.eks. kategori for salater',
                productCategoryHelpTextName: 'F.eks. salater',
                productCategoryDeleteRejected: 'Sletning af produktkategori afvist. Kontroller tilknyttede produkter!',
                week: 'Uge',
                viewMode: 'Vis',
                year: 'År',
                Yesterday: 'I går',
                groupBy: 'Gruppér efter',
                filterBy: 'Filtrer efter',
                reportType: 'Rapporttype',
                custom: 'Tilpasset',
                preDefined: 'Foruddefineret',
                selected: 'Valgt',
                metricsInfo: 'Måleværdier (sum, maksimum, minimum...) beregnes for alle valgte dimensioner/ressourcer',
                dimensionsInfo: 'Der hentes måleværdier for de valgte dimensioner/ressourcer',
                metrics: 'Måleværdier',
                controlsSoftwareVersion: 'Softwareversion',
                currentSoftwareVersion: 'Nuværende softwareversion',
                availableSoftwareVersion: 'Tilgængelig softwareversion',
                invalidLifetime: 'Ugyldig levetidsværdi',
                unpaidSales: 'Ubetalt salg',
                unpaidTransactions: 'Ubetalte transaktioner',
                softwareUpdateConfirmation: 'Er du sikker på, at du vil opdatere softwareversionen til den eller de valgte enheder?',
                softwareUpdateWarn:
                    'Opdatering af softwareversionen vil sende en kommando til enheden/enhederne om at hente opdateringen nu eller på det valgte tidspunkt. Valgte enheder vil installere opdateringen næste gang de er online efter udløsningen. Installationen kan tage tid afhængigt af forbindelseshastigheden. Enhed(er) genstarter efter installationen.',
                releaseVersion: 'Udgivelsesversioner',
                releaseDate: 'Udgivelsesdato',
                latestRelease: 'Seneste udgivelse',
                currentVersion: 'Nuværende version',
                softwareUpdateSuccess: 'Softwareversionen er blevet opdateret',
                prebooking: 'Forudbestilling',
                prebookedProduct: 'Forudbestilt produkt',
                prebookedProducts: 'Forudbestilte produkter',
                activeProducts: 'Aktive produkter',
                startMaintenanceMode: 'Start vedligeholdelsestilstand',
                endMaintenanceMode: 'Afslut vedligeholdelsestilstand',
                startMaintenanceConfirm: 'Er du sikker på, at du vil starte vedligeholdelsestilstand for skabet?',
                endMaintenanceConfirm: 'Er du sikker på, at du vil afslutte vedligeholdelsestilstanden for skabet?',
                startMaintenanceSuccess: 'Vedligeholdelsestilstand er startet.',
                endMaintenanceSuccess: 'Vedligeholdelsestilstand er afsluttet.',
                noResults: 'Ingen resultater',
                suppliers: 'Leverandører',
                supplier: 'Leverandør',
                supplierDeleteRejected: 'Sletning af leverandør afvist. Kontroller tilknyttede produkter!',
                supplierPromptMessage: 'Er du sikker på, at du vil slette leverandøren?',
                fetchingSuppliers: 'Henter leverandører',
                noSuppliers: 'Der blev ikke fundet nogen leverandørpost ',
                suppliersFetchError: 'Fejl ved hentning af leverandører',
                supplierName: 'Leverandørnavn',
                supplierHelpTextName: 'F.eks. et varemærke',
                supplierDescription: 'Beskrivelse af leverandøren',
                supplierHelpTextDescription: 'F.eks. en beskrivelse af et varemærke',
                wishes: 'Ønsker',
                checkNow: 'Kontroller nu',
                startTimeOn: 'Start tiden ',
                endTimeOn: 'Slut tiden ',
                signOutConfirmationModalTitle: 'Bekræftelse af log ud',
                signOutConfirmationModalContent: 'Er du sikker på, at du vil logge ud?',
                startDate: 'Startdato',
                endDate: 'Slutdato',
                scheduledDiscountApplyAllDays: 'Gælder alle dage',
                scheduledDiscountApplySelectedDays: 'Gælder udvalgte dage',
                discountCustomImageTitle: 'Rabatbillede (valgfrit)',
                discountCustomImageModalHeader: 'Billede for rabat',
                saveChanges: 'Gem ændringer',
                updateTagsQualitySettingsTitle: 'Indstillinger for tag-kvalitet',
                updateTagsQualitySettingsMessage: 'Opdaterede indstillinger for tag-kvalitet',
                IF3Cabinets: 'Model 3 køleskabe',
                IF2Cabinets: 'Model 2 køleskabe',
                IF3Freezers: 'Model 3 frysere',
                encodingStations: 'Kodningsstationer',
                if2: 'Model 2 køleskab',
                if3: 'Model 3 køleskab',
                if3Freezer: 'Model 3 fryser',
                legalNotice: 'Juridiske oplysninger',
                privacyPolicy: 'Politik om beskyttelse af personlige oplysninger',
                scheduledDiscountDateMissingError: 'Begge datoer er nødvendige, hvis der er angivet en start- eller slutdato',
                scheduledDiscountDuplicateTimeError: 'Tidspunkterne kan ikke være de samme, hvis datoerne er de samme',
                now: 'Nu',
                minimum: 'Minimum',
                maximum: 'Maksimum',
                discardChangesConfirmationText: 'Er du sikker på, at du vil kassere ændringerne?',
                exports: 'Eksport',
                selectAllCabinets: 'Vælg alle skabe',
                expiryIntervals: 'Udløbsintervaller',
                timeBeforeExpiry: 'Tid før udløb',
                apply: 'Anvend',
                yes: 'Ja',
                no: 'Nej',
                productOnScreenBannerText: 'Infobannertekst på skærmen',
                productOnScreenBannerAdditionalInfo:
                    'Bannertekst er tekst på én linje, der vises under produktbilledet og kortfattet formidler nøglefunktioner, sundhedsfordele eller yderligere oplysninger om produktet',
                productOnScreenBannerExampleText: 'Laktosefri',
                UI_IS_DOWN: 'Skabsdisplay virker ikke',
                READER_IS_DOWN: 'RFID-læseren er nede',
                ANTENOR_DEVICE_DISCONNECTED: 'Betalingsenheden er frakoblet',
                EXPIRED_PRODUCT: 'Udløbne produkter i skabet',
                CLOUD_SERVICE_DOWN: 'Cloud-tjeneste nede',
                ANTENNA_DISCONNECTED: 'Antennen er frakoblet',
                TAG_CHANGES: 'For mange dårlige tags i skabet',
                ANTENNA_DOWN: 'RFID-antennen i skabet virker ikke',
                READER_CONFIG_ERROR: 'Konfiguration af RFID-læseren mislykkedes, og den virker ikke',
                activeAlarms: 'Aktive alarmer',
                deviceStatus: 'Enhedsstatus',
                lastMonitored: 'Senest overvåget',
                noService: 'Uden service',
                operational: 'Operativ',
                offline: 'Offline',
                locationsSelectHelp: 'Når en placering tilføjes, vil dens underordnede placeringer også blive tilføjet.',
                select: 'Vælg...',
                labels: 'Etiketter',
                productDetails: 'Produktdetaljer',
                invalidProductName: 'Produktnavnet skal være mellem 1 og 125 tegn langt',
                standardPrice: 'Standardpris',
                locationPrices: 'Lokationspriser',
                addNew: 'Tilføj ny',
                productExpiry: 'Produktudløb',
                productLifeTime: 'Produktlevetid',
                invalidTTL: 'Produktets levetid skal være et heltal fra 0 til 32767',
                productImage: 'Produktbillede',
                uploadImage: 'Upload billede',
                pleaseUploadImage: 'Upload venligst et billede',
                productValidationError: 'Ret venligst følgende fejl, før du gemmer produktdataene',
                productSupplier: 'Produktsupplier',
                frozenProductLable: 'Dette er et frossent produkt',
                prebookingLabel: 'Dette er et forudbestillingsprodukt',
                previous: 'Forrige',
                bannerText: 'Bannertekst',
                packagingTypeValidationError: 'Vælg venligst en emballagetype til produktet',
                showOnlyFrozen: 'Vis kun frosne produkter',
                productClassification: 'Produktklassifikation',
                productClassLabel:
                    'Vælg venligst den passende klasse for produktet. Dette gør det muligt for Selfly Insights at give mere relevant produktinformation.',
                productLabels: 'Produktetiketter',
                productLabelsHelpText:
                    'Tilføj venligst valgfrie etiketter til dit produkt. Dette giver dig mulighed for at filtrere dine produkter i produktkataloget.',
                newLabel: 'Ny etiket',
                productClassValidationError: 'Vælg venligst en klassifikation for dit produkt fra mulighederne nedenfor',
                tagSensitivity: 'Mærkekvalitetsfølsomhed',
                tagSensitivitySetting: 'Indstilling af mærkekvalitetsfølsomhed',
                configTagSensitivity: 'Konfigurer følsomheden for mærkekvaliteten for produktet',
                productLevelSensitivityInfo:
                    'Læsbarheden af en RFID-mærkat, der er fastgjort til et produkt, kan påvirkes af forskellige faktorer, herunder emballagematerialer, metaloverflader, fugt og selve produktindholdet. Disse faktorer kan til tider forårsage intermitterende mærkesignaler, kendt som flimmer. Skabet behandler denne flimmer baseret på sine følsomhedsindstillinger. Hvis du er i tvivl om, hvilket følsomhedsniveau der passer til dette produkt, kan du springe dette afsnit over.',
                errorSaving: 'Fejl ved lagring af produktdata',
                successSaving: 'Produktdata gemt succesfuldt',
                basicDetail: 'Grundlæggende detaljer',
                additionalDetail: 'Yderligere detaljer',
                packaging: 'Emballagetype',
                classification: 'Klassifikation',
                selectOrtype: 'Vælg eller skriv...',
                max25Chars: 'maks. 25 tegn',
                noVatForProductCreation: 'Opret venligst en moms-kategori, før du tilføjer nye produkter',
                lifeTimeExplanation: 'Hvor mange dage dette produkt er gyldigt til salg. (0 dage) betyder indtil slutningen af den aktuelle dag',
                selflyEncode: 'Selfly Encode',
                userRestrict: 'Kun anerkendte forbrugere',
                userRestrictTitle: 'Berettigede forbrugere',
                userNotRestrict: 'Alle forbrugere',
                userRestrictHelperText:
                    "Valgmuligheden 'anerkendte forbrugere' betyder, at rabatten kun anvendes på forbrugere, der allerede er registreret for at være berettiget til rabatten!",
                searchCabinets: 'Søg skabe',
                selectAll: 'Vælg alle',
                monday: 'Mandag',
                tuesday: 'Tirsdag',
                wednesday: 'Onsdag',
                thursday: 'Torsdag',
                friday: 'Fredag',
                saturday: 'Lørdag',
                sunday: 'Søndag',
                fetchingLoader: 'Henter. Vent venligst...',
                category: 'Kategori',
                okay: 'Ok',
                noLocation: 'Ingen placering',
                copy: 'Kopier',
                pleaseConfirm: 'Bekræft venligst',
                saveQuery: 'Gem denne forespørgsel',
                saveQueryPlaceholder: 'f.eks. "Antal solgte varer"',
                saveQuerySuccess: 'Forespørgslen er gemt.',
                yourSavedQueries: 'Dine gemte forespørgsler',
                selectQuery: 'Vælg forespørgsel',
                noQuery: 'Der er endnu ingen gemte forespørgsler',
                deleteAdConfirm: 'Er du sikker på, at du vil slette denne annonce?',
                dataSaved: 'Data gemt med succes',
            },
            refill: {
                refillRuleApplyRuleDropdownText: 'Anvend også denne regel på andre skabe',
                refillPlanGenerate: 'Generer genopfyldningsplan',
                refillPlanEditPlan: 'Rediger plan',
                refillAmount: 'Genopfyldningsmængde',
                refillRuleTargetAmount: 'Mål for lagerniveau',
                refillRuleTargetAmountShort: 'Mål',
                refillPlanActivePlans: 'Aktive planer',
                refillPlanCompletedPlans: 'Færdiggjorte planer',
                refillNote: 'Påfyldningsnote',
                refillNoteHelp: 'Produktets påfyldningsnote (maksimalt 125 tegn)',
                refillPDFText:
                    'Nedenfor er påfyldningsplanen for skabet. Sørg venligst for at markere påfyldningsplanen som fuldført i påfyldningsapplikationen, når du har afsluttet påfyldningen.',
                refillPDFSummaryText: 'Nedenfor er en sammenfatning af påfyldningsplaner med produkter til tilføjelse og fjernelse for alle valgte skabe.',
                refillSummary: 'Påfyldningssammendrag',
                addItems: 'Tilføj produkter',
                removeItems: 'Fjern produkter',
                inventoryChange: 'Lagerændring',
                planogramPDFText: 'Planogram for strategien for genopfyldning af skabe.',
                planogramImagePDFText: 'Tilpasset billede til strategien for genopfyldning af skabe.',
                refillSchemas: 'Genopfyldningsskabeloner',
                refillPlans: 'Genopfyldningsplaner',
                refillSchemaTitle:
                    'Indstil mål- og minimumslagerbeløb som en skabelon for valgte skabe. Disse skabeloner vil blive brugt til at generere genopfyldningsplanerne.',
                refillPlansTitle:
                    'Vis alle aktive planer. Du kan redigere eller fjerne eksisterende aktive planer eller tilføje nye genopfyldningsplaner efter behov.',
                refillSchemaSubText: 'Vælg venligst et skab for at oprette eller opdatere genopfyldningsskabelonen.',
                noRefillSchema: 'Ingen genopfyldningsskabelon er endnu defineret for dette skab.',
                createRefillSchema: 'Opret en ny genopfyldningsskabelon.',
                noDevice: 'Ingen enhed fundet.',
                schemaSaved: 'Genopfyldningsskabelon gemt med succes.',
                deleteSchemaConfirmation: 'Er du sikker på, at du vil slette genopfyldningsskabelonen?',
                targetAmount: 'Målbeløb',
                targetAmountHelpText: 'Det ønskede lagerantal for hvert produkt i skabet efter genopfyldningen.',
                minimumThreshold: 'Minimum tærskel',
                minimumThresholdHelpText:
                    'Angiv det minimale lager niveau for hvert produkt i skabet. Hvis antallet falder under den definerede tærskel, modtager du en alarmnotifikation. At indstille tærsklen til 0 vil deaktivere notifikationer.',
                editNote: 'Rediger bemærkning',
                refillNoteTitle: 'Skriv en genopfyldningsbemærkning for produktet.',
                refillNoteCharacters: 'tegn',
                noRefillPlan:
                    'Der er i øjeblikket ingen aktive genopfyldningsplaner. Tilføj venligst nye genopfyldningsplaner for dine skabe, eller opret genopfyldningsskabelon for skabene først.',
                planSaved: 'Genopfyldningsplan gemt med succes.',
                deleteConfirmation: 'Er du sikker på, at du vil slette de valgte genopfyldningsplan data?',
                editPlan: 'Rediger plan',
                currentInventory: 'Nuværende lager',
                lastSevenDaySales: 'Salget de sidste syv dage',
                inventory: 'Lager',
                planogram: 'Planogram',
                copyRefillSchema: 'Kopier skabelon til andre skabe.',
                generatingPlans: 'Genererer genopfyldningsplaner. Vent venligst...',
                generatePlans: 'Generer genopfyldningsplaner',
                complete: 'fuldført',
                selectCabinetsToAddPlans: 'Vælg venligst skabe for at generere genopfyldningsplaner.',
                reviewSuggestion:
                    'Nedenfor er de foreslåede genopfyldningsplaner for de valgte skabe. Gennemgå, rediger om nødvendigt, og gem dem for at aktivere disse planer.',
                noSchema:
                    'Ingen skabe har i øjeblikket en genopfyldningsskabelon. Tilføj venligst en genopfyldningsskabelon til dine skabe for at generere genopfyldningsplaner.',
                searchProducts: 'Søg produkter',
                maxAllowedInventory: 'Maksimalt tilladt lager er 200.',
                savingData: 'Gemmer data. Vent venligst.',
                errorSavingData: 'Fejl ved gemning af data.',
                refillPlanSaved: 'Genopfyldningsplaner genereret med succes.',
                exportPDFHelp: 'Eksporter valgte planer til PDF-fil.',
                exportExcelHelp: 'Eksporter valgte planer til Excel-fil.',
                addPlanHelp: 'Tilføj nye genopfyldningsplaner.',
                deletePlanHelp: 'Slet valgte genopfyldningsplaner.',
                generatePlansHelp: 'Generer genopfyldningsplaner for de valgte skabe.',
                planogramSaved: 'Planogram gemt med succes.',
                schemaCopied: 'Genopfyldningsskabelonen blev synkroniseret med de andre skabe.',
                allProducts: 'Alle produkter',
                schemaProducts: 'Produkter i genopfyldningsskabelon',
                deletePlanogram: 'Er du sikker på, at du vil slette planogrammet?',
                dragInPlanogram: 'Træk venligst produkter til de tildelte skabs hylder, eller træk skabspladsen over produktet.',
                noPlanogramInSchema: 'Et planogram er endnu ikke defineret for skabet.',
                noPlanogramInPlan:
                    'Ingen planogramdata er tilgængelige for skabet. Opret venligst et planogram for skabet i fanen for genopfyldningsskabeloner først.',
                copyPlanogram: 'Kopier genopfyldningsplanogram sammen med genopfyldningsskabelonen.',
                planogramImageInfo: 'Ud over planogrammet kan du også tilføje et tilpasset planogrambillede.',
                addPlanogramImage: 'Tilføj planogrambillede.',
                editPlanogramImage: 'Rediger planogrambillede.',
                numberOfShelves: 'Antal hylder',
                addNewRefillPlan: 'Tilføj nye planer',
                qrcodeTitle: 'En QR-kode vil blive genereret for det valgte skab for at påbegynde genopfyldningsprocessen.',
                qrcodeSubText: 'Vælg venligst et skab, hvor du ønsker at påbegynde genopfyldningsprocessen.',
                qrcodeScan: 'Scan venligst QR-koden for at genopfylde, eller klik på knappen for at åbne genopfyldningsapplikationen.',
                qrcodeOpenApplication: 'Åbn genopfyldningsapplikation',
                createRefillPlanogram: 'Opret genopfyldningsplanogram',
            },
            navigation: {
                advertisements: 'Annoncer',
                analytics: 'Analyse',
                controlPanel: 'Kontrolpanel',
                deviceManagement: 'Enhedsstyring',
                discounts: 'Rabatter',
                fetching: 'Henter',
                group: 'Gruppér',
                inventory: 'Skabsbeholdning',
                language: 'Sprog',
                productCatalog: 'Produktkatalog',
                productCategories: 'Produktkategorier',
                reports: 'Rapporter',
                settings: 'Brugerindstillinger',
                signOut: 'Log ud',
                title: 'Selfly Cloud Portal',
                vatCategories: 'Momskategorier',
                community: 'Selfly Community',
                refillManagement: 'Doplnění administration',
                automations: 'Automatiseringer',
                mySelfly: 'Mine MySelfly indstillinger',
                automationInfo: 'Administrer automatiserede handlinger (BETA)',
            },
            reports: {
                alreadyRefund: 'blev tilbagebetalt',
                amount: 'Beløb',
                average: 'Gennemsnit',
                barcode: 'Stregkode',
                confirmIssueRefundButton: 'Bekræft tilbagebetaling nu',
                current: 'Nuværende',
                export: 'Eksport',
                fetching: 'Henter',
                fetchingError: 'Der opstod en fejl under hentning af',
                image: 'Billede',
                issueRefundButton: 'T I L B A G E B E T A L I N G',
                maximum: 'Maksimum',
                minimum: 'Minimum',
                name: 'Navn',
                noData: 'Ingen data',
                note: 'Bemærk',
                order: 'Transaktion',
                paymentWasDoneByCreditCard: 'Betalingen blev foretaget med kreditkort',
                price: 'Pris',
                ProductReport: 'Produktrapport',
                quantity: 'Mængde',
                'Refund impossible':
                    'Betalingen blev foretaget med kreditkort. Kontakt udstederen af kreditkortterminalen for at få oplysninger om tilbagebetaling.',
                refundFormTitle: 'Tilbagebetaling',
                refundReason: 'Årsag til tilbagebetaling',
                refundSuccess: 'Beløb {{sum}} blev tilbagebetalt',
                refundTotal: 'Tilbagebetalt beløb',
                replenishments: 'Refills',
                replenishmentsTitle: 'Rapport om genopfyldning',
                SaleTransaction: 'Salgstransaktion',
                summary: 'Resumé',
                SummaryReport: 'Salgsoversigt',
                temperature: 'Temperatur',
                temperatureTitle: 'Temperaturrapport',
                temperatureValueNotification: 'Temperaturværdierne er timegennemsnitlige værdier for skabstemperaturen.',
                time: 'Tid',
                total: 'I alt',
                transactions: 'Transaktioner',
                user: 'Bruger',
                paidTransactions: 'Betalte transaktioner',
                unpaidTransactions: 'Ubetalte transaktioner',
                partiallyPaid: 'Delvis betalt',
                failedTransactions: 'Mislykkede transaktioner',
                amountOwed: 'Skyldigt beløb',
                receiptLinkText: 'Få kvittering herfra',
                noPurchase: 'Intet køb',
                other: 'Andet',
                userId: 'Bruger ID:',
            },
            settings: {
                block_cabinet_when_product_expired: 'Bloker skabet for udløbne produkter',
                criticalExpiryLimitHours: 'Timer til kritisk udløbsvarsel',
                language: 'Sprog',
                logo: 'Virksomhedens logo',
                mediumExpiryLimitHours: 'Timer til medium udløbsvarsel',
                payment_method_logo: 'Logo for betalingsmetode',
                footer_instruction_logo: 'Billede af instruktion i sidefoden',
                readUserMemory: 'Hent data om produktets udløbsdato',
                ui_skus_sort_by: 'Sorteringsrækkefølge for produkter i skabets display',
                timeZone: 'Tidszone',
                country: 'Land',
                city: 'By',
                tagSettings: 'Tagindstillinger',
                themeAndLogos: 'Tema og logoer',
                advancedSettings: 'Avancerede indstillinger',
                themingInfoText: 'Du kan tilpasse temaet for dit skabs skærm',
                cabinetLogos: 'Skabslogoer',
                cabinetLogoInfoText: 'Upload logoer, der vises på skabsskærmen',
                logoHelperText: 'Brug et billede på 180px X 180px',
                settingsCopyInfoHeader: 'Vælg de skabe, du vil kopiere indstillingerne til',
                settingsCopyButton: 'Kopier indstillinger til valgte skabe',
                settingsCopySuccessModalTitle: 'Kopierer indstillinger',
                settingsCopySuccessModalContent: 'Indstillinger kopieret til {{cabinetsAmount}} skab(e)',
                theming: 'Tema',
                adminSettings: 'Adminindstillinger',
                settingsCopyErrorModalTitle: 'Fejl ved kopiering af indstillinger',
                key: 'Indstilling',
                value: 'Værdi',
                sleepScheduling: 'Planlagt standby',
                sensitivityLevel: 'Følsomhedsniveau',
                sensitivity_0:
                    'Skabet ignorerer mærkeflimmer og markerer konsekvent alle mærker som gyldige, hvilket lejlighedsvis kan føre til fejlopkrævning af varer.',
                sensitivity_20: 'Skabet tolererer op til tre dårlige aflæsninger af et mærke inden for 50 scanninger og markerer stadig mærket som gyldigt.',
                sensitivity_40: 'Skabet tolererer op til to dårlige aflæsninger af et mærke inden for 60 scanninger og markerer stadig mærket som gyldigt.',
                sensitivity_60: 'Hvis et mærke flimmer hyppigt, men forbliver stabilt over længere tid, tolererer skabet dette og læser mærket som gyldigt.',
                sensitivity_80: 'Hvis et mærke flimmer kortvarigt, men forbliver stabilt i en længere periode, vil det blive betragtet som gyldigt.',
                sensitivity_100:
                    'Skabet er meget intolerant over for mærkeflimmer. Selv en enkelt dårlig aflæsning kan få et mærke til at blive markeret som ugyldigt i længere tid, hvilket kan resultere i potentielle tab.',
                sensitivityInfo:
                    'Skabets mærkekvalitetsfølsomhed refererer til, hvor effektivt skabet håndterer inkonsekvente mærkelæsninger eller mærkeflimmer. Vær opmærksom på det følsomhedsniveau, du vælger, da det kan påvirke skabets ydeevne. Hvis du er usikker på, hvilket niveau der er passende, anbefales det at lade indstillingen være på standardniveauet 3.',
            },
            signIn: {
                signInButton: 'Log ind',
                signInExpiredError: 'Din session er udløbet. Log ind igen',
                signInMessage: 'Du skal være logget ind for at fortsætte. Klik på knappen nedenfor for at logge ind',
                noOrganizationsError: 'Ingen organisationer er knyttet til dette brugernavn. Prøv et andet brugernavn.',
            },
            translations: {
                add: 'Tilføj',
                closed: 'Lukket',
                delete: 'Slet',
                device: 'Enhed',
                edit: 'Rediger',
                off: 'OFF',
                offline: 'Offline',
                on: 'ON',
                online: 'Online',
                opened: 'Åbn',
                save: 'Gem',
                seeDetails: 'Oplysninger',
                settings: 'Indstillinger',
                unknown: 'Ukendt',
            },
            consumerApp: {
                enableConsumerFeedback: 'Aktiver brugerfeedback på MySelfly',
                enablePurchaseReceipts: 'Aktiver e-kvitteringer på MySelfly',
                enableSelflyTrademarkForReceipts: 'Tillad Selfy Store-sidefod i e-kvitteringer',
                enableSelflyTrademarkForReceiptsHelpText: 'Hvis du aktiverer dette, tilføjes Selfly Store-logo og -oplysninger på e-kvitteringen i PDF-format!',
                organizationFormHelpText: 'Angiv venligst oplysninger om udstederen af e-kvitteringen.',
                organizationFormValidationHelpText:
                    '* De obligatoriske felter for e-kvittering er den udstedende organisations navn, virksomheds-id, adresse og land.',
                organizationName: 'Organisationens navn',
                organizationLogo: 'Organisationens logo',
                businessId: 'Virksomheds-id',
                city: 'By',
                email: 'E-mail',
                website: 'Websted',
                postalCode: 'Postnummer',
                street: 'Gade/vej',
                phoneNumber: 'Telefonnummer',
                addressHeader: '* Adresseoplysninger',
                contactDetails: 'Kontaktoplysninger',
                organization: '* Udstedende organisation',
                organizationSettingsUpdatedTitle: 'Opdatering af organisationsindstillinger',
                organizationSettingsUpdatedContent: 'Organisationsindstillingerne er blevet opdateret',
                organizationSettingsMissingFields: 'Der mangler obligatoriske felter til e-kvittering. Udfyld venligst, før du gemmer.',
                organizationSettingsMissingFieldsTitle: 'Fejl ved validering af formular',
            },
            softwareVersions: {
                updateNotificationTitle: 'Opdatering af softwareversion',
                updateNotificationContent: 'Softwareversion(er) er blevet opdateret',
                selectAVersionPlaceholder: 'Vælg en version',
                searchDevices: 'Søg efter enheder',
                updateSelected: 'Opdatering valgt',
                softwareVersions: 'Softwareversioner',
                softwareVersion: 'Softwareversion',
                notSupportedDeviceNote: 'Version {{selectedVersion.version}} understøttes ikke på denne enhed',
                userNotificationContent: 'Der er blevet udgivet en ny softwareversion til dine enheder. Opdater dine enheder for at få de nyeste funktioner.',
                userNotificationTitle: 'Der er udgivet en ny softwareversion',
                notificationBell_newSoftwareVersions: 'Der er opdateringer til dine enheder',
                manageVersions: 'Administrer versioner af enhedssoftware',
                filterLocationsPlaceholder: 'Filtrer efter steder',
                allVersionNumbers: 'Alle udgivelser',
                noVersionsForUpdating: 'Ingen versioner til opdatering',
                scheduledUpdateNote: 'Version {{version}} er planlagt til at blive opdateret {{planlagt}}',
                scheduleRemoveNotificationTitle: 'Planlægning af softwareversion',
                scheduleRemoveNotificationContent: 'Softwareversionens planlagte opdatering er fjernet',
                scheduleRemoveNotificationModalContent: 'Er du sikker på, at du vil fjerne den planlagte opdatering for denne enhed',
            },
            header: {
                searchByOrganization: 'Søg efter organisation',
                pageLanguage: 'Sprog',
                configureMySelflyAndEreceipts: 'Konfigurer MySelfly-indstillinger',
                remindMeLater: 'Mind mig om det senere',
                updateNow: 'Opdatere nu',
            },
            notifications: {
                noNewNotifications: 'Ingen nye meddelelser',
            },
            cabinetSettings: {
                cabinetTheme: 'Skabstema',
                editAndSaveTheme: 'Rediger og gem tema',
                colorPicker: 'Farvevælger',
                customTheme: 'Brugerdefineret tema',
                themeSettings: 'Temaindstillinger',
                primary: 'Primært',
                negative: 'Negativt',
                attention: 'Opmærksomhed',
                positive: 'Positivt',
                background: 'Baggrund',
                dark: 'Mørk',
                light: 'Lys',
                white: 'Hvid',
                default: 'Standard',
                negativeLight: 'Negativt lys',
                primaryColor: 'Primær farve',
                positiveColors: 'Positive farver',
                negativeColors: 'Negative farver',
                secondaryColors: 'Sekundære farver',
                attentionColors: 'Opmærksomhedsfarver',
                screenBackground: 'Skærmbaggrund',
                borderColors: 'Kantlinjer - Skygger',
                shadow: 'Skygge',
                border: 'Kantlinje',
                fontColors: 'Farver på skrifttyper',
                footerTextPlaceholder: 'Tekst i sidefoden',
                bundleDiscounts: 'Kombiner og gem',
                expiringText: 'Produkter, der udløber. Kontroller udløbsdatoen, før du køber',
                expiredText: 'Udløbne produkter. Kontroller udløbsdatoen, før du køber',
                cabinetName: 'Navn på skab',
                productName: 'Produktnavn',
                confirmationModalHeader: 'Er du sikker på, at du vil gemme det tilpassede tema?',
                confirmationModalContent:
                    'Tilpasning af temaet påvirker det visuelle udseende af skærmen på dit skab. Sørg for, at ændringerne ikke går ud over den visuelle kvalitet af skærmen.',
                defaultRevertModalMessage: 'Er du sikker på, at du ønsker at ændre skabets tema til standard?',
                backgroundColor: 'Baggrundsfarve',
                backgroundImage: 'Baggrundsbillede',
                footerBackground: 'Fodnotens baggrund',
                cardsBackground: 'Baggrund for kort',
                screenImage: 'Billede til skabskærmens baggrund',
                uploadImage: 'Upload billede',
                editImage: 'Rediger billede',
                transactionComplete: 'Transaktion fuldført',
                customPostPurchaseImage: 'Upload venligst et billede til den brugerdefinerede skærm efter køb',
                postPurchaseScreen: 'Skærm efter køb',
                custom: 'brugerdefineret',
                postPurchaseText: 'Visningstekst efter køb',
            },
            automations: {
                transactionReports: 'Transaktionsrapporter',
                refillReports: 'Genopfyldningsrapporter',
                notifications: 'Underretninger',
                refillPlan: 'Genopfyldningsplaner',
                days: 'Dage',
                months: 'Måneder',
                weeks: 'Uger',
                configuration: 'Konfiguration',
                transactionReportTitle:
                    'Transaktionsrapporter for de valgte skabe vil automatisk blive genereret og sendt til de nedenfor angivne e-mailadresser',
                refillReportTitle: 'Genopfyldningsrapporter for de valgte skabe vil automatisk blive genereret og sendt til de nedenfor angivne e-mailadresser',
                noSalesNotificationTitle:
                    'Underretninger vil automatisk blive sendt til de nedenfor angivne e-mailadresser i tilfælde af, at der ikke er nogen salg i de definerede intervaller',
                frequencyTitle: 'Hvor ofte ønsker du, at rapporten skal genereres?',
                emailNotificationTitle: 'Tilføj venligst de nedenfor angivne e-mailadresser, hvor rapporten eller underretningen skal sendes',
                refillPlanGenerationTitle: 'Genopfyldningsplaner vil automatisk blive genereret for de valgte skabe i henhold til den angivne tidsplan',
                addNewEmail: 'Tilføj ny e-mail',
                noSalesBanner: 'Du vil modtage en e-mailunderretning, hvis de valgte skabe ikke foretager salg i den konfigurerede tidsperiode',
                noSaleTitle: 'Angiv venligst varigheden (i timer) uden salg i de valgte skabe, før en underretning sendes',
                minMaxTime: 'Min er 2 og max er 23 timer',
                timeRangeTitle: 'Angiv venligst tidsintervallet for skabene, hvor der vil blive tjekket for ingen salgssituation',
                startTime: 'Starttid',
                endTime: 'Sluttid',
                refillPlanTitle:
                    'Genopfyldningsplanen for de valgte skabe vil automatisk blive genereret. Planlæg venligst nedenfor det specifikke tidspunkt, hvor genopfyldningsplanen skal oprettes.',
                refillPlanTime: 'Vælg venligst det tidspunkt, hvor genopfyldningsplanen skal oprettes',
                everyDay: 'Hver dag',
                everyWeek: 'Hver uge',
                everyMonth: 'Hver måned',
                selectDays: 'Vælg dage',
                automationSaved: 'Automatiseringsindstillingerne blev gemt succesfuldt',
                automationDeleted: 'Automatiseringsindstillingerne blev slettet succesfuldt',
            },
            sleepScheduling: {
                timeRangeTitle: 'Angiv venligst tidsintervallet, hvori dette skab vil gå i standbytilstand.',
                startTime: 'Starttid',
                endTime: 'Sluttid',
                selectDays: 'Vælg dage',
                duration: 'Varighed (timer)',
            },
        },
        cs: {
            dateRangeFilter: {
                lastMonth: 'poslední měsíc',
                lastTwoWeek: 'poslední 2 týdny',
                lastWeek: 'poslední týden',
                selectDateRangeMsg: 'Vyber časové období',
                today: 'dnes',
                yesterday: 'včera',
            },
            main: {
                activateSuccess: 'Produkt byl úspěšně aktivován',
                activationDate: 'Datum aktivace',
                active: 'Aktivní',
                addAdvertisement: 'Přidej reklamu',
                addDiscountRule: 'Nastavte pravidlo slevy',
                addDiscountStop: 'Nastaveni min. doby trvanlivosti',
                addNewLocation: 'Vyber novou skupinu ',
                addProduct: 'Přidej nový produkt',
                addRegion: 'Přiřaď do skupiny',
                addRegionPrice: 'Přiřaď cenovou skupinu',
                addScheduledDiscount: 'Přiřaď nastavenou slevu',
                addBundleDiscount: 'Přiřaď slevový balíček',
                bundledDiscounts: 'Slevový balíček',
                bundleItemsLengthExceeded: 'Překročen počet max. 3 položek. Pro pokračování odeberte produkt(y).',
                bundleMaxProductLimitInfo: 'Označ 3 produkty, nebo kategorii produktů',
                bundleFixedAmountExceededOriginal: 'Pevná cena balíčku převyšuje původní cenu. Zkontrolujte vybrané produkty.',
                bundleFixedAmountNotAllowedToBeZero: 'Pevná cena balíčku musí být vyšší než 0',
                bundlePercentageNotAllowedToBeFull: 'Procentuální sleva balíčku musí být nižší než 100%',
                bundlePercentageNotAllowedToBeZero: 'Procentuální sleva balíčku musí být vyšší než 0%',
                bundleItemDiscountPriceAmountExceededOriginal: 'Nová zvýhodněná cena převyšuje původní cenu. Znovu zkontrolujte novou cenu.',
                bundleItemPriceMessage: 'Každé cenové pravidlo se vztahuje pouze na 1 položku, a to i v případě, že je vybráno více stejných položek',
                bundleItemFixedAmountExceededOriginal: 'Pevná cena produktu převyšuje původní cenu produktu. Znovu zkontrolujte pevnou cenu.',
                createDiscountRule: 'Vytvořte pravidlo slevy',
                deviceType: 'Typ zařízení',
                discountUpdateTitle: 'Aktualizace slev',
                discountCreateTitle: 'Vytvoření slevy',
                discountDeleteTitle: 'Odstranění slevy',
                discountDeleteContent: 'Sleva byla zrušena',
                discountStatusUpdated: 'Stav slevy byl aktualizován',
                discountUpdated: 'Sleva byla aktualizována',
                discountCreated: 'Sleva byla nastavena',
                discountSettings: 'Nastavení slevy',
                discountImage: 'Volitelný obrázek slevy',
                editDiscountStop: 'Upravit interval platnosti',
                editBundleConfiguration: 'Úprava konfigurace balíčku.',
                editExpiryConfiguration: 'Úprava konfigurace slevy',
                encodingStation: 'Kódovací stanice',
                everyDay: 'Každý den',
                scheduledDiscountDateInfoLabel: 'Pokud chcete, aby sleva byla trvalá, ponechte datum začátku a konce prázdné.',
                starts: 'Začátek',
                ends: 'Konec',
                error: 'Chyba',
                errorRetiringLocationMsg: 'Vyřazení umístění se nezdařilo. Nejprve odstraňte propojená zařízení, ceny produktů a reklamy.',
                fetchingDiscountRules: 'Načítání slevových pravidel',
                free: 'Zdarma',
                missingImage: 'Chybí obrázek',
                next: 'Další',
                back: 'Zpět',
                noDiscountRules: 'Nebyla nalezena žádná pravidla slevy',
                scheduledDiscountsAutomaticDeactivationInfo:
                    'Slevy, při kterých je překročeno časové období, jsou automaticky deaktivovány. Pokud je chcete aktivovat, upravte časové období tak, aby bylo platné.',
                scheduledDiscounts: ' Plánované slevy',
                schedulingStartHourIsOverEndHour: 'Začátek času slevy musí být před časem ukončení slevy',
                schedulingEndHourIsUnderStartHour: 'Konec času slevy musí být po čase začátku slevy',
                selectDiscountRuleProductCategories: 'Vyberte kategorie produktů týkajících se pravidla slevy',
                selectBundleProductsAmount: 'Vyberte počet pro',
                selectedBundleProductCategories: 'Vyberte slevovou skupinu produktů',
                setDiscountDays: 'Nastav dny slevy',
                setBundleDiscount: 'Nastavte buď procentuální slevu, nebo pevnou cenu balíčku',
                setBundleItemDiscount: 'Nastavte jednu z těchto možností pro jednu položku',
                setBundleProducts: 'Nastavte kategorii produktu, nebo balíčku',
                setAmountMoreThanOne: 'V případě balíčku s jednou položkou, nastavte množství více než jedna',
                setBundleDiscountType: 'Nastavte typ a výši slevy',
                sureActivateDiscountRule: 'Opravdu chcete aktivovat pravidlo slevy?',
                sureDeactivateDiscountRule: 'Opravdu chcete deaktivovat pravidlo slevy?',
                sureDeleteDiscountRule: 'Opravdu chcete vymazat pravidlo slevy?',
                timeRange: 'Doba trvání',
                editExpiryDiscountRule: 'Uprav dobu platnosti pravidla slevy',
                editScheduledDiscountRule: 'Uprav pravidlo plánované slevy',
                editBundleDiscountRule: 'Uprav pravidlo pro slevový balíček',
                releaseNotes: 'Poznámky',
                seeMore: 'Zobrazit více',
                scheduledDiscountTimeRangeLabel: 'Aktivní čas',
                scheduledDiscountDateRangeLabel: 'Datum slevy od..do..',
                weekDayAbrvMonday: 'Po',
                weekDayAbrvTuesday: 'Út',
                weekDayAbrvWednesday: 'St',
                weekDayAbrvThursday: 'Čt',
                weekDayAbrvFriday: 'Pá',
                weekDayAbrvSaturday: 'So',
                weekDayAbrvSunday: 'Ne',
                recurrence: 'Opakování',
                addScheduledDiscountStop: 'Přidat plánovanou slevu',
                editScheduledDiscountStop: 'Změnit plánovanou slevu',
                pieces: 'ks',
                expiryDiscountNavLabel: 'Ukončení',
                scheduledDiscountNavLabel: 'Plánovaná',
                bundleDiscountNavLabel: 'Balíček',
                amount: 'Množství',
                scheduling: 'Plánování',
                pricing: 'Stanovení cen',
                percentageBundleDiscountInputPlaceholder: '% sleva na balíček',
                fixedPriceBundleDiscountInputPlaceholder: 'Pevná cena za balíček',
                applyBundleLevelLabel: 'Nastav úroveň balíčku',
                applyItemLevelLabel: 'Použít úroveň produktu',
                productFixedPrice: 'Pevná cena produktu',
                percentageDiscount: '% sleva',
                fixedPriceDiscount: 'Pevná cena',
                freeDiscount: 'Zdarma',
                advertisementsFetchError: 'Při načítání reklam došlo k chybě. Ujistěte se, že je blokování reklam vypnuté',
                advertisementsTitle: 'Reklama',
                all: 'Vše',
                'All time': 'Celou dobu',
                allProducts: 'Všechny produkty mimo skupiny',
                allLocations: 'Všechny lokality',
                allCabinets: 'Všechny kabinety',
                antennaDown: 'Některé RFID antény nefungují',
                antennaError: 'Chyba v kabinetových anténách',
                antennaNoInfo: 'Pro kabinetové antény nejsou k dispozici žádné informace',
                antennaOk: 'Všechny antány fungují správně',
                antennaPartial: 'Některé antény jsou deaktivovány',
                archiveSuccess: 'Produkty byly úspěšně archyvovány',
                averageItems: 'Průměrný počet položek na transakci',
                averageSales: 'Průměrný prodej na transakci',
                barcode: 'Čárový kód',
                barcodeType: 'Typ čárového kódu',
                barcodeCannotBeChanged: 'Čárový kód nelze změnit',
                barcodeTypeCannotBeChanged: 'Typ čárového kódu nelze změnit',
                barcodeHelpText12: 'Zadejte 12 číslic čárového kódu, poslední číslice se připojí automaticky',
                barcodeHelpText11: 'Zadejte 11 číslic čárového kódu, poslední číslice se připojí automaticky',
                barcodeHelpText7: 'Zadejte 7 číslic čárového kódu, poslední číslice se připojí automaticky',
                beforeExpiry: 'před vypršením platnosti',
                cabinetDown: 'Porucha kabinetu',
                cabinetIsEmpty: 'Kabinet je prázdný',
                deviceOffline: 'Zařízení není připojeno k síti',
                deviceOnline: 'Zařízení je připojeno k síti',
                cabinets: 'Kabinety',
                cabinet: 'Kabinet',
                cabinetSettings: 'Nastavení kabinetu',
                cabinetTheming: 'Motivy kabinetu',
                cancel: 'Zrušit',
                cancelledSales: 'Sleva zrušena',
                cancelledTransactions: 'Transakce zrušena',
                categories: 'Kategorie',
                change: 'Změna',
                clear: 'Vymazat',
                close: 'Ukončit',
                clickEditButtonRegionalPrices: 'Chcete-li upravit lokaci ceny, klikněte na tlačítko "Upravit" u daného produktu.',
                clickEditLocationPrices: 'Lokaci ceny změníte kliknutím na cenu produktu.',
                clickToExpandLocationPrices: 'Lokaci ceny rozšíříte kliknutím na cenu produktu.',
                closeDoor: 'Zavřete dvířka kabinetu',
                rebootDevice: 'Restartujte zařízení',
                commandSentSuccessFully: 'Příkaz byl úspěšně odeslán',
                companyLogo: 'Firemní logo',
                confirm: 'Potvrdit',
                confirmDelete: 'Opravdu chcete produkt smazat? Tuto akci nelze vrátit zpět!',
                confirmDisable: 'Opravdu chcete produkt uložit?',
                confirmEnable: 'Opravdu chcete, aby byl produkt aktivní?',
                consumerResponses: 'Hodnocení užívatele',
                controlPanelTitle: 'Kontrolní panel',
                controls_acceptablePercentageOfBadTags: 'Přijatelné procento špatných štítků před tím, než kabinet přejde do režimu mimo provoz',
                controls_markTagsGoodQuality: 'Označte všechny štítky jako kvalitní',
                controls_markTagsGoodQuality_btn: 'Označte štítky jako kvalitní',
                controls_clearTagHistory: 'Vymazat historii štítků',
                controls_clearRemoveReplenishmentData: 'Vymazat údaje o doplňování',
                controls_companyAndPaymentLogo: 'Firemní a platební logo',
                controls_detectTagQuality: 'Zjistit kvalitu štítků',
                controls_door: 'Ovládání dveří',
                controls_device: 'Ovládání zařízení',
                controls_chargeBadTags: 'Přeprogramování chybných štítků',
                controls_refill: 'Doplnit',
                controls_tagQuality: 'Nastavení štítků',
                controls_tagQualityData: 'Údaje o kvalitě štítků',
                controls_replenishmentData: 'Údaje o doplňování',
                controls_soldTags: 'Prodané štítky',
                controls_tagHistory: 'Historie štítků',
                controls_markAllTagsReplenished: 'Označte všechny štítky jako doplněné',
                controls_markAllTagsUnsold: 'Označte všechny štítky jako neprodané',
                controls_markAlTagsReplenished_btn: 'Označte štítky jako doplněné',
                controls_markAllTagsUnsold_btn: 'Označte štítky jako neprodané',
                controls_chargeSoldTags: 'Účtovat prodané štítky',
                controls_chargeUnreplenishedTags: 'Účtovat doplněnéné štítky',
                controls_tagsWarn:
                    'Vymazáním historie štítků odstraníte všechna data o štítcích. Klikněte prosím na označit všechny doplněné štítky po této akci.',
                create: 'Vytvoř',
                createAdTitle: 'Nová reklama',
                createdAd: 'Reklama vytvořena',
                createExpiryDiscountRule: 'Vytvořte pravidlo pro vypršení platnosti slevy',
                createScheduledDiscountRule: 'Vytvořte pravidlo pro opakování slevy',
                createBundleDiscountRule: 'Vytvořte pravidlo pro slevový balíček',
                createNewVatCategory: 'Nová kategorie DPH',
                createProductTitle: 'Nový produkt',
                creatingAd: 'Vytváření reklamy',
                creatingProduct: 'Vytváření produktu',
                createNewProductCategory: 'Vytvoř novou kategorii produktů',
                createNewSupplier: 'Přidej nového dodavatele/výrobce',
                currency: 'Měna',
                customQuery: 'Vlastní dotazy',
                dashboard: 'Hlavní stránka',
                dateRange: 'Datum od…do..',
                dataAlredyFetchedTitle: 'Data již byla načtena',
                dataAlreadyFetchedDescription: 'Zvolte prosím jiné parametry',
                day: 'Den',
                days: 'Dny',
                deactivationDate: 'Datum deaktivace',
                default: 'Výchozí',
                delete: 'Vymazat',
                deleteError: 'Chyba',
                deleteSuccess: 'Produkt byl úspěšně odstraněn',
                description: 'Popis',
                deviceManagement: 'Správa zařízení',
                digitwinOffline: 'Zařízení digitwin je offline',
                digitwinOnline: 'Zařízení digitwin je online',
                dimensions: 'Rozměry',
                disable: 'Archivovat',
                discountAmount: 'Výše slevy',
                discountPrice: 'Cena po slevě',
                discountRuleAffects: 'Ovlivňuje slevu',
                discountRuleName: 'Název slevy',
                discountRuleStops: 'Intervaly min. doby trvanlivosti',
                discounts: 'Slevy',
                discountSalesData: 'Data prodeje ve slevě',
                discountSalesAmount: 'Částka prodeje ve slevě',
                discountTimeBeforeExpiryExists: 'Dřívější interval min.doby trvanlivosti již existuje',
                discountVsNormalSales: 'Sleva oproti běžnému prodeji',
                dismiss: 'Nepovolit',
                doesNotExist: 'Neexistující',
                doorCloseConfirm: 'Opravdu chcete zavřít dvířka kabinetu?',
                doorCloseSuccess: 'Zamčení dvířek kabinetu proběhlo úspěšně',
                doorOpenConfirm: 'Opravdu chcete otevřít dvířka kabunetu?',
                doorOpenReason: 'Důvod pro otevření dvířek kabinetu?',
                doorOpenSuccess: 'Dvířka kabinetu úspěšně otevřeny',
                rebootDeviceSuccess: 'Zařízení bylo úspěšně restartováno',
                deviceRebootConfirm: 'Opravdu chcete restartovat zařízení?',
                edit: 'Změnit',
                editCompanyLogo: 'Změnit logo společnosti',
                editDiscountRule: 'Změnitit výpočet slevy',
                editPaymentMethodLogo: 'Změnit logo platební metody',
                editVatCategoryModalTitle: 'Změnit kategorii DPH',
                editProductCategoryModalTitle: 'Změnit kategorii produktu',
                editSupplierModalTitle: 'Změnit dodavatele/výrobce',
                enable: 'Aktivovat',
                error_401: 'Neověřeno',
                error_403: 'Nemáte oprávnění provádět příkazy',
                error_500: 'Neočekávaná chyba serveru',
                errorCreatingAd: 'Při vytváření reklamy došlo k chybě',
                errorCreatingProduct: 'Při zadávání produktu došlo k chybě',
                errorFetchingCabinetStatus: 'Při načítání stavu kabinetu došlo k chybě',
                errorFetchingInventory: 'Při načítání inventáře kabinetu došlo k chybě',
                errorFetchingReport: 'Při načítání přehledů došlo k chybě',
                errorFetchingSettings: 'Při načítání nastavení došlo k chybě',
                errorUpdatingProduct: 'Při aktualizaci produktu došlo k chybě',
                expandToSeeBadTags: 'Pro zobrazení štítků, které byly detekovány jako chybné, otevřete seznam. Chybné štítky je třeba vyjmout z kabinetu. ',
                expandToSeeSoldItems: 'Pro zobrazení položek, které jsou již prodané, ale znovu se objevily v inventáři otevřete seznam.',
                expandToSeeReplenishedOutOfInventory:
                    'Pro zobrayení produktů, které byly umístěny do kabinetu v jinak, než je doplňovacím režímu otevřete seznam.',
                expired: 'Prošlá doba mim. Trvanlivosti',
                expiredCount: 'Počet min. trvanlivostí',
                expiredProductsCount: 'Počet produktů s prošlou min. trvanlivostí',
                expiredSum: 'Částka za produkty s prošlou min. dobou trvanlivosti',
                expiring: 'Procházející min. doba trvanlivosti',
                expiryDate: 'Poslední den prodeje',
                exportExcel: 'Převést na Excel',
                fetchAdvertisements: 'Načítání reklam',
                fetchingCabinets: 'Načítání kabinetů',
                fetchingCabinetsError: 'Při načítání kabinetů došlo k chybě',
                fetchingCabinetStatus: 'Načítání stavu kabinetu',
                fetchingError: 'Při načítání došlo k chybě',
                fetchingInventory: 'Načítání obsah kabinetu',
                fetchingProducts: 'Načítání produktů',
                fetchingReport: 'Načítání zpráv',
                fetchingVatCategories: 'Načítání kategorií DPH',
                fetchingProductCategories: 'Načítání skupin produktů',
                fillForm: 'Vyplňte formulář',
                freshFood: 'Toto je vypršení produktu',
                freezerPackage: 'Mražený produkt',
                generalFetchError: 'Načítání chyb',
                generalRequestReject: 'Chyba požadavku',
                get: 'Přijmout',
                group: 'Skupina',
                holdOn: 'Chvilku strpení',
                hour: 'hodina',
                hours: 'hodiny',
                hoursBeforeExpiry: 'Hodiny před uplynutím min. doby trvanlivosti',
                daysBeforeExpiry: 'Dny před uplynutím min. doby trvanlivosti',
                image: 'Obrázek',
                imageOrVideo: 'Vyberte obrázek, nebo video',
                inActive: 'Neaktivní',
                individualCabinets: 'Samostatné kabinety',
                individualCabinetsOnly: 'Pouze samostatné kabinety',
                invalidBarcodeOnlyDigits: 'Čárový kód musí obsahovat pouze číslice',
                invalidBarcodeTooLong: 'Čárový kód je příliš dlouhý',
                invalidBarcodeTooShort: 'Čárový kód je příiš krátký',
                invalidCabinetNameTooShort: 'Příliš krátké jméno kabinetu',
                invalidCabinetNameDuplicated: 'Jméno kabinetu již existuje',
                invalidLocationNameTooShort: 'Název místa je přípiš krátký',
                invalidLocationNameDuplicated: 'Název místa je duplicitní',
                invalidPercentage: 'Neplatná hodnota',
                invalidPrice: 'Neplatná cena',
                invalidProductBarcode: 'Neplatný čárový kód produktu',
                invalidTitle: 'Neplatný název',
                invalidBundleItems: 'Balíček obsahuje neplatné položky',
                inventoryLength: 'Délka inventáře',
                inventoryNotReported: 'Kabinet nenahlásil svůj inventář',
                inventoryTitle: 'Obsah kabinetu',
                'Inventory History': 'Historie obsahu',
                itemReplenishedOutOfInventoryProcess: 'Tento produkt nebo štítek byl vložen do kabiinetu v jiném než doplňovacím režimu!',
                itemSoldOn: 'Produkt prodán v',
                'Last 30 days': 'Uplynulých 30 dnů',
                'Last 7 days': 'Uplynulých 7 dnů',
                'Last month': 'Uplynulý měsíc',
                'Last quarter': 'Uplynulé 2 týdny',
                'Last week': 'Uplynulý týden',
                'Last year': 'Uplynulý rok',
                lastUpdate: 'Poslední aktualizace',
                lifetime: 'Po dobu životnosti',
                location: 'Místo',
                locations: 'Místa',
                logoEditor: 'Editor Laga',
                logoSettings: 'Nastavení Loga',
                loading: 'Nahrávání',
                measure: 'Měření',
                measures: 'Měření',
                minsBeforeExpiry: 'Minuty do vzpršení min. doby trvanlivosti',
                minutes: 'minuty',
                month: 'Měsíc',
                name: 'Název',
                newVatCategoryModalTitle: 'Nová kategorie DPH',
                newProductCategoryModalTitle: 'Nová skupina produktů',
                newSupplierModalTitle: 'Nový dodavattel/výrobce',
                'no region': '(bez umístění)',
                noAdvertisements: 'Bez reklam',
                noCabinets: 'Žádný kabinet',
                noCabinetSelected: 'Není zvolen žádný kabinet',
                noLogo: 'Bez loga',
                noData: 'Bez datumu',
                noDescription: 'Bez popisu',
                noEvents: 'Žádné události!',
                noIndividualCabinets: 'Žádný samostatný kabinet',
                noLocationPrices: 'Pro tento produkt nejsou definovány žádné místní ceny',
                none: 'Žádný',
                noPaymentMethodLogo: 'Žádné logo platební metody',
                noProducts: 'Žádný produkt',
                noProductsRemoved: 'Žádný produkt nebyl odebrán',
                noProductTypeSelected: 'Nebyl vybrán druh obalu',
                normalPrice: 'Normální cena',
                normalSalesAmount: 'Suma normální ceny',
                notAccessibleForYou:
                    'Funkce Vytvoření/Změna je povolena pouze pro administrátory a správce kabinetu. Chcete-li získat přístup, kontaktujte svého nadřízeného nebo správce systému.',
                notDefined: 'Nedefinováno',
                noTitle: 'Bez názvu',
                noVatCategories: 'Nebyl nalezen žádný  kategorie DPH',
                noProductCategories: 'Nebyla nalezena žádná skupiny produktú',
                noInventoryOnSelectedDate: 'Pro zvolené období nebyly zaznamenány žádné inventáře. Změňte datum.',
                openDoor: 'Otevřete dvířka kabinetu',
                orderSum: 'Částka objednávky',
                paymentMethodLogo: 'Logo platební metody',
                percentage: 'Procenta',
                permanent: 'Trvalé',
                preview: 'Náhled',
                price: 'Cena',
                print: 'Tisk',
                productCreated: 'Produkt vytvořen',
                products: 'Produkty',
                productsFetchError: 'Při načítání produktů došlo k chybě',
                productsTitle: 'Katalog produktů',
                productType: 'Druh obalu',
                productUpdated: 'Produkt aktualizován',
                quantity: 'Množství',
                readerDown: 'Čtečka RFID štítků je mimo provoz',
                region: 'Místo',
                regions: 'Místa',
                removedAt: 'Odebráno v',
                removeImage: 'Odeber obrázek',
                removeVideo: 'Odeber video',
                refillQRCodeInfo: 'Pro otevření aplikace pro dopnění kabinetu naskenujte QR kód, nebo postupujte dle pokynů',
                refillQRCodeTitle: 'QR kód pro doplnění produktů do kabinetu',
                refillRuleTitle: 'Pravidla pro doplnění kabinetu',
                reportsTitle: 'Zprávy',
                requestSuccess: 'Změny byly úspěšně provedeny',
                requiredPercentage: 'Povinný údaj. Procentuální hodnota bez znaménka procenta.',
                requiredText: 'Požadovaná délka znaků',
                retireLocation: 'Požadované umístění',
                rfidNoInfo: 'Nejsou dostupné žádné informace o RFID čtečce ani anténách',
                rfidOk: 'RFID čtečka a antény fungují správně.',
                sales: 'Prodeje',
                salesCount: 'Počet prodejů',
                salesVsWasteAmount: 'Prodej vs množství odpadu',
                save: 'Uložit',
                search: 'Vyhledávání',
                selectCabinet: 'Výběr kabinetu',
                selectDiscountRuleCabinets: 'Vyberte kabinety dle slevových pravidel',
                selectDiscountRuleLocations: 'Vyberte místa dle slevových pravidel',
                selectDiscountRuleProducts: 'Vyberte produkty dle slevových pravidel',
                selectProductType: 'Vyberte prosím typ balení pro váš produkt',
                setDiscountAmount: 'Nastavte výši slevy',
                setDiscountTimeBeforeExpiry: 'Nastavte interval vypršení min.doby trvanlivosti',
                settingConfirmMessage: 'Opravdu  si přejete tato nastavení uložit? Pokud si nejste jisti, nepotvrzujte to',
                showDisable: 'Archivované produkty',
                summary: 'Souhrn',
                sureRemoveDiscountRule: 'Opravdu chcete odstranit pravidlo slevy?',
                tagIsBad: 'Chybný štítek, je třeba jej vyjmout z kabinetu.',
                tagsRemovedReport: 'Přehled odstraněných štítků',
                taxValue: 'Hodnota daně',
                'This month': 'Tento měsíc',
                'This quarter': 'Těchto 14 dnů',
                'This week': 'Tento týden',
                'This year': 'Tento rok',
                thisLink: 'tento odkaz.',
                timeGrouping: 'Časové skupiny',
                title: 'Název',
                Today: 'Dnes',
                topExpiredProducts: 'Nečastěší produkty s prošlou dobou min. trvanlivosti',
                topProducts: 'Nejčastější produkty',
                total: 'Celkem',
                totalValue: 'Celková hodnota',
                transactions: 'Transakce',
                ttlInDays: 'Doba spotřeby produktu ve dnech',
                ttlsAffected: 'Produkty, které jsou ovlivněny dobou spotřeby.',
                unexpectedError: 'Nečekaná chyba',
                uniqueCustomers: 'Zvláštní zákazníci',
                unknown: 'Neznamé',
                update: 'Aktualizace',
                updateAdError: 'Při aktualizaci reklamy došlo k chybě',
                updateAdTitle: 'Aktualizovat reklamu',
                updatedAd: 'Reklama aktualizována',
                updateCabinetSettings: 'Synchronizace  dat s kabinetem',
                updateCabinetSettingsInfo: 'Opravdu chcete aktualizovat nastavení kabinetu?',
                updateData: 'Aktualizovat data',
                updateProductTitle: 'Aktualizovat produkt',
                updatingAd: 'Aktualizuji reklamu',
                updatingProduct: 'Aktualizuji produkt',
                uploading: 'Nahrávám',
                uploadingImage: 'Nahrávám obrázek',
                use: 'Použití',
                'w/o grouping': 'Žádné seskupování',
                waste: 'Odpad',
                vatCategoriesFetchError: 'Chyba při načítání kategorií DPH',
                vatCategoriesTitle: 'Kategorie DPH',
                vatCategory: 'Kategorie DPH',
                vatCategoryItems: 'Položky',
                vatCategoryName: 'Jméno kategorie DPH',
                vatCategoryPromptMessage: 'Opravdu chcete smazat kategorii DPH?',
                vatCategoryValue: 'Procento hodnoty daně',
                vatDeleteRejected: 'Odstranění kategorie DPH zamítnuto. Zkontrolujte související produkty',
                vatErrorRequesting: 'Chyba při zpracování požadavku',
                vatRequestRejected: 'Požadavek zamítnut',
                vats: 'DPH',
                productCategoriesFetchError: 'Chyba při načítání skupin produktů',
                productCategoriesTitle: 'Skupiny produktů',
                productCategory: 'Skupina produktů',
                productCategoryItems: 'Skupina',
                productCategoryName: 'Jméno skupiny produktů',
                productCategoryPromptMessage: 'Opravdu chcete smazat skupinu produktů?',
                productCategoryDescription: 'Popis kategorie produktů',
                productCategoryHelpTextDescription: 'Např. skupina Saláty',
                productCategoryHelpTextName: 'Např. Saláty',
                productCategoryDeleteRejected: 'Odstranění skupiny produktů bylo zamítnuto. Zkontrolujte související produkty!',
                week: 'Týden',
                viewMode: 'Náhled',
                year: 'Rok',
                Yesterday: 'včera',
                groupBy: 'Skupina vytvořená',
                filterBy: 'Filtrovat podle',
                reportType: 'Typ zprávy',
                custom: 'Vlastní',
                preDefined: 'Předdefinované',
                selected: 'Vybrané',
                metricsInfo: 'Všchny hodnoty (součet, maximum, minimum...) jsou počítány v desítkové/Metrické soustavě.',
                dimensionsInfo: 'Zvolenéhodnoty budou načteny v desítkové/Metrické soustavě',
                metrics: 'Desítková/Metricka soustava',
                controlsSoftwareVersion: 'Verze Softwaru',
                currentSoftwareVersion: 'Aktuální verze Softwaru',
                availableSoftwareVersion: 'Dostupná verze softwaru',
                invalidLifetime: 'Neplatna dodab trvanlivosti',
                unpaidSales: 'Nezaplacené prodeje',
                unpaidTransactions: 'Nazaplacené transakce',
                softwareUpdateConfirmation: 'Opravdu chcete aktualizovat verzi softwaru pro vybraná zařízení?',
                softwareUpdateWarn:
                    'Aktualizace verze softwaru odešle zařízení (zařízením) příkaz k načtení aktualizace nyní nebo ve zvolený čas. Vybraná zařízení nainstalují aktualizaci, jakmile budou online po aktivaci. Instalace může nějakou dobu trvat v závislosti na rychlosti připojení. Zařízení se po instalaci restartují.',
                releaseVersion: 'Vydané verze',
                releaseDate: 'Datum vydání',
                latestRelease: 'Nejnovější verze',
                currentVersion: 'Současná verze',
                softwareUpdateSuccess: 'Verze softwaru byla aktualizována',
                prebooking: 'Předběžná rezervace',
                prebookedProduct: 'Předběžná rezervace produktu',
                prebookedProducts: 'Předběžná rezervace produktů',
                activeProducts: 'Aktivní produkty',
                startMaintenanceMode: 'Spustit kontrolu kabinetu',
                endMaintenanceMode: 'Ukončení kontroly kabinetu',
                startMaintenanceConfirm: 'Opravdu chcete spustit režim kontroly kabinetu?',
                endMaintenanceConfirm: 'Opravdu chcete ukončit režim kontroly kabinetu?',
                startMaintenanceSuccess: 'Režim kontroly spuštěn.',
                endMaintenanceSuccess: 'Režim kontroly ukončen.',
                noResults: 'Žádné výsledky',
                suppliers: 'Dodavatelé/výrobci',
                supplier: 'Dodavatel/výrobce',
                supplierDeleteRejected: 'Odstranění dodavatele/výrobce zamítnuto. Zkontrolujte související produkty!',
                supplierPromptMessage: 'Opravdu chcete dodavatele/výrobce smazat?',
                fetchingSuppliers: 'Načítání dodavatelů/výrobců',
                noSuppliers: 'Nebyl nalezen žádný záznam dodavatele/výrobce',
                suppliersFetchError: 'Chyba při načítání dodavatel/výrobce',
                supplierName: 'Název dodavatele/výrobce',
                supplierHelpTextName: 'Např. název značky',
                supplierDescription: 'Popis dodavatele/výrobce',
                supplierHelpTextDescription: 'Například popis značky',
                wishes: 'Přání',
                checkNow: 'Zkontrolovat nyní',
                startTimeOn: 'Počítadlo času zapnuto',
                endTimeOn: 'Počítadlo času zastaveno',
                signOutConfirmationModalTitle: 'Potvrzení odhlášení',
                signOutConfirmationModalContent: 'Opravdu si přejete odhlásit?',
                startDate: 'Datum zahájení',
                endDate: 'Datum ukončení',
                scheduledDiscountApplyAllDays: 'Platí pro všchny dny',
                scheduledDiscountApplySelectedDays: 'Platí pro vybrané dny',
                discountCustomImageTitle: 'Obrázek slevy (volitelné)',
                discountCustomImageModalHeader: 'Slevový obrázek',
                saveChanges: 'Uložit změny',
                updateTagsQualitySettingsTitle: 'Nastavení kvality štítlů',
                updateTagsQualitySettingsMessage: 'Nastavení kvality štítlů aktualizováno',
                IF3Cabinets: 'Model 3 Chladničky',
                IF2Cabinets: 'Model 2 Chladničky',
                IF3Freezers: 'Model 3 Mrazáky',
                encodingStations: 'Kódovací stanice',
                if2: 'Model 2 Chladnička',
                if3: 'Model 3 Chladnička',
                if3Freezer: 'Model 3 Mrazák',
                legalNotice: 'Zákonné oznámení',
                privacyPolicy: 'Zásady ochrany osobních údajů',
                scheduledDiscountDateMissingError: 'Pokud je nastaveno počáteční nebo koncové datum, jsou potřeba obou datumů',
                scheduledDiscountDuplicateTimeError: 'Nastavané časy nemohou být stejné, pokud se datumy shodují',
                now: 'Nyní',
                minimum: 'Minimum',
                maximum: 'Maximum',
                discardChangesConfirmationText: 'Opravdu chcete změny zrušit?',
                exports: 'Přesun zboží',
                selectAllCabinets: 'Označ všechny kabinety',
                expiryIntervals: 'Intervaly expirace',
                timeBeforeExpiry: 'Čas před expirací',
                apply: 'Použít',
                yes: 'Ano',
                no: 'Ne',
                productOnScreenBannerText: 'Text informačního banneru na obrazovce',
                productOnScreenBannerAdditionalInfo:
                    'Text banneru je jednořádkový text, který se zobrazuje pod obrázkem produktu a výstižně vyjadřuje klíčové vlastnosti, zdravotní nezávadnost nebo jakékoli další informace o produktu.',
                productOnScreenBannerExampleText: 'Bez laktózy',
                UI_IS_DOWN: 'Displej skříně nefunguje',
                READER_IS_DOWN: 'RFID čtečka není aktivní',
                ANTENOR_DEVICE_DISCONNECTED: 'Platební zařízení je odpojeno',
                EXPIRED_PRODUCT: 'Vypršelé produkty ve skříni',
                CLOUD_SERVICE_DOWN: 'Cloudová služba je nedostupná',
                ANTENNA_DISCONNECTED: 'Anténa odpojena',
                TAG_CHANGES: 'Příliš mnoho vadných štítků ve skříni',
                ANTENNA_DOWN: 'RFID anténa ve skříni nefunguje',
                READER_CONFIG_ERROR: 'Konfigurace RFID čtečky selhala a nefunguje',
                activeAlarms: 'Aktivní poplachy',
                deviceStatus: 'Stav zařízení',
                lastMonitored: 'Poslední monitorováno',
                noService: 'Mimo provoz',
                operational: 'Operační',
                offline: 'Offline',
                locationsSelectHelp: 'Když je přidána lokalita, budou také přidány její podřízené lokality.',
                select: 'Vybrat...',
                labels: 'Štítky',
                productDetails: 'Detaily produktu',
                invalidProductName: 'Název produktu musí mít mezi 1 a 125 znaky',
                standardPrice: 'Standardní cena',
                locationPrices: 'Ceny podle umístění',
                addNew: 'Přidat nový',
                productExpiry: 'Doba expirace produktu',
                productLifeTime: 'Životnost produktu',
                invalidTTL: 'Životnost produktu musí být celé číslo od 0 do 32767',
                productImage: 'Obrázek produktu',
                uploadImage: 'Nahrát obrázek',
                pleaseUploadImage: 'Nahrajte prosím obrázek',
                productValidationError: 'Opravte prosím následující chyby před uložením údajů o produktu',
                productSupplier: 'Dodavatel produktu',
                frozenProductLable: 'Toto je zmrazený produkt',
                prebookingLabel: 'Toto je produkt k předobjednání',
                previous: 'Předchozí',
                bannerText: 'Text banneru',
                packagingTypeValidationError: 'Vyberte prosím typ balení produktu',
                showOnlyFrozen: 'Zobrazit pouze zmrazené produkty',
                productClassification: 'Klasifikace produktu',
                productClassLabel: 'Vyberte prosím vhodnou třídu pro produkt. To umožní Selfly Insights poskytovat relevantnější informace o produktu.',
                productLabels: 'Štítky produktu',
                productLabelsHelpText: 'Přidejte prosím k vašemu produktu volitelné štítky. To vám umožní filtrovat vaše produkty v katalogu produktů.',
                newLabel: 'Nový štítek',
                productClassValidationError: 'Vyberte prosím klasifikaci pro svůj produkt z níže uvedených možností',
                tagSensitivity: 'Citlivost kvality štítku',
                tagSensitivitySetting: 'Nastavení citlivosti kvality štítku',
                configTagSensitivity: 'Nakonfigurujte citlivost kvality štítku pro produkt',
                productLevelSensitivityInfo:
                    'Čitelnost RFID štítku připojeného k produktu může být ovlivněna různými faktory, včetně obalových materiálů, kovových povrchů, vlhkosti a dokonce i obsahem produktu. Tyto faktory mohou občas způsobit přerušované signály štítku, známé jako blikání. Skříň zpracovává toto blikání na základě nastavení konfigurace citlivosti. Pokud si nejste jisti, jaká úroveň citlivosti je pro tento produkt vhodná, můžete tuto část přeskočit.',
                errorSaving: 'Chyba při ukládání údajů o produktu',
                successSaving: 'Údaje o produktu byly úspěšně uloženy',
                basicDetail: 'Základní detaily',
                additionalDetail: 'Další detaily',
                packaging: 'Typ balení',
                classification: 'Klasifikace',
                selectOrtype: 'Vyberte nebo napište...',
                max25Chars: 'max. 25 znaků',
                noVatForProductCreation: 'Vytvořte prosím kategorii DPH před přidáním nových produktů',
                lifeTimeExplanation: 'Kolik dní je tento produkt platný k prodeji. (0 dní) znamená do konce aktuálního dne',
                selflyEncode: 'Selfly Encode',
                userRestrict: 'Pouze rozpoznaní spotřebitelé',
                userRestrictTitle: 'Oprávnění spotřebitelé',
                userNotRestrict: 'Všichni spotřebitelé',
                userRestrictHelperText:
                    "Možnost 'rozpoznaní spotřebitelé' znamená, že sleva bude použita pouze na spotřebitele, kteří jsou již zaregistrováni jako oprávnění ke slevě!",
                searchCabinets: 'Hledat skříně',
                selectAll: 'Vybrat vše',
                monday: 'Pondělí',
                tuesday: 'Úterý',
                wednesday: 'Středa',
                thursday: 'Čtvrtek',
                friday: 'Pátek',
                saturday: 'Sobota',
                sunday: 'Neděle',
                fetchingLoader: 'Načítání. Čekejte prosím...',
                category: 'Kategorie',
                okay: 'Ok',
                noLocation: 'Žádné umístění',
                copy: 'Kopírovat',
                pleaseConfirm: 'Potvrďte, prosím',
                saveQuery: 'Uložit tento dotaz',
                saveQueryPlaceholder: 'např. "Počet všech prodaných položek"',
                saveQuerySuccess: 'Dotaz byl uložen.',
                yourSavedQueries: 'Vaše uložené dotazy',
                selectQuery: 'Vyberte dotaz',
                noQuery: 'Zatím není žádný uložený dotaz',
                deleteAdConfirm: 'Opravdu chcete tuto reklamu smazat?',
                dataSaved: 'Data byla úspěšně uložena',
            },
            refill: {
                refillRuleApplyRuleDropdownText: 'Aplikujte i na ostatní kabinety',
                refillPlanGenerate: 'Vytvoření doplňovacího plánu',
                refillPlanEditPlan: 'Upravit plán',
                refillAmount: 'Doplňte množství',
                refillRuleTargetAmount: 'Cílová úroveň zásob',
                refillRuleTargetAmountShort: 'Cíl',
                refillPlanActivePlans: 'Aktivní plány',
                refillPlanCompletedPlans: 'Dokončené plány',
                refillNote: 'Poznámka k doplnění',
                refillNoteHelp: 'Poznámka k doplnění produktu (maximálně 125 znaků)',
                refillPDFText:
                    'Níže je plán doplnění skříně. Ujistěte se, že jste označili plán doplnění jako dokončený v aplikaci pro doplnění poté, co jste dokončili doplnění.',
                refillPDFSummaryText: 'Níže je shrnutí plánů doplnění s produkty k přidání a odebrání pro všechny vybrané skříně.',
                refillSummary: 'Shrnutí doplnění',
                addItems: 'Přidat produkty',
                removeItems: 'Odebrat produkty',
                inventoryChange: 'Změna inventáře',
                planogramPDFText: 'Plánogram pro strategii doplňování skříněk.',
                planogramImagePDFText: 'Vlastní obrázek pro strategii doplňování skříněk.',
                refillSchemas: 'Šablony doplňování',
                refillPlans: 'Plány doplňování',
                refillSchemaTitle:
                    'Nastavte cílové a minimální hodnoty zásob jako šablonu pro vybrané skříně. Tyto šablony budou použity pro generování plánů doplňování.',
                refillPlansTitle:
                    'Zobrazte všechny aktivní plány. Můžete upravit nebo odstranit existující aktivní plány, nebo přidat nové plány doplňování podle potřeby.',
                refillSchemaSubText: 'Vyberte prosím skříň, abyste vytvořili nebo aktualizovali šablonu doplňování.',
                noRefillSchema: 'Pro tuto skříň zatím není definována žádná šablona doplňování.',
                createRefillSchema: 'Vytvořit novou šablonu doplňování.',
                noDevice: 'Žádné zařízení nenalezeno.',
                schemaSaved: 'Šablona doplňování byla úspěšně uložena.',
                deleteSchemaConfirmation: 'Opravdu chcete smazat šablonu doplňování?',
                targetAmount: 'Cílové množství',
                targetAmountHelpText: 'Cílové množství zásob pro každý produkt ve skříni po doplnění.',
                minimumThreshold: 'Minimální práh',
                minimumThresholdHelpText:
                    'Uveďte minimální úroveň zásob pro každý produkt ve skříni. Pokud množství klesne pod definovaný práh, obdržíte alarmovou notifikaci. Nastavení prahu na 0 deaktivuje notifikace.',
                editNote: 'Upravit poznámku',
                refillNoteTitle: 'Napište poznámku k doplnění pro produkt.',
                refillNoteCharacters: 'znaky',
                noRefillPlan:
                    'V současné době nejsou k dispozici žádné aktivní plány doplňování. Přidejte prosím nové plány doplňování pro vaše skříně nebo nejprve vytvořte šablonu doplňování pro skříně.',
                planSaved: 'Plán doplňování byl úspěšně uložen.',
                deleteConfirmation: 'Opravdu chcete smazat vybraná data plánu doplňování?',
                editPlan: 'Upravit plán',
                currentInventory: 'Aktuální zásoby',
                lastSevenDaySales: 'Prodeje za posledních sedm dní',
                inventory: 'Zásoby',
                planogram: 'Plánogram',
                copyRefillSchema: 'Kopírovat šablonu do jiných skříní.',
                generatingPlans: 'Generuji plány doplňování. Prosím čekejte...',
                generatePlans: 'Generovat plány doplňování',
                complete: 'úplný',
                selectCabinetsToAddPlans: 'Vyberte prosím skříně pro generování plánů doplňování.',
                reviewSuggestion:
                    'Níže jsou navrhované plány doplňování pro vybrané skříně. Zkontrolujte je, upravte je, pokud je to nutné, a uložte je, abyste aktivovali tyto plány.',
                noSchema:
                    'V současné době žádné skříně nemají šablonu doplňování. Přidejte prosím šablonu doplňování do svých skříní, abyste mohli generovat plány doplňování.',
                searchProducts: 'Hledat produkty',
                maxAllowedInventory: 'Maximální povolené zásoby jsou 200.',
                savingData: 'Ukládání dat. Prosím čekejte.',
                errorSavingData: 'Chyba při ukládání dat.',
                refillPlanSaved: 'Plány doplňování byly úspěšně vygenerovány.',
                exportPDFHelp: 'Exportovat vybrané plány do PDF souboru.',
                exportExcelHelp: 'Exportovat vybrané plány do Excel souboru.',
                addPlanHelp: 'Přidat nové plány doplňování.',
                deletePlanHelp: 'Smazat vybrané plány doplňování.',
                generatePlansHelp: 'Generovat plány doplňování pro vybrané skříně.',
                planogramSaved: 'Plánogram byl úspěšně uložen.',
                schemaCopied: 'Šablona doplňování byla úspěšně synchronizována s ostatními skříněmi.',
                allProducts: 'Všechny produkty',
                schemaProducts: 'Produkty šablony doplňování',
                deletePlanogram: 'Opravdu chcete smazat plánogram?',
                dragInPlanogram: 'Prosím přetáhněte produkty na určené police skříní, nebo přetáhněte místo skříně nad produkt.',
                noPlanogramInSchema: 'Pro skříň zatím nebyl definován plánogram.',
                noPlanogramInPlan: 'Žádná data plánogramu nejsou dostupná pro skříň. Nejprve prosím vytvořte plánogram pro skříň v záložce šablony doplňování.',
                copyPlanogram: 'Kopírovat plán doplňování spolu se šablonou doplňování.',
                planogramImageInfo: 'Kromě plánogramu můžete také přidat vlastní obrázek plánogramu.',
                addPlanogramImage: 'Přidat obrázek plánogramu.',
                editPlanogramImage: 'Upravit obrázek plánogramu.',
                numberOfShelves: 'Počet polic',
                addNewRefillPlan: 'Přidat nové plány',
                qrcodeTitle: 'Pro vybrané skříň bude vygenerován QR kód pro zahájení procesu doplňování.',
                qrcodeSubText: 'Vyberte prosím skříň, kde chcete zahájit proces doplňování.',
                qrcodeScan: 'Prosím naskenujte QR kód pro doplnění, nebo klikněte na tlačítko pro otevření aplikace pro doplňování.',
                qrcodeOpenApplication: 'Otevřít aplikaci pro doplňování',
                createRefillPlanogram: 'Vytvořit plánogram doplňování',
            },
            navigation: {
                advertisements: 'Reklamy',
                analytics: 'Analýzi',
                controlPanel: 'Ovládací panel',
                deviceManagement: 'Správa zařízení',
                discounts: 'Slevy',
                fetching: 'Načítání',
                group: 'Skupina',
                inventory: 'Přehled zboží v kabinetu',
                language: 'Jazyk',
                productCatalog: 'Katalog produktů',
                productCategories: 'Skupiny produktů',
                reports: 'Záznamy',
                settings: 'Uživatelské nastavení',
                signOut: 'Odhlásit se',
                title: 'Selfly Cloud Portal',
                vatCategories: 'Kategorie DPH',
                community: 'Selfly Community',
                refillManagement: 'Správa doplňování',
                automations: 'Automatizace',
                mySelfly: 'Nastavení MySelfly',
                automationInfo: 'Spravovat automatizované akce (BETA)',
            },
            reports: {
                alreadyRefund: 'bylo vráceno',
                amount: 'Množství',
                average: 'Průměr',
                barcode: 'Čárový kód',
                confirmIssueRefundButton: 'Nyní potvrďte vrácení peněz',
                current: 'Aktuální',
                export: 'Přesunuto',
                fetching: 'Načítání',
                fetchingError: 'Chyba při načítání',
                image: 'Obrázek',
                issueRefundButton: 'VRÁCENÍ PENĚZ',
                maximum: 'Maximum',
                minimum: 'Minimum',
                name: 'Název',
                noData: 'Žádná data',
                note: 'Poznámka',
                order: 'Transakce',
                paymentWasDoneByCreditCard: 'Platba byla provedena kreditní kartou',
                price: 'Cena',
                ProductReport: 'Informace o produktu',
                quantity: 'Množství',
                'Refund impossible': 'Platba byla provedena kreditní kartou. Obraťte se na majitele terminálu ohledně vrácení peněz.',
                refundFormTitle: 'Vrácení peněz',
                refundReason: 'Důvod vrácení peněz',
                refundSuccess: 'Částka {{sum}} byla úspěšně vrácena',
                refundTotal: 'Vrácená částka',
                replenishments: 'Doplňování',
                replenishmentsTitle: 'Záznam o doplnění',
                SaleTransaction: 'Prodejní transakce',
                summary: 'Souhrn',
                SummaryReport: 'Souhrn prodeje',
                temperature: 'Teplota',
                temperatureTitle: 'Záznam o teplotě',
                temperatureValueNotification: 'Hodnoty teploty jsou hodinové průměrné hodnoty teploty kabinetu.',
                time: 'Čas',
                total: 'Celkem',
                transactions: 'Transakce',
                user: 'Uživatel',
                paidTransactions: 'Placené transakce',
                unpaidTransactions: 'Neplacené transakce',
                partiallyPaid: 'Částečně placené',
                failedTransactions: 'Neúspěšné transakce',
                amountOwed: 'Dlužná částka',
                receiptLinkText: 'Zde získáte účtenku',
                noPurchase: 'Žádný nákup',
                other: 'Jiné',
                userId: 'Uživatelské ID:',
            },
            settings: {
                block_cabinet_when_product_expired: 'Zablokovat kabinet pro produkty s procházející dobou min. trvanlivosti',
                criticalExpiryLimitHours: ' Upozornit hodiny před kritickým vypršením min. trvanlivosti produktu',
                language: 'Jazyk',
                logo: 'Logo společnosti',
                mediumExpiryLimitHours: 'Upozornit hodiny před středním vypršením min. trvanlivosti produktu',
                payment_method_logo: 'Logo platební metody',
                footer_instruction_logo: 'Podlkadní obrázek loga',
                readUserMemory: 'Načtení informací o min. době trvanlivosti produktu',
                ui_skus_sort_by: 'Pořadí zobrazení produktů na dispeji kabinetu',
                timeZone: 'Časové pásmo',
                country: 'Země',
                city: 'Město',
                tagSettings: 'Nastavení štítků',
                themeAndLogos: 'Motiv a loga',
                advancedSettings: 'Pokročilé nastavení',
                themingInfoText: 'Motivy obrazovky kabinetu si můžete přizpůsobit',
                cabinetLogos: 'Loga kabinetu',
                cabinetLogoInfoText: 'Nahrajte loga, která se objeví na obrazovce kabinetu',
                logoHelperText: 'Použijte prosím obrázek o velikosti 180 x 180 pixelů',
                settingsCopyInfoHeader: 'Označte kabinety, do kterých chcete nastavení zkopírovat',
                settingsCopyButton: 'Zkopírujte nastavení do označených kabinetů',
                settingsCopySuccessModalTitle: 'Kopírování nastavení',
                settingsCopySuccessModalContent: 'Nastavení byla úspěšně zkopírována do {{cabinetsAmount}} kabinetů',
                theming: 'Témování',
                adminSettings: 'Nastavení administrátora',
                settingsCopyErrorModalTitle: 'Chyba při kopírování nastavení',
                key: 'Nastavení',
                value: 'Hodnota',
                sleepScheduling: 'Plánovaná pohotovost',
                sensitivityLevel: 'Úroveň citlivosti',
                sensitivity_0:
                    'Skříň ignoruje blikání štítku a důsledně označuje všechny štítky jako platné, což může někdy vést k nesprávnému účtování položek.',
                sensitivity_20: 'Skříň toleruje až tři špatná čtení štítku během 50 skenování a stále označuje štítek jako platný.',
                sensitivity_40: 'Skříň toleruje až dvě špatná čtení štítku během 60 skenování a stále označuje štítek jako platný.',
                sensitivity_60: 'Pokud štítek často bliká, ale po delší dobu zůstává stabilní, skříň to toleruje a považuje štítek za platný.',
                sensitivity_80: 'Pokud štítek krátce bliká, ale zůstává stabilní po delší dobu, bude považován za platný štítek.',
                sensitivity_100:
                    'Skříň je velmi netolerantní vůči blikání štítků. I jedno špatné čtení může způsobit, že štítek bude označen jako neplatný po delší dobu, což může vést k potenciálním ztrátám.',
                sensitivityInfo:
                    'Citlivost skříně na kvalitu štítků se týká toho, jak efektivně skříň zpracovává nekonzistentní čtení štítků nebo blikání štítků. Buďte prosím opatrní při volbě úrovně citlivosti, protože to může ovlivnit výkon skříně. Pokud si nejste jisti, která úroveň je vhodná, doporučuje se ponechat nastavení na výchozí úrovni 3.',
            },
            signIn: {
                signInButton: 'Přihlášení',
                signInExpiredError: 'Platnost vaší relace vypršela. Znovu se přihlaste',
                signInMessage: 'Chcete-li pokračovat, musíte být přihlášeni. Kliknutím na tlačítko níže se přihlaste.',
                noOrganizationsError: 'Žádné organizace spojené s tímto uživatelským jménem. Zkuste jiné uživatelské jméno.',
            },
            translations: {
                add: 'Přidat',
                closed: 'Ukončeno',
                delete: 'Smazáno',
                device: 'Zařízení',
                edit: 'Upravit',
                off: 'VYPNUTO',
                offline: 'Offline',
                on: 'ZAPNUTO',
                online: 'Online',
                opened: 'Otevřeno',
                save: 'Uložit',
                seeDetails: 'Detaily',
                settings: 'Nastavení',
                unknown: 'Neznámé',
            },
            consumerApp: {
                enableConsumerFeedback: 'Povolení zpětné vazby od uživatelů na MySelfly',
                enablePurchaseReceipts: 'Povolení elektronické účtenky na MySelfly',
                enableSelflyTrademarkForReceipts: 'Povolení detailu obchodníka v elektronické účtence',
                enableSelflyTrademarkForReceiptsHelpText: 'Povolením této možnosti přidáte logo Selfly Store a detaily na elektronickou účtenku v PDF formátu!',
                organizationFormHelpText: 'Uveďte prosím údaje o vydavateli elektronické účtenky.',
                organizationFormValidationHelpText: '* Povinná pole pro e-účtenku jsou název organizace, IČO, DIČ a adresa.',
                organizationName: 'Název organizace',
                organizationLogo: 'Logo organizace',
                businessId: 'IČO, DIČ',
                city: 'Město',
                email: 'Email',
                website: 'Webová stránka',
                postalCode: 'PSČ',
                street: 'Ulice',
                phoneNumber: 'Telefoní číslo',
                addressHeader: '* Informace o adrese',
                contactDetails: 'Kontakt',
                organization: '* Organizace vystavitele',
                organizationSettingsUpdatedTitle: 'Aktualizace nastavení organizace',
                organizationSettingsUpdatedContent: 'Aktualizace nastavení organizace proběhlo úspěšně',
                organizationSettingsMissingFields: 'Chybí povinná pole pro elektronickou účtenku. Před uložením prosím vyplňte',
                organizationSettingsMissingFieldsTitle: 'Chyba ověření formuláře',
            },
            softwareVersions: {
                updateNotificationTitle: 'Aktualizace verze softwaru',
                updateNotificationContent: 'Aktualizace verze softwaru proběhla úspěšně',
                selectAVersionPlaceholder: 'Vyber verzi',
                searchDevices: 'Vyhledávíní zařízení',
                updateSelected: 'Aktualizace vybrána',
                softwareVersions: 'Softwarové verze',
                softwareVersion: 'Softwarová verze',
                notSupportedDeviceNote: 'Verze {{selectedVersion.version}} není na tomto zařízení podporována',
                userNotificationContent: 'Pro vaše zařízení byla vydána nová verze softwaru. Aktualizujte svá zařízení, abyste získali nejnovější funkce.',
                userNotificationTitle: 'Nová verze softwaru je k dispozici',
                notificationBell_newSoftwareVersions: 'Dostupné aktualizace pro vaše zařízení',
                manageVersions: 'Správa verzí softwaru zařízení',
                filterLocationsPlaceholder: 'Filtrujte podle místa',
                allVersionNumbers: 'Všechny vydané',
                noVersionsForUpdating: 'Žádné verze pro aktualizaci',
                scheduledUpdateNote: 'Aktualizace verze {{version}} je naplánována na {{scheduled}}',
                scheduleRemoveNotificationTitle: 'Plánování verzí softwaru',
                scheduleRemoveNotificationContent: 'Naplánovaná aktualizace verze softwaru byla úspěšně odstraněna',
                scheduleRemoveNotificationModalContent: 'Opravdu chcete odebrat naplánovanou aktualizaci pro toto zařízení?',
            },
            header: {
                searchByOrganization: 'Vyhledávání podle organizace',
                pageLanguage: 'Jazyk',
                configureMySelflyAndEreceipts: 'Nakonfigurujte nastavení MySelfly',
                remindMeLater: 'Připomeň mi později',
                updateNow: 'Aktualizovat nyní',
            },
            notifications: {
                noNewNotifications: 'Žádná nová oznámení',
            },
            cabinetSettings: {
                cabinetTheme: 'Motiv kabinetu',
                editAndSaveTheme: 'Upravit a uložit motiv',
                colorPicker: 'Výběr barvy',
                customTheme: 'Vlastní motiv',
                themeSettings: 'Nastavení motivu',
                primary: 'Hlavní',
                negative: 'Negativní',
                attention: 'Pozornost',
                positive: 'Pozitivní',
                background: 'Pozadí',
                dark: 'Tmavé',
                light: 'Světlé',
                white: 'Bílé',
                default: 'Výchozí',
                negativeLight: 'Negativní světlé',
                primaryColor: 'Hlavní barva',
                positiveColors: 'Teplé barvy',
                negativeColors: 'Studené barvy',
                secondaryColors: 'Sekundární barvy',
                attentionColors: 'Barvy pro zpozornění',
                screenBackground: 'Pozadí obrazovky',
                borderColors: 'Rozhraní - Stíny',
                shadow: 'Stín',
                border: 'Rozhraní',
                fontColors: 'Barvy písma',
                footerTextPlaceholder: 'Text zápatí',
                bundleDiscounts: 'Kombinujte a uložte',
                expiringText: 'Produkty s končící min. dobou trvanlivosti. Před nákupem zkontrolujte datum min.trvanlivosti',
                expiredText: 'Produkty s prošlou min. dobou trvanlivosti. Před nákupem zkontrolujte datum min.trvanlivosti',
                cabinetName: 'Název kabinetu',
                productName: 'Název produktu',
                confirmationModalHeader: 'Opravdu chcete uložit vytvořený motiv?',
                confirmationModalContent:
                    '„Přizpůsobení motivu ovlivní vizuální vzhled obrazovky vašeho kabinetu. Ujistěte se prosím, že změnami není ohrožena vizuální kvalita obrazovky.“',
                defaultRevertModalMessage: 'Opravdu chcete změnit motiv kabinetu na výchozí?',
                backgroundColor: 'Barva pozadí',
                backgroundImage: 'Obrázek pozadí',
                footerBackground: 'Pozadí paty',
                cardsBackground: 'Pozadí karty',
                screenImage: 'Obrázek pro pozadí obrazovky skříně',
                uploadImage: 'Nahrát obrázek',
                editImage: 'Upravit obrázek',
                transactionComplete: 'Transakce dokončena',
                customPostPurchaseImage: 'Prosím, nahrajte obrázek pro vlastní obrazovku po nákupu',
                postPurchaseScreen: 'Obrazovka po nákupu',
                custom: 'vlastní',
                postPurchaseText: 'Text zobrazení po nákupu',
            },
            automations: {
                transactionReports: 'Transakční zprávy',
                refillReports: 'Zprávy o doplnění',
                notifications: 'Oznámení',
                refillPlan: 'Plány doplnění',
                days: 'Dny',
                months: 'Měsíce',
                weeks: 'Týdny',
                configuration: 'Konfigurace',
                transactionReportTitle: 'Transakční zprávy pro vybrané skříně budou automaticky generovány a zasílány na níže uvedené e-mailové adresy',
                refillReportTitle: 'Zprávy o doplnění pro vybrané skříně budou automaticky generovány a zasílány na níže uvedené e-mailové adresy',
                noSalesNotificationTitle:
                    'Oznámení budou automaticky zasílána na níže uvedené e-mailové adresy v případě, že nebudou žádné prodeje v definovaných intervalech',
                frequencyTitle: 'Jak často chcete, aby byla zpráva generována?',
                emailNotificationTitle: 'Prosím, přidejte níže uvedené e-mailové adresy, na které chcete, aby byla zpráva nebo oznámení zaslána',
                refillPlanGenerationTitle: 'Plány doplnění budou automaticky generovány pro vybrané skříně podle zadaného plánu',
                addNewEmail: 'Přidat nový e-mail',
                noSalesBanner: 'Obdržíte e-mailové oznámení, pokud vybrané skříně neprovedou žádné prodeje během nakonfigurovaného časového období',
                noSaleTitle: 'Prosím, uveďte dobu (v hodinách) bez prodeje v vybraných skříních, než bude oznámení odesláno',
                minMaxTime: 'Min je 2 a max je 23 hodin',
                timeRangeTitle: 'Prosím, uveďte časové rozmezí pro skříně, během kterého bude kontrolována situace bez prodeje',
                startTime: 'Čas začátku',
                endTime: 'Čas konce',
                refillPlanTitle:
                    'Plán doplnění pro vybrané skříně bude automaticky generován. Prosím, naplánujte níže konkrétní čas, kdy chcete, aby byl plán doplnění vytvořen.',
                refillPlanTime: 'Vyberte prosím čas pro vytvoření plánu doplnění',
                everyDay: 'Každý den',
                everyWeek: 'Každý týden',
                everyMonth: 'Každý měsíc',
                selectDays: 'Vyberte dny',
                automationSaved: 'Nastavení automatizace bylo úspěšně uloženo',
                automationDeleted: 'Nastavení automatizace bylo úspěšně smazáno',
            },
            sleepScheduling: {
                timeRangeTitle: 'Prosím, uveďte časové rozmezí, během kterého tento skříň půjde do pohotovostního režimu.',
                startTime: 'Čas začátku',
                endTime: 'Čas konce',
                selectDays: 'Vyberte dny',
                duration: 'Doba trvání (hodiny)',
            },
        },
    },
    fallbackLng: 'en',

    // have a common namespace used around the full app
    ns: ['translations', 'navigation', 'signIn', 'weekDayAbrv'],
    defaultNS: 'translations',

    keySeparator: false, // we use content as keys

    interpolation: {
        escapeValue: false, // not needed for react!!
        formatSeparator: ',',
    },

    react: {
        wait: true,
    },
});
locale(i18n.language);

export default i18n;
