import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import classnames from 'classnames';
import Switch from 'react-switch';
import { Button, Input } from '@storaensods/seeds-react';

import { showNotification } from '../toastNotification/toastNotification';
import { SettingsCopyModal } from './settingsCopyModal';
import TagSensitivity from '../tagSensitivity';
import InfoBanner from '../refillPlans/segments/InfoBanner';

const DEFAULT_TAG_SENSITIVITY_LEVEL = 60;
const ALLOWED_SENSITIVITY_LEVELS = [0, 20, 40, 60, 80, 100];

const TagSettings = props => {
    const { t, selectedCabinetSettings, updateSelectedCabinetTagQualityMetrics, cabinets, locations, title, submitSettingsCopy, selectedCabinet } = props;
    const { detectTagQuality, ignoreBadTags, chargeSoldTags, chargeUnreplenishedTags, acceptablePercentageOfBadTags, cabinetTagSensitivityLevel } =
        selectedCabinetSettings || {};

    const [acceptableBadTagPercentage, setAcceptableBadTagPercentage] = useState(null);
    const [copySettingsOpen, setCopySettingsOpen] = useState(false);


    useEffect(() => {
        setAcceptableBadTagPercentage(acceptablePercentageOfBadTags);
    }, [acceptablePercentageOfBadTags]);

    const isValidPercentageValue = value => {
        if (!value) {
            return 'NO_VALUE';
        }
        if (value.length > 0 && !isNaN(Number(value)) && 0 <= Number(value) && Number(value) <= 100 && !value.includes('e')) {
            return 'VALID';
        }
        return 'INVALID';
    };

    // Arguments of updateSelectedCabinetTagQualityMetrics:
    // PLEASE DONT REMOVE THIS
    /* updateSelectedCabinetTagQualityMetrics(
        detectTagQuality,
        ignoreBadTags,
        removeTagQualityData,
        acceptablePercentageOfBadTags,
        removeTagHistoryData,
        removeReplenishmentData,
        chargeSoldTags,
        chargeUnreplenishedTags,
        markAllTagsUnsold,
        cabinetTagSensitivityLevel
    ) */

    /**
     * determine what should be the current tag sensitivity level based on the sensitivity value and the detectTagQuality settings
     * @returns int currentSensitivityLevel
     */
    const getTagSensitivityLevel = () => {
        const currentSensitivityLevel = typeof cabinetTagSensitivityLevel === 'undefined' ? DEFAULT_TAG_SENSITIVITY_LEVEL : parseInt(cabinetTagSensitivityLevel)

        if(typeof detectTagQuality === 'undefined')return currentSensitivityLevel

        if(detectTagQuality === false)return 0

        if(detectTagQuality)return currentSensitivityLevel

        if (ALLOWED_SENSITIVITY_LEVELS.includes(currentSensitivityLevel)) return parseInt(cabinetTagSensitivityLevel);

        return DEFAULT_TAG_SENSITIVITY_LEVEL;
    };

    const handleBadTagsDetectionToggle = value => {
        const currentSensitivityLevel = getTagSensitivityLevel();
        updateSelectedCabinetTagQualityMetrics(
            value,
            ignoreBadTags,
            null,
            acceptableBadTagPercentage,
            false,
            false,
            chargeSoldTags,
            chargeUnreplenishedTags,
            false,
            value===false ? 0 : currentSensitivityLevel === 0 ? DEFAULT_TAG_SENSITIVITY_LEVEL : currentSensitivityLevel 
        );
    };

    const handleChargeBadTagsToggle = value => {
        updateSelectedCabinetTagQualityMetrics(
            detectTagQuality,
            !value,
            null,
            acceptableBadTagPercentage,
            false,
            false,
            chargeSoldTags,
            chargeUnreplenishedTags,
            false,
            getTagSensitivityLevel()
        );
    };

    const handleRemoveTagQualityData = () => {
        updateSelectedCabinetTagQualityMetrics(
            detectTagQuality,
            ignoreBadTags,
            true,
            acceptableBadTagPercentage,
            false,
            false,
            chargeSoldTags,
            chargeUnreplenishedTags,
            false,
            getTagSensitivityLevel()
        );
    };

    const handleRemoveTagHistory = () => {
        updateSelectedCabinetTagQualityMetrics(
            detectTagQuality,
            ignoreBadTags,
            true,
            acceptableBadTagPercentage,
            true,
            false,
            chargeSoldTags,
            chargeUnreplenishedTags,
            false,
            getTagSensitivityLevel()
        );
    };
    const handleRemoveReplenishmentData = () => {
        updateSelectedCabinetTagQualityMetrics(
            detectTagQuality,
            ignoreBadTags,
            true,
            acceptableBadTagPercentage,
            false,
            true,
            chargeSoldTags,
            chargeUnreplenishedTags,
            false,
            getTagSensitivityLevel()
        );
    };

    // change setting whether sold tag should be charged
    const handleChargeSoldTagsToggle = value => {
        updateSelectedCabinetTagQualityMetrics(
            detectTagQuality,
            ignoreBadTags,
            false,
            acceptableBadTagPercentage,
            false,
            false,
            value,
            chargeUnreplenishedTags,
            false,
            getTagSensitivityLevel()
        );
    };

    const handleMarkAllTagsSold = () => {
        updateSelectedCabinetTagQualityMetrics(
            detectTagQuality,
            ignoreBadTags,
            false,
            acceptableBadTagPercentage,
            false,
            false,
            chargeSoldTags,
            chargeUnreplenishedTags,
            true,
            getTagSensitivityLevel()
        );
    };

    const handleTagSensitivityChange = value => {
        updateSelectedCabinetTagQualityMetrics(
            parseInt(value) === 0 ? false : true,
            ignoreBadTags,
            null,
            acceptableBadTagPercentage,
            false,
            false,
            chargeSoldTags,
            chargeUnreplenishedTags,
            false,
            //sliderRef.current.value
            value
        );
    };

    // change setting whether unreplenished tag should be charged
    const handleChargeUnreplenishedTagsToggle = value => {
        updateSelectedCabinetTagQualityMetrics(detectTagQuality, ignoreBadTags, false, acceptableBadTagPercentage, false, false, chargeSoldTags, value, false);
    };

    const handleNewAcceptablePercentageValue = value => {
        setAcceptableBadTagPercentage(value);
    };

    const handleAcceptablePercentageOfBadTags = () => {
        if (!selectedCabinet.isError && selectedCabinet.fetched) {
            showNotification(t('updateTagsQualitySettingsTitle'), t('updateTagsQualitySettingsMessage'), 'success');
        }

        updateSelectedCabinetTagQualityMetrics(
            detectTagQuality,
            ignoreBadTags,
            null,
            acceptableBadTagPercentage,
            false,
            false,
            chargeSoldTags,
            chargeUnreplenishedTags,
            false,
            getTagSensitivityLevel()
        );
    };

    const getCorrectSettingsForModal = () => {
        let detectTagQualitySetting = null;
        if (detectTagQuality === false) {
            detectTagQualitySetting = false;
        } else {
            // detectTagQuality is undefined or true
            detectTagQualitySetting = true;
        }

        const usedSettings = {
            detectTagQuality: detectTagQualitySetting,
            ignoreBadTags: ignoreBadTags,
            acceptablePercentageOfBadTags: acceptablePercentageOfBadTags || '66',
            chargeUnreplenishedTags: chargeUnreplenishedTags || false,
            chargeSoldTags: chargeSoldTags || false,
            cabinetTagSensitivityLevel: getTagSensitivityLevel(),
        };

        return usedSettings;
    };

    return (
        <div>
            <table className="table table-sm">
                <tbody>
                    <tr className="tag-qaulity-data">
                        <td className="data-header" colSpan={3}>
                            {t('controls_tagQualityData')}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <span className="mr-3">{t('controls_detectTagQuality')}</span>
                        </td>
                        <td></td>
                        <td>
                            <label>
                                <Switch
                                    // eslint-disable-next-line
                                    checked={detectTagQuality || false}
                                    onColor="#67b419"
                                    offColor="#d6d9df"
                                    onChange={handleBadTagsDetectionToggle}
                                    className="se-seeds-toggle"
                                />
                            </label>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <span className={classnames('mr-3')}>{t('controls_markTagsGoodQuality')}</span>
                        </td>
                        <td></td>
                        <td>
                            <label>
                                <Button onClick={handleRemoveTagQualityData} className="se-seeds-button" size="sm">
                                    {t('controls_markTagsGoodQuality_btn')}
                                </Button>
                            </label>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <span
                                className={classnames('mr-3', {
                                    // eslint-disable-next-line
                                    'text-muted': !detectTagQuality,
                                })}
                            >
                                {t('controls_chargeBadTags')}
                            </span>
                        </td>
                        <td></td>
                        <td>
                            <label>
                                <Switch
                                    // eslint-disable-next-line
                                    checked={!ignoreBadTags || false}
                                    onColor="#67b419"
                                    offColor="#d6d9df"
                                    onChange={handleChargeBadTagsToggle}
                                    className="se-seeds-toggle"
                                    // eslint-disable-next-line
                                    disabled={!detectTagQuality}
                                />
                            </label>
                        </td>
                    </tr>
                    <tr>
                        <td className="acceptable-bad-tag-percentage-description">
                            <span
                                className={classnames('mr-3', {
                                    // eslint-disable-next-line
                                    'text-muted': !detectTagQuality,
                                })}
                            >
                                {t('controls_acceptablePercentageOfBadTags')}
                            </span>
                        </td>
                        <td className="acceptable-bad-tag-percentage-input">
                            <Input
                                value={
                                    acceptableBadTagPercentage === 0
                                        ? '0'
                                        : acceptableBadTagPercentage === ''
                                        ? ''
                                        : acceptableBadTagPercentage === null || acceptableBadTagPercentage === undefined
                                        ? '66'
                                        : acceptableBadTagPercentage
                                }
                                size="sm"
                                disabled={!detectTagQuality}
                                onChange={event => handleNewAcceptablePercentageValue(event.target.value)}
                                invalid={isValidPercentageValue(acceptableBadTagPercentage) === 'INVALID'}
                                valid={
                                    isValidPercentageValue(acceptableBadTagPercentage) === 'VALID' ||
                                    isValidPercentageValue(acceptableBadTagPercentage) === 'NO_VALUE'
                                }
                                helpText={isValidPercentageValue(acceptableBadTagPercentage) === 'INVALID' ? t('invalidPercentage') : null}
                            />
                        </td>
                        <td>
                            <span>
                                <Button
                                    onClick={handleAcceptablePercentageOfBadTags}
                                    disabled={
                                        isValidPercentageValue(acceptableBadTagPercentage) === 'INVALID' ||
                                        isValidPercentageValue(acceptableBadTagPercentage) === 'NO_VALUE' ||
                                        !detectTagQuality
                                    }
                                    size="sm"
                                >
                                    {t('save')}
                                </Button>
                            </span>
                        </td>
                    </tr>
                    <tr className="tag-qaulity-data">
                        <td className="data-header" colSpan={3}>
                            {t('controls_replenishmentData')}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <span className={classnames('mr-3')}>{t('controls_markAllTagsReplenished')}</span>
                        </td>
                        <td></td>
                        <td>
                            <label>
                                <Button onClick={handleRemoveReplenishmentData} className="se-seeds-button" size="sm">
                                    {t('controls_markAlTagsReplenished_btn')}
                                </Button>
                            </label>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <span className={classnames('mr-3')}>{t('controls_chargeUnreplenishedTags')}</span>
                        </td>
                        <td></td>
                        <td>
                            <label>
                                <Switch
                                    // eslint-disable-next-line
                                    checked={chargeUnreplenishedTags || false}
                                    onColor="#67b419"
                                    offColor="#d6d9df"
                                    onChange={handleChargeUnreplenishedTagsToggle}
                                    className="se-seeds-toggle"
                                />
                            </label>
                        </td>
                    </tr>
                    <tr className="tag-qaulity-data">
                        <td className="data-header" colSpan={3}>
                            {t('controls_soldTags')}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <span className={classnames('mr-3')}>{t('controls_markAllTagsUnsold')}</span>
                        </td>
                        <td></td>
                        <td>
                            <label>
                                <Button onClick={handleMarkAllTagsSold} className="se-seeds-button" size="sm">
                                    {t('controls_markAllTagsUnsold_btn')}
                                </Button>
                            </label>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <span className={classnames('mr-3')}>{t('controls_chargeSoldTags')}</span>
                        </td>
                        <td></td>
                        <td>
                            <label>
                                <Switch
                                    // eslint-disable-next-line
                                    checked={chargeSoldTags || false}
                                    onColor="#67b419"
                                    offColor="#d6d9df"
                                    onChange={handleChargeSoldTagsToggle}
                                    className="se-seeds-toggle"
                                />
                            </label>
                        </td>
                    </tr>
                    <tr className="tag-qaulity-data">
                        <td className="data-header" colSpan={3}>
                            {t('controls_tagHistory')}
                        </td>
                    </tr>
                    <tr>
                        <td className="action-warn">
                            <span className={classnames('mr-3')}>{t('controls_clearTagHistory')}</span>
                            <p>({t('controls_tagsWarn')})</p>
                        </td>
                        <td></td>
                        <td>
                            <label>
                                <Button onClick={handleRemoveTagHistory} className="se-seeds-button" size="sm">
                                    {t('clear')}
                                </Button>
                            </label>
                        </td>
                    </tr>
                    <tr className="tag-qaulity-data">
                        <td className="data-header" colSpan={3}>
                            {t('main:tagSensitivity')}
                        </td>
                    </tr>
                    <tr className="cabinet-tag-sensitivity">
                        <td colSpan={2}>
                            <InfoBanner message={t('settings:sensitivityInfo')} />
                        </td>
                        <td>
                            <TagSensitivity changeSensitivity={(value)=>handleTagSensitivityChange(value)} t={t} currentSensitivityLevel={getTagSensitivityLevel()}/>
                        </td>
                    </tr>
                </tbody>
            </table>
            
            

            <Button className="mb-4" onClick={() => setCopySettingsOpen(true)}>
                <i className="fas fa-copy mr-2" />
                <span>{t('settings:settingsCopyButton')}</span>
            </Button>

            {copySettingsOpen && (
                <SettingsCopyModal
                    closeModal={() => setCopySettingsOpen(!copySettingsOpen)}
                    t={t}
                    submitSettingsCopy={submitSettingsCopy}
                    cabinets={cabinets}
                    locations={locations}
                    settingsType={title}
                    settings={getCorrectSettingsForModal()}
                />
            )}
        </div>
    );
};

export default withRouter(
    connect(state => ({
        selectedCabinet: state.controlPanel.selectedCabinet,
    }))(translate('main')(TagSettings))
);
