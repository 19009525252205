import { createAutomation as createAutomationHttpRequest, 
    updateAutomation as updateAutomationHttpRequest,
    fetchAutomation as fetchAutomationHttpRequest
 } from "../api";

const REQUEST_CREATE_AUTOMATION = 'REQUEST_CREATE_AUTOMATION';
const RECEIVE_CREATE_AUTOMATION = 'RECEIVE_CREATE_AUTOMATION';
const RECEIVE_CREATE_AUTOMATION_ERROR = 'RECEIVE_CREATE_AUTOMATION_ERROR';
const REQUEST_AUTOMATIONS = 'REQUEST_AUTOMATIONS';
const RECEIVE_AUTOMATIONS = 'RECEIVE_AUTOMATIONS';
const RECEIVE_AUTOMATIONS_ERROR = 'RECEIVE_AUTOMATIONS_ERROR';

/**
 * Request create Refill plan Redux action creator
 */
function requestCreateAutomation() {
    return {
        type: REQUEST_CREATE_AUTOMATION,
    };
}

/**
 * Receive VAT categories Redux action creator
 * @param {array} vatCategories Array of VAT categories received from root api
 */
function receiveCreateAutomation(automation) {
    return {
        type: RECEIVE_CREATE_AUTOMATION,
        automation,
        receivedAt: Date.now(),
    };
}

/**
 * Receive VAT categories error Redux action creator
 * @param {Error} error The error object created in api.js
 */
function receiveCreateAutomationError(error) {
    return {
        type: RECEIVE_CREATE_AUTOMATION_ERROR,
        error: error,
    };
}

/**
 * Request VAT categories Redux action creator
 */
function requestAutomation() {
    return {
        type: REQUEST_AUTOMATIONS,
    };
}

/**
 * Receive VAT categories Redux action creator
 * @param {array} vatCategories Array of VAT categories received from root api
 */
function receiveAutomation(automation) {
    return {
        type: RECEIVE_AUTOMATIONS,
        automation,
    };
}

/**
 * Receive VAT categories error Redux action creator
 * @param {Error} error The error object created in api.js
 */
function receiveAutomationError(error) {
    return {
        type: RECEIVE_AUTOMATIONS_ERROR,
        error: error,
    };
}


export function createAutomation(automation) {
    return (dispatch, getState) => {
        dispatch(requestCreateAutomation());
        const user = getState().user;

        return createAutomationHttpRequest(automation, user.group, user.accessToken)
            .then(automation => dispatch(receiveCreateAutomation(automation)))
            .catch(error => dispatch(receiveCreateAutomationError(error)));
    };
}

export function fetchAutomation(type) {
    return (dispatch, getState) => {
        dispatch(requestAutomation());
        const user = getState().user;

        return fetchAutomationHttpRequest(type, user.group, user.accessToken)
            .then(automation => dispatch(receiveAutomation(automation)))
            .catch(error => dispatch(receiveAutomationError(error)));
    };
}

export function updateAutomation(id, automation) {
    return (dispatch, getState) => {
        dispatch(requestCreateAutomation());
        const user = getState().user;

        return updateAutomationHttpRequest(id, automation, user.group, user.accessToken)
            .then(automation => dispatch(receiveCreateAutomation(automation)))
            .catch(error => dispatch(receiveCreateAutomationError(error)));
    };
}

export function deleteAutomation(id) {
    const automationData = {status: 'inactive'};
    return (dispatch, getState) => {
        dispatch(requestCreateAutomation());
        const user = getState().user;

        return updateAutomationHttpRequest(id, automationData, user.group, user.accessToken)
            .then(automation => dispatch(receiveCreateAutomation(automation)))
            .catch(error => dispatch(receiveCreateAutomationError(error)));
    };
}