import React, { useState, Fragment } from "react";
import { Popover, PopoverBody } from 'reactstrap';
import { sensitivityDescription } from ".";


const PopUpDialog=({index, changeSensitivity, currentSensitivityLevel, t})=>{
    const [isPopOverOpen, setIsPopOverOpen] = useState(false)
    const info = sensitivityDescription.find(info=>info.level ===index) || {}
    return(
        <Fragment>
            <span id={`sensitivity-${index}`} onClick={()=>changeSensitivity(index)}>
                {index / 20}
            </span>
            <Popover
                flip="true"
                isOpen={isPopOverOpen}
                target={`sensitivity-${index}`}
                toggle={()=>setIsPopOverOpen(!isPopOverOpen)}
                trigger="hover"
                placement={'top'}
                boundariesElement="window"
            >
                <PopoverBody>
                    <div className="tag-sensitivity__popup">
                        <h3>{t('settings:sensitivityLevel')}: {t(index / 20)}</h3>
                        {t(info.text)}
                    </div>
                </PopoverBody>
            </Popover>
        </Fragment>
        
    )
}

export default PopUpDialog