import React from 'react';
import moment from 'moment';
import { head, at } from 'lodash';
import { Tooltip } from 'react-tippy';

import 'react-tippy/dist/tippy.css';

/**
 * Format DateTime object onto string.
 * @param {*} datetime DateTime value.
 */
export function getDateAndTime(datetime) {
    if (!datetime.value) {
        return <div className="text-wrap">{'-'}</div>;
    }

    const date = moment(datetime.value);
    if (date._isValid) {
        return <div className="text-wrap">{date.format('lll')}</div>;
    } else {
        return <div className="text-wrap">{'-'}</div>;
    }
}

/**
 * Renders the icons & status based on transaction payment method.
 * @param {*} data Full raw data
 */
export const getPaymentMethodAndStatus = (data, t) => {
    if (!data || !data.original) return null;
    switch (data.original.paymentMethod) {
        case 'Logos-Valina':
        case 'mypos':
        case 'nayax':
        case 'nets':
        case 'nets2':
        case 'Axis-ingenico':
        case 'wipay':
            return (
                <div className="w-100 text-right pr-3">
                    <Tooltip theme="light" title={t('reports:paymentWasDoneByCreditCard')} position="top" trigger="mouseenter">
                        <span>{`${data.original.status}    `}</span>
                        <i className="fas fa-credit-card" />
                    </Tooltip>
                </div>
            );
        default:
            return (
                <div className="w-100 text-right pr-3">
                    <span>{`${data.original.status}    `}</span>
                    <i className="fas fa-mobile" />
                </div>
            );
    }
};

/**
 * Gets the property value of the object by property path.
 * @param {*} path Path of the property to fetch.
 */
export function atCurry(path) {
    return params => {
        const val = head(at(params, path));
        return val ? val : '-';
    };
}

export function renderCenterText(data) {
    const value = data.value || '-';
    return <div className="text-center">{value}</div>;
}

export function renderHeaderPosition(value, align) {
    const header = () => <div style={{ textAlign: align }}>{value}</div>;
    return header;
}

/**
 * Gets 2 digits decimal number from imput
 * @param {*} sum total sum number
 */
export function getSum(sum) {
    if (!sum.value) {
        return '-';
    }
    return +sum.value.toFixed(2);
}

export function renderImage(data) {
    return data.value && data.value !== '-' ? (
        <img src={data.value} className=" img-thumbnail" alt="product" style={{ width: '30px', height: '30px' }} />
    ) : (
        <div className="text-center d-flex align-items-center reports-missing-image-container">
            <span className="icon-picture material-icons reports-missing-image-icon"></span>
        </div>
    );
}
