import React, { useState, Fragment } from 'react';
import { Icon } from '@storaensods/seeds-react';
import { Popover, PopoverBody } from 'reactstrap';
import { measures, dimensions } from './config';

const getMetricFilterLength = (members, filters) => {
    if (filters && filters.length > 0) {
        return `${members.length - 1 + filters.length}`;
    } else {
        return members.length;
    }
};

const correctFilters = (filters, member) => {
    const filtersForMeasure = filters.some(filter => {
        const configFilterObject = measures.find(measure => {
            return measure.filters.dimension === filter.dimension;
        });
        if (configFilterObject && configFilterObject.value === member) {
            return true;
        } else {
            return false;
        }
    });

    return filtersForMeasure;
};
const SelectionSummary = ({ currentSelection, cabinets, t }) => {
    /**
     * States to check which summary is hovered over.
     */
    const [metricsOpen, setMetricOpen] = useState(false);
    const [dimensionOpen, setDimensionOpen] = useState(false);
    const [groupingOpen, setGroupingOpen] = useState(false);

    const preDefinedCurrentSelection = currentSelection.preDefinedSegment === 'InventoryHistory' ? 'Inventory History' : currentSelection.preDefinedSegment;

    return (
        <div className="summary">
            {currentSelection.preDefinedSegment !== 'InventoryHistory' && (
                <div className="summary__item">
                    <Icon>date_range</Icon>
                    <span>
                        {t(currentSelection.selectedDateRange)} ( {t('groupBy')} {t(currentSelection.selectedGranularity)} )
                    </span>
                </div>
            )}

            {currentSelection.queryType === 'preDefined' && currentSelection.preDefinedSegment && (
                <div className="summary__item">
                    <Icon>assignment_turned_in</Icon>
                    <span>{t(preDefinedCurrentSelection)}</span>
                </div>
            )}

            {currentSelection.groupBy === 'cabinets' && (
                <div className="summary__item" id="grouping">
                    <div className="summary__item-list">
                        <Icon>filter_list</Icon>
                        <span>
                            {t('filterBy')} {t('cabinets')}
                        </span>
                        <span className="summary__item-count ml-1">{currentSelection.selectedCabinetFilters.members.length}</span>
                    </div>
                    {currentSelection.selectedCabinetFilters.members.length > 0 && (
                        <Popover
                            className="summary__popover"
                            isOpen={groupingOpen}
                            target="grouping"
                            toggle={() => setGroupingOpen(!groupingOpen)}
                            placement="bottom"
                            trigger="click hover focus"
                        >
                            <PopoverBody className="summary__popover-container">
                                <ul>
                                    {currentSelection.selectedCabinetFilters.members.map((member, index) => {
                                        return (
                                            <li key={member}>
                                                <span className="mr-2">{index + 1}. </span>
                                                {cabinets.find(device => device.deviceCode === member) &&
                                                    cabinets.find(device => device.deviceCode === member).name}
                                            </li>
                                        );
                                    })}
                                </ul>
                            </PopoverBody>
                        </Popover>
                    )}
                </div>
            )}

            {currentSelection.groupBy === 'locations' && (
                <div className="summary__item" id="grouping">
                    <div className="summary__item-list">
                        <Icon>filter_list</Icon>
                        <span>
                            {t('filterBy')} {t('locations')}
                        </span>
                        <span className="summary__item-count ml-1">{currentSelection.selectedLocationFilters.members.length}</span>
                    </div>

                    {currentSelection.selectedLocationFilters.members.length > 0 && (
                        <Popover
                            className="summary__popover"
                            isOpen={groupingOpen}
                            target="grouping"
                            toggle={() => setGroupingOpen(!groupingOpen)}
                            placement="bottom"
                            trigger="click hover focus"
                        >
                            <PopoverBody className="summary__popover-container">
                                <ul>
                                    {currentSelection.selectedLocationFilters.members.map((member, index) => {
                                        return (
                                            <li key={member}>
                                                {' '}
                                                <span className="mr-2">{index + 1}. </span>
                                                {member}
                                            </li>
                                        );
                                    })}
                                </ul>
                            </PopoverBody>
                        </Popover>
                    )}
                </div>
            )}

            {currentSelection.queryType === 'custom' && (
                <Fragment>
                    <div className="summary__item" id="metrics">
                        <div className="summary__item-list">
                            <Icon>grain</Icon>
                            <span>{t('metrics')}</span>
                            <span className="summary__item-count ml-1">
                                {getMetricFilterLength(currentSelection.selectedMeasures.members, currentSelection.selectedMeasures?.filters[0]?.values)}
                            </span>
                        </div>
                        {currentSelection.selectedMeasures.members.length > 0 && measures && (
                            <Popover
                                className="summary__popover"
                                isOpen={metricsOpen}
                                target="metrics"
                                toggle={() => setMetricOpen(!metricsOpen)}
                                placement="bottom"
                                trigger="click hover focus"
                            >
                                <PopoverBody className="summary__popover-container">
                                    <ul>
                                        {currentSelection.selectedMeasures.members.map((member, index) => {
                                            const getMeasure = measures.find(measure => measure.value === member);
                                            const includesFilters = correctFilters(currentSelection.selectedMeasures.filters, member);
                                            return (
                                                <li key={member}>
                                                    {' '}
                                                    <span className="mr-2">{index + 1}. </span>
                                                    {getMeasure ? getMeasure.label : ''}
                                                    {includesFilters && currentSelection.selectedMeasures.filters.length > 0 && (
                                                        <ul>
                                                            {currentSelection.selectedMeasures.filters.map((filter, i) => {
                                                                return (
                                                                    <li key={i}>
                                                                        <ul style={{ maxWidth: '200px' }}>
                                                                            {filter.values.map((filterValue, i) => {
                                                                                return <li key={i}>{filterValue}</li>;
                                                                            })}
                                                                        </ul>
                                                                    </li>
                                                                );
                                                            })}
                                                        </ul>
                                                    )}
                                                </li>
                                            );
                                        })}
                                    </ul>
                                </PopoverBody>
                            </Popover>
                        )}
                    </div>
                    <div className="summary__item" id="dimension">
                        <div className="summary__item-list">
                            <Icon>format_list_bulleted</Icon>
                            <span>{t('dimensions')}</span>
                            <span className="summary__item-count ml-1">{currentSelection.selectedDimensions.members.length}</span>
                        </div>
                        {currentSelection.selectedDimensions.members.length > 0 && dimensions && (
                            <Popover
                                className="summary__popover"
                                isOpen={dimensionOpen}
                                target="dimension"
                                toggle={() => setDimensionOpen(!dimensionOpen)}
                                placement="bottom"
                                trigger="click hover focus"
                            >
                                <PopoverBody className="summary__popover-container">
                                    <ul>
                                        {currentSelection.selectedDimensions.members.map((member, index) => {
                                            const getDimension = dimensions.find(dimension => dimension.value === member);

                                            return (
                                                <li key={member}>
                                                    {' '}
                                                    <span className="mr-2">{index + 1}. </span>
                                                    {getDimension ? getDimension.label : ''}
                                                </li>
                                            );
                                        })}
                                    </ul>
                                </PopoverBody>
                            </Popover>
                        )}
                    </div>
                </Fragment>
            )}
        </div>
    );
};

export default SelectionSummary;
