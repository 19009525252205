import { runCubeQuery as runCubeQueryHttpRequest } from '../api.js';
import {
    getTotalSalesCubeQuery,
    getTotalTransactionCountCubeQuery,
    getTotalItemsSoldCountCubeQuery,
    getTotalUniqueCustomersCountCubeQuery,
    getTotalCanceledCubeQuery,
    getAvgSalesCubeQuery,
    getAvgItemsCountCubeQuery,
    getCustomCubeQuery,
    getDefaultGranularity,
    getDashboardSummaryDataCubeQuery,
    getTotalProductsSoldCubeQuery,
    getExpiredProductsCubeQuery,
    getProductsTransactionsCubeQuery,
    getDiscountCubeQuery,
    getDashboardSummaryPaidAmountCubeQuery,
    getTotalSalesTransactionDetails,
    getUnpaidSalesCubeQuery,
    getUnpaidSalesCountCubeQuery,
    getTotalConsumerResponsesCubeQuery,
    getTotalConsumerResponseRatingsCubeQuery,
} from '../cubeQuery.js';

export const REQUEST_DASHBOARD_DATA = 'REQUEST_DASHBOARD_DATA';
export const REQUEST_WASTE_DASHBOARD_DATA = 'REQUEST_WASTE_DASHBOARD_DATA';
export const RECEIVE_WASTE_DASHBOARD_DATA = 'RECEIVE_WASTE_DASHBOARD_DATA';
export const RECEIVE_WASTE_DASHBOARD_ERROR = 'RECEIVE_WASTE_DASHBOARD_ERROR';
export const RECEIVE_CONSUMER_RESPONSE_DASHBOARD_ERROR = 'RECEIVE_CONSUMER_RESPONSE_DASHBOARD_ERROR';
export const REQUEST_CONSUMER_RESPONSE_DASHBOARD_DATA = 'REQUEST_CONSUMER_RESPONSE_DASHBOARD_DATA';
export const RECEIVE_CONSUMER_RESPONSE_DASHBOARD_DATA = 'RECEIVE_CONSUMER_RESPONSE_DASHBOARD_DATA';
export const REQUEST_DISCOUNT_DASHBOARD_DATA = 'REQUEST_DISCOUNT_DASHBOARD_DATA';
export const RECEIVE_DISCOUNT_DASHBOARD_DATA = 'RECEIVE_DISCOUNT_DASHBOARD_DATA';
export const RECEIVE_DISCOUNT_DASHBOARD_ERROR = 'RECEIVE_DISCOUNT_DASHBOARD_ERROR';
export const RECEIVE_DASHBOARD_DATA = 'RECEIVE_DASHBOARD_DATA';
export const RECEIVE_DASHBOARD_DATA_ERROR = 'RECEIVE_DASHBOARD_DATA_ERROR';
export const REQUEST_DASHBOARD_CHART_DATA = 'REQUEST_DASHBOARD_CHART_DATA';
export const RECEIVE_DASHBOARD_CHART_DATA = 'RECEIVE_DASHBOARD_CHART_DATA';
export const RECEIVE_DASHBOARD_CHART_DATA_ERROR = 'RECEIVE_DASHBOARD_CHART_DATA_ERROR';
export const REQUEST_CUSTOM_QUERY_DATA = 'REQUEST_CUSTOM_QUERY_DATA';
export const RECEIVE_CUSTOM_QUERY_DATA = 'RECEIVE_CUSTOM_QUERY_DATA';
export const RECEIVE_CUSTOM_QUERY_DATA_ERROR = 'RECEIVE_CUSTOM_QUERY_DATA_ERROR';
export const RESET_CUSTOM_QUERY_DATA = 'RESET_CUSTOM_QUERY_DATA';
export const RESET_ANALYTICS_DATA = 'RESET_ANALYTICS_DATA';
export const ANALYTICS_SEARCH_QUERY = 'ANALYTICS_SEARCH_QUERY';

/**
 * Request dashboard data Redux action creator
 */
function requestDashboardData(dateRange) {
    return {
        type: REQUEST_DASHBOARD_DATA,
        dateRange,
    };
}

/**
 * Receive dashboard data Redux action creator
 */
function receiveDashboardData(
    summaryData,
    totalCanceled,
    chartData,
    salesChartData,
    transactionsChartData,
    productsSoldData,
    summaryDataTransactionDetails,
    totalOrderSum,
    totalUnpaidAmount,
    totalUnpaidCount
) {
    return {
        type: RECEIVE_DASHBOARD_DATA,
        summaryData,
        totalCanceled,
        chartData,
        salesChartData,
        transactionsChartData,
        productsSoldData,
        summaryDataTransactionDetails,
        totalOrderSum,
        totalUnpaidAmount,
        totalUnpaidCount,
    };
}

/**
 * Receive dashboard data error Redux action creator
 * @param {Error} error
 */
function receiveDashboardDataError(error) {
    return {
        type: RECEIVE_DASHBOARD_DATA_ERROR,
        error,
    };
}

function requestDashboardChartData() {
    return {
        type: REQUEST_DASHBOARD_CHART_DATA,
    };
}

function receiveDashboardChartData(data) {
    return {
        type: RECEIVE_DASHBOARD_CHART_DATA,
        data,
    };
}

function receiveDashboardChartDataError(error) {
    return {
        type: RECEIVE_DASHBOARD_CHART_DATA_ERROR,
        error,
    };
}

/**
 * Request custom query data Redux action creator
 */
function requestCustomQueryData() {
    return {
        type: REQUEST_CUSTOM_QUERY_DATA,
    };
}

/**
 * Request data for the waste dasboard
 */

function requestWasteDashabordData() {
    return {
        type: REQUEST_WASTE_DASHBOARD_DATA,
    };
}

/**
 * Received date for the waste dashboard
 */
function receivedWasteDashboardData(expiredProducts, totalSales) {
    return {
        type: RECEIVE_WASTE_DASHBOARD_DATA,
        expiredProducts,
        totalSales,
    };
}

function receivedWasteDashboardError(error) {
    return {
        type: RECEIVE_WASTE_DASHBOARD_ERROR,
        error,
    };
}
/**
 * Request data for the consumer response dasboard
 */

function requestConsumerResponseDashboardData() {
    return {
        type: REQUEST_CONSUMER_RESPONSE_DASHBOARD_DATA,
    };
}

/**
 * Received date for the consumer response dashboard
 */
function receivedConsumerResponseDashboardData(consumerResponses, consumerResponseRatings) {
    return {
        type: RECEIVE_CONSUMER_RESPONSE_DASHBOARD_DATA,
        consumerResponses,
        consumerResponseRatings,
    };
}

function receivedConsumerResponseDashboardError(error) {
    return {
        type: RECEIVE_CONSUMER_RESPONSE_DASHBOARD_ERROR,
        error,
    };
}

/**
 * Request discount dashboard data
 */
function requestDiscountDashboardData() {
    return {
        type: REQUEST_DISCOUNT_DASHBOARD_DATA,
    };
}

/**
 * Recive discount dashboard data
 */
function receiveDiscountDashboardData(data) {
    return {
        type: RECEIVE_DISCOUNT_DASHBOARD_DATA,
        data,
    };
}

/**
 * Recive discount dahsboard error
 */
function receiveDiscountDashboardError(error) {
    return {
        type: RECEIVE_DISCOUNT_DASHBOARD_ERROR,
        error,
    };
}

/**
 * Receive custom query data Redux action creator
 * @param {object} cubeResponse cubejs query response
 */
function receiveCustomQueryData(cubeResponse) {
    return {
        type: RECEIVE_CUSTOM_QUERY_DATA,
        cubeResponse,
    };
}

/**
 * Receive custom query data error Redux action creator
 * @param {Error} error
 */
function receiveCustomQueryDataError(error) {
    return {
        type: RECEIVE_CUSTOM_QUERY_DATA_ERROR,
        error: error,
    };
}

export function resetCustomQueryData() {
    return {
        type: RESET_CUSTOM_QUERY_DATA,
    };
}

export function resetAnalyticsData() {
    return {
        type: RESET_ANALYTICS_DATA,
    };
}

function getDashboardChartQueryFunctionFromDataCategory(dataCategory) {
    switch (dataCategory) {
        case 'sales':
            return getTotalSalesTransactionDetails;
        case 'transactions':
            return getTotalTransactionCountCubeQuery;
        case 'itemsSold':
            return getTotalItemsSoldCountCubeQuery;
        case 'uniqueCustomers':
            return getTotalUniqueCustomersCountCubeQuery;
        case 'unpaidSales':
            return getUnpaidSalesCubeQuery;
        case 'unpaidTransactions':
            return getUnpaidSalesCountCubeQuery;
        case 'averageSales':
            return getAvgSalesCubeQuery;
        case 'averageItems':
            return getAvgItemsCountCubeQuery;
        default:
            throw new Error('Cant get query for data category that doesnt exist!');
    }
}

/**
 * Thunk action creator for fetching products - sales dashboard
 * @param {string} dateRange
 */
export function fetchDashboardData(dateRange, filter, dataCategory = 'sales') {
    return function(dispatch, getState) {
        // update state to inform API call started
        dispatch(requestDashboardData(dateRange));

        // fetch dashboard data
        const user = getState().user;
        return Promise.all([
            runCubeQueryHttpRequest(getDashboardSummaryDataCubeQuery(dateRange, filter), user.group, user.accessToken),
            runCubeQueryHttpRequest(getTotalCanceledCubeQuery(dateRange, filter), user.group, user.accessToken),
            runCubeQueryHttpRequest(
                getDashboardChartQueryFunctionFromDataCategory(dataCategory)(dateRange, filter, getDefaultGranularity(dateRange)),
                user.group,
                user.accessToken
            ),
            runCubeQueryHttpRequest(
                getDashboardChartQueryFunctionFromDataCategory('sales')(dateRange, filter, getDefaultGranularity(dateRange)),
                user.group,
                user.accessToken
            ),
            runCubeQueryHttpRequest(
                getDashboardChartQueryFunctionFromDataCategory('transactions')(dateRange, filter, getDefaultGranularity(dateRange)),
                user.group,
                user.accessToken
            ),
            runCubeQueryHttpRequest(getTotalProductsSoldCubeQuery(dateRange, filter), user.group, user.accessToken),
            runCubeQueryHttpRequest(getDashboardSummaryPaidAmountCubeQuery(dateRange, filter), user.group, user.accessToken),
            runCubeQueryHttpRequest(getTotalSalesCubeQuery(dateRange, filter), user.group, user.accessToken),
            runCubeQueryHttpRequest(getUnpaidSalesCubeQuery(dateRange, filter), user.group, user.accessToken),
            runCubeQueryHttpRequest(getUnpaidSalesCountCubeQuery(dateRange, filter), user.group, user.accessToken),
        ])
            .then(results =>
                dispatch(
                    receiveDashboardData(
                        results[0],
                        results[1],
                        populateMinimumMissingChartData(results[2], filter),
                        populateMinimumMissingChartData(results[3], filter),
                        populateMinimumMissingChartData(results[4], filter),
                        results[5],
                        results[6],
                        results[7],
                        results[8],
                        results[9]
                    )
                )
            )
            .catch(error => dispatch(receiveDashboardDataError(error)));
    };
}

export function fetchWasteDashboardData(dateRange, filter, granularity) {
    if (!granularity) granularity = getDefaultGranularity(dateRange);
    return function(dispatch, getState) {
        // update state to inform API call started
        dispatch(requestWasteDashabordData());

        const user = getState().user;
        return Promise.all([
            runCubeQueryHttpRequest(getExpiredProductsCubeQuery(dateRange, filter, granularity), user.group, user.accessToken),
            runCubeQueryHttpRequest(getProductsTransactionsCubeQuery(dateRange, filter, granularity), user.group, user.accessToken),
        ])
            .then(result => {
                dispatch(receivedWasteDashboardData(result[0], result[1]));
            })
            .catch(error => {
                dispatch(receivedWasteDashboardError(error));
                console.error(error);
            });
    };
}

export function fetchConsumerResponseDashboardData(dateRange, filter, granularity) {
    if (!granularity) granularity = getDefaultGranularity(dateRange);
    return function(dispatch, getState) {
        // update state to inform API call started
        dispatch(requestConsumerResponseDashboardData());

        const user = getState().user;
        return Promise.all([
            runCubeQueryHttpRequest(getTotalConsumerResponsesCubeQuery(dateRange, filter), user.group, user.accessToken),
            runCubeQueryHttpRequest(getTotalConsumerResponseRatingsCubeQuery(dateRange, filter), user.group, user.accessToken),
        ])
            .then(result => {
                dispatch(receivedConsumerResponseDashboardData(result[0], result[1]));
            })
            .catch(error => {
                dispatch(receivedConsumerResponseDashboardError(error));
                console.error(error);
            });
    };
}

export function fetchDiscountDashboardData(dateRange, filter, granularity) {
    if (!granularity) granularity = getDefaultGranularity(dateRange);

    return (dispatch, getState) => {
        dispatch(requestDiscountDashboardData());

        const user = getState().user;

        return runCubeQueryHttpRequest(getDiscountCubeQuery(dateRange, filter, granularity), user.group, user.accessToken)
            .then(result => dispatch(receiveDiscountDashboardData(result)))
            .catch(error => receiveDiscountDashboardError(error));
    };
}

export function fetchDashboardChartData(dataCategory, dateRange, filter, granularity) {
    if (!granularity) granularity = getDefaultGranularity(dateRange);

    return function(dispatch, getState) {
        // update state to inform API call started
        dispatch(requestDashboardChartData());

        const queryFunc = getDashboardChartQueryFunctionFromDataCategory(dataCategory);
        const query = queryFunc(dateRange, filter, granularity);
        // fetch data
        const user = getState().user;
        return runCubeQueryHttpRequest(query, user.group, user.accessToken, dataCategory)
            .then(result => {
                dispatch(receiveDashboardChartData(populateMinimumMissingChartData(result, filter)));
            })
            .catch(error => dispatch(receiveDashboardChartDataError(error)));
    };
}

export function fetchCustomCubeQuery(
    measures = [],
    dimensions = [],
    dateRange = 'All time',
    granularity = 'w/o grouping',
    filters = [],
    segments = [],
    renewQuery = true
) {
    return function(dispatch, getState) {
        // update state to inform API call started
        dispatch(requestCustomQueryData());

        // fetch data then update state
        const user = getState().user;
        return runCubeQueryHttpRequest(
            getCustomCubeQuery(measures, dimensions, dateRange, granularity, filters, segments, renewQuery),
            user.group,
            user.accessToken,
            -8
        )
            .then(result => dispatch(receiveCustomQueryData(result)))
            .catch(error => dispatch(receiveCustomQueryDataError(error)));
    };
}

/**
 * Adds chart data for minimum timestamp for all missing locations/cabinets so that chart will show their lines
 * even if its value is a constant 0
 *
 * @param {object} cubeResult
 * @param {object} filter
 */
function populateMinimumMissingChartData(cubeResult, filter) {
    if (cubeResult.data.length < 1 || !filter || !cubeResult.query.timeDimensions[0].dateRange) return cubeResult;
    const filterValues = filter.filterBy === 'regions' ? filter.values : filter.values.map(value => value.name);
    const measure = cubeResult.query.measures[0];
    const dimension = cubeResult.query.dimensions[0];
    const minTimestamp = cubeResult.query.timeDimensions[0].dateRange[0];
    const minTimeData = cubeResult.data.filter(entry => entry['Transactions.timestamp'] === minTimestamp);
    const missingData = [];
    filterValues.forEach(filterValue => {
        const found = minTimeData.find(entry => entry[dimension] === filterValue);
        if (!found) {
            missingData.push({
                [dimension]: filterValue,
                'Transactions.timestamp': minTimestamp,
                [measure]: 0,
            });
        }
    });

    cubeResult.data.unshift(...missingData);
    return cubeResult;
}

export function analyticsSearchQuery(query) {
    return function(dispatch) {
        dispatch({
            type: ANALYTICS_SEARCH_QUERY,
            query,
        });
    };
}
