import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { Row, Col, Alert } from 'reactstrap';
import { Spinner, TabBar, TabItem } from '@storaensods/seeds-react';
import { NotificationManager } from 'react-notifications';
import { map } from 'lodash';
import { store } from './../../index';

import {
    fetchDeviceStatus,
    clearLockDoorCommandStatus,
    clearUnlockDoorCommandStatus,
    clearDeviceRebootCommandStatus,
    lockTheDoor,
    unlockTheDoor,
    rebootDevice,
    fetchSelectedDeviceConfiguration,
    startMaintenanceMode,
    endMaintenanceMode,
    clearEndMaintenaceStatus,
    clearStartMaintenaceStatus,
    updateCabinetInfoManually,
} from '../../actions/controlPanel';
import ConfirmPopover from './confirmPopover';
import SoftwareVersion from './softwareVersion';
import './controlPanel.css';

/**
 * ControlPanel page component
 */ export class ControlPanel extends Component {
    constructor(props) {
        super(props);
        this.canSeeSettings = props.canSeeSettings;
        const state = store.getState();
        this.isAdmin = state && state.user && state.user.isAdmin;

        this.state = {
            controls: {},
            selectedControl: null,
        };
    }

    renderControlsState = () => {
        if (this.props.selectedCabinetDeviceCode) {
            // Some controls enabled only for admins
            const updateobj = { controls: {} };
            //updateobj.controls.refill = 'refillQRCodeTitle';

            if (this.isAdmin) {
                updateobj.controls.deviceControl = 'controls_device';
                updateobj.controls.sofware_version = 'controlsSoftwareVersion';

                updateobj.selectedControl = 'controls_device';
            }

            this.setState(updateobj);
        } else if (this.props.selectedEncodingDeviceCode) {
            const updateObj = { controls: {} };
            if (this.isAdmin) {
                updateObj.controls.deviceControl = 'controls_device';
                updateObj.controls.sofware_version = 'controlsSoftwareVersion';
            }
            updateObj.selectedControl = 'controls_device';
            this.setState(updateObj);
        }
    };

    async componentDidMount() {
        if (this.props.selectedEncodingDeviceCode && this.props.encodingDevices.fetched) {
            this.props.fetchDeviceStatus(this.props.selectedEncodingDeviceCode);
            this.props.fetchSelectedDeviceConfiguration();
            this.renderControlsState();
        }
        if (this.props.selectedCabinetDeviceCode && this.props.cabinets.fetched) {
            // make sure it fetched the cabinet status
            await this.props.fetchDeviceStatus(this.props.selectedCabinetDeviceCode);
            this.props.fetchSelectedDeviceConfiguration();
            this.renderControlsState();
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.selectedCabinetDeviceCode && this.props.selectedCabinetDeviceCode !== prevProps.selectedCabinetDeviceCode) {
            this.renderControlsState();
            this.props.fetchDeviceStatus(this.props.selectedCabinetDeviceCode);
            this.props.fetchSelectedDeviceConfiguration();
        }

        if (this.props.selectedEncodingDeviceCode && this.props.selectedEncodingDeviceCode !== prevProps.selectedEncodingDeviceCode) {
            this.renderControlsState();
            this.props.fetchDeviceStatus(this.props.selectedEncodingDeviceCode);
            this.props.fetchSelectedDeviceConfiguration();
        }

        const lockDoorCommand = this.props.controlPanel.lockDoorCommand;
        if (lockDoorCommand.inProgress === false && lockDoorCommand.status) {
            this.props.clearLockDoorCommandStatus();
            this.showCommandResultNotification(lockDoorCommand.status, this.props.t('main:doorCloseSuccess'));
        }

        const unlockDoorCommand = this.props.controlPanel.unlockDoorCommand;
        if (unlockDoorCommand.inProgress === false && unlockDoorCommand.status) {
            this.props.clearUnlockDoorCommandStatus();
            this.showCommandResultNotification(unlockDoorCommand.status, this.props.t('main:doorOpenSuccess'));
        }

        const rebootDeviceCommand = this.props.controlPanel.rebootDeviceCommand;
        if (rebootDeviceCommand.inProgress === false && rebootDeviceCommand.status) {
            this.props.clearDeviceRebootCommandStatus();
            this.showCommandResultNotification(rebootDeviceCommand.status, this.props.t('main:rebootDeviceSuccess'));
        }

        const startMaintenanceCommand = this.props.controlPanel.maintenanceStartCommand;
        if (!startMaintenanceCommand.inProgress && startMaintenanceCommand.status) {
            this.props.clearStartMaintenaceStatus();
            this.showCommandResultNotification(startMaintenanceCommand.status, this.props.t('main:startMaintenanceSuccess'));
        }

        const endMaintenanceCommand = this.props.controlPanel.maintenanceEndCommand;
        if (!endMaintenanceCommand.inProgress && endMaintenanceCommand.status) {
            this.props.clearEndMaintenaceStatus();
            this.showCommandResultNotification(endMaintenanceCommand.status, this.props.t('main:endMaintenanceSuccess'));
        }
    }

    confirmlockTheDoor = deviceCode => {
        this.props.unlockTheDoor(deviceCode);
    };

    /**
     * Show notification bar on top of UI
     * @param {Boolean} status HTTP Response status
     * @param {String} message Text string in notification bar
     */
    showCommandResultNotification = (status, message) => {
        if (status === 201) {
            NotificationManager.success(message);
        }
        if (status === 401) {
            NotificationManager.error(this.props.t('error_401'));
        }
        if (status === 403) {
            NotificationManager.error(this.props.t('error_403'));
        }
        if (status === 500) {
            NotificationManager.error(this.props.t('error_405'));
        }
    };

    createReplenishmentURI = selectedCabinetDeviceCode => {
        if (selectedCabinetDeviceCode) {
            return (process.env.REACT_APP_REPPLENISHMENT_URL || 'https://refill-qa.selflystore.com/') + selectedCabinetDeviceCode;
        } else {
            console.error('selected cabinet device code is null');
        }
    };

    renderCabinetStatus = (t, selectedCabinet) => {
        let message = null;
        let alertColorClass = null;
        let iconName = null;
        let addSpinner = false;

        if (this.props.selectedCabinetDeviceCode && selectedCabinet && selectedCabinet.isFetching) {
            message = t('fetchingCabinetStatus');
            alertColorClass = 'light';
            iconName = '';
            addSpinner = true;
        }

        if (selectedCabinet && selectedCabinet.fetched && selectedCabinet.cabinet && selectedCabinet.cabinet.isOnline) {
            message = t('cabinetOnline');
            alertColorClass = 'success';
            iconName = 'mood';
        }

        if (this.props.selectedCabinetDeviceCode && selectedCabinet && selectedCabinet.isError) {
            message = t('errorFetchingCabinetStatus');
            alertColorClass = 'danger';
            iconName = 'error_outline';
        }

        if (selectedCabinet && selectedCabinet.fetched && selectedCabinet.cabinet && !selectedCabinet.cabinet.isOnline) {
            message = t('cabinetDown');
            alertColorClass = 'danger';
            iconName = 'mood_bad';
        }

        if (!message || !alertColorClass) {
            return null;
        }

        return (
            <Alert color={alertColorClass} className="mb-0">
                <div className="h-100 d-flex align-items-center">
                    <i className="material-icons se-icon">{iconName}</i>
                    {addSpinner && <Spinner />}
                    &nbsp;&nbsp;{message}
                </div>
            </Alert>
        );
    };

    render() {
        const { t, controlPanel } = this.props;
        const { controls, selectedControl } = this.state;

        let selectedDevice;

        if (controlPanel.selectedCabinet.cabinet) selectedDevice = controlPanel.selectedCabinet.cabinet;
        else if (controlPanel.selectedEncodingDevice.encodingDevice) {
            selectedDevice = controlPanel.selectedEncodingDevice.encodingDevice;
            if (selectedDevice?.type === 'selflyEncode') {
                controls.sofware_version = undefined;
            }
        }
        return (
            <div className="container-fluid">
                {/* / Header */}
                <TabBar className="mt-3 not-printable">
                    {map(controls, value => (
                        <TabItem
                            key={value}
                            isActive={value === selectedControl}
                            onClick={() => {
                                this.setState({
                                    selectedControl: value,
                                });
                            }}
                        >
                            {t(value)}
                        </TabItem>
                    ))}
                </TabBar>
                <Row>
                    <Col>
                        {selectedControl === controls.deviceControl && this.isAdmin && (
                            <Fragment>
                                <Row>
                                    <Col className="col-1 controlPanel__deviceIconContainer">
                                        <div
                                            className={`${
                                                selectedDevice?.type === 'encodingStation' || selectedDevice?.type === 'selflyEncode'
                                                    ? selectedDevice?.type
                                                    : selectedDevice?.subType
                                            }__icon controlPanel__icon`}
                                        >
                                            {' '}
                                        </div>
                                    </Col>
                                    <Col className="col-6" style={{ minWidth: 'fit-content' }}>
                                        <div>
                                            <b>{selectedDevice?.name}</b>
                                        </div>
                                        <div>
                                            <b>ID:</b> {selectedDevice?.id}
                                        </div>
                                        <div>
                                            <b>Code:</b> {selectedDevice?.deviceCode}
                                        </div>
                                        <div>
                                            <b>{t('deviceType')}</b>:{' '}
                                            {selectedDevice && (
                                                <>
                                                    {selectedDevice.type === 'encodingStation'
                                                        ? t('encodingStation')
                                                        : selectedDevice.type === 'selflyEncode'
                                                        ? t('selflyEncode')
                                                        : selectedDevice.subType === 'IF2'
                                                        ? t('if2')
                                                        : selectedDevice.subType === 'IF3'
                                                        ? t('if3')
                                                        : selectedDevice.subType === 'IF3Freezer'
                                                        ? t('if3Freezer')
                                                        : ''}
                                                </>
                                            )}
                                        </div>
                                    </Col>
                                </Row>

                                <div>
                                    {this.props.selectedCabinetDeviceCode && (
                                        <>
                                            <ConfirmPopover
                                                icon="lock_open"
                                                disabled={controlPanel.unlockDoorCommand.inProgress}
                                                title={t('openDoor')}
                                                confirm={t('doorOpenConfirm')}
                                                hint={t('doorOpenReason')}
                                                askReason
                                                action={reason => {
                                                    this.props.unlockTheDoor(this.props.controlPanel.selectedCabinet.cabinet.deviceCode, reason);
                                                }}
                                                style={{ background: '#67B419' }}
                                            />
                                            <ConfirmPopover
                                                icon="lock_outline"
                                                disabled={controlPanel.unlockDoorCommand.inProgress}
                                                title={t('closeDoor')}
                                                confirm={t('doorCloseConfirm')}
                                                action={() => {
                                                    this.props.lockTheDoor(this.props.controlPanel.selectedCabinet.cabinet.deviceCode);
                                                }}
                                            />
                                        </>
                                    )}
                                </div>
                                <div style={{ marginTop: '10px' }}>
                                    {selectedDevice?.type !== 'selflyEncode' && (
                                        <ConfirmPopover
                                            icon="lock_outline"
                                            disabled={controlPanel.rebootDeviceCommand.inProgress}
                                            title={t('rebootDevice')}
                                            confirm={t('deviceRebootConfirm')}
                                            action={() => {
                                                this.props.rebootDevice(this.props.controlPanel.selectedCabinet.cabinet.deviceCode);
                                            }}
                                            style={{ background: '#67B419' }}
                                        />
                                    )}
                                </div>
                                <div style={{ marginTop: '10px' }}>
                                    {this.props.selectedCabinetDeviceCode && (
                                        <>
                                            <ConfirmPopover
                                                icon="build"
                                                disabled={controlPanel.maintenanceStartCommand.inProgress}
                                                title={t('startMaintenanceMode')}
                                                confirm={t('startMaintenanceConfirm')}
                                                action={() => {
                                                    this.props.startMaintenanceMode(this.props.controlPanel.selectedCabinet.cabinet.deviceCode);
                                                }}
                                                style={{ background: '#67B419' }}
                                            />

                                            <ConfirmPopover
                                                icon="block"
                                                disabled={controlPanel.maintenanceEndCommand.inProgress}
                                                title={t('endMaintenanceMode')}
                                                confirm={t('endMaintenanceConfirm')}
                                                action={() => {
                                                    this.props.endMaintenanceMode(this.props.controlPanel.selectedCabinet.cabinet.deviceCode);
                                                }}
                                            />
                                        </>
                                    )}
                                </div>
                                {this.props.selectedCabinetDeviceCode && (
                                    <div style={{ marginTop: '10px' }}>
                                        <ConfirmPopover
                                            icon="settings_outline"
                                            disabled={controlPanel.updateCabinetInfoCommand.inProgress}
                                            title={t('updateCabinetSettings')}
                                            confirm={t('updateCabinetSettingsInfo')}
                                            action={() => {
                                                this.props.updateCabinetInfoManually(this.props.controlPanel.selectedCabinet.cabinet.deviceCode);
                                            }}
                                            style={{ background: '#67B419' }}
                                        />
                                    </div>
                                )}
                            </Fragment>
                        )}                       
                        {this.isAdmin && selectedDevice?.type !== 'selflyEncode' && selectedControl === controls.sofware_version && (
                            <Row>
                                <Col xs="12" lg="12">
                                    <SoftwareVersion
                                        deviceCode={
                                            this.props.selectedCabinetDeviceCode ? this.props.selectedCabinetDeviceCode : this.props.selectedEncodingDeviceCode
                                        }
                                        deviceId={this.props.selectedCabinetDeviceId ? this.props.selectedCabinetDeviceId : this.props.selectedEncodingDeviceId}
                                    />
                                </Col>
                            </Row>
                        )}
                    </Col>
                </Row>
            </div>
        );
    }
}

export default withRouter(
    connect(
        state => ({
            cabinets: state.cabinets,
            encodingDevices: state.encodingDevices,
            controlPanel: state.controlPanel,
            selectedCabinetDeviceCode: state.cabinets.selectedCabinetDeviceCode,
            selectedEncodingDeviceCode: state.encodingDevices.selectedEncodingDeviceCode,
            selectedEncodingDeviceId: state.encodingDevices.selectedEncodingDeviceId,
            selectedCabinetDeviceId: state.cabinets.selectedCabinetDeviceId,
        }),
        {
            fetchDeviceStatus,
            clearLockDoorCommandStatus,
            clearUnlockDoorCommandStatus,
            clearDeviceRebootCommandStatus,
            lockTheDoor,
            unlockTheDoor,
            startMaintenanceMode,
            endMaintenanceMode,
            rebootDevice,
            fetchSelectedDeviceConfiguration,
            clearEndMaintenaceStatus,
            clearStartMaintenaceStatus,
            updateCabinetInfoManually,
        }
    )(translate('main')(ControlPanel))
);
