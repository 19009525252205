import mgr from '../userAuthManager.js';
import { fetchProfile } from '../api.js';
import { refreshProducts } from './products.js';
import { refreshAds } from './advertisements.js';
import { refreshCabinets, fetchLocations, selectCabinet } from './cabinets.js';
import { fetchOrganizationAlarms } from './cabinetAlarms.js';
import { refreshDevices } from './encodingDevices.js';
import { resetCabinetInventory } from './inventory.js';
import { resetReports } from './report.js';
import { refreshVatCategories } from './vatCategories.js';
import { storeSelectedGroup, getStoredSelectedGroup } from '../localStorage.js';
import { refreshDiscounts } from './discounts';
import { refreshProductCategories } from './productCategories.js';
import { refreshProductLabels } from './productLabels.js';
import { refreshSuppliers } from './suppliers.js';
import { refreshOrgSettings } from './organizations';
import { refreshOrganizationReleaseVersions } from './releaseVersions';
import { refreshRefillPlans } from './refillPlans.js';
import { resetAnalyticsData, resetCustomQueryData } from './analytics.js';
export const SIGN_IN_REJECTED = 'SIGN_IN_REJECTED';
export const AUTH_DATA_RECEIVED = 'AUTH_DATA_RECEIVED';
export const PROFILE_DATA_RECEIVED = 'PROFILE_DATA_RECEIVED';
export const REDIRECTING_TO_AUTHORIZATION_PAGE = 'REDIRECTING_TO_AUTHORIZATION_PAGE';
export const SIGN_OUT_USER = 'SIGN_OUT_USER';
export const SELECT_GROUP = 'SELECT_GROUP';

/**
 * sign in rejected Redux action creator
 */
function signInRejected(error) {
    return {
        type: SIGN_IN_REJECTED,
        error: error,
    };
}

/**
 * Authentication data received Redux action creator
 */
function authDataReceived(accessToken, userName, expiresAt) {
    return {
        type: AUTH_DATA_RECEIVED,
        accessToken,
        userName,
        expiresAt,
    };
}

/**
 * Profile data received Redux action creator
 */
function profileDataReceived(profile) {
    return {
        type: PROFILE_DATA_RECEIVED,
        profile,
    };
}

function selectGroup(group) {
    storeSelectedGroup(group);

    return {
        type: SELECT_GROUP,
        group: group,
    };
}

/**
 * Empty filters when (client) group is changed
 */
function emptyFilters() {
    window.localStorage.setItem('dashboardDateRange', 'Last 7 days');
    window.localStorage.setItem('dashboardFilter', null);
}

export function handleGroupChange(group) {
    emptyFilters();
    return dispatch => {
        dispatch(selectGroup(group));
        dispatch(fetchLocations());
        dispatch(refreshProducts());
        dispatch(refreshAds());
        dispatch(refreshCabinets());
        dispatch(fetchOrganizationAlarms());
        dispatch(refreshDevices());
        dispatch(resetCabinetInventory());
        dispatch(resetReports());
        dispatch(refreshVatCategories());
        dispatch(refreshDiscounts());
        dispatch(refreshProductCategories());
        dispatch(refreshProductLabels());
        dispatch(refreshSuppliers());
        dispatch(refreshOrgSettings());
        dispatch(refreshOrganizationReleaseVersions());
        dispatch(selectCabinet(null));
        dispatch(refreshRefillPlans());
        dispatch(resetAnalyticsData());
        dispatch(resetCustomQueryData());
    };
}

/**
 * Sign out user Redux Thunk action creator
 */
export function signOutUser() {
    return function(dispatch) {
        mgr.signoutRedirect();
        dispatch({ type: SIGN_OUT_USER });
    };
}

/**
 * Thunk redirect to sign in page action creator
 */
export function redirectToAuthorizationPage(error = null) {
    return function(dispatch) {
        if (error) {
            dispatch({ type: 'REDIRECTING_TO_AUTHORIZATION_PAGE', error });
        } else {
            mgr.signinRedirect();
        }
    };
}

/**
 * Thunk sign in user action creator
 */
export function signInUser() {
    return function(dispatch) {
        return mgr
            .getUser()
            .then(authData => {
                if (!authData) {
                    return Promise.resolve('User not signed in.');
                }

                // user auth data has been retrieved from authorization server
                dispatch(authDataReceived(authData.access_token, authData.profile.name, authData.expires_at));
                return fetchProfile(authData.access_token).then(userData => {
                    // use stored selected group if set and user belongs to group
                    const storedSelectedGroup = getStoredSelectedGroup();
                    if (storedSelectedGroup && userData.groups.filter(group => group.Id === storedSelectedGroup.Id).length > 0) {
                        dispatch(selectGroup(storedSelectedGroup));
                    } else {
                        const group = userData.groups && userData.groups.length > 0 ? userData.groups[0] : null;
                        dispatch(selectGroup(group));
                    }

                    dispatch(profileDataReceived(userData));

                    return Promise.resolve();
                });
            })
            .catch(err => {
                dispatch(signInRejected(err));
                return Promise.resolve(err);
            });
    };
}
