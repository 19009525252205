import React, { useEffect, useState } from 'react';
import { Icon } from '@storaensods/seeds-react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';

import './style.scss';
import SideBar from './sideBar';
import ReportView from './reportView';

import { getCustomQuerySelectorSetting } from './../../../localStorage';
import { fetchProducts } from '../../../actions/products';

const Reports = props => {
    const [currentSelection, setCurrentSelection] = useState({});
    const [sideBarCollapse, setSideBarCollapse] = useState(false);

    const prevState = getCustomQuerySelectorSetting();

    useEffect(() => {
        if (!props.products.isFetching && !props.products.isError) {
            props.fetchProducts();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div style={{ display: 'flex' }}>
            {/**SIDE BAR SECTION */}
            <div className={`sidebar pt-2 ${sideBarCollapse ? 'sidebar--collapse' : ''}`}>
                <div className="sidebar__toggle" onClick={() => setSideBarCollapse(!sideBarCollapse)}>
                    <Icon>{sideBarCollapse ? 'chevron_right' : 'chevron_left'}</Icon>
                </div>
                <SideBar
                    {...props}
                    currentSelection={currentSelection}
                    setCurrentSelection={selection => setCurrentSelection(selection)}
                    prevState={prevState}
                />
            </div>
            {/**REPORT SECTION */}
            <div className={`mt-2 report-view ml-1 ${sideBarCollapse ? 'report-view--fullscreen' : ''}`}>
                <ReportView {...props} currentSelection={currentSelection} />
            </div>
        </div>
    );
};

export default withRouter(
    connect(
        state => ({
            products: state.products,
        }),
        {
            fetchProducts,
        }
    )(translate('main')(Reports))
);
