import React, { useState, useEffect } from 'react';
import { Button, Modal, Input } from '@storaensods/seeds-react';
import { Row, Col } from 'reactstrap';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { fetchProducts } from '../../actions/products';
import {
    fetchProductCategories,
    createProductCategory,
    updateProductCategory,
    deleteProductCategory,
    dismissProductCategoryResults,
} from '../../actions/productCategories';
import FetchingAlert from '../fetching/fetchingAlert.js';
import DeletePrompt from '../deletePrompt/DeletePrompt.jsx';
import { showNotification } from '../toastNotification/toastNotification.js';
import ProductCategorySearchBar from './productCategorySearchBar';
import ProductCategoryCards from './productCategoryCards';

import './productCategories.css';

/**
 * The Entry point of the product category CRUD UI
 * @param {object} props Props for Product Categories
 * @param {function} props.t i18n translator function
 * @param {object} props.productCategories The Redux state for Product categories CRUD
 * @param {Boolean} props.isAdmin From Redux state. Indicates if the user has admin privileges
 * @param {function} props.fetchProductCategories From Redux state. Thunk action for fetching product categories
 * @param {function} props.createProductCategory From Redux state. Thunk action for creating a new product category
 * @param {function} props.updateProductCategory From Redux state. Thunk action for updating a product category
 * @param {function} props.deleteProductCategory From Redux state. Thunk action for deleting a product category
 * @param {function} props.dismissProductCategoryResults From Redux state. Dismisses the product category results.
 */
export function ProductCategories(props) {
    const {
        t,
        productCategories,
        searchQuery,
        products,
        fetchProducts,
        isAdmin,
        fetchProductCategories,
        createProductCategory,
        updateProductCategory,
        deleteProductCategory,
        dismissProductCategoryResults,
    } = props;

    // Hooks for activating new/edit product category modal and set its mode to 'NEW' or 'EDIT'
    const [activateModal, setActivateModal] = useState(false);
    const [mode, setModalMode] = useState();

    // Hooks for setting items to be deleted or edited
    const [editItem, setEditItem] = useState(null);
    const [deleteItem, setDeleteItem] = useState(null);

    // Hook for showing confirm delete prompt
    const [showDeletePrompt, setShowDeletePrompt] = useState(false);

    /**
     * Gets the product categories and handles the filtering for the search bar
     */
    function getProductCategoryItems() {
        if (productCategories) {
            if (productCategories.productCategories) {
                const selectedProductCategories = productCategories.productCategories.filter(category => {
                    if (!searchQuery) {
                        return true;
                    } else {
                        return (category.name || '').toLowerCase().indexOf(searchQuery.toLowerCase()) !== -1;
                    }
                });
                return selectedProductCategories;
            }
        }
    }

    const productCategoryItems = getProductCategoryItems();

    // UseEffect hook for fetching the data after component is mounted in case the data is not loaded yet
    useEffect(() => {
        if (!productCategoryItems) {
            fetchProductCategories();
            fetchProducts();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [productCategoryItems, fetchProductCategories]);

    // The hook to monitor deleteItem value and open the prompt.
    // deleteItem value will be set if the delete icon is clicked on the ui.
    useEffect(() => {
        if (deleteItem) {
            setShowDeletePrompt(true);
        }
    }, [deleteItem]);

    /**
     * Call back function for cancel button in confirm delete item prompt.
     * resets the deleteItem value and closes the prompt
     */
    function onDeleteCanceled() {
        setDeleteItem(null);
        setShowDeletePrompt(false);
    }

    function checkAssociatedProducts(productCategoryId) {
        const associatedProducts = products.products.filter(p => {
            return p.productCategoryId === productCategoryId;
        });
        if (associatedProducts.length > 0) {
            return true;
        } else {
            return false;
        }
    }

    /**
     * Call back function for confirm button in confirm delete prompt.
     * sends a Thunk action to delete the item, resets the deleteItem value and closes the prompt.
     */
    function onDeleteConfirmed() {
        if (!checkAssociatedProducts(deleteItem.id)) {
            deleteProductCategory(deleteItem.id);
        } else {
            showNotification(t('generalRequestReject'), t('productCategoryDeleteRejected'), 'error', onNotificationToastChanged);
        }

        setDeleteItem(null);
        setShowDeletePrompt(false);
        fetchProductCategories();
    }

    /**
     * Call back function to pass to Toastify toast and react on its changes.
     * In this case, it sends a redux action to dismiss the results of the request.
     * @param {number} state The state of the toast returned from Toastify toasts. Will be '1' when opened and '0' when closed.
     */
    function onNotificationToastChanged(state) {
        if (state === 1) {
            dismissProductCategoryResults();
        }
    }

    /**
     * Call back function to open the modal for a new product
     */
    function openModal() {
        setModalMode('NEW');
        setActivateModal(true);
    }

    return (
        <div className="product-categories">
            <div>
                <ProductCategoryModal
                    setActive={setActivateModal}
                    active={activateModal}
                    t={t}
                    mode={mode}
                    editItem={{ ...editItem }} // Creating a new object every time so if an item clicked twice in a row, the shallow comparisons between two objects, happening in the hooks, does not make bugs.
                    setEditItem={setEditItem}
                    createProductCategory={createProductCategory}
                    updateProductCategory={updateProductCategory}
                />
            </div>
            <div>
                <DeletePrompt
                    showDeletePrompt={showDeletePrompt}
                    onDeleteClicked={onDeleteConfirmed}
                    onCancelClicked={onDeleteCanceled}
                    message={t('productCategoryPromptMessage')}
                    t={t}
                />
            </div>
            {productCategories.isRequestingFinished &&
                productCategories.isError &&
                showNotification(
                    t('generalFetchError'),
                    productCategories.isDeletingFailed ? t('productCategoryDeleteRejected') : t('generalRequestReject'),
                    'error',
                    onNotificationToastChanged
                )}
            {productCategories.isRequestingFinished &&
                !productCategories.isError &&
                showNotification(null, t('requestSuccess'), 'success', onNotificationToastChanged)}
            <Row className="my-3">
                <Col className="catalog-top-row">
                    <ProductCategorySearchBar />
                    <div className="catalog-top-row-end-elements hide-in-mb">
                        <Button className="catalog-top-row-element" type="positive" icon="add_circle" onClick={openModal} disabled={!isAdmin}>
                            {t('createNewProductCategory')}
                        </Button>
                    </div>
                </Col>
            </Row>
            {productCategories.isFetching ||
            !productCategories.productCategories ||
            (productCategories.productCategories && productCategories.productCategories.length === 0) ? (
                <FetchingAlert
                    fetchingMessage={t('fetchingProductCategories')}
                    noDataMessage={t('noProductCategories')}
                    errorMessage={t('productCategoriesFetchError')}
                    isError={productCategories.isError}
                    noData={productCategories.productCategories && productCategories.productCategories.length === 0 && !productCategories.isFetching}
                />
            ) : productCategoryItems.length ? (
                <ProductCategoryList
                    t={t}
                    productCategoryList={productCategoryItems}
                    setModalMode={setModalMode}
                    setActivateModal={setActivateModal}
                    setEditItem={setEditItem}
                    setDeleteItem={setDeleteItem}
                    isAdmin={isAdmin}
                />
            ) : (
                <div className="no-results">{t('noResults')}</div>
            )}
        </div>
    );
}

/**
 * Uses SEEDS data tables to show the list of product categories
 * @param {object} props Props for Product Category List
 * @param {function} props.t i18n translator function
 * @param {Array} props.productCategoryList The list of product category items
 * @param {function} props.setModalMode React hook function to set the modal mode, 'EDIT' or 'NEW'
 * @param {function} props.setActivateModal React hook function to open the modal
 * @param {function} props.setEditItem React hook function to set the item to be edited
 * @param {function} props.setDeleteItem React hook function to set the item to be deleted
 * @param {Boolean} props.isAdmin Determins if the user has Create/Update/Delete permissions
 */
function ProductCategoryList(props) {
    const { productCategoryList, setModalMode, setActivateModal, setEditItem, setDeleteItem, isAdmin } = props;

    /**
     * Call back function to react if the edit icon in front of an item is clicked
     * Sets the item to be edited, sets the modal mode and opens the modal
     * @param {object} item The item to be edited
     */
    function onEditButtonClicked(item) {
        setEditItem(item);
        setModalMode('EDIT');
        setActivateModal(true);
    }

    /**
     * Call back function to react when the delete icon is clicked  in front of an item.
     * Sets the item to be deleted using the react hook
     * @param {object} item The item to be deleted
     */
    function onDeleteButtonClicked(item) {
        setDeleteItem({ ...item });
    }

    return (
        <div className="ProductCategoryCardContainer">
            <ProductCategoryCards isAdmin={isAdmin} data={productCategoryList} deleteAction={onDeleteButtonClicked} editAction={onEditButtonClicked} />
        </div>
    );
}

/**
 * The modal for entering new product category information or edit an existing one
 * @param {object} props Props for Product Category Modal
 * @param {function} props.t i18n translator function
 * @param {Boolean} props.active Indicates if the modal should be active (open) or not
 * @param {function} props.setActive React hook function to activate/deactivate the modal
 * @param {String} props.mode Indicated the mode of the modal. cab be 'NEW' or 'EDIT'
 * @param {Object} props.editItem The product category to be edited
 * @param {function} props.setEditItem React hook function to set the item to be edited
 * @param {function} props.createProductCategory Thunk action function for creating a new product category
 * @param {function} props.updateProductCategory Thunk action function for updating a product category
 */
function ProductCategoryModal(props) {
    const { t, active, setActive, mode, editItem, setEditItem, createProductCategory, updateProductCategory } = props;

    // React hooks to set the values of product categories and show their value in the input components
    const [productCategoryName, setProductCategoryName] = useState('');
    const [productCategoryDescription, setProductCategoryDescription] = useState('');

    // React hooks to set the validity of entered data for name and description of entered data
    const [productCategoryNameValid, setProductCategoryNameValid] = useState(false);

    // React hook to enable/disable saving changes in case enetered data is valid/invalid
    const [dontSave, setDontSave] = useState(false);

    /**
     * The Hook for checking validity of the entered data
     */
    useEffect(() => {
        if (
            typeof productCategoryName === 'string' &&
            productCategoryName.length > 2 &&
            productCategoryName.length < 51 &&
            isNaN(Number(productCategoryName)) // Not to allow a number for the name
        ) {
            setProductCategoryNameValid(true);
        } else {
            setProductCategoryNameValid(false);
        }
    }, [productCategoryName]);

    /**
     * The hook for setting the values in value hooks in case a new edit item is received
     */
    useEffect(() => {
        setProductCategoryName(editItem.name || '');
        setProductCategoryDescription(editItem.description ? editItem.description.toString() : '');
    }, [editItem]);

    /**
     * Call back function to be called when save button is clicked in the modal
     * in case the input values are not valid, it will set dontSave to true and the color of the button turns red and does not react.
     */
    function onSaveButtonClicked() {
        if (!productCategoryNameValid) {
            setDontSave(true);
            return;
        }

        // get value and form the body
        const itemBody = {
            name: productCategoryName,
            description: productCategoryDescription,
        };

        // send them to be saved
        if (mode === 'NEW') {
            // send to create
            createProductCategory(itemBody);
        } else if (mode === 'EDIT') {
            //send to update
            updateProductCategory(editItem.id, itemBody);
        }
        // Close modal an clean up values
        onModalClosed();
    }

    /**
     * Cleans up the name and description from the states hooks and closes the modal
     */
    function onModalClosed() {
        setProductCategoryName('');
        setProductCategoryDescription('');
        setEditItem(null);
        setDontSave(false);
        setActive(false);
    }

    return (
        <div>
            <Modal
                active={active}
                actions={[
                    {
                        label: t('save'),
                        onClick: () => onSaveButtonClicked(),
                        type: dontSave ? 'negative' : 'primary',
                    },
                ]}
                onClose={() => onModalClosed()}
                title={mode === 'NEW' ? t('newProductCategoryModalTitle') : mode === 'NEW' ? t('editProductCategoryModalTitle') : t('productCategory')}
            >
                <div className="product-category-modal">
                    <div className="product-category-name-input">
                        <span>{t('productCategoryName')}</span>
                        <Input
                            helpText={t('requiredText') + '3 - 50'}
                            placeholder={t('productCategoryHelpTextName')}
                            valid={productCategoryNameValid}
                            invalid={!productCategoryNameValid}
                            onChange={event => setProductCategoryName(event.target.value)}
                            value={productCategoryName}
                        />
                    </div>
                    <div className="productCategory-description-input">
                        <span>{t('productCategoryDescription')}</span>
                        <Input
                            placeholder={t('productCategoryHelpTextDescription')}
                            onChange={event => setProductCategoryDescription(event.target.value)}
                            value={productCategoryDescription}
                        />
                    </div>
                </div>
            </Modal>
        </div>
    );
}

export default connect(
    state => ({
        productCategories: state.productCategories,
        searchQuery: state.productCategories.searchQuery,
        products: state.products,
        isAdmin: state && state.user && state.user.isAdmin,
    }),
    {
        fetchProducts,
        fetchProductCategories,
        createProductCategory,
        updateProductCategory,
        deleteProductCategory,
        dismissProductCategoryResults,
    }
)(translate('main')(ProductCategories));
