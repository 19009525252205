import React from 'react';
import { Spinner, Modal, ModalBody, ModalFooter } from 'reactstrap';
import icons from './../../../icons/icomoon.svg';

const ApiRequestModal = ({ isOpen, data, closeModal, successMessage, t }) => {
    return (
        <Modal isOpen={isOpen} centered={true} size="lg">
            <ModalBody className="refillschema__modal refillschema__modal-request">
                {data.saving ||
                    (data.deleting && (
                        <div className="refillschema__modal-spinner">
                            <Spinner />
                            <div>{t('refill:savingData')}...</div>
                        </div>
                    ))}
                {data.error && (
                    <div className="refillschema__modal-info refillschema__modal-info--warn">
                        <svg>
                            <use xlinkHref={`${icons}#icon-error_outline`}></use>
                        </svg>
                        <div>{t('refill:errorSavingData')}</div>
                    </div>
                )}
                {!data.error && (data.isSaved || data.isDeleted) && (
                    <div className="refillschema__modal-info refillschema__modal-info--success">
                        <svg>
                            <use xlinkHref={`${icons}#icon-check-alt`}></use>
                        </svg>
                        <div> {successMessage ? successMessage : t('main:dataSaved')}</div>
                    </div>
                )}
            </ModalBody>
            {(data.isSaved || data.error || data.isDeleted) && (
                <ModalFooter>
                    <div className="refillschema__modal-btn" onClick={() => closeModal()}>
                        {t('main:close')}
                    </div>
                </ModalFooter>
            )}
        </Modal>
    );
};

export default ApiRequestModal;
