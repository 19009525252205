import React, { Fragment } from 'react';
import { Button, Spinner, Modal, Icon } from '@storaensods/seeds-react';

const DeleteProductModal = ({ deleteModelOpen, deleteModalClose, product, deleteProduct, t, deleteProgress }) => {
    const productToDelete = {
        id: product.id,
        barcode: product.barcode,
        barcodeType: product.barcodeType,
        status: 4,
    };

    return (
        <Modal active={deleteModelOpen} onClose={deleteModalClose}>
            <div className="container" style={{ width: '30rem' }}>
                {deleteProduct.isError && (
                    <div className="align-items-center" style={{ minHeight: '10rem', textAlign: 'center' }}>
                        <Icon className="cu-error-icon"> error_outline </Icon>
                        <div style={{ color: '#dc3545' }}>{t('deleteError')}</div>
                        <div style={{ float: 'right', marginTop: '10px' }}>
                            <Button size="sm" type="positive" icon="cancel" onClick={() => deleteModalClose(true)}>
                                {t('close')}
                            </Button>
                        </div>
                    </div>
                )}
                {deleteProgress.isDeleting && !deleteProgress.deleted && (
                    <div className="row justify-content-center align-items-center" style={{ minHeight: '10rem' }}>
                        <Spinner size="lg" />
                    </div>
                )}
                {deleteProgress.deleted && !deleteProgress.isError && !deleteProgress.isDeleting && (
                    <div className="align-items-center" style={{ minHeight: '10rem', textAlign: 'center' }}>
                        <div className="cu-change-success"> </div>
                        <div>{t('deleteSuccess')}</div>
                        <div style={{ float: 'right', marginTop: '10px' }}>
                            <Button size="sm" type="positive" icon="cancel" onClick={() => deleteModalClose(true)}>
                                {t('close')}
                            </Button>
                        </div>
                    </div>
                )}
                {!deleteProgress.isDeleting && !deleteProgress.deleted && !deleteProgress.isError && (
                    <div className="row se-p-lg">
                        <h6 style={{ textAlign: 'center', fontWeight: '600', width: '100%', color: '#cf142b' }}>
                            <i class="fas fa-exclamation-circle"></i>
                            <br />
                            {t('confirmDelete')}
                        </h6>
                        <div className="col-12" style={{ textAlign: 'center' }}>
                            <div>
                                <img src={product.imageUrl} alt="Unable to fetch" style={{ width: '150px', textAlign: 'center' }} />
                                <p>{product.name}</p>
                                <p>
                                    {t('barcode')} : {product.barcode}
                                </p>
                            </div>
                            <div>
                                {product.status === 3 && (
                                    <Fragment>
                                        <Button
                                            size="sm"
                                            type="negative"
                                            icon="delete_forever"
                                            onClick={() => deleteProduct(productToDelete)}
                                            style={{ marginRight: '2rem' }}
                                        >
                                            {t('delete')}
                                        </Button>
                                        <Button
                                            size="sm"
                                            type="positive"
                                            icon="cancel"
                                            onClick={() => {
                                                deleteModalClose(false);
                                            }}
                                        >
                                            {t('cancel')}
                                        </Button>
                                    </Fragment>
                                )}
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </Modal>
    );
};
export default DeleteProductModal;
