const defaultState = {
    refillPlan: null,
    activePlans: {},
    completedPlans: null,
    fetched: false,
    isFetching: false,
    isFetchingSuggestion: false,
    refillSuggestion: {
        isFetching: false,
        currentProgress: 0,
        totalLength: 0,
        fetched: false,
        error:null,
        suggestion: [],
    },
    isError: false,
};

export default function refillPlans(state = defaultState, action) {
    switch (action.type) {
        case 'RECEIVE_CREATE_REFILL_PLAN_SUGGESTIONS':
            return {
                ...state,
                refillSuggestion: {
                    currentProgress: state.refillSuggestion.currentProgress + action.deviceCount,
                    totalLength: action.totalLength,
                    fetched: state.refillSuggestion.currentProgress + action.deviceCount !== action.totalLength,
                    isFetching: state.refillSuggestion.currentProgress + action.deviceCount !== action.totalLength,
                    suggestion: [...state.refillSuggestion.suggestion, ...action.refillPlanSuggestions],
                    error:null
                },
            };
        case 'REQUEST_CREATE_REFILL_PLAN_SUGGESTIONS':
            return {
                ...state,
                refillSuggestion: {
                    isFetching: true,
                    currentProgress: 0,
                    totalLength: 0,
                    fetched: false,
                    suggestion: [],
                    error:null
                },
            };

        case 'RECEIVE_CREATE_REFILL_PLAN_SUGGESTIONS_ERROR':
            return {
                ...state,
                refillSuggestion:{
                    ...state.refillSuggestion,
                    error: action.error

                }
            }
        case 'REQUEST_REFILL_PLANS':
            return {
                ...state,
                isFetching: true,
                refillSuggestion: {
                    isFetching: false,
                    currentProgress: 0,
                    totalLength: 0,
                    fetched: false,
                    suggestion: [],
                },
            };
        case 'RECEIVE_REFILL_PLANS':
            const activePlans = {};
            const completedPlans = [];
            action.refillPlans.forEach(plan => {
                if (plan.status === 'Active') activePlans[plan.deviceCode] = plan;
                if (plan.status === 'Completed') completedPlans.push(plan);
            });
            return {
                ...state,
                isFetching: false,
                isError: false,
                fetched: true,
                deleting: false,
                isDeleted: false,
                activePlans,
                completedPlans,
                refillPlan: action.refillPlans,
            };
        case 'RECEIVE_REFILL_PLANS_ERROR':
            console.error(action.error);
            return {
                ...state,
                isFetching: false,
                isError: false,
                fetched: true,
                refillPlan: null,
                deleting: false,
                isDeleted: false,
                error: action.error.toString(),
            };

        case 'REQUEST_UPDATE_REFILL_PLAN':
            return {
                ...state,
                isFetching: false,
                isError: false,
                fetched: false,
                saving: true,
                isSaved: false,
                deleting: false,
                isDeleted: false,
                error: null,
            };

        case 'RECEIVE_UPDATE_REFILL_PLAN':
            const deviceCode = action.refillPlan.data?.deviceCode;
            const updatedActivePlans = {
                ...state.activePlans,
                [deviceCode]: { ...state.activePlans[deviceCode], productData: action.refillPlan.data.productData },
            };
            return {
                ...state,
                isFetching: false,
                isError: false,
                fetched: false,
                saving: false,
                isSaved: true,
                deleting: false,
                isDeleted: false,
                activePlans: updatedActivePlans,
            };

        case 'RECEIVE_UPDATE_REFILL_PLAN_ERROR':
            return{
                ...state,
                isSaved:false,
                saving:false,
                error:action.error
            }
        case 'RESET_REFILL_PLANS':
            return {
                ...defaultState,
            };
        case 'RECEIVE_DELETE_REFILL_PLAN': {
            return {
                ...state,
                isFetching: false,
                isError: false,
                fetched: false,
                isDeleted: true,
                deleting: false,
                activePlans: state.activePlans,
            };
        }
        case 'REQUEST_DELETE_REFILL_PLAN': {
            let plans = { ...state.activePlans };
            const planId = action.id;
            let removeActivePlans = {};
            Object.keys(plans).forEach(deviceCode => {
                if (plans[deviceCode].id !== planId) {
                    removeActivePlans = { ...removeActivePlans, [deviceCode]: { ...plans[deviceCode] } };
                }
            });

            return {
                ...state,
                isFetching: false,
                isError: false,
                fetched: false,
                saving: false,
                isSaved: false,
                deleting: true,
                isDeleted: false,
                error: null,
                activePlans: removeActivePlans,
            };
        }

        case 'RECEIVE_DELETE_REFILL_PLAN_ERROR':{
            return{
                ...state,
                deleting:false,
                isDeleted:false,
                error:action.error
            }
        }

        case 'REQUEST_CREATE_REFILL_PLAN': {
            return {
                ...state,
                saving: true,
                isSaved: false,
                error: null,
            };
        }

        case 'RECEIVE_CREATE_REFILL_PLAN': {
            return {
                ...state,
                saving: false,
                isSaved: true,
                error: null,
            };
        }

        case 'RECEIVE_CREATE_REFILL_PLAN_ERROR': {
            return {
                ...state,
                saving: false,
                isSaved: false,
                error: action.error,
            };
        }

        default:
            return state;
    }
}
