import { REQUEST_PRODUCT_LABELS, RECEIVE_PRODUCT_LABELS, RECEIVE_PRODUCT_LABELS_ERROR, RESET_PRODUCT_LABELS } from '../actions/productLabels.js';

export const initState = {
    isFetching: false,
    isError: false,
    fetched: false,
    isRequesting: false,
    isRequestingFinished: false,
    isDeletingFailed: false,
    productLabels: [],
    error: '',
};

export default function productLabels(state = initState, action) {
    switch (action.type) {
        case REQUEST_PRODUCT_LABELS:
            return {
                ...state,
                isFetching: true,
                isError: false,
                fetched: false,
            };
        case RECEIVE_PRODUCT_LABELS:
            return {
                ...state,
                isFetching: false,
                isError: false,
                productLabels: action.productLabels,
                fetched: true,
            };
        case RECEIVE_PRODUCT_LABELS_ERROR:
            console.log(action.error);
            return {
                ...state,
                isFetching: false,
                isError: true,
                error: action.error.toString(),
            };
        case RESET_PRODUCT_LABELS:
            return {
                ...initState,
            };
        default:
            return state;
    }
}
