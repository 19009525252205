import React, { useState, useEffect } from 'react';
import icons from '../styles/icons.svg';
import PhoneInput from 'react-phone-number-input';
import { Button, Modal, Select } from '@storaensods/seeds-react';

import { withRouter, useHistory } from 'react-router-dom';

import { connect } from 'react-redux';
import { translate } from 'react-i18next';

import {
    removeOrganizationLogoImage,
    updateOrganizationSettings,
    uploadOrganizationLogo,
    revertOrganizationLogoImageState,
} from '../../../actions/organizations.js';

import { ExtendedCountryList } from './countryList';

import Switch from 'react-switch';

import { ToastNotification, showNotification } from '../../toastNotification/toastNotification';

import ImageUpload from '../../imageUpload/imageUpload';

const ConsumerApp = props => {
    const [newOrgSettings, setNewOrgSettings] = useState({
        enableReceipt: props && props.organizationSettings && props.organizationSettings.enableReceipt ? true : false,
        enableFeedback: props && props.organizationSettings && props.organizationSettings.enableFeedback ? true : false,
        enableSelflyStoreTrademark: props && props.organizationSettings && props.organizationSettings.enableSelflyStoreTrademark ? true : false,
    });
    const [cropping, toggleCropping] = useState(false);
    const [imageEditMode, setImageEditMode] = useState(false);
    let history = useHistory();

    useEffect(() => {
        if (props && props.organizationSettings) {
            setNewOrgSettings({ ...newOrgSettings, ...props.organizationSettings });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.organizationSettings]);

    const updateConsumerAppSettings = (key, value) => {
        const settings = { ...newOrgSettings };
        settings[key] = value;
        setNewOrgSettings(settings);
    };

    const closeForm = () => {
        history.push('/');
    };

    const saveOrganizationSettings = () => {
        let settings = {};
        // Change empty '' string values to null
        Object.keys(newOrgSettings).forEach(s => {
            if (newOrgSettings[s] === '') settings[s] = null;
            else settings[s] = newOrgSettings[s];
        });
        // Delete property which is needed to revert state
        delete settings.oldOrganizationLogoUrl;
        delete settings.organizationLogoStoring;
        delete settings.imageStoringError;
        let missingMandatoryProperties = false;
        // TODO: Change API validation logic so that the following mandatory parameter check is skipped if receipt not enabled!
        if (true) {
            //if (settings.enableReceipt) {
            const mandatoryProperties = ['businessId', 'country', 'organisationName', 'street'];
            mandatoryProperties.forEach(property => {
                const dataKeys = Object.keys(settings);
                if (!dataKeys.includes(property)) missingMandatoryProperties = true;
                else if (!settings[property]) missingMandatoryProperties = true;
            });

            if (missingMandatoryProperties) {
                showNotification(t('consumerApp:organizationSettingsMissingFieldsTitle'), t('consumerApp:organizationSettingsMissingFields'), 'error');
                return false;
            }
        }
        if (!missingMandatoryProperties) {
            props.updateOrganizationSettings(settings, props.user.group.Id).then(res => {
                if (res && res.error) {
                    showNotification(t('main:error'), t('main:unexpectedError'), 'error');
                } else {
                    showNotification(t('consumerApp:organizationSettingsUpdatedTitle'), t('consumerApp:organizationSettingsUpdatedContent'), 'success');
                }
                closeForm();
            });
        }
    };
    const { t, organizationSettings, uploadOrganizationLogo, removeOrganizationLogoImage, revertOrganizationLogoImageState } = props;

    const setSelectedOrgLogo = () => {
        setNewOrgSettings({ ...newOrgSettings, organizationLogoUrl: organizationSettings.organizationLogoUrl });
        setImageEditMode(false);
    };
    const editLogo = () => {
        removeOrganizationLogoImage();
        setImageEditMode(true);
    };

    const toggleImgCropping = () => {
        toggleCropping(!cropping);
    };

    const cancelImageEditing = () => {
        setImageEditMode(false);
        revertOrganizationLogoImageState(organizationSettings?.organizationLogoUrl);
    };
    const LogoUpload = () => {
        if (imageEditMode) {
            return (
                <Modal title={t('main:logoEditor')} active={imageEditMode} onClose={() => setImageEditMode(false)}>
                    <div className="logoEditContainer d-flex mb-2 justify-content-between">
                        <div className="">
                            <div className="scp-settings__inputs-item">
                                <div className="logoEditContainer d-flex mb-2 justify-content-between">
                                    <ImageUpload
                                        className="se-input-container"
                                        targetWidth={parseInt(process.env.REACT_APP_COMPANY_LOGO_IMAGE_WIDTH) || 100}
                                        targetHeight={parseInt(process.env.REACT_APP_COMPANY_LOGO_IMAGE_HEIGHT) || 100}
                                        imageUrl={organizationSettings?.organizationLogoUrl || ''}
                                        storing={organizationSettings?.organizationLogoStoring || false}
                                        uploadImage={uploadOrganizationLogo}
                                        removeImage={removeOrganizationLogoImage}
                                        toggleCropping={toggleImgCropping}
                                        t={props.t}
                                        paddedAspectRatio={true}
                                    />
                                    <div className="d-flex justify-content-end">
                                        {organizationSettings.organizationLogoUrl && organizationSettings.organizationLogoUrl !== '' ? (
                                            <Button onClick={() => setSelectedOrgLogo()} className="mr-2">
                                                {t('use')}
                                            </Button>
                                        ) : null}
                                        <Button onClick={() => cancelImageEditing()}>{t('cancel')}</Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>
            );
        }

        return (
            <div className="mt-3 w-100 d-flex align-items-center mb-2">
                <div className=" d-flex scp-settings__inputs ">
                    {organizationSettings?.organizationLogoUrl ? (
                        <img src={organizationSettings.organizationLogoUrl} className="logoImage" alt="logo" />
                    ) : (
                        <div className="ml-3">{t('noLogo')}</div>
                    )}
                </div>
                <div className="d-flex">
                    <Button className="se-btn se-btn--sm se-btn--secondary ml-3" icon="edit" onClick={() => editLogo()} />
                    {organizationSettings?.organizationLogoUrl && organizationSettings.organizationLogoUrl !== '' && (
                        <Button
                            className="se-btn se-btn--sm se-btn--secondary ml-3"
                            icon="delete"
                            onClick={() => {
                                organizationSettings.organizationLogoUrl = null;
                                setSelectedOrgLogo();
                            }}
                        />
                    )}
                </div>
            </div>
        );
    };
    return (
        <>
            <section className="scp-settings">
                <ToastNotification />

                {/** settings page breadcrumbs */}
                <div className="scp-settings__breadcrumb">
                    <svg>
                        <use xlinkHref={`${icons}#icon-params`}></use>
                    </svg>
                    <span>{t('settings')}</span>
                    <div className="scp-settings__breadcrumb-direction">
                        <svg>
                            <use xlinkHref={`${icons}#icon-arrow-down`}></use>
                        </svg>
                    </div>
                    <span>MySelfly</span>
                </div>

                <form className="scp-settings__form">
                    <div className="scp-settings__inputs scp-settings__inputs--margin">
                        <div className="scp-settings__inputs-switch">
                            <Switch
                                className="react-switch"
                                uncheckedIcon={false}
                                checked={newOrgSettings ? newOrgSettings.enableFeedback : false}
                                onChange={checked => updateConsumerAppSettings('enableFeedback', checked)}
                            />
                            <p className="mb-0">{t('consumerApp:enableConsumerFeedback')}</p>
                        </div>
                    </div>

                    <div className="scp-settings__inputs scp-settings__inputs--margin">
                        <div className="scp-settings__inputs-switch">
                            <Switch
                                className="react-switch"
                                uncheckedIcon={false}
                                checked={newOrgSettings ? newOrgSettings.enableReceipt : false}
                                onChange={checked => updateConsumerAppSettings('enableReceipt', checked)}
                            />
                            <p className="mb-0">{t('consumerApp:enablePurchaseReceipts')}</p>
                        </div>
                    </div>

                    <div className="scp-settings__inputs  scp-settings__inputs--margin">
                        <div className="scp-settings__inputs-switch">
                            <Switch
                                className="react-switch"
                                uncheckedIcon={false}
                                disabled={!newOrgSettings?.enableReceipt}
                                checked={newOrgSettings ? newOrgSettings.enableSelflyStoreTrademark : false}
                                onChange={checked => updateConsumerAppSettings('enableSelflyStoreTrademark', checked)}
                            />
                            <p className="mb-0">{t('consumerApp:enableSelflyTrademarkForReceipts')}</p>
                        </div>
                        <p className="scp-settings__inputs-info">{t('consumerApp:enableSelflyTrademarkForReceiptsHelpText')}</p>
                    </div>

                    <div className="scp-settings__note">
                        {t('consumerApp:organizationFormHelpText')} {t('consumerApp:organizationFormValidationHelpText')}
                    </div>

                    {/**Name of Org */}
                    <div className="scp-settings__group">
                        <div className="scp-settings__title">
                            <svg>
                                <use xlinkHref={`${icons}#icon-office`}></use>
                            </svg>
                            <p className="mb-0">{t('consumerApp:organization')}</p>
                        </div>
                        <div className="scp-settings__inputs">
                            <div className="scp-settings__inputs-item">
                                <input
                                    type="text"
                                    value={newOrgSettings?.organisationName}
                                    placeholder={t('consumerApp:organizationName')}
                                    onChange={e => updateConsumerAppSettings('organisationName', e.target.value)}
                                />
                                <input
                                    type="text"
                                    value={newOrgSettings?.businessId}
                                    placeholder={t('consumerApp:businessId')}
                                    onChange={e => updateConsumerAppSettings('businessId', e.target.value)}
                                />
                            </div>
                        </div>
                    </div>
                    {/**Address of Org */}
                    <div className="scp-settings__group">
                        <div className="scp-settings__title">
                            <svg>
                                <use xlinkHref={`${icons}#icon-location2`}></use>
                            </svg>
                            <p className="mb-0">{t('consumerApp:addressHeader')}</p>
                        </div>
                        <div className="scp-settings__inputs country-selector-dropdown">
                            <div className="scp-settings__inputs-item">
                                <Select
                                    size="sm"
                                    onChange={({ value }) => {
                                        updateConsumerAppSettings('country', value);
                                    }}
                                    value={{ label: newOrgSettings?.country, value: newOrgSettings?.country }}
                                    options={ExtendedCountryList.sort((a, b) => a.name.localeCompare(b.name)).map(country => {
                                        return {
                                            label: country.name,
                                            value: country.name,
                                            className: country.name === newOrgSettings?.country ? 'language-dropdown-item-active' : null,
                                        };
                                    })}
                                />

                                <input
                                    type="text"
                                    value={newOrgSettings?.city}
                                    placeholder={t('consumerApp:city')}
                                    onChange={e => updateConsumerAppSettings('city', e.target.value)}
                                />
                                <input
                                    type="text"
                                    value={newOrgSettings?.street}
                                    placeholder={t('consumerApp:street')}
                                    onChange={e => updateConsumerAppSettings('street', e.target.value)}
                                />
                                <input
                                    type="text"
                                    value={newOrgSettings?.postalCode}
                                    placeholder={t('consumerApp:postalCode')}
                                    onChange={e => updateConsumerAppSettings('postalCode', e.target.value)}
                                />
                            </div>
                        </div>
                    </div>
                    {/**CONTACT details */}
                    <div className="scp-settings__group">
                        <div className="scp-settings__title">
                            <svg>
                                <use xlinkHref={`${icons}#icon-contacts`}></use>
                            </svg>
                            <p className="mb-0">{t('consumerApp:contactDetails')}</p>
                        </div>
                        <div className="scp-settings__inputs">
                            <div className="scp-settings__inputs-item">
                                <div className="scp-settings__inputs-phone">
                                    <PhoneInput
                                        international
                                        countryCallingCodeEditable={false}
                                        placeholder="Enter phone number"
                                        value={newOrgSettings?.phoneNumber}
                                        defaultCountry={'FI'}
                                        withCountryCallingCode
                                        onChange={value => updateConsumerAppSettings('phoneNumber', value)}
                                    />
                                </div>

                                <input
                                    type="text"
                                    value={newOrgSettings?.email}
                                    placeholder={t('consumerApp:email')}
                                    onChange={e => updateConsumerAppSettings('email', e.target.value)}
                                />
                                <input
                                    type="text"
                                    value={newOrgSettings?.website}
                                    placeholder={t('consumerApp:website')}
                                    onChange={e => updateConsumerAppSettings('website', e.target.value)}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="scp-settings__group">
                        <div className="scp-settings__title">
                            <svg>
                                <use xlinkHref={`${icons}#icon-contacts`}></use>
                            </svg>
                            <p className="mb-0">{t('consumerApp:organizationLogo')}</p>
                        </div>
                        {LogoUpload()}
                    </div>
                    <div className="scp-settings__group">
                        <button type="button" onClick={() => closeForm()} className="cancel">
                            {t('cancel')}
                        </button>
                        <button className="save" type="button" onClick={() => saveOrganizationSettings()}>
                            {t('save')}
                        </button>
                    </div>
                </form>
            </section>
        </>
    );
};
export default withRouter(
    connect(
        state => ({
            user: state.user,
            organizationSettings: state.organizationsSettings.organizationSettings,
        }),
        { updateOrganizationSettings, removeOrganizationLogoImage, uploadOrganizationLogo, revertOrganizationLogoImageState }
    )(translate('main')(ConsumerApp))
);
