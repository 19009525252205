import React, { Component } from 'react';
import { BarChart, Bar, XAxis, YAxis, Tooltip, CartesianGrid, ResponsiveContainer, LabelList } from 'recharts';
import momentTimeZone from 'moment-timezone';
import { Dropdown } from '@storaensods/seeds-react';
import moment from 'moment';
import { cubeDataKeyToLabel } from './customSelector.js';
import { default as Smiley100 } from './consumerResponseDashboard/smileyRatings/smiley-100-percent.svg';
import { default as Smiley66 } from './consumerResponseDashboard/smileyRatings/smiley-66-percent.svg';
import { default as Smiley33 } from './consumerResponseDashboard/smileyRatings/smiley-33-percent.svg';
import { default as Smiley0 } from './consumerResponseDashboard/smileyRatings/smiley-0-percent.svg';
export const chartColors = [
    '#118BD6',
    '#67B419',
    '#FDC80A',
    '#CF142B',
    '#C3E2F5',
    '#F3C4CA',
    '#FEE384',
    '#D9ECC5',
    '#800000',
    '#FFFF00',
    '#808000',
    '#00FF00',
    '#FF00FF',
    '#800080',
    '#008080',
];

/**
 * Returns dates from start date to stop date using given time granularity
 *
 * @param {Date} startDate
 * @param {Date} stopDate
 * @param {string} granularity year, month, week, hour, day
 * @returns {Array} dates
 */
export function getDatesRange(startDate, stopDate, granularity) {
    if (startDate > stopDate) {
        return [startDate];
    }

    // set stopdate to last hour of day if granularity is hour
    if (granularity === 'hour') stopDate.setTime(stopDate.getTime() + 23 * 60 * 60 * 1000);

    const dates = [];
    const currentDate = startDate;
    while (currentDate <= stopDate) {
        const d = new Date(currentDate);
        if (granularity === 'month') {
            const m = d.getMonth();
            d.setUTCHours(0, 0, 0, 0);
            d.setUTCDate(1);
            d.setUTCMonth(m);
            currentDate.setMonth(currentDate.getMonth() + 1);
        } else if (granularity === 'year') {
            currentDate.setFullYear(currentDate.getFullYear() + 1);
        } else if (granularity === 'hour') {
            currentDate.setTime(currentDate.getTime() + 60 * 60 * 1000);
        } else if (granularity === 'week') {
            currentDate.setTime(currentDate.getTime() + 7 * 24 * 3600 * 1000);
        } else {
            currentDate.setTime(currentDate.getTime() + 24 * 3600 * 1000);
        }
        dates.push(d);
    }

    return dates;
}

/**
 *
 * @param {Array} data
 * @param {string} yKey
 * @param {string} granularity
 * @param {string} startDate example format 2020-01-01T00:00:00.000
 * @param {string} endDate example format 2020-01-01T00:00:00.000
 */
function wrangle2DTimeDataTransactionDetails(data, yKey, granularity, startDate, endDate) {
    if (data.length === 0) return data;
    const firstDate = new Date(startDate ? startDate + 'Z' : data[0]['TransactionDetails.timestamp'] + 'Z');

    const lastDate = new Date(endDate ? endDate + 'Z' : data[data.length - 1]['TransactionDetails.timestamp'] + 'Z');

    // set first date to first monday if granularity is week
    if (granularity === 'week') {
        const weekday = firstDate.getDay();
        let daysOffset;
        switch (weekday) {
            case 0:
                daysOffset = 1;
                break;
            case 1:
                daysOffset = 0;
                break;
            case 2:
                daysOffset = 6;
                break;
            case 3:
                daysOffset = 5;
                break;
            case 4:
                daysOffset = 4;
                break;
            case 5:
                daysOffset = 3;
                break;
            case 6:
                daysOffset = 2;
                break;
            default:
                daysOffset = 0;
        }
        firstDate.setDate(firstDate.getDate() + daysOffset);
    }

    // get all dates including dates missing in data
    const datesRange = getDatesRange(firstDate, lastDate, granularity).map(date => date.toISOString().slice(0, -1));
    // get all data including data entry dates missing in data
    return datesRange.map(date => {
        const dataDateMatch = data.find(entry => entry['TransactionDetails.timestamp'] === date);
        if (dataDateMatch) {
            return dataDateMatch;
        } else {
            return { 'TransactionDetails.timestamp': date, [yKey]: 0 };
        }
    });
}

/**
 *
 * @param {Array} data
 * @param {string} yKey
 * @param {string} granularity
 * @param {string} startDate example format 2020-01-01T00:00:00.000
 * @param {string} endDate example format 2020-01-01T00:00:00.000
 */
function wrangle2DTimeData(data, yKey, granularity, startDate, endDate) {
    if (data.length === 0) return data;
    const firstDate = new Date(startDate ? startDate + 'Z' : data[0]['Transactions.timestamp'] + 'Z');

    const lastDate = new Date(endDate ? endDate + 'Z' : data[data.length - 1]['Transactions.timestamp'] + 'Z');

    // set first date to first monday if granularity is week
    if (granularity === 'week') {
        const weekday = firstDate.getDay();
        let daysOffset;
        switch (weekday) {
            case 0:
                daysOffset = 1;
                break;
            case 1:
                daysOffset = 0;
                break;
            case 2:
                daysOffset = 6;
                break;
            case 3:
                daysOffset = 5;
                break;
            case 4:
                daysOffset = 4;
                break;
            case 5:
                daysOffset = 3;
                break;
            case 6:
                daysOffset = 2;
                break;
            default:
                daysOffset = 0;
        }
        firstDate.setDate(firstDate.getDate() + daysOffset);
    }

    // get all dates including dates missing in data
    const datesRange = getDatesRange(firstDate, lastDate, granularity).map(date => date.toISOString().slice(0, -1));
    // get all data including data entry dates missing in data
    return datesRange.map(date => {
        const dataDateMatch = data.find(entry => entry['Transactions.timestamp'] === date);
        if (dataDateMatch) {
            return dataDateMatch;
        } else {
            return { 'Transactions.timestamp': date, [yKey]: 0 };
        }
    });
}

/**
 *
 * @param {Array} data
 * @param {string} yKey
 * @param {string} zKey
 * @param {string} granularity
 * @param {string} startDate example format 2020-01-01T00:00:00.000
 * @param {string} endDate example format 2020-01-01T00:00:00.000
 */
function wrangle3DTimeData(data, yKey, zKey, granularity, startDate, endDate, timeDimension) {
    if (data.length === 0) return data;
    // get date range
    const firstDate = new Date(startDate ? startDate + 'Z' : data[0][timeDimension] + 'Z');
    const lastDate = new Date(endDate ? endDate + 'Z' : data[data.length - 1][timeDimension] + 'Z');

    // set first date to first monday if granularity is week
    if (granularity === 'week') {
        const weekday = firstDate.getDay();
        let daysOffset;
        switch (weekday) {
            case 0:
                daysOffset = 1;
                break;
            case 1:
                daysOffset = 0;
                break;
            case 2:
                daysOffset = 6;
                break;
            case 3:
                daysOffset = 5;
                break;
            case 4:
                daysOffset = 4;
                break;
            case 5:
                daysOffset = 3;
                break;
            case 6:
                daysOffset = 2;
                break;
            default:
        }
        firstDate.setDate(firstDate.getDate() + daysOffset);
    }

    // get all dates including dates missing in data
    const datesRange = getDatesRange(firstDate, lastDate, granularity).map(date => date.toISOString().slice(0, -1));

    // get unique z values as object with 0 as default value for zs
    const uniqueZs = [...new Set(data.map(entry => entry[zKey]))];
    // eslint-disable-next-line
    const zs = uniqueZs.reduce((a, b) => ((a[b] = 0), a), {});

    // get all data including data entry dates missing in data
    const wrangledData = datesRange.map(date => {
        // get object with all matched data entries with Z value as key and Y value as value
        const dataDateMatchZs = data
            .filter(entry => entry[[timeDimension]] === date)
            // eslint-disable-next-line
            .reduce((a, b) => ((a[b[zKey]] = b[yKey]), a), {});

        // return formatted data entry with default 0 for missing values
        return Object.assign(
            {
                [timeDimension]: date,
            },
            zs,
            dataDateMatchZs
        );
    });

    return {
        wrangledData,
        uniqueZs,
    };
}

/**
 *
 * @param {Array} data
 * @param {string} yKey
 */
function wrangleConsumerResponseData(data, t) {
    if (data.length === 0) return data;
    let wrangledData = [
        {
            rating: '0% Happy',
        },
        {
            rating: '33% Happy',
        },
        {
            rating: '66% Happy',
        },
        {
            rating: '100% Happy',
        },
    ];
    const uniqueZs = [];
    // get all data including data entry dates missing in data
    data.forEach((dataGroup, i) => {
        const targetName = dataGroup[0].target ? dataGroup[0].target : t('allCabinets');
        Object.keys(dataGroup).forEach(d => {
            wrangledData = wrangledData.map(w => {
                if (!w[targetName] && w.rating === dataGroup[d].rating && w.rating === dataGroup[d].rating)
                    return {
                        ...w,
                        [targetName]: dataGroup[d].count,
                    };
                else return w;
            });
        });

        uniqueZs.push(targetName);
    });
    return {
        uniqueZs,
        wrangledData,
    };
}

/**
 * Format datetime for charts for given granularity
 * @param {string} granularity
 * @param {string} dateString
 */
export function formatDatetime(granularity, dateString) {
    if (granularity === 'hour') {
        //Convert hours to local time zone
        return getLocalTime(dateString).format('YYYY-MM-DD HH');
    } else if (granularity === 'week') {
        return `${moment(dateString).format('YYYY-MM-DD')} - ${moment(dateString)
            .add(6, 'days')
            .format('YYYY-MM-DD')}`;
    } else {
        return moment(dateString).format('YYYY-MM-DD');
    }
}

export function formatTimeAxisDatetime(granularity, dateString) {
    if (granularity === 'hour') {
        return getLocalTime(dateString).format('LT');
    } else if (granularity === 'week') {
        return `${moment(dateString).format('GGGG-[Week-]WW')}`;
    } else {
        return moment(dateString).format('YYYY-MM-DD');
    }
}

function getLocalTime(dateString) {
    // Note! Cube returns the times already converted to client timezone, so we need to use them as they are without conversion!
    const clientTimeZone = 'Etc/UTC'; ////Intl.DateTimeFormat().resolvedOptions().timeZone;
    const UTCTime = moment(dateString).format('YYYY-MM-DD HH');
    // convert to the browser time zone
    return momentTimeZone.utc(UTCTime).tz(clientTimeZone);
}

/**
 * Sanitize tooltip values in chart
 * @param {*} value
 * @param {string} name
 */
export function tooltipFormatter(value, name) {
    return [typeof value === 'number' ? value.toFixed(2).replace(/[.,]00$/, '') : value, cubeDataKeyToLabel(name)];
}

/**
 * Bar chart for time series data returned by Cube
 *
 * @param {array} data
 * @param {string} yKey
 * @param {string} zKey optional
 * @param {string} granularity hour, day, week or year
 * @param {string} startDate YYYY-MM-DD
 * @param {string} endDate YYYY-MM-DD
 */
export function CubeBarChart({ data, yKey, zKey, granularity, startDate, endDate, chartHeight = 600, type, timeDimensions }) {
    if (zKey) {
        const { wrangledData, uniqueZs } = wrangle3DTimeData(
            data,
            yKey,
            zKey,
            granularity,
            startDate,
            endDate,
            (timeDimensions = timeDimensions ? timeDimensions : 'Transactions.timestamp')
        );

        return (
            <ResponsiveContainer width="99%" height={chartHeight}>
                <BarChart data={wrangledData} margin={{ top: 5, right: 0, left: -10, bottom: 5 }}>
                    <XAxis
                        dataKey={timeDimensions ? timeDimensions : 'Transactions.timestamp'}
                        tickFormatter={formatTimeAxisDatetime.bind(this, granularity)}
                    />
                    <YAxis type="number" domain={[0, dataMax => dataMax.toFixed(2)]} />
                    <Tooltip labelFormatter={formatDatetime.bind(this, granularity)} formatter={tooltipFormatter} />
                    <CartesianGrid stroke="#f5f5f5" />
                    {uniqueZs.map((z, i) => (
                        <Bar key={z} type="monotone" dataKey={z} fill={chartColors[i % (chartColors.length - 1)]} yAxisId={0} stackId="x" />
                    ))}
                </BarChart>
            </ResponsiveContainer>
        );
    } else if (type === 'unpaidSales' || type === 'unpaidTransactions') {
        const wrangledData = wrangle2DTimeDataTransactionDetails(data, yKey, granularity, startDate, endDate);
        const CustomizedAxisTick = props => {
            const { x, y, payload } = props;

            return (
                <g transform={`translate(${x},${y})`}>
                    <text x={0} y={0} dy={16} textAnchor="end" fill="#666" transform="rotate(-45)">
                        {formatTimeAxisDatetime(granularity, payload.value)}
                    </text>
                </g>
            );
        };

        // numbre of tick in x-axis
        const tickDensity = () => {
            if (wrangledData.length <= 12) {
                return 0;
            } else {
                // allow max 12 data-ticks on x-axis
                return Math.round(wrangledData.length / 12);
            }
        };
        return (
            <ResponsiveContainer width="99%" height={400}>
                <BarChart data={wrangledData} margin={{ top: 5, right: 0, left: -10, bottom: 5 }}>
                    <XAxis
                        dataKey="TransactionDetails.timestamp"
                        tickFormatter={formatTimeAxisDatetime.bind(this, granularity)}
                        tick={<CustomizedAxisTick />}
                        height={100}
                        interval={tickDensity()}
                    />
                    <YAxis dataKey={yKey} />
                    <Tooltip labelFormatter={formatDatetime.bind(this, granularity)} formatter={tooltipFormatter} />
                    <CartesianGrid stroke="#f5f5f5" />
                    <Bar type="monotone" dataKey={yKey} fill="#118BD6" yAxisId={0} />
                </BarChart>
            </ResponsiveContainer>
        );
    } else {
        const wrangledData = wrangle2DTimeData(data, yKey, granularity, startDate, endDate, type);
        const CustomizedAxisTick = props => {
            const { x, y, payload } = props;

            return (
                <g transform={`translate(${x},${y})`}>
                    <text x={0} y={0} dy={16} textAnchor="end" fill="#666" transform="rotate(-45)">
                        {formatTimeAxisDatetime(granularity, payload.value)}
                    </text>
                </g>
            );
        };

        // numbre of tick in x-axis
        const tickDensity = () => {
            if (wrangledData.length <= 12) {
                return 0;
            } else {
                // allow max 12 data-ticks on x-axis
                return Math.round(wrangledData.length / 12);
            }
        };
        return (
            <ResponsiveContainer width="99%" height={400}>
                <BarChart data={wrangledData} margin={{ top: 5, right: 0, left: -10, bottom: 5 }}>
                    <XAxis
                        dataKey="Transactions.timestamp"
                        tickFormatter={formatTimeAxisDatetime.bind(this, granularity)}
                        tick={<CustomizedAxisTick />}
                        height={100}
                        interval={tickDensity()}
                    />
                    <YAxis dataKey={yKey} />
                    <Tooltip labelFormatter={formatDatetime.bind(this, granularity)} formatter={tooltipFormatter} />
                    <CartesianGrid stroke="#f5f5f5" />
                    <Bar type="monotone" dataKey={yKey} fill="#118BD6" yAxisId={0} />
                </BarChart>
            </ResponsiveContainer>
        );
    }
}

/**
 * Bar chart for data returned by Cube where x axis is categorical
 *
 * @param {array} data
 * @param {string} xKey
 * @param {string} yKey
 * @param {number} chartHeight
 * @param {function} labelFormatter
 * @param {boolean} hideXAxis
 */
export function CubeBarChartCategory({ data, xKey, yKey, chartHeight = 600, labelFormatter = label => label, hideXAxis = false }) {
    return (
        <ResponsiveContainer width="99%" height={chartHeight}>
            <BarChart data={data} margin={{ top: 5, right: 20, left: 10, bottom: 5 }}>
                <XAxis dataKey={xKey} hide={hideXAxis} />
                <YAxis dataKey={yKey} />
                <CartesianGrid stroke="#f5f5f5" />
                <Tooltip labelFormatter={labelFormatter} formatter={tooltipFormatter} />
                <Bar type="monotone" dataKey={yKey} fill="#118BD6" yAxisId={0} />
            </BarChart>
        </ResponsiveContainer>
    );
}

/**
 * Bar chart for data returned by Cube where x axis is categorical. Chart is displated sideways
 *
 * @param {Array} da/**
 * Bar chart for data returned by Cube where x axis is categorical. Chart is displated sideways
 *
 * @param {Array} data
 * @param {string} xKey
 * @param {string} yKey
 * @param {number} chartHeight
 */
export function CubeBarChartForConsumerResponses({ data, xKey, yKey, t }) {
    const CustomIcon = props => {
        const { y, payload } = props;
        switch (payload.value) {
            case '100% Happy':
                return <image y={y - 20} width="40" height="40" href={Smiley100}></image>;
            case '66% Happy':
                return <image y={y - 20} width="40" height="40" href={Smiley66}></image>;
            case '33% Happy':
                return <image y={y - 20} width="40" height="40" href={Smiley33}></image>;
            case '0% Happy':
                return <image y={y - 20} width="40" height="40" href={Smiley0}></image>;
            case '':
            default:
                return null;
        }
    };

    const calcXAxisMax = (data, uniqueZs) => {
        return Math.max(
            ...uniqueZs.map(z => {
                return Math.max(...data.map(o => o[z]));
            })
        );
    };

    const { wrangledData, uniqueZs } = wrangleConsumerResponseData(data, t);
    return (
        <ResponsiveContainer width="99%" height={370}>
            <BarChart data={wrangledData} margin={{ top: 5, right: 0, left: 0, bottom: 5 }} layout="vertical">
                <XAxis type="number" dataKey={xKey} domain={[0, wrangledData && wrangledData.length ? calcXAxisMax(wrangledData, uniqueZs) + 5 : 10]} />
                <YAxis type="category" dataKey={yKey} tick={<CustomIcon />} />
                <Tooltip labelFormatter={() => ''} />
                {uniqueZs.map((z, i) => (
                    <Bar type="monotone" key={z} dataKey={z} fill={chartColors[i % (chartColors.length - 1)]} yAxisId={0} stackId="x"></Bar>
                ))}
            </BarChart>
        </ResponsiveContainer>
    );
}

/*
 * @param {string} xKey
 * @param {string} yKey
 * @param {number} chartHeight
 */
export function CubeBarChartCategorySide({ data, xKey, yKey }) {
    const renderCustomizedLabel = props => {
        const { x, y, value } = props;
        return (
            <g>
                <text className="dashboard-bar-chart-label" fill="#00d3ff" x={x + 10} y={y + 18} textAnchor="right">
                    {value}
                </text>
            </g>
        );
    };

    return (
        <ResponsiveContainer width="99%" height={370}>
            <BarChart data={data} margin={{ top: 5, right: 0, left: 0, bottom: 5 }} layout="vertical">
                <XAxis type="number" dataKey={yKey} />
                <YAxis type="category" dataKey={xKey} hide />
                <CartesianGrid stroke="#f5f5f5" />
                <Tooltip formatter={tooltipFormatter} />
                <Bar type="monotone" dataKey={yKey} fill="#118BD6" yAxisId={0}>
                    <LabelList content={renderCustomizedLabel} dataKey={xKey} position="insideTopLeft" />
                </Bar>
            </BarChart>
        </ResponsiveContainer>
    );
}

/**
 * Displays chart for custom query response data
 *
 * @param {object} cubeResponse
 * @param {function} t
 */
export class CubeCustomQueryChart extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedMeasure: props.cubeResponse.query.measures.length > 0 ? props.cubeResponse.query.measures[0] : null,
        };
    }

    setMeasure = selectedMeasure => this.setState(() => ({ selectedMeasure }));

    render() {
        const { cubeResponse, t } = this.props;
        const { selectedMeasure } = this.state;
        const dimensions = [...cubeResponse.query.dimensions];
        const measures = [...cubeResponse.query.measures];

        // add timestamp to dimensions of data has time granularity
        const hasGranularity = cubeResponse.query.timeDimensions[0].granularity ? true : false;
        if (hasGranularity) {
            dimensions.push('Transactions.timestamp');
        }

        // return nothing if data has no measures
        if (measures.length === 0 || cubeResponse.data.length === 0 || dimensions.length === 0) return <div />;

        // merge dimensions into single key
        const data = cubeResponse.data.map(entry => {
            let key = '';
            dimensions.forEach(dimension => {
                key += `${cubeDataKeyToLabel(dimension)}: ${entry[dimension]}, `;
            });
            key = key.substr(0, key.length - 2);
            return { ...entry, key };
        });

        // formatter for chart tooltip label
        const labelFormatter = label => (
            <span>
                {label.split(', ').map(label => (
                    <span key={label}>
                        {label}
                        <br />
                    </span>
                ))}
            </span>
        );

        return (
            <div>
                <div className="d-inline-block mr-3 ml-3 mb-2" style={{ minWidth: '300px' }}>
                    <div className="mb-1 small">{t('measure')}</div>
                    <Dropdown
                        value={selectedMeasure}
                        onSelect={({ value }) => this.setMeasure(value)}
                        options={measures.map(measure => ({
                            value: measure,
                            label: cubeDataKeyToLabel(measure),
                        }))}
                    />
                </div>
                <CubeBarChartCategory data={data} xKey="key" yKey={selectedMeasure} labelFormatter={labelFormatter} hideXAxis={true} />
            </div>
        );
    }
}
