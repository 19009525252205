import React, { Fragment, useState } from 'react';
import { Icon } from '@storaensods/seeds-react';
import { Row } from 'reactstrap';
import { groupBy } from 'lodash';

import AnalyticsSearchBar from './../wasteDashboard/analyticsSearchBar';
import TabMenu from './tabMenu';
import DiscountDetails from './discountDetails';
import { chartColors } from './../cubeCharts';

const DiscountList = ({ discountProducts, t }) => {
    const [activeRow, setActiveRow] = useState(null);
    const [activeTabMenu, setActiveTabMenu] = useState('products');

    const allDiscountProductsList = [];

    const discountRules = Object.keys(discountProducts);
    discountRules.forEach(rule => {
        const discountRuleStops = Object.keys(discountProducts[rule]);
        discountRuleStops.forEach(stop => {
            let totalSoldCounts = 0,
                discountAmount = 0,
                currency,
                normalSalesAmount = 0,
                discountSalesAmount = 0,
                discountAmountType = null;
            const purchasedProducts = [];
            if (!discountAmount) {
                discountAmount = parseInt(stop);
            }
            const products = discountProducts[rule][stop];
            products.forEach(product => {
                discountAmountType = 'percentage';
                let originalUnitPrice = 0;
                if (product['Transactions.discountAmountType']) {
                    discountAmountType = product['Transactions.discountAmountType'];
                }

                if (!product['Transactions.originalPrice']) {
                    if (discountAmountType === 'percentage') {
                        originalUnitPrice = (100 * product['Transactions.oneUnitPrice']) / (100 - product['Transactions.discountAmount']);
                    }
                } else {
                    originalUnitPrice = product['Transactions.originalPrice'];
                }
                // handle divide by 0 case
                originalUnitPrice = isFinite(originalUnitPrice) ? originalUnitPrice : 0;

                purchasedProducts.push(product);
                currency = product['Transactions.currency'];
                totalSoldCounts = totalSoldCounts + product['Transactions.soldCount'];
                normalSalesAmount = parseFloat(normalSalesAmount) + originalUnitPrice * parseInt(product['Transactions.soldCount']);
                discountSalesAmount =
                    parseFloat(discountSalesAmount) + parseFloat(product['Transactions.oneUnitPrice']) * parseInt(product['Transactions.soldCount']);
            });

            const discountData = {
                discountName: rule,
                discountAmount,
                totalSoldCounts,
                normalSalesAmount,
                discountSalesAmount,
                currency,
                salesDifference: normalSalesAmount - discountSalesAmount,
                products: groupBy(purchasedProducts, 'Transactions.productName'),
                cabinets: groupBy(purchasedProducts, 'Device.deviceName'),
                locations: groupBy(purchasedProducts, 'Transactions.locationName'),
                discountAmountType,
            };

            allDiscountProductsList.push(discountData);
        });
    });

    const headers = [
        t('name'),
        t('discounts'),
        t('salesCount'),
        /* t('normalSalesAmount'), hide for now*/
        t('discountAmount'),
        t('discountSalesAmount'),
    ];

    return (
        <Row className="ml-4 mr-2">
            <table className="se-table discount-table">
                <thead className="se-table-head">
                    <tr>
                        <th style={{ width: '50px', paddingLeft: '15px' }}>#</th>
                        {headers.map(header => (
                            <th key={header}>
                                <div className="se-table-head-content discount-table-header">{header}</div>
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody className="discount-table-body">
                    {allDiscountProductsList.length === 0 && (
                        <tr className="discount-table-no-data">
                            <td colSpan={headers.length + 1}>{t('noData')}</td>
                        </tr>
                    )}
                    {Object.values(allDiscountProductsList).map((discount, index) => (
                        <Fragment key={discount.discountName + discount.discountAmount}>
                            <tr
                                className={`se-table-item se-table-item--clickable ${index === activeRow ? 'active-row' : ''}`}
                                onClick={() => {
                                    activeRow === index ? setActiveRow(null) : setActiveRow(index);
                                }}
                            >
                                <td style={{ width: '50px', paddingLeft: '15px' }}>{index + 1}</td>
                                <td>
                                    <span className="discount-table-rule"> {discount.discountName} </span>{' '}
                                    <Icon color="blue">{activeRow === index ? 'arrow_drop_up' : 'arrow_drop_down'}</Icon>
                                </td>
                                <td>
                                    {discount.discountAmountType === 'percentage'
                                        ? discount.discountAmount + '%'
                                        : discount.discountAmountType === 'fixed'
                                        ? '- ' + discount.discountAmount.toFixed(2) + discount.currency
                                        : t('freeDiscount')}{' '}
                                </td>
                                <td>{discount.totalSoldCounts}</td>
                                {/* hide for now
                                <td>
                                    {discount.normalSalesAmount.toFixed(2)}
                                    {discount.currency}
                                </td>
                                */}
                                <td>
                                    {discount.currency}
                                    {(discount.normalSalesAmount - discount.discountSalesAmount).toFixed(2)}
                                </td>
                                <td>
                                    <span
                                        className="discount-table-balance"
                                        style={{
                                            background: `${chartColors[index % chartColors.length]}`,
                                        }}
                                    >
                                        {discount.currency}
                                        {discount.discountSalesAmount.toFixed(2)}
                                    </span>
                                </td>
                            </tr>
                            <tr className={index === activeRow ? 'active-row-detail--visible' : 'active-row-detail--hidden'}>
                                <td colSpan="6" style={{ background: '#fff' }}>
                                    <TabMenu activeMenu={menu => setActiveTabMenu(menu)} active={activeTabMenu} t={t} />
                                    <Row className="ml-2">
                                        <AnalyticsSearchBar />
                                    </Row>
                                    <DiscountDetails detailType={activeTabMenu} discountProducts={discount} activeRow={activeRow} t={t} />
                                </td>
                            </tr>
                        </Fragment>
                    ))}
                </tbody>
            </table>
        </Row>
    );
};

export default DiscountList;
