import { 
    THEMING_IMAGE_UPLOAD,
    THEMING_IMAGE_RECEIVE,
    THEMING_IMAGE_ERROR,
    POST_PURCHASE_THEME_IMAGE_UPLOAD,
    POST_PURCHASE_THEME_IMAGE_RECEIVE,
    POST_PURCHASE_THEME_IMAGE_ERROR

} from '../actions/theming';

const initalState = {
    uploading: false,
    imgUrl: null,
    error: null,
    postPurchaseThemeImage:{
        uploading:false,
        imgUrl:null,
        error:null
    }
};

const theming = (state = initalState, action) => {
    switch (action.type) {
        case THEMING_IMAGE_UPLOAD:
            return {
                ...state,
                uploading: true,
                error: null,
                imgUrl: null,
            };
        case THEMING_IMAGE_RECEIVE:
            return {
                ...state,
                uploading: false,
                error: false,
                imgUrl: action.imgUrl,
            };
        case THEMING_IMAGE_ERROR:
            return {
                ...state,
                uploading: false,
                error: action.error,
                imgUrl: null,
            };
        case POST_PURCHASE_THEME_IMAGE_UPLOAD:
            return {
                ...state,
                postPurchaseThemeImage:{
                    uploading: true,
                    error: null,
                    imgUrl: null,
                }
               
            };
        case POST_PURCHASE_THEME_IMAGE_RECEIVE:
            return {
                ...state,
                postPurchaseThemeImage:{
                    uploading: false,
                    error: null,
                    imgUrl: action.imgUrl,
                }
            };
        case POST_PURCHASE_THEME_IMAGE_ERROR:
            return {
                ...state,
                postPurchaseThemeImage:{
                    uploading: false,
                    error: action.error,
                    imgUrl: null,
                }

            };
        default:
            return state;
    }
};

export default theming;
