import axios from 'axios';
import { store } from './index.js';
import { requestUpdateAdImage } from './actions/advertisements';
import { redirectToAuthorizationPage } from './actions/user.js';
import { head, at, orderBy, uniq } from 'lodash';
import io from 'socket.io-client';

export function emitEventToSocketIO(topic) {
    return new Promise((resolve, reject) => {
        const client = io(process.env.REACT_APP_SOCKETIO_SERVER || 'https://socketio.selflystore.com');
        client.on('connect', () => {
            client.emit(topic, '1');
            client.close();
            resolve();
        });
        client.on('error', () => {
            console.error('connected socket error io');
            reject();
        });
    });
}

/**
 * Fetches the user profile.
 * /me
 */
export function fetchProfile(accessToken) {
    return fetch(`${process.env.REACT_APP_IPA_API_URL}/v4/me`, {
        headers: {
            Authorization: `Bearer ${accessToken}`,
        },
    }).then(response => {
        if (response.ok) {
            return Promise.resolve(response.json());
        } else {
            // handle session expired
            if (response.status === 401) {
                store.dispatch(redirectToAuthorizationPage('signInExpiredError'));
            }

            const error = new Error(response.statusText);
            error.response = response;
            return Promise.reject(error);
        }
    });
}

/**
 * Fetches the product catalog.
 *
 * @returns {Array} products
 * @throws {Error} httpError
 */
export function fetchProducts(accessToken, group) {
    const url = group ? `${process.env.REACT_APP_API_URL}/v1/products?group=${encodeURIComponent(group.Id)}` : `${process.env.REACT_APP_API_URL}/v1/products`;
    return fetch(url, {
        headers: {
            Authorization: `Bearer ${accessToken}`,
        },
    }).then(response => {
        if (response.ok) {
            return Promise.resolve(response.json());
        } else {
            // handle session expired
            if (response.status === 401) {
                store.dispatch(redirectToAuthorizationPage('signInExpiredError'));
            }

            const error = new Error(response.statusText);
            error.response = response;
            return Promise.reject(error);
        }
    });
}

/**
 *
 * @param {*} productBarcode : The barcode of the product needs to be updated
 *
 * @returns {Array} products
 * @throws {Error} httpError
 */
export function fetchSingleProduct(accessToken, group, productBarcode) {
    const url = group
        ? `${process.env.REACT_APP_API_URL}/v1/products/${productBarcode}?group=${encodeURIComponent(group.Id)}`
        : `${process.env.REACT_APP_API_URL}/v1/products/${productBarcode}`;
    return fetch(url, {
        headers: {
            Authorization: `Bearer ${accessToken}`,
        },
    }).then(response => {
        if (response.ok) {
            return Promise.resolve(response.json());
        } else {
            // handle session expired
            if (response.status === 401) {
                store.dispatch(redirectToAuthorizationPage('signInExpiredError'));
            }

            const error = new Error(response.statusText);
            error.response = response;
            return Promise.reject(error);
        }
    });
}

/**
 * Fetches the product types.
 *
 * @returns {Array} productTypes
 * @throws {Error} httpError
 */
export function fetchProductTypes(accessToken) {
    return fetch(`${process.env.REACT_APP_API_URL}/v1/encoding-instruction`, {
        headers: {
            Authorization: `Bearer ${accessToken}`,
        },
    }).then(response => {
        if (response.ok) {
            return Promise.resolve(response.json());
        } else {
            // handle session expired
            if (response.status === 401) {
                store.dispatch(redirectToAuthorizationPage('signInExpiredError'));
            }

            const error = new Error(response.statusText);
            error.response = response;
            return Promise.reject(error);
        }
    });
}

/**
 * Fetches the advertisements.
 *
 * @returns {Array} advertisements
 * @throws {Error} httpError
 */
export function fetchAdvertisements(accessToken, group) {
    const url = group
        ? `${process.env.REACT_APP_API_URL}/v1/advertisements?group=${encodeURIComponent(group.Id)}`
        : `${process.env.REACT_APP_API_URL}/v1/advertisements`;
    return fetch(url, {
        headers: {
            Authorization: `Bearer ${accessToken}`,
        },
    }).then(response => {
        if (response.ok) {
            return Promise.resolve(response.json());
        } else {
            // handle session expired
            if (response.status === 401) {
                store.dispatch(redirectToAuthorizationPage('signInExpiredError'));
            }

            const error = new Error(response.statusText);
            error.response = response;
            return Promise.reject(error);
        }
    });
}

/**
 * Sends HTTP request to create new product.
 *
 * @returns {Product} product
 * @throws {Error} httpError
 */
export function createProduct(product, accessToken, group) {
    const url = group ? `${process.env.REACT_APP_API_URL}/v1/products?group=${encodeURIComponent(group.Id)}` : `${process.env.REACT_APP_API_URL}/v1/products`;
    return fetch(url, {
        method: 'POST',
        body: JSON.stringify(product),
        headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
        },
    }).then(response => {
        if (response.ok) {
            return Promise.resolve(response.json());
        } else {
            // handle session expired
            if (response.status === 401) {
                store.dispatch(redirectToAuthorizationPage('signInExpiredError'));
            }

            if (response.headers.get('Content-Type') && response.headers.get('Content-Type').match(/application\/json/g)) {
                return response.json().then(body => {
                    if (body.message) {
                        return Promise.reject(new Error(body.message));
                    } else {
                        return Promise.reject(new Error(response.statusText));
                    }
                });
            }

            return Promise.reject(new Error(response.statusText));
        }
    });
}

/**
 * Sends HTTP request to update existing product.
 *
 * @param {string} productBarcode
 * @param {Product} product Product should not include barcode or product id (check api spec for what is allowed)
 * @param {string} accessToken
 * @returns {Product} product
 * @throws {Error} httpError
 */
export function updateProduct(productBarcode, product, accessToken, group) {
    const url = group
        ? `${process.env.REACT_APP_API_URL}/v1/products/${productBarcode}?group=${encodeURIComponent(group.Id)}`
        : `${process.env.REACT_APP_API_URL}/v1/products/${productBarcode}`;
    return fetch(url, {
        method: 'PUT',
        body: JSON.stringify(product),
        headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
        },
    }).then(response => {
        if (response.ok) {
            return Promise.resolve(response.json());
        } else {
            // handle session expired
            if (response.status === 401) {
                store.dispatch(redirectToAuthorizationPage('signInExpiredError'));
            }

            if (response.headers.get('Content-Type') && response.headers.get('Content-Type').match(/application\/json/g)) {
                return response.json().then(body => {
                    if (body.message) {
                        return Promise.reject(new Error(body.message));
                    } else {
                        return Promise.reject(new Error(response.statusText));
                    }
                });
            }

            return Promise.reject(new Error(response.statusText));
        }
    });
}

export function archiveProduct(productBarcode, product, accessToken, group) {
    const url = group
        ? `${process.env.REACT_APP_API_URL}/v1/products/${productBarcode}?group=${encodeURIComponent(group.Id)}`
        : `${process.env.REACT_APP_API_URL}/v1/products/${productBarcode}`;

    return fetch(url, {
        method: 'PATCH',
        headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ status: product.status }),
    }).then(response => {
        if (response.ok) {
            return Promise.resolve(response.json());
        } else {
            // handle session expired
            if (response.status === 401) {
                store.dispatch(redirectToAuthorizationPage('signInExpiredError'));
            }

            if (response.headers.get('Content-Type') && response.headers.get('Content-Type').match(/application\/json/g)) {
                return response.json().then(body => {
                    if (body.message) {
                        return Promise.reject(new Error(body.message));
                    } else {
                        return Promise.reject(new Error(response.statusText));
                    }
                });
            }

            return Promise.reject(new Error(response.statusText));
        }
    });
}

/**
 * Sends HTTP request to store product image and returns url to image
 *
 * @returns {Promise} formData Form data with 1 image
 * @throws {Error} httpError
 */
export function createProductImage(formData, accessToken, group) {
    return fetch(`${process.env.REACT_APP_API_URL}/v1/product-image?group=${encodeURIComponent(group)}`, {
        method: 'POST',
        body: formData,
        headers: {
            Authorization: `Bearer ${accessToken}`,
        },
    }).then(response => {
        if (response.ok) {
            return Promise.resolve(response.json());
        } else {
            // handle session expired
            if (response.status === 401) {
                store.dispatch(redirectToAuthorizationPage('signInExpiredError'));
            }

            if (response.headers.get('Content-Type') && response.headers.get('Content-Type').match(/application\/json/g)) {
                return response.json().then(body => {
                    if (body.message) {
                        return Promise.reject(new Error(body.message));
                    } else {
                        return Promise.reject(new Error(response.statusText));
                    }
                });
            }

            return Promise.reject(new Error(response.statusText));
        }
    });
}

/**
 * Sends HTTP request to store logo image and returns url to image
 *
 * @returns {Promise} formData Form data with 1 image
 * @throws {Error} httpError
 */
export function createLogoImage(formData, accessToken, group) {
    return fetch(`${process.env.REACT_APP_API_URL}/v1/logo-image?group=${encodeURIComponent(group.Id)}`, {
        method: 'POST',
        body: formData,
        headers: {
            Authorization: `Bearer ${accessToken}`,
        },
    }).then(response => {
        if (response.ok) {
            return Promise.resolve(response.json());
        } else {
            // handle session expired
            if (response.status === 401) {
                store.dispatch(redirectToAuthorizationPage('signInExpiredError'));
            }

            if (response.headers.get('Content-Type') && response.headers.get('Content-Type').match(/application\/json/g)) {
                return response.json().then(body => {
                    if (body.message) {
                        return Promise.reject(new Error(body.message));
                    } else {
                        return Promise.reject(new Error(response.statusText));
                    }
                });
            }

            return Promise.reject(new Error(response.statusText));
        }
    });
}

/**
 * Sends HTTP request to store product image and returns url to image
 *
 * @returns {FormData} formData Form data with 1 image
 * @throws {Error} httpError
 */
export function createAdImage(formData, accessToken, group) {
    return fetch(`${process.env.REACT_APP_API_URL}/v1/advertisement-image?group=${encodeURIComponent(group)}`, {
        method: 'POST',
        body: formData,
        headers: {
            Authorization: `Bearer ${accessToken}`,
        },
    }).then(response => {
        if (response.ok) {
            return Promise.resolve(response.json());
        } else {
            // handle session expired
            if (response.status === 401) {
                store.dispatch(redirectToAuthorizationPage('signInExpiredError'));
            }
            if (response.headers.get('Content-Type') && response.headers.get('Content-Type').match(/application\/json/g)) {
                return response.json().then(body => {
                    if (body.message) {
                        return Promise.reject(new Error(body.message));
                    } else {
                        return Promise.reject(new Error(response.statusText));
                    }
                });
            }
            return Promise.reject(new Error(response.statusText));
        }
    });
}

/**
 * upload the file to the server
 */
export async function createAdMedia(blobChunck, accessToken, group) {
    const fetchData = await axios.post(`${process.env.REACT_APP_API_URL}/v1/advertisement-media?group=${encodeURIComponent(group.Id)}`, blobChunck, {
        headers: {
            Authorization: `Bearer ${accessToken}`,
        },
        onUploadProgress: function(progressEvent) {
            const { loaded, total } = progressEvent;
            const percentage = Math.ceil((loaded / total) * 100);
            store.dispatch(requestUpdateAdImage(percentage));
        },
    });
    if (fetchData.status === 201) {
        return fetchData.data;
    } else {
        return new Error(fetchData.statusText);
    }
}

/**
 * Sends HTTP request to update existing ad
 *
 * @param {string} adId
 * @param {Product} ad Ad should not include id (check api spec for what is allowed)
 * @param {string} accessToken
 * @returns {Ad} ad
 * @throws {Error} httpError
 */
export function updateAd(adId, ad, accessToken, group) {
    //${process.env.REACT_APP_API_URL}

    const url = group
        ? `${process.env.REACT_APP_API_URL}/v1/advertisements/${adId}?group=${encodeURIComponent(group.Id)}`
        : `${process.env.REACT_APP_API_URL}/v1/advertisements/${adId}`;
    return fetch(url, {
        method: 'PUT',
        body: JSON.stringify(ad),
        headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
        },
    }).then(response => {
        if (response.ok) {
            return Promise.resolve(response.json());
        } else {
            // handle session expired
            if (response.status === 401) {
                store.dispatch(redirectToAuthorizationPage('signInExpiredError'));
            }

            if (response.headers.get('Content-Type') && response.headers.get('Content-Type').match(/application\/json/g)) {
                return response.json().then(body => {
                    if (body.message) {
                        return Promise.reject(new Error(body.message));
                    } else {
                        return Promise.reject(new Error(response.statusText));
                    }
                });
            }

            return Promise.reject(new Error(response.statusText));
        }
    });
}

/**
 * Sends HTTP request to deleting ad
 *
 * @param {string} adId
 * @param {string} accessToken
 * @param {string} group
 * @throws {Error} httpError
 */
export function deleteAd(adId, accessToken, group) {
    //${process.env.REACT_APP_API_URL}
    const url = group
        ? `${process.env.REACT_APP_API_URL}/v1/advertisements/${adId}?group=${encodeURIComponent(group.Id)}`
        : `${process.env.REACT_APP_API_URL}/v1/advertisements/${adId}`;
    return fetch(url, {
        method: 'DELETE',
        headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
        },
    }).then(response => {
        if (response.ok) {
            return Promise.resolve(response.json());
        } else {
            // handle session expired
            if (response.status === 401) {
                store.dispatch(redirectToAuthorizationPage('signInExpiredError'));
            }

            if (response.headers.get('Content-Type') && response.headers.get('Content-Type').match(/application\/json/g)) {
                return response.json().then(body => {
                    if (body.message) {
                        return Promise.reject(new Error(body.message));
                    } else {
                        return Promise.reject(new Error(response.statusText));
                    }
                });
            }

            return Promise.reject(new Error(response.statusText));
        }
    });
}

/**
 * Fetches the cabinet inventory.
 *
 * @returns {Array} cabinetInventory
 * @throws {Error} httpError
 */
export function fetchCabinetInventory(cabinetCode, params, accessToken) {
    const url = new URL(`${process.env.REACT_APP_API_URL}/v1/inventory/${cabinetCode}`);
    if (params && Object.keys(params).length) {
        url.search = new URLSearchParams(params);
    }

    return fetch(url, {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
        },
    }).then(response => {
        if (response.status === 204) {
            return Promise.resolve({
                readTime: null,
                tags: [],
            });
        }

        if (response.ok) {
            return Promise.resolve(response.json());
        } else {
            // handle session expired
            if (response.status === 401) {
                store.dispatch(redirectToAuthorizationPage('signInExpiredError'));
            }

            const error = new Error(response.statusText);
            error.response = response;
            return Promise.reject(error);
        }
    });
}

/**
 * Fetches the cabinet current status.
 *
 * @returns {Array} cabinetInventory
 * @throws {Error} httpError
 */
export function fetchDeviceStatus(deviceCode, accessToken) {
    // fetch current status of the selected cabinet
    const graphqlQuery = `{device(code: "${deviceCode}") {id name code type subType lastTimeSeenOnline isOnline currentState{values(namesCsv: "lock,door")}  }}`; // graphQL request to IPA API
    return fetch(`${process.env.REACT_APP_IPA_API_URL}/v4/graphql`, {
        method: 'POST',
        body: JSON.stringify({
            query: graphqlQuery,
        }),
        headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
        },
    }).then(response => {
        if (response.ok) {
            return response.json().then(body => {
                // missing data due to GraphQL error
                if (!body.data) {
                    const error = new Error('Unable to fetch device due to GraphQL error');
                    error.graphQLErrors = body.errors;
                    return Promise.reject(error);
                }

                // location doesn't exist
                if (body.data.device === null) {
                    return Promise.reject(new Error(`No device found with device code: ${deviceCode}`));
                }
                const cabinet = body.data.device;
                if (cabinet.currentState && cabinet.currentState.values) {
                    cabinet.isLocked = cabinet.currentState.values[0] && cabinet.currentState.values[0].isLocked === 'true';
                    cabinet.isOpened = cabinet.currentState.values[1] && cabinet.currentState.values[1].isOpened === 'true';
                }
                cabinet.deviceCode = cabinet.code;

                return Promise.resolve(cabinet);
            });
        } else {
            // handle session expired
            if (response.status === 401) {
                store.dispatch(redirectToAuthorizationPage('signInExpiredError'));
            }

            const error = new Error(response.statusText);
            error.response = response;
            return Promise.reject(error);
        }
    });
}

/**
 * Fetches the cabinet configuration.
 *
 * @returns {Array} cabinetInventory
 * @throws {Error} httpError
 */
export function fetchDeviceConfiguration(deviceCode, accessToken) {
    // fetch current status of the selected cabinet
    const graphqlQuery = `{device(code: "${deviceCode}") { alternativeIds } }`;

    // graphQL request to IPA API
    return fetch(`${process.env.REACT_APP_IPA_API_URL}/v4/graphql`, {
        method: 'POST',
        body: JSON.stringify({
            query: graphqlQuery,
        }),
        headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
        },
    }).then(response => {
        if (response.ok) {
            return response.json().then(body => {
                // missing data due to GraphQL error
                if (!body.data) {
                    const error = new Error('Unable to fetch device due to GraphQL error');
                    error.graphQLErrors = body.errors;
                    return Promise.reject(error);
                }

                // location doesn't exist
                if (body.data.device === null) {
                    return Promise.reject(new Error(`No device found with device code: ${deviceCode}`));
                }
                const cabinet = body.data.device;
                return Promise.resolve(cabinet.alternativeIds);
            });
        } else {
            // handle session expired
            if (response.status === 401) {
                store.dispatch(redirectToAuthorizationPage('signInExpiredError'));
            }

            const error = new Error(response.statusText);
            error.response = response;
            return Promise.reject(error);
        }
    });
}

/**
 * Updates the cabinet settings to detect tag quality and ignore bad tags.
 * @param {*} cabinetId Id of the cabinet
 * @param {*} accessToken Bearer user's access token
 * @param {*} detectTagQuality True/False value of detecting bad tags
 * @param {*} ignoreBadTags True/False value of ignoring bad tags
 */
export function updateTagQualityMetrics(
    cabinetId,
    accessToken,
    detectTagQuality = false,
    ignoreBadTags = false,
    acceptablePercentageOfBadTags,
    chargeSoldTags,
    chargeUnreplenishedTags,
    cabinetTagSensitivityLevel
) {
    return fetch(`${process.env.REACT_APP_IPA_API_URL}/v3/devices/${cabinetId}/alternative_id`, {
        method: 'POST',
        body: JSON.stringify({
            detectTagQuality,
            ignoreBadTags,
            acceptablePercentageOfBadTags,
            chargeSoldTags,
            chargeUnreplenishedTags,
            cabinetTagSensitivityLevel,
        }),
        headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
        },
    }).then(response => {
        if (response.ok) {
            return response.json();
        } else {
            // handle session expired
            if (response.status === 401) {
                store.dispatch(redirectToAuthorizationPage('signInExpiredError'));
            }

            const error = new Error(response.statusText);
            error.response = response;
            return Promise.reject(error);
        }
    });
}

/**
 * Update cabinet settings
 * @param {*} id
 * @param {*} accessToken
 * @param {*} settings
 */
export function updateCabinetSettings(id, accessToken, settings = {}) {
    return fetch(`${process.env.REACT_APP_IPA_API_URL}/v4/Devices/${id}/settings`, {
        method: 'POST',
        body: JSON.stringify(settings),
        headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
        },
    }).then(response => {
        if (response.ok) {
            return response.json();
        } else {
            // handle session expired
            if (response.status === 401) {
                store.dispatch(redirectToAuthorizationPage('signInExpiredError'));
            }
            const error = new Error(response.statusText);
            error.response = response;
            return Promise.reject(error);
        }
    });
}

/**
 * Fetches cabinets.
 *
 * @returns {Array} cabinets
 * @throws {Error} httpError
 */
export function fetchCabinets(accessToken, group) {
    const url = group ? `${process.env.REACT_APP_API_URL}/v1/cabinets?group=${encodeURIComponent(group.Id)}` : `${process.env.REACT_APP_API_URL}/v1/cabinets`;

    // graphQL request to IPA API
    return fetch(url, {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${accessToken}`,
        },
    }).then(response => {
        if (response.ok) {
            return Promise.resolve(response.json());
        } else {
            // handle session expired
            if (response.status === 401) {
                store.dispatch(redirectToAuthorizationPage('signInExpiredError'));
            }

            return Promise.reject(new Error(response.statusText));
        }
    });
}

/**
 * Fetches temperature by device id.
 *
 * @param {String} accessToken
 * @param {String} deviceCode
 * @param {String} from  ISO String
 * @param {String} to  ISO String
 * @returns {Array} cabinets
 * @throws {Error} httpError
 */
export function fetchTemperature(accessToken, deviceCode, from, to) {
    // fetch latest 1000 temperature values
    const graphqlQuery = `{
        device(code: "${deviceCode}") {
          id,
          code,
          name,
          lastTimeSeenOnline,
          stateHistory(from: "${from}", to: "${to}", count: 1000) {
            timestamp,
            values(namesCsv: "temperature")
          },
          currentState{values(namesCsv: "temperature")}
        }
    }`;

    // graphQL request to IPA API
    return fetch(`${process.env.REACT_APP_IPA_API_URL}/v4/graphql`, {
        method: 'POST',
        body: JSON.stringify({
            query: graphqlQuery,
        }),
        headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
        },
    }).then(response => {
        if (response.ok) {
            return response
                .json()
                .then(body => {
                    // missing data due to GraphQL error
                    if (!body.data) {
                        const error = new Error('Unable to fetch devices due to GraphQL error');
                        error.graphQLErrors = body.errors;
                        return Promise.reject(error);
                    }

                    let stateHistory = (head(at(body, 'data.device.stateHistory')) || []).filter(
                        t => t && t.values && t.timestamp && t.values[0] && t.values[0].temperature
                    );

                    // append timestamp to build the report
                    // NOTE! as temperature report changed in cabinet v3, the below
                    // code is added for backwards compatibility
                    // later on can be deleted or refactored
                    stateHistory = stateHistory.map(t => ({
                        ...t,
                        values: t.values.map(v => ({
                            ...v,
                            // don't use here timestamp from the temperature sensor, use timestamp from the measurement report
                            timestamp: t.timestamp,
                        })),
                    }));
                    if (stateHistory.length === 0) {
                        return Promise.resolve([]);
                    }

                    let temperature = stateHistory.map(s => s.values && s.values[0]);

                    // temperature = union(temperature, currentStateValue[0]) || []
                    temperature = orderBy(uniq(temperature), ['timestamp']);

                    return Promise.resolve(temperature);
                })
                .catch(e => {
                    // eslint-disable-next-line no-console
                    console.error(e);
                    return Promise.reject(e);
                });
        } else {
            // handle session expired
            if (response.status === 401) {
                store.dispatch(redirectToAuthorizationPage('signInExpiredError'));
            }

            const error = new Error(response.statusText);
            error.response = response;
            return Promise.reject(error);
        }
    });
}

/**
 * Sends command to cabinet
 *
 * @returns {Product} product
 * @throws {Error} httpError
 */
export function sendCommand(deviceCode, command, parameters, accessToken) {
    return fetch(`${process.env.REACT_APP_API_URL}/v1/command`, {
        method: 'POST',
        body: JSON.stringify({ deviceCode: deviceCode, command: command, params: parameters }),
        headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
        },
    }).then(response => {
        if (response.ok) {
            return Promise.resolve(response.json());
        } else {
            // handle session expired
            if (response.status === 401) {
                store.dispatch(redirectToAuthorizationPage('signInExpiredError'));
                return Promise.reject(401);
            }

            if (response.status === 403 || response.status === 500) {
                return Promise.reject(response.status);
            }

            return Promise.reject(new Error(response.statusText));
        }
    });
}

/**
 * Fetches tags that have been removed from cabinet.
 *
 * @param {string} accessToken
 * @param {string} deviceCode
 * @param {string} from (YYYY-MM-DD) time range start time
 * @param {string} to (YYYY-MM-DD) time range end time
 * @param {string} status (optional) {DONE, CANCEL, ERROR, TIMEOUT}
 *
 * @returns {array} transactions
 * @throws {Error} httpError
 */
export async function fetchTransactions(accessToken, deviceCode, from, to, status) {
    const url = new URL(`${process.env.REACT_APP_API_URL}/v1/transactions/${deviceCode}`);
    const params = { from, to, status };
    // Clean undefined keys
    Object.keys(params).forEach(key => params[key] === undefined && delete params[key]);
    url.search = new URLSearchParams(params);

    const response = await fetch(url, {
        headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
        },
    });

    if (!response.ok) {
        if (response.status === 401) {
            store.dispatch(redirectToAuthorizationPage('signInExpiredError'));
        }

        const error = new Error(response.statusText);
        error.response = response;
        return Promise.reject(error);
    }

    return response.json();
}

/**
 * Makes the refund for the order.
 * @param {*} accessToken Bearer token for api requests
 * @param {*} deviceCode Cabinet device code
 * @param {*} orderId Order ID
 * @param {*} refundItems Items to be refund
 * @param {*} reason Reason of the refund
 */
export async function refundOrder(accessToken, deviceCode, orderId, refundItems, reason) {
    const url = new URL(`${process.env.REACT_APP_API_URL}/v1/refund/${deviceCode}`);
    // eslint-disable-next-line no-console
    const response = await fetch(url, {
        method: 'PUT',
        body: JSON.stringify({ orderId, refundItems, reason }),
        headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
        },
    });

    if (!response.ok) {
        if (response.status === 401) {
            store.dispatch(redirectToAuthorizationPage('signInExpiredError'));
        }
        const errorData = await response.json();
        const error = new Error(errorData);
        error.response = response;
        return Promise.reject(errorData);
    }

    return response.json();
}

/**
 * Fetches the cabinet refills.
 *
 * @returns {Array} cabinetInventory
 * @throws {Error} httpError
 */
export function fetchCabinetRefills(deviceCode, params, accessToken) {
    const url = new URL(`${process.env.REACT_APP_API_URL}/v2/refill/${deviceCode}`);
    if (params && Object.keys(params).length) {
        url.search = new URLSearchParams(params);
    }

    return fetch(url, {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
        },
    }).then(response => {
        if (response.ok) {
            return Promise.resolve(response.json());
        } else {
            // handle session expired
            if (response.status === 401) {
                store.dispatch(redirectToAuthorizationPage('signInExpiredError'));
            }

            const error = new Error(response.statusText);
            error.response = response;
            return Promise.reject(error);
        }
    });
}

/**
 * Sends HTTP request to set cabinet location.
 *
 * @param {string} deviceCode
 * @param {string} locationId
 * @param {string} accessToken Bearer token for api requests
 * @throws {Error} httpError
 */
export function setCabinetLocation(deviceCode, locationId, accessToken, group) {
    const url = group
        ? `${process.env.REACT_APP_API_URL}/v1/cabinets/${deviceCode}?group=${encodeURIComponent(group.Id)}`
        : `${process.env.REACT_APP_API_URL}/v1/cabinets/${deviceCode}`;
    return fetch(url, {
        method: 'PUT',
        body: JSON.stringify({ locationId }),
        headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
        },
    }).then(response => {
        if (response.ok) {
            // Notify root api to notify cabinet backend to update cabinet location
            sendCommand(deviceCode, 'notifyCabinetToRefetchLocation', { value: true }, accessToken).catch(err => console.error(err));
            return Promise.resolve(response.json());
        } else {
            // handle session expired
            if (response.status === 401) {
                store.dispatch(redirectToAuthorizationPage('signInExpiredError'));
            }

            const error = new Error(response.statusText);
            error.response = response;
            return Promise.reject(error);
        }
    });
}

/**
 * Runs cubejs query end fetches result
 *
 * @param {object} queryObject CubeJS query object
 * @param {string} group Organization id & name
 * @param {string} accessToken Bearer token for api requests
 * @returns {object} cubeQueryResult
 * @throws {Error} httpError
 */
export function runCubeQuery(queryObject, group, accessToken, tries = 0, terminateOnTriesEnd = true, abortSignal = null) {
    if (terminateOnTriesEnd && tries === 5) {
        return Promise.reject('Data loading took too long!');
    }

    let clientTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    if (queryObject && queryObject.dimensions && queryObject.dimensions.length && queryObject.dimensions[0].split('.')[0] === 'InventoryHistory') {
        // While asking for inventory history, no timezone is sent as the date needs to stay as it is for each day!
        clientTimeZone = undefined;
    }
    const query = {
        ...queryObject,
        timezone: clientTimeZone,
    };
    const url = new URL(`${process.env.REACT_APP_API_URL}/v1/cube?group=${group.Id}&query=${encodeURIComponent(JSON.stringify(query))}`);
    return fetch(url, {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
        },
        signal: abortSignal ? abortSignal : undefined,
    }).then(response => {
        if (response.ok) {
            return response.json().then(json => {
                if (json.error && json.error === 'Continue wait') {
                    //Instead of re-trying the cube query immediately, giving an interval to avoid overloading to server.
                    //It anyway won't help to re-try immediately.
                    return new Promise((resolve, reject) => {
                        setTimeout(() => {
                            runCubeQuery(query, group, accessToken, tries + 1, terminateOnTriesEnd, abortSignal)
                                .then(res => resolve(res))
                                .catch(res => reject(res));
                        }, 8000); // 8 seconds interval
                    });
                }
                return Promise.resolve(json);
            });
        } else {
            // handle session expired
            if (response.status === 401) {
                store.dispatch(redirectToAuthorizationPage('signInExpiredError'));
            }

            const error = new Error(response.statusText);
            error.response = response;
            return Promise.reject(error);
        }
    });
}

/**
 * Get locations
 * @param {*} accessToken Bearer token for api requests
 * @param {*} organization
 */
export function fetchLocations(accessToken, organization) {
    // fetch locations
    const graphqlQuery = `{
        locations(organization: "${organization}", includeChildren: FLAT) {
          id,
          code,
          name,
          status,
          parentLocationCode
        }
    }`;

    // graphQL request to IPA API
    return fetch(`${process.env.REACT_APP_IPA_API_URL}/v4/graphql`, {
        method: 'POST',
        body: JSON.stringify({
            query: graphqlQuery,
        }),
        headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
        },
    }).then(response => {
        if (response.ok) {
            return response.json().then(responseJson =>
                responseJson.data.locations
                    .filter(location => location.status !== 'Retired')
                    .map(location => ({
                        id: location.id,
                        code: location.code,
                        name: location.name,
                        parentLocationCode: location.parentLocationCode,
                    }))
            );
        } else {
            // handle session expired
            if (response.status === 401) {
                store.dispatch(redirectToAuthorizationPage());
            }
            const error = new Error(response.statusText);
            error.response = response;
            return Promise.reject(error);
        }
    });
}

/**
 * Create location
 * @param {*} locationCode
 * @param {*} locationName
 * @param {*} accessToken
 * @param {*} group
 */
export function createLocation(locationCode, locationName, accessToken, group) {
    return fetch(`${process.env.REACT_APP_IPA_API_URL}/v4/Locations`, {
        method: 'POST',
        body: JSON.stringify({
            code: locationCode,
            name: locationName,
            owners: [group],
        }),
        headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
        },
    }).then(response => {
        if (response.ok) {
            return response.json();
        } else {
            // handle session expired
            if (response.status === 401) {
                store.dispatch(redirectToAuthorizationPage());
            }
            const error = new Error(response.statusText);
            error.response = response;
            return Promise.reject(error);
        }
    });
}

/**
 * Retire location
 * @param {*} locationCode
 * @param {*} updateLocationObject Object with fields to update
 * @param {*} accessToken Bearer token for api requests
 * @param {*} group
 */
export function updateLocation(locationCode, updateLocationObject, accessToken, group) {
    return fetch(`${process.env.REACT_APP_IPA_API_URL}/v4/Locations/${locationCode}`, {
        method: 'PUT',
        body: JSON.stringify({
            ...updateLocationObject,
            owners: [group],
        }),
        headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
        },
    }).then(response => {
        if (response.ok) {
            return response.json();
        } else {
            // handle session expired
            if (response.status === 401) {
                store.dispatch(redirectToAuthorizationPage());
            }
            const error = new Error(response.statusText);
            error.response = response;
            return Promise.reject(error);
        }
    });
}

/**
 * Update cabinet name
 * @param {*} code Code for the device aka cabinet id
 * @param {*} updateCabinetNameObject
 * @param {*} accessToken Bearer token for api requests
 */
export function updateCabinetName(deviceCode, updateCabinetNameObject = {}, accessToken) {
    return fetch(`${process.env.REACT_APP_IPA_API_URL}/v3/Devices/${deviceCode}`, {
        method: 'PUT',
        body: JSON.stringify(updateCabinetNameObject),
        headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
        },
    }).then(response => {
        if (response.ok) {
            return Promise.resolve({ code: deviceCode, ...updateCabinetNameObject });
        } else {
            // handle session expired
            if (response.status === 401) {
                store.dispatch(redirectToAuthorizationPage('signInExpiredError'));
            }
            const error = new Error(response.statusText);
            error.response = response;
            return Promise.reject(error);
        }
    });
}

/**
 * Sends a POST request to root API to create a new VAT Category
 * @param {object} body The body of POST request containing name and tacValue
 * @param {object} group The organization id where VAT category belongs to. Sent as query parameter
 * @param {string} accessToken Bearer token for api requests
 */
export function createVatCategory(body, group, accessToken) {
    const url = `${process.env.REACT_APP_API_URL}/v1/vat?group=${encodeURIComponent(group.Id)}`;

    return fetch(url, {
        method: 'POST',
        body: JSON.stringify(body),
        headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
        },
    }).then(response => {
        if (response.ok) {
            return Promise.resolve(response.json());
        } else {
            // handle session expired
            if (response.status === 401) {
                store.dispatch(redirectToAuthorizationPage('signInExpiredError'));
            }

            const error = new Error(response.statusText);
            error.response = response;
            return Promise.reject(error);
        }
    });
}

/**
 * Sends a GET request to root API to get all VAT Categories belonging to an organization
 * @param {object} group Contains the organization id where VAT categories belongs to. Sent as query parameter
 * @param {string} accessToken Bearer token for api requests
 */
export function fetchVatCategories(group, accessToken) {
    const url = new URL(`${process.env.REACT_APP_API_URL}/v1/vat?group=${group.Id}`);

    return fetch(url, {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
        },
    }).then(response => {
        if (response.ok) {
            return Promise.resolve(response.json());
        } else {
            // handle session expired
            if (response.status === 401) {
                store.dispatch(redirectToAuthorizationPage('signInExpiredError'));
            }

            const error = new Error(response.statusText);
            error.response = response;
            return Promise.reject(error);
        }
    });
}

/**
 * Sends a PUT request to root API to update an existing VAT Category
 * @param {string} id The id of the VAT category to be updated sent as path parameter
 * @param {object} body The body of PUT request containing name and tacValue
 * @param {object} group Contains the organization id where VAT categories belongs to. Sent as query parameter
 * @param {string} accessToken Bearer token for api requests
 */
export function updateVatCategory(id, body, group, accessToken) {
    const url = new URL(`${process.env.REACT_APP_API_URL}/v1/vat/${id}?group=${group.Id}`);

    return fetch(url, {
        method: 'PUT',
        headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
    }).then(response => {
        if (response.ok) {
            return Promise.resolve(response.json());
        } else {
            // handle session expired
            if (response.status === 401) {
                store.dispatch(redirectToAuthorizationPage('signInExpiredError'));
            }

            const error = new Error(response.statusText);
            error.response = response;
            return Promise.reject(error);
        }
    });
}

/**
 * Sends a DELETE request to root API to remove VAT Category
 * @param {string} id The id of the VAT category to be deleted sent as path parameter
 * @param {object} group Contains the organization id where VAT categories belongs to. Sent as query parameter
 * @param {string} accessToken Bearer token for api requests
 */
export function deleteVatCategory(id, group, accessToken) {
    const url = new URL(`${process.env.REACT_APP_API_URL}/v1/vat/${id}?group=${group.Id}`);

    return fetch(url, {
        method: 'DELETE',
        headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
        },
    }).then(response => {
        if (response.ok) {
            return Promise.resolve(response.json());
        } else {
            // handle session expired
            if (response.status === 401) {
                store.dispatch(redirectToAuthorizationPage('signInExpiredError'));
            }

            const error = new Error(response.statusText);
            error.response = response;
            return Promise.reject(error);
        }
    });
}

/**
 * Sends a POST request to root API to create a new Product Category
 * @param {object} body The body of POST request containing name and description
 * @param {object} group The organization id where product category belongs to. Sent as query parameter
 * @param {string} accessToken Bearer token for api requests
 */
export function createProductCategory(body, group, accessToken) {
    const url = `${process.env.REACT_APP_API_URL}/v2/product-category?group=${encodeURIComponent(group.Id)}`;

    return fetch(url, {
        method: 'POST',
        body: JSON.stringify(body),
        headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
        },
    }).then(response => {
        if (response.ok) {
            return Promise.resolve(response.json());
        } else {
            // handle session expired
            if (response.status === 401) {
                store.dispatch(redirectToAuthorizationPage('signInExpiredError'));
            }

            const error = new Error(response.statusText);
            error.response = response;
            return Promise.reject(error);
        }
    });
}

/**
 * Sends a GET request to root API to get all Product Labels belonging to an organization
 * @param {object} group Contains the organization id where product labels belongs to. Sent as query parameter
 * @param {string} accessToken Bearer token for api requests
 */
export function fetchProductLabels(group, accessToken) {
    const url = new URL(`${process.env.REACT_APP_API_URL}/v3/product-label?group=${group.Id}`);
    return fetch(url, {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
        },
    }).then(response => {
        if (response.ok) {
            return Promise.resolve(response.json());
        } else {
            // handle session expired
            if (response.status === 401) {
                store.dispatch(redirectToAuthorizationPage('signInExpiredError'));
            }

            const error = new Error(response.statusText);
            error.response = response;
            return Promise.reject(error);
        }
    });
}

/**
 * Sends a GET request to root API to get all Product Categories belonging to an organization
 * @param {object} group Contains the organization id where product categories belongs to. Sent as query parameter
 * @param {string} accessToken Bearer token for api requests
 */
export function fetchProductCategories(group, accessToken) {
    const url = new URL(`${process.env.REACT_APP_API_URL}/v2/product-category?group=${group.Id}`);
    return fetch(url, {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
        },
    }).then(response => {
        if (response.ok) {
            return Promise.resolve(response.json());
        } else {
            // handle session expired
            if (response.status === 401) {
                store.dispatch(redirectToAuthorizationPage('signInExpiredError'));
            }

            const error = new Error(response.statusText);
            error.response = response;
            return Promise.reject(error);
        }
    });
}

/**
 * Sends a PUT request to root API to update an existing Product Category
 * @param {string} id The id of the product category to be updated sent as path parameter
 * @param {object} body The body of PUT request containing name and description
 * @param {object} group Contains the organization id where product category belongs to. Sent as query parameter
 * @param {string} accessToken Bearer token for api requests
 */
export function updateProductCategory(id, body, group, accessToken) {
    const url = new URL(`${process.env.REACT_APP_API_URL}/v2/product-category/${id}?group=${group.Id}`);

    return fetch(url, {
        method: 'PUT',
        headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
    }).then(response => {
        if (response.ok) {
            return Promise.resolve(response.json());
        } else {
            // handle session expired
            if (response.status === 401) {
                store.dispatch(redirectToAuthorizationPage('signInExpiredError'));
            }

            const error = new Error(response.statusText);
            error.response = response;
            return Promise.reject(error);
        }
    });
}

/**
 * Sends a DELETE request to root API to remove Product Category
 * @param {string} id The id of the product category to be deleted sent as path parameter
 * @param {object} group Contains the organization id where product category belongs to. Sent as query parameter
 * @param {string} accessToken Bearer token for api requests
 */
export function deleteProductCategory(id, group, accessToken) {
    const url = new URL(`${process.env.REACT_APP_API_URL}/v2/product-category/${id}?group=${group.Id}`);

    return fetch(url, {
        method: 'DELETE',
        headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
        },
    }).then(response => {
        if (response.ok) {
            return Promise.resolve(response.json());
        } else {
            // handle session expired
            if (response.status === 401) {
                store.dispatch(redirectToAuthorizationPage('signInExpiredError'));
            }

            const error = new Error(response.statusText);
            error.response = response;
            return Promise.reject(error);
        }
    });
}

/**
 * Sends a POST request to root API to create a new supplier
 * @param {object} body The body of POST request containing name and description
 * @param {object} group The organization id where supplier belongs to. Sent as query parameter
 * @param {string} accessToken Bearer token for api requests
 */
export function createSupplier(body, group, accessToken) {
    const url = `${process.env.REACT_APP_API_URL}/v1/supplier?group=${encodeURIComponent(group.Id)}`;

    return fetch(url, {
        method: 'POST',
        body: JSON.stringify(body),
        headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
        },
    }).then(response => {
        if (response.ok) {
            return Promise.resolve(response.json());
        } else {
            // handle session expired
            if (response.status === 401) {
                store.dispatch(redirectToAuthorizationPage('signInExpiredError'));
            }

            const error = new Error(response.statusText);
            error.response = response;
            return Promise.reject(error);
        }
    });
}

/**
 * Sends a GET request to root API to get all suppliers belonging to an organization
 * @param {object} group Contains the organization id where suppliers belong to. Sent as query parameter
 * @param {string} accessToken Bearer token for api requests
 */
export function fetchSuppliers(group, accessToken) {
    const url = new URL(`${process.env.REACT_APP_API_URL}/v1/supplier?group=${group.Id}`);

    return fetch(url, {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
        },
    }).then(response => {
        if (response.ok) {
            return Promise.resolve(response.json());
        } else {
            // handle session expired
            if (response.status === 401) {
                store.dispatch(redirectToAuthorizationPage('signInExpiredError'));
            }

            const error = new Error(response.statusText);
            error.response = response;
            return Promise.reject(error);
        }
    });
}

/**
 * Sends a PUT request to root API to update an existing Supplier
 * @param {string} id The id of the supplier to be updated sent as path parameter
 * @param {object} body The body of PUT request containing name and description
 * @param {object} group Contains the organization id where supplier belongs to. Sent as query parameter
 * @param {string} accessToken Bearer token for api requests
 */
export function updateSupplier(id, body, group, accessToken) {
    const url = new URL(`${process.env.REACT_APP_API_URL}/v1/supplier/${id}?group=${group.Id}`);

    return fetch(url, {
        method: 'PUT',
        headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
    }).then(response => {
        if (response.ok) {
            return Promise.resolve(response.json());
        } else {
            // handle session expired
            if (response.status === 401) {
                store.dispatch(redirectToAuthorizationPage('signInExpiredError'));
            }

            const error = new Error(response.statusText);
            error.response = response;
            return Promise.reject(error);
        }
    });
}

/**
 * Sends a PATCH request to root API to "delete" the supplier. The supplier will not be deleted from the database
 * but the status of the supplier will be be updated so that an organization will no longer see it.
 * @param {object} group Contains the organization id where suppliers belong to. Sent as query parameter
 * @param {string} accessToken Bearer token for api requests
 */
export function archiveSupplier(supplier, accessToken, group) {
    const url = `${process.env.REACT_APP_API_URL}/v1/supplier/${supplier.id}?group=${encodeURIComponent(group.Id)}`;

    return fetch(url, {
        method: 'PATCH',
        headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ status: supplier.status }),
    }).then(response => {
        if (response.ok) {
            return Promise.resolve(response.json());
        } else {
            // handle session expired
            if (response.status === 401) {
                store.dispatch(redirectToAuthorizationPage('signInExpiredError'));
            }

            if (response.headers.get('Content-Type') && response.headers.get('Content-Type').match(/application\/json/g)) {
                return response.json().then(body => {
                    if (body.message) {
                        return Promise.reject(new Error(body.message));
                    } else {
                        return Promise.reject(new Error(response.statusText));
                    }
                });
            }

            return Promise.reject(new Error(response.statusText));
        }
    });
}

/**
 * Sends a GET request to root API to get all discount rules belonging to organization
 * @param {object} group Contains the organization id that discount rules belong to. Sent as query parameter
 * @param {string} accessToken Bearer token for api requests
 */
export function fetchDiscountRules(group, accessToken) {
    const url = new URL(`${process.env.REACT_APP_API_URL}/v2/discounts?group=${group.Id}`);

    return fetch(url, {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
        },
    }).then(response => {
        if (response.ok) {
            return Promise.resolve(response.json());
        } else {
            // handle session expired
            if (response.status === 401) {
                store.dispatch(redirectToAuthorizationPage('signInExpiredError'));
            }

            const error = new Error(response.statusText);
            error.response = response;
            return Promise.reject(error);
        }
    });
}

/**
 * Sends a POST request to root API to create a discount rule
 * @param {object} body The body of POST request
 * @param {object} group Contains the organization id that discount rules belong to. Sent as query parameter
 * @param {string} accessToken Bearer token for api requests
 */
export function createDiscountRule(body, group, accessToken) {
    const url = new URL(`${process.env.REACT_APP_API_URL}/v2/discounts?group=${group.Id}`);

    return fetch(url, {
        method: 'POST',
        body: JSON.stringify(body),
        headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
        },
    }).then(response => {
        if (response.ok) {
            return Promise.resolve(response.json());
        } else {
            // handle session expired
            if (response.status === 401) {
                store.dispatch(redirectToAuthorizationPage('signInExpiredError'));
            }

            const error = new Error(response.statusText);
            error.response = response;
            return Promise.reject(error);
        }
    });
}

/**
 * Sends a UPDATE request to root API to delete a discount rule
 * @param {object} rule Discount rule to update
 * @param {object} group Contains the organization id that discount rules belong to. Sent as query parameter
 * @param {string} accessToken Bearer token for api requests
 */
export function updateDiscountRule(rule, group, accessToken) {
    const url = new URL(`${process.env.REACT_APP_API_URL}/v2/discounts/${rule.Id}?group=${group.Id}`);

    //Delete not needed arguments
    delete rule.Id;
    delete rule.OrganisationId;
    if(!Array.isArray(rule.userIds) || rule.userIds === null){
        delete rule.userIds
    }

    return fetch(url, {
        method: 'PUT',
        body: JSON.stringify(rule),
        headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
        },
    }).then(response => {
        if (response.ok) {
            return Promise.resolve(response.json());
        } else {
            // handle session expired
            if (response.status === 401) {
                store.dispatch(redirectToAuthorizationPage('signInExpiredError'));
            }

            const error = new Error(response.statusText);
            error.response = response;
            return Promise.reject(error);
        }
    });
}

/**
 * Sends HTTP request to store logo image and returns url to image
 *
 * @returns {Promise} formData Form data with 1 image
 * @throws {Error} httpError
 */
export async function uploadDiscountImage(formData, accessToken, group, discountType) {
    return fetch(`${process.env.REACT_APP_API_URL}/v3/discount-image?group=${encodeURIComponent(group.Id)}&type=${discountType}`, {
        method: 'POST',
        body: formData,
        headers: {
            Authorization: `Bearer ${accessToken}`,
        },
    }).then(response => {
        if (response.ok) {
            return Promise.resolve(response.json());
        } else {
            // handle session expired
            if (response.status === 401) {
                store.dispatch(redirectToAuthorizationPage('signInExpiredError'));
            }

            if (response.headers.get('Content-Type') && response.headers.get('Content-Type').match(/application\/json/g)) {
                return response.json().then(body => {
                    if (body.message) {
                        return Promise.reject(new Error(body.message));
                    } else {
                        return Promise.reject(new Error(response.statusText));
                    }
                });
            }

            return Promise.reject(new Error(response.statusText));
        }
    });
}

/**
 * Sends request to fetch device software veersion to API
 */
export function fetchDeviceSoftwareVersion(deviceId, accessToken) {
    const url = new URL(`${process.env.REACT_APP_API_URL}/v2/device-release-version/${deviceId}`);

    return fetch(url, {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
        },
    }).then(response => {
        if (response.ok) {
            return Promise.resolve(response.json());
        } else {
            // handle session expired
            if (response.status === 401) {
                store.dispatch(redirectToAuthorizationPage('signInExpiredError'));
            }

            const error = new Error(response.statusText);
            error.response = response;
            return Promise.reject(error);
        }
    });
}

export function fetchAllSoftwareVersions(accessToken) {
    const url = new URL(`${process.env.REACT_APP_API_URL}/v2/release-version`);

    return fetch(url, {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
        },
    }).then(response => {
        if (response.ok) {
            return Promise.resolve(response.json());
        } else {
            // handle session expired
            if (response.status === 401) {
                store.dispatch(redirectToAuthorizationPage('signInExpiredError'));
            }

            const error = new Error(response.statusText);
            error.response = response;
            return Promise.reject(error);
        }
    });
}

export function updateOneOrMoreDeviceSoftwareVersions(group, releaseData, accessToken) {
    const url = new URL(`${process.env.REACT_APP_API_URL}/v2/device-release-version/${releaseData.devices[0].deviceId}?group=${group.Id}`);
    return fetch(url, {
        method: 'PUT',
        headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(releaseData),
    }).then(response => {
        if (response.ok) {
            return Promise.resolve(response.json());
        } else {
            // handle session expired
            if (response.status === 401) {
                store.dispatch(redirectToAuthorizationPage('signInExpiredError'));
            }

            const error = new Error(response.statusText);
            error.response = response;
            return Promise.reject(error);
        }
    });
}
export function removeReleaseUpdateScheduling(group, scheduleId, accessToken) {
    const url = new URL(`${process.env.REACT_APP_API_URL}/v2/device-release-version/${scheduleId}?group=${group.Id}`);
    return fetch(url, {
        method: 'DELETE',
        headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
        },
    }).then(response => {
        if (response.ok) {
            return Promise.resolve(response.json());
        } else {
            // handle session expired
            if (response.status === 401) {
                store.dispatch(redirectToAuthorizationPage('signInExpiredError'));
            }

            const error = new Error(response.statusText);
            error.response = response;
            return Promise.reject(error);
        }
    });
}

export function updateDeviceSoftwareVersion(deviceId, version, accessToken) {
    const url = new URL(`${process.env.REACT_APP_API_URL}/v2/device-release-version/${deviceId}`);
    const release = { release: version };

    return fetch(url, {
        method: 'PUT',
        headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(release),
    }).then(response => {
        if (response.ok) {
            return Promise.resolve(response.json());
        } else {
            // handle session expired
            if (response.status === 401) {
                store.dispatch(redirectToAuthorizationPage('signInExpiredError'));
            }

            const error = new Error(response.statusText);
            error.response = response;
            return Promise.reject(error);
        }
    });
}

export function fetchSelectedDeviceType(deviceId, accessToken) {
    const graphqlQuery = `{device(hardwareId: "${deviceId}") {type, subType, osVersion }}`;

    // graphQL request to IPA API
    return fetch(`${process.env.REACT_APP_IPA_API_URL}/v4/graphql`, {
        method: 'POST',
        body: JSON.stringify({
            query: graphqlQuery,
        }),
        headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
        },
    }).then(response => {
        if (response.ok) {
            return response.json().then(body => {
                // missing data due to GraphQL error
                if (!body.data) {
                    const error = new Error('Unable to fetch device due to GraphQL error');
                    error.graphQLErrors = body.errors;
                    return Promise.reject(error);
                }
                // missing device type info in the response
                if (body.data.device === null) {
                    return Promise.reject(new Error(`No device found with device code: ${deviceId}`));
                }

                const deviceType = body.data.device;
                // Forcing undefined cabinet sub-type (or subType = None ) to IF2 subtype
                if (deviceType && deviceType.type && deviceType.type === 'smartCabinet' && (deviceType.subType === 'None' || !deviceType.subType)) {
                    deviceType.subType = 'IF2';
                }
                return Promise.resolve(deviceType);
            });
        } else {
            // handle session expired
            if (response.status === 401) {
                store.dispatch(redirectToAuthorizationPage('signInExpiredError'));
            }

            const error = new Error(response.statusText);
            error.response = response;
            return Promise.reject(error);
        }
    });
}

/**
 * HTTP request for rebooting cabinet
 */
export function rebootDevice(deviceCode, accessToken) {
    const url = new URL(`${process.env.REACT_APP_API_URL}/v2/device-command?id=${deviceCode}`);
    return fetch(url, {
        method: 'POST',
        headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            command: 'reboot',
            deviceCode,
        }),
    }).then(response => {
        if (response.ok) {
            return Promise.resolve(response.json());
        } else {
            // handle session expired
            if (response.status === 401) {
                store.dispatch(redirectToAuthorizationPage('signInExpiredError'));
            }

            const error = new Error(response.statusText);
            error.response = response;
            return Promise.reject(error);
        }
    });
}

/**
 * Faster alternative for fetching temperature. Replacing cube query
 */
export function fetchTemperatureForChart(deviceCode, from, to, group, accessToken) {
    const url = new URL(`${process.env.REACT_APP_API_URL}/v2/device-health?group=${group.Id}&from=${from}&to=${to}&id=${deviceCode}`);

    return fetch(url, {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
        },
    }).then(response => {
        if (response.ok) {
            return Promise.resolve(response.json());
        } else {
            // handle session expired
            if (response.status === 401) {
                store.dispatch(redirectToAuthorizationPage('signInExpiredError'));
            }

            const error = new Error(response.statusText);
            error.response = response;
            return Promise.reject(error);
        }
    });
}

/**
 * Sends a GET request to root API to get all organizations belonging to an organization
 * @param {object} group Contains the organization id where organizations belongs to. Sent as query parameter
 * @param {string} accessToken Bearer token for api requests
 */
export function fetchOrganizationSettings(group, accessToken, signInError) {
    const url = new URL(`${process.env.REACT_APP_API_URL}/v3/organization/myselfly-settings?group=${group?.Id}`);

    return fetch(url, {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
        },
    }).then(response => {
        if (response.ok) {
            return Promise.resolve(response.json());
        } else {
            // handle session expired
            if (response.status === 401 && signInError === 'signInExpiredError') {
                store.dispatch(redirectToAuthorizationPage('signInExpiredError'));
            }

            const error = new Error(response.statusText);
            error.response = response;
            return Promise.reject(error);
        }
    });
}

/**
 * Update organization settings
 * @param {string} id
 * @param {string} accessToken
 * @param {object} settings
 */
export function updateOrganizationSettings(id, accessToken, settings = {}) {
    return fetch(`${process.env.REACT_APP_API_URL}/v3/organization/myselfly-settings/${id}?group=${id}`, {
        method: 'PUT',
        body: JSON.stringify(settings),
        headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
        },
    }).then(response => {
        if (response.ok) {
            return response.json();
        } else {
            // handle session expired
            if (response.status === 401) {
                store.dispatch(redirectToAuthorizationPage('signInExpiredError'));
            }
            const error = new Error(response.statusText);
            error.response = response;
            return Promise.reject(error);
        }
    });
}

/**
 * Fetches DES Devices.
 *
 * @returns {Array} cabinets
 * @throws {Error} httpError
 */
export function fetchEncodingDevices(accessToken, group) {
    const url = group
        ? `${process.env.REACT_APP_API_URL}/v3/encodingDevices?group=${encodeURIComponent(group.Id)}`
        : `${process.env.REACT_APP_API_URL}/v1/cabinets`;

    // graphQL request to IPA API
    return fetch(url, {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${accessToken}`,
        },
    }).then(response => {
        if (response.ok) {
            return Promise.resolve(response.json());
        } else {
            // handle session expired
            if (response.status === 401) {
                store.dispatch(redirectToAuthorizationPage('signInExpiredError'));
            }

            return Promise.reject(new Error(response.statusText));
        }
    });
}

export function fetchAllOrganizationsCabinetReleases(accessToken, group, signInError) {
    const url = new URL(`${process.env.REACT_APP_API_URL}/v2/device-release-version?group=${group?.Id}`);

    return fetch(url, {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
        },
    }).then(response => {
        if (response.ok) {
            return Promise.resolve(response.json());
        } else {
            // handle session expired
            if (response.status === 401 && signInError === 'signInExpiredError') {
                store.dispatch(redirectToAuthorizationPage('signInExpiredError'));
            }

            const error = new Error(response.statusText);
            error.response = response;
            return Promise.reject(error);
        }
    });
}

/**
 * Fetches the cabinet refills.
 *
 * @returns {Array} cabinetInventory
 * @throws {Error} httpError
 */
export function fetchRefillRules(accessToken, group) {
    const url = new URL(`${process.env.REACT_APP_API_URL}/v3/refill-rules?group=${group}`);

    return fetch(url, {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
        },
    }).then(response => {
        if (response.ok) {
            return Promise.resolve(response.json());
        } else {
            // handle session expired
            if (response.status === 401) {
                store.dispatch(redirectToAuthorizationPage('signInExpiredError'));
            }

            const error = new Error(response.statusText);
            error.response = response;
            return Promise.reject(error);
        }
    });
}

/**
 * request refill rule for a given device code
 * @param {*} accessToken
 * @param {*} group
 * @param {*} deviceCode
 * @returns
 *
 */
export function fetchCabinetRefillRule(accessToken, group, deviceCode) {
    const url = new URL(`${process.env.REACT_APP_API_URL}/v3/refill-rules/${deviceCode}?group=${group}`);

    return fetch(url, {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
        },
    })
        .then(response => {
            if (response.ok) {
                return Promise.resolve(response.json());
            } else {
                // handle session expired
                if (response.status === 401) {
                    store.dispatch(redirectToAuthorizationPage('signInExpiredError'));
                }

                const error = new Error(response.statusText);
                error.response = response;
                return Promise.reject(error);
            }
        })
        .catch(error => {
            console.error(error);
        });
}

/**
 * Sends a UPDATE request to root API to update a refill rule
 * @param {object} rule Discount rule to update
 * @param {object} group Contains the organization id that discount rules belong to. Sent as query parameter
 * @param {string} accessToken Bearer token for api requests
 */
export function createRefillRule(rule, accessToken, group, deviceCode) {
    const url = new URL(`${process.env.REACT_APP_API_URL}/v3/refill-rules?group=${group}&deviceCode=${deviceCode}`);

    return fetch(url, {
        method: 'POST',
        body: JSON.stringify(rule),
        headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
        },
    }).then(response => {
        if (response.ok) {
            return Promise.resolve(response.json());
        } else {
            // handle session expired
            if (response.status === 401) {
                store.dispatch(redirectToAuthorizationPage('signInExpiredError'));
            }

            const error = new Error(response.statusText);
            error.response = response;
            return Promise.reject(error);
        }
    });
}
/**
 * Sends a UPDATE request to root API to update a refill rule
 * @param {object} rule Discount rule to update
 * @param {object} group Contains the organization id that discount rules belong to. Sent as query parameter
 * @param {string} accessToken Bearer token for api requests
 */
export function updateRefillRule(rule, accessToken, group) {
    const url = new URL(`${process.env.REACT_APP_API_URL}/v3/refill-rules/${rule.id}?group=${group}&deviceCode=${rule.deviceCode}`);
    return fetch(url, {
        method: 'PUT',
        body: JSON.stringify(rule.data),
        headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
        },
    }).then(response => {
        if (response.ok) {
            return Promise.resolve(response.json());
        } else {
            // handle session expired
            if (response.status === 401) {
                store.dispatch(redirectToAuthorizationPage('signInExpiredError'));
            }

            const error = new Error(response.statusText);
            error.response = response;
            return Promise.reject(error);
        }
    });
}

/**
 * Sends a DELETE request to root API to delete a refill rule
 * @param {object} ruleId  Refill rule id to delete
 * @param {object} group Contains the organization id that discount rules belong to. Sent as query parameter
 * @param {string} accessToken Bearer token for api requests
 */
export function deleteRefillRule(deviceCode, accessToken, group) {
    const url = new URL(`${process.env.REACT_APP_API_URL}/v3/refill-rules/${deviceCode}?group=${group}`);

    return fetch(url, {
        method: 'DELETE',
        headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
        },
    })
        .then(response => {
            if (response.ok) {
                return Promise.resolve(response.json());
            } else {
                // handle session expired
                if (response.status === 401) {
                    store.dispatch(redirectToAuthorizationPage('signInExpiredError'));
                }

                const error = new Error(response.statusText);
                error.response = response;
                console.log(error);
                return Promise.reject(error);
            }
        })
        .catch(error => {
            return Promise.reject(error);
        });
}

/**
 * Fetches the cabinet refill plans.
 *
 * @returns {Array} Refill plan array
 * @throws {Error} httpError
 */
export function fetchRefillPlans(accessToken, group, deviceCode) {
    const url = new URL(`${process.env.REACT_APP_API_URL}/v3/refill-plans?group=${group.Id}${deviceCode ? '&deviceCode=' + deviceCode : ''}`);

    return fetch(url, {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
        },
    }).then(response => {
        if (response.ok) {
            return Promise.resolve(response.json());
        } else {
            // handle session expired
            if (response.status === 401) {
                store.dispatch(redirectToAuthorizationPage('signInExpiredError'));
            }

            const error = new Error(response.statusText);
            error.response = response;
            return Promise.reject(error);
        }
    });
}

/**
 * Creates a cabinet refill plan.
 *
 * @returns {Object} Updated refill plan
 * @throws {Error} httpError
 */
export function createRefillPlan(data, group, accessToken) {
    const url = new URL(`${process.env.REACT_APP_API_URL}/v3/refill-plans?group=${group.Id}`);
    // for hot fix in production, I directly change payload before sending to rootapi
    // as save method
    try {
        if (Object.keys(data) && Object.keys(data).length > 0) {
            Object.keys(data).forEach(deviceCode => {
                if (!Array.isArray(data[deviceCode].productData)) return;
                for (let i = 0; i < data[deviceCode].productData.length; i++) {
                    delete data[deviceCode].productData[i].imageUrl;
                    delete data[deviceCode].productData[i].name;
                }
            });
        }
    } catch (e) {
        console.log('can not parse data for refill plan');
    }

    return fetch(url, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
        },
    }).then(response => {
        if (response.ok) {
            return Promise.resolve(response.json());
        } else {
            // handle session expired
            if (response.status === 401) {
                store.dispatch(redirectToAuthorizationPage('signInExpiredError'));
            }

            const error = new Error(response.statusText);
            error.response = response;
            return Promise.reject(error);
        }
    });
}

/**
 * Updates the cabinet refill plan.
 *
 * @returns {Object} Updated refill plan
 * @throws {Error} httpError
 */
export function updateRefillPlan(accessToken, group, data, id) {
    const url = new URL(`${process.env.REACT_APP_API_URL}/v3/refill-plans/${id}?group=${group.Id}`);

    return fetch(url, {
        method: 'PUT',
        body: JSON.stringify(data),
        headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
        },
    }).then(response => {
        if (response.ok) {
            return Promise.resolve(response.json());
        } else {
            // handle session expired
            if (response.status === 401) {
                store.dispatch(redirectToAuthorizationPage('signInExpiredError'));
            }

            const error = new Error(response.statusText);
            error.response = response;
            return Promise.reject(error);
        }
    });
}

/**
 * Updates the cabinet refill plan.
 *
 * @returns {Object} Updated refill plan
 * @throws {Error} httpError
 */
export function deleteRefillPlan(accessToken, group, id) {
    const url = new URL(`${process.env.REACT_APP_API_URL}/v3/refill-plans/${id}?group=${group.Id}&planId=${true}`);

    return fetch(url, {
        method: 'DELETE',
        headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
        },
    }).then(response => {
        if (response.ok) {
            return Promise.resolve(response.json());
        } else {
            // handle session expired
            if (response.status === 401) {
                store.dispatch(redirectToAuthorizationPage('signInExpiredError'));
            }

            const error = new Error(response.statusText);
            error.response = response;
            return Promise.reject(error);
        }
    });
}

/**
 * Updates the refill plan status.
 *
 */
export async function updateRefillPlanStatus(accessToken, group, status, planId) {
    const url = new URL(`${process.env.REACT_APP_API_URL}/v3/refill-plans/${planId}?group=${group.Id}&status=${status}`);

    const response = await fetch(url, {
        method: 'PATCH',
        headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
        },
    });
    if (response.ok) {
        return Promise.resolve(response.json());
    } else {
        // handle session expired
        if (response.status === 401) {
            store.dispatch(redirectToAuthorizationPage('signInExpiredError'));
        }

        const error_1 = new Error(response.statusText);
        error_1.response = response;
        return Promise.reject(error_1);
    }
}

export async function getRefillSuggestionsByPage(selectedCabinets, group, accessToken) {
    let splitedCabinets = [],
        pageSize = 5;

    while (selectedCabinets.length > 0) splitedCabinets.push(selectedCabinets.splice(0, pageSize));

    let data = [];
    for (let i = 0; i < splitedCabinets.length; i++) {
        let resultData = (await postRefillPlanSuggestions(splitedCabinets[i], group, accessToken).catch(e => e)) || [];
        data = [...data, ...resultData];
    }
    return Promise.resolve(data);
}

/**
 * Posts the refill plan suggestions.
 *
 */
export async function postRefillPlanSuggestions(selectedCabinets, group, accessToken) {
    const url = new URL(`${process.env.REACT_APP_API_URL}/v3/refill-plan-suggestions?group=${group.Id}`);

    const response = await fetch(url, {
        method: 'POST',
        body: JSON.stringify({ selectedCabinets }),
        headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
        },
    });
    if (response.ok) {
        return Promise.resolve(response.json());
    } else {
        // handle session expired
        if (response.status === 401) {
            store.dispatch(redirectToAuthorizationPage('signInExpiredError'));
        }

        const error_1 = new Error(response.statusText);
        error_1.response = response;
        return Promise.reject(error_1);
    }
}

/**
 *
 * @param {*} formData image data
 * @param {*} accessToken bearer token
 * @param {*} group organisation group
 * @returns
 */
export function createThemeImage(formData, accessToken, group) {
    return fetch(`${process.env.REACT_APP_API_URL}/v3/theme-images?group=${group.Id}`, {
        method: 'POST',
        body: formData,
        headers: {
            Authorization: `Bearer ${accessToken}`,
        },
    }).then(response => {
        if (response.ok) {
            return Promise.resolve(response.json());
        } else {
            // handle session expired
            if (response.status === 401) {
                store.dispatch(redirectToAuthorizationPage('signInExpiredError'));
            }

            if (response.headers.get('Content-Type') && response.headers.get('Content-Type').match(/application\/json/g)) {
                return response.json().then(body => {
                    if (body.message) {
                        return Promise.reject(new Error(body.message));
                    } else {
                        return Promise.reject(new Error(response.statusText));
                    }
                });
            }

            return Promise.reject(new Error(response.statusText));
        }
    });
}

/**
 * Copies/updates the selected settings to the selected cabinets
 * @param {*} selectedCabinets The selected cabinets
 * @param {*} settingType The type of the settings being copied. This is currently only used for the refill rule setting.
 *  If the setting is the refill rule then the value should be 'refillRule' and otherwise the value should be null.
 * @param {*} settings The selected settings to be copied
 * @param {*} group organisation group
 * @param {*} accessToken bearer token
 * @returns Returns an array of the results of each request to copy settings to a cabinet.
 *  The results contain a successful or failure status of each request and if a failure status then also the error of the request.
 */
export async function postSettingsCopyRequest(selectedCabinets, settingType, settings, group, accessToken) {
    const url = new URL(`${process.env.REACT_APP_API_URL}/v1/settings?group=${group.Id}`);

    const response = await fetch(url, {
        method: 'POST',
        body: JSON.stringify({ selectedCabinets, settingType, settings }),
        headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
        },
    });

    if (response.ok) {
        return Promise.resolve(response);
    } else {
        // handle session expired
        if (response.status === 401) {
            store.dispatch(redirectToAuthorizationPage('signInExpiredError'));
        }

        return Promise.resolve(response.json());
    }
}

/** Fetch device alarms for the organisation */
export async function fetchDeviceAlarms(group, accessToken) {
    const query = `{alerts(organization: "${group.Id}", state: ACTIVE) { id, type, state, severity, description, device_Id, device_Code, firstTimeSeen, lastTimeSeen}}`;

    return fetch(`${process.env.REACT_APP_IPA_API_URL}/v4/graphql`, {
        method: 'POST',
        body: JSON.stringify({
            query,
        }),
        headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
        },
    }).then(response => {
        if (response.ok) {
            return response.json().then(body => {
                // missing data due to GraphQL error
                if (!body.data) {
                    const error = new Error('Unable to fetch device alarms due to GraphQL error');
                    error.graphQLErrors = body.errors;
                    return Promise.reject(error);
                }

                const alerts = body.data.alerts || [];

                return Promise.resolve(alerts);
            });
        } else {
            // handle session expired
            if (response.status === 401) {
                store.dispatch(redirectToAuthorizationPage('signInExpiredError'));
            }

            const error = new Error(response.statusText);
            error.response = response;
            return Promise.reject(error);
        }
    });
}

/**
 * Sends HTTP request to store plaogram image - it should return an image url.
 *
 * @returns {FormData} formData Form data with 1 image
 * @throws {Error} httpError
 */
export function imageUpload(formData, accessToken, group, type) {
    return fetch(`${process.env.REACT_APP_API_URL}/v3/image-upload?group=${encodeURIComponent(group)}&type=${type}`, {
        method: 'POST',
        body: formData,
        headers: {
            Authorization: `Bearer ${accessToken}`,
        },
    }).then(response => {
        if (response.ok) {
            return Promise.resolve(response.json());
        } else {
            // handle session expired
            if (response.status === 401) {
                store.dispatch(redirectToAuthorizationPage('signInExpiredError'));
            }

            if (response.headers.get('Content-Type') && response.headers.get('Content-Type').match(/application\/json/g)) {
                return response.json().then(body => {
                    if (body.message) {
                        return Promise.reject(new Error(body.message));
                    } else {
                        return Promise.reject(new Error(response.statusText));
                    }
                });
            }

            return Promise.reject(new Error(response.statusText));
        }
    });
}

/**
 *
 * @param {*} data
 * @param {*} group
 * @param {*} accessToken
 * @returns
 */
export function createAutomation(data, group, accessToken) {
    const url = new URL(`${process.env.REACT_APP_API_URL}/v3/automation?organizationId=${group.Id}`);

    return fetch(url, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
        },
    }).then(response => {
        if (response.ok) {
            return Promise.resolve(response.json());
        } else {
            // handle session expired
            if (response.status === 401) {
                store.dispatch(redirectToAuthorizationPage('signInExpiredError'));
            }
            if (response.headers.get('Content-Type') && response.headers.get('Content-Type').match(/application\/json/g)) {
                return response.json().then(body => {
                    if (body.message) {
                        return Promise.reject(new Error(body.message));
                    } else {
                        return Promise.reject(new Error(response.statusText));
                    }
                });
            }
            return Promise.reject(new Error(response.statusText));
        }
    });
}

/**
 *
 * @param {*} type
 * @param {*} group
 * @param {*} accessToken
 * @returns
 */
export function fetchAutomation(type, group, accessToken) {
    const url = new URL(`${process.env.REACT_APP_API_URL}/v3/automation?organizationId=${group.Id}&type=${type}`);

    return fetch(url, {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
        },
    }).then(response => {
        if (response.ok) {
            return Promise.resolve(response.json());
        } else {
            // handle session expired
            if (response.status === 401) {
                store.dispatch(redirectToAuthorizationPage('signInExpiredError'));
            }

            const error = new Error(response.statusText);
            error.response = response;
            return Promise.reject(error);
        }
    });
}

export function updateAutomation(id, data, group, accessToken) {
    const url = new URL(`${process.env.REACT_APP_API_URL}/v3/automation/${id}?organizationId=${group.Id}`);

    return fetch(url, {
        method: 'PUT',
        body: JSON.stringify(data),
        headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
        },
    })
        .then(response => {
            if (response.ok) {
                return Promise.resolve(response.json());
            } else {
                // handle session expired
                if (response.status === 401) {
                    store.dispatch(redirectToAuthorizationPage('signInExpiredError'));
                }
                const error = new Error(response.statusText);
                error.response = response;
                return Promise.reject(error);
            }
        })
        .catch(error => {
            return Promise.reject(error);
        });
}
