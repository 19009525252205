import React from 'react';
import { Input } from 'reactstrap';
import { Button } from '../../commons/utils';

const EmailLists = ({ emailAddresses, emailAddressChange, removeEmail, addNewEmail, t }) => {
    return (
        <div className="automations__contents-selects">
            <p>{t('automations:emailNotificationTitle')}</p>
            <ul>
                {emailAddresses.map((email, index) => {
                    return (
                        <li className="automations__contents-remove" key={index}>
                            <Input
                                type="text"
                                className="automations__contents-email"
                                key={index}
                                autoFocus="autoFocus"
                                placeholder={t('consumerApp:email')}
                                value={email}
                                onChange={e => emailAddressChange(e.target.value, index)}
                            />
                            <span onClick={e => removeEmail(index)}>x</span>
                        </li>
                    );
                })}
            </ul>
            <Button
                type="add"
                text={t('automations:addNewEmail')}
                onClick={() => {
                    addNewEmail();
                }}
            />
        </div>
    );
};

export default EmailLists;
