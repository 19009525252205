import React, { useEffect, useState } from 'react';

import InfoBanner from '../segments/InfoBanner';
import ActivePlanData from '../segments/activePlanData';
import { _13digitBarcode } from '../utils';
import AddRefillPlans from '../segments/addRefillPlans';
import RefillSuggestions from '../segments/refillSuggestions';
import { Button } from '../../commons/utils';

const ActivePlans = ({
    refillPlans,
    updateRefillPlan,
    allRefillRules,
    fetchRefillRules,
    locations,
    cabinets,
    fetchRefillPlans,
    fetchCabinetInventory,
    cabinetInventory,
    getTransactions,
    cabinetTransactionReport,
    deleteRefillPlan,
    organization,
    refillPlanCabinetSuggestions,
    allProducts,
    createRefillPlan,
    t,
    uploadPlanogramImage,
    planogramImageInStore,
    isAdmin,
}) => {
    const [allActiveRefillPlans, setAllActiveRefillPlans] = useState([]);
    const [addNewRefillPlansView, setAddNewRefillPlansView] = useState(false);
    const [refillSuggestionsView, setRefillSuggestionsView] = useState(false);
    const [activeViewMode, setActiveViewMode] = useState('inventory');

    useEffect(() => {
        if (refillPlans.isFetching || allRefillRules.isFetching) return;

        const { activePlans } = refillPlans;

        const refillPlansDeviceCodes = Object.keys(activePlans);
        if (!activePlans || !refillPlansDeviceCodes.length) {
            setAllActiveRefillPlans([]);
            return;
        }

        const schemaDatas = allRefillRules.refillRules || [];

        let mappedRefillPlans = [];

        refillPlansDeviceCodes.forEach(deviceCode => {
            const cabinet = cabinets.find(c => c.deviceCode === deviceCode);
            if (!cabinet) return;
            const cabinetLocation = locations.find(loc => loc.id === cabinet.locationId);

            const cabinetSchema = schemaDatas.find(data => data.deviceCode === deviceCode)?.refillRule;
            const cabinetSchamaProductData = cabinetSchema ? (cabinetSchema.productData ? cabinetSchema.productData : []) : [];

            const refillProducts = activePlans[deviceCode].productData || [];
            let mappedProductData = refillProducts.map(product => {
                const targetInvAmount = cabinetSchamaProductData.find(
                    schema => _13digitBarcode(schema.barcodeTye, schema.barcode) === _13digitBarcode(product.barcodeTye, product.barcode)
                )?.targetInvAmount;
                return {
                    ...product,
                    targetInvAmount,
                };
            });

            mappedRefillPlans.push({
                ...activePlans[deviceCode],
                productData: mappedProductData,
                deviceCode,
                location: cabinet.locationId ? cabinetLocation?.name : 'noLocation',
                deviceName: cabinet.name,
            });
        });

        setAllActiveRefillPlans(mappedRefillPlans);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [refillPlans, allRefillRules]);

    const refetchRefillPlans = () => {
        setAddNewRefillPlansView(false);
        setRefillSuggestionsView(false);
        fetchRefillPlans();
    };

    return (
        <div className="activeplans">
            {(() => {
                if (addNewRefillPlansView) {
                    return (
                        <AddRefillPlans
                            allActiveRefillPlans={allActiveRefillPlans}
                            allRefillSchemas={allRefillRules}
                            cabinets={cabinets}
                            locations={locations}
                            generateRefillPlanSuggestions={deviceCodes => {
                                setAddNewRefillPlansView(false);
                                setRefillSuggestionsView(true);
                                refillPlanCabinetSuggestions(deviceCodes);
                            }}
                            refillPlans={refillPlans}
                            cancelAddNewNewPlans={() => setAddNewRefillPlansView(false)}
                            t={t}
                            isAdmin={isAdmin}
                        />
                    );
                }

                if (refillSuggestionsView) {
                    return (
                        <RefillSuggestions
                            refillPlans={refillPlans}
                            locations={locations}
                            cabinets={cabinets}
                            allProducts={allProducts}
                            cancelRefillSuggestionView={() => setRefillSuggestionsView(false)}
                            createRefillPlan={createRefillPlan}
                            refetchRefillPlans={() => refetchRefillPlans()}
                            t={t}
                            getTransactions={getTransactions}
                            cabinetTransactionReport={cabinetTransactionReport}
                            fetchCabinetInventory={fetchCabinetInventory}
                            cabinetInventory={cabinetInventory}
                            isAdmin={isAdmin}
                        />
                    );
                }

                //if no active refill plan
                if (!Object.keys(allActiveRefillPlans).length) {
                    return (
                        <div className="activeplans__empty">
                            <InfoBanner message={t('noRefillPlan')} />
                            {isAdmin && <Button type="add" text={t('addNewRefillPlan')} onClick={() => setAddNewRefillPlansView(true)} />}
                        </div>
                    );
                }

                //render all the active plans
                return (
                    <ActivePlanData
                        allActiveRefillPlans={allActiveRefillPlans}
                        updateRefillPlan={updateRefillPlan}
                        fetchRefillPlans={fetchRefillPlans}
                        refillPlans={refillPlans}
                        fetchCabinetInventory={fetchCabinetInventory}
                        cabinetInventory={cabinetInventory}
                        getTransactions={getTransactions}
                        cabinetTransactionReport={cabinetTransactionReport}
                        deleteRefillPlan={deleteRefillPlan}
                        organization={organization}
                        t={t}
                        addNewPlans={() => setAddNewRefillPlansView(true)}
                        allProducts={allProducts}
                        toggleViewMode={mode => setActiveViewMode(mode)}
                        activeViewMode={activeViewMode}
                        allRefillSchemas={allRefillRules}
                        uploadPlanogramImage={uploadPlanogramImage}
                        planogramImageInStore={planogramImageInStore}
                        isAdmin={isAdmin}
                    />
                );
            })()}
        </div>
    );
};

export default ActivePlans;
