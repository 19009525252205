import React from 'react';
import { Button, Popover } from '@storaensods/seeds-react';
import { Row, Col } from 'reactstrap';
import { CSVLink } from 'react-csv';
import moment from 'moment';
import zipcelx from 'zipcelx';

const ProductsExport = ({ t, products, generateExcel, generateCSV, exportHeaders }) => (
    <Popover
        component={
            <Button className="catalog-top-row-element" icon="file_download" type="secondary">
                {t('reports:export')}
            </Button>
        }
        isFlipping
        position="bottom"
    >
        <Row>
            <Col>
                <Button
                    onClick={() => {
                        zipcelx({
                            ...generateExcel(products),
                            filename: `Products_catalog_${moment().format('YYYY-MM-DD')}`,
                        });
                    }}
                >
                    Excel
                </Button>
            </Col>
            <Col>
                <CSVLink
                    className="export-csv se-btn se-btn--md se-btn--primary"
                    data={generateCSV(products)}
                    headers={exportHeaders}
                    target="_blank"
                    filename={`Products_catalog_${moment().format('YYYY-MM-DD')}.csv`}
                >
                    CSV
                </CSVLink>
            </Col>
        </Row>
    </Popover>
);

export default ProductsExport;
