import zipcelx from 'zipcelx';

function getColumnHeadersFromCubeAnnotation(annotation, granularityIsSet = false, timeDimensions) {
    const excelColumns = [];
    const columns = [];

    Object.keys(annotation.dimensions).forEach(dimension => {
        excelColumns.push({
            value: annotation.dimensions[dimension].title,
            type: 'string',
        });

        if (annotation.dimensions[dimension].type === 'number') {
            columns.push({
                key: dimension,
                type: 'number',
            });
        } else {
            columns.push({
                key: dimension,
                type: 'string',
            });
        }
    });

    if (granularityIsSet) {
        columns.push({
            key: timeDimensions ? `${timeDimensions}` : 'Transactions.timestamp',
            type: 'string',
        });

        excelColumns.push({
            value: 'Timestamp',
            type: 'string',
        });
    }

    Object.keys(annotation.measures).forEach(dimension => {
        excelColumns.push({
            value: annotation.measures[dimension].shortTitle,
            type: 'string',
        });

        if (annotation.measures[dimension].type === 'number') {
            columns.push({
                key: typeof dimension === 'number' ? dimension.toFixed(2).replace(/[.,]00$/, '') : dimension,
                type: 'number',
            });
        } else {
            columns.push({
                key: dimension,
                type: 'string',
            });
        }
    });

    return {
        excelColumns,
        columns,
    };
}

function formatCubeDataForExcel(columns, data) {
    return data.map(entry =>
        columns.map(column => ({
            value: entry[column.key],
            type: column.type,
        }))
    );
}

// Return preDefined cube Response columns

function getColumnsheadersFromPreDefinedResponse(data) {
    let excelColumns = [];
    let columns = [];
    let combinedData = [];

    Object.keys(data).forEach(key => {
        let array = data[key];
        combinedData = combinedData.concat(array);
    });

    columns = combinedData.map(d => {
        excelColumns = [];
        let outArr = [];
        Object.keys(d).map(key => {
            let val = d[key];
            if (key !== 'Image') {
                excelColumns.push({ value: key, type: 'string' });
                outArr.push({ value: val, type: typeof val });
            }
            return null;
        });
        return outArr;
    });

    return {
        excelColumns,
        columns,
    };
}

export function exportExcelDataFromPredefinedInventory(filename, cubeData) {
    const now = new Date();
    const fullFilename = `${filename}_${now.getFullYear()}_${now.getMonth() + 1}_${now.getDate()}_${now.getHours()}_${now.getMinutes()}`;
    const { excelColumns, columns } = getColumnsheadersFromPreDefinedResponse(cubeData);

    const excelData = [excelColumns, ...columns];
    zipcelx({
        filename: fullFilename,
        sheet: {
            data: excelData,
        },
    });
}

export function exportExcelDataFromCubeResponse(filename, cubeResponse) {
    const now = new Date();
    const fullFilename = `${filename}_${now.getFullYear()}_${now.getMonth() + 1}_${now.getDate()}_${now.getHours()}_${now.getMinutes()}`;
    const granularityIsSet = cubeResponse.query.timeDimensions[0].granularity ? true : false;
    const { excelColumns, columns } = getColumnHeadersFromCubeAnnotation(
        cubeResponse.annotation,
        granularityIsSet,
        cubeResponse.query.timeDimensions[0].dimension
    );
    const excelData = [excelColumns, ...formatCubeDataForExcel(columns, cubeResponse.data)];
    zipcelx({
        filename: fullFilename,
        sheet: {
            data: excelData,
        },
    });
}
