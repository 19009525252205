import React, { Component } from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { Button, Spinner, Modal } from '@storaensods/seeds-react';
import ImageUpload from '../imageUpload/imageUpload.js';
import { uploadLogoImage, removeLogoImage, updateSelectedCabinetConfiguration } from '../../actions/controlPanel.js';

export class LogoSetting extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isEditMode: false,
            cropping: false,
        };
    }

    editLogo = () => {
        this.props.selectedCabinet.imageUrl = null;
        removeLogoImage();
        this.setState({
            isEditMode: true,
        });
    };

    stopEditing = () => {
        this.setState({
            isEditMode: false,
        });
    };

    /**
     * Toggle currently cropping to disable submitting while cropping
     */
    toggleCropping = () => {
        this.setState({
            cropping: !this.state.cropping,
        });
    };

    useLogo = () => {
        const { selectedCabinet, updateSelectedCabinetConfiguration } = this.props;
        const imageUrl = selectedCabinet.imageUrl ? selectedCabinet.imageUrl : null;
        updateSelectedCabinetConfiguration({ [this.props.logo]: imageUrl });
        this.stopEditing();
    };

    render() {
        const { settings, selectedCabinet, uploadLogoImage, removeLogoImage, t } = this.props;
        const { isEditMode } = this.state;

        if (selectedCabinet.fetchingSettings) {
            return (
                <div>
                    <Spinner />
                </div>
            );
        }

        if (selectedCabinet.settingsError) {
            return <div>{t('errorFetchingSettings')}</div>;
        }

        if (isEditMode) {
            const targetWidth = this.props.targetWidth || parseInt(process.env.REACT_APP_COMPANY_LOGO_IMAGE_WIDTH) || 100;
            const targetHeight = this.props.targetHeight || parseInt(process.env.REACT_APP_COMPANY_LOGO_IMAGE_HEIGHT) || 100;

            return (
                <Modal title={t('logoEditor')} active={true} onClose={this.stopEditing}>
                    <div className="logoEditContainer d-flex mb-2 justify-content-between">
                        <ImageUpload
                            className="se-input-container"
                            freeAspectRatio={this.props.freeAspectRatio}
                            targetWidth={targetWidth}
                            targetHeight={targetHeight}
                            imageUrl={selectedCabinet.imageUrl || ''}
                            storing={selectedCabinet.imageStoring || false}
                            uploadImage={uploadLogoImage}
                            removeImage={removeLogoImage}
                            toggleCropping={this.toggleCropping}
                            t={t}
                        />
                        <div className="d-flex justify-content-end">
                            {selectedCabinet.imageUrl && selectedCabinet.imageUrl !== '' ? (
                                <Button onClick={this.useLogo} className="mr-2">
                                    {t('use')}
                                </Button>
                            ) : null}
                            <Button onClick={this.stopEditing}>{t('cancel')}</Button>
                        </div>
                    </div>
                </Modal>
            );
        }

        return (
            <div className="mt-3 w-100 d-flex align-items-center mb-2 justify-content-between">
                <div className="ml-3 d-flex">
                    {settings && settings[this.props.logo] ? (
                        <img src={settings[this.props.logo]} className="logoImage" alt="logo" />
                    ) : (
                        <div>{t('noLogo')}</div>
                    )}
                </div>
                <div className="d-flex">
                    <Button className="se-btn se-btn--sm se-btn--secondary ml-3" icon="edit" onClick={this.editLogo} />
                    {settings && settings[this.props.logo] && settings[this.props.logo] !== '' && (
                        <Button
                            className="se-btn se-btn--sm se-btn--secondary ml-3"
                            icon="delete"
                            onClick={() => {
                                selectedCabinet.imageUrl = null;
                                this.useLogo();
                            }}
                        />
                    )}
                </div>
            </div>
        );
    }
}

export default connect(
    state => ({
        settings: state.controlPanel.selectedCabinet.settings,
        selectedCabinet: state.controlPanel.selectedCabinet,
    }),
    {
        uploadLogoImage,
        removeLogoImage,
        updateSelectedCabinetConfiguration,
    }
)(translate('main')(LogoSetting));
