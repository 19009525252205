import React, { useEffect, useState } from 'react';
import { Row } from 'reactstrap';
import { connect } from 'react-redux';
import { analyticsSearchQuery } from './../../../actions/analytics';

const DiscountDetails = props => {
    const { detailType, discountProducts, searchQuery, analyticsSearchQuery, activeRow, t } = props;
    const { locations, cabinets, products } = discountProducts;
    const [tableData, setTableData] = useState({ headers: [], data: [] });

    useEffect(() => {
        switch (detailType) {
            case 'products': {
                const keys = Object.keys(products);
                const productData = [];
                keys.forEach(key => {
                    if (key.toLowerCase().indexOf(searchQuery.toLowerCase()) === -1) {
                        setTableData({
                            headers: tableData.headers,
                            data: [],
                        });

                        return;
                    }

                    const productName = key;
                    let imageUrl,
                        barcode,
                        currency,
                        discountSalesCount = 0,
                        discountPrice = 0,
                        originalPrice = 0,
                        normalSalesAmount = 0,
                        discountSalesAmount = 0,
                        discountAmountType = null;

                    products[key].forEach((product, index) => {
                        discountAmountType = 'percentage';
                        let originalUnitPrice = 0;
                        if (product['Transactions.discountAmountType']) {
                            discountAmountType = product['Transactions.discountAmountType'];
                        }

                        if (!product['Transactions.originalPrice']) {
                            if (discountAmountType === 'percentage') {
                                originalUnitPrice = (100 * product['Transactions.oneUnitPrice']) / (100 - product['Transactions.discountAmount']);
                            }
                        } else {
                            originalUnitPrice = product['Transactions.originalPrice'];
                        }
                        // handle divide by 0 case
                        originalUnitPrice = isFinite(originalUnitPrice) ? originalUnitPrice : 0;

                        if (index === 0) {
                            imageUrl = product['Transactions.imageUrl'];
                            barcode = product['Transactions.barcode'];
                            discountPrice = parseFloat(product['Transactions.oneUnitPrice']);
                            originalPrice = parseFloat(originalUnitPrice);
                            currency = product['Transactions.currency'];
                        }
                        discountSalesCount = discountSalesCount + product['Transactions.soldCount'];
                        normalSalesAmount = parseFloat(normalSalesAmount) + product['Transactions.soldCount'] * parseFloat(originalUnitPrice);
                        discountSalesAmount =
                            parseFloat(discountSalesAmount) + product['Transactions.soldCount'] * parseFloat(product['Transactions.oneUnitPrice']);
                    });
                    productData.push({
                        imageUrl,
                        productName,
                        barcode,
                        discountSalesCount,
                        originalPrice: currency + originalPrice.toFixed(2),
                        discountPrice: currency + discountPrice.toFixed(2),
                        /* normalSalesAmount: normalSalesAmount.toFixed(2) + currency, HIDE FOR NOW*/
                        discountSalesAmount: currency + discountSalesAmount.toFixed(2),
                    });
                });

                setTableData({
                    headers: [
                        t('image'),
                        t('name'),
                        t('barcode'),
                        t('salesCount'),
                        t('normalPrice'),
                        t('discountPrice'),
                        /* t('normalSalesAmount'), HIDE FOR NOW*/
                        t('discountSalesAmount'),
                    ],
                    data: productData,
                });

                break;
            }
            case 'cabinets':
            case 'locations': {
                // set if cabinet or location type is requested
                const getData = detailType === 'cabinets' ? cabinets : locations;
                const data = [];
                Object.keys(getData).forEach(key => {
                    if (key.toLowerCase().indexOf(searchQuery.toLowerCase()) === -1) {
                        setTableData({
                            headers: tableData.headers,
                            data: [],
                        });
                        return;
                    }

                    const name = key;
                    let discountSalesCount = 0,
                        currency,
                        normalSalesAmount = 0,
                        discountSalesAmount = 0,
                        discountAmountType = null;

                    getData[key].forEach((d, index) => {
                        discountAmountType = 'percentage';
                        let originalUnitPrice = 0;
                        if (d['Transactions.discountAmountType']) {
                            discountAmountType = d['Transactions.discountAmountType'];
                        }

                        if (!d['Transactions.originalPrice']) {
                            if (discountAmountType === 'percentage') {
                                originalUnitPrice = (100 * d['Transactions.oneUnitPrice']) / (100 - d['Transactions.discountAmount']);
                            }
                        } else {
                            originalUnitPrice = d['Transactions.originalPrice'];
                        }

                        // handle divide by 0 case
                        originalUnitPrice = isFinite(originalUnitPrice) ? originalUnitPrice : 0;

                        if (index === 0) {
                            currency = d['Transactions.currency'];
                        }
                        discountSalesCount = discountSalesCount + d['Transactions.soldCount'];
                        normalSalesAmount = parseFloat(normalSalesAmount) + parseFloat(d['Transactions.soldCount'] * originalUnitPrice);
                        discountSalesAmount = parseFloat(discountSalesAmount) + d['Transactions.soldCount'] * parseFloat(d['Transactions.oneUnitPrice']);
                    });

                    data.push({
                        name,
                        discountSalesCount,
                        /* normalSalesAmount: normalSalesAmount.toFixed(2) + currency, HIDE FOR NOW*/
                        discountSalesAmount: currency + discountSalesAmount.toFixed(2),
                    });
                });

                setTableData({
                    headers: [
                        detailType === 'cabinets' ? t('cabinet') : t('location'),
                        t('salesCount'),
                        /* t('normalSalesAmount'), HIDE FOR NOW*/
                        t('discountSalesAmount'),
                    ],
                    data,
                });
                break;
            }
            default:
                break;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [detailType, searchQuery, t]);

    useEffect(() => {
        analyticsSearchQuery('');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeRow]);

    return (
        <Row className="ml-4 mr-4 mb-4">
            <table className="se-table discount-detail-table">
                <thead className="se-table-head">
                    <tr>
                        {tableData.headers.map((header, index) => (
                            <th style={{ paddingLeft: index === 0 ? '15px' : '0px' }} key={header + index}>
                                <div className="se-table-head-content">{header}</div>
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {tableData.data.map(data => (
                        <tr className="se-table-item" key={data.barcode}>
                            {Object.keys(data).map((key, index) => {
                                if (key === 'imageUrl' && detailType === 'products') {
                                    return (
                                        <td className="discount-image" key={data.imageUrl}>
                                            <img src={data['imageUrl']} alt="" />
                                        </td>
                                    );
                                } else {
                                    if (index + 1 === Object.keys(data).length) {
                                        return (
                                            <td key={data[key] + key}>
                                                <span className="discount-table-balance">{data[key]}</span>
                                            </td>
                                        );
                                    } else if (index === 0) {
                                        return (
                                            <td style={{ paddingLeft: '15px' }} key={data[key] + key}>
                                                {data[key]}
                                            </td>
                                        );
                                    } else {
                                        return <td key={data[key] + key}>{data[key]} </td>;
                                    }
                                }
                            })}
                        </tr>
                    ))}
                </tbody>
            </table>
        </Row>
    );
};

export default connect(
    state => ({
        searchQuery: state.analytics.chart.searchQuery,
    }),
    {
        analyticsSearchQuery,
    }
)(DiscountDetails);
