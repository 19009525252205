import React from 'react';
import icons from './../../../icons/icomoon.svg';

const Loader = ({ message }) => {
    return (
        <div className="refillplans__status">
            <svg>
                <use xlinkHref={`${icons}#icon-spinner7`}></use>
            </svg>
            <div className="refillplans__status-message">{message}</div>
        </div>
    );
};

export default Loader;
