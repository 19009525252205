import { v1 as uuidv1 } from 'uuid';

import {
    fetchProducts as fetchProductsHttpRequest,
    createProduct as createProductHttpRequest,
    updateProduct as updateProductHttpRequest,
    createProductImage as createProductImageHttpRequest,
    archiveProduct as archiveProductHttpRequest,
} from '../api.js';

export const REQUEST_PRODUCTS = 'REQUEST_PRODUCTS';
export const RECEIVE_PRODUCTS = 'RECEIVE_PRODUCTS';
export const RECEIVE_PRODUCTS_ERROR = 'RECEIVE_PRODUCTS_ERROR';
export const REQUEST_CREATE_PRODUCT = 'REQUEST_CREATE_PRODUCT';
export const REQUEST_ARCHIVE_PRODUCT = 'REQUEST_ARCHIVE_PRODUCT';
export const START_DELETE_PRODUCT = 'START_DELETE_PRODUCT';
export const DELETE_PRODUCT_SUCCESS = 'DELETE_PRODUCT_SUCCESS';
export const DELETE_PRODUCT_ERROR = 'DELETE_PRODUCT_ERROR';
export const RECEIVE_CREATE_PRODUCT = 'RECEIVE_CREATE_PRODUCT';
export const RECEIVE_CREATE_PRODUCT_ERROR = 'RECEIVE_CREATE_PRODUCT_ERROR';
export const DISMISS_CREATE_PRODUCT_RESULT = 'DISMISS_CREATE_PRODUCT_RESULT';
export const REQUEST_UPDATE_PRODUCT = 'REQUEST_UPDATE_PRODUCT';
export const RECEIVE_UPDATE_PRODUCT = 'RECEIVE_UPDATE_PRODUCT';
export const RECEIVE_UPDATE_PRODUCT_ERROR = 'RECEIVE_UPDATE_PRODUCT_ERROR';
export const DISMISS_UPDATE_PRODUCT_RESULT = 'DISMISS_UPDATE_PRODUCT_RESULT';
export const REQUEST_UPLOAD_PRODUCT_IMAGE = 'REQUEST_UPLOAD_PRODUCT_IMAGE';
export const UPLOAD_PRODUCT_IMAGE_SUCCESS = 'UPLOAD_PRODUCT_IMAGE_SUCCESS';
export const UPLOAD_PRODUCT_IMAGE_ERROR = 'UPLOAD_PRODUCT_IMAGE_ERROR';
export const REMOVE_PRODUCT_IMAGE = 'REMOVE_PRODUCT_IMAGE';
export const RESET_PRODUCTS = 'RESET_PRODUCTS';
export const SEARCH_PRODUCT_QUERY = 'SEARCH_PRODUCT_QUERY';

/**
 * Request products Redux action creator
 */
function requestProducts() {
    return {
        type: REQUEST_PRODUCTS,
    };
}

/**
 * Receive products Redux action creator
 * @param {array} products
 */
function receiveProducts(products) {
    // Remove padded 0's
    products.forEach(product => {
        product.barcode = removeBarcodeFormatting(product);
        if (product.barcodeType === null) product.barcodeType = 'EAN-13';
    });

    return {
        type: RECEIVE_PRODUCTS,
        products,
        receivedAt: Date.now(),
    };
}

/**
 * Receive products error Redux action creator
 * @param {Error} error
 */
function receiveProductsError(error) {
    return {
        type: RECEIVE_PRODUCTS_ERROR,
        error: error,
    };
}

/**
 * Request create product Redux action creator
 */
function requestCreateProduct() {
    return {
        type: REQUEST_CREATE_PRODUCT,
    };
}

/**
 * Request for product removal redux action creator
 */
function requestArchiveProduct(removing) {
    return {
        type: REQUEST_ARCHIVE_PRODUCT,
        removingProduct: removing,
    };
}

/**
 * Delete product request started
 */
function startDeleteProduct() {
    return {
        type: START_DELETE_PRODUCT,
        isDeleting: true,
    };
}

/**
 * Deleting product is successful
 */
function deleteProductSuccess() {
    return {
        type: DELETE_PRODUCT_SUCCESS,
        deleted: true,
    };
}

/**
 * Delete product has error
 */
function deleteProductError(error) {
    return {
        type: DELETE_PRODUCT_ERROR,
        isError: error,
    };
}

/**
 * Receive create product success response Redux action creator
 */
function receiveCreateProduct(product) {
    // Remove padded 0's
    product[0].barcode = removeBarcodeFormatting(product[0]);

    return {
        type: RECEIVE_CREATE_PRODUCT,
        product,
        receivedAt: Date.now(),
    };
}

/**
 * Receive create product error Redux action creator
 * @param {Error} error
 */
function receiveCreateProductError(error) {
    return {
        type: RECEIVE_CREATE_PRODUCT_ERROR,
        error,
    };
}

/**
 * Dismiss create product result Redux action creator
 */
export function dismissCreateProductResult() {
    return {
        type: DISMISS_CREATE_PRODUCT_RESULT,
    };
}

/**
 * Request update product Redux action creator
 */
function requestUpdateProduct(product) {
    return {
        type: REQUEST_UPDATE_PRODUCT,
        product,
    };
}

/**
 * Receive update product success response Redux action creator
 */
function receiveUpdateProduct() {
    return {
        type: RECEIVE_UPDATE_PRODUCT,
        receivedAt: Date.now(),
    };
}

/**
 * Receive update product error Redux action creator
 * @param {Error} error
 */
function receiveUpdateProductError(error) {
    return {
        type: RECEIVE_UPDATE_PRODUCT_ERROR,
        error,
    };
}

/**
 * Dismiss update product result Redux action creator
 */
export function dismissUpdateProductResult() {
    return {
        type: DISMISS_UPDATE_PRODUCT_RESULT,
    };
}

/**
 * Request create product image Redux action creator
 */
function requestUpdateProductImage() {
    return {
        type: REQUEST_UPLOAD_PRODUCT_IMAGE,
    };
}

/**
 * Receive create product image success response Redux action creator
 */
export function uploadProductImageSuccess(url) {
    return {
        type: UPLOAD_PRODUCT_IMAGE_SUCCESS,
        url,
        receivedAt: Date.now(),
    };
}

/**
 * Receive create product image error Redux action creator
 * @param {Error} error
 */
function uploadProductImageError(error) {
    return {
        type: UPLOAD_PRODUCT_IMAGE_ERROR,
        error,
    };
}

/**
 * Resets products state to initial state
 */
function resetProducts() {
    return {
        type: RESET_PRODUCTS,
    };
}

/**
 * Used to remove image from form of product being updated/created
 */
export function removeProductImage() {
    return {
        type: REMOVE_PRODUCT_IMAGE,
    };
}

/**
 * Thunk action creator for refreshing products state
 */
export function refreshProducts() {
    return function(dispatch) {
        dispatch(resetProducts());
        dispatch(fetchProducts());
    };
}

/**
 * Thunk action creator for fetching products
 */
export function fetchProducts() {
    return function(dispatch, getState) {
        // update state to inform API call started
        dispatch(requestProducts());

        // fetch products then update state
        const user = getState().user;
        return fetchProductsHttpRequest(user.accessToken, user.group)
            .then(response => dispatch(receiveProducts(response)))
            .catch(error => dispatch(receiveProductsError(error)));
    };
}

/**
 * Thunk action creator for creating new product
 * @param {Object} productIn
 */
export function createProduct(productIn) {
    // remove empty keys because null and empty strings aren't allowed for some optional fields in backend
    const product = Object.assign({}, productIn, {
        barcode: create13DigitBarcode(productIn),
        vatCategoryId: productIn.vatCategoryId ? productIn.vatCategoryId : null,
        productCategoryId: productIn.productCategoryId ? productIn.productCategoryId : null,
        supplierId: productIn.supplier ? productIn.supplier.id : null,
        productType: productIn.productType ? productIn.productType : null,
        productClass: productIn.productClass && Array.isArray(productIn.productClass) ? productIn.productClass : [0, 0, 0],
        labels: productIn.labels && Array.isArray(productIn.labels) ? productIn.labels : null,
        vatCategory: null,
        productCategory: null,
        supplier: null,
        tagSensitivity : productIn.tagSensitivity
    });

    Object.keys(product).forEach(key => (product[key] == null || product[key] === '') && delete product[key]);

    return (dispatch, getState) => {
        // update state to inform API call started
        dispatch(requestCreateProduct());

        // fetch products then update state
        const user = getState().user;

        return createProductHttpRequest(product, user.accessToken, user.group)
            .then(product => dispatch(receiveCreateProduct(product)))
            .catch(error => dispatch(receiveCreateProductError(error)));
    };
}

/**
 * Thunk action creator for updating existing product
 * @param {Object} productIn
 */
export function updateProduct(productIn) {
    const product = {
        name: productIn.name,
        barcodeType: productIn.barcodeType,
        currency: productIn.currency,
        price: parseFloat(productIn.price),
        locationPricings: productIn.locationPricings,
        vatCategoryId: productIn.vatCategoryId ? productIn.vatCategoryId : null,
        timeToLiveDays: productIn.timeToLiveDays || productIn.timeToLiveDays === 0 ? Number(productIn.timeToLiveDays) : null,
        isPrebooked: productIn.isPrebooked,
        isFrozen: productIn.isFrozen,
        bannerText: productIn.bannerText,
        productClass: productIn.productClass && Array.isArray(productIn.productClass) ? productIn.productClass : [0, 0, 0],
        labels: productIn.labels && Array.isArray(productIn.labels) ? productIn.labels : null, 
        tagSensitivity : productIn.tagSensitivity
    };

    if (productIn.productCategoryId) {
        product.productCategoryId = productIn.productCategoryId;
    }

    if (productIn.supplierId) {
        product.supplierId = productIn.supplierId;
    }

    if (productIn.productType) {
        product.productType = productIn.productType;
    }

    if (productIn.imageUrl) {
        product.imageUrl = productIn.imageUrl;
    }

    if (productIn.description) {
        product.description = productIn.description;
    }
    if (productIn.bannerText) {
        product.bannerText = productIn.bannerText;
    }

    productIn.price = parseFloat(productIn.price);

    const formattedBarcode = create13DigitBarcode(productIn);

    return (dispatch, getState) => {
        // update state to inform API call started
        dispatch(
            requestUpdateProduct(
                Object.assign({}, productIn, {
                    prices: [{ locationId: null, price: productIn.price, currency: productIn.currency }, ...productIn.locationPricings],
                })
            )
        );

        // fetch products then update state
        const user = getState().user;
        return updateProductHttpRequest(formattedBarcode, product, user.accessToken, user.group)
            .then(() => dispatch(receiveUpdateProduct()))
            .catch(error => dispatch(receiveUpdateProductError(error)));
    };
}

export function archiveProduct(product) {
    const formattedBarcode = create13DigitBarcode(product);

    return (dispatch, getState) => {
        let removing = true;
        dispatch(requestArchiveProduct(removing));
        const user = getState().user;

        return archiveProductHttpRequest(formattedBarcode, product, user.accessToken, user.group)
            .then(() => {
                removing = false;
                dispatch(requestArchiveProduct(removing));
            })
            .catch(error => {
                console.error(error);
                removing = false;
                dispatch(requestArchiveProduct(removing));
            });
    };
}

/**
 * delete the product by barcode from database
 * @param {Object} product
 */
export function deleteProduct(product) {
    const formattedBarcode = create13DigitBarcode(product);

    return (dispatch, getState) => {
        dispatch(startDeleteProduct());

        const user = getState().user;

        return archiveProductHttpRequest(formattedBarcode, product, user.accessToken, user.group)
            .then(() => dispatch(deleteProductSuccess()))
            .catch(error => dispatch(deleteProductError(error)));
    };
}

/**
 *
 * @param {File} imageFile
 */
export function uploadProductImage(imageFile) {
    return (dispatch, getState) => {
        // update state to inform API call started
        dispatch(requestUpdateProductImage());

        // convert image file to HTTP form data with unique filename
        const formData = new FormData();
        formData.append(0, imageFile, `${uuidv1()}.jpeg`);

        // get user
        const user = getState().user;
        const accessKey = user.accessToken;
        // send the group Id
        const group = user.group.Id;

        // store image in backend
        return createProductImageHttpRequest(formData, accessKey, group)
            .then(response => dispatch(uploadProductImageSuccess(encodeURI(response.url))))
            .catch(error => dispatch(uploadProductImageError(error)));
    };
}

export function productSearchQuery(query) {
    return dispatch => {
        dispatch({
            type: SEARCH_PRODUCT_QUERY,
            query,
        });
    };
}

/**
 * Add 0's to the beginning of a barcode to make all barcodes 13 digits
 * @param {Object} product
 * @return {String} Returns a 13-digit barcode
 */
function create13DigitBarcode(product) {
    switch (product.barcodeType) {
        case 'EAN-13':
            return product.barcode;
        case 'EAN-8':
            return '00000' + product.barcode;
        case 'UPC-A':
            return '0' + product.barcode;
        default:
            return product.barcode; // To be backwards compatible, products that do not have barcode types are treated as having a EAN-13 barcode type
    }
}

/**
 * Removes the formatting of barcodes so that they are best displayed for users. This
 * formatting is the zeros at the beginning of a barcode used to make a barcodes 13 digits
 * when it would otherwise be shorter.
 * @param {Object} product
 * @return {String} Returns a barcode with its original number of digits
 */
function removeBarcodeFormatting(product) {
    switch (product.barcodeType) {
        case 'EAN-13':
            return product.barcode;
        case 'EAN-8':
            return product.barcode.substr(5);
        case 'UPC-A':
            return product.barcode.substr(1);
        default:
            return product.barcode; // To be backwards compatible, products that do not have barcode types are treated as having a EAN-13 barcode type
    }
}
