import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { loadingBarReducer } from 'react-redux-loading-bar';
import products from './products';
import cabinets from './cabinets';
import controlPanel from './controlPanel';
import inventory from './inventory';
import advertisements from './advertisements';
import report from './report';
import user from './user';
import productTypes from './productTypes';
import replenishments from './replenishments';
import alarmIcon from './AlarmIcon';
import analytics from './analytics';
import vatCategories from './vatCategories';
import productCategories from './productCategories';
import productLabels from './productLabels';
import suppliers from './suppliers';
import discounts from './discounts.js';
import organizationsSettings from './organizations';
import encodingDevices from './encodingDevices';
import releaseVersions from './releaseVersions';
import refillRules from './refillRules';
import refillPlans from './refillPlans';
import theming from './theming.js';
import alarms from './cabinetAlarms.js';
import automations from './automations.js';

export default history =>
    combineReducers({
        router: connectRouter(history),
        loadingBar: loadingBarReducer,
        user,
        products,
        cabinets,
        encodingDevices,
        controlPanel,
        inventory,
        advertisements,
        report,
        productTypes,
        replenishments,
        alarmIcon,
        analytics,
        vatCategories,
        productCategories,
        productLabels,
        suppliers,
        discounts,
        organizationsSettings,
        releaseVersions,
        refillRules,
        refillPlans,
        theming,
        alarms,
        automations,
    });
