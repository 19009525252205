import React from 'react';

import './notAccessibleLabel.css';

function NotAccessibleLabel(props) {
    const { message, customStyle } = props;

    return (
        <div className={`not-accessible ${customStyle}`}>
            <label className="not-accessible-label">{`${message}`}</label>
        </div>
    );
}

export default NotAccessibleLabel;
