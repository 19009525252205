import React, { useState, useEffect } from 'react';
import { Button, Modal } from '@storaensods/seeds-react';
import { SettingsCopyModal } from './settingsCopyModal';
import AdminSettingItem from './adminSettingItem';
import './cabinetSettings.css';

export function AdminSettings(props) {
    const { selectedCabinetSettings, updateSelectedCabinetConfiguration, t, isRoot, cabinets, locations, title, submitSettingsCopy } = props;

    const [updatedSettings, setUpdatedSettings] = useState({});
    const [newSettingKey, setNewSettingKey] = useState('');
    const [newSettingValue, setNewSettingValue] = useState('');
    const [confirmModalOpen, setConfirmModalOpen] = useState(false);
    const [copySettingsOpen, setCopySettingsOpen] = useState(false);

    useEffect(() => {
        setUpdatedSettings({});
        setNewSettingKey('');
        setNewSettingValue('');
    }, [selectedCabinetSettings]);

    let userItems = process.env.REACT_APP_CABINET_SETTINGS_USER_ITEMS || '';
    let skippedItems = process.env.REACT_APP_CABINET_SETTINGS_SKIPPED_ITEMS || '';
    let rootItems = process.env.REACT_APP_CABINET_SETTINGS_ROOT_ITEMS || '';

    // convert from string "['detectTagQuality','ignoreBadTags','logo','payment_method_logo']"
    // to array ['detectTagQuality','ignoreBadTags','logo','payment_method_logo']
    try {
        skippedItems = JSON.parse(skippedItems.replace(/'/g, '"'));
        userItems = JSON.parse(userItems.replace(/'/g, '"'));
        rootItems = JSON.parse(rootItems.replace(/'/g, '"'));
    } catch (e) {
        skippedItems = [];
        userItems = [];
        rootItems = [];
    }

    if (!isRoot) {
        skippedItems = skippedItems.concat(rootItems);
    }

    const updateSetting = (userItems = []) => {
        const toBeUpdated = {};
        let needsUpdate = false;

        Object.keys(updatedSettings).forEach(key => {
            if (userItems.includes(key)) {
                return;
            }

            const setting = updatedSettings[key];
            // if a setting is new and also marked to be deleted, it is not added to the settings list to be updated.
            if (
                !(setting.deleted && setting.new && !selectedCabinetSettings[key]) &&
                (setting.deleted ||
                    setting.new ||
                    (typeof setting.value !== 'undefined' && setting.value !== null && setting.value !== selectedCabinetSettings[key]))
            ) {
                toBeUpdated[key] = setting.deleted ? null : typeof setting.value === 'string' ? setting.value.trim() : setting.value;
                needsUpdate = true;
            }
        });

        setConfirmModalOpen(false);

        if (needsUpdate) {
            updateSelectedCabinetConfiguration(toBeUpdated);
            setUpdatedSettings({});
            setNewSettingKey('');
            setNewSettingValue('');
        }
    };

    const addNewSetting = () => {
        if (newSettingKey.trim().length === 0 || newSettingValue.trim().length === 0) {
            return;
        }

        updatedSettings[newSettingKey.trim().replace(/\s/g, '_')] = {
            value:
                ['true', 'false'].indexOf(newSettingValue.toLowerCase()) >= 0
                    ? // eslint-disable-next-line
                      newSettingValue == 'true'
                    : newSettingValue,
            new: true,
        };

        setUpdatedSettings(updatedSettings);
        setNewSettingKey('');
        setNewSettingValue('');
    };

    const renderCreateSetting = () => {
        return (
            <div className="d-flex align-items-center mb-2">
                {/* Key editor */}
                <div className="se-input-container">
                    <input
                        type="text"
                        placeholder="Input text"
                        className="se-input se-input--md setting-label"
                        value={newSettingKey}
                        onChange={event => {
                            setNewSettingKey(event.target.value);
                        }}
                    />
                </div>

                {/* Value editor */}
                <div className="se-input-container ml-3">
                    <input
                        type="text"
                        placeholder="Input text"
                        className="se-input se-input--md setting-value"
                        value={newSettingValue}
                        onChange={event => {
                            setNewSettingValue(event.target.value);
                        }}
                    />
                </div>

                {/* Add button */}
                <button
                    type="button"
                    className="se-btn se-btn--sm se-btn--primary ml-3"
                    onClick={() => {
                        addNewSetting();
                    }}
                >
                    <i className="fas fa-plus" />
                </button>
            </div>
        );
    };

    const getCorrectSettingsForModal = () => {
        const usedSettings = {};

        Object.keys(selectedCabinetSettings)
            .filter(key => !userItems.includes(key) && !skippedItems.includes(key) && !(updatedSettings[key] && updatedSettings[key].new))
            .map(key => (usedSettings[key] = selectedCabinetSettings[key]));

        Object.keys(updatedSettings)
            .filter(key => !skippedItems.includes(key) && updatedSettings[key].new)
            .map(key => (usedSettings[key] = updatedSettings[key].value));

        return usedSettings;
    };

    const updateUpdatedSettings = settings => {
        setUpdatedSettings(settings);
    };

    return (
        <div className="mt-3 mb-4">
            {selectedCabinetSettings &&
                Object.keys(selectedCabinetSettings)
                    .filter(key => !userItems.includes(key) && !skippedItems.includes(key) && !(updatedSettings[key] && updatedSettings[key].new))
                    .map(key => (
                        <AdminSettingItem
                            settingKey={key}
                            settingValue={selectedCabinetSettings[key]}
                            updatedSettings={updatedSettings}
                            updateUpdatedSettings={updateUpdatedSettings}
                        />
                    ))}
            {selectedCabinetSettings &&
                Object.keys(updatedSettings)
                    .filter(key => !skippedItems.includes(key) && updatedSettings[key].new)
                    .map(key => (
                        <AdminSettingItem
                            settingKey={key}
                            settingValue={updatedSettings[key].value}
                            updatedSettings={updatedSettings}
                            updateUpdatedSettings={updateUpdatedSettings}
                        />
                    ))}

            <div>
                {/* Create setting */}
                {renderCreateSetting()}
                {/* / Create setting */}
            </div>

            <Modal active={confirmModalOpen} onClose={() => setConfirmModalOpen(false)}>
                <div className="confirm-model">
                    <h5> {t('main:settingConfirmMessage')}</h5>
                    <div className="confirm-model-buttons">
                        <Button onClick={() => setConfirmModalOpen(false)} size="md">
                            {t('cancel')}
                        </Button>
                        <Button
                            onClick={() => {
                                updateSetting(userItems);
                            }}
                            size="md"
                            type="positive"
                        >
                            {t('confirm')}
                        </Button>
                    </div>
                </div>
            </Modal>

            <div className="d-flex justify-content-between text-right mt-4">
                <Button onClick={() => setCopySettingsOpen(true)}>
                    <i className="fas fa-copy mr-2" />
                    <span>{t('settings:settingsCopyButton')}</span>
                </Button>

                {/* Save button */}
                <Button onClick={() => setConfirmModalOpen(true)}>{t('save')}</Button>
            </div>

            {copySettingsOpen && (
                <SettingsCopyModal
                    closeModal={() => setCopySettingsOpen(!copySettingsOpen)}
                    t={t}
                    submitSettingsCopy={submitSettingsCopy}
                    cabinets={cabinets}
                    locations={locations}
                    settingsType={title}
                    settings={getCorrectSettingsForModal(selectedCabinetSettings)}
                />
            )}
        </div>
    );
}
