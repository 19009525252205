import React, { Component } from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import classNames from 'classnames';
import { Table, Alert, Collapse, Row, Col } from 'reactstrap';
import moment from 'moment';
import { fetchCabinetInventory } from '../../actions/inventory.js';
import { showInventoryErrorAlarmIcon, hideInventoryErrorAlarmIcon } from '../../actions/alarmIcon';
import FetchingAlert from '../fetching/fetchingAlert.js';
import './inventory.css';
import { Tooltip } from 'react-tippy';
/**
 * Cabinet inventory product table row component
 * @param {string} name Product name
 */
class ProductRow extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isUnknownTagsOpen: false,
        };
    }

    formateExpiryDate(date) {
        try {
            const dateObj = new Date(date);
            const month = dateObj.getUTCMonth() + 1; //months from 1-12
            const day = dateObj.getUTCDate();
            const year = dateObj.getUTCFullYear();

            const newDate = year + '-' + month + '-' + day;
            return moment(newDate, 'YYYY-MM-DD')
                .startOf('day')
                .format('LL');
        } catch (e) {
            return null;
        }
    }

    /**
     * Gets the tag's expiry date to display and some additional class attributes
     * @param {*} tag
     */
    getTagExpiryData(tag) {
        if (tag && tag.product && tag.expiryDate) {
            const expiryDate = moment(tag.expiryDate);
            if (Number(expiryDate.year()) < 2022) {
                return null;
            }
            const timeDifference = expiryDate.diff(moment());
            return {
                expiryDate: this.formateExpiryDate(tag.expiryDate), // expiry date from tag user memory not taking into consideration cabinet timezone and time
                expired: moment() > expiryDate,
                expiring: timeDifference > 0 && timeDifference < moment.duration(24, 'hours').asMilliseconds(),
                ok: timeDifference > 0 && timeDifference >= moment.duration(24, 'hours').asMilliseconds(),
            };
        }
        return null;
    }

    /**
     * Gets the most expiring tag among others.
     * @param {*} tags
     */
    getMostExpiringTagExpiryData(tags) {
        if (tags.length === 0) return null;
        return tags
            .map(tag => ({
                ...tag,
                expiryDate: tag.expiryDate && moment(tag.expiryDate),
            }))
            .reduce(function(a, b) {
                if (!a.expiryDate) {
                    return b;
                } else if (!b.expiryDate) {
                    return a;
                } else if (a.expiryDate < b.expiryDate) {
                    return a;
                } else {
                    return b;
                }
            });
    }

    render() {
        const { product, t, cabinetLocationId, locations } = this.props;
        const { isUnknownTagsOpen } = this.state;

        const tagsListComponents = tags => {
            return (
                <div className="bg-light shadow-sm mt-2">
                    <table className="table table-sm table-borderless">
                        <tbody>
                            {tags &&
                                tags.map((tag, index) => {
                                    const tagExpiryData = this.getTagExpiryData(tag);
                                    return (
                                        <tr key={index}>
                                            {tag.quality === 'bad' ? (
                                                <td>
                                                    <Tooltip theme="light" title={t('tagIsBad')} position="top" trigger="mouseenter">
                                                        <div className="ml-1">
                                                            <small title="Tag Quality">
                                                                <i className="material-icons se-icon se-icon--sm error-icon">label_outline</i>
                                                            </small>
                                                        </div>
                                                    </Tooltip>
                                                </td>
                                            ) : (
                                                <td></td>
                                            )}
                                            {tag.sold ? (
                                                <td>
                                                    <Tooltip
                                                        theme="light"
                                                        title={`${t('itemSoldOn')}: ${moment(tag.sold).format('LLL')}`}
                                                        position="top"
                                                        trigger="mouseenter"
                                                    >
                                                        <div className="ml-1">
                                                            <small title="Sold Item">
                                                                <i className="material-icons se-icon se-icon--sm error-icon">gavel</i>
                                                            </small>
                                                        </div>
                                                    </Tooltip>
                                                </td>
                                            ) : (
                                                <td></td>
                                            )}
                                            {/* tag.epc check will skip the items that are not RFID tags */}
                                            {tag.epc && (!tag.replenished || tag.replenished === null) ? (
                                                <td>
                                                    <Tooltip
                                                        theme="light"
                                                        title={t('itemReplenishedOutOfInventoryProcess')}
                                                        position="top"
                                                        trigger="mouseenter"
                                                    >
                                                        <div className="ml-1">
                                                            <small title="Replenishment Error">
                                                                <i className="material-icons se-icon se-icon--sm error-icon">input</i>
                                                            </small>
                                                        </div>
                                                    </Tooltip>
                                                </td>
                                            ) : (
                                                <td></td>
                                            )}

                                            <td style={{ width: '52%' }}>
                                                <small title="EPC">
                                                    <i className="material-icons se-icon se-icon--sm ">attach_file</i>
                                                    &nbsp;{tag.epc || '-'}
                                                    {/* show - if it's not RFID tag */}
                                                </small>
                                            </td>

                                            <td style={{ width: '20%' }}>
                                                <small className="ml-3" title="antenna #">
                                                    <i className="material-icons se-icon se-icon--sm ">{tag.epc ? 'settings_input_antenna' : 'inbox'}</i>
                                                    &nbsp;{tag.antenna || tag.slot || '-'}
                                                    {/* Show either antenna nr or the scale slot nr */}
                                                </small>
                                            </td>
                                            <td style={{ width: '30%' }}>
                                                {tagExpiryData && (
                                                    <div
                                                        style={{ overflow: 'hidden' }}
                                                        className={classNames('text-center', {
                                                            'bg-danger text-white p-1': tagExpiryData.expired,
                                                            'bg-warning text-dark p-1': tagExpiryData.expiring,
                                                            'bg-light text-dark p-1': tagExpiryData.ok,
                                                        })}
                                                    >
                                                        <i className="material-icons mb-1 mr-2">timer_off</i>
                                                        {tagExpiryData.expiryDate}
                                                    </div>
                                                )}
                                            </td>
                                        </tr>
                                    );
                                })}
                        </tbody>
                    </table>
                </div>
            );
        };

        const { tags } = product;
        const mostExpiringTagExpiryData = this.getTagExpiryData(this.getMostExpiringTagExpiryData(tags));

        // unknown or archived/deleted product
        if (product.barcode === '' || product.status === 3 || product.status === 4) {
            return (
                <tr className="inventory-row-item">
                    <td className="text-danger">{product.amount}</td>
                    <td>
                        <div className="d-flex text-center align-items-center" style={{ height: 67 }}>
                            <i style={{ width: 60 }} className="material-icons se-icon se-icon--lg text-danger">
                                image
                            </i>
                        </div>
                    </td>
                    <td>
                        {product.containsBadTag && (
                            <Tooltip theme="light" title={t('expandToSeeBadTags')} position="top" trigger="mouseenter">
                                <small className="ml-3 error-icon-container" title="Tag Quality">
                                    <i className="material-icons se-icon se-icon--sm error-icon ">label_outline</i>
                                </small>
                            </Tooltip>
                        )}
                        {product.containingSoldItems && (
                            <Tooltip theme="light" title={t('expandToSeeSoldItems')} position="top" trigger="mouseenter">
                                <small className="ml-3 error-icon-container" title="Sold Item">
                                    <i className="material-icons se-icon se-icon--sm soldItem-icon">gavel</i>
                                </small>
                            </Tooltip>
                        )}
                        {product.itemReplenishedOutOfInventoryProcess && (
                            <Tooltip theme="light" title={t('expandToSeeReplenishedOutOfInventory')} position="top" trigger="mouseenter">
                                <small className="ml-3 error-icon-container" title="Replenishment Error">
                                    <i className="material-icons se-icon se-icon--sm error-icon ">input</i>
                                </small>
                            </Tooltip>
                        )}
                        <code
                            className="underlined-dotted"
                            onClick={() => {
                                this.setState({
                                    isUnknownTagsOpen: !isUnknownTagsOpen,
                                });
                            }}
                        >
                            {t('unknown')}{' '}
                        </code>
                        {/* tags if given */}
                        <Collapse isOpen={this.state.isUnknownTagsOpen}>{tagsListComponents(tags)}</Collapse>
                        {/* /tags if given */}
                    </td>
                    <td>{product.barcode}</td>
                    <td>
                        <code>{t('unknown')}</code>
                    </td>
                </tr>
            );
        }

        // get price from direct parent or parents tree location
        // if parents location price is missing, get default price
        const getProductPrice = () => {
            // if no location, return default price
            if (!cabinetLocationId) {
                return product.price.toFixed(2) + product.currency;
            }

            const cabinetLocation = product.prices.find(p => p.locationId === cabinetLocationId);
            // if cabinetLocation has a price
            if (cabinetLocation && cabinetLocation.price) {
                return cabinetLocation.price.toFixed(2) + cabinetLocation.currency;
            }

            // if cabinet does not have the price for the location, look from the parent tree
            let parentLocation = locations.find(l => l.id === cabinetLocationId);

            if (parentLocation) {
                let parentLocationCode = parentLocation.parentLocationCode;

                // check until parentLocationCode == null, meaning the top level parent
                /* eslint-disable */
                while (parentLocationCode) {
                    const parent = product.prices.find(p => p.locationId === parentLocation.id);

                    if (parent && parent.price) {
                        return parent.price.toFixed(2) + parent.currency;
                    }
                    if (!parent || !parent.price) {
                        parentLocation = locations.find(l => l.code === parentLocationCode);
                        parentLocationCode = parentLocation.parentLocationCode;
                    }
                }
                /* eslint-enable*/
            }

            // fall back to default price, in case
            return product.price.toFixed(2) + product.currency;
        };

        const renderPrebookedTooltip = isPrebooked => {
            return (
                isPrebooked && (
                    <Tooltip theme="light" title={t('prebookedProduct')} position="top" trigger="mouseenter">
                        <svg className="icon-svg prebooked-icon">
                            <use xlinkHref={`${require('./icons.svg')}#icon-inbox-check`}></use>
                        </svg>
                    </Tooltip>
                )
            );
        };

        return (
            <tr className="inventory-row-item">
                <td>{product.amount}</td>
                <td className="pt-1 pb-1 inventory-product">
                    {product.imageUrl ? (
                        <>
                            <img src={product.imageUrl} alt="product" style={{ width: '60px' }} />
                            {renderPrebookedTooltip(product.isPrebooked)}
                        </>
                    ) : (
                        <>
                            <div className="text-center d-flex align-items-center inventory-missing-image-container">
                                <span className="icon-picture inventory-missing-image-icon"></span>
                            </div>
                            {renderPrebookedTooltip(product.isPrebooked)}
                        </>
                    )}
                </td>
                <td>
                    {product.containsBadTag && (
                        <Tooltip theme="light" title={t('expandToSeeBadTags')} position="top" trigger="mouseenter">
                            <small className="ml-3 error-icon-container" title="Tag Quality">
                                <i className="material-icons se-icon se-icon--sm error-icon ">label_outline</i>
                            </small>
                        </Tooltip>
                    )}
                    {product.containingSoldItems && (
                        <Tooltip theme="light" title={t('expandToSeeSoldItems')} position="top" trigger="mouseenter">
                            <small className="ml-3 error-icon-container" title="Sold Item">
                                <i className="material-icons se-icon se-icon--sm soldItem-icon ">gavel</i>
                            </small>
                        </Tooltip>
                    )}
                    {product.itemReplenishedOutOfInventoryProcess && (
                        <Tooltip theme="light" title={t('expandToSeeReplenishedOutOfInventory')} position="top" trigger="mouseenter">
                            <small className="ml-3 error-icon-container" title="Replenishment Error">
                                <i className="material-icons se-icon se-icon--sm replenishment-error-icon ">input</i>
                            </small>
                        </Tooltip>
                    )}
                    <Row>
                        <Col xs="12" lg={mostExpiringTagExpiryData ? '6' : '12'} xl={mostExpiringTagExpiryData ? '8' : '12'}>
                            <span
                                className="underlined-dotted"
                                onClick={() => {
                                    this.setState({
                                        isUnknownTagsOpen: !isUnknownTagsOpen,
                                    });
                                }}
                            >
                                {product.name}{' '}
                            </span>
                        </Col>
                        {mostExpiringTagExpiryData && (
                            <Col xs="12" lg="6" xl="4">
                                <div
                                    style={{ overflow: 'hidden' }}
                                    className={classNames('text-center', {
                                        'bg-danger text-white p-1': mostExpiringTagExpiryData.expired,
                                        'bg-warning text-dark p-1': mostExpiringTagExpiryData.expiring,
                                        'bg-light text-dark p-1': mostExpiringTagExpiryData.ok,
                                    })}
                                >
                                    <i className="material-icons mb-1 mr-2">timer_off</i>
                                    <span>{mostExpiringTagExpiryData.expiryDate}</span>
                                </div>
                            </Col>
                        )}
                    </Row>

                    {/* tags if given */}
                    <Collapse isOpen={this.state.isUnknownTagsOpen}>{tagsListComponents(tags)}</Collapse>
                    {/* /tags if given */}
                </td>
                <td>{product.barcode}</td>
                <td>
                    <b>{getProductPrice()}</b>
                </td>
            </tr>
        );
    }
}

/**
 * HTML table body with cabinet inventory component.
 * Needs cabinet to be defined in props.
 * Also needs inventory Redux state and fetchCabinetInventory Redux action.
 * @param {string} cabinet Selected cabinet in props
 */
export class InventoryListComponent extends Component {
    componentDidMount() {
        const { hideInventoryErrorAlarmIcon, showInventoryErrorAlarmIcon, fetchCabinetInventory, cabinet, inventory } = this.props;

        // selected cabinet inventory has not been fetched
        if (cabinet && !inventory[cabinet.deviceCode]) {
            hideInventoryErrorAlarmIcon();
            fetchCabinetInventory(cabinet.deviceCode);
        } else {
            const cabinetInventory = inventory[cabinet.deviceCode];
            if (!cabinetInventory || cabinetInventory.fetched === false || cabinetInventory.isError === true) {
                hideInventoryErrorAlarmIcon();
            } else {
                const filteredInventory = this.filterGhostTags(cabinetInventory.inventory);
                let unknownTagFound = false;
                let soldTagFound = false;
                let badTagFound = false;
                let outOfInventoryReplenishedTagFound = false;
                filteredInventory.forEach(tag => {
                    if (!tag.product) {
                        unknownTagFound = true;
                    }

                    if (tag.sold) {
                        soldTagFound = true;
                    }

                    if (!tag.replenished || tag.replenished === null) {
                        outOfInventoryReplenishedTagFound = true;
                    }

                    if (tag.quality === 'bad') {
                        badTagFound = true;
                    }
                });

                if (unknownTagFound || badTagFound || soldTagFound || outOfInventoryReplenishedTagFound) {
                    showInventoryErrorAlarmIcon();
                } else {
                    hideInventoryErrorAlarmIcon();
                }
            }
        }
    }

    componentDidUpdate() {
        const { hideInventoryErrorAlarmIcon, showInventoryErrorAlarmIcon, fetchCabinetInventory, cabinet, inventory } = this.props;

        // selected cabinet inventory has not been fetched
        if (cabinet && !inventory[cabinet.deviceCode]) {
            hideInventoryErrorAlarmIcon();
            fetchCabinetInventory(cabinet.deviceCode);
        } else {
            const cabinetInventory = inventory[cabinet.deviceCode];
            if (!cabinetInventory || cabinetInventory.fetched === false || cabinetInventory.isError === true) {
                hideInventoryErrorAlarmIcon();
            } else {
                const filteredInventory = this.filterGhostTags(cabinetInventory.inventory);
                let unknownTagFound = false;
                let soldTagFound = false;
                let badTagFound = false;
                let outOfInventoryReplenishedTagFound = false;
                filteredInventory.forEach(tag => {
                    if (!tag.product) {
                        unknownTagFound = true;
                    }

                    if (tag.sold) {
                        soldTagFound = true;
                    }

                    if (!tag.replenished || tag.replenished === null) {
                        outOfInventoryReplenishedTagFound = true;
                    }

                    if (tag.quality === 'bad') {
                        badTagFound = true;
                    }
                });

                if (unknownTagFound || badTagFound || soldTagFound || outOfInventoryReplenishedTagFound) {
                    showInventoryErrorAlarmIcon();
                } else {
                    hideInventoryErrorAlarmIcon();
                }
            }
        }
    }

    flattenProductsWithAmount(products) {
        const { cabinet } = this.props;
        let flattenedProducts = [];
        for (let i = 0; i < products.length; i++) {
            const indexInFlattened = flattenedProducts.findIndex(product => product.barcode === products[i].barcode);
            if (indexInFlattened === -1) {
                flattenedProducts.push(Object.assign({}, products[i], { amount: 1 }));
            } else {
                flattenedProducts[indexInFlattened].amount += 1;
            }
        }

        // need to append EPC codes for products as we need to show what tags are behind them
        const inventory = this.filterGhostTags(this.props.inventory[(cabinet || {}).deviceCode].inventory);
        flattenedProducts = flattenedProducts.map(product => {
            const tags = [];
            let containsBadTag = false;
            let containingSoldItems = false;
            let itemReplenishedOutOfInventoryProcess = false;
            for (const tag of inventory) {
                if (tag.barcode === product.barcode || (tag.barcode === '-' && product.barcode === '')) {
                    tags.push(tag);
                    if (tag.quality === 'bad') {
                        containsBadTag = true;
                    }

                    if (tag.sold) {
                        containingSoldItems = true;
                    }

                    /* tag.epc check will skip the items that are not RFID tags */
                    if (tag.epc && (!tag.replenished || tag.replenished === null)) {
                        itemReplenishedOutOfInventoryProcess = true;
                    }
                }
            }
            return {
                ...product,
                tags,
                containsBadTag,
                containingSoldItems,
                itemReplenishedOutOfInventoryProcess,
            };
        });

        return flattenedProducts.sort((a, b) => a.amount - b.amount);
    }

    filterGhostTags(rawInventory) {
        // On UI we do not show the ghost tags, hence inventory should be filtered out.
        // Ghost tag means a tag that's expected to be in the inventory, but not read at this moment
        // Ghost tag = antenna info not available for the tag.
        // tag.epc check will skip the items that are not RFID tags
        return rawInventory.filter(el => (el.epc && el.antenna) || !el.epc);
    }

    render() {
        const { t, cabinet } = this.props;
        const inventory = this.props.inventory[(cabinet || {}).deviceCode];
        if (!inventory) {
            return <div />;
        }

        if (inventory.isFetching || inventory.isError || (inventory.inventory.length === 0 && !inventory.readTime)) {
            return (
                <FetchingAlert
                    fetchingMessage={t('fetchingInventory')}
                    noDataMessage={t('inventoryNotReported')}
                    errorMessage={t('errorFetchingInventory')}
                    isError={inventory.isError}
                    noData={inventory.inventory && inventory.inventory.length === 0 && !inventory.readTime && !inventory.isFetching}
                />
            );
        }

        if (inventory.inventory.length === 0 && inventory.readTime) {
            return (
                <div>
                    <div>
                        <h6>
                            {t('lastUpdate')}: {moment(inventory.readTime).format('LLL')}
                        </h6>
                    </div>
                    <Alert color="secondary">
                        <div className="h-100 d-flex align-items-center">{t('cabinetIsEmpty')}</div>
                    </Alert>
                </div>
            );
        }
        const filteredInventory = this.filterGhostTags(inventory.inventory);

        const productRows = this.flattenProductsWithAmount(filteredInventory.map(tag => tag.product || { barcode: '', name: '' })).map(product => (
            <ProductRow
                key={product.barcode}
                product={product}
                t={t}
                cabinetLocationId={cabinet.locationId}
                locationsTree={this.props.locationsTree}
                locations={this.props.locations}
            />
        ));

        return (
            <div>
                <div className="d-flex">
                    <h6>
                        {t('lastUpdate')} : <b>{inventory.readTime ? moment(inventory.readTime).format('LLL') : t('unknown')}</b>
                    </h6>
                    <h6 className="ml-3">
                        {t('inventoryLength')}: <b>{filteredInventory.length}</b>
                    </h6>
                </div>
                <div className="table-responsive">
                    <Table>
                        <thead>
                            <tr>
                                <th>{t('quantity')}</th>
                                <th style={{ width: '80px' }}>{t('image')}</th>
                                <th>
                                    <Row xs="12" lg="6" xl="8">
                                        <Col>{t('name')}</Col>
                                        <Col xs="12" lg="6" xl="4">
                                            {t('expiring')}
                                        </Col>
                                    </Row>
                                </th>
                                <th>{t('barcode')}</th>
                                <th>{t('price')}</th>
                            </tr>
                        </thead>
                        <tbody>{productRows}</tbody>
                    </Table>
                </div>
            </div>
        );
    }
}

export default connect(
    state => ({
        inventory: state.inventory,
        locationsTree: state.cabinets.locationsTree,
        locations: state.cabinets.locations,
    }),
    { fetchCabinetInventory, showInventoryErrorAlarmIcon, hideInventoryErrorAlarmIcon }
)(translate('main')(InventoryListComponent));
