import React, { Fragment } from 'react';
import moment from 'moment';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

import { Icon } from '@storaensods/seeds-react';
import { Alert } from 'reactstrap';
import tokens from '@storaensods/seeds-core/dist/_tokens.json';
import { getLocalTime } from './../export';

const quantile = (arr, q) => {
    const sorted = arr.sort((a, b) => a - b);
    const pos = (sorted.length - 1) * q;
    const base = Math.floor(pos);
    const rest = pos - base;
    if (sorted[base + 1] !== undefined) {
        return sorted[base] + rest * (sorted[base + 1] - sorted[base]);
    } else {
        return sorted[base];
    }
};

/**
 * Try to predict when a defrost cycle is ongoing from temperature data
 * @param {Array} temperatures temperature objects with averageTemp and date sorted by date
 * @param {Number} upperQuartal
 * @returns {Array} temperatures with defrost cycles
 */
const addDefrostCyclesToTemperatureData = (temperatures, upperQuartal) => {
    const temperaturesWithDefrost = temperatures.map(temp => {
        if (temp.averageTemp > upperQuartal) {
            return Object.assign(temp, { defrostCycle: 1, realTemp: temp.averageTemp });
        } else {
            return Object.assign(temp, { defrostCycle: 0, realTemp: temp.averageTemp });
        }
    });

    // make sure there arent too many consecutive defrosts
    let lastDefrostCycles = 0;
    let timeUntilNextCycle = 0;
    for (let i = 0; i < temperaturesWithDefrost.length; i++) {
        if (temperaturesWithDefrost[i].defrostCycle) {
            if (lastDefrostCycles > 3) {
                temperaturesWithDefrost[i].defrostCycle = 0;
                lastDefrostCycles = 0;
                timeUntilNextCycle = 3;
            } else {
                if (timeUntilNextCycle > 0) {
                    temperaturesWithDefrost[i].defrostCycle = 0;
                    timeUntilNextCycle--;
                } else {
                    temperaturesWithDefrost[i].defrostCycle = 1;
                    lastDefrostCycles++;
                }
            }
        } else {
            if (lastDefrostCycles > 0) {
                timeUntilNextCycle = 4;
                lastDefrostCycles = 0;
            }
            timeUntilNextCycle--;
        }
    }

    // alter temperatures during defrost cycles
    const newTemperatures = [];
    for (let i = 0; i < temperaturesWithDefrost.length; i++) {
        if (!temperaturesWithDefrost[i].defrostCycle) {
            newTemperatures.push(temperaturesWithDefrost[i]);
        } else {
            if (i === 0) {
                newTemperatures.push(Object.assign(temperaturesWithDefrost[i], { averageTemp: upperQuartal - 1 }));
            } else {
                const newTemp = newTemperatures[i - 1].averageTemp;
                newTemperatures.push(Object.assign(temperaturesWithDefrost[i], { averageTemp: newTemp }));
            }
        }
    }

    return newTemperatures;
};

const TemperatureChart = ({ average, current, timeRange, defrost, t }) => {
    const temperatureData = average.data.length ? average : null;

    // if the temperature data array is empty for all -min, max, average, display no data error
    if (!temperatureData) {
        return (
            <Alert color="warning" className="float-left">
                <div className="h-100 d-flex align-items-center">
                    <i className="material-icons se-icon">warning</i> &nbsp;
                    {t('noData')}
                </div>
            </Alert>
        );
    }

    const granularity = average.query.timeDimensions[0].granularity || 'day';
    // Note! Cube returns the times already converted to client timezone, so we need to use them as they are without conversion!
    const clientTimeZone = 'Etc/UTC'; //Intl.DateTimeFormat().resolvedOptions().timeZone;

    // combine maximum, minimum and average temperature data into one array - by date
    const temperaturesWithoutDefrost = temperatureData.data.map(data => {
        return {
            date: data['DeviceTemperatures.timestamp'],
            averageTemp: data['DeviceTemperatures.averageComputedTemperature'],
        };
    });

    const temperaturesWithoutDefrostNoNull = temperaturesWithoutDefrost.filter(temp => temp.averageTemp);
    let totalUpperQuartal = 0;
    if (temperaturesWithoutDefrostNoNull.length > 0) {
        totalUpperQuartal = quantile(
            temperaturesWithoutDefrostNoNull.map(temp => temp.averageTemp),
            0.75
        );
    }

    const temperatures = defrost ? addDefrostCyclesToTemperatureData(temperaturesWithoutDefrost, totalUpperQuartal) : temperaturesWithoutDefrost;

    let dFormat = 'YYYY-MM-DD HH';

    switch (granularity) {
        case 'minute':
            dFormat = 'YYYY-MM-DD HH:mm';
            break;
        case 'day':
            dFormat = 'YYYY-MM-DD';
            break;
        case 'week':
            dFormat = 'GGGG-[W]WW';
            break;
        default:
            break;
    }

    // convert date to local time and format the date-time
    const resolveTimeLine = fullDate => {
        const localTime = getLocalTime(fullDate, clientTimeZone);
        return moment(localTime).format(dFormat);
    };

    // what appears on the x-axis date label
    const resolveTimeLineLabel = (granularFormat = true, fullDate) => {
        switch (granularity) {
            case 'minute':
                return granularFormat ? moment(fullDate).format('LT') : moment(fullDate).format('YYYY-MM-DD LT');
            case 'hour':
                return moment(fullDate).format('YYYY-MM-DD LT');
            case 'day':
                return moment(fullDate).format('YYYY-MM-DD');
            case 'week':
                return moment(fullDate).format('GGGG-[Week-]WW');
            default:
                return moment(fullDate).format('GGGG-[Week-]WW');
        }
    };

    // format the data for the graph
    let formattedData = [];

    if (temperatures && temperatures.length > 2) {
        const minD = resolveTimeLine(timeRange.from);
        const maxD = resolveTimeLine(timeRange.to);
        let currentD = minD;

        temperatures.forEach(t => {
            if (t && t.date) {
                const d = resolveTimeLine(t['date']);
                // If there are gaps in the time-series data, i.e. no data points for certain times,
                // we are populating those with empty values so that x-axis is scaled properly
                let safetyCount = 0; // in case data is corrupt, to prevent endless loop
                while (moment(d).isAfter(moment(currentD)) && safetyCount < 500 && moment(currentD).isBefore(moment(maxD))) {
                    safetyCount++;
                    formattedData.push({
                        date: currentD,
                        average: '-',
                        defrost: '-',
                    });
                    currentD = moment(currentD)
                        .add(1, granularity)
                        .format(dFormat);
                }
                formattedData.push({
                    date: d,
                    average: t['averageTemp'] ? t['averageTemp'].toFixed(2) : '-',
                    defrost: t['realTemp'] ? t['realTemp'].toFixed(2) : '-',
                });
                currentD = moment(d)
                    .add(1, granularity)
                    .format(dFormat);
            }
        });
    } else {
        formattedData = temperatures.map(t => {
            if (t) {
                return {
                    date: resolveTimeLine(t['date']),
                    average: t['averageTemp'] ? t['averageTemp'].toFixed(2) : '-',
                    defrost: t['realTemp'] ? t['realTemp'].toFixed(2) : '-',
                };
            }
            return {};
        });
    }

    // Tilted lable of X-axis date
    const CustomizedAxisTick = props => {
        const { x, y, payload } = props;
        const timeLine = resolveTimeLineLabel(true, payload.value);

        return (
            <g transform={`translate(${x},${y})`}>
                <text x={0} y={0} dy={16} textAnchor="end" fill="#000" transform="rotate(-45)">
                    {timeLine}
                </text>
            </g>
        );
    };

    // the graph legend text -Temperature*C
    const formatLegendText = () => {
        return <p style={{ textAlign: 'right' }}>{t('temperature')} &deg;C</p>;
    };

    //the data points gap in the X-axis date
    const getAxisDataInterval = () => {
        // finding the time range selected
        const hoursOfTimeRange = moment.duration(moment(timeRange.to).diff(moment(timeRange.from))).asHours();
        const daysOfTimeRange = moment.duration(moment(timeRange.to).diff(moment(timeRange.from))).asDays();
        switch (granularity) {
            case 'minute':
                return 4;
            case 'hour':
                if (hoursOfTimeRange < 13) {
                    return 0; //divide by every record
                } else if (hoursOfTimeRange < 25) {
                    // 7 days
                    return 1; // divide by every 12 records
                } else if (hoursOfTimeRange < 169) {
                    // 7 days
                    return 11; // divide by every 12 records
                } else if (hoursOfTimeRange < 337) {
                    // 14 days
                    return 23; // divide every 24 records
                } else if (hoursOfTimeRange <= 745) {
                    // 31 days
                    return 47; // divide every 72 records
                } else if (hoursOfTimeRange > 745) {
                    return 50;
                } else {
                    return 0;
                }
            case 'day':
                if (daysOfTimeRange < 45) {
                    return 1; //divide by every record
                } else if (daysOfTimeRange < 75) {
                    return 2; //divide by every other record
                } else if (daysOfTimeRange < 100) {
                    //max record before before it changes to week
                    return 3;
                } else {
                    return 0;
                }
            default:
                return 0;
        }
    };

    // get the maximum temperature
    const getMaxTemperatureData = temperatures.reduce((maxTempData, currentData) => {
        const temp = Math.max(parseFloat(currentData['averageTemp']), parseFloat(maxTempData['averageTemp']));

        if (temp === parseFloat(currentData['averageTemp']) || !maxTempData['averageTemp']) {
            return currentData;
        } else {
            return maxTempData;
        }
    });

    // get the minimum temperature
    const getMinTemperatureData = temperatures.reduce((minTempData, currentData) => {
        const temp = Math.min(parseFloat(currentData['averageTemp']), parseFloat(minTempData['averageTemp']));

        if (temp === parseFloat(currentData['averageTemp']) || !minTempData['averageTemp']) {
            return currentData;
        } else {
            return minTempData;
        }
    });

    // get the average temperature
    const getAverageTemeprature = () => {
        let total = 0;
        temperatures.forEach(temperature => {
            const addition = parseFloat(temperature['averageTemp']);
            total = total + (addition ? addition : 0);
        });

        if (!total) {
            return null;
        }

        return (total / temperatures.length).toFixed(2);
    };

    const getTemperatureStats = () => {
        const noDataWarn = () => {
            return (
                <span className="temp-no-data" color="warning" size="sm">
                    <Icon size="sm">warning</Icon> {t('noData')}
                </span>
            );
        };

        const dataView = (temperature, date) => {
            return (
                <Fragment>
                    {parseFloat(temperature).toFixed(2)}
                    <span>&deg;C&nbsp;</span> |&nbsp;
                    {moment(date).format('LT')}
                </Fragment>
            );
        };

        return (
            <Fragment>
                <div className="row temp-color temp-current p-3">
                    <span className="">&#42;</span> {t('current')} {t('temperature')} :&nbsp;
                    {current.temperature && current.deviceOnline && dataView(current.temperature, current.lastRefreshTime)}
                    {!current.temperature && noDataWarn()}
                </div>

                <div className="row temp-color px-3">
                    <span className="temp-color-green"> </span> {t('average')} {t('temperature')} :&nbsp;
                    {getAverageTemeprature() && (
                        <Fragment>
                            {getAverageTemeprature()} <span>&deg;C</span>
                        </Fragment>
                    )}
                    {!getAverageTemeprature() && noDataWarn()}
                </div>
                <br />
                <div className="row temp-note px-3">
                    <span>{t('note')}:</span>
                    <span>&nbsp;{t('temperatureValueNotification')}</span>
                </div>
            </Fragment>
        );
    };

    const getTemperatureChart = () => {
        return (
            <ResponsiveContainer width="100%" height={600}>
                <LineChart data={formattedData}>
                    <CartesianGrid
                        stroke={tokens.color.bg['gray'].value}
                        strokeDasharray="-5 35"
                        verticalFill={[tokens.color.bg['gray-light'].value, tokens.color.bg.white.value]}
                        verticalLines={false}
                        horizontalLines={false}
                    />
                    <YAxis
                        dataKey="average"
                        domain={[Math.round(getMinTemperatureData['averageTemp']) - 2, Math.round(getMaxTemperatureData['averageTemp']) + 10]}
                    />
                    <XAxis
                        interval={getAxisDataInterval()}
                        dataKey="date"
                        tickFormatter={resolveTimeLineLabel.bind(this, true)}
                        tick={CustomizedAxisTick}
                        height={150}
                    />

                    <Tooltip labelFormatter={resolveTimeLineLabel.bind(this, false)} />
                    <Legend align="right" verticalAlign="top" iconType="circle" height={50} content={formatLegendText} />
                    {/* {showAverage && ( */}
                    <Line type="monotone" dataKey="average" stroke="#67B419" strokeWidth={2} dot={false} connectNulls={false} />
                    {defrost ? <Line type="monotone" dataKey="defrost" stroke="#cccccc" strokeWidth={1} dot={false} connectNulls={false} /> : null}
                </LineChart>
            </ResponsiveContainer>
        );
    };

    return (
        <Fragment>
            {getTemperatureStats()}
            {getTemperatureChart()}
        </Fragment>
    );
};
export default TemperatureChart;
