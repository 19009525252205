export const initState = {
    fetched: false,
    isFetching: false,
    isError: false,
    ads: [],
    requesting: false,
    finished: false,
    error: '',
    imageStoring: false,
    imageUrl: '',
    imageId: null,
    imageStoringError: null,
    adUpdate: null,
};
export default function products(state = initState, action) {
    switch (action.type) {
        case 'REQUEST_ADS':
            return {
                ...state,
                isFetching: true,
                isError: false,
            };
        case 'RECEIVE_ADS':
            return {
                ...state,
                isFetching: false,
                isError: false,
                fetched: true,
                ads: action.ads,
                lastUpdated: action.receivedAt,
            };
        case 'RECEIVE_ADS_ERROR':
            console.error(action.error);
            return {
                ...state,
                isFetching: false,
                isError: true,
                fetched: true,
                error: action.error.toString(),
            };
        case 'REQUEST_UPDATE_AD':
            return {
                ...state,
                requesting: true,
                finished: false,
                error: '',
                adUpdate: action.ad,
            };
        case 'RECEIVE_UPDATE_AD':
            return {
                ...state,
                requesting: false,
                finished: true,
                error: '',
                ads: [state.adUpdate, ...state.ads.filter(ad => ad.id !== state.adUpdate.id)],
                adUpdate: null,
            };
        case 'RECEIVE_UPDATE_AD_ERROR':
            console.error(action.error);
            return {
                ...state,
                requesting: false,
                finished: true,
                error: action.error.toString(),
                adUpdate: null,
            };
        case 'DISMISS_UPDATE_AD_RESULT':
            return {
                ...state,
                requesting: false,
                finished: false,
                error: '',
            };
        case 'REQUEST_UPLOAD_AD_IMAGE':
            return {
                ...state,
                imageStoring: true,
                imageUrl: '',
                imageId: null,
                imageStoringError: null,
                uploadProgress: action.progress || 0,
            };
        case 'UPLOAD_AD_IMAGE_SUCCESS':
            return {
                ...state,
                imageStoring: false,
                imageUrl: action.url,
                imageId: action.id,
                imageStoringError: null,
            };
        case 'UPLOAD_AD_IMAGE_ERROR':
            console.error(action.error);
            return {
                ...state,
                imageStoring: false,
                imageUrl: '',
                imageId: null,
                imageStoringError: action.error.toString(),
            };
        case 'REQUEST_DELETE_AD':
            return {
                ...state,
                imageUrl: '',
                imageId: null,
            };
        case 'RESET_ADS':
            return {
                ...initState,
            };
        default:
            return state;
    }
}
