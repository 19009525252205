import { utc } from 'moment';
import { fetchCabinetRefills, fetchProducts } from '../api.js';

export const REQUEST_REPLENISHMENTS = 'REQUEST_REPLENISHMENTS';
export const RECEIVE_REPLENISHMENTS = 'RECEIVE_REPLENISHMENTS';
export const RECEIVE_REPLENISHMENTS_ERROR = 'RECEIVE_REPLENISHMENTS_ERROR';

const requestCabinetReplenishment = id => ({
    type: REQUEST_REPLENISHMENTS,
    id,
});

const receiveCabinetReplenishment = (id, replenishments) => ({
    type: RECEIVE_REPLENISHMENTS,
    id,
    replenishments,
});

const receiveCabinetReplenishmentError = (id, error) => ({
    type: RECEIVE_REPLENISHMENTS_ERROR,
    id,
    error,
});

// TODO: Deprecated?
export const inventoryDiff = (previous, current) => {
    const removed = previous.filter(item => !current.find(other => item.epc === other.epc));
    const added = current.filter(item => !previous.find(other => item.epc === other.epc));

    return { current, removed, added };
};

// TODO: Deprecated?
/**
 * Construct replenishment report from inventory changes and audits that imitates transaction
 * structure.
 */
export const createReplenishmentReports = records => {
    const orderedRecords = [...records].sort((left, right) => utc(left.audit.timestamp).diff(utc(right.audit.timestamp)));

    // Find snapshots before and after replenishment
    orderedRecords.forEach(record => {
        const { start = {}, end = {} } = record;
        record.tags = inventoryDiff(start.tags, end.tags);
    });

    return orderedRecords;
};

/**
 * Fetching cabinet inventory history
 */
export function fetchCabinetReplenishments(cabinet, from, to) {
    return async (dispatch, getState) => {
        // update state to inform API call started
        dispatch(requestCabinetReplenishment(cabinet.deviceCode));

        // fetch products then update state
        const accessToken = getState().user.accessToken;
        const group = getState().user.group;

        try {
            // NOTE: returns the product information without differentiating status
            // v1/refill version fetched products separately
            // which did not include status: 4 products
            const [replenishments, products] = await Promise.all([
                fetchCabinetRefills(cabinet.deviceCode, { from, to }, accessToken),
                fetchProducts(accessToken, group),
            ]);

            // update replenishments with the information
            // that product is fetchable from the endpoint
            for (const replenishment of replenishments) {
                replenishment.refill.forEach(rp => {
                    if (rp.id === undefined) {
                        rp.is_showable = false;
                    } else {
                        const product = products.find(p => p.barcode === rp.barcode);
                        rp.is_showable = product !== undefined;
                    }
                });
            }

            dispatch(receiveCabinetReplenishment(cabinet.deviceCode, replenishments));
        } catch (error) {
            dispatch(receiveCabinetReplenishmentError(cabinet.deviceCode, error));
        }
    };
}
