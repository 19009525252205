import React, { useEffect, useState } from 'react';

import InfoBanner from './../segments/InfoBanner';
import SchemaProducts from './../segments/schemaProducts';
import Loader from './../segments/loader';
import { Button } from '../../commons/utils';
import Planogram from '../segments/planogram';
import DevicesByLocations from '../segments/devicesByLocations';

const RefillSchemas = ({
    cabinets,
    locations,
    refillRule,
    fetchCabinetRefillRule,
    allProducts,
    updateCabinetRefillSchema,
    createRefillSchema,
    deleteRefillSchema,
    fetchRefillRules,
    t,
    postSettingsCopyRequest,
    allRefillRules,
    allRefillPlans,
    uploadPlanogramImage,
    planogramImageInStore,
    isAdmin,
}) => {
    const [selectedCabinet, setSelectedCabinet] = useState();
    const [createNew, setCreateNew] = useState(false);
    const [activeViewMode, setActiveViewMode] = useState('inventory');
    const [shcemaPlanogramData, setSchemaPlanogramData] = useState({});

    useEffect(() => {
        if (!selectedCabinet) return;

        fetchCabinetRefillRule(selectedCabinet.deviceCode);
        setCreateNew(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedCabinet]);

    useEffect(() => {
        const { rule } = refillRule;
        if (!rule) return;

        const planogram = rule.planogram;

        setSchemaPlanogramData(planogram);
    }, [refillRule, activeViewMode]);

    return (
        <div className="refillschema">
            <DevicesByLocations
                cabinets={cabinets}
                locations={locations}
                t={t}
                cabinetSelectionChange={cabinet => {
                    setSelectedCabinet(cabinet);
                    setActiveViewMode('inventory');
                }}
            />
            <div className="refillschema__contents">
                {(() => {
                    if (!selectedCabinet) return <InfoBanner message={t('refillSchemaSubText')} />;

                    if (refillRule.fetching) return <Loader message={t('main:fetchingLoader')} />;

                    if (!createNew && (!refillRule.rule || !Object.keys(refillRule.rule).length)) {
                        return (
                            <div className="refillschema__contents-no-rule">
                                <InfoBanner message={t('noRefillSchema')} />
                                <Button
                                    type="add"
                                    text={t('createRefillSchema')}
                                    onClick={() => {
                                        setCreateNew(true);
                                        setActiveViewMode('inventory');
                                    }}
                                />
                            </div>
                        );
                    }

                    if (activeViewMode === 'planogram') {
                        //if no planogram data, suggest to create a new one.
                        if (!shcemaPlanogramData || !Object.keys(shcemaPlanogramData).length) {
                            return (
                                <div>
                                    <div className="refillschema__controller">
                                        <div className="refillschema__controller-toggle">
                                            <span
                                                className={`${
                                                    activeViewMode === 'inventory'
                                                        ? 'refillschema__controller-toggle-inventory refillschema__controller-toggle--active'
                                                        : ''
                                                }`}
                                                onClick={() => setActiveViewMode('inventory')}
                                            >
                                               {t('inventory')}
                                            </span>
                                            <span
                                                className={`${
                                                    activeViewMode === 'planogram'
                                                        ? 'refillschema__controller-toggle-planogram refillschema__controller-toggle--active'
                                                        : ''
                                                }`}
                                                onClick={() => setActiveViewMode('planogram')}
                                            >
                                                {t('planogram')}
                                            </span>
                                        </div>
                                    </div>

                                    <InfoBanner message={t('noPlanogramInSchema')} />
                                    {isAdmin && (
                                        <Button
                                            type="add"
                                            text={t('createRefillPlanogram')}
                                            mt
                                            onClick={() =>
                                                setSchemaPlanogramData({
                                                    shelvesCount: 5,
                                                    shelves: [
                                                        {
                                                            shelf: 1,
                                                            slotCount: 5,
                                                            productData: [],
                                                        },
                                                        {
                                                            shelf: 2,
                                                            slotCount: 5,
                                                            productData: [],
                                                        },
                                                        {
                                                            shelf: 3,
                                                            slotCount: 5,
                                                            productData: [],
                                                        },
                                                        {
                                                            shelf: 4,
                                                            slotCount: 5,
                                                            productData: [],
                                                        },
                                                        {
                                                            shelf: 5,
                                                            slotCount: 5,
                                                            productData: [],
                                                        },
                                                    ],
                                                })
                                            }
                                        />
                                    )}
                                </div>
                            );
                        } else {
                            return (
                                <Planogram
                                    toggleViewMode={mode => setActiveViewMode(mode)}
                                    activeViewMode={activeViewMode}
                                    t={t}
                                    cabinetRefillSchema={refillRule}
                                    allProducts={allProducts}
                                    updateCabinetRefillSchema={updateCabinetRefillSchema}
                                    selectedCabinet={selectedCabinet}
                                    fetchRefillRules={fetchRefillRules}
                                    planogramProductData={shcemaPlanogramData}
                                    planogramImageInStore={planogramImageInStore}
                                    uploadPlanogramImage={uploadPlanogramImage}
                                    isAdmin={isAdmin}
                                />
                            );
                        }
                    }

                    return (
                        <SchemaProducts
                            selectedCabinet={selectedCabinet}
                            cabinetRefillSchema={refillRule}
                            allProducts={allProducts}
                            updateCabinetRefillSchema={updateCabinetRefillSchema}
                            createRefillSchema={createRefillSchema}
                            deleteRefillSchema={deleteRefillSchema}
                            fetchRefillRules={fetchRefillRules}
                            fetchCabinetRefillRule={fetchCabinetRefillRule}
                            t={t}
                            toggleViewMode={mode => {
                                setActiveViewMode(mode);
                            }}
                            activeViewMode={activeViewMode}
                            locations={locations}
                            cabinets={cabinets}
                            copyRefillSchemaToOtherCabinets={postSettingsCopyRequest}
                            allRefillSchemas={allRefillRules}
                            allRefillPlans={allRefillPlans}
                            isAdmin={isAdmin}
                        />
                    );
                })()}
            </div>
        </div>
    );
};

export default RefillSchemas;
