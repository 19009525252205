import React, { useEffect, useState } from 'react';
import TextField from './textField';
import { Button } from '@storaensods/seeds-react';
import { deleteCustomQueryUserPreset, getCustomQueryUserPresets, saveCustomQueryUserPreset } from '../../../../localStorage';
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';

const UserPresetQueries = ({ t, custom, currentSelection, onSelected }) => {
    const [name, setName] = useState('');
    const [successFeedbackVisible, setSuccessFeedbackVisible] = useState(false);
    const [presets, setPresets] = useState([]);
    const [selectedPreset, setSelectedPreset] = useState(null);

    useEffect(() => {
        setPresets(getCustomQueryUserPresets());
    }, []);

    const handleSave = () => {
        const {
            selectedMeasures: measures,
            selectedDimensions: dimensions,
            selectedDateRange: dateRange,
            selectedGranularity: granularity,
            queryType: reportType,
            groupBy: filter,
            selectedCabinetFilters: cabinets,
            selectedLocationFilters: locations,
            preDefinedSegment: segments,
        } = currentSelection;

        saveCustomQueryUserPreset(name, { measures, dimensions, dateRange, granularity, reportType, filter, cabinets, locations, segments });
        setName('');
        setSuccessFeedbackVisible(true);
        setPresets(() => {
            const savedPresets = getCustomQueryUserPresets();
            setSelectedPreset(savedPresets.slice(-1)[0]);

            return savedPresets;
        });
    };

    const handleDelete = (e, id) => {
        e.stopPropagation();
        deleteCustomQueryUserPreset(id);
        setPresets(getCustomQueryUserPresets());

        if (id === selectedPreset?.id) {
            setSelectedPreset(null);
        }
    };

    const handleSelect = preset => {
        setSelectedPreset(preset);
        onSelected(preset.query);
    };

    return (
        <div className="save-query-container">
            <div className="textfield-container input-container">
                <TextField
                    label={t('saveQuery')}
                    maxLength={50}
                    placeholder={t('saveQueryPlaceholder')}
                    onChange={e => {
                        setSuccessFeedbackVisible(false);
                        setName(e.target.value);
                    }}
                    value={name}
                />
                <div className="buttons">
                    <Button icon="save" type="button" disabled={custom.invalidParams || name === ''} onClick={handleSave}>
                        {t('save')}
                    </Button>
                </div>
            </div>
            <div className="feedback-container">{successFeedbackVisible && <p>{t('saveQuerySuccess')}</p>}</div>
            <div className="dropdown-container input-container">
                <label>
                    {t('yourSavedQueries')} ({presets.length})
                </label>
                <UncontrolledDropdown>
                    <DropdownToggle caret dark>
                        {selectedPreset?.name || t(presets.length > 0 ? 'selectQuery' : 'noQuery')}
                    </DropdownToggle>
                    <DropdownMenu dark>
                        {presets.map(preset => (
                            <DropdownItem key={preset.id} onClick={() => handleSelect(preset)}>
                                <span>{preset.name}</span>
                                <Button icon="delete" onClick={e => handleDelete(e, preset.id)} />
                            </DropdownItem>
                        ))}
                    </DropdownMenu>
                </UncontrolledDropdown>
            </div>
        </div>
    );
};
export default UserPresetQueries;
