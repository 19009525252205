import React, { useEffect, useState } from 'react';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { groupBy } from 'lodash';
import moment from 'moment';

import { expiryDatesRange } from './../../wasteDashboard/expired/expiredProductsBarChart';

const DiscountAreaChart = ({ discountProducts, t }) => {
    const [chartData, setChartData] = useState([]);

    useEffect(() => {
        if (discountProducts && discountProducts.hasOwnProperty('data')) {
            const { data } = discountProducts;
            const chartProducts = [];
            const products = data.filter(product => {
                return (product['Transactions.isDiscounted'] || product['Transactions.isDiscounted'] === 'true') && product['Transactions.discountAmount'];
            });

            const dateRange = getDateRange(products);

            const productsByDate = getProductsByDate(products);
            dateRange.forEach(date => {
                const purchaseDate = date;
                let discountSalesAmount = 0,
                    normalSalesAmount = 0,
                    discountAmountType = null;
                if (productsByDate[date]) {
                    productsByDate[date].forEach(product => {
                        discountAmountType = 'percentage';
                        let originalUnitPrice = 0;
                        if (product['Transactions.discountAmountType']) {
                            discountAmountType = product['Transactions.discountAmountType'];
                        }

                        if (!product['Transactions.originalPrice']) {
                            if (discountAmountType === 'percentage') {
                                originalUnitPrice = (100 * product['Transactions.oneUnitPrice']) / (100 - product['Transactions.discountAmount']);
                            }
                            originalUnitPrice = (100 * product['Transactions.oneUnitPrice']) / (100 - product['Transactions.discountAmount']);
                        } else {
                            originalUnitPrice = product['Transactions.originalPrice'];
                        }

                        // handle divide by 0 case
                        originalUnitPrice = isFinite(originalUnitPrice) ? originalUnitPrice : 0;

                        discountSalesAmount =
                            parseFloat(discountSalesAmount) + product['Transactions.soldCount'] * parseFloat(product['Transactions.oneUnitPrice']);
                        normalSalesAmount = parseFloat(normalSalesAmount) + product['Transactions.soldCount'] * parseFloat(originalUnitPrice);
                    });
                }
                chartProducts.push({
                    purchaseDate,
                    [t('discountAmount')]: (normalSalesAmount - discountSalesAmount).toFixed(2),
                    [t('discountSalesAmount')]: discountSalesAmount.toFixed(2),
                });
            });

            setChartData(chartProducts);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [discountProducts]);

    const getDateRange = products => {
        const { timeDimensions } = discountProducts.query;

        const startDate = Array.isArray(products) && products.length ? products[0]['Transactions.timestamp'] : null;
        const endDate = Array.isArray(products) && products.length ? products[products.length - 1]['Transactions.timestamp'] : null;
        const granularity = timeDimensions[0].granularity;
        return expiryDatesRange(granularity, startDate, endDate);
    };

    const getProductsByDate = data => {
        const products = data.map(product => {
            return {
                ...product,
                'Transactions.timestamp': moment(product['Transactions.timestamp']).format('YYYY-MM-DD'),
            };
        });
        return groupBy(products, 'Transactions.timestamp');
    };

    return (
        <ResponsiveContainer width="100%" height={400}>
            <AreaChart data={chartData} margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="purchaseDate" />
                <YAxis />
                <Tooltip />
                <Area type="monotone" dataKey={t('discountSalesAmount')} stackId="1" stroke="#118BD6" fill="#118BD6" />
                <Area type="monotone" dataKey={t('discountAmount')} stackId="1" stroke="#FDC80A" fill="#FDC80A" />
            </AreaChart>
        </ResponsiveContainer>
    );
};

export default DiscountAreaChart;
