import React, { Fragment, useEffect, useState } from 'react';
import DevicesByLocations from '../segments/devicesByLocations';
import InfoBanner from '../segments/InfoBanner';
import QRCode from 'qrcode.react';

const RefillQRCode = ({ cabinets, locations, t }) => {
    const [selectedCabinet, setSelectedCabinet] = useState(null);
    const [refillQRCodeURL, setRefillQRCodeURL] = useState('');

    useEffect(() => {
        if (!selectedCabinet) return;

        const url = (process.env.REACT_APP_REPPLENISHMENT_URL || 'https://refill-qa.selflystore.com/') + selectedCabinet.deviceCode;
        setRefillQRCodeURL(url);
    }, [selectedCabinet]);

    return (
        <div className="refillschema">
            <DevicesByLocations cabinets={cabinets} locations={locations} t={t} cabinetSelectionChange={cabinet => setSelectedCabinet(cabinet)} />

            <div className="refillschema__contents">
                {(() => {
                    if (!selectedCabinet || !refillQRCodeURL) {
                        return <InfoBanner message={t('qrcodeSubText')} />;
                    }

                    return (
                        <Fragment>
                            <InfoBanner message={t('qrcodeScan')} />
                            <div className="refillschema__qrcode">
                                <QRCode size={300} className="printable card-img-top" value={refillQRCodeURL} />

                                <div className="refillschema__qrcode-name">{selectedCabinet.name}</div>
                                <div className="refillschema__qrcode-code">{selectedCabinet.deviceCode}</div>
                                <a href={refillQRCodeURL} target="_blank" rel="noopener noreferrer">
                                    {' '}
                                    {t('qrcodeOpenApplication')}
                                </a>
                            </div>
                        </Fragment>
                    );
                })()}
            </div>
        </div>
    );
};

export default RefillQRCode;
