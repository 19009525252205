import React, { useState, useEffect, Fragment } from 'react';
import { Col } from 'reactstrap';
import { RadioButton } from '@storaensods/seeds-react';

import { groupBy } from 'lodash';
import { CubeBarChartCategorySide } from './../../cubeCharts';

const TopExpiredProductsBarChart = ({ t, data }) => {
    const [expiredProducts, setExpiredProducts] = useState({});
    const [activeSelection, setActiveSelection] = useState('total');

    useEffect(() => {
        if (data && data.length) {
            setExpiredProducts(groupBy(data, product => product['ExpiredProducts.productName']));
        }
    }, [data]);

    const getChartData = () => {
        // normalise the data for chart
        const products = Object.keys(expiredProducts).map(key => {
            let total = 0;
            let cost = 0;
            expiredProducts[key].forEach(product => {
                total = total + product['ExpiredProducts.count'];
                cost = cost + parseFloat(product['ExpiredProducts.price']) * product['ExpiredProducts.count'];
            });
            return {
                count: total,
                cost,
                name: key,
            };
        });
        // order by DESC
        products.sort((a, b) => {
            return activeSelection === 'total' ? b['count'] - a['count'] : b['cost'] - b['cost'];
        });

        // get only top 5 expired prodcuts
        if (products.length > 5) {
            products.splice(5, products.length - 5);
        }
        return products;
    };

    return (
        <Col xs="12" md="6" lg="6">
            <div className="dashboard-chart-container">
                <div className="text-center">
                    <h6>{t('topExpiredProducts')}</h6>
                </div>
                {getChartData().length === 0 ? (
                    <div className="dashboard-no-data">{t('noData')}</div>
                ) : (
                    <Fragment>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <div style={{ display: 'flex' }}>
                                <RadioButton
                                    id="1"
                                    onChange={() => setActiveSelection('total')}
                                    checked={activeSelection === 'total'}
                                    size="md"
                                    label={t('expiredCount')}
                                />
                            </div>
                            <div style={{ display: 'flex' }}>
                                <RadioButton
                                    id="2"
                                    onChange={() => setActiveSelection('cost')}
                                    checked={activeSelection === 'cost'}
                                    size="md"
                                    label={t('expiredSum')}
                                />
                            </div>
                        </div>
                        <CubeBarChartCategorySide data={getChartData()} xKey="name" yKey={activeSelection === 'total' ? 'count' : 'cost'} />
                    </Fragment>
                )}
            </div>
        </Col>
    );
};

export default TopExpiredProductsBarChart;
